import {
  TableTitleRow,
  PrescriptionRow,
  RXNumber,
  Customer,
  Fill,
  Medication,
  Quantity,
  DaysSupply,
  Directions,
  RemainingQuantity,
  RemainingRefills,
  Written,
  FillDate,
  PrescriberRX,
  Status,
  RequestQuantity,
} from './StyledComponents'

type TitleRowProps = { withQuantity?: boolean }

const TitleRow = ({ withQuantity }: TitleRowProps): JSX.Element => (
  <TableTitleRow>
    <PrescriptionRow>
      <RXNumber>Rx Number</RXNumber>
      <Customer>Customer</Customer>
      <Fill>Fill #</Fill>
      <Medication>Medication</Medication>
      <Quantity>Qty</Quantity>
      <DaysSupply>DS</DaysSupply>
      <Directions>Instructions</Directions>
      <RemainingQuantity>Qty Left</RemainingQuantity>
      <RemainingRefills>RF Left</RemainingRefills>
      <Written>Written</Written>
      <FillDate>Fill Date</FillDate>
      <PrescriberRX>Prescriber</PrescriberRX>
      <Status>Status</Status>
      {withQuantity && <RequestQuantity>Dsp qty</RequestQuantity>}
    </PrescriptionRow>
  </TableTitleRow>
)

export default TitleRow
