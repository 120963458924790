import { useCallback } from 'react'
import type { ApolloError, FetchResult } from '@truepill/tpos-react-router'
import { useMutation } from '@truepill/tpos-react-router'
import { CREATE_DOCUMENT } from 'gql'

export type CreateDocumentParams = {
  file: File
  caption: string
  fill?: string
  prescription?: string
  patient?: string
  tag?: string
}

export type CreateDocumentResponse = {
  createDocument: {
    _id: string
    caption: string
    tag: string
    s3SignedUrl: string
    uploadedBy: {
      service: string
    }
  }
}

type useCreateDocumentType = {
  createDocument: (
    imageToUpload: File,
    caption: string,
    tag: string,
  ) => Promise<FetchResult<CreateDocumentResponse, Record<string, any>, Record<string, any>>>
  status: { loading: boolean; error?: ApolloError; success: boolean }
}

export const useCreateDocument = (
  orderId?: string,
  fillId?: string,
  prescriptionId?: string,
  patientId?: string,
): useCreateDocumentType => {
  const [uploadAttachment, { loading, error, called }] = useMutation<CreateDocumentResponse, CreateDocumentParams>(
    CREATE_DOCUMENT,
    { refetchQueries: ['getDocuments'] },
  )

  const createDocument = useCallback(
    (imageToUpload: File, caption: string, tag: string) => {
      const variables = { file: imageToUpload, caption, tag }

      // The attachment goes on either the order, the fill, the prescription or the patient
      // It cannot be associated with a combination of the fields as the information is specific
      if (orderId) {
        Object.assign(variables, { orderId: orderId })
      } else if (fillId) {
        Object.assign(variables, { fill: fillId })
      } else if (prescriptionId) {
        Object.assign(variables, { prescription: prescriptionId })
      } else if (patientId) {
        Object.assign(variables, { patient: patientId })
      }

      return uploadAttachment({
        variables,
      })
    },
    [orderId, fillId, prescriptionId, patientId],
  )

  return {
    createDocument,
    status: { loading, error, success: called && !error && !loading },
  }
}
