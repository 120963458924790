import { ReactComponent as UmbrellaIcon } from 'assets/icons/umbrella.svg'
import { SaveButton } from 'components/ActionButton'
import HyphenatedNdc from 'components/HyphenatedNdc'
import IconWrapper from 'components/IconWrapper'
import { ModalWrapper, ModalHeader, ButtonsContainer } from 'components/Modal'
import { ListRowLabel, ListRowValue, StyledRXImageCell } from 'components/RXTable'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import SelectionProvider from 'providers/SelectionProvider'
import styled from 'styled-components'
import { bodyPrimaryColor, contrastBackgroundColor } from 'styles/styleVariables'
import type { Fill, Order, ClaimsGroupPricingResponse, ClaimResponseInfo, Claim } from 'types'

interface ClaimsRejectedModalProps {
  fill: Fill
  orderId: Order['_id']
  claimResponse?: ClaimsGroupPricingResponse
  updatedDispensed?: ClaimResponseInfo
}

const WrappedClaimsRejectedModal = (props: ClaimsRejectedModalProps): JSX.Element => {
  return (
    <SelectionProvider>
      <ClaimsRejectedModal {...props} />
    </SelectionProvider>
  )
}

const ClaimsRejectedModal = (props: ClaimsRejectedModalProps) => {
  const { fill, updatedDispensed } = props

  const { dismissModal } = useModalContext()
  const { claims, claimSummary } = fill
  const runId = claimSummary?.runId
  const lastClaim = runId ? claims.find((claim: Claim) => claim?.runIds?.includes(runId)) : undefined
  const sentDaysSupply = lastClaim?.sent.groups?.[0]?.claim?.daysSupply

  return (
    <ModalWrapper id="ClaimsRejectedModal">
      <ModalHeader>
        <IconWrapper>
          <UmbrellaIcon fill={bodyPrimaryColor} />
        </IconWrapper>
        <h2>Rejected Claim Response</h2>
      </ModalHeader>
      <div>
        <ClaimResponseCell>
          <ListRowLabel> Medication:</ListRowLabel>
          <ListRowValue>{updatedDispensed?.name ?? fill.dispensed?.name}</ListRowValue>
        </ClaimResponseCell>
        <ClaimResponseCell>
          <ListRowLabel>NDC:</ListRowLabel>
          <ListRowValue>
            <HyphenatedNdc ndc={updatedDispensed?.ndc ?? fill.dispensed?.ndc} />
          </ListRowValue>
        </ClaimResponseCell>
        <ClaimResponseCell>
          <ListRowLabel>Qty:</ListRowLabel>
          <ListRowValue>{updatedDispensed?.quantity ?? fill.dispensed?.quantity}</ListRowValue>
        </ClaimResponseCell>
        <ClaimResponseCell>
          <ListRowLabel>Ds:</ListRowLabel>
          <ListRowValue>{sentDaysSupply ?? updatedDispensed?.daysSupply ?? fill.dispensed?.daysSupply} </ListRowValue>
        </ClaimResponseCell>
        <ClaimResponseCell>
          <ListRowLabel>Directions:</ListRowLabel>
          <ListRowValue>{updatedDispensed?.directions ?? fill.dispensed?.directions} </ListRowValue>
        </ClaimResponseCell>
      </div>
      <ButtonsContainer>
        <SaveButton
          isModal
          label={'Close'}
          onClick={() => {
            dismissModal()
          }}
        />
      </ButtonsContainer>
    </ModalWrapper>
  )
}

const ClaimResponseCell = styled(StyledRXImageCell)`
  :nth-of-type(even) {
    background-color: ${contrastBackgroundColor};
  }
  grid-template-columns: [label] 10rem [value] 15rem;
  font-family: Roboto;
  > h4 {
    font-family: roboto;
  }
  border: none;
  > label {
    padding-left: 1.25rem;
    font-weight: 500;
  }
`

export default WrappedClaimsRejectedModal
