import { useCallback } from 'react'
import OrderReturnModal from 'modals/OrderReturnModal'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import type { Order } from 'types'

type useProcessOrderIssueType = () => void

const useProcessOrderIssue = (orderId: Order['_id']): useProcessOrderIssueType => {
  const { showModal } = useModalContext()

  return useCallback(() => {
    showModal(() => <OrderReturnModal orderId={orderId} completionCallback={() => null} />)
  }, [orderId, showModal])
}

export default useProcessOrderIssue
