import { useMutation } from '@truepill/tpos-react-router'
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg'
import { SaveButton } from 'components/ActionButton'
import IconWrapper from 'components/IconWrapper'
import { ModalWrapper, ModalHeader, ButtonsContainer } from 'components/Modal'
import { CancelButton, SubTitle } from 'components/PageStructure'
import { REMOVE_ATTACHMENT_FROM_RX_IMAGE } from 'gql'
import useErrorToast from 'hooks/toast/useErrorToast'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import styled from 'styled-components'
import { bodyPrimaryColor } from 'styles/styleVariables'

type AttachmentModalProps = {
  title?: string
  orderId?: string
  fillId?: string
  prescriptionId?: string
  patientId?: string
}

const RemoveAttachmentModal = ({ title = 'Remove attachment', prescriptionId }: AttachmentModalProps): JSX.Element => {
  const { dismissModal } = useModalContext()
  const showErrorToast = useErrorToast()

  const [removeAttachmentFromRxImage] = useMutation(REMOVE_ATTACHMENT_FROM_RX_IMAGE, {
    onCompleted() {
      window.location.reload()
    },
    onError(err) {
      showErrorToast(`Error removing Rx Image attachment: ${err.message}`)
    },
  })

  return (
    <ModalWrapper id="AddAttachmentModal">
      <ModalHeader>
        <IconWrapper>
          <PlusIcon fill={bodyPrimaryColor} />
        </IconWrapper>
        <h2>{title}</h2>
      </ModalHeader>
      <SubTitleContainer>
        <SubTitle>Are you sure you want to remove an attachment?</SubTitle>
        <SubTitle>This will remove the most recent attachment and revert the rx image</SubTitle>
      </SubTitleContainer>
      <ButtonsContainer>
        <CancelButton label={'Cancel'} onClick={dismissModal} />
        <SaveButton
          isModal
          label={'Remove'}
          onClick={async () => {
            await removeAttachmentFromRxImage({
              variables: {
                prescriptionId,
              },
            })
          }}
        />
      </ButtonsContainer>
    </ModalWrapper>
  )
}

const SubTitleContainer = styled.div`
  margin: 1rem;
`

export default RemoveAttachmentModal
