import { useEffect, useRef } from 'react'
import JsBarcode from 'jsbarcode'

// Open chrome devtools -> sources for a preview barcode
// Must add localStorage item: devtoolBarcode=1
const useDevToolBarcode = (barcodes: { label: string; code: string }[]): void => {
  const barcodeToBlobURL = useRef(new Map<string, string>())

  useEffect(() => {
    const barcodeURLMap: Map<string, string> = barcodeToBlobURL.current

    if (localStorage.getItem('devtoolBarcode')) {
      barcodes.forEach(({ label, code }) => {
        // Ignore barcodes based on data that hasn't returned from the server
        if (!code || code.length < 5) {
          return
        }

        if (label && label.includes('NDC')) {
          // if it's an NDC add a random control character
          // the number 4 was chosen by fair dice roll
          // guaranteed to be random
          code = `${code}4`
        }

        const existingUrl = barcodeURLMap.get(code)
        if (existingUrl) {
          return
        }

        const svg = document.createElement('svg')
        JsBarcode(svg, code, { format: 'CODE128' })
        const blob = new Blob([svg.outerHTML], { type: 'image/svg+xml' })
        const url = URL.createObjectURL(blob)
        const img = document.createElement('img')

        img.onload = () => {
          console.info(`${label}: ${code}`)
          // Make this available to Cypress the easy way:
          localStorage.setItem('cypressBarCodeToScan', `${code}`)
          console.info(img.src)
        }

        img.src = url
        barcodeURLMap.set(code, url)
      })
    }
  }, [barcodes])
}

export default useDevToolBarcode
