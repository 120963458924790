import { useEffect } from 'react'
import { ModalHeader, ModalTitle, ModalWrapper } from 'components/Modal'
import { useFormData } from 'hooks/useFormData'
import type { ExceptionNDC } from 'types'
import ExceptionNDCForm from './ExceptionNDCForm'

const AddExceptionNDCModal = ({
  onSubmit,
  sigCode,
  editNDC,
}: {
  onSubmit?: (exceptionNdc: ExceptionNDC) => void
  sigCode?: string
  editNDC?: ExceptionNDC
}): JSX.Element => {
  const {
    actions: { updateFormData },
  } = useFormData()

  useEffect(() => {
    if (editNDC) {
      updateFormData({
        exceptionNdc: {
          $set: {
            ndc: editNDC.ndc,
            medicationName: editNDC.medicationName,
            multiplier: editNDC.multiplier,
          },
        },
      })
    } else {
      updateFormData({
        exceptionNdc: {
          $set: {
            ndc: '',
            medicationName: '',
            multiplier: 0,
          },
        },
      })
    }
  }, [editNDC, updateFormData])

  return (
    <ModalWrapper styles={[{ width: '31rem', minHeight: '35rem' }]} id="exceptionNDCModal">
      <ModalHeader>
        <ModalTitle>Add Exception NDC</ModalTitle>
      </ModalHeader>
      <ExceptionNDCForm sigCode={sigCode} editNDC={editNDC} />
    </ModalWrapper>
  )
}

export default AddExceptionNDCModal
