export enum ShippingCarrier {
  UPS = 'ups',
  USPS = 'usps',
  FEDEX = 'fedex',
  DHL = 'dhl',
}

export enum ShippingMethod {
  DhlEcommerceParcelPlusExpeditedMax = 'dhl_ecommerce_parcel_plus_expedited_max',
  DhlEcommerceParcelExpedited = 'dhl_ecommerce_parcel_expedited',
  DhlEcommerceParcelPlusExpedited = 'dhl_ecommerce_parcel_plus_expedited',
  DhlEcommerceParcelExpeditedMax = 'dhl_ecommerce_parcel_expedited_max',
  Fedex2Day = 'fedex_2_day',
  FedexGround = 'fedex_ground',
  FedexPriorityExpress = 'fedex_priority_express',
  FedexPriorityOvernight = 'fedex_priority_overnight',
  FedexSmartPost = 'fedex_smart_post',
  FedexStandardOvernight = 'fedex_standard_overnight',
  Ups3DaySelect = 'ups_3_day_select',
  UpsGround = 'ups_ground',
  UpsMailInnovationsDomestic = 'ups_mail_innovations_domestic',
  UpsNextDayAir = 'ups_next_day_air',
  UpsNextDayAirEarlyAm = 'ups_next_day_air_early_am',
  UpsNextDayAirSaver = 'ups_next_day_air_saver',
  UpsSecondDayAir = 'ups_second_day_air',
  UpsSecondDayAirAm = 'ups_second_day_air_am',
  UpsSurepostLightweight = 'ups_surepost_lightweight',
  UspsFirst = 'usps_first',
  UspsGroundAdvantage = 'usps_ground_advantage',
  UspsPriorityExpress = 'usps_priority_express',
  UspsPriorityMailExpress = 'usps_priority_mail_express',
  UspsPriority = 'usps_priority',
}

export const ShippingCarrierByShippingMethod: { [k in ShippingMethod]: ShippingCarrier } = {
  [ShippingMethod.UspsPriority]: ShippingCarrier.USPS,
  [ShippingMethod.UspsPriorityExpress]: ShippingCarrier.USPS,
  [ShippingMethod.UspsFirst]: ShippingCarrier.USPS,
  [ShippingMethod.UspsGroundAdvantage]: ShippingCarrier.USPS,
  [ShippingMethod.FedexPriorityOvernight]: ShippingCarrier.FEDEX,
  [ShippingMethod.FedexStandardOvernight]: ShippingCarrier.FEDEX,
  [ShippingMethod.FedexGround]: ShippingCarrier.FEDEX,
  [ShippingMethod.Fedex2Day]: ShippingCarrier.FEDEX,
  [ShippingMethod.UpsSurepostLightweight]: ShippingCarrier.UPS,
  [ShippingMethod.FedexPriorityExpress]: ShippingCarrier.FEDEX,
  [ShippingMethod.UpsNextDayAir]: ShippingCarrier.UPS,
  [ShippingMethod.UpsMailInnovationsDomestic]: ShippingCarrier.UPS,
  [ShippingMethod.DhlEcommerceParcelPlusExpeditedMax]: ShippingCarrier.DHL,
  [ShippingMethod.DhlEcommerceParcelExpedited]: ShippingCarrier.DHL,
  [ShippingMethod.DhlEcommerceParcelPlusExpedited]: ShippingCarrier.DHL,
  [ShippingMethod.DhlEcommerceParcelExpeditedMax]: ShippingCarrier.DHL,
  [ShippingMethod.UpsNextDayAirSaver]: ShippingCarrier.UPS,
  [ShippingMethod.UpsSecondDayAir]: ShippingCarrier.UPS,
  [ShippingMethod.Ups3DaySelect]: ShippingCarrier.UPS,
  [ShippingMethod.UpsGround]: ShippingCarrier.UPS,
  [ShippingMethod.UpsNextDayAirEarlyAm]: ShippingCarrier.UPS,
  [ShippingMethod.UpsSecondDayAirAm]: ShippingCarrier.UPS,
  [ShippingMethod.FedexSmartPost]: ShippingCarrier.FEDEX,
  [ShippingMethod.UspsPriorityMailExpress]: ShippingCarrier.USPS,
}

export const TrackingUrlByShippingCarrier: { [k in ShippingCarrier]: string } = {
  [ShippingCarrier.UPS]: 'http://wwwapps.ups.com/WebTracking/processInputRequest?TypeOfInquiryNumber=T&InquiryNumber1=',
  [ShippingCarrier.USPS]: 'https://tools.usps.com/go/TrackConfirmAction.action?tLabels=',
  [ShippingCarrier.FEDEX]: 'https://www.fedex.com/fedextrack/?tracknumbers=',
  [ShippingCarrier.DHL]: 'http://www.dhl-usa.com/content/us/en/express/tracking.shtml?brand=DHL&AWB=',
}
