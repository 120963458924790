import { Spacer } from '@truepill/react-capsule'
import LoadingSpinner from 'components/Loading'
import { TwoColumnDivision, ListRowLabel, ListRowValue, StyledRXImageCell, SmallPaddingBlock } from 'components/RXTable'
import { isNull } from 'lodash'
import moment from 'moment'
import styled from 'styled-components'
import { contrastBackgroundColor } from 'styles/styleVariables'
import type {
  Fill,
  Order,
  RXFillRequest,
  Claim,
  OrderInsurance,
  RxFillRequestDur,
  EdiOverrides,
  Prescription,
} from 'types'
import {
  stripTypename,
  calculateClaimPricingResponseFee,
  calculateClaimPricingFee,
  getInsuranceKey,
  getClaimsFromLastRun,
} from 'utils'
import HyphenatedNdc from './HyphenatedNdc'

const durReadable = {
  levelOfEffort: {
    display: 'DUR - Level Of Effort',
  },
  professionalService: {
    display: 'DUR - Professional Service',
  },
  reasonForService: {
    display: 'DUR - Reason For Service',
  },
  resultOfService: {
    display: 'DUR - Result Of Service',
  },
  coAgentQualifier: {
    display: 'DUR - CO-Agent Qualifier',
  },
  coAgentId: {
    display: 'DUR - Co-Agent Id',
  },
}

const overrideReadable = {
  additionalDocumentationTypeID: {
    display: 'Additional Documentation Type ID',
  },
  lengthOfNeed: {
    display: 'Length Of Need',
  },
  lengthOfNeedQualifier: {
    display: 'Length Of Need Qualifier',
  },
  prescriberDateSigned: {
    display: 'Prescriber Date Signed',
  },
  requestPeriodBeginDate: {
    display: 'Request Period Begin Date',
  },
  requestPeriodRecertificationDate: {
    display: 'Request Period Recertification Date',
  },
  requestStatus: {
    display: 'Request Status',
  },
  supportingDocumentation: {
    display: 'Supporting Documentation',
  },
  intermediaryAuthorizationId: {
    display: 'Intermediary AuthorizationId',
  },
  intermediaryAuthorizationTypeId: {
    display: 'Intermediary Authorization Type Id',
  },
  pharmacyServiceType: {
    display: 'Pharmacy Service Type',
  },
  scheduledPrescriptionIdNumber: {
    display: 'Scheduled Prescription Id Number',
  },
  clinicalInformationCounter: {
    display: 'Clinical Information Counter',
  },
  diagnosisCode: {
    display: 'Diagnosis Code',
  },
  diagnosisCodeQualifier: {
    display: 'Diagnosis Code Qualifier',
  },
  measurementDate: {
    display: 'Measurement Date',
  },
  measurementDimension: {
    display: 'Measurement Dimension',
  },
  measurementTime: {
    display: 'Measurement Time',
  },
  measurementUnit: {
    display: 'Measurement Unit',
  },
  measurementValue: {
    display: 'Measurement Value',
  },
  facilityCityAddress: {
    display: 'Facility City Address',
  },
  facilityId: {
    display: 'Facility Id',
  },
  facilityName: {
    display: 'facility Name',
  },
  facilityStateProvinceAddress: {
    display: 'Facility State Province Address',
  },
  facilityStreetAddress: {
    display: 'Facility Street Address',
  },
  facilityZipPostalCode: {
    display: 'Facility Zip Postal Code',
  },
  dateOfService: {
    display: 'Date Of Service',
  },
  serviceProviderId: {
    display: 'Service Provider Id',
  },
  softwareVendorId: {
    display: 'Software Vendor Id',
  },
  eligibilityClarification: {
    display: 'Eligibility Clarification',
  },
  firstName: {
    display: 'First Name',
  },
  homePlan: {
    display: 'Home Plan',
  },
  lastName: {
    display: 'Last Name',
  },
  planId: {
    display: 'Plan Id',
  },
  narrativeMessage: {
    display: 'Narrative Message',
  },
  id: {
    display: 'Id',
  },
  residence: {
    display: 'Residence',
  },
  medicaidId: {
    display: 'Medicaid Id',
  },
  primaryCareProviderId: {
    display: 'Primary Care Provider Id',
  },
  primaryCareProviderIdQualifier: {
    display: 'Primary Care Provider Id Qualifier',
  },
  primaryCareProviderLastName: {
    display: 'Primary Care Provider Last Name',
  },
  basisOfCostDetermination: {
    display: 'Basis Of Cost Determination',
  },
  dispensingFeeSubmitted: {
    display: 'Dispensing Fee Submitted',
    prefix: '$',
  },
  flatSalesTax: {
    display: 'Flat Sales Tax',
  },
  grossAmountDue: {
    display: 'Gross Amount Due',
    prefix: '$',
  },
  ingredientCostSubmitted: {
    display: 'Ingredient Cost Submitted',
    prefix: '$',
  },
  patientPayAmount: {
    display: 'Patient Pay Amount',
  },
  percentageSalesTaxAmount: {
    display: 'Percentage Sales Tax Amount',
  },
  percentageSalesTaxBasis: {
    display: 'Percentage Sales Tax Basis',
  },
  percentageSalesTaxRate: {
    display: 'Percentage Sales Tax Rate',
  },
  usualAndCustomaryCharge: {
    display: 'Usual And Customary Charge',
    prefix: '$',
  },
}

const claimType = ['Primary', 'Secondary', 'Tertiary', 'Tertiary']

const makeBasicOverview = (fill?: Fill, claims?: Claim[], prescription?: Prescription): JSX.Element => {
  if (!claims?.length) {
    return <></>
  }

  const primaryClaim = claims[0]
  const cost = primaryClaim?.cost || 0

  const totalPaid: number = claims.reduce((paid, curClaim) => {
    const pricingReceived = curClaim?.received?.groups?.[0]?.pricingResponse
    return paid + (pricingReceived?.totalAmountPaid || 0) + (pricingReceived?.patientPayAmount || 0)
  }, 0)

  const dateOfService = moment(primaryClaim?.dateOfService).format('MM/DD/YYYY')
  const sentDaysSupply = primaryClaim?.sent.groups?.[0]?.claim?.daysSupply

  return (
    <>
      <Spacer size="md" />
      <ClaimResponseCell>
        <ListRowLabel>RX Number:</ListRowLabel>
        <ListRowValue>{prescription?.rxNumber}</ListRowValue>
      </ClaimResponseCell>
      <ClaimResponseCell>
        <ListRowLabel>Date of service:</ListRowLabel>
        <ListRowValue>{dateOfService}</ListRowValue>
      </ClaimResponseCell>
      <ClaimResponseCell>
        <ListRowLabel>NDC:</ListRowLabel>
        <ListRowValue>
          <HyphenatedNdc ndc={fill?.dispensed?.ndc ?? ''} />
        </ListRowValue>
      </ClaimResponseCell>
      <ClaimResponseCell>
        <ListRowLabel>Medication:</ListRowLabel>
        <ListRowValue>{fill?.dispensed?.name}</ListRowValue>
      </ClaimResponseCell>
      <ClaimResponseCell>
        <ListRowLabel>Qty billed:</ListRowLabel>
        <ListRowValue>{fill?.dispensed?.quantity ?? 'Unknown'}</ListRowValue>
      </ClaimResponseCell>
      <ClaimResponseCell>
        <ListRowLabel>Days supply:</ListRowLabel>
        <ListRowValue>{sentDaysSupply ?? fill?.dispensed?.daysSupply ?? 'Unknown'}</ListRowValue>
      </ClaimResponseCell>
      <ClaimResponseCell>
        <ListRowLabel>Profit:</ListRowLabel>
        <ListRowValue>${(totalPaid - cost).toFixed(2)}</ListRowValue>
      </ClaimResponseCell>
    </>
  )
}

const getOrderInsuranceFromClaim = (claim?: Claim, insurances?: OrderInsurance[]) => {
  if (!claim || !insurances?.length) {
    return undefined
  }

  const bin = claim.sent?.header?.bin
  const pcn = claim.sent?.header?.processorControlNumber
  const cardholderId = claim.sent?.metadata?.insurance?.cardholderId
  const group = claim.sent?.metadata?.insurance?.groupId

  return insurances.find(i => i.bin === bin && i.pcn === pcn && i.cardholderId === cardholderId && i.group === group)
}

const makeInsuranceOverview = (claims?: Claim[], insurances?: OrderInsurance[]) => {
  if (!claims) {
    return <></>
  }

  return (
    <>
      <InsuranceContainer>
        <InsuranceHeaders>
          <TitleHeader>
            <h2>Payer</h2>
          </TitleHeader>
          {claims.map((claim, index) => {
            return (
              <ItemHeader>
                <h2>{claimType[index]}</h2>
              </ItemHeader>
            )
          })}
        </InsuranceHeaders>
        <InsuranceGrid>
          {claims.map(claim => {
            const bin = claim.sent?.header?.bin || ''
            const pcn = claim.sent?.header?.processorControlNumber || ''
            const cardholderId = claim.sent?.metadata?.insurance?.cardholderId || ''
            const group = claim.sent?.metadata?.insurance?.groupId || ''
            const claimInsuranceKey = getInsuranceKey(bin, cardholderId, pcn)
            const matachingInsuranceOption = insurances?.find(
              i => getInsuranceKey(i.bin || '', i.cardholderId, i.pcn) === claimInsuranceKey,
            )

            return (
              <InsuranceItem>
                <ClaimResponseCell closeOffTopBorder>
                  <ListRowLabel>Name:</ListRowLabel>
                  <ListRowValue>{matachingInsuranceOption?.name}</ListRowValue>
                </ClaimResponseCell>
                <ClaimResponseCell>
                  <ListRowLabel>Transmitted date:</ListRowLabel>
                  <ListRowValue>
                    {claim.transmissionDate ? moment(claim.transmissionDate).format('MM/DD/YYYY h:mm:ss a') : ''}
                  </ListRowValue>
                </ClaimResponseCell>
                <ClaimResponseCell>
                  <ListRowLabel>BIN:</ListRowLabel>
                  <ListRowValue>{bin}</ListRowValue>
                </ClaimResponseCell>
                <ClaimResponseCell>
                  <ListRowLabel>PCN:</ListRowLabel>
                  <ListRowValue>{pcn}</ListRowValue>
                </ClaimResponseCell>
                <ClaimResponseCell>
                  <ListRowLabel>Group:</ListRowLabel>
                  <ListRowValue>{group}</ListRowValue>
                </ClaimResponseCell>
                <ClaimResponseCell closeOffBottomBorder>
                  <ListRowLabel>Cardholder Id:</ListRowLabel>
                  <ListRowValue>{cardholderId}</ListRowValue>
                </ClaimResponseCell>
              </InsuranceItem>
            )
          })}
        </InsuranceGrid>
      </InsuranceContainer>
    </>
  )
}

const makePricingOverview = (claims?: Claim[]) => {
  return (
    <>
      <InsuranceContainer>
        <Columns>
          <PrimaryColumn>
            <h2>Transmission Pricing</h2>
          </PrimaryColumn>
        </Columns>
        <InsuranceGrid>
          {claims?.map(claim => {
            const pricingSent = claim?.sent?.groups?.[0]?.pricing
            const pricingReceived = claim?.received?.groups?.[0]?.pricingResponse
            const feeReceived = !!pricingReceived ? calculateClaimPricingResponseFee(pricingReceived) : 0
            const feeSent = !!pricingSent ? calculateClaimPricingFee(pricingSent) : 0

            return (
              <InsuranceItem>
                <ClaimResponseCell closeOffTopBorder>
                  <ListRowLabel></ListRowLabel>
                  <ListRowValue style={{ fontWeight: 'bold' }}>Submitted</ListRowValue>
                  <ListRowValue2 style={{ fontWeight: 'bold' }}>Paid</ListRowValue2>
                </ClaimResponseCell>
                <ClaimResponseCell>
                  <ListRowLabel>Ingredient cost:</ListRowLabel>
                  <ListRowValue>${pricingSent?.ingredientCostSubmitted}</ListRowValue>
                  <ListRowValue2>${pricingReceived?.ingredientCostPaid}</ListRowValue2>
                </ClaimResponseCell>
                <ClaimResponseCell>
                  <ListRowLabel>Fee:</ListRowLabel>
                  <ListRowValue>${feeSent}</ListRowValue>
                  <ListRowValue2>${feeReceived}</ListRowValue2>
                </ClaimResponseCell>
                <ClaimResponseCell>
                  <ListRowLabel>Remittance:</ListRowLabel>
                  <ListRowValue>${pricingSent?.usualAndCustomaryCharge}</ListRowValue>
                  <ListRowValue2>${pricingReceived?.totalAmountPaid}</ListRowValue2>
                </ClaimResponseCell>
                <ClaimResponseCell>
                  <ListRowLabel>Copay:</ListRowLabel>
                  <ListRowValue>$0</ListRowValue>
                  <ListRowValue2>${pricingReceived?.patientPayAmount}</ListRowValue2>
                </ClaimResponseCell>
                <ClaimResponseCell closeOffBottomBorder>
                  <ListRowLabel>Total paid:</ListRowLabel>
                  <ListRowValue>${pricingSent?.usualAndCustomaryCharge}</ListRowValue>
                  <ListRowValue2>
                    ${((pricingReceived?.totalAmountPaid || 0) + (pricingReceived?.patientPayAmount || 0)).toFixed(2)}
                  </ListRowValue2>
                </ClaimResponseCell>
              </InsuranceItem>
            )
          })}
        </InsuranceGrid>
      </InsuranceContainer>
    </>
  )
}

const makeOverrides = (rxFillRequest?: RXFillRequest, claims?: Claim[], insurances?: OrderInsurance[]) => {
  if (!rxFillRequest) {
    return <></>
  }

  const { durs, ediOverrides } = rxFillRequest

  return (
    <>
      <Columns>
        <PrimaryColumn>
          <h2>Overrides</h2>
        </PrimaryColumn>
      </Columns>
      <Columns>
        {claims?.map(claim => {
          const claimInsurance = getOrderInsuranceFromClaim(claim, insurances)
          const claimDurs: RxFillRequestDur | undefined = durs?.find(
            i => i.orderInsuranceOptionId === claimInsurance?.orderInsuranceOptionId,
          )
          const claimOverrides: EdiOverrides | undefined = ediOverrides?.find(
            i => i.orderInsuranceOptionId === claimInsurance?.orderInsuranceOptionId,
          )
          return <PrimaryColumn>{makeEdiOverridesSection(claimOverrides, claimDurs)}</PrimaryColumn>
        })}
      </Columns>
    </>
  )
}

const makeEdiOverridesSection = (ediOverrides?: EdiOverrides, durs?: RxFillRequestDur): JSX.Element => {
  const dursArray = durs
    ? Object.entries(stripTypename(durs)).filter(i => !!i[1] && i[0] !== 'orderInsuranceOptionId')
    : []
  const cleanEdiOverrides = ediOverrides ? Object.entries(stripTypename(ediOverrides)).filter(i => !!i[1]) : []

  return (
    <>
      {dursArray.map((dur, index) => {
        const [key, value] = dur

        return (
          <ClaimResponseCell
            key={`durs-${index}`}
            closeOffBottomBorder={cleanEdiOverrides.length === 0 && index === dursArray.length - 1}
          >
            <ListRowLabel>{durReadable[key as keyof typeof durReadable]?.display}</ListRowLabel>
            <ListRowValue>
              {(durReadable[key as keyof typeof durReadable] as unknown as { prefix: string }).prefix}
              {value}
            </ListRowValue>
          </ClaimResponseCell>
        )
      })}
      {cleanEdiOverrides.map((i, index) => {
        const [key, subOverrides] = i
        if (key === 'orderInsuranceOptionId') return null
        return (
          <>
            {Object.entries(subOverrides).map(entry => {
              const [name, value] = entry
              if (!isNull(value)) {
                return (
                  <ClaimResponseCell closeOffBottomBorder={index === cleanEdiOverrides.length - 1} key={index}>
                    <ListRowLabel>
                      {overrideReadable[name as keyof typeof overrideReadable]?.display}{' '}
                      <OverrideHeader>{key}</OverrideHeader>
                    </ListRowLabel>
                    <ListRowValue>
                      {
                        (overrideReadable[name as keyof typeof overrideReadable] as unknown as { prefix: string })
                          ?.prefix
                      }
                      {value}
                    </ListRowValue>
                  </ClaimResponseCell>
                )
              }
              return null
            })}
          </>
        )
      })}
    </>
  )
}

type ClaimSummaryProps = { fill?: Fill; order?: Order; rxFillRequest?: RXFillRequest; prescription: Prescription }

const ClaimSummary = ({ fill, order, rxFillRequest, prescription }: ClaimSummaryProps): JSX.Element => {
  if (!fill) {
    return (
      <ClaimSummaryContainer>
        <LoadingSpinnerContainer>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      </ClaimSummaryContainer>
    )
  }

  const claims = getClaimsFromLastRun(fill.claims, fill.claimSummary?.runId)

  if (claims.length === 0) {
    const rejectedClaim = [...fill.claims].reverse()[0]

    const dateOfService = rejectedClaim?.received?.header?.dateOfService
    const transactionResponseStatus = rejectedClaim?.received?.groups?.[0]?.response?.transactionStatus
    const rejectCount = rejectedClaim?.received?.groups?.map(i => i?.response?.transactionStatus === 'R').length
    const rejectCode = rejectedClaim?.received?.groups?.[0]?.response.rejectCodes[0].rejectCode
    const rejectCodeTranslated = rejectedClaim?.received?.groups?.[0]?.response.rejectCodes[0].rejectCodeTranslated
    const additionalMessageInformation = rejectedClaim?.received?.groups?.[0]?.response.additionalMessageInformation
    const prescriptionRefNumber = rejectedClaim?.received?.groups?.[0]?.claimResponse?.prescriptionRefNumber

    return (
      <ClaimSummaryContainer>
        <div>RX Number: {prescription.rxNumber}</div>
        <div>Date of Service: {dateOfService}</div>
        <div>Transaction Response Status: {transactionResponseStatus}</div>
        <div>Reject Count: {rejectCount}</div>
        <div>
          Reject Code: {rejectCode} ({rejectCodeTranslated})
        </div>
        <div>Additonal Message Information: {additionalMessageInformation}</div>
        <div>Prescription/Service Reference Number: {prescriptionRefNumber}</div>
      </ClaimSummaryContainer>
    )
  }

  return (
    <ClaimSummaryContainer>
      {makeBasicOverview(fill, claims, prescription)}
      <SmallPaddingBlock />
      {makeInsuranceOverview(claims, order?.insurances)}
      <SmallPaddingBlock />
      {makePricingOverview(claims)}
      <SmallPaddingBlock />
      {makeOverrides(rxFillRequest, claims, order?.insurances)}
    </ClaimSummaryContainer>
  )
}

const ClaimResponseCell = styled(StyledRXImageCell)`
  :nth-of-type(even) {
    background-color: ${contrastBackgroundColor};
  }
  grid-template-columns: [label] 10rem [value] 15rem;
  font-family: Roboto;
  border-radius: 0.4rem;
  > h4 {
    font-family: roboto;
  }
  border: none;
  > label {
    padding-left: 1.25rem;
    font-weight: 500;
  }
`

const ListRowValue2 = styled.div`
  grid-row: 1;
  align-self: center;
  grid-column: value2;
  display: flex;
  padding-right: 0.625rem;
  overflow: visible;
  align-items: flex-stretch;
  > button {
    flex: 1;
  }
`
ListRowValue.displayName = 'ListRowValue2'

const Columns = styled.div`
  ${TwoColumnDivision}
  grid-gap: 1.25rem;
`

const ClaimSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledColumn = styled.ul`
  > :nth-child(2n) {
    background-color: ${contrastBackgroundColor};
  }
`

const PrimaryColumn = styled(StyledColumn)`
  grid-column: center;
  width: 25rem;
`

const LoadingSpinnerContainer = styled.div`
  display: flex;
  padding-top: 0.8rem;
  justify-content: center;
  svg {
    height: 25px;
  }
`

const OverrideHeader = styled.div`
  font-size: 12px;
  font-weight: normal;
`

const InsuranceContainer = styled.div``
const InsuranceHeaders = styled.div`
  :after {
    clear: both;
    content: '';
    display: block;
  }
`
const TitleHeader = styled.div`
  display: inline-block;
  float: left;
  width: 10rem;
`
const ItemHeader = styled.div`
  display: inline-block;
  float: left;
  width: 30rem;
  padding-right: 1rem;
`

const InsuranceGrid = styled.div``
const InsuranceItem = styled.div`
  display: inline-block;
  padding-right: 1rem;
  float: left;
  width: 30rem;
`

export default ClaimSummary
