import { useState } from 'react'
import { useMutation } from '@truepill/tpos-react-router'
import { SaveButton } from 'components/ActionButton'
import NewPracticeForm from 'components/NewPracticeForm'
import type { ValidationStatus } from 'components/NewPrescriberForm'
import NewPrescriberForm from 'components/NewPrescriberForm'
import { CancelButton, PageContainer } from 'components/PageStructure'
import { ADD_PRESCRIBER } from 'gql'
import useErrorToast from 'hooks/toast/useErrorToast'
import useAddPractice from 'hooks/useAddPractice'
import useAddPrescriber from 'hooks/useAddPrescriber'
import useSetPageTitle from 'hooks/useSetPageTitle'
import { useHistory, useLocation } from 'react-router'
import { goToPrescribers, goToViewPrescriber } from 'routes'
import { formatPhoneForDB, validatePrescriberAndPractice } from 'utils'
import { Title, DataContainer, StyledFilledHeader, ButtonContainer } from './StyledComponents'

const AddPrescriberPage = (): JSX.Element => {
  const history = useHistory()
  const { search } = useLocation()
  const { businessName, phone, fax, address, handlePracticeFormChange } = useAddPractice()
  const { npi, firstName, lastName, suffix, stateLicense, handlePrescriberFormChange } = useAddPrescriber()
  const [canAddPrescriber, setCanAddPrescriber] = useState(false)

  const showErrorToast = useErrorToast()

  useSetPageTitle('New prescriber')

  const [addPrescriber] = useMutation(ADD_PRESCRIBER, {
    onCompleted(prescriber) {
      const id = prescriber?.addPrescriber?._id
      history.push(goToViewPrescriber({ prescriberId: id as string, search }))
    },
    onError(e) {
      showErrorToast('Failed to add new prescriber: ' + e.message)
    },
    refetchQueries: ['getSyncedPrescriber'],
  })

  const handleChangePrescriberValidationStatus = ({ isPrescriberDuplicated, loading, error }: ValidationStatus) => {
    setCanAddPrescriber(!isPrescriberDuplicated && !loading && !error)
  }

  return (
    <PageContainer>
      <Title>New prescriber</Title>
      <DataContainer>
        <StyledFilledHeader>Prescriber</StyledFilledHeader>
        <NewPrescriberForm
          npi={npi}
          firstName={firstName}
          lastName={lastName}
          suffix={suffix}
          stateLicense={stateLicense ? { number: stateLicense, state: address.state } : undefined}
          onChange={handlePrescriberFormChange}
          onChangeValidationStatus={handleChangePrescriberValidationStatus}
        />
        <StyledFilledHeader>Practice</StyledFilledHeader>
        <NewPracticeForm
          businessName={businessName}
          address={address}
          phone={phone}
          fax={fax}
          onChange={handlePracticeFormChange}
        />
        <ButtonContainer>
          <CancelButton label="Cancel" onClick={() => history.push(goToPrescribers({ search }))} />
          <SaveButton
            disabled={
              !canAddPrescriber ||
              !validatePrescriberAndPractice(
                npi || '',
                stateLicense || '',
                firstName || '',
                lastName || '',
                suffix || '',
                address,
                phone || '',
                fax || '',
              )
            }
            label="Create"
            onClick={() =>
              addPrescriber({
                variables: {
                  newPrescriber: { npi, firstName, lastName, stateLicense, suffix },
                  newPractice: {
                    address,
                    phone: formatPhoneForDB(phone),
                    ...(businessName && { name: businessName }),
                    ...(fax && { fax: formatPhoneForDB(fax) }),
                  },
                },
              })
            }
          />
        </ButtonContainer>
      </DataContainer>
    </PageContainer>
  )
}

export default AddPrescriberPage
