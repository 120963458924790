import type { FunctionComponent } from 'react'
import { ListRowLabel, ListRowValue, RaisedAnchor, RightFilledHeader, PaddingBlock } from 'components/RXTable'
import useHotKey, { HotKeyLevel } from 'hooks/useHotKey'
import { usePlusClient } from 'providers/VisionRouter'
import type { EdiOverrides } from 'types'
import { RXEDIListRow, RXEDImageCell, RXEDIRightEditCell, RXEDIRightTitleCell, RXEDITitleRow, RXTextInput } from '..'

const CoordinationOfBenefitSegment: FunctionComponent<{
  ediOverrides: EdiOverrides
  updateEdiOverrides: (input: EdiOverrides) => void
}> = ({ ediOverrides, updateEdiOverrides }) => {
  const { coordinationOfBenefits } = ediOverrides
  const otherPayerCoverageType = coordinationOfBenefits?.otherPayerCoverageType
  const internalControlNumber = coordinationOfBenefits?.internalControlNumber

  return (
    <>
      <CoordinationOfBenefitsBHeaderRow />
      <RXEDIListRow>
        <RXEDIRightEditCell>
          <ListRowLabel>Other Payer CoverageType(5C):</ListRowLabel>
          <ListRowValue>
            <RXTextInput
              value={otherPayerCoverageType ?? ''}
              onChange={({ target: { value: otherPayerCoverageType } }) =>
                updateEdiOverrides({ coordinationOfBenefits: { otherPayerCoverageType } })
              }
              placeholder="Other Payer CoverageType"
            />
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>
      <RXEDIListRow>
        <RXEDIRightEditCell>
          <ListRowLabel>Internal Control Number(A7):</ListRowLabel>
          <ListRowValue>
            <RXTextInput
              value={internalControlNumber ?? ''}
              onChange={({ target: { value: internalControlNumber } }) =>
                updateEdiOverrides({ coordinationOfBenefits: { internalControlNumber } })
              }
              placeholder="Internal Control Number"
            />
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>
    </>
  )
}

const CoordinationOfBenefitsBHeaderRow = () => {
  const { routeToHash } = usePlusClient()

  useHotKey('7', HotKeyLevel.normal, () => {
    routeToHash(`CoordinationOfBenefitSegment`)
  })

  return (
    <RXEDITitleRow>
      <RXEDImageCell />
      <RXEDIRightTitleCell>
        <PaddingBlock />
        <RightFilledHeader>
          <RaisedAnchor id={'CoordinationOfBenefitSegment'} />
          Coordination Of Benefit Segment
          {/* <HotKeyToolTip label={'7'} position={'right'} offsetLeft={1} offsetTop={0} /> */}
        </RightFilledHeader>
      </RXEDIRightTitleCell>
    </RXEDITitleRow>
  )
}

export default CoordinationOfBenefitSegment
