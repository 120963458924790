import isTypeofObject from './isTypeofObject'

const getErrorMessage = (err: unknown): string => {
  if (isTypeofObject(err) && typeof err.message === 'string') {
    return err.message
  }
  return String(err)
}

export default getErrorMessage
