import { useQuery } from '@apollo/client'
import { GET_DUR_MEDICATIONS } from 'gql'
import type { FuzzySearchProps } from 'modals/DurInputMappingsModal/types'
import type { DURSubject } from 'types'
import FuzzySearchAutoComplete from './FuzzySearchRender'

const MedicationsFuzzySearch = (props: FuzzySearchProps) => {
  const { data, error } = useQuery<{ getMedications: DURSubject[] }>(GET_DUR_MEDICATIONS, {
    skip: !props.searchTerm,
    variables: { medications: props.searchTerm },
  })

  return <FuzzySearchAutoComplete {...props} suggestions={data?.getMedications ?? []} error={error} />
}

export default MedicationsFuzzySearch
