import ActionButton from 'components/ActionButton'
import { ActionBar } from 'components/PageStructure'
import SearchInput from 'components/SearchInput'
import styled, { css } from 'styled-components'
import { primaryBackgroundColor, primaryColor } from 'styles/styleVariables'

const InactiveButton = css`
  background-color: ${primaryBackgroundColor};
`

const StyledActionButton = styled(ActionButton)`
  ${InactiveButton};
  :hover {
    border: 2px solid ${primaryColor};
  }
`

const StyledActionBar = styled(ActionBar)`
  padding-left: 0;
  padding-right: 0;
`

const SearchControls = styled.div`
  grid-column: meta;
  grid-row: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

const NewPrescriptionControls = styled.div`
  grid-column: actions;
  grid-row: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`

const Content = styled.div`
  display: flex;
  grid-column: 1 / span 2;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 0rem;
  > button {
    margin-right: 3.3rem;
  }
`

const Title = styled.h1`
  margin-bottom: 0.8rem;
`

const StyledSearchInput = styled(SearchInput)`
  flex: 1;
  max-width: 27rem;
`

export {
  StyledActionButton,
  StyledActionBar,
  SearchControls,
  NewPrescriptionControls,
  Content,
  Title,
  StyledSearchInput,
}
