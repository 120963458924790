import { useState } from 'react'
import { Chip } from '@truepill/react-capsule'
import DropDownOption from 'components/DropDownOption'
import SearchAutoComplete from 'components/SearchAutoComplete'
import { useTPCacheContext } from 'providers/TPCacheProvider'
import { usePlusClient } from 'providers/VisionRouter'
import styled from 'styled-components'
import { borderColor } from 'styles/styleVariables'
import type { Customer } from 'types'

interface CustomerSuggestion extends Customer {
  highlighted?: boolean
  selected?: boolean
}

interface Error {
  error: string
}

interface Loading {
  loading: boolean
}

type CustomerSuggestionItem = CustomerSuggestion | Error | Loading

export type CustomerIdFilterSetting = { [customerId: string]: boolean }

type CustomerSearchProps = {
  isVisible?: boolean
  hotKey?: string
  label?: string
  isModal?: boolean
  nameSpace?: string
  singleSelection?: boolean
  onSelect?: (chosenCustomerId: Customer['_id']) => void
  preventReload?: boolean
  state?: 'default' | 'complete' | 'error'
}

const CustomerSearch = ({
  isVisible,
  hotKey = 'c',
  label = 'Customers',
  isModal,
  singleSelection,
  onSelect,
  preventReload,
  state,
}: CustomerSearchProps): JSX.Element => {
  const { customers, getCustomerNameById } = useTPCacheContext()
  const { routeToMergedQuery, currentLocation } = usePlusClient()
  const [searchTerm, setSearchTerm] = useState('')

  const selectedCustomers = currentLocation?.queryMap?.customerIds || []

  return (
    <>
      <SearchAutoComplete
        withCapsule
        label={label}
        data-testid="customer"
        hotKey={isVisible ? hotKey : ''}
        isModal={isModal}
        iconColor={borderColor}
        placeholder={'Search customer by name'}
        autoCompleteSuggestions={customers.filter(c => c.name.toLowerCase().startsWith(searchTerm.toLowerCase()))}
        value={searchTerm}
        onSelect={(customer: any) => {
          if (!selectedCustomers.includes(customer._id) && !preventReload)
            routeToMergedQuery({ customerIds: [...selectedCustomers, customer._id], page: 0 })

          setSearchTerm('')
          onSelect?.(customer._id)
        }}
        suggestionComponent={optionProps => <AutoCompleteOption {...(optionProps as CustomerSuggestionItem)} />}
        onChange={setSearchTerm}
        state={state}
      />
      {!singleSelection && (
        <SearchResultContainer data-testid="customer-results">
          {selectedCustomers.map((customerId: any) => (
            <Chip
              key={customerId}
              data-testid="result-item"
              state="info"
              size="lg"
              onDismiss={() => {
                if (selectedCustomers.includes(customerId) && !preventReload) {
                  const newCustomers = [...selectedCustomers]
                  newCustomers.splice(selectedCustomers.indexOf(customerId), 1)

                  routeToMergedQuery({ customerIds: newCustomers, page: 0 })
                }
              }}
            >
              <p>{getCustomerNameById(customerId)}</p>
            </Chip>
          ))}
        </SearchResultContainer>
      )}
    </>
  )
}

const AutoCompleteOption = (props: CustomerSuggestionItem): JSX.Element => {
  if ((props as Error).error) {
    return (
      <DropDownOption {...props}>
        <p>{(props as Error).error}</p>
      </DropDownOption>
    )
  }

  if ((props as Loading).loading) {
    return (
      <DropDownOption {...props}>
        <p>Loading...</p>
      </DropDownOption>
    )
  }

  const customer = props as Customer

  return (
    <DropDownOption {...customer}>
      <p>{customer.name}</p>
    </DropDownOption>
  )
}

const SearchResultContainer = styled.div`
  width: 100%;
  margin-top: 0rem;
  overflow: hidden;
`

export default CustomerSearch
