import { useState, useEffect } from 'react'
import { useQuery } from '@truepill/tpos-react-router'
import AutoCompleteOption from 'components/MedicationAutocompleteOption'
import SearchAutoComplete from 'components/SearchAutoComplete'
import { SEARCH_NDC_PARTIAL_INFO, SEARCH_GROUPED_NDC_INFO } from 'gql'
import useStateWithDebouncedValue from 'hooks/useStateWithDebouncedValue'
import { bodySecondaryColor } from 'styles/styleVariables'
import type { MedicationSuggestion, NdcPartialInfo, GroupedNdcInfo } from 'types'
import { getDisplayedDrugName } from 'utils/getDisplayedDrugName'

type NdcPackageSearchOptionsProps = {
  isVisible?: boolean
  hotKey?: string
  onSelect: (medication: MedicationSuggestion) => void
  value?: string
  groupedView?: boolean
  showNdc?: boolean
  label?: string | undefined
  withCapsule?: boolean
  state?: 'default' | 'complete' | 'error'
}

const NdcPackageSearch = ({
  hotKey = 'm',
  isVisible,
  onSelect,
  value,
  groupedView = false,
  showNdc = true,
  withCapsule = false,
  state,
  label,
}: NdcPackageSearchOptionsProps): JSX.Element => {
  const [searchTerm, debouncedSearchTerm, setSearchTerm] = useStateWithDebouncedValue(value || '')
  const [suggestions, setSuggestions] = useState<Array<{ name: string; ndc: string }>>([])

  const variables = {
    [debouncedSearchTerm.length > 2 && isFinite(Number.parseInt(debouncedSearchTerm)) ? 'ndc' : 'name']:
      debouncedSearchTerm,
  }

  const { data, loading, error } = useQuery<{ searchNdcFullInfo: NdcPartialInfo[] }>(SEARCH_NDC_PARTIAL_INFO, {
    skip: groupedView || debouncedSearchTerm.length < 3,
    variables,
  })

  const {
    data: groupedData,
    loading: groupedDataLoading,
    error: groupedDataError,
  } = useQuery<{ searchGroupedNdcInfo: GroupedNdcInfo[] }>(SEARCH_GROUPED_NDC_INFO, {
    skip: !groupedView || debouncedSearchTerm.length < 3,
    variables,
  })

  useEffect(() => {
    const medicationSuggestions = groupedView
      ? groupedData?.searchGroupedNdcInfo?.map((groupedNdcPackage: GroupedNdcInfo) => ({
          name: getDisplayedDrugName(
            groupedNdcPackage.drugName,
            groupedNdcPackage.strength,
            groupedNdcPackage.strengthUnitOfMeasure,
            groupedNdcPackage.dosageForm,
          ),
          ndc: groupedNdcPackage.ndc,
        }))
      : data?.searchNdcFullInfo
          ?.map((ndcInfo: NdcPartialInfo) =>
            ndcInfo.packages.map(pkg => ({
              name: getDisplayedDrugName(
                pkg.drugName,
                ndcInfo.strength,
                ndcInfo.strengthUnitOfMeasure,
                ndcInfo.dosageForm,
              ),
              ndc: pkg.ndc,
            })),
          )
          ?.flat()

    setSuggestions(medicationSuggestions || [])
  }, [data?.searchNdcFullInfo, groupedData?.searchGroupedNdcInfo, groupedView])

  return (
    <>
      <SearchAutoComplete
        label={label}
        data-testid="ndc-package"
        withCapsule={withCapsule}
        hotKey={isVisible ? hotKey : ''}
        iconColor={bodySecondaryColor}
        autoCompleteSuggestions={
          error || groupedDataError
            ? [{ error: `Error getting NdcPackages: ${JSON.stringify(error)}` }]
            : loading || groupedDataLoading
            ? [{ loading: `Searching...` }]
            : suggestions || []
        }
        value={searchTerm}
        preventScrolling={false}
        suggestionComponent={optionProps => <AutoCompleteOption {...optionProps} showNdc={showNdc} />}
        onChange={newValue => setSearchTerm(newValue)}
        placeholder={'Search by name or NDC...'}
        state={state}
        onSelect={chosen => {
          setSearchTerm(chosen.name)
          if (!chosen) {
            return
          }

          onSelect(chosen)
        }}
      />
    </>
  )
}

export default NdcPackageSearch
