import { useEffect } from 'react'
import styled from 'styled-components'
import { primaryBackgroundColor, primaryColor } from 'styles/styleVariables'
import ActionButton from './ActionButton'
import { StyledRXImageCell } from './RXTable'

type NoEscriptInfoProps = { setNoEscript: () => void }

const NoEscriptInfo = ({ setNoEscript }: NoEscriptInfoProps): JSX.Element => {
  const calcBoxHeight = () => {
    const boxHeight1 = document.getElementById('ReviewPatientBox')?.clientHeight ?? 0
    const boxHeight2 = document.getElementById('ReviewPrescriberBox')?.clientHeight ?? 0
    const boxHeight3 = document.getElementById('ReviewPrescriptionBox')?.clientHeight ?? 0
    const noEscriptInfoBox = document.getElementById('NoEscriptBox')
    if (noEscriptInfoBox) {
      const newHeight = boxHeight1 + boxHeight2 + boxHeight3 - 50
      noEscriptInfoBox.style.height = newHeight + 'px'
    }
  }

  // Because the height could be variable, we make a calc of the existing components to set a considerable height
  useEffect(() => {
    calcBoxHeight()
    window.onresize = calcBoxHeight
  }, [])

  return (
    <StyledRXImageCell noColorBackground closeOffTopBorder>
      <NoEscriptInfoBox id="NoEscriptBox">
        <NoEscriptMessage>
          No escript or direct transfer - view Rx image for prescription details
          <StyledActionButton label="Switch to Rx image" onClick={setNoEscript} />
        </NoEscriptMessage>
      </NoEscriptInfoBox>
    </StyledRXImageCell>
  )
}

export default NoEscriptInfo

const NoEscriptInfoBox = styled.div`
  background-color: ${primaryBackgroundColor};
  position: absolute;
  height: 75vh;
  width: 30.6vw;
  z-index: 1;
  margin-left: 1px;
  margin-right: 1px;
  display: flex;
  align-items: flex-start;
`
const StyledActionButton = styled(ActionButton)`
  margin-top: 3rem;
  margin-left: 0rem;
  background-color: ${primaryColor};
  color: ${primaryBackgroundColor};
`
const NoEscriptMessage = styled.div`
  margin: 0.625rem;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
`
