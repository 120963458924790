import type { Address } from '../address'
import type { EntityId } from '../shared'

export enum TransferType {
  TRANSFER_OUT = 'out',
  TRANSFER_IN = 'in',
  TRANSFER_INTERNAL = 'internal',
}

export enum TransferDestination {
  INTERNAL = 'internal',
  PIONEER = 'pioneer',
  EXTERNAL = 'external',
}
export interface ExternalPharmacy {
  name: string
  address?: Address
  phone: string
  npi: string
  fax?: string
  dea?: string
}

export interface FillTransfer {
  pharmacistInChargeId: EntityId
  receivingPharmacistId?: EntityId
  toLocationId?: EntityId
  fromLocationId: EntityId
  transferredAt: Date
  transferredTo: TransferDestination
  transferredByUserId: EntityId
  quantityTransferred?: number // Added to reflect the transferred quantity instead of using data from the rx to calculate it
  imageId?: EntityId
  comments?: string
  receivingPharmacistName?: string
  transferringPharmacist?: string
  pharmacyNpi?: string
  externalPharmacy?: ExternalPharmacy
}

export interface ReceivingPharmacy {
  pharmacy: string
  address: Address
  phone: string
}

export interface TransferInput {
  pharmacyNpi: string
  pharmacistName?: string
  transferringPharmacistName?: string
  fax?: string
  comments?: string
  quantity?: number
  receivingPharmacy?: ReceivingPharmacy
}

export interface TransferOptions {
  transferFromPrescriptionLocation?: boolean
  skipPV1?: boolean
}
