import CloseButton from 'components/CloseButton'
import { SearchSelectedCard } from 'components/ColumnForm'
import { useTPCacheContext } from 'providers/TPCacheProvider'
import styled from 'styled-components'
import type { Customer } from 'types'

type CustomerCardProps = { customerId: Customer['_id']; closeCallback: () => void; label?: string }

const CustomerCard = ({ customerId, closeCallback, label }: CustomerCardProps): JSX.Element => {
  const { customersById } = useTPCacheContext()

  if (customerId === '') {
    return <></>
  }

  const customer = customersById[customerId]

  return (
    <>
      {!!label && <StyleLabel>{label}</StyleLabel>}
      <StyledSearchSelectedCard data-testid="customer-selected">
        <strong>{customer?.name}</strong>
        <CloseButton size="0.625rem" clickCallback={closeCallback} />
      </StyledSearchSelectedCard>
    </>
  )
}

export default CustomerCard

const StyleLabel = styled.label`
  font-weight: 700;
  margin-top: 1rem;
  display: block;
  line-height: 1.375;
  font-family: 'Lato', sans-serif;
`

const StyledSearchSelectedCard = styled(SearchSelectedCard)`
  margin-bottom: 0;
  margin-top: 0.425rem;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.625rem 0.8rem;
`
