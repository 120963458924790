import { useLocation } from '@truepill/tpos-react-router'
import ItemIdDisplay from 'components/ItemIdDisplay'
import { LinkWrapper, OrderGroup as CopayRequestGroup, OrderTitleBar, StyledLink } from 'components/Order'
import { PatientName, StyledFill, RXNumber, DateStamp, Drug, Statuses, UserBadges } from 'components/Order/Rows'
import Lozenge, { getCopayStatusColor } from 'components/Tiles/Lozenge'
import useFulfillmentQueue from 'hooks/useFulfillmentQueue'
import { useTPCacheContext } from 'providers/TPCacheProvider'
import { goToFulfillmentCopayRequestFill, goToFulfillmentCopay } from 'routes'
import { alertRed, contrastColor, primaryBackgroundColor, accentPink } from 'styles/styleVariables'
import { FillWorkStatus } from 'types'
import type { CopayRequest } from 'types'
import { formatCreatedDate } from 'utils'
import UserInitialsBadge from '../UserInitialsBadge'

interface CopayRequestProps {
  copayRequest: CopayRequest
  selectionLive?: boolean
  noIndividualFillSelection?: boolean
  disabled?: boolean
  disabledCheckbox?: boolean
  filterOnQueue?: boolean
  inline?: boolean
  hideCopayRequestDetails?: boolean
  onClick?: () => void
}

const CopayRequestEntry = (props: CopayRequestProps): JSX.Element => {
  const { getLocationNameById, getCustomerNameById } = useTPCacheContext()

  const { copayRequest, inline, disabled = false, hideCopayRequestDetails = false, onClick } = props
  const { search } = useLocation()
  const fulfillmentQueue = useFulfillmentQueue()
  const { copayRequestFills } = copayRequest
  const isBatchCopayRequest = copayRequestFills.length > 1

  const { date: createdAtDate, greaterThan48 } = formatCreatedDate(copayRequest.createdAt)

  return (
    <CopayRequestGroup
      id={`CopayRequest-${copayRequest._id}`}
      inline={inline}
      isMultiOrder={isBatchCopayRequest}
      data-testid="CopayRequest"
      data-order-id={copayRequest._id}
    >
      {isBatchCopayRequest && !hideCopayRequestDetails && (
        <OrderTitleBar
          data-testid="copay-titlebar"
          to={goToFulfillmentCopay({
            fulfillmentQueueName: fulfillmentQueue?.name ?? 'all',
            copayRequestId: copayRequest._id,
            search,
          })}
        >
          <p>
            Copay Request: <ItemIdDisplay id={copayRequest._id} customerRef={copayRequest.customerRef} />
          </p>
        </OrderTitleBar>
      )}
      {copayRequestFills.map(({ _id, prescription, fillId, fill, status }) => (
        <LinkWrapper disabled={false} key={`${copayRequest._id} ${fillId}`}>
          <StyledLink
            onClick={onClick}
            to={
              fill
                ? goToFulfillmentCopayRequestFill({
                    fulfillmentQueueName: fulfillmentQueue?.name,
                    copayRequestId: copayRequest._id,
                    fillId: fill._id,
                    search,
                  })
                : goToFulfillmentCopay({
                    fulfillmentQueueName: fulfillmentQueue?.name ?? 'all',
                    copayRequestId: copayRequest._id,
                    search,
                  })
            }
          >
            <StyledFill
              id={`Fill-${fillId}`}
              inline={true}
              isMultiOrder={isBatchCopayRequest}
              {...fill}
              workStatus={FillWorkStatus.normal}
              data-fill-exists={fill ? true : false}
              data-fill-id={fillId}
              data-copayRequest-id={copayRequest._id}
              data-rx-number={prescription?.rxNumber || 'undefined'}
              data-testid="Fill"
            >
              <RXNumber data-testid="rx-number" fade={!!copayRequest.lock || disabled}>
                Rx: {prescription?.rxNumber}
              </RXNumber>
              <DateStamp data-testid="date-created">
                {createdAtDate} {greaterThan48}
              </DateStamp>
              <PatientName data-testid="patient-name" fade={!!copayRequest.lock || disabled} data-private>
                {copayRequest.patient ? (
                  <>
                    {copayRequest.patient?.firstName} {copayRequest.patient?.lastName}
                  </>
                ) : (
                  <Lozenge data-testid="patient-missing" backgroundColor={alertRed} color={primaryBackgroundColor}>
                    This fill request is missing a patient!
                  </Lozenge>
                )}
              </PatientName>
              <Drug data-testid="medication" fade={!!copayRequest.lock || disabled}>
                {fill?.dispensed.name}
              </Drug>
              <Statuses>
                <Lozenge data-testid="status" backgroundColor={getCopayStatusColor(status)}>
                  {status}
                </Lozenge>
                <Lozenge data-testid="customer" backgroundColor={contrastColor}>
                  {getCustomerNameById(copayRequest.customerId)}
                </Lozenge>
                <Lozenge data-testid="location" backgroundColor={accentPink}>
                  {getLocationNameById(copayRequest.locationId)}
                </Lozenge>
                {!!copayRequest.lock && (
                  <UserBadges>
                    <UserInitialsBadge user={copayRequest.lock.user} />
                  </UserBadges>
                )}
              </Statuses>
            </StyledFill>
          </StyledLink>
        </LinkWrapper>
      ))}
    </CopayRequestGroup>
  )
}

export default CopayRequestEntry
