import { useState, useCallback, useMemo, useRef, useEffect } from 'react'
import { useMutation } from '@truepill/tpos-react-router'
import { DocumentTag } from '@truepill/tpos-types'
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg'
import { SaveButton } from 'components/ActionButton'
import { FormItem } from 'components/ColumnForm'
import IconWrapper from 'components/IconWrapper'
import LoadingSpinner from 'components/Loading'
import { ModalWrapper, ModalHeader, ButtonsContainer } from 'components/Modal'
import { CancelButton } from 'components/PageStructure'
import { StyledSelect } from 'components/RXTable'
import type { UploadState } from 'components/UploadTarget'
import UploadTarget from 'components/UploadTarget'
import { ADD_ATTACHMENT_TO_RX_IMAGE } from 'gql'
import { TextArea } from 'grommet'
import useErrorToast from 'hooks/toast/useErrorToast'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import styled from 'styled-components'
import { bodyPrimaryColor, borderColor } from 'styles/styleVariables'
import { humanReadableFileSize } from 'utils'
import { useCreateDocument } from './useCreateDocument'

type ImageToUpload = {
  name: string
  size: string
  dataURL: string
  uploaded: boolean
}

const useImageToUpload = () => {
  const [fileToUpload, setFileToUpload] = useState<File | null>(null)
  const [imagePreview, setImagePreview] = useState<ImageToUpload | null>(null)

  const readerRef = useRef<FileReader | null>(null)

  useEffect(() => {
    if (!fileToUpload) {
      setImagePreview(null)
      return
    }

    const { name, size } = fileToUpload

    readerRef.current = new FileReader()

    if (!readerRef.current) {
      return
    }

    readerRef.current.onload = function (e) {
      setImagePreview({
        name: name,
        size: humanReadableFileSize(size),
        dataURL: e.target?.result as string,
        uploaded: true,
      })
    }

    readerRef.current.readAsDataURL(fileToUpload)
  }, [fileToUpload])

  return { fileToUpload, imagePreview, setFileToUpload }
}

type AttachmentModalProps = {
  title?: string
  orderId?: string
  fillId?: string
  prescriptionId?: string
  patientId?: string
}

const AddAttachmentModal = ({
  title = 'Add attachment',
  orderId,
  fillId,
  prescriptionId,
  patientId,
}: AttachmentModalProps): JSX.Element => {
  const { dismissModal } = useModalContext()
  const showErrorToast = useErrorToast()

  const {
    createDocument,
    status: { loading: isLoading, error, success },
  } = useCreateDocument(orderId, fillId, prescriptionId, patientId)

  const [addAttachmentToRxImage] = useMutation(ADD_ATTACHMENT_TO_RX_IMAGE, {
    onCompleted() {
      window.location.reload()
    },
    onError(err) {
      showErrorToast(`Error adding Rx Image attachment: ${err.message}`)
    },
  })

  const [tag, setTag] = useState('')
  const [caption, setCaption] = useState('')
  const { fileToUpload, imagePreview, setFileToUpload } = useImageToUpload()

  const uploadState: UploadState[] = useMemo(() => (imagePreview ? [imagePreview] : []), [imagePreview])

  const onCaptionChange = useCallback(({ target: { value } }) => setCaption(value), [])

  const formIsValid = tag !== '' && caption !== '' && fileToUpload !== null

  useEffect(() => {
    if (success) {
      dismissModal()
    }
  }, [success])

  return (
    <ModalWrapper id="AddAttachmentModal">
      <ModalHeader>
        <IconWrapper>
          <PlusIcon fill={bodyPrimaryColor} />
        </IconWrapper>
        <h2>{title}</h2>
      </ModalHeader>
      <StyledUploadTarget
        uploaded={uploadState}
        onFilesDropped={(files: File[]) => {
          const [fileToUpload] = files
          if (!fileToUpload) {
            setFileToUpload(null)
            return
          }

          setFileToUpload(fileToUpload)
        }}
        onUploadCancelled={() => setFileToUpload(null)}
      />
      <FormStyledItem>
        <p>Caption</p>
        <TextArea value={caption} placeholder={'Enter a caption...'} resize={'vertical'} onChange={onCaptionChange} />
      </FormStyledItem>
      <FormStyledItem>
        <p>Tags</p>
        <TagSelect
          multiple={false}
          modal={true}
          value={tag ? tag : undefined}
          placeholder={'Select tags for image...'}
          options={Object.keys(DocumentTag)}
          onChange={changes => {
            const [tagChange] = changes
            if (!tagChange) {
              setTag('')
              return
            }
            const { value } = tagChange
            setTag(value)
          }}
        />
      </FormStyledItem>
      {isLoading && (
        <LoadingSpinnerContainer>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      )}
      {error && <ErrorMessage>error</ErrorMessage>}
      <ButtonsContainer>
        <CancelButton label={'Cancel'} onClick={dismissModal} />
        <SaveButton
          disabled={!formIsValid}
          isModal
          label={'Add'}
          onClick={async () => {
            if (!fileToUpload) {
              return
            }

            if (formIsValid) {
              const { data } = await createDocument(fileToUpload, caption, tag)
              if (data?.createDocument && tag === 'Rx Image') {
                await addAttachmentToRxImage({
                  variables: {
                    prescriptionId,
                    newDocumentId: data.createDocument._id,
                  },
                })
              }
            }
          }}
        />
      </ButtonsContainer>
    </ModalWrapper>
  )
}

const LoadingSpinnerContainer = styled.div`
  display: flex;
  padding-top: 0.8rem;
  justify-content: center;
  svg {
    height: 60px;
  }
`

const ErrorMessage = styled.p`
  color: red;
`

const StyledUploadTarget = styled(UploadTarget)`
  margin: 1.5rem 0;
`

const FormStyledItem = styled(FormItem)`
  > textarea {
    height: 38px;
    padding: 0.5rem;
  }
`

const TagSelect = styled(StyledSelect)`
  height: 2.5rem;
  align-self: stretch;
  font-weight: none;
  color: ${borderColor};
`

export default AddAttachmentModal
