import { useState, useEffect } from 'react'
import type { ApolloError } from '@truepill/tpos-react-router'
import Pagination from 'components/Pagination'
import type { PaginatedLogs, UserLog } from 'hooks/useUserLogs'
import moment from 'moment'
import type { FlattenSimpleInterpolation } from 'styled-components'
import styled from 'styled-components'
import type { TableDefinition } from '../../Table'
import Table from '../../Table'

interface UserActivityProps {
  paginatedLogs?: PaginatedLogs
  fetchMoreLogs: CallableFunction
  loading: boolean
  error?: ApolloError
}

interface PaginationInfo {
  logsToShow: UserLog[]
  currentPage: number
  total: number
  totalPages: number
  pageSize: number
  setPage: (pageNumber: number) => void
  setLimit: (limit: number) => void
}

interface ParsedUserActivity {
  logId: string
  logEvent: string
  message: string
  createdDate: string
  prescriptionInfo: string | null
  fillInfo: string | null
  patientInfo: string | null
  orderInfo: string | null
  change?: { field: string; oldValue: string; newValue: string }
}

export const UserActivity = ({ paginatedLogs, fetchMoreLogs, loading }: UserActivityProps): JSX.Element => {
  const { logsToShow, currentPage, setPage, total, totalPages, pageSize, setLimit } = useLogsPaginationInfo(
    fetchMoreLogs,
    paginatedLogs,
  )

  return (
    <UserColumn>
      <Table
        data={logsToShow.map(log => parseUserActivity(log))}
        keyField="logId"
        loading={loading}
        definition={definition}
      />
      <Pagination
        totalPages={totalPages}
        currentPage={currentPage}
        onChangePage={setPage}
        totalRecords={total}
        rowsPerPage={pageSize}
        onChangeRowsPerPage={setLimit}
      />
    </UserColumn>
  )
}

const parseUserActivity = (log: UserLog): ParsedUserActivity => {
  const { _id: logId, message, event, change, createdAt, isAnnotation, prescription, fill, patient, order } = log

  const createdDate = parseDate(createdAt)
  const logEvent = isAnnotation ? 'Annotation' : event

  const prescriptionInfo = prescription ? `Rx: ${prescription.rxNumber}` : null
  const fillInfo = fill ? `Fill: ${fill.rxFillCode}` : null
  const patientInfo = patient ? `Patient: ${patient.firstName} - ${patient.lastName}` : null
  const orderInfo = order ? `Order: ${order.coreOrderId}` : null

  return {
    logId,
    logEvent,
    message,
    change,
    createdDate,
    prescriptionInfo,
    fillInfo,
    patientInfo,
    orderInfo,
  }
}

const useLogsPaginationInfo = (fetchMoreLogs: CallableFunction, paginatedLogs?: PaginatedLogs): PaginationInfo => {
  const { total, logs } = paginatedLogs ?? { logs: [], total: 0 }
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(10)

  useEffect(() => {
    const newOffset = page * limit
    fetchMoreLogs(newOffset, limit)
  }, [fetchMoreLogs, page, limit])

  const totalPages = Math.ceil(total / limit)

  return {
    logsToShow: logs,
    currentPage: page,
    setPage,
    total,
    totalPages,
    pageSize: limit,
    setLimit,
  }
}

const definition: TableDefinition<ParsedUserActivity>[] = [
  { field: 'logEvent', headerName: '', width: '1fr', minWidth: '8rem' },
  {
    headerName: 'Actions',
    width: '5fr',
    minWidth: '25rem',
    customRender: ({ message, createdDate, change }) => {
      const { field, oldValue, newValue } = change ?? {}
      const changeFound = field && oldValue && newValue
      const text = `${message && message} ${
        changeFound && `Field: ${field}, Old value: ${oldValue} - New Value: ${newValue}`
      }`
      return (
        <div data-private>
          <p>{text}</p>
          <ActivityDate>Created: {createdDate}</ActivityDate>
        </div>
      )
    },
  },
  {
    headerName: 'Rx/Fill/Order',
    width: '1fr',
    minWidth: '15rem',
    customRender: ({ fillInfo, orderInfo, prescriptionInfo, patientInfo }) => {
      return (
        <div>
          {prescriptionInfo && <p>{prescriptionInfo}</p>}
          {fillInfo && <p>{fillInfo}</p>}
          {patientInfo && <p>{patientInfo}</p>}
          {orderInfo && <p>{orderInfo}</p>}
        </div>
      )
    },
  },
]

const parseDate = (date: string) => {
  try {
    const numericDate = parseInt(date)
    return moment(numericDate).format('MM/DD/YYYY hh:mmA')
  } catch (e) {
    return ''
  }
}

const UserColumn = styled.ul<{
  styles?: FlattenSimpleInterpolation
}>`
  :not(:last-child) {
    margin-right: 1.25rem;
  }
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  ${({ styles }) => styles};
`

const ActivityDate = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #999999;
`
