import { useQuery } from '@truepill/tpos-react-router'
import type { Language } from '@truepill/tpos-types'
import { DEFAULT_LANGUAGE } from '@truepill/tpos-types'
import LoadingSpinner from 'components/Loading'
import { GET_MONOGRAPH } from 'gql'
import parse, { domToReact } from 'html-react-parser'
import styled from 'styled-components'
import PrintMonographForm from './PrintMonographForm'

type MonographInfoProps = { ndc: string; className?: string }

const MonographInfo = ({ className, ndc }: MonographInfoProps): JSX.Element => {
  const { data, loading, error } = useQuery(GET_MONOGRAPH, {
    variables: { ndc },
  })

  if (error) {
    return (
      <MonographContainer data-testid="MonographInfo" data-test-status="error" className={className}>
        <p>Error loading monograph for this ndc {JSON.stringify(error)}</p>
      </MonographContainer>
    )
  }

  if (loading) {
    return (
      <MonographContainer data-testid="MonographInfo" data-test-status="loading" className={className}>
        <LoadingSpinnerContainer>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      </MonographContainer>
    )
  }

  const monographHtml: string = data?.getMonograph?.eng

  const parseOptions = {
    replace: (domNode: any) => {
      if (domNode.name === 'html' || domNode.name === 'head' || domNode.name === 'meta')
        return <>{domToReact(domNode.children, parseOptions)}</>

      if (domNode.name === 'style' || domNode.name === 'br') return <></>

      if (domNode.name === 'body') {
        // First 2 elements are a note text we don't want to display
        domNode.children.shift()
        domNode.children.shift()
        return <>{domToReact(domNode.children, parseOptions)}</>
      }

      if (domNode.name === 'div' && domNode.attribs.class === 'bigFont')
        return <MedicationName>{domToReact(domNode.children, parseOptions)}</MedicationName>

      if (domNode.name === 'div') return <TextContainer>{domToReact(domNode.children, parseOptions)}</TextContainer>

      if (domNode.name === 'p') return <SectionQuestion>{domToReact(domNode.children, parseOptions)}</SectionQuestion>

      if (domNode.name === 'b') return <ImportantText>{domToReact(domNode.children, parseOptions)}</ImportantText>

      if (domNode.name === 'li') return <InfoListItem>{domToReact(domNode.children, parseOptions)}</InfoListItem>

      if (domNode.name === 'blockquote')
        return <ListParagraph>{domToReact(domNode.children, parseOptions)}</ListParagraph>

      return domNode
    },
  }

  if (!monographHtml)
    return (
      <MonographContainer data-testid="MonographInfo" data-test-status="monograph-empty" className={className}>
        There is no monograph for this drug
      </MonographContainer>
    )

  const languages = data?.getMonograph?.spa ? [DEFAULT_LANGUAGE, 'es' as Language] : [DEFAULT_LANGUAGE]

  return (
    <Container>
      <MonographContainer data-testid="MonographInfo" data-test-status="monograph-loaded" className={className}>
        {parse(monographHtml, parseOptions)}
      </MonographContainer>
      <PrintMonographContainer>
        <PrintMonographForm ndc={ndc} languagesAvailable={languages} />
      </PrintMonographContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 3rem;
`
const PrintMonographContainer = styled.div`
  flex-basis: 19rem;
  flex-shrink: 0;
`

const MonographContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const LoadingSpinnerContainer = styled.div`
  display: flex;
  padding-top: 4rem;
  justify-content: center;
  svg {
    height: 50px;
  }
`

const MedicationName = styled.p`
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
`

const SectionQuestion = styled.p`
  margin-top: 25px;
  font-size: 14px;
`
const ImportantText = styled.span`
  font-family: 'Roboto';
  font-weight: 600;
`

const InfoListItem = styled.li`
  list-style: disc;
  margin-bottom: 8px;
  margin-left: 25px;
  font-size: 16px;
`

const ListParagraph = styled.p`
  margin-bottom: 8px;
  margin-left: 25px;
  font-size: 16px;
`

const TextContainer = styled.div`
  margin-bottom: 5px;
`

export default MonographInfo
