import { useQuery } from '@truepill/tpos-react-router'
import LoadingSpinner from 'components/Loading'
import {
  RXImageCell,
  RXCenterCell,
  RXListRow,
  ListRowLabel,
  ListRowValue,
  RXListRowTwoColumns,
} from 'components/RXTable'
import { GET_DRUG_IMPRINT } from 'gql'
import styled from 'styled-components'

interface DrugDescriptor {
  form?: string
  front?: string
  back?: string
  coating?: string[]
  clarity?: string[]
  color?: string[]
  flavor?: string[]
  score?: string[]
  shape?: string[]
}

interface DrugImageImprintType {
  ndcPackage: string
  manufacturer: string
  image?: string
  descriptors: DrugDescriptor
}

type DrugImageImprintProps = {
  ndc: string
  closeOffBottomBorder?: boolean
  hasTwoColumns?: boolean
  hideImageColumn?: boolean
}

const DrugImageImprint = ({
  ndc,
  closeOffBottomBorder,
  hasTwoColumns,
  hideImageColumn,
}: DrugImageImprintProps): JSX.Element => {
  const { data, loading, error } = useQuery(GET_DRUG_IMPRINT, {
    variables: { ndcPackageCode: ndc },
  })

  if (loading) {
    return hasTwoColumns ? (
      <RXListRowTwoColumns hideImageColumn={hideImageColumn}>
        <RXImageCell />
        <RXCenterCell>
          <ListRowLabel></ListRowLabel>
          <StyledLoadingSpinnerContainer>
            <LoadingSpinner />
          </StyledLoadingSpinnerContainer>
        </RXCenterCell>
      </RXListRowTwoColumns>
    ) : (
      <RXListRow>
        <RXImageCell closeOffBottomBorder />
        <RXCenterCell>
          <ListRowLabel></ListRowLabel>
          <StyledLoadingSpinnerContainer>
            <LoadingSpinner />
          </StyledLoadingSpinnerContainer>
        </RXCenterCell>
      </RXListRow>
    )
  }

  if (error) {
    return hasTwoColumns ? (
      <RXListRowTwoColumns hideImageColumn={hideImageColumn}>
        <RXImageCell>
          <ListRowLabel>ERROR:</ListRowLabel>
        </RXImageCell>
        <RXCenterCell>
          <ListRowValue>Failed to load Drug Data {JSON.stringify(error)}</ListRowValue>
        </RXCenterCell>
      </RXListRowTwoColumns>
    ) : (
      <RXListRow>
        <RXImageCell>ERROR:</RXImageCell>
        <RXCenterCell>Failed to load Drug Data {JSON.stringify(error)}</RXCenterCell>
      </RXListRow>
    )
  }

  const drugImageImprint: DrugImageImprintType = data.getDrugImageImprint

  const imageContent = (
    <>
      <RXImageCell />
      <RXCenterCell>
        <ListRowLabel>Image</ListRowLabel>
        <ImageWraper>
          {drugImageImprint?.image ? (
            <img alt={'drug'} src={`data:image/jpeg;base64, ${drugImageImprint.image}`} />
          ) : (
            'No image available'
          )}
        </ImageWraper>
      </RXCenterCell>
    </>
  )

  const descriptionContent = (
    <>
      <RXImageCell closeOffBottomBorder={closeOffBottomBorder} />
      <RXCenterCell>
        <ListRowLabel>Description</ListRowLabel>
        {drugImageImprint ? (
          <ListRowValue>{formatDescriptorText(drugImageImprint.descriptors)}</ListRowValue>
        ) : (
          <ListRowValue>No drug description found</ListRowValue>
        )}
      </RXCenterCell>
    </>
  )

  return hasTwoColumns ? (
    <>
      <RXListRowTwoColumns hideImageColumn={hideImageColumn}>{imageContent}</RXListRowTwoColumns>
      <RXListRowTwoColumns hideImageColumn={hideImageColumn}>{descriptionContent}</RXListRowTwoColumns>
    </>
  ) : (
    <>
      <RXListRow>{imageContent}</RXListRow>
      <RXListRow>{descriptionContent}</RXListRow>
    </>
  )
}

const formatDescriptorText = ({ form, front, back, coating, clarity, color, flavor, score, shape }: DrugDescriptor) => {
  const descriptors = [
    listToString(clarity),
    listToString(color),
    listToString(shape, '', '-shaped'),
    listToString(score),
    listToString(coating),
    listToString(flavor, '', '-flavored'),
  ]
    .filter(Boolean)
    .join(', ')
  return `A${descriptorToString(descriptors, ' ')}${descriptorToString(form, ' ')}${descriptorToString(
    front || back,
    ' imprinted with ',
    ' on one side',
  )}${descriptorToString(front && back, ' and ', ' on the other side')}.`
}

const ImageWraper = styled.div`
  flex: 1;
  > img {
    object-fit: scale-down;
    max-width: 100%;
    border-radius: 4px;
  }
`

const listToString = (descriptor?: string[], prefix = '', suffix = '') => {
  if (descriptor && descriptor.length > 0) {
    return `${prefix}${descriptor.join(', ')}${suffix}`
  }
  return ''
}

const descriptorToString = (descriptor?: string, prefix = '', suffix = '') => {
  if (descriptor) {
    return `${prefix}${descriptor}${suffix}`
  }
  return ''
}

const StyledLoadingSpinnerContainer = styled.div`
  display: flex;
  flex-basis: 100%;
  padding-top: 0.8rem;
  height: 200px;
  justify-content: left;
  position: relative;
  margin-left: 8rem;
  align-items: center;
  svg {
    height: 2rem;
  }
`

export default DrugImageImprint
