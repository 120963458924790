import { ReactComponent as RouteIcon } from 'assets/icons/route.svg'
import ActionButton from 'components/ActionButton'
import IconWrapper from 'components/IconWrapper'
import { bodyPrimaryColor } from 'styles/styleVariables'
import type { CopayRequest } from 'types'
import useRerouteCopay from './useRerouteCopay'

type RerouteButtonProps = { copayRequest: CopayRequest; disabled?: boolean }

const RerouteButton = ({ copayRequest, disabled }: RerouteButtonProps): JSX.Element => {
  const rerouteCopay = useRerouteCopay(copayRequest)

  return (
    <ActionButton
      data-testid="Reroute"
      icon={
        <IconWrapper>
          <RouteIcon fill={bodyPrimaryColor} />
        </IconWrapper>
      }
      onClick={rerouteCopay}
      label="Reroute"
      hotKey="ar"
      hotKeyLabel="a-r"
      disabled={disabled}
    />
  )
}
export default RerouteButton
