import { useHistory, useLocation, useQuery } from '@truepill/tpos-react-router'
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg'
import BreadCrumb from 'components/BreadCrumb'
import IconWrapper from 'components/IconWrapper'
import { ActionBar, PageContainer } from 'components/PageStructure'
import Paging from 'components/Paging'
import PayersTable from 'components/PayersTable'
import { SEARCH_INSURANCE_PROVIDER_WITH_PAGINATION } from 'gql'
import useSetPageTitle from 'hooks/useSetPageTitle'
import useStateWithDebouncedValue from 'hooks/useStateWithDebouncedValue'
import { usePlusClient } from 'providers/VisionRouter'
import { goToCreatePayer } from 'routes'
import { borderColor } from 'styles/styleVariables'
import {
  Title,
  SearchControls,
  StyledSearchInput,
  NewPayerControls,
  StyledActionButton,
  Content,
} from './StyledComponents'

const PayersPage = (): JSX.Element => {
  const history = useHistory()
  const { search } = useLocation()
  const pageSize = 10
  const [searchTerm, debouncedSearchTerm, setSearchTerm] = useStateWithDebouncedValue('')
  const cleanedSearchTerm = searchTerm.trimStart().trimEnd().replace(/[,.]/, '')

  const { currentLocation, routeToMergedQuery } = usePlusClient()

  const { data, error, loading } = useQuery(SEARCH_INSURANCE_PROVIDER_WITH_PAGINATION, {
    variables: {
      input: cleanedSearchTerm,
      pageSize: pageSize,
      pageNumber: Number(currentLocation.queryMap?.page) || 0,
    },
  })

  const payersList = data ? data.searchInsuranceProviderPaginated.paginatedResult : []
  const totalRecords = data ? data.searchInsuranceProviderPaginated.totalRecords : 0
  const currentPage = data ? data.searchInsuranceProviderPaginated.currentPage : 0
  const totalPages = Math.ceil(totalRecords / pageSize)

  useSetPageTitle('Payers')

  const Pager = (): JSX.Element => {
    if (totalRecords > pageSize) {
      return (
        <Paging
          totalPages={totalPages}
          currentPage={currentPage}
          setPage={pageNumber => {
            routeToMergedQuery({ page: pageNumber })
          }}
          totalRecords={totalRecords}
          pageSize={pageSize}
        />
      )
    } else {
      return <div></div>
    }
  }

  return (
    <PageContainer>
      <BreadCrumb />
      <Title>Payers</Title>
      <ActionBar>
        <SearchControls>
          <StyledSearchInput
            data-private
            iconColor={borderColor}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            placeholder="Search payers by BIN, PCN, or name..."
            value={searchTerm}
            onChange={({ currentTarget: { value } }) => setSearchTerm(value)}
            width="15rem"
          />
        </SearchControls>
        <NewPayerControls>
          <StyledActionButton
            icon={
              <IconWrapper>
                <PlusIcon fill="black" />
              </IconWrapper>
            }
            hotKey="n"
            onClick={() => history.push(goToCreatePayer({ search }))}
            label="New payer"
          />
        </NewPayerControls>
      </ActionBar>
      <Content>
        {error ? (
          <p>Failed to load payers list {JSON.stringify(error)}</p>
        ) : loading || !data ? (
          <PayersTable loading={loading} payers={[]} />
        ) : (
          <PayersTable searchTermGiven={debouncedSearchTerm !== ''} payers={payersList} />
        )}
        <Pager />
      </Content>
    </PageContainer>
  )
}

export default PayersPage
