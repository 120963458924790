import { FormItem, Select, WideTextInput } from 'components/ColumnForm'
import styled from 'styled-components'

const StateSelect = styled(Select)`
  width: 6rem;
`

const CityFormItem = styled(FormItem)`
  width: 100%;
`

const DiscountContainer = styled.div`
  display: flex;
`

const FormItemDiscount = styled(FormItem)`
  display: flex;
`

const PhoneInput = styled(WideTextInput)`
  width: 10rem;
`

const PayerOptionsContainer = styled.div`
  display: flex;
`

const PayerOptionsInnerContainer = styled.div`
  width: 50%;
  :not(:last-child) {
    padding-right: 20px;
  }
`

const InputWithLabel = styled.div`
  display: flex;
  padding-right: 15px;
`

export {
  StateSelect,
  CityFormItem,
  DiscountContainer,
  FormItemDiscount,
  PhoneInput,
  PayerOptionsContainer,
  PayerOptionsInnerContainer,
  InputWithLabel,
}
