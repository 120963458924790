import { useContext } from 'react'
import { Button } from '@truepill/react-capsule'
import { ModalHeader, ModalWrapper } from 'components/Modal'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import styled from 'styled-components'
import type { ReviewDuplicateRxCtx } from './ReviewDuplicateRxContext'
import ReviewDuplicateRxContextProvider, { ReviewDuplicateRxContext } from './ReviewDuplicateRxContext'
import DuplicatePrescriptionsTable from './Table/DuplicatePrescriptionsTable'
import useFetchDuplicatePrescriptions from './Table/useFetchDuplicatePrescriptions'
import type { ChosenDuplicateReason } from './types'
import useDiscontinuePrescriptions from './useDiscontinuePrescriptions'
import useResolveWithoutChanges from './useResolveWithoutChanges'

interface ReviewDuplicatePrescriptionModalProps {
  ids: string[]
  mainPrescriptionId: string
  orderId: string
  hideResolveWithoutChangesButton?: boolean
}

interface ReviewDuplicatePrescriptionModalUIProps
  extends Omit<ReviewDuplicatePrescriptionModalProps, 'orderId' | 'ids'> {
  handleResolveWithoutChanges: () => void
  handleDiscontinue: (selectedDuplicatePrescriptions: ChosenDuplicateReason[]) => void
  isResolveDuplicateTriageLoading: boolean
  isDiscontinuePrescriptionsLoading: boolean
  isPrescriptionLoading: boolean
  prescriptions: Parameters<typeof DuplicatePrescriptionsTable>[0]['prescriptions']
}

const ReviewDuplicatePrescriptionModal = (props: ReviewDuplicatePrescriptionModalProps) => {
  const { dismissModal } = useModalContext()

  const { prescriptions, loading: rxsFetchLoading } = useFetchDuplicatePrescriptions([
    props.mainPrescriptionId,
    ...props.ids,
  ])

  const { resolveDuplicateTriage, isResolveDuplicateTriageLoading } = useResolveWithoutChanges({
    onCompleted: dismissModal,
    onError: dismissModal,
  })

  const { discontinuePrescriptions, isDiscontinuePrescriptionsLoading } = useDiscontinuePrescriptions({
    onCompleted: dismissModal,
    onError: dismissModal,
  })

  const handleResolveWithoutChanges = () => {
    resolveDuplicateTriage({
      variables: {
        orderId: props.orderId,
        prescriptionIds: [...props.ids],
      },
    })
  }

  const handleDiscontinue = (selectedDuplicatePrescriptions: ChosenDuplicateReason[]) => {
    discontinuePrescriptions({
      variables: {
        orderId: props.orderId,
        prescriptions: selectedDuplicatePrescriptions?.map(s => ({
          prescriptionId: s.prescriptionId,
          reason: s.reason,
          note: s.description,
        })),
      },
    })
  }

  return (
    <ReviewDuplicateRxContextProvider>
      <ReviewDuplicatePrescriptionModalUI
        {...props}
        isResolveDuplicateTriageLoading={isResolveDuplicateTriageLoading}
        isDiscontinuePrescriptionsLoading={isDiscontinuePrescriptionsLoading}
        isPrescriptionLoading={rxsFetchLoading}
        handleDiscontinue={handleDiscontinue}
        handleResolveWithoutChanges={handleResolveWithoutChanges}
        prescriptions={prescriptions}
      />
    </ReviewDuplicateRxContextProvider>
  )
}

export const ReviewDuplicatePrescriptionModalUI = ({
  mainPrescriptionId,
  hideResolveWithoutChangesButton = false,
  handleResolveWithoutChanges,
  handleDiscontinue,
  prescriptions,
  isDiscontinuePrescriptionsLoading,
  isResolveDuplicateTriageLoading,
  isPrescriptionLoading,
}: ReviewDuplicatePrescriptionModalUIProps) => {
  const { selectedDuplicatePrescriptions } = useContext(ReviewDuplicateRxContext) as ReviewDuplicateRxCtx

  const isAnyOperationLoading = isResolveDuplicateTriageLoading || isDiscontinuePrescriptionsLoading
  const valuesSelected = selectedDuplicatePrescriptions.length > 0

  return (
    <ModalWrapper id="ReviewDuplicatePrescriptionModal">
      <ModalHeader>
        <HeaderTitle>Review duplicate Rx</HeaderTitle>
      </ModalHeader>
      <Legend>Review and select which Rx to discontinue.</Legend>
      <DuplicatePrescriptionsTable
        prescriptions={prescriptions}
        loading={isPrescriptionLoading}
        mainPrescriptionId={mainPrescriptionId}
      />
      <Buttons style={{ visibility: isPrescriptionLoading ? 'hidden' : 'visible' }}>
        {!hideResolveWithoutChangesButton && (
          <Button
            disabled={valuesSelected || isAnyOperationLoading}
            onClick={handleResolveWithoutChanges}
            variant="primary-text"
          >
            {isResolveDuplicateTriageLoading ? 'Resolving...' : 'Resolve without changes'}
          </Button>
        )}
        <Button
          disabled={!valuesSelected || isAnyOperationLoading}
          onClick={() => handleDiscontinue(selectedDuplicatePrescriptions)}
        >
          {isDiscontinuePrescriptionsLoading ? 'Discontinuing...' : 'Discontinue'}
        </Button>
      </Buttons>
    </ModalWrapper>
  )
}

const HeaderTitle = styled.h2`
  font-weight: bold;
`

const Legend = styled.p`
  margin: 1rem 0;
`

const Buttons = styled.div`
  margin: 2rem 0;
  display: flex;
  gap: 1rem;
  justify-content: right;
`

export default ReviewDuplicatePrescriptionModal
