import { OrderStatus } from '@truepill/tpos-types'
import { ReactComponent as CancelIcon } from 'assets/icons/cancel.svg'
import ActionButton from 'components/ActionButton'
import IconWrapper from 'components/IconWrapper'
import useErrorToast from 'hooks/toast/useErrorToast'
import RejectOrderModal from 'modals/RejectOrderModal'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import { bodyPrimaryColor } from 'styles/styleVariables'
import type { Order } from 'types'

type RejectOrderButtonProps = { order: Order; disabled?: boolean }

const RejectOrderButton = ({ order, disabled }: RejectOrderButtonProps): JSX.Element => {
  const { showModal } = useModalContext()

  const showErrorToast = useErrorToast(true)

  const isOrderStatusValid = (order: Order) => {
    // cannot reject order if it's already complete/returned/cancelled
    return (
      order.status !== OrderStatus.Complete &&
      order.status !== OrderStatus.Returned &&
      order.status !== OrderStatus.Cancelled
    )
  }

  return (
    <ActionButton
      data-testid="RejectOrder"
      icon={
        <IconWrapper>
          <CancelIcon fill={bodyPrimaryColor} />
        </IconWrapper>
      }
      onClick={() =>
        isOrderStatusValid(order)
          ? showModal(() => <RejectOrderModal item={order} />)
          : showErrorToast('Completed or partially shipped orders cannot be rejected.')
      }
      label="Reject order"
      disabled={disabled}
    />
  )
}
export default RejectOrderButton
