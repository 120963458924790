import type { CustomDurSubject } from '@truepill/tpos-types'
import styled from 'styled-components'

type CustomDURSubjectsListProps = { className?: string; subjects: CustomDurSubject[] }

const CustomDURSubjectsList = ({ className, subjects }: CustomDURSubjectsListProps): JSX.Element => {
  return (
    <SubjectList className={className}>
      <Subject>Custom Input:</Subject>
      {subjects.map(({ name }, index) => (
        <Subject key={index}>{name}</Subject>
      ))}
    </SubjectList>
  )
}

const SubjectList = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
`

const Subject = styled.li`
  font-style: italic;
  :first-of-type {
    :after {
      content: ' ';
      margin-right: 0.4rem;
    }
  }
  :not(:last-of-type):not(:first-of-type) {
    :after {
      content: ', ';
      margin-right: 0.4rem;
    }
  }
  text-transform: capitalize;
`

export default CustomDURSubjectsList
