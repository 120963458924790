import { ReactComponent as CancelIcon } from 'assets/icons/cancel.svg'
import ActionButton from 'components/ActionButton'
import IconWrapper from 'components/IconWrapper'
import RejectModal from 'modals/RejectModal'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import { bodyPrimaryColor } from 'styles/styleVariables'
import type { Fill, Order } from 'types'

type RejectButtonProps = { order: Order; fill: Fill; orderEditable: boolean }

export const RejectButton = ({ fill, order, orderEditable }: RejectButtonProps): JSX.Element => {
  const { showModal } = useModalContext()

  return (
    <ActionButton
      data-testid="reject"
      disabled={!orderEditable}
      icon={
        <IconWrapper>
          <CancelIcon fill={bodyPrimaryColor} />
        </IconWrapper>
      }
      label="Reject"
      onClick={() =>
        showModal(() => (
          <RejectModal
            itemId={order._id}
            fillId={fill._id}
            durs={fill.durScreenings?.slice(-1)[0]?.results ?? []}
            isControlledSubstance={!!fill.handlingTags.deaScheduleNum || fill.handlingTags.isControlledSubstance}
          />
        ))
      }
      hotKey="R"
      hotKeyMeta={{ shift: true }}
      hotKeyLabel="SHIFT-R"
    />
  )
}
