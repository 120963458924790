import { DURStatus } from '@truepill/tpos-types'
import { ReactComponent as CheckboxIcon } from 'assets/icons/checkmark.svg'
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg'
import IconWrapper from 'components/IconWrapper'
import IconTile from 'components/Tiles/IconTile'
import Lozenge from 'components/Tiles/Lozenge'
import styled from 'styled-components'
import { confirmColor, primaryColor, primaryBackgroundColor, alertRed } from 'styles/styleVariables'

type ClinicalReviewTileProps = { durStatus?: string; lozenge?: boolean; role?: string }

const ClinicalReviewTile = ({ durStatus, lozenge }: ClinicalReviewTileProps): JSX.Element => {
  const Wrapper = lozenge ? StyledLozenge : StyledIconTile
  const labelText = `CLINICAL REVIEW - ${durStatus}`
  const lozengeText = lozenge ? labelText : undefined
  const testId = `dur-${durStatus}`

  // Add a tooltip and accessibility hint to the icon, but only if not showing lozenge text
  const iconAttrs = { role: 'img', title: lozenge ? undefined : labelText, 'aria-hidden': lozenge ? true : undefined }

  if (durStatus === DURStatus.MILD) {
    return (
      <Wrapper backgroundColor={primaryColor} data-testid={testId}>
        <CheckboxIconWrapper {...iconAttrs}>
          <CheckboxIcon fill={primaryBackgroundColor} />
        </CheckboxIconWrapper>
        {lozengeText}
      </Wrapper>
    )
  }
  if (durStatus === DURStatus.MODERATE) {
    return (
      <Wrapper backgroundColor={alertRed} data-testid={testId}>
        <IconWrapper {...iconAttrs}>
          <WarningIcon fill={primaryBackgroundColor} />
        </IconWrapper>
        {lozengeText}
      </Wrapper>
    )
  }
  if (durStatus === DURStatus.SEVERE) {
    return (
      <Wrapper backgroundColor={alertRed} data-testid={testId}>
        <IconWrapper {...iconAttrs}>
          <WarningIcon fill={primaryBackgroundColor} />
        </IconWrapper>
        {lozengeText}
      </Wrapper>
    )
  }
  if (durStatus === DURStatus.NONE) {
    return (
      <Wrapper backgroundColor={confirmColor} data-testid={testId}>
        <CheckboxIconWrapper {...iconAttrs}>
          <CheckboxIcon fill={primaryBackgroundColor} />
        </CheckboxIconWrapper>
        {lozengeText}
      </Wrapper>
    )
  }
  return <></>
}

const CheckboxIconWrapper = styled(IconWrapper)`
  padding-top: 0.1rem;
`

const StyledIconTile = styled(IconTile)`
  color: ${primaryBackgroundColor};
`

const StyledLozenge = styled(Lozenge)`
  padding-left: 0.25rem;
  text-transform: uppercase;
  color: ${primaryBackgroundColor};
  > p {
    margin-left: 0.3rem;
  }
`

export default ClinicalReviewTile
