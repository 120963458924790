import { useEffect } from 'react'
import { useMutation } from '@truepill/tpos-react-router'
import { LOG_PAGE_VIEW } from 'gql'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const sortByEntryKey = ([keyA]: [string, any], [keyB]: [string, any]) => {
  return keyA === keyB ? 0 : keyA < keyB ? -1 : 1
}

/** Log a page view */
const useLogPageView = (
  pageData: { page: string; orderId?: string; fillId?: string; copayRequestId?: string; priorAuthorizationId?: string },
  ready = true, // pass false if data is incomplete or still loading
) => {
  const { page, ...pageViewAttributes } = pageData
  const { orderId, fillId, copayRequestId, priorAuthorizationId } = pageViewAttributes
  const [logPageView] = useMutation(LOG_PAGE_VIEW, {
    onError(err) {
      console.error(err)
    },
  })

  // create a page key
  const attsQueryString = new URLSearchParams(
    Object.fromEntries(Object.entries(pageViewAttributes).sort(sortByEntryKey)),
  ).toString()
  const pageKey = `${page}${attsQueryString ? `?${attsQueryString}` : ''}`

  useEffect(() => {
    if (!ready) {
      return
    }

    // get the last page key from session storage
    const lastView = sessionStorage.getItem('lastPageView')

    // allow the user to refresh the page without triggering another view event
    if (lastView === pageKey) {
      return
    }

    sessionStorage.setItem('lastPageView', pageKey)

    logPageView({
      variables: {
        page,
        pageViewAttributes: {
          orderId,
          fillId,
          copayRequestId,
          priorAuthorizationId,
        },
      },
    })
  }, [ready, page, pageKey, orderId, fillId, copayRequestId, priorAuthorizationId, logPageView])
}

export default useLogPageView
