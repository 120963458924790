import type { ChangeEvent } from 'react'
import { useCallback, useState, memo } from 'react'
import { Button } from '@truepill/react-capsule'
import { useMutation } from '@truepill/tpos-react-router'
import type { Language } from '@truepill/tpos-types'
import { PrinterPurpose } from '@truepill/tpos-types'
import { ReactComponent as PrintIcon } from 'assets/icons/print.svg'
import CustomizedTextField from 'components/CustomizedTextField'
import IconWrapper from 'components/IconWrapper'
import type { LanguageOption } from 'components/LanguageSelect'
import {
  defaultOption as defaultLanguageOption,
  MemoizedLanguageSelect as LanguageSelect,
} from 'components/LanguageSelect'
import PrinterSelect from 'components/PrinterSelect'
import { PRINT_MONOGRAPH } from 'gql'
import useErrorToast from 'hooks/toast/useErrorToast'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import { usePlusClient } from 'providers/VisionRouter'
import styled from 'styled-components'
import { capsulePrimaryColorDark } from 'styles/styleVariables'
import type { Printer } from 'types'

interface PrintMonographFormProps {
  ndc: string
  languagesAvailable: Language[]
}

const validateForm = (values: {
  printer: string | Printer | undefined
  qty: number | undefined
  language: LanguageOption | undefined
}) => {
  type Values = typeof values
  const errors: { [V in keyof Values]?: string[] } = {}

  const rules: { [V in keyof Values]: { validate: () => boolean; error: string }[] } = {
    printer: [{ validate: () => !!values.printer, error: 'This field is required' }],
    qty: [
      { validate: () => values.qty !== undefined && values.qty.toString() !== '', error: 'This field is required' },
      { validate: () => !isNaN(values.qty as number), error: 'This field must be a number' },
      { validate: () => (values.qty as number) >= 1, error: 'Min value is 1' },
    ],
    language: [{ validate: () => !!values.language, error: 'This field is required' }],
  }

  Object.keys(rules).forEach(k => {
    const el = k as keyof typeof values
    const invalid = rules[el].find(rule => !rule.validate())

    if (invalid) {
      if (!errors[el]) errors[el] = []
      errors[el]?.push(invalid.error)
    }
  })

  return { errors, valid: !Object.keys(errors).length }
}

const PrintMonographForm = ({ ndc, languagesAvailable }: PrintMonographFormProps) => {
  return (
    <>
      <Title>Print monograph</Title>
      <FormBody ndc={ndc} languagesAvailable={languagesAvailable} />
    </>
  )
}

const FormBody = ({ ndc, languagesAvailable }: PrintMonographFormProps) => {
  const [printer, setPrinter] = useState<string | Printer | undefined>()
  const [qty, setQty] = useState<number | undefined>(1)
  const [language, setLanguage] = useState<LanguageOption | undefined>(defaultLanguageOption)

  const showErrorToast = useErrorToast()
  const showSuccessToast = useSuccessToast()

  const { tokenContext } = usePlusClient()

  const handlePrintSuccess = useCallback(
    ({ printMonograph }) => {
      if (printer === 'pdf') {
        const s3SignedUrl = printMonograph?.previewResponse?.s3SignedUrl
        if (s3SignedUrl) {
          window.open(s3SignedUrl)
        }
      } else {
        showSuccessToast('Print request sent.')
      }
    },
    [showSuccessToast, printer],
  )

  const handlePrintError = useCallback(
    (error: Error) => {
      showErrorToast(`Failed to print. ` + error.message)
    },
    [showErrorToast],
  )

  const [printMonograph] = useMutation(PRINT_MONOGRAPH, {
    onCompleted: handlePrintSuccess,
    onError: error => handlePrintError(error),
  })

  const handleSubmit = useCallback(() => {
    const isPreview = printer === 'pdf'

    printMonograph({
      variables: {
        ndc,
        copies: qty,
        language: language?.value,
        preview: isPreview,
        printerId: `${(printer as Printer)?._id}`,
      },
    })
  }, [language, ndc, printMonograph, printer, qty])

  const locationId = !tokenContext?.isAdmin() ? tokenContext?.locationId : undefined
  const { valid: formIsValid, errors } = validateForm({ qty, printer, language })
  const printerInputHasErrors = !formIsValid && errors.printer?.[0]
  const qtyInputHasErrors = !formIsValid && errors.qty?.[0]

  return (
    <>
      <FormGroup>
        <PrinterSelect
          printerType="pdf"
          helperText={printerInputHasErrors ? errors.printer?.[0] : ''}
          state={printerInputHasErrors ? 'error' : 'default'}
          printerPurpose={PrinterPurpose.RxLabel}
          label="Select printer"
          locationId={locationId}
          onChange={setPrinter}
        />
      </FormGroup>
      <FormGroup>
        <QtyTextField
          helperText={qtyInputHasErrors ? errors.qty?.[0] : ''}
          state={qtyInputHasErrors ? 'error' : 'default'}
          minValue={1}
          value={qty}
          onChange={setQty}
        />
      </FormGroup>
      {languagesAvailable.length > 1 && (
        <FormGroup>
          <LanguageSelect onChange={setLanguage} value={language} languagesAvailable={languagesAvailable} />
        </FormGroup>
      )}
      <FormGroup>
        <WrapButton disabled={!formIsValid} onClick={handleSubmit} variant="primary-outline">
          <IconWrapper>
            <PrintIcon />
          </IconWrapper>
          Print
        </WrapButton>
      </FormGroup>
    </>
  )
}

interface QtyTextFieldProps {
  minValue: number
  value?: number
  onChange(value?: number): void
  helperText?: string
  state?: 'default' | 'error'
}

const QtyTextField = memo(({ value, onChange, minValue, helperText, state }: QtyTextFieldProps) => {
  return (
    <CustomizedTextField
      state={state}
      helperText={helperText}
      value={value}
      min={minValue}
      type="number"
      label="Qty"
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value
        const n = val !== '' ? Number(val) : undefined
        onChange(n)
      }}
    />
  )
})

const FormGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1.5rem;
`

export const Title = styled.h1`
  font-size: 1.5rem;
  font-family: Lato, sans-serif;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.55px;
  margin-bottom: 0.5rem;
`

export const WrapButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  margin-left: 0.5rem;

  span {
    margin-right: 0.25rem;
    svg {
      fill: ${capsulePrimaryColorDark};
    }
  }

  :hover:not(:disabled) {
    span {
      svg {
        fill: white;
      }
    }
  }
`

export default PrintMonographForm
