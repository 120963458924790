import RerouteModal from 'modals/RerouteModal'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import type { Order } from 'types'

type useRerouteOrderType = () => void

const useRerouteOrder = (order: Order): useRerouteOrderType => {
  const { showModal } = useModalContext()

  return () => showModal(() => <RerouteModal item={order} />)
}

export default useRerouteOrder
