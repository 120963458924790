interface RejectCodeCategory {
  _id: string
  name: string
}

const OTHER_CATEGORY = {
  key: '-1',
  name: 'Other',
}

export const categories = {
  '1': {
    name: 'Claims',
    codes: [
      '12',
      '15',
      '16',
      '17',
      '19',
      '20',
      '21',
      '22',
      '23',
      '26',
      '27',
      '28',
      '285',
      '29',
      '292',
      '30',
      '31',
      '33',
      '39',
      '3P',
      '4Y',
      '50',
      '54',
      '55',
      '566',
      '606',
      '614',
      '645',
      '650',
      '6J',
      '704',
      '77',
      '78',
      '7V',
      '80',
      '80',
      '81',
      '81',
      '83',
      '83',
      '8E',
      '8J',
      '8K',
      '8P',
      '8V',
      '8Z',
      '9D',
      '9E',
      '9G',
      'AA',
      'AC',
      'AK',
      'DN',
      'DQ',
      'DU',
      'DX',
      'DY',
      'E1',
      'E3',
      'E6',
      'E7',
      'EK',
      'G5',
      'G9',
      'HG',
      'M2',
      'M4',
      'M5',
      'M7',
      'MU',
      'MV',
      'PZ',
      'R6',
      'R9',
      'UU',
    ],
  },
  '2': {
    name: 'Coordination Of Benefits (COB)',
    codes: [
      '41',
      '442',
      '443',
      '446',
      '4C',
      '535',
      '557',
      '5C',
      '5E',
      '609',
      '6E',
      '6G',
      '74',
      '7C',
      '7K',
      '7M',
      '8W',
      'DV',
      'E8',
      'H8',
      'H9',
      'HB',
      'HC',
      'NP',
      'NQ',
      'NR',
      'PE',
      'PX',
      'R2',
    ],
  },
  '3': { name: 'Drug Utilization Review (DUR)', codes: ['34', '79', '88', '88', 'J9'] },
  '4': {
    name: 'Eligibility',
    codes: ['52', '600', '646', '65', '67', '68', '69', '7D', '9', 'AF', 'M1', 'MS', 'N1', 'PW', 'ZZ'],
  },
  '5': {
    name: 'M/I Patient Info',
    codes: [
      '10',
      '11',
      '13',
      '4X',
      '53',
      '538',
      '62',
      '7H',
      '7J',
      '8',
      'CA',
      'CB',
      'CC',
      'CD',
      'CM',
      'CN',
      'CO',
      'CP',
      'CQ',
      'CY',
      'PK',
    ],
  },
  '6': {
    name: 'M/I Prescriber Info',
    codes: ['25', '2N', '42', '43', '44', '46', '56', '583', '595', '619', '6Z', '71', 'A1', 'AD', 'E9', 'EZ'],
  },
  '7': { name: 'M/I Payer Info', codes: ['1', '1T', '4', '51', '6', '7', 'MG', 'MK'] },
  '8': { name: 'Medicare ', codes: ['569', '620', '621', 'A3', 'A4', 'A6', 'AE', 'WE'] },
  '9': {
    name: 'Overrides / Prior Auth',
    codes: [
      '3R',
      '3Y',
      '4Z',
      '521',
      '608',
      '64',
      '651',
      '6Q',
      '75',
      '7W',
      '8R',
      'AJ',
      'BB',
      'E4',
      'E5',
      'EU',
      'EV',
      'N8',
      'PA',
      'TQ',
    ],
  },
  '10': {
    name: 'Plan Limitations',
    codes: ['40', '4W', '561', '60', '61', '66', '70', '73', '76', '7X', 'A5', 'AG', 'MR'],
  },
  '11': {
    name: 'System/ Host Error',
    codes: ['84', '85', '86', '87', '90', '91', '92', '95', '96', '97', '98', '99', 'M3', 'M6', 'M8', 'MZ', 'NN', 'R8'],
  },
}

const getCodesWithCategories = () => {
  const codes = {}
  Object.keys(categories).forEach(key => {
    categories[key].codes.forEach(code => {
      codes[code] = key
    })
  })

  return codes
}

const getAvailableCategories = (availableRejectCodes: Array<string>) => {
  const codesWithCategories = getCodesWithCategories()

  const availableCategories = {}
  availableRejectCodes.forEach(code => {
    const categoryId = codesWithCategories[code]
    if (categoryId !== undefined) {
      availableCategories[categoryId] = categories[categoryId]
    } else {
      availableCategories[OTHER_CATEGORY.key] = { name: OTHER_CATEGORY.name }
    }
  })

  return availableCategories
}

export const getRejectCodeCategories = (availableRejectCodes: Array<string>): Array<RejectCodeCategory> => {
  const availableCategories = getAvailableCategories(availableRejectCodes)
  return Object.keys(availableCategories).map(key => {
    return {
      name: availableCategories[key].name,
      _id: key,
    } as RejectCodeCategory
  }) as RejectCodeCategory[]
}

const getCodesForOtherCategory = (excludedCategories: Array<string>): Array<string> => {
  let codes = []
  Object.keys(categories).forEach(categoryKey => {
    if (categoryKey !== OTHER_CATEGORY.key && !excludedCategories.includes(categoryKey)) {
      codes = codes.concat(categories[categoryKey].codes)
    }
  })

  return codes
}

const getCodes = (includedCategories: Array<string>) => {
  let codes = []
  includedCategories.forEach(categoryKey => {
    if (categories[categoryKey] !== undefined) {
      codes = codes.concat(categories[categoryKey].codes)
    }
  })
  return codes
}

export const getQueryForRejectCodeCategories = (selectedCategories: Array<string>): Record<string, any> => {
  if (selectedCategories.includes(OTHER_CATEGORY.key)) {
    return {
      'rxFillRequests.adjudication.lastClaimRejectCodes.0': { $exists: true },
      'rxFillRequests.adjudication.lastClaimRejectCodes': { $nin: getCodesForOtherCategory(selectedCategories) },
    }
  }

  const codes = getCodes(selectedCategories)
  return codes.length ? { 'rxFillRequests.adjudication.lastClaimRejectCodes': { $in: codes } } : {}
}
