import { Link, useLocation } from '@truepill/tpos-react-router'
import LoadingSpinner from 'components/Loading'
import { FilledHeadingStyle } from 'components/PageStructure'
import moment from 'moment'
import { goToViewPayer } from 'routes'
import styled from 'styled-components'
import { subduedColor, contrastColor, contrastBackgroundColor, primaryColorLight } from 'styles/styleVariables'
import type { InsuranceProvider } from 'types'
import { checkIfProvided, formatPhoneNumber } from 'utils'

type PayersTableProps = { payers: InsuranceProvider[]; searchTermGiven?: boolean; loading?: boolean }

const PayersTable = (props: PayersTableProps): JSX.Element => {
  const { searchTermGiven, payers = [], loading } = props

  const noPayersText = searchTermGiven ? 'No results' : 'Enter a search term to find payers'

  if (loading) {
    return (
      <PayerLoadingContainer>
        <TitleRow />
        <LoadingSpinnerBox>
          <LoadingSpinner />
        </LoadingSpinnerBox>
      </PayerLoadingContainer>
    )
  }

  return (
    <PayerContainer>
      <TitleRow />
      {payers.map(payer => (
        <PayerEntry key={payer._id} {...payer} />
      ))}
      {payers.length === 0 && <NoResultsEntry>{noPayersText}</NoResultsEntry>}
    </PayerContainer>
  )
}

const TitleRow = (): JSX.Element => {
  return (
    <TitlePayerRow>
      <Name>Name</Name>
      <Bin>BIN</Bin>
      <Pcn>PCN</Pcn>
      <Type>Type</Type>
      <HelpdeskNumber>Helpdesk Number</HelpdeskNumber>
      <Fax>Fax</Fax>
      <Created>Created</Created>
    </TitlePayerRow>
  )
}

const PayerEntry = (props: InsuranceProvider): JSX.Element => {
  const { search } = useLocation()
  const payerCreated = moment(props.importDate).format('MM/DD/YYYY')

  return (
    <StyledLink to={goToViewPayer({ payerId: props._id, search })}>
      <PayerRow data-private>
        <Name>{props.name}</Name>
        <Bin>{props.bin}</Bin>
        <Pcn>{props.pcn}</Pcn>
        <Type>{props.planType}</Type>
        <HelpdeskNumber>{checkIfProvided(formatPhoneNumber(props.helpdeskNumber))}</HelpdeskNumber>
        <Fax>{checkIfProvided(formatPhoneNumber(undefined))}</Fax>
        <Created>{payerCreated}</Created>
      </PayerRow>
    </StyledLink>
  )
}

const PayerCell = styled.li`
  grid-row: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 0.3125rem;
  margin-right: 0.3125rem;
`

const PayerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  a:last-child {
    border-bottom: 0.25rem solid ${contrastColor};
    border-radius: 0.25rem;
  }
  flex: 1;
`

const PayerLoadingContainer = styled(PayerContainer)`
  :last-child {
    border-bottom: none;
    border-radius: 0;
  }
`

const StyledLink = styled(Link)`
  :hover {
    background-color: ${primaryColorLight};
    :nth-of-type(2n + 1) {
      background-color: ${primaryColorLight};
    }
  }
  :nth-of-type(2n + 1) {
    background-color: ${contrastBackgroundColor};
  }
`

const NoResultsEntry = styled.p`
  font-size: 1rem;
  color: ${subduedColor};
  padding: 1rem;
  width: 100%;
  text-align: center;
`

const PayerRow = styled.ul`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns:
    [name] 1fr [bin] 1fr [pcn] minmax(7rem, 8rem) [type] minmax(5rem, 8rem)
    [helpdeskNumber] 2fr [fax] minmax(8rem, 15rem)
    [created] minmax(7rem, 15rem);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
`

const TitlePayerRow = styled(PayerRow)`
  ${FilledHeadingStyle}
  font-weight: 500;
  padding-left: 0rem;
  padding-right: 0rem;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  border-radius: 0.25rem 0.25rem 0rem 0rem;
`

const Name = styled(PayerCell)`
  grid-column: name;
`
const Bin = styled(PayerCell)`
  grid-column: bin;
`
const Pcn = styled(PayerCell)`
  grid-column: pcn;
`

const Type = styled(PayerCell)`
  text-transform: capitalize;
  grid-column: type;
`
const HelpdeskNumber = styled(PayerCell)`
  grid-column: helpdeskNumber;
`

const Fax = styled(PayerCell)`
  grid-column: fax;
`
const Created = styled(PayerCell)`
  grid-column: created;
`

const LoadingSpinnerBox = styled.div`
  display: flex;
  padding-top: 4rem;
  justify-content: center;
  svg {
    height: 126px;
  }
`

export default PayersTable
