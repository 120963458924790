import type { FormEvent, FC } from 'react'
import { useState, useEffect, useCallback } from 'react'
import { FormGroup, Radio, Select, Button, Text, Theme, Checkbox, Spacer } from '@truepill/react-capsule'
import { useMutation } from '@truepill/tpos-react-router'
import {
  RxFillRequestStatus,
  FillLabelReprintReason,
  PrinterPurpose,
  RxPrintComponentOption,
  CustomerIDs,
  PrinterType,
  AutobotType,
} from '@truepill/tpos-types'
import { ReactComponent as AlertTriangle } from 'assets/icons/alert-triangle.svg'
import CustomizedTextField from 'components/CustomizedTextField'
import IconWrapper from 'components/IconWrapper'
import { ModalWrapper, ModalHeader, ButtonsContainer } from 'components/Modal'
import PrinterSelect from 'components/PrinterSelect'
import TextAreaWithCharacterCount from 'components/TextAreaWithCharacterCount'
import {
  CREATE_PREVIEW_RX_LABEL,
  PRINT_RX_LABEL,
  PRINT_SHIPPING_LABEL,
  SET_DISPENSED,
  BULK_SEND_DEFERRED_TO_AUTOMATION,
} from 'gql'
import useErrorToast from 'hooks/toast/useErrorToast'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import { isEmpty } from 'lodash'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import { usePrintProvider } from 'providers/PrintProvider'
import { useTPCacheContext } from 'providers/TPCacheProvider'
import { usePlusClient } from 'providers/VisionRouter'
import styled, { css } from 'styled-components'
import type { Printer, Order } from 'types'
import { directionsLengthDetails } from 'utils'
import { capsuleGreyColor } from '../styles/styleVariables'

const getPrintReasons = (status?: RxFillRequestStatus) => {
  const options = Object.values(FillLabelReprintReason)
  return status !== RxFillRequestStatus.PV2
    ? options.filter(option => option !== FillLabelReprintReason.EditDirections)
    : options
}
interface FillWithOrderId {
  fillId: string
  orderId: string
}
interface RxLabelReprintSectionOption {
  label: string
  value: RxPrintComponentOption
  customerBlackList: CustomerIDs[]
}

const rxLabelReprintSectionOptions: RxLabelReprintSectionOption[] = [
  { label: 'Reprint Rx label only', value: RxPrintComponentOption.RxLabelOnly, customerBlackList: [] },
  {
    label: 'Reprint Rx label + patient education',
    value: RxPrintComponentOption.RxLabelAndPatientEducation,
    customerBlackList: [CustomerIDs.HIMS, CustomerIDs.LIFE_MD, CustomerIDs.REX_MD, CustomerIDs.SHAPIRO_MD],
  },
]

const getRxLabelReprintSectionOptions = (customerLegacyId: number | undefined): RxLabelReprintSectionOption[] =>
  rxLabelReprintSectionOptions.filter(opt => !customerLegacyId || !opt.customerBlackList.includes(customerLegacyId))

type PrintModalProps = {
  confirmationCallback?: () => void
  fills: FillWithOrderId[]
  isReprint?: boolean
  orderId?: Order['_id']
  printerPurpose?: PrinterPurpose
  title: string
  status?: RxFillRequestStatus
  isGeneric?: boolean
  customerId?: number
}

const overrides = {
  autocomplete: { margin: '1.5rem 0 1rem' },
  selectTrigger: { height: 48 },
  formGroup: {
    margin: '1rem 0',
    '& [data-form-group-label]': { fontSize: '1rem' },
  },
}

export const PrintModal: FC<PrintModalProps> = props => {
  const { isReprint = false, printerPurpose = PrinterPurpose.RxLabel } = props
  const { fills, orderId, title, confirmationCallback, status, isGeneric, customerId } = props

  const { dismissModal } = useModalContext()
  const { setPrinter, selectedPrinter, getSavedPrinterId } = usePrintProvider()
  const { getPrinterById } = useTPCacheContext()
  const { tokenContext } = usePlusClient()
  const {
    currentLocation: { queryMap },
  } = usePlusClient()
  const isDeferredAutomation = queryMap.autobot === AutobotType.deferredAutomation

  const [reprintReason, setReprintReason] = useState('')
  const [printingComponentOption, setPrintingComponentOption] = useState(RxPrintComponentOption.RxLabelOnly)
  const [newDirections, setNewDirections] = useState('')
  const [openAsPdf, setOpenAsPdf] = useState(false)
  const [showPdfPreviewCheckBox, setShowPdfPreviewCheckBox] = useState(false)
  const [pdfPreviewLoading, setPdfPreviewLoading] = useState(false)
  const [quantity, setQuantity] = useState<number | string>(1)

  const showErrorToast = useErrorToast()
  const showSuccessToast = useSuccessToast()

  const handlePrintingSectionSelection = useCallback(
    e => {
      setPrintingComponentOption(e.target.value)
    },
    [setPrintingComponentOption],
  )

  const onPrintSuccess = useCallback(() => {
    showSuccessToast('Print request sent.')
    confirmationCallback?.()
    dismissModal()
  }, [showSuccessToast, confirmationCallback, dismissModal])

  const onPrintFail = useCallback(
    (error: Error) => {
      dismissModal()
      showErrorToast(`Failed to print ${orderId ? 'shipping label' : 'fill'}. ` + error.message)
    },
    [dismissModal, orderId, showErrorToast],
  )

  const [printShippingLabel] = useMutation(PRINT_SHIPPING_LABEL, {
    onCompleted: () => {
      onPrintSuccess()
    },
    onError: error => onPrintFail(error),
  })

  const [printLabel] = useMutation(PRINT_RX_LABEL, {
    onCompleted: () => {
      onPrintSuccess()
      if (isDeferredAutomation) {
        bulkSendDeferredToAutomation({ variables: { fills } })
      }
    },
    onError: error => onPrintFail(error),
  })

  const [createPDFLabel] = useMutation(CREATE_PREVIEW_RX_LABEL, {
    onCompleted: ({ createPreviewLabel }) => {
      for (const { s3SignedUrl } of createPreviewLabel) {
        window.open(s3SignedUrl)
      }
      setPdfPreviewLoading(false)
    },
    onError: error => onPrintFail(error),
  })

  const [bulkSendDeferredToAutomation] = useMutation(BULK_SEND_DEFERRED_TO_AUTOMATION, {
    onCompleted(res) {
      const error = res.bulkSendDeferredToAutomation.map((i: any) => i.error).filter((i: any) => !!i)
      if (error.length) {
        showErrorToast(error.join('/'))
      } else {
        showSuccessToast(`Successfully sent to automation.`)
      }
    },
    onError(err) {
      showErrorToast(err.message)
    },
  })

  const sendLabelToPrint = () => {
    switch (printerPurpose) {
      case PrinterPurpose.RxLabel:
        printLabel({
          variables: {
            fills,
            packagesRequired: quantity,
            printerId: selectedPrinter?._id,
            reason: reprintReason,
            printComponent: isReprint ? printingComponentOption : undefined,
          },
        })
        break
      case PrinterPurpose.ShippingLabel:
        printShippingLabel({
          variables: {
            orderId,
            printerUrl: selectedPrinter?.GCPAddress,
          },
        })
        break
    }
  }

  const [updateDispensedMedication] = useMutation(SET_DISPENSED, {
    onCompleted: () => sendLabelRequest(),
    onError: error => onPrintFail(error),
  })

  const sendLabelRequest = () => {
    if (openAsPdf) {
      setPdfPreviewLoading(true)
      createPDFLabel({
        variables: {
          printerId: selectedPrinter?._id,
          fills,
          printComponent: isReprint ? printingComponentOption : undefined,
        },
      })
    } else {
      sendLabelToPrint()
    }
  }

  const isReasonGiven = reprintReason !== ''

  const newDirectionsAreMissing =
    printerPurpose === PrinterPurpose.RxLabel &&
    status === RxFillRequestStatus.PV2 &&
    reprintReason === FillLabelReprintReason.EditDirections &&
    newDirections === ''

  const locationId = !tokenContext?.isAdmin() ? tokenContext?.locationId : undefined

  const onPrinterChange = (printer?: Printer | string): void => {
    setShowPdfPreviewCheckBox(!!printer && (printer as Printer).printerType === PrinterType.PDF)
    setPrinter(printer as Printer, printerPurpose)
  }

  useEffect(() => {
    const persistedPrinterId = getSavedPrinterId(printerPurpose)
    if (persistedPrinterId && persistedPrinterId.length) {
      const printer = getPrinterById(persistedPrinterId)
      printer && setPrinter(printer as Printer)
    }
  }, [setPrinter, getPrinterById, printerPurpose, getSavedPrinterId])

  const submitDisabled =
    (isReprint && (!isReasonGiven || newDirectionsAreMissing || !printingComponentOption)) ||
    (!selectedPrinter && !openAsPdf) ||
    pdfPreviewLoading ||
    !quantity

  const selectedEditDirections =
    printerPurpose === PrinterPurpose.RxLabel &&
    status === RxFillRequestStatus.PV2 &&
    reprintReason === FillLabelReprintReason.EditDirections

  const directionsDetails = selectedEditDirections ? directionsLengthDetails(!!isGeneric, newDirections) : undefined

  const handleOnClick = () => {
    if (selectedEditDirections) {
      const [{ fillId }] = fills
      updateDispensedMedication({ variables: { itemId: orderId, fillId, directions: newDirections } })
      return
    }

    sendLabelRequest()
  }

  const handleOnQuantityChange = (event: FormEvent<HTMLInputElement>) => {
    const result = event.currentTarget.value.replace(/\D/g, '')
    const isValidQuantity = !isEmpty(result) && parseInt(result) > 0

    setQuantity(isValidQuantity ? parseInt(result) : '')
  }

  return (
    <ModalWrapper id="PrintModal" styles={StyledModalWrapper}>
      <ModalHeader>{title}</ModalHeader>
      {isReprint && (
        <TextWrapper>
          Some of these labels have already been printed, are you sure you want to reprint these fills?
        </TextWrapper>
      )}
      {isDeferredAutomation && (
        <DeferredAutomationTextGroup>
          <StyledIconWrapper>
            <AlertTriangle />
          </StyledIconWrapper>
          <DeferredAutomationText>
            The fills in at least one of the selected batch orders span different Automation machines and/or manual
            fill. Refer to the Rx leaflets to locate each fill in the batch.
          </DeferredAutomationText>
        </DeferredAutomationTextGroup>
      )}
      <Theme overrides={overrides}>
        <PrinterSelect
          showOpenAsPdf={false}
          locationId={locationId}
          onChange={onPrinterChange}
          printerPurpose={printerPurpose}
        />
        {showPdfPreviewCheckBox && (
          <>
            <Checkbox
              label="Open PDF preview"
              checked={openAsPdf}
              onCheckedChange={val => {
                setOpenAsPdf(Boolean(val))
              }}
            />
            <Spacer />
          </>
        )}
        {isReprint && (
          <>
            <Select
              value={reprintReason}
              label="Reason to reprint"
              placeholder={'Select a reason...'}
              options={getPrintReasons(status)}
              onChange={value => setReprintReason(value ? value : '')}
            />
            {selectedEditDirections && (
              <TextAreaWithCharacterCount
                data-testid="new-directions"
                placeholder="Type new directions..."
                onChange={event => setNewDirections(event.target.value)}
                value={newDirections}
                helperText={!newDirections ? 'This field is required' : undefined}
                state={!newDirections ? 'error' : 'default'}
                error={directionsDetails?.tooLong}
                errorText="Directions may print on leaflet instead of Rx label"
                maxLength={directionsDetails?.maxLength}
                stripWhiteSpace
              />
            )}
            <FormGroup label="Select an option below:">
              {getRxLabelReprintSectionOptions(customerId).map(option => (
                <Radio
                  key={option.value}
                  value={option.value}
                  label={option.label}
                  checked={printingComponentOption === option.value}
                  onChange={handlePrintingSectionSelection}
                />
              ))}
            </FormGroup>
          </>
        )}

        <StyledTextField type="number" value={quantity} label="Qty of Rx label" onChange={handleOnQuantityChange} />
      </Theme>

      <ButtonsContainer>
        <Button size="sm" variant="primary-text" onClick={dismissModal} disabled={pdfPreviewLoading}>
          Cancel
        </Button>
        <Button size="sm" disabled={submitDisabled} onClick={handleOnClick}>
          {isReprint ? 'Yes, reprint label' : 'Print'}
        </Button>
      </ButtonsContainer>
    </ModalWrapper>
  )
}

export default PrintModal

const StyledModalWrapper = css`
  overflow-y: visible;
  width: 450px;

  > span div {
    font-size: 26pt;
  }
`

const StyledTextField = styled(CustomizedTextField)`
  height: 48px;
  width: 100px;
  padding-right: 0.625rem;
`

const TextWrapper = styled(Text)`
  padding-top: 1rem;
  margin-bottom: -0.5rem;
  color: ${capsuleGreyColor};
`
const DeferredAutomationText = styled(Text)`
  padding-left: 1rem;
`

const DeferredAutomationTextGroup = styled(Text)`
  display: flex;
  background: #fff6e8;
  color: #af5304;
  border: solid 1px #af5304;
  border-radius: 5px;
  padding: 0.5rem;
  margin-bottom: -0.5rem;
  margin-top: 1rem;
`

const StyledIconWrapper = styled(IconWrapper)`
  height: 1.5rem;
  padding-left: 5px;
`
