import type { ReactNode } from 'react'
import styled from 'styled-components'
import { capsuleDarkRedColor, capsuleLightRedColor, darkInfo, lightInfo } from 'styles/styleVariables'

type State = 'info' | 'error'
interface NotificationBoxProps {
  state: State
  children: ReactNode
}

const NotificationBox = ({ state, children }: NotificationBoxProps) => {
  return <Container state={state}>{children}</Container>
}

const Container = styled.div<{ state?: State }>`
  padding: 1rem;
  border-radius: 0.5rem;
  line-height: 1.25rem;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
  font-weight: 400;
  box-shadow: 0 0.25rem 0.25rem 0.1rem #0000000f;
  ${({ state }) => `background-color: ${state === 'info' ? lightInfo : capsuleLightRedColor};`}
  ${({ state }) => `color: ${state === 'info' ? darkInfo : capsuleDarkRedColor};`}
  ${({ state }) => `border-left: 0.25rem solid ${state === 'info' ? darkInfo : capsuleDarkRedColor};`}
`

export default NotificationBox
