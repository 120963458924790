import { useEffect, useCallback, useRef } from 'react'
import { couriers, findTracking } from '@truepill/tpos-data-util'
import useErrorToast from './toast/useErrorToast'

// Commonly used barcode regexes
export const REGEXES = {
  // e.g. 04-3778106-00-R1
  RxFillCode: /(\d+)-(\d+)-(\d\d)(-[CP])?(-R\d)?/,
  // e.g. ^yjD02a3sny^
  ShortFillCode: /^\^.*\^$/,
  // e.g. 323155019101 (12 digit)
  NDC: /^(\d{4,6}-?\d{3,4}-?\d{2,3})$/,
  // e.g. 323155019101 (mandatory 12 digit)
  SKU: /^\d{1,12}$/,
  // e.g. 1a2b3c4d5e6f7 or MALSHAM-8 (3-13 characters)
  UPC: /^[0-9a-zA-Z-]{3,13}$/,
  // e.g. *jg1x4m*
  CoreOrderToken: /^\*.*\*$/,
  // GS1 Regular expressions are defined for each Application Identifier
  // Batch or lot number: https://www.gs1.org/standards/barcodes/application-identifiers/10
  // This seems to be the most inclusive for the all the AIs that we will need
  // Some hex values were replaced with characters for readability e.g. a-z
  GS1: /^[A-Za-z0-9_\x21-\x22\x25-\x2F\x3A-\x3F\x1D]{16,}/,
  // Tracking number matching will be done via ts-tracking-number library, inside notifyListeners logic
  ShippingLabel: 'TRACKING_NUMBER',
}

interface BarcodeOptions {
  minCharMatch: number
  debounceWindow: number
  terminationChar: KeyboardEvent['key']
  charsAllowed: RegExp
}

const BarcodeOptionsDefault: BarcodeOptions = {
  minCharMatch: 3,
  debounceWindow: 200,
  terminationChar: 'Enter',
  charsAllowed: /[A-Za-z0-9-._~^*\x21-\x22\x25-\x2F\x3A-\x3F]/,
}

type BarcodeListener = (barcode: string) => void

type useBarcodeScannerType = {
  registerListener: (regex: RegExp | string, callback: BarcodeListener) => void
  deregisterListener: (regex: RegExp | string, callback: BarcodeListener) => void
}

const useBarcodeScanner = ({
  minCharMatch,
  debounceWindow,
  terminationChar,
  charsAllowed,
} = BarcodeOptionsDefault): useBarcodeScannerType => {
  const scannerListeners = useRef(new Map<RegExp | string, BarcodeListener[]>())
  const showErrorToast = useErrorToast(true)

  const registerListener = useCallback((regex: RegExp | string, callback: BarcodeListener) => {
    const listeners = scannerListeners.current.get(regex)
    if (listeners && listeners.length > 1) {
      scannerListeners.current.set(regex, [...listeners, callback])
    } else {
      scannerListeners.current.set(regex, [callback])
    }
  }, [])

  const deregisterListener = useCallback((regex: RegExp | string, callback: (barcode: string) => void) => {
    const listeners = scannerListeners.current.get(regex)
    if (listeners && listeners.length > 1) {
      scannerListeners.current.set(
        regex,
        listeners.filter((cb: BarcodeListener) => cb !== callback),
      )
    } else if (listeners && listeners.length <= 1) {
      scannerListeners.current.set(regex, [])
    }
  }, [])

  const scanCode = useRef('')
  const timeout = useRef<number | null>()

  const notifyListeners = useCallback(
    (barcodeInput: string) => {
      scannerListeners.current.forEach((cbs: ((barcode: string) => void)[], key: RegExp | string) => {
        if (cbs && cbs.length < 1) {
          return
        }
        if (typeof key === 'string') {
          if (key === REGEXES.ShippingLabel) {
            // Check if the barcodeInput matches any possible tracking number from our couriers
            let trackingNumbersFormats = findTracking(barcodeInput, couriers)

            if (trackingNumbersFormats.length < 1) {
              const minBarcodeLength = 30
              const barcodeInputLength = barcodeInput.length
              // special case for barcodeInput containing a valid tracking number at the end
              if (barcodeInputLength >= minBarcodeLength) {
                const minCharsForTrackingNumber = 12
                for (let i = minCharsForTrackingNumber; i <= barcodeInputLength; i++) {
                  const startIndex = barcodeInputLength - i
                  const potentialTrackingNumber = barcodeInput.slice(startIndex)
                  trackingNumbersFormats = findTracking(potentialTrackingNumber)
                  if (trackingNumbersFormats.length > 0) {
                    break
                  }
                }
              }
            }

            if (trackingNumbersFormats.length > 0) {
              cbs.forEach(cb => cb(trackingNumbersFormats[0]?.trackingNumber))
            } else {
              showErrorToast(`Invalid shipping label ${barcodeInput} scanned.`)
            }
          }
        } else {
          if (key.test(barcodeInput)) {
            cbs.forEach(cb => cb(barcodeInput))
          }
        }
      })
    },
    [scannerListeners],
  )

  const keydownHandler = useCallback(
    (ev: KeyboardEvent) => {
      clearTimeout(timeout.current as number)

      let char = ev.key && charsAllowed.test(ev.key) && ev.key.length === 1 ? ev.key : ''

      // Barcode scanners transmit GS (Group Sepearator ASCII 29) as CTRL+]
      if (!char && ev.key === ']' && ev.ctrlKey) {
        char = String.fromCharCode(29)
      }
      scanCode.current += char

      if (scanCode.current.length >= minCharMatch && ev.key === terminationChar) {
        notifyListeners(scanCode.current)
        scanCode.current = ''
      }

      timeout.current = window.setTimeout(() => {
        scanCode.current = ''
      }, debounceWindow)
    },
    [debounceWindow, minCharMatch, terminationChar, charsAllowed, notifyListeners],
  )

  useEffect(() => {
    document.addEventListener('keydown', keydownHandler)

    return () => {
      document.removeEventListener('keydown', keydownHandler)
    }
  }, [keydownHandler])

  return {
    registerListener,
    deregisterListener,
  }
}

export default useBarcodeScanner
