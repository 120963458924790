import UserInitialsBadge from 'components/UserInitialsBadge'
import styled from 'styled-components'
import type { QueueItem, UserFirstLastName } from 'types'
import { isOrderItems } from 'utils'
const GetUsersWorkingOnItems = (qItems: QueueItem[]): UserFirstLastName[] => {
  const users: UserFirstLastName[] = []
  if (isOrderItems(qItems)) {
    qItems.forEach(item => {
      if (item.lock?.user) {
        users.push(item.lock.user)
      }
    })
  }

  return users
}

type TruncatedUserListProps = { items: QueueItem[]; maxShown?: number }

const TruncatedUserList = ({ items, maxShown = 2 }: TruncatedUserListProps): JSX.Element => {
  const usersWorkingOnOrders = GetUsersWorkingOnItems(items)

  let visibleUsers: UserFirstLastName[] = []

  if (usersWorkingOnOrders.length) {
    visibleUsers = usersWorkingOnOrders.splice(0, Math.min(usersWorkingOnOrders.length, maxShown))
  }

  const excess = usersWorkingOnOrders.length ? `+${usersWorkingOnOrders.length}` : null

  return (
    <List>
      {visibleUsers.map(user => (
        <UserInitialsBadge key={user._id} user={user} />
      ))}
      <Excess>{excess}</Excess>
    </List>
  )
}

const Excess = styled.p`
  font-size: 0.625em;
`

const List = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`

export default TruncatedUserList
