import type { QueryResult } from '@truepill/tpos-react-router'
import { useQuery } from '@truepill/tpos-react-router'
import { GET_ORDERS_BY_AUTOMATION_COUNT } from 'gql'

export interface CountsByAutomationStatus {
  deferred: number
  mini: number
  max: number
  unassigned: number
}
export interface GetOrdersByAutomationCountResult {
  countByStatus: CountsByAutomationStatus
}

export interface GetOrdersByAutomationCountParams {
  medicationNames?: string[]
  customerIds?: string[]
  selectedLocationIds?: string[]
  locationId?: string
}

const useOrdersCountByAutomationStatus = ({
  params,
  skip,
}: {
  params: GetOrdersByAutomationCountParams
  skip: boolean
}): QueryResult<GetOrdersByAutomationCountResult, GetOrdersByAutomationCountParams> => {
  return useQuery<GetOrdersByAutomationCountResult, GetOrdersByAutomationCountParams>(GET_ORDERS_BY_AUTOMATION_COUNT, {
    fetchPolicy: 'network-only',
    variables: params,
    skip,
  })
}

export default useOrdersCountByAutomationStatus
