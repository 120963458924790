import { Chip } from '@truepill/react-capsule'
import { Link, useLocation } from '@truepill/tpos-react-router'
import { PatientStatus } from '@truepill/tpos-types'
import SpeciesLogo from 'components/SpeciesLogo'
import usePatient from 'hooks/navigation/usePatient'
import moment from 'moment'
import { goToViewPatient } from 'routes'
import styled from 'styled-components'
import { bodyPrimaryColor, primaryColor } from 'styles/styleVariables'
import { ageFormatter, languagesSupported } from 'utils'
import { isHuman } from 'utils/Patient'

type PatientHeadingInfoProps = { patientId: string }

const PatientHeadingInfo = ({ patientId }: PatientHeadingInfoProps): JSX.Element => {
  const { search } = useLocation()
  const { patient } = usePatient({ patientId })
  if (!patient) {
    return <></>
  }
  const dob = patient.dob

  return (
    <>
      <SpeciesLogo isHuman={isHuman(patient)} />
      &nbsp;
      <Link target="_blank" rel="noopener noreferrer" to={goToViewPatient({ patientId, search })}>
        <PatientName data-private>
          {patient.firstName} {patient.lastName}
        </PatientName>
      </Link>
      &nbsp;
      {patient.status === PatientStatus.Inactive && <Chip state="error">Deactivated</Chip>}
      <NormalFontPatientInfo data-private>
        {moment(dob).format('MM/DD/YYYY')}, {ageFormatter(dob)}/{patient.gender.charAt(0).toUpperCase()}{' '}
        {patient.preferences?.language &&
          patient.preferences?.language !== 'en' &&
          `(${languagesSupported.find(({ key }) => patient.preferences?.language === key)?.shortCode})`}
      </NormalFontPatientInfo>
    </>
  )
}

const NormalFontPatientInfo = styled.span`
  color: ${bodyPrimaryColor};
`

const PatientName = styled.span`
  color: ${primaryColor};
  visited {
    color: ${primaryColor};
  }
`

export default PatientHeadingInfo
