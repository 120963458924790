import type { FC } from 'react'
import { Chip } from '@truepill/react-capsule'
import { useQuery } from '@truepill/tpos-react-router'
import type { CustomDurSubject, Medispan } from '@truepill/tpos-types'
import DropDownOption from 'components/DropDownOption'
import { GET_DUR_CONDITIONS } from 'gql'
import useStateWithDebouncedValue from 'hooks/useStateWithDebouncedValue'
import { capsulePrimaryColorDark, darkOrange, lightOrange } from 'styles/styleVariables'
import type { DeepPartial } from 'ts-essentials'
import type { DURSubject } from 'types'
import { stripTypename } from 'utils'
import { isReal, ErrorText, SearchResultContainer, StyledSearchAutoComplete } from './shared'

interface Props {
  hotKey?: string
  idKey?: string
  label?: string
  onChange: (value: Medispan.Condition[]) => void
  onCustomInputChange?: (value: CustomDurSubject[]) => void
  value?: DeepPartial<Medispan.Condition[]>
  customValue?: DeepPartial<CustomDurSubject[]>
}

const DURConditionsAutoComplete: FC<Props> = ({
  hotKey = 'c',
  idKey,
  onCustomInputChange,
  customValue = [],
  onChange,
  value = [],
  label,
}: Props): JSX.Element => {
  const [searchTerm, debouncedSearchTerm, setSearchTerm] = useStateWithDebouncedValue('', 1000)

  const { data, error } = useQuery<{ getConditions: DURSubject[] }>(GET_DUR_CONDITIONS, {
    skip: !debouncedSearchTerm,
    variables: { conditions: debouncedSearchTerm },
  })
  const hasError = !!error
  const suggestions = data?.getConditions.map(stripTypename) ?? []
  return (
    <>
      <StyledSearchAutoComplete
        allowCustomInput
        data-testid="conditions"
        withCapsule
        label={label}
        autoCompleteSuggestions={suggestions}
        hotKey={hotKey}
        placeholder="Search conditions by name…"
        retainFocusAfterSelection
        value={searchTerm}
        idKey={idKey}
        onChange={setSearchTerm}
        suggestionComponent={({
          name,
          highlighted,
          onClick,
          value,
        }: DURSubject & { highlighted: boolean; onClick: () => void }) => (
          <DropDownOption data-testid="suggestion-item" highlighted={highlighted} onClick={onClick}>
            <p>
              {name} {name?.length ? <>({value})</> : value}
            </p>
          </DropDownOption>
        )}
        onCustomInputSelect={(chosen: string) => {
          setSearchTerm('')
          onCustomInputChange?.([...(customValue as CustomDurSubject[]), { name: chosen }])
        }}
        onSelect={chosen => {
          setSearchTerm('')
          onChange(stripTypename([...value, chosen]))
        }}
      >
        {hasError && <ErrorText>Unable to load DUR subjects for conditions</ErrorText>}
      </StyledSearchAutoComplete>
      <SearchResultContainer data-testid="conditions-results">
        {value.filter(isReal).map(({ fromHistory, name, value }, index, arr) => (
          <Chip
            key={`${value}${index}`}
            data-testid={fromHistory ? 'history-item' : 'result-item'}
            state="info"
            size="lg"
            css={{ color: capsulePrimaryColorDark, fontWeight: 'bold', textTransform: 'uppercase' }}
            onDismiss={
              fromHistory ? undefined : () => onChange(arr.filter(({ name: otherName }) => otherName !== name))
            }
          >
            {name}
          </Chip>
        ))}
        {customValue.map((customVal, index, arr) => (
          <Chip
            key={`${customVal?.name}${index}`}
            data-testid={'result-item'}
            state="info"
            size="lg"
            css={{ backgroundColor: lightOrange, color: darkOrange, fontWeight: 'bold', textTransform: 'uppercase' }}
            onDismiss={() =>
              onCustomInputChange?.(arr.filter(other => other?.name !== customVal?.name) as CustomDurSubject[])
            }
          >
            {customVal?.name}
          </Chip>
        ))}
      </SearchResultContainer>
    </>
  )
}
export default DURConditionsAutoComplete
