import { useMutation } from '@truepill/tpos-react-router'
import { SaveButton } from 'components/ActionButton'
import { ActionButtonContainer, CancelButton } from 'components/PageStructure'
import { UPDATE_ORDER_FILL_REQUEST_EDI_REVERSAL_OVERRIDES, UPDATE_COPAY_REQUEST_FILL_EDI_REVERSAL_OVERRIDES } from 'gql'
import moment from 'moment'
import type { QueueItem, EdiReversalOverrides } from 'types'
import { isOrder } from 'utils'

type EdiEditActionButtonsProps = {
  setDisplayEdiReversalOverrides: (val: boolean) => void
  item: QueueItem
  fillId: string
  orderId?: string
  copayRequestId?: string
  ediReversalOverrides: EdiReversalOverrides[]
}

const EdiReversalOverridesActionButtons = ({
  setDisplayEdiReversalOverrides,
  item,
  copayRequestId,
  orderId,
  fillId,
  ediReversalOverrides,
}: EdiEditActionButtonsProps): JSX.Element => {
  const [updateOrderFillRequestEdiReversalOverrides] = useMutation(UPDATE_ORDER_FILL_REQUEST_EDI_REVERSAL_OVERRIDES)
  const [updateCopayRequestFillEdiReversalOverrides] = useMutation(UPDATE_COPAY_REQUEST_FILL_EDI_REVERSAL_OVERRIDES)

  const formatEdiReversalOverrides = (ediReversalOverrides: EdiReversalOverrides[]) => {
    return ediReversalOverrides.map(reversalOverride => ({
      orderInsuranceOptionId: reversalOverride.orderInsuranceOptionId,
      header: {
        serviceProviderId: {
          value: reversalOverride.header?.serviceProviderId?.value || undefined,
          send: reversalOverride.header?.serviceProviderId?.send,
        },
        bin: {
          value: reversalOverride.header?.bin?.value || undefined,
          send: reversalOverride.header?.bin?.send,
        },
        processorControlNumber: {
          value: reversalOverride.header?.processorControlNumber?.value || undefined,
          send: reversalOverride.header?.processorControlNumber?.send,
        },
        dateOfService: {
          value:
            reversalOverride.header?.dateOfService?.value &&
            moment(reversalOverride.header?.dateOfService?.value).format('MM/DD/YYYY'),
          send: reversalOverride.header?.dateOfService?.send,
        },
        softwareVendorId: {
          value: reversalOverride.header?.softwareVendorId?.value || undefined,
          send: reversalOverride.header?.softwareVendorId?.send,
        },
      },
      insurance: {
        cardholderId: {
          value: reversalOverride.insurance?.cardholderId?.value || undefined,
          send: reversalOverride.insurance?.cardholderId?.send,
        },
        groupId: {
          value: reversalOverride.insurance?.groupId?.value || undefined,
          send: reversalOverride.insurance?.groupId?.send,
        },
        medigapId: {
          value: reversalOverride.insurance?.medigapId?.value || undefined,
          send: reversalOverride.insurance?.medigapId?.send,
        },
      },
      claim: {
        prescriptionRefNumber: {
          value: reversalOverride.claim?.prescriptionRefNumber?.value || undefined,
          send: reversalOverride.claim?.prescriptionRefNumber?.send,
        },
        productId: {
          value: reversalOverride.claim?.productId?.value || undefined,
          send: reversalOverride.claim?.productId?.send,
        },
        fillNumber: {
          value: reversalOverride.claim?.fillNumber?.value || undefined,
          send: reversalOverride.claim?.fillNumber?.send,
        },
        otherCoverageCode: {
          value: reversalOverride.claim?.otherCoverageCode?.value || undefined,
          send: reversalOverride.claim?.otherCoverageCode?.send,
        },
        pharmacyServiceType: {
          value: reversalOverride.claim?.pharmacyServiceType?.value || undefined,
          send: reversalOverride.claim?.pharmacyServiceType?.send,
        },
      },
      pricing: {
        incentiveAmountSubmitted: {
          value: reversalOverride.pricing?.incentiveAmountSubmitted?.value || undefined,
          send: reversalOverride.pricing?.incentiveAmountSubmitted?.send,
        },
        grossAmountDue: {
          value: reversalOverride.pricing?.grossAmountDue?.value || undefined,
          send: reversalOverride.pricing?.grossAmountDue?.send,
        },
      },
      coordinationOfBenefits: {
        otherPayerCoverageType: {
          value: reversalOverride.coordinationOfBenefits?.otherPayerCoverageType?.value || undefined,
          send: reversalOverride.coordinationOfBenefits?.otherPayerCoverageType?.send,
        },
      },
    }))
  }
  return (
    <ActionButtonContainer>
      <CancelButton
        label="Cancel"
        onClick={() => {
          setDisplayEdiReversalOverrides(false)
        }}
      />
      <SaveButton
        label="Update"
        onClick={() => {
          const options = {
            variables: {
              orderId,
              copayRequestId,
              fillId,
              ediReversalOverrides: formatEdiReversalOverrides(ediReversalOverrides),
            },
          }
          if (isOrder(item)) {
            options.variables.orderId = item._id
            updateOrderFillRequestEdiReversalOverrides(options)
          } else {
            options.variables.copayRequestId = item._id
            updateCopayRequestFillEdiReversalOverrides(options)
          }

          setDisplayEdiReversalOverrides(false)
        }}
      />
    </ActionButtonContainer>
  )
}

export default EdiReversalOverridesActionButtons
