import { rejectCodes } from './definitions/rejectCodes'

export interface RejectCode {
  _id: string
  name: string
}

export const getRejectCodes = (codes: string[]): Array<RejectCode> => {
  return codes?.map(code => {
    const description = rejectCodes[code]?.text
    return {
      name: `${code}${description ? ` - ${description}` : ''}`,
      _id: code,
    } as RejectCode
  })
}
