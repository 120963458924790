import type { NDCSubstitution, NdcFullInfoPackage } from '@truepill/tpos-types'

export enum CoreToTposRxCheckError {
  REQUESTED_NDC_NOT_FOUND = 'Requested NDC not found',
  REQUESTED_DAW_INVALID = 'Requested DAW is invalid',
  REQUESTED_DAW_CONFLICTS_WITH_PRESCRIBED_MED = 'Requested DAW conflicts with prescribed medication',
  MEDICATION_MISMATCH = 'Medication Mismatch',
}

export const handleCoreToTposDawCheck = (
  requestedDaw: number,
  tposDaw: number,
  prescriptionNdcFullInfoPackage: NdcFullInfoPackage,
  shouldRejectConflictingDawNdc = false,
): { error?: string; daw?: number } => {
  if (requestedDaw === tposDaw) {
    return {}
  }

  if (requestedDaw !== 0 && requestedDaw !== 2) {
    return { error: CoreToTposRxCheckError.REQUESTED_DAW_INVALID }
  }

  const brandPrescription =
    prescriptionNdcFullInfoPackage.nameTypeCode === 'T' &&
    ['M', 'N', 'O'].includes(prescriptionNdcFullInfoPackage.multiSourceCode || '')

  if (shouldRejectConflictingDawNdc) {
    if (requestedDaw === 2 && !brandPrescription) {
      return { error: CoreToTposRxCheckError.REQUESTED_DAW_CONFLICTS_WITH_PRESCRIBED_MED }
    }
  }

  return { daw: requestedDaw }
}

export const handleCoreToTposRequestedNdcCheck = (
  requestedNdc: string,
  prescriptionDaw: number,
  substitions: NDCSubstitution[],
  requestedNdcFullInfoPackage: NdcFullInfoPackage,
): { error?: string; useRequestedNdc?: boolean } => {
  const therapeuticEquivalent = substitions.map(i => i.ndc).includes(requestedNdc)
  if (!therapeuticEquivalent) {
    return {
      error: CoreToTposRxCheckError.MEDICATION_MISMATCH,
      useRequestedNdc: false,
    }
  }

  const isRequestedNdcBrand =
    requestedNdcFullInfoPackage.nameTypeCode === 'T' &&
    ['M', 'N', 'O'].includes(requestedNdcFullInfoPackage.multiSourceCode || '')
  if (isRequestedNdcBrand && prescriptionDaw === 0) {
    return { useRequestedNdc: false }
  }

  return { useRequestedNdc: true }
}
