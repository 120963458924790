import { useEffect, useState } from 'react'
import Select from 'components/Select'
import { usePlusClient } from 'providers/VisionRouter'
import styled from 'styled-components'
import { primaryBackgroundColor, bodyPrimaryColor, borderColor } from 'styles/styleVariables'

const StyledSelectButton = styled(Select)`
  border-color: ${borderColor};
  background-color: ${primaryBackgroundColor};
  color: ${bodyPrimaryColor};
  > svg {
    fill: ${bodyPrimaryColor};
  }
  > div {
    > svg {
      fill: ${bodyPrimaryColor};
    }
  }
`

const options = [
  { label: 'Newest to oldest', value: 'desc' },
  { label: 'Oldest to newest', value: 'asc' },
]

export default function SortDropdown(): JSX.Element {
  const { routeToMergedQuery, currentLocation } = usePlusClient()

  const [value, setValue] = useState(options[0])

  useEffect(() => {
    if (currentLocation.queryMap?.sort && value && value !== currentLocation.queryMap?.sort) {
      const current = options.find(option => option.value === currentLocation.queryMap?.sort)
      if (current) {
        setValue(current)
      }
    }
  }, [currentLocation.queryMap?.sort, value])

  return (
    <StyledSelectButton
      options={options}
      value={value}
      onChange={([option]) => {
        routeToMergedQuery({ sort: option.value })
      }}
      showActive={true}
      disableClear={true}
    />
  )
}
