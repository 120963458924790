import { useContext } from 'react'
import type { PaneType, PaneProviderProps } from 'providers/PaneProvider'
import { PaneContext } from 'providers/PaneProvider'

type usePaneType = {
  paneType: PaneType
  paneVisible: boolean
  firstLoad: boolean
  togglePane: () => void
  showPane: () => void
  hidePane: () => void
}

const usePane = (inputPaneType: PaneType): usePaneType => {
  const { paneVisible, firstLoad, togglePane, hidePane, showPane, paneType } = useContext(
    PaneContext,
  ) as PaneProviderProps

  return {
    paneType,
    paneVisible: paneVisible && paneType === inputPaneType,
    firstLoad,
    togglePane: () => {
      togglePane(inputPaneType)
    },
    showPane: () => {
      showPane(inputPaneType)
    },
    hidePane: () => {
      hidePane(inputPaneType)
    },
  }
}

export default usePane
