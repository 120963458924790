import type { NdcPackage, NdcSubstitutionPackage } from 'types'

export const camelCaseToHumanReadable = (text = ''): string => {
  const result = text.replace(/([A-Z0-9])/g, ' $1')
  return result.charAt(0).toUpperCase() + result.slice(1)
}

export const removeUnderscore = (text: string): string => {
  return text.replace(/_/g, ' ')
}

export const snakeCaseToHumanReadable = (text: string): string => {
  const camel = text.replace(/([-_]\w)/g, g => g[1].toUpperCase())
  return camelCaseToHumanReadable(camel)
}

export const removeCamelCase = (text: string): string => {
  return text.replace(/([A-Z]+)/g, ' $1').replace(/([A-Z][a-z])/g, ' $1')
}

export const capitalize = (text: string): string => {
  return text[0].toUpperCase() + text.slice(1, text.length)
}

export const checkIfProvided = (value?: string | number | null, fallback?: string): string | number => {
  return value || (fallback ? fallback : 'None provided')
}

export const checkIfNull = (value?: string | number | null): string | number => {
  return value ?? 'null'
}

export const firstLetterUpper = (text: string): string => {
  const lowerCaseText = text.toLowerCase()
  return lowerCaseText.replace(/\b[a-z]/g, x => x.toUpperCase())
}

export const minTwoDigits = (digit: number): string => {
  return (digit < 10 ? '0' : '') + digit
}

export function removeSlashOne(text?: string): string | undefined {
  return text?.split('/')[0]
}

export const ordinal = (i: number): string => {
  const j = i % 10
  const k = i % 100
  if (j === 1 && k !== 11) {
    return i + 'st'
  }
  if (j === 2 && k !== 12) {
    return i + 'nd'
  }
  if (j === 3 && k !== 13) {
    return i + 'rd'
  }
  return i + 'th'
}

// If we need to go beyond 10, fall back to dynamic ones.
const StaticOrdinals: { [key: string]: string } = {
  '1': 'First',
  '2': 'Second',
  '3': 'Third',
  '4': 'Fourth',
  '5': 'Fifth',
  '6': 'Sixth',
  '7': 'Seventh',
  '8': 'Eighth',
  '9': 'Nineth',
  '10': 'Tenth',
}

export const wordyOrdinal = (i: number): string => {
  if (StaticOrdinals[`${i}`]) {
    return StaticOrdinals[`${i}`]
  }
  return ordinal(i)
}

export const escapeRegExp = (text: string): string => {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\\s]/g, '\\$&')
}

export const usdFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

export const addStateToLocationName = (name: string, state?: string): string => {
  return `${removeUnderscore(name)}${state ? ', ' + state : ''}`
}

export const nameFromNdcPackage = (ndcPackage: NdcPackage): string => {
  const { ndcProduct } = ndcPackage
  const strengthUnit = ndcProduct?.strengthUnits[0]
  const strength = removeSlashOne(strengthUnit)
  const MedicationName = ndcProduct?.name

  return `${MedicationName} ${strength}`
}

export const nameFromNdcSubstitution = (ndcInfo: NdcSubstitutionPackage): string => {
  const { drugName, strength, strengthUnitOfMeasure } = ndcInfo
  return `${drugName} ${strength} ${strengthUnitOfMeasure}`
}

export const descriptionFromNdcSubstitution = (ndcInfo: NdcSubstitutionPackage): string => {
  const { packageSize, dosageForm, packageQuantity, packageDescriptionCode } = ndcInfo
  return `${packageSize} ${dosageForm} in ${packageQuantity} ${packageDescriptionCode}`
}

export const removeNewLineAndTrim = (text?: string): string => {
  return text ? text.replace(/\s\s+/g, ' ').trim() : ''
}
