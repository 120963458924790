import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg'
import IconWrapper from 'components/IconWrapper'
import styled, { css } from 'styled-components'
import {
  bodySecondaryColor,
  capsuleDarkGreyColor,
  contrastBackgroundColor,
  primaryBackgroundColor,
} from 'styles/styleVariables'

type ButtonProps = {
  className?: string
  clickCallback: () => void
  contrast?: boolean
  size?: string
}

const CloseButton = ({ className, clickCallback, contrast, size }: ButtonProps): JSX.Element => {
  return (
    <StyledCloseButton
      data-testid="close"
      type="button"
      onClick={clickCallback}
      className={className}
      contrast={contrast}
    >
      <IconWrapper>
        <CloseIcon fill={capsuleDarkGreyColor} height={size} width={size} />
      </IconWrapper>
    </StyledCloseButton>
  )
}

export const RemoveButton = ({ className, clickCallback, contrast }: ButtonProps): JSX.Element => {
  return (
    <StyledCloseButton data-testid="remove" onClick={clickCallback} className={className} contrast={contrast}>
      <MinusIcon fill={bodySecondaryColor} />
    </StyledCloseButton>
  )
}

const ContrastBackground = css`
  background-color: ${primaryBackgroundColor};
`

const NormalBackground = css`
  background-color: ${contrastBackgroundColor};
`

const StyledCloseButton = styled.button<{ contrast?: boolean }>`
  border: none;
  position: absolute;
  right: 0.1rem;
  top: 0.1rem;
  ${({ contrast }) => (contrast ? ContrastBackground : NormalBackground)}
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  > span {
    margin-top: 0.1em;
  }
`

export default CloseButton
