import AddressEntry from 'components/AddressEntry'
import LoadingSpinner from 'components/Loading'
import { RXImageCell, RXListRow, ListRowLabel, ListRowValue, PaddingBlock } from 'components/RXTable'
import { useFormData } from 'hooks/useFormData'
import styled from 'styled-components'
import type { EscriptPrescriber, EscriptSupervisor, Prescription } from 'types'
import { checkIfProvided, formatPhoneNumber, getRxImagePrescriber } from 'utils'

type ReviewPrescriberProps = {
  prescription: Prescription
}

const ReviewPrescriber = ({ prescription }: ReviewPrescriberProps): JSX.Element => {
  const {
    state: { formData },
  } = useFormData()
  const prescriptionValues = formData.prescription as Prescription
  const escript = prescription?.escript
  const escriptPrescriber = escript?.prescriber as EscriptPrescriber
  const escriptSupervisor = escript?.supervisor as EscriptSupervisor
  const { prescriber: prescriberValues } = prescriptionValues

  const directTransferPrescriber = prescription?.directTransfer?.prescriber
  const rxImagePrescriber = getRxImagePrescriber(escriptPrescriber, directTransferPrescriber)

  if (!prescriberValues?._id) {
    return (
      <>
        <RXListRow noStripe>
          <RXImageCell closeOffBottomBorder>
            <PrescriberLoadingPadding>
              <LoadingSpinnerContainer>
                <LoadingSpinner />
              </LoadingSpinnerContainer>
            </PrescriberLoadingPadding>
          </RXImageCell>
        </RXListRow>
      </>
    )
  }

  return (
    <ul>
      <RXListRow>
        <RXImageCell>
          <PaddingBlock />
        </RXImageCell>
      </RXListRow>
      <ul>
        {rxImagePrescriber && (
          <>
            <RXListRow>
              <RXImageCell>
                <ListRowLabel>Prescriber:</ListRowLabel>
                <ListRowValue>
                  {checkIfProvided(rxImagePrescriber.firstName)} {rxImagePrescriber.lastName}
                  {rxImagePrescriber?.suffix ? `, ${rxImagePrescriber.suffix}` : ''}
                </ListRowValue>
              </RXImageCell>
            </RXListRow>
            <RXListRow>
              <RXImageCell>
                <ListRowLabel>NPI:</ListRowLabel>
                <ListRowValue>{checkIfProvided(rxImagePrescriber.npi)}</ListRowValue>
              </RXImageCell>
            </RXListRow>
            {rxImagePrescriber.stateLicense && (
              <RXListRow>
                <RXImageCell>
                  <ListRowLabel>License #:</ListRowLabel>
                  <ListRowValue>{checkIfProvided(rxImagePrescriber.stateLicense)}</ListRowValue>
                </RXImageCell>
              </RXListRow>
            )}
            <RXListRow>
              <RXImageCell>
                <ListRowLabel>Supervising prescriber:</ListRowLabel>
                <ListRowValue>
                  {checkIfProvided(escriptSupervisor?.firstName)} {escriptSupervisor?.lastName}
                </ListRowValue>
              </RXImageCell>
            </RXListRow>
            <RXListRow>
              <RXImageCell>
                <ListRowLabel>Address:</ListRowLabel>
                <AddressEntry address={rxImagePrescriber.address} />
              </RXImageCell>
            </RXListRow>
            <RXListRow>
              <RXImageCell>
                <ListRowLabel>Phone:</ListRowLabel>
                <ListRowValue>
                  {checkIfProvided(formatPhoneNumber(rxImagePrescriber.communicationNumbers?.phone?.number))}
                </ListRowValue>
              </RXImageCell>
            </RXListRow>
            <RXListRow>
              <RXImageCell>
                <ListRowLabel>Customer:</ListRowLabel>
                <ListRowValue>{checkIfProvided(escript?.clinicName)}</ListRowValue>
              </RXImageCell>
            </RXListRow>
            <RXListRow>
              <RXImageCell closeOffBottomBorder>
                <ListRowLabel>DEA:</ListRowLabel>
                <ListRowValue>{checkIfProvided(rxImagePrescriber.deaNumber)}</ListRowValue>
              </RXImageCell>
            </RXListRow>
          </>
        )}
        {!rxImagePrescriber && (
          <RXListRow>
            <RXImageCell closeOffBottomBorder></RXImageCell>
          </RXListRow>
        )}
      </ul>
      <PaddingBlock />
    </ul>
  )
}

export default ReviewPrescriber

const LoadingSpinnerContainer = styled.div`
  display: flex;
  padding-top: 0.8rem;
  justify-content: center;
  svg {
    height: 2rem;
  }
`

const PrescriberLoadingPadding = styled.div`
  height: 18rem;
  grid-column: 1 / span 2;
`
