import { useState } from 'react'
import { useQuery } from '@truepill/tpos-react-router'
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg'
import ActionButton from 'components/ActionButton'
import CloseButton, { RemoveButton } from 'components/CloseButton'
import { FormItem, FormItems, WideTextInput, SearchSelectedCard } from 'components/ColumnForm'
import DropDownOption from 'components/DropDownOption'
import IconWrapper from 'components/IconWrapper'
import { ToggleButton, TransparentHeader } from 'components/PageStructure'
import { StyledSelect } from 'components/RXTable'
import SearchAutoComplete from 'components/SearchAutoComplete'
import { SEARCH_INSURANCE_PROVIDER } from 'gql'
import { Box, CheckBox } from 'grommet'
import { relationshipCodes } from 'pages/fulfillment/orders/rx/screens/claims/ReviewSections/ReviewPayer/ServiceCodes'
import { adjust, remove } from 'ramda'
import styled from 'styled-components'
import {
  bodySecondaryColor,
  contrastBackgroundColor,
  primaryBackgroundColor,
  primaryColor,
} from 'styles/styleVariables'
import type { InsuranceInput, ManualInsuranceProviderInput, Insurance } from 'types'

const deleteFromArray = (insurances: InsuranceInput[]) => (index: number) => remove(index, 1, insurances)

type InsuranceColumProps = {
  insurances: InsuranceInput[]
  setInsurances: (insurances: InsuranceInput[]) => void
}

const InsuranceColumn = ({ insurances, setInsurances }: InsuranceColumProps): JSX.Element => {
  const deleteInsurance = deleteFromArray(insurances)
  const [activeTab, setActiveTab] = useState<number>(0)
  const [manualEntryInsurance, setManualEntryInsurance] = useState<ManualInsuranceProviderInput>({
    bin: '',
    pcn: '',
    name: '',
  })

  return (
    <ul data-test-column="insurance">
      {insurances.length === 0 ? (
        <TransparentHeader>Coverage</TransparentHeader>
      ) : (
        insurances.map(
          ({ bin, group, pcn, personCode, cardholderId, relationship, name, governmentSponsoredPlan }, index) => (
            <li key={index}>
              <TransparentHeader>
                <Box direction="row">
                  <Box flex={{ grow: 1 }}>Coverage #{index + 1}</Box>
                  <StyledRemoveButton clickCallback={() => setInsurances([...deleteInsurance(index)])} />
                </Box>
              </TransparentHeader>
              <FormItems key={index}>
                <FormItem>
                  <Box direction="row" background={contrastBackgroundColor}>
                    <SearchBinPcn active={activeTab === 0} label="Search BIN / PCN" onClick={() => setActiveTab(0)} />
                    <ToggleButton
                      active={activeTab === 1}
                      label="Manually  BIN / PCN"
                      onClick={() => setActiveTab(1)}
                      data-testid="insurance-manually"
                    />
                  </Box>
                  <Box pad={{ top: 'small' }}>
                    {bin ? (
                      <InsuranceCard
                        bin={bin}
                        pcn={pcn || ''}
                        name={name || ''}
                        closeCallback={() =>
                          setInsurances(
                            adjust(
                              index,
                              ins => ({ ...ins, pcn: '', bin: '', group: '', cardholderId: '', personCode: '' }),
                              insurances,
                            ),
                          )
                        }
                      />
                    ) : activeTab === 0 ? (
                      <FormItem>
                        <InsuranceSearchOptions
                          onSelect={selectedInsurance => {
                            const { bin, pcn, name } = selectedInsurance

                            insurances[index] = { bin, pcn, name, cardholderId, governmentSponsoredPlan }
                            setInsurances([...insurances])
                          }}
                        />
                      </FormItem>
                    ) : (
                      <FormItem>
                        <Box style={{ flexDirection: 'row' }}>
                          <Box pad={{ right: 'small' }}>
                            <p>RxBIN</p>
                            <WideTextInput
                              data-testid="rx-bin"
                              value={manualEntryInsurance.bin}
                              onChange={({ currentTarget: { value: rxBIN } }) =>
                                setManualEntryInsurance({ ...manualEntryInsurance, bin: rxBIN })
                              }
                            />
                          </Box>
                          <Box>
                            <p>RxPCN (optional)</p>
                            <WideTextInput
                              data-testid="rx-pcn"
                              value={manualEntryInsurance.pcn}
                              onChange={({ currentTarget: { value: rxPCN } }) =>
                                setManualEntryInsurance({ ...manualEntryInsurance, pcn: rxPCN })
                              }
                            />
                          </Box>
                        </Box>
                        <Box>
                          <p>Payer name (optional)</p>
                          <WideTextInput
                            data-testid="payer-name"
                            value={manualEntryInsurance.name}
                            onChange={({ currentTarget: { value: name } }) =>
                              setManualEntryInsurance({ ...manualEntryInsurance, name })
                            }
                          />
                        </Box>

                        <SavePayerButton
                          label="Save Payer"
                          data-testid="save-payer"
                          onClick={() => {
                            setInsurances(adjust(index, ins => ({ ...ins, ...manualEntryInsurance }), insurances))
                          }}
                        />
                      </FormItem>
                    )}
                  </Box>
                  <Box>
                    <CheckBox
                      data-testid="govsponsored"
                      checked={governmentSponsoredPlan}
                      label="Government sponsored plan"
                      onChange={({ target: { checked: governmentSponsoredPlan } }) =>
                        setInsurances(adjust(index, ins => ({ ...ins, governmentSponsoredPlan }), insurances))
                      }
                    />
                  </Box>
                </FormItem>
                <FormItem>
                  <p>Group # (RxGroup)</p>
                  <WideTextInput
                    data-testid="group"
                    value={group}
                    onChange={({ currentTarget: { value: group } }) =>
                      setInsurances(adjust(index, ins => ({ ...ins, group }), insurances))
                    }
                  />
                </FormItem>
                <FormItem>
                  <p>Cardholder ID</p>
                  <WideTextInput
                    data-testid="cardholder"
                    value={cardholderId}
                    onChange={({ currentTarget: { value: cardholderId } }) =>
                      setInsurances(adjust(index, ins => ({ ...ins, cardholderId }), insurances))
                    }
                  />
                </FormItem>
                <FormItem style={{ flexDirection: 'row' }}>
                  <Box>
                    <p>Person Code</p>
                    <PersonCodeInput
                      data-testid="personcode"
                      value={personCode}
                      onChange={({ currentTarget: { value: personCode } }) =>
                        setInsurances(adjust(index, ins => ({ ...ins, personCode }), insurances))
                      }
                    />
                  </Box>
                  <Box flex="grow">
                    <p>Relationship</p>
                    <PayerStyledSelect
                      data-testid="relationship"
                      disableClear={true}
                      multiple={false}
                      placeholder={'Relationship'}
                      options={relationshipCodes}
                      value={relationship}
                      onChange={([option]) => {
                        const value = option ? option.value : ''
                        setInsurances(adjust(index, ins => ({ ...ins, relationship: value }), insurances))
                      }}
                    />
                  </Box>
                </FormItem>
              </FormItems>
            </li>
          ),
        )
      )}

      {insurances.length < 4 && (
        <InsuranceBox>
          <StyledActionButton
            data-testid="add-insurance"
            icon={
              <IconWrapper>
                <PlusIcon fill="black" />
              </IconWrapper>
            }
            hotKey="i"
            label="Add another insurance"
            onClick={() =>
              setInsurances([
                ...insurances,
                { pcn: '', bin: '', group: '', cardholderId: '', personCode: '', governmentSponsoredPlan: false },
              ])
            }
          />
        </InsuranceBox>
      )}
    </ul>
  )
}

type InsuranceCardProps = { bin: string; name: string; pcn: string; closeCallback: () => void }

const InsuranceCard = ({ bin, name, pcn, closeCallback }: InsuranceCardProps): JSX.Element => {
  return (
    <SearchSelectedCard>
      <CloseButton clickCallback={closeCallback} contrast />
      <h4>RxBIN: {bin}</h4>
      <ul>
        <li>
          <strong>RxPCN:</strong> {pcn}
        </li>
        <li>
          <strong>Payer:</strong> {name ? name : 'Name not provided'}
        </li>
      </ul>
    </SearchSelectedCard>
  )
}

const StyledRemoveButton = styled(RemoveButton)`
  position: inherit;
  height: 1.5rem;
  width: 1.5rem;
`
const PersonCodeInput = styled(WideTextInput)`
  width: 9rem;
`

type InsuranceSearchOptionsProps = { onSelect: (insurance: Insurance) => void }

const InsuranceSearchOptions = ({ onSelect }: InsuranceSearchOptionsProps): JSX.Element => {
  const [searchTerm, setSearchTerm] = useState('')
  const { data, error } = useQuery<{
    searchInsuranceProvider: {
      _id: string
      bin: string
      name: string
      importDate?: string
      marketCoverage?: string
      networkId?: string
      networkURL?: string
      sourceName?: string
      state?: string
      dentalOnly?: boolean
    }[]
  }>(SEARCH_INSURANCE_PROVIDER, { variables: { bin: searchTerm } })

  return error ? (
    <div>Error while loading insurance providers</div>
  ) : (
    <SearchAutoComplete
      data-testid="search-insurance"
      iconColor={bodySecondaryColor}
      autoCompleteSuggestions={data?.searchInsuranceProvider ?? []}
      value={searchTerm}
      suggestionComponent={optionProps => <AutoCompleteOption {...optionProps} />}
      onChange={setSearchTerm}
      placeholder={'Search insurance by RxBIN and/or RxPCN...'}
      onSelect={onSelect}
    />
  )
}

const AutoCompleteOption = (props: any) => {
  if (props.error) {
    return (
      <DropDownOption {...props}>
        <p>{props.error}</p>
      </DropDownOption>
    )
  }

  if (props.loading) {
    return (
      <DropDownOption {...props}>
        <p>Loading...</p>
      </DropDownOption>
    )
  }

  const { bin, pcn, name, onClick } = props

  return (
    <DropDownOption onClick={onClick}>
      <p>{name}</p>
      <p>
        RxBIN: {bin}, RxPCN: {pcn}
      </p>
    </DropDownOption>
  )
}

const InsuranceBox = styled(Box)`
  margin-top: 3rem;
  div {
    left: 3.5rem;
  }
`
const StyledActionButton = styled(ActionButton)`
  margin-left: 0rem;
  background-color: ${primaryBackgroundColor};
`
const SavePayerButton = styled(ActionButton)`
  text-transform: capitalize;
  margin-left: 0rem;
  height: 2.5rem;
  background-color: ${primaryColor};
  color: ${primaryBackgroundColor};
  border: none;
`
const PayerStyledSelect = styled(StyledSelect)`
  max-height: 2.5rem;
  font-weight: 400;
`

const SearchBinPcn = styled(ToggleButton)<{ active?: boolean }>`
  ${({ active }) =>
    active &&
    `
  background: ${primaryColor};
  border-radius: 0em;
  border-top-left-radius: 0.25em;
  border-bottom-left-radius: 0.25em;
  `}
`

export default InsuranceColumn
