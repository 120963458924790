import type { FC } from 'react'
import { useState } from 'react'
import { Button, Select } from '@truepill/react-capsule'
import CustomizedTextField from 'components/CustomizedTextField'
import { ButtonsContainer } from 'components/Modal'
import { Eye, EyeOff, X } from 'react-feather'
import styled from 'styled-components'
import type { InsuranceOverrideCob } from 'types'
import { applyToOptions } from './COBStyleCommon'

type ValueProps = boolean | string[] | string
type OtherPayerRejectCodesProps = {
  overrideCOB: Partial<InsuranceOverrideCob>
  handleChange: (name: keyof InsuranceOverrideCob, key: string, value?: ValueProps, index?: number) => void
}

const OtherPayerRejectCodes: FC<OtherPayerRejectCodesProps> = ({ overrideCOB, handleChange }): JSX.Element => {
  const otherPayerRejectCodes = overrideCOB?.otherPayerRejectCode ?? { value: [''], send: true, applyTo: '' }
  const otherPayerRejectCodeValues = otherPayerRejectCodes?.value?.length ? otherPayerRejectCodes?.value : ['']

  const otherPayerRejectCodesSendStatus = otherPayerRejectCodes?.send
  const [sendFlag, setSendFlag] = useState(
    otherPayerRejectCodesSendStatus === undefined || !!otherPayerRejectCodesSendStatus,
  )
  const toggleIsDisabled = () => {
    handleChange('otherPayerRejectCode', 'send', !otherPayerRejectCodesSendStatus)
    setSendFlag(!otherPayerRejectCodesSendStatus)
  }

  const inputStyleSelect = {
    margin: '0',
  }

  const inputStyle = {
    margin: '8px 0 0 0',
  }

  return (
    <PayerFormElement>
      <PayerFormElementLabel>Other payer reject code (6E)</PayerFormElementLabel>
      {otherPayerRejectCodeValues.map((payerRejectCode, index) => (
        <Payer3Columns key={`other-payer-reject${index}`}>
          <CustomizedTextField
            placeholder="Other Payer Reject Code"
            value={payerRejectCode || otherPayerRejectCodeValues?.[index]}
            disabled={!sendFlag}
            size="sm"
            onChange={e => {
              const otherRejectCodeValue = otherPayerRejectCodeValues ? [...otherPayerRejectCodeValues] : []
              otherRejectCodeValue[index] = e.target.value
              handleChange('otherPayerRejectCode', 'value', otherRejectCodeValue)
            }}
            style={inputStyle}
          />
          {index === 0 ? (
            <>
              {sendFlag ? (
                <Select
                  id={`#StyledSelect-d`}
                  options={applyToOptions}
                  label=""
                  required
                  selectedKey="label"
                  placeholder=""
                  onChange={option => {
                    const value = option?.value
                    handleChange('otherPayerRejectCode', 'applyTo', value)
                  }}
                  value={applyToOptions.find(i => i.value === otherPayerRejectCodes?.applyTo)}
                  isOptionDisabled={option => !sendFlag}
                  css={inputStyleSelect}
                  variant="small"
                />
              ) : (
                <CustomizedTextField
                  id={`#StyledSelect-d`}
                  placeholder=""
                  value={applyToOptions.find(i => i.value === otherPayerRejectCodes?.applyTo)?.label}
                  disabled={true}
                  style={inputStyle}
                  size="sm"
                />
              )}
              <Button
                size="xs"
                style={{
                  borderRadius: '100%',
                  backgroundColor: 'rgb(238, 238, 238)',
                  borderColor: 'rgb(238, 238, 238)',
                  height: '3.5rem',
                  width: '3.5rem',
                  verticalAlign: 'center',
                  color: 'var(--cap-colors-primary-700)',
                  textAlign: 'center',
                  margin: '2px 0 0 0.25rem',
                }}
                onClick={toggleIsDisabled}
              >
                {sendFlag ? <Eye aria-hidden /> : <EyeOff aria-hidden />}
              </Button>
            </>
          ) : (
            <>
              <Button
                size="xs"
                style={{
                  borderRadius: '100%',
                  backgroundColor: 'rgb(238, 238, 238)',
                  borderColor: 'rgb(238, 238, 238)',
                  height: '3.5rem',
                  width: '3.5rem',
                  verticalAlign: 'center',
                  color: 'var(--cap-colors-primary-700)',
                  textAlign: 'center',
                  margin: '2px 0 0 0',
                }}
                disabled={!sendFlag}
                onClick={() => {
                  const otherRejectCodeValue = otherPayerRejectCodes?.value
                  if (otherRejectCodeValue) otherRejectCodeValue.splice(index, 1)
                  handleChange('otherPayerRejectCode', 'value', otherRejectCodeValue)
                }}
              >
                <X aria-hidden />
              </Button>
            </>
          )}
        </Payer3Columns>
      ))}
      <ButtonsContainerRight>
        <Button
          variant="primary-outline"
          style={{ margin: '8px 0 0 0' }}
          disabled={!sendFlag}
          size="sm"
          onClick={() => {
            const otherRejectCodeValue = otherPayerRejectCodeValues
            otherRejectCodeValue.push('')
            handleChange('otherPayerRejectCode', 'value', otherRejectCodeValue)
          }}
        >
          Add another payer reject code
        </Button>
      </ButtonsContainerRight>
    </PayerFormElement>
  )
}

const PayerColumns = styled.div`
  width: 100%;
  display: grid;
  align-items: flex-start;
  marginft: 0 0 0 0;
  grid-gap: 0.25rem;
  vertical-align: baseline;
`

const Payer3Columns = styled(PayerColumns)`
  grid-template-columns: [col1] 47% [col2] 45% [col3] 8%;
`

export const COBRejectCodeContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-rows: [content] 100%;
  grid-template-columns: [value] 13% [value] 100% [value2] 25% [value2] 13%;
`

export default OtherPayerRejectCodes

const PayerFormElement = styled.div``

const PayerFormElementLabel = styled.div`
  font-weight: 500;
  font-size: 0.875rem;
  display: block;
  width: 100%;
  margin: 12px 0 0 0;
`
const ButtonsContainerRight = styled(ButtonsContainer)`
  padding-bottom: 0;
  margin-top: 0px;
`
