import type { Ref } from 'react'
import { useState } from 'react'
import { useMutation } from '@truepill/tpos-react-router'
import { ReactComponent as RerouteIcon } from 'assets/icons/route.svg'
import { SaveButton } from 'components/ActionButton'
import IconWrapper from 'components/IconWrapper'
import LoadingSpinner from 'components/Loading'
import LocationSelectorDropDown from 'components/LocationSelectorDropDown/Legacy'
import { ButtonsContainer, InputContainer, ModalHeader, ModalWrapper } from 'components/Modal'
import { CancelButton } from 'components/PageStructure'
import { REROUTE_ORDER, REROUTE_COPAY } from 'gql'
import useErrorToast from 'hooks/toast/useErrorToast'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import useAutoFocus from 'hooks/useAutoFocus'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import { useTPCacheContext } from 'providers/TPCacheProvider'
import styled from 'styled-components'
import { bodyPrimaryColor } from 'styles/styleVariables'
import type { Order, CopayRequest } from 'types'
import { isOrder } from 'utils'

type RerouteModalType = { item: Order | CopayRequest; confirmationCallback?: () => void }

const RerouteModal = ({ item, confirmationCallback }: RerouteModalType): JSX.Element => {
  const { locations } = useTPCacheContext()
  const { dismissModal } = useModalContext()
  const [itemLocationId, setItemLocationId] = useState<string>(item.locationId)
  const [rerouteOrder, { loading }] = useMutation(REROUTE_ORDER, {
    refetchQueries: ['getBasicOrder'],
  })
  const [rerouteCopay] = useMutation(REROUTE_COPAY, {
    refetchQueries: ['getBasicCopay'],
  })
  const showErrorToast = useErrorToast()
  const showSuccessToast = useSuccessToast()
  const itemType = isOrder(item) ? 'order' : 'copay'

  const autoFocusRef = useAutoFocus()

  const locationHasChanged = itemLocationId !== item.locationId

  if (loading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
      </LoadingContainer>
    )
  }

  return (
    <ModalWrapper id="RerouteModal">
      <ModalHeader>
        <IconWrapper>
          <RerouteIcon fill={bodyPrimaryColor} />
        </IconWrapper>
        <h2>Reroute {itemType}</h2>
      </ModalHeader>
      <StyledInputContainer>
        <LocationSelectorDropDown
          modal
          ref={autoFocusRef as Ref<HTMLButtonElement>}
          value={itemLocationId}
          onChange={locationId => {
            if (locationId !== undefined) {
              setItemLocationId(locationId)
            }
          }}
        />
      </StyledInputContainer>
      <ButtonsContainer>
        <CancelButton label="Cancel" onClick={dismissModal} />
        <SaveButton
          disabled={!locationHasChanged}
          label="Reroute"
          isModal
          onClick={async () => {
            if (!locationHasChanged) {
              return
            }

            try {
              if (isOrder(item)) {
                await rerouteOrder({
                  variables: {
                    orderId: item._id,
                    locationId: itemLocationId,
                  },
                })
              } else {
                await rerouteCopay({
                  variables: {
                    copayRequestId: item._id,
                    locationId: itemLocationId,
                  },
                })
              }
              showSuccessToast(`Rerouted ${itemType} to ${locations?.find(({ _id }) => _id === itemLocationId)?.name}`)
              confirmationCallback && confirmationCallback()
            } catch (e) {
              showErrorToast(`Failed to reroute ${itemType}: ${e.message}`)
            }
            dismissModal()
          }}
        />
      </ButtonsContainer>
    </ModalWrapper>
  )
}

const StyledInputContainer = styled(InputContainer)`
  > button:first-child {
    height: 2.5rem;
  }
`

const LoadingContainer = styled.div`
  width: 50px;
  margin: auto;
`

export default RerouteModal
