import type { FC } from 'react'
import { useMutation } from '@truepill/tpos-react-router'
import { AutobotType, UserRoles, autobotMap } from '@truepill/tpos-types'
import { ReactComponent as LoadingDots } from 'assets/icons/loading_dots.svg'
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg'
import { ReactComponent as PrintIcon } from 'assets/icons/print.svg'
import { ReactComponent as ReverseIcon } from 'assets/icons/reverse.svg'
import { ReactComponent as RouteIcon } from 'assets/icons/route.svg'
import ActionButton from 'components/ActionButton'
import AuthLimited from 'components/AuthLimited'
import IconWrapper from 'components/IconWrapper'
import OrderSortDropdown from 'components/OrderSortDropdown'
import PageSizeDropDown from 'components/PageSizeDropdown'
import { ActionButtonContainer } from 'components/PageStructure'
import {
  BULK_SYNC_AUTOMATION_STATUS,
  BULK_SEND_TO_MANUAL,
  CREATE_COPAY_REQUEST,
  BULK_SEND_DEFERRED_TO_AUTOMATION,
} from 'gql'
import useErrorToast from 'hooks/toast/useErrorToast'
import useInfoToast from 'hooks/toast/useInfoToast'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import useFulfillmentQueue, { FulfillmentQueueName } from 'hooks/useFulfillmentQueue'
import useGetNextAdjudication from 'hooks/useGetNextAdjudication'
import useGetNextOtcOnly from 'hooks/useGetNextOtcOnly'
import useGetNextPV1 from 'hooks/useGetNextPV1'
import useGetNextTriage from 'hooks/useGetNextTriage'
import useQueryParamBoolean from 'hooks/useQueryParamBoolean'
import CreateCopayRequestModal from 'modals/CreateCopayRequestModal'
import PrintModal from 'modals/PrintModal'
import PrintPickSlipModal from 'modals/PrintPickSlipModal'
import SendToManualModal from 'modals/SendToManualModal'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import { useSelectionContext } from 'providers/SelectionProvider'
import { usePlusClient } from 'providers/VisionRouter'
import styled from 'styled-components'
import { bodyPrimaryColor } from 'styles/styleVariables'
import type { Fill, Order } from 'types'
import { StyledBasicActionButton } from '../PharmacyPage/PharmacyOrdersPage/StyledComponents'
import { WrapButton } from './orders/rx/screens/fill/ActionButtons'

interface FillWithOrderId {
  fillId: string
  orderId: string
}

const ActionButtons = (): JSX.Element => {
  const {
    currentLocation: { queryMap },
    QueryToolkit,
  } = usePlusClient()
  const { autobot } = queryMap
  const { showModal } = useModalContext()
  const { startPV1Work } = useGetNextPV1()
  const { startOtcOnlyWork } = useGetNextOtcOnly()
  const { startWork: startAdjudicationWork } = useGetNextAdjudication()
  const { startWork: startTriageWork } = useGetNextTriage()
  const fulfillmentQueue = useFulfillmentQueue()

  const { selections } = useSelectionContext()
  const [, { loading: loadingCreateCopayRequest }] = useMutation(CREATE_COPAY_REQUEST)

  const [otcOnlyControl] = useQueryParamBoolean('otcOnly', false)
  const [otcRxOnlyControl] = useQueryParamBoolean('rxOtcOnly', false)

  const sorted = selections.sort((left, right) => {
    if (fulfillmentQueue?.name !== FulfillmentQueueName.Packing) {
      const typedLeft = left as { _id: string; fill: Fill; order: Order }
      const typedRight = right as { _id: string; fill: Fill; order: Order }
      const leftIsBatch = typedLeft.order.rxFillRequests.length > 1
      const rightIsBatch = typedRight.order.rxFillRequests.length > 1

      if (leftIsBatch || rightIsBatch) {
        if (leftIsBatch && !rightIsBatch) return -1
        if (!leftIsBatch && rightIsBatch) return 1

        return typedLeft.order.patient?.lastName.localeCompare(typedRight.order.patient?.lastName as string) ?? 0
      }

      return typedLeft.fill.dispensed.name.localeCompare(typedRight.fill.dispensed.name)
    } else {
      return 0
    }
  })

  const nonBatchOrderFillIds: Fill['_id'][] = sorted
    .filter(sel => (sel as Fill).order?.rxFillRequests?.length === 1)
    .map(sel => sel._id)
  const fillIds = sorted.map(fill => fill._id)
  const fills = sorted.map(fill => {
    return {
      fillId: fill._id,
      orderId: (fill as Fill)?.order?._id || '',
    }
  })
  const orders = sorted.map(order => {
    return (order as Order)?._id
  })
  const hasReprints = selections.some(
    item => ((item as { _id: string; fill: Fill; order: Order })?.fill?.labelsPrinted || 0) > 0,
  )
  const ActionableOrderSort = () => {
    const showOrderSort = fulfillmentQueue?.name === FulfillmentQueueName.Fill

    if (!showOrderSort) {
      return null
    }
    return <OrderSortDropdown />
  }

  const ActionablePrint = () => {
    const currentAutobot: AutobotType = queryMap.autobot || AutobotType.mini
    const autobotShowPrint = currentAutobot === AutobotType.noStatus ? false : autobotMap[currentAutobot].showPrint
    const showPrint =
      fulfillmentQueue?.name === FulfillmentQueueName.Fill ||
      (fulfillmentQueue?.name === FulfillmentQueueName.Automation && autobotShowPrint)

    if (!showPrint) {
      return null
    }

    return <PrintActionButtons hasReprints={hasReprints} fills={fills} />
  }

  const ActionablePickSlipPrint = () => {
    const isPackingQueue = fulfillmentQueue?.name === FulfillmentQueueName.Packing

    if (!isPackingQueue) {
      return <></>
    }

    if (!otcOnlyControl && !otcRxOnlyControl) {
      return <></>
    }

    return <PrintPickSlipActionButtons orders={orders} />
  }

  const StartWorkPV1 = () => {
    const showStartWork = fulfillmentQueue?.name === FulfillmentQueueName.PV1
    if (!showStartWork) {
      return null
    }

    return (
      <ActionButton
        id="StartWork"
        icon={<StartWorkIcon />}
        label={'Start work'}
        hotKey={'F12'}
        onClick={() => startPV1Work()}
      />
    )
  }

  const StartWorkTriage = () => {
    const showStartWork = fulfillmentQueue?.name === FulfillmentQueueName.Triage
    if (!showStartWork) {
      return null
    }

    return (
      <ActionButton
        id="StartWork"
        icon={<StartWorkIcon />}
        label={'Start work'}
        hotKey={'F12'}
        onClick={() => startTriageWork()}
      />
    )
  }

  const StartWorkAdjudication = () => {
    const showStartWork = fulfillmentQueue?.name === FulfillmentQueueName.Adjudication

    if (!showStartWork) {
      return null
    }

    return (
      <ActionButton
        icon={<StartWorkIcon />}
        label={'Start work'}
        onClick={() => startAdjudicationWork()}
        hotKey={'F12'}
      />
    )
  }

  const StartWorkOtcOnly = () => {
    const showStartWork =
      fulfillmentQueue?.name === FulfillmentQueueName.Packing && QueryToolkit.StringBoolean(queryMap.otcOnly)

    if (!showStartWork) {
      return null
    }

    return (
      <ActionButton
        id="StartWork"
        icon={<StartWorkIcon />}
        label={'Start work'}
        hotKey={'F12'}
        onClick={() => startOtcOnlyWork()}
      />
    )
  }

  const StartWorkCopayRequests = () => {
    const showStartWork = fulfillmentQueue?.name === FulfillmentQueueName.Copay

    if (!showStartWork) {
      return null
    }

    return (
      // TODO - add copay start work  JR-5619
      <ActionButton id="StartWork" icon={<StartWorkIcon />} label={'Start work'} hotKey={'F12'} onClick={() => null} />
    )
  }

  const CreateCopayRequests = () => {
    const showCreateCopay = fulfillmentQueue?.name === FulfillmentQueueName.Copay

    if (!showCreateCopay) {
      return null
    }

    return (
      <AuthLimited roles={[UserRoles.Developer]}>
        <StyledBasicActionButton
          label={loadingCreateCopayRequest ? 'Generating...' : 'Generate Fixture Copay'}
          icon={
            <IconWrapper>
              <PlusIcon fill={'black'} />
            </IconWrapper>
          }
          onClick={() => {
            showModal(() => <CreateCopayRequestModal />)
          }}
        />
      </AuthLimited>
    )
  }

  const AutomationActionButtons = () => {
    const isAutomationQueue = fulfillmentQueue?.name === FulfillmentQueueName.Automation

    if (!isAutomationQueue) {
      return <></>
    }

    return (
      <>
        {autobot === AutobotType.deferredAutomation && <SendToAutomationButton fills={fills} />}
        <SendToManualButton fillIds={nonBatchOrderFillIds} />
        <BulkSyncAutomationButton fillIds={fillIds} />
      </>
    )
  }

  return (
    <span>
      <ActionButtonContainer>
        <ActionableOrderSort />
        {fulfillmentQueue?.name && <PageSizeDropDown />}
        <ActionablePrint />
        <StartWorkPV1 />
        <StartWorkTriage />
        <StartWorkOtcOnly />
        <CreateCopayRequests />
        <StartWorkCopayRequests />
        <StartWorkAdjudication />
        <AutomationActionButtons />
        <ActionablePickSlipPrint />
      </ActionButtonContainer>
    </span>
  )
}

const BulkSyncAutomationButton = ({ fillIds }: { fillIds: string[] }): JSX.Element => {
  const showErrorToast = useErrorToast()
  const showInfoToast = useInfoToast()
  const { initSelections, selections } = useSelectionContext()
  const [bulkSyncAutomationStatus, { loading }] = useMutation<{
    bulkSyncAutomationStatus: [{ result?: string; error?: string }]
  }>(BULK_SYNC_AUTOMATION_STATUS, {
    onCompleted() {
      showInfoToast('Messages successfully sent to Parata machine')
      initSelections([])
    },
    onError(err) {
      showErrorToast(`Failed to bulk sync automation status with error: ${err}.`)
      initSelections([])
    },
    refetchQueries: ['getOrdersCountByAutomationStatus'],
  })
  return (
    <AuthLimited roles={[UserRoles.Pharmacist, UserRoles.Technician]}>
      <ActionButton
        disabled={loading || selections.length === 0}
        icon={
          loading ? (
            <IconWrapper>
              <LoadingDots fill={bodyPrimaryColor} />
            </IconWrapper>
          ) : (
            <IconWrapper>
              <ReverseIcon fill={bodyPrimaryColor} />
            </IconWrapper>
          )
        }
        onClick={() => {
          void bulkSyncAutomationStatus({ variables: { fills: fillIds } })
        }}
        label="Resync Status"
      />
    </AuthLimited>
  )
}

export const PrintActionButtons: FC<{
  hasReprints: boolean
  fills: { fillId: string; orderId: string }[]
  orderId?: string
}> = ({ hasReprints, fills }) => {
  const { showModal } = useModalContext()
  const { selections, initSelections, totalSelectableItems } = useSelectionContext()

  const showPrintModal = () => (
    <PrintModal
      title={`${hasReprints ? 'Reprint' : 'Print'} label`}
      fills={fills}
      isReprint={hasReprints}
      confirmationCallback={() => initSelections([])}
    />
  )

  return (
    <WrapButton variant="primary-outline" onClick={() => showModal(showPrintModal)} disabled={selections.length === 0}>
      <IconWrapper>
        <PrintIcon />
      </IconWrapper>
      {`Print ${fills.length && fills.length !== totalSelectableItems ? 'Selected' : 'All'}`}
    </WrapButton>
  )
}

export const PrintPickSlipActionButtons: FC<{
  orders: string[]
}> = ({ orders }) => {
  const { showModal } = useModalContext()
  const { selections, initSelections, totalSelectableItems } = useSelectionContext()

  const showPrintPickSlipModal = () => (
    <PrintPickSlipModal confirmationCallback={() => initSelections([])} orderIDs={orders} />
  )

  return (
    <WrapButton
      variant="primary-outline"
      onClick={() => showModal(showPrintPickSlipModal)}
      disabled={selections.length === 0}
    >
      <IconWrapper>
        <PrintIcon />
      </IconWrapper>
      {`Print ${orders.length && orders.length !== totalSelectableItems ? 'Selected' : 'All'}`}
    </WrapButton>
  )
}

const SendToManualButton = ({ fillIds }: { fillIds: string[] }): JSX.Element => {
  const { showModal } = useModalContext()
  const showErrorToast = useErrorToast()
  const showSuccessToast = useSuccessToast()
  const [bulkSendToManual, { loading }] = useMutation(BULK_SEND_TO_MANUAL, {
    onCompleted() {
      showSuccessToast(`Successfully sent to manual.`)
    },
    onError(err) {
      showErrorToast(err.message)
    },
  })

  return (
    <AuthLimited roles={[UserRoles.Admin]}>
      <ActionButton
        icon={
          loading ? (
            <IconWrapper>
              <LoadingDots fill={bodyPrimaryColor} />
            </IconWrapper>
          ) : (
            <IconWrapper>
              <RouteIcon fill={bodyPrimaryColor} />
            </IconWrapper>
          )
        }
        label={'Send to Manual'}
        disabled={!fillIds || fillIds.length === 0 || loading}
        onClick={() =>
          showModal(() => (
            <SendToManualModal
              isBulk={true}
              onConfirm={async () => {
                await bulkSendToManual({
                  variables: { fillIds },
                })
              }}
            />
          ))
        }
      />
    </AuthLimited>
  )
}

const SendToAutomationButton = ({ fills }: { fills: FillWithOrderId[] }): JSX.Element => {
  const showErrorToast = useErrorToast()
  const showSuccessToast = useSuccessToast()
  const [bulkSendDeferredToAutomation, { loading }] = useMutation(BULK_SEND_DEFERRED_TO_AUTOMATION, {
    onCompleted(res) {
      const error = res.bulkSendDeferredToAutomation.map((i: any) => i.error).filter((i: any) => !!i)
      if (error.length) {
        showErrorToast(error.join('/'))
      } else {
        showSuccessToast(`Successfully sent to automation.`)
      }
    },
    onError(err) {
      showErrorToast(err.message)
    },
  })

  return (
    <ActionButton
      icon={
        loading ? (
          <IconWrapper>
            <LoadingDots fill={bodyPrimaryColor} />
          </IconWrapper>
        ) : (
          <IconWrapper>
            <RouteIcon fill={bodyPrimaryColor} />
          </IconWrapper>
        )
      }
      label={'Send to Automation'}
      disabled={!fills || fills.length === 0 || loading}
      onClick={() =>
        bulkSendDeferredToAutomation({
          variables: { fills },
        })
      }
    />
  )
}

const StartWorkIcon = styled.span`
  border: solid ${bodyPrimaryColor};
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
`

export default ActionButtons
