// Words which represent a single key on the keyboard
export const KeyWords: { [keyname: string]: boolean } = {
  Backspace: true,
  Shift: true,
  Enter: true,
  Escape: true,
  ArrowLeft: true,
  ArrowRight: true,
  ArrowUp: true,
  ArrowDown: true,
  Tab: true,
  '?': true,
}
