import UserInitialsBadge from 'components/UserInitialsBadge'
import moment from 'moment'
import styled from 'styled-components'
import { bodySecondaryColor } from 'styles/styleVariables'
import type { Log } from 'types'

const MessagesList = (props: { logs: Log[] }): JSX.Element => {
  const messages = (props.logs || []).filter(log => log.event === 'note')

  return (
    <StyledList>
      {messages.map((message: Log, i) => (
        <MessageEntry key={`${message._id}${i}`} log={message} />
      ))}
    </StyledList>
  )
}

type MessageEntryProps = {
  log: Log
}

export const MessageEntry = ({ log }: MessageEntryProps): JSX.Element => {
  const { createdAt, user, message } = log

  const createDate =
    typeof createdAt === 'string'
      ? moment(createdAt).format('MM/DD/YYYY hh:mmA')
      : moment(createdAt, 'x').format('MM/DD/YYYY hh:mmA')

  return (
    <StyledMessage>
      <Row>
        <BadgeContainer>{user.firstName && user.lastName && <UserInitialsBadge user={user} />}</BadgeContainer>
        <UserName>
          {user.firstName} {user.lastName}
        </UserName>
        <Date>{createDate}</Date>
      </Row>
      <Row>
        {log.change?.field ? (
          <p>
            <strong>Changed</strong> {log.change?.field} from {log.change?.oldValue} to {log.change?.newValue}.
          </p>
        ) : (
          <p>
            <strong>Note: </strong> {message}.
          </p>
        )}
      </Row>
    </StyledMessage>
  )
}

const BadgeContainer = styled.span`
  font-size: 1.25rem;
`

const UserName = styled.p`
  font-weight: 500;
`

const Date = styled.p`
  color: ${bodySecondaryColor};
`

const StyledList = styled.ul``

const Row = styled.span`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  > :not(:last-child) {
    margin-right: 0.625rem;
  }
  white-space: pre-wrap;
`

const StyledMessage = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  margin-top: 1.25rem;
  > :not(:first-child) {
    margin-top: 0.3125rem;
  }
  position: relative;
`

export default MessagesList
