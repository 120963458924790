import {
  ListRowLabel,
  ListRowValue,
  SmallPaddingBlock,
  RaisedAnchor,
  RXImageCell,
  RXListRowSingle,
  ImageFilledHeader,
} from 'components/RXTable'
import styled from 'styled-components'
import type { Item } from 'types'

type ReviewPayerProps = {
  item: Item
}

const ReviewPayer = ({ item }: ReviewPayerProps): JSX.Element => {
  const itemInsurances = item.insurances

  return (
    <>
      <TitleRow>
        <SmallPaddingBlock />
        <RXImageInsuranceFilledHeader>
          <RaisedAnchor id={`ReviewPayer`} />
          Payer
        </RXImageInsuranceFilledHeader>
      </TitleRow>
      {itemInsurances && itemInsurances.length > 0 && (
        <>
          <Designation>Primary</Designation>
          <RXListRowSingle>
            <RXImageCell closeOffTopBorder>
              <ListRowLabel>BIN:</ListRowLabel>
              <ListRowValue>{itemInsurances[0].bin}</ListRowValue>
            </RXImageCell>
          </RXListRowSingle>
          <RXListRowSingle>
            <RXImageCell>
              <ListRowLabel>PCN:</ListRowLabel>
              <ListRowValue>{itemInsurances[0].pcn}</ListRowValue>
            </RXImageCell>
          </RXListRowSingle>
          <RXListRowSingle>
            <RXImageCell closeOffBottomBorder>
              <ListRowLabel>Group:</ListRowLabel>
              <ListRowValue>{itemInsurances[0].group}</ListRowValue>
            </RXImageCell>
          </RXListRowSingle>
          <SmallPaddingBlock />
        </>
      )}
      {itemInsurances && itemInsurances.length > 1 && (
        <>
          <Designation>Secondary</Designation>
          <RXListRowSingle>
            <RXImageCell closeOffTopBorder>
              <ListRowLabel>BIN:</ListRowLabel>
              <ListRowValue>{itemInsurances[1].bin}</ListRowValue>
            </RXImageCell>
          </RXListRowSingle>
          <RXListRowSingle>
            <RXImageCell>
              <ListRowLabel>PCN:</ListRowLabel>
              <ListRowValue>{itemInsurances[1].pcn}</ListRowValue>
            </RXImageCell>
          </RXListRowSingle>
          <RXListRowSingle>
            <RXImageCell closeOffBottomBorder>
              <ListRowLabel>Group:</ListRowLabel>
              <ListRowValue>{itemInsurances[1].group}</ListRowValue>
            </RXImageCell>
          </RXListRowSingle>
        </>
      )}
    </>
  )
}

const RXImageInsuranceFilledHeader = styled(ImageFilledHeader)`
  grid-column: 1 / span 3;
  margin-bottom: 1rem;
`

const Designation = styled.div`
  font-weight: 500;
`
const TitleRow = styled.div``

export default ReviewPayer
