import { useCallback, useRef } from 'react'
import { OtcProductStatus } from '@truepill/tpos-types'
import { GET_NEXT_OTC_PACKING } from 'gql'
import useErrorToast from 'hooks/toast/useErrorToast'
import useInfoToast from 'hooks/toast/useInfoToast'
import useAutoUpdatingRef from 'hooks/useAutoUpdatingRef'
import { usePlusClient } from 'providers/VisionRouter'
import type { Order, OTCProduct } from 'types'
import { FulfillmentQueueName } from './useFulfillmentQueue'

let lastFilterSet = {}

export const getNextOtcProduct = (order: Order, currentOtcProduct?: OTCProduct): OTCProduct | undefined =>
  order.otcProducts.find(
    product => product._id !== currentOtcProduct?._id && product.otcStatus === OtcProductStatus.Packing,
  )

type useGetNextOtcOnlyType = {
  startOtcOnlyWork: () => Promise<void>
  getNextOtcOnly: () => Promise<void>
}
const useGetNextOtcOnly = (): useGetNextOtcOnlyType => {
  const {
    client,
    routeTo,
    tokenContext,
    currentLocation: { queryMap },
  } = usePlusClient()
  const showErrorToast = useErrorToast()
  const showInfoToast = useInfoToast()
  const showInfoToastRef = useAutoUpdatingRef(showInfoToast)
  const showErrorToastRef = useAutoUpdatingRef(showErrorToast)
  const streak = useRef(0)

  const getNextOtcOnly = useCallback(async () => {
    try {
      const variables = Object.keys(lastFilterSet).length
        ? lastFilterSet
        : {
            selectedLocationIds: queryMap.locationIds || undefined,
            customerIds: queryMap.customerIds || undefined,
            locationId: queryMap.locationId || tokenContext?.locationId,
            otcOnly: true,
          }

      const { data } = await client.query({
        query: GET_NEXT_OTC_PACKING,
        fetchPolicy: 'no-cache',
        variables,
      })
      const order = data.getNextOtcPacking as Order

      streak.current += 1

      if (order) {
        showInfoToastRef.current(`Another order is ready for your review. (Streak: ${streak.current})`)
        routeTo
          .order(order._id, true, {
            searchMap: {
              ...lastFilterSet,
              otcOnly: true,
            },
          })
          .now()
      } else {
        showInfoToastRef.current('No more work found')
        routeTo
          .fulfillmentQueue(FulfillmentQueueName.Packing, {
            searchMap: {
              locationId: queryMap.locationId || tokenContext?.locationId,
              otcOnly: true,
            },
          })
          .now()
      }
    } catch (error) {
      showErrorToastRef.current('Failed to get next OTC Only: ' + error.message)
    }
  }, [showErrorToastRef, showInfoToastRef, queryMap, tokenContext.locationId, client, routeTo])

  const startOtcOnlyWork = () => {
    lastFilterSet = {
      selectedLocationIds: queryMap.locationIds || undefined,
      medicationNames: queryMap.medications || undefined,
      customerIds: queryMap.customerIds || undefined,
      locationId: queryMap.locationId || tokenContext?.locationId,
      otcOnly: true,
    }
    return getNextOtcOnly()
  }

  return { startOtcOnlyWork, getNextOtcOnly }
}

export default useGetNextOtcOnly
