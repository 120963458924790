import type { ReactElement } from 'react'
import { useState } from 'react'
import { Modal, Header, Spacer, Text } from '@truepill/react-capsule'
import styled from 'styled-components'

interface ModalProps {
  shouldOpen: boolean
}
const MissingLicenseModal = ({ shouldOpen }: ModalProps): ReactElement => {
  const [isOpen, setIsOpen] = useState(shouldOpen)

  return (
    <Modal isOpen={isOpen} onDismiss={() => setIsOpen(false)} aria-labelledby="title" overlayCss={{ 'z-index': 10 }}>
      <>
        <StyledHeader variant="xl" id="title">
          Nevada License Only
        </StyledHeader>
        <Spacer />
        <StyledText>
          This fill can only be handled by Nevada licensed pharmacists or by pharmacists who work on a location where
          the PIC is licensed in Nevada.
        </StyledText>
      </>
    </Modal>
  )
}

const StyledHeader = styled(Header)`
  font-weight: bold;
  font-size: 36px;
`

const StyledText = styled(Text)`
  font-size: 16px;
`

export default MissingLicenseModal
