import type { SetStateAction } from 'react'
import { useState } from 'react'
import { useMutation } from '@truepill/tpos-react-router'
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg'
import { SaveButton } from 'components/ActionButton'
import { EditMode, NormalMode } from 'components/RXPageStructure'
import {
  ListRowLabel,
  ListRowValue,
  SmallPaddingBlock,
  RaisedAnchor,
  RXImageCell,
  ImageFilledHeader,
  StyledTextInput,
} from 'components/RXTable'
import { UPDATE_ORDER_FILL_REQUEST_INSURANCE_OVERRIDE_CODES } from 'gql'
import { CheckBox } from 'grommet'
import useErrorToast from 'hooks/toast/useErrorToast'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import styled from 'styled-components'
import { primaryColor, contrastBackgroundColor } from 'styles/styleVariables'
import type { Fill, Order, PriorAuthorization } from 'types'
import CMMKeyLink from './CMMKeyLink'

type InsuranceOptionType = { orderInsuranceOptionId: string }
type PriorAuthNumberType = { priorAuthorizationNumberSubmitted: string }

type CoverMyMedsProps = {
  priorAuthorization?: PriorAuthorization
  fill: Fill
  order?: Order
  valIsManual?: boolean
  valCMMKey?: string
  setIsManualVal?: React.Dispatch<SetStateAction<boolean>>
  setCMMKeyVal: React.Dispatch<SetStateAction<string>>
}

const CoverMyMeds = ({
  order,
  fill,
  priorAuthorization,
  valIsManual,
  valCMMKey,
  setIsManualVal,
  setCMMKeyVal,
}: CoverMyMedsProps): JSX.Element => {
  const showSuccessToast = useSuccessToast()
  const showErrorToast = useErrorToast()

  const [updateOrderFillRequestInsuranceOverrideCodes] = useMutation(UPDATE_ORDER_FILL_REQUEST_INSURANCE_OVERRIDE_CODES)
  const overrideCodes: any = order?.rxFillRequests?.[0]?.overrideCodes ?? {}

  const [isEditPriorAuthCode, setIsEditPriorAuthCode] = useState(false)
  const [updatedOrderOverrideCodes, setUpdatedOrderOverrideCodes] = useState([])

  const handlePriorAuthCodeChange = (value?: string) => {
    const updatedOrderInsurances: any = order?.insurances?.slice().map(activeInsurance => {
      const activeOverrideCodes =
        updatedOrderOverrideCodes?.find(
          (i: InsuranceOptionType) => i.orderInsuranceOptionId === activeInsurance.orderInsuranceOptionId,
        ) || activeInsurance.overrideCodes
      const updatedOverrideCodes: any = {
        ...activeOverrideCodes,
        orderInsuranceOptionId: activeInsurance.orderInsuranceOptionId,
        priorAuthorizationNumberSubmitted: value,
        priorAuthorizationTypeCode: { value: 1, applyTo: 'Prescription' },
      }

      return updatedOverrideCodes
    })

    setUpdatedOrderOverrideCodes(updatedOrderInsurances)
  }

  const handleUpdateOverrides = async () => {
    try {
      for await (const updatedOverrideCodes of updatedOrderOverrideCodes || []) {
        await updateOrderFillRequestInsuranceOverrideCodes({
          variables: {
            orderId: order?._id,
            fillId: fill._id,
            insuranceOverrideCodes: updatedOverrideCodes,
          },
        })
      }
      setUpdatedOrderOverrideCodes([])
      showSuccessToast(`Successfully updated prior authorization override code!`)
      setIsEditPriorAuthCode(false)
    } catch (err) {
      showErrorToast(`Failed to properly update prior authorization override: ${JSON.stringify(err)}`)
    }
  }

  const paCode =
    (updatedOrderOverrideCodes?.[0] as PriorAuthNumberType)?.priorAuthorizationNumberSubmitted ||
    overrideCodes?.[0]?.priorAuthorizationNumberSubmitted

  return (
    <>
      <InsuranceTitleRow>
        <SmallPaddingBlock />
        <RXImageInsuranceFilledHeader>
          <RaisedAnchor id={`CoverMyMeds`} />
          Cover My Meds
        </RXImageInsuranceFilledHeader>
      </InsuranceTitleRow>
      <RXImageCell closeOffTopBorder>
        <ListRowLabel>CMM Key:</ListRowLabel>
        <EditMode>
          <ListRowValue>
            <StyledTextInput value={valCMMKey} onChange={val => setCMMKeyVal(val.target.value ?? '')} />
          </ListRowValue>
        </EditMode>
        <NormalMode>{valCMMKey && <CMMKeyLink cmmKey={valCMMKey} />}</NormalMode>
      </RXImageCell>
      {order && (
        <>
          <RXImageCell closeOffBottomBorder>
            <ListRowLabel>PA Code:</ListRowLabel>
            <ListRowValue>
              {!isEditPriorAuthCode ? (
                <>
                  <CoverMyMedsValue>{paCode}</CoverMyMedsValue>
                  <StyledButton
                    onClick={() => {
                      setIsEditPriorAuthCode(true)
                    }}
                  >
                    <EditIcon fill={'black'} />
                  </StyledButton>
                </>
              ) : (
                <>
                  <StyledTextInput
                    placeholder="Code..."
                    value={paCode}
                    onChange={e => handlePriorAuthCodeChange(e.target.value)}
                  />
                  <SaveButton
                    label="Save"
                    disabled={!updatedOrderOverrideCodes.length}
                    onClick={handleUpdateOverrides}
                  />
                </>
              )}
            </ListRowValue>
          </RXImageCell>
        </>
      )}
      {priorAuthorization && (
        <RXImageCell closeOffBottomBorder={!order}>
          <ListRowLabel>Manual:</ListRowLabel>
          <ListRowValue style={{ gap: '0.3125rem' }}>
            <EditMode>
              <CheckBox checked={valIsManual} onChange={e => setIsManualVal?.(e.target.checked)} />
              <span>{valIsManual ? 'Yes' : 'No'}</span>
            </EditMode>
            <NormalMode>{priorAuthorization.isManual ? 'Yes' : 'No'}</NormalMode>
          </ListRowValue>
        </RXImageCell>
      )}
    </>
  )
}

const RXImageInsuranceFilledHeader = styled(ImageFilledHeader)`
  grid-column: 1 / span 3;
  margin-bottom: 1rem;
`
const InsuranceTitleRow = styled.div``

export const StyledButton = styled.button`
  margin: 0.25rem;
  max-height: 2.5rem;
  max-width: 2.5rem;
  border-radius: 0.3rem;
  background-color: ${contrastBackgroundColor};
  border: 0.1rem solid ${contrastBackgroundColor};
  :hover {
    box-shadow: 0px 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
  }
  :focus {
    outline: 0;
    box-shadow: 0 0 0.625rem ${primaryColor};
  }
`

const CoverMyMedsValue = styled.div`
  width: 70%;
  align-self: center;
  flex-grow: 1;
`

export default CoverMyMeds
