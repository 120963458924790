import { useRef, useCallback, useState } from 'react'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg'
import { XLIconWrapper } from 'components/IconWrapper'
import LoadingSpinner from 'components/Loading'
import { UploadCloud } from 'react-feather'
import styled, { css } from 'styled-components'
import {
  primaryBackgroundColor,
  bodySecondaryColor,
  borderColor,
  primaryColor,
  borderColorTransparent,
  bodyPrimaryColor,
  capsulePrimaryColor,
  capsuleGrayColor,
  capsuleGreyColor,
  capsuleLightGreyColor,
} from 'styles/styleVariables'

const pullFiles = (fileList: FileList): File[] => {
  const files = []
  for (let i = 0; i < fileList.length; i++) {
    const file = fileList.item(i)
    if (file) {
      files.push(file)
    }
  }
  return files
}

export type UploadState = {
  name: string
  uploaded: boolean
  dataURL?: string
  size?: string
  url?: string
}

type UploadTargetProps = {
  uploaded?: UploadState[]
  onFilesDropped: (files: File[]) => void | Promise<void>
  onUploadCancelled: (uploadIndex: number) => void
  className?: string
  withCapsule?: boolean
}

const UploadTarget = ({
  onFilesDropped,
  onUploadCancelled,
  uploaded = [],
  className,
  withCapsule = false,
}: UploadTargetProps): JSX.Element => {
  const [beingDraggedOver, setBeingDraggedOver] = useState(false)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const fileDropHandler = useCallback(
    inputFiles => {
      const files = pullFiles(inputFiles)
      onFilesDropped(files)
    },
    [onFilesDropped],
  )

  return (
    <TargetBox
      withCapsule={withCapsule}
      className={className}
      highlighted={beingDraggedOver}
      onDrop={e => {
        e.preventDefault()
        setBeingDraggedOver(false)
        fileDropHandler(e.dataTransfer.files)
      }}
      onDragOver={e => {
        setBeingDraggedOver(true)
        e.stopPropagation()
        e.preventDefault()
      }}
      onDragLeave={e => {
        setBeingDraggedOver(false)
      }}
    >
      <HiddenInput type={'file'} ref={fileInputRef} onChange={e => fileDropHandler(e.target.files)} />
      <StyledUploadDocList>
        {uploaded.map((uploadState, i) => (
          <UploadDoc
            key={uploadState.name + uploadState.size}
            removeCallback={() => onUploadCancelled(i)}
            {...uploadState}
          />
        ))}
      </StyledUploadDocList>
      {!uploaded.length && (
        <XLIconWrapper>
          {withCapsule ? <UploadCloud color={capsuleGreyColor} /> : <UploadIcon fill={bodyPrimaryColor} />}
        </XLIconWrapper>
      )}
      <Text withCapsule={withCapsule}>
        {!uploaded.length ? 'Drag-and-drop to upload or ' : 'To replace image '}
        <ActionLink
          type="button"
          withCapsule={withCapsule}
          onClick={() => {
            if (fileInputRef.current) {
              fileInputRef.current.click()
            }
          }}
        >
          click here
        </ActionLink>
      </Text>
    </TargetBox>
  )
}

function isPdf(url?: string) {
  return url && url.includes('application/pdf')
}

const UploadDoc = (props: UploadState & { removeCallback: () => void }) => {
  const [showButton, setShowButton] = useState(false)
  const { dataURL, uploaded, removeCallback, name } = props

  const isPDF = isPdf(dataURL)

  return (
    <StyledUploadDoc onMouseEnter={() => setShowButton(true)} onMouseLeave={() => setShowButton(false)}>
      {!isPDF && <img src={dataURL} alt={'Uploaded Document Preview'} />}
      {isPDF && (
        <PdfNameContainer>
          <PdfName>{name}</PdfName>
        </PdfNameContainer>
      )}
      {!uploaded && (
        <LoadingSpinnerContainer>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      )}
      {showButton && (
        <CloseButton type="button" onClick={removeCallback}>
          <CloseIcon fill={primaryBackgroundColor} />
        </CloseButton>
      )}
    </StyledUploadDoc>
  )
}

const PdfNameContainer = styled.div`
  width: 100%;
  height: 100%;
  min-width: 6rem;
  min-height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

const PdfName = styled.p`
  text-align: center;
`

const CloseButton = styled.button`
  position: absolute;
  top: -0.25rem;
  right: -0.25rem;
  border-radius: 100%;
  width: 1.25rem;
  height: 1.25rem;
  background-color: ${borderColor};
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 0.25rem;
  padding-left: 0.3rem;
`

const LoadingSpinnerContainer = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  > svg {
    width: 2rem;
  }
  background-color: ${borderColorTransparent};
`

const StyledUploadDocList = styled.ul`
  position: relative;
`

const StyledUploadDoc = styled.li`
  display: flex;
  border: 0.125rem solid;
  border-color: ${borderColor};
  border-radius: 0.25rem;
  max-width: 6.875rem;
  max-height: 8rem;
  > img {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
  }
`

const Highlighted = css`
  background-color: ${borderColor};
`

const TargetBox = styled.div<{ highlighted?: boolean; withCapsule?: boolean }>`
  border: 0.125rem ${({ withCapsule }) => !withCapsule && 'dashed'};
  border-color: ${({ withCapsule }) => (withCapsule ? capsuleLightGreyColor : bodySecondaryColor)};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem;
  ${({ withCapsule }) => withCapsule && `background-color: ${capsuleGrayColor}`};
  ${({ highlighted }) => highlighted && Highlighted}
`

const HiddenInput = styled.input`
  display: none;
`

const Text = styled.p<{ withCapsule?: boolean }>`
  margin-top: 1rem;
  text-align: center;
  max-width: 12rem;
  ${({ withCapsule }) => withCapsule && `color: ${capsuleGreyColor}; font-weight: bold`}
`

const ActionLink = styled.button<{ withCapsule?: boolean }>`
  border: none;
  background-color: ${({ withCapsule }) => (withCapsule ? capsuleGrayColor : primaryBackgroundColor)};
  color: ${({ withCapsule }) => (withCapsule ? capsulePrimaryColor : primaryColor)};
  font-size: 1rem;
  font-weight: 500;
  text-decoration: underline;
`

export default UploadTarget
