import Lozenge from 'components/Tiles/Lozenge'
import styled from 'styled-components'
import { primaryColor, primaryBackgroundColor } from 'styles/styleVariables'

type StatusLozengeProps = { status: string }

// No Tile for this, just lozenge
const StatusLozenge = ({ status }: StatusLozengeProps): JSX.Element => {
  return <StyledStatusLozenge data-testid="status">{status}</StyledStatusLozenge>
}

const StyledStatusLozenge = styled(Lozenge)`
  text-transform: uppercase;
  background-color: ${primaryColor};
  color: ${primaryBackgroundColor};
`

export default StatusLozenge
