import { ReactComponent as PageArrowIcon } from 'assets/icons/pageArrow.svg'
import styled from 'styled-components'
import EllipsisTruncate from 'styles/EllipsisTruncate'
import { primaryBackgroundColor, primaryColor, greyPaging } from 'styles/styleVariables'

const PagingRow = styled.div`
  display: grid;
  width: 100%;
  grid-template-rows: [content] 1fr;
  grid-template-columns: [numberOfOrders] minmax(5rem, 8rem) [pageNumbers] minmax(0, 1fr);
`

const PageNumbers = styled.div<{
  marginStyling?: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: pageNumbers;
  ${({ marginStyling }) => marginStyling && `margin: 0 5rem;`}
  @media screen and (min-width: 1280px) {
    ${({ marginStyling }) => marginStyling && `margin: 0 15rem;`}
  }
  @media screen and (min-width: 1800px) {
    ${({ marginStyling }) => marginStyling && `margin: 0 25rem;`}
  }
  @media screen and (min-width: 2400px) {
    ${({ marginStyling }) => marginStyling && `margin: 0 40rem;`}
  }
`

const NumberOfOrders = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  color: ${greyPaging};
  grid-column: numberOfOrders;
`

const PageNumber = styled.a`
  padding: 6px 8px;
  font-weight: 500;
  border-radius: 4px;
  cursor: pointer;
  color: ${greyPaging};
`

const HighlightedPageNumber = styled(PageNumber)`
  background-color: ${primaryColor};
  color: ${primaryBackgroundColor};
`

const RightPageArrowIcon = styled(PageArrowIcon)`
  transform: rotate(180deg);
`

const PagingButtonWrapperRight = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-right: 0.625rem;
`
const PagingButtonWrapperLeft = styled.div`
  display: flex;
  align-self: center;
  margin-right: 10px;
  cursor: pointer;
`

const MiddlePages = styled.div`
  ${EllipsisTruncate}
  overflow: hidden;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`

const PagingWrapper = styled.div<{ pageMargin?: string }>`
  grid-row: paging;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-top: 0.8rem;
  margin-bottom: 2rem;
  > span {
    position: relative;
    font-weight: 500;
    width: 6.25rem;
    color: ${greyPaging};
    grid-column: numberOfOrders;
  }
  ${({ pageMargin }) => pageMargin && `margin: ${pageMargin};`}
`

type NavProps = { setPage: () => void }

const FirstPage = ({ setPage }: NavProps): JSX.Element => (
  <PageButton onClick={setPage}>
    <PageArrowIcon />
    <PageArrowIcon />
  </PageButton>
)
const PreviousPage = ({ setPage }: NavProps): JSX.Element => (
  <PageButton onClick={setPage}>
    <PageArrowIcon />
  </PageButton>
)
const LastPage = ({ setPage }: NavProps): JSX.Element => (
  <PageButton onClick={setPage}>
    <RightPageArrowIcon />
    <RightPageArrowIcon />
  </PageButton>
)
const NextPage = ({ setPage }: NavProps): JSX.Element => (
  <NextPageButtonPadding>
    <PageButton onClick={setPage}>
      <RightPageArrowIcon />
    </PageButton>
  </NextPageButtonPadding>
)

type PageButtonsProps = {
  currentPage: number
  start: number
  end: number
  setPage: (i: number) => void
}

const PageButtons = ({ currentPage, end, start, setPage }: PageButtonsProps): JSX.Element => {
  const items = []
  for (let i = start; i < end; i++) {
    if (i === currentPage) {
      items.push(<HighlightedPageNumber key={i}>{currentPage + 1}</HighlightedPageNumber>)
    } else {
      items.push(
        <PageNumber key={i} onClick={() => setPage(i)}>
          {' '}
          {i + 1}{' '}
        </PageNumber>,
      )
    }
  }
  return <>{items}</>
}

type PagingProps = {
  totalPages: number
  currentPage: number
  pageSize: number
  totalRecords: number
  margin?: string
  setPage: (pageNumber: number) => void
}

/**
 * This Paging component is deprecated. It has the old design, and the only pages that should use this component
 * are the ones that we haven't implemented the new design
 * Please, use Pagination component instead.
 */
const Paging = ({ currentPage, margin, setPage, pageSize, totalRecords, totalPages }: PagingProps): JSX.Element => {
  const startGroupPagesEnd = Math.max(Math.min(3, totalPages - 3), 1)
  const middleGroupPagesStart = Math.max(startGroupPagesEnd, Math.min(totalPages - 4, currentPage - 3))
  const endGroupPagesStart = Math.max(Math.min(totalPages - 3, totalPages), middleGroupPagesStart)
  const middleGroupPagesEnd = Math.min(endGroupPagesStart, middleGroupPagesStart + 50)
  const isGreaterThan200 = totalRecords > 200

  const showPaging = totalRecords > pageSize
  const lowerBound = pageSize * currentPage + 1
  const upperBound = pageSize * (currentPage + 1)

  return (
    <PagingWrapper pageMargin={margin}>
      <PagingRow>
        <NumberOfOrders>
          {lowerBound} - {upperBound > totalRecords ? totalRecords : upperBound} of {totalRecords}
        </NumberOfOrders>
        {showPaging && (
          <PageNumbers marginStyling={isGreaterThan200}>
            <PagingButtonWrapperLeft>
              <FirstPage setPage={() => setPage(0)} />
              <PreviousPage setPage={() => currentPage - 1 > 0 && setPage(currentPage - 1)} />
            </PagingButtonWrapperLeft>
            <MiddlePages>
              <PageButtons start={0} end={startGroupPagesEnd} setPage={setPage} currentPage={currentPage} />
              {startGroupPagesEnd - middleGroupPagesStart < 0 ? '...' : ''}
              <PageButtons
                start={middleGroupPagesStart}
                end={middleGroupPagesEnd}
                setPage={setPage}
                currentPage={currentPage}
              />
            </MiddlePages>
            <PagingButtonWrapperRight>
              <PageButtons start={endGroupPagesStart} end={totalPages} setPage={setPage} currentPage={currentPage} />
              <NextPage
                setPage={() => {
                  currentPage + 1 < totalPages && setPage(currentPage + 1)
                }}
              />
              <LastPage setPage={() => setPage(totalPages - 1)} />
            </PagingButtonWrapperRight>
          </PageNumbers>
        )}
      </PagingRow>
    </PagingWrapper>
  )
}

const PageButton = styled.button`
  background-color: ${primaryBackgroundColor};
  border: none;
  display: flex;
  flex-grow: 1;
`

const NextPageButtonPadding = styled.div`
  margin-left: 0.5rem;
`

export default Paging
