import { useMutation } from '@truepill/tpos-react-router'
import type { PatientStatus, PatientStatusChangeReason } from '@truepill/tpos-types'
import { SaveButton } from 'components/ActionButton'
import { ModalWrapper, ModalHeader, ButtonsContainer } from 'components/Modal'
import { CancelButton, SubTitle } from 'components/PageStructure'
import { UPDATE_PATIENT_STATUS } from 'gql'
import useErrorToast from 'hooks/toast/useErrorToast'
import { useFormData } from 'hooks/useFormData'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import styled from 'styled-components'

type AttachmentModalProps = {
  title: string
  status: PatientStatus
  patientId: string
  reason: PatientStatusChangeReason
}

const UpdatePatientStatusModal = ({ title, status, patientId, reason }: AttachmentModalProps): JSX.Element => {
  const { dismissModal } = useModalContext()
  const showErrorToast = useErrorToast()
  const {
    actions: { clearFormData },
  } = useFormData()

  const [updateStatus] = useMutation(UPDATE_PATIENT_STATUS, {
    onCompleted() {
      window.location.reload()
      clearFormData()
    },
    onError(err) {
      showErrorToast(`Error updating patient status: ${err.message}`)
    },
  })

  return (
    <ModalWrapper id="UpdatePatientStatusModal">
      <ModalHeader>
        <h1>{title}</h1>
      </ModalHeader>
      <SubTitleContainer>
        <SubTitle>Are you sure you want to deactivate this patient profile?</SubTitle>
        <SubTitle>Prescriptions assigned to this patient profile in the future</SubTitle>
        <SubTitle> will not trigger webhook events back to the Customer.</SubTitle>
      </SubTitleContainer>
      <ButtonsContainer>
        <CancelButton label={'Back'} onClick={dismissModal} />
        <SaveButton
          isModal
          label={'Deactivate'}
          onClick={async () => {
            await updateStatus({
              variables: {
                status,
                reason,
                patientId,
              },
            })
          }}
        />
      </ButtonsContainer>
    </ModalWrapper>
  )
}

const SubTitleContainer = styled.div`
  margin: 1rem;
`

export default UpdatePatientStatusModal
