import { ToolButton } from 'components/SideToolBar'
import styled from 'styled-components'
import { primaryColorDark } from 'styles/styleVariables'

const AutomationSectionContainer = styled.div`
  padding-bottom: 2rem;
  display: flex;
  max-width: 90vw;
  overflow: scroll;
`

const AutomationCard = styled.div.attrs((props: { active: boolean }) => ({
  active: props.active,
}))`
  margin: 20px;
  width: 360px;
  height: 270px;
  padding: 20px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  border: 2px solid ${props => (props.active ? primaryColorDark : 'transparent')};
`

const TitleRow = styled.div`
  font-size: 21px;
  line-height: 24px;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: [icon] 1.3rem [name] 15rem [settings] auto;
`

const SettingsIcon = styled.div.attrs((props: { isAdmin: boolean }) => ({
  isAdmin: props.isAdmin,
}))`
  padding-left: ${props => (props.isAdmin ? '.5rem' : '2rem')};
  cursor: pointer;
`

const IconContainer = styled.div`
  display: flex;
`

const DeleteIconContainer = styled.div`
  padding-left: 1rem;
  cursor: pointer;
`

const LocationRow = styled.div`
  color: #999999;
  font-size: 14px;
`

const DetailsContainer = styled.div`
  padding-top: 1rem;
`

const Details = styled.div`
  font-size: 18px;
  line-height: 22px;
  padding-bottom: 0.5rem;
`

const Row = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: [label] 10rem [value] 10rem;
`

const Label = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
`

const StyledToolButton = styled(ToolButton)`
  position: absolute;
  right: 3rem;
`

export {
  AutomationSectionContainer,
  AutomationCard,
  TitleRow,
  SettingsIcon,
  IconContainer,
  DeleteIconContainer,
  LocationRow,
  DetailsContainer,
  Details,
  Row,
  Label,
  StyledToolButton,
}
