import type { ReactComponentElement } from 'react'
import { useMutation, useQuery } from '@truepill/tpos-react-router'
import AccuracyScanningItem from 'components/AccuracyScanningItem'
import AccuracyScanningList from 'components/AccuracyScanningList'
import { GET_PACKING_MATERIALS_BY_SKUS, OVERRIDE_PACKING_MATERIAL } from 'gql'
import useErrorToast from 'hooks/toast/useErrorToast'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import { useTaskContext } from 'providers/TaskProvider'
import type { Order, PackingMaterial } from 'types'

const PackingMaterialListAccuracy = (props: { order: Order }): ReactComponentElement<typeof AccuracyScanningList> => {
  const { order } = props

  const skus = order.packingMaterials?.map(packingMaterial => packingMaterial.sku) || []

  const { data, loading } = useQuery<{ getPackingMaterialsBySKUS: PackingMaterial[] }>(GET_PACKING_MATERIALS_BY_SKUS, {
    variables: { skus },
    skip: !order.packingMaterials?.length,
  })

  const showSuccessToast = useSuccessToast(true)
  const showErrorToast = useErrorToast()

  const [overridePackingMaterial] = useMutation<
    { overridePackingMaterial: { _id: string; overrideScan: boolean } },
    { orderId: string; packingMaterialId: string }
  >(OVERRIDE_PACKING_MATERIAL, {
    onError: (e: Error) => showErrorToast('Failed to override Packing Material: ' + e.message),
    onCompleted: () => showSuccessToast('Packing Material successfully overridden'),
  })

  const handleOverride = (productId: string) => {
    overridePackingMaterial({ variables: { orderId: order._id, packingMaterialId: productId } })
  }

  const { tasks } = useTaskContext()

  return (
    <AccuracyScanningList loading={!order.packingMaterials || loading}>
      {order.packingMaterials?.map(packingMaterial => {
        const packingMaterialData = data?.getPackingMaterialsBySKUS.find(pm => pm.sku === packingMaterial.sku)
        const packSize = packingMaterialData?.quantity ?? 0
        const scansNeeded = packSize ? packingMaterial.quantity / packSize : packingMaterial.quantity
        const numberOfScansNotSupported = !Number.isInteger(scansNeeded)

        return (
          <AccuracyScanningItem
            key={packingMaterial._id}
            product={{
              ...packingMaterial,
              name: packingMaterialData?.itemName || '',
            }}
            packingData={{
              error: numberOfScansNotSupported ? 'Qty Error - Please report to your lead' : '',
              packSize,
            }}
            tasks={tasks}
            productType="PM"
            handleOverride={() => handleOverride(packingMaterial._id)}
          />
        )
      })}
    </AccuracyScanningList>
  )
}

export default PackingMaterialListAccuracy
