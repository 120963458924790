import { useState } from 'react'
import { SaveButton } from 'components/ActionButton'
import BreadCrumb from 'components/BreadCrumb'
import {
  ActionButtonContainer,
  CancelButton,
  PageContent,
  PageHeading,
  StickyPageHeadingContainer,
} from 'components/PageStructure'
import RxHeading from 'components/RxHeading'
import { RXTable, RXSegment, RXRowTable } from 'components/RXTable'
import ToolBar from 'components/ToolBar'
import styled from 'styled-components'
import { contrastBackgroundColor } from 'styles/styleVariables'
import type {
  EdiOverrides,
  RxFillRequestOverridesKeys,
  QueueItem,
  Fill,
  RXFillRequest,
  CopayRequestFill,
  Prescription,
  Patient,
} from 'types'
import EdiEditActionButtons from './ActionButtons'
import AdditionalDocumentationSegment from './ReviewSegments/AdditionalDocumentationSegment'
import ClaimSegment from './ReviewSegments/ClaimSegment'
import ClinicalSegment from './ReviewSegments/ClinicalSegment'
import CoordinationOfBenefitSegment from './ReviewSegments/COBSegment'
import FacilitySegment from './ReviewSegments/FacilitySegment'
import InsuranceSegment from './ReviewSegments/InsuranceSegment'
import NarrativeSegment from './ReviewSegments/NarrativeSegment'
import PatientSegment from './ReviewSegments/PatientSegment'
import PharmacyProviderSegment from './ReviewSegments/PharmacyProviderSegment'
import PrescriberSegment from './ReviewSegments/PrescriberSegment'
import PricingSegment from './ReviewSegments/PricingSegment'
import TransmissionSegment from './ReviewSegments/TransmissionSegment'

type EdiEditViewProps = {
  setDisplayEdiEdit: (val: boolean) => void
  item: QueueItem
  fill: Fill
  itemFill: RXFillRequest | CopayRequestFill
  prescription: Prescription
  patient: Patient
}

const payerOrderNames = ['primary', 'secondary', 'tertiary']
const makePayerButtons = (
  item: QueueItem,
  selectedOption: string,
  setSelectedOption: (val: string) => void,
): JSX.Element => {
  return (
    <ActionButtonContainer>
      <>BINS: </>
      {item.insurances.map((i, index) => {
        if (selectedOption.toString() === i.orderInsuranceOptionId.toString()) {
          return (
            <SaveButton
              label={`${i.bin} (${payerOrderNames[index]})`}
              onClick={() => setSelectedOption(i.orderInsuranceOptionId)}
            />
          )
        }

        return (
          <StyledCancelButton
            label={`${i.bin} (${payerOrderNames[index]})`}
            onClick={() => setSelectedOption(i.orderInsuranceOptionId)}
          />
        )
      })}
    </ActionButtonContainer>
  )
}

const EdiEditView = ({
  setDisplayEdiEdit,
  item,
  itemFill,
  prescription,
  patient,
  fill,
}: EdiEditViewProps): JSX.Element => {
  const [ediOverrides, setEdiOverrides] = useState(itemFill?.ediOverrides || [])
  const [ediOverridesOptionId, setEdiOverridesOptionId] = useState(item?.insurances?.[0]?.orderInsuranceOptionId)
  const fillId = fill._id

  let selectedEdiOverride =
    ediOverrides.find(i => i.orderInsuranceOptionId?.toString() === ediOverridesOptionId?.toString()) || {}

  const updateEdiOverrides = (update: EdiOverrides) => {
    const newEdiOverrides = [...ediOverrides]
    const updateKey: RxFillRequestOverridesKeys = Object.keys(update)[0] as RxFillRequestOverridesKeys

    const outerPatch = { ...selectedEdiOverride[updateKey], ...update[updateKey] }
    const newObj: Partial<EdiOverrides> = {}
    newObj[updateKey] = outerPatch
    selectedEdiOverride = { ...selectedEdiOverride, ...newObj }
    const payerIndex = ediOverrides.findIndex(
      override => ediOverridesOptionId.toString() === override.orderInsuranceOptionId?.toString(),
    )

    if (payerIndex >= 0) {
      newEdiOverrides[payerIndex] = selectedEdiOverride
    } else {
      newEdiOverrides.push({ ...selectedEdiOverride, orderInsuranceOptionId: ediOverridesOptionId })
    }

    setEdiOverrides(newEdiOverrides)
  }

  return (
    <>
      <StickyPageHeadingContainer>
        <BreadCrumb />
        <PageHeading>
          <RxHeading item={item} prescription={prescription} fill={fill} patient={patient} />
          <EdiEditActionButtons
            setDisplayEdiEdit={setDisplayEdiEdit}
            item={item}
            fillId={fillId}
            ediOverrides={ediOverrides}
          />
        </PageHeading>
      </StickyPageHeadingContainer>
      <PageContent>
        <RXViewContainer>
          <PayerButtons>{makePayerButtons(item, ediOverridesOptionId, setEdiOverridesOptionId)}</PayerButtons>
          <RXTable>
            <RXSegment>
              <RXRowTable>
                <TransmissionSegment ediOverrides={selectedEdiOverride} updateEdiOverrides={updateEdiOverrides} />
                <InsuranceSegment ediOverrides={selectedEdiOverride} updateEdiOverrides={updateEdiOverrides} />
                <PatientSegment ediOverrides={selectedEdiOverride} updateEdiOverrides={updateEdiOverrides} />
                <ClaimSegment ediOverrides={selectedEdiOverride} updateEdiOverrides={updateEdiOverrides} />
                <PricingSegment ediOverrides={selectedEdiOverride} updateEdiOverrides={updateEdiOverrides} />
                <PharmacyProviderSegment ediOverrides={selectedEdiOverride} updateEdiOverrides={updateEdiOverrides} />
                <PrescriberSegment ediOverrides={selectedEdiOverride} updateEdiOverrides={updateEdiOverrides} />
                <CoordinationOfBenefitSegment
                  ediOverrides={selectedEdiOverride}
                  updateEdiOverrides={updateEdiOverrides}
                />
                <FacilitySegment ediOverrides={selectedEdiOverride} updateEdiOverrides={updateEdiOverrides} />
                <ClinicalSegment ediOverrides={selectedEdiOverride} updateEdiOverrides={updateEdiOverrides} />
                <AdditionalDocumentationSegment
                  ediOverrides={selectedEdiOverride}
                  updateEdiOverrides={updateEdiOverrides}
                />
                <NarrativeSegment ediOverrides={selectedEdiOverride} updateEdiOverrides={updateEdiOverrides} />
              </RXRowTable>
            </RXSegment>
          </RXTable>
        </RXViewContainer>
        <ToolBar>
          {/* <LogButton logs={order.logs} fillId={fill._id}/> */}
          {/* stupid minimal hack to make types happy */}
          <></>
        </ToolBar>
      </PageContent>
    </>
  )
}

const RXViewContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-top: 1.25rem;
  padding-left: 1.875rem;
`

const PayerButtons = styled.div`
  padding-bottom: 1rem;
`

const StyledCancelButton = styled(CancelButton)`
  background: ${contrastBackgroundColor};
`

export default EdiEditView
