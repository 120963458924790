import { createContext, useContext } from 'react'
import { createBrowserHistory } from 'history'
import { Router } from 'react-router-dom'
import type { CentralRoutes, CentralRoutingProps, RouteToGeneric } from './CentralRouting'
import CentralRouting, { CentralRoutingDefaults, useCentralRouting } from './CentralRouting'
import type { ClientProps } from './Client'
import Client, { ClientContextDefaults, useClient } from './Client'

export interface RouteThisContext<T, U> {
  routeTo: CentralRoutes<T>
  tokenContext: U
}

type ClientRouterProps<T, U> = ClientProps & CentralRoutingProps<T, U> & { children: any }

const ClientRouterDefaults = { ...CentralRoutingDefaults, ...ClientContextDefaults }

export const ClientRouterContext = createContext(ClientRouterDefaults)

export function useClientRouter<T, U>() {
  const clientRouter = useContext(ClientRouterContext)

  return {
    ...clientRouter,
    tokenContext: clientRouter.tokenContext as T,
    routeTo: clientRouter.routeTo as unknown as Omit<CentralRoutes<U>, 'landingPage'> & {
      landingPage: () => RouteToGeneric
    },
  }
}

function ClientRouterProvider<T, U>(props: ClientRouterProps<T, U>) {
  const clientContext = useClient()
  const centralRoutingContext = useCentralRouting()

  const value = {
    ...clientContext,
    ...centralRoutingContext,
  }

  return <ClientRouterContext.Provider value={value}>{props.children}</ClientRouterContext.Provider>
}

export default function ClientRouter<T, U>(props: ClientRouterProps<T, U>) {
  return (
    <Client {...props}>
      <Router history={createBrowserHistory()}>
        <CentralRouting<T, U> {...props}>
          <ClientRouterProvider<T, U> {...props} />
        </CentralRouting>
      </Router>
    </Client>
  )
}
