function parseMedicationString(input: string | string[]): string {
  if (!input) return ''

  if (typeof input !== 'string') input = input.join(' ')

  return input
    .replace(
      /([,\d-]+?)?(mg)?\s(tablet|tab|solution|drops|capsule|eye drops|inhaler|spray|soft gel|cream|[\d]+?mg|[\d]+?%|[\d]+?ml|powder|patch|pen|eye drop|mg\/ml|units\/ml|vial|unit)/gm,
      '',
    )
    .trim()
}

export default parseMedicationString
