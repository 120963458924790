import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Header, Modal, Spacer } from '@truepill/react-capsule'
import { dehyphenate } from '@truepill/tpos-data-util'
import type { StockInfo } from '@truepill/tpos-types'
import { LaunchDarkly } from '@truepill/tpos-types'
import CustomizedTextField from 'components/CustomizedTextField'
import { useFlag } from 'providers/LaunchDarklyProvider'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { object, string } from 'yup'

// quantity per scan not required to be filled manually in this modal
export type StockInfoWithoutQuantity = Omit<StockInfo, 'quantity'>

type ManualEntryNdc = {
  ndc: string
}

type ConfirmationCallback = {
  stockInfo: StockInfo[]
  confirmationCallback: (stockInfo: StockInfoWithoutQuantity) => void
  requireStockInfo: boolean
  disableStockInfo?: boolean
}

type ManualEntryFillModalProps = ManualEntryNdc & ConfirmationCallback

type ManualEntryFill = ManualEntryNdc & StockInfoWithoutQuantity

const isValidDate = (expirationDateMonthYear: string | undefined, required: boolean) => {
  if (!expirationDateMonthYear) return !required
  const [month, year] = expirationDateMonthYear.split('/')
  const enteredDate = new Date(Number(year), Number(month))
  const currentDate = new Date()
  return enteredDate > currentDate
}

const getLastDayOfMonth = ({ month, year }: { month: number; year: number }): number => {
  const lastDay = new Date(year, month, 0).getDate()
  return lastDay
}

const defaultValues: ManualEntryFill = {
  ndc: '',
  lot: '',
  expirationDate: '',
  serialNumber: null,
}

const ManualEntryFillModal = ({
  ndc,
  stockInfo,
  confirmationCallback,
  requireStockInfo,
  disableStockInfo,
}: ManualEntryFillModalProps): JSX.Element => {
  const { dismissModal } = useModalContext()
  const requireDataMatrix =
    useFlag(LaunchDarkly.FeatureFlags.REQUIRE_USER_TO_SCAN_DATAMATRIX_DURING_FILL) && !disableStockInfo

  const ndcFormSchema = object().shape({
    ndc: string()
      .label('NDC')
      .required()
      .defined()
      .test('ndc-matches', 'GTIN must match NDC', value => dehyphenate(value) === dehyphenate(ndc)),
  })

  const notRequiredRegex = /^(0[1-9]|1[0-2])\/\d{4}$|^$/
  const requiredRegex = /^(0[1-9]|1[0-2])\/\d{4}$/

  const schemaShape = {
    ...ndcFormSchema.fields,
    lot: string()
      .label('Lot number')
      .max(20)
      .when('expirationDate', {
        is: (expirationDate: string) => !!expirationDate,
        then: schema => schema.required(),
      }),
    expirationDate: string()
      .label('Expiration Date')
      .matches(requireStockInfo ? requiredRegex : notRequiredRegex, 'Invalid date format (MM/YYYY)')
      .test('valid-date', 'Expiration Date must be on or after the current date', value =>
        isValidDate(value, requireStockInfo),
      )
      .when('lot', {
        is: (lot: string) => !!lot,
        then: schema => schema.required(),
      }),
    serialNumber: string()
      .label('Serial number')
      .max(20)
      .nullable()
      .test(
        'not-repeated',
        'Serial number already scanned',
        value => !stockInfo.some(({ serialNumber }) => serialNumber === value),
      ),
  }

  if (requireStockInfo) {
    schemaShape.expirationDate = schemaShape.expirationDate.required()
    schemaShape.lot = schemaShape.lot.required().defined()
  }

  const ManualEntryFillFormSchema = object().shape(schemaShape, [
    ['expirationDate', 'lot'],
    ['lot', 'expirationDate'],
  ])

  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors, isValid },
  } = useForm<ManualEntryFill>({
    defaultValues,
    resolver: yupResolver(requireDataMatrix ? ManualEntryFillFormSchema : ndcFormSchema),
    mode: 'onChange',
  })

  const formatExpirationDate = (value: string) => {
    const numericValue = value.replace(/\D/g, '')

    if (numericValue.length > 2) {
      return `${numericValue.slice(0, 2)}/${numericValue.slice(2)}`
    } else {
      return numericValue
    }
  }

  const onSubmit = ({ lot, expirationDate: expirationDateMonthYear, serialNumber }: ManualEntryFill) => {
    let expirationDate = expirationDateMonthYear
    if (expirationDateMonthYear) {
      const [month, year] = expirationDateMonthYear.split('/')
      const lastDayMonth = getLastDayOfMonth({ month: Number(month), year: Number(year) })
      expirationDate = `${year}-${month}-${lastDayMonth}`
    }
    confirmationCallback({ lot, expirationDate, serialNumber: serialNumber || null })
    dismissModal()
  }

  return (
    <Modal
      aria-label="ManualEntryFillModal"
      aria-labelledby="title"
      overlayCss={{ zIndex: 999, background: 'rgba(24, 39, 75, 0.08)' }}
      css={{ width: '27rem', padding: '1.5rem' }}
      isOpen
      onDismiss={dismissModal}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Header variant="2xl" as="h2" bold id="title">
          Product data manual entry
        </Header>
        <Spacer size="xl" />
        <StyledBoxInputs>
          <CustomizedTextField
            required
            label="GTIN (NDC)"
            placeholder="Enter NDC"
            {...register('ndc')}
            state={errors.ndc ? 'error' : 'default'}
            helperText={errors.ndc?.message}
          />
          {requireDataMatrix && (
            <>
              <CustomizedTextField
                required={requireStockInfo}
                label="Batch/lot number"
                placeholder="Enter batch/lot number"
                {...register('lot')}
                state={errors.lot ? 'error' : 'default'}
                helperText={errors.lot?.message}
              />
              <CustomizedTextField
                required={requireStockInfo}
                label="Expiration Date"
                {...register('expirationDate')}
                onChange={e => {
                  const formattedValue = formatExpirationDate(e.target.value)
                  setValue('expirationDate', formattedValue)
                  trigger('expirationDate')
                }}
                state={errors.expirationDate ? 'error' : 'default'}
                helperText={errors.expirationDate?.message}
                maxLength={7}
                placeholder="MM/YYYY"
              />
              <CustomizedTextField
                label="Serial number"
                placeholder="Enter serial number"
                {...register('serialNumber')}
                state={errors.serialNumber ? 'error' : 'default'}
                helperText={errors.serialNumber?.message}
              />
            </>
          )}
        </StyledBoxInputs>
        <StyledBoxButtons>
          <Button size="sm" type="button" variant="primary-text" onClick={dismissModal}>
            Cancel
          </Button>
          <Button size="sm" type="submit" disabled={!isValid} data-testid="save">
            Submit
          </Button>
        </StyledBoxButtons>
      </form>
    </Modal>
  )
}

const StyledBoxButtons = styled(Box)`
  display: flex;
  justify-content: flex-end;
  margin-top: 1.5rem;
`

const StyledBoxInputs = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export default ManualEntryFillModal
