import { useEffect, useMemo, useState } from 'react'
import type { RouteComponentProps } from '@truepill/tpos-react-router'
import { useQuery, useMutation, useLocation } from '@truepill/tpos-react-router'
import { SaveButton } from 'components/ActionButton'
import { FilledFormHeader } from 'components/ColumnForm'
import LoadingSpinner from 'components/Loading'
import { CancelButton } from 'components/PageStructure'
import { GET_PAYER, CREATE_NEW_PAYER, UPDATE_PAYER } from 'gql'
import useErrorToast from 'hooks/toast/useErrorToast'
import useSetPageTitle from 'hooks/useSetPageTitle'
import { usePlusClient } from 'providers/VisionRouter'
import type { InsuranceProvider } from 'types'
import { stripTypename, formatPhoneInput } from 'utils'
import PayerContactForm from './components/PayerContactForm'
import PayerDetailsForm from './components/PayerDetailsForm'
import PayerMinimumProfitRejectionForm from './components/PayerMinimumProfitRejectionForm'
import PayerOptionsForm from './components/PayerOptionsForm'
import PayerPricingForm from './components/PayerPricingForm'
import PayerReconciliationForm from './components/PayerReconciliationForm'
import {
  ExpandedActionButtonContainer,
  LoadingSpinnerContainer,
  PayerContactColumn,
  PayerDetailsColumn,
  PayerOptionsColumn,
  PayerOthersColumn,
  StyledFormPage,
  StyledFormPageConfigurations,
  Title,
  TitleRow,
} from './StyledComponents'

type PayerEditPageType = RouteComponentProps<{ payerId?: string }>

const PayerEditPage = (props: PayerEditPageType): JSX.Element => {
  const {
    match: {
      params: { payerId },
    },
  } = props
  const { routeTo } = usePlusClient()
  const { search } = useLocation()
  const showConfigurations: boolean = search ? search.split('=')[1] === 'true' : false
  const showErrorToast = useErrorToast()

  const [createNewPayer] = useMutation(CREATE_NEW_PAYER)
  const [updatePayer] = useMutation(UPDATE_PAYER)

  // If someone navigates straight to the edit page we will need to populate the state
  const { data, error, loading } = useQuery(GET_PAYER, {
    skip: !payerId,
    variables: {
      payerId: payerId,
    },
  })

  const [payerForm, setPayerForm] = useState<InsuranceProvider>({} as InsuranceProvider)

  const pageTitle = useMemo(() => {
    if (!payerId) return 'New payer'

    const payer = data?.getInsuranceProvider

    return !payer ? 'Edit payer' : `Edit payer - ${payer.name}`
  }, [data?.getInsuranceProvider, payerId])

  useSetPageTitle(pageTitle)

  useEffect(() => {
    if (!loading) {
      const payer = data ? data.getInsuranceProvider : {}

      const formattedPayer = {
        ...payer,
        helpdeskNumber: formatPhoneInput(payer.helpdeskNumber),
      }
      setPayerForm(formattedPayer)
    }
  }, [data, loading])

  const updatePayerForm = (update: InsuranceProvider) => {
    setPayerForm({ ...payerForm, ...update })
  }

  const cleanFormBeforeSave = (payerFormData: InsuranceProvider) => {
    return {
      ...payerFormData,
      helpdeskNumber: payerFormData.helpdeskNumber?.replace(/[^\d.]/g, ''),
    }
  }

  if (error) {
    return (
      <>
        <Title>Edit Payer</Title>
        <p> Error: Failed to get Payer Data {JSON.stringify(error)}</p>
      </>
    )
  }

  if (loading) {
    return (
      <>
        <Title>Edit Payer</Title>
        <LoadingSpinnerContainer>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      </>
    )
  }

  return (
    <>
      <TitleRow>
        <Title>{payerId ? <>Edit</> : <>New</>} payer</Title>
        <ExpandedActionButtonContainer>
          <CancelButton
            data-testid="cancel"
            label="Cancel"
            onClick={() => {
              if (payerId) {
                routeTo.payer(payerId).now()
                return
              }

              routeTo.payers().now()
            }}
          />
          <SaveButton
            label={payerId ? 'Update' : 'Create'}
            disabled={payerId ? false : Object.keys(payerForm).length === 0 || !payerForm.bin}
            onClick={async () => {
              if (payerId) {
                const { _id, ...rest } = cleanFormBeforeSave(payerForm)

                const provider = {
                  ...rest,
                  address: rest.address?.street1 ? rest.address : undefined,
                }

                const updatePayerVariables = {
                  variables: {
                    payerId,
                    insuranceProvider: stripTypename(provider),
                  },
                }

                try {
                  await updatePayer(updatePayerVariables)
                  routeTo.payer(payerId).now()
                } catch (error) {
                  showErrorToast(`Failed to update payer: ${error.message}`)
                }
              } else {
                const newPayerVariables = {
                  variables: {
                    insuranceProvider: stripTypename(payerForm),
                  },
                }
                try {
                  const response = await createNewPayer(newPayerVariables)
                  if (response.data?.createInsuranceProvider._id) {
                    routeTo.payer(response.data.createInsuranceProvider._id).now()
                  }
                } catch (error) {
                  showErrorToast(`Failed to create payer: ${error.message}`)
                }
              }
            }}
          />
        </ExpandedActionButtonContainer>
      </TitleRow>

      {!showConfigurations && (
        <StyledFormPage>
          <PayerDetailsColumn data-test-column="payer-details">
            <FilledFormHeader>Details</FilledFormHeader>
            <PayerDetailsForm payerForm={payerForm} updatePayerForm={updatePayerForm} />
          </PayerDetailsColumn>
          <PayerContactColumn data-test-column="payer-contact">
            <FilledFormHeader>Contact</FilledFormHeader>
            <PayerContactForm payerForm={payerForm} updatePayerForm={updatePayerForm} />
          </PayerContactColumn>
        </StyledFormPage>
      )}
      {showConfigurations && (
        <StyledFormPageConfigurations>
          <PayerOptionsColumn data-test-column="payer-details">
            <FilledFormHeader>Options</FilledFormHeader>
            <PayerOptionsForm payerForm={payerForm} updatePayerForm={updatePayerForm} />
          </PayerOptionsColumn>
          <PayerOthersColumn data-test-column="payer-contact">
            <FilledFormHeader>Pricing</FilledFormHeader>
            <PayerPricingForm payerForm={payerForm} updatePayerForm={updatePayerForm} />
            <FilledFormHeader>Reconciliation</FilledFormHeader>
            <PayerReconciliationForm payerForm={payerForm} updatePayerForm={updatePayerForm} />
            <FilledFormHeader>Minimum profit rejection</FilledFormHeader>
            <PayerMinimumProfitRejectionForm payerForm={payerForm} updatePayerForm={updatePayerForm} />
          </PayerOthersColumn>
        </StyledFormPageConfigurations>
      )}
    </>
  )
}

export default PayerEditPage
