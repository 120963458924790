import { Link } from '@truepill/tpos-react-router'
import { FilledHeadingStyle } from 'components/PageStructure'
import Lozenge from 'components/Tiles/Lozenge'
import styled, { css } from 'styled-components'
import EllipsisTruncate from 'styles/EllipsisTruncate'
import { contrastBackgroundColor, contrastColor, primaryColorLight } from 'styles/styleVariables'

const TrucateTextPosition = css`
  display: block;
  margin-top: 0.5rem;
`

const OrderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-bottom: 0.25rem solid ${contrastColor};
  border-radius: 0.25rem;
  flex: 1;
  width: 100%;
`

const OrdersPagingContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  margin-left: 1.875rem;
  margin-right: 1.875rem;
`

const StyledOrderContainer = styled(OrderContainer)`
  :last-child {
    border-bottom: none;
  }
`

const StyledLink = styled(Link)`
  :hover {
    background-color: ${primaryColorLight};
    :nth-of-type(2n + 1) {
      background-color: ${primaryColorLight};
    }
  }
  :nth-of-type(2n + 1) {
    background-color: ${contrastBackgroundColor};
  }
`

const PaddingContainer = styled.div`
  min-height: 480px;
  width: 100%;
`

const OrderRow = styled.ul`
  display: grid;
  grid-template-rows: [content] 2.5rem;
  grid-template-columns:
    [orderToken] minmax(6rem, 9rem) [refNumber] minmax(6rem, 10rem) [customer] minmax(5rem, 8rem) [createdAt] minmax(
      11rem,
      14rem
    )
    [rxNumbers] minmax(4rem, 9rem)
    [species] 2.5rem
    [patient] minmax(5rem, 10rem)
    [dob] minmax(7rem, 1.6fr) [shipping] minmax(4rem, 8rem)
    [shipDate] minmax(4rem, 8rem)
    [trackingNumber] minmax(6rem, 3fr) [status] minmax(7rem, 12rem);
  padding-left: 1rem;
  padding-right: 0rem;
`

const TitleOrderRow = styled(OrderRow)`
  position: sticky;
  top: 0rem;
  ${FilledHeadingStyle}
  font-weight: 500;
  padding-right: 0rem;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  padding-left: 0.9rem;
  border-radius: 0.25rem 0.25rem 0rem 0rem;
`

const OrderRowCell = styled.li`
  grid-row: 1;
  ${EllipsisTruncate}
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 0.3125rem;
  margin-right: 0.3125rem;
`

const OrderToken = styled(OrderRowCell)`
  grid-column: orderToken;
`

const RefNumber = styled(OrderRowCell)`
  grid-column: refNumber;
  ${TrucateTextPosition};
`

const Truncatable = styled.p`
  ${EllipsisTruncate}
`

const CreatedAt = styled(OrderRowCell)`
  grid-column: createdAt;
`

const Species = styled(OrderRowCell)`
  grid-column: species;
`

const Patient = styled(OrderRowCell)`
  grid-column: patient;
  ${TrucateTextPosition};
`

const DOB = styled(OrderRowCell)`
  grid-column: dob;
  ${TrucateTextPosition};
`

const Shipping = styled(OrderRowCell)`
  grid-column: shipping;
  ${TrucateTextPosition};
`

const ShipDate = styled(OrderRowCell)`
  grid-column: shipDate;
  ${TrucateTextPosition};
`

const TrackingNumber = styled(OrderRowCell)`
  grid-column: trackingNumber;
  ${TrucateTextPosition};
`

const RXNumbers = styled(OrderRowCell)`
  grid-column: rxNumbers;
  ${TrucateTextPosition};
`

const Customer = styled(OrderRowCell)`
  grid-column: customer;
  ${TrucateTextPosition};
`

const Status = styled(OrderRowCell)`
  grid-column: status;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  ${Lozenge}:first-child {
    margin-left: 0;
    margin-right: 0.5rem;
  }
  ${Lozenge}:last-child {
    margin-left: 0;
    margin-right: 0;
  }
  @media screen and (min-width: 1640px) {
    flex-wrap: nowrap;
  }
`

const LoadingSpinnerContainer = styled.div`
  display: flex;
  padding-top: 4rem;
  position: relative;
  justify-content: center;
  svg {
    height: 126px;
  }
`

export {
  OrderContainer,
  OrdersPagingContainer,
  StyledOrderContainer,
  StyledLink,
  PaddingContainer,
  OrderRow,
  TitleOrderRow,
  OrderToken,
  RefNumber,
  Truncatable,
  CreatedAt,
  Patient,
  Species,
  DOB,
  Shipping,
  ShipDate,
  TrackingNumber,
  RXNumbers,
  Customer,
  Status,
  LoadingSpinnerContainer,
}
