import React, { useCallback, useEffect, useState } from 'react'
import { Button, Grid, GridItem, Autocomplete, Checkbox } from '@truepill/react-capsule'
import CustomerCard from 'components/CustomerCard'
import CustomizedTextField from 'components/CustomizedTextField'
import { useFormData } from 'hooks/useFormData'
import type { FormUpdate } from 'providers/Store/FormDataStore'
import { useTPCacheContext } from 'providers/TPCacheProvider'
import styled from 'styled-components'
import type { Customer } from 'types'

interface InventoryGroupFormProps {
  onSubmit?: (pioneerAlias: string, customerId: string, active: boolean) => void
  buttonText: string
}

interface TextInputProps {
  value?: string
  setter: (value: FormUpdate) => void
}

const PioneerTextInput = React.memo(({ value, setter }: TextInputProps): JSX.Element => {
  return (
    <CustomizedTextField
      value={value}
      onChange={e => {
        setter({
          inventoryGroup: {
            pioneerAlias: {
              $set: e.target.value,
            },
          },
        })
      }}
      label="Pioneer Alias"
      required
      placeholder="Add Pioneer Alias"
    />
  )
})

export const InventoryGroupFormRaw = (): JSX.Element => {
  const {
    state: { formData },
    actions: { updateFormData },
  } = useFormData()
  const { customers } = useTPCacheContext()
  const customerId = formData.inventoryGroup.customerId
  const customer = customers.find(customer => customer._id.toString() === customerId)
  const active = formData.inventoryGroup.active

  const [selectedCustomer, setSelectedCustomer] = useState<Customer | undefined>(customer)
  const [shouldBeActive, setShouldBeActive] = useState<boolean | undefined>(active)

  useEffect(() => {
    if (customer) setSelectedCustomer(customer)
    if (active !== undefined) setShouldBeActive(active)
  }, [customer, active])

  const pioneerAlias = formData.inventoryGroup.pioneerAlias

  return (
    <FormContainer>
      <Grid>
        <GridItem desktop={12} tablet={8}>
          <PioneerTextInput value={pioneerAlias} setter={updateFormData} />
        </GridItem>
        <GridItem desktop={12} tablet={8}>
          <Checkbox
            label={'Inventory group is active?'}
            checked={shouldBeActive}
            onCheckedChange={value => {
              updateFormData({
                inventoryGroup: {
                  active: { $set: Boolean(value) },
                },
              })
              setShouldBeActive(Boolean(value))
            }}
            required={true}
          />
        </GridItem>
        <GridItem desktop={12} tablet={8}>
          {selectedCustomer?._id ? (
            <CustomerCard
              label="Customer"
              customerId={selectedCustomer._id}
              closeCallback={() => setSelectedCustomer(undefined)}
            />
          ) : (
            <Autocomplete
              type="search"
              variant="small"
              label="Customer"
              selectedKey="name"
              options={customers}
              placeholder="Search customer by name"
              value={selectedCustomer as Customer}
              onChange={option => {
                updateFormData({
                  inventoryGroup: {
                    customerId: { $set: option?._id },
                  },
                })
                option && setSelectedCustomer(option)
              }}
            />
          )}
        </GridItem>
      </Grid>
    </FormContainer>
  )
}

export const validateForm = (formData: any): boolean => {
  const pioneerAlias = formData.inventoryGroup?.pioneerAlias || ''
  const customerId = formData.inventoryGroup?.customerId || ''
  const active = formData.inventoryGroup?.active !== undefined

  return !!pioneerAlias && !!customerId && active
}

const InventoryGroupForm = ({ onSubmit, buttonText }: InventoryGroupFormProps): JSX.Element => {
  const {
    state: { formData },
  } = useFormData()

  const customerId = formData.inventoryGroup.customerId ?? ''
  const pioneerAlias = formData.inventoryGroup.pioneerAlias ?? ''
  const active = !!formData.inventoryGroup.active
  const formIsValid = validateForm(formData)

  const handleSubmit = useCallback(() => {
    if (formIsValid) {
      onSubmit?.(pioneerAlias, customerId, active)
    }
  }, [pioneerAlias, customerId, active, formIsValid, onSubmit])

  return (
    <>
      <InventoryGroupFormRaw />
      <ButtonContainer>
        <Button disabled={!formIsValid} onClick={handleSubmit} size="sm">
          {buttonText}
        </Button>
      </ButtonContainer>
    </>
  )
}

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
`

const FormContainer = styled.div`
  margin: 1rem 0;
`

export default InventoryGroupForm
