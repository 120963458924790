import { OrderStatus } from '@truepill/tpos-types'
import { ListRowLabel, ListRowValue, RXCell } from 'components/RXTable'
import { Box } from 'grommet'
import moment from 'moment'
import styled from 'styled-components'
import { contrastBackgroundColor } from 'styles/styleVariables'
import type { Order, Log } from 'types'
import { formatCreatedDate } from 'utils'

type EventLogProps = { logs: Log[]; createdAt?: Order['createdAt'] }

export const EventLog = ({ logs, createdAt }: EventLogProps): JSX.Element => {
  const orderLogEvent = logs
    ? logs.filter(
        log =>
          (log.event === 'change' &&
            log?.change?.field === 'status' &&
            log?.change.oldValue === OrderStatus.Packing &&
            log?.change.newValue === OrderStatus.Complete) ||
          (log.change?.field === 'status' && log.change.newValue === OrderStatus.Shipped),
      )
    : []

  const { date: createdAtDate } = formatCreatedDate(createdAt)

  return (
    <Box>
      <StyledRXCell>
        <ListRowLabel>Created:</ListRowLabel>
        <ListLogValue>{createdAtDate}</ListLogValue>
      </StyledRXCell>
      {orderLogEvent.map(log => {
        const { user } = log

        const logDate = moment(new Date(log.createdAt)).format('MM/DD/YYYY, h:mmA')
        return (
          <StyledRXCell key={log._id}>
            {log?.change?.oldValue === OrderStatus.Packing && log?.change.newValue === OrderStatus.Complete && (
              <>
                <ListRowLabel>Packed:</ListRowLabel>
                <ListLogValue>
                  <span>
                    {user.firstName} {user.lastName}
                  </span>
                  -<span>{logDate}</span>
                </ListLogValue>
              </>
            )}
            {log?.change?.field === 'status' && log?.change.newValue === OrderStatus.Shipped && (
              <>
                <ListRowLabel>Shipped:</ListRowLabel>
                <ListLogValue>
                  <span>
                    {user.firstName} {user.lastName}
                  </span>
                  -<span>{logDate}</span>
                </ListLogValue>
              </>
            )}
          </StyledRXCell>
        )
      })}
    </Box>
  )
}

const ListLogValue = styled(ListRowValue)`
  span {
    margin: 0 0.5rem;
    text-transform: capitalize;
    :first-child {
      margin-left: 0;
    }
  }
  p {
    width: 100%;
    :first-letter {
      text-transform: capitalize;
    }
  }
`

const StyledRXCell = styled(RXCell)`
  grid-template-columns: [label] 6rem [value] 1fr;
  padding-left: 0.625rem;
  :nth-of-type(even) {
    background-color: ${contrastBackgroundColor};
  }
`
