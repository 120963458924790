import { useReducer, useContext, useMemo, createContext } from 'react'
import type { ChildProps } from 'types'

type PackingAdditonalData = {
  upcOptions: string[]
  upcScanned?: string
}

export type TaskProductType = 'RX' | 'OTC' | 'PM'

interface Task {
  label: string
  key: string
  completed: boolean
  code: string
  meta?: string
  productType: TaskProductType
  additionalData?: PackingAdditonalData
  subtasks?: Subtask[]
}

interface Subtask {
  label: string
}

interface TaskReducerState {
  tasks: Task[]
}

type SetTaskCompletionAction = {
  type: 'setTaskCompletion'
  payload: { completed: boolean; key: string; meta?: string }
}

type InitTasksAction = {
  type: 'initTasks'
  payload: Task[]
}

type TaskAction = SetTaskCompletionAction | InitTasksAction

interface TaskProviderContextProps {
  tasks: Task[]
  completeTask: (key: Task['key'], meta?: string) => void
  resetTask: (key: Task['key']) => void
  initTasks: (tasks: Task[]) => void
  nextProductScanTaskIndex: number
  scanProductsTasksCompleted: boolean
}

const TaskReducer = (state: TaskReducerState, action: TaskAction) => {
  const { payload, type } = action
  const { tasks } = state

  if (type === 'initTasks') {
    return { tasks: payload as Task[] }
  }

  if (type === 'setTaskCompletion') {
    const { key, completed, meta } = payload as SetTaskCompletionAction['payload']
    const taskIndex = tasks.findIndex(({ key: taskKey }) => taskKey === key)
    const targetTask = tasks[taskIndex]
    if (targetTask && !targetTask.completed) {
      const newTasks = [...tasks]
      newTasks.splice(taskIndex, 1, {
        ...targetTask,
        key,
        completed,
        meta,
      })
      return { ...state, tasks: newTasks }
    }
  }
  return state
}

const TaskContext = createContext<TaskProviderContextProps | undefined>(undefined)

type TaskProviderProps = { initialTasks: Task[] } & ChildProps

const TaskProvider = ({ initialTasks, children }: TaskProviderProps): JSX.Element => {
  const [{ tasks }, dispatch] = useReducer(TaskReducer, { tasks: initialTasks })

  const value = useMemo(
    () => ({
      tasks,
      completeTask: (key: Task['key'], meta?: string) => {
        dispatch({
          type: 'setTaskCompletion',
          payload: { key, completed: true, meta },
        })
      },
      resetTask: (key: Task['key']) => {
        dispatch({
          type: 'setTaskCompletion',
          payload: { key, completed: false },
        })
      },
      initTasks: (tasks: Task[]) => {
        dispatch({ type: 'initTasks', payload: tasks })
      },
      // product scan task keys are scanProduct-Num after the scanFill task, so
      // find what the next one will be
      nextProductScanTaskIndex: tasks.findIndex(({ key, completed }) => !completed && key.includes('scanProduct')) - 1,
      scanProductsTasksCompleted: tasks
        .filter(({ key }) => key.includes('scanProduct'))
        .every(({ completed }) => completed),
    }),
    [tasks],
  )

  return <TaskContext.Provider value={value}>{children}</TaskContext.Provider>
}

const useTaskContext = (): TaskProviderContextProps => {
  const taskContext = useContext(TaskContext)

  if (taskContext === undefined) {
    throw new Error('Attempting to read TaskContext outside a Provider heirarchy')
  }

  return taskContext
}

export type { Task, Subtask }
export { TaskContext, useTaskContext }

export default TaskProvider
