import type { RefObject } from 'react'
import { useState } from 'react'
import { BannerAlert, Button, Grid, GridItem, Text } from '@truepill/react-capsule'
import CustomizedTextField from 'components/CustomizedTextField'
import DURCard from 'components/DURCard'
import { ModalWrapper, ModalHeader } from 'components/Modal'
import useAutoFocus from 'hooks/useAutoFocus'
import { useYupValidationResolver } from 'hooks/useYupValidationResolver'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import { Controller, useForm } from 'react-hook-form'
import styled, { css } from 'styled-components'
import type { Fill, DUR, RXFillRequest } from 'types'
import { sortDURsBySeverity } from 'utils'
import { validateDEANumber } from 'utils/validateDEANumber'
import * as yup from 'yup'

const randomInt = (max: number): number => {
  return Math.floor(Math.random() * Math.floor(max))
}

const generateTestValue = (): string => {
  let code = ''
  for (let i = 0; i < 4; i++) {
    code += `${randomInt(9)}`
  }
  return code
}

const getPv1ConfirmationSchema = (isControlledSubstance: boolean, testValue: string, prescriberLastName: string) => {
  return yup.object({
    ...(isControlledSubstance && { prescriberStateLicense: yup.string().required() }),
    ...(isControlledSubstance && { pharmacistAttestation: yup.boolean().required().isTrue() }),
    ...(isControlledSubstance && {
      prescriberDeaNumber: yup
        .string()
        .required()
        .test('dea-number', function (value) {
          const valid = validateDEANumber(value ?? '', prescriberLastName)
          if (!valid) {
            return this.createError({
              path: this.path,
              message: 'DEA Number is not valid',
            })
          }
          return true
        }),
    }),
    pinInput: yup
      .string()
      .required()
      .test('dea-number', function (value) {
        if (value !== testValue) {
          return this.createError({
            path: this.path,
            message: 'PIN is not valid',
          })
        }
        return true
      }),
  })
}

type PV1ConfirmationModalProps = {
  isControlledSubstance?: boolean
  fill: Fill
  confirmationCallback: ({
    prescriberStateLicense,
    prescriberDeaNumber,
    pharmacistAttestation,
  }: {
    prescriberStateLicense?: string
    prescriberDeaNumber?: string
    pharmacistAttestation?: boolean
  }) => void
  rxFillRequest: RXFillRequest
}

const PV1ConfirmationModal = ({
  fill,
  rxFillRequest,
  confirmationCallback,
  isControlledSubstance = false,
}: PV1ConfirmationModalProps): JSX.Element => {
  const { dismissModal } = useModalContext()
  const [testValue] = useState(generateTestValue())
  const resolver = useYupValidationResolver(
    getPv1ConfirmationSchema(isControlledSubstance, testValue, rxFillRequest.prescription?.prescriber?.lastName),
  )
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
  } = useForm({ resolver })

  const onSubmit = (formData: {
    prescriberStateLicense?: string
    prescriberDeaNumber?: string
    pharmacistAttestation?: boolean
  }) => {
    const { prescriberStateLicense, prescriberDeaNumber, pharmacistAttestation } = formData

    confirmationCallback({
      prescriberStateLicense,
      prescriberDeaNumber,
      pharmacistAttestation,
    })
    dismissModal()
  }
  const autoFocusRef = useAutoFocus()

  return (
    <ModalWrapper id="PV1ConfirmationModal" styles={ModalWrapperStyles}>
      <ModalHeader>
        <Title>Clinical review confirmation</Title>
      </ModalHeader>
      <ClinicalReviewCardList fill={fill} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid>
          <GridItem desktop={12} tablet={8}>
            <Text>By entering the 4 digit pin, I confirm I have performed a clinical review for this medication.</Text>
          </GridItem>
          <PinContainer desktop={4} tablet={2}>
            <Controller
              name="pinInput"
              control={control}
              render={({ field }) => (
                <CustomizedTextField
                  {...field}
                  width={'50%'}
                  state={errors.pinInput ? 'error' : 'default'}
                  helperText={(errors.pinInput?.message as string) ?? ''}
                  data-testid="dur-pin-input"
                  ref={autoFocusRef as RefObject<HTMLInputElement>}
                />
              )}
            />
            <TestValue data-testid="dur-pin-display">{testValue}</TestValue>
          </PinContainer>
        </Grid>

        {isControlledSubstance && (
          <Grid>
            <GridItem desktop={6} tablet={4}>
              <Controller
                name="prescriberDeaNumber"
                control={control}
                render={({ field }) => (
                  <CustomizedTextField
                    {...field}
                    state={errors.prescriberDeaNumber ? 'error' : 'default'}
                    helperText={(errors.prescriberDeaNumber?.message as string) ?? ''}
                    label="DEA #"
                    data-testid="prescriber-dea-number-input"
                  />
                )}
              />
            </GridItem>
            <GridItem desktop={6} tablet={4}>
              <Controller
                name="prescriberStateLicense"
                control={control}
                render={({ field }) => (
                  <CustomizedTextField
                    {...field}
                    state={errors.prescriberStateLicense ? 'error' : 'default'}
                    helperText={(errors.prescriberStateLicense?.message as string) ?? ''}
                    label="State license"
                    data-testid="prescriber-state-license-input"
                  />
                )}
              />
            </GridItem>
            <AttestationContainer desktop={12} tablet={8}>
              <Controller
                name="pharmacistAttestation"
                control={control}
                render={({ field }) => <input {...field} type="checkbox" data-testid="attestation-statement-input" />}
              />
              <Text>
                I attest that a comprehensive review and analysis of the past 6 months' prescription history and
                controlled substance utilization of the patient has been completed. I confirm that the prescribed
                medication is medically necessary for the patient and attest that there are no additional prescriptions
                for the same medication written on the same day.
              </Text>
            </AttestationContainer>
            <GridItem desktop={12} tablet={8}>
              {errors.pharmacistAttestation && (
                <BannerAlert state="error">
                  <p>Attestation statement is required</p>
                </BannerAlert>
              )}
            </GridItem>
          </Grid>
        )}
        <Grid>
          <ButtonsContainer>
            <Button disabled={!!Object.keys(errors).length || !isDirty || isSubmitting} type="submit">
              Confirm
            </Button>
          </ButtonsContainer>
        </Grid>
      </form>
    </ModalWrapper>
  )
}

const ClinicalReviewCardList = (props: { fill: Fill }) => {
  const screenings = props.fill.durScreenings
  const durs = (screenings && screenings.length && screenings[screenings.length - 1].results) || []

  const sortedDurs: DUR[] = sortDURsBySeverity(durs)

  return (
    <StyledClinicalReviewCardList>
      {sortedDurs.map(dur => (
        <DURCard key={`${dur.status} - ${dur.type}`} {...dur} />
      ))}
    </StyledClinicalReviewCardList>
  )
}

const ModalWrapperStyles = css`
  width: 42.375rem;
`

const TestValue = styled.div`
  border: 0.125rem solid 4e5a70;
  border-radius: 0.25rem;
  color: white;
  background-color: #4e5a70;
  padding: 1rem;
  border-radius: 8px;
`

const StyledClinicalReviewCardList = styled.ul`
  > li {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }
`

const Title = styled.h2`
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
`

const AttestationContainer = styled(GridItem)`
  display: flex;
  gap: 1rem;
  align-items: baseline;
`
const PinContainer = styled(GridItem)`
  display: flex;
  justify-content: center;
  align-items: baseline;
`
const ButtonsContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  grid-column: -1;
`

export default PV1ConfirmationModal
