import { useMemo } from 'react'
import { Box } from '@truepill/react-capsule'
import { Link } from '@truepill/tpos-react-router'
import { FillStatus } from '@truepill/tpos-types'
import type { TableDefinition } from 'components/Table'
import Table from 'components/Table'
import { getFragmentData, gql, type FragmentType } from 'gql/generated'
import type { DuplicatePrescription_PrescriptionFragmentFragment } from 'gql/generated/graphql'
import { goToViewPharmacyPrescription } from 'routes'
import styled from 'styled-components'
import { darkBlue, darkInfo, lightOrange } from 'styles/styleVariables'
import type { BaseTableRow, TableRow } from '../types'
import DiscontinueReasonInputs from './DiscontinueReasonInputs'

const DuplicatePrescription_PrescriptionFragment = gql(`
  fragment DuplicatePrescription_PrescriptionFragment on Prescription {
    _id
    name
    strength {
      value
      form
      unit
    }
    fills {
      status
      dispensed {
        dispensedAt
      }
    }
    customer {
      name
    }
    dosageForm
    ndc
    quantity
    daysSupply
    directions
    rxNumber
    daw
    refillsRemaining
    quantityRemaining
    writtenDate
    expirationDate
    status
  }
`)

const parseStrength = (strength: { value: string | null; unit: string | null } | null | undefined) => {
  return strength ? `${strength.value} ${strength.unit}` : 'N/A'
}

const parseData = (
  data: readonly DuplicatePrescription_PrescriptionFragmentFragment[],
  mainPrescriptionId: string,
): TableRow[] => {
  const mainRx = data.find(rx => rx._id === mainPrescriptionId)
  const mainStrength = parseStrength(mainRx?.strength)
  const mainRxCompletedFills = mainRx?.fills?.filter(f => f?.status === FillStatus.Complete)
  const mainRxLastCompletedFillDate = mainRxCompletedFills?.[mainRxCompletedFills?.length - 1]?.dispensed?.dispensedAt
  const mainRxCustomer = mainRx?.customer?.name

  return data.map(d => {
    const strength = parseStrength(d.strength)
    const completedFills = d.fills?.filter(f => f?.status === FillStatus.Complete)
    const lastCompletedFillDate = completedFills?.[completedFills?.length - 1]?.dispensed?.dispensedAt

    return {
      ...Object.keys(d).reduce((acc, key) => {
        const _key = key as keyof DuplicatePrescription_PrescriptionFragmentFragment // unfortunately ts needs this line to infer types
        return { ...acc, [key]: { value: d[_key], highlight: d[_key] !== mainRx?.[_key] } }
      }, {} as BaseTableRow),
      id: d._id,
      strength: { value: strength, highlight: strength !== mainStrength },
      lastFillDate: {
        value: lastCompletedFillDate ? lastCompletedFillDate : 'N/A',
        highlight: lastCompletedFillDate !== mainRxLastCompletedFillDate,
      },
      customer: { value: d.customer?.name ?? 'N/A', highlight: d.customer?.name !== mainRxCustomer },
      meta: {
        isMainPrescription: mainPrescriptionId === d._id,
      },
    }
  })
}

const StyledLink = styled(Link)`
  &:hover {
    text-decoration: underline;
    color: ${darkBlue};
  }
`

const RowValue = ({ value, highlight }: { value: unknown; highlight: boolean }) => (
  <Box>
    <span
      style={{
        backgroundColor: highlight ? lightOrange : 'transparent',
        fontWeight: highlight ? 'bold' : 'normal',
        borderRadius: '0.5rem',
        padding: '0px 4px 0px 4px',
      }}
    >
      {value}
    </span>
  </Box>
)

const tableDefinition: TableDefinition<TableRow>[] = [
  {
    headerName: '',
    width: '2.5rem',
    customRender: item => (!item.meta.isMainPrescription ? <DiscontinueReasonInputs item={item} /> : <Box></Box>),
  },
  {
    field: 'name',
    headerName: 'Medication',
    width: '6rem',
    customRender: item => <RowValue {...item.name} />,
  },
  {
    field: 'strength',
    headerName: 'Strength',
    width: '4rem',
    customRender: item => <RowValue {...item.strength} />,
  },
  {
    field: 'dosageForm',
    headerName: 'Form',
    width: '4rem',
    customRender: item => <RowValue {...item.dosageForm} />,
  },
  { field: 'ndc', headerName: 'Ndc', width: '6.5rem', customRender: item => <RowValue {...item.ndc} /> },
  { field: 'quantity', headerName: 'Qty', width: '1.5rem', customRender: item => <RowValue {...item.quantity} /> },
  { field: 'daysSupply', headerName: 'DS', width: '1.5rem', customRender: item => <RowValue {...item.daysSupply} /> },
  {
    field: 'directions',
    headerName: 'Sig',
    width: '12rem',

    customRender: item => <RowValue {...item.directions} />,
  },
  {
    field: 'rxNumber',
    headerName: 'Rx #',
    width: '4rem',
    customRender: item => (
      <StyledLink target="_blank" to={goToViewPharmacyPrescription({ prescriptionId: `${item.id}` })}>
        {item.rxNumber.value}
      </StyledLink>
    ),
  },
  {
    field: 'customer',
    headerName: 'Customer',
    width: '4rem',
    customRender: item => <RowValue {...item.customer} />,
  },
  { field: 'daw', headerName: 'Daw', width: '2rem', customRender: item => <RowValue {...item.daw} /> },
  {
    field: 'refillsRemaining',
    headerName: 'Refills Left',
    width: '3rem',
    customRender: item => <RowValue {...item.refillsRemaining} />,
  },
  {
    field: 'quantityRemaining',
    headerName: 'Qty Left',
    width: '3rem',
    customRender: item => <RowValue {...item.quantityRemaining} />,
  },
  {
    field: 'writtenDate',
    headerName: 'Written date',
    width: '3rem',
    customRender: item => <RowValue {...item.writtenDate} />,
  },
  {
    field: 'expirationDate',
    headerName: 'Exp date',
    width: '3rem',
    customRender: item => <RowValue {...item.expirationDate} />,
  },
  {
    field: 'lastFillDate',
    headerName: 'Last filled',
    width: '3rem',
    customRender: item => <RowValue {...item.lastFillDate} />,
  },
  { field: 'status', headerName: 'Status', width: '6rem', customRender: item => <RowValue {...item.status} /> },
]

const moveMainFirst = (
  rxs: readonly DuplicatePrescription_PrescriptionFragmentFragment[],
  mainPrescriptionId: string,
) => {
  if (!rxs.length) return []

  const idx = rxs.findIndex(rx => rx._id === mainPrescriptionId)
  const rxsClone = [...rxs]
  const element = rxsClone.splice(idx, 1)[0]
  rxsClone.unshift(element)

  return rxsClone
}

const DuplicatePrescriptionsTable = ({
  prescriptions,
  loading,
  mainPrescriptionId,
}: {
  mainPrescriptionId: string
  prescriptions: FragmentType<typeof DuplicatePrescription_PrescriptionFragment>[]
  loading?: boolean
}): JSX.Element => {
  const rxs = getFragmentData(DuplicatePrescription_PrescriptionFragment, prescriptions)
  const sortedRxs = useMemo(() => moveMainFirst(rxs, mainPrescriptionId), [mainPrescriptionId, rxs])
  const parsedData = useMemo(() => parseData(sortedRxs ?? [], mainPrescriptionId), [mainPrescriptionId, sortedRxs])

  return (
    <Table
      headerStyle={{ border: 'none', alignItems: 'center', textAlign: 'center', gap: '0.625rem' }}
      data={parsedData}
      keyField="id"
      getCustomRowStyle={item =>
        item.id === mainPrescriptionId
          ? {
              background: '#F7FCFF',
              border: ` 1px solid ${darkInfo}`,
              borderRadius: '0.5rem',
              alignItems: 'center',
              textAlign: 'center',
              gap: '0.625rem',
            }
          : { alignItems: 'center', textAlign: 'center', gap: '0.625rem' }
      }
      loading={loading}
      definition={tableDefinition}
    />
  )
}

export default DuplicatePrescriptionsTable
