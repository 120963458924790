import type { ReactComponentElement } from 'react'
import type AccuracyScanningItem from 'components/AccuracyScanningItem'
import styled from 'styled-components'
import { contrastBackgroundColor } from 'styles/styleVariables'

const AccuracyScanningList = (props: {
  loading: boolean
  children?: ReactComponentElement<typeof AccuracyScanningItem>[]
}): ReactComponentElement<typeof ListContainer> => {
  const { loading, children } = props

  if (loading) {
    return <ListContainer />
  }

  return <ListContainer>{children}</ListContainer>
}

export default AccuracyScanningList

const ListContainer = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  background-color: ${contrastBackgroundColor};
  border-radius: 8px;
  padding: 4px;
  vertical-align: top;
`
