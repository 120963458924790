import { useMemo } from 'react'
import type { Prescriber } from 'types'
import { validatePrescriberForm } from 'utils'

type usePrescriberFormValidationType = { isValid: boolean; errors: Record<string, string> }

const usePrescriberFormValidation = (prescriber: Partial<Prescriber>): usePrescriberFormValidationType => {
  const validation = useMemo(() => {
    return validatePrescriberForm(prescriber)
  }, [prescriber])
  return validation
}

export default usePrescriberFormValidation
