import { useMutation } from '@truepill/tpos-react-router'
import { FillStatus } from '@truepill/tpos-types'
import { ReactComponent as TimeIcon } from 'assets/icons/time.svg'
import ActionButton from 'components/ActionButton'
import IconWrapper from 'components/IconWrapper'
import { SEND_BACK_TO_ADJUDICATION, SEND_BACK_TO_PENDING } from 'gql'
import { useOrderLock } from 'hooks'
import useErrorToast from 'hooks/toast/useErrorToast'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import { bodyPrimaryColor } from 'styles/styleVariables'
import type { Fill, PriorAuthorization, QueueItem } from 'types'
import { getErrorMessage, isOrder } from 'utils'

type SendBackActionButtonProps = {
  item: QueueItem
  fill: Fill
  priorAuthorization?: PriorAuthorization
  isPriorAuthorization: boolean
  isRejectedOrder: boolean
  isManualPriorAuth: boolean
}

export const SendBackActionButton = ({
  item,
  fill,
  isManualPriorAuth,
  isPriorAuthorization,
  isRejectedOrder,
  priorAuthorization,
}: SendBackActionButtonProps): JSX.Element => {
  const showErrorToast = useErrorToast()
  const showSuccessToast = useSuccessToast()

  const [sendBackToAdjudication] = useMutation(SEND_BACK_TO_ADJUDICATION, {
    refetchQueries: ['getBasicOrder'],
  })

  const [sendBackToPending] = useMutation(SEND_BACK_TO_PENDING, {
    refetchQueries: ['getBasicOrder'],
  })

  const isOrderItem = isOrder(item)
  const { orderEditable } = useOrderLock(item._id, !isOrderItem)

  if ((!isManualPriorAuth && !isPriorAuthorization) || isRejectedOrder) {
    return <></>
  }

  const priorAuthOpenStatus = priorAuthorization?.prescription?.fills.some(fill =>
    [FillStatus.InFulfillment, FillStatus.CopayCheck].includes(fill.status),
  )

  return (
    <ActionButton
      data-testid="sendToAdjudication"
      disabled={!orderEditable}
      icon={
        <IconWrapper>
          <TimeIcon fill={bodyPrimaryColor} />
        </IconWrapper>
      }
      label={'Send Back'}
      onClick={async () => {
        if (isPriorAuthorization && !priorAuthOpenStatus) {
          showErrorToast('Original copay/order no longer active')
        } else {
          if (isOrder(item)) {
            try {
              const variables = {
                variables: {
                  orderId: item._id,
                  fillId: fill?._id,
                },
              }
              await sendBackToAdjudication(variables)
              showSuccessToast('Successfully sent back to Adjudication')
            } catch (e) {
              showErrorToast(getErrorMessage(e))
              console.error(e)
            }
          } else {
            try {
              const variables = {
                variables: {
                  copayRequestId: item._id,
                  fillId: fill?._id,
                },
              }
              await sendBackToPending(variables)
              showSuccessToast('Successfully sent back to Pending')
            } catch (e) {
              showErrorToast(getErrorMessage(e))
              console.error(e)
            }
          }
        }
      }}
    />
  )
}
