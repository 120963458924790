import { useEffect } from 'react'
import { CopayStatus, LaunchDarkly } from '@truepill/tpos-types'
import ActionButton, { SaveButton } from 'components/ActionButton'
import ExternalRedirectButton from 'components/ExternalRedirectButton'
import { ActionButtonContainer, CancelButton } from 'components/PageStructure'
import TriageOrResolveTriageButton from 'components/TriageOrResolveTriageButton'
import useErrorToast from 'hooks/toast/useErrorToast'
import useEditMode from 'hooks/useEditMode'
import useEditValue from 'hooks/useEditValue'
import RejectOrderModal from 'modals/RejectOrderModal'
import { useFlag, useClient } from 'providers/LaunchDarklyProvider'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import type { CopayRequest } from 'types'
import { RerouteButton } from './components'

type CopayViewButtonsType = {
  onCancel: () => void
  copayRequest: CopayRequest
  disabled?: boolean
}

const ActionButtons = ({ onCancel, copayRequest, disabled }: CopayViewButtonsType): JSX.Element => {
  const showErrorToast = useErrorToast(true)
  const { showModal } = useModalContext()
  const [editMode, setEditMode] = useEditMode()
  const [editValue, setEditValue] = useEditValue()
  const { status, customer, coreCopayToken } = copayRequest
  const hideButtons = status === CopayStatus.Rejected || status === CopayStatus.Confirmed
  const inTriage = status === CopayStatus.Triage
  const isComplete = status === CopayStatus.Complete

  const { client: ldClient } = useClient()

  const customerLegacyId = customer?.legacyId

  useEffect(() => {
    if (customerLegacyId) {
      ldClient?.identify({ key: customerLegacyId.toString() })
    }
  }, [ldClient, customerLegacyId])

  const displayNewCopayCheckButton: boolean = useFlag(LaunchDarkly.FeatureFlags.DISPLAY_NEW_COPAY_CHECK_BUTTON)

  if (editMode || editValue) {
    return (
      <ActionButtonContainer>
        <CancelButton
          label="Cancel"
          disabled={disabled}
          onClick={() => {
            onCancel()
            if (editMode) {
              setEditMode(false)
            } else {
              setEditValue(undefined)
            }
          }}
        />
        <SaveButton label="Update" disabled={disabled} />
      </ActionButtonContainer>
    )
  }
  return (
    <ActionButtonContainer>
      {!hideButtons && (
        <>
          {displayNewCopayCheckButton && coreCopayToken && (
            <ExternalRedirectButton entity="copay" identifier={coreCopayToken} altText="New Copay Check" />
          )}
          {!isComplete && <RerouteButton copayRequest={copayRequest} disabled={disabled} />}
          {!inTriage && !isComplete && (
            <TriageOrResolveTriageButton item={copayRequest} forceShowTriage disabled={disabled} />
          )}
          <ActionButton
            label="Reject Copay"
            disabled={disabled}
            onClick={() =>
              copayRequest
                ? showModal(() => <RejectOrderModal item={copayRequest} isCopay={true} />)
                : showErrorToast('Copay request cannot be rejected.')
            }
          />
        </>
      )}
    </ActionButtonContainer>
  )
}
export default ActionButtons
