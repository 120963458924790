import { ReactComponent as Danger } from 'assets/icons/danger.svg'
import IconWrapper from 'components/IconWrapper'
import Lozenge from 'components/Tiles/Lozenge'
import styled from 'styled-components'
import { warningYellow, bodyPrimaryColor } from 'styles/styleVariables'

const QtyMissmatch = (): JSX.Element => (
  <StyledLozenge data-testid="qty-missmatch" backgroundColor={warningYellow}>
    <StyledIcon>
      <Danger fill={bodyPrimaryColor} />
    </StyledIcon>
    &nbsp; REQUESTED QTY ≠ PRESCRIBED QTY
  </StyledLozenge>
)

const StyledLozenge = styled(Lozenge)`
  padding: 4px, 6px;
  border-radius: 4px;
  height: 20px;
  margin-left: 20px;
`

const StyledIcon = styled(IconWrapper)`
  width: 16px;
  height: 16px;
  margin: 0;
`

export default QtyMissmatch
