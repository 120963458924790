import { useQuery } from '@truepill/tpos-react-router'
import { GET_BASIC_COPAY } from 'gql'
import { useTPCacheContext } from 'providers/TPCacheProvider'
import type { GuestPatient, CopayRequest, Patient, Log } from 'types'
import type { LoadingError } from './types'
import usePatient from './usePatient'

export interface BasicCopay extends LoadingError {
  copayRequest?: CopayRequest
  notes?: Log[]
  patient?: Patient
  guestPatient?: GuestPatient
  apiRequest: any
}

const CopayRequestDefaults: BasicCopay = {
  copayRequest: undefined,
  apiRequest: {},
}

/**
 * @param orderId If undefined, an undefined copay request will be returned. This is to
 *                allow for use with components that have a copay request in some
 *                cases but not others (like `ReviewPrescription`).
 */
export default function useCopayRequest(copayRequestId?: string): BasicCopay {
  const { getCustomerById, getLocationById } = useTPCacheContext()

  const fullCopay = useQuery(GET_BASIC_COPAY, {
    skip: !copayRequestId,
    variables: { copayRequestId },
  })

  const { loading, error, data } = fullCopay

  const patientHookProps = usePatient({
    loading,
    error,
    patient: data?.getCopay?.patient,
    guestPatient: data?.getCopay?.guestPatient,
  })

  if (loading || error || patientHookProps.loading || patientHookProps.error)
    return {
      loading: loading || patientHookProps.loading,
      error: error || patientHookProps.error,
    } as BasicCopay

  let value: BasicCopay = { ...CopayRequestDefaults }

  if (copayRequestId) {
    const cr = data.getCopay as CopayRequest
    const copayRequest = Object.freeze({
      ...cr,
      customer: getCustomerById(cr.customerId),
      location: getLocationById(cr.locationId),
    })

    const { patient, apiRequest } = copayRequest

    value = Object.assign({}, value, {
      loading,
      error,
      copayRequest: Object.freeze(copayRequest),
      apiRequest: apiRequest ? Object.freeze(JSON.parse(apiRequest as unknown as string)) : undefined,
      copayStatus: copayRequest.status,
      ...(patient && { patient }),
    })
  }

  return value
}
