import { useEffect } from 'react'
import { BannerAlert, Text } from '@truepill/react-capsule'
import { useMutation } from '@truepill/tpos-react-router'
import LoadingSpinner from 'components/Loading'
import { ModalHeader, ModalTitle, ModalWrapper } from 'components/Modal'
import { CREATE_INVENTORY_GROUP } from 'gql'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import { useFormData } from 'hooks/useFormData'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import styled from 'styled-components'
import InventoryGroupForm from './InventoryGroupForm'

const AddInventoryGroupModal = (): JSX.Element => {
  return (
    <ModalWrapper styles={[{ width: '31rem', minHeight: '35rem' }]} id="addInventoryGroupModal">
      <ModalHeader>
        <ModalTitle>Add Inventory Group</ModalTitle>
      </ModalHeader>
      <AddInventoryGroupModalContent />
    </ModalWrapper>
  )
}

const AddInventoryGroupModalContent = (): JSX.Element => {
  const [createInventoryGroup, { data, loading, error }] = useMutation(CREATE_INVENTORY_GROUP, {
    refetchQueries: ['listInventoryGroups'],
  })
  const { dismissModal } = useModalContext()
  const showSuccessToast = useSuccessToast()
  const {
    actions: { clearFormData },
  } = useFormData()

  useEffect(() => {
    clearFormData?.()
  }, [clearFormData])

  useEffect(() => {
    if (data) {
      showSuccessToast('Inventory Group created succesfully.')
      dismissModal()
    }
  }, [data, dismissModal, showSuccessToast])

  if (loading) {
    return (
      <LoadingSpinnerContainer>
        <LoadingSpinner />
      </LoadingSpinnerContainer>
    )
  }

  return (
    <>
      {error && (
        <BannerAlert state="error">
          <Text variant="body-sm">{error.message}</Text>
        </BannerAlert>
      )}
      <InventoryGroupForm
        buttonText="Add"
        onSubmit={(pioneerAlias: string, customerId: string, active: boolean) => {
          createInventoryGroup({
            variables: {
              createInventoryGroupInput: {
                pioneerAlias,
                customerId,
                active,
              },
            },
          })
        }}
      />
    </>
  )
}

const LoadingSpinnerContainer = styled.div`
  width: 3rem;
  margin: 1rem auto;
`

export default AddInventoryGroupModal
