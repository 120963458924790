import { useState, useEffect } from 'react'
import { TextInput } from 'grommet'
import styled, { css } from 'styled-components'
import { alertRed, borderColor, subduedColor, primaryBackgroundColor } from 'styles/styleVariables'

interface NumericInputProps {
  className?: string
  value?: number
  alert?: boolean
  alertMessage?: string
  disabled?: boolean
  placeholder?: string
  allowFloat?: boolean
  onChange?: (newNumber?: number) => void
  onBlur?: (newNumber?: number) => void
  fadeOnZeroValue?: boolean
}

const NumericInput = ({
  value,
  onChange,
  onBlur,
  className,
  allowFloat = true,
  fadeOnZeroValue,
  disabled,
  alert,
  alertMessage,
  placeholder,
}: NumericInputProps): JSX.Element => {
  const [error, setError] = useState<string>()
  const [_value, _setValue] = useState<string>(value?.toString() || '')

  useEffect(() => {
    if (value?.toString() !== _value) {
      _setValue(value?.toString() || '')
    }
    // This intentionally only fires when `value` (and not `_value`) changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  useEffect(() => {
    setError(alertMessage || '')
  }, [alertMessage])

  return (
    <>
      <StyledNumberInput
        className={className}
        error={!!error}
        alert={alert}
        value={_value}
        disabled={disabled}
        placeholder={placeholder}
        type="number"
        pattern="[0-9]"
        onChange={e => {
          const parsed = parseFloat(e.target.value)
          const isNumber = !isNaN(parsed)
          if (e.target.value.length <= 11) {
            _setValue(e.target.value)
            if (isNumber) {
              onChange?.(parsed)
            }
          }
        }}
        fade={fadeOnZeroValue && !value}
        onBlur={async () => {
          const parsed = value || 0

          if (isNaN(parsed)) {
            setError('Invalid number')
          } else {
            const valueStr = parsed.toString()

            if (!allowFloat) {
              await onBlur?.(parseInt(valueStr))
              onChange?.(parseInt(valueStr))
            } else {
              await onBlur?.(parseFloat(valueStr))
              onChange?.(parseFloat(valueStr))
            }
          }
        }}
      />
      {error && <ErrorText>{error}</ErrorText>}
    </>
  )
}

const ErrorStyle = css`
  color: ${alertRed};
  border-color: ${alertRed};
`

const Fade = css`
  color: ${subduedColor};
`

export const StyledNumberInput = styled(TextInput)<{
  error?: boolean
  fade?: boolean
  alert?: boolean
}>`
  ${({ fade }) => fade && Fade}
  ${({ error }) => error && ErrorStyle}
  ${({ alert }) => alert && ErrorStyle}
  height: 2rem;
  border-width: 0.125rem;
  border-color: ${borderColor};
  background-color: ${primaryBackgroundColor};
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }
`

const ErrorText = styled.p`
  ${ErrorStyle}
  padding-top: 0.5rem;
`

export default NumericInput
