import type { NextFillInfo } from '@truepill/tpos-types'
import moment from 'moment'

const defaultDaysSupply = 0

export const getNextFillInfo = (
  dispensed: {
    dispensedAt: string
    daysSupply?: number
  },
  handlingTags: { deaScheduleNum?: string },
  forceDaysPercentage?: number | null,
): NextFillInfo => {
  const { dispensedAt, daysSupply = defaultDaysSupply } = dispensed
  const { deaScheduleNum } = handlingTags
  const daySupplyMultiplierPercentage =
    forceDaysPercentage === undefined || forceDaysPercentage === null ? 0.75 : forceDaysPercentage / 100
  const daySupplyPercentage =
    forceDaysPercentage === undefined || forceDaysPercentage === null ? 0.25 : 1 - forceDaysPercentage / 100
  // Due date is the day after the daysSupply finishes
  const dueDate = moment(dispensedAt).add(daysSupply + 1, 'd')
  // Launch Darkly flag useAutoTriagePercentage will not manage over non-controlled substances a.k.a deaScheduleNum
  const daysLeft = dueDate.diff(moment(), 'days')
  const daysCutoff = deaScheduleNum ? daysSupply * 0.1 : daysSupply * daySupplyPercentage
  const isOkToFill = daysLeft <= daysCutoff
  const daysSupplyMultiplier = deaScheduleNum ? 0.9 : daySupplyMultiplierPercentage
  const dateOkToFill = moment(dispensedAt).add(Math.floor(daysSupply * daysSupplyMultiplier), 'd')

  return {
    dueDate: dueDate.toDate(),
    dateOkToFill: dateOkToFill.toDate(),
    daysLeft,
    isOkToFill,
  }
}
