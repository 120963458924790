import { Fragment, useState } from 'react'
import { ReactComponent as ArrowIcon } from 'assets/icons/pageArrow.svg'
import styled, { css } from 'styled-components'
import { capsuleDarkBlue } from 'styles/styleVariables'
import type { Order, RXFillRequest } from 'types'
import { FillRow } from './Rows'

enum OTC_ADJ_ITEM_TYPE {
  OTC_ADJ_ITEM = 0,
  RX_ITEM = 1,
}

const clickable = css`
  cursor: pointer;
`

const Header = styled.div`
  user-select: none;
  font-weight: bold;
  display: flex;
  align-items: baseline;
  gap: 1rem;
  font-size: 1rem;
  margin: 0 0.6125rem;
`

const ClickableHeader = styled(Header)`
  ${clickable}
`

const OtcAdjudicationHeader = ({ onClick, isExpanded }: { onClick: () => void; isExpanded: boolean }) => {
  return (
    <ClickableHeader onClick={onClick}>
      OTC Rx
      <ArrowIcon fill={capsuleDarkBlue} style={{ transform: isExpanded ? 'rotate(90deg)' : 'rotate(270deg)' }} />
    </ClickableHeader>
  )
}

const OtcAdjudicationFills = ({
  rxFillRequests,
  noIndividualFillSelection,
  onClick,
  order,
  disabledCheckbox,
  disabled,
  selectionLive,
  editableEntries,
  inline,
}: {
  rxFillRequests: RXFillRequest[]
  noIndividualFillSelection?: boolean
  order: Order
  disabled: boolean
  disabledCheckbox: boolean
  selectionLive?: boolean
  inline?: boolean
  editableEntries?: boolean
  onClick?: () => void
}) => {
  let currType: OTC_ADJ_ITEM_TYPE,
    previousType: OTC_ADJ_ITEM_TYPE,
    visible = true
  const [otcCollapsed, setOtcCollapsed] = useState(true)
  // sort requests so otc adjudication products are grouped
  const sortedRxFillRequests = rxFillRequests.slice().sort(a => (a.otcProductId ? 1 : -1))

  return (
    <>
      {sortedRxFillRequests.map(request => {
        let header = <></>
        currType = !!request.otcProductId ? OTC_ADJ_ITEM_TYPE.OTC_ADJ_ITEM : OTC_ADJ_ITEM_TYPE.RX_ITEM
        if (currType !== previousType) {
          if (currType === OTC_ADJ_ITEM_TYPE.OTC_ADJ_ITEM) {
            header = <OtcAdjudicationHeader onClick={() => setOtcCollapsed(!otcCollapsed)} isExpanded={!otcCollapsed} />
            visible = !otcCollapsed
          } else {
            header = <Header>RX</Header>
            visible = true
          }
        }
        previousType = currType
        return (
          <Fragment key={request._id}>
            {header}
            <FillRow
              visible={visible}
              fill={request}
              order={order}
              onClick={onClick}
              noIndividualFillSelection={noIndividualFillSelection}
              disabled={disabled}
              disabledCheckbox={disabledCheckbox}
              inline={inline}
              selectionLive={selectionLive}
              editableEntries={editableEntries}
              showAsBatchOrder={false}
            />
          </Fragment>
        )
      })}
    </>
  )
}

export default OtcAdjudicationFills
