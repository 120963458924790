import type { ReactNode } from 'react'
import { useEffect, useMemo, useRef } from 'react'
import { Box, Header } from '@truepill/react-capsule'
import LoadingSpinner from 'components/Loading'
import UserInitialsBadge from 'components/UserInitialsBadge'
import dayjs from 'dayjs'
import useLogs from 'hooks/navigation/useLogs'
import styled from 'styled-components'
import type { UserFirstLastName, Log } from 'types'

interface EditListProps {
  orderId: string
}

interface EditListItemsProps {
  edits: Log[]
}

interface EditListItemProps {
  user: UserFirstLastName
  date: string
  children: ReactNode
}

const EditListItem = ({ date, user, children }: EditListItemProps) => {
  return (
    <li data-testid="EditListItem">
      <Box css={{ display: 'flex', gap: '0.5rem', marginBottom: '1rem' }}>
        <UserBadge user={user} />
        <Box css={{ display: 'flex', gap: '0.5rem', flexDirection: 'column' }}>
          <EditListItemTitle>
            <strong>{`${user.firstName} ${user.lastName}`}</strong>
            <p style={{ fontSize: '14px' }}>{dayjs(date).format('MMM DD, YYYY hh:mmA Z[Z] ')}</p>
          </EditListItemTitle>
          {children}
        </Box>
      </Box>
    </li>
  )
}

const EditListItems = ({ edits }: EditListItemsProps) => {
  const containerRef = useRef<HTMLUListElement>(null)

  // Process edits to determine added and removed items
  const processedEdits = useMemo(() => {
    return edits.map((edit: Log) => {
      const currentValues: string[] = JSON.parse(edit.change?.newValue ?? '[]')
      const oldValues: string[] = JSON.parse(edit.change?.oldValue ?? '[]')

      // Identify added and removed edits and split values
      const added = currentValues.filter(value => !oldValues.includes(value)).join(', ')
      const removed = oldValues.filter(value => !currentValues.includes(value)).join(', ')

      return {
        id: edit._id,
        added,
        createdAt: edit.createdAt,
        removed,
        user: edit.user,
      }
    })
  }, [edits])

  // Scroll to the bottom of the container to show the latest edit
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight
    }
  }, [edits])

  if (!edits.length) {
    return <p>No edit history</p>
  }

  return (
    <EditListItemsContainer ref={containerRef}>
      {processedEdits.map(
        ({ id, added, createdAt, removed, user }) =>
          (added || removed) && (
            <EditListItem key={id} user={user} date={createdAt}>
              {added && <p>Added - {added}</p>}
              {removed && <p>Removed - {removed}</p>}
            </EditListItem>
          ),
      )}
    </EditListItemsContainer>
  )
}

const EditList = ({ orderId }: EditListProps) => {
  const { logs, loading, error } = useLogs({ orderId })

  if (error) {
    return <p>An error ocurred while loading the edit</p>
  }

  const edits = logs?.filter((log: Log) => {
    return log.event === 'change' && log.change?.field?.includes('durInputs')
  })

  return (
    <Box>
      <Header variant="2xl" id="title" css={{ fontWeight: 700, marginBottom: '1rem' }}>
        Edits
      </Header>
      {loading ? (
        <LoadingSpinnerWrapper>
          <span>
            <LoadingSpinner />
          </span>
        </LoadingSpinnerWrapper>
      ) : (
        <EditListItems edits={edits} />
      )}
    </Box>
  )
}

const LoadingSpinnerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15rem;
  > span {
    width: 3rem;
    opacity: 0.3;
    display: block;
  }
`

const EditListItemsContainer = styled.ul`
  font-size: 1rem;
  font-family: 'lato';
  max-height: 26rem;
  overflow-y: auto;
`

const EditListItemTitle = styled.p`
  display: flex;
  gap: 0.5rem;
  flexwrap: wrap;
  font-size: 1rem;
  > strong {
    font-weight: 700;
  }
`

const UserBadge = styled(UserInitialsBadge)`
  font-size: 0.875rem;
`

export default EditList
