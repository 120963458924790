import { useQuery } from '@truepill/tpos-react-router'
import AddressEntry from 'components/AddressEntry'
import CloseButton from 'components/CloseButton'
import { SearchSelectedCard } from 'components/ColumnForm'
import { GET_PHARMACY } from 'gql'
import styled from 'styled-components'
import type { Pharmacy, PharmacyAddress } from 'types'
import { formatPhoneNumber } from 'utils'

type PharmacyCardProps = { pharmacyId: Pharmacy['_id']; closeCallback: () => void }

const PharmacyCard = ({ pharmacyId, closeCallback }: PharmacyCardProps): JSX.Element => {
  const { data, loading, error } = useQuery(GET_PHARMACY, {
    variables: { _id: pharmacyId },
  })

  if (pharmacyId === '') {
    return <></>
  }

  if (loading) {
    return (
      <SearchSelectedCard>
        <p>Loading</p>
      </SearchSelectedCard>
    )
  }
  if (error) {
    return (
      <SearchSelectedCard>
        <p>Error loading pharmacy: {JSON.stringify(error)} </p>
      </SearchSelectedCard>
    )
  }

  const pharmacy = data.getPharmacy as Pharmacy

  const address = pharmacy.practiceAddresses[0] as PharmacyAddress

  return (
    <SearchSelectedCard>
      <StyledCloseButton clickCallback={closeCallback} />
      <h4>{pharmacy.alternativeOrganizationName || pharmacy.organizationName}</h4>
      <ul>
        <li>
          <AddressEntry address={address} />
        </li>
        <li>
          <DetailsLabel>Phone:</DetailsLabel>
          {formatPhoneNumber(address.phone)}
        </li>
        <li>
          <DetailsLabel>NPI:</DetailsLabel>
          {pharmacy.npi}
        </li>
      </ul>
    </SearchSelectedCard>
  )
}

const StyledCloseButton = styled(CloseButton)`
  padding-right 0.56rem;
`

const DetailsLabel = styled.span`
  font-weight: bold;
  margin-right: 0.25rem;
`

export default PharmacyCard
