import { CopayStatus } from '@truepill/tpos-types'
import TopBanner from 'components/TopBanner'
import { ProblemList } from 'components/TopBanner/OrderTriageTopBanner'
import type { CopayRequest, RejectionDetails, CopayRequestFill, UserFirstLastName } from 'types'

type CopayRequestFillTopBannerProps = {
  copay: CopayRequest
  copayRequestFill: CopayRequestFill
  disableCopay?: boolean
  copayLockedBy?: UserFirstLastName
}

//TODO - combine with rxTopBanner if they become similar
const CopayRequestFillTopBanner = ({
  copay,
  copayRequestFill,
  disableCopay,
  copayLockedBy,
}: CopayRequestFillTopBannerProps): JSX.Element => {
  if (!copay) {
    return <></>
  }

  const inTriage = copayRequestFill.status === CopayStatus.Triage || copay.status === CopayStatus.Triage
  if (inTriage) {
    const copayRequestFillTriages = copay.triages.filter(
      i => i.copayRequestFillId?.toString() === copayRequestFill._id.toString() && !i.endDate,
    )
    return (
      <TopBanner message="Triage">
        <ProblemList>
          {copayRequestFillTriages.map((triage, i) => (
            <li key={i}>
              {triage.reason}: {triage.message}
            </li>
          ))}
        </ProblemList>
      </TopBanner>
    )
  }

  const problemsWithCopay: string[] = []

  if (disableCopay) {
    problemsWithCopay.push(
      `This copay request is currently being worked on by ${copayLockedBy?.firstName} ${copayLockedBy?.lastName}`,
    )
  }

  if (copayRequestFill && copayRequestFill.status === CopayStatus.Rejected) {
    const { rejectionDetails } = copayRequestFill as {
      rejectionDetails: RejectionDetails
    }

    let message = rejectionDetails?.reason || 'Missing Rejection Details'
    if (rejectionDetails?.message) {
      message += ` (${rejectionDetails.message})`
    }

    problemsWithCopay.push(`This copay request has been rejected: ${message}`)
  }

  return problemsWithCopay.length ? (
    <TopBanner message="There are problems with this copay request">
      <ProblemList>
        {problemsWithCopay.map((problem, i) => (
          <li key={i}>{problem}</li>
        ))}
      </ProblemList>
    </TopBanner>
  ) : (
    <></>
  )
}

export default CopayRequestFillTopBanner
