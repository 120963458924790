import { RXCell, ListRowLabel, ListRowValue } from 'components/RXTable'
import { useTPCacheContext } from 'providers/TPCacheProvider'
import styled from 'styled-components'
import { contrastBackgroundColor } from 'styles/styleVariables'
import type { Order } from 'types'

type ReferencesProps = { order: Order }

const References = ({ order }: ReferencesProps): JSX.Element => {
  const { getCustomerNameById } = useTPCacheContext()

  const customerName = getCustomerNameById(order.customerId)

  return (
    <StyledReferencesDetails>
      <RXCell>
        <ListRowLabel>Order token:</ListRowLabel>
        <ListRowValue>{order.coreOrderToken}</ListRowValue>
      </RXCell>
      <RXCell>
        <ListRowLabel>Order ID:</ListRowLabel>
        <ListRowValue>{order.coreOrderId}</ListRowValue>
      </RXCell>
      <RXCell>
        <ListRowLabel>Customer:</ListRowLabel>
        <ListRowValue>{customerName}</ListRowValue>
      </RXCell>
      {order.customerRef?.match(/^\w{2,12}$/) && (
        <RXCell>
          <ListRowLabel>Reference #:</ListRowLabel>
          <ListRowValue>{order.customerRef}</ListRowValue>
        </RXCell>
      )}
      {/* <RXCell>
        <ListRowLabel>Notes:</ListRowLabel>
        <ListRowValue>--NEED--</ListRowValue>
      </RXCell> */}
      {customerName === 'OptumRx' && (
        <>
          <RXCell>
            <ListRowLabel>Optum Bank</ListRowLabel>
            <ListRowValue>(866) 899-5419</ListRowValue>
          </RXCell>
          <RXCell>
            <ListRowLabel>Optum UHC</ListRowLabel>
            <ListRowValue>1 (800) 356-3477</ListRowValue>
          </RXCell>
          {/* <RXCell>
            <ListRowLabel>Promo list:</ListRowLabel>
            <ListRowValue>--NEED--</ListRowValue>
          </RXCell> */}
        </>
      )}
    </StyledReferencesDetails>
  )
}

const StyledReferencesDetails = styled.ul`
  > :nth-child(2n) {
    background-color: ${contrastBackgroundColor};
  }
`

export default References
