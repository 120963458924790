import { useState } from 'react'
import type { PrescriberAddress } from '@truepill/tpos-types'
import { PracticeFormField } from 'components/NewPracticeForm'
import type { Address } from 'types'

const getInitialAddress = (practiceAddress?: PrescriberAddress): Address => ({
  street1: practiceAddress?.street1 || '',
  street2: practiceAddress?.street2 || '',
  city: practiceAddress?.city || '',
  state: practiceAddress?.state || '',
  zip: practiceAddress?.zip || '',
})

type useAddPracticeType = {
  businessName: string
  phone: string
  fax: string
  address: Address
  handlePracticeFormChange: (field: PracticeFormField, value: string | Address) => void
  clearPracticeForm: () => void
}

const useAddPractice = (practiceAddress?: PrescriberAddress): useAddPracticeType => {
  const [businessName, setBusinessName] = useState<string>(practiceAddress?.businessName || '')
  const [phone, setPhone] = useState<string>(practiceAddress?.phone || '')
  const [fax, setFax] = useState<string>(practiceAddress?.fax || '')
  const [address, setAddress] = useState<Address>(getInitialAddress(practiceAddress))

  const handlePracticeFormChange = (field: PracticeFormField, value: string | Address) => {
    switch (field) {
      case PracticeFormField.Name:
        setBusinessName(value as string)
        break
      case PracticeFormField.Phone:
        setPhone(value as string)
        break
      case PracticeFormField.Fax:
        setFax(value as string)
        break
      case PracticeFormField.Address:
        setAddress(value as Address)
        break
      default:
        break
    }
  }

  const clearPracticeForm = () => {
    setBusinessName('')
    setPhone('')
    setFax('')
    setAddress(getInitialAddress())
  }

  return { businessName, phone, fax, address, handlePracticeFormChange, clearPracticeForm }
}

export default useAddPractice
