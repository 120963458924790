import { useMemo } from 'react'
import type { PrescriberAddress } from '@truepill/tpos-types'
import { validatePrescriberPracticeForm } from 'utils'

type usePrescriberPracticeFormValidationType = { isValid: boolean; errors: Record<string, string> }

const usePrescriberPracticeFormValidation = (
  address: PrescriberAddress | undefined,
): usePrescriberPracticeFormValidationType => {
  const validation = useMemo(() => {
    return validatePrescriberPracticeForm(address)
  }, [address])
  return validation
}

export default usePrescriberPracticeFormValidation
