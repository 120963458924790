import { Select as CapsuleSelect } from '@truepill/react-capsule'
import Select from 'components/Select'
import { ShippingMethod } from 'types'
import { shippingMethodToName, camelCaseToHumanReadable } from 'utils'

type ShippingSelectProps = {
  onSelect: (shippingMethod: ShippingMethod) => void
  value?: string
  className?: string
  withCapsule?: boolean
}

interface SelectOption {
  label: string
  value: ShippingMethod
}

// Define the shipping methods that are not selectable
const shippingMethodsNotSelectable = [ShippingMethod.UspsFirst.toString()]

const ShippingSelect = ({ onSelect, value, className, withCapsule }: ShippingSelectProps): JSX.Element => {
  // Convert ShippingMethod to options array, excluding UspsFirst
  const options: SelectOption[] = Object.values(shippingMethodToName)
    .filter(option => {
      return !shippingMethodsNotSelectable.includes(ShippingMethod[option as keyof typeof ShippingMethod].toString())
    })
    .map(option => ({
      label: camelCaseToHumanReadable(option),
      value: ShippingMethod[option as keyof typeof ShippingMethod],
    }))

  // Define the selected value
  const selectedValue = value && {
    label: camelCaseToHumanReadable(shippingMethodToName[value]),
    value: value as ShippingMethod,
  }

  return withCapsule ? (
    //We use this version as a filter selector, found in the filter's menu [JR-18617]
    <CapsuleSelect
      label="Shipping method"
      value={selectedValue as SelectOption}
      placeholder="Select shipping method…"
      options={options}
      selectedKey="label"
      onChange={(option?: SelectOption) => {
        if (option) {
          onSelect(option.value)
        }
      }}
    />
  ) : (
    <Select
      disableClear
      value={selectedValue}
      className={className}
      placeholder={'Select shipping method…'}
      options={options}
      onChange={([option]) => onSelect(option?.value as ShippingMethod)}
    />
  )
}

export default ShippingSelect
