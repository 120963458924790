import { Chip } from '@truepill/react-capsule'
import styled from 'styled-components'

const CustomChip = styled(Chip)`
  font-size: 11px;
  text-transform: uppercase;
  font-weight: bold;
  margin-right: 5px;
`

interface TableChipProps {
  label: string
  colors?: {
    main: string
    background: string
  }
}

/**
 * Chip component used inside tables. E.g. Users sessions
 * Usage:
 * <TableChip
 *  colors={{
 *    main: '#0E7016',
 *    background: '#E6FFE9'
 *  }}
 *  label='My Label'
 * />
 * @param label - A string to display in the chip
 * @param [colors] - Optional. Object to specify the chip colors
 * @param colors.main - Chip color
 * @param colors.background - Chip background color
 * @returns Chip component
 */
const TableChip = ({ label, colors }: TableChipProps): JSX.Element => {
  const props = {
    ...(colors && {
      css: {
        color: colors.main,
        backgroundColor: colors.background,
      },
    }),
  }
  return <CustomChip {...props}>{label}</CustomChip>
}
export default TableChip
