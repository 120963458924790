import { ReactComponent as DangerIcon } from 'assets/icons/danger.svg'
import ActionButton from 'components/ActionButton'
import IconWrapper from 'components/IconWrapper'
import useProcessOrderIssue from 'hooks/useProcessOrderIssue'
import { bodyPrimaryColor } from 'styles/styleVariables'

type IssueButtonProps = { orderId: string; disabled?: boolean }

const IssueButton = ({ orderId, disabled }: IssueButtonProps): JSX.Element => {
  const processOrderIssue = useProcessOrderIssue(orderId)

  return (
    <ActionButton
      icon={
        <IconWrapper>
          <DangerIcon fill={bodyPrimaryColor} />
        </IconWrapper>
      }
      onClick={processOrderIssue}
      label="Issue"
      hotKey="I"
      hotKeyMeta={{ ctrl: true, shift: true }}
      hotKeyLabel="CTRL-SHIFT-I"
      disabled={disabled}
    />
  )
}
export default IssueButton
