import { Button } from '@truepill/react-capsule'
import { ModalWrapper, ModalHeader, ButtonsContainer } from 'components/Modal'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import styled from 'styled-components'
import { darkInfo, lightInfo, contrastBackgroundColor } from 'styles/styleVariables'
import type { RXFillRequest } from 'types'
import { checkIfProvided } from 'utils'

type SendToManualModalProps = {
  onConfirm: () => void
  fillRequests?: RXFillRequest[]
  isBulk: boolean
}

type SendToManualBodyProps = {
  fillRequests: RXFillRequest[]
  isBulk: boolean
}

const ModalBody = ({ fillRequests, isBulk }: SendToManualBodyProps): JSX.Element => {
  const isBatchOrder = fillRequests.length > 1
  if (isBulk) {
    return (
      <div style={{ width: '28.25rem' }}>
        <Description>
          All selected fills will move to the manual fill queue. Please confirm that this action won't result in any
          duplicate dispensed fills.
        </Description>
        <Description>Note: Selected batch orders won't be moved, as they must be moved individually.</Description>
      </div>
    )
  } else if (isBatchOrder) {
    return (
      <div style={{ width: '57rem' }}>
        <Description>
          This fill is part of a batch order. All fills in automation in the batch will move to the manual fill queue.
          Please confirm that this action won't result in duplicate dispensed fills for any medications in the batch.
        </Description>
        <GridHeader>
          <div>Rx #</div>
          <div>Medication</div>
          <div>Fill status</div>
          <div>Machine</div>
          <div>Parata Status</div>
        </GridHeader>
        <GridRows>
          {fillRequests.map(fr => (
            <GridRow>
              <p>{checkIfProvided(fr.prescription?.rxNumber)}</p>
              <p>{checkIfProvided(fr.fill?.dispensed?.name)}</p>
              <p>{checkIfProvided(fr.status)}</p>
              <p>{checkIfProvided(fr.machine?.deviceName)}</p>
              <p>{checkIfProvided(fr.machine?.status)}</p>
            </GridRow>
          ))}
        </GridRows>
      </div>
    )
  }
  return (
    <div style={{ width: '28.25rem' }}>
      <Description>
        Please confirm that this action won't result in duplicate dispensed fills of this medication
      </Description>
      <AssignedMachineStatusWrapper>
        Assigned machine: {fillRequests?.[0]?.machine?.deviceName}
      </AssignedMachineStatusWrapper>
    </div>
  )
}

const SendToManualModal = ({ onConfirm, fillRequests = [], isBulk }: SendToManualModalProps): JSX.Element => {
  const { dismissModal } = useModalContext()
  return (
    <ModalWrapper id="sendToManual" styles={[{ padding: '1.5rem' }]}>
      <ModalHeader hideClose>
        <Title>Send to manual</Title>
      </ModalHeader>
      <ModalBody isBulk={isBulk} fillRequests={fillRequests} />
      <ButtonsContainer>
        <Button size={'sm'} variant="primary-text" onClick={dismissModal}>
          Cancel
        </Button>
        <Button
          size={'sm'}
          onClick={() => {
            onConfirm()
            dismissModal()
          }}
        >
          Confirm
        </Button>
      </ButtonsContainer>
    </ModalWrapper>
  )
}

const Description = styled.div`
  font-size: 1rem;
  font-family: Lato;
  margin: 1rem 0;
  color: #535762;
`
const AssignedMachineStatusWrapper = styled.div`
  background-color: ${lightInfo};
  padding: 1rem;
  border-radius: 0.5rem;
  color: ${darkInfo};
  font-size: 0.875rem;
  font-weight: bold;
`
const GridHeader = styled.div`
  display: grid;
  font-weight: bold;
  padding: 0.5rem;
  font-size: 0.875rem;
  font-family: Lato;
  grid-template-columns: 1fr 3fr 1fr 1fr 1fr;
`
const GridRows = styled.div`
  font-family: Lato;
  font-size: 0.875rem;
  div:nth-child(even) {
    background: ${contrastBackgroundColor};
  }
`

const GridRow = styled.div`
  border-radius: 0.5rem;
  padding: 0.5rem;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr 1fr 1fr;
`

const Title = styled.h2`
  font-size: 36px;
  line-height: 40px;
  letter-spacing: -0.55px;
  margin: 5px;
`

export default SendToManualModal
