import { useMemo } from 'react'

export const DOTS = -1
export type RangeType = number[]

const range = (start: number, end: number): number[] => {
  const length = end - start + 1
  return Array.from({ length }, (_, index) => index + start)
}

const calculateItems = (pagesForEachSide: number): number => 3 * pagesForEachSide

interface Pagination {
  totalPages: number
  currentPage: number
  pagesForEachSide: number
}

export const usePagination = ({ totalPages, currentPage, pagesForEachSide }: Pagination): RangeType => {
  const paginationRange = useMemo(() => {
    const totalPageNumbers = calculateItems(pagesForEachSide)

    if (totalPageNumbers >= totalPages) {
      return range(1, totalPages)
    }

    const leftSiblingIndex = Math.max(currentPage - pagesForEachSide, 1)
    const rightSiblingIndex = Math.min(currentPage + pagesForEachSide, totalPages)

    const showLeftDots = leftSiblingIndex > 2
    const showRightDots = rightSiblingIndex < totalPages - 1

    if (!showLeftDots && showRightDots) {
      const leftRange = range(1, totalPageNumbers)
      return [...leftRange, DOTS]
    }

    if (showLeftDots && !showRightDots) {
      const rightRange = range(totalPages - totalPageNumbers + 1, totalPages)
      return [DOTS, ...rightRange]
    }

    const middleRange = range(leftSiblingIndex, rightSiblingIndex)
    return [DOTS, ...middleRange, DOTS]
  }, [pagesForEachSide, currentPage, totalPages])
  return paginationRange
}
