import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useState } from 'react'
import { noop } from 'ramda-adjunct'
import { usePrevious } from 'react-use'

/**
 * This works like useState, except that if the second parameter is set to true,
 * the initial value won't be used and all state-setting will be discarded until
 * the second parameter is false or undefined.
 */
export default function useStateWithDelay<S extends NonNullable<any>>(
  initialState: S | (() => S) | undefined,
  wait = false,
): [S, Dispatch<SetStateAction<S>>] | [undefined, typeof noop] {
  const previousWait = usePrevious(wait)
  const [innerState, setInnerState] = useState(initialState)

  useEffect(() => {
    if (previousWait === true && wait === false && innerState !== initialState) {
      setInnerState(initialState)
    }
  }, [initialState, innerState, previousWait, wait])

  if (typeof initialState === 'undefined') {
    return [undefined, noop]
  }

  return [innerState, setInnerState] as [S, Dispatch<SetStateAction<S>>]
}
