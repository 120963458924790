import moment from 'moment'
import styled from 'styled-components'
import type { Order, OrderTriage } from 'types'
import TopBanner from './index'

export const ProblemList = styled.ul`
  list-style: disc inside !important;
  margin-left: 5px;
  padding-bottom: 10px;
`

interface OrderTriageTopBannerProps {
  order: Order
}

export const triageMessage = (triage: OrderTriage): string =>
  `${moment(+triage.startDate).format('MM/DD/YYYY')} - ${triage.reason}${
    triage.inTriageUserMessage ? ` - ${triage.inTriageUserMessage}` : ''
  }`

export const OrderTriageTopBanner = ({ order }: OrderTriageTopBannerProps): JSX.Element => {
  const activeTriages = order?.triages.filter(triage => !triage.endDate)

  return order.inTriage && activeTriages.length ? (
    <TopBanner message="Order is in Triage">
      <ProblemList>
        {activeTriages.map((triage, i) => (
          <li key={i}>{triageMessage(triage)}</li>
        ))}
      </ProblemList>
    </TopBanner>
  ) : (
    <></>
  )
}
