import type { ReactNode } from 'react'
import { FormularyLabel, StyledIconContainer } from './StyledComponents'

interface FormularySubstitutionLabelprops {
  text: string
  children: ReactNode
}

export const FormularySubstitutionLabel = ({ text, children }: FormularySubstitutionLabelprops): JSX.Element => {
  return (
    <FormularyLabel>
      <StyledIconContainer>{children}</StyledIconContainer>
      {text}
    </FormularyLabel>
  )
}
