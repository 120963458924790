import { ReactComponent as DangerIcon } from 'assets/icons/danger.svg'
import IconWrapper from 'components/IconWrapper'
import styled from 'styled-components'
import { alertRed, primaryBackgroundColor } from 'styles/styleVariables'
import type { OptChildProps } from 'types'

const Wrapper = styled.div`
  background-color: ${alertRed};
  color: ${primaryBackgroundColor};
  padding-left: 1.875rem;
`

const StyledTopBanner = styled.div`
  width: 100%;
  min-height: 2.5rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 1rem;
  > p {
    margin-left: 0.5rem;
    font-weight: 500;
    white-space: pre;
  }
`

type TopBannerProps = {
  message?: string
  icon?: JSX.Element
} & OptChildProps

const TopBanner = ({ icon, message, children }: TopBannerProps): JSX.Element => {
  if (!message && !children) {
    return <></>
  }

  return (
    <Wrapper>
      <StyledTopBanner data-testid="TopBanner">
        <IconWrapper>{icon || <DangerIcon fill={primaryBackgroundColor} />}</IconWrapper>
        <p>{message}</p>
      </StyledTopBanner>
      {children}
    </Wrapper>
  )
}

export default TopBanner
