import { ReactComponent as LegendIcon } from 'assets/icons/legend.svg'
import { ToolButton } from 'components/SideToolBar'

type LegendButtonProps = {
  onClick: () => void
}

const LegendButton = ({ onClick }: LegendButtonProps): JSX.Element => {
  return <ToolButton label="Legend" icon={LegendIcon} clickCallback={onClick} />
}

export default LegendButton
