import type { FC } from 'react'
import { Button, Select } from '@truepill/react-capsule'
import CustomizedTextField from 'components/CustomizedTextField'
import { USDInput } from 'components/UsdInput'
import { Eye, EyeOff, X } from 'react-feather'
import styled from 'styled-components'
import type { InsuranceOverrideCob, NumericArrayOverrideCOB, AmountOverrideCOB } from 'types'
import type { ReturnProps } from '../../helper'
import { findFullOption } from '../../helper'
import { applyToOptions } from './COBStyleCommon'

type COBProps = {
  overrideCOBKey?: NumericArrayOverrideCOB
  handleChange: (name: keyof InsuranceOverrideCob, key: string, value?: any, index?: number) => void
  name: keyof InsuranceOverrideCob
  row?: AmountOverrideCOB
  options: ReturnProps[]
  index: number
  handleClick: () => void
  disabledStatus: boolean
}

const COBAmountRow: FC<COBProps> = ({
  row,
  handleChange,
  options,
  name,
  overrideCOBKey,
  index,
  handleClick,
  disabledStatus,
}): JSX.Element => {
  const inputStyleSelect = {
    margin: '0',
  }

  const inputStyle = {
    margin: '8px 0 0 0',
  }

  return (
    <Payer4Columns>
      {disabledStatus ? (
        <Select
          options={options}
          label=""
          required
          selectedKey="label"
          placeholder="Qualifier"
          onChange={option => {
            handleChange(name, 'value', { qualifier: option?.value }, index)
          }}
          value={findFullOption(row?.qualifier || 0, options)}
          isOptionDisabled={option => !disabledStatus}
          css={inputStyleSelect}
          variant="small"
        />
      ) : (
        <CustomizedTextField
          id={`#StyledSelect-d`}
          placeholder="Qualifier"
          value={findFullOption(row?.qualifier || 0, options)?.label}
          disabled={true}
          style={inputStyle}
          size="sm"
        />
      )}
      <USDInput
        value={overrideCOBKey?.value?.[index]?.amount || row?.amount}
        onChange={e => handleChange(name, 'value', { amount: e }, index)}
        placeholder={`Amount..`}
        disabled={!disabledStatus}
      />

      {index === 0 ? (
        <>
          {disabledStatus ? (
            <Select
              id={`#StyledSelect-d`}
              options={applyToOptions}
              label=""
              required
              selectedKey="label"
              placeholder=""
              onChange={option => {
                const value = option?.value
                handleChange(name, 'applyTo', value)
              }}
              value={applyToOptions.find(i => i.value === overrideCOBKey?.applyTo)}
              isOptionDisabled={option => !disabledStatus}
              css={inputStyleSelect}
              variant="small"
            />
          ) : (
            <CustomizedTextField
              id={`#StyledSelect-d`}
              placeholder=""
              value={applyToOptions.find(i => i.value === overrideCOBKey?.applyTo)?.label}
              disabled={true}
              style={inputStyle}
              size="sm"
            />
          )}
          <Button
            size="xs"
            style={{
              borderRadius: '100%',
              backgroundColor: 'rgb(238, 238, 238)',
              borderColor: 'rgb(238, 238, 238)',
              height: '3.5rem',
              width: '3.5rem',
              verticalAlign: 'center',
              color: 'var(--cap-colors-primary-700)',
              textAlign: 'center',
              margin: '2px 0 0 0',
            }}
            onClick={handleClick}
          >
            {disabledStatus ? <Eye aria-hidden /> : <EyeOff aria-hidden />}
          </Button>
        </>
      ) : (
        <Button
          size="xs"
          style={{
            borderRadius: '100%',
            backgroundColor: 'rgb(238, 238, 238)',
            borderColor: 'rgb(238, 238, 238)',
            height: '3.5rem',
            width: '3.5rem',
            verticalAlign: 'center',
            color: 'var(--cap-colors-primary-700)',
            textAlign: 'center',
            margin: '4px 0 0 0',
          }}
          disabled={!disabledStatus}
          onClick={() => {
            const COBValues = overrideCOBKey?.value
            if (COBValues) COBValues.splice(index, 1)
            handleChange(name, 'value', COBValues, -1)
          }}
        >
          <X aria-hidden />
        </Button>
      )}
    </Payer4Columns>
  )
}

const PayerColumns = styled.div`
  display: grid;
  align-items: flex-start;
  margin: 0 0 0 0;
  grid-gap: 0.25rem;
  vertical-align: baseline;
  width: 100%;
`

const Payer4Columns = styled(PayerColumns)`
  grid-template-columns: [col1] 32% [col2] 30% [col3] 30% [col4] 8%;
`

export const COBAmountContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-rows: [content] 100%;
  grid-template-columns: [value] 13% [value] 60% [value] 40% [value2] 25% [value2] 13%;
`
export default COBAmountRow
