import { useMutation } from '@truepill/tpos-react-router'
import type { Preferences } from '@truepill/tpos-types'
import { UPDATE_PATIENT } from 'gql'
import type { Address, DURSubject, InsuranceInput, Patient } from 'types'

export default function useUpdatePatient(options: any = {}) {
  return useMutation<
    { updatePatient: Patient },
    Partial<Pick<Patient, 'firstName' | 'lastName' | 'dob' | 'gender' | 'contacts'>> & {
      patientId: string
      address?: {
        home?: Partial<Address>
        defaultShipping?: Partial<Address>
      }
      medicalHistory?: {
        allergies?: DURSubject[]
        conditions?: DURSubject[]
        medications?: DURSubject[]
      }
      insurances?: InsuranceInput[]
      preferences?: Preferences
    }
  >(UPDATE_PATIENT, { refetchQueries: ['getPatient'], ...options })
}
