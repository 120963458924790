import { useState } from 'react'
import { MessageEntry } from 'components/MessagesPane/MessageList'
import { Box } from 'grommet'
import useErrorToast from 'hooks/toast/useErrorToast'
import { useFormData } from 'hooks/useFormData'
import { usePlusClient } from 'providers/VisionRouter'
import styled from 'styled-components'
import { primaryBackgroundColor, primaryColor } from 'styles/styleVariables'
import type { Log, Prescription } from 'types'
import ActionButton from './ActionButton'
import { EditMode } from './RXPageStructure'
import { StyledTextInput } from './RXTable'

export interface AnnotationRxProps {
  height?: string
}

const RxAnnotations = (props: AnnotationRxProps): JSX.Element => {
  const { height } = props
  const {
    state: { formData },
    actions: { updateFormData },
  } = useFormData()
  const {
    tokenContext: { id: userId, firstName, lastName },
  } = usePlusClient()
  const [message, setMessage] = useState('')
  const showErrorToast = useErrorToast()

  const prescriptionFormData = formData.prescription as Prescription

  const addNewAnnotation = () => {
    try {
      const newAnnotation: Partial<Log> = {
        _id: '',
        userId: userId,
        prescriptionId: prescriptionFormData._id,
        message: message,
        isAnnotation: true,
        user: {
          _id: userId,
          firstName: firstName,
          lastName: lastName,
        },
        createdAt: new Date().toISOString(),
      }
      updateFormData({
        prescription: {
          newAnnotations: !prescriptionFormData.newAnnotations
            ? {
                $set: [newAnnotation] as Log[],
              }
            : {
                $push: [newAnnotation] as Log[],
              },
          annotations: {
            $push: [newAnnotation] as Log[],
          },
        },
      })
      setMessage('')
    } catch (e) {
      showErrorToast('Failed to submit annotation: ' + (e as Error).message)
      console.error(e)
    }
  }

  return (
    <>
      <Box height={height} overflow="auto">
        <ul>
          {(prescriptionFormData.annotations ?? []).map((log: Log, index: number) => (
            <MessageEntry key={index} log={log} />
          ))}
        </ul>
      </Box>

      <EditMode>
        <Box direction="row" margin={{ top: '1rem' }} width="100%">
          <StyledTextInput placeholder="Enter annotation…" value={message} onChange={e => setMessage(e.target.value)} />
          <StyledSubmitButton
            active={true}
            type="button"
            label="Post"
            disabled={message === ''}
            onClick={addNewAnnotation}
          />
        </Box>
      </EditMode>
    </>
  )
}

const StyledSubmitButton = styled(ActionButton)`
  margin-left: auto;
  height: 2rem;
  background-color: ${primaryColor};
  color: ${primaryBackgroundColor};
  border-color: ${primaryColor};
  margin-left: 0.5rem;
`

export default RxAnnotations
