import { RxFillRequestSpecialHandlingTagAllOrNoOptions, stateAllOption } from '@truepill/tpos-types'
import useFulfillmentQueue, { FulfillmentQueueName } from 'hooks/useFulfillmentQueue'
import { useTPCacheContext } from 'providers/TPCacheProvider'
import { usePlusClient } from 'providers/VisionRouter'
import { compose, join, prop, map, keys, filter, sortBy, identity, equals } from 'ramda'
import styled from 'styled-components'
import type { OrderSearchVariables } from 'types'
import { getStatesExcluding } from 'utils/states'

const getNames = compose(join(', '), sortBy(identity), map(prop('name')))
const getSelectedStatuses = filter(equals(true))
const getStatusNames = compose(join(', '), sortBy(identity), keys)

const Filters = styled.div`
  display: flex;
  flex-flow: row wrap;
  flex-grow: 1;
  align-items: baseline;
  margin-top: 8px;
`

const Tag = styled.div`
  padding: 2px 7px;
  border-radius: 10px;
  background-color: #afbcc6;
  margin: 3px;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
`

const UpperCase = styled.span`
  text-transform: uppercase;
`

const Title = styled.div`
  font-size: 14px;
  line-height: 16px;
  padding-right: 4px;
`

const STATE_FILTER_MAX_STATES_SHOWN = 10

type AppliedSearchFiltersProps = { orderSearchVariables: OrderSearchVariables }

export const AppliedSearchFilters = ({ orderSearchVariables }: AppliedSearchFiltersProps): JSX.Element => {
  const {
    currentLocation: { queryMap },
    QueryToolkit: { StringBoolean },
  } = usePlusClient()
  const { getCustomerById, getLocationById } = useTPCacheContext()
  const fulfillmentQueue = useFulfillmentQueue()

  const selectedCustomers = (queryMap.customerIds || []).map(getCustomerById)
  const medications = queryMap.medications || []
  const ndcs = queryMap.ndcs || []
  const statusIds = queryMap.statuses || []
  const selectedLocations = (queryMap.locationIds || []).map(getLocationById)
  const batchOrders = queryMap.batchOrders !== undefined ? queryMap.batchOrders : undefined
  const isReservedForInvestigation =
    queryMap.reservedForInvestigation !== undefined ? queryMap.reservedForInvestigation : undefined
  const medicationNames = medications.join(', ')
  const ndcList = ndcs
    .map((ndc: string) => {
      if (ndc.length !== 11) {
        return ndc
      }
      return `${ndc.slice(0, 5)}-${ndc.slice(5, 9)}-${ndc.slice(9)}`
    })
    .join(', ')
  const { transactionId, lastFourDigits } = orderSearchVariables
  const customerNames = getNames(selectedCustomers)
  const selectedStatuses = getSelectedStatuses(statusIds)
  const statusNames = getStatusNames(selectedStatuses)
  const locationNames = getNames(selectedLocations)
  const patientDob = orderSearchVariables.patientDob
  const customerRef = orderSearchVariables.customerRef
  const coreOrderToken = orderSearchVariables.coreOrderToken
  const orderNumber = orderSearchVariables.orderNumber
    ? parseInt(orderSearchVariables.orderNumber as string, 10)
    : undefined
  const coreFillRequestToken = orderSearchVariables.coreFillRequestToken
  const patientName = orderSearchVariables.patientName
  const drugName = orderSearchVariables.drugName
  const rxFillCode = orderSearchVariables.rxFillCode
  const specialHandlingTags = queryMap.specialHandlingTags || []
  const states = queryMap.states || []

  const statesFilterApplied = states.length > 0 && !states.includes(stateAllOption)
  const specialHandlingTagsFilterApplied =
    specialHandlingTags.length > 0 && !specialHandlingTags.includes(RxFillRequestSpecialHandlingTagAllOrNoOptions.ALL)
  const podFilterApplied = !!queryMap.podName

  const filtersApplied =
    Boolean(medicationNames) ||
    Boolean(ndcList) ||
    Boolean(customerNames) ||
    batchOrders ||
    isReservedForInvestigation ||
    Boolean(statusNames) ||
    (Boolean(locationNames) && fulfillmentQueue?.name === FulfillmentQueueName.PV1) ||
    Boolean(orderNumber) ||
    Boolean(coreOrderToken) ||
    Boolean(coreFillRequestToken) ||
    Boolean(patientName) ||
    Boolean(patientDob) ||
    Boolean(customerRef) ||
    Boolean(drugName) ||
    Boolean(rxFillCode) ||
    specialHandlingTagsFilterApplied ||
    statesFilterApplied ||
    podFilterApplied ||
    transactionId ||
    lastFourDigits

  return filtersApplied ? (
    <Filters>
      <Title>Filters</Title>
      {patientDob && (
        <Tag>
          <UpperCase>Patient DOB:</UpperCase> {patientDob}
        </Tag>
      )}
      {rxFillCode && (
        <Tag>
          <UpperCase>Rx Fill Code: </UpperCase> {rxFillCode}
        </Tag>
      )}
      {customerRef && (
        <Tag>
          <UpperCase>Customer ref:</UpperCase>
          {customerRef}
        </Tag>
      )}
      {medicationNames && (
        <Tag>
          <UpperCase>Medication: {medicationNames}</UpperCase>
        </Tag>
      )}
      {ndcList && (
        <Tag>
          <UpperCase>NDC: {ndcList}</UpperCase>
        </Tag>
      )}
      {customerNames && (
        <Tag>
          <UpperCase>Customer: {customerNames}</UpperCase>
        </Tag>
      )}
      {isReservedForInvestigation && (
        <Tag>
          <UpperCase>Reserved for investigation</UpperCase>
        </Tag>
      )}
      {batchOrders && (
        <Tag>
          <UpperCase>{StringBoolean(batchOrders) ? 'Batch Orders' : 'Non Batch Orders'}</UpperCase>
        </Tag>
      )}
      {statusNames && (
        <Tag>
          <UpperCase>Status: {statusNames}</UpperCase>
        </Tag>
      )}
      {locationNames && (
        <Tag>
          <UpperCase>Location: {locationNames}</UpperCase>
        </Tag>
      )}
      {orderNumber && (
        <Tag>
          <UpperCase>Core Order ID:</UpperCase> {orderNumber}
        </Tag>
      )}
      {coreOrderToken && (
        <Tag>
          <UpperCase>Order Token:</UpperCase> {coreOrderToken}
        </Tag>
      )}
      {coreFillRequestToken && (
        <Tag>
          <UpperCase>Fill Request Token: </UpperCase>
          {coreFillRequestToken}
        </Tag>
      )}
      {patientName && (
        <Tag data-private>
          <UpperCase>Patient Name: </UpperCase>
          {patientName}
        </Tag>
      )}
      {drugName && (
        <Tag>
          <UpperCase>Drug: </UpperCase>
          {drugName}
        </Tag>
      )}
      {specialHandlingTagsFilterApplied && (
        <Tag>
          <UpperCase>Special Handling: </UpperCase>
          {specialHandlingTags.join(', ')}
        </Tag>
      )}
      {statesFilterApplied && (
        <Tag>
          <UpperCase>Ship-to State: </UpperCase>
          {states.length > STATE_FILTER_MAX_STATES_SHOWN
            ? `All except ${getStatesExcluding(states).join(', ')}`
            : states.join(', ')}
        </Tag>
      )}
      {podFilterApplied && (
        <Tag>
          <UpperCase>Pod: </UpperCase>
          {queryMap.podName}
        </Tag>
      )}
      {transactionId && (
        <Tag>
          <UpperCase>Transaction ID: </UpperCase>
          {transactionId}
        </Tag>
      )}
      {lastFourDigits && (
        <Tag>
          <UpperCase>Last Four Digits: </UpperCase>
          {lastFourDigits}
        </Tag>
      )}
    </Filters>
  ) : (
    <></>
  )
}
