import { useLocation } from '@truepill/tpos-react-router'
import { LegendButton, LegendModal } from 'components/Legend'
import { Nav } from 'grommet'
import { useGetProductionEnvironment } from 'hooks/useGetProductionEnvironment'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import { usePlusClient } from 'providers/VisionRouter'
import styled from 'styled-components'
import { NAV_MENU_BREAKPOINT, accentPink } from 'styles/styleVariables'
import HotKeysButton from '../HotKeysButton'
import UserAvatar from '../UserAvatar'
import NavButtonList from './ButtonList'
import Logo from './Logo'

export default function NavContainer(): JSX.Element {
  const { isAuthenticated } = usePlusClient()
  const { showModal } = useModalContext()
  const isProductionEnvironment = useGetProductionEnvironment()

  const showLegend = () => {
    showModal(() => <LegendModal />)
  }

  const location = useLocation()
  const pathArray = location.pathname.split('/')

  // Hide header for prescription image popup view
  if (pathArray[pathArray.length - 1] === 'image' && pathArray[pathArray.length - 3] === 'prescriptions') {
    return <></>
  }

  return (
    <AppNav elevation="medium" isProductionEnvironment={isProductionEnvironment}>
      <NavBox>
        <Logo />
        <NavButtonList />
        <Placeholder />
      </NavBox>
      {isAuthenticated && (
        <RightBox>
          <LegendButton onClick={showLegend} />
          <HotKeysButton />
          <UserAvatar />
        </RightBox>
      )}
    </AppNav>
  )
}

const RightBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > button {
    margin-right: 0.625rem;
  }
`

const AppNav = styled(Nav)<{ isProductionEnvironment: boolean }>`
  height: 60px;
  padding: 0 20px;
  @media screen and (min-width: ${NAV_MENU_BREAKPOINT}) {
    padding: 0 30px;
  }
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  background: ${({ isProductionEnvironment }) => (isProductionEnvironment ? 'transparent' : accentPink)};
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 3;
  position: relative;
`

const NavBox = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  @media screen and (min-width: ${NAV_MENU_BREAKPOINT}) {
    justify-content: left;
  }
`

const Placeholder = styled.div`
  height: 16px;
  width: 16px;
`
