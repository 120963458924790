import { useHistory, useLocation } from '@truepill/tpos-react-router'
import { UserRoles } from '@truepill/tpos-types'
import { ReactComponent as PayersIcon } from 'assets/icons/umbrella.svg'
import { ReactComponent as PeopleIcon } from 'assets/nav/people.svg'
import { ReactComponent as PersonIcon } from 'assets/nav/person.svg'
import { ReactComponent as PrescribersIcon } from 'assets/nav/prescribers.svg'
import AuthLimited from 'components/AuthLimited'
import useSetPageTitle from 'hooks/useSetPageTitle'
import { goToTeam, goToViewPatients, goToPrescribers, goToPayers } from 'routes'
import { bodyPrimaryColor } from 'styles/styleVariables'
import type { ChildProps } from 'types'
import { Title, Content, StyledLargeButtonCard, PageContainer, IconWrapper } from './StyledComponents'

type PublicCardType = {
  destination: ({ search }: { search?: string }) => string
  label: string
} & ChildProps

type AuthCardType = { roles: UserRoles[] } & PublicCardType

const PublicCard = ({ destination, label, children }: PublicCardType): JSX.Element => {
  const history = useHistory()
  const { search } = useLocation()
  return (
    <StyledLargeButtonCard onClick={() => history.push(destination({ search }))}>
      <IconWrapper>{children}</IconWrapper>
      <h2>{label}</h2>
    </StyledLargeButtonCard>
  )
}

const AuthCard = ({ roles, destination, label, children }: AuthCardType): JSX.Element => (
  <AuthLimited roles={roles}>
    <PublicCard destination={destination} label={label}>
      {children}
    </PublicCard>
  </AuthLimited>
)

const PeoplePage = (): JSX.Element => {
  useSetPageTitle('People')

  return (
    <PageContainer>
      <Title>People</Title>
      <Content>
        <PublicCard destination={goToViewPatients} label="Patients">
          <PeopleIcon fill={bodyPrimaryColor} />
        </PublicCard>
        <AuthCard roles={[UserRoles.Admin]} destination={goToTeam} label="Truepill team">
          <PersonIcon fill={bodyPrimaryColor} />
        </AuthCard>
        <AuthCard
          roles={[UserRoles.Pharmacist, UserRoles.Technician, UserRoles.Admin]}
          destination={goToPrescribers}
          label="Prescribers"
        >
          <PrescribersIcon fill={bodyPrimaryColor} />
        </AuthCard>
        <AuthCard
          roles={[UserRoles.Pharmacist, UserRoles.Technician, UserRoles.Admin]}
          destination={goToPayers}
          label="Payers"
        >
          <PayersIcon fill={bodyPrimaryColor} />
        </AuthCard>
      </Content>
    </PageContainer>
  )
}

export default PeoplePage
