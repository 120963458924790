import HorizontalItemList from 'components/OrderView/components/HorizontalItemList'
import styled from 'styled-components'
import type { Order } from 'types'

const PackingMaterialsSimpleList = ({ order }: { order: Order }) => {
  const packingMaterials = order.packingMaterials ?? []

  return (
    <HorizontalItemList
      items={packingMaterials}
      getItemKey={item => item._id}
      renderItem={(item: (typeof packingMaterials)[number]) => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <ContentLabel>SKU:</ContentLabel>
            <Text>{item.sku}</Text>
          </div>
          <div>
            <ContentLabel>Quantity:</ContentLabel>
            <Text>{item.quantity}</Text>
          </div>
        </div>
      )}
    />
  )
}

const ContentLabel = styled.span`
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 700;
`

const Text = styled.span`
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin-left: 0.2rem;
`

export default PackingMaterialsSimpleList
