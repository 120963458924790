import { ReactComponent as RobotIcon } from 'assets/icons/robot.svg'
import IconWrapper from 'components/IconWrapper'
import { ModalWrapper, ModalHeader, ButtonsContainer } from 'components/Modal'
import { CancelButton, SaveButton } from 'components/PageStructure'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import { bodyPrimaryColor } from 'styles/styleVariables'

const ConfirmationModal = (props: { title: string; onConfirm: any }): JSX.Element => {
  const { dismissModal } = useModalContext()
  const { onConfirm, title } = props

  return (
    <ModalWrapper>
      <ModalHeader>
        <IconWrapper>
          <RobotIcon fill={bodyPrimaryColor} />
        </IconWrapper>
        <h2>{title}</h2>
      </ModalHeader>
      <ButtonsContainer>
        <CancelButton label={'No'} onClick={dismissModal} />
        <SaveButton
          label={'Yes'}
          onClick={() => {
            onConfirm()
            dismissModal()
          }}
        />
      </ButtonsContainer>
    </ModalWrapper>
  )
}

export default ConfirmationModal
