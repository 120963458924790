import { CopayStatus } from '@truepill/tpos-types'
import TopBanner from 'components/TopBanner'
import { ProblemList } from 'components/TopBanner/OrderTriageTopBanner'
import type { UserFirstLastName, CopayRequest } from 'types'

type CopayRequestTopBannerProps = {
  copayRequest: CopayRequest
  disableCopay: boolean | undefined
  copayLockedBy: UserFirstLastName | undefined
}

const CopayRequestTopBanner = ({
  copayRequest,
  disableCopay,
  copayLockedBy,
}: CopayRequestTopBannerProps): JSX.Element => {
  if (!copayRequest) {
    return <></>
  }

  const problemsWithCopay: string[] = []

  if (disableCopay) {
    problemsWithCopay.push(
      `This copay request is currently being worked on by ${copayLockedBy?.firstName} ${copayLockedBy?.lastName}`,
    )
  }

  if (copayRequest && copayRequest.status === CopayStatus.Rejected) {
    const { rejectionDetails } = copayRequest

    let message = rejectionDetails?.reason || 'Missing Rejection Details'
    if (rejectionDetails?.message) {
      message += ` (${rejectionDetails.message})`
    }
    problemsWithCopay.push(`This copay request has been rejected: ${message}`)
  }

  return problemsWithCopay.length ? (
    <TopBanner message="There are problems with this copay request">
      <ProblemList>
        {problemsWithCopay.map((problem, i) => (
          <li key={i}>{problem}</li>
        ))}
      </ProblemList>
    </TopBanner>
  ) : (
    <></>
  )
}

export default CopayRequestTopBanner
