import { useState } from 'react'
import type {
  ApolloCache,
  ApolloError,
  DefaultContext,
  MutationFunctionOptions,
  OperationVariables,
} from '@truepill/tpos-react-router'
import { useMutation } from '@truepill/tpos-react-router'
import { RUN_DUR_SCREEN } from 'gql'

interface UseRunClinicalReviewParams {
  onCompleted?: () => void
  onError?: (err: ApolloError) => void
}

const useRunClinicalReview = ({ onCompleted, onError }: UseRunClinicalReviewParams) => {
  const [isLoading, setIsLoading] = useState<boolean>(false) // workaround for loading since sometimes it freezes

  const [run, { error, called, data }] = useMutation(RUN_DUR_SCREEN, {
    onCompleted: () => {
      setIsLoading(false)
      onCompleted?.()
    },
    onError: err => {
      setIsLoading(false)
      onError?.(err)
    },
  })

  const runClinicalReview = (
    args: MutationFunctionOptions<unknown, OperationVariables, DefaultContext, ApolloCache<unknown>> | undefined,
  ) => {
    setIsLoading(true)
    run(args)
  }

  return {
    runClinicalReview,
    loading: isLoading,
    error,
    called,
    data,
  }
}

export default useRunClinicalReview
