import { useCallback, useEffect } from 'react'
import ExcessiveCsRejectionsModal from 'modals/ExcessiveCsRejectionsModal'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import type { Log, Order } from 'types'

type useExcessiveCsRejectionModalProps = {
  order: Order | undefined
  logs: Log[]
}

const useExcessiveCsRejectionsModal = ({ order, logs }: useExcessiveCsRejectionModalProps): void => {
  const rejectedByPrescriberLog = logs?.find(
    log =>
      log?.message?.includes('Controlled substance fill rejection') && log?.message?.includes('from this prescriber'),
  )
  const rejectedByCustomerLog = logs?.find(
    log =>
      log?.message?.includes('Controlled substance fill rejection') && log?.message?.includes('from this customer'),
  )

  const countRejectedByPrescriber = Number(rejectedByPrescriberLog?.message?.split(' ')[4]?.replace('#', ''))
  const countRejectedByCustomer = Number(rejectedByCustomerLog?.message?.split(' ')[4]?.replace('#', ''))

  let rejectedCsFillId
  if (countRejectedByPrescriber % 10 === 0) {
    rejectedCsFillId = rejectedByPrescriberLog?.fillId
  } else if (countRejectedByCustomer % 50 === 0) {
    rejectedCsFillId = rejectedByCustomerLog?.fillId
  }

  const shouldShowExcessiveCsRejectionsModal: boolean =
    !!rejectedCsFillId &&
    !logs?.find(log => log?.message?.includes('Excessive CS rejections reported by')) &&
    !!order?._id

  const useExcessiveCsRejectionsPopModal = (rejectedCsFillId?: string) => {
    const { showModal } = useModalContext()
    return useCallback(() => {
      showModal(() => <ExcessiveCsRejectionsModal orderId={order?._id} fillId={rejectedCsFillId} />)
    }, [shouldShowExcessiveCsRejectionsModal])
  }

  const popExcessiveCsRejectionsModal = useExcessiveCsRejectionsPopModal(rejectedCsFillId)

  useEffect(() => {
    if (shouldShowExcessiveCsRejectionsModal) {
      return popExcessiveCsRejectionsModal()
    }
    return undefined
  }, [popExcessiveCsRejectionsModal, shouldShowExcessiveCsRejectionsModal])
}

export default useExcessiveCsRejectionsModal
