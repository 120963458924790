import type { QueryResult } from '@truepill/tpos-react-router'
import { useQuery } from '@truepill/tpos-react-router'
import { LIST_FULFILLMENT_PRIOR_AUTHORIZATIONS } from 'gql'
import type { PriorAuthorization } from 'types'

export interface GetFulfillmentPriorAuthorizationParams {
  isOrder?: boolean
  isCopay?: boolean
  locationId?: string
  customerIds?: string[]
  statuses?: string[]
  patientId?: string
  stringSearch?: string
  pageNumber?: number
  pageSize?: number
  sort?: number
}

export interface GetFulfillmentPriorAuthorizationsResult {
  getFulfillmentPriorAuthorizations: {
    currentPage: number
    totalRecords: number
    priorAuthorizations: PriorAuthorization[]
  }
}

const useFulfillmentPriorAuthorizations = (
  priorAuthorizationQueryVariables: GetFulfillmentPriorAuthorizationParams,
): QueryResult<GetFulfillmentPriorAuthorizationsResult, GetFulfillmentPriorAuthorizationParams> => {
  return useQuery<GetFulfillmentPriorAuthorizationsResult, GetFulfillmentPriorAuthorizationParams>(
    LIST_FULFILLMENT_PRIOR_AUTHORIZATIONS,
    {
      variables: priorAuthorizationQueryVariables,
      fetchPolicy: 'network-only',
    },
  )
}

export default useFulfillmentPriorAuthorizations
