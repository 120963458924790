import { useMemo } from 'react'
import { useQuery } from '@truepill/tpos-react-router'
import AddressEntry from 'components/AddressEntry'
import LoadingSpinner from 'components/Loading'
import { NoteEntry } from 'components/NotesPane/NotesList'
import { FilledHeader } from 'components/PageStructure'
import { GET_PAYER_NOTES } from 'gql'
import styled from 'styled-components'
import { contrastBackgroundColor } from 'styles/styleVariables'
import type { Note, InsuranceProvider } from 'types'
import { checkIfProvided, formatPhoneNumber } from 'utils'
import { SmallPaddingBlock } from './RXTable'

type PayerDataProps = { payer: InsuranceProvider; showConfigurations: boolean }

const PayerData = ({ payer, showConfigurations }: PayerDataProps): JSX.Element => {
  if (!payer) {
    return (
      <LoadingSpinnerContainer>
        <LoadingSpinner />
      </LoadingSpinnerContainer>
    )
  }

  return (
    <RXPayerDataContainer data-testid="PayerData">
      <RXDataContainer>
        {showConfigurations && (
          <>
            <RXDataColumnWide data-test-column="payer">
              <FilledHeader>Options</FilledHeader>
              <OptionsBlock payer={payer} />
            </RXDataColumnWide>
            <RXDataColumn data-test-column="payer">
              <FilledHeader>Pricing</FilledHeader>
              <PricingBlock payer={payer} />
              <SmallPaddingBlock />
              <FilledHeader>Reconciliation</FilledHeader>
              <ReconciliationBlock payer={payer} />
              <SmallPaddingBlock />
              <FilledHeader>Minimum profit rejection</FilledHeader>
              <MinimumProfitRejectionBlock payer={payer} />
            </RXDataColumn>
          </>
        )}
        {!showConfigurations && (
          <>
            <RXDataColumn data-test-column="payer">
              <FilledHeader>Details</FilledHeader>
              <PayerBlock payer={payer} />
            </RXDataColumn>
            <RXDataColumn data-test-column="contact">
              <FilledHeader>Contact</FilledHeader>
              <PayerContactBlock payer={payer} />
            </RXDataColumn>
            <RXDataColumn data-test-column="critical-notes">
              <FilledHeader>Critical Notes</FilledHeader>
              <Notes payerId={payer._id} />
            </RXDataColumn>
          </>
        )}
      </RXDataContainer>
    </RXPayerDataContainer>
  )
}

type OptionsBlockProps = { payer: InsuranceProvider }

export const OptionsBlock = ({ payer }: OptionsBlockProps): JSX.Element => {
  return (
    <OptionsContainer>
      <OptionsInnerContainer>
        <RXDataRow data-test-row="b3">
          <ListRowLabel>B3:</ListRowLabel>
          <ListRowValue data-private>{payer.configurations?.options?.b3}</ListRowValue>
        </RXDataRow>
        <RXDataRow data-test-row="allow-partial-fills">
          <ListRowLabel>Allow partial fills:</ListRowLabel>
          <ListRowValue data-private>
            {payer.configurations?.options?.allowPartialFills ? 'True' : 'False'}
          </ListRowValue>
        </RXDataRow>
        <RXDataRow data-test-row="allow-partial-fills-cII">
          <ListRowLabel>Allow partial fills on C-II:</ListRowLabel>
          <ListRowValue data-private>
            {payer.configurations?.options?.allowPartialFillsCII ? 'True' : 'False'}
          </ListRowValue>
        </RXDataRow>
        <RXDataRow data-test-row="allow-partial-fills-cIII">
          <ListRowLabel>Allow partial fills on C-III:</ListRowLabel>
          <ListRowValue data-private>
            {payer.configurations?.options?.allowPartialFillsCIII ? 'True' : 'False'}
          </ListRowValue>
        </RXDataRow>
        <RXDataRow data-test-row="allow-partial-fills-cIV">
          <ListRowLabel>Allow partial fills on C-IV:</ListRowLabel>
          <ListRowValue data-private>
            {payer.configurations?.options?.allowPartialFillsCIV ? 'True' : 'False'}
          </ListRowValue>
        </RXDataRow>
        <RXDataRow data-test-row="allow-partial-fills-cV">
          <ListRowLabel>Allow partial fills on C-V:</ListRowLabel>
          <ListRowValue data-private>
            {payer.configurations?.options?.allowPartialFillsCV ? 'True' : 'False'}
          </ListRowValue>
        </RXDataRow>
        <RXDataRow data-test-row="rx-expiration">
          <ListRowLabel>Rx expiration:</ListRowLabel>
          <ListRowValue data-private>
            {payer.configurations?.options?.rxExpiration && `${payer.configurations?.options?.rxExpiration} days`}
          </ListRowValue>
        </RXDataRow>
        <RXDataRow data-test-row="cII-expiration">
          <ListRowLabel>C-II expiration:</ListRowLabel>
          <ListRowValue data-private>
            {payer.configurations?.options?.cIIExpiration && `${payer.configurations?.options?.cIIExpiration} days`}
          </ListRowValue>
        </RXDataRow>
        <RXDataRow data-test-row="other-C-expiration">
          <ListRowLabel>C-III, IV, V expiration:</ListRowLabel>
          <ListRowValue data-private>
            {payer.configurations?.options?.otherCExpiration &&
              `${payer.configurations?.options?.otherCExpiration} days`}
          </ListRowValue>
        </RXDataRow>
        <RXDataRow data-test-row="copay-method">
          <ListRowLabel>Copay method:</ListRowLabel>
          <ListRowValue data-private>{payer.configurations?.options?.copayMethod}</ListRowValue>
        </RXDataRow>
        <RXDataRow data-test-row="refills-due">
          <ListRowLabel>Refills Due:</ListRowLabel>
          <ListRowValue data-private>{payer.configurations?.options?.refillsDue}</ListRowValue>
        </RXDataRow>
      </OptionsInnerContainer>
      <OptionsInnerContainer>
        <RXDataRow data-test-row="tax-method">
          <ListRowLabel>Tax method:</ListRowLabel>
          <ListRowValue data-private>{payer.configurations?.options?.taxMethod}</ListRowValue>
        </RXDataRow>
        <RXDataRow data-test-row="max-claims-per-transmit">
          <ListRowLabel>Max claims per transmit:</ListRowLabel>
          <ListRowValue data-private>{payer.configurations?.options?.maxClaimsPerTransmit}</ListRowValue>
        </RXDataRow>
        <RXDataRow data-test-row="min-rx-price">
          <ListRowLabel>Min Rx price:</ListRowLabel>
          <ListRowValue data-private>
            {payer.configurations?.options?.minRxPrice && `$${payer.configurations?.options?.minRxPrice}`}
          </ListRowValue>
        </RXDataRow>
        <RXDataRow data-test-row="max-rx-price">
          <ListRowLabel>Max Rx price:</ListRowLabel>
          <ListRowValue data-private>
            {payer.configurations?.options?.maxRxPrice && `$${payer.configurations?.options?.maxRxPrice}`}
          </ListRowValue>
        </RXDataRow>
        <RXDataRow data-test-row="max-monthly-fills">
          <ListRowLabel>Max monthly fills:</ListRowLabel>
          <ListRowValue data-private>{payer.configurations?.options?.maxMonthlyFills}</ListRowValue>
        </RXDataRow>
        <RXDataRow data-test-row="max-rx-refills">
          <ListRowLabel>Max Rx refills:</ListRowLabel>
          <ListRowValue data-private>{payer.configurations?.options?.maxRxRefills}</ListRowValue>
        </RXDataRow>
        <RXDataRow data-test-row="max-cIII-refills">
          <ListRowLabel>Max C-III refills:</ListRowLabel>
          <ListRowValue data-private>{payer.configurations?.options?.maxCIIIRefills}</ListRowValue>
        </RXDataRow>
        <RXDataRow data-test-row="max-cIV-refills">
          <ListRowLabel>Max C-IV refills:</ListRowLabel>
          <ListRowValue data-private>{payer.configurations?.options?.maxCIVRefills}</ListRowValue>
        </RXDataRow>
        <RXDataRow data-test-row="max-cV-refills">
          <ListRowLabel>Max C-V refills:</ListRowLabel>
          <ListRowValue data-private>{payer.configurations?.options?.maxCVRefills}</ListRowValue>
        </RXDataRow>
        <RXDataRow data-test-row="max-monthly-amount">
          <ListRowLabel>Max monthly amount:</ListRowLabel>
          <ListRowValue data-private>
            {payer.configurations?.options?.maxMonthlyAmount && `$${payer.configurations?.options?.maxMonthlyAmount}`}
          </ListRowValue>
        </RXDataRow>
        <RXDataRow data-test-row="max-day-supply">
          <ListRowLabel>Max day supply:</ListRowLabel>
          <ListRowValue data-private>{payer.configurations?.options?.maxDaySupply}</ListRowValue>
        </RXDataRow>
      </OptionsInnerContainer>
    </OptionsContainer>
  )
}

type PayerProps = { payer: InsuranceProvider }

export const PricingBlock = ({ payer }: PayerProps): JSX.Element => {
  return (
    <>
      <RXDataRow data-test-row="claim-processing-fee">
        <ListRowLabel>Claim processing fee:</ListRowLabel>
        <ListRowValue data-private>{payer.configurations?.pricing?.claimProcessingFee}</ListRowValue>
      </RXDataRow>
      <RXDataRow data-test-row="brand-discount">
        <ListRowDoubleLabel>Brand:</ListRowDoubleLabel>
        <ListRowDoubleValue>
          <ListRowDoubleValueContainer>
            <ListRowDoubleValueLabel>Discount: </ListRowDoubleValueLabel>
            <ListRowDoubleValueValue data-private>
              {payer.configurations?.pricing?.brandDiscount && `${payer.configurations?.pricing?.brandDiscount}%`}
            </ListRowDoubleValueValue>
          </ListRowDoubleValueContainer>
          <ListRowDoubleValueContainer>
            <ListRowDoubleValueLabel>Max discount: </ListRowDoubleValueLabel>
            <ListRowDoubleValueValue data-private>
              {payer.configurations?.pricing?.brandMaxDiscount && `$${payer.configurations?.pricing?.brandMaxDiscount}`}
            </ListRowDoubleValueValue>
          </ListRowDoubleValueContainer>
        </ListRowDoubleValue>
      </RXDataRow>
      <RXDataRow data-test-row="generic-discount">
        <ListRowDoubleLabel>Generic:</ListRowDoubleLabel>
        <ListRowDoubleValue>
          <ListRowDoubleValueContainer>
            <ListRowDoubleValueLabel>Discount: </ListRowDoubleValueLabel>
            <ListRowDoubleValueValue data-private>
              {payer.configurations?.pricing?.genericDiscount && `${payer.configurations?.pricing?.genericDiscount}%`}
            </ListRowDoubleValueValue>
          </ListRowDoubleValueContainer>
          <ListRowDoubleValueContainer>
            <ListRowDoubleValueLabel>Max discount: </ListRowDoubleValueLabel>
            <ListRowDoubleValueValue data-private>
              {payer.configurations?.pricing?.genericMaxDiscount &&
                `$${payer.configurations?.pricing?.genericMaxDiscount}`}
            </ListRowDoubleValueValue>
          </ListRowDoubleValueContainer>
        </ListRowDoubleValue>
      </RXDataRow>
      <RXDataRow data-test-row="compound-discount">
        <ListRowDoubleLabel>Compound:</ListRowDoubleLabel>
        <ListRowDoubleValue>
          <ListRowDoubleValueContainer>
            <ListRowDoubleValueLabel>Discount: </ListRowDoubleValueLabel>
            <ListRowDoubleValueValue data-private>
              {payer.configurations?.pricing?.compoundDiscount && `${payer.configurations?.pricing?.compoundDiscount}%`}
            </ListRowDoubleValueValue>
          </ListRowDoubleValueContainer>
          <ListRowDoubleValueContainer>
            <ListRowDoubleValueLabel>Max discount: </ListRowDoubleValueLabel>
            <ListRowDoubleValueValue data-private>
              {payer.configurations?.pricing?.compoundMaxDiscount &&
                `$${payer.configurations?.pricing?.compoundMaxDiscount}`}
            </ListRowDoubleValueValue>
          </ListRowDoubleValueContainer>
        </ListRowDoubleValue>
      </RXDataRow>
    </>
  )
}

export const ReconciliationBlock = ({ payer }: PayerProps): JSX.Element => {
  return (
    <>
      <RXDataRow data-test-row="per-transaction-fee">
        <ListRowLabel>Per transaction fee:</ListRowLabel>
        <ListRowValue data-private>
          {payer.configurations?.reconciliation?.transactionFee &&
            `$${payer.configurations?.reconciliation?.transactionFee}`}
        </ListRowValue>
      </RXDataRow>
      <RXDataRow data-test-row="per-rx-fee">
        <ListRowLabel>Per rx fee:</ListRowLabel>
        <ListRowValue data-private>
          {payer.configurations?.reconciliation?.rxFee && `$${payer.configurations?.reconciliation?.rxFee}`}
        </ListRowValue>
      </RXDataRow>
      <RXDataRow data-test-row="per-paid-claim-fee">
        <ListRowLabel>Per paid claimm fee:</ListRowLabel>
        <ListRowValue data-private>
          {payer.configurations?.reconciliation?.paidClaimFee &&
            `$${payer.configurations?.reconciliation?.paidClaimFee}`}
        </ListRowValue>
      </RXDataRow>
      <RXDataRow data-test-row="per-reversal-claim-fee">
        <ListRowLabel>Per reversal claim fee:</ListRowLabel>
        <ListRowValue data-private>
          {payer.configurations?.reconciliation?.reversalClaimFee &&
            `$${payer.configurations?.reconciliation?.reversalClaimFee}`}
        </ListRowValue>
      </RXDataRow>
      <RXDataRow data-test-row="automatically-reconcile-claims">
        <ListRowLabel>Automatically reconcile $0 claims:</ListRowLabel>
        <ListRowValue data-private>
          {payer.configurations?.reconciliation?.automaticallyReconcileClaims ? 'True' : 'False'}
        </ListRowValue>
      </RXDataRow>
      <RXDataRow data-test-row="bill-no-cost-secondary-claims">
        <ListRowLabel>Bill $0 secondary claims:</ListRowLabel>
        <ListRowValue data-private>
          {payer.configurations?.reconciliation?.billNoCostSecondaryClaims ? 'True' : 'False'}
        </ListRowValue>
      </RXDataRow>
    </>
  )
}

export const MinimumProfitRejectionBlock = ({ payer }: PayerProps): JSX.Element => {
  return (
    <>
      <RXDataRow data-test-row="percent-submitted">
        <ListRowLabel>Percent of submitted:</ListRowLabel>
        <ListRowValue data-private>
          {payer.configurations?.minimumProfitRejection?.percentSubmitted &&
            `${payer.configurations?.minimumProfitRejection?.percentSubmitted}%`}
        </ListRowValue>
      </RXDataRow>
      <RXDataRow data-test-row="percent-over-cost">
        <ListRowLabel>Percent over cost:</ListRowLabel>
        <ListRowValue data-private>
          {payer.configurations?.minimumProfitRejection?.percentOverCost &&
            `${payer.configurations?.minimumProfitRejection?.percentOverCost}%`}
        </ListRowValue>
      </RXDataRow>
      <RXDataRow data-test-row="amount-due-over-cost">
        <ListRowLabel>Amount due over cost:</ListRowLabel>
        <ListRowValue data-private>
          {payer.configurations?.minimumProfitRejection?.amountDueOverCost &&
            `$${payer.configurations?.minimumProfitRejection?.amountDueOverCost}`}
        </ListRowValue>
      </RXDataRow>
    </>
  )
}

export const PayerBlock = ({ payer }: PayerProps): JSX.Element => {
  return (
    <>
      <RXDataRow data-test-row="bin">
        <ListRowLabel>BIN:</ListRowLabel>
        <ListRowValue data-private>{payer.bin}</ListRowValue>
      </RXDataRow>
      <RXDataRow data-test-row="pcn">
        <ListRowLabel>PCN:</ListRowLabel>
        <ListRowValue data-private>{payer.pcn}</ListRowValue>
      </RXDataRow>
      <RXDataRow data-test-row="name">
        <ListRowLabel>Name:</ListRowLabel>
        <ListRowValue data-private>{payer.name}</ListRowValue>
      </RXDataRow>
      <RXDataRow data-test-row="code">
        <ListRowLabel>Code:</ListRowLabel>
        <ListRowValue>
          <ListRowValue data-private>{payer.code}</ListRowValue>
        </ListRowValue>
      </RXDataRow>
      <RXDataRow data-test-row="plan-type">
        <ListRowLabel>Plan type:</ListRowLabel>
        <ListRowValue data-private>{payer.planType}</ListRowValue>
      </RXDataRow>
      <RXDataRow data-test-row="submission-type">
        <ListRowLabel>Submission type:</ListRowLabel>
        <ListRowValue data-private>{payer.submissionType}</ListRowValue>
      </RXDataRow>
      <RXDataRow data-test-row="billing-type">
        <ListRowLabel>Billing type:</ListRowLabel>
        <ListRowValue data-private>{payer.billingType}</ListRowValue>
      </RXDataRow>
      <RXDataRow data-test-row="contact-expiration">
        <ListRowLabel>Contact expiration:</ListRowLabel>
        <ListRowValue data-private>{payer.contractExpiration}</ListRowValue>
      </RXDataRow>
      <RXDataRow data-test-row="status">
        <ListRowLabel>Status:</ListRowLabel>
        <ListRowValue data-private>{payer.status}</ListRowValue>
      </RXDataRow>
      <RXDataRow data-test-row="state-code">
        <ListRowLabel>State Code:</ListRowLabel>
        <ListRowValue data-private>{payer.stateCode}</ListRowValue>
      </RXDataRow>
    </>
  )
}

export const PayerContactBlock = ({ payer }: PayerProps): JSX.Element => {
  return (
    <>
      <RXDataRow data-test-row="phone">
        <ListRowLabel>Phone:</ListRowLabel>
        <ListRowValue data-private>{checkIfProvided(formatPhoneNumber(payer.phoneNumber))}</ListRowValue>
      </RXDataRow>
      <RXDataRow data-test-row="helpdesk">
        <ListRowLabel>Help desk:</ListRowLabel>
        <ListRowValue data-private>{checkIfProvided(formatPhoneNumber(payer.helpdeskNumber))}</ListRowValue>
      </RXDataRow>
      <RXDataRow data-test-row="fax">
        <ListRowLabel>Fax:</ListRowLabel>
        <ListRowValue data-private>{checkIfProvided(formatPhoneNumber(payer.faxNumber))}</ListRowValue>
      </RXDataRow>
      <RXDataRow data-test-row="prior-authorization">
        <ListRowLabel>Prior authorization:</ListRowLabel>
        <ListRowValue data-private>{checkIfProvided(formatPhoneNumber(payer.priorAuthorizationNumber))}</ListRowValue>
      </RXDataRow>
      <RXDataRow data-test-row="address">
        <ListRowLabel>Address:</ListRowLabel>
        <ListRowValue>
          <AddressEntry data-private address={payer.address} />
        </ListRowValue>
      </RXDataRow>
      <RXDataRow data-test-row="email">
        <ListRowLabel>Email:</ListRowLabel>
        <ListRowValue data-private>{payer.email}</ListRowValue>
      </RXDataRow>
      <RXDataRow data-test-row="website">
        <ListRowLabel>Website:</ListRowLabel>
        <ListRowValue data-private>{payer.website}</ListRowValue>
      </RXDataRow>
    </>
  )
}

type NotesProps = { payerId: InsuranceProvider['_id'] }

const Notes = ({ payerId }: NotesProps): JSX.Element => {
  const { data, loading, error } = useQuery(GET_PAYER_NOTES, {
    variables: { payerId },
  })

  const notes: Note[] = data ? data.notes : []

  const filteredNotes = useMemo(
    () =>
      notes
        .filter(({ tags }) => tags.includes('Critical' || 'critical'))
        .sort((a: Note, b: Note) => {
          const createdAtA = new Date(a.createdAt).getTime()
          const createdAtB = new Date(b.createdAt).getTime()
          return createdAtB - createdAtA
        }),
    [notes],
  )

  if (error) {
    return <AnnotationContainer>Error loading notes: {JSON.stringify(error)}</AnnotationContainer>
  }

  if (loading) {
    return (
      <AnnotationContainer>
        <LoadingSpinnerContainer>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      </AnnotationContainer>
    )
  }

  return (
    <AnnotationContainer>
      <StyledList>
        {filteredNotes.map((message: Note) => (
          <NoteEntry key={message._id} note={message} />
        ))}
      </StyledList>
    </AnnotationContainer>
  )
}

const AnnotationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

const StyledList = styled.ul`
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  flex-grow: 1;
  overflow-y: auto;
  > :first-child {
    margin-top: 0rem;
  }
`

const RXPayerDataContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`

const RXDataContainer = styled.ul`
  display: flex;
  flex-basis: 100%;
  flex-direction: row;
  align-items: flex-start;
  :not(:last-child) {
    margin-bottom: 1.75rem;
  }
  :last-child {
    margin-bottom: 1rem;
  }
`

const ListRowDoubleValue = styled.div``
const ListRowDoubleValueLabel = styled.div``
const ListRowDoubleValueValue = styled.div``

const ListRowDoubleValueContainer = styled.div`
  display: flex;
`

const OptionsContainer = styled.div`
  display: flex;
`

const OptionsInnerContainer = styled.div`
  width: 50%;
  :not(:last-child) {
    padding-right: 20px;
  }
`

const RXDataColumn = styled.ul`
  :not(:last-child) {
    margin-right: 1.25rem;
  }
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 33%;
`

const RXDataColumnWide = styled.ul`
  :not(:last-child) {
    margin-right: 1.25rem;
  }
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 66%;
`

const RXDataRow = styled.ul`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: [label] 15rem [value] 1fr;
  padding: 0.5rem 0.3125rem;
  :nth-of-type(even) {
    background-color: ${contrastBackgroundColor};
  }
`
const ListRowLabel = styled.li`
  grid-row: 1;
  grid-column: label;
  padding-right: 0.3125rem;
  padding-left: 0.425rem;
  font-size: 0.875rem;
  font-weight: 500;
`
const ListRowValue = styled.li.attrs({
  'data-testid': 'value',
})`
  grid-row: 1;
  grid-column: value;
`

const LoadingSpinnerContainer = styled.div`
  display: flex;
  width: 100%;
  padding-top: 0.8rem;
  justify-content: center;
  align-items: center;
  svg {
    height: 25px;
  }
`

const ListRowDoubleLabel = styled.li`
  line-height: 48px;
  grid-row: 1;
  grid-column: label;
  padding-right: 0.3125rem;
  padding-left: 0.425rem;
  font-size: 0.875rem;
  font-weight: 500;
`

export default PayerData
