import type { MessageHeader } from './shared'

export interface Error {
  Code: string
  DescriptionCode?: string
  Description?: string
}

export interface ErrorMessageRoot {
  Message: Partial<{
    Header: MessageHeader
    Body: Partial<{
      Error: Error
    }>
  }>
}

export enum MessageProcessingError {
  COMPOUND_NOT_SUPPORTED = 'The receiving system cannot process the compound or supply item.',
  DIGITAL_SIGNATURE_OR_INDICATOR_MISSING = 'Controlled substance must be signed or DigitalSignature Indicator must be true.',
  INVALID_DIGITAL_SIGNATURE = 'Invalid Digital Signature',
  MESSAGE_PROCESSING_FAILED = 'The receiving system could not process the message.',
  CONTROLLED_SUBSTANCE_DAYS_SUPPLY = 'Pharmacy allows a maximum of 30 day supply on prescriptions written on the same day for controlled substances. The limit has been exceeded for this patient and medication. Therefore, the prescription submitted has been voided.',
}
