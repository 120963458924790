import { useEffect, useState } from 'react'
import { useAuth0, Auth0Provider as Auth0ProviderModule, type AppState } from '@auth0/auth0-react'
import LoadingSpinner from 'components/Loading'
import config from 'config'
import { type LocationDescriptorObject } from 'history'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import type { ChildProps } from 'types'
import { getDefaultPath, usePlusClient } from './VisionRouter'

type Auth0WatcherProps = {
  newLogin: boolean
  redirectUrl: LocationDescriptorObject | undefined
  setRedirectUrl: (redirectUrl: LocationDescriptorObject | undefined) => void
} & ChildProps

export const Auth0Watcher = ({ children, newLogin, redirectUrl, setRedirectUrl }: Auth0WatcherProps): JSX.Element => {
  const { isAuthenticated, isLoading, getIdTokenClaims } = useAuth0()
  const { loginUserAuth0, isAuthenticated: isLoggedIn, routeTo, tokenContext } = usePlusClient()
  const [token, setToken] = useState<string | undefined>(undefined)
  const loadingToken = isAuthenticated && !isLoggedIn
  const history = useHistory()

  useEffect(() => {
    if (isLoading) return

    if (isAuthenticated) {
      getIdTokenClaims().then(idToken => setToken(idToken.__raw))
    }
  }, [isLoading, isAuthenticated, getIdTokenClaims])

  useEffect(() => {
    if (token) {
      loginUserAuth0(token, newLogin)
    }
  }, [loginUserAuth0, token, newLogin])

  // When tokenContext is set redirect to landing page coming from appState
  useEffect(() => {
    if (tokenContext?.id && token && redirectUrl) {
      const path = getDefaultPath(tokenContext, redirectUrl, routeTo)
      const url = `${path?.pathname || ''}${path?.search || ''}${path?.hash || ''}` || '/'
      history.push(url)
      setRedirectUrl(undefined)
    }
  }, [tokenContext, token, redirectUrl, setRedirectUrl, history, routeTo])

  if (isLoading || loadingToken) {
    return (
      <LoadingSpinnerContainer>
        <LoadingSpinner />
      </LoadingSpinnerContainer>
    )
  }

  return <>{children}</>
}

const Auth0Provider = ({ children }: ChildProps): JSX.Element => {
  const [newLogin, setNewLogin] = useState<boolean>(false)
  const [redirectUrl, setRedirectUrl] = useState<LocationDescriptorObject | undefined>(undefined)

  const onRedirectCallback = (appState: AppState) => {
    setNewLogin(true)
    setRedirectUrl(appState?.redirectUrl)
  }

  return (
    <Auth0ProviderModule
      domain={config.auth0.domain}
      clientId={config.auth0.clientId}
      redirectUri={config.auth0.callbackUrl}
      cacheLocation="localstorage"
      useRefreshTokens={true}
      onRedirectCallback={onRedirectCallback}
    >
      <Auth0Watcher newLogin={newLogin} redirectUrl={redirectUrl} setRedirectUrl={setRedirectUrl}>
        {children}
      </Auth0Watcher>
    </Auth0ProviderModule>
  )
}

const LoadingSpinnerContainer = styled.div`
  display: flex;
  padding-top: 12rem;
  justify-content: center;
  svg {
    height: 126px;
  }
`

export default Auth0Provider
