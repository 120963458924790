import { useEffect, useState } from 'react'
import { ReactComponent as ShowIcon } from 'assets/icons/show.svg'
import Select from 'components/Select'
import { usePlusClient } from 'providers/VisionRouter'
import styled from 'styled-components'
import { primaryBackgroundColor, bodyPrimaryColor, borderColor } from 'styles/styleVariables'

const StyledSelectButton = styled(Select)`
  border-color: ${borderColor};
  margin-left: 0.625rem;
  background-color: ${primaryBackgroundColor};
  color: ${bodyPrimaryColor};
  > svg {
    fill: ${bodyPrimaryColor};
  }
  > div {
    > svg {
      fill: ${bodyPrimaryColor};
    }
  }
`

export default function PageSizeDropdown(): JSX.Element {
  const { routeToMergedQuery, currentLocation } = usePlusClient()
  const options = [10, 50, 100].map(i => ({ label: i.toString(), value: i }))
  const [value, setValue] = useState(10)
  useEffect(() => {
    if (currentLocation.queryMap?.pageSize && value && value !== currentLocation.queryMap?.pageSize) {
      setValue(parseInt(currentLocation.queryMap?.pageSize, 10))
    }
  }, [currentLocation.queryMap?.pageSize, value])

  return (
    <StyledSelectButton
      hotKey="p"
      options={options}
      value={value.toString()}
      placeholder="PageSize"
      icon={<ShowIcon />}
      onChange={([option]) => {
        routeToMergedQuery({ pageSize: option.value })
      }}
      showActive={true}
      disableClear={true}
    />
  )
}
