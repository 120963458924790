import type { InventoryGroup } from 'types'
import {
  InventoryGroupTabsContainer,
  InventoryGroupTab,
  InventoryGroupTabSelected,
  InventoryGroupTabWrap,
} from './StyledComponents'

interface InventoryGroupSelectorProps {
  inventoryGroups: InventoryGroup[]
  selectedInventoryGroup: InventoryGroup
  onSelectInventoryGroup: (inventoryGroup: InventoryGroup) => void
}

export const InventoryGroupSelector = ({
  inventoryGroups,
  selectedInventoryGroup,
  onSelectInventoryGroup,
}: InventoryGroupSelectorProps): JSX.Element => {
  // there should be at least two inventory groups for this selector to appear: the default one and the customer's inventory group
  if (!inventoryGroups || inventoryGroups.length < 2) return <></>

  return (
    <InventoryGroupTabsContainer>
      {inventoryGroups.map(invGroup => {
        const { pioneerAlias, _id, customer } = invGroup
        const label = `${pioneerAlias} (${customer.name})`
        return (
          <InventoryGroupTabWrap
            onClick={() => {
              onSelectInventoryGroup(invGroup)
            }}
          >
            {_id === selectedInventoryGroup?._id ? (
              <InventoryGroupTabSelected>{label}</InventoryGroupTabSelected>
            ) : (
              <InventoryGroupTab>{label} </InventoryGroupTab>
            )}
          </InventoryGroupTabWrap>
        )
      })}
    </InventoryGroupTabsContainer>
  )
}
