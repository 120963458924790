import { useCallback, useMemo } from 'react'
import { useHistory, useLocation } from '@truepill/tpos-react-router'
import { isNil } from 'ramda'

/**
 * A hook that can be used in a similar way to `useState`, but that will save
 * the value in the URL's query params. This hook is meant only for strings and
 * automatically handles the details of them.
 */
export default function useQueryParamString(
  queryParam: string,
  defaultValue: string | undefined = undefined,
): [string | undefined, (value?: string) => void] {
  const history = useHistory()
  const { pathname, search } = useLocation()
  const searchObj = useMemo(() => new URLSearchParams(search), [search])

  const setQueryParam = useCallback(
    (value: string | undefined = defaultValue) => {
      const updatedSearch = new URLSearchParams(searchObj.toString())
      if (isNil(value)) {
        updatedSearch.delete(queryParam)
      } else {
        updatedSearch.set(queryParam, value)
      }

      if (updatedSearch.toString() === searchObj.toString()) {
        return
      }

      history.push(updatedSearch.toString().length > 0 ? `${pathname}?${updatedSearch.toString()}` : pathname)
    },
    [defaultValue, history, pathname, queryParam, searchObj],
  )

  return [searchObj.get(queryParam) ?? defaultValue, setQueryParam]
}
