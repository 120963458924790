import { useParams, Redirect, useQuery } from '@truepill/tpos-react-router'
import { GET_COPAY_BY_CORE_COPAY_TOKEN } from 'gql'
import { goToFulfillmentCopay, goToViewPharmacyCopay } from 'routes'
import styled from 'styled-components'
import type { CopayQueryResult } from 'types'

export const LoadFromCoreCopayToken = (): JSX.Element => {
  const routeParams = useParams<{ coreCopayToken: string; fulfillmentQueueName?: string }>()
  const { data } = useQuery<CopayQueryResult>(GET_COPAY_BY_CORE_COPAY_TOKEN, {
    variables: { coreCopayToken: routeParams.coreCopayToken },
  })

  if (data?.copayRequest?._id) {
    const redirectParams = { copayRequestId: data.copayRequest._id }
    const copayUrl = routeParams.fulfillmentQueueName
      ? goToFulfillmentCopay(redirectParams)
      : goToViewPharmacyCopay(redirectParams)
    return <Redirect to={copayUrl} />
  }

  if (data?.copayRequest === null) {
    return (
      <Container>
        <h1>Copay Not Found</h1>
        <p>Could not find copay for Copay Token: {routeParams.coreCopayToken}</p>
      </Container>
    )
  }

  return <></>
}

const Container = styled.div`
  margin: 30px;
  p {
    margin: 10px 0;
  }
`
