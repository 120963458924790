import { Link } from '@truepill/tpos-react-router'
import { FilledHeadingStyle } from 'components/PageStructure'
import styled, { css } from 'styled-components'
import EllipsisTruncate from 'styles/EllipsisTruncate'
import { contrastBackgroundColor, primaryColorLight } from 'styles/styleVariables'

const EntryStyle = css`
  :nth-of-type(2n + 1) {
    background-color: ${contrastBackgroundColor};
  }
  display: grid;
  grid-column: prescription;
  grid-template-rows: [content] auto;
  grid-template-columns: [checkbox] auto [content] 1fr;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
`

const TrucateTextPosition = css`
  display: block;
  margin-top: 0.5rem;
`

const StyledLink = styled(Link)`
  grid-column: content;
  :hover {
    background-color: ${primaryColorLight};
    :nth-of-type(2n + 1) {
      background-color: ${primaryColorLight};
    }
  }
  ${EntryStyle}
`

const PrescriptionRow = styled.ul`
  display: grid;
  grid-column: content;
  grid-template-rows: [content] 2.5rem;
  grid-template-columns:
    [rxNumber] minmax(6rem, 8rem) [customer] minmax(7rem, 0.8fr)
    [fillNumber] 3.5rem [medication] minmax(4rem, 1.8fr)
    [qty] minmax(2.5rem, 0.5fr)
    [ds] minmax(3rem, 0.6fr) [directions] minmax(3rem, 2fr)
    [qtyLeft] minmax(5rem, 0.7fr) [refillsLeft] minmax(4rem, 0.8fr) [written] minmax(6.2rem, 1fr)
    [fill] minmax(6rem, 1fr)
    [prescriber] minmax(4rem, 1.6fr)
    [status] 10rem
    [quantity] 4rem;
`

const TableTitleRow = styled.ul`
  display: grid;
  grid-template-rows: [content] auto;
  grid-template-columns: [checkbox] auto [content] 1fr;
  ${FilledHeadingStyle}
  font-weight: 500;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  border-radius: 0.25rem 0.25rem 0rem 0rem;
  position: sticky;
  top: 0rem;
`

const PrescriptionRowCell = styled.li`
  grid-row: 1;
  ${EllipsisTruncate}
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 0.3125rem;
  margin-right: 0.3125rem;
`

const RXNumber = styled(PrescriptionRowCell)`
  grid-column: rxNumber;
  ${TrucateTextPosition}
`

const Fill = styled(PrescriptionRowCell)`
  grid-column: fillNumber;
  ${EllipsisTruncate}
`

const Medication = styled(PrescriptionRowCell)`
  grid-column: medication;
  ${TrucateTextPosition}
`

const Quantity = styled(PrescriptionRowCell)`
  grid-column: qty;
  ${TrucateTextPosition}
`

const Directions = styled(PrescriptionRowCell)`
  grid-column: directions;
  ${TrucateTextPosition};
`

const DaysSupply = styled(PrescriptionRowCell)`
  grid-column: ds;
  ${TrucateTextPosition}
`

const RemainingQuantity = styled(PrescriptionRowCell)`
  grid-column: qtyLeft;
  ${TrucateTextPosition};
`

const RemainingRefills = styled(PrescriptionRowCell)`
  grid-column: refillsLeft;
  ${TrucateTextPosition};
`

const Written = styled(PrescriptionRowCell)`
  grid-column: written;
  ${TrucateTextPosition};
`

const PrescriberRX = styled(PrescriptionRowCell)`
  grid-column: prescriber;
  ${TrucateTextPosition};
`

const Customer = styled(PrescriptionRowCell)`
  grid-column: customer;
  ${TrucateTextPosition}
`

const FillDate = styled(PrescriptionRowCell)`
  grid-column: fill;
  ${TrucateTextPosition}
`

const Status = styled(PrescriptionRowCell)`
  grid-column: status;
`

const RequestQuantity = styled(PrescriptionRowCell)`
  grid-column: quantity;
`

export {
  StyledLink,
  PrescriptionRow,
  TableTitleRow,
  RXNumber,
  Fill,
  Medication,
  Quantity,
  Directions,
  DaysSupply,
  RemainingQuantity,
  RemainingRefills,
  Written,
  PrescriberRX,
  Customer,
  FillDate,
  Status,
  RequestQuantity,
}
