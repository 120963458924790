// NDC Barcodes are always a 10 digit ndc instead of a 11
// Anyway, we should check the 10 digit against the full ndc

import { ABRatedCodes } from '@truepill/tpos-types'

// in the 3 potential ways it could match NDC or GTIN
//note: this function can work for both linear and datamatrix barcodes
export const validateNdcBarcode = (scannedCode: string, ndc: string, isGtin = false): string | undefined => {
  // For NDC (linear barcodes), the first digit is always a 3 and the last digit is always a control
  // For GTIN (datamatrix), we want to remove the first 3 digits ('003') and the last digit (control digit)
  const strippedCode = scannedCode.substring(isGtin ? 3 : 1, scannedCode.length - 1)

  // the string can be formatted as either 4-4-2, 5-3-2 or 5-4-1 digits.
  const style442 = 0 + strippedCode

  if (ndc === style442.replace(/-/g, '')) {
    return style442
  }
  const style532 = strippedCode.slice(0, 5) + '0' + strippedCode.slice(5)
  if (ndc === style532.replace(/-/g, '')) {
    return style532
  }
  const style541 = strippedCode.slice(0, 9) + '0' + strippedCode.slice(9)
  if (ndc === style541.replace(/-/g, '')) {
    return style541
  }
}

export const isABRatedSubstitution = (teCode: string): boolean =>
  (Object.values(ABRatedCodes) as string[]).includes(teCode)

export const isScannedCodeAnAlternateBarCode = (scannedCode: string, alternateBarCodes: string[]): boolean => {
  const noVerificationDigitsNumber = scannedCode.substring(1, scannedCode.length - 1)
  return alternateBarCodes.includes(noVerificationDigitsNumber) || alternateBarCodes.includes(scannedCode)
}
