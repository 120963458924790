import type { Error } from './Error'
import type { MessageHeader } from './shared'
import type { Address, Name } from './sharedBase'
import type { CommunicationNumbers } from './sharedComplex'
import type { Status } from './Status'

// Download Flatfile types
export interface ProviderDownloadElements {
  SPI: string
  NPI?: string // NPI is not required for Clinical Direct Messaging
  DEANumber?: string
  StateLicenseNumber?: string
  Specialty?: string
  Prefix?: string
  LastName: string
  FirstName: string
  MiddleName: string
  Suffix: string
  BusinessName?: string
  AddressLine1: string
  AddressLine2?: string
  City: string
  StateProvince: string
  PostalCode: number
  CountryCode: string
  StandardizedAddressLine1?: string // *The standardized address from the address information in the add/update messages. Based on a CASS (Coding Accuracy Support System) standardization. Marks them as mailable/deliverable by USPS as a physical mailing address
  StandardizedAddressLine2?: string
  StandarizedCity?: string
  StandardizedStateProvince?: string
  StandardizedPostalCode?: number
  PrimaryTelephone?: string // telephone number (n10), extension (n1..8), and supportsSMS (an1) fields separated by ^ (caret) Example: |6512321111^1233^Y| or |6512321111^1233|
  Fax?: string // number (n10), extension (n1..8), and supportsSMS (an1) fields separated by ^ (caret) Example: |6512321111^^N| or |6512321111|
  ElectronicMail?: string
  AlternatePhoneNumbers?: string // The field is a composite of qualifier, telephone number, extension, and supportsSMS, separated by ^ (caret). Multiple communication numbers are separated by ~ (tilde.*The character limit noted in the data type applies to each composite communication number value in a repeatable field element and may repeat up to six (6) times. Note: If null or blank value is at the end of the string it will be dropped. Example - two repetitions: |BN^6512321111^1233^Y~WP^6122212222 | The messaging schema maps the following qualifiers that will be published in the download. Values: BN = Beeper HP = HomeTelephone WP = WorkTelephone TP = OtherTelephone
  ActiveStartTime: string // utc date type
  ActiveEndTime: string // utc date type
  ServiceLevel?: string // Designates the provider's capabilities. Multiple service levels are separated by ~. *The character limit noted in the Data Type applies to each individual value in a repeatable field element. May repeat up to 50 times. Example: |New~Refill|. For more detail, please see the Service Level Descriptions description. Note: System should not fail if an unexpected value is received.
  PartnerAccount?: string
  LastModifiedDate: string
  RecordChange?: string // Indicates whether this record is new, updated, or disabled. Only populated in the nightly download. Values: - N=New-recordswithActiveStartTimewithinthe last 24 hours. - U=Updated-recordswithLastModifiedTimewithin the last 24 hours that are not "N" or "D". - D=Disabled-recordswithActiveEndTimewithinthe last 24 hours.
  OldServiceLevel?: string // Previous service level(s). Multiple service levels separated by ~. Only populated in the nightly download. The character limit noted in the Data Type applies to each individual value in a repeatable field element. Example: |New~Refill|. Valid values are case sensitive and cannot be repeated. For more detail, please see the Service Level Descriptions section.
  Version?: string // ex: v6_1_erx, v10_6mu2
  DirectorySpecialtyName?: string
  MedicareNumber?: string
  MedicaidNumber?: string
  UPIN?: string
  CertificateToPrescribe?: string
  Data2000WaiverID?: string // This is used for the NADEAN (Narcotic Addiction DEA Number).
  REMSHealthCareProviderEnrollmentID?: string
  StateControlSubstanceNumber?: string
  MutuallyDefined?: string // For providers participating in the Learning Directory, this field will contain "LL" for a record that has been learned and included in the directory. Records created under the Direct Trust account (for direct messaging) will contain the associated HISP vendor value.
  DirectAddress?: string
  UseCases?: string
  AvailableRoutes: string // Repetitions separated by ~. The character limit noted in the Data Type applies to each individual value in a repeatable field element. Example: |CI EDI~CI Mail|| Values: l CIEDI l CIFax l CIMail Note: System should not fail if an unexpected value is received. Not used for E-prescribing.
  OrganizationID?: number
  Latitude?: number
  Longitude?: number
  Precise?: string
}

export interface OrganizationDownloadElements {
  NCPDPID?: string // Only present for Pharmacy Organization type. NCPDPID is 7 digits.
  StoreNumber?: string
  OrganizationName: string
  AddressLine1: string
  AddressLine2?: string
  City: string
  StateProvince: string
  PostalCode: number
  CountryCode: string
  StandardizedAddressLine1?: string
  StandardizedAddressLine2?: string
  StandarizedCity?: string
  StandardizedStateProvince?: string
  StandardizedPostal?: number
  PrimaryTelephone?: string // telephone number (n10), extension (n1..8), and supportsSMS (an1) fields separated by ^ (caret) Example: |6512321111^1233^Y| or |6512321111^1233|
  Fax?: string // number (n10), extension (n1..8), and supportsSMS (an1) fields separated by ^ (caret) Example: |6512321111^^N| or |6512321111|
  ElectronicMail?: string
  AlternatePhoneNumbers?: string // The field is a composite of qualifier, telephone number, extension, and supportsSMS, separated by ^ (caret). Multiple communication numbers are separated by ~ (tilde.*The character limit noted in the data type applies to each composite communication number value in a repeatable field element and may repeat up to six (6) times. Note: If null or blank value is at the end of the string it will be dropped. Example - two repetitions: |BN^6512321111^1233^Y~WP^6122212222 | The messaging schema maps the following qualifiers that will be published in the download. Values: BN = Beeper HP = HomeTelephone WP = WorkTelephone TP = OtherTelephone
  ActiveStartTime: string // utc date type
  ActiveEndTime: string // utc date type
  ServiceLevel?: string // Designates the provider's capabilities. Multiple service levels are separated by ~. *The character limit noted in the Data Type applies to each individual value in a repeatable field element. May repeat up to 50 times. Example: |New~Refill|. For more detail, please see the Service Level Descriptions description. Note: System should not fail if an unexpected value is received.
  PartnerAccount?: string
  LastModifiedDate: string
  CrossStreet?: string
  RecordChange?: string
  OldServiceLevel: string
  Version?: string
  NPI?: string
  DirectorySpecialtyName?: string
  ReplaceNCPDPID?: string
  StateLicenseNumber?: string
  UPIN?: string
  FacilityID?: string
  MedicareNumber?: string
  MedicaidNumber?: string
  PayerID?: string
  DEANumber?: string
  HIN?: string
  MutuallyDefined?: string
  DirectAddress?: string
  OrganizationType: string
  OrganizationID
  ParentOrganizationID?: string
  Latitude?: string
  Longitude?: string
  Precise?: string
  UseCase?: string
}

// Common to Add and Update messages and their responses
export interface AlternatePhoneNumber {
  type: string
  phoneNumber: string
  ext?: string
  sms?: string
}

export interface DirectoryInformation {
  AccountID: string
  PortalID: string
  ActiveStartTime: string
  ActiveEndTime: string
}

export enum DownloadType {
  ProviderLocation = 'ProviderLocation',
  Organization = 'Organization',
}

export interface DirectoryMessageRoot {
  DirectoryMessage: DirectoryMessage
}
export interface DirectoryMessage {
  Header: MessageHeader
  Body: Body
}

interface Body {
  DirectoryDownload?: DirectoryDownload
  DirectoryDownloadResponse?: DirectoryDownloadResponse
  GetProviderLocation?: GetProviderLocation
  GetOrganization?: GetOrganization
  AddProviderLocation?: AddProviderLocation
  Status?: Status
  Error?: Error
}

export interface DirectoryDownload {
  AccountID: string
  DownloadType: string
  DirectoryDate?: string // UTC, omit date and you get a full download file
  VersionID?: 61
}

export interface DirectoryDownloadResponse {
  URL?: string
}

export interface ProviderLocation {
  Identification: ProviderIdentification
  Specialty?: string // max 10 char length, http://www.wpc-edi.com/reference/ click Health Care Provider Taxonomy Code. (ransomware as of May 2020)
  PracticeLocation?: DirectoryPracticeLocation
  Name?: Name
  FormerName?: Name // Former name, maiden name, alias, nickname, etc. of the provider.
  Address?: Address
  CommunicationNumbers?: CommunicationNumbers
}

export interface GetProviderLocation {
  ProviderLocation: ProviderLocation
}

export interface BaseIdentification {
  StateLicenseNumber?: string // max 35 char length
  MedicareNumber?: string // max 35 char length
  MedicaidNumber?: string // max 35 char length
  UPIN?: string // max 35 char length
  DEANumber?: string // REQUIRED for controlled substance, max 35 char length  *special rules for military heathcare providers
  HIN?: string // max 35 char length
  NPI?: string // max 35 char length, Provider records must contain the individual (not organizational) NPI of the Provider.
  // Required for directory and/or message inclusion based on Product (including e-prescribing.) Not required for Clinical Direct Messaging, but should be sent when available.
}

export interface ProviderIdentification extends BaseIdentification {
  SocialSecurity?: string // max 35 char length, do not include punctuation, Required for prescribing of controlled substances if provider is military or public health provider and does not have a DEANumber, along with providing the military or public health provider Specialty field with one of the following codes: "2083P0901X", "171000000X", "1710I1002X", or "1710I1003X". Do not send if provider has a DEANumber. Note: This field is not published to the download file.
  CertificateToPrescribe?: string // max 35 char length
  Data2000WaiverID?: string // max 35 char length
  MutallyDefined?: string // max 35 char length
  REMSHealthcareProviderEnrollmentID?: string // max 35 char length
  StateControlSubstanceNumber?: string // max 35 char length
  SPI?: string // max 13 char length, This field is only allowed when updating an established SPI using the UpdateProviderLocation Message.
  Veterinarian?: string // y, n
}

export interface DirectoryPracticeLocation {
  Identification: DirectoryPracticeLocationID
}

export interface DirectoryPracticeLocationID {
  OrganizationID?: string // max 10 char length
  BusinessName?: string // max 75 char length
}

export interface GetOrganization {
  Organization: Organization
}

export interface Organization {
  Identification: OrganizationIdentification
}

export interface OrganizationIdentification extends BaseIdentification {
  NCPDPID?: string // max length 35 char, REQUIRED for pharmacy org type, Must be 7 digits, unique, and not already in the directory.
  FacilityID?: string // max length 35 char
  PayerID?: string // max length 80 char
  MutuallyDefined?: string // max length 35 char, This field may be populated by the customer with relevant identifiers internal to their database.
  IHEID?: string // max length 64 char
  OrganizationID?: string // max 10 char length
  StoreNumber?: string // max 35 char length
  OrganizationName?: string //  char length 70 for pharmacy, 100 for General Organization, Pharmacy or Payer, The name of the Pharmacy, Payer, or General Organization.
  OrganizationType?: OrganizationType
  Address?: Address
  CommunicationNumbers?: CommunicationNumbers
}

export enum OrganizationType {
  'General',
  'Pharmacy',
  'Payer',
}

export interface AddProviderLocation {
  DirectoryInformation: DirectoryInformation
  ProviderLocation: ProviderLocation
}
