import { useState } from 'react'
import { PrescriberFormField } from 'components/NewPrescriberForm'
import type { Prescriber } from 'types'

type useAddPrescriberType = {
  npi?: string
  dea: string
  firstName: string
  lastName: string
  suffix: string
  stateLicense?: string
  handlePrescriberFormChange: (field: PrescriberFormField, value: string | undefined) => void
  clearPrescriberForm: () => void
}

const useAddPrescriber = (prescriber?: Partial<Prescriber>): useAddPrescriberType => {
  const [npi, setNpi] = useState<string | undefined>(prescriber?.npi)
  const [dea, setDea] = useState<string>(prescriber?.dea || '')
  const [firstName, setFirstName] = useState<string>(prescriber?.firstName || '')
  const [lastName, setLastName] = useState<string>(prescriber?.lastName || '')
  const [suffix, setSuffix] = useState<string>(prescriber?.suffix || '')
  const [stateLicense, setStateLicense] = useState<string | undefined>(prescriber?.stateLicense)

  const handlePrescriberFormChange = (field: PrescriberFormField, value: string | undefined) => {
    switch (field) {
      case PrescriberFormField.Npi:
        setNpi(value as string)
        break
      case PrescriberFormField.Dea:
        setDea(value as string)
        break
      case PrescriberFormField.FirstName:
        setFirstName(value as string)
        break
      case PrescriberFormField.LastName:
        setLastName(value as string)
        break
      case PrescriberFormField.Suffix:
        setSuffix(value as string)
        break
      case PrescriberFormField.StateLicense:
        setStateLicense(value as string)
        break
      default:
        break
    }
  }

  const clearPrescriberForm = () => {
    setNpi(undefined)
    setDea('')
    setFirstName('')
    setLastName('')
    setSuffix('')
    setStateLicense(undefined)
  }

  return { npi, dea, firstName, lastName, suffix, stateLicense, handlePrescriberFormChange, clearPrescriberForm }
}

export default useAddPrescriber
