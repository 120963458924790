import { useState } from 'react'
import { useMutation, useQuery } from '@truepill/tpos-react-router'
import { LaunchDarkly, RxStatus } from '@truepill/tpos-types'
import { ReactComponent as LoadingDots } from 'assets/icons/loading_dots.svg'
import { ReactComponent as ReplaceIcon } from 'assets/icons/replace.svg'
import { ReactComponent as TakeoverIcon } from 'assets/icons/transfer-in.svg'
import ActionButton, { SaveButton } from 'components/ActionButton'
import IconWrapper from 'components/IconWrapper'
import LoadingSpinner from 'components/Loading'
import { ModalWrapper, ModalHeader, InputContainer, ButtonsContainer } from 'components/Modal'
import { NumericInput } from 'components/NumericInput'
import { CancelButton } from 'components/PageStructure'
import { PrescriptionsTable } from 'components/PrescriptionsTable'
import Select from 'components/Select'
import { GET_PATIENT_PRESCRIPTIONS, RUN_TAKEOVER_RX } from 'gql'
import gql from 'graphql-tag'
import { Box } from 'grommet'
import useErrorToast from 'hooks/toast/useErrorToast'
import { SELECTED_PRESCRIPTION_NAMESPACE } from 'NameSpaces'
import FormDataProvider, { useFormDataContext } from 'providers/FormDataProvider'
import { useFlag } from 'providers/LaunchDarklyProvider'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import SelectionProvider, { useSelectionContext } from 'providers/SelectionProvider'
import type { Selectable } from 'providers/SelectionProvider'
import { useTPCacheContext } from 'providers/TPCacheProvider'
import { usePlusClient } from 'providers/VisionRouter'
import styled from 'styled-components'
import { bodyPrimaryColor, contrastColor } from 'styles/styleVariables'
import type { Patient, Prescription, SimpleKV } from 'types'

interface ReplaceRxFillRequestModalProps {
  completionCallback: (rxId: string, quantity: number) => void
  patientId: Patient['_id']
  initialPrescriptionIds?: Prescription['_id'][]
}

const GET_PATIENT_PRESCRIPTIONS_FEAT_FLAG = gql`
  query patientPrescriptions(
    $patientIds: [ID]
    $statuses: [String]
    $customerIds: [ID]
    $pageNumber: Int
    $pageSize: Int
  ) {
    getPrescriptions(
      patientIds: $patientIds
      statuses: $statuses
      customerIds: $customerIds
      pageNumber: $pageNumber
      pageSize: $pageSize
    ) {
      currentPage
      totalRecords
      prescriptions {
        _id
        rxNumber
        customer {
          _id
          name
          legacyId
        }
        fills {
          _id
          fillNumber
          dispensed {
            dispensedAt
          }
        }
        name
        quantity
        daysSupply
        directions
        quantityRemaining
        refillsRemaining
        writtenDate
        patient {
          firstName
          lastName
        }
        prescriber {
          firstName
          lastName
          suffix
        }
        status
        strength {
          value
          form
          unit
        }
      }
    }
  }
`

const ReplaceRxFillRequestModal = (props: ReplaceRxFillRequestModalProps) => {
  const { patientId, completionCallback } = props
  const showErrorToast = useErrorToast(true)
  const {
    currentLocation: { queryMap },
    tokenContext,
    routeToMergedQuery,
  } = usePlusClient()
  const { dismissModal } = useModalContext()
  const { locations, getLocationNameById } = useTPCacheContext()
  const [waitingTakeover, setWaitingTakeover] = useState(false)
  const [selectedRx, setSelectedRx] = useState('')
  const [takeoverRxNumber, setTakeoverRxNumber] = useState<number | undefined>()
  const [takeoverRxLocation, setTakeoverRxLocation] = useState({
    value: tokenContext.locationId,
    label: getLocationNameById(tokenContext.locationId || ''),
  })

  const [takeOverRx] = useMutation(RUN_TAKEOVER_RX, {
    onCompleted({ takeOverPrescription }) {
      setWaitingTakeover(false)
      setTakeoverRxNumber(0)
      setSelectedRx(takeOverPrescription._id)
    },
    onError(err) {
      dismissModal()
      setWaitingTakeover(false)
      showErrorToast(`Failed to takeover prescription! ${err.message.replace('GraphQL error:', '')}`)
    },
  })

  const locationOptions = [...locations]
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(location => ({ label: location.name, value: location._id }))

  const pageSize = queryMap.pageSize ? parseInt(queryMap.pageSize, 10) : 10

  // This variable will help us decide which query we will use depending if the FF is turned on/off
  const shouldUseNewGraphqlQuery = useFlag(LaunchDarkly.FeatureFlags.TEMP_USE_GRAPHQL_QUERY_IN_COMPONENT_FILE)

  const { data, error, loading } = useQuery(
    shouldUseNewGraphqlQuery ? GET_PATIENT_PRESCRIPTIONS_FEAT_FLAG : GET_PATIENT_PRESCRIPTIONS,
    {
      variables: {
        patientIds: [patientId],
        statuses: [RxStatus.OnHold, RxStatus.Filled],
        pageNumber: Number(queryMap?.page) || 0,
        pageSize,
      },
    },
  )

  const prescriptions = data ? data.getPrescriptions.prescriptions : []
  const totalPrescriptions = data?.getPrescriptions.totalRecords ?? 0
  const currentPage = data?.getPrescriptions.currentPage ?? 0
  const errorMessage = error ? error.message : undefined
  const { selections } = useSelectionContext()
  const { formData = {} } = useFormDataContext()
  const { prescriptions: selectedPrescriptions } = formData[SELECTED_PRESCRIPTION_NAMESPACE] as {
    prescriptions: SimpleKV[]
  }

  if (totalPrescriptions < pageSize && queryMap?.page > 0) {
    routeToMergedQuery({ page: 0 })
  }

  const anySelected = selections.length > 0

  const buttonIcon = waitingTakeover ? (
    <WideLoadingDots />
  ) : (
    <IconWrapper>
      <TakeoverIcon />
    </IconWrapper>
  )

  return (
    <ModalWrapper id="ReplaceRxFillRequestModal">
      <StyledModalHeader>
        <IconWrapper>
          <ReplaceIcon fill={bodyPrimaryColor} />
        </IconWrapper>
        <h2>Swap prescription</h2>
      </StyledModalHeader>
      <InputContainer>
        <PrescriptionsTable
          error={errorMessage}
          loading={loading}
          prescriptions={prescriptions}
          totalPrescriptions={totalPrescriptions}
          currentPage={currentPage}
          selectedPrescription={selectedRx}
          withRadioSelection
          disableLinks
          withQuantity
        />
        <ImportPrescriptionContainer>
          <ImportLabel>Import prescription</ImportLabel>
          <StyledNumericInput value={takeoverRxNumber} onChange={newValue => setTakeoverRxNumber(newValue)} />
          <StyledSelect
            fadePlaceholderText={false}
            options={locationOptions}
            value={takeoverRxLocation}
            placeholder="Location"
            onChange={([option]) => setTakeoverRxLocation({ value: option?.value, label: option.label })}
            disableClear={true}
            showLoadingAnimation={locationOptions.length <= 1}
          />
          <StyledActionButton
            label={waitingTakeover ? '' : 'Rx takeover'}
            disabled={!takeoverRxNumber}
            icon={buttonIcon}
            onClick={() => {
              setWaitingTakeover(true)
              takeOverRx({
                variables: {
                  rxNumber: takeoverRxNumber,
                  patientId,
                  locationId: takeoverRxLocation.value,
                },
                refetchQueries: ['patientPrescriptions'],
              })
            }}
          />
        </ImportPrescriptionContainer>
      </InputContainer>
      {waitingTakeover && (
        <LoadingContainer>
          <LoadingSpinnerContainer>
            <LoadingSpinner />
          </LoadingSpinnerContainer>
          <LoadingText>Takeover in process. Please wait, it may take a while...</LoadingText>
        </LoadingContainer>
      )}
      <StyledButtonsContainer>
        <CancelButton label={'Cancel'} onClick={dismissModal} />
        <SaveButton
          label={'Swap'}
          disabled={!anySelected}
          isModal
          onClick={() => {
            const selectedRxKV = selectedPrescriptions.find(rx =>
              selections.map(sel => sel._id).includes(Object.keys(rx)[0]),
            )
            if (selectedRxKV) completionCallback(Object.keys(selectedRxKV)[0], Object.values(selectedRxKV)[0] as number)
            dismissModal()
          }}
        />
      </StyledButtonsContainer>
    </ModalWrapper>
  )
}

const WrappedReplaceRxFillRequestModal = (props: ReplaceRxFillRequestModalProps): JSX.Element => {
  const { initialPrescriptionIds = [] } = props
  const initialSelections: Selectable[] = initialPrescriptionIds.reduce((acc, id) => {
    acc.push({ _id: id })

    return acc
  }, [] as Selectable[])

  return (
    <FormDataProvider
      initialData={{
        [SELECTED_PRESCRIPTION_NAMESPACE]: { prescriptions: [] },
      }}
    >
      <SelectionProvider initialSelections={initialSelections}>
        <ReplaceRxFillRequestModal {...props} />
      </SelectionProvider>
    </FormDataProvider>
  )
}

const StyledModalHeader = styled(ModalHeader)`
  margin-bottom: 1rem;
`

const StyledButtonsContainer = styled(ButtonsContainer)`
  margin-bottom: 0.625rem;
`

const ImportPrescriptionContainer = styled(Box)`
  display: grid;
  align-items: center;
  padding: 0.625rem;
  border-bottom: 0.25rem solid;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  grid-template-rows: auto;
  grid-template-columns: [label] 9rem [input] auto [location] 9.5rem [button] 8.5rem;
`
const ImportLabel = styled.p`
  display: flex;
  grid-column: label;
  font-weight: 700;
`

const StyledNumericInput = styled(NumericInput)`
  display: flex;
  grid-column: input;
`

const StyledSelect = styled(Select)`
  display: flex;
  margin-left: 0.625rem;
  grid-column: location;
  min-height: 2rem;
`

const StyledActionButton = styled(ActionButton)`
  display: flex;
  align-items: center;
  grid-column: button;
  height: 2rem;
  svg {
    fill: ${contrastColor};
  }
`

const WideLoadingDots = styled(LoadingDots)`
  width: 100%;
`

const LoadingText = styled.p`
  font-weight: 700;
  font-size: 20px;
  margin-top: 15px;
`

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const LoadingSpinnerContainer = styled.div`
  display: flex;
  padding-top: 0.8rem;
  justify-content: center;
  svg {
    height: 120px;
  }
`

export default WrappedReplaceRxFillRequestModal
