import type { RefObject } from 'react'
import { useState, useEffect, useCallback } from 'react'
import { BannerAlert, Button, Grid, GridItem, Text } from '@truepill/react-capsule'
import { useMutation } from '@truepill/tpos-react-router'
import { UserRoles } from '@truepill/tpos-types'
import { FormItem, FormItems, Select, WideTextInput } from 'components/ColumnForm'
import DatePicker from 'components/DatePicker'
import LocationSelectorDropDown from 'components/LocationSelectorDropDown/Legacy'
import type { Option } from 'components/Select'
import type { UploadState } from 'components/UploadTarget'
import UploadTarget from 'components/UploadTarget'
import { CREATE_DOCUMENT } from 'gql'
import useAutoFocus from 'hooks/useAutoFocus'
import { useFormData } from 'hooks/useFormData'
import moment from 'moment'
import styled from 'styled-components'
import type { User, LicenseWithImage } from 'types'
import { fileToUploadState } from 'utils/fileUpload'
import states from 'utils/states'
import { v4 as uuidv4 } from 'uuid'
import { notEmpty } from '../../utils'

export interface UserFormData extends User {
  locationId: string
}
const ROLE_OPTIONS = Object.values(UserRoles)

interface UserFormProps {
  className?: string
  isNewUser?: boolean
  modal?: boolean
}

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

const StyledFormItems = styled(FormItems)`
  width: 24rem;
`

const StyledBannerAlert = styled(BannerAlert)`
  margin-bottom: 12px;
`

const StyledLicenseHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

const StyledDatePicker = styled.div`
  height: 2.5rem;
  > div {
    height: 100%;
  }
`

const StyledFormLabel = styled(Text).attrs(props => ({ as: props.as || 'h3', bold: true }))`
  margin-bottom: 4px;
`

function licenseFactory() {
  return {
    id: uuidv4(),
    number: '',
    expirationDate: new Date(),
    registeredState: '',
  }
}

const LicenseForm = ({
  license,
  number,
  className = '',
}: {
  license: LicenseWithImage
  number: number
  className?: string
}) => {
  const {
    actions: { updateFormData },
  } = useFormData()

  const [uploadFile] = useMutation(CREATE_DOCUMENT)
  const [uploadingFile, setUploadingFile] = useState<UploadState>()
  const [error, setError] = useState('')

  useEffect(() => {
    if (!!license.image?.s3SignedUrl) {
      setUploadingFile({ uploaded: true, dataURL: license.image.s3SignedUrl } as UploadState)
    }
  }, [license.image?.s3SignedUrl])

  const removeSelf = () =>
    updateFormData({
      user: { licenses: { $splice: [[number - 1, 1]] } },
    })

  return (
    <>
      <StyledLicenseHeader>
        <Text bold as="h2">
          License {number}
        </Text>
        <Button onClick={removeSelf} size="xs" variant="primary-text">
          Delete
        </Button>
      </StyledLicenseHeader>
      <FormItems className={className}>
        <FormItem>
          <StyledFormLabel>License Number</StyledFormLabel>
          <WideTextInput
            value={license.number}
            onChange={e => {
              updateFormData({
                user: { licenses: { [number - 1]: { number: { $set: e.target.value } } } },
              })
            }}
          />
        </FormItem>
        <Grid>
          <GridItem desktop={6}>
            <FormItem>
              <StyledFormLabel>Expiration Date</StyledFormLabel>
              <StyledDatePicker>
                <DatePicker
                  date={license.expirationDate ? moment(license.expirationDate).toDate() : undefined}
                  onChange={date => {
                    if (!date) return
                    updateFormData({
                      user: { licenses: { [number - 1]: { expirationDate: { $set: date } } } },
                    })
                  }}
                />
              </StyledDatePicker>
            </FormItem>
          </GridItem>
          <GridItem desktop={6}>
            <FormItem>
              <StyledFormLabel>Registered State</StyledFormLabel>
              <Select
                placeholder={'Select state'}
                value={license.registeredState}
                options={states}
                onChange={options => {
                  const val = options?.[0]?.value ?? ''

                  updateFormData({
                    user: { licenses: { [number - 1]: { registeredState: { $set: val } } } },
                  })
                }}
              />
            </FormItem>
          </GridItem>
        </Grid>
        <FormItem>
          <StyledFormLabel>Image (Optional)</StyledFormLabel>
          <UploadTarget
            uploaded={uploadingFile ? [uploadingFile] : undefined}
            onFilesDropped={async ([file]: File[]) => {
              const uploadState = await fileToUploadState(file)
              setUploadingFile(uploadState)
              try {
                const resp = await uploadFile({ variables: { file } })
                const { createDocument } = resp.data
                setUploadingFile({ ...uploadState, uploaded: true })
                updateFormData({
                  user: { licenses: { [number - 1]: { imageId: { $set: createDocument._id } } } },
                })
                updateFormData({ document: { $set: createDocument } })
              } catch (e) {
                const err = e as Error

                console.warn('Error uploading document!')
                console.warn(err)

                setUploadingFile(undefined)
                setError(err?.message ?? 'Unable to upload file')
              }
            }}
            onUploadCancelled={() => {
              setUploadingFile(undefined)
              updateFormData({
                user: { licenses: { [number - 1]: { imageId: { $set: '' } } } },
              })
            }}
          />
        </FormItem>
      </FormItems>
      {!!error && <StyledBannerAlert state="error">Error: {error}</StyledBannerAlert>}
    </>
  )
}

const UserForm = (props: UserFormProps): JSX.Element => {
  const { className, isNewUser, modal = false } = props
  const {
    state: { formData },
    actions: { updateFormData },
  } = useFormData()

  const autoFocusRef = useAutoFocus()

  const addLicense = useCallback(() => {
    const licenses = formData.user.licenses ?? []

    updateFormData({
      user: { licenses: { $set: [...licenses, licenseFactory()] } },
    })
  }, [formData.user.licenses, updateFormData])

  useEffect(() => {
    if (isNewUser) addLicense()
  }, [])

  const userRoles = formData.user.roles?.filter(notEmpty) ?? []
  return (
    <>
      <Grid>
        <GridItem desktop={6} mobile={4}>
          <StyledFormItems className={className}>
            <FormItem>
              <StyledFormLabel>First name</StyledFormLabel>
              <WideTextInput
                ref={autoFocusRef as RefObject<HTMLInputElement>}
                value={formData.user.firstName}
                onChange={e => {
                  updateFormData({
                    user: { firstName: { $set: e.target.value } },
                  })
                }}
              />
            </FormItem>
            <FormItem>
              <StyledFormLabel>Last name</StyledFormLabel>
              <WideTextInput
                value={formData.user.lastName}
                onChange={e => {
                  updateFormData({
                    user: { lastName: { $set: e.target.value } },
                  })
                }}
              />
            </FormItem>
            <FormItem>
              <StyledFormLabel>Preferred name (optional)</StyledFormLabel>
              <WideTextInput
                value={formData.user.preferredName}
                onChange={e => {
                  updateFormData({
                    user: { preferredName: { $set: e.target.value } },
                  })
                }}
              />
            </FormItem>
            {isNewUser ? (
              <FormItem>
                <StyledFormLabel>Email</StyledFormLabel>
                <WideTextInput
                  value={formData.user.email}
                  onChange={e => {
                    updateFormData({
                      user: { email: { $set: e.target.value } },
                    })
                  }}
                />
              </FormItem>
            ) : null}
            <FormItem>
              <StyledFormLabel>Location</StyledFormLabel>
              <LocationSelectorDropDown
                value={formData.user.locationId}
                onChange={locationId => {
                  updateFormData({
                    user: { locationId: { $set: locationId } },
                  })
                }}
              />
            </FormItem>
            <FormItem>
              <StyledFormLabel>Roles</StyledFormLabel>
              <Select
                modal={modal}
                multiple
                placeholder={'Select roles...'}
                value={userRoles}
                options={ROLE_OPTIONS}
                onChange={(options: Option<UserRoles>[]) => {
                  updateFormData({
                    user: {
                      roles: {
                        $set: options.map(({ value }) => value),
                      },
                    },
                  })
                }}
              />
            </FormItem>
          </StyledFormItems>
        </GridItem>
        <GridItem desktop={6} mobile={4}>
          {formData.user.licenses?.filter(notEmpty).map((license, key) => (
            <LicenseForm
              key={license.id}
              license={license as unknown as LicenseWithImage}
              className={className}
              number={key + 1}
            />
          ))}
          <StyledButtonWrapper>
            <Button onClick={addLicense} size="sm" variant="primary-outline">
              Add License
            </Button>
          </StyledButtonWrapper>
        </GridItem>
      </Grid>
    </>
  )
}

export default UserForm
