import { useCallback, useEffect } from 'react'
import { useMutation, useQuery } from '@truepill/tpos-react-router'
import { CLEAR_ORDER_LOCK, GET_ORDER_LOCK, SET_ORDER_LOCK } from 'gql'
import { usePlusClient } from 'providers/VisionRouter'
import { isNil } from 'ramda'
import type { Lock, Order, User, UserFirstLastName } from 'types'
import { updateOrder } from 'utils/subscribeToolkit'

type LockWithPartialUser = Omit<Lock, 'user'> & { user: Pick<User, '_id' | 'firstName' | 'lastName'> }

/**
 * @param orderId If undefined, then the callbacks do nothing. This is for use
 *                in components with possibly undefined order IDs.
 */
export default function useOrderLock(
  orderId?: string,
  shouldSkip = false,
): {
  orderEditable: boolean
  orderLock?: LockWithPartialUser
  orderLockedBy?: UserFirstLastName
  isOrderLockedByMe: boolean
  setOrderLock: () => Promise<void>
  clearOrderLock: () => Promise<LockWithPartialUser | undefined>
} {
  const { tokenContext } = usePlusClient()

  const { loading, error, data, subscribeToMore } = useQuery<{ order: { lock?: LockWithPartialUser } }>(
    GET_ORDER_LOCK,
    {
      skip: isNil(orderId) || shouldSkip,
      variables: { orderId },
      fetchPolicy: 'no-cache',
    },
  )

  const [setOrderLockMutation, { error: orderLockError }] = useMutation<{
    order?: Pick<Order, '_id'> & {
      lock: LockWithPartialUser
    }
  }>(SET_ORDER_LOCK)

  const [clearOrderLockMutation, { data: orderClearData, error: orderClearError }] = useMutation<{
    order?: Pick<Order, '_id'> & {
      lock: LockWithPartialUser
    }
  }>(CLEAR_ORDER_LOCK)

  /**
   * @returns The updated lock information. This may not be the expected lock
   *          (check the lock's user ID to be sure). If nil, there is no lock
   *          despite the attempt.
   * @throws If there was an error in the mutation (such as an invalid order).
   */
  const setOrderLock = useCallback(async () => {
    if (!orderId) {
      return
    }
    await setOrderLockMutation({ variables: { orderId } })

    if (orderLockError) {
      console.error(orderLockError)
      throw new Error(orderLockError.toString())
    }
  }, [orderId, setOrderLockMutation, orderLockError])

  /**
   * @returns The updated lock information. If nil, the order has successfully
   *          been unlocked; if still defined, then the attempt to unlock was
   *          unsuccessful.
   * @throws If there was an error in the mutation (such as an invalid order).
   */
  const clearOrderLock = async () => {
    if (!orderId) {
      return
    }

    await clearOrderLockMutation({ variables: { orderId } })

    if (orderClearError) {
      console.error(orderClearError)
      throw new Error(orderClearError.toString())
    }

    return orderClearData?.order?.lock
  }

  useEffect(() => {
    if (orderId) {
      return updateOrder(subscribeToMore, { orderId })
    }
  }, [subscribeToMore, orderId])

  const isOrderLockedByMe = data?.order?.lock?.user._id === tokenContext?.id
  let orderEditable = false

  if (!orderId || (!!tokenContext && (!data?.order?.lock || isOrderLockedByMe))) {
    orderEditable = true
  }

  return {
    orderEditable,
    isOrderLockedByMe,
    orderLockedBy: data?.order?.lock?.user,
    orderLock:
      loading || error || !data || (data.order?.lock?.expiresAt ?? Infinity) < Date.now()
        ? undefined
        : data.order?.lock,
    setOrderLock,
    clearOrderLock,
  }
}
