// 439-E4
export enum ReasonForService {
  AdditionalDrugNeeded = 'AD',
  PrescriptionAuthenticaton = 'AN',
  AdverseDrugReaction = 'AR',
  AdditiveToxicity = 'AT',
  ChronicDiseaseManagement = 'CD',
  CallHelpDesk = 'CD',
  PatientComplaint = 'CS',
  DrugAllergy = 'DA',
  DrugDiseaseInferred = 'DC',
  DrugDrug = 'DD',
  DrugFood = 'DF',
  DrugIncompatibility = 'DI',
  DrugLab = 'DL',
  ApparentDrugMisuse = 'DM',
  DoseRangeConflict = 'DR',
  TobaccoUse = 'DS',
  PatientEducation = 'ED',
  Overuse = 'ER',
  ExcessiveQuantity = 'EX',
  HighDose = 'HD',
  IatrogenicCondition = 'IC',
  IngredientDuplication = 'ID',
  LowDose = 'LD',
  LockInRecipient = 'LK',
  Underuse = 'LR',
  DrugDiseaseReported = 'MC',
  InsufficientDuration = 'MN',
  MissingInformationClarification = 'MS',
  ExcessiveDuration = 'MX',
  DrugNotAvailable = 'NA',
  NonCoveredDrugPurchase = 'NC',
  NewDiseaseDiagnosis = 'ND',
  NonFormularyDrug = 'NF',
  UnnecessaryDrug = 'NN',
  NewPatientProcessing = 'NP',
  LactationNursingInteration = 'NR',
  InsufficientQuantity = 'NS',
  AlcoholConflict = 'OH',
  DrugAge = 'PA',
  PatientQuestionConcern = 'PC',
  DrugPregnancy = 'PG',
  PreventiveHealthCare = 'PH',
  PrescriberConsultation = 'PN',
  PlanProtocol = 'PP',
  PriorAdverseReaction = 'PR',
  ProductSelectionOpportunity = 'PS',
  SuspectedEnvironmentalRisk = 'RE',
  HealthProviderReferral = 'RF',
  SuboptimalCompliance = 'SC',
  SuboptimalDrug = 'SD',
  SideEffect = 'SE',
  SuboptimalDosageForm = 'SF',
  SuboptimalRegimen = 'SR',
  DrugGender = 'SX',
  Therapeutic = 'TD',
  LaboratoryTestNeeded = 'TN',
  PayerProcessorQuestion = 'TP',
  DuplicateDrug = 'UD',
}

// 441-E6
export enum ResultOfService {
  NotSpecified = '00',
  FilledAsIsFalsePositive = '1A',
  FilledAsIs = '1B',
  FilledDifferentDose = '1C',
  FilledDifferentDirections = '1D',
  FilledDifferentDrug = '1E',
  FilledDifferentQuantity = '1F',
  FilledWithPrescriberApproval = '1G',
  BrandToGenericChange = '1H',
  RxToOTCChange = '1J',
  FilledDifferentDosageForm = '1K',
  PrescriptionNotFilled = '2A',
  NotFilledDirectionsClarified = '2B',
  RecommendationAccepted = '3A',
  RecommendationNotAccepted = '3B',
  DiscontinuedDrug = '3C',
  RegimenChanged = '3D',
  TherapyChanged = '3E',
  DrugTherapyUnchanged = '3G',
  FollowUpReport = '3H',
  PatientReferral = '3J',
  InstructionsUnderstood = '3K',
  ComplianceAidProvided = '3M',
  MedicationAdministered = '3N',
  PrescribedWithAcknowledgments = '4A',
}

// 474-8E
export enum LevelOfEffort {
  NotSpecified = 0,
  Level1 = 11, // Straight forward Requires 1 to 4 minutes of effort
  Level2 = 12, // 5 to 14 minutes
  Level3 = 13, // 15 to 29 minutes
  Level4 = 12, // 30 to 59 minutes
  Level5 = 15, // GREATER THAN 60 MINUTES
  Level6 = 14, // GREATER THAN 60 MINUTES
  // THE REST OF THE LEVELS ARE FOR COMPOUNDS
}

// 475-J9

export enum CoAgentQualifier {
  NotSpecified = '  ',
  UPC = '01', // Universal Product Code
  HRI = '02', // Health Related Item
  NDC = '03', // National Drug Code
  HIBCC = '04', // Health Industry Business Communications Council
  CPT4 = '07', // Current Procedural Terminology
  CPT5 = '08', // Current Procedural Terminology
  HCPCS = '09', // Healthcare Common Procedure Coding System
  NAPPI = '11', // National Pharmaceutical Product Interface Code
  GTIN = '12', // Global Trade Identification Number
  GPI = '14', // Medi Span's Generic Product Identifier
  GCN = '15', // First Databack Formulation ID
  GFC = '16', // Truven Micromedex Generic Formulation Code
  DDID = '17', // Medi Span Drug Descriptor ID
  FDBSmartKey = '18', // First DataBank Smart Key
  TruvenMicromedexGenericMaster = '19',
  ICD9 = '20',
  ICD10CM = '21',
  NCCI = '23', // National Criteria Care Institute
  SNOMED = '24', // Systematized Nomenclature of Medicine Clinical Terms
  CDT = '25', // Current Dental Terminology
  DSMIV = '26', // American Psychiatric Association Diagnostic Statistical Manual of Mental Disorders
  ICD10PCS = '27', //
  FDBMedName = '28', // First DataBank Medication Name ID
  FDBRoutedMed = '29',
  FDBRoutedDosage = '30',
  FDBMedId = '31',
  FDBFormId = '32',
  FDBIngredientList = '33',
  LOINC = '35', // Logical Observation Identifier Names and Codes
  AHFS = '37', // American Hospital Formulary Service
  SCD = '38', // RxNorm Semantic Clinical Drug
  SBD = '39', // RxNorm Semantic Branded Drug
  GPCK = '40', // RxNorm Generic Package
  BPCK = '41', // RxNorm Branded Package
  DSM5 = '46', // American Psychiatric Association Diagnostic Statistical Manual of Mental Disorders
  Other = '99',
}
