import { FormPage } from 'components/ColumnForm'
import { ActionButtonContainer } from 'components/PageStructure'
import styled from 'styled-components'

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 1285px;
  margin: 2rem 2rem 0.8rem;
`

const Title = styled.h1`
  font-size: 24px;
  width: 100%;
`

const ExpandedActionButtonContainer = styled(ActionButtonContainer)`
  margin-left: auto;
`

const StyledFormPage = styled(FormPage)`
  grid-template-columns: [details] 0.1fr [contact] 0.1fr;
  grid-gap: 1.25rem;
`

const PayerContactColumn = styled.ul`
  width: 26rem;
  grid-column: contact;
`

const PayerDetailsColumn = styled.ul`
  width: 26rem;
  grid-column: details;
`

const StyledFormPageConfigurations = styled(FormPage)`
  grid-template-columns: [options] 0.6fr [others] 0.3fr;
  grid-gap: 1.25rem;
`

const PayerOptionsColumn = styled.div`
  width: 100%;
  grid-column: options;
`

const PayerOthersColumn = styled.div`
  width: 100%;
  grid-column: others;
`

const LoadingSpinnerContainer = styled.div`
  display: flex;
  padding-top: 12rem;
  justify-content: center;
  svg {
    height: 126px;
  }
`

export {
  Title,
  TitleRow,
  ExpandedActionButtonContainer,
  StyledFormPage,
  PayerContactColumn,
  PayerDetailsColumn,
  StyledFormPageConfigurations,
  PayerOptionsColumn,
  PayerOthersColumn,
  LoadingSpinnerContainer,
}
