import { OrderStatus, CopayStatus } from '@truepill/tpos-types'
import CloseButton from 'components/CloseButton'
import styled, { css, keyframes } from 'styled-components'
import {
  bodyPrimaryColor,
  primaryBackgroundColor,
  highlightPrimaryColor,
  subduedColor,
  accentPink,
  accentPurple,
  alertRed,
  errorRed,
  confirmColor,
  primaryColor,
  contrastColor,
  warningYellow,
  darkBlue,
  darkGreen,
} from 'styles/styleVariables'
import type { ChildProps } from 'types'
import { PrinterStatus } from 'types'

const getContrastingColor = (color: string): string => {
  if (
    [
      alertRed,
      confirmColor,
      primaryColor,
      contrastColor,
      accentPink,
      accentPurple,
      errorRed,
      darkBlue,
      darkGreen,
    ].includes(color)
  ) {
    return primaryBackgroundColor
  }

  if ([warningYellow].includes(color)) {
    return contrastColor
  }

  return bodyPrimaryColor
}

const Lozenge = styled.span<{ backgroundColor?: string; color?: string }>`
  display: inline-flex;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  line-height: normal;
  border-radius: 0.125rem;
  padding-top: 0.2rem;
  padding-bottom: 0.125rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  ${({ backgroundColor }) => backgroundColor && `background-color: ${backgroundColor};`}
  ${({ backgroundColor, color }) =>
    color ? `color: ${color};` : backgroundColor && `color: ${getContrastingColor(backgroundColor)};`}
  text-transform: uppercase;

  /* This prevents needing special styling in tables. */
  td & {
    margin: 0;
  }
`

export const getStatusColor = (status: string): '#afbcc6' | '#11CAB4' | '#44bdd0' | '#FFB803' => {
  if (
    status === OrderStatus.PV1 ||
    status === OrderStatus.PV2 ||
    status === OrderStatus.Fill ||
    status === OrderStatus.Packing
  ) {
    return primaryColor
  }
  if (status === OrderStatus.PartialShipped || status === OrderStatus.Returned || status === OrderStatus.Cancelled) {
    return warningYellow
  }
  if (status === OrderStatus.Shipped || status === OrderStatus.Complete) {
    return confirmColor
  }
  return subduedColor
}

export const getCopayStatusColor = (status: string): '#afbcc6' | '#11CAB4' | '#44bdd0' | '#8FD7E3' | '#DE3702' => {
  if (status === CopayStatus.Pending || status === CopayStatus.Confirmed) {
    return primaryColor
  }
  if (status === CopayStatus.Rejected) {
    return errorRed
  }
  if (status === CopayStatus.Triage) {
    return highlightPrimaryColor
  }
  if (status === CopayStatus.Complete) {
    return confirmColor
  }
  return subduedColor
}

export const getPrinterStatusColour = (status: string): '#FFB803' | '#8FD7E3' | '#da3d3d' => {
  switch (status) {
    case PrinterStatus.idle:
    case PrinterStatus.processing:
      return highlightPrimaryColor
    case PrinterStatus.stopped:
      return warningYellow
    case PrinterStatus.unknown:
    default:
      return alertRed
  }
}
type DismissableLozengeProps = {
  className?: string
  closeCallback: () => void
  'data-testid'?: string
} & ChildProps

export const DismissableLozenge = (props: DismissableLozengeProps): JSX.Element => {
  return (
    <Lozenge data-testid={props['data-testid']} className={props.className}>
      {props.children}
      <StyledCloseButton clickCallback={props.closeCallback} />
    </Lozenge>
  )
}

const PopInKeyframes = keyframes`
  0% {
    transform: scale(0)
  }
  95% {
    transform: scale(1.05)
  }
  100% {
    transform: scale(1)
  }

  `

const PopInAnimation = css`
  animation: ${PopInKeyframes} 200ms;
`

export const SearchResultLozenge = styled(DismissableLozenge)`
  border-radius: 0.15rem;
  border: 0.125rem solid;
  position: relative;
  color: ${bodyPrimaryColor};
  background-color: ${subduedColor};
  border-color: ${subduedColor};
  width: max-content;
  margin-right: 0.25rem;
  margin-top: 0.25rem;
  padding: 0.15rem;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
  text-transform: uppercase;
  ${PopInAnimation}
`

export const MedicalHistoryLozenge = styled(Lozenge)`
  border-radius: 0.15rem;
  border: 0.125rem solid;
  position: relative;
  color: ${bodyPrimaryColor};
  background-color: ${highlightPrimaryColor};
  border-color: ${highlightPrimaryColor};
  width: max-content;
  margin-right: 0.25rem;
  margin-top: 0.25rem;
  padding: 0.15rem;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
  text-transform: uppercase;
  ${PopInAnimation}
`

export const StyledCloseButton = styled(CloseButton)`
  position: relative;
  right: 0rem;
  padding: 0rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0.5rem;
  height: 1rem;
  width: 1rem;
  background-color: transparent;

  > span {
    margin-left: -0.352rem;
    margin-top: 0rem;
    > svg {
      margin-left: 0rem;
      height: 9px;
      width: 9px;
      fill: ${bodyPrimaryColor};
    }
  }
`

export const SearchResultLozengeList = styled.ul`
  margin-top: 0.125rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export default Lozenge
