import ItemIdDisplay from 'components/ItemIdDisplay'
import { VerticalStackItemStyle } from 'components/RXTable'
import styled from 'styled-components'
import EllipsisTruncate from 'styles/EllipsisTruncate'
import { bodySecondaryColor } from 'styles/styleVariables'
import type { Order } from 'types'
import { formatCreatedDate } from 'utils'

const OrderError = ({ order }: { order: Order }): JSX.Element => {
  const { _id: orderId, createdAt } = order

  const message = `An error has occurred processing the fills for this order`
  const { date, greaterThan48 } = formatCreatedDate(createdAt)

  return (
    <OrderContainer>
      <p>
        <OrderId>
          Order: <ItemIdDisplay id={order._id} customerRef={order.customerRef} /> {`(${orderId})`}
        </OrderId>
        <DateStamp>
          {date} {greaterThan48}
        </DateStamp>
        <Message>{message}</Message>
      </p>
    </OrderContainer>
  )
}

const DateStamp = styled.span`
  margin-left: 10px;
  color: ${bodySecondaryColor};
  ${EllipsisTruncate}
`

const Message = styled.span`
  margin-left: 10px;
`

const OrderId = styled.span`
  font-weight: bold;
`

const OrderContainer = styled.li`
  ${VerticalStackItemStyle}
  border-width: 2px;
  border-color: red;
  border-style: solid;
`

export default OrderError
