import { useCallback } from 'react'
import { useLazyQuery } from '@truepill/tpos-react-router'
import { GET_PREFERRED_SUBSTITUTION } from 'gql'
import SimpleModal from 'modals/SimpleModal'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import type { Prescription } from 'types'
import usePrescription from './navigation/usePrescription'

const useShowConfirmationPrompt = (onConfirm?: () => void, onCancel?: () => void) => {
  const { showModal } = useModalContext()

  const showConfirmationPrompt = useCallback(() => {
    showModal(() => (
      <SimpleModal
        title="Are you sure?"
        confirmationCallback={onConfirm}
        cancelCallback={onCancel}
        width="28rem"
        contentMargin="1rem 0"
        confirmButtonLabel="Update"
        cancelButtonLabel="Cancel"
      >
        The prescribed NDC that you selected is not on the customer's formulary and the fill will move to triage
      </SimpleModal>
    ))
  }, [onCancel, onConfirm, showModal])

  return showConfirmationPrompt
}

const useUpdatePrescribed = ({
  prescription,
  onCompleted,
  onError,
  onCancelPrompt,
}: {
  prescription?: Prescription
  onCompleted?: (...args: any[]) => void
  onError?: () => void
  onCancelPrompt?: () => void
}) => {
  const { savePrescriptionChanges, getChanges, savingChanges } = usePrescription({
    prescription,
    onPrescriptionSavedCompleted: onCompleted,
    onPrescriptionSavedError: onError,
  })
  const showConfirmationPrompt = useShowConfirmationPrompt(savePrescriptionChanges, onCancelPrompt)
  const [getPreferredSub, { loading: getPreferredSubIsLoading }] = useLazyQuery(GET_PREFERRED_SUBSTITUTION, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onError() {
      onError?.()
    },
    onCompleted(response) {
      if (response?.getPreferredSubstitution?.onFormulary === false) {
        showConfirmationPrompt()
        return
      }
      savePrescriptionChanges()
    },
  })

  const updatePrescribed = useCallback(async () => {
    if (getPreferredSubIsLoading) return

    const { daw, ndc } = getChanges()
    const willTriggerSubstitutionLookUp = (ndc && prescription?.daw === 0) || daw === 0

    if (willTriggerSubstitutionLookUp) {
      getPreferredSub({
        variables: {
          ndc: ndc ?? prescription?.ndc,
          locationId: prescription?.locationId,
          prescriptionId: prescription?._id,
        },
      })
      return
    }

    savePrescriptionChanges()
  }, [
    getChanges,
    getPreferredSub,
    prescription?._id,
    prescription?.daw,
    prescription?.locationId,
    prescription?.ndc,
    savePrescriptionChanges,
    getPreferredSubIsLoading,
  ])

  return {
    updatePrescribed,
    loading: getPreferredSubIsLoading || savingChanges,
  }
}

export default useUpdatePrescribed
