import { LaunchDarkly } from '@truepill/tpos-types'
import { useFlag } from 'providers/LaunchDarklyProvider'
import type { Fill, Order, Prescription } from 'types'
import DisplayFillPage from './DisplayFillPageV1'
import DisplayFillPageV2 from './DisplayFillPageV2'

type MainFillSectionProps = {
  prescription: Prescription
  fill: Fill
  order: Order
  alternateBarCodes: string[]
}

const MainFillSection = ({ prescription, fill, order, alternateBarCodes }: MainFillSectionProps): JSX.Element => {
  // This LD Flag toggles between hiding the Rx Image column from Fill Page or shows the regular view (three columns)
  const areRxAndHardCopyRemovedFromFillPage = useFlag(
    LaunchDarkly.FeatureFlags.REMOVE_PRESCRIPTION_AND_HARDCOPY_FROM_FILL_PAGE,
  )
  return areRxAndHardCopyRemovedFromFillPage ? (
    <DisplayFillPageV2 prescription={prescription} fill={fill} order={order} alternateBarCodes={alternateBarCodes} />
  ) : (
    <DisplayFillPage prescription={prescription} fill={fill} order={order} alternateBarCodes={alternateBarCodes} />
  )
}

export default MainFillSection
