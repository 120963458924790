import React, { useCallback, useEffect, useState } from 'react'
import { Chip } from '@truepill/react-capsule'
import TextAreaWithCharacterCount from 'components/TextAreaWithCharacterCount'
import { useSearchSigCode } from 'hooks/useSearchSigCode'

export interface OnChangeParams {
  directions?: string
  sigCode?: string
}
interface DirectionsInputProps {
  onChange: ({ directions, sigCode }: OnChangeParams) => void
  disabled?: boolean
  directionsValue: string
  patientId?: string
  ref?: React.MutableRefObject<unknown>
  helperText?: string
  sigCodeValue?: string
  error?: boolean
  errorText?: string
  maxLength?: number
  stripWhiteSpace?: boolean
  placeholder?: string
  state: 'default' | 'error'
}

const DirectionsInput = ({
  patientId,
  directionsValue,
  disabled = false,
  placeholder,
  sigCodeValue,
  state,
  error,
  helperText,
  onChange,
  maxLength,
  ref,
  errorText,
  stripWhiteSpace,
}: DirectionsInputProps): JSX.Element => {
  const [displayedSigCode, setDisplayedSigCode] = useState<string | undefined>(undefined)
  const [forceDisable, setForceDisable] = useState(false)

  const {
    searchSigCode,
    error: searchError,
    loading,
  } = useSearchSigCode({
    onCompleted: data => {
      if (data?.searchSigCode?.text) {
        const result = data.searchSigCode
        // Set the text value when data from the sig code search arrives
        onChange({ directions: result.text, sigCode: result.code })
        setDisplayedSigCode(
          `${result.code}${!result.accurateTranslation ? '\u00A0(Spanish translation not found)' : ''}`,
        )
      }
    },
    onError: error => {
      // Set the text value empty when an error ocurrs - ie: sig code not found
      if (error) {
        onChange({ directions: '', sigCode: undefined })
        setDisplayedSigCode(undefined)
      }
    },
  })

  // Set initialSigcode as the default visible sigCode, and disable the input if it is present
  useEffect(() => {
    if (sigCodeValue && !displayedSigCode) {
      setDisplayedSigCode(sigCodeValue)
      setForceDisable(true)
    }
  }, [sigCodeValue, displayedSigCode])

  // On dismiss clear and re enable the textbox
  const handleDismiss = useCallback(() => {
    onChange({ directions: '', sigCode: '' })
    setForceDisable(false)
    setDisplayedSigCode(undefined)
  }, [onChange])

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const inputValue = e.target.value
      // if the string written starts with a $ char and ends in an space char then call the backend search service
      if (patientId && /^[$][^\s]+[\s]$/.test(inputValue)) {
        searchSigCode(inputValue.slice(1, -1), patientId) // strip $ and space chars
        // Then disabled the input
        setForceDisable(true)
        // Otherwise normal behavior
      } else {
        onChange({ directions: inputValue, sigCode: undefined })
      }
    },
    [onChange, patientId, searchSigCode],
  )

  const showSearchError = searchError && forceDisable

  return (
    <>
      <TextAreaWithCharacterCount
        ref={ref}
        placeholder={placeholder}
        disabled={disabled || forceDisable}
        onChange={handleChange}
        state={loading ? 'default' : state}
        value={directionsValue}
        helperText={loading ? 'Searching for the entered sigcode...' : helperText}
        error={error}
        errorText={showSearchError ? '' : errorText}
        maxLength={maxLength}
        stripWhiteSpace={stripWhiteSpace}
      />
      {displayedSigCode && (
        <Chip css={{ width: 'fit-content' }} onDismiss={handleDismiss}>
          {displayedSigCode}
        </Chip>
      )}
      {showSearchError && (
        <Chip css={{ width: 'fit-content' }} onDismiss={handleDismiss} state="error">
          {searchError?.message}
        </Chip>
      )}
    </>
  )
}

export const MemoizedDirectionsInput = React.memo(DirectionsInput)

export default DirectionsInput
