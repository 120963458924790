// 338-5C Other Payer Coverage Type
export enum CoverageType {
  NotSpecified = '  ',
  First = '01',
  Second = '02',
  Third = '03',
  Fourth = '04',
  Fifth = '05',
  Sixth = '06',
  Seventh = '07',
  Eighth = '08',
  Ninth = '09',
}

// 339-6C Other Payer Qualifier ID
export enum QualifierID {
  NotSpecified = '  ',
  HealthPlanIdentifier = '01',
  HealthIndustryNumber = '02',
  BankInformationNumber = '03',
  NationalAssociationOfInsuranceCommisioners = '04',
  MedicareCarrier = '05',
}

// 342-HC { // Other Payer Amount Paid Qualifier
export enum OtherAmount {
  Delivery = '01',
  Shipping = '02',
  Postage = '03',
  Administrative = '04',
  Incentive = '05',
  CognitiveService = '06',
  DrugBenefit = '07',
  CompoundPreparation = '09',
  SalesTax = '10',
  MedicationAdministration = '11',
}

// 351-NP Other Payer-Patient Responsibility Amount
export enum PatientResponsibility {
  NotSpecified = '  ',
  PeriodicDeductible = '01', // 517-FH
  BrandProductSelection = '02', // 134-UK
  SalesTax = '03', // 523-FN
  PeriodicBenefitExceedsMax = '04', // 520-FK
  Copay = '05', // 518-FI
  PatientPay = '06', // 505-F5
  Coinsurance = '07', // 572-4U
  NonPreferredFormularyProductSelection = '08', // 135-UM
  HealthPlanAssistance = '09', // 129-UD
  ProviderNetworkSelection = '10', // 133-UJ
  BrandNonPrefferedForumlaryProductSelection = '11', // 136-UN
  CoverageGap = '12', // 137-UP
  ProcessorFee = '13', // 571-NZ
}

// 393-MV Other Payer Benefit Stage Amount Qualifier
export enum BenefitStage {
  Deductible = '01',
  InitialBenefit = '02',
  CoverageGap = '03',
  CatastrophicCoverage = '04',
}
