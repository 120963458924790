import type { ReactNode } from 'react'
import styled from 'styled-components'
import { bodySecondaryColor } from 'styles/styleVariables'

const Container = styled.div`
  display: flex;
`

const WrapSection = styled.div`
  display: flex;
  flex-basis: 100%;
  flex-wrap: wrap;
`

const TitleSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 1rem 0;
  padding: 0 1.875rem;
  h1 {
    width: min-content;
    white-space: nowrap;
  }
  p {
    font-size: 0.95rem;
    font-weight: 500;
    width: 100%;
    span {
      font-weight: normal;
    }
    color: ${bodySecondaryColor};
  }
`

const Title = styled.h1`
  font-size: 24px;
`

const LoadingSpinnerContainer = styled.div`
  display: flex;
  padding-top: 12rem;
  justify-content: center;
  svg {
    height: 126px;
  }
`

interface TabProps {
  children: ReactNode
  icon: ReactNode
  title: string
}

const Tab = ({ children, title }: TabProps): JSX.Element => {
  return <PagePadding>{children}</PagePadding>
}

const PagePadding = styled.div`
  padding-left: 1.745rem;
`

export { Container, WrapSection, TitleSection, Title, LoadingSpinnerContainer, Tab }
