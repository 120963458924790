import styled from 'styled-components'

const NoteSkeleton = () => {
  return (
    <NoteSkeletonWrapper>
      <div className="container">
        <div className="circle skeleton-load"></div>
        <div className="rectangle skeleton-load"></div>
        <div className="rectangle skeleton-load"></div>
        <div className="rectangle skeleton-load"></div>
      </div>
      <div className="container">
        <div className="rectangle skeleton-load" style={{ flexGrow: '0.3' }}></div>
      </div>
      <div className="container">
        <div className="rectangle skeleton-load" style={{ height: '2.5rem' }}></div>
      </div>
    </NoteSkeletonWrapper>
  )
}

const NotesListSkeleton = () => {
  return (
    <SkeletonWrapper>
      <Divider></Divider>
      <SkeletonNotesWrapper>
        <NoteSkeleton />
        <NoteSkeleton />
        <NoteSkeleton />
        <NoteSkeleton />
        <NoteSkeleton />
      </SkeletonNotesWrapper>
    </SkeletonWrapper>
  )
}

const Divider = styled.div`
  border-bottom: 1px solid #cccccc;
  height: 40px;
  margin-bottom: 0.625rem;
`

const SkeletonNotesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const SkeletonWrapper = styled.div`
  margin-top: 1.5rem;

  @keyframes skeleton-anim {
    0% {
      background-color: #efefef;
    }
    100% {
      background-color: #dedede;
    }
  }

  .skeleton-load {
    animation: skeleton-anim 1.2s linear infinite alternate;
  }

  .container {
    display: flex;
    gap: 0.5rem;
    margin: 0.5rem 0;
  }

  .rectangle {
    height: 1.25rem;
    flex-grow: 1;
  }

  .circle {
    height: 1.25rem;
    width: 1.25rem;
    border-radius: 50%;
  }
`
const NoteSkeletonWrapper = styled.div`
  padding: 0.5rem 1rem;
  margin-top: 1.25rem;
`

export default NotesListSkeleton
