import { FieldCodes } from '@truepill/tpos-types'

const SUCCESSFULL_REVERSAL_STATUS: Set<string> = new Set([
  FieldCodes.TransactionStatus.Approved,
  FieldCodes.TransactionStatus.DuplicatedOfApproved,
])

export const isSuccessfulReversal = (status: string | undefined) => {
  if (!status) {
    return false
  }

  return SUCCESSFULL_REVERSAL_STATUS.has(status)
}
