import { useState, useContext, createContext, useCallback } from 'react'
import type { ChildProps } from 'types'

type Selectable = Record<'_id', string>

export interface SelectionProviderContextProps {
  selections: Selectable[]
  selectionId?: string
  totalSelectableItems?: number
  currentSelections?: Selectable[]
  select: (item: Selectable) => void
  deSelect: (item: Selectable) => void
  bulkSetSelections: (newSelections: Selectable[]) => void
  removeBulkSelections: (selections: Selectable[]) => void
  initSelections: (startSelections: Selectable[], newSelectionId?: string) => void
  isSelection: (item: Selectable) => boolean
  setTotalSelectableItems: (amount: number) => void
  setCurrentSelections: (selections: Selectable[]) => void
}

const SelectionContext = createContext<SelectionProviderContextProps>({
  selections: [] as Selectable[],
  totalSelectableItems: -1,
  currentSelections: [] as Selectable[],
  select: (item: Selectable) => {
    return
  },
  deSelect: (item: Selectable) => {
    return
  },
  bulkSetSelections: (newSelections: Selectable[]) => {
    return
  },
  removeBulkSelections: (selections: Selectable[]) => {
    return
  },
  initSelections: (startSelections: Selectable[], newSelectionId?: string) => {
    return
  },
  isSelection: (item: Selectable) => true,
  setTotalSelectableItems: (amount: number) => {
    return
  },
  setCurrentSelections: (selections: Selectable[]) => {
    return
  },
})

type SelectionProviderProps = { initialSelections?: Selectable[] } & ChildProps

const select = (state: Selectable[], item: Selectable) => {
  if (state.some(s => s._id === item._id)) return [...state]
  return [...state, item]
}

const deSelect = (state: Selectable[], item: Selectable) =>
  state.reduce((acc, i) => {
    if (i._id === item._id) return acc

    return [...acc, i]
  }, [] as Selectable[])

const bulkSetSelections = (state: Selectable[], selections: Selectable[]) => {
  const toAdd = []
  for (const selection of selections) {
    if (state.some(s => s._id === selection._id)) continue

    toAdd.push(selection)
  }

  return [...state, ...toAdd]
}

const removeBulkSelections = (state: Selectable[], selections: Selectable[]) => {
  const removeIds = selections.map(s => s._id)

  return state.reduce((acc, sel) => {
    if (removeIds.includes(sel._id)) return acc

    return [...acc, sel]
  }, [] as Selectable[])
}

const SelectionProvider = ({ initialSelections, children }: SelectionProviderProps): JSX.Element => {
  const [selectionState, setSelectionState] = useState<Selectable[]>(initialSelections || [])
  const [selectionId, setSelectionId] = useState<string | undefined>()
  const [totalSelectableItemsState, setTotalSelectableItemsState] = useState<number>(-1)
  const [currentSelectionsState, setCurrentSelectionsState] = useState<Selectable[]>([])

  const initSelections = useCallback(
    (startSelections: Selectable[], newSelectionId?: string) => {
      setSelectionId(newSelectionId)
      setSelectionState(startSelections)
      setCurrentSelectionsState(startSelections)
    },
    [setSelectionId, setSelectionState, setCurrentSelectionsState],
  )

  const value = {
    selectionId,
    selections: selectionState,
    totalSelectableItems: totalSelectableItemsState,
    currentSelections: currentSelectionsState,
    select: (item: Selectable) => {
      setSelectionState(state => select(state, item))
      setCurrentSelectionsState(state => select(state, item))
    },
    deSelect: (item: Selectable) => {
      setSelectionState(state => deSelect(state, item))
      setCurrentSelectionsState(state => deSelect(state, item))
    },
    bulkSetSelections: (selections: Selectable[]) => {
      setSelectionState(state => bulkSetSelections(state, selections))
      setCurrentSelectionsState(state => bulkSetSelections(state, selections))
    },
    removeBulkSelections: (selections: Selectable[]) => {
      setSelectionState(state => removeBulkSelections(state, selections))
      setCurrentSelectionsState(state => removeBulkSelections(state, selections))
    },
    initSelections: initSelections,
    isSelection: (item: Selectable) => selectionState.some(sel => sel._id === item._id),
    setTotalSelectableItems: (amount: number) => setTotalSelectableItemsState(amount),
    setCurrentSelections: (items: Selectable[]) => setCurrentSelectionsState(items),
  }

  return <SelectionContext.Provider value={value}>{children}</SelectionContext.Provider>
}

const useSelectionContext = (): SelectionProviderContextProps => {
  const selectionContext = useContext(SelectionContext)

  if (selectionContext === undefined) {
    throw new Error('Attempting to read SelectionContext outside a Provider heirarchy')
  }

  return selectionContext
}

export type { Selectable }
export { SelectionContext, useSelectionContext }
export default SelectionProvider
