export enum DurInputMappingTypes {
  allergy = 'allergy',
  medication = 'medication',
  condition = 'condition',
}

export interface DurInputMappingInput {
  type: DurInputMappingTypes
  reportedInput: string
  translatedInput: string
  active?: boolean
}
