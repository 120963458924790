export const FILTER_NAMESPACE = 'filter'
export const PATIENT_NAMESPACE = 'patient'
export const GUEST_PATIENT_NAMESPACE = 'guestPatient'
export const PRESCRIBER_NAMESPACE = 'prescriber'
export const RX_NAMESPACE = 'rx'
export const FILL_NAMESPACE = 'fill'
export const INITIAL_FILL_NAMESPACE = 'initialFill'
export const SUBSTITUTION_NAMESPACE = 'substitution'
export const NEW_PRESCRIPTION_NAMESPACE = 'newPrescription'
export const SELECTED_PRESCRIPTION_NAMESPACE = 'selectedPrescriptions'
export const CUSTOMER_NAMESPACE = 'customer'
export const STEP_FORM_STATE_NAMESPACE = 'stepFormStateNamespace'
export const ORDER_NAMESPACE = 'order'
export const INSURANCE_NAMESPACE = 'insurance'
export const LOCATION_NAMESPACE = 'location'
export const DOCUMENT_UPLOAD_NAMESPACE = 'documentUpload'
export const SHIPPING_ADDRESS_NAMESPACE = 'shippingAddress'
export const SHIPPING_NAMESPACE = 'shippingData'
export const PAYMENT_NAMESPACE = 'payment'
