export enum PinoLevel {
  trace = 'trace',
  debug = 'debug',
  info = 'info',
  warn = 'warn',
  error = 'error',
  fatal = 'fatal',
}

export enum GCPSeverity {
  debug = 'DEBUG',
  info = 'INFO',
  warning = 'WARNING',
  error = 'ERROR',
  critical = 'CRITICAL',
}

export const PinoLevelToGCPSeverity = {
  [PinoLevel.trace]: GCPSeverity.debug,
  [PinoLevel.debug]: GCPSeverity.debug,
  [PinoLevel.info]: GCPSeverity.info,
  [PinoLevel.warn]: GCPSeverity.warning,
  [PinoLevel.error]: GCPSeverity.error,
  [PinoLevel.fatal]: GCPSeverity.critical,
}
