import { forwardRef } from 'react'
import { ReactComponent as LocationIcon } from 'assets/icons/location.svg'
import Select from 'components/Select'
import { useTPCacheContext } from 'providers/TPCacheProvider'

const LocationSelectorDropDown = forwardRef<
  HTMLButtonElement,
  {
    onChange: (locationId?: string) => void
    value?: string
    modal?: boolean
    allLocations?: boolean
  }
>(({ modal, onChange, value, allLocations = false }, ref) => {
  const { locations, getLocationNameById } = useTPCacheContext()
  const selectedLocationName = value ? getLocationNameById(value) : ''
  const label = value === 'All' ? value : selectedLocationName
  const selectValue = value && { label, value }

  if (!locations) {
    // This reduces UI element jumping around during first load.
    return <Select options={[]} placeholder="Location" />
  }

  const locationOptions = [...locations]
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(location => ({ label: location.name, value: location._id }))

  if (allLocations) {
    locationOptions.unshift({ label: 'All', value: '' })
  }

  return (
    <Select
      data-testid="LocationSelector"
      ref={ref}
      fadePlaceholderText={false}
      modal={modal}
      hotKey="l"
      options={locationOptions}
      value={selectValue}
      placeholder="Location"
      icon={<LocationIcon />}
      onChange={([option]) => {
        onChange(option?.value as string | undefined)
      }}
      showActive={!!value}
      disableClear={true}
      showLoadingAnimation={!label}
    />
  )
})
LocationSelectorDropDown.displayName = 'LocationSelectorDropDown'
export default LocationSelectorDropDown
