import type { DefaultContext, MutationFunctionOptions, MutationResult } from '@truepill/tpos-react-router'
import { useMutation } from '@truepill/tpos-react-router'
import type { FfsInventoryInfo } from '@truepill/tpos-types'
import { SET_DISPENSED } from 'gql'
import { isNil } from 'ramda'
import type { DURRecord, SurescriptsNumber } from 'types'
import useErrorToast from './toast/useErrorToast'
import useInfoToast from './toast/useInfoToast'

export type optionsType = MutationResult<{
  _id: string
  dispensed: {
    ndc: string
    quantity: SurescriptsNumber
    daysSupply: number
    directions: string
    inventoryGroupId?: string
  }
  durScreenings: DURRecord[]
}>

export type mutateWithToastType = (
  options?: MutationFunctionOptions<
    {
      _id: string
      dispensed: {
        ndc: string
        quantity: SurescriptsNumber
        daysSupply: number
        directions: string
        inventoryGroupId?: string
        ffsInventoryInfo?: FfsInventoryInfo
      }
      durScreenings: DURRecord[]
    },
    {
      itemId: string
      fillId: string
      ndc?: string
      quantity?: SurescriptsNumber
      daysSupply?: number
      directions?: string
      inventoryGroupId?: string
      ffsInventoryInfo?: FfsInventoryInfo
    },
    DefaultContext,
    any
  >,
) => any

const useUpdateDispensedMedication = (
  isInsurance = false,
  completeCallback?: (data: any) => void,
): readonly [mutateWithToastType, optionsType] => {
  const showInfoToast = useInfoToast(true)
  const showErrorToast = useErrorToast()
  const [mutate, options] = useMutation<
    {
      _id: string
      dispensed: {
        ndc: string
        quantity: SurescriptsNumber
        daysSupply: number
        directions: string
        inventoryGroupId?: string
        ffsInventoryInfo?: FfsInventoryInfo
      }
      durScreenings: DURRecord[]
    },
    {
      itemId: string
      fillId: string
      ndc?: string
      quantity?: SurescriptsNumber
      daysSupply?: number
      directions?: string
      inventoryGroupId?: string
      ffsInventoryInfo?: FfsInventoryInfo
    }
  >(SET_DISPENSED, {
    onError: error => {
      options.client.refetchQueries({ include: ['getBasicOrder'] })
      console.error(`Failed to update dispensed:`, error)
      showErrorToast(`Failed to update dispensed: ${error}`)
    },
    onCompleted: data => {
      completeCallback?.(data)
    },
  })

  const mutateWithToast: typeof mutate = (...args: Parameters<typeof mutate>) => {
    // On PV1, whenever the `daysSupply` or NDC is updated (but not other
    // dispensed values), we want to show this toast to remind users to check
    // the clinical review information. With the way this works it will show the
    // toast whenever this is used from anywhere, not just PV1, so we'll want to
    // do a design pass on this later to refine how we want it to work.
    if (
      isInsurance &&
      (!isNil(args[0]?.variables?.daysSupply) ||
        !isNil(args[0]?.variables?.ndc) ||
        !isNil(args[0]?.variables?.quantity))
    ) {
      showInfoToast('Reversing/Rerunning Claim & Clinical Screening ')
    } else if (!isNil(args[0]?.variables?.daysSupply) || !isNil(args[0]?.variables?.ndc)) {
      showInfoToast('Clinical screening in process')
    }

    return mutate(...args)
  }

  return [mutateWithToast, options] as const
}

export default useUpdateDispensedMedication
