import { useState, createContext, useContext } from 'react'
import { PrinterPurpose } from '@truepill/tpos-types'
import type { ChildProps, Printer } from 'types'

interface PrintProviderProps {
  printingInProgress: boolean
  selectedPrinter?: Printer
  setPrinter: (printer?: Printer, printerPurpose?: PrinterPurpose) => void
  getSavedPrinterId: (printerPurpose: PrinterPurpose) => string | null
  resetSavedPrinters: () => void
}

const PrintContext = createContext<PrintProviderProps>({
  getSavedPrinterId: () => {
    return ''
  },
  setPrinter: () => {
    return
  },
  resetSavedPrinters: () => {
    return
  },
  printingInProgress: false,
})

const PrintProvider = ({ children }: ChildProps): JSX.Element => {
  const [printer, setPrinter] = useState<Printer>()
  const prefixKey = 'selectedPrinterID'

  const value = {
    selectedPrinter: printer,
    getSavedPrinterId: (printerPurpose: PrinterPurpose) => {
      return localStorage.getItem(`selectedPrinterID-${printerPurpose}`)
    },
    setPrinter: (printer?: Printer, printerPurpose?: PrinterPurpose) => {
      setPrinter(printer)

      if (printer && printerPurpose) {
        const persistValue = printer ? printer._id : ''
        localStorage.setItem(`${prefixKey}-${printerPurpose}`, persistValue)
      } else if (printerPurpose) {
        localStorage.removeItem(`${prefixKey}-${printerPurpose}`)
      }
    },
    resetSavedPrinters: () => {
      Object.values(PrinterPurpose).map(value => localStorage.removeItem(`${prefixKey}-${value}`))
    },
    printingInProgress: true,
  }

  return <PrintContext.Provider value={value}>{children}</PrintContext.Provider>
}

const usePrintProvider = (): PrintProviderProps => {
  const printContext = useContext(PrintContext)

  if (printContext === undefined) {
    throw new Error('Attempting to read PrintContext outside a Provider hierarchy')
  }

  return printContext as PrintProviderProps
}

export type { PrintProviderProps }
export { PrintContext, usePrintProvider }
export default PrintProvider
