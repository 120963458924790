import { Checkbox, Select } from '@truepill/react-capsule'
import { stateAllOption } from '@truepill/tpos-types'
import { usePlusClient } from 'providers/VisionRouter'
import { capitalize } from 'utils'
import states from '../../utils/states'

type StatesSelectProps = {
  label: string
  variant?: 'small' | 'large'
}

const StatesSelect = (props: StatesSelectProps): JSX.Element => {
  const { label, variant = 'large' } = props
  const { currentLocation, routeToMergedQuery } = usePlusClient()

  const noStates = currentLocation?.queryMap?.noStates
  const selectedOptions = currentLocation?.queryMap?.states || []

  const defaultOption = { name: 'Select all', value: stateAllOption }

  const statesOptions: { name: string; value: string }[] = Object.values(states).map(value => ({ name: value, value }))

  const options = [defaultOption, ...statesOptions]

  const value = selectedOptions.includes(stateAllOption) ? stateAllOption : selectedOptions.join(',')

  if (!noStates && !selectedOptions.length) {
    routeToMergedQuery({ states: [stateAllOption] })
  }

  return (
    <Select
      variant={variant}
      label={label && capitalize(label)}
      css={{
        ul: { maxHeight: '21rem' },
      }}
      value={value}
      onChange={() => {
        // Because the elements are checkboxes which trigger an action, we do not need to do anything here
      }}
      options={options}
      placeholder="Select states"
      selectedKey="name"
      optionComponent={({ option }) => (
        <>
          <Checkbox
            label={option?.name}
            checked={selectedOptions.includes(option?.value) || selectedOptions.includes(stateAllOption)}
            onCheckedChange={checked => {
              if (checked) {
                if (option?.value === stateAllOption) {
                  routeToMergedQuery({ states: [option.value], noStates: undefined })
                } else {
                  routeToMergedQuery({
                    states: [...selectedOptions, option?.value],
                    noStates: undefined,
                  })
                }
              } else {
                if (option?.value === stateAllOption) {
                  routeToMergedQuery({ states: [], noStates: true })
                } else {
                  if (selectedOptions.includes(stateAllOption)) {
                    routeToMergedQuery({
                      states: statesOptions.filter(opt => opt.value !== option?.value).map(opt => opt.value),
                      noStates: undefined,
                    })
                  } else {
                    const newSelectedOptions = selectedOptions.filter((value: string) => value !== option?.value)
                    routeToMergedQuery({
                      states: newSelectedOptions,
                      noStates: undefined,
                    })
                  }
                }
              }
            }}
          />
        </>
      )}
    />
  )
}

export default StatesSelect
