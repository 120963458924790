import { Chip, Tooltip, Text } from '@truepill/react-capsule'
import Lozenge from 'components/Tiles/Lozenge'
import styled from 'styled-components'
import { accentPurple } from 'styles/styleVariables'
import { ExternalInventorySystemPretty } from 'types'

type InventorySystemTagProps = {
  externalSystemName?: string
  useLozenge?: boolean
}

const InventorySystemTag = (props: InventorySystemTagProps): JSX.Element => {
  const { externalSystemName, useLozenge } = props

  if (!externalSystemName) return <></>

  if (externalSystemName !== 'pioneer_inventory' && externalSystemName !== 'tecsys') {
    return <></>
  }

  // tooltip has zindex set to prevent it from rendering below other assets
  return (
    <Tooltip
      label={`Inventory for this item was selected from ${ExternalInventorySystemPretty[externalSystemName]}`}
      css={{ backgroundColor: accentPurple, color: 'white', zIndex: 10 }}
      arrowCss={{ borderColor: accentPurple, zIndex: 10 }}
    >
      {useLozenge && <StyledLozenge>{ExternalInventorySystemPretty[externalSystemName]}</StyledLozenge>}
      {!useLozenge && (
        <Chip css={{ backgroundColor: accentPurple, color: 'white' }}>
          <Text variant="body">{ExternalInventorySystemPretty[externalSystemName]}</Text>
        </Chip>
      )}
    </Tooltip>
  )
}

export default InventorySystemTag

const StyledLozenge = styled(Lozenge)`
  background-color: ${accentPurple};
  color: white;
`
