import { Link } from '@truepill/tpos-react-router'
import { ReactComponent as LinkIcon } from 'assets/icons/external-link.svg'
import styled from 'styled-components'
import { primaryColor } from 'styles/styleVariables'

const CMMKeyLink = ({ cmmKey }: { cmmKey: string }) => {
  return (
    <StyledLink target="_blank" to={{ pathname: `https://www.covermymeds.com/request/view/${cmmKey}` }} title={cmmKey}>
      <span style={{ display: 'inline-flex' }}>
        {cmmKey} <LinkIcon width="0.625rem" stroke="black" />
      </span>
    </StyledLink>
  )
}

const StyledLink = styled(Link)`
  font-size: inherit;
  margin: 0 0.5rem;
  :hover {
    margin: 0 0.125rem 0rem 0.5rem;
    color: ${primaryColor};
    svg {
      stroke: ${primaryColor};
      width: 1rem;
    }
  }
`
export default CMMKeyLink
