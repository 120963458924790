import type { ReactNode } from 'react'
import { useContext } from 'react'
import { ReactComponent as ToolTipTail } from 'assets/icons/tooltipTail.svg'
import type { HotKeyProps } from 'providers/HID/HotKeyProvider'
import { HotKeyContext } from 'providers/HID/HotKeyProvider'
import styled, { css } from 'styled-components'
import { primaryBackgroundColor, bodyPrimaryColor } from 'styles/styleVariables'

export type ToolTipFacing = 'top' | 'bottom' | 'left' | 'right'

type HotKeyToolTipProps = {
  label: string | ReactNode
  position: ToolTipFacing
  offsetTop?: number
  offsetLeft?: number
  forceShow?: boolean
  forceHide?: boolean
  onMouseEnter?: () => void
}

const HotKeyToolTip = ({
  offsetTop,
  offsetLeft,
  onMouseEnter,
  position,
  label,
  forceShow,
  forceHide,
}: HotKeyToolTipProps): JSX.Element => {
  const { showToolTips } = useContext(HotKeyContext) as HotKeyProps

  // We always need to return at least a div because inserting/removing
  // a 0-dimension dom element can still alter styling, so elements where the
  // ToolTip is included should be able better able to style around consistent
  // markup.
  if ((!showToolTips && !forceShow) || forceHide) {
    return <div />
  }

  return (
    <ToolTipAnchor>
      <ToolTipWrapper
        offsetTop={offsetTop}
        offsetLeft={offsetLeft}
        position={position}
        onMouseEnter={() => onMouseEnter && onMouseEnter()}
      >
        <ToolTip>{label}</ToolTip>
        <TailWrapper position={position}>
          <ToolTipTail />
        </TailWrapper>
      </ToolTipWrapper>
    </ToolTipAnchor>
  )
}

const TailWrapperTop = css`
  margin-top: -0.125rem;
`

const TailWrapperBottom = css`
  transform: rotate(180deg);
  margin-bottom: -0.125rem;
`

const TailWrapperRight = css`
  transform: rotate(90deg);
  margin-right: -0.125rem;
`

const TailWrapperLeft = css`
  transform: rotate(-90deg);
  margin-left: -0.125rem;
`

const ToolTipWrapperTop = css`
  align-items: center;
  flex-direction: column;
`

const ToolTipWrapperBottom = css`
  align-items: center;
  flex-direction: column-reverse;
`

const ToolTipWrapperLeft = css`
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`

const ToolTipWrapperRight = css`
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
`

const ToolTip = styled.div`
  display: flex;
  white-space: nowrap;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  width: max-content;
  background-color: ${primaryBackgroundColor};
  padding: 0.25rem 0.625rem;
  color: ${bodyPrimaryColor};
  box-shadow: 0px 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
  border: 1px solid ${primaryBackgroundColor};
  border-radius: 0.25rem;
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: capitalize;
`

const TailWrapper = styled.div<{
  position: ToolTipFacing
}>`
  z-index: 5;
  overflow: visible;
  display: flex;
  flex-direction: column;
  color: visible;
  justify-content: center;
  align-items: center;
  ${({ position }) => {
    if (position === 'top') {
      return TailWrapperTop
    } else if (position === 'bottom') {
      return TailWrapperBottom
    } else if (position === 'right') {
      return TailWrapperRight
    } else {
      return TailWrapperLeft
    }
  }}
`

const ToolTipWrapper = styled.div<{
  offsetTop?: number
  offsetLeft?: number
  position: ToolTipFacing
}>`
  position: absolute;
  z-index: 5;
  display: flex;
  flex-direction: column;
  height: 0;
  width: 0;
  overflow: visible;
  ${({ position }) => {
    if (position === 'bottom') {
      return ToolTipWrapperBottom
    } else if (position === 'right') {
      return ToolTipWrapperRight
    } else if (position === 'left') {
      return ToolTipWrapperLeft
    } else {
      return ToolTipWrapperTop
    }
  }}
  ${({ offsetLeft, offsetTop }) => {
    return `left: ${offsetLeft}rem; top: ${offsetTop}rem;`
  }}
`

const ToolTipAnchor = styled.div`
  z-index: 5;
  overflow: visible;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 0;
  width: 0;
`

export default HotKeyToolTip
