import { LaunchDarkly } from '@truepill/tpos-types'
import useShouldIncludePatientRelatedLogs from 'hooks/useShouldIncludePatientRelatedLogs'
import { useFlag } from 'providers/LaunchDarklyProvider'
import NotesListAlt from './NotesListAlt'
import NotesListOriginal from './NotesListOriginal'
import NotesListSkeleton from './NotesListSkeleton'
import type { NoteListProps } from './types'

const NotesList = (props: Omit<NoteListProps, 'includeAllPatientRelatedLogsInAlltab'>): JSX.Element => {
  const supportCSANotes = useFlag(LaunchDarkly.FeatureFlags.TEMP_SUPPORT_CSA_NOTES)
  const shouldIncludePatientRelatedLogs = useShouldIncludePatientRelatedLogs()

  if (props.isLoadingLogs) {
    return <NotesListSkeleton />
  }

  if (supportCSANotes) {
    return <NotesListAlt {...props} includeAllPatientRelatedLogsInAlltab={shouldIncludePatientRelatedLogs} />
  }
  return <NotesListOriginal {...props} includeAllPatientRelatedLogsInAlltab={shouldIncludePatientRelatedLogs} />
}

export default NotesList
export { NoteEntry } from './NotesListOriginal'
