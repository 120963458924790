import type React from 'react'
import { InsuranceCardType } from '@truepill/tpos-types'
import {
  StyledSelect,
  ListRowLabel,
  RXCell,
  AlternatingShading,
  StyledTextInput,
  ListRowValue,
} from 'components/RXTable'
import type { Option } from 'components/Select'
import styled, { css } from 'styled-components'
import type { InsurancePayer } from 'types'
import { relationshipCodes } from '../ServiceCodes'

type NewPayerDetailsProps = {
  newPayer: InsurancePayer
  setNewPayer: React.Dispatch<React.SetStateAction<InsurancePayer>>
}

const cardTypesValuesMap: Record<string, string> = {}
const cardTypesKeysMap: Record<string, string> = {}
Object.entries(InsuranceCardType).forEach(([key, value]: string[]) => {
  cardTypesValuesMap[value] = key
})
Object.entries(InsuranceCardType).forEach(([key, value]: string[]) => {
  cardTypesKeysMap[key] = value
})

const cardTypes = Object.keys(InsuranceCardType).map((k: string) => {
  return { value: k, label: k }
})

const NewPayerDetails = ({ newPayer, setNewPayer }: NewPayerDetailsProps): React.JSX.Element => {
  const setFormDataValue = (name: keyof InsurancePayer, value: string) => {
    setNewPayer({
      ...newPayer,
      [name]: value,
    })
  }

  const {
    name = '',
    bin = '',
    group = '',
    cardholderId = '',
    pcn = '',
    personCode = '',
    relationship = 'Not specified',
    cardType = InsuranceCardType.NotSpecified,
  } = newPayer

  return (
    <ul>
      <PayerTitle>Payer data</PayerTitle>
      <PayerFormCell>
        <RXFormRow>
          <ListRowLabel>Name:</ListRowLabel>
          <RXFormInput
            placeholder="Name..."
            value={name}
            onChange={e => {
              setFormDataValue('name', e.target.value)
            }}
          />
        </RXFormRow>
      </PayerFormCell>
      <PayerFormCell>
        <RXFormRow>
          <ListRowLabel>RxBIN / RxPCN:</ListRowLabel>
          <ListRowValue>
            <BINPCNContainer>
              <RXFormInput placeholder="RxBin..." value={bin} onChange={e => setFormDataValue('bin', e.target.value)} />
              <RXFormInput placeholder="RxPCN..." onChange={e => setFormDataValue('pcn', e.target.value)} value={pcn} />
            </BINPCNContainer>
          </ListRowValue>
        </RXFormRow>
      </PayerFormCell>
      <PayerFormCell>
        <RXFormRow>
          <ListRowLabel>Group #:</ListRowLabel>
          <RXFormInput
            placeholder="Group #..."
            value={group}
            onChange={e => {
              setFormDataValue('group', e.target.value)
            }}
          />
        </RXFormRow>
      </PayerFormCell>
      <PayerFormCell>
        <RXFormRow>
          <ListRowLabel>Cardholder ID:</ListRowLabel>
          <RXFormInput
            placeholder="Cardholder ID..."
            value={cardholderId}
            onChange={e => {
              setFormDataValue('cardholderId', e.target.value)
            }}
          />
        </RXFormRow>
      </PayerFormCell>
      <PayerFormCell>
        <RXFormRow>
          <ListRowLabel>Person code:</ListRowLabel>
          <PersonCodeInput
            placeholder="Person code..."
            value={personCode}
            onChange={e => {
              setFormDataValue('personCode', e.target.value)
            }}
          />
        </RXFormRow>
      </PayerFormCell>
      <PayerFormCell>
        <RXFormRow>
          <ListRowLabel>Relationship:</ListRowLabel>
          <PayerStyledSelect
            id={`#StyledSelect-d`}
            disableClear={true}
            multiple={false}
            placeholder={'Relationship'}
            options={relationshipCodes}
            value={relationship}
            onChange={([option]) => {
              const value = option ? option.value : ''
              setFormDataValue('relationship', value as string)
            }}
            preventScrolling={false}
          />
        </RXFormRow>
      </PayerFormCell>
      <PayerFormCell>
        <RXFormRow>
          <ListRowLabel>Card type:</ListRowLabel>
          <PayerStyledSelect
            id={`#StyledSelect-d`}
            disableClear={true}
            multiple={false}
            placeholder={'CardType'}
            options={cardTypes}
            value={cardTypesValuesMap[cardType]}
            onChange={([option]: Option<string>[]) => {
              if (option) {
                const valMapped = cardTypesKeysMap[option.value]
                setFormDataValue('cardType', valMapped)
              }
            }}
            preventScrolling={false}
          />
        </RXFormRow>
      </PayerFormCell>
    </ul>
  )
}

export default NewPayerDetails

const PayerFormCell = styled.ul<{
  even?: boolean
}>`
  ${AlternatingShading}
`

const PayerTitle = styled.p`
  font-weight: 500;
  margin-left: 0.625rem;
  margin-top: 1rem;
`

const BINPCNContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-rows: [content] 100%;
  grid-template-columns: [value] 43% [value2] 58%;
`
const styledPadding = css`
  margin: 0.225rem;
  width: 98%;
`

const RXFormInput = styled(StyledTextInput)`
  ${styledPadding}
`

const RXFormRow = styled(RXCell)`
  grid-template-columns: [label] 6.5rem [value] 1fr;
`

const PersonCodeInput = styled(RXFormInput)`
  width: 8rem;
`

const PayerStyledSelect = styled(StyledSelect)`
  margin: 0.225rem;
  width: 98%;
  font-weight: 400;
`
