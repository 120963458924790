import { FormItem, WideTextInput } from 'components/ColumnForm'
import PrescriberCard from 'components/PrescriberCard'
import PrescriberSearch from 'components/PrescriberSearch'
import SegmentedSelectButton from 'components/SegmentedSelectButton'
import { useFormData } from 'hooks/useFormData'
import type { FormDataState } from 'providers/Store'
import type { TransferRequestFormData } from 'types'

type PrescriberSectionProps = { nameSpace: keyof FormDataState }

const PrescriberSection = ({ nameSpace }: PrescriberSectionProps): JSX.Element => {
  const {
    actions: { updateFormData },
    state: {
      formData: { [nameSpace]: formData },
    },
  } = useFormData()

  const { contactMethod, prescriberId, spi, faxNumber } = formData as TransferRequestFormData

  return (
    <>
      <FormItem>
        <p>Contact method</p>
        <SegmentedSelectButton
          options={[
            { id: 'electronic', label: 'Electronic' },
            { id: 'fax', label: 'Fax' },
          ]}
          selectionChanged={newMethod => {
            updateFormData({
              [nameSpace]: {
                contactMethod: { $set: newMethod as TransferRequestFormData['contactMethod'] },
              },
            })
          }}
        />
      </FormItem>
      <FormItem>
        <p>Prescriber</p>
        <PrescriberSearch
          isModal
          onSelect={({ prescriberId }) => {
            updateFormData({
              [nameSpace]: {
                prescriberId: { $set: prescriberId },
              },
            })
          }}
        />
        {prescriberId && (
          <PrescriberCard
            prescriberId={prescriberId || ''}
            closeCallback={() =>
              updateFormData({
                [nameSpace]: {
                  prescriberId: { $set: '' },
                },
              })
            }
          />
        )}
      </FormItem>
      {contactMethod === 'fax' && (
        <FormItem>
          <p>Fax</p>
          <WideTextInput
            placeholder={'(xxx) xxx - xxxx'}
            value={faxNumber}
            onChange={e => {
              updateFormData({
                [nameSpace]: {
                  faxNumber: { $set: e.target.value },
                },
              })
            }}
          />
        </FormItem>
      )}
      {contactMethod === 'electronic' && (
        <FormItem>
          <p>Surescript SPI</p>
          <WideTextInput
            placeholder={''}
            value={spi}
            onChange={e => {
              updateFormData({
                [nameSpace]: {
                  spi: { $set: e.target.value },
                },
              })
            }}
          />
        </FormItem>
      )}
    </>
  )
}

export default PrescriberSection
