import DatePicker from 'components/DatePicker'
import { NumericInput } from 'components/NumericInput'
import { ActionButtonContainer, FilledHeader } from 'components/PageStructure'
import { StyledTextInput } from 'components/RXTable'
import SearchableSelect from 'components/Select'
import styled, { css } from 'styled-components'
import { primaryBackgroundColor, contrastBackgroundColor } from 'styles/styleVariables'

export const FormPage = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: [form] 25rem [assistant] 1fr;
  padding-left: 1.875rem;
`

export const FormColumn = styled.div`
  grid-row: 1;
  grid-column: form;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  min-width: 23.75rem;
  align-items: stretch;
`

export const CompressedFormColumn = styled(FormColumn)`
  min-width: 13.875rem;
  @media screen and (min-width: 1280px) {
    min-width: 16.875rem;
  }
`

export const InputHeight = css`
  height: 2.5rem;
`

export const Select = styled(SearchableSelect)`
  background-color: ${primaryBackgroundColor};
`

export const FormDatePicker = styled(DatePicker)`
  height: 2.125rem;
  padding-left: 0.625rem;
`

export const StyledDatePicker = styled.div`
  ${InputHeight}
  > div {
    height: 100%;
  }
`

export const FormItems = styled.ul`
  position: relative;
  width: 100%;
  > input {
  }
`
export const PatientTitle = styled.h3`
  margin-top: 1rem;
  margin-bottom: 0.5rem;
`

export const ShippingTitle = styled.h3`
  margin-top: 1rem;
  margin-bottom: -1rem;
`

export const FilledFormHeader = styled(FilledHeader)`
  flex: 1;
`

export const WideTextInput = styled(StyledTextInput)`
  ${InputHeight}
  flex: 1;
  margin-bottom: 0.5rem;
`

export const NarrowTextInput = styled(StyledTextInput)`
  ${InputHeight}
  background-color: ${primaryBackgroundColor};
  width: 6.25rem;
`

export const NarrowNumericInput = styled(NumericInput)`
  ${InputHeight}
  background-color: ${primaryBackgroundColor};
  width: 6.25rem;
`

export const WideNumericInput = styled(NumericInput)`
  ${InputHeight}
  flex: 1;
  margin-bottom: 0.5rem;
`

export const FormItem = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  margin-top: 1.1rem;
  margin-bottom: 1.1rem;
  > p {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1rem;
    margin-bottom: 0.5rem;
  }
`

export const CompoundFormLine = styled.ul`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  > li {
    margin-top: 0rem;
    margin-bottom: 0rem;
    width: auto;
  }
  > li:nth-child(2n) {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
`

export const AddressFormLine = styled(CompoundFormLine)`
  > li:first-child {
    width: 100%;
  }
`
export const ShippingFormLine = styled(CompoundFormLine)`
  > li:nth-child(1) {
    width: 50%;
  }
  > li:nth-child(2) {
    width: 50%;
  }
`

export const SearchSelectedCard = styled.div`
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  background-color: ${contrastBackgroundColor};
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 0.25rem;
  position: relative;

  > h4 {
    font-weight: 500;
    font-size: 1.125rem;
    margin-bottom: 0.5rem;
  }
`

export const FloatingBoxFormColumn = styled(FormColumn)`
  width: 22.5rem;
  padding: 1.25rem;
  padding-top: 1.25rem;
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

export const FloatingBoxFormActionButtonContainer = styled(ActionButtonContainer)`
  margin-top: 1.25rem;
  justify-content: flex-end;
  align-self: flex-end;
`
