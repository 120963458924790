import { ClaimDataPaidStatus, FieldCodes } from '@truepill/tpos-types'
import LoadingSpinner from 'components/Loading'
import { FilledHeadingStyle } from 'components/PageStructure'
import Lozenge from 'components/Tiles/Lozenge'
import moment from 'moment'
import { billingOrderDesignationType } from 'pages/fulfillment/orders/rx/screens/claims/ReviewSections/ReviewPayer'
import styled from 'styled-components'
import { subduedColor, alertRed, contrastColor, contrastBackgroundColor } from 'styles/styleVariables'
import type { Claim } from 'types'

const getInsuranceLabel = ({
  name,
  pcn,
  bin,
  billingOrder,
}: {
  name?: string
  pcn?: string
  bin?: string
  billingOrder?: number
}): string => {
  const text = `${name ? `${name} ` : ''}${bin ?? ''} | ${pcn ?? ''}`
  return `${text}${typeof billingOrder === 'number' ? ` (${billingOrderDesignationType[billingOrder]})` : ''}`
}

type ClaimsTableProps = { claims: Claim[]; loading?: boolean }

const ClaimsTable = ({ claims = [], loading }: ClaimsTableProps): JSX.Element => {
  const sortedClaims: any[] = []

  claims.forEach(claim => {
    const { bin, processorControlNumber } = claim.sent?.header || {}
    const { billingOrder, payerName, transmissionDate } = claim
    const pricingReceived = claim?.received?.groups?.[0]?.pricingResponse

    if (claim.status === 'Reversed') {
      sortedClaims.push({
        payerName,
        billingOrder,
        transmissionDate,
        status: 'Paid',
        sent: {
          header: {
            bin: bin || '',
            processorControlNumber: processorControlNumber || '',
          },
        },
        received: {
          groups: [
            {
              pricingResponse: pricingReceived,
            },
          ],
        },
      })
    }

    sortedClaims.push(claim)
  })

  const displayClaims = [...sortedClaims].reverse()

  return (
    <ClaimsContainer>
      <TitleClaimsRow>
        <TypeColumn>Type</TypeColumn>
        <ThirdPartyColumn>Third party</ThirdPartyColumn>
        <TransmittedColumn>Transmitted</TransmittedColumn>
        <CopayColumn>Copay</CopayColumn>
        <TotalPaidColumn>Total paid</TotalPaidColumn>
        <StatusColumn>Status</StatusColumn>
        <ResponsesColumn>Responses</ResponsesColumn>
      </TitleClaimsRow>
      {loading ? (
        <LoadingSpinnerBox>
          <LoadingSpinner />
        </LoadingSpinnerBox>
      ) : (
        <ClaimsRowContainer>
          {displayClaims.map((claim, claimIndex) => ClaimEntry(claim, claimIndex))}
          {displayClaims.length === 0 && <NoResultsEntry>No Claims</NoResultsEntry>}
        </ClaimsRowContainer>
      )}
    </ClaimsContainer>
  )
}

export const ClaimStatus = (status?: string): JSX.Element => {
  if (!status) {
    return <></>
  }

  let color = alertRed

  if (ClaimDataPaidStatus.includes(status)) {
    color = '#0FCAB4'
  } else if (status === 'Reversed' || status === FieldCodes.TransactionStatusNames.DuplicatedOfApproved) {
    color = subduedColor
  }

  const display = FieldCodes.TransactionStatusNames[status as keyof typeof FieldCodes.TransactionStatusNames] ?? status

  if (!display) {
    return <></>
  }
  return <Lozenge backgroundColor={color}>{display}</Lozenge>
}

const ClaimEntry = (claim: Claim, claimIndex: number): JSX.Element => {
  const pricingReceived = claim?.received?.groups?.[0]?.pricingResponse
  const rejectCodes = claim.received?.groups?.[0]?.response?.rejectCodes
  const { bin, processorControlNumber } = claim.sent?.header || {}
  const { billingOrder, payerName, transmissionDate, status } = claim
  const transmittedTime = status === 'Reversed' ? claim.reversal?.reversedAt : transmissionDate
  const claimStatus =
    claim.status === 'Reversed' &&
    claim.reversal?.received?.groups?.[0]?.response?.transactionStatus ===
      FieldCodes.TransactionStatus.DuplicatedOfApproved
      ? FieldCodes.TransactionStatusNames.DuplicatedOfApproved
      : claim.status

  return (
    <Row>
      <ClaimRow data-private>
        <TypeColumn>Billing</TypeColumn>
        <ThirdPartyColumn>
          {getInsuranceLabel({ name: payerName, pcn: processorControlNumber, bin, billingOrder })}
        </ThirdPartyColumn>
        <TransmittedColumn>{moment(transmittedTime).format('MM/DD/YYYY h:mm:ssa')}</TransmittedColumn>
        <CopayColumn>${pricingReceived?.patientPayAmount || '0.00'}</CopayColumn>
        <TotalPaidColumn>
          ${((pricingReceived?.totalAmountPaid || 0) + (pricingReceived?.patientPayAmount || 0)).toFixed(2)}
        </TotalPaidColumn>
        <StatusColumn data-testid="statusColumn">{ClaimStatus(claimStatus)}</StatusColumn>
        <ResponsesColumn>
          {rejectCodes?.map((i, index) => {
            return (
              <RejectCode key={index}>
                {i.rejectCode}
                {index === rejectCodes.length - 1 ? '' : ','}
              </RejectCode>
            )
          })}
        </ResponsesColumn>
      </ClaimRow>
    </Row>
  )
}

const ClaimCell = styled.li`
  grid-row: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 0.3125rem;
  margin-right: 0.3125rem;
`

const ClaimsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  :last-child {
    border-bottom: 0.25rem solid ${contrastColor};
    border-radius: 0.25rem;
  }
  flex: 1;

  border-bottom: 0.125rem solid #cccccc;
  border-radius: 0rem 0rem 0.25rem 0.25rem;
  margin-bottom: 2rem;
`

const ClaimsRowContainer = styled.div`
  max-height: 9rem;
  overflow: scroll;
`

const Row = styled.div`
  :nth-of-type(2n + 1) {
    background-color: ${contrastBackgroundColor};
  }
  border-left: 0.125rem solid #cccccc;
  border-right: 0.125rem solid #cccccc;
`

const NoResultsEntry = styled.p`
  font-size: 1rem;
  color: ${subduedColor};
  padding: 1rem;
  width: 100%;
  text-align: center;
`

const ClaimRow = styled.ul`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns:
    [type] 1fr [thirdParty] 1.5fr [transmitted] 2fr [copay] 1fr [totalPaid] 1fr
    [status] 1fr [responses] 3fr;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
`

const RejectCode = styled.span``

const TitleClaimsRow = styled(ClaimRow)`
  ${FilledHeadingStyle}
  font-weight: 500;
  padding-left: 0rem;
  padding-right: 0rem;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  border-radius: 0.25rem 0.25rem 0rem 0rem;
`

const TypeColumn = styled(ClaimCell)`
  grid-column: type;
`
const ThirdPartyColumn = styled(ClaimCell)`
  grid-column: thirdParty;
`
const TransmittedColumn = styled(ClaimCell)`
  grid-column: transmitted;
`

const CopayColumn = styled(ClaimCell)`
  grid-column: copay;
`

const TotalPaidColumn = styled(ClaimCell)`
  grid-column: totalPaid;
`

const StatusColumn = styled(ClaimCell)`
  grid-column: status;
`

const ResponsesColumn = styled(ClaimCell)`
  grid-column: responses;
`

const LoadingSpinnerBox = styled.div`
  display: flex;
  padding-top: 4rem;
  justify-content: center;
  svg {
    height: 126px;
  }
`

export default ClaimsTable
