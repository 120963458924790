import { useState, useEffect, useRef } from 'react'

interface Highlight {
  start: number
  end: number
  text: string
  color: string
}

interface HighlightProps {
  text: string
  containerName: string
  defaultHighlights?: string[]
  onHighlight: (highlight: string) => void
  onUnhighlight: (unhighlight: string) => void
}

const findTextPositions = (text: string, search: string, color: string) => {
  const positions: Highlight[] = []
  const regex = new RegExp(search, 'gi')

  for (const match of text.matchAll(regex)) {
    positions.push({
      start: match.index,
      end: match.index + match[0].length,
      text: match[0],
      color,
    })
  }

  return positions
}

const getCharacterOffsetWithin = (range: Range, container: HTMLElement) => {
  let charCount = 0
  const treeWalker = document.createTreeWalker(container, NodeFilter.SHOW_TEXT, {
    acceptNode: (node: Node) => {
      return NodeFilter.FILTER_ACCEPT
    },
  })

  let currentNode = treeWalker.nextNode()

  while (currentNode) {
    if (currentNode === range.startContainer) {
      charCount += range.startOffset
      break
    }
    if (currentNode.textContent?.length) {
      charCount += currentNode.textContent.length
    }
    currentNode = treeWalker.nextNode()
  }

  return charCount
}

const getRandomVibrantColor = (): string => {
  const hue = Math.floor(Math.random() * 361)
  const saturation = Math.floor(Math.random() * (100 - 72 + 1)) + 72
  const lightness = Math.floor(Math.random() * (80.02 - 66.7 + 1)) + 66.7
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`
}

const HighlightComponent = ({ text, containerName, defaultHighlights, onHighlight, onUnhighlight }: HighlightProps) => {
  const [highlights, setHighlights] = useState<Highlight[]>([])
  const highlightColors = useRef(new Map<string, string>())

  const handleMouseUp = () => {
    const selection = window.getSelection()
    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0)
      const selectedText = selection.toString()

      if (selectedText && selectedText.trim()) {
        const container = document.getElementById(containerName)
        if (!container) {
          return
        }

        const start = getCharacterOffsetWithin(range, container)
        const end = start + selectedText.length

        const color = highlightColors.current.get(selectedText) ?? getRandomVibrantColor()
        highlightColors.current.set(selectedText, color)

        const existingHighlight = highlights.find(highlight => highlight.start === start && highlight.end === end)

        if (existingHighlight) {
          const updatedHighlights = highlights.filter(highlight => highlight !== existingHighlight)
          setHighlights(updatedHighlights)
          onUnhighlight(existingHighlight.text)
        } else {
          const overlappingHighlights: Highlight[] = highlights.filter(
            highlight => !(highlight.end <= start || highlight.start >= end),
          )

          overlappingHighlights.forEach(highlight => onUnhighlight(highlight.text))

          const nonOverlappingHighlights: Highlight[] = highlights.filter(
            highlight => highlight.end <= start || highlight.start >= end,
          )

          const newHighlights: Highlight[] = [
            ...nonOverlappingHighlights,
            { start, end, text: selectedText, color: getRandomVibrantColor() },
          ]

          setHighlights(newHighlights)
          onHighlight(selectedText)
        }

        selection.removeAllRanges()
      }
    }
  }

  const renderHighlightedText = () => {
    const highlightedText = []
    let currentIndex = 0

    highlights
      .sort((a, b) => a.start - b.start)
      .forEach((highlight, index) => {
        if (currentIndex < highlight.start) {
          highlightedText.push(<span key={`text-${index}`}>{text.slice(currentIndex, highlight.start)}</span>)
        }

        if (currentIndex < highlight.end) {
          highlightedText.push(
            <span key={`highlight-${index}`} style={{ backgroundColor: highlight.color }}>
              {text.slice(highlight.start, highlight.end)}
            </span>,
          )
          currentIndex = highlight.end
        }
      })

    if (currentIndex < text.length) {
      highlightedText.push(<span key="text-end">{text.slice(currentIndex)}</span>)
    }

    return highlightedText
  }

  useEffect(() => {
    if (!defaultHighlights?.length || !text) {
      return
    }
    const initialHighlights = defaultHighlights.flatMap(highlightText => {
      const color = highlightColors.current.get(highlightText) ?? getRandomVibrantColor()
      highlightColors.current.set(highlightText, color)
      return findTextPositions(text, highlightText, color)
    })

    setHighlights(initialHighlights)
  }, [text, defaultHighlights])

  return (
    <div role="textbox" tabIndex={0} onMouseUp={handleMouseUp} key={containerName} style={{ lineHeight: 'normal' }}>
      <p id={containerName}>{renderHighlightedText()}</p>
    </div>
  )
}

export default HighlightComponent
