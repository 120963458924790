import type { DrugImageImprintsType } from '../drugImageImprint'
import type { MedispanGppcShared } from '../medispan'
import type { MedispanNdcType } from '../ndcs'
import type { FfsInventoryInfo } from '../prescription/fill'
import type { WithStamps, InventoryPricing, EntityId } from '../shared'

export interface NdcFullInfoPackage extends MedispanNdcType, MedispanGppcShared, WithStamps {
  oldNdc?: string
  drugImageImprints?: DrugImageImprintsType
  inventories: NdcInventory[]
  ndc10?: string
  drugName: string
  alternateLabelName?: string
  alternateBarCodes?: string[]
}

export interface NdcInventory extends WithStamps {
  locationId: EntityId
  inventoryGroupId?: EntityId
  availableBalance?: number
  balanceOnHand: number
  balanceInFlight?: number
  updatedAt: Date
  pricing?: InventoryPricing
  createdAt?: Date
  customerId?: string
  ffsInventoryInfo?: FfsInventoryInfo
}

export enum NdcFullInfoSource {
  Medispan = 'Medispan',
  FDB = 'FDB',
  Override = 'Override',
}

export type NdcFullInfoSourceType = (typeof NdcFullInfoSource)[keyof typeof NdcFullInfoSource]
