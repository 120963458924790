import ActionButton from 'components/ActionButton'
import OrderSearch from 'components/OrderSearch'
import { PageContainer } from 'components/PageStructure'
import styled, { css } from 'styled-components'
import {
  bodyPrimaryColor,
  borderColor,
  primaryActiveButtonBackground,
  primaryBackgroundColor,
  primaryColor,
} from 'styles/styleVariables'
import OrdersTable from './components/OrdersTable'

const Inactive = css`
  background-color: ${primaryBackgroundColor};
  color: ${bodyPrimaryColor};
  > svg {
    fill: ${bodyPrimaryColor};
  }
`

const Active = css`
  background-color: ${primaryActiveButtonBackground};
  color: ${primaryBackgroundColor};
  > svg {
    fill: ${primaryBackgroundColor};
  }
`

const OrdersSection = styled(OrdersTable)`
  margin-top: 0.25rem;
  grid-row: content;
  grid-column: orders;
`

const Title = styled.h1`
  font-size: 24px;
  grid-row: title;
  grid-column: 1 / span 2;
  margin-bottom: 0.8rem;
  padding-left: 1.875rem;
`

const FilterControls = styled.div`
  grid-column: meta;
  grid-row: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  > button:nth-child(n + 2) {
    margin-left: 0.625rem;
  }
`

const StyledOrderSearch = styled(OrderSearch)`
  height: 2.25rem;
  margin-left: 0.625rem;
  input {
    margin-left: 0rem;
  }
`

const StyledActionButton = styled(ActionButton)<{ active?: boolean }>`
  font-weight: 500;
  font-family: Roboto;
  font-size: 1rem;
  ${({ active }) => (active ? Active : Inactive)}
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 2.5rem;
  border: 0.125rem solid;
  border-color: ${borderColor};
  border-radius: 0.25rem;
  min-width: 4rem;
  padding: 0.3rem 1rem;
  margin-left: 0rem;
  :hover {
    border: 2px solid ${primaryColor};
  }
  :focus {
    border: 0.1px solid ${primaryColor};
  }
`

const OrdersPageContainer = styled(PageContainer)`
  background-color: white;
  display: grid;
  grid-template-rows: auto;
  grid-template-rows: [title] auto [actionBar] auto [content] 1fr [paging] 1fr;
  grid-template-columns: [filter] auto [orders] minmax(0, 1fr);
`

const StyledBasicActionButton = styled(ActionButton)`
  border-color: ${borderColor};
  background-color: ${primaryBackgroundColor};
  :hover {
    border: 2px solid ${primaryColor};
  }
`

export {
  OrdersSection,
  Title,
  FilterControls,
  StyledOrderSearch,
  StyledActionButton,
  OrdersPageContainer,
  StyledBasicActionButton,
}
