import AddAttachmentModal from 'modals/AddAttachmentModal'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import styled from 'styled-components'
import { borderColor, primaryColor } from 'styles/styleVariables'
import type { AttachmentsOptions } from 'types'

const AddAttachment = ({ orderId, fillId, prescriptionId, patientId }: AttachmentsOptions): JSX.Element => {
  const { showModal } = useModalContext()

  const onAddAttachmentClicked = () => {
    showModal(() => (
      <AddAttachmentModal orderId={orderId} fillId={fillId} prescriptionId={prescriptionId} patientId={patientId} />
    ))
  }

  return <StyledButton onClick={onAddAttachmentClicked}>+ Add attachment</StyledButton>
}

const StyledButton = styled.div`
  height: 40px;
  margin-top: 15px;
  padding-left: 10px;
  font-size: 16px;
  line-height: 40px;
  font-weight: 500;
  border: 0.125rem solid ${borderColor};
  border-radius: 0.25rem;
  &:hover {
    border: 0.125rem solid ${primaryColor};
  }
`

export default AddAttachment
