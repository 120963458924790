import { useContext } from 'react'
import { ReactComponent as TriangleIcon } from 'assets/icons/pageArrow.svg'
import type { DisclosureProviderProps } from 'providers/DisclosureProvider'
import { DisclosureContext } from 'providers/DisclosureProvider'
import styled, { css, keyframes } from 'styled-components'
import { capsuleBorderColor } from 'styles/styleVariables'

// A Simple Disclosure Arrow designed to be used with a DisclosureContext Provider
// In that way, the Arrow and what it's disclosing can be separated which is necessary from a layout/styling objective
const DisclosureArrow = (props: { [key: string]: string }): JSX.Element => {
  const { disclosed, firstLoad, toggleDisclosure } = useContext(DisclosureContext) as DisclosureProviderProps
  return (
    <DisclosureArrowButton
      data-testid="DisclosureArrow"
      aria-expanded={disclosed}
      {...props}
      disclosed={disclosed}
      firstLoad={firstLoad}
      onClick={toggleDisclosure}
    >
      <Arrow role="img" fill={capsuleBorderColor} />
    </DisclosureArrowButton>
  )
}

const DisclosureArrowButton = styled.button<{
  disclosed: boolean
  firstLoad: boolean
}>`
  border: none;
  background-color: transparent;
  ${({ disclosed }) => (disclosed ? Up : Down)}
  ${({ firstLoad }) => firstLoad && Instant}
  transform-origin: center;
  animation-fill-mode: forwards;
`

const DownAnimation = keyframes`
  0% {
  transform: rotate(90deg);
  }
  50% {
  transform: rotate(180deg);
  }
  100% {
  transform: rotate(270deg);
  }
`

const UpAnimation = keyframes`
  0% {
  transform: rotate(270deg);
  }
  50% {
  transform: rotate(180deg);
  }
  100% {
  transform: rotate(90deg);
  }
`

const Instant = css`
  animation-duration: 0ms;
`

const Up = css`
  animation: ${UpAnimation} 100ms;
`

const Down = css`
  animation: ${DownAnimation} 100ms;
`

const Arrow = styled(TriangleIcon)<{
  fill: string
}>`
  width: 0.625rem;
  height: 0.5rem;
`

export default DisclosureArrow
