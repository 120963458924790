import { useContext } from 'react'
import { ClaimStatus } from 'components/ClaimsTable'
import DisclosureArrow from 'components/DisclosureArrow'
import { NoResultsEntry } from 'components/PageStructure'
import isEqual from 'lodash/isEqual'
import moment from 'moment'
import DisclosureProvider, { DisclosureContext } from 'providers/DisclosureProvider'
import styled from 'styled-components'
import { primaryBackgroundColor, bodySecondaryColor, contrastBackgroundColor } from 'styles/styleVariables'
import type { User, Order, Log, Fill, Prescription, Autobot, ParataNdc, Patient, Escript, CopayRequest } from 'types'

enum LogsToIgnore {
  STATUS_CHANGE_BASED_ON_FILL = 'status change based on fill requests',
}

const LogView = (props: {
  logs: Log[]
  orderId?: Order['_id']
  fillId?: Fill['_id']
  prescriptionId?: Prescription['_id']
  autobotIds?: Autobot['_id'][]
  parataNdcIds?: ParataNdc['_id'][]
  patientId?: Patient['_id']
  escriptId?: Escript['_id']
  copayRequestId?: CopayRequest['_id']
  isCopay?: boolean
  details?: string
  updatedUserId?: User['_id']
}): JSX.Element => {
  const {
    orderId,
    fillId,
    prescriptionId,
    patientId,
    escriptId,
    autobotIds,
    parataNdcIds,
    copayRequestId,
    updatedUserId,
  } = props
  if (
    orderId === undefined &&
    fillId === undefined &&
    prescriptionId === undefined &&
    patientId === undefined &&
    escriptId === undefined &&
    updatedUserId === undefined &&
    copayRequestId === undefined &&
    (!autobotIds || autobotIds.length === 0) &&
    (!parataNdcIds || parataNdcIds.length === 0)
  ) {
    console.warn(
      'Attempted to load Log component without either an OrderId/FillId/PrescriptionId/AutobotId/ParataNdcId',
    )
    return <></>
  }

  return (
    <LogContainer>
      <LogEntries {...props} />
    </LogContainer>
  )
}

const LogEntries = (props: {
  logs: Log[]
  orderId?: Order['_id']
  fillId?: Fill['_id']
  prescriptionId?: Prescription['_id']
  autobotIds?: Autobot['_id'][]
  parataNdcIds?: ParataNdc['_id'][]
  patientId?: Patient['_id']
  escriptId?: Escript['_id']
  copayRequestId?: CopayRequest['_id']
  isCopay?: boolean
  details?: string
}): JSX.Element => {
  const {
    orderId,
    fillId,
    prescriptionId,
    patientId,
    escriptId,
    logs,
    autobotIds,
    parataNdcIds,
    copayRequestId,
    isCopay,
  } = props

  const logEntries =
    logs.filter(
      (log: Log) =>
        log.message !== LogsToIgnore.STATUS_CHANGE_BASED_ON_FILL &&
        (log.change?.newValue || log.change?.oldValue ? !isEqual(log.change?.newValue, log.change?.oldValue) : true) &&
        // For copays, we are isolating copay-level and copay-fill level logs, and also not showing prescription logs on copays (JR-10808)
        (isCopay && fillId ? !!log.fillId && log.fillId === fillId : true) && // hide logs without fillId if we pass in a fillId/filter out sibling logs
        (isCopay && !fillId ? !log.fillId : true) && // hide logs with a fillId if we don't pass a fillId in
        (isCopay && copayRequestId && !prescriptionId ? !log.prescriptionId : true) && // hide logs with a prescriptionId if we dont pass one in
        (log?.message?.includes('Controlled substance') ? log?.fillId === fillId || log?.orderId === orderId : true) &&
        ((log.event !== 'note' && log.event !== 'dur' && !log.fillId && !log.prescriptionId && !log.copayRequestId) ||
          (log.fillId && log.fillId === fillId) ||
          (log.copayRequestId && log.copayRequestId === copayRequestId) ||
          (log.prescriptionId && log.prescriptionId === prescriptionId) ||
          (log.patientId && log.patientId === patientId) ||
          (log.escriptId && log.escriptId === escriptId) ||
          (!fillId && !prescriptionId && !patientId && !escriptId && !copayRequestId) ||
          (log.autobotId && autobotIds?.includes(log.autobotId)) ||
          (log.parataNdcId && parataNdcIds?.includes(log.parataNdcId))),
    ) || []

  logEntries.sort((logA: Log, logB: Log) => {
    const aCreated = logA.createdAt
    const bCreated = logB.createdAt
    if (aCreated > bCreated) {
      return -1
    } else if (aCreated < bCreated) {
      return 1
    } else {
      return 0
    }
  })

  return (
    <StyledList>
      {logEntries.map((entry: Log) => (
        <DisclosureProvider key={entry._id}>
          <LogEntryRow key={entry._id} {...entry} />
        </DisclosureProvider>
      ))}
      {logEntries.length === 0 && <NoResultsEntry> No results </NoResultsEntry>}
    </StyledList>
  )
}

const billingOrderToText = ['Primary', 'Secondary', 'Tertiary', 'Quaternary']
const payerIndexToText = (payerIndex: number) => billingOrderToText[payerIndex] ?? `${payerIndex}th payer`
const getPayerIndexText = (payerIndex: number | undefined) =>
  payerIndex === undefined ? '' : `(${payerIndexToText(payerIndex)})`
const getClaimLogMessage = (field: string, payerIndex: number | undefined) => (
  <LogMessage>
    {field} {getPayerIndexText(payerIndex)}
  </LogMessage>
)

const getEligibilityCheckMessage = (field: string) => <LogMessage>{field}</LogMessage>

const LogEntryMessage = (props: {
  event: Log['event']
  message: Log['message']
  change: Log['change']
  payerIndex: number | undefined
  isOrder?: boolean
}) => {
  const { message, change, payerIndex, isOrder = true, event } = props

  if (change?.field?.includes('Eligibility')) {
    return getEligibilityCheckMessage(change.field)
  }

  if (change?.field?.includes('claim')) {
    return getClaimLogMessage(change.field, payerIndex)
  }

  if (event === 'dur') {
    return <LogMessage>Dur screening</LogMessage>
  }

  if (change?.field === 'inTriage') {
    const messageTrimmed = message.endsWith(':') ? message.slice(0, -1) : message
    const itemType = isOrder ? 'order' : 'copay'
    return (
      <LogMessage>
        {change.newValue === 'true'
          ? `Placed ${itemType} in triage - (${messageTrimmed})`
          : `Removed ${itemType} from triage - (${messageTrimmed})`}
      </LogMessage>
    )
  } else if (message && change?.field) {
    return (
      <LogMessage>
        {message} <br /> Change {change.field} from &quot;{change.oldValue}&quot; to &quot;{change.newValue}&quot;
      </LogMessage>
    )
  } else if (!change || change?.field === null) {
    return <LogMessage>{message || '-'}</LogMessage>
  } else {
    return (
      <LogMessage>
        Change {change?.field} from &quot;{change?.oldValue}&quot; to &quot;{change?.newValue}&quot;
      </LogMessage>
    )
  }
}

const LogEntryRow = (props: Log): JSX.Element => {
  const { user, createdAt, message, event, change, payerIndex, orderId, debug, details } = props
  const { disclosed } = useContext(DisclosureContext)

  const isClaim = change?.field?.includes('claim')
  const isEligibilityCheck = change?.field?.includes('Eligibility')
  const isOrder = orderId ? true : false
  let isRecieved
  let status
  if (isClaim) {
    const claim = change?.newValue ? JSON.parse(change?.newValue) : ''
    isRecieved = !!claim?.groups?.[0]?.response
    status = claim?.groups?.[0]?.response?.transactionStatusTranslated
  }

  // Temporaririly hiding data dump options as they're not part of the current
  // log spec

  return (
    <>
      <StyledLogEntryRow data-testid={`Log-${props._id}`}>
        <Name>
          {user?.firstName} {user?.lastName}
        </Name>
        <Date>{moment(createdAt).format('MM/DD/YYYY, h:mmA')}</Date>
        <Tag>
          {event} {isRecieved && ClaimStatus(status)}
        </Tag>
        <LogEntryMessage message={message} change={change} payerIndex={payerIndex} isOrder={isOrder} event={event} />
        {(!!change?.field || !!details?.length) && <DisclosureArrow aria-label={event} />}
        {isClaim || isEligibilityCheck
          ? disclosed && (
              <>
                {message && <SubMessage>{message}</SubMessage>}
                <Dump>
                  <CodeBlock>{change?.newValue}</CodeBlock>
                  {debug?.origin && <CodeBlock>Origin: {debug.origin}</CodeBlock>}
                </Dump>
              </>
            )
          : change &&
            disclosed && (
              <Dump>
                <CodeBlock>{details?.length ? details : JSON.stringify(change, null, '  ')}</CodeBlock>
                {debug?.origin && <CodeBlock>Origin: {debug.origin}</CodeBlock>}
              </Dump>
            )}
      </StyledLogEntryRow>
    </>
  )
}

const StyledLogEntryRow = styled.li`
  display: grid;
  grid-template-rows: minmax(2.5rem, auto) auto auto;
  grid-template-columns: 10rem 10rem 30rem auto 2rem;
  grid-template-areas: 'date name message tag disclosureTriangle' 'submessage submessage submessage submessage submessage' 'dump dump dump dump dump';
  align-items: center;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
`

const Date = styled.p.attrs({
  'data-testid': 'date',
})`
  grid-row: 1;
  grid-column: date;
  color: ${bodySecondaryColor};
`
const Name = styled.p.attrs({
  'data-testid': 'name',
})`
  grid-row: 1;
  grid-column: name;
`

const Tag = styled.p.attrs({
  'data-testid': 'tag',
})`
  grid-row: 1;
  grid-column: tag;
  margin-left: 0.625rem;
`

const SubMessage = styled.p.attrs({
  'data-testid': 'sub-message',
})`
  grid-area: submessage;
  padding: 0.625rem;
`

const Dump = styled.p.attrs({
  'data-testid': 'dump',
})`
  grid-area: dump;
  padding: 0.625rem;
  font-family: monospace;
`

const LogMessage = styled.p.attrs({
  'data-testid': 'message',
})`
  grid-row: 1;
  grid-column: message;
  hyphens: auto;
  overflow-wrap: break-word;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
`

const LogContainer = styled.div`
  position: relative;
  background-color: ${primaryBackgroundColor};
  margin-bottom: 2rem;
  overflow: auto;
`

const StyledList = styled.ul`
  margin-top: 0.625rem;
  background-color: ${primaryBackgroundColor};
  li:nth-of-type(2n) {
    background-color: ${contrastBackgroundColor};
  }
  width: 62rem;
`

const CodeBlock = styled.pre`
  white-space: pre-wrap;
  word-break: break-all;
  background: ${contrastBackgroundColor};
  border: 0.075rem solid ${bodySecondaryColor};
  padding: 1rem;
  margin: 0.1875rem 0;
`

export default LogView
