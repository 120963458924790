declare global {
  interface Window {
    barcode: {
      scan: (barcode: string) => void
    }
  }
}

// A few example barcode strings that can be used as GS1 DataMatrix
//
// - NDC: 68462-130-05
// Create order ndc: 68462-0130-05
// - 01003684621300562182XCCW6G3W54{GS}172401311019210983
//   - (01)00368462130056
//   - (21)82XCCW6G3W54
//   - (17)240131
//   - (10)19210983
//
// - NDC: 16729-170-17
// - 010031672917017821NX82BDVRB04G74{GS}1723093010P2005721
//   - (01)00316729170178
//   - (21)NX82BDVRB04G74
//   - (17)230930
//   - (10)P2005721
//
// - NDC: 35573-400-99
// - 010033557340099621AH54K6G0X8KM2FA{GS}1722103110NS0120103-A
//   - (01)00335573400996
//   - (21)AH54K6G0X8KM2FA
//   - (17)221031
//   - (10)NS0120103-A
//
// Refer to scanner.ts#parseGS1String() for more details

window.barcode = {
  scan: (barcodeString: string) => {
    console.warn(`[Fake Barcode Scanner] - faking barcode input for: ${barcodeString}`)

    // Since special characters can't be typed in dev tools, we use a placeholder string
    barcodeString = barcodeString.replaceAll('{GS}', String.fromCharCode(29))

    barcodeString.split('').forEach(key => {
      let ctrlKey = false
      if (key.charCodeAt(0) === 29) {
        // 2D scanners can be configured to replace special characters.
        //
        // We choose a configuration that replaces special characters
        // with a single character + a meta key to simplify implementation
        // (see useBarcodeScanner.ts)
        //
        // GS (Group Separator) is replaced with Ctrl+]
        key = ']'
        ctrlKey = true
      }
      document.dispatchEvent(new KeyboardEvent('keydown', { key, ctrlKey }))
    })
    document.dispatchEvent(new KeyboardEvent('keydown', { key: 'Enter' }))
  },
}

export {}
