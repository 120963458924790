import { OutboundTransferStatus } from '@truepill/tpos-types'
import AddressEntry from 'components/AddressEntry'
import { RXCenterCell, ListRowLabel, ListRowValue } from 'components/RXTable'
import { Box } from 'grommet'
import styled from 'styled-components'
import { contrastBackgroundColor } from 'styles/styleVariables'
import type { OutboundTransfer } from 'types'
import { checkIfProvided, formatPhoneNumber } from 'utils'

type OutboundTransferDetailsProps = {
  outboundTransfer: OutboundTransfer
}

const OutboundTransferDetails = ({ outboundTransfer }: OutboundTransferDetailsProps): JSX.Element => {
  return (
    <Box height="auto" overflow="auto" id="OutboundTransferDetails">
      <StyledRXCenterCell data-test-row="pharmacy">
        <ListRowLabel>Pharmacy:</ListRowLabel>
        <ListRowValue>{outboundTransfer.transferTo.pharmacy}</ListRowValue>
      </StyledRXCenterCell>
      <StyledRXCenterCell data-test-row="npi">
        <ListRowLabel>NPI:</ListRowLabel>
        <ListRowValue>{outboundTransfer.transferTo.pharmacyNPI ?? 'None provided'}</ListRowValue>
      </StyledRXCenterCell>
      <StyledRXCenterCell data-test-row="phone">
        <ListRowLabel>Phone:</ListRowLabel>
        <ListRowValue>{checkIfProvided(formatPhoneNumber(outboundTransfer.transferTo.phone))}</ListRowValue>
      </StyledRXCenterCell>
      <StyledRXCenterCell data-test-row="fax">
        <ListRowLabel>Fax:</ListRowLabel>
        <ListRowValue>{checkIfProvided(formatPhoneNumber(outboundTransfer.transferTo.fax))}</ListRowValue>
      </StyledRXCenterCell>
      <StyledRXCenterCell data-test-row="address">
        <ListRowLabel>Address:</ListRowLabel>
        <ListRowValue>
          <AddressEntry data-private address={outboundTransfer.transferTo.address} />
        </ListRowValue>
      </StyledRXCenterCell>
      {outboundTransfer.status === OutboundTransferStatus.Complete ? (
        <StyledRXCenterCell data-test-row="transferring-pharmacist">
          <ListRowLabel>Transferring pharmacist:</ListRowLabel>
          <ListRowValue>
            {outboundTransfer.completedByUser?.firstName ?? ''} {outboundTransfer.completedByUser?.lastName ?? ''}
          </ListRowValue>
        </StyledRXCenterCell>
      ) : (
        <></>
      )}
      <StyledRXCenterCell data-test-row="receiving-pharmacist">
        <ListRowLabel>Receiving pharmacist:</ListRowLabel>
        <ListRowValue>{outboundTransfer.transferTo.pharmacistName ?? 'None provided'}</ListRowValue>
      </StyledRXCenterCell>
    </Box>
  )
}

const StyledRXCenterCell = styled(RXCenterCell)`
  margin-left: 1rem;
  padding-left: 1rem;
  :nth-of-type(even) {
    background-color: ${contrastBackgroundColor};
  }
`

export default OutboundTransferDetails
