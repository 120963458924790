import { useCallback, useEffect, useMemo, useState } from 'react'
import { useMutation, useQuery } from '@truepill/tpos-react-router'
import type { StockInfo } from '@truepill/tpos-types'
import { LaunchDarkly, NdcFullInfoSource } from '@truepill/tpos-types'
import CriticalMedicalNoteWarning from 'components/CriticalMedicalNoteWarning'
import DrugImageImprint from 'components/DrugImageImprint'
import { ActionButton } from 'components/PageStructure'
import RXScanImage from 'components/RXScanImage'
import {
  ImageFilledHeader,
  ListRowLabel,
  ListRowValue,
  RightFilledHeader,
  RXCenterCell,
  RXCenterColumn,
  RXImageCell,
  RXLeftCellColumn,
  RXListRow,
  RXRightCellVerticalOverflow,
  RXTable,
  RXTitleRow,
  StyledRXImageCell,
  TableContainer,
} from 'components/RXTable'
import { CREATE_LOG, GET_NDC_FULL_INFO } from 'gql'
import { FulfillmentQueueName } from 'hooks/useFulfillmentQueue'
import useHotKey, { HotKeyLevel } from 'hooks/useHotKey'
import useRxImageIsPic from 'hooks/useRxImageIsPic'
import LotQuantityFillModal from 'modals/LotQuantityFillModal'
import ManualEntryFillModal from 'modals/ManualEntryFillModal'
import type { StockInfoWithoutQuantity } from 'modals/ManualEntryFillModal'
import { useFlag, useClient } from 'providers/LaunchDarklyProvider'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import { useTaskContext } from 'providers/TaskProvider'
import { usePlusClient } from 'providers/VisionRouter'
import styled from 'styled-components'
import { borderColor, primaryBackgroundColor } from 'styles/styleVariables'
import type { Fill, Order, Prescription, NdcFullInfo } from 'types'
import { getNdcText, getPackageMeasureDescription, getRxImagePrescription } from 'utils'
import { getFormattedInventoryGroupName } from 'utils/inventoryGroup'
import ScanTasks from './ScanTasks'
import ScanTasksV2 from './ScanTasksV2'
import useFirstScanType, { BarcodeTypes } from './useFirstScanType'

type DisplayFillPageProps = {
  prescription: Prescription
  fill: Fill
  order: Order
  alternateBarCodes: string[]
}

const DisplayFillPage = ({ prescription, fill, order, alternateBarCodes }: DisplayFillPageProps): JSX.Element => {
  const [rxImageIsPic, setRxImageIsPic] = useRxImageIsPic()
  const [stockInfo, setStockInfo] = useState<Array<StockInfo>>([])
  const [requireModalQuantityFilled, setRequireModalQuantityFilled] = useState(false)
  const [modalQuantityShown, setModalQuantityShown] = useState(false)
  const [manualEntryFilled, setManualEntryFilled] = useState(false)
  const { showModal } = useModalContext()
  const { tasks, completeTask, scanProductsTasksCompleted, nextProductScanTaskIndex } = useTaskContext()
  const { routeTo } = usePlusClient()
  const [createLog] = useMutation(CREATE_LOG, {
    refetchQueries: ['getAllLogs'],
  })

  const tempDisplayPackSizeWithNdc = useFlag(LaunchDarkly.FeatureFlags.TEMP_DISPLAY_PACK_SIZE_WITH_NDC)
  const hypenhateNdc = useFlag(LaunchDarkly.FeatureFlags.TEMP_HYPHENATE_NDCS)

  const ndcs =
    !!fill?.dispensed.ndc && prescription.ndc !== fill.dispensed.ndc
      ? [prescription.ndc, fill.dispensed.ndc]
      : [prescription.ndc]

  const { data } = useQuery(GET_NDC_FULL_INFO, {
    variables: { ndcs },
  })

  const results: NdcFullInfo[] = data?.getNdcFullInfo || []
  let prescribedNdcFullInfo, prescribedNdcPackage, dispensedNdcPackage

  for (const fullInfo of results) {
    for (const ndcPackage of fullInfo.packages) {
      if (ndcPackage.ndc === prescription.ndc) {
        prescribedNdcPackage = ndcPackage
        prescribedNdcFullInfo = fullInfo
      }
      if (ndcPackage.ndc === fill?.dispensed.ndc) {
        dispensedNdcPackage = ndcPackage
      }
    }
  }

  const prescribedNdcText = getNdcText(prescribedNdcPackage, tempDisplayPackSizeWithNdc, hypenhateNdc)
  const dispensedNdcText = getNdcText(dispensedNdcPackage, tempDisplayPackSizeWithNdc, hypenhateNdc)

  const { firstScanType, setFirstScanType, resetFirstScanType } = useFirstScanType()

  const isHumanRX = (prescribedNdcFullInfo?.source || NdcFullInfoSource.Medispan) === NdcFullInfoSource.Medispan

  const tasksInitialized = useMemo(() => !!tasks.every(({ completed }) => !completed), [tasks])

  const resetStates = useCallback(() => {
    setStockInfo([])
    resetFirstScanType()
    setRequireModalQuantityFilled(false)
    setModalQuantityShown(false)
    setManualEntryFilled(false)
  }, [resetFirstScanType])

  useEffect(() => {
    // reset states when fill changes
    resetStates()
  }, [resetStates, fill])

  useEffect(() => {
    // reset states when tasks are initialized
    if (tasksInitialized) {
      resetStates()
    }
  }, [resetStates, tasksInitialized])

  // TEMP HACKY DEV STUFF FOR TESTING
  useHotKey('x', HotKeyLevel.normal, () => setRxImageIsPic(!rxImageIsPic))

  const rxFillRequest = order.rxFillRequests?.find(i => i.fillId === fill._id)
  const inParataWorkflow = rxFillRequest?.status === 'Automation'

  const { escript, directTransfer } = prescription

  const inventoryGroup = getFormattedInventoryGroupName(fill)

  const rxImagePrescription = getRxImagePrescription(escript, directTransfer?.prescription)

  // Feature flag displayScanTasksV2 depends on customer and location
  // Current rule in development env is set to evaluate true if the customer is Hims and accepts all locations
  const customer = order.customer
  const location = order.location
  const dispensedNdc = fill.dispensed.ndc

  // Set the customer, location and ndc for feature-flag check
  const { client: launchDarklyClient } = useClient()

  useEffect(() => {
    if (launchDarklyClient && customer && location) {
      launchDarklyClient.identify({
        key: customer.legacyId.toString(),
        custom: {
          location: location.legacyId.toString(),
          ndc: dispensedNdc.toString(),
        },
      })
    }
  }, [launchDarklyClient, customer, location, dispensedNdc])

  // If the feature flag displayScanTasksV2 is on, then we will display ScanTasksV2 and be able to scan datamatrix barcodes
  const displayScanTasksV2 = useFlag(LaunchDarkly.FeatureFlags.REQUIRE_USER_TO_SCAN_DATAMATRIX_DURING_FILL)
  const allowLinearScanFlag = useFlag(LaunchDarkly.FeatureFlags.ALLOW_LINEAR_BARCODE_SCANNING)

  const allowLinearScan = allowLinearScanFlag && !isHumanRX

  const fillLabelScanned = useMemo(() => !!tasks.find(({ key }) => key === 'scanFill')?.completed, [tasks])

  const packageCount = fill.dispensed.packagesRequired || 1
  const lastProductIndex = packageCount - 1

  const isUnbreakable = useMemo(
    () => Boolean(!tasks.find(({ key }) => key === `scanProduct-${lastProductIndex}`)?.subtasks?.length),
    [tasks, lastProductIndex],
  )

  const quantity = fill.dispensed.quantity / packageCount

  const handleModalSubmit = ({ lot, expirationDate, serialNumber }: StockInfoWithoutQuantity) => {
    if (
      firstScanType === BarcodeTypes.twoDimensional ||
      (!firstScanType && displayScanTasksV2 && lot && expirationDate)
    ) {
      setFirstScanType(BarcodeTypes.twoDimensional)
      setStockInfo((prevStockInfo: StockInfo[]) => {
        return [
          ...prevStockInfo,
          {
            lot,
            expirationDate,
            serialNumber,
            quantity: scanProductsTasksCompleted ? 0 : quantity,
          },
        ]
      })
    } else setFirstScanType(BarcodeTypes.oneDimensional)

    setManualEntryFilled(true)
    createLog({
      variables: {
        fillId: fill._id,
        message: `NDC manual entry for ${fill.dispensed.ndc} was used${
          displayScanTasksV2 && lot && expirationDate
            ? `, lot number "${lot}", expiration date "${expirationDate}"${
                serialNumber ? `, serial number "${serialNumber}"` : ''
              }`
            : ''
        }`,
        event: 'ndc manual entry',
      },
    })
    nextProductScanTaskIndex >= 0 && completeTask(`scanProduct-${nextProductScanTaskIndex}`, fill.dispensed.ndc)
  }

  const stockInfoHasDifferentLots: boolean = useMemo(() => {
    if (isUnbreakable || stockInfo.length === 0 || !scanProductsTasksCompleted) return false
    const firstLot = stockInfo[0].lot
    return !stockInfo.every(({ lot }) => lot === firstLot)
  }, [isUnbreakable, stockInfo, scanProductsTasksCompleted])

  useEffect(() => {
    if (
      stockInfoHasDifferentLots &&
      !modalQuantityShown &&
      (fill.dispensed.quantity % fill.dispensed.quantityPerPackage !== 0 ||
        stockInfo.some(product => product.quantity === 0))
    ) {
      setRequireModalQuantityFilled(true)
    }
  }, [
    stockInfoHasDifferentLots,
    modalQuantityShown,
    fill.dispensed.quantity,
    fill.dispensed.quantityPerPackage,
    stockInfo,
  ])

  useEffect(() => {
    if (
      displayScanTasksV2 &&
      !modalQuantityShown &&
      requireModalQuantityFilled &&
      stockInfoHasDifferentLots &&
      tasks.length >= 3 &&
      tasks.every(({ completed }) => completed)
    ) {
      showModal(() => (
        <LotQuantityFillModal
          quantity={fill.dispensed.quantity}
          stockInfo={stockInfo}
          confirmationCallback={(stockInfoWithManualQuantities: StockInfo[]) => {
            setStockInfo(stockInfoWithManualQuantities)
            setRequireModalQuantityFilled(false)
          }}
          cancelCallback={() => {
            const fulfillmentQueueName = FulfillmentQueueName.Fill
            routeTo.fulfillment(order._id, fill._id, fulfillmentQueueName).now()
          }}
        />
      ))
      setModalQuantityShown(true)
    }
  }, [
    requireModalQuantityFilled,
    stockInfoHasDifferentLots,
    modalQuantityShown,
    tasks,
    stockInfo,
    showModal,
    quantity,
    displayScanTasksV2,
    order._id,
    fill._id,
    routeTo,
    fill.dispensed.quantity,
  ])

  return (
    <TableContainer>
      <RXTitleRow>
        <StyledRXImageCell noBorder>
          <ImageFilledHeader>Rx Image</ImageFilledHeader>
        </StyledRXImageCell>
        <RXCenterColumn>
          <RightFilledHeader>Fill Medication</RightFilledHeader>
        </RXCenterColumn>
        <RXLeftCellColumn> {!inParataWorkflow && <RightFilledHeader>Tasks</RightFilledHeader>}</RXLeftCellColumn>
      </RXTitleRow>
      <RXTable>
        <RXListRow>
          {rxImageIsPic ? (
            <StyledRXImageCell noColorBackground closeOffTopBorder>
              <RXScanImage prescription={prescription} />
            </StyledRXImageCell>
          ) : (
            <StyledRXImageCell closeOffTopBorder>
              <ListRowLabel>Medication:</ListRowLabel>
              <ListRowValue>{rxImagePrescription?.drugDescription ?? prescription.name}</ListRowValue>
            </StyledRXImageCell>
          )}
          <RXCenterCell>
            <ListRowLabel>Medication:</ListRowLabel>
            <ListRowValue>
              <DrugNameWithWarning>
                {fill.dispensed.name}
                <CriticalMedicalNoteWarning ndc={fill.dispensed.ndc} />
              </DrugNameWithWarning>
            </ListRowValue>
          </RXCenterCell>
          {!inParataWorkflow && (
            <RXRightCellVerticalOverflow>
              {displayScanTasksV2 ? (
                <ScanTasksV2
                  prescription={prescription}
                  fill={fill}
                  order={order}
                  stockInfo={stockInfo}
                  setStockInfo={setStockInfo}
                  requireModalQuantityFilled={requireModalQuantityFilled}
                  alternateBarCodes={alternateBarCodes}
                  allowLinearScan={allowLinearScan}
                  firstScanType={firstScanType}
                  setFirstScanType={setFirstScanType}
                  manualEntryFilled={manualEntryFilled}
                />
              ) : (
                <ScanTasks
                  prescription={prescription}
                  fill={fill}
                  order={order}
                  alternateBarCodes={alternateBarCodes}
                  manualEntryFilled={manualEntryFilled}
                />
              )}
            </RXRightCellVerticalOverflow>
          )}
        </RXListRow>
        <RXListRow>
          <RXImageCell>
            <ListRowLabel>NDC:</ListRowLabel>
            <ListRowValue>{prescribedNdcText}</ListRowValue>
          </RXImageCell>
          <RXCenterCell>
            <ListRowLabel>NDC:</ListRowLabel>
            <CenteredListRowValue>
              {dispensedNdcText} {inventoryGroup}
              <ManualEntryButton
                data-testid="manualEntry"
                disabled={inParataWorkflow || !fillLabelScanned || (scanProductsTasksCompleted && isUnbreakable)}
                onClick={() =>
                  showModal(() => (
                    <ManualEntryFillModal
                      ndc={fill.dispensed.ndc}
                      stockInfo={stockInfo}
                      requireStockInfo={
                        firstScanType === BarcodeTypes.twoDimensional || (displayScanTasksV2 && !allowLinearScan)
                      }
                      disableStockInfo={firstScanType === BarcodeTypes.oneDimensional}
                      confirmationCallback={handleModalSubmit}
                    />
                  ))
                }
              >
                Manual Entry
              </ManualEntryButton>
            </CenteredListRowValue>
          </RXCenterCell>
        </RXListRow>
        <RXListRow>
          <RXImageCell>
            <ListRowLabel />
            <ListRowValue />
          </RXImageCell>
          <RXCenterCell>
            <ListRowLabel>Mfg:</ListRowLabel>
            <ListRowValue>{fill.dispensed.manufacturer}</ListRowValue>
          </RXCenterCell>
        </RXListRow>
        <RXListRow>
          <RXImageCell>
            <ListRowLabel>Qty:</ListRowLabel>
            <ListRowValue>{rxImagePrescription?.quantity ?? prescription.quantity}</ListRowValue>
          </RXImageCell>
          <RXCenterCell>
            <ListRowLabel>Qty:</ListRowLabel>
            <ListRowValue>
              {fill.dispensed.quantity} {getPackageMeasureDescription(fill?.dispensed?.packageSizeUnitOfMeasure)}
            </ListRowValue>
          </RXCenterCell>
        </RXListRow>
        <RXListRow>
          <RXImageCell>
            <ListRowLabel>DS:</ListRowLabel>
            <ListRowValue>{rxImagePrescription?.daysSupply ?? prescription.daysSupply}</ListRowValue>
          </RXImageCell>
          <RXCenterCell>
            <ListRowLabel>DS:</ListRowLabel>
            <ListRowValue>{fill.dispensed.daysSupply}</ListRowValue>
          </RXCenterCell>
        </RXListRow>
        <RXListRow>
          <RXImageCell />
          <RXCenterCell>
            <ListRowLabel>Labels printed:</ListRowLabel>
            <ListRowValue>{fill.labelsPrinted}</ListRowValue>
          </RXCenterCell>
        </RXListRow>
        <RXListRow>
          <RXImageCell />
          <RXCenterCell>
            <ListRowLabel>Container:</ListRowLabel>
            <ListRowValue>-</ListRowValue>
          </RXCenterCell>
        </RXListRow>
        <DrugImageImprint ndc={fill.dispensed.ndc} closeOffBottomBorder />
      </RXTable>
    </TableContainer>
  )
}

const ManualEntryButton = styled(ActionButton)`
  background-color: ${primaryBackgroundColor};
  border: 0.125rem solid ${borderColor};
  border-radius: 0.25rem;
  height: 2rem;
  text-align: center;
`

const CenteredListRowValue = styled(ListRowValue)`
  align-items: center;
`

const DrugNameWithWarning = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 0.25rem;
`

export default DisplayFillPage
