import { LaunchDarkly } from '@truepill/tpos-types'
import { useFlag } from 'providers/LaunchDarklyProvider'
import hyphenateNdc from 'utils/hyphenateNdc'

const HyphenatedNdc = ({ ndc }: { ndc: string | null }) => {
  const hyphenate = useFlag(LaunchDarkly.FeatureFlags.TEMP_HYPHENATE_NDCS)

  return <>{hyphenate ? hyphenateNdc(ndc ?? '') : ndc}</>
}
export default HyphenatedNdc
