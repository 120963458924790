import { useQuery } from '@truepill/tpos-react-router'
import LoadingSpinner from 'components/Loading'
import { FilledHeader } from 'components/PageStructure'
import { RXCenterCell, TwoColumnDivision, ListRowLabel, ListRowValue } from 'components/RXTable'
import styled from 'styled-components'
import { contrastBackgroundColor } from 'styles/styleVariables'
import type { Patient } from 'types'
import { wordyOrdinal } from 'utils'
import { gql } from '../gql/generated'
import type { FragmentType } from '../gql/generated/fragment-masking'
import { getFragmentData } from '../gql/generated/fragment-masking'

const PatientCoverageInfo_InsuranceFragment = gql(`
  fragment PatientCoverageInfo_InsuranceFragment on Insurance {
    _id
    group
    bin
    cardholderId
  }
`)

const PatientCoverageInfo_PatientFragment = gql(`
  fragment PatientCoverageInfo_PatientFragment on Patient {
    _id
    insurances {
      ...PatientCoverageInfo_InsuranceFragment
    }
  }
`)

const GET_PATIENT_QUERY = gql(`
  query getPatientCoverageInfo($patientId: ID!) {
    getPatient(_id: $patientId) {
      ...PatientCoverageInfo_PatientFragment
    }
  }
`)

type CoverageInfoProps = { patientId: Patient['_id']; className?: string }

const CoverageInfo = ({ className, patientId }: CoverageInfoProps): JSX.Element => {
  const { data, loading, error } = useQuery(GET_PATIENT_QUERY, {
    variables: { patientId },
  })

  if (error) {
    return (
      <CoverageInfoContainer className={className}>
        <p>Error loading Coverage Information for patient {JSON.stringify(error)}</p>
      </CoverageInfoContainer>
    )
  }

  if (loading || !data?.getPatient) {
    return (
      <CoverageInfoContainer className={className}>
        <LoadingSpinnerContainer>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      </CoverageInfoContainer>
    )
  }

  const patient = getFragmentData(PatientCoverageInfo_PatientFragment, data.getPatient)
  const { insurances } = patient

  return (
    <CoverageInfoContainer className={className}>
      <Columns>
        <PatientColumn>
          <FilledHeader>Information</FilledHeader>
        </PatientColumn>
        <ImageColumn>
          <FilledHeader>Image</FilledHeader>
        </ImageColumn>
      </Columns>
      <StyledColumn>
        {(insurances ?? []).map((insurance, i) => {
          const _insurance = getFragmentData(PatientCoverageInfo_InsuranceFragment, insurance)
          return (
            <CoverageCell key={'insurance ' + _insurance?._id}>
              <StyledListRowLabel>{wordyOrdinal(i + 1)} payer</StyledListRowLabel>
              <StyledListRowValue>{insurance && <InsuranceEntry insurance={insurance} />}</StyledListRowValue>
              <StyledListRowImage>
                <ImageEntry />
              </StyledListRowImage>
            </CoverageCell>
          )
        })}
      </StyledColumn>
    </CoverageInfoContainer>
  )
}

const CoverageCell = styled(RXCenterCell)`
  grid-template-columns: [label] 8rem [value] 17rem [image];
  padding-left: 0.3125rem;
  padding-top: 1rem;
`

const StyledListRowLabel = styled(ListRowLabel)`
  line-height: 1rem;
  font-size: 0.875rem;
  padding-top: 0.125rem;
`

const StyledListRowValue = styled(ListRowValue)`
  padding-left: 1.25rem;
`

const InsuranceEntry = (props: {
  insurance: FragmentType<typeof PatientCoverageInfo_InsuranceFragment>
}): JSX.Element => {
  const _insurance = getFragmentData(PatientCoverageInfo_InsuranceFragment, props.insurance)
  const { group, cardholderId, bin } = _insurance
  return (
    <StyledInsuranceEntry>
      {/* <li>
        <strong>Insurance:</strong> {provider.name}
      </li> */}
      <li>
        <strong>Group #:</strong> {group}
      </li>
      <li>
        <strong>Member ID:</strong> {cardholderId}
      </li>
      <li>
        <strong>RxBIN:</strong> {bin}
      </li>
      {/* <li>
        <strong>RxPCN:</strong> {pcn}
      </li> */}
    </StyledInsuranceEntry>
  )
}

const ImageEntry = (): JSX.Element => {
  return (
    <StyledInsuranceEntry>
      <li>No image provided</li>
    </StyledInsuranceEntry>
  )
}

const StyledInsuranceEntry = styled.ul`
  li {
    line-height: 1.25rem;
  }
`

const Columns = styled.div`
  ${TwoColumnDivision}
  grid-gap: 1.25rem;
`

const CoverageInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledColumn = styled.ul`
  > :nth-child(2n) {
    background-color: ${contrastBackgroundColor};
  }
`

const PatientColumn = styled(StyledColumn)`
  grid-column: center;
  width: 25rem;
`

const ImageColumn = styled(StyledColumn)`
  grid-column: right;
  width: 25rem;
`

const StyledListRowImage = styled(StyledListRowValue)`
  grid-row: 1;
  grid-column: image;
  display: flex;
  align-items: flex-stretch;
  > button {
    flex: 1;
  }
`

const LoadingSpinnerContainer = styled.div`
  display: flex;
  padding-top: 0.8rem;
  justify-content: center;
  svg {
    height: 25px;
  }
`

export default CoverageInfo
