import { Link, useLocation } from '@truepill/tpos-react-router'
import AddressEntry from 'components/AddressEntry'
import LoadingSpinner from 'components/Loading'
import { FilledHeadingStyle } from 'components/PageStructure'
import moment from 'moment'
import { goToViewPrescriber } from 'routes'
import styled from 'styled-components'
import EllipsisTruncate from 'styles/EllipsisTruncate'
import { subduedColor, contrastColor, contrastBackgroundColor, primaryColorLight } from 'styles/styleVariables'
import type { Prescriber } from 'types'
import { checkIfProvided, formatPhoneNumber } from 'utils'

type PrescribersTableProps = {
  prescribers?: Prescriber[]
  searchTermGiven?: boolean
  loading?: boolean
}

const PrescribersTable = ({ prescribers = [], searchTermGiven, loading }: PrescribersTableProps): JSX.Element => {
  const noPrescribersText = searchTermGiven ? 'No results' : 'Enter a search term to find prescribers'

  if (loading) {
    return (
      <PrescriberLoadingContainer>
        <TitleRow />
        <LoadingSpinnerBox>
          <LoadingSpinner />
        </LoadingSpinnerBox>
      </PrescriberLoadingContainer>
    )
  }

  return (
    <PrescriberContainer>
      <TitleRow />
      {prescribers.map(prescriber => (
        <PrescriberEntry key={prescriber._id} {...prescriber} />
      ))}
      {prescribers.length === 0 && <NoResultsEntry>{noPrescribersText}</NoResultsEntry>}
    </PrescriberContainer>
  )
}

const TitleRow = (): JSX.Element => {
  return (
    <TitlePrescriberRow>
      <LastName>Last name</LastName>
      <FirstName>First name</FirstName>
      <NPI>NPI/License #</NPI>
      {/* <Specialty>Specialty</Specialty> */}
      <AddressCell>Primary Address</AddressCell>
      <Phone>Phone</Phone>
      <Created>Created</Created>
    </TitlePrescriberRow>
  )
}

const PrescriberEntry = ({
  firstName,
  lastName,
  suffix,
  npi,
  stateLicense,
  addresses,
  contacts,
  createdAt,
  _id,
}: Prescriber): JSX.Element => {
  const { search } = useLocation()
  const prescriberCreated = createdAt ? moment(createdAt, 'x').format('MM/DD/YYYY') : ''

  return (
    <StyledLink to={goToViewPrescriber({ prescriberId: _id, search })}>
      <PrescriberRow>
        <LastName>
          {lastName}
          {suffix ? `, ${suffix}` : ''}
        </LastName>
        <FirstName>{firstName}</FirstName>
        <NPI>{npi || stateLicense}</NPI>
        {/* <Specialty>{props.primarySpecializationText}</Specialty> */}
        <AddressCell>
          <AddressEntry address={addresses[0]} inline />
        </AddressCell>
        <Phone>{checkIfProvided(formatPhoneNumber(contacts?.phone))}</Phone>
        <Created>{prescriberCreated}</Created>
      </PrescriberRow>
    </StyledLink>
  )
}

const PrescriberCell = styled.li`
  grid-row: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 0.3125rem;
  margin-right: 0.3125rem;
`

const StyledLink = styled(Link)`
  :hover {
    background-color: ${primaryColorLight};
    :nth-of-type(2n + 1) {
      background-color: ${primaryColorLight};
    }
  }
  :nth-of-type(2n + 1) {
    background-color: ${contrastBackgroundColor};
  }
`
const PrescriberContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  :last-child {
    border-bottom: 0.25rem solid ${contrastColor};
    border-radius: 0.25rem;
  }
  flex: 1;
`

const PrescriberLoadingContainer = styled(PrescriberContainer)`
  :last-child {
    border-bottom: none;
    border-radius: 0;
  }
`

const NoResultsEntry = styled.p`
  font-size: 1rem;
  color: ${subduedColor};
  padding: 1rem;
  width: 100%;
  text-align: center;
`

const PrescriberRow = styled.ul`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns:
    [lastName] 1fr [firstName] 1fr [npi] minmax(7rem, 8rem)
    [address] 2fr [phone] minmax(8rem, 15rem)
    [created] minmax(7rem, 15rem);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
`

const TitlePrescriberRow = styled(PrescriberRow)`
  ${FilledHeadingStyle}
  font-weight: 500;
  padding-left: 0rem;
  padding-right: 0rem;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  border-radius: 0.25rem 0.25rem 0rem 0rem;
`

const LastName = styled(PrescriberCell)`
  grid-column: lastName;
  ${EllipsisTruncate};
`
const FirstName = styled(PrescriberCell)`
  grid-column: firstName;
  ${EllipsisTruncate};
`
const NPI = styled(PrescriberCell)`
  grid-column: npi;
  ${EllipsisTruncate};
`

// const Specialty = styled(PrescriberCell)`
//   text-transform: capitalize;
//   grid-column: specialty;
// `

const Phone = styled(PrescriberCell)`
  grid-column: phone;
  ${EllipsisTruncate};
`

const AddressCell = styled(PrescriberCell)`
  grid-column: address;
  ${EllipsisTruncate};
`
const Created = styled(PrescriberCell)`
  grid-column: created;
  ${EllipsisTruncate};
`

const LoadingSpinnerBox = styled.div`
  display: flex;
  padding-top: 4rem;
  justify-content: center;
  svg {
    height: 126px;
  }
`

export default PrescribersTable
