import { Species } from '@truepill/tpos-types'

interface EntityWithSpecies {
  species?: Species
}

/**
 * Checks that an entity that has a species is or is not a pet
 * @param e Entity to be checked
 * @returns true if the entity is a pet otherwise false
 */
const isPet = (e: EntityWithSpecies): boolean => !!e.species && e.species !== Species.Human

export default isPet
