import { BinCodes } from '@truepill/tpos-types'
import type { RXFillRequest, Order } from 'types'
import { PaymentMethod, PaymentType } from 'types'

export const getPaymentMethod = (paymentType: PaymentType): PaymentMethod =>
  paymentType === PaymentType.cash ? PaymentMethod.cash : PaymentMethod.copay

export const getCopayTotalCost = (rxFillRequests: RXFillRequest[] = []): number => {
  return rxFillRequests.reduce((total, request) => {
    return total + (request?.fill?.claimSummary?.copay ?? 0)
  }, 0)
}

/**
 *  If the order paymentType is “cash” but orders.insurances.bin is the cash BIN (025573),
 *  then display “Cash” but calculate the value as if it were an insurance order
 *  (sum of fills.claimSummary.copay for all the fills on the order) - JR-18830
 */
export const getCashTotalCost = ({
  pricing,
  bins = [],
  rxFillRequests = [],
}: {
  pricing: Order['pricing']
  bins?: string[]
  rxFillRequests?: RXFillRequest[]
}): number => {
  return bins.includes(BinCodes.CASH) ? getCopayTotalCost(rxFillRequests) : pricing?.total ?? 0
}

export const getTotalCost = ({
  paymentType = PaymentType.cash,
  pricing,
  rxFillRequests = [],
  bins = [],
}: {
  paymentType?: PaymentType
  pricing: Order['pricing']
  rxFillRequests?: RXFillRequest[]
  bins?: string[]
}) => {
  return getPaymentMethod(paymentType) === PaymentMethod.cash
    ? getCashTotalCost({ pricing, bins, rxFillRequests })
    : getCopayTotalCost(rxFillRequests)
}
