import { Header } from '@truepill/react-capsule'
import { ReactComponent as TreeIcon } from 'assets/icons/git-branch.svg'
import DispensingDecisionView from 'components/DispensingDecision'
import IconWrapper from 'components/IconWrapper'
import { ModalWrapper, ModalHeader } from 'components/Modal'
import { bodyPrimaryColor } from 'styles/styleVariables'
import type { Fill, Prescription } from 'types'

type DispensingDecisionModalProps = {
  prescription: Prescription
  fillId: Fill['_id']
}

const DispensingDecisionModal = (props: DispensingDecisionModalProps): JSX.Element => {
  const { prescription, fillId } = props

  return (
    <ModalWrapper id="DispensingDecisionModal">
      <ModalHeader>
        <IconWrapper>
          <TreeIcon fill={bodyPrimaryColor} />
        </IconWrapper>
        <Header variant="2xl">Dispensing decision</Header>
      </ModalHeader>
      <DispensingDecisionView prescription={prescription} fillId={fillId} />
    </ModalWrapper>
  )
}

export default DispensingDecisionModal
