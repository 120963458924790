import { useState, useEffect } from 'react'

/**
 * Debounces `value`, updating after each interval `delay`. This is useful for
 * cases where want to use internal state that updates immediately, but only
 * re-query an API intermittently.
 */
const useDebouncedValue = <T>(value: T, delay = 500): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [delay, value])

  return debouncedValue
}

export default useDebouncedValue
