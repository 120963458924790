import type { QueryResult } from '@truepill/tpos-react-router'
import { useQuery } from '@truepill/tpos-react-router'
import { LIST_ORDERS } from 'gql'
import type { Order } from 'types'

export interface GetOrdersParams {
  customerIds?: string[]
  locationId?: string
  paymentType?: string
  selectedLocationIds?: string[]
  inTriage?: boolean
  triageReasons?: string[]
  orderStages?: string[]
  pageNumber?: number
  pageSize?: number
  ndcs?: string[]
  patientName?: string
  patientDob?: string
  drugName?: string
  hasNoFills?: boolean
  orderNumber?: number
  includeTriage?: boolean
  customerRef?: string
  rxFillCode?: string
  sortField?: string
  sort?: number
  coreOrderToken?: string
  coreFillRequestToken?: string
  states?: string[]
  transactionId?: string
  lastFourDigits?: string
}

export interface GetOrdersResult {
  getOrders: {
    currentPage: number
    totalRecords: number
    orders: Order[]
  }
}

const useOrders = (orderQueryVariables: GetOrdersParams): QueryResult<GetOrdersResult, GetOrdersParams> => {
  return useQuery<GetOrdersResult, GetOrdersParams>(LIST_ORDERS, {
    variables: orderQueryVariables,
    fetchPolicy: 'network-only',
  })
}

export default useOrders
