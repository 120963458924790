import { useState } from 'react'
import type { PrescriberAddress } from '@truepill/tpos-types'
import type { Option } from 'components/Select'
import Select from 'components/Select'
import { Box } from 'grommet'
import useEditMode from 'hooks/useEditMode'
import { useFormData } from 'hooks/useFormData'
import useHotKey, { HotKeyLevel } from 'hooks/useHotKey'
import { useTPCacheContext } from 'providers/TPCacheProvider'
import { usePlusClient } from 'providers/VisionRouter'
import styled from 'styled-components/macro'
import { PrescriptionOrigin } from 'types'
import type { EscriptPrescriber, EscriptSupervisor, Prescriber, Prescription } from 'types'
import {
  checkIfAddressChanged,
  checkIfProvided,
  formatPhoneNumber,
  getPrescriberAddress,
  getRxImagePrescriber,
  getRxImagePrescriberManualEntry,
} from 'utils'
import type { PrescriberField } from 'utils/compare'
import { normalizeProviderType } from 'utils/prescriber'
import AddressEntry from './AddressEntry'
import CustomerCard from './CustomerCard'
import CustomerSearch from './CustomerSearch'
import DropDownOption from './DropDownOption'
import HotKeyToolTip from './HotKeyToolTip'
import PrescriberCard from './PrescriberCard'
import PrescriberSearch from './PrescriberSearch'
import RxAnnotations from './RxAnnotations'
import { EditMode, NormalMode } from './RXPageStructure'
import {
  ListRowLabel,
  ListRowValue,
  PaddingBlock,
  RaisedAnchor,
  RightFilledHeader,
  RXCenterCell,
  RXCenterCellHeader,
  RXCenterColumn,
  RXImageCell,
  RXListRow,
  RXRightCell,
  RXTitleRow,
  TwoColumnFilledHeader,
} from './RXTable'

type ReviewPrescriberProps = {
  prescription: Prescription
  withoutAnnotations?: boolean
  highlightedFields?: Map<PrescriberField, boolean>
}

const RxReviewPrescriber = ({
  prescription,
  withoutAnnotations = false,
  highlightedFields = new Map<PrescriberField, boolean>(),
}: ReviewPrescriberProps): JSX.Element => {
  const {
    state: { formData },
    actions: { updateFormData },
  } = useFormData()
  const { customersById, getCustomerNameById } = useTPCacheContext()
  const [editMode] = useEditMode()
  const [showPrescriberCard, setShowPrescriberCard] = useState(true)
  const [showCustomerCard, setShowCustomerCard] = useState(true)
  const { routeToHash } = usePlusClient()

  const prescriptionFormData = formData.prescription as Prescription
  const prescriber = prescriptionFormData.prescriber as Prescriber
  const prescriptionPracticeAddress = getPrescriberAddress(prescriptionFormData)
  const directTransferPrescriber = prescriptionFormData.directTransfer?.prescriber
  const escript = prescriptionFormData.escript
  const escriptPrescriber = escript?.prescriber as EscriptPrescriber
  const escriptSupervisor = escript?.supervisor as EscriptSupervisor

  let rxImagePrescriber
  if (prescription.origin === PrescriptionOrigin.TELEPHONE) {
    rxImagePrescriber = getRxImagePrescriberManualEntry(prescription?.prescriber, prescription?.customer)
  } else {
    rxImagePrescriber = getRxImagePrescriber(escriptPrescriber, directTransferPrescriber)
  }

  const addressHasChanged = checkIfAddressChanged(rxImagePrescriber.address, prescriptionPracticeAddress)
  useHotKey('2', HotKeyLevel.normal, () => {
    routeToHash(`ReviewPrescriber`)
  })

  const convertAddressToOption = (address?: PrescriberAddress): Option => {
    const businessName = address?.businessName || ''
    const addressInfo = `${address?.street1 || ''} ${address?.street2 || ''} ${address?.city || ''}
      ${address?.state || ''} ${address?.zip || ''}`
    const phone = address?.phone || ''

    const label = `${businessName || 'No Clinic Name Provided'}, ${addressInfo}, ${phone || 'None Phone Provided'}`
    const value = address?._id

    return { value, label, additionalProps: { businessName, addressInfo, phone } }
  }

  const directTransferCustomer = prescriptionFormData.directTransfer?.customerId
    ? customersById[prescriptionFormData.directTransfer?.customerId.toString()]
    : undefined

  if (!prescription) {
    return <></>
  }

  return (
    <Box id="ReviewPrescriberBox" direction="column" data-testid="ReviewPrescriber">
      <RXTitleRow data-testid="headers">
        <RaisedAnchor id="ReviewPrescriber" />
        <RXImageCell></RXImageCell>
        <NormalMode>
          <RXCenterColumn>
            <PaddingBlock />
            <RightFilledHeader>Review prescriber</RightFilledHeader>
            <HotKeyToolTip label="2" position="right" offsetLeft={11.5} offsetTop={-1} />
          </RXCenterColumn>
        </NormalMode>
        <EditMode>
          <RXCenterCellHeader>
            <PaddingBlock />
            <TwoColumnFilledHeader>Review prescriber</TwoColumnFilledHeader>
            <HotKeyToolTip label="2" position="right" offsetLeft={10.5} offsetTop={-1} />
          </RXCenterCellHeader>
        </EditMode>
        {!withoutAnnotations && (
          <AdditionalNotesRightCell>
            <RightFilledHeader>Annotations</RightFilledHeader>
          </AdditionalNotesRightCell>
        )}
      </RXTitleRow>

      <Box direction="row">
        <Box basis="2/3" width={{ min: '53rem' }} data-testid="table-body">
          <RXListRowTwoThirds data-test-row="prescriber">
            <RXImageCell>
              <ListRowLabel>Prescriber:</ListRowLabel>
              <ListRowValue>
                {checkIfProvided(rxImagePrescriber?.firstName)} {rxImagePrescriber?.lastName}
                {rxImagePrescriber?.suffix ? `, ${rxImagePrescriber.suffix}` : ''}
              </ListRowValue>
            </RXImageCell>
            <RXCenterCell className={highlightedFields.get('name') ? 'highlighted' : ''}>
              <ListRowLabel>Prescriber:</ListRowLabel>
              <ListRowValue>
                <EditMode>
                  {showPrescriberCard ? (
                    <div>
                      <PrescriberCard
                        prescriberId={prescriptionFormData.prescriberId}
                        closeCallback={() => setShowPrescriberCard(false)}
                      />
                    </div>
                  ) : (
                    <PrescriberSearch
                      onSelect={({ prescriberId, prescriberAddresses }) => {
                        updateFormData({
                          prescription: {
                            prescriberId: { $set: prescriberId },
                            prescriberAddressId: { $set: prescriberAddresses[0]?._id },
                            prescriber: {
                              addresses: { $set: prescriberAddresses },
                            },
                          },
                        })
                        setShowPrescriberCard(true)
                      }}
                    />
                  )}
                </EditMode>
                <NormalMode>
                  {prescriber?.firstName} {prescriber?.lastName}
                  {prescriber?.suffix ? `, ${prescriber.suffix}` : ''}
                </NormalMode>
              </ListRowValue>
            </RXCenterCell>
          </RXListRowTwoThirds>
          <RXListRowTwoThirds data-test-row="credential">
            <RXImageCell>
              <ListRowLabel>Credential:</ListRowLabel>
              <ListRowValue>
                {rxImagePrescriber?.prescriberType
                  ? normalizeProviderType(rxImagePrescriber.prescriberType)
                  : 'unknown'}
              </ListRowValue>
            </RXImageCell>
            <RXCenterCell>
              <ListRowLabel>Credential:</ListRowLabel>
              <ListRowValue>
                {prescription?.prescriber?.npiDoc?.credential
                  ? normalizeProviderType(prescription.prescriber.npiDoc.credential)
                  : 'unknown'}
              </ListRowValue>
            </RXCenterCell>
          </RXListRowTwoThirds>
          <RXListRowTwoThirds data-test-row="npi">
            <RXImageCell>
              <ListRowLabel>NPI:</ListRowLabel>
              <ListRowValue>{checkIfProvided(rxImagePrescriber?.npi)}</ListRowValue>
            </RXImageCell>
            <RXCenterCell className={highlightedFields.get('npi') ? 'highlighted' : ''}>
              <ListRowLabel>NPI:</ListRowLabel>
              <ListRowValue>{checkIfProvided(prescriber?.npi)}</ListRowValue>
            </RXCenterCell>
          </RXListRowTwoThirds>
          {(rxImagePrescriber?.stateLicense || prescriber?.stateLicense) && (
            <RXListRowTwoThirds data-test-row="state-license">
              {rxImagePrescriber?.stateLicense && (
                <RXImageCell>
                  <ListRowLabel>License #:</ListRowLabel>
                  <ListRowValue>{checkIfProvided(rxImagePrescriber?.stateLicense)}</ListRowValue>
                </RXImageCell>
              )}
              {prescriber?.stateLicense && (
                <RXCenterCell>
                  <ListRowLabel>License #:</ListRowLabel>
                  <ListRowValue>{checkIfProvided(prescriber?.stateLicense)}</ListRowValue>
                </RXCenterCell>
              )}
            </RXListRowTwoThirds>
          )}
          <RXListRowTwoThirds data-test-row="supervisor">
            <RXImageCell>
              <ListRowLabel>Supervising prescriber:</ListRowLabel>
              <ListRowValue>
                {checkIfProvided(escriptSupervisor?.firstName)} {escriptSupervisor?.lastName}
              </ListRowValue>
            </RXImageCell>
            <RXCenterCell>
              <EditMode>
                <ListRowLabel>Supervising prescriber:</ListRowLabel>
                <ListRowValue>
                  {checkIfProvided(escriptSupervisor?.firstName)} {escriptSupervisor?.lastName}
                </ListRowValue>
              </EditMode>
              <NormalMode>
                <ListRowLabel>Supervising prescriber:</ListRowLabel>
                <ListRowValue>
                  {checkIfProvided(escriptSupervisor?.firstName)} {escriptSupervisor?.lastName}
                </ListRowValue>
              </NormalMode>
            </RXCenterCell>
          </RXListRowTwoThirds>
          <RXListRowTwoThirds data-test-row="address">
            <RXImageCell>
              <ListRowLabel>Address:</ListRowLabel>
              <ListRowValue>
                <AddressEntry address={rxImagePrescriber?.address} />
              </ListRowValue>
            </RXImageCell>
            <RXCenterCell className={highlightedFields.get('address') ? 'highlighted' : ''}>
              <NormalMode>
                <ListRowLabel>Address:</ListRowLabel>
                <ListRowValue>
                  <AddressEntry address={prescriptionPracticeAddress} />
                </ListRowValue>
              </NormalMode>
              <EditMode>
                <ListRowLabel>Address:</ListRowLabel>
                <ListRowValueAddress>
                  <Select
                    value={convertAddressToOption(getPrescriberAddress(prescriptionFormData))}
                    disableClear
                    placeholder="Select a practice..."
                    options={prescriber?.addresses?.map(convertAddressToOption)}
                    onChange={([selectAddress]) => {
                      const { value: id } = selectAddress ?? Object.create({})
                      updateFormData({
                        prescription: {
                          prescriberAddressId: { $set: id },
                        },
                      })
                    }}
                    children={(option, status) => (
                      <DropDownOption
                        highlighted={status.highlighted}
                        selected={status.selected}
                        data-testid="prescriber-searchable-selected"
                      >
                        <h4>{checkIfProvided(option.additionalProps?.businessName, 'No clinic name provided')}</h4>
                        <ul>
                          <li data-testid="prescriber-address">{option.additionalProps?.addressInfo}</li>
                          <li data-testid="prescriber-phone">
                            {checkIfProvided(formatPhoneNumber(option.additionalProps?.phone))}
                          </li>
                        </ul>
                      </DropDownOption>
                    )}
                  />
                  {addressHasChanged ? (
                    <StyledSpan>
                      <p>The Rx image address does not match the prescription address.</p>
                    </StyledSpan>
                  ) : (
                    ''
                  )}
                </ListRowValueAddress>
              </EditMode>
            </RXCenterCell>
          </RXListRowTwoThirds>
          <RXListRowTwoThirds data-test-row="phone">
            <RXImageCell>
              <ListRowLabel>Phone:</ListRowLabel>
              <ListRowValue>
                {checkIfProvided(formatPhoneNumber(rxImagePrescriber?.communicationNumbers?.phone?.number))}
              </ListRowValue>
            </RXImageCell>
            <RXCenterCell className={highlightedFields.get('phone') ? 'highlighted' : ''}>
              <ListRowLabel>Phone:</ListRowLabel>
              <ListRowValue>
                {checkIfProvided(formatPhoneNumber(prescriptionPracticeAddress?.phone || prescriber?.contacts?.phone))}
              </ListRowValue>
            </RXCenterCell>
          </RXListRowTwoThirds>
          <RXListRowTwoThirds data-test-row="fax">
            <RXImageCell>
              <ListRowLabel>Fax:</ListRowLabel>
              <ListRowValue>
                {checkIfProvided(formatPhoneNumber(rxImagePrescriber?.communicationNumbers?.fax?.[0]?.number))}
              </ListRowValue>
            </RXImageCell>
            <RXCenterCell>
              <ListRowLabel>Fax:</ListRowLabel>
              <ListRowValue>
                {checkIfProvided(formatPhoneNumber(prescriptionPracticeAddress?.fax || prescriber?.contacts?.fax))}
              </ListRowValue>
            </RXCenterCell>
          </RXListRowTwoThirds>
          <RXListRowTwoThirds data-test-row="dea">
            <RXImageCell>
              <ListRowLabel>DEA:</ListRowLabel>
              <ListRowValue>{checkIfProvided(rxImagePrescriber?.deaNumber)}</ListRowValue>
            </RXImageCell>
            <RXCenterCell className={highlightedFields.get('dea') ? 'highlighted' : ''}>
              <ListRowLabel>DEA:</ListRowLabel>
              <ListRowValue>{checkIfProvided(prescriber?.dea)}</ListRowValue>
            </RXCenterCell>
          </RXListRowTwoThirds>
          <RXListRowTwoThirds data-test-row="customer">
            <RXImageCell>
              <ListRowLabel>Customer:</ListRowLabel>
              <ListRowValue>
                {checkIfProvided(escript?.clinicName || directTransferCustomer?.name || rxImagePrescriber.customer)}
              </ListRowValue>
            </RXImageCell>
            <RXCenterCell className={highlightedFields.get('customer') ? 'highlighted' : ''}>
              <ListRowLabel>Customer:</ListRowLabel>
              <ListRowValue>
                <EditMode>
                  {showCustomerCard ? (
                    <CustomerCard
                      customerId={prescriptionFormData.customerId}
                      closeCallback={() => setShowCustomerCard(false)}
                    />
                  ) : (
                    <CustomerSearch
                      isVisible
                      singleSelection
                      preventReload={true}
                      onSelect={(customerId: string) => {
                        updateFormData({
                          prescription: {
                            customerId: { $set: customerId },
                          },
                        })
                        setShowCustomerCard(true)
                      }}
                    />
                  )}
                </EditMode>
                <NormalMode>{checkIfProvided(getCustomerNameById(prescriptionFormData.customerId))}</NormalMode>
              </ListRowValue>
            </RXCenterCell>
          </RXListRowTwoThirds>
        </Box>
        {!withoutAnnotations && (
          <Box basis="1/3" pad={{ left: '2rem' }} overflow="auto" data-testid="annotations-container">
            <RxAnnotations height={editMode ? '19rem' : '19rem'} />
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default RxReviewPrescriber

const AdditionalNotesRightCell = styled(RXRightCell)`
  flex-wrap: wrap;
  overflow-y: visible;
  position: relative;
  white-space: nowrap;
  padding-top: 4.3444rem;
  height: 0;
  padding-left: 0;
  background-color: white;
`
const ListRowValueAddress = styled.div.attrs({
  'data-testid': 'value',
})`
  grid-row: 1;
  align-self: center;
  display: flex;
  flex-direction: column;
  padding-right: 0.625rem;
  overflow: overlay;
  align-items: flex-stretch;
  > button {
    flex: 1;
  }
`

const StyledSpan = styled.span`
  color: red;
  font-size: 0.8rem;
  display: flex;
  justify-content: left;
`

const RXListRowTwoThirds = styled(RXListRow)`
  grid-template-columns: [image] minmax(26.5rem, 1fr) [center] minmax(26.5rem, 1fr);
`
