import { RxFillRequestSpecialHandlingTag } from '@truepill/tpos-types'
import { ReactComponent as ColdIcon } from 'assets/icons/cold.svg'
import { ReactComponent as ControlledSubstanceIcon, ReactComponent as Control2Icon } from 'assets/icons/controlled2.svg'
import { ReactComponent as Control3Icon } from 'assets/icons/controlled3.svg'
import { ReactComponent as Control4Icon } from 'assets/icons/controlled4.svg'
import { ReactComponent as Control5Icon } from 'assets/icons/controlled5.svg'
import { ReactComponent as ExpensiveIcon } from 'assets/icons/expensive.svg'
import { ReactComponent as SpecialtyIcon } from 'assets/icons/specialty.svg'
import IconWrapper, { LargeIconWrapper } from 'components/IconWrapper'
import Lozenge from 'components/Tiles/Lozenge'
import styled from 'styled-components'
import { accentPurple, primaryColor, warningYellow } from 'styles/styleVariables'

type HandlingTagsTileProps = {
  specialHandlingTags?: RxFillRequestSpecialHandlingTag[]
}

// No Tile for this, just lozenges
const HandlingTagsTile = ({ specialHandlingTags }: HandlingTagsTileProps): JSX.Element => {
  // at least one of the tags must be present to be displayed
  if (!specialHandlingTags?.length) {
    return <></>
  }

  return (
    <>
      {specialHandlingTags?.includes(RxFillRequestSpecialHandlingTag.COLD_CHAIN) && (
        <StyledContrastLozenge data-testid="handling-tags-cold-chain">
          <IconWrapper>
            <ColdIcon />
          </IconWrapper>
        </StyledContrastLozenge>
      )}
      {specialHandlingTags?.includes(RxFillRequestSpecialHandlingTag.CONTROLLED_SUBSTANCE) && (
        <StyledContrastLozenge data-testid="handling-tags-controlled-substance">
          <IconWrapper>
            <ControlledSubstanceIcon />
          </IconWrapper>
        </StyledContrastLozenge>
      )}
      {specialHandlingTags?.includes(RxFillRequestSpecialHandlingTag.CII) && (
        <StyledLozenge data-testid="handling-tags-controlled-substance-ii">
          <Control2Icon />
        </StyledLozenge>
      )}
      {specialHandlingTags?.includes(RxFillRequestSpecialHandlingTag.CIII) && (
        <StyledWarningLozenge data-testid="handling-tags-controlled-substance-iii">
          <LargeIconWrapper style={{ width: '1.5rem' }}>
            <Control3Icon />
          </LargeIconWrapper>
        </StyledWarningLozenge>
      )}
      {specialHandlingTags?.includes(RxFillRequestSpecialHandlingTag.CIV) && (
        <StyledWarningLozenge data-testid="handling-tags-controlled-substance-iv">
          <LargeIconWrapper style={{ width: '1.5rem' }}>
            <Control4Icon />
          </LargeIconWrapper>
        </StyledWarningLozenge>
      )}
      {specialHandlingTags?.includes(RxFillRequestSpecialHandlingTag.CV) && (
        <StyledWarningLozenge data-testid="handling-tags-controlled-substance-v">
          <LargeIconWrapper style={{ width: '1.5rem' }}>
            <Control5Icon />
          </LargeIconWrapper>
        </StyledWarningLozenge>
      )}
      {specialHandlingTags?.includes(RxFillRequestSpecialHandlingTag.HIGH_COST) && (
        <ExpensiveIconWrapper data-testid="handling-tags-expensive">
          <ExpensiveIcon />
        </ExpensiveIconWrapper>
      )}
      {specialHandlingTags?.includes(RxFillRequestSpecialHandlingTag.SPECIALTY) && (
        <StyledAccentLozenge data-testid="handling-tags-specialty">
          <IconWrapper>
            <SpecialtyIcon />
          </IconWrapper>
        </StyledAccentLozenge>
      )}
    </>
  )
}

const StyledLozenge = styled(Lozenge)`
  padding: 0;
`
const StyledContrastLozenge = styled(StyledLozenge)`
  background-color: ${primaryColor};
  padding: 0.2rem;
`
const StyledWarningLozenge = styled(StyledLozenge)`
  background-color: ${warningYellow};
  padding: 0.25rem;
`
const ExpensiveIconWrapper = styled(StyledLozenge)`
  margin-left: 0.5rem;
`
const StyledAccentLozenge = styled(StyledLozenge)`
  background-color: ${accentPurple};
  padding: 0.2rem;
`

export default HandlingTagsTile
