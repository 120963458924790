import React from 'react'
import { Select } from '@truepill/react-capsule'
import { ShippingLabelReprintReason } from '@truepill/tpos-types'

interface ReprintShippingLabelReasonSelectProps {
  required?: boolean
  label?: string
  value?: ShippingLabelReprintReason
  onChange: (reason?: ShippingLabelReprintReason) => void
}

const options = Object.values(ShippingLabelReprintReason)

const ReprintShippingLabelReasonSelect = ({
  label = '',
  required = false,
  value = ShippingLabelReprintReason.LabelWrinkedOrDamaged,
  onChange,
}: ReprintShippingLabelReasonSelectProps): JSX.Element => (
  <div data-testid="reprint-shipping-label-select">
    <Select onChange={onChange} required={required} options={options} value={value} label={label} />
  </div>
)

export default React.memo(ReprintShippingLabelReasonSelect)
