import type { HTMLAttributes } from 'react'
import { useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import {
  primaryBackgroundColor,
  bodyPrimaryColor,
  bodySecondaryColor,
  capsulePrimaryColorLight,
} from 'styles/styleVariables'
import type { ChildProps } from 'types'

const Highlighted = css`
  background-color: ${capsulePrimaryColorLight};
  > p,
  p:not(:first-of-type) {
    color: ${bodyPrimaryColor};
  }
  > div {
    color: ${bodyPrimaryColor};
  }
`
type AutoScrollElementprops = {
  className?: string
  selected?: boolean
  onClick?: () => void
  onMouseDown?: () => void
} & ChildProps

export const AutoScrollElement = (props: AutoScrollElementprops): JSX.Element => {
  const { selected, className, children, onClick, onMouseDown } = props
  const ref = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    if (selected) {
      if (ref.current && ref.current.parentElement) {
        const el = ref.current
        const parent = ref.current.parentElement
        const diff = el.offsetTop - parent.clientHeight
        if (diff > 0) {
          parent.scroll(0, diff + el.scrollHeight)
        }
      }
    }
  }, [selected, ref])

  // using onMouseDown override to prevent issues with Select losing focus before
  // onClick can fire
  return (
    <StyledAutoScrollElement
      onClick={e => {
        e.preventDefault()
        onClick && onClick()
      }}
      onMouseDown={e => {
        e.preventDefault()
        onMouseDown && onMouseDown()
      }}
      ref={ref}
      className={className}
    >
      {children}
    </StyledAutoScrollElement>
  )
}

const StyledAutoScrollElement = styled.span`
  background-color: ${primaryBackgroundColor};
  border: none;
  padding: 0px;
  margin: 0px;
  text-align: left;
  font-family: roboto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
`

interface DropDownOptionProps extends HTMLAttributes<HTMLLIElement> {
  active?: boolean
  capitalize?: boolean
  disabled?: boolean
  selected?: boolean
  highlighted?: boolean
  isModal?: boolean
  className?: string
  loading?: boolean
}

const DropDownOption = (props: DropDownOptionProps): JSX.Element => {
  const { capitalize, ...dropdownProps } = props

  const htmlProps = {
    ...dropdownProps,
    highlighted: props.highlighted?.toString() || 'false',
    loading: props.loading?.toString() || 'false',
  }

  return (
    <li className={props.className} {...htmlProps}>
      {props.children}
    </li>
  )
}

const StyledDropDownOption = styled(DropDownOption)`
  ${({ disabled }) => (disabled ? `cursor: default` : `cursor: pointer`)};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  padding: 0.5rem 1rem;
  color: ${bodyPrimaryColor};
  flex-grow: 1;
  :hover {
    ${Highlighted}
  }
  ${({ isModal }) => (isModal ? 'z-index: 9' : 'z-index: 1')}
  > p {
    color: ${bodySecondaryColor};
    font-size: 0.875rem;
    font-weight: normal;
    text-transform: capitalize;
  }
  > p:not(:first-of-type) {
    color: ${bodySecondaryColor};
  }
  > :first-child {
    ${({ capitalize }) => (capitalize ? 'text-transform: capitalize;' : '')}
    color: ${bodyPrimaryColor};
    font-weight: 500;
    font-size: 1rem;
  }
  ${({ disabled, highlighted }) => highlighted && !disabled && Highlighted}
`

export default StyledDropDownOption
