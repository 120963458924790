import type { ClaimData } from '@truepill/tpos-types'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'
import getNextFillDateFromMessage from './getNextFillDateFromMessage'

dayjs.extend(utc)
dayjs.extend(customParseFormat)

const getNextAvailableFillDateAsDate = (claim: ClaimData): Date | undefined => {
  const dateString = claim?.received?.groups?.[0]?.response?.nextAvailableFillDate
  const date = dateString ? dayjs.utc(dateString, 'YYYYMMDD').toDate() : undefined
  return date && !isNaN(date.getTime()) ? date : undefined
}

export const getNextFillableDateValue = (claim: ClaimData): Date | undefined => {
  return (
    getNextAvailableFillDateAsDate(claim) ??
    getNextFillDateFromMessage(claim?.received?.groups?.[0]?.response?.additionalMessageInformation, {
      after: new Date(),
    })
  )
}
