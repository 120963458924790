import { useMutation } from '@truepill/tpos-react-router'
import type { RxFillRequestStatus } from '@truepill/tpos-types'
import { OrderStatus } from '@truepill/tpos-types'
import { ReactComponent as ConfirmIcon } from 'assets/icons/circlecheck.svg'
import ActionButton from 'components/ActionButton'
import IconWrapper from 'components/IconWrapper'
import { CONFIRM_FILL_PV2, CAN_CONFIRM_PV2, SEND_BACK_TO_ADJUDICATION } from 'gql'
import useErrorToast from 'hooks/toast/useErrorToast'
import { FulfillmentQueueName } from 'hooks/useFulfillmentQueue'
import { usePlusClient } from 'providers/VisionRouter'
import { bodyPrimaryColor } from 'styles/styleVariables'
import type { Fill, Order, Prescription } from 'types'
import { PaymentType } from 'types'

type ConfirmButtonsProps = { order: Order; fill: Fill; orderEditable: boolean; disableRphButtons: boolean }

const validatePV2Confirmation = ({
  order,
  noPaidClaim,
  prescription,
  fill,
}: {
  fill?: Fill
  order?: Order
  noPaidClaim: boolean
  prescription?: Prescription
}) => {
  if (!prescription || !fill || !order) {
    return { valid: false, errorMessage: 'Missing data when validating' }
  }
  if (!fill.dispensed.daysSupply) {
    return { valid: false, errorMessage: 'Days Supply is missing or 0' }
  }
  if (!fill.dispensed.directions) {
    return { valid: false, errorMessage: 'Missing sig' }
  }
  if (!order.isReplacement && prescription.quantityRemaining < fill.dispensed.quantity) {
    return { valid: false, errorMessage: 'Insufficient quantity remaining' }
  }
  if (noPaidClaim) {
    return { valid: false, errorMessage: 'No paid claim on this insurance fill; cannot confirm PV2' }
  }
  if (order.inTriage) {
    return { valid: false, errorMessage: 'unable to confirm a fill while it is in Triage' }
  }
  return { valid: true }
}

export const ConfirmButton = ({ fill, order, orderEditable, disableRphButtons }: ConfirmButtonsProps): JSX.Element => {
  const showErrorToast = useErrorToast()
  const { routeTo, tokenContext } = usePlusClient()
  const fulfillmentQueueName = FulfillmentQueueName.PV2

  const [confirmFillPV2, { loading: loadingConfirmFillPV2 }] = useMutation(CONFIRM_FILL_PV2, {
    refetchQueries: ['getRXPageQuery', 'getBasicOrder'],
    onError: e => {
      showErrorToast('Failed to confirm fill: ' + e.message)
    },
    onCompleted: () => {
      routeTo
        .fulfillmentQueue(fulfillmentQueueName, {
          searchMap: {
            locationId: tokenContext.locationId,
          },
        })
        .now()
    },
  })

  const [sendBackToAdjudication, { client, loading: loadingSendBack }] = useMutation(SEND_BACK_TO_ADJUDICATION, {
    refetchQueries: ['getBasicOrder'],
    onCompleted: () => {
      routeTo.fulfillment(order._id, fill._id, FulfillmentQueueName.Adjudication).now()
    },
  })

  const [canConfirmPV2, { loading: loadingCanConfirmPV2 }] = useMutation(CAN_CONFIRM_PV2, {
    variables: { orderId: order._id, fillId: fill._id },
    refetchQueries: ['getRXPageQuery', 'getBasicOrder'],
    onError: async () => {
      showErrorToast('Failed to rerun claim, moved to Adjudication.')
      await client.refetchQueries({ include: ['getBasicOrder'] })
      if (order.status === OrderStatus.Adjudication) {
        routeTo.fulfillment(order._id, fill._id, FulfillmentQueueName.Adjudication).now()
      } else {
        sendBackToAdjudication({
          variables: {
            orderId: order._id,
            fillId: fill?._id,
            message: 'PV2 Failed to rerun claim, moved to Adjudication',
          },
        })
      }
    },
    onCompleted: (data: { canConfirmPV2: { canConfirm: boolean } }) => {
      if (data.canConfirmPV2.canConfirm) {
        confirmPV2(fill, order)
      } else {
        //If I cant confirm, it means that the copay was different from the original. I need to triage PV1 patient outhreach
        routeTo.fulfillment(order._id, fill._id, FulfillmentQueueName.PV1).now()
      }
    },
  })

  const confirmPV2 = (fill: Fill, order: Order) => {
    const rxFillRequest = order.rxFillRequests.find(({ fillId }) => fillId.toString() === fill._id.toString())
    const prescription = rxFillRequest?.prescription
    const noPaidClaim =
      rxFillRequest?.paymentType === PaymentType.insurance && !rxFillRequest?.adjudication?.runSuccessful

    const validation = validatePV2Confirmation({
      order,
      fill,
      noPaidClaim,
      prescription,
    })

    if (!validation.valid) {
      showErrorToast(validation.errorMessage ?? 'This order is not ready to be confirmed')
      return
    }

    return confirmFillPV2({
      variables: { orderId: order._id, fillId: fill._id },
    })
  }

  const cannotProceedPastPV2 = order.rxFillRequests
    .map(({ status }: { status: RxFillRequestStatus }) => status)
    .some((status: RxFillRequestStatus) => ['Fill', 'PV1'].includes(status))

  const disableConfirm = disableRphButtons || !orderEditable || cannotProceedPastPV2

  return (
    <ActionButton
      disabled={disableConfirm || loadingCanConfirmPV2 || loadingSendBack || loadingConfirmFillPV2}
      data-testid="confirm"
      icon={
        <IconWrapper>
          <ConfirmIcon fill={bodyPrimaryColor} />
        </IconWrapper>
      }
      label={'Confirm'}
      hotKeyLabel={'CTRL-Enter'}
      hotKey={'Enter'}
      hotKeyMeta={{ ctrl: true }}
      onClick={canConfirmPV2}
    />
  )
}
