import type { QueryResult } from '@truepill/tpos-react-router'
import { useQuery } from '@truepill/tpos-react-router'
import { GET_COPAYS_BY_STATUS_COUNT } from 'gql'

export interface CountsByCopayStatus {
  pending: number
  triage: number
  rejected: number
  complete: number
  confirmed: number
  priorAuthorization: number
}
export interface GetCopaysByStatusCountResult {
  countByStatus: CountsByCopayStatus
}

export interface GetCopaysByStatusCountParams {
  medicationNames?: string[]
  customerIds?: string[]
  selectedLocationIds?: string[]
  locationId?: string
}

const useCopayCountByStatus = ({
  params,
}: {
  params: GetCopaysByStatusCountParams
}): QueryResult<GetCopaysByStatusCountResult, GetCopaysByStatusCountParams> => {
  return useQuery<GetCopaysByStatusCountResult, GetCopaysByStatusCountParams>(GET_COPAYS_BY_STATUS_COUNT, {
    fetchPolicy: 'network-only',
    variables: params,
  })
}

export default useCopayCountByStatus
