import { ReactComponent as OrderIcon } from 'assets/icons/order.svg'
import IconWrapper from 'components/IconWrapper'
import { ModalWrapper, ModalHeader } from 'components/Modal'
import OrderHeading from 'components/OrderHeading'
import OrderView, { OrderViewMode } from 'components/OrderView'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import styled, { css } from 'styled-components'
import { bodyPrimaryColor } from 'styles/styleVariables'
import type { Order } from 'types'

type OrderModalProps = {
  order: Order
}

const OrderModal = ({ order }: OrderModalProps): JSX.Element => {
  const { dismissModal } = useModalContext()
  return (
    <ModalWrapper id="OrderModal" styles={[{ display: 'block' }]}>
      <OrderModalHeader marginTop="0.5rem">
        <OrderHeading headingStyle={headingStyle} order={order}>
          <StyledIconWrapper>
            <OrderIcon fill={bodyPrimaryColor} />
          </StyledIconWrapper>
        </OrderHeading>
      </OrderModalHeader>
      <OrderView inModal={true} onClickOrderEntry={dismissModal} mode={OrderViewMode.Fulfillment} orderId={order._id} />
    </ModalWrapper>
  )
}

const OrderModalHeader = styled(ModalHeader)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1em;
  > :first-child {
    margin-right: 0.5rem;
  }
`

const headingStyle = css`
  font-size: 1.13rem;
`

const StyledIconWrapper = styled(IconWrapper)`
  margin-right: 0.5rem;
`

export default OrderModal
