import { useQuery } from '@truepill/tpos-react-router'
import { RxStatus } from '@truepill/tpos-types'
import LoadingSpinner from 'components/Loading'
import { NoResultsEntry } from 'components/PageStructure'
import Paging from 'components/Paging'
import { LIST_PRESCRIPTIONS } from 'gql'
import { usePlusClient } from 'providers/VisionRouter'
import type { Prescriber, Prescription } from 'types'
import PrescriptionEntry from './components/PrescriptionEntry'
import TitleRow from './components/TitleRow'
import { StyledPrescriptionContainer, PrescriptionContainer, LoadingSpinnerContainer } from './StyledComponents'

interface GetPrescriptionVariables {
  customerIds?: string[]
  prescriberIds: Prescriber['_id']
  ndcs?: string[]
  statuses?: string[]
  medicationNames?: string[]
  rxNumber?: number
  rxFillCode?: string
  corePrescriptionToken?: string
  pageNumber?: number
  pageSize?: number
}

type PrescriberPrescriptionsTableProps = {
  className?: string
  searchTerm?: string
  prescriberIds: Prescriber['_id']
}

const PrescriberPrescriptionsTable = (props: PrescriberPrescriptionsTableProps): JSX.Element => {
  const { className, searchTerm = '', prescriberIds } = props

  const {
    currentLocation: { queryMap },
    routeToMergedQuery,
  } = usePlusClient()

  const variables: GetPrescriptionVariables = { prescriberIds }

  const rxFillCodeMatch = searchTerm && searchTerm.match(/(\d+)-(\d+)-(\d\d)(-[CP])?(-R\d)?/)
  const rxNumberMatch = searchTerm && searchTerm.match(/^[0-9]*$/)

  if (rxFillCodeMatch) {
    variables.rxFillCode = searchTerm
  } else if (rxNumberMatch && rxNumberMatch.length) {
    variables.rxNumber = Number.parseInt(searchTerm)
  }
  // Do we know a format that we can check against for corePrescriptionToken?
  else if (searchTerm) {
    variables.corePrescriptionToken = searchTerm
  }

  if (queryMap.customerIds?.length) {
    variables.customerIds = queryMap.customerIds
  }

  if (queryMap.statuses?.length) {
    const statuses = queryMap.statuses.map((status: unknown) => RxStatus[status as keyof typeof RxStatus])

    variables.statuses = statuses
  }

  if (queryMap.medications?.length) {
    variables.medicationNames = queryMap.medications
  }

  const pageSize = queryMap.pageSize ? parseInt(queryMap.pageSize, 10) : 10
  variables.pageNumber = Number(queryMap?.page) || 0
  variables.pageSize = pageSize

  const { data, loading, error } = useQuery(LIST_PRESCRIPTIONS, {
    variables: variables,
  })

  const prescriptions = (data?.getPrescriptions.prescriptions || []) as Prescription[]
  const totalPrescriptions = data?.getPrescriptions.totalRecords ?? 0
  const currentPage = data?.getPrescriptions.currentPage ?? 0
  const errorMessage = error?.message

  if (loading) {
    return (
      <StyledPrescriptionContainer className={className}>
        <TitleRow />
        <LoadingSpinnerContainer>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      </StyledPrescriptionContainer>
    )
  }

  if (error) {
    return <p>Failed to load Prescriptions {errorMessage} </p>
  }

  const totalPages = Math.ceil(totalPrescriptions / pageSize)
  const showPagination = !loading && totalPrescriptions > pageSize

  return (
    <>
      <PrescriptionContainer className={className}>
        <TitleRow />
        {prescriptions.map(prescription => (
          <PrescriptionEntry key={prescription._id} prescription={prescription} />
        ))}
        {prescriptions.length === 0 && <NoResultsEntry> No results </NoResultsEntry>}
      </PrescriptionContainer>
      {showPagination && (
        <Paging
          totalPages={totalPages}
          currentPage={currentPage}
          setPage={pageNumber => routeToMergedQuery({ page: pageNumber })}
          totalRecords={totalPrescriptions}
          pageSize={pageSize}
          margin="1.875rem"
        />
      )}
    </>
  )
}

export default PrescriberPrescriptionsTable
