import { useCallback, useEffect } from 'react'
import CriticalNotesModal from 'components/CriticalNotesModal'
import ControlledSubstanceNote from 'pages/fulfillment/orders/rx/screens/pv1/ControlledSubstanceNote'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import type { Log, Order, Prescription } from 'types'
import { getCriticalLogs } from 'utils'

type useCriticalNotesModalProps = {
  order?: Order
  prescription?: Prescription
  logs?: Log[]
  orderOnlyNotes?: boolean
}

const useCriticalNotesModal = ({
  order,
  prescription,
  logs,
  orderOnlyNotes = false,
}: useCriticalNotesModalProps): void => {
  const criticalNotes: Log[] = []

  if (order?.logs) {
    let orderCriticalNotes = getCriticalLogs(order.logs)
    if (prescription) {
      // In batch orders there could be critical notes from different prescriptions
      // so filter to the current prescription
      orderCriticalNotes = orderCriticalNotes.filter(note =>
        note.prescriptionId ? note.prescriptionId === prescription._id : true,
      )

      // On fill pages if there are any order logs for a specific fill make sure we filter out the others
      const rxFillRequest = order.rxFillRequests.find(
        rxFillRequest => rxFillRequest.prescriptionId === prescription._id,
      )
      orderCriticalNotes = orderCriticalNotes.filter(note =>
        note.fillId ? note.fillId === rxFillRequest?.fillId : true,
      )
    }

    if (orderOnlyNotes) {
      orderCriticalNotes = orderCriticalNotes.filter(note => !note.prescriptionId && !note.fillId)
    }

    criticalNotes.push(...orderCriticalNotes)
  }

  if (prescription?.logs) {
    const prescriptionCriticalNotes = getCriticalLogs(prescription.logs)
    const currentNoteIds = criticalNotes.map(note => note._id)
    // Some of the order logs might contain the prescription logs so we need to make sure we don't push duplicates
    prescriptionCriticalNotes.forEach(note => {
      if (!currentNoteIds.includes(note._id)) {
        criticalNotes.push(note)
      }
    })
  }

  if (logs) {
    const currentNoteIds = criticalNotes.map(note => note._id)
    const criticalLogs = getCriticalLogs(logs)
    criticalLogs.forEach(log => {
      if (!currentNoteIds.includes(log._id)) {
        criticalNotes.push(log)
      }
    })
  }

  const criticalNotesToShow = criticalNotes.filter(note => !note.isArchived)

  const useCriticalNotesPopModal = (prescription?: Prescription, notes?: Log[]) => {
    const { showModal } = useModalContext()
    return useCallback(() => {
      showModal(() => (
        <CriticalNotesModal notes={notes}>
          {prescription?.deaSchedule && <ControlledSubstanceNote />}
        </CriticalNotesModal>
      ))
    }, [prescription?.deaSchedule, notes, showModal])
  }

  const popCriticalNotesModal = useCriticalNotesPopModal(prescription, criticalNotesToShow)

  useEffect(() => {
    if (criticalNotesToShow.length || prescription?.deaSchedule) {
      return popCriticalNotesModal()
    }
    return undefined
  }, [criticalNotesToShow.length])
}

export default useCriticalNotesModal
