import DatePicker from 'components/DatePicker'
import { ListRowLabel, ListRowValue, RaisedAnchor, RightFilledHeader, PaddingBlock } from 'components/RXTable'
import moment from 'moment'
import type { EdiOverrides } from 'types'
import {
  RXEDIListRow,
  RXEDImageCell,
  RXEDIRightEditCell,
  RXEDIRightTitleCell,
  RXEDITitleRow,
  DateCellPadding,
  RXTextInput,
} from '..'

type AdditionalDocumentationSegmentProps = {
  ediOverrides: EdiOverrides
  updateEdiOverrides: (input: EdiOverrides) => void
}

const AdditionalDocumentationSegment = ({
  ediOverrides,
  updateEdiOverrides,
}: AdditionalDocumentationSegmentProps): JSX.Element => {
  const { additionalDocumentation } = ediOverrides

  const additionalDocumentationTypeID = additionalDocumentation?.additionalDocumentationTypeID
  const requestPeriodBeginDate = additionalDocumentation?.requestPeriodBeginDate
  const requestPeriodRecertificationDate = additionalDocumentation?.requestPeriodRecertificationDate
  const requestStatus = additionalDocumentation?.requestStatus
  const lengthOfNeedQualifier = additionalDocumentation?.lengthOfNeedQualifier
  const lengthOfNeed = additionalDocumentation?.lengthOfNeed
  const prescriberDateSigned = additionalDocumentation?.prescriberDateSigned
  const supportingDocumentation = additionalDocumentation?.supportingDocumentation

  return (
    <>
      <AdditionalDocumentationHeaderRow />
      <RXEDIListRow>
        <RXEDIRightEditCell>
          <ListRowLabel>Additional Documentation Type Id (2Q):</ListRowLabel>
          <ListRowValue>
            <RXTextInput
              value={additionalDocumentationTypeID ?? ''}
              onChange={({ target: { value: additionalDocumentationTypeID } }) =>
                updateEdiOverrides({ additionalDocumentation: { additionalDocumentationTypeID } })
              }
              placeholder="Additional Documentation Type Id..."
            />
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>

      <RXEDIListRow>
        <RXEDIRightEditCell>
          <ListRowLabel>Request Period Begin Date (2V):</ListRowLabel>
          <ListRowValue>
            <DateCellPadding>
              <DatePicker
                date={moment(requestPeriodBeginDate ?? new Date(), 'MM/DD/YYYY').toDate()}
                onChange={date =>
                  updateEdiOverrides({
                    additionalDocumentation: { requestPeriodBeginDate: moment(date).format('MM/DD/YYYY') },
                  })
                }
              />
            </DateCellPadding>
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>

      <RXEDIListRow>
        <RXEDIRightEditCell>
          <ListRowLabel>Request Period Recertification Date (2W):</ListRowLabel>
          <ListRowValue>
            <DateCellPadding>
              <DatePicker
                date={moment(requestPeriodRecertificationDate ?? new Date(), 'MM/DD/YYYY').toDate()}
                onChange={date =>
                  updateEdiOverrides({
                    additionalDocumentation: { requestPeriodRecertificationDate: moment(date).format('MM/DD/YYYY') },
                  })
                }
              />
            </DateCellPadding>
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>

      <RXEDIListRow>
        <RXEDIRightEditCell>
          <ListRowLabel>Request Status (2U):</ListRowLabel>
          <ListRowValue>
            <RXTextInput
              value={requestStatus ?? ''}
              onChange={({ target: { value: requestStatus } }) =>
                updateEdiOverrides({ additionalDocumentation: { requestStatus } })
              }
              placeholder="Request Status..."
            />
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>

      <RXEDIListRow>
        <RXEDIRightEditCell>
          <ListRowLabel>Length of Need Qualifier (2S):</ListRowLabel>
          <ListRowValue>
            <RXTextInput
              value={lengthOfNeedQualifier ?? ''}
              onChange={({ target: { value: lengthOfNeedQualifier } }) =>
                updateEdiOverrides({ additionalDocumentation: { lengthOfNeedQualifier } })
              }
              placeholder="Length of Need Qualifier..."
            />
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>

      <RXEDIListRow>
        <RXEDIRightEditCell>
          <ListRowLabel>Prescriber Date Signed (2T):</ListRowLabel>
          <ListRowValue>
            <DateCellPadding>
              <DatePicker
                date={moment(prescriberDateSigned ?? new Date(), 'MM/DD/YYYY').toDate()}
                onChange={date =>
                  updateEdiOverrides({
                    additionalDocumentation: { prescriberDateSigned: moment(date).format('MM/DD/YYYY') },
                  })
                }
              />
            </DateCellPadding>
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>

      <RXEDIListRow>
        <RXEDIRightEditCell>
          <ListRowLabel>Length of Need (2R):</ListRowLabel>
          <ListRowValue>
            <RXTextInput
              value={lengthOfNeed ?? ''}
              onChange={({ target: { value: lengthOfNeed } }) =>
                updateEdiOverrides({ additionalDocumentation: { lengthOfNeed } })
              }
              placeholder="Length of Need..."
            />
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>

      <RXEDIListRow>
        <RXEDIRightEditCell closeOffBottomBorder>
          <ListRowLabel>Supporting Documentation (2X):</ListRowLabel>
          <ListRowValue>
            <RXTextInput
              value={supportingDocumentation ?? ''}
              onChange={({ target: { value: supportingDocumentation } }) =>
                updateEdiOverrides({ additionalDocumentation: { supportingDocumentation } })
              }
              placeholder="Supporting Documentation..."
            />
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>
    </>
  )
}

const AdditionalDocumentationHeaderRow = () => {
  return (
    <RXEDITitleRow>
      <RXEDImageCell />
      <RXEDIRightTitleCell>
        <PaddingBlock />
        <RightFilledHeader>
          <RaisedAnchor id={'AdditionalDocumentationSegment'} />
          Additional Documentation segment
          {/* <HotKeyToolTip label={'2'} position={'right'} offsetLeft={1} offsetTop={0} /> */}
        </RightFilledHeader>
      </RXEDIRightTitleCell>
    </RXEDITitleRow>
  )
}

export default AdditionalDocumentationSegment
