import { useState, useEffect } from 'react'
import { TextInput } from 'grommet'
import styled, { css } from 'styled-components'
import { alertRed, borderColor, subduedColor, primaryBackgroundColor } from 'styles/styleVariables'
import type { SurescriptsNumber } from 'types'
import { limitPeriods } from 'utils'

// A Numeric Input field to comply with Surescripts requirements Below
/*
  2. Attempt to enter a quantity of 14.000 and TAB out.
  Should change to 14.
  3. Attempt to enter a quantity of 12345.123456.
  - The application should not permit the 11th digit if the decimal is being
  counted toward the total length as expected.
  4. Attempt to enter a quantity of .14555
  - The application should populate a zero in front of the decimal.
*/

interface SurescriptsNumericInputProps {
  className?: string
  value?: SurescriptsNumber
  alert?: boolean
  disabled?: boolean
  placeholder?: string
  allowFloat?: boolean
  onChange?: (newNumber?: SurescriptsNumber) => void
  onBlur?: (newNumber?: number) => void
  fadeOnZeroValue?: boolean
}

export const SurescriptsNumericInput = ({
  value,
  onChange,
  onBlur,
  className,
  fadeOnZeroValue,
  disabled,
}: SurescriptsNumericInputProps): JSX.Element => {
  const [buffer, setBuffer] = useState(`0`)
  const [error, setError] = useState<string>()

  const bufferMatchesValue = value === parseFloat(buffer)

  useEffect(() => {
    setBuffer(`${value || 0}`)
  }, [value, setBuffer, bufferMatchesValue])

  return (
    <>
      <StyledNumberInput
        className={className}
        error={!!error}
        value={buffer}
        disabled={disabled}
        onChange={e => {
          // prevent multiple periods eg: 0.02.2
          let newText = limitPeriods(e.target.value)
          // numbers over 10 digits should be ignored
          if (newText.length > 11) {
            newText = newText.slice(0, 11)
          }
          const newNumber = parseFloat(newText)
          // We still set the buffer to this new text value but we
          // don't fire the onchange unless there's a valid number
          setBuffer(newText)
          if (newText === '.') {
            onChange?.('.')
          } else if (!isNaN(newNumber) && !newText.match(/[^\d\.]/g)) {
            setError(undefined)
            onChange?.(newNumber)
          } else if (newText === '') {
            setError(undefined)
            onChange?.(0)
            setBuffer('0')
          } else {
            setError('Invalid number')
          }
        }}
        fade={fadeOnZeroValue && !value}
        onBlur={async () => {
          const parsed = parseFloat(buffer)
          if (isNaN(parsed)) {
            setError('Invalid number')
          } else {
            // Strip trailing zeroes
            let strippedBuffer = buffer.replace(/\.0*$/, '')

            // replace .xx with 0.xx
            if (strippedBuffer[0] === '.') {
              strippedBuffer = '0' + strippedBuffer
            }
            setBuffer(strippedBuffer)
            onChange?.(parseFloat(strippedBuffer))
            await onBlur?.()
          }
        }}
      />
      {error && <ErrorText>{error}</ErrorText>}
    </>
  )
}

const ErrorStyle = css`
  color: ${alertRed};
  border-color: ${alertRed};
`

const Fade = css`
  color: ${subduedColor};
`

export const StyledNumberInput = styled(TextInput)<{
  error?: boolean
  fade?: boolean
  alert?: boolean
}>`
  ${({ fade }) => fade && Fade}
  ${({ error }) => error && ErrorStyle}
  ${({ alert }) => alert && ErrorStyle}
  height: 2rem;
  border-width: 0.125rem;
  border-color: ${borderColor};
  background-color: ${primaryBackgroundColor};
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }
`

const ErrorText = styled.p`
  ${ErrorStyle}
`

export default SurescriptsNumericInput
