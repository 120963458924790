import { LaunchDarkly } from '@truepill/tpos-types'
import { useFlag } from 'providers/LaunchDarklyProvider'
import NotesCreateOrUpdateAlternative from './NotesCreateOrUpdateAlternative'
import NotesCreateOrUpdateOriginal from './NotesCreateOrUpdateOriginal'
import type { NotesCreateOrUpdateProps } from './types'

const NotesCreateOrUpdate = (props: NotesCreateOrUpdateProps): JSX.Element => {
  const supportCSANotes = useFlag(LaunchDarkly.FeatureFlags.TEMP_SUPPORT_CSA_NOTES)
  if (supportCSANotes) {
    return <NotesCreateOrUpdateAlternative {...props} />
  }
  return <NotesCreateOrUpdateOriginal {...props} />
}

export default NotesCreateOrUpdate
