import { createContext, useReducer, useCallback } from 'react'
import type { Spec } from 'immutability-helper'
import update from 'immutability-helper'
import type { FormUpdate } from 'providers/Store/FormDataStore'
import { defaultState as formData } from 'providers/Store/FormDataStore'
import type { ChildProps } from 'types'

interface StoreState {
  formData: typeof formData
  rxImageIsPic: boolean
}

const defaultState: StoreState = {
  formData,
  rxImageIsPic: false,
}

export type StoreContextType = {
  state: StoreState
  actions: {
    updateFormData: (update: FormUpdate) => void
    clearFormData: () => void
    setRxImageIsPic: (value: boolean) => void
  }
}

export const StoreContext = createContext({
  state: defaultState,
  actions: {
    updateFormData: (update: FormUpdate) => {
      console.debug('Store Provider not mounted', update)
    },
    clearFormData: () => {
      console.debug('Store Provider not mounted for clearing')
    },
    setRxImageIsPic: (value: boolean) => {
      console.debug('Store Provider not mounted', value)
    },
  },
})

interface UpdateAction {
  type: 'update-form-data'
  payload: {
    update: FormUpdate
  }
}

interface ClearAction {
  type: 'clear-form-data'
}

interface SetRxImageIsPicAction {
  type: 'set-rx-image-is-pic'
  payload: {
    value: boolean
  }
}

type StoreAction = UpdateAction | ClearAction | SetRxImageIsPicAction

const StoreReducer = (state: StoreState, action: StoreAction): StoreState => {
  switch (action.type) {
    case 'update-form-data': {
      return update<StoreState>(state, { formData: action.payload.update } as Spec<StoreState>)
    }
    case 'set-rx-image-is-pic': {
      return update(state, { rxImageIsPic: { $set: action.payload.value } })
    }
    case 'clear-form-data':
      return defaultState
    default:
      return state
  }
}

type ActionsType = {
  updateFormData: (update: FormUpdate) => void
  clearFormData: () => void
  setRxImageIsPic: (value: boolean) => void
}

const StoreProvider = ({ children }: ChildProps): JSX.Element => {
  const [state, dispatch] = useReducer(StoreReducer, defaultState)

  const updateFormData = useCallback(
    (update: FormUpdate) => dispatch({ type: 'update-form-data', payload: { update } }),
    [],
  )

  const clearFormData = useCallback(() => dispatch({ type: 'clear-form-data' }), [])

  const setRxImageIsPic = useCallback(
    (value: boolean) => dispatch({ type: 'set-rx-image-is-pic', payload: { value } }),
    [],
  )

  const actions: ActionsType = {
    updateFormData,
    clearFormData,
    setRxImageIsPic,
  }

  return <StoreContext.Provider value={{ state, actions }}>{children}</StoreContext.Provider>
}

export type { StoreState, ActionsType }
export default StoreProvider
