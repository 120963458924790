import { useState } from 'react'
import { BannerAlert, Text } from '@truepill/react-capsule'
import { Link } from '@truepill/tpos-react-router'
import { darkOrange } from 'styles/styleVariables'

interface ActivePriorAuthorizationProps {
  reference: string
}
const ActivePriorAuthorization = ({ reference }: ActivePriorAuthorizationProps): JSX.Element => {
  const [isBannerVisible, setIsBannerVisible] = useState(true)
  return (
    <BannerAlert
      css={{ '$$banner-border': 'none' }}
      state="warning"
      visible={isBannerVisible}
      onDismiss={() => setIsBannerVisible(false)}
    >
      <Text variant="body-sm">
        There is an open PA for this prescription. Click&nbsp;
        <Link
          style={{ fontWeight: 'bold', textDecoration: 'underline', color: darkOrange }}
          target="_blank"
          to={reference}
        >
          here
        </Link>
        &nbsp;to go to the PA page.
      </Text>
    </BannerAlert>
  )
}

export default ActivePriorAuthorization
