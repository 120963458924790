export enum TaxonomyCode {
  PharmacyType = '3336',
  Pharmacy = '333600000X',
  ClinicPharmacy = '3336C0002X',
  CommunityRetailPharmacy = '3336C0003X',
  CompoundingPharmacy = '3336C0004X',
  HomeInfusionPharmacy = '3336H0001X',
  InstitutionalPharmacy = '3336I0012X',
  LongTermCarePharmacy = '3336L0003X',
  MailOrderPharmacy = '3336M0002X',
  ManagedCarePharmacy = '3336M0003X',
  NuclearPharmacy = '3336N0007X',
  SpecialtyPharmacy = '3336S0011X',
}
