import type { QueryResult } from '@truepill/tpos-react-router'
import { useQuery } from '@truepill/tpos-react-router'
import { LIST_FULFILLMENT_ORDERS } from 'gql'
import type { Order } from 'types'

export interface GetFulfillmentOrdersParams {
  orderNumber?: number
  customerIds?: string[]
  locationId?: string
  paymentType?: string
  selectedLocationIds?: string[]
  patientIds?: string[]
  inTriage?: boolean
  triageReasons?: string[]
  fillStages?: string[]
  orderStages?: string[]
  pageNumber?: number
  pageSize?: number
  ndcs?: string[]
  patientName?: string
  patientDob?: string
  drugName?: string
  medicationNames?: string[]
  hasOverdueFills?: boolean
  hasRobotFills?: boolean
  autobotType?: string
  includeTriage?: boolean
  isBatch?: boolean
  isReservedForInvestigation?: boolean
  customerRef?: string
  hasNoPrints?: boolean
  completeAutomation?: boolean
  rxFillCode?: string
  sortField?: string
  sort?: number
  coreOrderToken?: string
  coreFillRequestToken?: string
  rejectCodes?: string[]
  isOtcOnly?: boolean
  isRxOtcOnly?: boolean
  specialHandlingTags?: string[]
  states?: string[]
}

export interface GetFulfillmentOrdersResult {
  getFulfillmentOrders: {
    currentPage: number
    totalRecords: number
    orders: Order[]
  }
}

const useFulfillmentOrders = (
  orderQueryVariables: GetFulfillmentOrdersParams,
): QueryResult<GetFulfillmentOrdersResult, GetFulfillmentOrdersParams> => {
  return useQuery<GetFulfillmentOrdersResult, GetFulfillmentOrdersParams>(LIST_FULFILLMENT_ORDERS, {
    variables: orderQueryVariables,
    fetchPolicy: 'network-only',
  })
}

export default useFulfillmentOrders
