import HotKeyToolTip from 'components/HotKeyToolTip'
import { ListRowLabel, ListRowValue, RaisedAnchor, RightFilledHeader, PaddingBlock } from 'components/RXTable'
import useHotKey, { HotKeyLevel } from 'hooks/useHotKey'
import { usePlusClient } from 'providers/VisionRouter'
import type { EdiOverrides } from 'types'
import { RXEDIListRow, RXEDImageCell, RXEDIRightEditCell, RXEDIRightTitleCell, RXEDITitleRow, RXTextInput } from '..'

type ClaimSegmentProps = {
  ediOverrides: EdiOverrides
  updateEdiOverrides: (input: EdiOverrides) => void
}

const ClaimSegment = ({ ediOverrides, updateEdiOverrides }: ClaimSegmentProps): JSX.Element => {
  const { claim } = ediOverrides

  const scheduledPrescriptionIdNumber = claim?.scheduledPrescriptionIdNumber
  const daysSupply = claim?.daysSupply
  const intermediaryAuthorizationTypeId = claim?.intermediaryAuthorizationTypeId
  const intermediaryAuthorizationId = claim?.intermediaryAuthorizationId
  const pharmacyServiceType = claim?.pharmacyServiceType

  return (
    <>
      <ClaimHeaderRow />
      <RXEDIListRow>
        <RXEDIRightEditCell>
          <ListRowLabel>Days Supply (D5):</ListRowLabel>
          <ListRowValue>
            <RXTextInput
              value={daysSupply ?? ''}
              onChange={({ target: { value } }) => {
                updateEdiOverrides({ claim: { daysSupply: !isNaN(Number(value)) ? Number(value) : undefined } })
              }}
              placeholder="Day Supply..."
            />
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>

      <RXEDIListRow>
        <RXEDIRightEditCell>
          <ListRowLabel>Scheduled Prescription Id Number (EK):</ListRowLabel>
          <ListRowValue>
            <RXTextInput
              value={scheduledPrescriptionIdNumber ?? ''}
              onChange={({ target: { value: scheduledPrescriptionIdNumber } }) =>
                updateEdiOverrides({ claim: { scheduledPrescriptionIdNumber } })
              }
              placeholder="Scheduled Prescription Id Number..."
            />
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>

      <RXEDIListRow>
        <RXEDIRightEditCell>
          <ListRowLabel>Intermediary Authorization Type Id (EW):</ListRowLabel>
          <ListRowValue>
            <RXTextInput
              value={intermediaryAuthorizationTypeId ?? ''}
              onChange={({ target: { value: intermediaryAuthorizationTypeId } }) =>
                updateEdiOverrides({ claim: { intermediaryAuthorizationTypeId } })
              }
              placeholder="Intermediary Authorization Type Id..."
            />
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>

      <RXEDIListRow>
        <RXEDIRightEditCell>
          <ListRowLabel>Intermediary Authorization Id (EX):</ListRowLabel>
          <ListRowValue>
            <RXTextInput
              value={intermediaryAuthorizationId ?? ''}
              onChange={({ target: { value: intermediaryAuthorizationId } }) =>
                updateEdiOverrides({ claim: { intermediaryAuthorizationId } })
              }
              placeholder="Intermediary Authorization Id..."
            />
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>

      <RXEDIListRow>
        <RXEDIRightEditCell>
          <ListRowLabel>Pharmacy Service Type (U7):</ListRowLabel>
          <ListRowValue>
            <RXTextInput
              value={pharmacyServiceType ?? ''}
              onChange={({ target: { value: pharmacyServiceType } }) =>
                updateEdiOverrides({ claim: { pharmacyServiceType } })
              }
              placeholder="Pharmacy Service Type..."
            />
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>
    </>
  )
}

const ClaimHeaderRow = () => {
  const { routeToHash } = usePlusClient()

  useHotKey('4', HotKeyLevel.normal, () => {
    routeToHash(`ClaimSegment`)
  })

  return (
    <RXEDITitleRow>
      <RXEDImageCell />
      <RXEDIRightTitleCell>
        <PaddingBlock />
        <RightFilledHeader>
          <RaisedAnchor id={'ClaimSegment'} />
          Claim segment (07)
          <HotKeyToolTip label={'4'} position={'right'} offsetLeft={1} offsetTop={0} />
        </RightFilledHeader>
      </RXEDIRightTitleCell>
    </RXEDITitleRow>
  )
}

export default ClaimSegment
