import { ReactComponent as FalsePillLogo } from 'assets/nav/faslepillLogo.svg'
import { ReactComponent as Logo } from 'assets/nav/logo.svg'
import { useGetProductionEnvironment } from 'hooks/useGetProductionEnvironment'
import styled from 'styled-components'

const StyledLogo = styled(Logo)`
  width: 105px;
  height: 50px;
  align-self: center;
`

const StyledFalsePillLogo = styled(FalsePillLogo)`
  width: 105px;
  height: 50px;
  align-self: center;
`

const TruepillLogo = (): JSX.Element => {
  const isProductionEnvironment = useGetProductionEnvironment()

  return isProductionEnvironment ? <StyledLogo /> : <StyledFalsePillLogo />
}

export default TruepillLogo
