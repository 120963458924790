import { ListRowLabel, ListRowValue, RaisedAnchor, RightFilledHeader, PaddingBlock } from 'components/RXTable'
import type { EdiOverrides } from 'types'
import { RXEDIListRow, RXEDImageCell, RXEDIRightEditCell, RXEDIRightTitleCell, RXEDITitleRow, RXTextInput } from '..'

type FacilitySegmentProps = {
  ediOverrides: EdiOverrides
  updateEdiOverrides: (input: EdiOverrides) => void
}

const FacilitySegment = ({ ediOverrides, updateEdiOverrides }: FacilitySegmentProps): JSX.Element => {
  const { facility } = ediOverrides

  const facilityId = facility?.facilityId
  const facilityName = facility?.facilityName
  const facilityStreetAddress = facility?.facilityStreetAddress
  const facilityCityAddress = facility?.facilityCityAddress
  const facilityStateProvinceAddress = facility?.facilityStateProvinceAddress
  const facilityZipPostalCode = facility?.facilityZipPostalCode

  return (
    <>
      <FacilityHeaderRow />
      <RXEDIListRow>
        <RXEDIRightEditCell>
          <ListRowLabel>Facility Id (8C):</ListRowLabel>
          <ListRowValue>
            <RXTextInput
              value={facilityId ?? ''}
              onChange={({ target: { value: facilityId } }) => updateEdiOverrides({ facility: { facilityId } })}
              placeholder="Facility Id..."
            />
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>

      <RXEDIListRow>
        <RXEDIRightEditCell>
          <ListRowLabel>Facility Name (3Q):</ListRowLabel>
          <ListRowValue>
            <RXTextInput
              value={facilityName ?? ''}
              onChange={({ target: { value: facilityName } }) => updateEdiOverrides({ facility: { facilityName } })}
              placeholder="Facility Name..."
            />
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>

      <RXEDIListRow>
        <RXEDIRightEditCell>
          <ListRowLabel>Facility Street Address (3U):</ListRowLabel>
          <ListRowValue>
            <RXTextInput
              value={facilityStreetAddress ?? ''}
              onChange={({ target: { value: facilityStreetAddress } }) =>
                updateEdiOverrides({ facility: { facilityStreetAddress } })
              }
              placeholder="Facility Street Address..."
            />
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>

      <RXEDIListRow>
        <RXEDIRightEditCell>
          <ListRowLabel>Facility City Address (5J):</ListRowLabel>
          <ListRowValue>
            <RXTextInput
              value={facilityCityAddress ?? ''}
              onChange={({ target: { value: facilityCityAddress } }) =>
                updateEdiOverrides({ facility: { facilityCityAddress } })
              }
              placeholder="Facility City Address..."
            />
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>

      <RXEDIListRow>
        <RXEDIRightEditCell>
          <ListRowLabel>Facility State/Province Address (3V):</ListRowLabel>
          <ListRowValue>
            <RXTextInput
              value={facilityStateProvinceAddress ?? ''}
              onChange={({ target: { value: facilityStateProvinceAddress } }) =>
                updateEdiOverrides({ facility: { facilityStateProvinceAddress } })
              }
              placeholder="Facility State/Province Address..."
            />
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>

      <RXEDIListRow>
        <RXEDIRightEditCell>
          <ListRowLabel>Facility Zip Postal Code (6D):</ListRowLabel>
          <ListRowValue>
            <RXTextInput
              value={facilityZipPostalCode ?? ''}
              onChange={({ target: { value: facilityZipPostalCode } }) =>
                updateEdiOverrides({ facility: { facilityZipPostalCode } })
              }
              placeholder="Facility Zip Postal Code..."
            />
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>
    </>
  )
}

const FacilityHeaderRow = () => {
  return (
    <RXEDITitleRow>
      <RXEDImageCell />
      <RXEDIRightTitleCell>
        <PaddingBlock />
        <RightFilledHeader>
          <RaisedAnchor id={'FacilitySegment'} />
          Facility segment
          {/* <HotKeyToolTip label={'?'} position={'right'} offsetLeft={1} offsetTop={0} /> */}
        </RightFilledHeader>
      </RXEDIRightTitleCell>
    </RXEDITitleRow>
  )
}

export default FacilitySegment
