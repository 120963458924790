import { FillStatus } from '@truepill/tpos-types'
import type { FillData } from '@truepill/tpos-types'

export const getLastCompletedFill = (fills: FillData[]): FillData | undefined => {
  for (let i = fills.length - 1; i >= 0; i--) {
    if (fills[i].status === FillStatus.Complete) {
      return fills[i]
    }
  }
}
