import styled from 'styled-components'
import { subduedColor } from 'styles/styleVariables'

const IconTile = styled.span<{ backgroundColor?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${subduedColor};
  height: 20px;
  width: 20px;
  border-radius: 2px;
  ${({ backgroundColor }) => backgroundColor && `background-color: ${backgroundColor};`}
`

export default IconTile
