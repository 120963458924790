import type { EntityId, WithStamps } from '../shared'

export enum PrintReason {
  Preview = 'Preview',
  LabelPrint = 'Label Print',
  ReplacementLabel = 'Replacement label',
  AdditionalLabelsRequired = 'Additional labels required',
  DispensedItemChanged = 'Dispensed item changed',
  SigChange = 'Sig Change',
  EditDirections = 'Edit directions',
}

export interface PrintHistory extends WithStamps {
  _id: EntityId
  printedBy: string
  printedAt: Date
  sentTo: string
  reason: PrintReason
  printedDoc?: EntityId
}
