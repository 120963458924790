import type { OperationVariables, QueryLazyOptions, LazyQueryResult, ApolloError } from '@truepill/tpos-react-router'
import { useLazyQuery } from '@truepill/tpos-react-router'
import { SEARCH_SIG_CODE } from 'gql'

interface SigCodeQueryData {
  searchSigCode: {
    code: string
    text: string
    accurateTranslation: boolean
  }
}

interface UseSearchSigCodeParams {
  onCompleted?: (sigCodeData: SigCodeQueryData) => void
  onError?: (error: ApolloError) => void
}

const searchSigCode = (
  search: (
    options?: QueryLazyOptions<OperationVariables> | undefined,
  ) => Promise<LazyQueryResult<SigCodeQueryData, OperationVariables>>,
) => {
  return (code: string, patientId: string) =>
    search({
      variables: {
        code,
        patientId,
      },
    })
}

export const useSearchSigCode = (sigCodeParams?: UseSearchSigCodeParams) => {
  const [search, { data, loading, error }] = useLazyQuery<SigCodeQueryData>(SEARCH_SIG_CODE, {
    onCompleted: sigCodeParams?.onCompleted,
    onError: sigCodeParams?.onError,
  })

  return {
    searchSigCode: searchSigCode(search),
    result: data?.searchSigCode,
    loading,
    error,
  }
}
