import type { Ref } from 'react'
import { useState, useEffect } from 'react'
import { useMutation } from '@truepill/tpos-react-router'
import type { TriageReasons } from '@truepill/tpos-types'
import {
  MiscellaneousTriageReasons,
  OrderStatus,
  AdjudicationTriageReasons,
  PV1TriageReasons,
  PV1TriageReasonsRestricted,
  FillTriageReasons,
  PV2TriageReasons,
  CopayTriageReasons,
  AutomationTriageReasons,
  ManualTestingTriageReasons,
  OrderTriageReasons,
} from '@truepill/tpos-types'
import { ReactComponent as DangerIcon } from 'assets/icons/danger.svg'
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg'
import { SaveButton } from 'components/ActionButton'
import IconWrapper from 'components/IconWrapper'
import { ModalHeader, ModalWrapper } from 'components/Modal'
import { ActionButton, CancelButton } from 'components/PageStructure'
import { StyledSelect } from 'components/RXTable'
import { SET_IN_TRIAGE, SET_COPAY_IN_TRIAGE } from 'gql'
import { TextArea, Box } from 'grommet'
import useErrorToast from 'hooks/toast/useErrorToast'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import useAutoFocus from 'hooks/useAutoFocus'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import { update } from 'ramda'
import styled from 'styled-components'
import { bodyPrimaryColor, borderColor, primaryBackgroundColor } from 'styles/styleVariables'
import type { QueueItem, OrderTriage, RxChangeTriage } from 'types'
import { isOrder } from 'utils'

const TRIAGE_REASONS = new Map<OrderStatus | undefined, string[] | undefined>([
  [
    OrderStatus.Adjudication,
    [
      ...Object.values(AdjudicationTriageReasons),
      ...Object.values(ManualTestingTriageReasons),
      OrderTriageReasons.ReplacementOrder,
    ],
  ],
  [OrderStatus.Automation, [...Object.values(AutomationTriageReasons), ...Object.values(ManualTestingTriageReasons)]],
  [
    OrderStatus.PriorAuthorization,
    [...Object.values(AdjudicationTriageReasons), ...Object.values(ManualTestingTriageReasons)],
  ],
  [
    OrderStatus.PV1,
    [
      ...Object.values(PV1TriageReasonsRestricted),
      ...Object.values(PV1TriageReasons).filter(
        triageReason => triageReason !== PV1TriageReasons.PatientOutreachRequired,
      ),
      MiscellaneousTriageReasons.Other,
      FillTriageReasons.OOS,
      FillTriageReasons.NeedsReplenishment,
      OrderTriageReasons.ReplacementOrder,
      ...Object.values(ManualTestingTriageReasons),
    ],
  ],
  [
    OrderStatus.Fill,
    [
      ...Object.values(FillTriageReasons),
      MiscellaneousTriageReasons.Other,
      ...Object.values(ManualTestingTriageReasons),
    ],
  ],
  [
    OrderStatus.PV2,
    [
      ...Object.values(PV2TriageReasons),
      MiscellaneousTriageReasons.Other,
      ...Object.values(ManualTestingTriageReasons),
    ],
  ],
  [
    OrderStatus.Packing,
    [
      OrderTriageReasons.OOS,
      MiscellaneousTriageReasons.Other,
      FillTriageReasons.WeatherDelay,
      ...Object.values(ManualTestingTriageReasons),
    ],
  ],
])

interface TriageEntry {
  reason: TriageReasons | string
  selectedReason?: string
  inTriageUserMessage?: string
  fillId?: string
  rxChange?: RxChangeTriage
}

interface FieldsProps {
  index: number
  value: TriageEntry
  item: QueueItem
  fillId?: string
  onChange: (triageEntry: TriageEntry, index: number) => void
}
const defaultTriageReasons = [MiscellaneousTriageReasons.Other]

const TriageEntryFields = (props: FieldsProps): JSX.Element => {
  const { item } = props
  const itemIsOrder = isOrder(item)
  const autoFocusRef = useAutoFocus()

  const [triageReasons, setTriageReasons] = useState<string[]>(defaultTriageReasons)

  useEffect(() => {
    setTriageReasons(
      itemIsOrder
        ? TRIAGE_REASONS.get(item.status)?.sort() ?? defaultTriageReasons
        : [...Object.values(CopayTriageReasons)],
    )
  }, [item.status, itemIsOrder])

  return (
    <InputContainer data-index={props.index}>
      {props.index > 0 && <TriageIndexHeader>Triage reason {props.index + 1}</TriageIndexHeader>}
      <ReasonSelect
        data-testid="reason"
        ref={autoFocusRef as Ref<HTMLButtonElement>}
        multiple={false}
        maximumHeight="12rem"
        modal={true}
        value={props.value.selectedReason}
        placeholder="Select a triage reason..."
        options={triageReasons}
        onChange={([option]) => {
          const selectedReason = option ? option.value : ''
          props.onChange({ ...props.value, reason: selectedReason, selectedReason, fillId: props.fillId }, props.index)
        }}
      />
      <TextArea
        data-testid="message"
        onChange={event =>
          props.onChange({ ...props.value, inTriageUserMessage: event.target.value, fillId: props.fillId }, props.index)
        }
        placeholder="Type a message..."
        value={props.value.inTriageUserMessage}
        resize="vertical"
      />
    </InputContainer>
  )
}

const defaultTriageEntry = (): TriageEntry => ({
  reason: '',
})

type TriagePayload = Pick<OrderTriage, 'fillId' | 'reason' | 'inTriageUserMessage' | 'rxChange'>
type TriageModalProps = {
  fillId?: string
  item: QueueItem
  confirmationCallback: () => void
  onDismiss?: () => void
}

const TriageModal = ({ fillId, item, confirmationCallback, onDismiss }: TriageModalProps): JSX.Element => {
  const [triages, setTriages] = useState<TriageEntry[]>([defaultTriageEntry()])
  const { dismissModal } = useModalContext()

  const [setInTriageMutation] = useMutation<
    {
      setInTriage: { _id: string; inTriage: { reason: TriageReasons } }
      status: OrderStatus
      triages: TriagePayload[]
    },
    { orderId: string; triages: TriagePayload[] }
  >(SET_IN_TRIAGE, {
    onCompleted: () => {
      showSuccessToast(`Set triage for order ${item._id}`)
      confirmationCallback()
      dismissModal()
    },
    onError: error => {
      console.error('Failed to set triage:', error)
      showErrorToast(`Failed to set triage for order ${item._id}: ${error?.message ?? error}`)
      dismissModal()
    },
  })

  const [setCopayInTriageMutation] = useMutation<{ copayRequestId: string; triages: TriagePayload[] }>(
    SET_COPAY_IN_TRIAGE,
    {
      onCompleted: () => {
        showSuccessToast(`Set triage for copay ${item._id}`)
        confirmationCallback()
        dismissModal()
      },
      onError: error => {
        console.error('Failed to set triage:', error)
        showErrorToast(`Failed to set triage for copay ${item._id}: ${error?.message ?? error}`)
        dismissModal()
      },
    },
  )

  const showErrorToast = useErrorToast()
  const showSuccessToast = useSuccessToast()

  const reasonGiven = triages[0].reason !== ''

  const handleTriageUpdate = (updatedTriage: TriageEntry, index: number) => {
    const updatedTriageEntries = update(index, updatedTriage, triages)
    setTriages(updatedTriageEntries)
  }
  const handleAddTriage = () => setTriages([...triages, defaultTriageEntry()])

  return (
    <ModalWrapper id="TriageModal">
      <ModalHeader closeCallback={onDismiss}>
        <IconWrapper>
          <DangerIcon fill={bodyPrimaryColor} />
        </IconWrapper>
        <h2>Triage reason</h2>
      </ModalHeader>
      {triages.map((triageEntry, index) => (
        <TriageEntryFields
          item={item}
          key={index}
          index={index}
          value={triageEntry}
          fillId={fillId}
          onChange={handleTriageUpdate}
        />
      ))}
      <Box margin={{ top: '1rem' }} width={'15rem'}>
        <StyledActionButton
          data-testid="add-reason"
          icon={
            <IconWrapper>
              <PlusIcon fill="black" />
            </IconWrapper>
          }
          label="Add another triage reason"
          onClick={handleAddTriage}
        />
      </Box>
      <ButtonsContainer>
        <CancelButton
          label="Cancel"
          onClick={() => {
            dismissModal()
            onDismiss && onDismiss()
          }}
        />
        <SaveButton
          isModal
          disabled={!reasonGiven}
          label="Submit"
          onClick={async () => {
            const filteredTriages = triages.map(({ reason, fillId, inTriageUserMessage, rxChange }) => {
              // Compose the reason and details into the enum keys by removing
              // the spaces added in for display readability
              if (rxChange?.reason) rxChange.reason = rxChange.reason.split(' ').join('')
              if (rxChange?.details) rxChange.details = rxChange.details.map(detail => detail.split(' ').join(''))
              // at this point, because this can only be clicked if reasonGiven is true, then we can safely assert
              // reason is not an empty string and thus is a TriageReason
              return { reason: reason as TriageReasons, fillId, inTriageUserMessage, rxChange }
            })

            if (isOrder(item)) {
              await setInTriageMutation({ variables: { orderId: item._id, triages: filteredTriages } })
            } else {
              await setCopayInTriageMutation({ variables: { copayRequestId: item._id, triages: filteredTriages } })
            }
          }}
        />
      </ButtonsContainer>
    </ModalWrapper>
  )
}

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1.25rem;
`

const TriageIndexHeader = styled.h3`
  margin: 1.25rem 0 1.125rem;
`

const InputContainer = styled.div`
  width: 24rem;
  margin-top: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  > textarea {
    padding-top: 0.3125rem;
    height: 7rem;
    margin-top: 0.625rem;
    border: 0.125rem solid ${borderColor};
  }
`

const StyledActionButton = styled(ActionButton)`
  margin-left: 0rem;
  background-color: ${primaryBackgroundColor};
`

const ReasonSelect = styled(StyledSelect)`
  height: 2.5rem;
`

export default TriageModal
