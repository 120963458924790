import { useReactiveVar } from '@truepill/tpos-react-router'
import HotKeyToolTip from 'components/HotKeyToolTip'
import { NumericInput } from 'components/NumericInput'
import {
  StyledRXImageCell,
  ImageFilledHeader,
  RightFilledHeader,
  ListRowLabel,
  ListRowValue,
  RaisedAnchor,
} from 'components/RXTable'
import { USDInput as UsdInput } from 'components/UsdInput'
import { claimsRequest } from 'hooks/navigation/useClaim'
import useHotKey, { HotKeyLevel } from 'hooks/useHotKey'
import { usePlusClient } from 'providers/VisionRouter'
import type { ClaimsRequest, EdiOverrides } from 'types'
import { checkIfNull, usdFormatter } from 'utils'
import { RXTextInput, RXEDIListRow, RXEDImageCell, RXEDIRightEditCell, RXEDIRightTitleCell, RXEDITitleRow } from '..'

type PricingSegmentProps = {
  ediOverrides: EdiOverrides
  updateEdiOverrides: (input: EdiOverrides) => void
}

const PricingSegment = ({ ediOverrides, updateEdiOverrides }: PricingSegmentProps): JSX.Element => {
  const { originalClaimsRequest = {} }: ClaimsRequest = (useReactiveVar(claimsRequest) as ClaimsRequest) ?? {}

  const { pricing } = ediOverrides

  const dispensingFeeSubmitted = pricing?.dispensingFeeSubmitted
  const grossAmountDue = pricing?.grossAmountDue
  const ingredientCostSubmitted = pricing?.ingredientCostSubmitted
  const usualAndCustomaryCharge = pricing?.usualAndCustomaryCharge
  const basisOfCostDetermination = pricing?.basisOfCostDetermination
  const flatSalesTax = pricing?.flatSalesTax
  const patientPayAmount = pricing?.patientPayAmount
  const percentageSalesTaxAmount = pricing?.percentageSalesTaxAmount
  const percentageSalesTaxRate = pricing?.percentageSalesTaxRate
  const percentageSalesTaxBasis = pricing?.percentageSalesTaxBasis

  return (
    <>
      <PricingHeaderRow />
      <RXEDIListRow>
        <RXEDImageCell closeOffTopBorder>
          <ListRowLabel>Ingredient cost submitted (D9):</ListRowLabel>
          <ListRowValue>
            {usdFormatter.format(originalClaimsRequest?.pricing?.ingredientCostSubmitted ?? 0)}
          </ListRowValue>
        </RXEDImageCell>
        <RXEDIRightEditCell>
          <ListRowLabel>Ingredient cost submitted (D9):</ListRowLabel>
          <ListRowValue>
            <UsdInput
              value={ingredientCostSubmitted}
              onChange={ingredientCostSubmitted => updateEdiOverrides({ pricing: { ingredientCostSubmitted } })}
              placeholder="Ingredient cost submitted..."
            />
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>
      <RXEDIListRow>
        <RXEDImageCell>
          <ListRowLabel>Dispensing fee submitted (DC):</ListRowLabel>
          <ListRowValue>
            {usdFormatter.format(originalClaimsRequest?.pricing?.dispensingFeeSubmitted ?? 0)}
          </ListRowValue>
        </RXEDImageCell>
        <RXEDIRightEditCell>
          <ListRowLabel>Dispensing fee submitted (DC):</ListRowLabel>
          <ListRowValue>
            <UsdInput
              value={dispensingFeeSubmitted}
              onChange={dispensingFeeSubmitted => updateEdiOverrides({ pricing: { dispensingFeeSubmitted } })}
              placeholder="Dispensing fee submitted..."
            />
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>
      <RXEDIListRow>
        <RXEDImageCell>
          <ListRowLabel>Usual and customary charge (DQ):</ListRowLabel>
          <ListRowValue>
            {usdFormatter.format(originalClaimsRequest?.pricing?.usualAndCustomaryCharge ?? 0)}
          </ListRowValue>
        </RXEDImageCell>
        <RXEDIRightEditCell>
          <ListRowLabel>Usual and customary charge (DQ):</ListRowLabel>
          <ListRowValue>
            <UsdInput
              value={usualAndCustomaryCharge}
              onChange={usualAndCustomaryCharge => updateEdiOverrides({ pricing: { usualAndCustomaryCharge } })}
              placeholder="Usual and customary charge..."
            />
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>
      <RXEDIListRow>
        <RXEDImageCell>
          <ListRowLabel>Gross amount due (DU):</ListRowLabel>
          <ListRowValue>{usdFormatter.format(originalClaimsRequest?.pricing?.grossAmountDue ?? 0)}</ListRowValue>
        </RXEDImageCell>
        <RXEDIRightEditCell>
          <ListRowLabel>Gross amount due (DU):</ListRowLabel>
          <ListRowValue>
            <UsdInput
              value={grossAmountDue}
              onChange={grossAmountDue => updateEdiOverrides({ pricing: { grossAmountDue } })}
              placeholder="Gross amount due..."
            />
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>
      <RXEDIListRow>
        <RXEDImageCell>
          <ListRowLabel>Basis of cost determination (DN):</ListRowLabel>
          <ListRowValue>{checkIfNull(originalClaimsRequest?.pricing?.basisOfCostDetermination)}</ListRowValue>
        </RXEDImageCell>
        <RXEDIRightEditCell closeOffBottomBorder>
          <ListRowLabel>Basis of cost determination (DN):</ListRowLabel>
          <ListRowValue>
            <RXTextInput
              value={basisOfCostDetermination ?? ''}
              onChange={({ target: { value: basisOfCostDetermination } }) =>
                updateEdiOverrides({ pricing: { basisOfCostDetermination } })
              }
              placeholder="Basis of cost determination..."
            />
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>

      <RXEDIListRow>
        <RXEDIRightEditCell>
          <ListRowLabel>Flat Sales Tax (HA):</ListRowLabel>
          <ListRowValue>
            <NumericInput
              value={flatSalesTax ?? undefined}
              onChange={val => updateEdiOverrides({ pricing: { flatSalesTax: val } })}
              placeholder="Flat Sales Tax..."
            />
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>

      <RXEDIListRow>
        <RXEDIRightEditCell>
          <ListRowLabel>Patient Pay Amount (D):</ListRowLabel>
          <ListRowValue>
            <UsdInput
              value={patientPayAmount}
              onChange={patientPayAmount => updateEdiOverrides({ pricing: { patientPayAmount } })}
              placeholder="Patient Pay Amount..."
            />
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>

      <RXEDIListRow>
        <RXEDIRightEditCell closeOffBottomBorder>
          <ListRowLabel>Percentage Sales Tax Amount (GE):</ListRowLabel>
          <ListRowValue>
            <NumericInput
              value={percentageSalesTaxAmount}
              onChange={val => updateEdiOverrides({ pricing: { percentageSalesTaxAmount: val } })}
              placeholder="Percentage Sales Tax Rate Amount..."
            />
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>

      <RXEDIListRow>
        <RXEDIRightEditCell closeOffBottomBorder>
          <ListRowLabel>Percentage Sales Tax Rate (HE):</ListRowLabel>
          <ListRowValue>
            <NumericInput
              value={percentageSalesTaxRate ?? undefined}
              onChange={val => updateEdiOverrides({ pricing: { percentageSalesTaxRate: val } })}
              placeholder="Percentage Sales Tax Rate..."
            />
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>

      <RXEDIListRow>
        <RXEDIRightEditCell closeOffBottomBorder>
          <ListRowLabel>Percentage Sales Tax Basis (JE):</ListRowLabel>
          <ListRowValue>
            <RXTextInput
              value={percentageSalesTaxBasis ?? ''}
              onChange={({ target: { value: percentageSalesTaxBasis } }) =>
                updateEdiOverrides({ pricing: { percentageSalesTaxBasis } })
              }
              placeholder="Percentage Sales Tax Basis..."
            />
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>
    </>
  )
}

const PricingHeaderRow = () => {
  const { routeToHash } = usePlusClient()

  useHotKey('5', HotKeyLevel.normal, () => {
    routeToHash(`PricingSegment`)
  })
  return (
    <RXEDITitleRow>
      <StyledRXImageCell noBorder>
        <ImageFilledHeader>Rx Image</ImageFilledHeader>
      </StyledRXImageCell>
      <RXEDIRightTitleCell>
        <RightFilledHeader>
          <RaisedAnchor id={'PricingSegment'} />
          Pricing segment (11)
          <HotKeyToolTip label={'5'} position={'right'} offsetLeft={1} offsetTop={0} />
        </RightFilledHeader>
      </RXEDIRightTitleCell>
    </RXEDITitleRow>
  )
}

export default PricingSegment
