import type { SelectedItem, Items } from 'providers/ReturnDialogProvider'
import type { Order, OTCProduct, Full } from 'types'
import { OTCShippingPayment } from 'types'

export interface OrderReturnVariables {
  orderId: Order['_id']
  otcProducts: { otcId: string; quantity: number }[]
  action: string
  reason: string
  // shippingCharge: string
  // shippingMethod: string
  shippingPayment: string
  shippingCostRefunded: number
  isABundle: boolean
  isEntireOrder: boolean
  name: string
}

// Build arguments for Create Return Mutations from the selections stored in
// a ReturnDialogProvider
function useOrderReturnVariables(
  selectedItems: Full<SelectedItem>[],
  items: Items,
  order?: Order,
): OrderReturnVariables[] {
  if (!order) {
    return []
  }

  // Whole Order selections
  if (selectedItems.length === 1) {
    const selectedItem = selectedItems[0]
    const product = items[selectedItem._id]
    if (product && product.type === 'order') {
      return generateWholeOrderReturn(order, selectedItem)
    }
  }

  const orderReturnVariables = []

  const bundles = order.otcProducts.reduce((acc: { [key: string]: OTCProduct[] }, otcProduct) => {
    if (otcProduct.bundleId) {
      if (!acc[otcProduct.bundleId]) {
        acc[otcProduct.bundleId] = []
      }
      acc[otcProduct.bundleId].push(otcProduct)
    }
    return acc
  }, {})

  for (const selectedItem of selectedItems) {
    const { reason, action, quantity, shippingPayment } = selectedItem
    const abstractProduct = items[selectedItem._id]
    let shippingCostRefunded = 0
    // Handle bundles
    if (!abstractProduct) {
      continue
    }
    if (abstractProduct.type === 'bundle') {
      const bundleItems = bundles[selectedItem._id]
      const otcs = bundleItems.map(({ _id, pricing }) => {
        if (shippingPayment === OTCShippingPayment.REFUND || shippingPayment === OTCShippingPayment.PARTIAL_REFUND) {
          shippingCostRefunded += pricing.shippingCost || 0
        }
        return { quantity, otcId: _id }
      })

      orderReturnVariables.push({
        otcProducts: otcs,
        orderId: order._id,
        reason,
        action,
        shippingPayment,
        shippingCostRefunded,
        isABundle: true,
        isEntireOrder: false,
        name: abstractProduct.name,
      })
    } else {
      // Handle loose items
      if (shippingPayment === OTCShippingPayment.REFUND) {
        shippingCostRefunded = abstractProduct.shippingCost
      } else if (shippingPayment === OTCShippingPayment.PARTIAL_REFUND) {
        shippingCostRefunded = (abstractProduct.shippingCost / abstractProduct.quantity) * quantity
      }

      orderReturnVariables.push({
        otcProducts: [
          {
            quantity,
            otcId: abstractProduct._id,
          },
        ],
        orderId: order._id,
        reason,
        action,
        shippingPayment,
        shippingCostRefunded,
        isABundle: false,
        isEntireOrder: false,
        name: abstractProduct.name,
      })
    }
  }

  return orderReturnVariables
}

function generateWholeOrderReturn(order: Order, selectedItem: Full<SelectedItem>): OrderReturnVariables[] {
  const { reason, action, shippingPayment } = selectedItem

  let shippingCostRefunded = 0
  const otcProducts = order.otcProducts.map(({ quantity, _id, pricing }) => {
    if (shippingPayment === OTCShippingPayment.REFUND) shippingCostRefunded += pricing.shippingCost || 0
    return {
      otcId: _id,
      quantity,
    }
  })

  return [
    {
      orderId: order._id,
      reason,
      action,
      shippingPayment,
      shippingCostRefunded,
      otcProducts,
      isABundle: false,
      isEntireOrder: true,
      name: 'Entire Order',
    },
  ]
}

export default useOrderReturnVariables
