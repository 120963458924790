import SearchInput from 'components/SearchInput'
import { bodySecondaryColor } from 'styles/styleVariables'

type NotesSearchProps = { searching?: boolean; setSearch: (value: any) => void }

const NotesSearch = ({ searching, setSearch }: NotesSearchProps): JSX.Element => {
  return (
    <>
      {searching && <SearchInput iconColor={bodySecondaryColor} onChange={setSearch} placeholder={'Search notes...'} />}
    </>
  )
}

export default NotesSearch
