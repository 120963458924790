import type { RouteProps } from '@truepill/tpos-react-router'
import { Route, Redirect } from '@truepill/tpos-react-router'
import type { UserRoles } from '@truepill/tpos-types'
import { usePlusClient } from 'providers/VisionRouter'
import { RoutePath } from 'routes'

type AuthenticatedRouteProps = RouteProps & { roles?: UserRoles[] }

const AuthenticatedRoute = ({ roles, ...rest }: AuthenticatedRouteProps): JSX.Element => {
  const {
    tokenContext: { hasRole },
  } = usePlusClient()

  if (roles && !hasRole(roles)) {
    return (
      <Route
        {...rest}
        render={({ location }) => (
          <Redirect
            to={{
              pathname: RoutePath.Login,
              state: { from: location },
            }}
          />
        )}
        component={undefined}
        // eslint-disable-next-line react/no-children-prop
        children={undefined}
      />
    )
  }

  return <Route {...rest} />
}
export default AuthenticatedRoute
