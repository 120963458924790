import { Select as CapsuleSelect } from '@truepill/react-capsule'
import Select from 'components/Select'
import { PaymentType } from 'types'

type PaymentTypeSelectProps = {
  onSelect: (paymentType: PaymentType) => void
  value?: string
  className?: string
  multipleFills?: boolean
  clearable?: boolean
  withCapsule?: boolean
}

const PaymentTypeSelect = ({
  onSelect,
  value,
  className,
  multipleFills,
  clearable,
  withCapsule,
}: PaymentTypeSelectProps): JSX.Element => {
  const allOptions = Object.keys(PaymentType).map(option => ({
    label: option,
    value: option,
  }))

  const options = multipleFills ? allOptions.filter(option => option.value !== 'split') : allOptions

  const selectedValue = value ? { label: value, value } : undefined

  return withCapsule ? (
    <CapsuleSelect
      label="Payment type"
      value={selectedValue}
      placeholder="Select payment method…"
      options={options}
      selectedKey="value"
      onChange={option => onSelect(option?.value as PaymentType)}
    />
  ) : (
    <Select
      disableClear={!clearable}
      value={selectedValue}
      className={className}
      placeholder="Select payment method…"
      options={options}
      onChange={([option]) => onSelect(option?.value as PaymentType)}
    />
  )
}

export default PaymentTypeSelect
