export function formatTree(dispensingDecisionData: any, daw: number) {
  const useJanusFormulary = dispensingDecisionData?.useJanusFormulary || false
  const janusResponse = dispensingDecisionData?.janusResponse
  const onJanusFormulary = dispensingDecisionData?.onFormulary || false
  return {
    data: {
      name: 'NDC product substitution process',
      attributes: {
        highlight: true,
      },
      children: [
        {
          name: 'Use Janus formulary',
          attributes: {
            description: `value is ${janusResponse?.formulary_status}`,
            highlight: useJanusFormulary,
          },
          children: [
            {
              name: 'On formulary and DAW is 0',
              attributes: {
                description: 'process substitution',
                highlight: useJanusFormulary && onJanusFormulary && daw === 0,
              },
              children: [
                {
                  name: 'DAR',
                  attributes: {
                    highlight: onJanusFormulary && dispensingDecisionData?.dispenseAsRequested,
                  },
                },
                {
                  name: 'Consigned NDC',
                  attributes: {
                    highlight: onJanusFormulary && dispensingDecisionData?.consigned,
                  },
                  children: [
                    {
                      name: 'Preferred',
                      attributes: {
                        highlight:
                          onJanusFormulary && dispensingDecisionData?.consigned && dispensingDecisionData?.preferred,
                      },
                    },
                    {
                      name: 'Not-preferred',
                      attributes: {
                        highlight: onJanusFormulary && !dispensingDecisionData?.preferred,
                      },
                    },
                    {
                      name: 'Out of Stock',
                      children: [{ name: 'Process default case' }],
                    },
                  ],
                },
              ],
            },
            {
              name: 'DAW > 0',
              attributes: {
                description: 'does not make a substitution',
                highlight: onJanusFormulary && daw > 0,
              },
              children: [
                {
                  name: 'Consigned NDC',
                  attributes: {
                    description: 'selects from consigned inventory',
                    highlight: onJanusFormulary && daw > 0 && dispensingDecisionData?.consigned,
                  },
                },
                {
                  name: 'No consigned NDC',
                  attributes: {
                    description: 'gets triaged',
                    highlight: onJanusFormulary && daw > 0 && !dispensingDecisionData?.consigned,
                  },
                },
              ],
            },
            {
              name: 'Off formulary and DAW is 0',
              attributes: {
                description: 'dispenses from default selection',
                highlight: !onJanusFormulary && daw === 0,
              },
              children: [
                {
                  name: 'Process default case',
                  attributes: {
                    highlight: !onJanusFormulary && daw === 0,
                  },
                },
              ],
            },
          ],
        },
        {
          name: 'Process default case',
          attributes: {
            highlight: !useJanusFormulary,
          },
          children: [
            {
              name: 'Preferred NDC',
              attributes: {
                highlight: !useJanusFormulary && dispensingDecisionData?.preferred,
              },
            },
            {
              name: 'Highest BoH',
              attributes: {
                highlight: !useJanusFormulary && !dispensingDecisionData?.preferred,
              },
            },
          ],
        },
      ],
    },
  }
}
