import { Button } from 'grommet'
import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css, keyframes } from 'styled-components'
import {
  bodyPrimaryColor,
  contrastBackgroundColor,
  primaryBackgroundColor,
  contrastColor,
  subduedColor,
  primaryColor,
} from 'styles/styleVariables'

export const CapsulePageHeading = styled.div.attrs(props => {
  return { id: props.id ?? 'PageHeading' }
})`
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
export const CapsulePageContainer = styled.div.attrs(props => {
  return { id: props.id ?? 'PageContainer' }
})`
  position: relative;
  background-color: ${primaryBackgroundColor};
  margin: 1.25rem 1.875rem;
`

export const CapsuleTitle = styled.h1`
  font-size: 1.25rem;
  font-family: Lato, sans-serif;
  font-weight: 700;
  line-height: 1.625rem;
  letter: -0.47 px;
`

export const PageHeading = styled.div.attrs(props => {
  return { id: props.id ?? 'PageHeading' }
})`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 1.875rem;
`
export const StickyPageHeadingContainer = styled.div.attrs(props => {
  return { id: props.id ?? 'PageHeadingContainer' }
})`
  position: sticky;
  top: 0em;
  background-color: ${primaryBackgroundColor};
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`

export const ActionButtonContainer = styled.span.attrs(props => {
  return { id: props.id ?? 'ActionButtonContainer' }
})`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

export const ActionBar = styled.div.attrs(props => {
  return { id: props.id ?? 'ActionBar' }
})`
  display: grid;
  grid-row: actionBar;
  grid-column: 1 / span 2;
  grid-template-rows: auto;
  grid-template-columns: [meta] 1fr [actions] auto;
  margin-bottom: 1rem;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
`

export const ToggleButton = styled(Button)<{ textHover?: boolean }>`
  width: 50%;
  border: none;
`

export const ActionButton = styled(Button)<{ textHover?: boolean }>`
  font-weight: 500;
  font-size: 1rem;
  height: 2.5rem;
  background-color: ${subduedColor};
  padding: 0rem 0.625rem;
  color: black;
  margin-left: 0.625rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  :focus {
    outline-color: ${primaryColor};
  }
`

export const NoResultsEntry = styled.div`
  display: flex;
  color: ${subduedColor};
  justify-content: center;
  align-items: center;
  min-height: 3.75rem;
`

export const SaveButton = styled(Button)`
  text-transform: capitalize;
  height: 2.5rem;
  margin-left: 0.5rem;
  background-color: ${primaryColor};
  color: ${primaryBackgroundColor};
  border: none;
`

export const CancelButton = styled(Button).attrs({
  'data-testid': 'cancel',
})`
  text-transform: capitalize;
  height: 2.5rem;
  margin-left: 0.5rem;
  color: ${bodyPrimaryColor};
  border: none;
`
export const GoToNewOrderButton = styled(ActionButton)`
  text-transform: capitalize;
  height: 2.5rem;
  margin-left: 0.5rem;
  background-color: ${primaryColor};
  color: ${primaryBackgroundColor};
  border: none;
`

export const PageContainer = styled.div.attrs(props => {
  return { id: props.id ?? 'PageContainer' }
})`
  position: relative;
  background-color: ${primaryBackgroundColor};
  margin-top: 1.25rem;
  margin-left: 1.875rem;
`

export const UnPaddedPageContainer = styled(PageContainer)`
  margin-top: 0rem;
`

export const AssistedFormPageContainer = styled.div`
  position: relative;
  flex: 1;
  display: grid;
  grid-template-rows: [header] auto [content] auto;
  grid-template-columns: [form] 25rem [assistant] 1fr;
  padding: 1.25rem;
`

export const FormPageContainer = styled.div`
  position: relative;
  flex: 1;
  padding: 1.25rem;
`

export const PageContent = styled.div.attrs(props => {
  return { id: props.id ?? 'PageContent' }
})`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`

export const SubTitle = styled.h4`
  font-weight: 500;
  font-size: 1.1em;
`

export const FilledHeadingStyle = css`
  font-weight: 500;
  border-radius: 0.25rem;
  padding: 0.1rem 0.625rem;
  background-color: ${contrastColor};
  color: white;
  border: 1px solid ${contrastColor};
`

export const FilledHeader = styled(SubTitle)`
  ${FilledHeadingStyle}
`

export const TransparentHeadingStyle = css`
  font-weight: 700;
  border-radius: 0;
  padding: 0.1rem 0.1rem;
  background-color: transparent;
  color: #000;
  font-family: Lato;
  font-size: 1.4rem;
  border: none;
`

export const TransparentHeader = styled(SubTitle)`
  ${TransparentHeadingStyle}
`

export const LargeButtonCard = styled.button`
  background-color: ${contrastBackgroundColor};
  width: 25rem;
  height: 11.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  :focus,
  :hover {
    outline-color: ${primaryColor};
    border: 2px solid ${primaryColor};
    border-radius: 4px;
  }
  :active {
    color: ${contrastColor};
  }
`

export const SinglePanePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 2.5rem;
`

const slideIn = keyframes`
  0% {
    overflow: hidden;
    max-width: 0rem;
  }
  99% {
    overflow: hidden;
    max-width: 30rem;
  }
  100% {
    overflow: hidden;
    flex: 1;
    max-width: 30rem;
  }
`

const slideOut = keyframes`
  0% {
    overflow: hidden;
    max-width: 25rem;
  }
  100% {
    overflow: hidden;
    max-width: 0rem;
  }
`

const SlideInAnimation = css`
  animation: ${slideIn} 200ms;
`

const SlideOutAnimation = css`
  animation: ${slideOut} 200ms;
`

const InstantDuration = css`
  animation-duration: 0ms;
`

export const Pane = styled.div<{
  visible: boolean
  firstLoad: boolean
}>`
  position: fixed;
  top: 3.75rem;
  right: 0rem;
  height: 100vh;
  width: 29rem;
  box-shadow: -2px 0px 2px rgba(0, 0, 0, 0.13);
  background-color: white;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  ${({ visible }) => (visible ? SlideInAnimation : SlideOutAnimation)};
  ${({ firstLoad }) => firstLoad && InstantDuration}
  animation-fill-mode: forwards;
`

export const PaneContents = styled.div<{ styles?: FlattenSimpleInterpolation }>`
  display: flex;
  position: fixed;
  height: 86.5vh;
  width: 27rem;
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 1;
  @media (min-width: 1700px) {
    height: 90vh;
  }
  ${({ styles }) => styles}
`
