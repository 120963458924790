import type { SendBackReason } from './SendBack'
import { SendBackToPv1FromPV2Reason } from './SendBack'

export enum SystemTriageReasons {
  RefillTooSoon = 'Refill too soon',
  RxChange = 'Rx change',
  RxClarification = 'Rx clarification',
  ExpiredDate = 'Expired date',
  EffectiveDateInFuture = 'Effective date in the future',
  NoGenderEntered = 'No Gender Entered For Patient',
  UnlicensedState = 'Location is not licensed to ship to this state',
  PreCheck = 'Pre Check needed',
  RxOrderDiscrepancy = 'Rx-Order Discrepancy',
}

export enum AdjudicationTriageReasons {
  InvalidAddress = 'Invalid Address',
  PricingNeeded = 'Pricing Needed',
  PriorAuthorizationRequired = 'Prior Authorization Required',
  CustomerClarificationNeeded = 'Customer Clarification Needed',
  OOS = 'Out of stock',
  ClinicalOutreachToMD = 'Clinical Outreach to MD',
  CustomerRequestedHold = 'Customer Requested Hold',
  RefillTooSoonRejection = 'Refill Too Soon', // Front-end uses value to find key so this is different from SystemTriageReasons.RefillTooSoon due to capitilization
  OtherAdjudication = 'Other ', // Front-end uses value to find key so this is different from MiscellaneousTriageReasons.Other due to space at end
  HighCopay = 'High Copay',
  NeedsReplenishment = 'Forward pick needs replenishment',
}

export enum PV1TriageReasons {
  ClinicalOutreachToMD = 'Clinical Outreach to MD',
  ContainsControlledSubstance = 'Order contains controlled substance',
  CustomerClarificationNeeded = 'Customer Clarification Needed',
  CustomerRequestedHold = 'Customer Requested Hold',
  DoseClarification = 'Dose clarification (sig or strength)',
  IncorrectSig = 'Incorrect sig',
  IncorrectDaySupply = 'Incorrect day supply',
  IncorrectMD = 'Incorrect MD',
  IncorrectDrug = 'Incorrect drug',
  IncorrectPatient = 'Incorrect patient',
  IncorrectQuantity = 'Incorrect quantity',
  IncorrectPayerOrPaymentMethod = 'Incorrect payer/payment method',
  InvalidAddress = 'Invalid Address',
  HighCopay = 'High Copay',
  MissingCopayCheck = 'Unable to automatically confirm copay',
  PatientNameDOBMismatch = 'Patient name/dob mismatch',
  PatientOutreachRequired = 'Patient outreach required',
  PriorAuthorizationRequired = 'Prior Authorization Required',
  RefillTooSoon = 'Refill too soon',
  RphClarificationRequired = 'RPh clarification required',
  RxClarificationRequired = 'Rx clarification required',
  WeatherDelay = 'Weather Delay',
  SupervisingPhysicianRequired = 'Supervising Physician Required',
}

export enum PV1TriageReasonsRestricted {
  DDI = 'Drug-Drug Interaction (DDI)',
  DrugAllergy = 'Drug allergy',
  DrugDisease = 'Drug disease',
  Contraindication = 'Contraindications',
  DUR = 'Drug utilization review (DUR)',
}

export enum FillTriageReasons {
  OOS = 'Out of stock',
  OffFormularyPrescribedNDC = 'Off-formulary prescribed NDC',
  ControlledRxUtilizationHold = 'Controlled Rx Utilization Hold',
  ContainsControlledSubstance = 'Order contains controlled substance',
  FormularyCheckFailed = 'Formulary Check Failed',
  OtherConsigned = 'Other consigned ndc available',
  NeedsReplenishment = 'Forward pick needs replenishment',
  WeatherDelay = 'Weather Delay',
  SupervisingPhysicianRequired = 'Supervising Physician Required',
}

export enum AutomationTriageReasons {
  OOS = 'Out of stock',
  NeedsReplenishment = 'Forward pick needs replenishment',
}

export enum LocationTriageReasons {
  UnlicensedState = 'Location is not licensed to ship to this state',
}

export enum PV2TriageReasons {
  WeatherDelay = 'Weather Delay',
  WrongQuantityFilled = 'Wrong quantity filled',
  WrongDrugLabeled = 'Wrong drug labeled',
  WrongPatient = 'Wrong patient',
}

export enum ShippingTriageReasons {
  AddressIssues = 'Address issues (Address Formatting)',
  ShippingPriorityChange = 'Shipping priority change',
  OutboundScanDuplicates = 'Outbound scan (Duplicates)',
}

export enum OrderTriageReasons {
  PatientNotFoundForOrder = 'Patient not found for order',
  LocationNotFoundForOrder = 'Location not found for order',
  PrescriptionExpired = 'Prescription Expired',
  SpanishTranslationRequired = 'Spanish translation required',
  ReplacementOrder = 'Replacement Order',
  OOS = 'Out of stock',
  NeedsReplenishment = 'Forward pick needs replenishment',
}

export enum PatientTriageReasons {
  ContactIssues = 'Problem with contact information (formatting)',
  NoGenderEntered = 'No Gender Entered For Patient',
}

export enum ExternalSystemTriageReasons {
  ProductNotFoundInExternalSystem = 'Product not found in external system',
  CreateShipmentFailedInExternalSystem = 'Create shipment failed in external system',
  ShippingMethodNotSupportedInExternalSystem = 'Shipping method not supported in external system',
}

export enum PackingTriageReasons {
  SentBackFromPacking = 'Sent back to PV1 from pack',
}

export enum CoreToTPOSTriageReasons {
  InsuranceOrder = 'Order came from core with associated insurance',
}

export enum MiscellaneousTriageReasons {
  Other = 'Other',
}

export enum ManualTestingTriageReasons {
  ProductionTesting = 'Production Testing',
  ManualTesting = 'Manual Testing',
}

export const TriageReasons = {
  SendBackReasons: SendBackToPv1FromPV2Reason,
  SystemTriageReasons,
  AdjudicationTriageReasons,
  PV1TriageReasons,
  PV1TriageReasonsRestricted,
  FillTriageReasons,
  AutomationTriageReasons,
  LocationTriageReasons,
  PV2TriageReasons,
  ShippingTriageReasons,
  OrderTriageReasons,
  PatientTriageReasons,
  ExternalSystemTriageReasons,
  CoreToTPOSTriageReasons,
  PackingTriageReasons,
  MiscellaneousTriageReasons,
  ManualTestingTriageReasons,
}

export enum TriageCodes {
  InvalidAddress = 'T001',
  AddressIssues = 'T001',
  PricingNeeded = 'T002',
  PriorAuthorizationRequired = 'T003',
  CustomerClarificationNeeded = 'T004',
  OOS = 'T005',
  ClinicalOutreachToMD = 'T006',
  OtherReason = 'T007',
  Other = 'T007',
  OtherAdjudication = 'T007',
  CustomerRequestedHold = 'T008',
  RefillTooSoon = 'T009',
  RefillTooSoonRejection = 'T009',
  ContainsControlledSubstance = 'T010',
  ReplacementOrder = 'T011',
  RphClarificationRequired = 'T012',
  RxClarificationRequired = 'T013',
  RxClarification = 'T013',
  ControlledRxUtilizationHold = 'T014',
  NoGenderEntered = 'T015',
  UnlicensedState = 'T016',
  SpanishTranslationRequired = 'T017',
  OffFormularyPrescribedNDC = 'T018',
  OtherConsigned = 'T019',
  SentBackToPV1 = 'T020',
  SentBackFromPacking = 'T020',
  PatientOutreachRequired = 'T021',
  LocationNotFoundForOrder = 'T022',
  RxChange = 'T023',
  HighCopay = 'T024',
  MissingCopayCheck = 'T025',
  ExpiredDate = 'T026',
  EffectiveDateInFuture = 'T027',
  PatientNotFoundForOrder = 'T028',
  ProductNotFoundInExternalSystem = 'T029',
  ShippingMethodNotSupportedInExternalSystem = 'T030',
  CreateShipmentFailedInExternalSystem = 'T031',
  // PV1 manual triages
  Contraindication = 'T032',
  DDI = 'T033',
  DUR = 'T034',
  DoseClarification = 'T035',
  DrugAllergy = 'T036',
  DrugDisease = 'T037',
  IncorrectMD = 'T038',
  IncorrectDaySupply = 'T039',
  IncorrectDrug = 'T040',
  IncorrectPatient = 'T041',
  IncorrectPayerOrPaymentMethod = 'T042',
  IncorrectQuantity = 'T043',
  IncorrectSig = 'T044',
  PatientNameDOBMismatch = 'T045',
  // Fill manual triages
  FormularyCheckFailed = 'T046',
  // PV2 manual triages
  WrongQuantityFilled = 'T047',
  WrongDrugLabeled = 'T048',
  WrongPatient = 'T049',
  // Manual Testing triages
  ProductionTesting = 'T050',
  ManualTesting = 'T051',

  PreCheck = 'T052',
  RxOrderDiscrepancy = 'T053',
  ShippingPriorityChange = 'T054',
  OutboundScanDuplicates = 'T055',
  PrescriptionExpired = 'T056',
  ContactIssues = 'T057',
  InsuranceOrder = 'T058',
  NeedsReplenishment = 'T059',
  WeatherDelay = 'T060',
  SupervisingPhysicianRequired = 'T061',
}

export type TriageReasons =
  | SendBackReason
  | SystemTriageReasons
  | AdjudicationTriageReasons
  | PV1TriageReasons
  | PV1TriageReasonsRestricted
  | FillTriageReasons
  | AutomationTriageReasons
  | LocationTriageReasons
  | PV2TriageReasons
  | ShippingTriageReasons
  | OrderTriageReasons
  | PatientTriageReasons
  | ExternalSystemTriageReasons
  | CoreToTPOSTriageReasons
  | PackingTriageReasons
  | MiscellaneousTriageReasons
  | ManualTestingTriageReasons

export type TriageReason =
  | keyof typeof SendBackToPv1FromPV2Reason
  | keyof typeof SystemTriageReasons
  | keyof typeof AdjudicationTriageReasons
  | keyof typeof PV1TriageReasons
  | keyof typeof PV1TriageReasonsRestricted
  | keyof typeof FillTriageReasons
  | keyof typeof AutomationTriageReasons
  | keyof typeof LocationTriageReasons
  | keyof typeof PV2TriageReasons
  | keyof typeof ShippingTriageReasons
  | keyof typeof OrderTriageReasons
  | keyof typeof PatientTriageReasons
  | keyof typeof ExternalSystemTriageReasons
  | keyof typeof CoreToTPOSTriageReasons
  | keyof typeof PackingTriageReasons
  | keyof typeof MiscellaneousTriageReasons
  | keyof typeof ManualTestingTriageReasons

export enum OrderTriageMessages {
  DUPLICATE_PRESCRIPTION = 'Patient already has another prescription for the same medication and the prescription may be a duplicate',
}
