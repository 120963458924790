import type { InsuranceProvider, InsuranceProviderConfiguration } from 'types'

const updatePayerFormConfigurations = (
  payerForm: InsuranceProvider,
  updatePayerForm: (update: InsuranceProvider) => void,
  groupKey: keyof InsuranceProviderConfiguration,
  key: string,
  value: unknown,
): void => {
  const update = {
    configurations: {
      ...payerForm.configurations,
      [groupKey]: { ...payerForm.configurations?.[groupKey], [key]: value },
    },
  } as InsuranceProvider

  updatePayerForm(update)
}

export { updatePayerFormConfigurations }
