import { useParams } from '@truepill/tpos-react-router'
import { OrderStatus, RxFillRequestStatus } from '@truepill/tpos-types'

export interface FulfillmentQueue {
  name: FulfillmentQueueName
  fillStages?: RxFillRequestStatus[]
  orderStages?: OrderStatus[]
  filters?: { [key: string]: boolean }
  hidden?: boolean
}

export enum FulfillmentQueueName {
  Adjudication = 'Adjudication',
  Automation = 'Automation',
  Cancelled = 'Cancelled',
  Complete = 'Complete',
  Fill = 'Fill',
  Packing = 'Packing',
  PriorAuthorizationNew = 'Prior-Authorization',
  PriorAuthorization = 'Prior Authorization',
  Processing = 'Processing',
  PV1 = 'PV1',
  PV2 = 'PV2',
  Returned = 'Returned',
  Shipped = 'Shipped',
  Triage = 'Triage',
  Copay = 'Copay',
}

export type PartialRxFillRequestStatus = Exclude<
  RxFillRequestStatus,
  RxFillRequestStatus.PreAdjudication | RxFillRequestStatus.Reverted
>

type PartialOrderStatus = Exclude<OrderStatus, OrderStatus.Reverted>

export const RXFillRequestStatusToFulfillmentQueueName: {
  [k in PartialRxFillRequestStatus]: FulfillmentQueueName | undefined
} = {
  [RxFillRequestStatus.Adjudication]: FulfillmentQueueName.Adjudication,
  [RxFillRequestStatus.Automation]: FulfillmentQueueName.Automation,
  [RxFillRequestStatus.Cancelled]: FulfillmentQueueName.Cancelled,
  [RxFillRequestStatus.Complete]: FulfillmentQueueName.Complete,
  [RxFillRequestStatus.Fill]: FulfillmentQueueName.Fill,
  [RxFillRequestStatus.PriorAuthorization]: FulfillmentQueueName.PriorAuthorization,
  [RxFillRequestStatus.PV1]: FulfillmentQueueName.PV1,
  [RxFillRequestStatus.PV2]: FulfillmentQueueName.PV2,
}

export const OrderStatusToFulfillmentQueueName: {
  [k in PartialOrderStatus]: FulfillmentQueueName | undefined
} = {
  [OrderStatus.Adjudication]: FulfillmentQueueName.Adjudication,
  [OrderStatus.Automation]: FulfillmentQueueName.Automation,
  [OrderStatus.Cancelled]: FulfillmentQueueName.Cancelled,
  [OrderStatus.Complete]: FulfillmentQueueName.Complete,
  [OrderStatus.Fill]: FulfillmentQueueName.Fill,
  [OrderStatus.Packing]: FulfillmentQueueName.Packing,
  [OrderStatus.PartialShipped]: FulfillmentQueueName.Shipped,
  [OrderStatus.PriorAuthorization]: FulfillmentQueueName.PriorAuthorization,
  [OrderStatus.PV1]: FulfillmentQueueName.PV1,
  [OrderStatus.PV2]: FulfillmentQueueName.PV2,
  [OrderStatus.Returned]: FulfillmentQueueName.Returned,
  [OrderStatus.Shipped]: FulfillmentQueueName.Shipped,
}

export const FULFILLMENT_QUEUES: {
  [k in FulfillmentQueueName]: FulfillmentQueue
} = {
  [FulfillmentQueueName.Copay]: {
    name: FulfillmentQueueName.Copay,
    filters: {},
  },
  [FulfillmentQueueName.PriorAuthorizationNew]: {
    name: FulfillmentQueueName.PriorAuthorizationNew,
    filters: {},
  },
  [FulfillmentQueueName.PV1]: {
    name: FulfillmentQueueName.PV1,
    fillStages: [RxFillRequestStatus.PV1],
    filters: {},
  },
  [FulfillmentQueueName.Adjudication]: {
    name: FulfillmentQueueName.Adjudication,
    fillStages: [RxFillRequestStatus.Adjudication],
    orderStages: [OrderStatus.Adjudication],
    filters: {},
  },
  [FulfillmentQueueName.PriorAuthorization]: {
    name: FulfillmentQueueName.PriorAuthorization,
    fillStages: [RxFillRequestStatus.PriorAuthorization],
    orderStages: [OrderStatus.PriorAuthorization],
    filters: {},
  },
  [FulfillmentQueueName.Automation]: {
    name: FulfillmentQueueName.Automation,
    fillStages: [RxFillRequestStatus.Automation],
    orderStages: [OrderStatus.Automation],
    filters: { inTriage: false },
  },
  [FulfillmentQueueName.Fill]: {
    name: FulfillmentQueueName.Fill,
    fillStages: [RxFillRequestStatus.Fill],
    orderStages: [OrderStatus.Fill],
    filters: {},
  },
  [FulfillmentQueueName.PV2]: {
    name: FulfillmentQueueName.PV2,
    fillStages: [RxFillRequestStatus.PV2],
    orderStages: [OrderStatus.PV2],
    filters: {},
  },
  [FulfillmentQueueName.Triage]: {
    name: FulfillmentQueueName.Triage,
    fillStages: [
      RxFillRequestStatus.PV1,
      RxFillRequestStatus.Adjudication,
      RxFillRequestStatus.PriorAuthorization,
      RxFillRequestStatus.Fill,
      RxFillRequestStatus.PV2,
      RxFillRequestStatus.Automation,
    ],
    orderStages: [
      OrderStatus.PV1,
      OrderStatus.PV2,
      OrderStatus.Fill,
      OrderStatus.Packing,
      OrderStatus.PartialShipped,
      OrderStatus.Adjudication,
      OrderStatus.PriorAuthorization,
      OrderStatus.Automation,
    ],
    filters: { inTriage: true, includeTriage: true },
  },
  [FulfillmentQueueName.Processing]: {
    name: FulfillmentQueueName.Processing,
    fillStages: [RxFillRequestStatus.PV1, RxFillRequestStatus.Fill, RxFillRequestStatus.PV2],
    orderStages: [OrderStatus.Packing],
    filters: {},
  },
  [FulfillmentQueueName.Cancelled]: {
    name: FulfillmentQueueName.Cancelled,
    orderStages: [OrderStatus.Cancelled],
    filters: {},
  },
  [FulfillmentQueueName.Packing]: {
    name: FulfillmentQueueName.Packing,
    fillStages: [RxFillRequestStatus.Complete],
    orderStages: [OrderStatus.Packing],
    filters: {},
  },
  [FulfillmentQueueName.Shipped]: {
    name: FulfillmentQueueName.Shipped,
    fillStages: [RxFillRequestStatus.Complete],
    orderStages: [OrderStatus.Shipped, OrderStatus.PartialShipped],
    filters: {},
  },
  [FulfillmentQueueName.Complete]: {
    name: FulfillmentQueueName.Complete,
    fillStages: [RxFillRequestStatus.Complete],
    filters: {},
  },
  [FulfillmentQueueName.Returned]: {
    name: FulfillmentQueueName.Returned,
    orderStages: [OrderStatus.Returned],
    filters: {},
  },
}

type QueueNameType = { fulfillmentQueueName: FulfillmentQueueName }

export default function useFulfillmentQueue(): FulfillmentQueue | undefined {
  const { fulfillmentQueueName } = useParams<QueueNameType>()

  return FULFILLMENT_QUEUES[fulfillmentQueueName]
}
