import { RxStatus } from '@truepill/tpos-types'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import type { Fill, Prescription } from 'types'

export const getCurrentFill = (prescription: Prescription, fillId?: string): Fill | undefined => {
  const { fills } = prescription
  if (!fills.length) {
    return
  }

  if (fillId) {
    for (const fill of fills) {
      if (fill._id === fillId) {
        return fill
      }
    }
    return
  }

  return fills.slice(-1)[0]
}

export const matchRXLabel = (rxLabel: string): { rxNumber?: string; fillIndex?: string } => {
  const rxLabelMatches = rxLabel.match(/(\d+)-(\d+)-(\d\d[CP]?)/)
  if (!rxLabelMatches) {
    return {}
  }
  let fillIndex = rxLabelMatches[3]
  // Some barcode readers will automatically trim trailing 0s out of the box
  // and getting all hardware set up correctly is more of pain than just
  // fixing it in software
  if (!fillIndex.match(/\d{2}/)) {
    fillIndex = '0' + fillIndex
  }

  return {
    rxNumber: rxLabelMatches[2],
    fillIndex,
  }
}

export const hasOpenFills = (prescription: Prescription): boolean => prescription.status === RxStatus.InFulfillment

export function calculateFillsRemaining(prescription: Prescription): number {
  return Math.ceil(prescription.quantityRemaining / (prescription.quantity as number))
}

export function calculateExpirationDate(prescription: Prescription, defaultDate?: string): Dayjs {
  if (prescription.expirationDate == null) {
    return dayjs(prescription.writtenDate).add(1, 'year')
  }
  return dayjs(prescription.expirationDate || defaultDate)
}
