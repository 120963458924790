import { Checkbox } from '@truepill/react-capsule'
import type { QueuesFilterState } from 'components/Filter'
import useSetFormDataValue from 'hooks/useSetFormDataValue'
import { FILTER_NAMESPACE } from 'NameSpaces'
import { useFormDataContext } from 'providers/FormDataProvider'
import { usePlusClient } from 'providers/VisionRouter'
import styled from 'styled-components'

const StyledCheckbox = styled(Checkbox)`
  margin-bottom: 0.5rem;
`

const EscriptShowRejectedCheckbox = (): JSX.Element => {
  const { routeToMergedQuery } = usePlusClient()
  const { formData } = useFormDataContext()
  const filters = formData[FILTER_NAMESPACE] as unknown as QueuesFilterState
  const setFilterValue = useSetFormDataValue(FILTER_NAMESPACE)

  const handleChange = (showRejected: boolean) => {
    setFilterValue('showRejected', !!showRejected)
    const meds = filters?.medications && Array.isArray(filters?.medications) ? filters.medications : []
    if (showRejected) routeToMergedQuery({ ...filters, statuses: ['erxRejected'], medications: meds })
    else routeToMergedQuery({ ...filters, statuses: [], medications: meds })
  }

  return (
    <>
      <StyledCheckbox
        label="Only show rejected eScripts"
        checked={filters.showRejected || false}
        onCheckedChange={checked => handleChange(!!checked)}
      />
    </>
  )
}

export default EscriptShowRejectedCheckbox
