import { useCallback, useState } from 'react'

export enum BarcodeTypes {
  oneDimensional = '1d',
  twoDimensional = '2d',
}

const useFirstScanType = () => {
  const [firstScanType, setFirstScan] = useState<BarcodeTypes | undefined>()

  const setFirstScanType = (scanType: BarcodeTypes) => {
    if (!firstScanType) setFirstScan(scanType)
  }

  const resetFirstScanType = useCallback(() => setFirstScan(undefined), [])

  return { firstScanType, setFirstScanType, resetFirstScanType }
}
export default useFirstScanType
