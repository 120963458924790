import { useParams, Redirect, useQuery } from '@truepill/tpos-react-router'
import { GET_ORDER_BY_CORE_FILL_REQUEST_TOKEN } from 'gql'
import { goToViewPharmacyOrder } from 'routes'
import type { OrderQueryResult } from 'types'
import { OrderNotFound } from './OrderNotFound'

export const LoadFromCoreFillRequestToken = (): JSX.Element => {
  const routeParams = useParams<{ coreFillRequestToken: string }>()

  const { data } = useQuery<OrderQueryResult>(GET_ORDER_BY_CORE_FILL_REQUEST_TOKEN, {
    variables: { coreFillRequestToken: routeParams.coreFillRequestToken },
  })

  if (data?.order?._id) {
    const orderUrl = goToViewPharmacyOrder({ orderId: data.order._id })
    return <Redirect to={orderUrl} />
  }

  if (data?.order === null) {
    return (
      <OrderNotFound>
        Could not find order for fill request token: <strong>{routeParams.coreFillRequestToken}</strong>
      </OrderNotFound>
    )
  }

  return <></>
}
