import type { MutableRefObject } from 'react'
import { useRef, useEffect } from 'react'

const useAutoUpdatedRef = <T>(value: T): MutableRefObject<T> => {
  const autoUpdateRef = useRef(value)

  useEffect(() => {
    autoUpdateRef.current = value
  }, [value])

  return autoUpdateRef
}

export default useAutoUpdatedRef
