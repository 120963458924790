import type { FC } from 'react'
import { useQuery } from '@truepill/tpos-react-router'
import styled from 'styled-components'
import { APP_DATA } from '../gql'

const AppCommitData: FC<{ branch: string; commit: string; errorMessage?: string; label?: string }> = ({
  branch,
  commit,
  errorMessage,
  label = '',
}) => {
  const labelText = label ? `${label}: ` : ''

  if (errorMessage) {
    return (
      <span>
        {labelText}
        {errorMessage}
      </span>
    )
  }

  if (branch === '[local]') {
    return <span>{labelText}local</span>
  }

  return (
    <span>
      {labelText}
      {branch === 'main' ? '' : `${branch}:`}
      {commit.substring(0, 7)}
    </span>
  )
}

export const AppData: FC = () => {
  const { data, error, loading } = useQuery(APP_DATA)

  if (loading) {
    return <AppDataFooter>loading app data...</AppDataFooter>
  }

  const frontendBranch = process.env.REACT_APP_GITHUB_BRANCH || '[local]'
  const frontendCommit = process.env.REACT_APP_GITHUB_COMMIT || '[unknown]'
  const backendBranch = data?.appData?.branch || '[local]'
  const backendCommit = data?.appData?.commit || '[unknown]'

  if (!error && frontendBranch === backendBranch && frontendCommit === backendCommit) {
    return (
      <AppDataFooter>
        <AppCommitData branch={frontendBranch} commit={frontendCommit} label="v" />
      </AppDataFooter>
    )
  }

  return (
    <AppDataFooter>
      <AppCommitData branch={frontendBranch} commit={frontendCommit} label="fe" />{' '}
      <AppCommitData branch={backendBranch} commit={backendCommit} errorMessage={error?.message} label="be" />
    </AppDataFooter>
  )
}

export const AppDataFooter = styled.div`
  padding: 1.25rem 1.875rem;
  text-align: right;
  font-size: 0.8rem;
  color: #afbcc6;
`
