import { useHistory } from '@truepill/tpos-react-router'
import { CopayStatus, FillStatus, RxFillRequestStatus } from '@truepill/tpos-types'
import { ReactComponent as CancelIcon } from 'assets/icons/cancel.svg'
import ActionButton from 'components/ActionButton'
import IconWrapper from 'components/IconWrapper'
import useErrorToast from 'hooks/toast/useErrorToast'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import useFulfillmentQueue, { FulfillmentQueueName } from 'hooks/useFulfillmentQueue'
import useGetNextAdjudication from 'hooks/useGetNextAdjudication'
import useGetNextTriage from 'hooks/useGetNextTriage'
import RejectModal from 'modals/RejectModal'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import { usePlusClient } from 'providers/VisionRouter'
import { useLocation } from 'react-use'
import { goToFulfillmentFill, goToFulfillmentCopayRequestFill } from 'routes'
import { bodyPrimaryColor } from 'styles/styleVariables'
import type { Fill, PriorAuthorization, QueueItem } from 'types'
import { continueOrderWorkflow, getItemFill, isCopayRequest, isOrder } from 'utils'

type RejectActionButtonProps = {
  fill: Fill
  item: QueueItem
  priorAuthorization?: PriorAuthorization
  isPriorAuthorization: boolean
  isRejectedOrder: boolean
  disabled?: boolean
}

export const RejectActionButton = ({
  item,
  fill,
  priorAuthorization,
  isPriorAuthorization,
  isRejectedOrder,
  disabled = false,
}: RejectActionButtonProps): JSX.Element => {
  const showErrorToast = useErrorToast()
  const showSuccess = useSuccessToast()
  const { showModal } = useModalContext()
  const { search } = useLocation()
  const {
    currentLocation: { queryMap },
  } = usePlusClient()
  const history = useHistory()
  const fulfillmentQueue = useFulfillmentQueue()
  const { getNextOrder: getNextAdjudication } = useGetNextAdjudication()
  const { getNextOrder: getNextTriage } = useGetNextTriage()

  const isComplete = isOrder(item)
    ? getItemFill(item, fill._id)?.status === RxFillRequestStatus.Complete
    : getItemFill(item, fill._id)?.status === CopayStatus.Complete
  const isCompleteCopay = isCopayRequest(item) && isComplete

  if ((!isPriorAuthorization && !isCompleteCopay && isComplete) || isRejectedOrder) {
    return <></>
  }

  const priorAuthOpenStatus = priorAuthorization?.prescription?.fills.some(fill =>
    [FillStatus.InFulfillment, FillStatus.CopayCheck].includes(fill.status),
  )

  const fulfillmentQueueName = fulfillmentQueue?.name
  let getNextOrder = fulfillmentQueueName === FulfillmentQueueName.PriorAuthorization ? undefined : getNextAdjudication
  if (isOrder(item) && item.inTriage && fulfillmentQueueName === FulfillmentQueueName.Triage) {
    getNextOrder = getNextTriage
  }

  return (
    <ActionButton
      data-testid="Reject"
      disabled={disabled}
      icon={
        <IconWrapper>
          <CancelIcon fill={bodyPrimaryColor} />
        </IconWrapper>
      }
      label={'Reject'}
      onClick={() => {
        if (isPriorAuthorization && !priorAuthOpenStatus) {
          showErrorToast('Original copay/order no longer active')
        } else {
          showModal(() => (
            <RejectModal
              itemId={item._id}
              fillId={fill._id}
              isControlledSubstance={!!fill.handlingTags.deaScheduleNum || fill.handlingTags.isControlledSubstance}
              confirmationCallback={async () => {
                showSuccess(`Rx: ${fill._id} - rejected`)
                if (isPriorAuthorization) {
                  if (isOrder(item)) {
                    history.push(
                      goToFulfillmentFill({
                        orderId: item._id,
                        fillId: fill._id,
                      }),
                    )
                  } else if (isCopayRequest(item)) {
                    history.push(
                      goToFulfillmentCopayRequestFill({
                        copayRequestId: item._id,
                        fillId: fill._id,
                      }),
                    )
                  }
                } else if (isOrder(item)) {
                  await continueOrderWorkflow(
                    search,
                    queryMap,
                    history,
                    () => getNextOrder?.(item, fill),
                    fulfillmentQueueName,
                    item.rxFillRequests.length > 1,
                  )
                }
              }}
              isCopayRequest={isCopayRequest(item)}
            />
          ))
        }
      }}
      hotKey={'R'}
      hotKeyMeta={{ ctrl: true, shift: true }}
      hotKeyLabel={'CTRL-SHIFT-R'}
    />
  )
}
