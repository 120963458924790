import { Species } from '@truepill/tpos-types'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { all, props, values } from 'ramda'
import { isFalsy, isTruthy } from 'ramda-adjunct'
import type { DeepPartial } from 'ts-essentials'
import type { Patient } from 'types'
import { validateAddressForm } from './address'
import { validPhoneNumber } from './phoneFormatter'

const createValidator = () => {
  let isValid = true
  const append = (b: boolean) => (isValid = isValid && b)
  return {
    append,
    get validity() {
      return isValid
    },
  }
}

const REQUIRED_PATIENT_FORM_FIELDS = ['firstName', 'lastName', 'gender', 'dob']
export const isPatientFormValid = (patient: DeepPartial<Patient>): boolean => {
  const validator = createValidator()

  // check if the patient exists
  validator.append(!!patient)

  // if we have a home address and that address does *not* consist of all null values
  if (patient.address?.home && !all(isFalsy, values(patient.address.home))) {
    validator.append(validateAddressForm(patient.address.home).isValid)
  }

  // phone numnbers are also optional - check if they exist
  if (patient.contacts?.phone) {
    validator.append(validPhoneNumber(patient.contacts.phone))
  }

  // finally, check the required patient fields
  validator.append(all(isTruthy, props(REQUIRED_PATIENT_FORM_FIELDS, patient)))

  return validator.validity
}

export const isHuman = (patient?: Partial<Patient>) => {
  return !patient?.species || patient?.species === Species.Human
}

export const validatePatientForm = (
  patient: DeepPartial<Patient>,
): { isValid: boolean; errors: Record<string, string> } => {
  if (!patient) {
    return { isValid: false, errors: {} }
  }

  // Patient ID means this is a found patient so we can be sure the form has
  // been completed
  if (patient._id) {
    return { isValid: true, errors: {} }
  }

  const { firstName, lastName, gender, dob, contacts, address } = patient
  const errors: Record<string, string> = { ...validateAddressForm(address?.home, true).errors }
  const requiredFields = {
    firstName,
    lastName,
    gender,
    dob,
    phone: contacts?.phone,
  }

  if (moment(dob).isAfter(moment())) {
    errors.dob = 'Cannot be in the future'
  }

  if ((contacts?.phone?.match(/\d+/g) || []).join('').length < 10) {
    errors.phone = '10 digits minimum'
  }

  Object.entries(requiredFields).forEach(([key, value]) => {
    if (!value) {
      errors[key] = 'Missing field'
    }
  })

  return { isValid: isEmpty(errors), errors }
}
