import { Grid, GridItem } from '@truepill/react-capsule'
import { Link } from '@truepill/tpos-react-router'
import LoadingSpinner from 'components/Loading'
import styled from 'styled-components'
import { capsuleLightGreyColor } from 'styles/styleVariables'

export interface TableDefinition<T> {
  field?: keyof T
  customRender?: (rowData: T) => JSX.Element
  headerName: string
  width: string
  minWidth?: string
}
export interface TableProps<T> {
  definition: TableDefinition<T>[]
  keyField?: keyof T
  data: T[]
  rowLinkTo?: (row: T) => string
  loading?: boolean
  rowStyle?: React.CSSProperties
  headerStyle?: React.CSSProperties
  getCustomRowStyle?: (rowData: T) => React.CSSProperties | undefined
}

/**
 * @param data Array having all the items that are going to be rendered on the table.
 * @param loading Boolean
 * @param rowStyle css styles to be applied on the row
 * @param headerStyle css styles to be applied on the header
 * @param getCustomRowStyle To inject custom style at each row, and at row level. Takes precedence over rowStyle. Optional
 * @param rowLinkTo gives the ability to click the row and open a link
 * @param keyfield A string that has to be a key from the object type that make up the data array. Optional
 * @param definition Object having important definition on how to render the table
 * @param definition.width Width of the row ie: '10px', '1fr', '1rem', '10%', etc
 * @param definition.minwidth Min width of the row ie: '10px', '1rem',
 * @param definition.field A property from the object that is part of the data array which value is going to be shown in the column. Optional
 * @param definition.headerName String value that represents the name shown in the header part of the table for a given column.
 * @param definition.customRender Custom function to render a component inside one of the columns. Optional
 */
export default function Table<T>({
  data,
  definition,
  keyField,
  rowStyle,
  rowLinkTo,
  loading = false,
  headerStyle,
  getCustomRowStyle,
}: TableProps<T>): JSX.Element {
  const distribution = definition.map(({ width }) => width)

  return (
    <ScrollContainer>
      <TableContainer>
        <Header style={headerStyle} distribution={distribution}>
          {definition.map(({ headerName, minWidth }, index: number) => (
            <ColItem key={index} minWidth={minWidth}>
              {headerName}
            </ColItem>
          ))}
        </Header>
        {loading && (
          <LoadingContainer>
            <LoadingSpinner />
          </LoadingContainer>
        )}
        {!loading && (
          <Body>
            {data.map((item: T, rowIndex: number) => {
              const key = keyField ? (item[keyField] as unknown as string | number) : rowIndex

              const rowToRender = (
                <Row
                  style={getCustomRowStyle ? getCustomRowStyle(item) : rowStyle}
                  key={key}
                  distribution={distribution}
                >
                  {definition.map(({ field, customRender, minWidth }, index: number) => {
                    if (customRender) {
                      return (
                        <ColItem key={index} minWidth={minWidth}>
                          {customRender(item)}
                        </ColItem>
                      )
                    }
                    return field && <ColItem minWidth={minWidth}>{item[field]}</ColItem>
                  })}
                </Row>
              )
              if (rowLinkTo) {
                return (
                  <Link to={rowLinkTo(item)} key={key}>
                    {rowToRender}
                  </Link>
                )
              }

              return rowToRender
            })}
          </Body>
        )}
      </TableContainer>
    </ScrollContainer>
  )
}

const setDistribution = (distribution?: string[]) => {
  return distribution?.length
    ? `grid-template-columns: ${distribution
        .map(distributionVal => {
          return `[col] ${distributionVal}`
        })
        .join(' ')};`
    : ''
}

const LoadingContainer = styled.div`
  width: 3rem;
  margin: 3rem auto;
`

const ColItem = styled(({ minWidth, ...rest }) => <GridItem {...rest} />)`
  ${props => props.minWidth && `min-width: ${props.minWidth};`}
  padding: 0.5rem 0;
`

const ScrollContainer = styled.div`
  width: 100%;
  overflow-x: auto;
`

const TableContainer = styled.div`
  width: auto;
  font-family: Lato;
`

const BaseRow = styled(Grid)<{ distribution?: string[] }>`
  ${props => setDistribution(props.distribution)}
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0 0.5rem;
`

const Header = styled(BaseRow)`
  width: fit-content;
  min-width: 100%;
  font-weight: bold;
  border-bottom: 1px solid ${capsuleLightGreyColor};
`

const Row = styled(BaseRow)`
  font-weight: 400;
`

const Body = styled.div`
  width: fit-content;
  min-width: 100%;
  border-bottom: 1px solid ${capsuleLightGreyColor};
  > div:nth-child(even) {
    background-color: #eee;
  }
  > a:nth-child(even) {
    display: block;
    background-color: #f2f2f2;
  }
`

/*
Example of usage:

<Table
  data={[
    { id: 1, firstName: 'Carlos', lastName: 'Perez', age: '28', createdDate: '1990/12/12' },
    { id: 202, firstName: 'John', lastName: 'Mayer', age: '45', createdDate: '1990/12/12' },
    { id: 331, firstName: 'Micheal', lastName: 'Gomez', age: '52', createdDate: '1990/12/12' },
  ]}
  keyField='id'
  definition={[
    { field: 'id', headerName: 'ID', width: '0.5fr' },
    { field: 'firstName', headerName: 'First name', width: '2fr' },
    { field: 'lastName', headerName: 'Last name', width: '2fr' },
    { field: 'age', headerName: 'Age', width: '1fr' },
    {
      headerName: 'Action',
      width: '3fr',
      customRender: item => (
        <button
          onClick={() => {
            alert(item.id)
          }}>
          Click me!
        </button>
      ),
    },
    {
      headerName: 'Other',
      width: '2fr',
      customRender: item => (
        <div>
          <p>
            {item.firstName} {item.lastName}
          </p>
          <p>{item.createdDate}</p>
        </div>
      ),
    },
  ]}
/>
*/
