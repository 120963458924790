import { useMutation } from '@truepill/tpos-react-router'
import { OutboundTransferStatus, UserRoles } from '@truepill/tpos-types'
import { ReactComponent as CancelIcon } from 'assets/icons/cancel.svg'
import { ReactComponent as CheckIcon } from 'assets/icons/circlecheck.svg'
import { ReactComponent as DangerIcon } from 'assets/icons/danger.svg'
import { ReactComponent as HouseIcon } from 'assets/icons/house.svg'
import ActionButton from 'components/ActionButton'
import AuthLimited from 'components/AuthLimited'
import IconWrapper from 'components/IconWrapper'
import { ActionButtonContainer } from 'components/PageStructure'
import { CONFIRM_OUTBOUND_TRANSFER_PENDING } from 'gql'
import useErrorToast from 'hooks/toast/useErrorToast'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import RejectOutboundTransferModal from 'modals/RejectOutboundTransferModal'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import { bodyPrimaryColor } from 'styles/styleVariables'
import type { OutboundTransfer } from 'types'
import TriageModal from './TriageModal'

type ActionButtonsProps = {
  outboundTransfer: OutboundTransfer
}

// Action buttons need a Prescription object to function correctly
const WrappedActionButtons = (props: ActionButtonsProps): JSX.Element => {
  return (
    <AuthLimited roles={[UserRoles.Pharmacist, UserRoles.Technician, UserRoles.CustomerSupport]}>
      <ActionButtons {...props} />
    </AuthLimited>
  )
}

const ActionButtons = ({ outboundTransfer }: ActionButtonsProps) => {
  const { showModal } = useModalContext()
  const showErrorToast = useErrorToast()
  const showSuccessToast = useSuccessToast()

  const [confirmOutboundTransferPending] = useMutation(CONFIRM_OUTBOUND_TRANSFER_PENDING, {
    refetchQueries: ['getBasicOutboundTransfer'],
    onCompleted: data => {
      showSuccessToast('Outbound transfer moved to PV')
    },
    onError: e => {
      console.error('Failed to reject outbound transfer. ' + e.message)
      showErrorToast('Failed to confirm outbound transfer pending. ' + e.message)
    },
  })

  const inTriage = outboundTransfer.status === OutboundTransferStatus.Triage
  const inPV = outboundTransfer.status === OutboundTransferStatus.PV
  // This component shouldnt even be rendered if the outbound transfer is complete/rejected, but additional
  // check in case logic changes in the future will force updates to be made explicitly here.
  const hideAllButtons =
    outboundTransfer.status === OutboundTransferStatus.Complete ||
    outboundTransfer.status === OutboundTransferStatus.Rejected

  return (
    <ActionButtonContainer>
      {!hideAllButtons && (
        <>
          <ActionButton
            // TODO - when implementing this button find the proper icon
            data-testid="Log-Outreach"
            icon={
              <IconWrapper>
                <HouseIcon fill={bodyPrimaryColor} />
              </IconWrapper>
            }
            label={'Log Outreach'}
          />

          {!inTriage && (
            <ActionButton
              data-testid="Triage"
              icon={
                <IconWrapper>
                  <DangerIcon fill={bodyPrimaryColor} />
                </IconWrapper>
              }
              label={'Triage'}
              onClick={() => {
                showModal(() => <TriageModal outboundTransferId={outboundTransfer._id} />)
              }}
            />
          )}
          <ActionButton
            data-testid="Reject"
            icon={
              <IconWrapper>
                <CancelIcon fill={bodyPrimaryColor} />
              </IconWrapper>
            }
            label={'Reject'}
            onClick={() => {
              showModal(() => <RejectOutboundTransferModal outboundTransferId={outboundTransfer._id} />)
            }}
          />
          {!inPV && (
            <ActionButton
              data-testid="Confirm"
              icon={
                <IconWrapper>
                  <CheckIcon fill={bodyPrimaryColor} />
                </IconWrapper>
              }
              label={'Confirm'}
              onClick={() => {
                confirmOutboundTransferPending({
                  variables: {
                    outboundTransferId: outboundTransfer._id,
                  },
                })
              }}
            />
          )}
          {inPV && (
            <ActionButton
              data-testid="Transfer-Out"
              icon={
                <IconWrapper>
                  <CheckIcon fill={bodyPrimaryColor} />
                </IconWrapper>
              }
              label={'Transfer Out'}
            />
          )}
        </>
      )}
    </ActionButtonContainer>
  )
}

export default WrappedActionButtons
