import type { FC } from 'react'

const lineBreaks = /(?:\r\n|\n)/g

/**
 * Take a message and convert all line return characters to <br> tags
 */
const MultiLineMessage: FC<{ message: string }> = ({ message }) => {
  const messageLines = String(message).split(lineBreaks)
  return (
    <>
      {messageLines.map((msg, i) => (
        <span key={i}>
          {i > 0 && <br />}
          {msg}
        </span>
      ))}
    </>
  )
}

export default MultiLineMessage
