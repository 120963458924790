import type { MachineStatus } from '@truepill/tpos-types'
import { AutobotType, autobotMap } from '@truepill/tpos-types'
import CopayEntry from 'components/CopayRequest'
import ErrorBoundary from 'components/ErrorBoundary'
import LoadingSpinner from 'components/Loading'
import { OrderEntry } from 'components/Order'
import OrderError from 'components/Order/OrderError'
import PriorAuthorizationEntry from 'components/PriorAuthorization'
import { FulfillmentQueueName } from 'hooks/useFulfillmentQueue'
import type { FulfillmentQueue } from 'hooks/useFulfillmentQueue'
import { usePlusClient } from 'providers/VisionRouter'
import styled, { css } from 'styled-components'
import { contrastBackgroundColor } from 'styles/styleVariables'
import type { QueueItem, PriorAuthorization } from 'types'
import { isCopayItems, isOrderItems } from 'utils'
import { isPriorAuthorizationItems } from 'utils/copayChecks'
import QueueHeader from './QueueHeader'

type PrescriptionQueueProps = {
  items: QueueItem[] | PriorAuthorization[]
  totalItems: number
  selected?: boolean
  loading?: boolean
  queue: FulfillmentQueue
  locationId?: string
}

const PrescriptionQueue = ({
  items = [],
  totalItems,
  selected,
  loading,
  queue,
  locationId,
}: PrescriptionQueueProps): JSX.Element => {
  const {
    currentLocation: { queryMap },
  } = usePlusClient()
  const currentAutobotType: AutobotType = queryMap.autobot || AutobotType.mini
  const selectionLive =
    (queue.name === FulfillmentQueueName.Fill ||
      queue.name === FulfillmentQueueName.Automation ||
      queue.name === FulfillmentQueueName.Packing) &&
    selected
  const noIndividualFillSelection =
    (queue.name === FulfillmentQueueName.Automation || queue.name === FulfillmentQueueName.Packing) && selected

  if (loading) {
    return (
      <StyledPrescriptionQueueContainer>
        <QueueHeader items={items} totalItems={totalItems} queue={queue} selected={selectionLive} />
        <PrescriptionsContainer>
          <LoadingSpinnerContainer>
            <LoadingSpinner />
          </LoadingSpinnerContainer>
        </PrescriptionsContainer>
      </StyledPrescriptionQueueContainer>
    )
  }

  return (
    <StyledPrescriptionQueueContainer>
      <QueueHeader
        items={items}
        totalItems={totalItems}
        queue={queue}
        selected={selectionLive}
        locationId={locationId}
      />
      <PrescriptionsContainer>
        <StyledPrescriptionQueue inline={!!selected}>
          <StyledPrescriptionsList>
            {isPriorAuthorizationItems(items) &&
              items.map(item => <PriorAuthorizationEntry priorAuthorization={item} />)}
            {isCopayItems(items) && items.map(item => <CopayEntry copayRequest={item} />)}
            {isOrderItems(items) &&
              items.map(item => {
                const filterOnQueue = currentAutobotType !== AutobotType.deferredAutomation
                let disabledStatuses: MachineStatus[] = []
                let disabledCheckboxStatuses: MachineStatus[] = []

                if (currentAutobotType !== AutobotType.noStatus) {
                  disabledStatuses = autobotMap[currentAutobotType].disabledStatuses
                  disabledCheckboxStatuses = autobotMap[currentAutobotType].disabledCheckboxStatuses
                }

                const disabled =
                  queue.name === FulfillmentQueueName.Automation &&
                  item.rxFillRequests.some(i => disabledStatuses.includes(i.machine?.status))

                const disabledCheckbox =
                  queue.name === FulfillmentQueueName.Automation &&
                  selected &&
                  item.rxFillRequests.some(i => disabledCheckboxStatuses.includes(i.machine?.status))

                return (
                  <ErrorBoundary errorComponent={<OrderError order={item} />} key={`error_${item._id}`}>
                    <OrderEntry
                      selectionLive={selectionLive}
                      noIndividualFillSelection={noIndividualFillSelection}
                      key={item._id}
                      inline={!!selected}
                      order={item}
                      disabled={disabled}
                      disabledCheckbox={disabledCheckbox}
                      filterOnQueue={filterOnQueue}
                      groupRxAndOTC={true}
                    />
                  </ErrorBoundary>
                )
              })}
            {!items.length && <p>{`No items found.`}</p>}
          </StyledPrescriptionsList>
        </StyledPrescriptionQueue>
      </PrescriptionsContainer>
    </StyledPrescriptionQueueContainer>
  )
}

export default PrescriptionQueue

const StyledPrescriptionQueueContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const PrescriptionsContainer = styled.div`
  display: flex;
  min-height: 480px;
  justify-content: center;
`

const PrescriptionQueueInline = css`
  margin-left: 0px;
  margin-right: 0px;
  width: 100%;
`

const StyledPrescriptionQueue = styled.div<{ inline?: boolean }>`
  flex-shrink: 0;
  margin-left: 0.625rem;
  margin-right: 0.625rem;
  border: 5px solid ${contrastBackgroundColor};
  justify-content: center;
  border-radius: 4px;
  display: flex;
  max-height: calc(100% - 2.625rem);
  overflow-y: auto;
  align-items: flex-start;
  width: 18rem;
  height: 100%;
  position: relative;
  background-color: ${contrastBackgroundColor};
  ${({ inline }) => inline && PrescriptionQueueInline}
`

const LoadingSpinnerContainer = styled.div`
  display: flex;
  align-content: center;
  padding-top: 8rem;
  justify-content: center;
  svg {
    height: 126px;
  }
`
const StyledPrescriptionsList = styled.ul`
  border: 5px solid;
  border-color: ${contrastBackgroundColor};
  background-color: ${contrastBackgroundColor};
  border-radius: 4px;
  ul:nth-of-type(n + 2) {
    margin-top: 4px;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
`
