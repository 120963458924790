export enum CustomerIDs {
  MARK_CUBAN = 144,
  LIFE_MD = 224,
  REX_MD = 229,
  SHAPIRO_MD = 228,
  ABB_VIE = 103,
  AHEAD = 70,
  ANNOVERA = 100,
  APOSTROPHE = 1,
  ASEMBIA = 90,
  ASSIST_RX = 184,
  BICYCLE_HEALTH = 27,
  BRIGHTSIDE = 14,
  CALIBRATE = 104,
  CEREBRAL = 55,
  DR_HANK = 35,
  DRUG_REHAB_AGENCY = 36,
  E_MED = 110,
  ELI_LILY = 243,
  EQUISCRIPTS = 79,
  EVERNOW = 37,
  GOOD_RX = 25,
  GOOD_RX_GOLD = 89,
  GOOD_RX_MAP = 95,
  GOOD_RX_PRESCRIPTION_SERVICES = 247,
  HELLO_ALPHA = 16,
  HERO_HEALTH = 21,
  HEY_DOCTOR = 81,
  HIMS = 11,
  K_HEALTH = 82,
  KICK_HEALTH = 15,
  LEMONAID = 19,
  LEVELS = 66,
  LIFE_SCAN = 111,
  MEDISAFE = 57,
  MISTR = 28,
  MONUMENT = 78,
  NAVY = 248,
  NOVARTIS = 105,
  NURX = 2,
  NVA = 219,
  NUTRISENSE = 127,
  OPHELIA = 80,
  OPTUM_RX = 87,
  OSCAR = 124,
  OSMOLEX = 113,
  OVARY_IT = 101,
  PEAK = 112,
  PEAR = 92,
  PHIL = 88,
  PILL_CLUB = 91,
  PREPTECH = 102,
  PREVEMED = 107,
  RALLY = 108,
  RX_SENSE = 96,
  SIMPLE_HEALTH = 24,
  SKIN_SOLUTIONS = 75,
  SOHO_MD = 106,
  SOUTHERN_SCRIPTS = 83,
  SVP = 218,
  TORCH = 73,
  TP = 3,
  TRANSPARENT_RX = 76,
  TRUEPILL_EMR = 97,
  TRUESCRIPTS = 49,
  TWENTYEIGHT_HEALTH = 122,
  TXMD = 72,
  UHG = 98,
  UP_SCRIPT = 99,
  STRATPHARMA = 116,
  RX_SAVINGS_SOLUTIONS = 93,
  VETCOVE = 204,
  WISP = 20,
  QUEST_DIRECT = 158,
  BESTBUY = 222,
}
