import type { FC, MutableRefObject, ChangeEvent } from 'react'
import { ReactComponent as DangerIcon } from 'assets/icons/danger.svg'
import styled from 'styled-components'
import { darkInfo, errorRed } from 'styles/styleVariables'
import { removeNewLineAndTrim } from 'utils'
import CustomizedTextField from './CustomizedTextField'

type TextAreaWithCharacterCountProps = {
  ref?: MutableRefObject<unknown>
  disabled?: boolean
  label?: string
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void
  value: string
  state: 'default' | 'error'
  helperText?: string
  error?: boolean
  errorText?: string
  maxLength?: number
  stripWhiteSpace?: boolean
  placeholder?: string
  resize?: 'vertical' | 'horizontal'
  maxWidth?: string
}

const TextAreaWithCharacterCount: FC<TextAreaWithCharacterCountProps> = ({
  ref,
  disabled,
  label,
  onChange,
  value,
  helperText,
  state,
  error,
  errorText,
  maxLength,
  stripWhiteSpace,
  placeholder,
  maxWidth = '420px',
}) => (
  <TextAreaContainer maxWidth={maxWidth}>
    <CustomizedTextField
      type="textarea"
      disabled={disabled}
      ref={ref}
      label={label}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      helperText={helperText}
      state={state}
      maxLength={maxLength}
    />
    <CharacterCount hasError={error}>
      {(stripWhiteSpace ? removeNewLineAndTrim(value) : value).length}/{maxLength}
    </CharacterCount>
    {error && (
      <ErrorTextContainer>
        <DangerIcon fill={darkInfo} />
        <ErrorText>{errorText}</ErrorText>
      </ErrorTextContainer>
    )}
  </TextAreaContainer>
)

const TextAreaContainer = styled.div<{ maxWidth: string }>`
  position: relative;
  margin-top: 1rem;
  max-width: ${({ maxWidth }) => maxWidth};
  div {
    margin-top: 0;
  }
`

const CharacterCount = styled.span<{ hasEmptyError?: boolean; hasError?: boolean }>`
  position: absolute;
  right: 0.5rem;
  bottom: 0.5rem;
  opacity: 0.5;
  ${props => props.hasError && `bottom: 2rem; color: ${errorRed};`}
`

const ErrorTextContainer = styled.div`
  display: flex;
  align-items: center;
`

const ErrorText = styled.p`
  font-size: 0.875rem;
  color: ${darkInfo};
  margin-left: 0.5rem;
`

export default TextAreaWithCharacterCount
