import { useState, useContext } from 'react'
import { ReactComponent as KeyboardIcon } from 'assets/icons/keyboard.svg'
import { ToolButton } from 'components/SideToolBar'
import type { HotKeyProps } from 'providers/HID/HotKeyProvider'
import { HotKeyContext } from 'providers/HID/HotKeyProvider'
import styled from 'styled-components'

const HotKeysButton = (): JSX.Element => {
  const { toggleShowToolTips, showToolTips } = useContext(HotKeyContext) as HotKeyProps

  // Some users find it odd that when you push the button, you see the tooltip
  // eg: they expect the tooltip to only appear if you mouse in, not if you
  // previously were moused in and now click to hide the tooltip toggle
  const [allowShowToolTip, setAllowShowToolTip] = useState(false)

  return (
    <ToolButton
      label="HotKeys"
      active={showToolTips}
      onMouseLeave={() => setAllowShowToolTip(true)}
      tooltipText={
        <ToolTipContainer>
          <ToolTipTitle>Did you Know?</ToolTipTitle>
          <ToolTipMessage>
            You can toggle your keyboard shortcuts any time by hitting the “?” key or by clicking the keyboard icon
            here.
          </ToolTipMessage>
        </ToolTipContainer>
      }
      tooltipForceHide={showToolTips || !allowShowToolTip}
      clickCallback={() => {
        if (showToolTips) {
          setAllowShowToolTip(false)
        }
        toggleShowToolTips()
      }}
      icon={KeyboardIcon}
    />
  )
}

const ToolTipContainer = styled.div`
  padding-top: 4rem;
  width: 20rem;
  overflow-y: visible;
`

const ToolTipTitle = styled.p`
  text-align: left;
  font-weight: 500;
  font-size: 1.125rem;
`
const ToolTipMessage = styled.p`
  margin-top: 0.625rem;
  text-align: left;
  font-weight: normal;
  white-space: normal;
  padding-bottom: 1rem;
  text-transform: none;
`

export default HotKeysButton
