import type { History } from 'history'
import type { FulfillmentQueueName } from 'hooks/useFulfillmentQueue'
import { goToFulfillment } from 'routes'
import type { Fill, Order } from 'types'

interface QueryMapWorkflow {
  workflow?: string
}

const continueOrderWorkflow = async (
  search: string | undefined,
  queryMap: QueryMapWorkflow,
  history: History<unknown>,
  getNextOrder?: (currentOrder?: Order | undefined, currentFill?: Fill | undefined) => void | Promise<void>,
  fulfillmentQueueName?: FulfillmentQueueName,
  isBatchOrder?: boolean,
): Promise<void> => {
  const isQueueFlow = queryMap.workflow === 'queue' || isBatchOrder
  if (isQueueFlow && getNextOrder && fulfillmentQueueName) {
    return await getNextOrder()
  }

  return history.push(goToFulfillment({ fulfillmentQueueName, search }))
}

export default continueOrderWorkflow
