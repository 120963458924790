import { useQuery } from '@apollo/client'
import { GET_DUR_ALLERGIES } from 'gql'
import type { FuzzySearchProps } from 'modals/DurInputMappingsModal/types'
import type { DURSubject } from 'types'
import FuzzySearchAutoComplete from './FuzzySearchRender'

const AllergiesFuzzySearch = (props: FuzzySearchProps) => {
  const { data, error } = useQuery<{ getAllergies: DURSubject[] }>(GET_DUR_ALLERGIES, {
    skip: !props.searchTerm,
    variables: { knownAllergies: props.searchTerm },
  })

  return <FuzzySearchAutoComplete {...props} suggestions={data?.getAllergies ?? []} error={error} />
}

export default AllergiesFuzzySearch
