import { TaskBadge } from 'components/UserInitialsBadge'
import type { Subtask } from 'providers/TaskProvider'
import styled from 'styled-components'
import { borderColor } from 'styles/styleVariables'

interface Task {
  label: string
  completed: boolean
  subtasks?: Subtask[]
}

interface TaskListProps {
  tasks: Task[]
  className?: string
}

const TaskList = ({ tasks, className }: TaskListProps): JSX.Element => {
  return (
    <TaskListContainer className={className}>
      <StyledTaskList>
        {tasks.map((task, i) => (
          <>
            <TaskEntry key={task.label} {...task} />
            {task.subtasks?.map((subtask, j) => (
              <SubtaskEntry key={subtask.label} {...subtask} />
            ))}
          </>
        ))}
      </StyledTaskList>
    </TaskListContainer>
  )
}

const TaskEntry = ({ completed, label }: Task): JSX.Element => {
  return (
    <StyledTaskEntry>
      <TaskBadge completed={completed} />
      <TaskLabel>{label}</TaskLabel>
    </StyledTaskEntry>
  )
}

const SubtaskEntry = ({ label }: Subtask): JSX.Element => {
  return (
    <StyledTaskEntry>
      <SubtaskLabel>{label}</SubtaskLabel>
    </StyledTaskEntry>
  )
}

export const StyledTaskEntry = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  :not(:first-of-type) {
    margin-top: 0.625rem;
  }
  > div {
    margin-left: -0.8125rem;
  }
`

const TaskLabel = styled.p`
  margin-left: 0.5rem;
`

const SubtaskLabel = styled.p`
  color: #535762;
  margin-left: 2.5rem;
`

const TaskListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 0.625rem;
`

const StyledTaskList = styled.ul`
  border-left: 0.125rem solid ${borderColor};
  overflow: visible;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

export default TaskList
