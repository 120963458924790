import DatePicker from 'components/DatePicker'
import { NumericInput } from 'components/NumericInput'
import { ListRowLabel, ListRowValue, RaisedAnchor, RightFilledHeader, PaddingBlock } from 'components/RXTable'
import moment from 'moment'
import type { EdiOverrides } from 'types'
import {
  RXEDIListRow,
  RXEDImageCell,
  RXEDIRightEditCell,
  RXEDIRightTitleCell,
  RXEDITitleRow,
  DateCellPadding,
  RXTextInput,
} from '..'

type ClinicalSegmentProps = {
  ediOverrides: EdiOverrides
  updateEdiOverrides: (input: EdiOverrides) => void
}

const ClinicalSegment = ({ ediOverrides, updateEdiOverrides }: ClinicalSegmentProps): JSX.Element => {
  const { clinical } = ediOverrides

  const diagnosisCode = clinical?.diagnosisCode
  const diagnosisCodeQualifier = clinical?.diagnosisCodeQualifier
  const clinicalInformationCounter = clinical?.clinicalInformationCounter
  const measurementDate = clinical?.measurementDate
  const measurementTime = clinical?.measurementTime
  const measurementDimension = clinical?.measurementDimension
  const measurementUnit = clinical?.measurementUnit
  const measurementValue = clinical?.measurementValue

  return (
    <>
      <ClinicalHeaderRow />
      <RXEDIListRow>
        <RXEDIRightEditCell>
          <ListRowLabel>Diagnosis Code (DO):</ListRowLabel>
          <ListRowValue>
            <RXTextInput
              value={diagnosisCode ?? ''}
              onChange={({ target: { value: diagnosisCode } }) => updateEdiOverrides({ clinical: { diagnosisCode } })}
              placeholder="Diagnosis Code..."
            />
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>

      <RXEDIListRow>
        <RXEDIRightEditCell>
          <ListRowLabel>Diagnosis Code Qualifier (WE):</ListRowLabel>
          <ListRowValue>
            <RXTextInput
              value={diagnosisCodeQualifier ?? ''}
              onChange={({ target: { value: diagnosisCodeQualifier } }) =>
                updateEdiOverrides({ clinical: { diagnosisCodeQualifier } })
              }
              placeholder="Diagnosis Code Qualifier..."
            />
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>

      <RXEDIListRow>
        <RXEDIRightEditCell>
          <ListRowLabel>Clinical Information Counter (XE):</ListRowLabel>
          <ListRowValue>
            <NumericInput
              value={clinicalInformationCounter}
              onChange={val => updateEdiOverrides({ clinical: { clinicalInformationCounter: val } })}
              placeholder="Clinical Information Counter..."
            />
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>

      <RXEDIListRow>
        <RXEDIRightEditCell>
          <ListRowLabel>Measurement Date (ZE):</ListRowLabel>
          <ListRowValue>
            <DateCellPadding>
              <DatePicker
                date={moment(measurementDate ?? new Date(), 'MM/DD/YYYY').toDate()}
                onChange={date => {
                  updateEdiOverrides({ clinical: { measurementDate: moment(date).format('YYYYMMDD') } })
                }}
              />
            </DateCellPadding>
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>

      <RXEDIListRow>
        <RXEDIRightEditCell closeOffBottomBorder>
          <ListRowLabel>Measurement Time (H1):</ListRowLabel>
          <ListRowValue>
            <RXTextInput
              value={measurementTime ?? ''}
              onChange={({ target: { value: measurementTime } }) =>
                updateEdiOverrides({ clinical: { measurementTime } })
              }
              placeholder="Measurement Time..."
            />
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>

      <RXEDIListRow>
        <RXEDIRightEditCell closeOffBottomBorder>
          <ListRowLabel>Measurement Dimension (H2):</ListRowLabel>
          <ListRowValue>
            <RXTextInput
              value={measurementDimension ?? ''}
              onChange={({ target: { value: measurementDimension } }) =>
                updateEdiOverrides({ clinical: { measurementDimension } })
              }
              placeholder="Measurement Dimension..."
            />
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>

      <RXEDIListRow>
        <RXEDIRightEditCell closeOffBottomBorder>
          <ListRowLabel>Measurement Unit (H3):</ListRowLabel>
          <ListRowValue>
            <RXTextInput
              value={measurementUnit ?? ''}
              onChange={({ target: { value: measurementUnit } }) =>
                updateEdiOverrides({ clinical: { measurementUnit } })
              }
              placeholder="Measurement Unit..."
            />
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>

      <RXEDIListRow>
        <RXEDIRightEditCell closeOffBottomBorder>
          <ListRowLabel>Measurement Value (H4):</ListRowLabel>
          <ListRowValue>
            <RXTextInput
              value={measurementValue ?? ''}
              onChange={({ target: { value: measurementValue } }) =>
                updateEdiOverrides({ clinical: { measurementValue } })
              }
              placeholder="Measurement Value..."
            />
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>
    </>
  )
}

const ClinicalHeaderRow = () => {
  return (
    <RXEDITitleRow>
      <RXEDImageCell />
      <RXEDIRightTitleCell>
        <PaddingBlock />
        <RightFilledHeader>
          <RaisedAnchor id={'ClinicalSegment'} />
          Clinical segment
          {/* <HotKeyToolTip label={'?'} position={'right'} offsetLeft={1} offsetTop={0} /> */}
        </RightFilledHeader>
      </RXEDIRightTitleCell>
    </RXEDITitleRow>
  )
}

export default ClinicalSegment
