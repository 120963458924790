import type { ApolloError } from '@truepill/tpos-react-router'
import { useQuery } from '@truepill/tpos-react-router'
import { GET_USER } from 'gql'
import type { User } from 'types'

export interface UserInfo extends User {
  phone: string
}

interface GetUserInfoParams {
  userId: string
}

interface GetUserInfoResponse {
  user: UserInfo
}

type useUserInfoType = {
  loading: boolean
  error?: ApolloError
  userInfo?: UserInfo
}

const useUserInfo = (userId: string): useUserInfoType => {
  const { data, loading, error } = useQuery<GetUserInfoResponse, GetUserInfoParams>(GET_USER, {
    skip: !userId,
    variables: { userId },
  })

  if (!data) {
    return {
      loading,
      error,
    }
  }

  const { user } = data

  return {
    userInfo: user,
    loading,
    error,
  }
}

export default useUserInfo
