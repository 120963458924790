import { useMemo } from 'react'
import { Badge } from 'components/UserInitialsBadge'
import { alertRed, warningYellow, primaryColor } from 'styles/styleVariables'
import type { QueueItem } from 'types'
import { FillWorkStatus } from 'types'
import { isCopayRequest } from 'utils'

const calculateNumberToDisplay = (items: QueueItem[], status: FillWorkStatus): number => {
  let count = 0
  for (let i = 0; i < items.length; i++) {
    const item = items[i]

    const iterItems = isCopayRequest(item) ? item.copayRequestFills : item.rxFillRequests

    // hacked out till we have this handled
    const itemFitsStatus = false

    if (itemFitsStatus) {
      for (let j = 0; j < iterItems.length; j++) {
        // you can't have an order with prescriptions with no fills
        count += 1
      }
    }
  }
  return count
}

type TicketCounterProps = { items: QueueItem[]; status: FillWorkStatus }

const TicketCounter = ({ items, status }: TicketCounterProps): JSX.Element => {
  const numberToDisplay = useMemo(() => calculateNumberToDisplay(items, status), [items, status])

  if (numberToDisplay === 0) {
    return <></>
  }

  let color = primaryColor
  if (status === FillWorkStatus.overdue) {
    color = alertRed
  } else if (status === FillWorkStatus.nearOverdue) {
    color = warningYellow
  }
  return <Badge backgroundColor={color} label={`${numberToDisplay}`} />
}

export default TicketCounter
