import styled from 'styled-components'

const SecurityButton = styled.button`
  background: #ffffff;

  border: 2px solid #cccccc;
  border-radius: 4px;

  width: 250px;
  height: 40px;
  line-height: 24px;
  box-sizing: border-box;

  font-weight: 500;
  font-size: 0.875rem;
  font-weight: 500;

  text-align: center;
`
export default SecurityButton
