import type { Dispatch, SetStateAction } from 'react'
import { EditMode, NormalMode } from 'components/RXPageStructure'
import { CompoundLabel, CompoundTextInput, CompoundValue, ListRowLabel, ListRowValue, RXCell } from 'components/RXTable'
import styled from 'styled-components'
import { contrastBackgroundColor } from 'styles/styleVariables'
import type { GuestPatient } from 'types'
import { checkIfProvided, formatPhoneNumber } from 'utils'

type GuestPatientDetailsProps = {
  className?: string
  guestPatient: GuestPatient
  guestPatientValues: GuestPatient
  setGuestPatientValues: Dispatch<SetStateAction<GuestPatient>>
}

const GuestPatientDetails = ({
  className,
  guestPatient,
  guestPatientValues,
  setGuestPatientValues,
}: GuestPatientDetailsProps): JSX.Element => {
  return (
    <StyledPatientDetails data-testid="GuestPatientDetails" className={className}>
      <StyledRXCell data-test-row="name">
        <NormalMode>
          <ListRowLabel>Name:</ListRowLabel>
          <ListRowValue>
            {guestPatient.firstName} {guestPatient.lastName}
          </ListRowValue>
        </NormalMode>
        <EditMode>
          <ListRowLabel>
            <CompoundLabel>First Name:</CompoundLabel>
            <CompoundLabel>Last Name:</CompoundLabel>
          </ListRowLabel>
          <CompoundValue>
            <CompoundTextInput
              value={guestPatientValues.firstName}
              onChange={e =>
                setGuestPatientValues({
                  ...guestPatientValues,
                  firstName: e.target.value,
                })
              }
            />
            <CompoundTextInput
              value={guestPatientValues.lastName}
              onChange={e =>
                setGuestPatientValues({
                  ...guestPatientValues,
                  lastName: e.target.value,
                })
              }
            />
          </CompoundValue>
        </EditMode>
      </StyledRXCell>
      {/*
      <StyledRXCell data-test-row="dob">
        <NormalMode>
          <ListRowLabel>DOB:</ListRowLabel>
          <ListRowValue>
            {moment(patient.dob).format('MM/DD/YYYY')}
          </ListRowValue>
        </NormalMode>
        <EditMode>
          <ListRowLabel>DOB:</ListRowLabel>
          <ListRowValue>
            <DatePicker
              date={patientValues.dob}
              onChange={(date?: Date) => {
                setGuestPatientState('dob', moment(date).format('YYYY-MM-DD'))
              }}
            />
          </ListRowValue>
        </EditMode>
      </StyledRXCell>
      */}

      <StyledRXCell data-test-row="phone">
        <NormalMode>
          <ListRowLabel>Phone:</ListRowLabel>
          <ListRowValue>{checkIfProvided(formatPhoneNumber(guestPatient.contacts?.phone))}</ListRowValue>
        </NormalMode>
        <EditMode>
          <ListRowLabel>Phone:</ListRowLabel>
          <CompoundValue>
            <CompoundTextInput
              value={guestPatientValues?.contacts?.phone}
              onChange={e =>
                setGuestPatientValues({
                  ...guestPatientValues,
                  contacts: {
                    ...guestPatientValues.contacts,
                    phone: e.target.value,
                  },
                })
              }
            />
          </CompoundValue>
        </EditMode>
      </StyledRXCell>
      <StyledRXCell data-test-row="email">
        <NormalMode>
          <ListRowLabel>Email:</ListRowLabel>
          <ListRowValue>{checkIfProvided(guestPatient.contacts?.email)}</ListRowValue>
        </NormalMode>
        <EditMode>
          <ListRowLabel>Email:</ListRowLabel>
          <ListRowValue>
            <CompoundTextInput
              type="email"
              value={guestPatientValues.contacts?.email}
              onChange={e =>
                setGuestPatientValues({
                  ...guestPatientValues,
                  contacts: {
                    ...guestPatientValues.contacts,
                    email: e.target.value,
                  },
                })
              }
            />
          </ListRowValue>
        </EditMode>
      </StyledRXCell>
    </StyledPatientDetails>
  )
}

const StyledPatientDetails = styled.ul`
  > :nth-child(2n) {
    background-color: ${contrastBackgroundColor};
  }
`

const StyledRXCell = styled(RXCell)`
  grid-template-columns: [label] 6rem [value] 1fr;
  padding-left: 0.625rem;
`

export default GuestPatientDetails
