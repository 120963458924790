// give a numerator and denominator, calculates the remainder in such a way that
// avoids floating point error with numbers that have decimals.
// basically takes the floats and multiplies them by orders of 10 until they're ints,
// performs the modulo calculation, then returns the remainder

export const floatSafeRemainder = (numerator: number, denominator: number): number => {
  const valDecCount = (numerator.toString().split('.')[1] || '').length
  const stepDecCount = (denominator.toString().split('.')[1] || '').length
  const decCount = valDecCount > stepDecCount ? valDecCount : stepDecCount
  const numeratorInt = parseInt(numerator.toFixed(decCount).replace('.', ''))
  const denominatorInt = parseInt(denominator.toFixed(decCount).replace('.', ''))
  return (numeratorInt % denominatorInt) / Math.pow(10, decCount)
}
