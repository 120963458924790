import { useHistory, useLocation, useMutation } from '@truepill/tpos-react-router'
import type { SendBackReason } from '@truepill/tpos-types'
import { SendBackToFillFromPV2Reason, SendBackToPv1FromPV2Reason } from '@truepill/tpos-types'
import { ReactComponent as CancelIcon } from 'assets/icons/cancel.svg'
import ActionButton from 'components/ActionButton'
import IconWrapper from 'components/IconWrapper'
import { SEND_BACK_TO_FILL, SEND_BACK_TO_PV1_AND_TRIAGE } from 'gql'
import { FulfillmentQueueName } from 'hooks/useFulfillmentQueue'
import SendBackModal from 'modals/SendBackModal'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import { goToFulfillment } from 'routes'
import { bodyPrimaryColor } from 'styles/styleVariables'
import type { Fill, Order } from 'types'

type SendBackButtonProps = { order: Order; fill: Fill; orderEditable: boolean }

export const SendBackButton = ({ fill, order, orderEditable }: SendBackButtonProps): JSX.Element => {
  const { showModal } = useModalContext()
  const history = useHistory()
  const { search } = useLocation()

  const [sendBackToPV1AndTriage] = useMutation(SEND_BACK_TO_PV1_AND_TRIAGE)
  const [sendBackToFill] = useMutation(SEND_BACK_TO_FILL)

  return (
    <ActionButton
      disabled={!orderEditable}
      icon={
        <IconWrapper>
          <CancelIcon fill={bodyPrimaryColor} />
        </IconWrapper>
      }
      label={'Send back'}
      onClick={() => {
        showModal(() => (
          <SendBackModal
            tabs={[
              { id: 'toFill', title: 'Send to fill', options: Object.values(SendBackToFillFromPV2Reason) },
              { id: 'toPv1', title: 'Send to PV1', options: Object.values(SendBackToPv1FromPV2Reason) },
            ]}
            confirmationCallback={async (tabId: string, sendBackReason: SendBackReason, message?: string) => {
              if (tabId === 'toFill') {
                await sendBackToFill({
                  variables: { orderId: order._id, fillId: fill._id, message, sendBackReason },
                })
              } else if (tabId === 'toPv1') {
                await sendBackToPV1AndTriage({
                  variables: { orderId: order._id, fillId: fill._id, message, sendBackReason },
                })
              }
              history.push(goToFulfillment({ fulfillmentQueueName: FulfillmentQueueName.PV2, search }))
            }}
          />
        ))
      }}
    />
  )
}
