import { isObject } from 'lodash'

export default function omitDeep<T extends object>(obj: T, keys: string[]): T {
  const result = Object.keys(obj).reduce((acc, key) => {
    const value = (obj as T)[key as keyof T]
    if (keys.includes(key)) {
      return acc
    }
    acc[key as keyof T] = isObject(value) ? omitDeep(value, keys) : value
    return acc
  }, {} as T)

  const isArray = Array.isArray(obj)
  if (isArray) {
    const arrayResult = [] as Array<unknown>

    Object.keys(result).forEach(key => {
      arrayResult[parseInt(key)] = result[key as keyof T]
    })

    return arrayResult as unknown as T
  }

  return result
}
