import {
  AlternatingShading,
  ErrorText,
  NoColorBackground,
  StyledTextInput,
  StyledSelect,
  CloseOffTopBorder,
} from 'components/RXTable'
import styled, { css } from 'styled-components'
import { primaryBackgroundColor } from 'styles/styleVariables'

export const RxEdiRow = styled.ul<{ transparent?: boolean }>`
  display: grid;
  grid-template-rows: auto;
  ${({ transparent }) => transparent && NoColorBackground}
`

export const RxEdiListRow = styled(RxEdiRow)<{ noStripe?: boolean; error?: boolean }>`
  ${({ error }) => error && ErrorText}
  ${({ noStripe }) => !noStripe && AlternatingShading}
`

export const RxEdiTitleRow = styled(RxEdiRow)`
  position: relative;
  background-color: ${primaryBackgroundColor};
`

export const RxEdiRightTitleCell = styled.ul`
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  grid-row: 1;
`
interface StyledRXImageCellProps {
  noBorder?: boolean
  closeOffTopBorder?: boolean
  closeOffBottomBorder?: boolean
  noColorBackground?: boolean
  fullWidth?: boolean
}

export const RxEdiRowContainer = styled.ul<StyledRXImageCellProps>`
  padding-left: 0.375rem;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  grid-row: 1;
  display: grid;
  padding-left: 2rem;
  grid-template-rows: auto;
  grid-template-columns: [label] 0.6fr [value] 1fr [eyeButton] 0.07fr;
  ${({ closeOffTopBorder }) => closeOffTopBorder && CloseOffTopBorder}
`

const containerMargin = css`
  margin: 0.225rem;
`

export const RxTextInput = styled(StyledTextInput)`
  ${containerMargin}
`

export const DateCellPadding = styled.div`
  ${containerMargin}
`

export const StateSelect = styled(StyledSelect)`
  ${containerMargin}
  font-weight: normal;
  width: 7rem;
`
