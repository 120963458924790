import { Select } from '@truepill/react-capsule'
import { InsuranceCardType } from '@truepill/tpos-types'
import CustomizedTextField from 'components/CustomizedTextField'
import { TwoColumnDivision } from 'components/RXTable'
import styled from 'styled-components'
import { InsuranceDesignationType } from 'types'
import { camelCaseToHumanReadable } from 'utils'
import { relationshipCodes } from '../ServiceCodes'

const billingOrderDesgnationType = [
  InsuranceDesignationType.Primary,
  InsuranceDesignationType.Secondary,
  InsuranceDesignationType.Tertiary,
]

const inputStyle = {
  margin: '8px 0 0 0',
  width: '100%',
}

type Option = { value: string; label: string }

const buildOptions = (obj: { [key: string]: string }): Option[] => {
  return Object.keys(obj).map(key => {
    return { label: camelCaseToHumanReadable(key), value: obj[key] }
  })
}

const getOptionFromValue = (value: string, obj: { [key: string]: string }): Option | undefined => {
  const key = Object.keys(obj).find(key => obj[key] === value)

  if (key) {
    return { label: camelCaseToHumanReadable(key), value: obj[key] }
  }

  return undefined
}

const defaultInsuranceCardOption: Option = { label: 'Not Specified', value: '' }
const cardTypeOptions = buildOptions(InsuranceCardType)

type EditPayerDetailsProps = { handleChange: any; payerInfo: any; payerIndex: number }
const EditPayerDetails = ({ handleChange, payerInfo, payerIndex }: EditPayerDetailsProps): JSX.Element => {
  return (
    <PayerView>
      <PayerTitle>{billingOrderDesgnationType[payerIndex] ?? 'Other'} payer</PayerTitle>
      <Columns style={{ width: '70rem' }}>
        <ul>
          <PayerFormElement>
            <PayerFormElementLabel>Insurance Name</PayerFormElementLabel>
            <CustomizedTextField
              placeholder="Insurance Name..."
              value={payerInfo?.name}
              onChange={e => handleChange('name', e.target.value)}
              style={inputStyle}
              size="sm"
            />
          </PayerFormElement>{' '}
          <PayerFormElement>
            <PayerFormElementLabel>Cardholder / Member ID</PayerFormElementLabel>
            <CustomizedTextField
              placeholder="Cardholder ID..."
              value={payerInfo?.cardholderId}
              onChange={e => handleChange('cardholderId', e.target.value)}
              style={inputStyle}
              size="sm"
            />
          </PayerFormElement>
          <PayerFormElement>
            <PayerFormElementLabel>RxBIN</PayerFormElementLabel>
            <CustomizedTextField
              data-testid="rxbin"
              placeholder="RxBin..."
              value={payerInfo?.bin}
              onChange={e => handleChange('bin', e.target.value)}
              style={inputStyle}
              size="sm"
            />
          </PayerFormElement>
          <PayerFormElement>
            <PayerFormElementLabel>RxPCN (optional)</PayerFormElementLabel>
            <CustomizedTextField
              data-testid="rxpcn"
              placeholder="RxPCN..."
              value={payerInfo?.pcn}
              onChange={e => handleChange('pcn', e.target.value)}
              style={inputStyle}
              size="sm"
            />
          </PayerFormElement>
          <PayerFormElement>
            <PayerFormElementLabel>Group number (optional)</PayerFormElementLabel>
            <CustomizedTextField
              placeholder="Group #..."
              value={payerInfo?.group}
              onChange={e => handleChange('group', e.target.value)}
              style={inputStyle}
              size="sm"
            />
          </PayerFormElement>
          <PayerFormElement>
            <PayerFormElementLabel>Person code (optional)</PayerFormElementLabel>
            <CustomizedTextField
              placeholder="Person code..."
              value={payerInfo?.personCode}
              onChange={e => handleChange('personCode', e.target.value)}
              style={inputStyle}
              size="sm"
            />
          </PayerFormElement>
          <PayerFormElement>
            <PayerFormElementLabel>Relationship (optional)</PayerFormElementLabel>
            <Select
              options={relationshipCodes}
              label=""
              required
              placeholder=""
              onChange={option => {
                const value = option ? option : ''
                handleChange('relationship', value)
              }}
              value={payerInfo?.relationship}
              css={{
                ...inputStyle,
                margin: '12px 0 0 0',
              }}
              variant="small"
            />
          </PayerFormElement>
        </ul>
        <ul>
          <PayerFormElement>
            <PayerFormElementLabel>Card Type (optional)</PayerFormElementLabel>
            <Select
              selectedKey="label"
              optionComponent={({ option }) => <div>{option.label}</div>}
              options={cardTypeOptions}
              label=""
              required
              placeholder=""
              onChange={option => {
                const value = option ? option.value : defaultInsuranceCardOption.value
                handleChange('cardType', value)
              }}
              value={
                (payerInfo?.cardType ? getOptionFromValue(payerInfo?.cardType, InsuranceCardType) : undefined) ||
                defaultInsuranceCardOption
              }
              css={{
                ...inputStyle,
                margin: '12px 0 0 0',
              }}
              variant="small"
            />
          </PayerFormElement>
        </ul>
      </Columns>
    </PayerView>
  )
}

export default EditPayerDetails

const PayerFormElement = styled.div``

const PayerFormElementLabel = styled.div`
  font-weight: 500;
  font-size: 0.875rem;
  display: block;
  width: 100%;
  margin: 12px 0 0 0;
`

const PayerTitle = styled.h2`
  font-weight: 500;
  margin: 0 0 0 0;
`

const PayerView = styled.div`
  display: inline-block;
  width: 100%;
`

const Columns = styled.div`
  ${TwoColumnDivision}
  grid-gap: 1.25rem;
`
