import { Select } from '@truepill/react-capsule'
import type { Language, Preferences } from '@truepill/tpos-types'
import { Languages, DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from '@truepill/tpos-types'
import { FormItem } from 'components/ColumnForm'
import { TransparentHeader } from 'components/PageStructure'

interface LanguageOption {
  key: Language
  value: string
}

const supportedLanguages =
  (SUPPORTED_LANGUAGES.map(language => ({
    key: language,
    value: Languages[language],
  })) as LanguageOption[]) ?? []

interface PreferencesDetailProps {
  preferences: Preferences
  onChange: (preferences: Preferences) => void
}

const defaultLanguageOption = {
  key: DEFAULT_LANGUAGE,
  value: Languages[DEFAULT_LANGUAGE],
}

const PreferencesDetail = ({ preferences, onChange }: PreferencesDetailProps) => {
  const languageValue = preferences?.language ?? defaultLanguageOption.key
  const languageOption = supportedLanguages.find(({ key }) => key === languageValue)
  return (
    <>
      <TransparentHeader>Preferences</TransparentHeader>
      <FormItem data-test-row="preferences">
        <Select
          required
          data-testid="language"
          label="Language"
          variant="small"
          value={languageOption ?? defaultLanguageOption}
          placeholder="Select language..."
          options={supportedLanguages}
          selectedKey="value"
          onChange={(option: LanguageOption | undefined) => onChange({ ...preferences, language: option?.key })}
        />
      </FormItem>
    </>
  )
}

export default PreferencesDetail
