import moment from 'moment'
import type { Fill } from 'types'

type FormattedFillDateProps = { fill?: Fill }
/**
 * A helper component for displaying fill dates in a standardized way.
 */
const FormattedFillDate = ({ fill }: FormattedFillDateProps): JSX.Element => (
  <>{fill ? moment(fill.dispensed.dispensedAt).format('MM/DD/YYYY') : <>- No Fills -</>}</>
)
FormattedFillDate.displayName = 'FillDate'
export default FormattedFillDate
