import { ListRowLabel, ListRowValue } from 'components/RXTable'
import moment from 'moment'
import styled from 'styled-components'
import { borderColor } from 'styles/styleVariables'
import type { Fill } from 'types'

type EventLogProps = { fill: Fill }

const EventLog = ({ fill }: EventLogProps): JSX.Element => {
  const claims = [...fill.claims].reverse()

  return (
    <EventLogContainer borderBottom>
      {claims.map((claim, claimIndex) => {
        const { transmissionDate, status } = claim
        const transmittedTime = status === 'Reversed' ? claim.reversal?.reversedAt : transmissionDate
        const timestamp = moment(transmittedTime).format('MM/DD/YYYY h:mm:ssa')
        const rejectCodes = claim.received?.groups?.[0]?.response?.rejectCodes
        const rejectCodeArray = rejectCodes?.map(i => i.rejectCode) ?? []

        return (
          <EventLogCell key={claimIndex}>
            <ListRowLabel>Attempt {claims.length - claimIndex}:</ListRowLabel>
            <ListRowValue>
              {timestamp} {rejectCodeArray?.join(', ')}
            </ListRowValue>
          </EventLogCell>
        )
      })}
    </EventLogContainer>
  )
}

const EventLogContainer = styled.div<{
  borderBottom?: boolean
}>`
  font-family: monospace;
  border-radius: 0.25rem;
  border: 0.125rem solid ${borderColor};
  margin-top: 0.4rem;
  padding: 0.6rem;
  min-height: 100px;
  max-height: 127px;
  label {
    font-weight: 550;
  }
  overflow-y: auto;
`
const EventLogCell = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 0.5rem;

  & > ${ListRowLabel} {
    flex: 0 0 auto;
    margin-right: 0.5rem;
  }

  & > ${ListRowValue} {
    flex: 1 1 auto;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
`

export default EventLog
