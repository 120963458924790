import type { ReactNode } from 'react'
import { useEffect } from 'react'
import CloseButton from 'components/CloseButton'
import useHotKey, { HotKeyLevel } from 'hooks/useHotKey'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import type { FlattenSimpleInterpolation } from 'styled-components'
import styled from 'styled-components'
import { borderColor, capsuleGreyColor, primaryBackgroundColor } from 'styles/styleVariables'
import type { ChildProps } from 'types'
import IconWrapper from './IconWrapper'

export interface ModalProps {
  dismissModal: () => void
}

const Modal = (): JSX.Element => {
  const { modals } = useModalContext()
  if (modals.length) {
    return <ModalView />
  }

  return <></>
}

const ModalView = (): JSX.Element => {
  const { modals, dismissModal } = useModalContext()

  useHotKey(
    'Escape',
    HotKeyLevel.modal,
    () => {
      if (document.activeElement?.tagName === 'BODY') {
        dismissModal()
      }
    },
    undefined,
    'modalView',
    true,
  )

  useEffect(() => {
    // Blur the initial button that was clicked / element that was selected
    // when this modal appeared
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur()
    }
  }, [])

  const TopModal = modals[0]

  return (
    <StyledModalContainer id="ModalView">
      <TopModal />
    </StyledModalContainer>
  )
}

type ModalWrapperProps = {
  styles?: FlattenSimpleInterpolation
  id?: string
  'data-testid'?: string
} & ChildProps

export const ModalWrapper = ({ id, styles, 'data-testid': testId, children }: ModalWrapperProps): JSX.Element => {
  return (
    <StyledModalWrapper id={id ?? 'Modal'} data-testid={testId} role="dialog" aria-modal="true" styles={styles}>
      {children}
    </StyledModalWrapper>
  )
}

type ModalHeaderType = {
  marginBottom?: string
  marginTop?: string
  closeCallback?: () => void
  hideClose?: boolean
} & ChildProps

export const ModalHeader = ({
  hideClose = false,
  marginBottom,
  marginTop,
  closeCallback,
  children,
}: ModalHeaderType): JSX.Element => {
  const { dismissModal } = useModalContext()
  const handleClickCallback = () => {
    dismissModal()
    closeCallback?.()
  }

  return (
    <StyledModalHeader id="ModalHeader" marginBottom={marginBottom} marginTop={marginTop}>
      <TitleContainer>{children}</TitleContainer>
      {!hideClose && <StyledCloseButton size="0.8rem" contrast clickCallback={handleClickCallback} />}
    </StyledModalHeader>
  )
}

type ModalHeaderWithIconProps = {
  icon: ReactNode
  title: string
  marginBottom?: string
  marginTop?: string
  closeCallback?: () => void
}
export const ModalHeaderWithIcon = ({
  icon,
  title,
  marginBottom,
  marginTop,
  closeCallback,
}: ModalHeaderWithIconProps): JSX.Element => {
  return (
    <StyledHeader marginBottom={marginBottom} marginTop={marginTop} closeCallback={closeCallback}>
      <Heading>
        <StyledIconWrapper>{icon}</StyledIconWrapper>
        <ModalTitle>{title}</ModalTitle>
      </Heading>
    </StyledHeader>
  )
}

export const ModalDescription = styled.p`
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${capsuleGreyColor};
`

export const ModalTitle = styled.h1`
  font-size: 2.25rem;
  font-family: Lato, sans-serif;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.55px;
`

const Heading = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const StyledHeader = styled(ModalHeader)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1em;
  > :first-child {
    margin-right: 0.5rem;
  }
`

const StyledIconWrapper = styled(IconWrapper)`
  margin-right: 0.5rem;
`

const StyledCloseButton = styled(CloseButton)`
  position: relative;
  width: auto;
  height: auto;
  top: 0;
  right: 0;
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-size: 18.5pt;
  > * {
    margin-right: 0.5rem;
  }
`

const StyledModalContainer = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 6;
  background: rgba(33, 45, 65, 0.9);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 2.5rem;
`

const StyledModalWrapper = styled.div<{
  styles?: FlattenSimpleInterpolation
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${primaryBackgroundColor};
  padding: 1.5rem;
  min-width: 23.75rem;
  width: max-content;
  height: max-content;
  max-height: calc(100vh - 8rem);
  max-width: calc(100vw - 5.375rem);
  overflow-y: auto;
  border-radius: 1rem;
  ${({ styles }) => styles}
`

StyledModalWrapper.displayName = 'StyledModalWrapper'

export const StyledModalHeader = styled.span<{
  marginBottom?: string
  marginTop?: string
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom};`}
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop};`}
  > :first-child {
    margin-right: 0.5rem;
  }
`

StyledModalHeader.displayName = 'StyledModalHeader'

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 1rem;
  align-items: center;
  margin-top: 1.25rem;
`

export const InputContainer = styled.div<{ width?: string; margin?: string }>`
  ${({ width }) => width && `width: ${width};`}
  margin-top: 0.625rem;
  ${({ margin }) => margin && `margin: ${margin};`}
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  textarea {
    padding-top: 0.3125rem;
    height: 7rem;
    margin-top: 0.625rem;
    border: 0.125rem solid ${borderColor};
  }
`

export default Modal
