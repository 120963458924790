//used for displaying phoneNumbers when you already have the entire output
export default function formatPhoneNumber(phoneNumberString: string | null | undefined): string | undefined {
  if (!phoneNumberString) {
    return
  }

  const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    const intlCode = match[1] ? '+1 ' : ''
    return [intlCode, '(', match[2], ')', ' ', match[3], '-', match[4]].join('')
  } else {
    return 'Improper Format'
  }
}

//used to display phone numbers in an input field
export function formatPhoneInput(phoneNumberString?: string | null): string | undefined {
  if (!phoneNumberString) return ''

  const phoneNumber = phoneNumberString.replace(/[^\d]/g, '') //cleans input for any non-digit values
  const phoneLength = phoneNumber.length

  if (phoneLength < 4) return phoneNumber

  if (phoneLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
  }

  if (phoneLength > 10 && phoneNumber.slice(0, 1) === '1') {
    return `+1(${phoneNumber.slice(1, 4)}) ${phoneNumber.slice(4, 7)}-${phoneNumber.slice(7, 11)}`
  }

  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
}

export const validPhoneNumber = (phoneNumberString?: string): boolean =>
  !!phoneNumberString && phoneNumberString.length > 9

export const formatPhoneForDB = (inputPhone?: string): string => {
  if (!inputPhone) {
    return ''
  }

  let returnVal = inputPhone.replace(/[^0-9]/g, '')
  returnVal = returnVal.slice(0, 3) + '-' + returnVal.slice(3, 6) + '-' + returnVal.slice(6)
  return returnVal
}
