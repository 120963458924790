import { useEffect } from 'react'
import type { ApolloError } from '@truepill/tpos-react-router'
import { useQuery } from '@truepill/tpos-react-router'
import { GET_LOGS_ORDER } from 'gql'
import type { Log } from 'types'
import { updateNotes } from 'utils/subscribeToolkit'

// this is only required as we get duplicate entries from the subscription
export const uniqueLogEntries = (logs: Log[]): Log[] => {
  const logsCopy = [...logs]
  return logsCopy.filter((item, pos, ary) => {
    return !pos || item._id !== ary[pos - 1]._id
  })
}

type useLogsProps = {
  copayRequestId?: string
  orderId?: string
  patientId?: string
  prescriptionId?: string
  prescriberId?: string
  fillId?: string
  payerId?: string
  userId?: string
  escriptId?: string
  priorAuthorizationId?: string
}

type useLogsType = {
  logs: Log[]
  loading: boolean
  error?: ApolloError
}

// TODO - make logs work with copay requests
export default function useOrderLogs({
  orderId,
  patientId,
  prescriptionId,
  prescriberId,
  fillId,
}: useLogsProps): useLogsType {
  const logs = useQuery(GET_LOGS_ORDER, {
    skip: !orderId && !patientId && !prescriptionId && !prescriberId && !fillId,
    variables: {
      orderId,
      patientId,
      prescriptionId,
      prescriberId,
      fillId,
    },
  })

  const { data, subscribeToMore, loading, error } = logs

  useEffect(() => {
    if (orderId || patientId || prescriptionId || fillId)
      return updateNotes(subscribeToMore, {
        orderId,
        patientId,
        prescriptionId,
        fillId,
      })
  }, [subscribeToMore, orderId, patientId, prescriptionId, fillId])

  let value = { logs: [] as Log[], loading, error }

  if (orderId || patientId || prescriptionId || prescriberId || fillId) {
    let logs: Log[] = Object.freeze(data?.getAllOrderLogs)

    if (logs?.length > 0) {
      logs = uniqueLogEntries(logs)
    }

    value = Object.assign({}, value, {
      logs: Object.freeze(logs),
    })
  }

  return value
}
