import { useState } from 'react'
import { Header, Spacer, Text, Grid, GridItem, Divider, Chip } from '@truepill/react-capsule'
import { useQuery } from '@truepill/tpos-react-router'
import HyphenatedNdc from 'components/HyphenatedNdc'
import LoadingSpinner from 'components/Loading'
import Lozenge from 'components/Tiles/Lozenge'
import { GET_DISPENSING_DECISIONS, LIST_INVENTORY_GROUPS } from 'gql'
import moment from 'moment'
import Tree from 'react-d3-tree'
import type { SyntheticEventHandler, TreeNodeDatum } from 'react-d3-tree'
import styled from 'styled-components'
import {
  accentPink,
  contrastColor,
  darkBlue,
  darkGreen,
  warningYellow,
  capsuleGrayColor,
  capsulePrimaryColor,
} from 'styles/styleVariables'
import type { Fill, Prescription, InventoryGroup } from 'types'
import { formatTree } from './buildTree'

const textLayout = {
  title: {
    textAnchor: 'start',
    x: 40,
  },
  attribute: {
    x: 40,
    dy: '1.2em',
  },
}

type renderNodeProps = {
  nodeDatum: TreeNodeDatum
  toggleNode: () => void
  onNodeClick: SyntheticEventHandler
  onNodeMouseOver: SyntheticEventHandler
  onNodeMouseOut: SyntheticEventHandler
}

const renderNode = (props: renderNodeProps) => {
  const { nodeDatum, toggleNode, onNodeClick, onNodeMouseOver, onNodeMouseOut } = props

  const highlightNode = nodeDatum.attributes?.highlight

  return (
    <>
      <circle
        className={highlightNode ? 'node_highlight' : 'node_normal'}
        r={15}
        onClick={evt => {
          toggleNode()
          onNodeClick(evt)
        }}
        onMouseOver={onNodeMouseOver}
        onMouseOut={onNodeMouseOut}
      ></circle>
      <g className="rd3t-label">
        <text className="rd3t-label__title" {...textLayout.title}>
          {nodeDatum.name}
        </text>
        <text className="rd3t-label__attributes">
          {nodeDatum.attributes && (
            <tspan key="description" {...textLayout?.attribute}>
              {nodeDatum.attributes?.description}
            </tspan>
          )}
        </text>
      </g>
    </>
  )
}

type ListInventoryGroupsResponseProps = {
  currentPage: number
  records: InventoryGroup[]
  totalRecords: number
}

type DispensingDecisionViewProps = {
  prescription: Prescription
  fillId: Fill['_id']
}

const DispensingDecisionView = (props: DispensingDecisionViewProps): JSX.Element => {
  const [dispensingDecision, setDispensingDecision] = useState<any | undefined>(undefined)
  const { prescription, fillId } = props

  const {
    data: dispensingDecisionsData,
    loading: dispensingDecisionsLoading,
    error: dipsensingDecisionsError,
  } = useQuery<{ getDispensingDecisions: any[] }>(GET_DISPENSING_DECISIONS, {
    variables: { fillId, prescriptionId: prescription._id },
  })

  const {
    data: inventoryGroupsData,
    loading: inventoryGroupLoading,
    error: inventoryGroupError,
  } = useQuery<{ listInventoryGroups: ListInventoryGroupsResponseProps }>(LIST_INVENTORY_GROUPS, {})

  const inventoryGroups = inventoryGroupsData?.listInventoryGroups?.records
  // eslint-disable-next-line no-console
  console.log(inventoryGroupsData?.listInventoryGroups?.records)

  if (dipsensingDecisionsError || inventoryGroupError) {
    return (
      <DispensingDecisionContainer>
        <p>Error loading the dispensing decision for this fill {fillId}</p>
      </DispensingDecisionContainer>
    )
  }

  if (dispensingDecisionsLoading || inventoryGroupLoading) {
    return (
      <DispensingDecisionContainer>
        <LoadingSpinnerContainer>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      </DispensingDecisionContainer>
    )
  }

  if (dispensingDecisionsData?.getDispensingDecisions.length === 0) {
    return (
      <DispensingDecisionContainer>
        <p>No dispensing decision data to show.</p>
      </DispensingDecisionContainer>
    )
  } else if (!dispensingDecision && dispensingDecisionsData?.getDispensingDecisions.length === 1) {
    setDispensingDecision(dispensingDecisionsData.getDispensingDecisions[0])
  }

  const treeData: any = formatTree(dispensingDecision, prescription.daw).data

  if (
    !dispensingDecision &&
    dispensingDecisionsData?.getDispensingDecisions &&
    dispensingDecisionsData.getDispensingDecisions.length > 1
  ) {
    return (
      <>
        <Spacer />
        <Header variant="xl">Select a dispensing decision to view:</Header>
        <Spacer />
        <DispensingDecisionSelectorList>
          {dispensingDecisionsData.getDispensingDecisions.map(dd => (
            <DispensingDecisionSelect key={dd._id} onClick={() => setDispensingDecision(dd)}>
              <Lozenge backgroundColor={darkBlue}>
                Selected NDC: <HyphenatedNdc ndc={dd.selectedNdc} />
              </Lozenge>
              <Spacer axis="horizontal" size="xs" />
              <Lozenge backgroundColor={darkGreen}>
                Prescribed NDC: <HyphenatedNdc ndc={dd.prescribedNdc} />
              </Lozenge>
              {inventoryGroups?.find(ig => ig._id === dd.selectedInventoryGroupId) && (
                <>
                  <Spacer axis="horizontal" size="xs" />
                  <Lozenge backgroundColor={warningYellow}>
                    {inventoryGroups?.find(ig => ig._id === dd.selectedInventoryGroupId)?.pioneerAlias}
                  </Lozenge>
                </>
              )}
              {dd.createdAt && (
                <>
                  <Spacer axis="horizontal" size="xs" />
                  <Lozenge backgroundColor={accentPink}>{moment(dd.createdAt).format('MM/DD/YYYY, h:mmA')}</Lozenge>
                </>
              )}
            </DispensingDecisionSelect>
          ))}
        </DispensingDecisionSelectorList>
      </>
    )
  }

  if (dispensingDecision) {
    return (
      <>
        <Text variant="body">
          PrescriptionId: {prescription._id} - FillId: {fillId}
        </Text>
        <Lozenges>
          <Lozenge backgroundColor={darkBlue}>
            Prescribed NDC: <HyphenatedNdc ndc={dispensingDecision.prescribedNdc} />
          </Lozenge>
          <Lozenge backgroundColor={darkGreen}>DAW: {prescription.daw}</Lozenge>
          <Lozenge backgroundColor={contrastColor}>{prescription.location.name}</Lozenge>
          <Lozenge backgroundColor={accentPink}>{prescription.customer?.name}</Lozenge>
          {dispensingDecision.useJanusFormulary && (
            <Lozenge backgroundColor={warningYellow}>Using Janus Formulary</Lozenge>
          )}
        </Lozenges>
        <Spacer />
        <DispensingDecisionContainer>
          <div id="treeWrapper" style={{ width: '100em', height: '45em' }}>
            <Tree
              data={treeData}
              draggable={false}
              orientation="vertical"
              translate={{ x: 800, y: 50 }}
              renderCustomNodeElement={renderNode}
              separation={{ siblings: 1.5, nonSiblings: 1.5 }}
              zoom={0.9}
              zoomable={false}
            />
          </div>
        </DispensingDecisionContainer>
        <Grid>
          <NdcListGridItem desktop={3}>
            <Text variant="overline">Janus data</Text>
            <Divider />
            <Spacer />
            <Text>
              Using Janus formulary &nbsp;
              <Chip outline state={dispensingDecision?.useJanusFormulary ? 'success' : 'error'}>
                {dispensingDecision.useJanusFormulary?.toString()}
              </Chip>
            </Text>
            <Spacer />
            <Text>List of Janus response ndcs</Text>
            {dispensingDecision.janusResponse?.results.map((jndc: any) => {
              return (
                <li>
                  <Text variant="chip">
                    {jndc.ndc}
                    <Chip state={jndc.consigned_inventory ? 'success' : 'error'}>Consigned</Chip>
                    <Chip state={jndc.preferred_ndc ? 'success' : 'error'}>Preferred</Chip>
                    <Chip state={jndc.dispense_as_requested ? 'success' : 'error'}>DAR</Chip>
                  </Text>
                </li>
              )
            })}
          </NdcListGridItem>
          <NdcListGridItem desktop={3}>
            <Text variant="overline">Preferred Ndcs</Text>
            <Divider />
            <Spacer />
            {dispensingDecision.classifySubstitutionsResult?.preferred.map((ndc: any) => {
              return (
                <li>
                  <Text variant="chip">
                    {ndc.ndc} ({inventoryGroups?.find(ig => ig._id === ndc.inventoryGroupId)?.pioneerAlias}) &nbsp;
                    <Chip state={ndc.consigned ? 'success' : 'error'}>Consigned</Chip>
                    <Chip state={ndc.preferred ? 'success' : 'error'}>Preferred</Chip>
                    <Chip state={ndc.dispenseAsRequested ? 'success' : 'error'}>DAR</Chip> BoH: {ndc.balanceOnHand}
                  </Text>
                </li>
              )
            })}
          </NdcListGridItem>
          <NdcListGridItem desktop={3}>
            <Text variant="overline">Non-preferred Ndcs</Text>
            <Divider />
            <Spacer />
            {dispensingDecision.classifySubstitutionsResult?.nonPreferred.map((ndc: any) => {
              return (
                <li>
                  <Text variant="chip">
                    {ndc.ndc} ({inventoryGroups?.find(ig => ig._id === ndc.inventoryGroupId)?.pioneerAlias}) &nbsp;
                    <Chip state={ndc.consigned ? 'success' : 'error'}>Consigned</Chip>
                    <Chip state={ndc.preferred ? 'success' : 'error'}>Preferred</Chip>
                    <Chip state={ndc.dispenseAsRequested ? 'success' : 'error'}>DAR</Chip> BoH: {ndc.balanceOnHand}
                  </Text>
                </li>
              )
            })}
          </NdcListGridItem>
          <NdcListGridItem desktop={3}>
            <Text variant="overline">Rest of Ndcs</Text>
            <Divider />
            <Spacer />
            {dispensingDecision.classifySubstitutionsResult?.rest.map((ndc: any) => {
              return (
                <li>
                  <Text variant="chip">
                    {ndc.ndc} ({inventoryGroups?.find(ig => ig._id === ndc.inventoryGroupId)?.pioneerAlias}) &nbsp;
                    <Chip state={ndc.consigned ? 'success' : 'error'}>Consigned</Chip>
                    <Chip state={ndc.preferred ? 'success' : 'error'}>Preferred</Chip>
                    <Chip state={ndc.dispenseAsRequested ? 'success' : 'error'}>DAR</Chip> BoH: {ndc.balanceOnHand}
                  </Text>
                </li>
              )
            })}
          </NdcListGridItem>
        </Grid>
      </>
    )
  }

  return <></>
}

const NdcListGridItem = styled(GridItem)`
  height: 400px;
  overflow-y: auto;
`

const Lozenges = styled.ul`
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  > span {
    margin-left: 0.1rem;
  }
`

const LoadingSpinnerContainer = styled.div`
  display: flex;
  padding-top: 4rem;
  justify-content: center;
  svg {
    height: 50px;
  }
`

const DispensingDecisionContainer = styled.div`
  display: flex;
  background: #eeeeee;
  flex-direction: column;
  .node_highlight {
    fill: gold;
  }
  .node_normal {
    fill: white;
  }
`

const DispensingDecisionSelectorList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 400px;
  width: 800px;
  overflow-y: auto;
`

const DispensingDecisionSelect = styled.p`
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0.5rem 1rem;
  margin: 0.25rem 0rem;
  font-size: 1rem;
  font-weight: normal;
  text-transform: capitalize;
  background-color: ${capsuleGrayColor};
  :hover {
    background-color: ${capsulePrimaryColor};
    cursor: pointer;
  }
`

export default DispensingDecisionView
