// visionConfig created using env-config.js for use during `yarn serve`
const visionConfig: Record<string, string> =
  typeof (window as any).visionConfig !== 'object' ? {} : (window as any).visionConfig
const env: Record<string, string> = Object.assign({}, process.env, visionConfig)

const config = {
  auth0: {
    callbackUrl: env.REACT_APP_AUTH0_CALLBACK ?? 'http://localhost:3001/login',
    clientId: env.REACT_APP_AUTH0_CLIENT ?? 'N6ezr5rYUQdiikP1CP4SXPVO8emy7tsn',
    googleConnection: env.REACT_APP_AUTH0_GOOGLE_CONNECTION ?? 'google-oauth2',
    domain: env.REACT_APP_AUTH0_DOMAIN ?? 'truepill-dev.us.auth0.com',
  },
  launchDarkly: {
    clientSideID: env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID ?? '631f5e84cc6fc7115d584143', // default to tpos Development environment
  },
}

export default config
