import React, { useCallback } from 'react'
import { Box, Button, Grid, GridItem } from '@truepill/react-capsule'
import CustomizedTextField from 'components/CustomizedTextField'
import { useFormData } from 'hooks/useFormData'
import type { FormUpdate } from 'providers/Store/FormDataStore'
import styled from 'styled-components'
import { capsuleGrayColor } from 'styles/styleVariables'

interface SigCodeFormProps {
  onSubmit?: (sigCode: string, engDescription: string, spaTranslation?: string, multiplier?: number) => void
  buttonText: string
  isLoading?: boolean
}

interface TextInputProps {
  value?: string
  setter: (value: FormUpdate) => void
}

const validations = [
  {
    validator: (value: string) => /[^A-Za-z0-9\s]/.test(value),
    error: 'Special characters are not allowed in the sig code',
  },
  {
    validator: (value: string) => /\s/.test(value),
    error: 'Spaces are not allowed in the sig code',
  },
  {
    validator: (value: string) => !value,
    error: 'Sig code is required',
  },
]

const validateSigCode = (value: string) => {
  const wrongValidation = validations.find(({ validator }) => validator(value))
  return { error: wrongValidation?.error }
}

const SigCodeTextInput = React.memo(({ value, setter }: TextInputProps): JSX.Element => {
  const { error: errorMessage } = validateSigCode(value ?? '')
  return (
    <CustomizedTextField
      value={value}
      onChange={e => {
        setter({
          sigCode: {
            code: {
              $set: e.target.value,
            },
          },
        })
      }}
      subCopy="Create a sig code with mix of alphabetics or numericals with no spaces in between."
      label="Sig code"
      required
      placeholder="Ex. 1CTIDPO"
      helperText={errorMessage}
      state={errorMessage ? 'error' : 'default'}
    />
  )
})

const MultiplierTextInput = React.memo(({ value, setter }: TextInputProps): JSX.Element => {
  return (
    <CustomizedTextField
      type="number"
      value={value}
      onChange={e => {
        setter({
          sigCode: {
            multiplier: {
              $set: Number(e.target.value),
            },
          },
        })
      }}
      subCopy=""
      label="DS Multiplier"
      required
      placeholder="Ex. 30"
    />
  )
})

const EnglishDirectionsTextInput = React.memo(
  ({ value, setter }: TextInputProps): JSX.Element => (
    <CustomizedTextField
      value={value}
      onChange={e => {
        setter({
          sigCode: {
            translations: {
              en: { $set: e.target.value },
            },
          },
        })
      }}
      type="textarea"
      maxLength={255}
      label="English description"
      required
      subCopy="Description should be less then 255 characters."
      placeholder="Ex. One capsule by mouth three times a day"
      helperText={!value ? 'English description is required' : ''}
      state={!value ? 'error' : 'default'}
    />
  ),
)

const SpanishTranslationInput = React.memo(
  ({ value, setter }: TextInputProps): JSX.Element => (
    <CustomizedTextField
      value={value}
      onChange={e => {
        setter({
          sigCode: {
            translations: {
              es: { $set: e.target.value },
            },
          },
        })
      }}
      type="textarea"
      maxLength={255}
      label="Spanish translation (optional)"
      subCopy="Translation should be less then 255 characters. "
      placeholder="Ex. Una cápsula por vía oral tres veces al día"
    />
  ),
)

export const SigCodeFormRaw = (): JSX.Element => {
  const {
    state: { formData },
    actions: { updateFormData },
  } = useFormData()

  const code = formData.sigCode?.code ?? ''
  const englishTranslation = formData.sigCode?.translations?.en ?? ''
  const spanishTranslation = formData.sigCode?.translations?.es ?? ''
  const multiplier = formData.sigCode?.multiplier ?? ''

  return (
    <FormWrapper>
      <StyledBox>
        <FormContainer>
          <Grid>
            <GridItem desktop={12} tablet={8}>
              <SigCodeTextInput value={code} setter={updateFormData} />
            </GridItem>
            <GridItem desktop={12} tablet={8}>
              <MultiplierTextInput value={multiplier?.toString()} setter={updateFormData} />
            </GridItem>
          </Grid>
        </FormContainer>
      </StyledBox>
      <StyledBox>
        <FormContainer>
          <Grid>
            <GridItem desktop={12} tablet={8}>
              <EnglishDirectionsTextInput value={englishTranslation} setter={updateFormData} />
            </GridItem>
            <GridItem desktop={12} tablet={8}>
              <SpanishTranslationInput value={spanishTranslation} setter={updateFormData} />
            </GridItem>
          </Grid>
        </FormContainer>
      </StyledBox>
    </FormWrapper>
  )
}

export const validateForm = (formData: any): boolean => {
  const code = formData.sigCode?.code || ''
  const englishTranslation = formData.sigCode?.translations?.en || ''
  const { error } = validateSigCode(code)
  return !!code && !!englishTranslation && !error
}

const SigCodeForm = ({ onSubmit, buttonText = 'Save', isLoading }: SigCodeFormProps): JSX.Element => {
  const {
    state: { formData },
  } = useFormData()

  const code = formData.sigCode?.code || ''
  const englishTranslation = formData.sigCode?.translations?.en || ''
  const spanishTranslation = formData.sigCode?.translations?.es
  const multiplier = formData.sigCode?.multiplier

  const formIsValid = validateForm(formData)

  const handleSubmit = useCallback(() => {
    if (formIsValid) {
      onSubmit?.(code, englishTranslation, spanishTranslation, multiplier)
    }
  }, [code, englishTranslation, formIsValid, onSubmit, spanishTranslation, multiplier])

  return (
    <>
      <div style={{ paddingLeft: '2rem' }}>
        <SigCodeFormRaw />
      </div>
      <ButtonContainer>
        <Button disabled={!formIsValid || isLoading} size="sm" onClick={handleSubmit}>
          {buttonText}
        </Button>
      </ButtonContainer>
    </>
  )
}

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
`

const FormContainer = styled.div`
  margin: 1rem 0;
`
const FormWrapper = styled.div`
  display: flex;
  gap: 1rem;
  margin: 3rem 0;
`

const StyledBox = styled(Box)`
  background-color: ${capsuleGrayColor};
  padding: 1rem;
  width: 45%;
  max-width: 450px;
`

export default SigCodeForm
