import type { EntityId, Address } from '../index'

export enum PatientMatchStatus {
  'Pending' = 'Pending',
  'Complete' = 'Complete',
  'Rejected' = 'Rejected',
}

export enum MatchCandidateStatus {
  'Pending' = 'Pending',
  'Matched' = 'Matched',
  'Rejected' = 'Rejected',
}

export interface PrePatient {
  firstName: string
  lastName: string
  gender: string
  dob: string
  address?: Address
  email?: string
  phone?: string
  medications?: string
  payers?: string[]
  conditions?: string[]
  allergies?: string[]
}

export interface MatchCandidate {
  patientId: EntityId
  status: MatchCandidateStatus
}

export interface PatientMatchProps {
  escriptId?: EntityId
  status?: PatientMatchStatus
  customerId?: EntityId
  prePatient?: PrePatient
  existingPatient?: EntityId
  matchCandidates: MatchCandidate[]
}

export interface SecondaryInfo {
  names: {
    firstName: string
    lastName: string
  }[]
  emails: string[]
  phoneNumbers: string[]
  addresses: Address[]
}
