import type { RefObject } from 'react'
import { useRef, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useHistory } from '@truepill/tpos-react-router'
import DropDown from 'components/DropDownContainer'
import DropDownOption from 'components/DropDownOption'
import UserInitialsBadge from 'components/UserInitialsBadge'
import { RELEASE_NOTES_DOC } from 'modals/ReleaseNotesModal'
import SimpleModal from 'modals/SimpleModal'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import { useToastContext } from 'providers/Overlays/ToastProvider'
import { usePlusClient } from 'providers/VisionRouter'
import { goToMyProfile } from 'routes'
import styled, { css, keyframes } from 'styled-components'
import type { UserFirstLastName } from 'types'
import { ToastType } from 'types'
import { usePrintProvider } from '../../providers/PrintProvider'

const UserAvatar = (): JSX.Element => {
  const { showToast } = useToastContext()
  const [showMenu, setShowMenu] = useState(false)
  const { tokenContext, logoutUser } = usePlusClient()
  const { resetSavedPrinters } = usePrintProvider()
  const history = useHistory()
  const { logout } = useAuth0()

  const { showModal } = useModalContext()

  const ref = useRef<HTMLDivElement>(null)

  if (!tokenContext) {
    return <></>
  }

  const isUsingV2: string | null = localStorage.getItem('use-v2')

  return (
    <StyledRollInContainer data-testid="user-icon-container">
      <StyledUserInitialsBadge
        user={
          {
            _id: tokenContext.id,
            ...tokenContext,
            roles: tokenContext.scope,
          } as UserFirstLastName
        }
        onClick={() => setShowMenu(!showMenu)}
      />
      {showMenu && (
        <DropDown
          data-testid="user-dropdown"
          minWidth={170}
          onClickOutside={() => setShowMenu(false)}
          preventScrolling={false}
          anchorToRight={true}
        >
          <MenuItems data-testid="user-menu">
            <DropDownOption
              onClick={() => {
                setShowMenu(false)
                history.push(goToMyProfile())
              }}
            >
              Profile
            </DropDownOption>
            <DropDownOption
              data-testid="show-release-notes"
              onClick={() => {
                setShowMenu(false)
                window.open(RELEASE_NOTES_DOC, '_blank')
              }}
            >
              Release notes
            </DropDownOption>
            <DropDownOption
              data-testid="use-v2-testing"
              onClick={() => {
                if (isUsingV2) {
                  localStorage.removeItem('use-v2')
                } else {
                  localStorage.setItem('use-v2', 'true')
                }
                showToast(`To ${isUsingV2 ? 'stop' : 'start'} using v2, refresh your page`, ToastType.success)
                setShowMenu(false)
              }}
            >
              {isUsingV2 ? 'stop using v2 (testing)' : 'use v2 (testing)'}
            </DropDownOption>
            <DropDownOption
              data-testid="logout"
              onClick={() => {
                setShowMenu(false)
                showModal(() => (
                  <SimpleModal
                    title={'End session'}
                    confirmationCallback={() => {
                      resetSavedPrinters()
                      logout({ returnTo: window.location.origin })
                      logoutUser()
                    }}
                    confirmButtonLabel={'End session'}
                  >
                    <p>By ending this session you will lose all unsaved changes.</p>
                  </SimpleModal>
                ))
              }}
            >
              End session
            </DropDownOption>
          </MenuItems>
        </DropDown>
      )}
      <DropAnchor ref={ref} />
    </StyledRollInContainer>
  )
}

const DropAnchor = styled.div`
  position: absolute;
  right: 1.5rem;
  top: 2.75rem;
`

const MenuItems = styled.ul`
  min-width: 10rem;
  padding-top: 0.33rem;
  padding-bottom: 0.33rem;
  border-radius: 0.25rem;
  > li {
    font-weight: 500;
  }
`

const rollIn = keyframes`
  0% {
    left: 4rem;
    transform: rotate(180deg);
  }
  75% {
    left: -0.2rem;
    transform: rotate(-5deg);
  }
  100% {
    left: 0;
    transform: rotate(-0deg);
  }
`

const StyledRollInContainer = styled.div<{
  ref?: RefObject<HTMLDivElement>
}>`
  position: relative;
  width: 2.625rem;
  height: 2.5rem;
`

const RollInAnimation = css`
  animation: ${rollIn} 500ms;
`

const StyledUserInitialsBadge = styled(UserInitialsBadge)`
  position: absolute;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.63rem;
  cursor: pointer;
  ${RollInAnimation}
`

export default UserAvatar
