import styled from 'styled-components'

type DURSubjectsListProps = { className?: string; subjects: { name: string }[] }

const DURSubjectsList = ({ className, subjects }: DURSubjectsListProps): JSX.Element => {
  return (
    <SubjectList className={className}>
      {subjects.map(({ name }, id) => (
        <Subject key={id}>{name}</Subject>
      ))}
    </SubjectList>
  )
}

const SubjectList = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
`

const Subject = styled.li`
  font-weight: 500;
  :not(:last-of-type) {
    :after {
      content: ', ';
      margin-right: 0.4rem;
    }
  }
  text-transform: capitalize;
`

export default DURSubjectsList
