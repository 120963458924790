import { RxFillRequestStatus } from '@truepill/tpos-types'
import { ReactComponent as PrintIcon } from 'assets/icons/print.svg'
import IconWrapper from 'components/IconWrapper'
import PrintModal from 'modals/PrintModal'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import type { Fill, Order } from 'types'
import { WrapButton } from '../../fill/ActionButtons'

type ReprintButtonProps = { order: Order; fill: Fill; orderEditable: boolean }

export const ReprintButton = ({ fill, order, orderEditable }: ReprintButtonProps): JSX.Element => {
  const { showModal } = useModalContext()

  const showPrintModal = () => (
    <PrintModal
      isReprint
      orderId={order._id}
      fills={[{ fillId: fill._id, orderId: order._id }]}
      customerId={order.rxFillRequests[0]?.prescription?.customer?.legacyId}
      title="Reprint label"
      status={RxFillRequestStatus.PV2}
      isGeneric={!!fill.genericFor}
    />
  )

  return (
    <WrapButton variant="primary-outline" onClick={() => showModal(showPrintModal)} disabled={!orderEditable}>
      <IconWrapper>
        <PrintIcon />
      </IconWrapper>
      Reprint
    </WrapButton>
  )
}
