import { useRef, useEffect, useState } from 'react'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { ReactComponent as MessageIcon } from 'assets/icons/message.svg'
import ErrorBoundary from 'components/ErrorBoundary'
import { Pane, PaneContents } from 'components/PageStructure'
import { TextInput, Button } from 'grommet'
import usePane from 'hooks/usePane'
import styled from 'styled-components'
import { borderColor, bodyPrimaryColor, bodySecondaryColor } from 'styles/styleVariables'
import type { Log } from 'types'
import MessageList from './MessageList'

type MessagesPaneProps = { logs: Log[] }

const MessagesPane = ({ logs }: MessagesPaneProps): JSX.Element => {
  const [messageText, setMessageText] = useState('')

  const { paneVisible, firstLoad, hidePane } = usePane('Message')

  const hidePaneRef = useRef(hidePane)

  useEffect(() => {
    return hidePaneRef.current
  }, [])

  const elRef = useRef(null)
  useEffect(() => {
    if (paneVisible && elRef !== null) {
      // Can't formulate a valid type for the ref so we'll have to
      // hard-cast :/
      const inputRef = elRef.current as unknown as HTMLInputElement
      inputRef.focus()
    }
  }, [elRef, paneVisible])

  // const postMessage = () => {
  //   createMessage({
  //     variables: { order, message: messageText }
  //   })
  //   setMessageText('')
  // }

  return (
    <ErrorBoundary>
      <Pane id="MessagesPane" firstLoad={firstLoad} visible={paneVisible}>
        <PaneContents>
          <CloseButton onClick={hidePane}>
            <CloseIcon fill={bodySecondaryColor} />
          </CloseButton>
          <MessagesTitle>
            <MessageIcon fill={bodyPrimaryColor} />
            Messages ( NOT YET IMPLEMENTED )
          </MessagesTitle>
          <StyledTextInput
            ref={elRef}
            placeholder={'Type a message…'}
            value={messageText}
            onChange={e => setMessageText(e.target.value)}
          />
          <SubmitControls>
            <StyledSubmitButton active={true} label={'Post'} disabled={messageText === ''} />
          </SubmitControls>
          <MessageList logs={logs} />
        </PaneContents>
      </Pane>
    </ErrorBoundary>
  )
}

const SubmitControls = styled.span`
  margin-top: 0.625rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const StyledTextInput = styled(TextInput)`
  height: 2.3rem;
  margin-top: 0.5625rem;
  border: 0.125rem solid ${borderColor};
  border-radius: 0.125rem;
  input {
    margin-left: 0rem;
  }
`

const StyledSubmitButton = styled(Button)`
  margin-left: auto;
  height: 2.5rem;
`

const MessagesTitle = styled.h3`
  display: flex;
  font-size: 1.125rem;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  > svg {
    margin-right: 0.5rem;
  }
`

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  position: absolute;
  right: 0.625rem;
`

export default MessagesPane
