import { Box, Button, Header, Modal, Spacer, Text, TextField } from '@truepill/react-capsule'
import CustomizedTextField from 'components/CustomizedTextField'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'

type LotAccuracyFillModalProps = {
  confirmationCallback: () => void
  expectedLots: string[]
  lot: string
}

const LotAccuracyFillModal = ({ confirmationCallback, expectedLots, lot }: LotAccuracyFillModalProps): JSX.Element => {
  const { dismissModal } = useModalContext()

  const { handleSubmit } = useForm({
    mode: 'onChange',
  })

  const onSubmit = () => {
    confirmationCallback()
    dismissModal()
  }

  const expectedLotsString = expectedLots.join(', ')

  // DO NOT REMOVE THIS FUNCTION
  // Scanners use Enter key at the end, so the modal detects it as a click on the X button
  // The X button has default focus, so that Enter key ends up accidentaly closing the modal
  // Limiting the event to mouse type and allowing only Escape key to close the modal fixes this issue
  const checkEventBeforeDismissModal = (event: React.SyntheticEvent | undefined) => {
    if (!event) return
    const nativeEvent = event.nativeEvent as PointerEvent & KeyboardEvent
    if (nativeEvent?.pointerType === 'mouse' || nativeEvent?.key === 'Escape') {
      dismissModal()
    }
  }

  return (
    <Modal
      aria-label="LotAccuracyFillModal"
      overlayCss={{ zIndex: 999, background: 'rgba(24, 39, 75, 0.08)' }}
      css={{ width: '27rem', padding: '1.5rem' }}
      isOpen
      onDismiss={checkEventBeforeDismissModal}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Header variant="2xl" as="h2" bold>
          Lot accuracy
        </Header>
        <Spacer size="xl" />
        <Text css={{ color: 'red' }}>Scanned lot is not one of the expected lots.</Text>
        <Spacer size="xl" />
        <StyledBoxInputs>
          <CustomizedTextField label="Scanned Lot" placeholder="Lot" value={lot} readOnly={true} />
          <StyledTextAreaField
            label="Expected Lots"
            type="textarea"
            value={expectedLotsString || 'No expected lots'}
            readOnly={true}
          />
        </StyledBoxInputs>
        <StyledBoxButtons>
          <Button size="sm" type="button" variant="primary-text" onClick={checkEventBeforeDismissModal}>
            Cancel
          </Button>
          <Button size="sm" type="submit">
            Confirm Scan
          </Button>
        </StyledBoxButtons>
      </form>
    </Modal>
  )
}

const StyledBoxButtons = styled(Box)`
  display: flex;
  justify-content: flex-end;
  margin-top: 1.5rem;
`

const StyledBoxInputs = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const StyledTextAreaField = styled(TextField)`
  min-height: 12.5rem;
  max-height: 25rem;
  line-height: 1.5;
  resize: vertical;
`

export default LotAccuracyFillModal
