const formatZip = (inputZip: string): string => {
  const raw = inputZip.replace(/-/, '')
  if (raw.length === 5) {
    return raw
  }

  let returnVal = raw.slice(0, 9)
  if (returnVal.length > 5) {
    returnVal = returnVal.slice(0, 5) + '-' + returnVal.slice(5)
  }
  return returnVal
}

export const matchZip = (inputText: string): string | undefined => {
  const zipMatch = inputText.match(/^\d{5}(?:[-\s]\d{4})?$/)
  if (zipMatch) {
    return inputText
  }
}
export const matchZip5Digits = (inputText: string): string | undefined => {
  const zipMatch = inputText.match(/^(\d{5})?$/)
  if (zipMatch) {
    return inputText
  }
}
export default formatZip
