import { TriageCapsuleButton } from './triageCapsuleButton'
import { TriageButton } from './triageStandardButton'
import type { TriageButtonProps } from './types'

export const TriageButtonFactory = ({
  onClick,
  item,
  fillId,
  disabled,
  onDismissModal,
  isCapsule = false,
}: TriageButtonProps): JSX.Element => {
  if (isCapsule) {
    return (
      <TriageCapsuleButton
        onClick={onClick}
        item={item}
        fillId={fillId}
        disabled={disabled}
        onDismissModal={onDismissModal}
      />
    )
  }

  return (
    <TriageButton onClick={onClick} item={item} fillId={fillId} disabled={disabled} onDismissModal={onDismissModal} />
  )
}
