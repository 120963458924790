import type { FC } from 'react'
import { useState } from 'react'
import { Button, Select } from '@truepill/react-capsule'
import CustomizedTextField from 'components/CustomizedTextField'
import { Eye, EyeOff } from 'react-feather'
import styled from 'styled-components'
import type { InsuranceOverrideCob } from 'types'
import { getOptionsFromLabelMap, findFullOption } from '../../helper'
import { overrideCOB } from '../../ServiceCodes'
import { applyToOptions } from './COBStyleCommon'

type ValueProps = string | boolean
type OtherPayerIdProps = {
  overrideCOB: Partial<InsuranceOverrideCob>
  handleChange: (name: keyof InsuranceOverrideCob, key: string, value?: ValueProps, index?: number) => void
}

const otherPayerIdQualifierOptions = getOptionsFromLabelMap(overrideCOB.otherPayerIdQualifier, true)

const OtherPayerId: FC<OtherPayerIdProps> = ({ overrideCOB, handleChange }): JSX.Element => {
  const otherPayerId = overrideCOB.otherPayerId
  const [sendFlag, setSendFlag] = useState(otherPayerId?.send === undefined || !!otherPayerId?.send)

  const toggleIsDisabled = () => {
    handleChange('otherPayerId', 'send', !otherPayerId?.send)
    setSendFlag(!!otherPayerId?.send)
  }

  const inputStyleSelect = {
    margin: '0',
  }

  const inputStyle = {
    margin: '8px 0 0 0',
  }

  return (
    <PayerFormElement>
      <PayerFormElementLabel>Other payer ID (6C / 7C)</PayerFormElementLabel>
      <Payer4Columns>
        {sendFlag ? (
          <Select
            options={otherPayerIdQualifierOptions}
            isOptionDisabled={option => !sendFlag}
            label=""
            required
            selectedKey="label"
            placeholder="Qualifier"
            onChange={option => {
              const value = option?.value
              handleChange('otherPayerId', 'qualifier', value)
            }}
            value={findFullOption(otherPayerId?.qualifier || 0, otherPayerIdQualifierOptions)}
            css={inputStyleSelect}
            variant="small"
          />
        ) : (
          <CustomizedTextField
            id={`#StyledSelect-d`}
            placeholder="Qualifier"
            value={findFullOption(otherPayerId?.qualifier || 0, otherPayerIdQualifierOptions)?.label}
            disabled={true}
            style={inputStyle}
            size="sm"
          />
        )}
        <CustomizedTextField
          placeholder="Other payer ID"
          value={otherPayerId?.value || ''}
          onChange={e => handleChange('otherPayerId', 'value', e.target.value)}
          disabled={!sendFlag}
          style={inputStyle}
          size="sm"
        />
        {sendFlag ? (
          <Select
            id={`#StyledSelect-d`}
            options={applyToOptions}
            isOptionDisabled={option => !sendFlag}
            label=""
            required
            selectedKey="label"
            placeholder=""
            onChange={option => {
              const value = option?.value
              handleChange('otherPayerId', 'applyTo', value)
            }}
            value={applyToOptions.find(i => i.value === otherPayerId?.applyTo)}
            css={inputStyleSelect}
            variant="small"
          />
        ) : (
          <CustomizedTextField
            id={`#StyledSelect-d`}
            placeholder=""
            value={applyToOptions.find(i => i.value === otherPayerId?.applyTo)?.label}
            disabled={true}
            style={inputStyle}
            size="sm"
          />
        )}
        <Button
          size="xs"
          style={{
            borderRadius: '100%',
            backgroundColor: 'rgb(238, 238, 238)',
            borderColor: 'rgb(238, 238, 238)',
            height: '3.5rem',
            width: '3.5rem',
            verticalAlign: 'center',
            color: 'var(--cap-colors-primary-700)',
            textAlign: 'center',
            margin: '2px 0 0 0',
          }}
          onClick={toggleIsDisabled}
        >
          {sendFlag ? <Eye aria-hidden /> : <EyeOff aria-hidden />}
        </Button>
      </Payer4Columns>
    </PayerFormElement>
  )
}

export default OtherPayerId

export const COBPayerIdContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-rows: [content] 100%;
  grid-template-columns: [value] 13% [value] 60% [value1] 50% [value2] 25%;
`

const PayerFormElement = styled.div``

const PayerFormElementLabel = styled.div`
  font-weight: 500;
  font-size: 0.875rem;
  display: block;
  width: 100%;
  margin: 12px 0 0 0;
`

const PayerColumns = styled.div`
  width: 100%;
  display: grid;
  align-items: flex-start;
  margin: 0 0 0 0;
  grid-gap: 0.25rem;
  vertical-align: baseline;
`

const Payer4Columns = styled(PayerColumns)`
  grid-template-columns: [col1] 32% [col2] 30% [col3] 30% [col4] 8%;
`
