import { useMemo } from 'react'
import type { Fill, Prescription } from 'types'
import {
  comparePrescriber,
  comparePatient,
  compareDispensed,
  comparePrescribed,
  getLastCompletedTposFill,
} from 'utils/compare'

export const useLastFillDiff = (
  { prescription, fill }: { prescription: Prescription; fill: Fill },
  options?: { skip?: boolean },
) => {
  const { skip } = options ?? { skip: false }
  // If comparition is skipped return an undefined last completed fill so empty maps are returned
  const lastCompletedFill = !skip ? getLastCompletedTposFill(prescription.fills) : undefined

  const prescriberFields = useMemo(
    () =>
      comparePrescriber({
        prescriber: prescription.prescriber,
        lastCompletedFill,
        prescriberAddressId: prescription.prescriberAddressId,
      }),
    [lastCompletedFill, prescription.prescriber, prescription.prescriberAddressId],
  )

  const patientFields = useMemo(
    () => comparePatient({ patient: prescription.patient, lastCompletedFill }),
    [lastCompletedFill, prescription.patient],
  )

  const dispensedFields = useMemo(
    () => compareDispensed({ currentFill: fill, lastCompletedFill }),
    [fill, lastCompletedFill],
  )

  const prescribedFields = useMemo(
    () =>
      comparePrescribed({
        prescription: {
          ...prescription,
          quantity: Number(prescription.quantity),
          refillsRemaining: Number(prescription.refillsRemaining),
        },
        lastCompletedFill,
      }),
    [lastCompletedFill, prescription],
  )

  return {
    prescriberFields,
    patientFields,
    dispensedFields,
    prescribedFields,
  }
}
