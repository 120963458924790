import { useState } from 'react'
import { SaveButton } from 'components/ActionButton'
import BreadCrumb from 'components/BreadCrumb'
import {
  ActionButtonContainer,
  CancelButton,
  PageContent,
  PageHeading,
  StickyPageHeadingContainer,
} from 'components/PageStructure'
import RxHeading from 'components/RxHeading'
import styled from 'styled-components'
import { contrastBackgroundColor } from 'styles/styleVariables'
import type {
  RxFillRequestReversalOverridesKeys,
  EdiReversalOverrides,
  QueueItem,
  Fill,
  RXFillRequest,
  CopayRequestFill,
  Prescription,
  Patient,
} from 'types'
import EdiReversalOverridesActionButtons from './ActionButtons'
import ClaimSegmentEditReversal from './ReviewSegmentsEditReversalView/ClaimSegmentEditReversal'
import CoordinationOfBenefitSegmentEditReversal from './ReviewSegmentsEditReversalView/COBSegmentEditReversal'
import InsuranceSegmentEditReversal from './ReviewSegmentsEditReversalView/InsuranceSegmentEditReversal'
import PricingSegmentEditReversal from './ReviewSegmentsEditReversalView/PricingSegmentEditReversal'
import TransmissionSegmentEditReversal from './ReviewSegmentsEditReversalView/TransmissionSegmentEditReversal'

type EditReversalInfoViewProps = {
  setDisplayEditReversalInfo: (val: boolean) => void
  item: QueueItem
  fill: Fill
  itemFill: RXFillRequest | CopayRequestFill
  prescription: Prescription
  patient: Patient
}

const payerOrderNames = ['primary', 'secondary', 'tertiary']
const makePayerButtons = (
  item: QueueItem,
  selectedOption: string,
  setSelectedOption: (val: string) => void,
): JSX.Element => {
  return (
    <ActionButtonContainer>
      <>BINS: </>
      {item.insurances.map((i, index) => {
        if (selectedOption.toString() === i.orderInsuranceOptionId.toString()) {
          return (
            <SaveButton
              label={`${i.bin} (${payerOrderNames[index]})`}
              onClick={() => setSelectedOption(i.orderInsuranceOptionId)}
            />
          )
        }

        return (
          <StyledCancelButton
            label={`${i.bin} (${payerOrderNames[index]})`}
            onClick={() => setSelectedOption(i.orderInsuranceOptionId)}
          />
        )
      })}
    </ActionButtonContainer>
  )
}

const EditReversalInfoView = ({
  setDisplayEditReversalInfo,
  item,
  itemFill,
  prescription,
  patient,
  fill,
}: EditReversalInfoViewProps): JSX.Element => {
  const [ediReversalOverrides, setEdiReversalOverrides] = useState(itemFill?.ediReversalOverrides || [])
  const [ediReversalOverridesOptionId, setEdiReversalOverridesOptionId] = useState(
    item?.insurances?.[0]?.orderInsuranceOptionId,
  )
  const fillId = fill._id

  let selectedEdiReversalOverride =
    ediReversalOverrides.find(i => i.orderInsuranceOptionId?.toString() === ediReversalOverridesOptionId?.toString()) ||
    {}

  const updateEdiReversalOverrides = (update: EdiReversalOverrides) => {
    const newEdiReversalOverrides = [...ediReversalOverrides]
    const updateKey: RxFillRequestReversalOverridesKeys = Object.keys(update)[0] as RxFillRequestReversalOverridesKeys

    const outerPatch = { ...selectedEdiReversalOverride[updateKey], ...update[updateKey] }
    const newObj: Partial<EdiReversalOverrides> = {}
    newObj[updateKey] = outerPatch

    selectedEdiReversalOverride = { ...selectedEdiReversalOverride, ...newObj }

    const payerIndex = ediReversalOverrides.findIndex(
      override => ediReversalOverridesOptionId.toString() === override.orderInsuranceOptionId?.toString(),
    )

    if (payerIndex >= 0) {
      newEdiReversalOverrides[payerIndex] = selectedEdiReversalOverride
    } else {
      newEdiReversalOverrides.push({
        ...selectedEdiReversalOverride,
        orderInsuranceOptionId: ediReversalOverridesOptionId,
      })
    }

    setEdiReversalOverrides(newEdiReversalOverrides)
  }

  return (
    <>
      <StickyPageHeadingContainer>
        <BreadCrumb />
        <PageHeading>
          <RxHeading item={item} prescription={prescription} fill={fill} patient={patient} />
          <EdiReversalOverridesActionButtons
            setDisplayEdiReversalOverrides={setDisplayEditReversalInfo}
            item={item}
            fillId={fillId}
            ediReversalOverrides={ediReversalOverrides}
          />
        </PageHeading>
      </StickyPageHeadingContainer>
      <PageContent>
        <RXViewContainer>
          <PayerButtons>
            {makePayerButtons(item, ediReversalOverridesOptionId, setEdiReversalOverridesOptionId)}
          </PayerButtons>
          <TransmissionSegmentEditReversal
            ediReversalOverrides={selectedEdiReversalOverride}
            updateEdiReversalOverrides={updateEdiReversalOverrides}
          />
          <InsuranceSegmentEditReversal
            ediReversalOverrides={selectedEdiReversalOverride}
            updateEdiReversalOverrides={updateEdiReversalOverrides}
          />
          <ClaimSegmentEditReversal
            ediReversalOverrides={selectedEdiReversalOverride}
            updateEdiReversalOverrides={updateEdiReversalOverrides}
          />
          <PricingSegmentEditReversal
            ediReversalOverrides={selectedEdiReversalOverride}
            updateEdiReversalOverrides={updateEdiReversalOverrides}
          />
          <CoordinationOfBenefitSegmentEditReversal
            ediReversalOverrides={selectedEdiReversalOverride}
            updateEdiReversalOverrides={updateEdiReversalOverrides}
          />
        </RXViewContainer>
      </PageContent>
    </>
  )
}

const RXViewContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-top: 1.25rem;
  padding-left: 1.875rem;
`

const PayerButtons = styled.div`
  padding-bottom: 1rem;
`

const StyledCancelButton = styled(CancelButton)`
  background: ${contrastBackgroundColor};
`

export default EditReversalInfoView
