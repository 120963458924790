import type { Address } from '../address'
import type { Species, Gender, PatientContacts, PatientAddress } from './index'

export interface PatientLookup {
  firstName: string
  lastName: string
  alias?: string
  dob: string
  gender: Gender
  homeZip?: string
  contacts?: PatientContacts
  address?: PatientAddress
}

export enum PatientStatus {
  Active = 'ACTIVE',
  Merged = 'MERGED',
  Inactive = 'INACTIVE',
}

export enum PatientStatusChangeReason {
  Other = 'OTHER',
}

export enum ContactType {
  Email = 'email',
  Phone = 'phone',
  SMS = 'sms',
  Unknown = 'unknown',
}

export interface CoreNewPatientInput {
  first_name: string
  last_name: string
  dob: string
  gender: Gender
  id: number
  url_token: string
  phone?: string
  email?: string
  address?: Address
  species?: Species
  guardian?: string
  language_preference?: string
}

export interface CoreUpdatePatientInput {
  id: number
  url_token: string
  phone?: string
  email?: string
  address?: Address
  species?: Species
  guardian?: string
  language_preference?: string
}
