import type { TPOSDocument } from 'types'

export const getOrderAttachmentsCount = (docs?: TPOSDocument[]): number => {
  return (docs?.filter(attach => Boolean(attach.orderId) && !attach.isArchived) || []).length
}

export const getParentAttachments = (docs?: TPOSDocument[]): boolean => {
  return (
    (docs?.filter(attach => (Boolean(attach.patient) || !attach.prescription) && !attach.isArchived) || []).length > 0
  )
}
