type ItemIdDisplayProps = { id: string; customerRef: string | undefined }
/**
 * A component to display the ID for a given order or copay request, including the customerRef
 * if it looks like an external ID. We match for a short alphanumeric string
 * here because there are many customerRef values (depending on customer) that
 * aren't IDs or that are useless for this purpose, like long JSON strings.
 */
const ItemIdDisplay = ({ id, customerRef }: ItemIdDisplayProps): JSX.Element => (
  <>
    {id}
    {customerRef?.match(/^\w{2,12}$/) && <> ({customerRef})</>}
  </>
)
export default ItemIdDisplay
