import { BannerAlert, Button, Grid, GridItem, Header, Spacer, Text } from '@truepill/react-capsule'
import { useMutation } from '@truepill/tpos-react-router'
import { TriageReasons, OrderStatus } from '@truepill/tpos-types'
import { ModalHeader, ModalWrapper } from 'components/Modal'
import { SET_IN_TRIAGE } from 'gql'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import styled from 'styled-components'
import type { OrderTriage, Patient } from 'types'

interface HeaderProps {
  header: string
  content: string
}

const InfoListHeader = ({ header, content }: HeaderProps) => (
  <StyledBodyText bold>
    <div>{header} </div>
    <div>{content} </div>
  </StyledBodyText>
)

const StyledHeader = styled(Header)`
  font-size: 36px;
  font-weight: 700;
  display: inline-block;
  margin: 8px 0;
`

const StyledWarningText = styled.p`
  font-size: 16px;
`

const StyledBodyText = styled(Text)`
  display: inline-block;
  font-size: 16px;
  margin-right: 8px;
  > div {
    margin: 6px 0;
  }
`

const StyledInfoList = styled.section`
  > div {
    margin-bottom: 16px;
    padding: 4px 12px;
  }
  > div:nth-child(even) {
    background-color: #eee;
    border-radius: 8px;
  }
`

const StyledButtonArea = styled.section`
  display: flex;
  justify-content: flex-end;
  > button {
    margin: 4px;
  }
`

const StyledButtonOuter = styled.div`
  width: 100%;
`

const StyledInfoListHeader = styled(InfoListHeader)`
  > div {
    margin: 4px 0;
  }
`

const StyledModalWrapper = styled.div`
  padding: 12px;
`

export type PatientDetails = Pick<Patient, 'firstName' | 'lastName' | '_id' | 'dob'>

export interface ModalOrder {
  _id: string
  inTriage?: OrderTriage
  status: OrderStatus
  patient?: PatientDetails
}

export interface ModalPrescription {
  patient: PatientDetails
}
interface ModalProps {
  order: ModalOrder
  prescription: ModalPrescription
}

const skipTriageStatus = [OrderStatus.Cancelled, OrderStatus.Complete, OrderStatus.Packing, OrderStatus.Returned]

type TriagePayload = Pick<OrderTriage, 'fillId' | 'reason' | 'inTriageUserMessage' | 'rxChange'>
function useTriageMutation() {
  const [setInTriageMutation] = useMutation<
    {
      setInTriage: { _id: string; inTriage: { reason: TriageReasons } }
      status: OrderStatus
      triages: TriagePayload[]
    },
    { orderId: string; triages: TriagePayload[] }
  >(SET_IN_TRIAGE)
  return setInTriageMutation
}

const MismatchedPatientPrescriptionModal = ({ order, prescription }: ModalProps) => {
  const setInTriageMutation = useTriageMutation()
  const { dismissModal } = useModalContext()

  const disableTriageButton = !!order.inTriage || skipTriageStatus.includes(order.status)

  const triageOnClick = () => {
    setInTriageMutation({
      variables: {
        orderId: order._id,
        triages: [
          {
            reason: TriageReasons.SystemTriageReasons.RxOrderDiscrepancy, // 'Rx-Order Discrepancy',
            inTriageUserMessage: 'Evaluate difference in patient associated with order and prescription',
          },
        ],
      },
    })
    dismissModal()
  }

  return (
    <ModalWrapper>
      <StyledModalWrapper>
        <ModalHeader>
          <StyledHeader>Evaluate patient mismatch</StyledHeader>
        </ModalHeader>

        <Spacer />
        <BannerAlert state="error">
          <Text variant="body-sm">
            <StyledWarningText>
              The patient linked to this order is different than the patient linked to the prescription Please validate
              that this is the appropriate prescription for this patient.
            </StyledWarningText>
          </Text>
        </BannerAlert>

        <Spacer />
        <PrescriptionOrderDisplay
          disableTriageButton={disableTriageButton}
          order={order}
          prescription={prescription}
          continueOnClick={dismissModal}
          triageOnClick={triageOnClick}
        />
      </StyledModalWrapper>
    </ModalWrapper>
  )
}

interface DisplayProps {
  order: ModalOrder
  prescription: ModalPrescription
  continueOnClick: (args: unknown) => unknown
  triageOnClick: (args: unknown) => unknown
  disableTriageButton?: boolean
}

const PrescriptionOrderDisplay = ({
  order,
  prescription,
  continueOnClick,
  triageOnClick,
  disableTriageButton = false,
}: DisplayProps) => (
  <>
    <Grid>
      <GridItem desktop={6} tablet={4}>
        <StyledInfoList>
          <div data-testid="order-patient-name">
            <StyledInfoListHeader header="Order" content="patient name:" />
            <StyledBodyText>
              {order.patient?.firstName} {order.patient?.lastName}
            </StyledBodyText>
          </div>

          <div data-testid="order-patient-id">
            <StyledInfoListHeader header="Order" content="patient ID:" />
            <StyledBodyText>{order.patient?._id}</StyledBodyText>
          </div>

          <div data-testid="order-patient-dob">
            <StyledInfoListHeader header="Order" content="DOB:" />
            <StyledBodyText>{order.patient?.dob}</StyledBodyText>
          </div>
        </StyledInfoList>
      </GridItem>

      <GridItem desktop={6} tablet={4}>
        <StyledInfoList>
          <div data-testid="prescription-patient-name">
            <StyledInfoListHeader header="Prescription" content="patient name:" />
            <StyledBodyText>
              {prescription.patient?.firstName} {prescription.patient?.lastName}
            </StyledBodyText>
          </div>

          <div data-testid="prescription-patient-id">
            <StyledInfoListHeader header="Prescription" content="patient ID:" />
            <StyledBodyText>{prescription.patient?._id}</StyledBodyText>
          </div>

          <div data-testid="prescription-patient-dob">
            <StyledInfoListHeader header="Prescription" content="DOB:" />
            <StyledBodyText>{prescription.patient?.dob}</StyledBodyText>
          </div>
        </StyledInfoList>
      </GridItem>
    </Grid>
    <StyledButtonOuter>
      <Spacer />
      <StyledButtonArea>
        <Button onClick={continueOnClick} variant="primary-text">
          Continue
        </Button>
        <Button disabled={disableTriageButton} onClick={triageOnClick}>
          Triage
        </Button>
      </StyledButtonArea>
    </StyledButtonOuter>
  </>
)

export default MismatchedPatientPrescriptionModal
