import { useEffect } from 'react'
import { useHistory, useLocation, useRouteMatch } from '@truepill/tpos-react-router'
import useOrderLock from 'hooks/useOrderLock'

const useUnlockOrder = (orderId: string): void => {
  const { clearOrderLock, orderLockedBy, isOrderLockedByMe } = useOrderLock(orderId)
  const location = useLocation()
  const history = useHistory()
  const { url } = useRouteMatch()
  const currentPath = location.pathname
  const splitUrl = url.slice(1, url.length).split('/')
  const basePath = `/${splitUrl.slice(0, 4).join('/')}`

  return useEffect(() => {
    if (orderLockedBy && isOrderLockedByMe) {
      const unlisten = history.listen(location => {
        if (location.pathname !== currentPath && location.pathname !== basePath) {
          clearOrderLock()
        }
      })
      return function cleanup() {
        unlisten()
      }
    }
  }, [location, isOrderLockedByMe, orderLockedBy, history, basePath, clearOrderLock, currentPath])
}

export default useUnlockOrder
