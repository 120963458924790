import { FormItem, FormItems, AddressFormLine, WideTextInput } from 'components/ColumnForm'
import type { Address, InsuranceProvider } from 'types'
import { formatPhoneInput, formatZip, states as USStates } from 'utils'
import { CityFormItem, PhoneInput, StateSelect } from './StyledComponents'

type PayerContactFormProps = {
  payerForm: InsuranceProvider
  updatePayerForm: (update: InsuranceProvider) => void
}

const PayerContactForm = ({ payerForm, updatePayerForm }: PayerContactFormProps): JSX.Element => (
  <FormItems data-testid="PayerDetailForm">
    <FormItem data-test-row="phone">
      <p>Phone</p>
      <PhoneInput
        data-testid="phone"
        value={payerForm.phoneNumber}
        placeholder={'(xxx) - xxx - xxxx'}
        onChange={e => updatePayerForm({ ...payerForm, phoneNumber: formatPhoneInput(e.target.value) })}
      />
    </FormItem>
    <FormItem data-test-row="helpdesk">
      <p>Help desk</p>
      <PhoneInput
        data-testid="helpdesk"
        value={payerForm.helpdeskNumber}
        placeholder={'(xxx) - xxx - xxxx'}
        onChange={e => updatePayerForm({ ...payerForm, helpdeskNumber: formatPhoneInput(e.target.value) })}
      />
    </FormItem>
    <FormItem data-test-row="fax">
      <p>Fax</p>
      <PhoneInput
        data-testid="fax"
        value={payerForm.faxNumber}
        placeholder={'(xxx) - xxx - xxxx'}
        onChange={e => updatePayerForm({ ...payerForm, faxNumber: formatPhoneInput(e.target.value) })}
      />
    </FormItem>
    <FormItem data-test-row="prior-auth">
      <p>Prior authorization</p>
      <PhoneInput
        data-testid="prior-auth"
        value={payerForm.priorAuthorizationNumber}
        placeholder={'(xxx) - xxx - xxxx'}
        onChange={e => updatePayerForm({ ...payerForm, priorAuthorizationNumber: formatPhoneInput(e.target.value) })}
      />
    </FormItem>

    <FormItem data-test-row="street-address">
      <p>Address</p>
      <WideTextInput
        data-testid="street1"
        value={payerForm.address?.street1}
        onChange={e =>
          updatePayerForm({ ...payerForm, address: { ...(payerForm.address as Address), street1: e.target.value } })
        }
      />
      <WideTextInput
        data-testid="street2"
        value={payerForm.address?.street2}
        onChange={e =>
          updatePayerForm({ ...payerForm, address: { ...(payerForm.address as Address), street2: e.target.value } })
        }
        placeholder={'Suite, building, etc...'}
      />
    </FormItem>
    <AddressFormLine data-test-row="city-address">
      <CityFormItem data-test-row="city">
        <p>City</p>
        <WideTextInput
          data-testid="city"
          value={payerForm.address?.city}
          onChange={e =>
            updatePayerForm({ ...payerForm, address: { ...(payerForm.address as Address), city: e.target.value } })
          }
        />
      </CityFormItem>
      <FormItem data-test-row="state">
        <p>State</p>
        <StateSelect
          data-testid="state"
          placeholder={'State'}
          multiple={false}
          value={payerForm.address?.state || ''}
          options={USStates}
          onChange={([option]: any) =>
            updatePayerForm({
              ...payerForm,
              address: { ...(payerForm.address as Address), state: option ? option.value : '' },
            })
          }
        />
      </FormItem>
      <FormItem data-test-row="zip">
        <p>Zip code</p>
        <WideTextInput
          data-testid="zip"
          value={payerForm.address?.zip}
          onChange={e =>
            updatePayerForm({
              ...payerForm,
              address: { ...(payerForm.address as Address), zip: formatZip(e.target.value) },
            })
          }
        />
      </FormItem>
    </AddressFormLine>

    <FormItem data-test-row="email">
      <p>Email</p>
      <WideTextInput value={payerForm.email} onChange={e => updatePayerForm({ ...payerForm, email: e.target.value })} />
    </FormItem>
    <FormItem data-test-row="website">
      <p>Website</p>
      <WideTextInput
        value={payerForm.website}
        onChange={e => updatePayerForm({ ...payerForm, website: e.target.value })}
      />
    </FormItem>
  </FormItems>
)

export default PayerContactForm
