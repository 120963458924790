import { useMutation, useHistory, useLocation } from '@truepill/tpos-react-router'
import { OrderStatus, UserRoles } from '@truepill/tpos-types'
import { ReactComponent as DangerIcon } from 'assets/icons/danger.svg'
import ActionButton from 'components/ActionButton'
import AuthLimited from 'components/AuthLimited'
import IconWrapper from 'components/IconWrapper'
import { REVERT_TO_CORE } from 'gql'
import useErrorToast from 'hooks/toast/useErrorToast'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import { useTPCacheContext } from 'providers/TPCacheProvider'
import { usePlusClient } from 'providers/VisionRouter'
import { bodyPrimaryColor } from 'styles/styleVariables'
import type { Order } from 'types'

type RevertToCoreButtonProps = { order: Order; disabled?: boolean }

const RevertToCoreButton = ({ order, disabled }: RevertToCoreButtonProps): JSX.Element => {
  const history = useHistory()
  const location = useLocation()
  const showSuccessToast = useSuccessToast()
  const showErrorToast = useErrorToast()
  const {
    tokenContext: { hasRole },
  } = usePlusClient()
  const { getLocationById } = useTPCacheContext()
  const [revertToCoreMutation] = useMutation<{ revertToCore?: { _id: string } }>(REVERT_TO_CORE, {
    onCompleted: ({ revertToCore }) => {
      if (revertToCore?._id) {
        showErrorToast(`Failed to revert order ${order._id} to dashboard.`)
        return
      }

      showSuccessToast('Order reverted to dashboard successfully!')

      if (location.pathname.startsWith('/fulfillment/')) {
        history.push('/fulfillment')
      }

      if (location.pathname.startsWith('/pharmacy/')) {
        history.push('/pharmacy')
      }
    },
    onError: error => {
      console.error(`Failed to revert order ${order._id} to dashboard:`, error)
      showErrorToast(`Failed to revert order ${order._id} to dashboard: ${error}`)
    },
  })

  const isOTCOnlyOrder = !!order.guestPatient || (!order.rxFillRequests?.length && order.otcProducts?.length > 0)
  const isAppropriateStatus = !isOTCOnlyOrder
    ? [
        OrderStatus.Adjudication,
        OrderStatus.PriorAuthorization,
        OrderStatus.PV1,
        OrderStatus.Fill,
        OrderStatus.PV2,
      ].includes(order.status)
    : order.status !== OrderStatus.Complete && !getLocationById(order.locationId)?.isSnapLocation

  if (hasRole([UserRoles.Customer, UserRoles.Patient]) || !order.coreOrderId || !isAppropriateStatus) {
    return <></>
  }

  return (
    <AuthLimited
      roles={[
        UserRoles.Admin,
        UserRoles.LeadPharmacist,
        UserRoles.Pharmacist,
        UserRoles.Technician,
        UserRoles.LeadCustomerSupport,
        UserRoles.CustomerSupport,
        UserRoles.Warehouse,
        UserRoles.Developer,
        UserRoles.Tester,
      ]}
    >
      <ActionButton
        data-testid="RevertToDashboard"
        icon={
          <IconWrapper>
            <DangerIcon fill={bodyPrimaryColor} />
          </IconWrapper>
        }
        onClick={() => revertToCoreMutation({ variables: { orderId: order._id } })}
        label="Revert to Dashboard"
        disabled={disabled}
      />
    </AuthLimited>
  )
}
export default RevertToCoreButton
