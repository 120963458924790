import { QueuesFilter } from 'components/Filter'
import FilterPane from 'components/FilterPane'

type FilterSectionType = { hideFilters: boolean; isFirstLoad: boolean }

const FilterSection = ({ hideFilters, isFirstLoad }: FilterSectionType): JSX.Element => {
  return (
    <FilterPane hideFilters={hideFilters} isFirstLoad={isFirstLoad}>
      <QueuesFilter isVisible={!hideFilters} />
    </FilterPane>
  )
}

export default FilterSection
