import { ReactComponent as LogIcon } from 'assets/icons/log.svg'
import IconWrapper from 'components/IconWrapper'
import LogView from 'components/Log'
import { ModalWrapper, ModalHeader } from 'components/Modal'
import { bodyPrimaryColor } from 'styles/styleVariables'
import type { Fill, Prescription, Order, Log, Autobot, ParataNdc, Patient, Escript, CopayRequest, User } from 'types'

type LogModalProps = {
  logs?: Log[]
  orderId?: Order['_id']
  fillId?: Fill['_id']
  prescriptionId?: Prescription['_id']
  autobotIds?: Autobot['_id'][]
  parataNdcIds?: ParataNdc['_id'][]
  patientId?: Patient['_id']
  escriptId?: Escript['_id']
  copayRequestId?: CopayRequest['_id']
  updatedUserId?: User['_id']
  isCopay?: boolean
  details?: string
}

const LogModal = (props: LogModalProps): JSX.Element => {
  const {
    logs,
    fillId,
    orderId,
    prescriptionId,
    autobotIds,
    parataNdcIds,
    patientId,
    escriptId,
    copayRequestId,
    updatedUserId,
    isCopay,
    details,
  } = props

  return (
    <ModalWrapper id="LogModal">
      <ModalHeader>
        <IconWrapper>
          <LogIcon fill={bodyPrimaryColor} />
        </IconWrapper>
        <h2>Log</h2>
      </ModalHeader>
      <LogView
        logs={logs || []}
        fillId={fillId}
        orderId={orderId}
        prescriptionId={prescriptionId}
        autobotIds={autobotIds}
        parataNdcIds={parataNdcIds}
        patientId={patientId}
        escriptId={escriptId}
        copayRequestId={copayRequestId}
        updatedUserId={updatedUserId}
        isCopay={isCopay}
        details={details}
      />
    </ModalWrapper>
  )
}

export default LogModal
