import type { FC } from 'react'
import { useCallback } from 'react'
import { Button, Theme } from '@truepill/react-capsule'
import { useMutation } from '@truepill/tpos-react-router'
import { ModalWrapper, ModalHeader, ButtonsContainer } from 'components/Modal'
import PrinterSelect from 'components/PrinterSelect'
import { PRINT_PICK_SLIPS } from 'gql'
import useErrorToast from 'hooks/toast/useErrorToast'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import { usePrintProvider } from 'providers/PrintProvider'
import { usePlusClient } from 'providers/VisionRouter'
import { css } from 'styled-components'
import type { Printer } from 'types'

type PrintModalProps = {
  confirmationCallback?: () => void
  orderIDs: string[]
}

const overrides = {
  autocomplete: { margin: '1.5rem 0 1rem' },
  selectTrigger: { height: 48 },
  formGroup: {
    margin: '1rem 0',
    '& [data-form-group-label]': { fontSize: '1rem' },
  },
}

export const PrintModal: FC<PrintModalProps> = props => {
  const { confirmationCallback, orderIDs } = props

  const { dismissModal } = useModalContext()
  const { setPrinter, selectedPrinter } = usePrintProvider()
  const { tokenContext } = usePlusClient()

  const showErrorToast = useErrorToast()
  const showSuccessToast = useSuccessToast()

  const onPrintSuccess = useCallback(() => {
    showSuccessToast('Print pick slips request sent.')
    confirmationCallback?.()
    dismissModal()
  }, [showSuccessToast, confirmationCallback, dismissModal])

  const onPrintFail = useCallback(
    (error: Error) => {
      dismissModal()
      showErrorToast(`Failed to print pick slips. ` + error.message)
    },
    [dismissModal, showErrorToast],
  )

  const [printLabel] = useMutation(PRINT_PICK_SLIPS, {
    onCompleted: data => {
      if (data.printPickSlips.status) {
        onPrintSuccess()
      } else {
        onPrintFail({ name: '', message: data.printPickSlips.error })
      }
    },
    onError: error => onPrintFail(error),
  })

  const sendLabelToPrint = () => {
    if (orderIDs.length > 0) {
      printLabel({
        variables: {
          orderIds: orderIDs,
          printerUrl: selectedPrinter?.GCPAddress,
        },
      })
    } else {
      showErrorToast(`Failed to print pick slips. There is no order selected`)
    }
  }

  const locationId = !tokenContext?.isAdmin() ? tokenContext?.locationId : undefined

  const onPrinterChange = (printer?: Printer | string): void => {
    setPrinter(printer as Printer)
  }

  const submitDisabled = !selectedPrinter

  return (
    <ModalWrapper id="PrintModal" styles={StyledModalWrapper}>
      <ModalHeader>Print pick slips</ModalHeader>
      <Theme overrides={overrides}>
        <PrinterSelect showOpenAsPdf={false} locationId={locationId} onChange={onPrinterChange} />
      </Theme>

      <ButtonsContainer>
        <Button size="sm" variant="primary-text" onClick={dismissModal}>
          Cancel
        </Button>
        <Button size="sm" disabled={submitDisabled} onClick={sendLabelToPrint}>
          Print
        </Button>
      </ButtonsContainer>
    </ModalWrapper>
  )
}

export default PrintModal

const StyledModalWrapper = css`
  overflow-y: visible;
  width: 450px;

  > span div {
    font-size: 26pt;
  }
`
