import { useState } from 'react'
import { Button, Spacer, Tooltip } from '@truepill/react-capsule'
import { isPaidClaim } from '@truepill/tpos-data-util'
import { useMutation } from '@truepill/tpos-react-router'
import { RxFillRequestStatus, UserRoles } from '@truepill/tpos-types'
import { ReactComponent as UmbrellaIcon } from 'assets/icons/umbrella.svg'
import ClaimSummary from 'components/ClaimSummary'
import IconWrapper from 'components/IconWrapper'
import { ModalWrapper, ModalHeader, ButtonsContainer } from 'components/Modal'
import { SEND_BACK_TO_ADJUDICATION } from 'gql'
import useErrorToast from 'hooks/toast/useErrorToast'
import useGetNextPV1 from 'hooks/useGetNextPV1'
import useOrderLock from 'hooks/useOrderLock'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import { useToastContext } from 'providers/Overlays/ToastProvider'
import SelectionProvider from 'providers/SelectionProvider'
import { usePlusClient } from 'providers/VisionRouter'
import { Clock } from 'react-feather'
import { bodyPrimaryColor } from 'styles/styleVariables'
import { ToastType } from 'types'
import type { Fill, Order, Prescription, RXFillRequest } from 'types'
import { getDiffDaysBetweenDates, formatDate } from 'utils/dates'
import WrappedReverseClaimDocumentationModal from './ReverseClaimDocumentationModal'

interface ClaimsSummaryModalProps {
  fill: Fill
  order: Order
  rxFillRequest: RXFillRequest
  prescription: Prescription
}

const WrappedClaimsSummaryModal = (props: ClaimsSummaryModalProps): JSX.Element => {
  return (
    <SelectionProvider>
      <ClaimsSummaryModal {...props} />
    </SelectionProvider>
  )
}

const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: {
  condition: boolean
  wrapper: (chidren: JSX.Element) => JSX.Element
  children: JSX.Element
}) => (condition ? wrapper(children) : children)

const ClaimsSummaryModal = (props: ClaimsSummaryModalProps) => {
  const { fill, order, rxFillRequest, prescription } = props
  const {
    tokenContext: { hasRole },
  } = usePlusClient()
  const orderEditable = useOrderLock(order._id)
  const showErrorToast = useErrorToast()
  const { showToast } = useToastContext()
  const { dismissModal } = useModalContext()
  const { getNextPV1 } = useGetNextPV1()
  const [showClaimsSummaryModal, setShowClaimsSummaryModal] = useState(true)
  const [showReverseClaimsDocumentationModal, setShowReverseClaimsDocumentationModal] = useState(false)

  const [sendBackToAdjudication, { loading: loadingSendBackToAdjudication }] = useMutation(SEND_BACK_TO_ADJUDICATION, {
    variables: { orderId: order._id, fillId: fill?._id, message: 'PV1 Reverse Claims' },
    refetchQueries: ['getBasicOrder'],
  })

  const isComplete = rxFillRequest.status === RxFillRequestStatus.Complete
  const isPV2 = rxFillRequest.status === RxFillRequestStatus.PV2
  const isAnyPaidClaimOlderThan30D =
    fill?.claims?.some(
      claim =>
        isPaidClaim(claim) &&
        claim.dateOfService &&
        getDiffDaysBetweenDates(formatDate(claim.dateOfService) as unknown as Date, new Date()) > 30,
    ) ?? false

  const shouldLeadCSReverseClaim =
    isComplete &&
    isAnyPaidClaimOlderThan30D &&
    hasRole([UserRoles.CustomerSupport]) &&
    !hasRole([UserRoles.LeadCustomerSupport])

  return (
    <>
      {showClaimsSummaryModal && (
        <ModalWrapper id="ClaimsSummaryModal">
          <ModalHeader hideClose>
            <IconWrapper>
              <UmbrellaIcon fill={bodyPrimaryColor} />
            </IconWrapper>
            <h2>Claim Summary</h2>
          </ModalHeader>
          <ClaimSummary fill={fill} order={order} rxFillRequest={rxFillRequest} prescription={prescription} />
          <ButtonsContainer>
            {!isPV2 && (
              <ConditionalWrapper
                condition={shouldLeadCSReverseClaim}
                wrapper={children => (
                  <Tooltip
                    label="Claim too old; supervisor to assist if reversal required"
                    css={{
                      zIndex: '999',
                    }}
                    arrowCss={{
                      zIndex: '999',
                    }}
                  >
                    {children}
                  </Tooltip>
                )}
              >
                <Button
                  size="sm"
                  variant="primary-outline"
                  disabled={
                    !orderEditable ||
                    rxFillRequest.status === RxFillRequestStatus.Adjudication ||
                    shouldLeadCSReverseClaim
                  }
                  data-testid="reverse"
                  style={{ display: 'flex', alignItems: 'center' }}
                  onClick={async () => {
                    try {
                      if (isComplete) {
                        setShowClaimsSummaryModal(false)
                        setShowReverseClaimsDocumentationModal(true)
                      } else {
                        await sendBackToAdjudication()
                        showToast(`Successfully reversed claims for fill: ${fill?._id}`, ToastType.success)
                      }

                      if (rxFillRequest.status === RxFillRequestStatus.PV1) {
                        await getNextPV1(order, fill)
                      }
                    } catch (e) {
                      showErrorToast('Failed to reverse claims: ' + e.message)
                    }
                  }}
                >
                  <Clock aria-hidden />
                  <Spacer size="xs" />
                  {loadingSendBackToAdjudication ? 'Reversing...' : 'Reverse Claims'}
                </Button>
              </ConditionalWrapper>
            )}
            <Button
              size="sm"
              style={{ display: 'flex', alignItems: 'center', marginLeft: '0.5em' }}
              onClick={() => {
                dismissModal()
              }}
            >
              Close
            </Button>
          </ButtonsContainer>
        </ModalWrapper>
      )}

      {showReverseClaimsDocumentationModal && (
        <WrappedReverseClaimDocumentationModal
          order={order}
          rxFillRequest={rxFillRequest}
          fill={fill}
          prescription={prescription}
          showReverseClaimsModal={showReverseClaimsDocumentationModal}
          setShowClaimsSummaryModal={setShowClaimsSummaryModal}
          setShowReverseClaimsDocumentationModal={setShowReverseClaimsDocumentationModal}
          dismissModal={dismissModal}
        />
      )}
    </>
  )
}

export default WrappedClaimsSummaryModal
