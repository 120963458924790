import { useMemo, useReducer, useContext, createContext } from 'react'
import type { ChildProps } from 'types'

interface Funs {
  changeQuantity: (_id: AbstractOTCProduct['_id'], quantity: number, selectionId: string) => void
  changeAction: (_id: AbstractOTCProduct['_id'], selectionId: string, action?: string) => void
  changeShippingPayment: (_id: AbstractOTCProduct['_id'], selectionId: string, shippingPayment?: string) => void
  // changeShippingCharge: (_id: AbstractOTCProduct['_id'], selectionId: string, shippingCharge?: string) => void
  // changeShippingSpeed: (_id: AbstractOTCProduct['_id'], selectionId: string, shippingSpeed?: string) => void
  changeReason: (_id: AbstractOTCProduct['_id'], selectionId: string, reason?: string) => void
  changeShippingForm: (form: ShippingForm) => void
  changeOtherReason: (_id: AbstractOTCProduct['_id'], selectionId: string, otherReason?: string) => void
  changeItems: (items: AbstractOTCProduct[]) => void
  removeSelection: (selectionId: string) => void
}

interface ReturnDialogProviderProps extends Funs {
  items: Items
  selectedItems: SelectedItem[]
  availableItems: AbstractOTCProduct[]
  shippingForm: ShippingForm
}

export interface AbstractOTCProduct {
  type: 'bundle' | 'item' | 'order'
  quantity: number
  _id: string
  name: string
  left?: number
  unitCost: number
  discount: number
  bundleId?: string
  tax: number
  action: string
  total: number
  shippingCost: number
}

interface ReturnItemReducerState {
  selectedItems: SelectedItem[]
  items: Items
  shippingForm: ShippingForm
}

export interface ShippingForm {
  firstName?: string
  lastName?: string
  state?: string
  zip?: string
  city?: string
  street1?: string
  street2?: string
  shippingSpeed?: string
  shippingCharge?: string
}

export interface Items {
  [key: string]: AbstractOTCProduct
}

export type SelectedItem = {
  _id: string
  quantity: number
  selectionId: string
  reason?: string
  action?: string
  shippingPayment?: string
  otherReason?: string
}

type ReturnItemReducerAction =
  | ChangeQuantityAction
  | ChangeShippingPaymentAction
  //| ChangeShippingSpeedAction
  | ChangeActionAction
  | ChangeReasonAction
  | ChangeOtherReasonAction
  | ChangeItemsAction
  | RemoveSelectionAction
  | ChangeShippingForm

type ChangeShippingForm = {
  type: 'changeShippingForm'
  payload: ShippingForm
}

type ChangeQuantityAction = {
  type: 'changeQuantity'
  payload: {
    _id: string
    quantity: number
    selectionId: string
  }
}

type ChangeActionAction = {
  type: 'changeAction'
  payload: {
    _id: string
    action?: string
    selectionId: string
  }
}

type ChangeShippingPaymentAction = {
  type: 'changeShippingPayment'
  payload: {
    _id: string
    shippingPayment?: string
    selectionId: string
  }
}

// type ChangeShippingSpeedAction = {
//   type: 'changeShippingSpeed'
//   payload: {
//     _id: string
//     shippingSpeed?: string
//     selectionId: string
//   }
// }

type ChangeReasonAction = {
  type: 'changeReason'
  payload: {
    _id: string
    reason?: string
    selectionId: string
  }
}

type ChangeOtherReasonAction = {
  type: 'changeOtherReason'
  payload: {
    _id: string
    otherReason?: string
    selectionId: string
  }
}

type ChangeItemsAction = {
  type: 'changeItems'
  payload: AbstractOTCProduct[]
}

type RemoveSelectionAction = {
  type: 'removeSelection'
  payload: {
    selectionId: string
  }
}

const INITIAL_REDUCER_STATE: ReturnItemReducerState = {
  items: {},
  selectedItems: [],
  shippingForm: {},
}

const ReturnDialogContext = createContext<ReturnDialogProviderProps | undefined>(undefined)

const getTotalQuantities = (selectedItems: SelectedItem[]) => {
  return selectedItems.reduce((acc: { [key: string]: number }, selection) => {
    if (acc[selection._id] === undefined) {
      acc[selection._id] = 0
    }
    acc[selection._id] += selection.quantity
    return acc
  }, {})
}

const ReturnItemReducer = (state: ReturnItemReducerState, action: ReturnItemReducerAction) => {
  const { selectedItems, items, shippingForm } = state
  const { type, payload } = action

  if (type === 'changeItems') {
    const changeItemsPaylod = payload as ChangeItemsAction['payload']
    const newItems = changeItemsPaylod.reduce((acc: Items, item) => {
      acc[item._id] = item
      return acc
    }, {})

    return {
      items: newItems,
      selectedItems: [],
      shippingForm,
    }
  }

  if (type === 'removeSelection') {
    const changeQuantityPayload = payload as RemoveSelectionAction['payload']
    const { selectionId } = changeQuantityPayload
    const indexOfSelection = selectedItems.findIndex(
      ({ selectionId: itemSelectionId }) => itemSelectionId === selectionId,
    )

    if (indexOfSelection !== -1) {
      const newSelections = [...selectedItems]
      newSelections.splice(indexOfSelection, 1)
      return { ...state, selectedItems: newSelections }
    }
  }

  if (type === 'changeReason') {
    const changeReasonPayload = payload as ChangeReasonAction['payload']
    const { selectionId, reason } = changeReasonPayload
    const indexOfSelection = selectedItems.findIndex(
      ({ selectionId: itemSelectionId }) => itemSelectionId === selectionId,
    )

    if (indexOfSelection === -1) {
      return state
    }

    const currentSelection = selectedItems[indexOfSelection]
    const newSelectedItems = [...selectedItems]
    const newSelection = { ...currentSelection, reason }
    if (reason !== 'OTHER') {
      delete newSelection.otherReason
    }
    newSelectedItems.splice(indexOfSelection, 1, newSelection)
    return {
      ...state,
      selectedItems: newSelectedItems,
    }
  }

  if (type === 'changeAction') {
    const changeActionPayload = payload as ChangeActionAction['payload']
    const { selectionId, action } = changeActionPayload
    const indexOfSelection = selectedItems.findIndex(
      ({ selectionId: itemSelectionId }) => itemSelectionId === selectionId,
    )

    if (indexOfSelection === -1) {
      return state
    }

    const currentSelection = selectedItems[indexOfSelection]
    const newSelectedItems = [...selectedItems]
    const newSelection = { ...currentSelection, action }
    newSelectedItems.splice(indexOfSelection, 1, newSelection)
    return {
      ...state,
      selectedItems: newSelectedItems,
    }
  }

  if (type === 'changeShippingPayment') {
    const changeShippingPaymentPayload = payload as ChangeShippingPaymentAction['payload']
    const { selectionId, shippingPayment } = changeShippingPaymentPayload
    const indexOfSelection = selectedItems.findIndex(
      ({ selectionId: itemSelectionId }) => itemSelectionId === selectionId,
    )

    if (indexOfSelection === -1) {
      return state
    }

    const currentSelection = selectedItems[indexOfSelection]
    const newSelectedItems = [...selectedItems]
    const newSelection = { ...currentSelection, shippingPayment }
    newSelectedItems.splice(indexOfSelection, 1, newSelection)
    return {
      ...state,
      selectedItems: newSelectedItems,
    }
  }

  // if (type === 'changeShippingCharge') {
  //   const changeShippingChargePayload = payload as ChangeShippingChargeAction['payload']
  //   const { selectionId, shippingCharge } = changeShippingChargePayload
  //   const indexOfSelection = selectedItems.findIndex(
  //     ({ selectionId: itemSelectionId }) => itemSelectionId === selectionId
  //   )

  //   if (indexOfSelection === -1) {
  //     return state
  //   }

  //   const currentSelection = selectedItems[indexOfSelection]
  //   const newSelectedItems = [...selectedItems]
  //   const newSelection = { ...currentSelection, shippingCharge }
  //   newSelectedItems.splice(indexOfSelection, 1, newSelection)
  //   return {
  //     ...state,
  //     selectedItems: newSelectedItems,
  //   }
  // }

  // if (type === 'changeShippingSpeed') {
  //   const changeShippingSpeedPayload = payload as ChangeShippingSpeedAction['payload']
  //   const { selectionId, shippingSpeed } = changeShippingSpeedPayload
  //   const indexOfSelection = selectedItems.findIndex(
  //     ({ selectionId: itemSelectionId }) => itemSelectionId === selectionId
  //   )

  //   if (indexOfSelection === -1) {
  //     return state
  //   }

  //   const currentSelection = selectedItems[indexOfSelection]
  //   const newSelectedItems = [...selectedItems]
  //   const newSelection = { ...currentSelection, shippingSpeed }
  //   newSelectedItems.splice(indexOfSelection, 1, newSelection)
  //   return {
  //     ...state,
  //     selectedItems: newSelectedItems,
  //   }
  // }

  if (type === 'changeOtherReason') {
    const changeOtherReasonPayload = payload as ChangeOtherReasonAction['payload']
    const { selectionId, otherReason } = changeOtherReasonPayload
    const indexOfSelection = selectedItems.findIndex(
      ({ selectionId: itemSelectionId }) => itemSelectionId === selectionId,
    )
    if (indexOfSelection === -1) {
      return state
    }

    const currentSelection = selectedItems[indexOfSelection]
    const newSelectedItems = [...selectedItems]
    const newSelection = { ...currentSelection, otherReason }
    newSelectedItems.splice(indexOfSelection, 1, newSelection)
    return {
      ...state,
      selectedItems: newSelectedItems,
    }
  }

  if (type === 'changeQuantity') {
    const changeQuantityPayload = payload as ChangeQuantityAction['payload']
    const { _id, selectionId } = changeQuantityPayload
    const indexOfSelection = selectedItems.findIndex(
      ({ selectionId: itemSelectionId }) => itemSelectionId === selectionId,
    )

    // If this is the first of an item, add it
    if (indexOfSelection === -1) {
      return {
        ...state,
        selectedItems: [
          ...selectedItems,
          {
            _id,
            quantity: 1,
            selectionId,
          },
        ],
      }
    } else {
      // if we have an existing entry, update the quantity
      // we now need to find the actuals selection item

      const selectedItem = items[_id]

      // No item, no update
      if (!selectedItem) {
        return state
      }

      const newSelection = {
        ...changeQuantityPayload,
      }

      // Selecting the entire order should wipe other selections
      if (selectedItem.type === 'order') {
        return {
          ...state,
          selectedItems: [newSelection],
        }
      }

      // Selecting a bundle should wipe out all other items in the bundle
      if (selectedItem.type === 'bundle') {
        const newSelectedItems = [
          ...selectedItems.filter(prevSelected => {
            const item = items[prevSelected._id]
            // can't have a whole order selected
            if (item) {
              if (item.type === 'order') {
                return false
              }
              return item.bundleId !== selectedItem._id
            }
            return false
          }),
        ]

        const newIndexOfSelection = newSelectedItems.findIndex(
          ({ selectionId: itemSelectionId }) => itemSelectionId === selectionId,
        )

        if (newIndexOfSelection !== -1) {
          newSelectedItems.splice(newIndexOfSelection, 1, newSelection)
        } else {
          newSelectedItems.push(newSelection)
        }

        return {
          ...state,
          selectedItems: newSelectedItems,
        }
      }

      // Selecting an item in a bundle should wipe out its Bundle and any
      // Entire Order selections
      const newSelectedItems = [
        ...selectedItems.filter(prevSelected => {
          const item = items[prevSelected._id]

          // if this is the selection that's currently being changed, we don't
          // need to remove it as it'll be overwritten later
          if (prevSelected.selectionId === selectionId) {
            return true
          }

          // But we do need to remove any entire order entries or Bundle entries
          // containing this item
          if (item) {
            if (item.type === 'order' || (item.type === 'bundle' && item._id === selectedItem.bundleId)) {
              return false
            }
          }
          return true
        }),
      ]

      // Now that selectedItems might be different we need a new index
      const newIndexOfSelection = newSelectedItems.findIndex(
        ({ selectionId: itemSelectionId }) => itemSelectionId === selectionId,
      )

      // This block is commented because a validation was added to show a message saying the user up to how many
      // products can be refunded.
      // Can't try and grab more inventory than is available
      // if (selectedItem) {
      //   const quantities = getTotalQuantities(selectedItems)

      //   const available = selectedItem.quantity - (quantities[_id] || 0) + currentSelection.quantity

      //   newSelection.quantity = Math.min(available, newSelection.quantity)
      // }

      newSelectedItems.splice(newIndexOfSelection, 1, newSelection)

      return {
        ...state,
        selectedItems: newSelectedItems,
      }
    }
  }

  if (type === 'changeShippingForm') {
    const shippingFormData = { ...shippingForm, ...payload }
    return {
      ...state,
      shippingForm: shippingFormData,
    }
  }

  //This condition will always return 'false' since the types
  //'"removeSelection"' and '"changeShippingForm"' have no overlap.

  return state
}

const ReturnDialogProvider = ({ children }: ChildProps): JSX.Element => {
  const [{ selectedItems, items, shippingForm }, dispatch] = useReducer(ReturnItemReducer, INITIAL_REDUCER_STATE)
  const availableItems = useMemo(() => {
    const unSelected: AbstractOTCProduct[] = []
    const totalQuantities = getTotalQuantities(selectedItems)
    Object.keys(items).forEach(itemId => {
      const item = items[itemId]
      if (!totalQuantities[itemId] || item.quantity > totalQuantities[itemId]) {
        if (item.type === 'item' || item.type === 'bundle') {
          item.left = item.quantity - (totalQuantities[itemId] || 0)
        }
        unSelected.push(item)
      }
    })

    return unSelected.sort(function (a, b) {
      const itemA = a.bundleId || a._id
      const itemB = b.bundleId || b._id
      return itemA < itemB ? -1 : itemA > itemB ? 1 : 0
    })
  }, [selectedItems, items])

  const value = {
    items,
    availableItems,
    selectedItems,
    shippingForm,
    changeQuantity: (_id: AbstractOTCProduct['_id'], quantity: number, selectionId: string) => {
      dispatch({
        type: 'changeQuantity',
        payload: { _id, quantity, selectionId },
      })
    },
    changeAction: (_id: AbstractOTCProduct['_id'], selectionId: string, action?: string) => {
      dispatch({
        type: 'changeAction',
        payload: { _id, action, selectionId },
      })
    },
    changeShippingPayment: (_id: AbstractOTCProduct['_id'], selectionId: string, shippingPayment?: string) => {
      dispatch({
        type: 'changeShippingPayment',
        payload: { _id, shippingPayment, selectionId },
      })
    },
    // changeShippingCharge: (_id: AbstractOTCProduct['_id'], selectionId: string, shippingCharge?: string) => {
    //   dispatch({
    //     type: 'changeShippingCharge',
    //     payload: { _id, shippingCharge, selectionId },
    //   })
    // },
    // changeShippingSpeed: (_id: AbstractOTCProduct['_id'], selectionId: string, shippingSpeed?: string) => {
    //   dispatch({
    //     type: 'changeShippingSpeed',
    //     payload: { _id, shippingSpeed, selectionId },
    //   })
    // },
    changeReason: (_id: AbstractOTCProduct['_id'], selectionId: string, reason?: string) => {
      dispatch({
        type: 'changeReason',
        payload: { _id, reason, selectionId },
      })
    },
    changeShippingForm: (form: ShippingForm) => {
      dispatch({
        type: 'changeShippingForm',
        payload: form,
      })
    },
    changeOtherReason: (_id: AbstractOTCProduct['_id'], selectionId: string, otherReason?: string) => {
      dispatch({
        type: 'changeOtherReason',
        payload: { _id, otherReason, selectionId },
      })
    },
    changeItems: (items: AbstractOTCProduct[]) => {
      dispatch({ type: 'changeItems', payload: items })
    },
    removeSelection: (selectionId: string) => {
      dispatch({ type: 'removeSelection', payload: { selectionId } })
    },
  }

  return <ReturnDialogContext.Provider value={value}>{children}</ReturnDialogContext.Provider>
}

const useReturnDialogContext = (): ReturnDialogProviderProps => {
  const returnDialogContext = useContext(ReturnDialogContext)

  if (returnDialogContext === undefined) {
    throw new Error('Attempting to read ReturnDialogContext outside a Provider heirarchy')
  }

  return returnDialogContext as ReturnDialogProviderProps
}

export { useReturnDialogContext }
export default ReturnDialogProvider
