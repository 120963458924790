import CustomizedTextField from 'components/CustomizedTextField'
import { Search } from 'react-feather'
import styled from 'styled-components'

/**
 * Build search input component using capsule
 * Usage:
 * <SearchTextField
 *    value='My value'
 *    onChange={(e) => myFunction(e.target.value)}
 * />
 * @param value - Current value
 * @param onChange - Function called when the input changes its value
 * @returns Search input component
 */

const SearchTextField = styled(CustomizedTextField).attrs({
  size: 'sm',
  startAdornment: Search,
})``

export default SearchTextField
