import { Button, Spacer, Tooltip } from '@truepill/react-capsule'
import { doesDispensedMatchClaim } from '@truepill/tpos-data-util'
import { useMutation, useHistory } from '@truepill/tpos-react-router'
import type { TriageReasons } from '@truepill/tpos-types'
import { ClaimDataPaidStatus, RxFillRequestStatus, CopayStatus } from '@truepill/tpos-types'
import { ReactComponent as UmbrellaIcon } from 'assets/icons/umbrella.svg'
import HyphenatedNdc from 'components/HyphenatedNdc'
import IconWrapper from 'components/IconWrapper'
import { ModalWrapper, ModalHeader, ButtonsContainer } from 'components/Modal'
import { ListRowLabel, ListRowValue, StyledRXImageCell } from 'components/RXTable'
import TriageOrResolveTriageButton from 'components/TriageOrResolveTriageButton'
import {
  SEND_BACK_TO_PV1,
  REVERSE_ACTIVE_CLAIMS,
  COMPLETE_COPAY_REQUEST_FILL,
  REVERSE_ACTIVE_COPAY_CLAIMS,
  RESOLVE_COPAY_TRIAGE,
  RESOLVE_TRIAGE,
} from 'gql'
import { useOrderLock, useCopayLock } from 'hooks'
import useErrorToast from 'hooks/toast/useErrorToast'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import { FulfillmentQueueName } from 'hooks/useFulfillmentQueue'
import useGetNextAdjudication from 'hooks/useGetNextAdjudication'
import useGetNextTriage from 'hooks/useGetNextTriage'
import RejectModal from 'modals/RejectModal'
import moment from 'moment'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import SelectionProvider from 'providers/SelectionProvider'
import { usePlusClient } from 'providers/VisionRouter'
import { Clock, XCircle, CheckCircle } from 'react-feather'
import { useLocation } from 'react-use'
import styled from 'styled-components'
import { bodyPrimaryColor, contrastBackgroundColor } from 'styles/styleVariables'
import type { OrderInsurance, Fill, QueueItem, RXFillRequest, Claim, ClaimResponseInfo, CopayRequestFill } from 'types'
import {
  continueOrderWorkflow,
  formatCurrency,
  isCopayRequest,
  isOrder,
  calculateClaimPricingResponseFee,
  getInsuranceKey,
  getClaimsFromLastRun,
} from 'utils'

interface ClaimsSuccessModalProps {
  fill: Fill
  item: QueueItem
  claim?: Claim
  itemFill: RXFillRequest | CopayRequestFill
  onDismissOtherModals?: () => void
  showActions?: boolean
  showReverse?: boolean
  updatedDispensed?: ClaimResponseInfo
  setShouldShowClaimSuccessModal?: (arg0: number) => void
  fulfillmentQueueName?: FulfillmentQueueName | undefined
}

interface ClaimModalProps {
  fill: Fill
  claim: Claim
  updatedDispensed?: ClaimResponseInfo
  payerIndex: number
  insurances?: OrderInsurance[]
}

const WrappedClaimsSuccessModal = (props: ClaimsSuccessModalProps): JSX.Element => {
  return (
    <SelectionProvider>
      <ClaimsSuccessModal {...props} />
    </SelectionProvider>
  )
}

const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: {
  condition: boolean
  wrapper: (chidren: JSX.Element) => JSX.Element
  children: JSX.Element
}) => (condition ? wrapper(children) : children)

const ClaimModal = (props: ClaimModalProps) => {
  const { fill, claim, updatedDispensed, payerIndex, insurances } = props
  const { bin, pcn, status } = claim
  const cardholderId = claim.sent?.metadata?.insurance?.cardholderId || ''
  const acquisitionCost = claim?.cost
  const claimPricing = claim?.received?.groups?.[0]?.pricingResponse
  const copay = claimPricing?.patientPayAmount
  const fees = !!claimPricing ? calculateClaimPricingResponseFee(claimPricing) : 0

  const ingredientCostPaid = claimPricing?.ingredientCostPaid
  const remittance = claimPricing?.totalAmountPaid
  const grossProfit =
    remittance !== undefined && acquisitionCost !== undefined && copay !== undefined
      ? remittance + copay - acquisitionCost
      : undefined

  const dateOfService = moment(claim?.dateOfService).format('MM/DD/YYYY')

  const style = ClaimDataPaidStatus.includes(claim.status ?? '')
    ? { 'background-color': '#e6ffe9', color: '#0e7016' }
    : { 'background-color': '#fff1f1', color: '#c3362e' }

  const claimInsuranceKey = getInsuranceKey(bin, cardholderId, pcn)
  const matachingInsuranceOption = insurances?.find(
    i => getInsuranceKey(i.bin || '', i.cardholderId, i.pcn) === claimInsuranceKey,
  )
  const sentDaysSupply = claim.sent.groups?.[0]?.claim?.daysSupply
  const sentQuantity = claim.sent.groups?.[0]?.claim?.quantityDispensed

  return (
    <PayerBlock>
      <h3>{matachingInsuranceOption?.name || `Payer ${payerIndex}`}</h3>
      <ClaimPayerHeader>
        <ClaimPayerBlock>
          <p>BIN</p>
          <ClaimPayerValue>{bin}</ClaimPayerValue>
        </ClaimPayerBlock>
        <ClaimPayerBlock>
          <p>PCN</p>
          <ClaimPayerValue>{pcn}</ClaimPayerValue>
        </ClaimPayerBlock>
        <ClaimPayerBlock>
          <p>Cardholder ID</p>
          <ClaimPayerValue>{cardholderId}</ClaimPayerValue>
        </ClaimPayerBlock>
        <ClaimPayerBlock style={style}>
          <p>Status</p>
          <ClaimPayerValue>{status}</ClaimPayerValue>
        </ClaimPayerBlock>
      </ClaimPayerHeader>
      <br />
      <ClaimResponseCell data-test-row="date-of-service-modal">
        <ListRowLabel>Date of service:</ListRowLabel>
        <ListRowValue>{dateOfService}</ListRowValue>
      </ClaimResponseCell>
      <ClaimResponseCell data-test-row="ndcs-modal">
        <ListRowLabel>NDCs:</ListRowLabel>
        <ListRowValue>
          <HyphenatedNdc ndc={updatedDispensed?.ndc ?? fill.dispensed.ndc} />
        </ListRowValue>
      </ClaimResponseCell>
      <ClaimResponseCell data-test-row="medication-modal">
        <ListRowLabel> Medication:</ListRowLabel>
        <ListRowValue>{updatedDispensed?.name ?? fill.dispensed.name}</ListRowValue>
      </ClaimResponseCell>
      <ClaimResponseCell data-test-row="qty-modal">
        <ListRowLabel>Qty billed:</ListRowLabel>
        <ListRowValue>
          {sentQuantity ?? updatedDispensed?.quantity ?? fill?.dispensed?.quantity ?? 'Unknown'}
        </ListRowValue>
      </ClaimResponseCell>
      <ClaimResponseCell data-test-row="dayssupply-modal">
        <ListRowLabel>Days supply:</ListRowLabel>
        <ListRowValue>
          {sentDaysSupply ?? updatedDispensed?.daysSupply ?? fill?.dispensed?.daysSupply ?? 'Unknown'}
        </ListRowValue>
      </ClaimResponseCell>
      <ClaimResponseCell data-test-row="cost-modal">
        <ListRowLabel>Acquisition cost:</ListRowLabel>
        <ListRowValue>{formatCurrency(acquisitionCost)}</ListRowValue>
      </ClaimResponseCell>
      <ClaimResponseCell data-test-row="remittance-modal">
        <ListRowLabel>Remittance:</ListRowLabel>
        <ListRowValue>{formatCurrency(remittance)}</ListRowValue>
      </ClaimResponseCell>
      <ClaimResponseCell data-test-row="copay">
        <ListRowLabel>Copay:</ListRowLabel>
        <ListRowValue>{formatCurrency(copay)}</ListRowValue>
      </ClaimResponseCell>
      <ClaimResponseCell data-test-row="ingredient-modal">
        <ListRowLabel>Ingredient cost:</ListRowLabel>
        <ListRowValue>{formatCurrency(ingredientCostPaid)}</ListRowValue>
      </ClaimResponseCell>
      <ClaimResponseCell data-test-row="fee-modal">
        <ListRowLabel>Fees:</ListRowLabel>
        <ListRowValue>{formatCurrency(fees)}</ListRowValue>
      </ClaimResponseCell>
      {payerIndex === 1 && (
        <ClaimResponseCell data-test-row="profit-modal">
          <ListRowLabel>Gross profit:</ListRowLabel>
          <ListRowValue>{formatCurrency(grossProfit)}</ListRowValue>
        </ClaimResponseCell>
      )}
    </PayerBlock>
  )
}

const ClaimsSuccessModal = (props: ClaimsSuccessModalProps) => {
  const {
    fill,
    item,
    itemFill,
    onDismissOtherModals,
    showActions,
    showReverse,
    updatedDispensed,
    setShouldShowClaimSuccessModal,
    fulfillmentQueueName,
    claim,
  } = props
  const { dismissModal, showModal } = useModalContext()
  const showSuccess = useSuccessToast()
  const showError = useErrorToast()
  const history = useHistory()
  const { search } = useLocation()
  const isCopay = isCopayRequest(item)
  const isOrderItem = isOrder(item)
  const orderLocked = useOrderLock(item._id, !isOrderItem)
  const copayLocked = useCopayLock(item._id, isOrderItem)
  const itemEditable = isCopay ? copayLocked : orderLocked

  const [sendToPv1] = useMutation(SEND_BACK_TO_PV1, {
    refetchQueries: ['getBasicOrder'],
  })

  const [completeCopayRequestFill] = useMutation(COMPLETE_COPAY_REQUEST_FILL, {
    refetchQueries: ['getBasicCopay'],
  })

  const [resolveOrderTriage] = useMutation(RESOLVE_TRIAGE, {
    refetchQueries: ['getBasicOrder'],
  })

  const [resolveCopayTriage] = useMutation<
    {
      resolveTriage: { _id: string }
      status: CopayStatus
      triages: { reason: TriageReasons }[]
    },
    { copayRequestId: string; triageId: string }
  >(RESOLVE_COPAY_TRIAGE, {
    onCompleted() {
      showSuccess(`Resolved Triage Copay: ${item._id}`)
      dismissModal()
    },
    onError(err) {
      showSuccess(`Failed to resolve triage: ${err.message.replace('GraphQL error:', '')}`)
    },
  })

  const [reverseActiveClaims, { loading: loadingReverse }] = useMutation(REVERSE_ACTIVE_CLAIMS, {
    refetchQueries: ['getBasicOrder'],
  })

  const [reverseActiveCopayClaims, { loading }] = useMutation(REVERSE_ACTIVE_COPAY_CLAIMS, {
    refetchQueries: ['getBasicCopay'],
  })

  const {
    currentLocation: { queryMap },
    tokenContext: { isAdmin },
  } = usePlusClient()

  const isAdminUser = isAdmin()
  const { getNextOrder: getNextAdjudication } = useGetNextAdjudication()
  const { getNextOrder: getNextTriage } = useGetNextTriage()

  let payerIndex = 0

  const lastClaims = getClaimsFromLastRun(fill.claims, fill.claimSummary?.runId)
  if (claim && !lastClaims.map(i => i._id.toString()).includes(claim?._id.toString())) {
    lastClaims.push(claim)
  }
  const inAdjudication =
    itemFill.status === RxFillRequestStatus.Adjudication ||
    itemFill.status === RxFillRequestStatus.PriorAuthorization ||
    (isCopayRequest(item) && itemFill.status === CopayStatus.Pending)
  const runSuccessful = itemFill.adjudication?.runSuccessful
  const showActionButtons =
    showActions && (inAdjudication || (isCopayRequest(item) && itemFill.status === CopayStatus.Triage)) && runSuccessful

  // This is only used for actions on claim success (which dont appear downstream of adjudication)
  // so only relevant for traige/adjudication/priorAuth states
  let getNextOrder = fulfillmentQueueName === FulfillmentQueueName.PriorAuthorization ? undefined : getNextAdjudication

  if (fulfillmentQueueName === FulfillmentQueueName.Triage) {
    getNextOrder = getNextTriage
  }

  const doesNotDispensedMatchClaim = !doesDispensedMatchClaim(lastClaims, fill.dispensed, isAdminUser)

  return (
    <ModalWrapper id="ClaimsSuccessModal" styles={[{ 'overflow-x': 'hidden' }, { 'overflow-y': 'hidden' }]}>
      <ModalHeader hideClose>
        <IconWrapper>
          <UmbrellaIcon fill={bodyPrimaryColor} />
        </IconWrapper>
        <h2>Claim response</h2>
      </ModalHeader>
      <PayerWrapper>
        {lastClaims.map(currentClaim => {
          payerIndex++
          return (
            <ClaimModal
              insurances={item.insurances}
              fill={fill}
              claim={currentClaim}
              updatedDispensed={updatedDispensed}
              payerIndex={payerIndex}
            />
          )
        })}
      </PayerWrapper>
      <ButtonsContainer>
        {(showActionButtons || showReverse) && (
          <Button
            size="sm"
            variant="primary-outline"
            data-testid="reverse"
            style={{ display: 'flex', alignItems: 'center' }}
            onClick={async () => {
              if (isCopay) {
                try {
                  const variables = {
                    variables: {
                      copayRequestId: item._id,
                      fillId: fill._id,
                    },
                  }
                  await reverseActiveCopayClaims(variables)
                  if (setShouldShowClaimSuccessModal) {
                    setShouldShowClaimSuccessModal(0)
                  }
                  dismissModal()
                } catch (e) {
                  showError(e.message)
                  console.error(e)
                }
              } else {
                try {
                  const variables = {
                    variables: {
                      fillId: fill._id,
                    },
                  }
                  await reverseActiveClaims(variables)
                  if (setShouldShowClaimSuccessModal) {
                    setShouldShowClaimSuccessModal(0)
                  }
                  dismissModal()
                } catch (e) {
                  showError(e.message)
                  console.error(e)
                }
              }
            }}
          >
            <Clock aria-hidden />
            <Spacer size="xs" />
            {loadingReverse || loading ? 'Reversing...' : 'Reverse Claims'}
          </Button>
        )}
        {showActionButtons && !isCopayRequest(item) && (
          <Button
            size="sm"
            variant="primary-outline"
            data-testid="reject"
            disabled={!itemEditable}
            style={{ display: 'flex', alignItems: 'center', marginLeft: '0.5em' }}
            onClick={() =>
              showModal(() => (
                <RejectModal
                  itemId={item._id}
                  fillId={fill._id}
                  isControlledSubstance={!!fill.handlingTags.deaScheduleNum || fill.handlingTags.isControlledSubstance}
                  onDismiss={onDismissOtherModals}
                  confirmationCallback={async () => {
                    showSuccess(`Rx: ${fill._id} - rejected`)
                    await continueOrderWorkflow(
                      search,
                      queryMap,
                      history,
                      () => getNextOrder?.(item, fill),
                      fulfillmentQueueName,
                      item.rxFillRequests.length > 1,
                    )

                    dismissModal()
                  }}
                />
              ))
            }
          >
            <XCircle aria-hidden />
            <Spacer size="xs" />
            Reject
          </Button>
        )}
        {showActionButtons && !isCopayRequest(item) && (
          <TriageOrResolveTriageButton
            item={item}
            fillId={fill._id}
            forceShowTriage
            onDismiss={onDismissOtherModals}
            isCapsule={true}
            onClick={async () => {
              try {
                const variables = {
                  variables: {
                    fillId: fill._id,
                    orderId: item._id,
                  },
                }
                await sendToPv1(variables)
                await continueOrderWorkflow(
                  search,
                  queryMap,
                  history,
                  () => getNextOrder?.(item, fill),
                  fulfillmentQueueName,
                  item.rxFillRequests.length > 1,
                )
                dismissModal()
              } catch (e) {
                showError(e.message)
                console.error(e)
              }
            }}
          />
        )}
        {showActionButtons && (
          <ConditionalWrapper
            condition={doesNotDispensedMatchClaim}
            wrapper={children => (
              <Tooltip
                label="Dispensed does not match paid claim"
                css={{
                  zIndex: '999',
                }}
                arrowCss={{
                  zIndex: '999',
                }}
              >
                {children}
              </Tooltip>
            )}
          >
            <Button
              size="sm"
              variant="primary-outline"
              disabled={!itemEditable || doesNotDispensedMatchClaim}
              style={{ display: 'flex', alignItems: 'center', marginLeft: '0.5em' }}
              data-testid="confirm"
              aria-keyshortcuts={'???'}
              onClick={async () => {
                if (isCopayRequest(item)) {
                  //Move copay to complete
                  try {
                    const variables = {
                      variables: {
                        copayRequestId: item._id,
                        fillId: fill._id,
                      },
                    }
                    await completeCopayRequestFill(variables)
                    // Resolve Triage, if one exists
                    const incompleteFill = item.copayRequestFills.find(
                      copayRequestFill =>
                        copayRequestFill.fillId !== fill._id && copayRequestFill.status !== CopayStatus.Complete,
                    )
                    if (incompleteFill === undefined) {
                      const triage = item.triages.find(({ endDate, _id }) => !endDate)
                      if (triage) {
                        await resolveCopayTriage({
                          variables: { copayRequestId: item._id, triageId: triage._id || '' },
                        })
                      }
                    }
                    dismissModal()
                  } catch (e) {
                    showError(e.message)
                    console.error(e)
                  }
                } else if (isOrder(item)) {
                  try {
                    const variables = {
                      variables: {
                        fillId: fill._id,
                        orderId: item._id,
                        skipPv1Check: true,
                      },
                    }

                    await sendToPv1(variables)
                    // Resolve Unable to automatically confirm copay triage
                    const triage = item.triages.find(
                      ({ endDate, reason }) => !endDate && (reason as string) === 'MissingCopayCheck',
                    )

                    if (triage) {
                      await resolveOrderTriage({ variables: { orderId: item._id, triageId: triage._id } })
                    }

                    await continueOrderWorkflow(
                      search,
                      queryMap,
                      history,
                      () => getNextOrder?.(item, fill),
                      fulfillmentQueueName,
                      item.rxFillRequests.length > 1,
                    )
                    dismissModal()
                  } catch (e) {
                    showError(e.message)
                    console.error(e)
                  }
                }
              }}
            >
              <CheckCircle aria-hidden />
              <Spacer size="xs" />
              Confirm
            </Button>
          </ConditionalWrapper>
        )}
        {!showActionButtons && (
          <Button
            size="sm"
            variant="primary"
            data-testid="close"
            disabled={!itemEditable}
            style={{ display: 'flex', alignItems: 'center', marginLeft: '0.5em' }}
            onClick={() => {
              dismissModal()
            }}
          >
            <Spacer size="xs" />
            Close
          </Button>
        )}
      </ButtonsContainer>
    </ModalWrapper>
  )
}

const ClaimResponseCell = styled(StyledRXImageCell)`
  :nth-of-type(even) {
    background-color: ${contrastBackgroundColor};
  }
  grid-template-columns: [label] 10rem [value] 15rem;
  font-family: Roboto;
  border-radius: 0.4rem;
  > h4 {
    font-family: roboto;
  }
  border: none;
  > label {
    padding-left: 1.25rem;
    font-weight: 500;
  }
`

const ClaimPayerValue = styled.p`
  font-weight: bold;
`

const ClaimPayerBlock = styled.span`
  background-color: ${contrastBackgroundColor};
  border-radius: 0.4rem;
  padding: 0.5rem;
  margin: 0.5rem;
  display: inline-block;
  height: 4rem;
  vertical-align: top;
`

const PayerWrapper = styled.div`
  padding-top: 1rem;
`

const PayerBlock = styled.div`
  min-width: 23.75rem;
  display: block;
  float: left;
  margin: 0 0.25rem 0 0.25rem;
`

const ClaimPayerHeader = styled.div``

export default WrappedClaimsSuccessModal
