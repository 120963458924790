import CriticalMedicalNoteWarning from 'components/CriticalMedicalNoteWarning'
import StyledDropDownOption from 'components/DropDownOption'
import HyphenatedNdc from 'components/HyphenatedNdc'
import { AlertTriangle } from 'react-feather'
import type { NdcFullInfo, NdcSubstitutionPackage } from 'types'
import { descriptionFromNdcSubstitution, nameFromNdcSubstitution } from 'utils'
import type { SubstitutionSuggestionOption } from '../types'
import { CheckAbRatedSubstitution, MedicationInfo } from './StyledComponents'

interface AutoCompleteOptionProps extends SubstitutionSuggestionOption {
  ndc: string
  ndcsFromSearch: NdcFullInfo[]
  nonTherapeuticEquivalent: boolean
}

const AutoCompleteOption = (props: AutoCompleteOptionProps) => {
  const { highlighted, label, value, ndc, ndcsFromSearch, nonTherapeuticEquivalent, onClick } = props
  if (!value) {
    return (
      <StyledDropDownOption disabled={true}>
        <p>{label}</p>
      </StyledDropDownOption>
    )
  }

  const ndcPackage = value as NdcSubstitutionPackage

  const isFromManualSearch = ndcsFromSearch?.some(ndcInfo =>
    ndcInfo.packages.some(ndcInfoPackage => ndcInfoPackage.ndc === ndcPackage.ndc),
  )

  const therapeuticEquivalentSubstitute = !nonTherapeuticEquivalent && !isFromManualSearch

  const isSameNdc = ndcPackage.ndc === ndc

  return (
    <StyledDropDownOption highlighted={highlighted} onClick={onClick} capitalize>
      <p>
        {nameFromNdcSubstitution(ndcPackage)} - {descriptionFromNdcSubstitution(ndcPackage)} BOH:{' '}
        {ndcPackage.balanceOnHand}
      </p>
      <MedicationInfo>
        <p>Mfg: {ndcPackage.labeler}</p>
        <p>
          NDC: <HyphenatedNdc ndc={ndcPackage.ndc} />
        </p>
      </MedicationInfo>
      {!therapeuticEquivalentSubstitute && !isSameNdc && (
        <CheckAbRatedSubstitution>
          <AlertTriangle />
          <p>Not therapeutically equivalent</p>
        </CheckAbRatedSubstitution>
      )}
      <CriticalMedicalNoteWarning style={{ maxWidth: '25rem' }} ndc={ndcPackage.ndc} disableBackgroundColor={true} />
    </StyledDropDownOption>
  )
}

export default AutoCompleteOption
