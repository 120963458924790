import { useParams } from '@truepill/tpos-react-router'

export interface TaskQueue {
  name: TaskQueueName
  title: string
}

export enum TaskQueueName {
  PatientMatch = 'Match',
}

export const TASK_QUEUES: {
  [k in TaskQueueName]: TaskQueue
} = {
  [TaskQueueName.PatientMatch]: {
    name: TaskQueueName.PatientMatch,
    title: 'eRx-Patient Match',
  },
}

export default function useTaskQueue(): TaskQueue | undefined {
  const { taskQueueName } = useParams<{
    taskQueueName?: TaskQueueName
  }>()

  return taskQueueName ? TASK_QUEUES[taskQueueName] : undefined
}
