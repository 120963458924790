import type { FC } from 'react'
import { ReactComponent as PrintedIcon } from 'assets/icons/print.svg'
import IconWrapper from 'components/IconWrapper'
import Lozenge from 'components/Tiles/Lozenge'
import styled from 'styled-components'
import { subduedColor, bodyPrimaryColor } from 'styles/styleVariables'

const PrintedTile: FC = () => (
  <StyledPrintedLozenge role="img" data-testid="printed" title="Printed (at least one time)" color={subduedColor}>
    <IconWrapper>
      <PrintedIcon fill={bodyPrimaryColor} />
    </IconWrapper>
  </StyledPrintedLozenge>
)

const StyledPrintedLozenge = styled(Lozenge)`
  padding: 0 0.25rem;
  text-transform: uppercase;
  background-color: ${subduedColor};
  color: ${bodyPrimaryColor};
  > p {
    margin-left: 0.3rem;
  }
`

export default PrintedTile
