import { RightFilledHeader, ListRowLabel, ListRowValue, ListRowEyeButton } from 'components/RXTable'
import { USDInput as UsdInput } from 'components/UsdInput'
import type { EdiReversalOverrides } from 'types'
import { RxEdiListRow, RxEdiRowContainer, RxEdiTitleRow } from '..'
import SuppressFieldButton from '../../SuppressFieldButton'

type PricingSegmentEditReversalProps = {
  ediReversalOverrides: EdiReversalOverrides
  updateEdiReversalOverrides: (input: EdiReversalOverrides) => void
}

const PricingSegmentEditReversal = ({
  ediReversalOverrides,
  updateEdiReversalOverrides,
}: PricingSegmentEditReversalProps): JSX.Element => {
  const { pricing } = ediReversalOverrides

  const grossAmountDue = pricing?.grossAmountDue
  const incentiveAmountSubmitted = pricing?.incentiveAmountSubmitted

  const hideIncentiveAmountSubmittedField = grossAmountDue?.send === false
  const hideGrossAmountDueField = incentiveAmountSubmitted?.send === false

  // These toggle functions handle the state of SupressFieldButton when you click on it
  // and the true/false field we are sending to the backend
  const toggleIsIncentiveAmountSubmittedField = () => {
    updateEdiReversalOverrides({
      pricing: {
        grossAmountDue: { value: grossAmountDue?.value ?? 0, send: hideIncentiveAmountSubmittedField },
      },
    })
  }
  const toggleIsGrossAmountDueFieldDisabled = () => {
    updateEdiReversalOverrides({
      pricing: {
        incentiveAmountSubmitted: { value: incentiveAmountSubmitted?.value ?? 0, send: hideGrossAmountDueField },
      },
    })
  }

  return (
    <>
      <PricingHeaderRow />
      <RxEdiListRow>
        <RxEdiRowContainer>
          <ListRowLabel>Incentive Amount Submitted (E3):</ListRowLabel>
          <ListRowValue>
            <UsdInput
              value={incentiveAmountSubmitted?.value}
              onChange={incentiveAmountSubmitted => {
                if (!incentiveAmountSubmitted) return

                updateEdiReversalOverrides({
                  pricing: {
                    incentiveAmountSubmitted: {
                      value: incentiveAmountSubmitted,
                      send: true,
                    },
                  },
                })
              }}
              placeholder="Incentive amount submitted..."
              disabled={hideIncentiveAmountSubmittedField}
            />
          </ListRowValue>
          <ListRowEyeButton>
            <SuppressFieldButton
              onClick={toggleIsIncentiveAmountSubmittedField}
              hideField={hideIncentiveAmountSubmittedField}
            />
          </ListRowEyeButton>
        </RxEdiRowContainer>
      </RxEdiListRow>

      <RxEdiListRow>
        <RxEdiRowContainer>
          <ListRowLabel>Gross amount due (DU):</ListRowLabel>
          <ListRowValue>
            <UsdInput
              value={grossAmountDue?.value}
              onChange={grossAmountDue => {
                if (!grossAmountDue) return

                updateEdiReversalOverrides({
                  pricing: { grossAmountDue: { value: grossAmountDue, send: true } },
                })
              }}
              placeholder="Gross amount due..."
              disabled={hideGrossAmountDueField}
            />
          </ListRowValue>
          <ListRowEyeButton>
            <SuppressFieldButton onClick={toggleIsGrossAmountDueFieldDisabled} hideField={hideGrossAmountDueField} />
          </ListRowEyeButton>
        </RxEdiRowContainer>
      </RxEdiListRow>
    </>
  )
}

const PricingHeaderRow = () => {
  return (
    <RxEdiTitleRow>
      <RightFilledHeader>Pricing segment</RightFilledHeader>
    </RxEdiTitleRow>
  )
}

export default PricingSegmentEditReversal
