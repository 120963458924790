import type { KeyboardEvent, ChangeEvent } from 'react'
import { useEffect, useState } from 'react'
import { useMutation } from '@truepill/tpos-react-router'
import ActionButton, { SaveButton } from 'components/ActionButton'
import { ButtonsContainer } from 'components/Modal'
import { CancelButton } from 'components/PageStructure'
import Select from 'components/Select'
import { CREATE_NOTE, UPDATE_NOTE } from 'gql'
import { TextArea, CheckBox } from 'grommet'
import useErrorToast from 'hooks/toast/useErrorToast'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import { usePlusClient } from 'providers/VisionRouter'
import styled, { css, keyframes } from 'styled-components'
import { primaryColor, borderColor, primaryBackgroundColor } from 'styles/styleVariables'
import { NoteSpecialTags } from 'types'
import type { NotesCreateOrUpdateProps } from './types'

const NotesCreateOrUpdate = ({
  grabFocus = false,
  isNewNote = true,
  note,
  isCopayRequest,
  excludeCritical = false,
  excludeExternal = false,
}: NotesCreateOrUpdateProps): JSX.Element => {
  const {
    tokenContext: { isPharmacist },
  } = usePlusClient()
  const { dismissModal } = useModalContext()
  const showErrorToast = useErrorToast()
  const showSuccessToast = useSuccessToast()

  const {
    fillId,
    orderId,
    copayRequestId,
    patientId,
    prescriptionId,
    prescriberId,
    payerId,
    message,
    tags,
    escriptId,
    priorAuthorizationId,
  } = note
  const [noteFillId, setNoteFillId] = useState(fillId)
  const [noteOrderId, setNoteOrderId] = useState(orderId)
  const [noteCopayRequestId, setNoteCopayRequestId] = useState(copayRequestId)
  const [notePatientId, setNotePatientId] = useState(patientId)
  const [notePrescriptionId, setNotePrescriptionId] = useState(prescriptionId)
  const [notePrescriberId, setNotePrescriberId] = useState(prescriberId)
  const [notePayerId, setNotePayerId] = useState(payerId)
  const [noteMessage, setNoteMessage] = useState(message || '')
  const [selectedTags, setSelectedTags] = useState(tags)
  const [enterNoteFocused, setEnterNoteFocused] = useState(grabFocus)
  const [noteEscriptId, setNoteEscriptId] = useState(escriptId)
  const [notePriorAuthorizationId, setNotePriorAuthorizationId] = useState(priorAuthorizationId)

  const [createNote] = useMutation(CREATE_NOTE, {
    onCompleted() {
      setNoteMessage('')
      resetTypeAndTag()
      setEnterNoteFocused(false)
    },
    onError(err) {
      showErrorToast('Failed to submit note: ' + err.message)
      console.error(err)
    },
    refetchQueries: ['getAllLogs'],
  })

  const [updateNote] = useMutation(UPDATE_NOTE, {
    onCompleted() {
      setNoteMessage('')
      resetTypeAndTag()
      dismissModal()
      showSuccessToast('Note updated')
    },
    onError(err) {
      setNoteMessage('')
      resetTypeAndTag()
      dismissModal()
      showErrorToast(`Failed to update note! ${err.message.replace('GraphQL error:', '')}`)
    },
    refetchQueries: ['getAllLogs'],
  })

  const updateNoteTags = (newTag: string) => {
    setNoteTag(newTag)
    const updatedTags = [newTag]

    if (selectedTags?.includes(NoteSpecialTags.CRITICAL)) updatedTags.push(NoteSpecialTags.CRITICAL)
    if (selectedTags?.includes(NoteSpecialTags.EXTERNAL)) updatedTags.push(NoteSpecialTags.EXTERNAL)

    setSelectedTags(updatedTags)
  }

  const assignIdFromType = (type?: string) => {
    setNoteType(type)
    let assignedFillId,
      assignedOrderId,
      assignedCopayRequestId,
      assignedPrescriptionId,
      assignedEscriptId,
      assignedPatientId,
      assignedPrescriberId,
      assignedPayerId,
      assignedPriorAuthorizationId

    if (type === viewDictionary?.fill?.label) {
      assignedFillId = fillId
      assignedOrderId = orderId
      assignedCopayRequestId = copayRequestId
    } else if (type === viewDictionary?.order?.label) {
      assignedOrderId = orderId
    } else if (type === viewDictionary?.copay?.label) {
      assignedCopayRequestId = copayRequestId
    } else if (type === viewDictionary?.rx?.label) {
      assignedPrescriptionId = prescriptionId
      assignedPriorAuthorizationId = priorAuthorizationId
    } else if (type === viewDictionary?.patient?.label) {
      assignedPatientId = patientId
    } else if (type === viewDictionary?.prescriber?.label) {
      assignedPrescriberId = prescriberId
    } else if (type === viewDictionary?.payer?.label) {
      assignedPayerId = payerId
    } else if (type === viewDictionary?.escript?.label) {
      assignedEscriptId = escriptId
    }

    setNoteFillId(assignedFillId)
    setNoteOrderId(assignedOrderId)
    setNoteCopayRequestId(assignedCopayRequestId)
    setNotePrescriptionId(assignedPrescriptionId)
    setNotePatientId(assignedPatientId)
    setNotePrescriberId(assignedPrescriberId)
    setNotePayerId(assignedPayerId)
    setNoteEscriptId(assignedEscriptId)
    setNotePriorAuthorizationId(assignedPriorAuthorizationId)
  }

  type viewOption = 'fill' | 'order' | 'rx' | 'patient' | 'prescriber' | 'payer' | 'copay' | 'escript'
  interface viewDict {
    [key: string]: {
      label: string
      viewOptions: viewOption[]
      activeView: boolean
    }
  }

  const viewDictionary: viewDict = {
    fill: {
      label: 'Fill',
      viewOptions: ['fill', 'order', 'rx', 'patient'],
      activeView: !!fillId && !isCopayRequest,
    },
    copayFill: {
      label: 'Fill',
      viewOptions: ['fill', 'copay', 'rx', 'patient'],
      activeView: !!fillId && !!isCopayRequest,
    },
    order: {
      label: 'Order',
      viewOptions: ['order', 'patient'],
      activeView: !fillId && !!orderId,
    },
    copay: {
      label: 'Copay',
      viewOptions: ['copay', 'patient'],
      activeView: !fillId && !!copayRequestId,
    },
    rx: {
      label: 'Rx',
      viewOptions: ['rx', 'patient'],
      activeView: !fillId && !orderId && !!prescriptionId && !priorAuthorizationId,
    },
    escript: {
      label: 'Escript',
      viewOptions: ['escript', 'patient'],
      activeView: !!escriptId,
    },
    patient: {
      label: 'Patient',
      viewOptions: ['patient'],
      activeView: !fillId && !orderId && !prescriptionId && !!patientId,
    },
    prescriber: {
      label: 'Prescriber',
      viewOptions: ['prescriber'],
      activeView: !!prescriberId,
    },
    payer: {
      label: 'Payer',
      viewOptions: ['payer'],
      activeView: !!payerId,
    },
    priorAuthorization: {
      label: 'Rx',
      viewOptions: ['rx'],
      activeView: !!priorAuthorizationId && !!prescriptionId,
    },
  }

  const getActiveView = () =>
    Object.keys(viewDictionary)
      .map(view => viewDictionary[view])
      .find(view => view.activeView)

  const [currentView, setCurrentView] = useState(getActiveView())
  const noteTypes: string[] = currentView?.viewOptions.map(view => viewDictionary[view].label) || []

  const noteTags: string[] = [
    'Informational',
    'QRE',
    'Typing',
    'Adjudication',
    'Complaint',
    'Inquiry',
    ...(isPharmacist() ? ['Clinical', 'DUR'] : []),
  ]

  const filteredNoteTags = selectedTags?.filter(
    tag => tag !== NoteSpecialTags.CRITICAL && tag !== NoteSpecialTags.EXTERNAL,
  )

  const [noteType, setNoteType] = useState(currentView?.label)

  const [noteTag, setNoteTag] = useState(filteredNoteTags?.[0] || noteTags[0])

  const resetTypeAndTag = () => {
    setCurrentView(getActiveView())
    setNoteType(getActiveView()?.label)
    setNoteTag(noteTags[0])
    setSelectedTags([noteTags[0]])
  }

  // For new notes set the tag and type as the first one in the list
  useEffect(() => {
    if (isNewNote) {
      setNoteTag(noteTags[0])
      setSelectedTags([noteTags[0]])
      assignIdFromType(noteType)
    }
  }, [])

  return (
    <>
      <StyledTextArea
        data-testid="note-text"
        onFocus={() => setEnterNoteFocused(true)}
        placeholder={'Enter a note…'}
        value={noteMessage}
        onKeyPress={(e: KeyboardEvent<HTMLTextAreaElement>) => {
          if (e.key === 'Enter' && isNewNote) {
            createNote({
              variables: {
                fillId: noteFillId,
                orderId: noteOrderId,
                copayRequestId: noteCopayRequestId,
                patientId: notePatientId,
                prescriptionId: notePrescriptionId,
                prescriberId: notePrescriberId,
                escriptId: noteEscriptId,
                payerId: notePayerId,
                message: noteMessage,
                tags: selectedTags,
                priorAuthorizationId: notePriorAuthorizationId,
                isExternal: !!selectedTags?.includes(NoteSpecialTags.EXTERNAL),
              },
            })
          }
        }}
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setNoteMessage(e.target.value)}
      />
      <SubmitControls visible={enterNoteFocused}>
        <ControlsRow>
          <StyledSelect
            data-testid="note-type"
            options={noteTypes}
            value={noteType}
            disabled={!isNewNote} // When updating a note disallow changing the type
            onChange={([option]) => {
              option?.value && assignIdFromType(option.value as string)
            }}
            disableClear={true}
          />

          <StyledSelect
            data-testid="note-tags"
            options={noteTags}
            value={noteTag}
            placeholder="tags"
            onChange={([option]) => {
              updateNoteTags(option.value as string)
            }}
            disableClear={true}
          />
        </ControlsRow>
        <ControlsRow>
          <ul>
            {!excludeExternal ? (
              <CheckBoxRow>
                <CheckBoxLabel>
                  <CheckBox
                    checked={selectedTags?.includes(NoteSpecialTags.EXTERNAL)}
                    onChange={() => {
                      if (selectedTags?.includes(NoteSpecialTags.EXTERNAL)) {
                        const tagsNotExternal = selectedTags?.filter(tag => tag !== NoteSpecialTags.EXTERNAL)
                        setSelectedTags(tagsNotExternal)
                      } else {
                        setSelectedTags([...(selectedTags || []), NoteSpecialTags.EXTERNAL])
                      }
                    }}
                  />
                  &nbsp;External
                </CheckBoxLabel>
              </CheckBoxRow>
            ) : (
              <></>
            )}
            {!excludeCritical ? (
              <CheckBoxRow>
                <CheckBoxLabel>
                  <CheckBox
                    checked={selectedTags?.includes(NoteSpecialTags.CRITICAL)}
                    onChange={() => {
                      if (selectedTags?.includes(NoteSpecialTags.CRITICAL)) {
                        const tagsNotCritical = selectedTags?.filter(tag => tag !== NoteSpecialTags.CRITICAL)
                        setSelectedTags(tagsNotCritical)
                      } else {
                        setSelectedTags([...(selectedTags || []), NoteSpecialTags.CRITICAL])
                      }
                    }}
                  />
                  &nbsp;Critical
                </CheckBoxLabel>
              </CheckBoxRow>
            ) : (
              <></>
            )}
          </ul>
          {isNewNote && (
            <StyledSubmitButton
              data-testid="save-note"
              active={true}
              type={'button'}
              label={'Post'}
              disabled={!noteMessage.trim()}
              onClick={() =>
                createNote({
                  variables: {
                    fillId: noteFillId,
                    orderId: noteOrderId,
                    copayRequestId: noteCopayRequestId,
                    patientId: notePatientId,
                    prescriptionId: notePrescriptionId,
                    prescriberId: notePrescriberId,
                    payerId: notePayerId,
                    escriptId: noteEscriptId,
                    message: noteMessage,
                    tags: selectedTags,
                    priorAuthorizationId: notePriorAuthorizationId,
                    isExternal: !!selectedTags?.includes(NoteSpecialTags.EXTERNAL),
                  },
                })
              }
            />
          )}
        </ControlsRow>
        {!isNewNote && (
          <ButtonsRow>
            <ButtonsContainer>
              <CancelButton
                label="Cancel"
                onClick={() => {
                  setNoteMessage('')
                  setSelectedTags([])
                  dismissModal()
                }}
              />
              <SaveButton
                label="Update"
                isModal={false}
                onClick={() =>
                  updateNote({
                    variables: {
                      noteId: note._id,
                      message: noteMessage,
                      tags: selectedTags,
                    },
                  })
                }
              />
            </ButtonsContainer>
          </ButtonsRow>
        )}
      </SubmitControls>
    </>
  )
}

const StyledTextArea = styled(TextArea)`
  height: 5.125rem;
  margin-top: 0.5625rem;
  border: 0.125rem solid ${borderColor};
  border-radius: 0.25rem;
  resize: none;
`

const SubmitControls = styled.div<{ visible?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  ${({ visible }) => (visible ? SlideInAnimation : SlideOutAnimation)};
  animation-fill-mode: forwards;
`

const ControlsRow = styled.ul`
  margin-top: 0.625rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  > :first-child {
    margin-right: 0.25rem;
  }
`

const CheckBoxRow = styled.li`
  margin-left: 0.125rem;
`

const CheckBoxLabel = styled.label`
  display: flex;
  flex-direction: row;
  align-content: center;
  font-size: 0.875rem;
  cursor: pointer;
  > :first-child {
    margin-right: 0.5rem;
  }
`

const StyledSubmitButton = styled(ActionButton)`
  margin-left: auto;
  height: 2.5rem;
  background-color: ${primaryColor};
  color: ${primaryBackgroundColor};
  border-color: ${primaryColor};
`

export const StyledSelect = styled(Select)`
  width: 100%;
  flex: 1;
  align-self: center;
  background-color: ${primaryBackgroundColor};
  overflow: visible;
  min-height: 2rem;
`

const slideIn = keyframes`
  0% {
    overflow: hidden;
    max-height: 0rem;
    min-height: 0rem;
  }
  99% {
    overflow: hidden;
    max-height: 50rem;
    min-height: 7rem;
  }
  100% {
    overflow: hidden;
    max-height: 50rem;
    min-height: 7rem;
  }
`

const slideOut = keyframes`
  0% {
    overflow: hidden;
    max-height: 50rem;
  }
  100% {
    overflow: hidden;
    max-height: 0rem;
  }
`

const SlideInAnimation = css`
  animation: ${slideIn} 200ms;
`

const SlideOutAnimation = css`
  animation: ${slideOut} 200ms;
`

const ButtonsRow = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 0.5rem;
`

export default NotesCreateOrUpdate
