import type { URL } from 'url'
import type { LabelType } from '../document'

export enum PrinterType {
  PDF = 'pdf',
  ZPL = 'zpl',
}

export enum PrinterPurpose {
  RxLabel = 'Rx Labels',
  ShippingLabel = 'Shipping labels',
  CustomSticker = 'Custom stickers',
}

export enum RxPrintComponentOption {
  RxLabelOnly = 'RxLabelOnly',
  RxLabelAndPatientEducation = 'RxLabelAndPatientEducation',
}

export enum ShippingLabelReprintReason {
  LabelWrinkedOrDamaged = 'Label is wrinkled or damaged',
  LabelDidNotPrint = 'Label did not print',
  LabelMissing = 'Label is missing',
  Other = 'Other',
}

export enum PrinterStatus {
  unknown = 'unknown',
  idle = 'idle',
  processing = 'processing',
  stopped = 'stopped',
  notAvailable = 'not available',
}

export enum RxLabelSection {
  Monograph = 'Monograph',
  MedGuide = 'MedGuide',
  Label = 'Label',
}

export enum TTsarTemplate {
  // Pdf rx labels
  EnglishPDFRxLabelTemplate = 'rx-label-template-en',
  EnglishTexasPDFRxLabelTemplate = 'rx-label-template-en-tx',
  SpanishTexasPDFRxLabelTemplate = 'rx-label-template-es-tx',
  SpanishPDFRxLabelTemplate = 'rx-label-template-es',

  // Monograph only
  MonographPdfTemplate = 'monograph-template',

  // Zebra hims rx labels
  EnglishHimsZebraRxLabelTemplate = 'rx-label-zebra-template-hims-en',
  EnglishTexasHimsZebraRxLabelTemplate = 'rx-label-zebra-template-hims-en-tx',
  SpanishTexasHimsZebraRxLabelTemplate = 'rx-label-zebra-template-hims-es-tx',
  SpanishHimsZebraRxLabelTemplate = 'rx-label-template-hims-es',

  // Zebra lifemd rx labels
  EnglishLifeMDZebraRxLabelTemplate = 'rx-label-zebra-template-lifemd-en',
  EnglishTexasLifeMDZebraRxLabelTemplate = 'rx-label-zebra-template-lifemd-en-tx',

  // Optum rx labels
  EnglishOptumZebraRxLabelTemplate = 'rx-label-zebra-template-optum-en',

  // PDF default rx label
  // This one is not really a file in the tsar templates folder but is the value requested and then its changed for
  // one starting with 'rx-label-*'
  DefaultPDFRxLabelTemplate = 'rx-label-pioneer-template',

  // Zebra default rx label
  DefaultZebraRxLabelTemplate = 'rx-label-zebra-template',

  // Rx Label dynamic pioneer
  RxLabelDynamicPioneerTemplate = 'rx-label-dynamic-pioneer-template',

  // This one is not really a file in the tsar templates folder but is the value requested and then its changed for
  // one starting with 'transfer-out-template'
  TransferFillToPioneerTemplate = 'transfer-fill-to-pioneer',
  // Transfer out
  TransferOutTemplate = 'transfer-out-template',
  // Transfer Single Fill Out, Cancel Remaining / similar to transferOut with different quantityRemaining and refillsRemaining
  TransferOutSingleFillCancelRemainingTemplate = 'transfer-single-fill-cancel-remaining',
  // Transfer Single Fill similar to transferOut for single fill
  TransferOutSingleFillTemplate = 'transfer-single-fill',

  // Rx Escript
  RxEscriptV2 = 'rx-escript-v2',
  RxEscriptV2Supervisor = 'rx-escript-v2-supervisor',
  RxEscript = 'rx-escript',

  // Direct Transfer
  DirectTransferImageToPDF = 'direct-transfer-image-to-pdf',
  DirectTransferRxImageTemplate = 'direct-transfer-rx-image-template',
  DirectTransferRxImageAnnotationsTemplate = 'direct-transfer-rx-image-annotations-template',

  // Optum receipt
  OptumReceiptTemplate = 'optum-receipt',

  // Pioneer transfer rx
  PioneerTransferRxTemplate = 'pioneer-transfer-rx-template',

  // Rx Image
  RxImageNonTransferTemplate = 'rx-image-non-transfer-template',
  RxImageNonTransferAnnotationsTemplate = 'rx-image-non-transfer-annotations-template',
  RxImageTransferInTemplate = 'rx-image-transfer-in-template',
  RxImageTemplate = 'rx-image-template',

  // RxChange
  rxChangeTemplate = 'rx-change-template',

  // RxRenewal
  rxRenewalTemplate = 'rx-renewal-template',

  //Vetcove sticker
  deliveryPetCustomer = 'delivery-pet-customer',

  // Order pick slip
  OrderPickSlip = 'order-pick-slip',

  // Fill location history template (will be appended to rx image when downloading)
  FillLocationHistoryTemplate = 'fill-location-history-template',
}

export interface ShippingLabelReprintInformation {
  reason: ShippingLabelReprintReason
  message?: string
}

export interface PrinterInfo {
  name?: string
  model?: string
  toner?: {
    levels: number
    colors: string
    name?: string
  }
  status: PrinterStatus
  queueLength?: number
}

export interface IPrintResponse {
  documentId: any
  s3?: {
    key: string
    bucket: string
    location: URL
  }
  labelTypes: LabelType[]
  pdfBuffer: Buffer
}
