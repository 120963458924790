import { ClaimDataPaidStatus } from '@truepill/tpos-types'
import type { Claim } from 'types'

export const getInsuranceKey = (bin: string, cardholderId: string, pcn?: string): string =>
  `${bin}:${pcn || ''}:${cardholderId}`

export const getRunIdFromFill = (claims: Claim[]): string | undefined => {
  // if there was a paid claim, grab it (there is a chance that we discard a rejected secondary that would be after the primary)
  const lastPaidClaim = claims.filter(claim => ClaimDataPaidStatus.includes(claim.status || '')).pop()
  // otherwise, fall back to the last claim (we stop at a failed claim so, if there was no paid claim, we can safely grab the last one)
  const lastClaim = lastPaidClaim ?? claims[claims.length - 1]
  // grab the last run id
  const lastRunIdIndex = (lastClaim?.runIds?.length ?? 1) - 1
  return lastClaim?.runIds?.[lastRunIdIndex]
}

export const getClaimsFromLastRun = (claims: Claim[], runId: string | undefined): Claim[] => {
  const lastRunId = runId ?? getRunIdFromFill(claims)

  if (!lastRunId) {
    return []
  }

  const claimsFromLastRun = claims.filter(claim => claim.runIds?.includes(lastRunId))

  return claimsFromLastRun
}
