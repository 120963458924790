import { NoteEntry } from 'components/NotesPane/NotesList'
import type { Log } from 'types'
import { getCriticalLogs } from 'utils'
import { AnnotationContainer, StyledList } from './StyledComponents'

type NotesType = { notes: Log[] }

const Notes = ({ notes }: NotesType): JSX.Element => {
  return (
    <AnnotationContainer>
      <StyledList>
        {getCriticalLogs(notes).map(message => (
          <NoteEntry key={message._id} note={message} />
        ))}
      </StyledList>
    </AnnotationContainer>
  )
}

export default Notes
