import { UserRoles } from '@truepill/tpos-types'
import { ReactComponent as ColumnIcon } from 'assets/icons/column.svg'
import { ReactComponent as PharmacyIcon } from 'assets/icons/pharmacy.svg'
import { ReactComponent as AdminIcon } from 'assets/nav/gear.svg'
import { ReactComponent as PeopleIcon } from 'assets/nav/people.svg'
import AuthLimited from 'components/AuthLimited'
import styled from 'styled-components'
import NavButton from './NavButton'

const NavButtonList = (): JSX.Element => {
  return (
    <StyledNavButtonList>
      <AuthLimited roles={[UserRoles.CustomerSupport, UserRoles.LeadCustomerSupport]}>
        <NavButton title="Tpcs" icon={<PeopleIcon />} path="https://tpcs.truepill.com/tpcs" target="_blank" hotKey="" />
      </AuthLimited>
      <AuthLimited
        roles={[
          UserRoles.Pharmacist,
          UserRoles.Technician,
          UserRoles.CustomerSupport,
          UserRoles.LeadCustomerSupport,
          UserRoles.Admin,
          UserRoles.Warehouse,
        ]}
      >
        <NavButton hotKey="!" title="Fulfillment" icon={<ColumnIcon />} path="/fulfillment" />
      </AuthLimited>
      <AuthLimited
        roles={[
          UserRoles.Pharmacist,
          UserRoles.Technician,
          UserRoles.Admin,
          UserRoles.CustomerSupport,
          UserRoles.LeadCustomerSupport,
        ]}
      >
        <NavButton hotKey="@" title="People" icon={<PeopleIcon />} path="/people" />
      </AuthLimited>
      <NavButton hotKey="#" title="Pharmacy" icon={<PharmacyIcon />} path="/pharmacy" />
      <AuthLimited roles={[UserRoles.Admin]}>
        <NavButton hotKey="$" title="Admin" icon={<AdminIcon />} path="/admin" />
      </AuthLimited>
    </StyledNavButtonList>
  )
}

const StyledNavButtonList = styled.ul`
  margin-left: 0.625rem;
  display: flex;
  flex-direction: row;
  align-items: column;
  justify-content: flex-start;
  height: 100%;
`

export default NavButtonList
