import styled, { css } from 'styled-components'

const PagePadding = css`
  padding-left: 1.875rem;
  padding-right: 1.875rem;
`

const TitleSection = styled.span`
  display: block;

  align-items: center;
  h1 {
    width: min-content;
    white-space: nowrap;
  }
  p {
    font-size: 1rem;
    font-weight: 500;
  }
  margin-top: 1rem;
`

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-bottom: 3rem;
  align-items: flex-start;
`

const ToolBarContainer = styled.div`
  display: flex;
  margin-left: 1.875rem;
`

const ViewToggle = styled.ul`
  display: flex;
  flex-direction: row;
  padding-left: 1.875rem;
  margin-bottom: 5;
  margin-bottom: 25px;
  border-bottom: solid 1px #a9a9a9;
  cursor: pointer;
`

const ViewOption = styled.li`
  padding-right: 1rem;
  color: #8e8e8e;
  font-weight: 500;
`

const ViewOptionSelected = styled.li`
  padding-right: 1rem;
  color: #8e8e8e;
  font-weight: 500;
  border-bottom: solid 5px black;
`

const ViewLabel = styled.span`
  padding-left: 0.5rem;
`

const Title = styled.h1`
  font-size: 24px;
  ${PagePadding}
`

const Subtitle = styled.h2`
  color: grey;
  font-size: 14px;
  padding-left: 1.875rem;
  padding-bottom: 1rem;
`

const LoadingSpinnerContainer = styled.div`
  display: flex;
  padding-top: 12rem;
  justify-content: center;
  svg {
    height: 126px;
  }
`

export {
  TitleSection,
  Content,
  ToolBarContainer,
  ViewToggle,
  ViewOption,
  ViewOptionSelected,
  ViewLabel,
  Title,
  Subtitle,
  LoadingSpinnerContainer,
}
