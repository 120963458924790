import { useCallback } from 'react'
import { useMutation } from '@truepill/tpos-react-router'
import { ReactComponent as ReverseIcon } from 'assets/icons/reverse.svg'
import ActionButton from 'components/ActionButton'
import IconWrapper from 'components/IconWrapper'
import { RESET_ORDER } from 'gql'
import useErrorToast from 'hooks/toast/useErrorToast'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import ConfirmationModal from 'modals/ConfirmationModal'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import { bodyPrimaryColor } from 'styles/styleVariables'

type ResetOrderButtonProps = { orderId: string; disabled?: boolean }

const ResetOrderButton = ({ orderId, disabled }: ResetOrderButtonProps): JSX.Element => {
  const { showModal } = useModalContext()
  const [resetOrder, { loading }] = useMutation(RESET_ORDER, {
    refetchQueries: ['getFullOrder'],
    onCompleted(result) {
      showSuccessToast(`Order with id: ${result?.resetOrder?._id} successfully reset`)
    },
    onError(err) {
      showErrorToast(`Failed to reset order. Error: ${err.message.replace('GraphQL error:', '')}`)
    },
  })
  const showErrorToast = useErrorToast(true)
  const showSuccessToast = useSuccessToast(true)

  const showConfirmationModal = useCallback(
    onConfirm => {
      showModal(() => <ConfirmationModal onConfirm={onConfirm} title={'Reset Order'} />)
    },
    [showModal],
  )

  return (
    <ActionButton
      disabled={loading || disabled}
      icon={
        <IconWrapper>
          <ReverseIcon fill={bodyPrimaryColor} />
        </IconWrapper>
      }
      onClick={() => {
        showConfirmationModal(() => {
          resetOrder({ variables: { orderId } })
        })
      }}
      label={`Reset${loading ? 'ing' : ''} Order`}
    />
  )
}
export default ResetOrderButton
