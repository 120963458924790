import type BigNumber from 'bignumber.js'

export interface ClaimSummary {
  runId: string
  runSuccessful: boolean
  dateOfService: string
  copay?: number
  planPay?: PlanPay[]
  awpPrice?: BigNumber // Average Wholesale Price (awp)
  totalPayment?: number
  ingredientCostPaid?: number
  dispensingFeePaid?: number
  flatSalesTaxAmountPaid?: number
  percentageSalesTaxAmountPaid?: number
  incentiveAmountPaid?: number
  otherAmountsPaid?: number
  switchServices: SwitchServices
}

export enum EVoucherType {
  eVoucher = 'eVoucher',
  denialConversion = 'Denial Conversion',
  unknown = 'unknown',
}
export interface EVoucherSwitchService {
  voucherType?: EVoucherType
  originalCopay?: number
  voucherAmount?: number
}

export interface SwitchServices {
  eVoucher?: EVoucherSwitchService
}

export interface PlanPay {
  billingOrder?: number
  status?: string
  paidAmount?: number
}
