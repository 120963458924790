import { useState } from 'react'
import { Button } from '@truepill/react-capsule'
import { ButtonsContainer } from 'components/Modal'
import styled from 'styled-components'
import type { InsuranceOverrideCob, OrderInsurance } from 'types'
import { getOptionsFromLabelMap } from '../../helper'
import { overrideCOB } from '../../ServiceCodes'
import COBAmountRow from './COBAmountComponent'
import OtherPayerDate from './COBDate'
import OtherPayerId from './COBPayerID'
import OtherPayerRejectCodes from './COBRejectCode'

const otherPayerAmountPaidQualifierOptions = getOptionsFromLabelMap(overrideCOB.otherPayerAmountPaidQualifier, true)
const patientResponsibilityAmountQualifier = getOptionsFromLabelMap(
  overrideCOB.patientResponsibilityAmountQualifier,
  true,
)
const benefitStageQualifierOptions = getOptionsFromLabelMap(overrideCOB.benefitStageQualifier, true)

const EditCOBOverrideSection = (props: {
  handleChange: (name: keyof InsuranceOverrideCob, key: string, value?: any, index?: number) => void
  payerInfo: OrderInsurance
}): JSX.Element => {
  const initialValue = [{ amount: 0, qualifier: '' }]
  const initialCOB = {
    otherPayerPatientResponsibilityAmount: { value: initialValue },
    otherPayerRejectCode: { value: [''] },
    benfitStageAmount: { value: initialValue },
    otherPayerAmountPaid: { value: initialValue },
  }
  const { handleChange, payerInfo } = props
  const overrideCOB: Partial<InsuranceOverrideCob> = payerInfo?.cobOverrides || {}
  const otherPayerAmountPaidValues = overrideCOB?.otherPayerAmountPaid?.value?.length
    ? overrideCOB?.otherPayerAmountPaid?.value
    : initialValue
  const otherPayerPatientResponsibilityValues = overrideCOB?.otherPayerPatientResponsibilityAmount?.value?.length
    ? overrideCOB?.otherPayerPatientResponsibilityAmount?.value
    : initialValue
  const benfitStageValues = overrideCOB?.benefitStageAmount?.value?.length
    ? overrideCOB?.benefitStageAmount?.value
    : initialValue

  // Handle toggling rows of other payer amount paid
  const sendPaidAmount = overrideCOB?.otherPayerAmountPaid?.send
  const [sendAmountPaidFlag, setSendAmountPaidFlag] = useState(sendPaidAmount === undefined || !!sendPaidAmount)
  const handleAmountPaid = () => {
    handleChange('otherPayerAmountPaid', 'send', !sendPaidAmount)
    setSendAmountPaidFlag(!sendPaidAmount)
  }

  // Handle toggling rows of other patient repsonsiblity amounts
  const sendPatientResponsiblityAmount = overrideCOB?.otherPayerPatientResponsibilityAmount?.send
  const [sendPatientResponsiblityFlag, setSsendPatientResponsbilityFlag] = useState(
    sendPatientResponsiblityAmount === undefined || !!sendPatientResponsiblityAmount,
  )
  const handlePatientResponsiblityAmount = () => {
    handleChange('otherPayerPatientResponsibilityAmount', 'send', !sendPatientResponsiblityAmount)
    setSsendPatientResponsbilityFlag(!sendPatientResponsiblityAmount)
  }

  // Handle toggling rows of other Benefit stage Amounts
  const sendBenefitAmount = overrideCOB?.benefitStageAmount?.send
  const [sendBenefitAmountFlag, setSendBenefitAmountFlag] = useState(
    sendBenefitAmount === undefined || !!sendBenefitAmount,
  )
  const handleBenefitStageAmountSend = () => {
    handleChange('benefitStageAmount', 'send', !sendBenefitAmount)
    setSendBenefitAmountFlag(!sendBenefitAmount)
  }

  return (
    <ul>
      <PayerTitle>COB Override</PayerTitle>
      <OtherPayerId overrideCOB={overrideCOB} handleChange={handleChange} />
      <OtherPayerDate overrideCOB={overrideCOB} handleChange={handleChange} />

      <PayerFormElement>
        <PayerFormElementLabel>Other payer amount paid (HC / DV)</PayerFormElementLabel>
        {otherPayerAmountPaidValues.map((otherPayerAmount, index) => (
          <COBAmountRow
            index={index}
            handleChange={handleChange}
            options={otherPayerAmountPaidQualifierOptions}
            overrideCOBKey={overrideCOB.otherPayerAmountPaid || initialCOB.otherPayerAmountPaid}
            name={'otherPayerAmountPaid'}
            row={otherPayerAmount}
            handleClick={handleAmountPaid}
            disabledStatus={sendAmountPaidFlag}
          />
        ))}
        <ButtonsContainerRight>
          <Button
            variant="primary-outline"
            style={{ margin: '8px 0 0 0' }}
            size="sm"
            onClick={() => {
              const updateCOB = [
                ...((overrideCOB.otherPayerAmountPaid || initialCOB.otherPayerAmountPaid)?.value ?? []),
                { amount: 0, qualifier: '' },
              ]
              handleChange('otherPayerAmountPaid', 'value', updateCOB, -1)
            }}
          >
            Add another payer amount paid
          </Button>
        </ButtonsContainerRight>
      </PayerFormElement>

      <OtherPayerRejectCodes overrideCOB={overrideCOB || initialCOB} handleChange={handleChange} />
      <PayerFormElement>
        <PayerFormElementLabel>Other payer patient responsibility amount (NP / NQ)</PayerFormElementLabel>
        {otherPayerPatientResponsibilityValues.map((patientResponsibility, index) => (
          <COBAmountRow
            index={index}
            handleChange={handleChange}
            options={patientResponsibilityAmountQualifier}
            overrideCOBKey={
              overrideCOB?.otherPayerPatientResponsibilityAmount || initialCOB.otherPayerPatientResponsibilityAmount
            }
            name={'otherPayerPatientResponsibilityAmount'}
            row={patientResponsibility}
            handleClick={handlePatientResponsiblityAmount}
            disabledStatus={sendPatientResponsiblityFlag}
          />
        ))}
      </PayerFormElement>
      <PayerFormElement>
        <PayerFormElementLabel>Benefit stage amount (MV / MW)</PayerFormElementLabel>
        {benfitStageValues.map((benefitStage, index) => (
          <COBAmountRow
            index={index}
            handleChange={handleChange}
            options={benefitStageQualifierOptions}
            row={benefitStage}
            overrideCOBKey={overrideCOB?.benefitStageAmount || initialCOB.benfitStageAmount}
            name={'benefitStageAmount'}
            handleClick={handleBenefitStageAmountSend}
            disabledStatus={sendBenefitAmountFlag}
          />
        ))}
      </PayerFormElement>
    </ul>
  )
}

export default EditCOBOverrideSection

const PayerTitle = styled.h2`
  font-weight: 500;
  margin: 0;
`

const PayerFormElement = styled.div``

const PayerFormElementLabel = styled.div`
  font-weight: 500;
  font-size: 0.875rem;
  display: block;
  width: 100%;
  margin: 12px 0 0 0;
`

const ButtonsContainerRight = styled(ButtonsContainer)`
  padding-bottom: 0;
  margin-top: 0px;
`
