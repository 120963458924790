import { Button } from '@truepill/react-capsule'
import { UserRoles } from '@truepill/tpos-types'
import { ReactComponent as CheckboxIcon } from 'assets/icons/checkmark.svg'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { ReactComponent as FlagIcon } from 'assets/icons/flag.svg'
import IconWrapper from 'components/IconWrapper'
import { StyledTextInput as TextInput } from 'components/RXTable'
import useErrorToast from 'hooks/toast/useErrorToast'
import type { Task, TaskProductType } from 'providers/TaskProvider'
import { usePlusClient } from 'providers/VisionRouter'
import styled, { css } from 'styled-components'
import EllipsisTruncate from 'styles/EllipsisTruncate'
import { primaryBackgroundColor } from 'styles/styleVariables'

const getCompletedTasks = (tasks: Task[]) => {
  if (tasks.length > 0) {
    return tasks.filter(task => task.completed).length
  }

  return 0
}

const getScansInfo = (tasks: Task[]) => {
  const complete = getCompletedTasks(tasks)
  return { complete, isCompleted: tasks.length - complete === 0 }
}

const getScannedUpc = (tasks: Task[]): string => {
  const upcs = tasks
    .filter(task => task.completed)
    .map(task => task.additionalData?.upcScanned)
    .reduce((acc, upc) => {
      if (upc) {
        if (acc) {
          acc = acc + ', ' + upc
        } else {
          acc = upc
        }
      }

      return acc
    }, '')

  return upcs || ''
}

interface ScannableProduct {
  _id: string
  name?: string
  overrideScan?: boolean
  sku: string
  quantity: number
}

const AccuracyScanningItem = (props: {
  product: ScannableProduct
  tasks: Task[]
  packingData: { error?: string; packSize: number }
  productType: TaskProductType
  handleOverride?: () => void
}): JSX.Element => {
  const { product, tasks, packingData, handleOverride, productType } = props
  const { tokenContext } = usePlusClient()
  const { hasRole, isAdmin } = tokenContext
  const showErrorToast = useErrorToast()

  const itemTasks = tasks.filter(task => task.productType === productType && task.key.includes(product._id))

  const hasItemTasks = itemTasks.length > 0
  const { complete, isCompleted } = getScansInfo(itemTasks)

  const overriden = product.overrideScan || false

  const completed = overriden || (isCompleted && !packingData.error)
  const cancel = overriden ? false : !hasItemTasks || !!packingData.error

  const overrideScan = () => {
    if (
      isAdmin() ||
      hasRole([UserRoles.Pharmacist, UserRoles.LeadPharmacist, UserRoles.LeadWarehouse, UserRoles.LeadCustomerSupport])
    ) {
      handleOverride?.()
    } else {
      showErrorToast('Only leads can override scanning')
    }
  }

  return (
    <StyledItem data-testid="AccuracyScanningItem" data-sku={product.sku}>
      <ItemHeader>
        <Line>
          <CheckMarkIcon completed={completed} cancel={cancel} error={!!packingData.error && !overriden} />
          <HeaderLabel>Name:</HeaderLabel>
          <HeaderText data-testid="sku">{product.name || 'N/A'}</HeaderText>
        </Line>
      </ItemHeader>
      <ItemBody>
        <Info>
          <Line>
            <ContentLabelUPC>UPC:</ContentLabelUPC>
            <TextUPC>
              <StyledTextInput
                value={cancel ? '' : getScannedUpc(itemTasks)}
                placeholder={overriden || cancel ? 'Manual check required' : 'Scan UPC'}
                readOnly={true}
              />
            </TextUPC>
          </Line>
          <Line>
            <ContentLabel>SKU:</ContentLabel>
            <Text data-testid="sku">{product.sku}</Text>
          </Line>
          {packingData.packSize ? (
            <Line>
              <ContentLabel>Pack size:</ContentLabel>
              <Text data-testid="packSize">{packingData.packSize}</Text>
            </Line>
          ) : (
            <></>
          )}
          <Line>
            <ContentLabel>Quantity:</ContentLabel>
            <Text data-testid="quantity">
              {product.quantity}
              {packingData.error && ` (${packingData.error})`}
              {hasItemTasks && ` (Pack ${complete} of ${itemTasks.length})`}
            </Text>
          </Line>
          {!!handleOverride && !overriden && (!cancel || !!packingData.error) ? (
            <LineOverride>
              <OverrideButton variant="primary-text" onClick={overrideScan}>
                Override
              </OverrideButton>
            </LineOverride>
          ) : (
            <LineEmptyOverride></LineEmptyOverride>
          )}
        </Info>
      </ItemBody>
    </StyledItem>
  )
}

const CheckMarkIcon = (props: { completed?: boolean; cancel?: boolean; error?: boolean }): JSX.Element => {
  const { completed, cancel, error } = props
  let icon: React.ReactElement | undefined

  if (error) {
    icon = <FlagIcon fill="white" />
  } else {
    icon = cancel ? <CloseIcon fill="white" /> : completed ? <CheckboxIcon /> : undefined
  }

  return (
    <CheckMarkIconWrapper>
      <StyledTaskBadge completed={completed} cancel={cancel} error={error}>
        {icon ? <IconWrapper>{icon}</IconWrapper> : <></>}
      </StyledTaskBadge>
    </CheckMarkIconWrapper>
  )
}

export default AccuracyScanningItem

const OverrideButton = styled(Button)`
  width: 100%;
  font-size: 12px;
  color: #006fc3;
  text-decoration: underline;
`

const LineOverride = styled.div`
  display: block;
  height: 3rem;
  text-align: center;
`
const LineEmptyOverride = styled.div`
  display: block;
  padding: 1.5rem;
`

const StyledTextInput = styled(TextInput)`
  padding: 0.2rem 0.4rem;
  font-size: 12px;
  border-radius: 8px;
  min-width: 10rem;
  width: 100%;
`

const CheckMarkIconWrapper = styled.div`
  display: inline-flex;
`

const StyledItem = styled.li`
  margin: 4px;
  padding: 0.5rem 1rem;
  background-color: ${primaryBackgroundColor};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  flex-basis: calc(33.333% - 0.5rem);
`

const ItemHeader = styled.div`
  padding-bottom: 0.2rem;
`

const ItemBody = styled.div``

const Line = styled.div`
  display: flex;
  margin-top: 0.2rem;
  width: 100%;
`

const ContentLabel = styled.p`
  display: inline-block;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-size: 12px;
  padding-left: 0.5rem;
`

const Text = styled.p`
  display: inline-block;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  margin-left: 0.2rem;
  font-size: 12px;
`

const HeaderLabel = styled.p`
  display: inline-flex;
  height: 1.75rem;
  padding-left: 0.5rem;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-size: 14px;
  padding-top: 0.1rem;
`

const HeaderText = styled(Text)`
  font-size: 14px;
  width: 70%;
  display: inline-flex;
  padding-top: 0.1rem;
`

const ContentLabelUPC = styled(ContentLabel)`
  margin-top: 0.2rem;
`

const TextUPC = styled(Text)`
  font-size: 14px;
  height: 1.75rem;
  width: 80%;
  margin-bottom: 0.5rem;
`

const Info = styled.div`
  display: block;
  font-size: 1rem;
  > p {
    ${EllipsisTruncate}
  }
`

const FilledBadge = css`
  background-color: #034d83;
`

const CompletedBadgeBorder = css`
  border: 0.125rem solid #034d83;
`

const BadgeBorder = css`
  border: 0.125rem solid #034d83;
`

const FilledBadgeCancel = css`
  background-color: #9eacbc;
`

const CompletedBadgeBorderCancel = css`
  border: 0.125rem solid #9eacbc;
`

const StyledTaskBadge = styled.div<{ completed?: boolean; cancel?: boolean; error?: boolean }>`
  width: 1.75rem;
  height: 1.75rem;
  flex-shrink: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${primaryBackgroundColor};
  ${({ completed }) => completed && FilledBadge}
  ${({ completed }) => completed && CompletedBadgeBorder}
  ${({ completed }) => !completed && BadgeBorder}
  ${({ cancel }) => cancel && FilledBadgeCancel}
  ${({ cancel }) => cancel && CompletedBadgeBorderCancel}
  ${({ error }) => error && 'background-color: #af5304;'}
  ${({ error }) => error && 'border-color: #af5304;'}
`
