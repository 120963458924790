import { useMutation } from '@truepill/tpos-react-router'
import { CopayStatus, UserRoles } from '@truepill/tpos-types'
import { ReactComponent as TimeIcon } from 'assets/icons/time.svg'
import ActionButton from 'components/ActionButton'
import AuthLimited from 'components/AuthLimited'
import IconWrapper from 'components/IconWrapper'
import { REVERSE_ACTIVE_COPAY_CLAIMS } from 'gql'
import useErrorToast from 'hooks/toast/useErrorToast'
import { bodyPrimaryColor } from 'styles/styleVariables'
import type { Fill, QueueItem } from 'types'
import { getErrorMessage, getItemFill, isCopayRequest } from 'utils'

type ReverseActiveCopayClaimsActionButtonProps = { fill: Fill; item: QueueItem }

export const ReverseActiveCopayClaimsActionButton = ({
  item,
  fill,
}: ReverseActiveCopayClaimsActionButtonProps): JSX.Element => {
  const showErrorToast = useErrorToast()

  const [reverseActiveCopayClaims, { loading: loadingReverseCopay }] = useMutation(REVERSE_ACTIVE_COPAY_CLAIMS, {
    refetchQueries: ['getBasicCopay'],
    onError: e => {
      showErrorToast(getErrorMessage(e))
    },
  })

  const isRejectedCopay = isCopayRequest(item) && getItemFill(item, fill._id)?.status === CopayStatus.Rejected
  if (!isRejectedCopay) {
    return <></>
  }

  return (
    <AuthLimited roles={[UserRoles.Admin, UserRoles.LeadCustomerSupport]}>
      <ActionButton
        icon={
          <IconWrapper>
            <TimeIcon fill={bodyPrimaryColor} />
          </IconWrapper>
        }
        label={loadingReverseCopay ? 'Reversing...' : 'Reverse Claims'}
        onClick={() => {
          reverseActiveCopayClaims({
            variables: {
              copayRequestId: item._id,
              fillId: fill._id,
            },
          })
        }}
      />
    </AuthLimited>
  )
}
