import type { SetStateAction } from 'react'
import { useState } from 'react'
import { Button } from '@truepill/react-capsule'
import { ReactComponent as ZoomInIcon } from 'assets/icons/zoom-in.svg'
import { ReactComponent as ZoomOutIcon } from 'assets/icons/zoom-out.svg'
import CustomizedTextField from 'components/CustomizedTextField'
import HotKeyToolTip from 'components/HotKeyToolTip'
import IconWrapper from 'components/IconWrapper'
import useHotKey, { HotKeyLevel } from 'hooks/useHotKey'
import type { FlattenSimpleInterpolation } from 'styled-components'
import styled from 'styled-components'
import { primaryColor, borderColor, primaryBackgroundColor } from 'styles/styleVariables'
import type { Prescription } from 'types'

type RXScanImageProps = {
  prescription?: Prescription
  src?: string
  inline?: boolean
  scanPdfStyle?: FlattenSimpleInterpolation
  wrapperStyle?: FlattenSimpleInterpolation
  showPDFCustomToolbar?: boolean
}

const NoSource = ({ inline }: { inline?: boolean }) => {
  return (
    <ScanImageAnchor fixed={!inline}>
      <ErrorText>
        <span role="img" aria-label="Warning">
          ⚠️{' '}
        </span>
        Prescription image not found
      </ErrorText>
    </ScanImageAnchor>
  )
}

const ImageRenderer = ({ src }: { src: string }) => {
  const [scanImageScale, setScanImageScale] = useState(1)
  const [showToolTip, setShowToolTip] = useState(false)

  useHotKey('-', HotKeyLevel.normal, () => setScanImageScale(1))
  useHotKey('+', HotKeyLevel.normal, () => setScanImageScale(2))

  const scalePercent = scanImageScale * 100
  const zoomIn = scanImageScale === 1
  const hotKeyLabel = zoomIn ? 'Zoom in (+)' : 'Zoom out (-)'
  const fillColor = showToolTip ? primaryColor : borderColor

  return (
    <>
      <ScaleButtons>
        <ZoomButton
          onMouseEnter={() => {
            setShowToolTip(true)
          }}
          onMouseLeave={() => {
            setShowToolTip(false)
          }}
          onClick={() => setScanImageScale(zoomIn ? 2 : 1)}
        >
          <HotKeyToolTip
            forceShow={showToolTip}
            label={hotKeyLabel}
            position={'top'}
            offsetTop={-2.4}
            offsetLeft={0.7}
          />
          <IconWrapper>{zoomIn ? <ZoomInIcon fill={fillColor} /> : <ZoomOutIcon fill={fillColor} />}</IconWrapper>
        </ZoomButton>
      </ScaleButtons>
      <ScanImageWrapper fixed>
        <ScanImage src={src} scaleHeight={scalePercent} scaleWidth={scalePercent} />
      </ScanImageWrapper>
    </>
  )
}

const PDFCustomToolBar = (props: {
  zoom?: number
  setZoom: React.Dispatch<SetStateAction<number | undefined>>
  onZoomApplied?: (zoom: number | undefined) => void
}) => {
  const [zoom, setZoom] = useState<number | undefined>(props.zoom)

  const saveZoom = () => {
    props.onZoomApplied?.(zoom)
    props.setZoom(zoom)
    localStorage.setItem('rx-image:default-zoom', zoom?.toString() ?? '')
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      saveZoom()
    }
  }

  return (
    <CustomToolBarContainer>
      <p style={{ color: 'white' }}>Zoom: </p>
      <div style={{ width: 'fit-content' }}>
        <CustomizedTextField
          type="number"
          size="sm"
          style={{ width: 'auto', height: 30, padding: '0 0.25rem 0 0.75rem' }}
          value={zoom}
          onChange={event => {
            const zoom = event.target.value ? Number(event.target.value) : undefined
            setZoom(zoom)
          }}
          onKeyDown={handleKeyDown}
        />
      </div>

      <Button size="2xs" onClick={saveZoom}>
        Apply
      </Button>
    </CustomToolBarContainer>
  )
}

const PDFRenderer = ({
  src,
  prescriptionId,
  showPDFCustomToolbar,
  wrapperStyle,
  scanPdfStyle,
  zoom,
  setZoom,
}: {
  src: string
  prescriptionId: string
  showPDFCustomToolbar?: boolean
  scanPdfStyle?: FlattenSimpleInterpolation
  wrapperStyle?: FlattenSimpleInterpolation
  zoom?: number
  setZoom: React.Dispatch<SetStateAction<number | undefined>>
}) => {
  const traigeContainer = document.getElementById('TriageIssueContainer')
  const [iframeKey, setIframeKey] = useState(`${prescriptionId}`)
  const pdfAdditionalHeight = traigeContainer?.clientHeight ?? 0
  const imageSource = `${src}#zoom=${showPDFCustomToolbar ? zoom : 100}&toolbar=${showPDFCustomToolbar ? 0 : 1}`

  if (!src) {
    return <p> unable to retrieve rxImage at this time </p>
  }

  return (
    <ScanImageWrapper styles={wrapperStyle} fixed={!showPDFCustomToolbar}>
      {showPDFCustomToolbar && (
        <PDFCustomToolBar
          zoom={zoom}
          setZoom={setZoom}
          onZoomApplied={() => {
            setIframeKey(`${prescriptionId}-${zoom}`)
          }}
        />
      )}
      <ScanPDF key={iframeKey} styles={scanPdfStyle} src={imageSource} additionalHeight={pdfAdditionalHeight} />
    </ScanImageWrapper>
  )
}

const RXScanImage = ({
  src,
  inline,
  prescription,
  scanPdfStyle,
  wrapperStyle,
  showPDFCustomToolbar = false,
}: RXScanImageProps): JSX.Element => {
  const defaultZoom = localStorage.getItem('rx-image:default-zoom')
    ? Number(localStorage.getItem('rx-image:default-zoom'))
    : 100
  const [zoom, setZoom] = useState<number | undefined>(defaultZoom)

  const imageSource = src || prescription?.rxImage?.s3SignedUrl

  if (!imageSource) {
    return <NoSource inline={inline} />
  }

  const isPDF = imageSource.toLowerCase().includes('.pdf')

  if (isPDF) {
    return (
      <ScanImageAnchor fixed={!inline}>
        <ScanImageContainer>
          <PDFRenderer
            src={imageSource}
            showPDFCustomToolbar={showPDFCustomToolbar}
            prescriptionId={prescription?._id ?? ''}
            scanPdfStyle={scanPdfStyle}
            wrapperStyle={wrapperStyle}
            zoom={zoom}
            setZoom={setZoom}
          />
        </ScanImageContainer>
      </ScanImageAnchor>
    )
  }

  return (
    <ScanImageAnchor fixed={!inline}>
      <ScanImageContainer>
        <ImageRenderer src={imageSource} />
      </ScanImageContainer>
    </ScanImageAnchor>
  )
}

const ScanImageWrapper = styled.div<{ styles?: FlattenSimpleInterpolation; fixed?: boolean }>`
  display: ${({ fixed }) => (fixed ? 'fixed' : 'block')}
  overflow: auto;
  height: 100%;
  width: 24.77rem;
  margin-top: 0.5rem;
  @media screen and (min-width: 1380px) {
    width: 30vw;
  }
  @media screen and (min-width: 2400px) {
    width: 31.5vw;
  }
  ${({ styles }) => styles}
`

const ScanImageContainer = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const ScanImageAnchor = styled.div<{ fixed?: boolean }>`
  background-color: ${primaryBackgroundColor};
  overflow: auto;
  ${({ fixed }) => fixed && 'position: fixed; height: calc(100vh - 16rem); width: 30.6vw; overflow: visible;'}
  z-index: 1;
  margin-left: 1px;
  margin-right: 1px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`

const ScaleButtons = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
`

const ScanPDF = styled.iframe<{
  styles?: FlattenSimpleInterpolation
  additionalHeight?: number
}>`
  vertical-align: bottom;
  width: 24.76rem;
  ${({ additionalHeight }) =>
    additionalHeight ? `height: calc(100vh - ${additionalHeight}px - 23.25rem);` : ' height: calc(100vh - 20rem);'}
  @media screen and (min-width: 1380px) {
    width: 30vw;
  }
  @media screen and (min-width: 2400px) {
    width: 31.5vw;
  }
  ${({ styles }) => styles}
`

const ErrorText = styled.p`
  margin: 0.625rem;
`

const ScanImage = styled.img<{ scaleHeight: number; scaleWidth: number }>`
  vertical-align: bottom;
  height: auto;
  width: ${({ scaleWidth }) => scaleWidth}%;
`

const ZoomButton = styled.button`
  border: none;
  background-color: ${primaryBackgroundColor};
`

const CustomToolBarContainer = styled.div`
  background: black;
  height: 45px;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0 1rem;
`

export default RXScanImage
