import { useState } from 'react'
import { Select, TextField } from '@truepill/react-capsule'
import { useMutation } from '@truepill/tpos-react-router'
import type { EntityId } from '@truepill/tpos-types'
import { OutboundTransferTriageReason } from '@truepill/tpos-types'
import { ReactComponent as DangerIcon } from 'assets/icons/danger.svg'
import { SaveButton } from 'components/ActionButton'
import IconWrapper from 'components/IconWrapper'
import { ModalHeader, ModalWrapper } from 'components/Modal'
import { CancelButton } from 'components/PageStructure'
import { SET_OUTBOUND_TRASNFER_IN_TRIAGE } from 'gql'
import useErrorToast from 'hooks/toast/useErrorToast'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import styled from 'styled-components'
import { bodyPrimaryColor, borderColor } from 'styles/styleVariables'

const TriageModal = ({ outboundTransferId }: { outboundTransferId: EntityId }): JSX.Element => {
  const { dismissModal } = useModalContext()

  const [reasonSelected, setReasonSelected] = useState('')
  const [userMessage, setUserMessage] = useState('')

  const [setInTriage] = useMutation(SET_OUTBOUND_TRASNFER_IN_TRIAGE, {
    onCompleted: data => {
      showSuccessToast('Operation is successful')
      dismissModal()
    },
    refetchQueries: ['getBasicOutboundTransfer'],
    onError: error => {
      console.error('Failed to set triage:', error)
      showErrorToast(`Failed to set triage: ${error?.message ?? error}`)
      dismissModal()
    },
  })

  const showErrorToast = useErrorToast()
  const showSuccessToast = useSuccessToast()

  return (
    <ModalWrapper id="TriageModal">
      <ModalHeader>
        <IconWrapper>
          <DangerIcon fill={bodyPrimaryColor} />
        </IconWrapper>
        <h2>Triage reason</h2>
      </ModalHeader>
      <InputContainer>
        <Select
          data-testid="reason"
          label="Triage reason"
          value={reasonSelected}
          placeholder="Select a triage reason..."
          options={Object.values(OutboundTransferTriageReason)}
          onChange={setReasonSelected as (change?: unknown) => void}
        />
        <TextField
          type="textarea"
          label="Message"
          data-testid="message"
          onChange={event => {
            setUserMessage(event.target.value)
          }}
          placeholder="Type a message..."
          value={userMessage}
        />
      </InputContainer>
      <ButtonsContainer>
        <CancelButton
          label="Cancel"
          onClick={() => {
            dismissModal()
          }}
        />
        <SaveButton
          isModal
          disabled={!reasonSelected}
          label="Submit"
          onClick={() => {
            setInTriage({
              variables: {
                outboundTransferId,
                triages: [
                  {
                    reason: reasonSelected,
                    message: userMessage,
                  },
                ],
              },
            })
          }}
        />
      </ButtonsContainer>
    </ModalWrapper>
  )
}

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1.25rem;
`

const InputContainer = styled.div`
  width: 24rem;
  margin-top: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  > textarea {
    padding-top: 0.3125rem;
    height: 7rem;
    margin-top: 0.625rem;
    b  order: 0.125rem solid ${borderColor};
  }
`

export default TriageModal
