import ErrorBoundary from 'components/ErrorBoundary'
import type { ChildProps } from 'types'
import ModalProvider from './ModalProvider'
import ToastProvider from './ToastProvider'

export default function OverlayProviders({ children }: ChildProps): JSX.Element {
  return (
    <ErrorBoundary>
      <ModalProvider>
        <ToastProvider>{children}</ToastProvider>
      </ModalProvider>
    </ErrorBoundary>
  )
}
