import { useEffect, useState } from 'react'
import { CheckBox } from 'grommet'
import useFulfillmentQueue, { FulfillmentQueueName } from 'hooks/useFulfillmentQueue'
import type { Selectable } from 'providers/SelectionProvider'
import { useSelectionContext } from 'providers/SelectionProvider'
import { usePlusClient } from 'providers/VisionRouter'
import type { Order, Fill } from 'types'

type SelectItem = { _id: string; fill?: Fill; order: Order } | null
type QueueSelectedCheckboxProps = { items: Order[]; disabled?: boolean }

const QueueSelectedCheckbox = ({ items, disabled = false }: QueueSelectedCheckboxProps): JSX.Element => {
  const fulfillmentQueue = useFulfillmentQueue()
  const { bulkSetSelections, initSelections, totalSelectableItems, currentSelections = [] } = useSelectionContext()
  const [isChecked, setChecked] = useState(false)
  const {
    tokenContext: { isLeadPharmacist, isPharmacist, isTechnician },
  } = usePlusClient()

  useEffect(() => {
    setChecked(currentSelections.length === totalSelectableItems)
  }, [currentSelections, totalSelectableItems])

  if (
    fulfillmentQueue?.name === FulfillmentQueueName.Fill ||
    fulfillmentQueue?.name === FulfillmentQueueName.Automation ||
    fulfillmentQueue?.name === FulfillmentQueueName.Packing
  ) {
    return (
      <CheckBox
        checked={isChecked}
        disabled={disabled}
        onChange={event => {
          if (event.target.checked) {
            const selectMe = items
              .flatMap<SelectItem>(item => {
                if (fulfillmentQueue?.name === FulfillmentQueueName.Fill) {
                  return item.rxFillRequests.map(rxf => {
                    if ((rxf.fill?.labelsPrinted || 0) > 0 && !isLeadPharmacist() && !isPharmacist() && !isTechnician)
                      return null

                    return { _id: rxf.fillId, fill: rxf.fill, order: item }
                  })
                } else if (fulfillmentQueue?.name === FulfillmentQueueName.Automation) {
                  return item.rxFillRequests.map(rxf => ({ _id: rxf.fillId, fill: rxf.fill, order: item }))
                } else if (fulfillmentQueue?.name === FulfillmentQueueName.Packing) {
                  return [{ _id: item._id, order: item }]
                } else return null
              })
              .filter(n => !!n)
            bulkSetSelections(selectMe as Selectable[])
          } else {
            initSelections([])
          }

          setChecked(event.target.checked)
        }}
      />
    )
  }

  return <></>
}

export default QueueSelectedCheckbox
