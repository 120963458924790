import { ReactComponent as RobotIcon } from 'assets/icons/robot.svg'
import IconWrapper from 'components/IconWrapper'
import IconTile from 'components/Tiles/IconTile'
import Lozenge from 'components/Tiles/Lozenge'
import styled from 'styled-components'
import { subduedColor, bodyPrimaryColor } from 'styles/styleVariables'
import type { MachineOptions } from 'types'

type RobotTileProps = { status?: string; machine?: boolean | MachineOptions; lozenge?: boolean }

const RobotTile = ({ status, machine, lozenge }: RobotTileProps): JSX.Element => {
  const isRobot = status === 'Automation' && !!machine

  if (!isRobot) {
    return <></>
  }

  const Wrapper = lozenge ? StyledRobotLozenge : IconTile

  const labelText = 'ROBOT'

  // Add a tooltip and accessibility hint to the icon, but only if not showing lozenge text
  const iconAttrs = { role: 'img', title: lozenge ? undefined : labelText, 'aria-hidden': lozenge ? true : undefined }

  return (
    <Wrapper color={subduedColor} data-testid="robot">
      <IconWrapper {...iconAttrs}>
        <RobotIcon fill={bodyPrimaryColor} />
      </IconWrapper>
      {lozenge && <p>{labelText}</p>}
    </Wrapper>
  )
}

const StyledRobotLozenge = styled(Lozenge)`
  padding-left: 0.25rem;
  text-transform: uppercase;
  background-color: ${subduedColor};
  color: ${bodyPrimaryColor};
  > p {
    margin-left: 0.3rem;
  }
`

export default RobotTile
