import { ListRowLabel, ListRowValue, RightFilledHeader, ListRowEyeButton } from 'components/RXTable'
import type { EdiReversalOverrides } from 'types'
import { RxEdiListRow, RxEdiRowContainer, RxEdiTitleRow, RxTextInput } from '..'
import SuppressFieldButton from '../../SuppressFieldButton'

type InsuranceSegmentEditReversalProps = {
  ediReversalOverrides: EdiReversalOverrides
  updateEdiReversalOverrides: (input: EdiReversalOverrides) => void
}

const InsuranceSegmentEditReversal = ({
  ediReversalOverrides,
  updateEdiReversalOverrides,
}: InsuranceSegmentEditReversalProps): JSX.Element => {
  const { insurance } = ediReversalOverrides

  const cardholderId = insurance?.cardholderId
  const groupId = insurance?.groupId
  const medigapId = insurance?.medigapId

  const hideCardholderIdField = cardholderId?.send === false
  const hideGroupIdField = groupId?.send === false
  const hideMedigapIdField = medigapId?.send === false

  // These toggle functions handle the state of SupressFieldButton when you click on it
  // and the true/false field we are sending to the backend
  const toggleIsCardholderIdFieldDisabled = () => {
    updateEdiReversalOverrides({
      insurance: {
        cardholderId: { value: cardholderId?.value ?? '', send: hideCardholderIdField },
      },
    })
  }
  const toggleIsGroupIdFieldDisabled = () => {
    updateEdiReversalOverrides({
      insurance: {
        groupId: { value: groupId?.value ?? '', send: hideGroupIdField },
      },
    })
  }
  const toggleIsMedigapIdFieldDisabled = () => {
    updateEdiReversalOverrides({
      insurance: {
        medigapId: { value: medigapId?.value ?? '', send: hideMedigapIdField },
      },
    })
  }

  return (
    <>
      <InsuranceHeaderRow />
      <RxEdiListRow>
        <RxEdiRowContainer>
          <ListRowLabel>Cardholder ID (C2):</ListRowLabel>
          <ListRowValue>
            <RxTextInput
              value={cardholderId?.value ?? ''}
              onChange={({ target: { value: cardholderId } }) =>
                updateEdiReversalOverrides({
                  insurance: { cardholderId: { value: cardholderId, send: true } },
                })
              }
              placeholder="Cardholder ID..."
              disabled={hideCardholderIdField}
            />
          </ListRowValue>
          <ListRowEyeButton>
            <SuppressFieldButton onClick={toggleIsCardholderIdFieldDisabled} hideField={hideCardholderIdField} />
          </ListRowEyeButton>
        </RxEdiRowContainer>
      </RxEdiListRow>

      <RxEdiListRow>
        <RxEdiRowContainer>
          <ListRowLabel>Group ID (C1):</ListRowLabel>
          <ListRowValue>
            <RxTextInput
              value={groupId?.value ?? ''}
              onChange={({ target: { value: groupId } }) =>
                updateEdiReversalOverrides({ insurance: { groupId: { value: groupId, send: true } } })
              }
              placeholder="Group ID..."
              disabled={hideGroupIdField}
            />
          </ListRowValue>
          <ListRowEyeButton>
            <SuppressFieldButton onClick={toggleIsGroupIdFieldDisabled} hideField={hideGroupIdField} />
          </ListRowEyeButton>
        </RxEdiRowContainer>
      </RxEdiListRow>

      <RxEdiListRow>
        <RxEdiRowContainer>
          <ListRowLabel>Medigap ID (2A)</ListRowLabel>
          <ListRowValue>
            <RxTextInput
              value={medigapId?.value ?? ''}
              onChange={({ target: { value: medigapId } }) =>
                updateEdiReversalOverrides({
                  insurance: { medigapId: { value: medigapId, send: true } },
                })
              }
              placeholder="Medigap ID..."
              disabled={hideMedigapIdField}
            />
          </ListRowValue>
          <ListRowEyeButton>
            <SuppressFieldButton onClick={toggleIsMedigapIdFieldDisabled} hideField={hideMedigapIdField} />
          </ListRowEyeButton>
        </RxEdiRowContainer>
      </RxEdiListRow>
    </>
  )
}

const InsuranceHeaderRow = () => {
  return (
    <RxEdiTitleRow>
      <RightFilledHeader>Insurance segment</RightFilledHeader>
    </RxEdiTitleRow>
  )
}

export default InsuranceSegmentEditReversal
