import type { InsuranceData } from '@truepill/tpos-types'
import { InsuranceDesignationType } from '@truepill/tpos-types'

export interface Insurance extends InsuranceData {
  orderInsuranceOptionId?: string
}

interface PositionChange {
  oldValue: string
  newValue: string
  type: string
}

const INSURANCE_TYPES = [
  InsuranceDesignationType.Primary,
  InsuranceDesignationType.Secondary,
  InsuranceDesignationType.Tertiary,
]

const findInsurancesPositionChanges = (
  firstInsurances: Insurance[],
  secondInsurances: Insurance[],
  key = 'orderInsuranceOptionId',
): PositionChange[] => {
  const changes: PositionChange[] = []

  firstInsurances.forEach((insurance, index) => {
    const newIndex = secondInsurances.findIndex(
      item => item[key as keyof Insurance]?.toString() === insurance[key as keyof Insurance]?.toString(),
    )
    if (newIndex !== index) {
      changes.push({
        newValue: secondInsurances[index]?.bin
          ? JSON.stringify({
              bin: secondInsurances[index].bin,
              pcn: secondInsurances[index].pcn,
              group: secondInsurances[index].group,
              cardholderId: secondInsurances[index].cardholderId,
              relationship: secondInsurances[index].relationship,
            })
          : '',
        oldValue: firstInsurances[index]?.bin
          ? JSON.stringify({
              bin: firstInsurances[index].bin,
              pcn: firstInsurances[index].pcn,
              group: firstInsurances[index].group,
              cardholderId: firstInsurances[index].cardholderId,
              relationship: firstInsurances[index].relationship,
            })
          : '',
        type: INSURANCE_TYPES[index] ?? 'Other',
      })
    }
  })

  return changes
}

export default findInsurancesPositionChanges
