export enum Languages {
  en = 'English',
  es = 'Spanish',
}

export type Language = keyof typeof Languages

export const DEFAULT_LANGUAGE = 'en' as Language

export const SUPPORTED_LANGUAGES = Object.keys(Languages) as Language[]
