import { useMemo } from 'react'
import { floatSafeRemainder } from 'utils'
import type { NDCValidationInfo } from './useNdcValidationInfo'

export enum ValidateQuantityContexts {
  ChangeDispensed = 'ChangeDispensed',
  TransferOut = 'TransferOut',
}

export enum QuantityErrors {
  QuantityRequired = 'QuantityRequired',
  NaN = 'NaN',
  TransferQtyTooHigh = 'TransferQtyTooHigh',
  DispenseQtyTooHigh = 'DispenseQtyTooHigh',
  DispenseWholePackage = 'DispenseWholePackage',
  WholeNumber = 'WholeNumber',
}

export const useValidateQuantity = (
  quantity: number | '.',
  quantityRemaining: number,
  ndcValidationInfo: NDCValidationInfo,
  context?: ValidateQuantityContexts,
): [boolean, string | undefined, QuantityErrors | undefined] => {
  const validationInfo = useMemo<[boolean, string | undefined, QuantityErrors | undefined]>(() => {
    if (!quantity || quantity === '.' || quantity < 0) {
      return [true, 'Quantity is a required field', QuantityErrors.QuantityRequired]
    }
    if (typeof quantity !== 'number' || isNaN(quantity)) {
      return [true, 'Quantity must be a number', QuantityErrors.NaN]
    }

    if (context === ValidateQuantityContexts.TransferOut && (quantity || 0) > (quantityRemaining || 0)) {
      return [true, 'To transfer qty can not be greater than remaining qty.', QuantityErrors.TransferQtyTooHigh]
    }
    if (context === ValidateQuantityContexts.ChangeDispensed && quantity > quantityRemaining) {
      return [true, 'Dispensed qty is greater than qty remaining', QuantityErrors.DispenseQtyTooHigh]
    }

    const { requiresUnitDosePackaging, canUseDecimalQty, packageSize } = ndcValidationInfo

    if (requiresUnitDosePackaging && floatSafeRemainder(quantity, packageSize) !== 0) {
      return [
        true,
        `Qty can only be dispensed in multiples of pack qty' (${packageSize})`,
        QuantityErrors.DispenseWholePackage,
      ]
    }
    if (!canUseDecimalQty && !Number.isInteger(quantity)) {
      return [
        true,
        `Qty must be a whole number when unit of measure is not grams or milliliters`,
        QuantityErrors.WholeNumber,
      ]
    }

    return [false, undefined, undefined]
  }, [ndcValidationInfo, quantityRemaining, quantity, context])

  return validationInfo
}

export default useValidateQuantity
