import type { RefObject } from 'react'
import { useEffect } from 'react'

// shouldCapture prevents handleClick from being called when child element gets rerendered (https://github.com/facebook/react/issues/20325)
const useClickOutside = (ref: RefObject<HTMLElement>, callback: () => void, shouldCapture = false): void => {
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node) && !ref.current.isSameNode(e.target as Node)) {
        callback()
      }
    }
    document.addEventListener('click', handleClick, { capture: shouldCapture })

    return () => {
      document.removeEventListener('click', handleClick, { capture: shouldCapture })
    }
  }, [ref, callback, shouldCapture])
}

export default useClickOutside
