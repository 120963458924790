import type { ClaimPricingResponse, ClaimPricing } from '@truepill/tpos-types'

export const calculateClaimPricingResponseFee = (pricingResponse: ClaimPricingResponse) =>
  +(
    (pricingResponse.dispensingFeePaid ?? 0) +
    (pricingResponse.incentiveAmountPaid ?? 0) +
    (pricingResponse.flatSalesTaxAmountPaid ?? 0) +
    (pricingResponse.percentageSalesTaxAmountPaid ?? 0) +
    (pricingResponse.otherAmountsPaid?.reduce(
      (accumulator, current) => accumulator + (current?.otherAmountPaid ?? 0),
      0,
    ) ?? 0)
  ).toFixed(2)

export const calculateClaimPricingFee = (pricing: ClaimPricing) =>
  +(
    (pricing.dispensingFeeSubmitted ?? 0) +
    (pricing.incentiveAmountSubmitted ?? 0) +
    (pricing.flatSalesTaxSubmitted ?? 0) +
    (pricing.percentageSalesTaxAmountSubmitted ?? 0) +
    (pricing.otherAmountClaims?.reduce(
      (accumulator, current) => accumulator + (current?.otherAmountClaimSubmitted ?? 0),
      0,
    ) ?? 0)
  ).toFixed(2)
