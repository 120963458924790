import { Accordion, AccordionPanel, Box } from 'grommet'
import styled from 'styled-components'
import xmlFormat from 'xml-formatter'

type ApiRequestDetailsProps = { xml: string }

const ApiRequestDetails = ({ xml }: ApiRequestDetailsProps): JSX.Element => {
  // removing formatting characters just in case they exist
  const xmlUnformatted = xml.replace(/\r?\n|\r|\t/g, '')
  const xmlFormatted = xmlFormat(xmlUnformatted)
  return (
    <RawXMLContainer background="light-2" animate={false}>
      <StyledAccordionPanel label="eRx XML">
        <StyledBox pad="small" background="light-2">
          <pre>{xmlFormatted}</pre>
        </StyledBox>
      </StyledAccordionPanel>
    </RawXMLContainer>
  )
}

const RawXMLContainer = styled(Accordion)`
  border-radius: 4px;
  color: #000000;
  font-family: monospace;
  font-weight: 500;
  margin-top: 10px;
  :hover:not(:focus-visible),
  :focus:not(:focus-visible) {
    box-shadow: none;
    outline: none;
  }
  z-index: 0 !important;
`
const StyledAccordionPanel = styled(AccordionPanel)`
  padding: 10px;
`

const StyledBox = styled(Box)`
  overflow-x: scroll;
`

export default ApiRequestDetails
