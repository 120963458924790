import { ReactComponent as EditIcon } from 'assets/icons/edit.svg'
import AddressEntry from 'components/AddressEntry'
import AddressForm from 'components/AddressForm'
import { SmallIconWrapper } from 'components/IconWrapper'
import { EditMode, NormalMode } from 'components/RXPageStructure'
import { ListRowLabel, ListRowValue, RXCell, StyledTextInput } from 'components/RXTable'
import ShippingSelect from 'components/ShippingSelect'
import { useEditMode, useEditValue } from 'hooks'
import EditPaymentTypeModal from 'modals/EditPaymentTypeModal'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import { uniq } from 'ramda'
import styled from 'styled-components'
import { contrastBackgroundColor, contrastColor } from 'styles/styleVariables'
import type { Address, Order, ShippingMethod } from 'types'
import { PaymentType } from 'types'
import {
  boolToHumanReadable,
  shippingMethodToName,
  camelCaseToHumanReadable,
  ShippingLabelStatus,
  getShippingLabelStatus,
} from 'utils'
import { OrderTotalCost } from '.'

interface PaymentAndShippingProps {
  disableOrder: boolean
  order: Order
  paymentType?: PaymentType
  setShippingAddressValues: (a: Address) => void
  setShippingMethod: (sm: ShippingMethod) => void
  setTrackingNumber: (tn: string) => void
  shippingAddressValues?: Address
  shippingMethod?: ShippingMethod
  trackingNumber: string
  condensedView?: boolean
}

const PaymentAndShipping = ({
  disableOrder,
  order,
  paymentType,
  setShippingAddressValues,
  setShippingMethod,
  setTrackingNumber,
  shippingAddressValues,
  shippingMethod,
  trackingNumber,
  condensedView,
}: PaymentAndShippingProps) => {
  const { showModal } = useModalContext()
  const [, setEditValue] = useEditValue()
  const [editMode] = useEditMode()

  const setEditOptions = async (value: string) => {
    setEditValue(value)
  }

  const editValueButton = (editValue: string): JSX.Element => {
    if (disableOrder) return <></>
    return (
      <FloatingEditSpan onClick={() => setEditOptions(editValue)}>
        <SmallIconWrapper>
          <EditIcon fill={contrastColor} />
        </SmallIconWrapper>
      </FloatingEditSpan>
    )
  }

  const trackingNumbers = uniq([
    ...(order.shipments ?? []).map(({ trackingNumber }) => trackingNumber),
    ...(order.otcProducts ?? []).map(({ trackingNumber }) => trackingNumber),
  ]).filter(Boolean)

  const shippingLabelStatus = getShippingLabelStatus(order)

  return (
    <StyledRowWrapper>
      {!condensedView && (
        <RXCell data-test-row="payment-type">
          <ListRowLabel>Payment:</ListRowLabel>
          <NormalMode value={'paymentType'}>
            <ListRowValueBlock>
              {paymentType}
              <FloatingEditSpan
                data-testid="edit-payment-type"
                onClick={() => showModal(() => <EditPaymentTypeModal order={order} />)}
              >
                <SmallIconWrapper>
                  <EditIcon fill={contrastColor} />
                </SmallIconWrapper>
              </FloatingEditSpan>
            </ListRowValueBlock>
          </NormalMode>
        </RXCell>
      )}

      {!condensedView && paymentType !== PaymentType.split && (
        <OrderTotalCost
          bins={order.insurances?.map(ins => ins.bin ?? '')?.filter(ins => !!ins) ?? []}
          paymentType={paymentType ?? PaymentType.cash}
          pricing={order.pricing}
          rxFillRequests={order.rxFillRequests}
        />
      )}

      <RXCell data-test-row="shipping-label">
        <ListRowLabel>Shipping method:</ListRowLabel>
        <ColumnListRowValue>
          <NormalMode value={'shippingMethod'}>
            {order.shippingMethod ? (
              <p>
                {camelCaseToHumanReadable(shippingMethodToName[order.shippingMethod])}
                &nbsp;
                {!editMode && editValueButton('shippingMethod')}
              </p>
            ) : (
              <p>No Shipping Method {!editMode && editValueButton('shippingMethod')}</p>
            )}
          </NormalMode>
          <EditMode value={'shippingMethod'}>
            <ShippingSelect
              value={shippingMethod}
              onSelect={(selectedMethod: ShippingMethod) => {
                setShippingMethod(selectedMethod)
              }}
            />
          </EditMode>
          <NormalMode value={'trackingNumber'}>
            {shippingLabelStatus === ShippingLabelStatus.Ready ? (
              <>
                {trackingNumbers.map((trackingNumber, index) => (
                  <p key={trackingNumber}>
                    {trackingNumber} {index === 0 && editValueButton('trackingNumber')}
                  </p>
                ))}
              </>
            ) : (
              <ShippingStatus status={shippingLabelStatus}>
                No Tracking Number Available {!editMode && editValueButton('trackingNumber')}
              </ShippingStatus>
            )}
          </NormalMode>
          <EditMode value={'trackingNumber'}>
            <StyledTextInput
              value={trackingNumber}
              onChange={({ target: { value } }) => {
                setTrackingNumber(value)
              }}
            />
          </EditMode>
        </ColumnListRowValue>
      </RXCell>

      <RXCell data-test-row="shipping-address">
        <ListRowLabel>Shipping address:</ListRowLabel>
        <NormalMode value={'shippingAddress'}>
          <ListRowValueBlock data-private>
            {!editMode && editValueButton('shippingAddress')}
            <AddressEntry address={order.shippingAddress} />
          </ListRowValueBlock>
        </NormalMode>
        <EditMode value={'shippingAddress'}>
          {shippingAddressValues && (
            <AddressForm
              {...shippingAddressValues}
              onChange={(key: string, value: string) =>
                setShippingAddressValues({
                  ...shippingAddressValues,
                  [key]: value,
                })
              }
            />
          )}
        </EditMode>
      </RXCell>

      <RXCell data-test-row="is-otc">
        <ListRowLabel>OTC:</ListRowLabel>
        <ListRowValue>{boolToHumanReadable(order.otcProducts.length > 0)}</ListRowValue>
      </RXCell>
    </StyledRowWrapper>
  )
}

const ColumnListRowValue = styled(ListRowValue)`
  flex-direction: column;
`

const ListRowValueBlock = styled(ListRowValue)`
  display: block;
`

const FloatingEditSpan = styled.span`
  cursor: pointer;
  float: right;
`

const ShippingStatus = styled.p`
  color: ${(p: { status: ShippingLabelStatus }) => (p.status === ShippingLabelStatus.NotRequested ? 'red' : 'blue')};
`

const StyledRowWrapper = styled.ul`
  > :nth-child(2n) {
    background-color: ${contrastBackgroundColor};
  }
`

export default PaymentAndShipping
