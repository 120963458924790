import { Text } from '@truepill/react-capsule'
import styled, { css } from 'styled-components'
import { capsuleBodySecondaryColor, capsuleContrastColor } from 'styles/styleVariables'

interface TabStyles {
  isCurrentTab?: boolean
}

const StyledText = styled(Text)<TabStyles>`
  font-weight: 500;
  font-size: 1rem;
  font-family: Roboto;
  color: ${({ isCurrentTab }) => (isCurrentTab ? capsuleContrastColor : capsuleBodySecondaryColor)};
`

const StyledTabList = styled.ul`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: column;
  justify-content: flex-start;
  height: 100%;
  margin-bottom: 1.5rem;
  border-bottom: 0.1rem solid #e9ecf0;
`

const StyledLink = styled.li`
  display: flex;
  position: relative;
  top: 0.125rem;
  margin-left: 16px;
  align-items: center;
  display: flex;
  flex-direction: row;
`

const Highlighted = css`
  > svg {
    fill: ${capsuleContrastColor};
  }
  border-bottom: 0.3rem solid ${capsuleContrastColor};
`

const StyledButton = styled.button<TabStyles>`
  background-color: transparent;
  text-decoration: none;
  border: none;
  cursor: pointer;
  padding: 10px 0;
  line-height: 20px;
  :focus {
    outline: none;
  }
  :focus-visible {
    outline: 0.5rem solid ${capsuleContrastColor};
  }
  ${({ isCurrentTab }) => (isCurrentTab ? Highlighted : Normal)}
`

const Normal = css`
  > svg {
    fill: ${capsuleBodySecondaryColor};
  }
  border-bottom: 0.25rem solid transparent;
  border {
    opacity: 0.52;
  }
`

export { StyledText, StyledTabList, StyledLink, StyledButton }
