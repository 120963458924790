import { useState, useEffect } from 'react'
import { useQuery } from '@truepill/tpos-react-router'
import type { ClaimEdiRecieved } from '@truepill/tpos-types'
import { LaunchDarkly, RxFillRequestStatus } from '@truepill/tpos-types'
import DrugImageImprint from 'components/DrugImageImprint'
import FillRequestDetails from 'components/FillRequestDetails'
import { GET_NDC_PACKAGE } from 'components/ReviewPrescription'
import RXScanImage from 'components/RXScanImage'
import {
  ImageFilledHeader,
  ListRowLabel,
  ListRowValue,
  RightFilledHeader,
  RXCenterCell,
  RXCenterColumn,
  RXImageCell,
  RXListRowTwoColumns,
  RXRowTwoColumns,
  RXTitleRow,
  StyledRXImageCell,
  TableContainer,
} from 'components/RXTable'
import { GET_DRUG_IMPRINT } from 'gql'
import { Box } from 'grommet'
import useHotKey, { HotKeyLevel } from 'hooks/useHotKey'
import useRxImageIsPic from 'hooks/useRxImageIsPic'
import moment from 'moment'
import { useClient, useFlag } from 'providers/LaunchDarklyProvider'
import styled from 'styled-components'
import { contrastBackgroundColor } from 'styles/styleVariables'
import type { Fill, Log, NdcFullInfoPackage, Order, Patient, Prescription, RXFillRequest, Weight } from 'types'
import { getPackageMeasureDescription, getRxImagePrescription, convertWeight, getNdcText } from 'utils'
import { isHuman } from 'utils/Patient'
import ReviewSafety from '../../../components/ReviewSafety'

type CompletedContentSectionProps = {
  fill: Fill
  prescription: Prescription
  patient: Patient
  order: Order
  rxFillRequest: RXFillRequest
  orderLogs: Log[]
}

const CompletedContentSection = ({
  order,
  fill,
  patient,
  prescription,
  rxFillRequest,
  orderLogs,
}: CompletedContentSectionProps): JSX.Element => {
  const [rxImageIsPic, setRxImageIsPic] = useRxImageIsPic()
  const { customer, directTransfer, escript } = prescription

  const rxImagePrescription = getRxImagePrescription(escript, directTransfer?.prescription)
  const patientWeight = patient.weight?.value ? convertWeight(patient.weight.value as number) : ({} as Weight)

  useHotKey('x', HotKeyLevel.normal, () => setRxImageIsPic(!rxImageIsPic))

  const { error: drugImprintError, data: drugImprintData } = useQuery(GET_DRUG_IMPRINT, {
    variables: { ndcPackageCode: fill.dispensed.ndc },
  })

  const drugImageImprint = drugImprintData?.getDrugImageImprint
  const ndcs =
    !!fill?.dispensed.ndc && prescription.ndc !== fill.dispensed.ndc
      ? [prescription.ndc, fill.dispensed.ndc]
      : [prescription.ndc]

  const { data } = useQuery(GET_NDC_PACKAGE, {
    variables: { ndcs },
  })

  // Feature flags
  const { client: launchDarklyClient } = useClient()
  const [showCopayCollected, setShowCopayCollected] = useState(false)
  const displayCopayCollected = useFlag(LaunchDarkly.FeatureFlags.DISPLAY_COLLECTED_COPAY_IN_FRONTEND)
  const tempDisplayPackSizeWithNdc = useFlag(LaunchDarkly.FeatureFlags.TEMP_DISPLAY_PACK_SIZE_WITH_NDC)
  const hyphenateNdc = useFlag(LaunchDarkly.FeatureFlags.TEMP_HYPHENATE_NDCS)

  const ndcPackages = data?.getNdcPackage as NdcFullInfoPackage[]
  const prescribedNdcPackage = ndcPackages?.find(ndcPackage => ndcPackage.ndc === prescription.ndc)
  const dispensedNdcPackage = ndcPackages?.find(ndcPackage => ndcPackage?.ndc === fill.dispensed.ndc)

  const prescribedNdcText = getNdcText(prescribedNdcPackage, tempDisplayPackSizeWithNdc, hyphenateNdc)
  const dispensedNdcText = getNdcText(dispensedNdcPackage, tempDisplayPackSizeWithNdc, hyphenateNdc)

  useEffect(() => {
    if (customer?.legacyId && launchDarklyClient) {
      launchDarklyClient.identify({ key: customer.legacyId.toString() })

      setShowCopayCollected(displayCopayCollected)
    }
  }, [launchDarklyClient, customer, displayCopayCollected])

  return (
    <TableContainer>
      <RXTitleRow>
        <StyledRXImageCell noBorder>
          <ImageFilledHeader>Rx image</ImageFilledHeader>
        </StyledRXImageCell>
        <RXCenterColumn>
          <RightFilledHeader>Dispensed medication</RightFilledHeader>
        </RXCenterColumn>
        <RXCenterColumn>
          <RightFilledHeader>Event log</RightFilledHeader>
        </RXCenterColumn>
      </RXTitleRow>
      <Box direction="row">
        <Box basis="2/3" width={{ min: '53rem' }}>
          <RXRowTwoColumns>
            {rxImageIsPic ? (
              <StyledRXImageCell noColorBackground closeOffTopBorder>
                <RXScanImage prescription={prescription} />
              </StyledRXImageCell>
            ) : (
              <>
                <RXImageCell closeOffTopBorder>
                  <ListRowLabel>Name:</ListRowLabel>
                  <ListRowValue>
                    {patient?.firstName} {patient?.lastName}
                  </ListRowValue>
                </RXImageCell>
                <RXCenterCell>
                  <ListRowLabel>Name:</ListRowLabel>
                  <ListRowValue>
                    {patient?.firstName} {patient?.lastName}
                  </ListRowValue>
                </RXCenterCell>
              </>
            )}
          </RXRowTwoColumns>
          {!isHuman(patient) && (
            <>
              <RXListRowTwoColumns>
                <RXImageCell>
                  <ListRowLabel>Species:</ListRowLabel>
                  <ListRowValue>{patient?.species}</ListRowValue>
                </RXImageCell>
                <RXCenterCell>
                  <ListRowLabel>Species:</ListRowLabel>
                  <ListRowValue>{patient?.species}</ListRowValue>
                </RXCenterCell>
              </RXListRowTwoColumns>
              <RXListRowTwoColumns>
                <RXImageCell>
                  <ListRowLabel>Guardian:</ListRowLabel>
                  <ListRowValue>{patient?.guardian}</ListRowValue>
                </RXImageCell>
                <RXCenterCell>
                  <ListRowLabel>Guardian:</ListRowLabel>
                  <ListRowValue>{patient?.guardian}</ListRowValue>
                </RXCenterCell>
              </RXListRowTwoColumns>
            </>
          )}
          {patient?.weight?.value && (
            <RXListRowTwoColumns>
              <RXImageCell>
                <ListRowLabel>Weight:</ListRowLabel>
                <ListRowValue>{`${patientWeight.kg} kg / ${patientWeight.lb} lb`}</ListRowValue>
              </RXImageCell>
              <RXCenterCell>
                <ListRowLabel>Weight:</ListRowLabel>
                <ListRowValue>{`${patientWeight.kg} kg / ${patientWeight.lb} lb`}</ListRowValue>
              </RXCenterCell>
            </RXListRowTwoColumns>
          )}
          <RXListRowTwoColumns>
            <RXImageCell>
              <ListRowLabel>Medication:</ListRowLabel>
              <ListRowValue>{rxImagePrescription?.drugDescription ?? prescription.name}</ListRowValue>
            </RXImageCell>
            <RXCenterCell>
              <ListRowLabel>Medication:</ListRowLabel>
              <ListRowValue>{fill.dispensed.name}</ListRowValue>
            </RXCenterCell>
          </RXListRowTwoColumns>
          <RXListRowTwoColumns>
            <RXImageCell>
              <ListRowLabel>NDC:</ListRowLabel>
              <ListRowValue>{prescribedNdcText}</ListRowValue>
            </RXImageCell>
            <RXCenterCell>
              <ListRowLabel>NDC:</ListRowLabel>
              <ListRowValue>{dispensedNdcText}</ListRowValue>
            </RXCenterCell>
          </RXListRowTwoColumns>
          <RXListRowTwoColumns>
            <RXImageCell>
              <ListRowLabel />
              <ListRowValue />
            </RXImageCell>
            <RXCenterCell>
              <ListRowLabel>Mfg:</ListRowLabel>
              <ListRowValue>{fill.dispensed.manufacturer}</ListRowValue>
            </RXCenterCell>
          </RXListRowTwoColumns>
          <RXListRowTwoColumns>
            <RXImageCell>
              <ListRowLabel>Qty:</ListRowLabel>
              <ListRowValue>{rxImagePrescription?.quantity ?? prescription.quantity}</ListRowValue>
            </RXImageCell>
            <RXCenterCell>
              <ListRowLabel>Qty:</ListRowLabel>
              <ListRowValue>
                {fill.dispensed.quantity} {getPackageMeasureDescription(fill?.dispensed?.packageSizeUnitOfMeasure)}
              </ListRowValue>
            </RXCenterCell>
          </RXListRowTwoColumns>
          <RXListRowTwoColumns>
            <RXImageCell>
              <ListRowLabel>DS:</ListRowLabel>
              <ListRowValue>{rxImagePrescription?.daysSupply ?? prescription.daysSupply}</ListRowValue>
            </RXImageCell>
            <RXCenterCell>
              <ListRowLabel>DS:</ListRowLabel>
              <ListRowValue>{fill.dispensed.daysSupply}</ListRowValue>
            </RXCenterCell>
          </RXListRowTwoColumns>
          <RXListRowTwoColumns>
            <RXImageCell />
            <RXCenterCell>
              <ListRowLabel>Labels printed:</ListRowLabel>
              <ListRowValue>{fill.labelsPrinted}</ListRowValue>
            </RXCenterCell>
          </RXListRowTwoColumns>
          <DrugImageImprint ndc={fill.dispensed.ndc} hasTwoColumns={true} />
          <RXListRowTwoColumns>
            <RXImageCell />
            <RXCenterCell>
              <ListRowLabel>Robot image:</ListRowLabel>
              {rxFillRequest.machine?.vials ? (
                rxFillRequest.machine?.vials.map(i => (
                  <>
                    <VialHeader>Vial {i.number}</VialHeader>
                    <VialImageWrapper>
                      <img alt={'drug'} src={`data:image/jpeg;base64, ${i.image}`} />
                    </VialImageWrapper>
                  </>
                ))
              ) : (
                <VialImageWrapper>
                  <ListRowValue>No image available</ListRowValue>
                </VialImageWrapper>
              )}
            </RXCenterCell>
          </RXListRowTwoColumns>
        </Box>
        <Box basis="1/3" overflow="auto">
          <EventLog
            height={drugImprintError || drugImageImprint?.image === 'null' ? '14rem' : '30rem'}
            logs={orderLogs}
            createdAt={fill?.createdAt}
            fillId={fill._id}
            prescriptionId={prescription?._id}
          />
        </Box>
      </Box>
      <ReviewSafety
        order={order}
        fill={fill}
        rxFillRequest={rxFillRequest}
        prescription={prescription}
        initialDisclosed={true}
      />
      <RXTitleRow>
        <RXCenterColumn />
        <RXCenterColumn />
        <RXCenterColumn>
          <RightFilledHeader>Fill request</RightFilledHeader>
        </RXCenterColumn>
      </RXTitleRow>
      <Box direction="row">
        <Box basis="2/3" />
        <Box basis="1/3">
          <FillRequestDetails
            fill={fill}
            order={order}
            prescription={prescription}
            rxFillRequest={rxFillRequest}
            showCopayCollected={showCopayCollected}
          />
        </Box>
      </Box>
    </TableContainer>
  )
}

type EventLogProps = {
  height?: string
  logs?: Log[]
  createdAt: Fill['createdAt']
  fillId: Fill['_id']
  prescriptionId?: Prescription['_id']
}

const EventLog = ({ height, logs, createdAt, fillId, prescriptionId }: EventLogProps): JSX.Element => {
  if (!logs) {
    return <></>
  }

  if (!createdAt) {
    return <></>
  }

  if (fillId === undefined && prescriptionId === undefined) {
    console.warn('Attempted to load Log component without FillId or PrescriptionId')
    return <></>
  }

  const logEvent =
    logs?.filter(
      (log: Log) =>
        log.message !== 'status change based on fill requests' &&
        ((!log.fillId && !log.prescriptionId) ||
          !log.change ||
          log.fillId === fillId ||
          log.prescriptionId === prescriptionId ||
          (!fillId && !prescriptionId)),
    ) || []

  logEvent.sort((logA: Log, logB: Log) => {
    return new Date(logA.createdAt)?.getTime() - new Date(logB.createdAt)?.getTime()
  })

  return (
    <Box height={height}>
      <StyledRXCenterCell>
        <ListRowLabel>Created:</ListRowLabel>
        <ListLogValue>{`${createdAt && moment.utc(createdAt).format('MM/DD/YYYY hh:mmA')}`}</ListLogValue>
      </StyledRXCenterCell>
      {logEvent.map(log => {
        const user = log.user
        const logDate = moment(log.createdAt).format('MM/DD/YYYY hh:mmA')
        const message = log.message
        const messageTrimmed = message && (message.endsWith(':') ? message.slice(0, -1) : message)
        const dateFillCreated = Number(new Date(createdAt))
        const dateCompleted = log?.change?.newValue === RxFillRequestStatus.Complete && parseInt(log.createdAt)

        const hours = dateCompleted ? (dateCompleted - dateFillCreated) / 36e5 : 0
        const rhours = hours && Math.floor(hours)
        const rminutes =
          hours < 1 ? hours && Math.round(hours && hours * 60) : hours && rhours && Math.round((hours - rhours) * 60)
        const hoursMins = rhours + 'h ' + rminutes + 'm'
        const days = hours && Math.ceil(hours / 24)
        const greaterThan48 = hours && hours > 48 ? `(${days} days)` : `(${hoursMins})`

        return (
          <>
            {log.event === 'verification' && log?.change?.oldValue === RxFillRequestStatus.PV1 && (
              <StyledRXCenterCell>
                <ListRowLabel>PV1:</ListRowLabel>
                <ListLogValue>
                  <span>
                    {user?.firstName} {user?.lastName}
                  </span>
                  -<span>{logDate}</span>
                </ListLogValue>
              </StyledRXCenterCell>
            )}
            {log.event === 'change' && log?.change?.field === 'inTriage' && log?.change?.newValue === 'true' && (
              <StyledRXCenterCell>
                <ListRowLabel>Triage:</ListRowLabel>
                <InTriageLog>
                  <p>
                    <span>
                      {user?.firstName} {user?.lastName}
                    </span>
                    -<span>{logDate}</span>
                  </p>
                  <p>{messageTrimmed}</p>
                </InTriageLog>
              </StyledRXCenterCell>
            )}
            {isAdjudicationEvent(log) ? (
              <StyledRXCenterCell>
                <ListRowLabel>Adjudication:</ListRowLabel>
                <ListLogValue>
                  <span>
                    {user?.firstName} {user?.lastName}
                  </span>
                  -<span>{logDate}</span>
                </ListLogValue>
              </StyledRXCenterCell>
            ) : null}
            {log.event === 'change' && log?.change?.oldValue === RxFillRequestStatus.Fill && (
              <StyledRXCenterCell>
                <ListRowLabel>Fill:</ListRowLabel>
                <ListLogValue>
                  <span>
                    {user?.firstName} {user?.lastName}
                  </span>
                  -<span>{logDate}</span>
                </ListLogValue>
              </StyledRXCenterCell>
            )}
            {log.event === 'change' && log?.change?.field === 'ndc' && (
              <StyledRXCenterCell>
                <ListRowLabel>Change dispensed item:</ListRowLabel>
                <ListLogValue>
                  <span>
                    {user?.firstName} {user?.lastName}
                  </span>
                  -<span>{logDate}</span>
                </ListLogValue>
              </StyledRXCenterCell>
            )}
            {log.event === 'verification' && log?.change?.oldValue === RxFillRequestStatus.PV2 && (
              <StyledRXCenterCell>
                <ListRowLabel>PV2:</ListRowLabel>
                <ListLogValue>
                  <span>
                    {user?.firstName} {user?.lastName}
                  </span>
                  -<span>{logDate}</span>
                </ListLogValue>
              </StyledRXCenterCell>
            )}
            {log.event === 'verification' && log?.change?.newValue === RxFillRequestStatus.Complete && (
              <StyledRXCenterCell>
                <ListRowLabel>Completed:</ListRowLabel>
                <ListLogValue>
                  <span>
                    {user?.firstName} {user?.lastName}
                  </span>
                  -
                  <span>
                    {logDate} {greaterThan48}
                  </span>
                </ListLogValue>
              </StyledRXCenterCell>
            )}
          </>
        )
      })}
    </Box>
  )
}

/*
// Checks if a given Log is an Adjudication event.
*/
const isAdjudicationEvent: (log: Log) => boolean = (log: Log) => {
  if (log.event !== 'claim' || !log.change?.newValue) {
    return false
  }
  try {
    const parsedInsuranceResponse: ClaimEdiRecieved = JSON.parse(log.change.newValue)
    // Check for the "P" transaction status, AKA "Paid".
    // If no groups are defined, this is not an adjudication event so return false.
    return parsedInsuranceResponse?.groups?.some(group => group?.response.transactionStatus === 'P') ?? false
  } catch {
    return false
  }
}

const ListLogValue = styled(ListRowValue)`
  span {
    margin: 0 0.5rem;
    text-transform: capitalize;
    :first-child {
      margin-left: 0;
    }
  }
  p {
    width: 100%;
    :first-letter {
      text-transform: capitalize;
    }
  }
`

const InTriageLog = styled(ListLogValue)`
  display: flex;
  flex-wrap: wrap;
`

const VialHeader = styled(ListRowValue)`
  padding-left: 1.2rem;
  padding-top: 5px;
`

const VialImageWrapper = styled.div`
  flex: 1;
  > img {
    object-fit: scale-down;
    max-width: 100%;
    border-radius: 4px;
  }
`

const StyledRXCenterCell = styled(RXCenterCell)`
  margin-left: 1rem;
  padding-left: 1rem;
  :nth-of-type(even) {
    background-color: ${contrastBackgroundColor};
  }
`

export default CompletedContentSection
