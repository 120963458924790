import type { RefObject } from 'react'
import { useRef, useEffect } from 'react'

interface FocusableElement {
  focus: () => void
}

const useAutoFocus = (): RefObject<FocusableElement> => {
  const elRef = useRef<FocusableElement>(null)

  useEffect(() => {
    // Chucking a 0 second timeout prevents the ref from failing to catch with some
    // grommet components
    setTimeout(() => {
      if (elRef.current !== null) {
        // Yep. Don't be using this hook with non focus compliant elements ya hear?
        const inputEl = elRef.current
        inputEl.focus()
      }
    }, 0)
  }, [elRef])

  return elRef
}

export default useAutoFocus
