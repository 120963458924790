import { ReactComponent as ColumnIcon } from 'assets/icons/column.svg'
import Select from 'components/Select'
import type { FulfillmentQueueName } from 'hooks/useFulfillmentQueue'
import { FULFILLMENT_QUEUES } from 'hooks/useFulfillmentQueue'
import useQueryParamBoolean from 'hooks/useQueryParamBoolean'
import { usePlusClient } from 'providers/VisionRouter'

type QueueSelectorDropdownProps = { queue?: FulfillmentQueueName }

const QueueSelectorDropdown = ({ queue }: QueueSelectorDropdownProps): JSX.Element => {
  const {
    currentLocation: { queryMap },
    routeTo,
    tokenContext,
  } = usePlusClient()
  const [otcOnlyControl] = useQueryParamBoolean('otcOnly')

  return (
    <Select
      data-testid="queues"
      fadePlaceholderText={false}
      hotKey="q"
      value={queue}
      options={Object.values(FULFILLMENT_QUEUES)
        .filter(({ hidden }) => !hidden)
        .map(({ name }) => ({ label: name, value: name }))}
      placeholder="Queues"
      icon={<ColumnIcon />}
      onChange={([option]) => {
        if (queue === option?.label) {
          return
        }
        if (option) {
          routeTo
            .fulfillmentQueue(option?.label as FulfillmentQueueName, {
              searchMap: {
                ...(queryMap.states?.length && { states: queryMap?.states }),
                ...(queryMap.customerIds?.length && { customerIds: queryMap.customerIds }),
                ...(queryMap.noStates && { states: [] }),
                locationId: queryMap.locationId || tokenContext?.locationId,
                otcOnly: otcOnlyControl,
              },
            })
            .now()
        }
        // If there is no option value that means the select was cleared so we need to clear the filters as well
        else {
          routeTo
            .fulfillmentQueue('all' as FulfillmentQueueName, {
              searchMap: {
                ...(queryMap.states?.length && { states: queryMap?.states }),
                ...(queryMap.customerIds?.length && { customerIds: queryMap.customerIds }),
                ...(queryMap.noStates && { states: [] }),
                locationId: tokenContext?.locationId,
                otcOnly: otcOnlyControl,
              },
            })
            .now()
        }
      }}
      showActive={!!queue}
    />
  )
}
export default QueueSelectorDropdown
