import { LaunchDarkly } from '@truepill/tpos-types'
import DropDownOption from 'components/DropDownOption'
import { useFlag } from 'providers/LaunchDarklyProvider'
import styled from 'styled-components'
import type { Error, Loading, MedicationNoResults, MedicationSuggestion, MedicationSuggestionItem } from 'types'
import hyphenateNdc from 'utils/hyphenateNdc'

const AutoCompleteOption = (props: MedicationSuggestionItem): JSX.Element => {
  const shouldHyphenateNdc = useFlag(LaunchDarkly.FeatureFlags.TEMP_HYPHENATE_NDCS)

  if ((props as Error).error) {
    return (
      <DropDownOption {...props}>
        <p>{(props as Error).error}</p>
      </DropDownOption>
    )
  }

  if ((props as Loading).loading) {
    return (
      <DropDownOption {...props}>
        <p>Searching...</p>
      </DropDownOption>
    )
  }

  if ((props as MedicationNoResults).noResults) {
    return (
      <NoResultsDropDownOption {...props}>
        <p>No results</p>
      </NoResultsDropDownOption>
    )
  }

  let medicationOptionText = (props as MedicationSuggestion).name
  const showNdc = (props as MedicationSuggestion).showNdc

  const ndc = shouldHyphenateNdc
    ? hyphenateNdc((props as MedicationSuggestion).ndc ?? '')
    : (props as MedicationSuggestion).ndc ?? ''

  if (showNdc) {
    medicationOptionText = medicationOptionText.concat(` (${ndc})`)
  }

  return (
    <DropDownOption {...props}>
      <p>{`${medicationOptionText}`}</p>
    </DropDownOption>
  )
}

const NoResultsDropDownOption = styled(DropDownOption)`
  > p {
    text-transform: none;
  }
  > :first-child {
    text-transform: none;
  }
`
export default AutoCompleteOption
