import { useCallback } from 'react'
import { ReactComponent as ResetIcon } from 'assets/icons/reset.svg'
import IconWrapper from 'components/IconWrapper'
import { ActionButton } from 'components/PageStructure'
import useUncancelFill from 'hooks/useUncancelFill'
import styled from 'styled-components'
import { bodyPrimaryColor, primaryBackgroundColor } from 'styles/styleVariables'

type UncancelFillButtonProps = {
  orderId: string
  fillId: string
  onUncancelled?: () => void
}

const UncancelFillButton = ({ orderId, fillId, onUncancelled }: UncancelFillButtonProps): JSX.Element => {
  const uncancelFill = useUncancelFill()
  const clickHandler = useCallback(() => uncancelFill(orderId, fillId).then(() => onUncancelled?.()), [])
  return (
    <StyledActionButton
      data-testid="uncancel"
      icon={
        <ResetIconWrapper title="Uncancel Fill">
          <ResetIcon fill={bodyPrimaryColor} />
        </ResetIconWrapper>
      }
      onClick={clickHandler}
    />
  )
}

const ResetIconWrapper = styled(IconWrapper)`
  display: flex;
  grid-column: removeIcon;
  svg {
    fill: #999999;
  }
`

const StyledActionButton = styled(ActionButton)`
  background-color: ${primaryBackgroundColor};
  margin: 1px 0px 0px -3px;
  padding: 0 5px 0 2px;
  border-radius: 4px;
  grid-column: uncancelFill;
}
`

export default UncancelFillButton
