import styled from 'styled-components'
import type { ChildProps } from 'types'

const Container = styled.div`
  margin: 30px;
  p {
    margin: 10px 0;
  }
`
export const OrderNotFound = ({ children }: ChildProps): JSX.Element => (
  <Container>
    <h1>Order Not Found</h1>
    <p>{children}</p>
  </Container>
)
