import { ShippingMethod, TPCSShippingMethod } from 'types'

export const shippingMethodToName = Object.keys(ShippingMethod).reduce((acc: { [key: string]: string }, key) => {
  const value = ShippingMethod[key as keyof typeof ShippingMethod]
  acc[value] = key
  return acc
}, {})

export const getTPCSShippingMethodName = (id: number): string => {
  return TPCSShippingMethod[id] || ''
}
