import { lazy } from 'react'
import { Redirect, Route, Switch, useLocation } from '@truepill/tpos-react-router'
import { UserRoles } from '@truepill/tpos-types'
import { AppData } from 'components/AppData'
import AuthenticatedRoute from 'components/AuthenticatedRoute'
import CopayRequestView from 'components/CopayRequestView'
import { LoadFromCoreCopayToken } from 'components/CopayRequestView/LoadFromCoreCopayToken'
import ErrorBoundary from 'components/ErrorBoundary'
import { OrderUnlocker } from 'components/Order/OrderUnlocker'
import OrderView, { OrderViewMode } from 'components/OrderView'
import { LoadFromCoreFillRequestToken } from 'components/OrderView/LoadFromCoreFillRequestToken'
import { LoadFromCoreOrderId } from 'components/OrderView/LoadFromCoreOrderId'
import { LoadFromCoreOrderToken } from 'components/OrderView/LoadFromCoreOrderToken'
import { FILTER_NAMESPACE } from 'NameSpaces'
import FormDataProvider from 'providers/FormDataProvider'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import { usePlusClient } from 'providers/VisionRouter'
import { range } from 'ramda'
import { RoutePath } from 'routes'
import AdminPage from './AdminPage'
import InventoryGroupAdministration from './AdminPage/inventoryGroupAdministration'
import SigCodesAdministration from './AdminPage/sigCodesAdministration'
import SigCodesDetails from './AdminPage/sigCodesAdministration/SigCodesDetails'
import { QueuesPage as FulfillmentQueuesPage, RXPage as FulfillmentRXPage, CopayPage } from './fulfillment'
import {
  PeopleMainPage,
  PatientEditPage,
  PatientPage,
  PatientsPage,
  PrescribersPage,
  PayersPage,
  PayerPage,
  PayerEditPage,
  TeamPage,
  UserProfilePage,
  PrescriberPage,
  AddPrescriberPracticePage,
  AddPrescriberPage,
} from './PeoplePage'
import OutboundTransfersPage from './TasksPage/components/outboundTransfers'
import useNavigation from './useNavigation'
import useScannerRouting from './useScannerRouting'

const LoginPage = lazy(() => import('./LoginPage'))
const ResetPasswordPage = lazy(() => import('./ResetPasswordPage'))

const AutomationPage = lazy(() => import('./PharmacyPage/PharmacyAutomationPage'))
const CopaysPage = lazy(() => import('./PharmacyPage/PharmacyCopaysPage'))
const DrugDetailsPage = lazy(() => import('./PharmacyPage/PharmacyDrugPage'))
const DrugsPage = lazy(() => import('./PharmacyPage/PharmacyDrugsPage'))
const EscriptPage = lazy(() => import('./PharmacyPage/PharmacyEscriptPage'))
const EscriptsPage = lazy(() => import('./PharmacyPage/PharmacyEscriptsPage'))
const NewOrderPage = lazy(() => import('./PharmacyPage/PharmacyNewOrderPage'))
const NewPrescriptionPage = lazy(() => import('./PharmacyPage/PharmacyNewPrescriptionPage'))
const NewPrescriptionPageV2 = lazy(() => import('./PharmacyPage/PharmacyNewPrescriptionPageV2'))
const OutboundScanPage = lazy(() => import('./PharmacyPage/PharmacyOutboundScan'))
const PharmacyLocationPage = lazy(() => import('./PharmacyPage/PharmacyLocationViewPage'))
const PharmacyLocationsPage = lazy(() => import('./PharmacyPage/PharmacyLocationsPage'))
const PharmacyMainPage = lazy(() => import('./PharmacyPage/PharmacyMainPage'))
const PharmacyOrdersPage = lazy(() => import('./PharmacyPage/PharmacyOrdersPage'))
const PrescriptionPage = lazy(() => import('./PharmacyPage/PharmacyPrescriptionPage'))
const PrescriptionRxImagePage = lazy(() => import('./PharmacyPage/PharmacyPrescriptionRxImagePage'))
const PrescriptionsPage = lazy(() => import('./PharmacyPage/PharmacyPrescriptionsPage'))
const PAPageView = lazy(() => import('./fulfillment/orders/rx/screens/priorAuth/index'))
const RxIntakeIssuesPage = lazy(() => import('./PharmacyPage/PharmacyRxIntakeIssuesPage'))
const RxIntakeDetailsPage = lazy(() => import('./PharmacyPage/PharmacyRxIntakeDetailsPage'))
const TransfersPage = lazy(() => import('./PharmacyPage/PharmacyTransfersPage'))
const TransferPage = lazy(() => import('./PharmacyPage/PharmacyTransferPage'))
const TaskQueuesPage = lazy(() => import('./TasksPage'))

const Pages = (): JSX.Element => {
  const { isAuthenticated } = usePlusClient()

  // Each time the path changes, dismiss all modals.
  const { dismissModal, modals } = useModalContext()
  useNavigation(() => {
    range(0, modals.length).forEach(() => dismissModal())
  })

  useScannerRouting()
  const location = useLocation()

  // If you're not logged in, all you get is the login page and the reset
  // password page.
  if (!isAuthenticated) {
    return (
      <ErrorBoundary>
        <Switch>
          <Route exact path={RoutePath.Login} component={LoginPage} />
          <Route exact path={RoutePath.ResetPassword} component={ResetPasswordPage} />
          <Route>
            <Redirect to={{ pathname: RoutePath.Login, state: { from: location } }} />
          </Route>
        </Switch>
      </ErrorBoundary>
    )
  }

  return (
    <ErrorBoundary>
      <Switch>
        <Route exact path={RoutePath.Login} component={LoginPage} />
        <Route exact path={RoutePath.ResetPassword} component={ResetPasswordPage} />
        <AuthenticatedRoute
          exact
          roles={[
            UserRoles.Pharmacist,
            UserRoles.LeadPharmacist,
            UserRoles.Admin,
            UserRoles.Technician,
            UserRoles.CustomerSupport,
            UserRoles.LeadCustomerSupport,
            UserRoles.Warehouse,
          ]}
          path={RoutePath.FulfillmentFill}
          render={({ match: { params } }) => (
            <>
              <OrderUnlocker />
              <FormDataProvider
                initialData={{
                  [FILTER_NAMESPACE]: { customerIds: {}, medications: {} },
                }}
              >
                <FulfillmentRXPage orderId={params.orderId as string} fillId={params.fillId as string} />
              </FormDataProvider>
            </>
          )}
        />
        <AuthenticatedRoute
          exact
          roles={[
            UserRoles.Pharmacist,
            UserRoles.LeadPharmacist,
            UserRoles.Admin,
            UserRoles.Technician,
            UserRoles.Warehouse,
            UserRoles.CustomerSupport,
            UserRoles.LeadCustomerSupport,
          ]}
          path={RoutePath.FulfillmentOrder}
          render={({ match: { params } }) => (
            <>
              <OrderUnlocker />
              <FormDataProvider
                initialData={{
                  [FILTER_NAMESPACE]: { customerIds: {}, medications: {} },
                }}
              >
                <OrderView mode={OrderViewMode.Fulfillment} orderId={params.orderId as string} />
              </FormDataProvider>
            </>
          )}
        />
        <AuthenticatedRoute
          exact
          roles={[
            UserRoles.Pharmacist,
            UserRoles.LeadPharmacist,
            UserRoles.Admin,
            UserRoles.Technician,
            UserRoles.CustomerSupport,
            UserRoles.LeadCustomerSupport,
          ]}
          path={RoutePath.FulfillmentCopayRequestFill}
          render={({ match: { params } }) => (
            <>
              <FormDataProvider
                initialData={{
                  [FILTER_NAMESPACE]: { customerIds: {}, medications: {} },
                }}
              >
                <CopayPage copayRequestId={params.copayRequestId as string} fillId={params.fillId as string} />
              </FormDataProvider>
            </>
          )}
        />
        <AuthenticatedRoute
          exact
          roles={[
            UserRoles.Pharmacist,
            UserRoles.LeadPharmacist,
            UserRoles.Admin,
            UserRoles.Technician,
            UserRoles.CustomerSupport,
            UserRoles.LeadCustomerSupport,
          ]}
          path={RoutePath.FulfillmentCopay}
          render={({ match: { params } }) => (
            <>
              <CopayRequestView copayRequestId={params.copayRequestId as string} />
            </>
          )}
        />
        <AuthenticatedRoute
          exact
          roles={[
            UserRoles.Pharmacist,
            UserRoles.LeadPharmacist,
            UserRoles.Admin,
            UserRoles.Technician,
            UserRoles.CustomerSupport,
            UserRoles.LeadCustomerSupport,
            UserRoles.Warehouse,
          ]}
          path={RoutePath.Fulfillment}
          render={() => (
            <FormDataProvider
              initialData={{
                [FILTER_NAMESPACE]: { medications: {}, selectedCustomers: [], selectedLocations: [] },
              }}
            >
              <FulfillmentQueuesPage />
            </FormDataProvider>
          )}
        />
        <AuthenticatedRoute
          exact
          roles={[
            UserRoles.Pharmacist,
            UserRoles.LeadPharmacist,
            UserRoles.Admin,
            UserRoles.Technician,
            UserRoles.CustomerSupport,
            UserRoles.LeadCustomerSupport,
          ]}
          path={RoutePath.OutboundTransfer}
          render={({ match: { params } }) => (
            <>
              <OutboundTransfersPage outboundTransferId={params.outboundTransferId as string} />
            </>
          )}
        />
        <AuthenticatedRoute
          exact
          roles={[
            UserRoles.Pharmacist,
            UserRoles.LeadPharmacist,
            UserRoles.Admin,
            UserRoles.Developer,
            UserRoles.Technician,
          ]}
          path={RoutePath.Tasks}
          component={TaskQueuesPage}
        />
        <AuthenticatedRoute
          exact
          roles={[
            UserRoles.Pharmacist,
            UserRoles.Admin,
            UserRoles.Technician,
            UserRoles.CustomerSupport,
            UserRoles.LeadCustomerSupport,
          ]}
          path={RoutePath.CreatePatient}
          component={PatientEditPage}
        />
        <AuthenticatedRoute
          exact
          roles={[
            UserRoles.Pharmacist,
            UserRoles.Admin,
            UserRoles.Technician,
            UserRoles.CustomerSupport,
            UserRoles.LeadCustomerSupport,
          ]}
          path={RoutePath.EditPatient}
          component={PatientEditPage}
        />
        <AuthenticatedRoute
          exact
          roles={[UserRoles.Pharmacist, UserRoles.LeadPharmacist]}
          path={RoutePath.AddPrescriber}
          component={AddPrescriberPage}
        />
        <AuthenticatedRoute
          exact
          roles={[UserRoles.Pharmacist, UserRoles.Admin, UserRoles.Technician]}
          path={RoutePath.ViewPrescriberById}
          component={PrescriberPage}
        />
        <AuthenticatedRoute
          exact
          roles={[UserRoles.Pharmacist, UserRoles.LeadPharmacist]}
          path={RoutePath.AddPrescriberPracticeById}
          component={AddPrescriberPracticePage}
        />
        <AuthenticatedRoute
          exact
          roles={[
            UserRoles.Pharmacist,
            UserRoles.Admin,
            UserRoles.Technician,
            UserRoles.CustomerSupport,
            UserRoles.LeadCustomerSupport,
          ]}
          path={RoutePath.ViewPatient}
          component={PatientPage}
        />
        <AuthenticatedRoute
          exact
          roles={[
            UserRoles.Pharmacist,
            UserRoles.Admin,
            UserRoles.Technician,
            UserRoles.CustomerSupport,
            UserRoles.LeadCustomerSupport,
          ]}
          path={RoutePath.ViewPatients}
          component={PatientsPage}
        />
        <AuthenticatedRoute exact roles={[UserRoles.Admin]} path={RoutePath.Team} component={TeamPage} />
        <AuthenticatedRoute exact path={RoutePath.MyProfile} component={UserProfilePage} />
        <AuthenticatedRoute
          exact
          roles={[UserRoles.Pharmacist, UserRoles.Admin, UserRoles.Technician]}
          path={RoutePath.Prescribers}
          component={PrescribersPage}
        />
        <AuthenticatedRoute
          exact
          roles={[UserRoles.Pharmacist, UserRoles.Admin, UserRoles.Technician]}
          path={RoutePath.CreatePayer}
          component={PayerEditPage}
        />
        <AuthenticatedRoute
          exact
          roles={[UserRoles.Pharmacist, UserRoles.Admin, UserRoles.Technician]}
          path={RoutePath.EditPayer}
          component={PayerEditPage}
        />
        <AuthenticatedRoute
          exact
          roles={[UserRoles.Pharmacist, UserRoles.Admin, UserRoles.Technician]}
          path={RoutePath.ViewPayer}
          component={PayerPage}
        />
        <AuthenticatedRoute
          exact
          roles={[UserRoles.Pharmacist, UserRoles.Admin, UserRoles.Technician]}
          path={RoutePath.ViewPayers}
          component={PayersPage}
        />
        <AuthenticatedRoute
          exact
          roles={[
            UserRoles.Pharmacist,
            UserRoles.Admin,
            UserRoles.Technician,
            UserRoles.CustomerSupport,
            UserRoles.LeadCustomerSupport,
          ]}
          path={RoutePath.People}
          component={PeopleMainPage}
        />
        <AuthenticatedRoute exact path={RoutePath.UserProfile} component={UserProfilePage} />
        <AuthenticatedRoute exact roles={[UserRoles.Admin]} path={RoutePath.Admin} component={AdminPage} />
        <AuthenticatedRoute
          exact
          roles={[UserRoles.Admin]}
          path={RoutePath.AdminSigCodes}
          component={SigCodesAdministration}
        />
        <AuthenticatedRoute
          exact
          roles={[UserRoles.Admin]}
          path={RoutePath.ViewASigCodes}
          render={({ match: { params } }) => {
            return <SigCodesDetails sigCode={params.sigCode as string} />
          }}
        />
        {/* New Prescription V1 */}
        <AuthenticatedRoute
          exact
          roles={[
            UserRoles.Pharmacist,
            UserRoles.Admin,
            UserRoles.Technician,
            UserRoles.CustomerSupport,
            UserRoles.LeadCustomerSupport,
          ]}
          path={RoutePath.CreatePharmacyPrescriptionForPatient}
        >
          <NewPrescriptionPage />
        </AuthenticatedRoute>
        <AuthenticatedRoute
          exact
          roles={[
            UserRoles.Pharmacist,
            UserRoles.Admin,
            UserRoles.Technician,
            UserRoles.CustomerSupport,
            UserRoles.LeadCustomerSupport,
          ]}
          path={RoutePath.CreatePharmacyPrescription}
        >
          <NewPrescriptionPage />
        </AuthenticatedRoute>
        {/* New Prescription V2 */}
        <AuthenticatedRoute
          exact
          roles={[
            UserRoles.Pharmacist,
            UserRoles.Admin,
            UserRoles.Technician,
            UserRoles.CustomerSupport,
            UserRoles.LeadCustomerSupport,
          ]}
          path={RoutePath.CreatePharmacyPrescriptionForPatientV2}
        >
          <NewPrescriptionPageV2 />
        </AuthenticatedRoute>
        <AuthenticatedRoute
          exact
          roles={[UserRoles.Pharmacist, UserRoles.Admin, UserRoles.Technician]}
          path={RoutePath.CreatePharmacyPrescriptionV2}
        >
          <NewPrescriptionPageV2 />
        </AuthenticatedRoute>
        <AuthenticatedRoute
          exact
          roles={[UserRoles.Pharmacist, UserRoles.Admin, UserRoles.Technician]}
          path={RoutePath.EditPharmacyPrescription}
          render={
            () => <div />
            /*  <PrescriptionEditPage /> */
          }
        />
        <AuthenticatedRoute
          exact
          roles={[UserRoles.Pharmacist, UserRoles.Admin, UserRoles.Technician]}
          path={RoutePath.ViewRxImagePharmacyPrescription}
          component={PrescriptionRxImagePage}
        />

        <AuthenticatedRoute
          exact
          roles={[UserRoles.Admin]}
          path={RoutePath.AdminInventoryGroup}
          component={InventoryGroupAdministration}
        />
        <AuthenticatedRoute
          exact
          roles={[
            UserRoles.Pharmacist,
            UserRoles.Admin,
            UserRoles.Technician,
            UserRoles.CustomerSupport,
            UserRoles.LeadCustomerSupport,
          ]}
          path={RoutePath.ViewPharmacyPrescription}
          component={PrescriptionPage}
        />
        <AuthenticatedRoute
          exact
          roles={[
            UserRoles.Pharmacist,
            UserRoles.Admin,
            UserRoles.Technician,
            UserRoles.LeadCustomerSupport,
            UserRoles.CustomerSupport,
          ]}
          path={RoutePath.ViewPharmacyEscript}
          component={EscriptPage}
        />
        <AuthenticatedRoute
          exact
          roles={[
            UserRoles.Pharmacist,
            UserRoles.Admin,
            UserRoles.Technician,
            UserRoles.LeadCustomerSupport,
            UserRoles.CustomerSupport,
          ]}
          path={RoutePath.ViewPharmacyEscripts}
          component={EscriptsPage}
        />
        <AuthenticatedRoute
          exact
          roles={[
            UserRoles.Pharmacist,
            UserRoles.Admin,
            UserRoles.Technician,
            UserRoles.CustomerSupport,
            UserRoles.LeadCustomerSupport,
          ]}
          path={RoutePath.ViewPharmacyPrescriptions}
          component={PrescriptionsPage}
        />
        <AuthenticatedRoute
          exact
          roles={[
            UserRoles.Pharmacist,
            UserRoles.Admin,
            UserRoles.Technician,
            UserRoles.CustomerSupport,
            UserRoles.LeadCustomerSupport,
          ]}
          path={RoutePath.ViewDrugDetails}
          component={DrugDetailsPage}
        />
        <AuthenticatedRoute
          exact
          roles={[UserRoles.Pharmacist, UserRoles.Admin, UserRoles.Technician]}
          path={RoutePath.ViewPharmacyAutomation}
          component={AutomationPage}
        />
        <AuthenticatedRoute
          exact
          roles={[
            UserRoles.Pharmacist,
            UserRoles.Admin,
            UserRoles.Technician,
            UserRoles.CustomerSupport,
            UserRoles.LeadCustomerSupport,
          ]}
          path={RoutePath.ViewPharmacyDrugs}
          component={DrugsPage}
        />
        <AuthenticatedRoute
          exact
          //roles={[UserRoles.Pharmacist, UserRoles.Admin, UserRoles.Technician]}
          //Todo: Please fix this at some point
          path={RoutePath.FulfillmentPriorAuthorization}
          render={({ match: { params } }) => (
            <>
              <PAPageView priorAuthorizationId={params.priorAuthorizationId as string} />
            </>
          )}
        />
        <AuthenticatedRoute path={RoutePath.FulfillmentCopayViaToken}>
          <LoadFromCoreCopayToken />
        </AuthenticatedRoute>
        <AuthenticatedRoute path={RoutePath.ViewPharmacyCopayByCopayToken}>
          <LoadFromCoreCopayToken />
        </AuthenticatedRoute>
        <AuthenticatedRoute path={RoutePath.ViewPharmacyOrderOrderToken}>
          <LoadFromCoreOrderToken />
        </AuthenticatedRoute>
        <AuthenticatedRoute path={RoutePath.ViewPharmacyOrderFillRequestId}>
          <LoadFromCoreFillRequestToken />
        </AuthenticatedRoute>
        <AuthenticatedRoute path={RoutePath.ViewPharmacyOrderByCoreOrderId}>
          <LoadFromCoreOrderId />
        </AuthenticatedRoute>
        <Route
          exact
          path={RoutePath.ViewPharmacyCopay}
          render={({ match: { params } }) => (
            <CopayRequestView copayRequestId={params.copayRequestId as string} /> //TODO - add a mode here?
          )}
        />
        <Route exact path={RoutePath.ViewPharmacyCopays} component={CopaysPage} />
        <Route exact path={RoutePath.CreatePharmacyOrder} component={NewOrderPage} />
        <Route
          exact
          path={RoutePath.ViewPharmacyOrder}
          render={({ match: { params } }) => (
            <>
              <OrderUnlocker />
              <OrderView mode={OrderViewMode.Pharmacy} orderId={params.orderId as string} />
            </>
          )}
        />
        <Route exact path={RoutePath.ViewPharmacyOrders} component={PharmacyOrdersPage} />
        <Route exact path={RoutePath.ViewPharmacyTransfers} component={TransfersPage} />
        <Route exact path={RoutePath.ViewPharmacyLocation} component={PharmacyLocationPage} />
        <Route exact path={RoutePath.ViewPharmacyLocations} component={PharmacyLocationsPage} />
        <Route exact path={[RoutePath.Pharmacy, RoutePath.Root]} component={PharmacyMainPage} />
        <Route exact path={RoutePath.ViewRxIntakeIssues} component={RxIntakeIssuesPage} />
        <AuthenticatedRoute
          exact
          roles={[
            UserRoles.Pharmacist,
            UserRoles.Admin,
            UserRoles.Technician,
            UserRoles.LeadCustomerSupport,
            UserRoles.CustomerSupport,
          ]}
          path={RoutePath.ViewRxIntakeDetails}
          component={RxIntakeDetailsPage}
        />
        <AuthenticatedRoute
          exact
          roles={[
            UserRoles.Pharmacist,
            UserRoles.Admin,
            UserRoles.Technician,
            UserRoles.LeadCustomerSupport,
            UserRoles.CustomerSupport,
          ]}
          path={RoutePath.ViewPharmacyTransfer}
          component={TransferPage}
        />
        <AuthenticatedRoute
          exact
          roles={[UserRoles.Pharmacist, UserRoles.Admin, UserRoles.Warehouse]}
          path={RoutePath.ViewOutboundScan}
          component={OutboundScanPage}
        />
        {/* If no other paths match, you get redirected to the index page. */}
        <Route>
          <Redirect to={RoutePath.Root} />
        </Route>
      </Switch>
      <AppData />
    </ErrorBoundary>
  )
}
export default Pages
