import {
  AlternatingShading,
  ErrorText,
  NoColorBackground,
  StyledRXImageCell,
  StyledTextInput,
  StyledSelect,
  CloseOffTopBorder,
} from 'components/RXTable'
import useRxImageIsPic from 'hooks/useRxImageIsPic'
import styled, { css } from 'styled-components'
import { primaryBackgroundColor } from 'styles/styleVariables'
import type { OptChildProps } from 'types'

export const RXEDIRow = styled.ul<{ transparent?: boolean }>`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: [image] 1fr [edit] 1fr;
  ${({ transparent }) => transparent && NoColorBackground}
`

export const RXEDIListRow = styled(RXEDIRow)<{ noStripe?: boolean; error?: boolean }>`
  ${({ error }) => error && ErrorText}
  ${({ noStripe }) => !noStripe && AlternatingShading}
`

export const RXEDITitleRow = styled(RXEDIRow)`
  position: relative;
  background-color: ${primaryBackgroundColor};
`

export const RXEDIImageStyledCell = styled(StyledRXImageCell)`
  grid-template-columns: [label] 1.1fr [value] 0.9fr;
`

export const RXEDIRightTitleCell = styled.ul`
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  grid-row: 1;
  grid-column: edit;
`
interface StyledRXImageCellProps {
  noBorder?: boolean
  closeOffTopBorder?: boolean
  closeOffBottomBorder?: boolean
  noColorBackground?: boolean
  fullWidth?: boolean
}

export const RXEDIRightEditCell = styled.ul<StyledRXImageCellProps>`
  padding-left: 0.375rem;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  grid-row: 1;
  display: grid;
  padding-left: 2rem;
  grid-template-rows: auto;
  grid-template-columns: [label] 0.9fr [value] 1fr;
  grid-column: edit;
  ${({ closeOffTopBorder }) => closeOffTopBorder && CloseOffTopBorder}
`
export const RXEDImageCell = (props: StyledRXImageCellProps & OptChildProps): JSX.Element => {
  const [rxImageIsPic] = useRxImageIsPic()

  const { children, ...otherProps } = props

  if (rxImageIsPic) {
    return <RXEDIImageStyledCell {...otherProps} />
  } else {
    return <RXEDIImageStyledCell {...otherProps}>{props.children}</RXEDIImageStyledCell>
  }
}

const containerMargin = css`
  margin: 0.225rem;
`

export const RXTextInput = styled(StyledTextInput)`
  ${containerMargin}
`

export const DateCellPadding = styled.div`
  ${containerMargin}
`

export const StateSelect = styled(StyledSelect)`
  ${containerMargin}
  font-weight: normal;
  width: 7rem;
`
