import {
  ImageFilledHeader,
  NoColorBackground,
  RaisedAnchor,
  RightFilledHeader,
  RXCenterAndRightCell,
  RXTitleRow,
  StyledRXImageCell,
} from 'components/RXTable'
import styled from 'styled-components'
import type { Fill, PriorAuthorization } from 'types'
import EventLog from './EventLog'
import RejectionMessages from './RejectionMessages'

type TopSectionProps = {
  fill: Fill
  priorAuthorization?: PriorAuthorization
}

const TopSection = ({ fill, priorAuthorization }: TopSectionProps): JSX.Element => {
  return (
    <>
      <HeaderRow />
      <RXTwoColumn>
        <EventLog fill={fill} />
        <RejectionMessages priorAuthorization={priorAuthorization} />
      </RXTwoColumn>
    </>
  )
}

const HeaderRow = (): JSX.Element => {
  return (
    <RXTitleRow>
      <StyledRXImageCell noBorder>
        <ImageFilledHeader>Event Log</ImageFilledHeader>
      </StyledRXImageCell>

      <RXCenterAndRightCell>
        <RaisedAnchor id={'RejectionMessage'} />
        <RightFilledHeader>Message</RightFilledHeader>
      </RXCenterAndRightCell>
    </RXTitleRow>
  )
}

const RXTwoColumn = styled.div`
  grid-template-columns: 33.333% auto;
  display: grid;
`

const RXReviewRow = styled.ul<{ transparent?: boolean }>`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: [image] 33.333% [edit] auto;
  ${({ transparent }) => transparent && NoColorBackground}
`
RXReviewRow.displayName = 'RXReviewRow'

const RXPatientRightEditCell = styled.ul`
  display: flex;
  flex-basis: 100%;
  flex-wrap: wrap;
  overflow-y: visible;
  position: relative;
  height: 0;
  background-color: white;
  margin-top: 0.625rem;
  top: -0.98rem;
  z-index: 1;
`
RXPatientRightEditCell.displayName = 'RXPatientRightEditCell'

const RXPatientRightEditRow = styled.ul`
  grid-template-columns: [label] 10rem [value] 1fr;
  display: grid;
  grid-template-rows: auto 0.625rem;
  margin-right: 1rem;
`
RXPatientRightEditRow.displayName = 'RXPatientRightEditRow'

const StyledPatientLeftCell = styled(StyledRXImageCell)`
  grid-template-columns: [label] 1fr [value] 1fr;
  min-height: 2rem;
`
StyledPatientLeftCell.displayName = 'StyledPatientLeftCell'

const RXRejectionCell = styled.ul<{ backgroundColor?: string }>`
  display: grid;
  grid-template-rows: auto;
  margin-left: 1.25rem;
  grid-template-columns: [image] 100%;
  background-color: ${({ backgroundColor }) => backgroundColor};
`
RXRejectionCell.displayName = 'RXRejectionCell'

export default TopSection
