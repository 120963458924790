import { useState } from 'react'
import { useMutation } from '@truepill/tpos-react-router'
import { ReactComponent as CancelIcon } from 'assets/icons/cancel.svg'
import { SaveButton } from 'components/ActionButton'
import IconWrapper from 'components/IconWrapper'
import { ButtonsContainer, InputContainer, ModalHeader, ModalWrapper } from 'components/Modal'
import { CancelButton } from 'components/PageStructure'
import { StyledTextInput as TextInput } from 'components/RXTable'
import { SEND_BACK_TO_PV1_AND_TRIAGE } from 'gql'
import useErrorToast from 'hooks/toast/useErrorToast'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import styled, { css } from 'styled-components'
import { bodyPrimaryColor } from 'styles/styleVariables'

interface ModalProps {
  orderId: string
  fillId: string
}

const SendBackAndTriageModal = ({ orderId, fillId }: ModalProps): JSX.Element => {
  const { dismissModal } = useModalContext()
  const [message, setMessage] = useState('')
  const [sendBackToPV1AndTriage] = useMutation(SEND_BACK_TO_PV1_AND_TRIAGE)
  const showSuccessToast = useSuccessToast(true)
  const showErrorToast = useErrorToast(true)

  return (
    <ModalWrapper styles={modalWrapperStyles}>
      <ModalHeader>
        <IconWrapper>
          <CancelIcon fill={bodyPrimaryColor} />
        </IconWrapper>
        <h2>{'Send back'}</h2>
      </ModalHeader>
      <InputContainer>
        <div>This fill will be sent back to PV1 and flagged for triage.</div>
        <StyledTextInput value={message} placeholder={'Enter a message…'} onChange={e => setMessage(e.target.value)} />
      </InputContainer>
      <ButtonsContainer>
        <CancelButton label={'Cancel'} onClick={dismissModal} />
        <SaveButton
          isModal
          disabled={!message}
          label={'Submit'}
          onClick={async () => {
            try {
              await sendBackToPV1AndTriage({
                variables: {
                  orderId,
                  fillId,
                  message,
                },
              })
              dismissModal()
              showSuccessToast('Fill sent back to PV1 and flagged for triage.')
            } catch (e) {
              dismissModal()
              showErrorToast(`Fill could not be sent back: ${e.message}`)
              console.error(e)
            }
          }}
        />
      </ButtonsContainer>
    </ModalWrapper>
  )
}

// unlike other styled components, the ModalWrapper requires additional styling to be passed in
const modalWrapperStyles = css`
  overflow-y: hidden;
`

const StyledTextInput = styled(TextInput)`
  margin-top: 0.625rem;
  height: 2.5rem;
`

export default SendBackAndTriageModal
