import HotKeyToolTip from 'components/HotKeyToolTip'
import { ListRowLabel, ListRowValue, RaisedAnchor, RightFilledHeader, PaddingBlock } from 'components/RXTable'
import useHotKey, { HotKeyLevel } from 'hooks/useHotKey'
import { usePlusClient } from 'providers/VisionRouter'
import type { EdiOverrides } from 'types'
import { RXEDIListRow, RXEDImageCell, RXEDIRightEditCell, RXEDIRightTitleCell, RXEDITitleRow, RXTextInput } from '..'

type InsuranceSegmentProps = {
  ediOverrides: EdiOverrides
  updateEdiOverrides: (input: EdiOverrides) => void
}

const InsuranceSegment = ({ ediOverrides, updateEdiOverrides }: InsuranceSegmentProps): JSX.Element => {
  const { insurance } = ediOverrides

  const firstName = insurance?.firstName
  const lastName = insurance?.lastName
  const homePlan = insurance?.homePlan
  const planId = insurance?.planId
  const eligibilityClarification = insurance?.eligibilityClarification

  return (
    <>
      <InsuranceHeaderRow />
      <RXEDIListRow>
        <RXEDIRightEditCell>
          <ListRowLabel>Cardholder First Name (CC):</ListRowLabel>
          <ListRowValue>
            <RXTextInput
              value={firstName ?? ''}
              onChange={({ target: { value: firstName } }) => updateEdiOverrides({ insurance: { firstName } })}
              placeholder="First Name..."
            />
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>

      <RXEDIListRow>
        <RXEDIRightEditCell>
          <ListRowLabel>Cardholder Last Name (CD):</ListRowLabel>
          <ListRowValue>
            <RXTextInput
              value={lastName ?? ''}
              onChange={({ target: { value: lastName } }) => updateEdiOverrides({ insurance: { lastName } })}
              placeholder="Last Name..."
            />
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>

      <RXEDIListRow>
        <RXEDIRightEditCell>
          <ListRowLabel>Home Plan (CE):</ListRowLabel>
          <ListRowValue>
            <RXTextInput
              value={homePlan ?? ''}
              onChange={({ target: { value: homePlan } }) => updateEdiOverrides({ insurance: { homePlan } })}
              placeholder="Home Plan..."
            />
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>

      <RXEDIListRow>
        <RXEDIRightEditCell>
          <ListRowLabel>Plan Id (FO):</ListRowLabel>
          <ListRowValue>
            <RXTextInput
              value={planId ?? ''}
              onChange={({ target: { value: planId } }) => updateEdiOverrides({ insurance: { planId } })}
              placeholder="Plan Id..."
            />
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>

      <RXEDIListRow>
        <RXEDIRightEditCell closeOffBottomBorder>
          <ListRowLabel>Eligibility Clarification (C9):</ListRowLabel>
          <ListRowValue>
            <RXTextInput
              value={eligibilityClarification ?? ''}
              onChange={({ target: { value: eligibilityClarification } }) =>
                updateEdiOverrides({ insurance: { eligibilityClarification } })
              }
              placeholder="Eligibility Clarification..."
            />
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>
    </>
  )
}

const InsuranceHeaderRow = () => {
  const { routeToHash } = usePlusClient()

  useHotKey('2', HotKeyLevel.normal, () => {
    routeToHash(`InsuranceSegment`)
  })

  return (
    <RXEDITitleRow>
      <RXEDImageCell />
      <RXEDIRightTitleCell>
        <PaddingBlock />
        <RightFilledHeader>
          <RaisedAnchor id={'InsuranceSegment'} />
          Insurance segment (04)
          <HotKeyToolTip label={'2'} position={'right'} offsetLeft={1} offsetTop={0} />
        </RightFilledHeader>
      </RXEDIRightTitleCell>
    </RXEDITitleRow>
  )
}

export default InsuranceSegment
