import type { Log, Note } from 'types'
import { NoteSpecialTags } from 'types'

interface Tagged {
  tags: string[]
}
interface DateStamped {
  createdAt: string
}

export const isCritical = ({ tags }: Tagged): boolean => tags.includes(NoteSpecialTags.CRITICAL)

export const noteToLog = (note: Note): Partial<Log> => ({
  ...note,
  isAnnotation: false,
  event: 'note',
})

export const sortByDateStamp = (a: DateStamped, b: DateStamped): number => {
  const createdAtA = new Date(a.createdAt).getTime()
  const createdAtB = new Date(b.createdAt).getTime()
  return createdAtB - createdAtA
}

export const getCriticalNotes = (notes: Note[] = []): Partial<Log>[] =>
  notes.filter(isCritical).sort(sortByDateStamp).map(noteToLog)

export const getCriticalLogs = (logs: Log[] = []): Log[] => logs.filter(isCritical).sort(sortByDateStamp)

// For copay requests we do not store the copayRequestId on fill level notes
export const getFillLevelNotes = (notes: Log[] = [], fillId: string, itemId: string, isCopay?: boolean): Log[] => {
  if (isCopay) {
    return notes.filter(note => note.fillId === fillId && note.event === 'note' && !note.isArchived)
  }

  return notes.filter(
    note => note.fillId === fillId && note.event === 'note' && !note.isArchived && note.orderId === itemId,
  )
}

export const getNotes = (notes: Log[]): Log[] => {
  return notes.filter(note => note.event === 'note' && !note.isArchived)
}

export const getFillParentNotes = (notes: Log[] = [], orderId: string, isCopay?: boolean): Log[] => {
  return notes.filter(
    note =>
      note.event === 'note' &&
      !note.isArchived &&
      (((note.orderId === orderId || (isCopay && note.copayRequestId === orderId)) && !note.fillId) ||
        !!note.prescriptionId ||
        !!note.patientId),
  )
}
