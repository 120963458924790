import { ListRowLabel, ListRowValue, RXCell } from 'components/RXTable'
import styled from 'styled-components'
import { borderColor } from 'styles/styleVariables'
import type { PriorAuthorization } from 'types'

//only display the CMM message if priorauthorizations.message exists
type RejectionMessagesProps = { priorAuthorization?: PriorAuthorization }

const RejectionMessages = ({ priorAuthorization }: RejectionMessagesProps): JSX.Element => {
  const priorAuthMessage = priorAuthorization?.message
  const rx = priorAuthorization?.prescription
  const initClaim = priorAuthorization?.initiatedBy.claimId
  const initFill = rx?.fills.find(f => f._id.toString() === priorAuthorization?.initiatedBy.fillId.toString())
  const claim = initFill?.claims.find(claim => claim._id.toString() === initClaim?.toString())
  const rejectCodes = claim?.received?.groups?.[0]?.response.rejectCodes ?? []

  return (
    <RejectionMessageContainer>
      <TopRow />
      {rejectCodes.map(({ rejectCode, rejectCodeTranslated }, i) => {
        return (
          <div key={`rejection-messages-${i}`}>
            <RejectionMessageCell>
              <ListRowLabel data-testid="rejectCode">{rejectCode}:</ListRowLabel>
              <ListRowValue>{rejectCodeTranslated || 'Unknown Rejection Code'}</ListRowValue>
            </RejectionMessageCell>
          </div>
        )
      })}
      {priorAuthMessage && (
        <RejectionMessageCell>
          <ListRowValue>{priorAuthMessage}</ListRowValue>
        </RejectionMessageCell>
      )}
    </RejectionMessageContainer>
  )
}

const TopRow = styled.div`
  display: flex;
  flex-basis: 100%;
`

const RejectionMessageContainer = styled.div`
  font-family: monospace;
  border-radius: 0.25rem;
  border: 0.125rem solid ${borderColor};
  margin-top: 0.4rem;
  margin-left: 1.25rem;
  padding-top: 0.3rem;
  min-height: 127px;
  max-height: 127px;
  label {
    font-weight: 550;
  }
  overflow-y: auto;
`

const RejectionMessageCell = styled(RXCell)`
  grid-template-columns: [label] 3rem [value] 1fr;
`

export default RejectionMessages
