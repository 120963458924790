import { useEffect, useRef, useCallback } from 'react'
import { useLocation, useRouteMatch } from '@truepill/tpos-react-router'
import { OrderStatus } from '@truepill/tpos-types'
import { GET_ORDERS_WITH_SHORT_FILL_CODE, GET_ORDER_BY_CORE_ORDER_TOKEN } from 'gql'
import useErrorToast from 'hooks/toast/useErrorToast'
import useBarcodeScanner, { REGEXES } from 'hooks/useBarcodeScanner'
import { FulfillmentQueueName } from 'hooks/useFulfillmentQueue'
import { usePlusClient } from 'providers/VisionRouter'
import type { OrderQueryResult } from 'types'

interface GetOrderWithFillCodeParams {
  shortFillCode: string
}

interface RxFillRequestInfo {
  _id: string
  status: string
  fill: {
    _id: string
    shortFillCode: string
  }
}

interface OrderInfo {
  _id: string
  status: OrderStatus
  rxFillRequests: RxFillRequestInfo[]
}

interface GetOrderWithFillCodeResult {
  getOrdersWithShortFillCode: OrderInfo[]
}

function useScannerRouting(): void {
  const location = useLocation()
  const { search } = location

  const urlParams = (useRouteMatch as any)({
    path: '/fulfillment/*/order/:orderId/fill/:fillId',
  })
  const fillIdParam = urlParams?.params?.fillId

  const { registerListener, deregisterListener } = useBarcodeScanner()
  const {
    client,
    routeTo,
    tokenContext: { isTechnician },
  } = usePlusClient()

  const lastScannedFillCode = useRef('')
  const showErrorToast = useErrorToast(true)

  const navigate = useCallback(
    (order: OrderInfo, rxFillRequest: RxFillRequestInfo) => {
      lastScannedFillCode.current = ''

      const preventNavigation =
        [OrderStatus.PV2, OrderStatus.Packing, OrderStatus.Complete].includes(order.status) && isTechnician()

      if (preventNavigation) {
        // showErrorToast('Order has already been filled.')
        // return
      }

      // A really interesting side effect of most scanners is that they end
      // their Barcode barf with 'Enter', which browsers will interpret as a click
      // event if a button is focused. This can result in some really janky stuff
      // and in the case of our router, will often bounce the user between paths
      // if the last pressed button was say, a nav button. For this reason we
      // blur the acative element if it exists
      ;(document.activeElement as HTMLElement).blur()

      const searchMap = new URLSearchParams(search)
      const fulfillmentQueueName = (FulfillmentQueueName as any)[rxFillRequest.status]

      if (order.status !== OrderStatus.Packing) {
        routeTo.fulfillment(order._id, rxFillRequest.fill._id, fulfillmentQueueName, { searchMap }).now()
      } else {
        routeTo.order(order._id, true, { searchMap }).now()
      }
    },
    [search, routeTo, isTechnician, showErrorToast],
  )

  const handleShortcodeScan = useCallback(
    async (shortFillCode: string) => {
      lastScannedFillCode.current = shortFillCode
      try {
        const { data } = await client.query<GetOrderWithFillCodeResult, GetOrderWithFillCodeParams>({
          query: GET_ORDERS_WITH_SHORT_FILL_CODE,
          variables: { shortFillCode },
          fetchPolicy: 'network-only',
        })

        const orders = data.getOrdersWithShortFillCode || []
        const order = orders[orders.length - 1]
        const rxFillRequest = order.rxFillRequests.find(
          ({ fill }) => fill.shortFillCode === lastScannedFillCode.current,
        )

        if (rxFillRequest && rxFillRequest?.fill._id !== fillIdParam) {
          navigate(order, rxFillRequest)
        }
      } catch (e) {
        console.error(e)
      }
    },
    [client, fillIdParam, navigate],
  )

  const handleCoreOrderToken = useCallback(
    async (coreOrderToken: string) => {
      const parsedCoreOrderToken = coreOrderToken.replaceAll(/\*/g, '')
      const searchMap = new URLSearchParams(search)
      try {
        const { data } = await client.query<OrderQueryResult>({
          query: GET_ORDER_BY_CORE_ORDER_TOKEN,
          variables: { coreOrderToken: parsedCoreOrderToken },
          fetchPolicy: 'network-only',
        })

        const orderId = data?.order?._id

        if (orderId) {
          routeTo.order(orderId, true, { searchMap }).now()
        }
      } catch (e) {
        console.error(e)
      }
    },
    [client, routeTo, search],
  )

  useEffect(() => {
    registerListener(REGEXES.ShortFillCode, handleShortcodeScan)
    registerListener(REGEXES.CoreOrderToken, handleCoreOrderToken)
    return () => {
      deregisterListener(REGEXES.ShortFillCode, handleShortcodeScan)
      deregisterListener(REGEXES.CoreOrderToken, handleCoreOrderToken)
    }
  }, [handleShortcodeScan, handleCoreOrderToken, registerListener, deregisterListener])
}

export default useScannerRouting
