import type { ReactNode } from 'react'
import { useState, useEffect } from 'react'
import { Button, Spacer } from '@truepill/react-capsule'
import type { ApolloError } from '@truepill/tpos-react-router'
import type { FillDUR } from '@truepill/tpos-types'
import { DURStatus, RxFillRequestStatus } from '@truepill/tpos-types'
import { ReactComponent as CycleIcon } from 'assets/icons/capsule/cycle.svg'
import { ReactComponent as LoadingSpinner } from 'assets/icons/loading-circle.svg'
import AccordionContainer from 'components/AccordionContainer'
import CustomDURSubjectsList from 'components/CustomDURSubjectsList'
import DURCard from 'components/DURCard'
import DURSubjectsList from 'components/DURSubjectsList'
import HotKeyToolTip from 'components/HotKeyToolTip'
import NotificationBox from 'components/NotificationBox'
import { NormalMode } from 'components/RXPageStructure'
import {
  SubTitle,
  ListRowLabel,
  ListRowValue,
  ListRowValueColumn,
  RightSubTitle,
  RXCenterCell,
  RXListRowTwoColumns,
  StyledRXImageCell,
} from 'components/RXTable'
import { Badge } from 'components/UserInitialsBadge'
import { Box } from 'grommet'
import useHotKey, { HotKeyLevel } from 'hooks/useHotKey'
import moment from 'moment'
import { usePlusClient } from 'providers/VisionRouter'
import { last } from 'ramda'
import styled from 'styled-components'
import {
  bodySecondaryColor,
  capsuleDarkBlue,
  capsuleDarkRedColor,
  capsuleLightGreenColor,
  capsuleLightRedColor,
  contrastBackgroundColor,
  lightOrange,
} from 'styles/styleVariables'
import type { DURSubject, Fill, Order, Prescription, RXFillRequest } from 'types'
import { keyFromDur, sortDURsBySeverity, formatCreatedDate } from 'utils'

type ReviewSafetyProps = {
  fill: Fill
  order: Order
  rxFillRequest: RXFillRequest
  prescription: Prescription
  isClinicalReviewLoading?: boolean
  onRerunClinicalReviewClick?: () => void
  showEditUracModal?: () => void
  clinicalReviewError?: ApolloError | undefined
  initialDisclosed?: boolean
}

const ClinicalReviewNotificationBox = (props: {
  lastScreening: FillDUR | undefined
  error?: unknown
  loading?: boolean
  onReRunClick?: () => void
}) => {
  const { error, loading = false, lastScreening, onReRunClick } = props
  const [hasErrored, setHasErrored] = useState(false)

  useEffect(() => {
    if (error && !loading) {
      setHasErrored(true)
    }
    if (!error && !loading) {
      setHasErrored(false)
    }
  }, [loading, error])

  const handleReRunClick = () => {
    onReRunClick?.()
  }

  if (hasErrored) {
    return (
      <NotificationBox state="error">
        <StyledNotificationBox>
          <span>
            {`Failed to run. Manual review needed. ${
              lastScreening?.updatedAt
                ? `Last successfully updated on ${moment(lastScreening?.updatedAt).format('MM/DD/YYYY hh:mm:ssA')}`
                : ''
            }`}
          </span>
          {loading ? (
            <LoadingSpinner style={{ justifySelf: 'center', height: '1rem' }} stroke={capsuleDarkRedColor} />
          ) : (
            <TransparentButton
              style={{
                border: 'transparent',
                background: 'transparent',
              }}
              onClick={handleReRunClick}
            >
              <CycleIcon />
            </TransparentButton>
          )}
        </StyledNotificationBox>
      </NotificationBox>
    )
  }

  if (lastScreening?.updatedAt || loading) {
    return (
      <NotificationBox state="info">
        <StyledNotificationBox>
          <span>
            {lastScreening?.updatedAt
              ? `Last successfully updated on ${moment(lastScreening?.updatedAt).format('MM/DD/YYYY hh:mm:ssA')}`
              : ''}
          </span>
          {loading && <LoadingSpinner style={{ justifySelf: 'center', height: '1rem' }} stroke={capsuleDarkBlue} />}
        </StyledNotificationBox>
      </NotificationBox>
    )
  }

  return <></>
}

const ClinicalReviewBlock = ({
  fill,
  prescription,
  SubHeaderComponent,
  initialDisclosed = false,
}: {
  fill: Fill
  prescription: Prescription
  SubHeaderComponent?: ReactNode
  initialDisclosed?: boolean
}) => {
  const lastScreening = last(fill?.durScreenings ?? [])
  const durs = lastScreening?.results?.filter(result => !result.ignore) ?? []
  const messages = lastScreening?.messages?.filter(result => !result.ignore) ?? []

  // Find who was the last person to 'release' the DURs from all previous fills (JR-15087)
  const dursWithMostRecentRelease = sortDURsBySeverity(durs).map(dur => {
    let previouslyReleasedBy
    if (dur.releasedByUser) {
      previouslyReleasedBy = `${dur.releasedByUser.firstName} ${dur.releasedByUser.lastName}`
    } else {
      const durKey = keyFromDur(dur)
      const fillsToIter = prescription.fills.filter(i => i.fillNumber < fill.fillNumber).reverse()
      for (const fillItem of fillsToIter) {
        const fillLastScreening = last(fillItem?.durScreenings ?? [])
        const fillDurs = fillLastScreening?.results ?? []
        const matchingDur = fillDurs.find(i => keyFromDur(i) === durKey)
        if (matchingDur && matchingDur.releasedByUser) {
          previouslyReleasedBy = `${matchingDur.releasedByUser.firstName} ${matchingDur.releasedByUser.lastName}`
          break
        }
      }
    }
    return { previouslyReleasedBy, ...dur }
  })

  return (
    <ClinicalReviewContainer data-testid="dur-container">
      <ClinicalReviewHeading data-testid="dur-header">
        <ClinicalReviewTitle>Clinical Review</ClinicalReviewTitle>
        {[
          [DURStatus.SEVERE, capsuleLightRedColor],
          [DURStatus.MODERATE, lightOrange],
          [DURStatus.MILD, capsuleLightGreenColor],
        ]
          .filter(([status]) => durs.some(dur => dur.status === status))
          .map(([status, color]) => (
            <Badge
              preserveCircle
              key={status}
              data-testid={`dur-badge-${status}`}
              label={durs.filter(dur => dur.status === status).length.toString()}
              fontSize="0.6875rem"
              backgroundColor={color}
            />
          ))}
      </ClinicalReviewHeading>
      {SubHeaderComponent && SubHeaderComponent}

      {messages.length > 0 && (
        <StyledClinicalReviewCardList>
          <ClinicalReviewResultTitle>Exceptions</ClinicalReviewResultTitle>
          {messages.map(({ type, description, subject }) => (
            <DURCard
              showManagement={false}
              key={description}
              status={DURStatus.MODERATE}
              type={type}
              directions={description}
              subjects={[subject]}
            />
          ))}
        </StyledClinicalReviewCardList>
      )}

      {durs.length === 0 ? (
        <NoClinicalReviewMessage data-testid="dur-empty">
          No clinical review required for this fill
        </NoClinicalReviewMessage>
      ) : (
        <StyledClinicalReviewCardList data-testid="dur-items">
          <ClinicalReviewResultTitle>Results</ClinicalReviewResultTitle>
          {dursWithMostRecentRelease.map(({ status, type, directions, subjects, previouslyReleasedBy }) => {
            return (
              <DURCard
                status={status}
                type={type}
                directions={directions}
                releasedBy={previouslyReleasedBy}
                subjects={subjects}
                initialDisclosed={initialDisclosed}
              />
            )
          })}
        </StyledClinicalReviewCardList>
      )}
    </ClinicalReviewContainer>
  )
}

const ReviewSafety = ({
  fill,
  order,
  prescription,
  rxFillRequest,
  clinicalReviewError,
  onRerunClinicalReviewClick,
  showEditUracModal,
  isClinicalReviewLoading = false,
  initialDisclosed = false,
}: ReviewSafetyProps): JSX.Element => {
  const lastScreening = last(fill?.durScreenings ?? [])
  const { routeToHash } = usePlusClient()
  const [lastReviewedDate, setLastReviewedDate] = useState<Date | undefined>()

  useEffect(() => {
    if (fill?.fillNumber > 0) {
      const lastPV1VerfiedFill = prescription.fills.find(
        rxFill => rxFill.verifications?.pv1 && rxFill.fillNumber === fill.fillNumber - 1,
      )
      setLastReviewedDate(lastPV1VerfiedFill?.verifications?.pv1?.verifiedAt)
    }
  }, [fill.fillNumber, prescription.fills, prescription.fills.length])

  useHotKey('4', HotKeyLevel.normal, () => {
    routeToHash('ReviewSafety')
  })

  const fillRequestStatusInPV1 = rxFillRequest?.status === RxFillRequestStatus?.PV1
  const fillRequestStatusInPV2 = rxFillRequest?.status === RxFillRequestStatus?.PV2
  const showEditUracButton = fillRequestStatusInPV1

  if (!order) {
    return <></>
  }

  const { date: createdAtDate } = formatCreatedDate(order.createdAt)

  return (
    <Box direction="column" data-testid="ReviewSafety" id="ReviewSafety">
      <AccordionContainer title="Review safety and utilization" makeAccordionCollapsible={fillRequestStatusInPV2}>
        <HotKeyToolTip label="4" position="right" offsetLeft={16} offsetTop={-1} />
        <Spacer desktop="sm" />
        <ReviewSafetyWrapper>
          <div>
            <RXListRowTwoColumns data-testid="subheaders">
              <LastUpdatedCell noColorBackground closeOffTopBorder>
                {fillRequestStatusInPV1 && (
                  <>
                    <ListRowLabel>Patient Survey:</ListRowLabel>
                    <ListRowValue>{createdAtDate}</ListRowValue>
                  </>
                )}
              </LastUpdatedCell>
              <URACHeadingCell>
                <URACHeadingContainer>
                  <URACSubTitle>Clinical history:</URACSubTitle>
                  {fillRequestStatusInPV1 && lastReviewedDate && (
                    <URACDateReviewed>{`(Last reviewed: ${moment(lastReviewedDate).format(
                      'MM/DD/YYYY',
                    )})`}</URACDateReviewed>
                  )}
                  {showEditUracButton && (
                    <EditUracButtonWrapper>
                      <Button onClick={() => showEditUracModal?.()} variant="primary-text" style={{ padding: 0 }}>
                        Edit
                      </Button>
                    </EditUracButtonWrapper>
                  )}
                </URACHeadingContainer>
              </URACHeadingCell>
            </RXListRowTwoColumns>

            <RXListRowTwoColumns data-test-row="allergies">
              <StyledRXImageCell>
                <ListRowLabel>Allergies:</ListRowLabel>
                <ListRowValue>{order.survey?.allergies || 'No known drug allergies'}</ListRowValue>
              </StyledRXImageCell>
              <URACCenterCell>
                <div>
                  {order.durInputs.allergies.length ? (
                    <StyledClinicalReviewSubjectsList subjects={order.durInputs.allergies} />
                  ) : (
                    <>{!order.durInputs.custom?.allergies?.length && <>No known drug allergies</>}</>
                  )}
                </div>
                {!!order.durInputs.custom?.allergies?.length && (
                  <div>
                    <CustomDURSubjectsList subjects={order.durInputs.custom?.allergies} />
                  </div>
                )}
              </URACCenterCell>
            </RXListRowTwoColumns>

            <RXListRowTwoColumns data-test-row="medications">
              <StyledRXImageCell>
                <ListRowLabel>Medications:</ListRowLabel>
                <ListRowValue>{order.survey?.medications ?? 'No known other medications'}</ListRowValue>
              </StyledRXImageCell>
              <URACCenterCell>
                <div>
                  {order.durInputs.medications.length ? (
                    <StyledClinicalReviewSubjectsList subjects={order.durInputs.medications} />
                  ) : (
                    <>{!order.durInputs.custom?.medications?.length && <>No known other medications</>}</>
                  )}
                </div>
                {!!order.durInputs.custom?.medications?.length && (
                  <div>
                    <CustomDURSubjectsList subjects={order.durInputs.custom?.medications} />
                  </div>
                )}
              </URACCenterCell>
            </RXListRowTwoColumns>

            <RXListRowTwoColumns data-test-row="conditions">
              <StyledRXImageCell>
                <ListRowLabel>Conditions:</ListRowLabel>
                <ListRowValue>{order.survey?.conditions || 'No known other conditions'}</ListRowValue>
              </StyledRXImageCell>
              <URACCenterCell>
                <div>
                  {order.durInputs.conditions.length ? (
                    <StyledClinicalReviewSubjectsList subjects={order.durInputs.conditions as DURSubject[]} />
                  ) : (
                    <>{!order.durInputs.custom?.conditions?.length && <>No known other conditions</>}</>
                  )}
                </div>
                {!!order.durInputs.custom?.conditions?.length && (
                  <div>
                    <CustomDURSubjectsList subjects={order.durInputs.custom?.conditions} />
                  </div>
                )}
              </URACCenterCell>
            </RXListRowTwoColumns>

            <RXListRowTwoColumns data-test-row="events">
              <StyledRXImageCell>
                <ListRowLabel>Events:</ListRowLabel>
                <ListRowValueColumn>{order.survey?.events?.join(', ') || 'No known events'}</ListRowValueColumn>
              </StyledRXImageCell>
              <URACCenterCell />
            </RXListRowTwoColumns>

            <RXListRowTwoColumns data-test-row="smokingFrequency">
              <StyledRXImageCell>
                <ListRowLabel>Smoking Frequency:</ListRowLabel>
                <ListRowValue>{order.survey?.smokingFrequency ?? 'No known smoking frequency'}</ListRowValue>
              </StyledRXImageCell>
              <URACCenterCell />
            </RXListRowTwoColumns>

            <RXListRowTwoColumns data-test-row="smokingFrequency">
              <StyledRXImageCell>
                <ListRowLabel>Drinking Frequency:</ListRowLabel>
                <ListRowValue>{order.survey?.drinkingFrequency ?? 'No known drinking frequency'}</ListRowValue>
              </StyledRXImageCell>
              <URACCenterCell />
            </RXListRowTwoColumns>

            <PaddedFinalRow noStripe data-test-row="border">
              <StyledRXImageCell noColorBackground closeOffBottomBorder></StyledRXImageCell>
            </PaddedFinalRow>
          </div>
          <NormalMode>
            <ClinicalReviewBlock
              fill={fill}
              prescription={prescription}
              initialDisclosed={initialDisclosed}
              SubHeaderComponent={
                <ClinicalReviewNotificationBox
                  onReRunClick={onRerunClinicalReviewClick}
                  error={clinicalReviewError}
                  loading={isClinicalReviewLoading}
                  lastScreening={lastScreening}
                />
              }
            />
          </NormalMode>
        </ReviewSafetyWrapper>
      </AccordionContainer>
    </Box>
  )
}

const TransparentButton = styled.button`
  justify-self: center;
  border: transparent,
  background: transparent,
`

const StyledNotificationBox = styled.div`
  display: grid;
  grid-template-columns: 11fr 1fr;
  align-items: center;
`

const EditUracButtonWrapper = styled.div`
  flex-grow: 1;
  text-align: end;
  > button {
    text-decoration: underline;
  }
`

const PaddedFinalRow = styled(RXListRowTwoColumns)`
  margin-bottom: 4rem;
`

const URACHeadingCell = styled(RXCenterCell)`
  grid-template-columns: auto;
`
const URACCenterCell = styled(RXCenterCell)`
  display: flex;
  flex-flow: column;
  align-items: start;
  justify-items: center;
`
const URACSubTitle = styled(SubTitle)`
  font-weight: bold;
`

const ClinicalReviewTitle = styled(SubTitle)`
  font-size: 1rem;
  font-weight: bold;
`

const ClinicalReviewResultTitle = styled(ClinicalReviewTitle)`
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
`

const LastUpdatedCell = styled(StyledRXImageCell)`
  color: ${bodySecondaryColor};
`

const URACHeadingContainer = styled.div`
  display: flex;
`
const URACDateReviewed = styled.p`
  padding: 0 0.25rem;
`

const ClinicalReviewHeading = styled(RightSubTitle)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 0.3125rem;
  > span {
    margin-left: 0.5rem;
  }
  margin-bottom: 0.5rem;
  white-space: nowrap;
`

const ClinicalReviewContainer = styled.div`
  padding-left: 0.625rem;
`

const NoClinicalReviewMessage = styled.p`
  font-weight: 500;
  font-size: 1.3125rem;
  margin-left: 0.38rem;
  margin-top: 0.625rem;
`

// word-break: break-all - fixes the text overflow
const ReviewSafetyWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  word-break: break-all;
`

const StyledClinicalReviewCardList = styled.ul`
  padding: 0.5rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  background-color: ${contrastBackgroundColor};
  > li:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`

const StyledClinicalReviewSubjectsList = styled(DURSubjectsList)`
  grid-row: 1;
  grid-column: value;
  display: flex;
`

export default ReviewSafety
