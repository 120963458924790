import type { ReactNode } from 'react'
import { Component } from 'react'

interface ErrorBoundaryProps {
  errorComponent?: ReactNode
}

interface ErrorBoundaryState {
  hasError: boolean
}

export default class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(): { hasError: boolean } {
    return { hasError: true }
  }

  render(): any {
    if (this.state.hasError) {
      const { errorComponent } = this.props
      if (errorComponent) {
        return errorComponent
      }

      return <h1>Something went wrong</h1>
    }

    return this.props.children
  }
}
