import DatePicker from 'components/DatePicker'
import HotKeyToolTip from 'components/HotKeyToolTip'
import RXScanImage from 'components/RXScanImage'
import { ImageFilledHeader, ListRowLabel, ListRowValue, RaisedAnchor, RightFilledHeader } from 'components/RXTable'
import { claimsRequest } from 'hooks/navigation/useClaim'
import useHotKey, { HotKeyLevel } from 'hooks/useHotKey'
import useRxImageIsPic from 'hooks/useRxImageIsPic'
import moment from 'moment'
import { RX_NAMESPACE } from 'NameSpaces'
import { useFormDataContext } from 'providers/FormDataProvider'
import { usePlusClient } from 'providers/VisionRouter'
import type { Prescription, ClaimsRequest, EdiOverrides } from 'types'
import {
  RXEDIImageStyledCell,
  RXEDIListRow,
  RXEDImageCell,
  RXEDIRightEditCell,
  RXEDIRightTitleCell,
  RXEDIRow,
  RXEDITitleRow,
  DateCellPadding,
  RXTextInput,
} from '..'

type TransmissionSegmentProps = {
  ediOverrides: EdiOverrides
  updateEdiOverrides: (input: EdiOverrides) => void
}

const TransmissionSegment = ({ ediOverrides, updateEdiOverrides }: TransmissionSegmentProps): JSX.Element => {
  const [rxImageIsPic] = useRxImageIsPic()
  const { formData } = useFormDataContext()
  const prescription = formData[RX_NAMESPACE] as unknown as Prescription
  const existingClaimsRequest: ClaimsRequest = claimsRequest() as ClaimsRequest
  const { header } = ediOverrides

  const serviceProviderId = header?.serviceProviderId
  const softwareVendorId = header?.softwareVendorId
  const dateOfService = header?.dateOfService

  return (
    <>
      <TopRow />
      <RXEDIRow>
        {rxImageIsPic && (
          <RXEDIImageStyledCell noColorBackground closeOffTopBorder>
            <RXScanImage prescription={prescription} />
          </RXEDIImageStyledCell>
        )}
        <RXEDIImageStyledCell closeOffTopBorder></RXEDIImageStyledCell>
      </RXEDIRow>
      <RXEDIListRow>
        <RXEDIRightEditCell>
          <ListRowLabel>Service Provider ID (B1):</ListRowLabel>
          <ListRowValue>
            <RXTextInput
              value={serviceProviderId || ''}
              onChange={({ target: { value: serviceProviderId } }) =>
                updateEdiOverrides({ header: { serviceProviderId } })
              }
              placeholder="Service Provider ID..."
            />
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>
      <RXEDIListRow>
        <RXEDIRightEditCell>
          <ListRowLabel>Software Vendor Id (AK):</ListRowLabel>
          <ListRowValue>
            <RXTextInput
              value={softwareVendorId || ''}
              onChange={({ target: { value: softwareVendorId } }) =>
                updateEdiOverrides({ header: { softwareVendorId } })
              }
              placeholder="Software Vendor ID..."
            />
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>
      <RXEDIListRow>
        <RXEDImageCell closeOffBottomBorder>
          <ListRowLabel>Date filled (D1):</ListRowLabel>
          <ListRowValue>{existingClaimsRequest?.overrides?.header?.dateOfService || 'null'}</ListRowValue>
        </RXEDImageCell>
        <RXEDIRightEditCell>
          <ListRowLabel>Date filled (D1):</ListRowLabel>
          <ListRowValue>
            <DateCellPadding>
              <DatePicker
                date={moment(dateOfService ? new Date(dateOfService) : new Date(), 'MM/DD/YYYY').toDate()}
                onChange={date => {
                  updateEdiOverrides({ header: { dateOfService: moment(date).format('YYYY/MM/DD') } })
                }}
              />
            </DateCellPadding>
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>
    </>
  )
}

const TopRow = () => {
  const { routeToHash } = usePlusClient()

  useHotKey('1', HotKeyLevel.normal, () => {
    routeToHash(`TransmissionSegment`)
  })

  return (
    <RXEDITitleRow>
      <RaisedAnchor id={'ReviewPatient'} />
      <RXEDIImageStyledCell noBorder>
        <ImageFilledHeader>Sent EDI</ImageFilledHeader>
      </RXEDIImageStyledCell>
      <RXEDIRightTitleCell>
        <RightFilledHeader>
          Transmission
          <HotKeyToolTip label={'1'} position={'right'} offsetLeft={1} offsetTop={0} />
        </RightFilledHeader>
      </RXEDIRightTitleCell>
    </RXEDITitleRow>
  )
}

export default TransmissionSegment
