import { ReactComponent as DataIcon } from 'assets/icons/data.svg'
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg'
import IconWrapper from 'components/IconWrapper'
import { ModalWrapper, ModalHeader } from 'components/Modal'
import RawXMLAccordion from 'components/RawXMLAccordion'
import RXData from 'components/RXData'
import styled, { css } from 'styled-components'
import { bodyPrimaryColor, offsetBackgroundColor } from 'styles/styleVariables'
import type { Prescription } from 'types'

type RXDataModalProps = { prescription: Prescription }

const RXDataModal = ({ prescription }: RXDataModalProps): JSX.Element => {
  return (
    <ModalWrapper id="RXDataModal">
      <ModalHeader>
        <IconWrapper>
          <DataIcon fill={bodyPrimaryColor} />
        </IconWrapper>
        <h2>Rx image</h2>
        <StyledDownloadButton
          data-testid="download"
          title="Download RX Image"
          href={prescription.rxImage?.s3SignedUrl}
          target="_blank"
          download
        >
          <IconWrapper>
            <DownloadIcon fill={bodyPrimaryColor} />
          </IconWrapper>
        </StyledDownloadButton>
      </ModalHeader>
      <RXData columnStyles={columnStyles} prescription={prescription} isModal />
      {prescription?.escript?.raw && <RawXMLAccordion xml={prescription?.escript?.raw}></RawXMLAccordion>}
    </ModalWrapper>
  )
}

const columnStyles = css`
  margin-left: 0;
`

const StyledDownloadButton = styled.a<{ contrast?: boolean }>`
  border: none;
  position: absolute;
  top: 1rem;
  right: 4rem;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  background-color: ${offsetBackgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
  > span {
    margin-top: 0.1em;
  }
`

export default RXDataModal
