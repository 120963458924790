import { useState, useRef, useEffect, createContext } from 'react'
import type { ChildProps } from 'types'

interface Funcs {
  toggleDisclosure: () => void
  setDisclosure: (newValue: boolean) => void
}

export interface DisclosureProviderProps extends Funcs {
  disclosed: boolean
  firstLoad: boolean
}

export const DisclosureContext = createContext<Partial<DisclosureProviderProps>>({
  disclosed: false,
  firstLoad: false,
})

type DisclosureProps = { initialDisclosed?: boolean } & ChildProps

const DisclosureProvider = ({ children, initialDisclosed = false }: DisclosureProps): JSX.Element => {
  const [[disclosed, firstLoad], setDisclosed] = useState([initialDisclosed, true])

  // Enable Visibility Toggling
  const disclosedRef = useRef(disclosed)
  const firstLoadRef = useRef(firstLoad)

  useEffect(() => {
    disclosedRef.current = disclosed
    firstLoadRef.current = firstLoad
  }, [disclosed, firstLoad])

  const value = {
    disclosed,
    firstLoad,
    toggleDisclosure: () => {
      setDisclosed([!disclosedRef.current, false])
    },
    setDisclosed: (newDisclosure: boolean) => {
      setDisclosed([newDisclosure, false])
    },
  }

  return <DisclosureContext.Provider value={value}>{children}</DisclosureContext.Provider>
}

export default DisclosureProvider
