import type { ReactElement, ReactNode } from 'react'
import { useCallback, useState } from 'react'
import styled from 'styled-components'
import { StyledText, StyledTabList, StyledLink, StyledButton } from './StyledComponents'

export const Container = styled.div`
  display: flex;
`

export const WrapSection = styled.div`
  display: flex;
  flex-basis: 100%;
  flex-wrap: wrap;
  width: 100%;
`

interface TabProps {
  children: ReactNode
  icon?: ReactNode
  title?: string
}

/*
 *
 * @param children -> Allows passing children components into the Tab component
 * @param icon -> Allows adding icons to the Tabs
 * @param title -> Allows adding title to each one of the tabs
 */

export const Tab = ({ children }: TabProps): JSX.Element => {
  return <>{children}</>
}

type TabTitleProps = {
  title: string
  index: number
  icon: JSX.Element
  setSelectedTab: (index: number) => void
  isCurrentTab: boolean
}

/*
 *
 * @param title -> Allows adding title to each one of the tabs
 * @param index -> Provides a unique index for each tab
 * @param icon -> Allows adding icons to the tabs
 * @param setSelectedTab -> It helps to trigger logic when you click a tab
 * @param isCurrentTab -> It helps to style the selected tab
 */

const TabTitle = ({ title, setSelectedTab, index, icon, isCurrentTab }: TabTitleProps): JSX.Element => {
  const onClick = useCallback(() => {
    setSelectedTab(index)
  }, [setSelectedTab, index])

  return (
    <StyledLink>
      <StyledButton isCurrentTab={isCurrentTab} onClick={onClick}>
        {icon}
        <StyledText isCurrentTab={isCurrentTab}>{title}</StyledText>
      </StyledButton>
    </StyledLink>
  )
}

type NavTabContainerProps = { children: ReactElement[] }

const NavTabContainer = ({ children }: NavTabContainerProps): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState(0)

  return (
    <div>
      <StyledTabList>
        {children.map((item, index) => (
          <TabTitle
            key={index}
            title={item.props.title}
            index={index}
            icon={item.props.icon}
            setSelectedTab={setSelectedTab}
            isCurrentTab={selectedTab === index}
          />
        ))}
      </StyledTabList>
      {children[selectedTab]}
    </div>
  )
}

export default NavTabContainer

/*
Example of usage:

 <Wrapper>
  <Container>
    <Header> Hello World! </Header>
  <Container>
  <NavTabContainer>
    <Tab title='Test One'>
      <Container>
        <WrapSection>
          <TestOneComponent />
        </WrapSection>
      </Container>
    </Tab>
    <Tab title='Test Two'>
      <Container>
        <WrapSection>
          <TestTwoComponent testTwoProps={props}/>
        </WrapSection>
      </Container>
    </Tab>
  </NavTabContainer>
</Wrapper>

*/
