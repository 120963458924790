import { Text } from 'grommet'
import styled, { css } from 'styled-components'
import { bodySecondaryColor, borderColor, contrastColor } from 'styles/styleVariables'

const StyledText = styled(Text)<{ isCurrentTab: boolean }>`
  font-weight: 500;
  font-size: 0.875rem;
  font-family: Roboto;
  margin-left: 0.5rem;
  color: ${({ isCurrentTab }) => (isCurrentTab ? contrastColor : bodySecondaryColor)};
`

const StyledPrescriberTabList = styled.ul`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: column;
  justify-content: flex-start;
  height: 100%;
  margin-bottom: 0.625rem;
  border-bottom: 0.1rem solid ${borderColor};
`

const StyledLink = styled.li<{ isCurrentTab: boolean }>`
  display: flex;
  position: relative;
  top: 0.125rem;
  :first-child {
    padding-left: 1.745rem;
  }
  padding-right: 1.25rem;
  align-items: center;
  display: flex;
  flex-direction: row;
`

const Highlighted = css`
  > svg {
    fill: ${contrastColor};
  }
  border-bottom: 0.25rem solid ${contrastColor};
`

const StyledButton = styled.button<{ isCurrentTab: boolean }>`
  background-color: transparent;
  text-decoration: none;
  border: none;
  cursor: pointer;
  :focus {
    outline: none;
  }
  :focus-visible {
    outline: 0.15rem solid ${contrastColor};
  }
  ${({ isCurrentTab }) => (isCurrentTab ? Highlighted : Normal)}
`

const Normal = css`
  > svg {
    fill: ${bodySecondaryColor};
  }
  border-bottom: 0.25rem solid transparent;
  border {
    opacity: 0.52;
  }
`

export { StyledText, StyledPrescriberTabList, StyledLink, StyledButton }
