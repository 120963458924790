import styled from 'styled-components'
import { contrastBackgroundColor } from 'styles/styleVariables'

const AnnotationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

const StyledList = styled.ul`
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  flex-grow: 1;
  overflow-y: auto;
  > :first-child {
    margin-top: 0rem;
  }
`

const RXDataRow = styled.ul`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: [label] 7rem [value] 1fr;
  padding: 0.5rem 0.3125rem;
  :nth-of-type(even) {
    background-color: ${contrastBackgroundColor};
  }
`

const ListRowLabel = styled.li`
  grid-row: 1;
  grid-column: label;
  padding-right: 0.3125rem;
  padding-left: 0.425rem;
  font-size: 0.875rem;
  font-weight: 500;
`

const ListRowValue = styled.li`
  grid-row: 1;
  grid-column: value;
`

export { AnnotationContainer, StyledList, RXDataRow, ListRowLabel, ListRowValue }
