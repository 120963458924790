import { useQuery } from '@apollo/client'
import { GET_DUR_CONDITIONS } from 'gql'
import type { FuzzySearchProps } from 'modals/DurInputMappingsModal/types'
import type { DURSubject } from 'types'
import FuzzySearchAutoComplete from './FuzzySearchRender'

const ConditionsFuzzySearch = (props: FuzzySearchProps) => {
  const { data, error } = useQuery<{ getConditions: DURSubject[] }>(GET_DUR_CONDITIONS, {
    skip: !props.searchTerm,
    variables: { conditions: props.searchTerm },
  })

  return <FuzzySearchAutoComplete {...props} suggestions={data?.getConditions ?? []} error={error} />
}

export default ConditionsFuzzySearch
