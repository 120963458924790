import RerouteModal from 'modals/RerouteModal'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import type { CopayRequest } from 'types'

type useRerouteCopayType = () => void

const useRerouteCopay = (copayRequest: CopayRequest): useRerouteCopayType => {
  const { showModal } = useModalContext()

  return () => showModal(() => <RerouteModal item={copayRequest} />)
}

export default useRerouteCopay
