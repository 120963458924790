import { Select, Text, Grid, GridItem } from '@truepill/react-capsule'
import { ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight } from 'react-feather'
import styled from 'styled-components'
import { capsuleGreyColor, capsuleDarkBlue, contrastBackgroundColor, capsuleDarkGreyColor } from 'styles/styleVariables'
import { usePagination, DOTS } from './usePagination'
import type { RangeType } from './usePagination'

export const PagingWrapper = styled.div`
  margin: 1em 0;
  width: 100%;
`

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`

export const PagingContent = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: pagingContent;
`

export const PagingItemWrapper = styled.li`
  cursor: pointer;
  margin: 0 0.625rem;
  font-size: 1rem;
`

export const PagingItem = styled.a`
  height: 32px;
  min-width: 32px;
  text-align: center;
  font-weight: 500;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${capsuleGreyColor};
`

export const PageNumber = styled(PagingItem)`
  &:hover {
    background-color: ${contrastBackgroundColor};
  }
`

export const PageButton = styled(PagingItem)<{
  disabled?: boolean
}>`
  background-color: ${contrastBackgroundColor};
  ${({ disabled }) => disabled && 'pointer-events: none; opacity: 0.38;'}
`
export const PageLink = styled(PagingItem)<{
  disabled?: boolean
}>`
  ${({ disabled }) => disabled && 'pointer-events: none; opacity: 0.38;'}
`

export const PageDots = styled(PagingItem)`
  cursor: text;
`

export const HighlightedPageNumber = styled(PagingItem)`
  background-color: ${capsuleDarkBlue};
  color: ${contrastBackgroundColor};
`

export enum Variants {
  HIGHLIGHTED = 'highlighted',
  DOTS = 'dots',
  NUMBERS = 'numbers',
}

export interface NavigationProps {
  currentPage: number
  setPage: (page: number) => void
}

export interface PaginationProps {
  onChangePage: (page: number) => void
  totalRecords: number
  totalPages: number
  currentPage: number
  rowsPerPage?: number
  pagesForEachSide?: number
  rowsPerPageOptions?: number[]
  onChangeRowsPerPage?: (rowsPerPage: number) => void
}

export interface PagingButtonsProps extends NavigationProps {
  range: RangeType
}

export interface NavigationActions extends NavigationProps {
  disabled: boolean
}

export const defaultOnChangeRowsPerPage = (option: number) => option

export const getItemVariant = (pageNumber: number, currentPage: number) => {
  return pageNumber - 1 === currentPage ? Variants.HIGHLIGHTED : pageNumber === DOTS ? Variants.DOTS : Variants.NUMBERS
}

export const FirstPage = ({ setPage, currentPage, disabled }: NavigationActions): JSX.Element => (
  <PageButton disabled={disabled} onClick={() => setPage(currentPage)}>
    <ChevronsLeft />
  </PageButton>
)

export const LastPage = ({ setPage, currentPage, disabled }: NavigationActions): JSX.Element => (
  <PageButton disabled={disabled} onClick={() => setPage(currentPage)}>
    <ChevronsRight />
  </PageButton>
)

export const PreviousPage = ({ setPage, currentPage, disabled }: NavigationActions): JSX.Element => (
  <PageButton disabled={disabled} onClick={() => setPage(currentPage - 1)}>
    <ChevronLeft />
  </PageButton>
)

export const NextPage = ({ setPage, currentPage, disabled }: NavigationActions): JSX.Element => (
  <PageButton disabled={disabled} onClick={() => setPage(currentPage + 1)}>
    <ChevronRight />
  </PageButton>
)

export const PagingButtons = ({ currentPage, range, setPage }: PagingButtonsProps): JSX.Element => (
  <>
    {range.map((pageNumber, index) => (
      <PagingItemWrapper key={index}>
        {
          {
            [Variants.HIGHLIGHTED]: <HighlightedPageNumber>{pageNumber}</HighlightedPageNumber>,
            [Variants.DOTS]: <PageDots key={index}>&#8230;</PageDots>,
            [Variants.NUMBERS]: (
              <PageNumber key={index} onClick={() => setPage(pageNumber - 1)}>
                {pageNumber}
              </PageNumber>
            ),
          }[getItemVariant(pageNumber, currentPage)]
        }
      </PagingItemWrapper>
    ))}
  </>
)

/**
 * Build the pagination component
 * Usage:
 * <Pagination
 *    onChangePage={(page) => myFunction(page)}
 *    totalRecords={100}
 *    currentPage={1}
 *    totalPages={10}
 *    pagesForEachSide={1}
 *    rowsPerPage={10}
 *    rowsPerPageOptions={[10, 25, 50]}
 *    onChangeRowsPerPage={(rowsPerPage) => myFunction(rowsPerPage)}
 * />
 * @param onChangePage - Function called when a new page is selected
 * @param totalRecords - Total number of records. If total pages is less or equal to 0, the component is not displayed
 * @param currentPage - Current selected page
 * @param totalPages - Total number of pages
 * @param [rowsPerPage] - Optional. Default 10. Number of rows per page
 * @param [pagesForEachSide] - Optional. Default 1. Number of pages displayed for each side.
 * E.g The currentPage is 5 and pagesForEachSide is 1 means  .. 4 5 6 ..
 * @param [rowsPerPageOptions] - Optional. Default [10, 25, 50]. Options to display in the number of rows per page selector
 * @param [onChangeRowsPerPage] - Optional. Default (option: number) => option. Function called when a new number
 * of rows per page is selected. If this function is not set, the selector is disabled
 * @returns Pagination component
 */
const Pagination = ({
  onChangePage,
  totalRecords,
  currentPage,
  totalPages,
  rowsPerPage = 10,
  pagesForEachSide = 1,
  rowsPerPageOptions = [10, 25, 50],
  onChangeRowsPerPage,
}: PaginationProps): JSX.Element => {
  const range = usePagination({
    currentPage: currentPage + 1,
    totalPages,
    pagesForEachSide,
  })

  const allowChangeRows = typeof onChangeRowsPerPage === 'function'
  const _onChangeRowsPerPage = onChangeRowsPerPage ?? defaultOnChangeRowsPerPage
  const lowerBound = rowsPerPage * currentPage + 1
  const upperBound = lowerBound + rowsPerPage - 1
  const lastPage = totalPages - 1

  if (totalPages <= 0) return <></>

  return (
    <PagingWrapper>
      <Grid alignItems="center">
        <GridItem desktop={3} tablet={4} mobile={2}>
          <FlexContainer>
            <Text bold css={{ color: capsuleDarkGreyColor }}>
              {lowerBound} - {upperBound > totalRecords ? totalRecords : upperBound}
            </Text>
            <Text css={{ color: capsuleGreyColor }}> &nbsp;of&nbsp;{totalRecords}</Text>
          </FlexContainer>
        </GridItem>
        <GridItem
          desktop={6}
          tablet={8}
          mobile={4}
          css={{
            order: 3,
            '@desktop': {
              // Currently, based on the doc, capsule does not support either order or @mobile. So, I had to use !important in order to re-write the styles in desktop
              order: '2 !important',
              // When the order is re-written, the gridColumn does not take the value set in the GridItem props
              gridColumn: 'span 6 / auto',
            },
          }}
        >
          <PagingContent>
            <PagingItemWrapper>
              <FirstPage disabled={currentPage === 0} setPage={onChangePage} currentPage={0} />
            </PagingItemWrapper>
            <PagingItemWrapper>
              <PreviousPage disabled={currentPage === 0} setPage={onChangePage} currentPage={currentPage} />
            </PagingItemWrapper>
            <PagingButtons range={range} currentPage={currentPage} setPage={onChangePage} />
            <PagingItemWrapper>
              <NextPage disabled={currentPage === lastPage} setPage={onChangePage} currentPage={currentPage} />
            </PagingItemWrapper>
            <PagingItemWrapper>
              <LastPage disabled={currentPage === lastPage} setPage={onChangePage} currentPage={lastPage} />
            </PagingItemWrapper>
          </PagingContent>
        </GridItem>

        <GridItem
          desktop={3}
          tablet={4}
          mobile={2}
          css={{
            justifySelf: 'end',
            order: 2,
            '@desktop': {
              // Currently, based on the doc, capsule does not support either order or @mobile. So, I had to use !important in order to re-write the styles in desktop
              order: '3 !important',
              // When the order is re-written, the gridColumn does not take the value set in the GridItem props
              gridColumn: 'span 3 / auto',
            },
          }}
        >
          <FlexContainer>
            <Text>Show</Text>
            <Select
              variant="small"
              value={String(rowsPerPage)}
              options={rowsPerPageOptions.map(row => String(row))}
              label=""
              onChange={option => _onChangeRowsPerPage(option ? Number(option) : -1)}
              disabled={!allowChangeRows}
              css={{
                margin: '0 0.625rem',
                label: {
                  display: 'none',
                },
              }}
            />
            <Text>entries</Text>
          </FlexContainer>
        </GridItem>
      </Grid>
    </PagingWrapper>
  )
}

export default Pagination
