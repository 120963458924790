import { useState } from 'react'
import { useMutation } from '@truepill/tpos-react-router'
import { ReactComponent as CancelIcon } from 'assets/icons/cancel.svg'
import { SaveButton } from 'components/ActionButton'
import IconWrapper from 'components/IconWrapper'
import { InputContainer, ButtonsContainer, ModalWrapper, ModalHeader } from 'components/Modal'
import { CancelButton } from 'components/PageStructure'
import { StyledSelect } from 'components/RXTable'
import { REJECT_OUTBOUND_TRANSFER } from 'gql'
import { TextArea } from 'grommet'
import useErrorToast from 'hooks/toast/useErrorToast'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import styled from 'styled-components'
import { bodyPrimaryColor } from 'styles/styleVariables'
import { OutboundTransferRejectionReason } from 'types'

interface RejectOutboundTransferModalProps {
  outboundTransferId: string
  onDismiss?: () => void
}

const RejectOutboundTransferModal = (props: RejectOutboundTransferModalProps) => {
  const { outboundTransferId, onDismiss } = props
  const { dismissModal } = useModalContext()
  const showErrorToast = useErrorToast()
  const showSuccessToast = useSuccessToast()
  const [rejectReason, setRejectReason] = useState('')
  const [rejectMessage, setRejectMessage] = useState('')
  const [rejectCode, setRejectCode] = useState<OutboundTransferRejectionReason | undefined>(undefined)

  const [rejectOutboundTransfer, { loading }] = useMutation(REJECT_OUTBOUND_TRANSFER, {
    onCompleted: data => {
      showSuccessToast('Outbound transfer rejected')
      dismissModal()
    },
    refetchQueries: ['getBasicOutboundTransfer'],
    onError: e => {
      console.error('Failed to reject outbound transfer. ' + e.message)
      showErrorToast('Failed to reject outbound transfer. ' + e.message)
      dismissModal()
    },
  })
  return (
    <ModalWrapper id="RejectOutboundTransferModal">
      <ModalHeader closeCallback={onDismiss}>
        <IconWrapper data-testid="close">
          <CancelIcon role="img" fill={bodyPrimaryColor} />
        </IconWrapper>
        <h2>Reject reason</h2>
      </ModalHeader>
      <ConstrainedInputContainer>
        <ReasonSelect
          data-testid="reason"
          multiple={false}
          modal={true}
          value={rejectReason}
          placeholder={'Select a reject reason...'}
          options={Object.keys(OutboundTransferRejectionReason)}
          onChange={([option]) => {
            if (!option) {
              return
            }

            const value = option.value as keyof typeof OutboundTransferRejectionReason
            const newRejectCode = OutboundTransferRejectionReason[value]
            setRejectReason(value)
            setRejectCode(newRejectCode)
          }}
        />
        <TextArea
          data-testid="message"
          onChange={event => setRejectMessage(event.target.value)}
          placeholder={'Type a message...'}
          value={rejectMessage}
          resize={'vertical'}
        />
      </ConstrainedInputContainer>
      <ButtonsContainer>
        <CancelButton
          label="Cancel"
          onClick={() => {
            dismissModal()
            onDismiss && onDismiss()
          }}
        />
        <SaveButton
          isModal
          label={`Submit${loading ? 'ing' : ''}`}
          onClick={() => {
            if (rejectCode) {
              const vars = {
                outboundTransferId,
                reason: rejectReason,
                reasonCode: rejectCode,
                message: rejectMessage && rejectMessage.length ? rejectMessage : '',
              }

              rejectOutboundTransfer({
                variables: vars,
              })
            }
          }}
        />
      </ButtonsContainer>
    </ModalWrapper>
  )
}

const ReasonSelect = styled(StyledSelect)`
  height: 2.5rem;
  align-self: stretch;
`
const ConstrainedInputContainer = styled(InputContainer)`
  max-width: 31rem;
`

export default RejectOutboundTransferModal
