import type { FC } from 'react'
import { Theme, Button } from '@truepill/react-capsule'
import { ReactComponent as NextOrderIcon } from 'assets/icons/circlecheck.svg'
import useErrorToast from 'hooks/toast/useErrorToast'
import useGetNextOtcOnly from 'hooks/useGetNextOtcOnly'
import styled, { css } from 'styled-components'
import { capsulePrimaryColorDark, capsulePrimaryColorLight } from 'styles/styleVariables'

type NextOrderButtonProps = { disabled?: boolean }

const NextOrderButton: FC<NextOrderButtonProps> = ({ disabled }) => {
  const showErrorToast = useErrorToast()
  const { getNextOtcOnly } = useGetNextOtcOnly()

  const handleNextOrderClick = async () => {
    try {
      await getNextOtcOnly()
    } catch (e) {
      showErrorToast(`Failed to get next order: ${(e as Record<string, string>).message}`)
    }
  }

  return (
    <Theme>
      <NextOrderButtonWrapper>
        <Button size="xs" variant="primary-outline" disabled={disabled} onClick={handleNextOrderClick}>
          <NextOrderIcon fill={capsulePrimaryColorDark} />
          Next Order
        </Button>
      </NextOrderButtonWrapper>
    </Theme>
  )
}

const NextOrderButtonStyles = css`
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  svg {
    fill: ${capsulePrimaryColorDark};
    margin-right: 0.75rem;
    min-width: 16px;
  }
  :hover {
    svg {
      fill: ${capsulePrimaryColorLight};
    }
  }
  :disabled {
    svg {
      fill: ${capsulePrimaryColorDark};
    }
  }
`

const NextOrderButtonWrapper = styled.div`
  display: flex;
  margin-left: 0.625rem;
  > button {
    ${NextOrderButtonStyles}
  }
`
export default NextOrderButton
