import DropDownOption from 'components/DropDownOption'
import { IconContainer } from 'pages/PharmacyPage/PharmacyAutomationPage/components/AutomationSection/StyledComponents'
import styled from 'styled-components'
import { bodyPrimaryColor, bodySecondaryColor, errorDark, btnPrimary } from 'styles/styleVariables'

export const StyledDropDownOption = styled(DropDownOption)`
  max-width: 450px;
`

export const StyledIconContainer = styled(IconContainer)`
  padding: 0 0.5rem;
`

export const FormularyLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 700;
  background: #e9ecf0;
  padding: 0.5rem;
  line-height: 20px;
  user-select: none;
`

export const InventoryGroupTabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.15rem 0.3rem;
`

export const InventoryGroupTab = styled.div`
  font-size: 1rem;
  font-weight: 900;
  line-height: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.6rem;
  color: ${bodyPrimaryColor};
  :hover {
    cursor: pointer;
  }
`

export const InventoryGroupTabWrap = styled.div`
  padding: 0.3rem 0.6rem;
`

export const InventoryGroupTabSelected = styled(InventoryGroupTab)`
  color: ${btnPrimary};
  border-bottom: 2px solid ${btnPrimary};
  padding-bottom: 0.5rem;
`

export const NoSubstitutionsInInventoryGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.6rem 1rem;
  font-size: 1rem;
`

export const MedicationInfo = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 0.3rem;
  p:not(:last-child) {
    padding-right: 1.5rem;
  }
  font-size: 0.9rem;
  font-weight: 400;
  color: ${bodySecondaryColor};
  :hover {
    color: ${bodyPrimaryColor};
  }
`

export const CheckAbRatedSubstitution = styled.div`
display: flex;
align-items: center;
padding-top: 0.3rem;
font-size: 0.9rem;
font-weight: 400;
color: ${errorDark}};
:hover {
  color: ${errorDark};
}
> p {
  padding-left: 0.2rem;
}
`

export const RowItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
`
