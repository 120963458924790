import { UserRoles } from '@truepill/tpos-types'
import AuthLimited from 'components/AuthLimited'
import BreadCrumb from 'components/BreadCrumb'
import NewTeamMember from 'components/NewTeamMemberButton'
import { PageContainer } from 'components/PageStructure'
import SearchTextField from 'components/SearchTextField'
import TeamTable from 'components/TeamTable'
import useSetPageTitle from 'hooks/useSetPageTitle'
import useStateWithDebouncedValue from 'hooks/useStateWithDebouncedValue'
import useUsers from 'hooks/useUsers'
import { Content, Title, SearchControls, NewTeamMemberControls, StyledActionBar } from './StyledComponents'

const TruepillTeamPage = (): JSX.Element => {
  const [searchTerm, , setSearchTerm] = useStateWithDebouncedValue('')
  const { paginatedUsers, fetchMore, loading, error } = useUsers(searchTerm)
  useSetPageTitle('Truepill team')

  if (error) {
    return (
      <AuthLimited roles={[UserRoles.Admin]}>
        <PageContainer>
          <BreadCrumb />
          <Content>Error loading team data: {JSON.stringify(error)}</Content>
        </PageContainer>
      </AuthLimited>
    )
  }

  return (
    <AuthLimited roles={[UserRoles.Admin]}>
      <PageContainer>
        <BreadCrumb />
        <Title>Truepill team</Title>
        <StyledActionBar>
          <SearchControls>
            <SearchTextField
              placeholder="Search employees..."
              value={searchTerm}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value)}
            />
          </SearchControls>
          <NewTeamMemberControls>
            <NewTeamMember />
          </NewTeamMemberControls>
        </StyledActionBar>
        <Content>
          <TeamTable paginatedUsers={paginatedUsers} fetchMore={fetchMore} loading={loading} />
        </Content>
      </PageContainer>
    </AuthLimited>
  )
}

export default TruepillTeamPage
