import type { PriorAuthorizationStatus } from '@truepill/tpos-types'
import DashboardTokenLink from 'components/DashboardTokenLink'
import PatientHeadingInfo from 'components/PatientHeadingInfo'
import Lozenge, { getStatusColor } from 'components/Tiles/Lozenge'
import { useTPCacheContext } from 'providers/TPCacheProvider'
import styled from 'styled-components'
import type { FlattenSimpleInterpolation } from 'styled-components'
import { accentPink, contrastColor, bodySecondaryColor } from 'styles/styleVariables'
import type { OptChildProps, CopayRequest, PriorAuthorization } from 'types'
import { removeUnderscore, removeCamelCase, formatCreatedDate } from 'utils'

type CopayRequestHeadingProps = {
  copayRequest: CopayRequest
  priorAuthStatus?: PriorAuthorizationStatus
  priorAuthorization?: PriorAuthorization
} & OptChildProps

const CopayRequestHeading = ({
  copayRequest,
  children,
  priorAuthStatus,
  priorAuthorization,
}: CopayRequestHeadingProps): JSX.Element => {
  const { getCustomerNameById, getLocationNameById } = useTPCacheContext()

  if (!copayRequest) {
    return <></>
  }

  const { date: createdAtDate } = formatCreatedDate(copayRequest.createdAt)
  const { date: priorAuthCreatedAtDate } = formatCreatedDate(priorAuthorization?.createdAt)

  return (
    <StyledCopayRequestViewHeading id="CopayRequestHeading">
      <CopayRequestViewHeading>
        {children}
        <StyledHeading data-testid="copay-request-id">
          Copay: {copayRequest.coreCopayId}
          {copayRequest.coreCopayToken && <DashboardTokenLink token={copayRequest.coreCopayToken} entity="copays" />}
        </StyledHeading>
        <Lozenge data-testid="location" backgroundColor={contrastColor}>
          {removeCamelCase(removeUnderscore(getLocationNameById(copayRequest.locationId)))}
        </Lozenge>
        <Lozenge data-testid="customer" backgroundColor={accentPink}>
          {getCustomerNameById(copayRequest.customerId)}
        </Lozenge>
        <Lozenge data-testid="status" backgroundColor={getStatusColor(priorAuthStatus || copayRequest.status)}>
          {priorAuthStatus || copayRequest.status}
        </Lozenge>
      </CopayRequestViewHeading>
      <CopayRequestInfo>
        {copayRequest.patient && <PatientHeadingInfo patientId={copayRequest.patient._id} />}
        {priorAuthorization?.createdAt ? (
          <>
            <CopayCreated>PA created:</CopayCreated>
            <DateCreated data-testid="date-created">{priorAuthCreatedAtDate}</DateCreated>
          </>
        ) : (
          copayRequest.createdAt && (
            <>
              <CopayCreated> Copay created:</CopayCreated>
              <DateCreated data-testid="date-created">{createdAtDate}</DateCreated>
            </>
          )
        )}
      </CopayRequestInfo>
    </StyledCopayRequestViewHeading>
  )
}

const CopayRequestViewHeading = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-space-between;
  align-items: center;
`

const StyledHeading = styled.h1<{
  styles?: FlattenSimpleInterpolation
}>`
  font-size: 1.3em;
  ${({ styles }) => styles}
`

const StyledCopayRequestViewHeading = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
`

const CopayRequestInfo = styled.div`
  margin-top: 0.2rem;
  color: ${bodySecondaryColor};
  font-size: 1rem;
  font-weight: 500;
`

const CopayCreated = styled.span`
  margin-right: 0.2rem;
  margin-left: 0.6rem;
`

const DateCreated = styled.span`
  font-weight: 400;
`

export default CopayRequestHeading
