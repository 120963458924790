import { useState } from 'react'
import { useMutation } from '@truepill/tpos-react-router'
import { DocumentTag } from '@truepill/tpos-types'
import { SaveButton } from 'components/ActionButton'
import { FormItem } from 'components/ColumnForm'
import { ModalWrapper, ModalHeader, ButtonsContainer } from 'components/Modal'
import { CancelButton } from 'components/PageStructure'
import RXScanImage from 'components/RXScanImage'
import { FullWidthHeader, StyledSelect } from 'components/RXTable'
import Lozenge from 'components/Tiles/Lozenge'
import { UPDATE_DOCUMENT } from 'gql'
import { TextArea } from 'grommet'
import useErrorToast from 'hooks/toast/useErrorToast'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import moment from 'moment'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import styled from 'styled-components'
import { subduedColor } from 'styles/styleVariables'
import type { TPOSDocument } from 'types'

type AttachmentDetailsModalProps = { document: TPOSDocument }

const AttachmentDetailsModal = ({ document }: AttachmentDetailsModalProps): JSX.Element => {
  const [caption, setCaption] = useState(document.caption || '')
  const [tag, setTag] = useState(document.tag || '')
  const { dismissModal } = useModalContext()

  const formIsValid = tag !== '' && caption !== ''

  const showSuccessToast = useSuccessToast()
  const showErrorToast = useErrorToast()
  const [updateDocument] = useMutation(UPDATE_DOCUMENT, {
    onCompleted() {
      dismissModal()
      showSuccessToast('Attachment updated')
    },
    onError(err) {
      dismissModal()
      showErrorToast(`Failed to update attachment! ${err.message.replace('GraphQL error:', '')}`)
    },
  })

  return (
    <ModalWrapper>
      <ModalHeader>
        <InfoContainer>
          <h2>{document.caption}</h2>
          <AttachmentsDate>Added {moment(document.createdAt).format('MM/DD/YYYY')}</AttachmentsDate>
          <StyledLozenge backgroundColor={subduedColor}>{document.tag}</StyledLozenge>
        </InfoContainer>
      </ModalHeader>
      <FormStyledItem>
        <p>Caption</p>
        <TextArea
          value={caption}
          placeholder={'Enter a caption...'}
          resize={'vertical'}
          onChange={({ target }) => setCaption(target.value)}
        />
      </FormStyledItem>
      <FormStyledItem>
        <p>Tag</p>
        <TagSelect
          multiple={false}
          modal={true}
          value={tag}
          placeholder={'Select tag for image...'}
          options={Object.keys(DocumentTag)}
          onChange={changes => {
            const [tagChange] = changes
            if (!tagChange) {
              setTag('')
              return
            }
            const { value } = tagChange
            setTag(value)
          }}
        />
      </FormStyledItem>
      <ContentHeader> Image </ContentHeader>
      <RXScanImage src={document.s3SignedUrl} inline />
      <ButtonsContainer>
        <CancelButton label={'Cancel'} onClick={dismissModal} />
        <SaveButton
          disabled={!formIsValid}
          isModal
          label={'Update'}
          onClick={() => {
            if (formIsValid) {
              updateDocument({
                variables: {
                  documentId: document._id,
                  caption,
                  tag,
                },
              })
            }
          }}
        />
      </ButtonsContainer>
    </ModalWrapper>
  )
}

const ContentHeader = styled(FullWidthHeader)`
  margin-top: 20px;
  margin-bottom: 10px;
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledLozenge = styled(Lozenge)`
  width: 80px;
  margin-left: 0;
  margin-top: 5px;
`

const AttachmentsDate = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #999999;
  margin-top: 2px;
`

const FormStyledItem = styled(FormItem)`
  margin-bottom: 0;
  > textarea {
    height: 38px;
    padding: 0.5rem;
  }
`

const TagSelect = styled(StyledSelect)`
  height: 2.5rem;
  align-self: stretch;
  font-weight: none;
`

export default AttachmentDetailsModal
