import { useState } from 'react'
import AddressForm from 'components/AddressForm'
import { CompoundFormLine, FormItem, FormItems } from 'components/ColumnForm'
import CustomizedTextField from 'components/CustomizedTextField'
import usePrescriberPracticeFormValidation from 'hooks/usePrescriberPracticeFormValidation'
import styled from 'styled-components'
import type { Address } from 'types'
import { formatPhoneInput } from 'utils'

export enum PracticeFormField {
  Name = 1,
  Address = 2,
  Phone = 3,
  Fax = 4,
}

interface Props {
  businessName?: string
  phone?: string
  fax?: string
  address: Address
  onChange: (field: PracticeFormField, value: string | Address) => void
}

const NewPracticeForm = ({ businessName, phone, fax, address, onChange }: Props): JSX.Element => {
  const { errors } = usePrescriberPracticeFormValidation({ phone: phone, ...address })
  const [showErrors, setShowErrors] = useState<Record<string, boolean>>({})

  return (
    <FormItems data-testid="PracticeForm">
      <FormItem data-test-row="practicename">
        <CustomizedTextField
          size="sm"
          label="Practice Name (optional)"
          value={businessName}
          onChange={event => onChange(PracticeFormField.Name, event.target.value)}
        />
      </FormItem>
      <AddressForm
        {...address}
        withCapsule
        onChange={(key: string, value: string) => {
          onChange(PracticeFormField.Address, {
            ...address,
            [key]: value,
          })
        }}
      />
      <FormItem>
        <CompoundFormLine>
          <HalfFormItem data-test-row="phone">
            <CustomizedTextField
              required
              data-testid="phone"
              label="Phone"
              size="sm"
              type="tel"
              value={phone}
              state={showErrors.phone && errors.phone ? 'error' : 'default'}
              helperText={showErrors.phone ? errors.phone : undefined}
              placeholder={'(xxx) xxx - xxxx'}
              onBlur={() => setShowErrors(value => ({ ...value, phone: true }))}
              onChange={event => onChange(PracticeFormField.Phone, formatPhoneInput(event.target.value) || '')}
            />
          </HalfFormItem>
          <HalfFormItem data-test-row="fax">
            <CustomizedTextField
              data-testid="fax"
              label="Fax (optional)"
              size="sm"
              type="tel"
              value={fax}
              placeholder={'(xxx) xxx - xxxx'}
              onChange={event => onChange(PracticeFormField.Fax, formatPhoneInput(event.target.value) || '')}
            />
          </HalfFormItem>
        </CompoundFormLine>
      </FormItem>
    </FormItems>
  )
}

const HalfFormItem = styled(FormItem)`
  width: 50% !important;
`

export default NewPracticeForm
