import { Chip } from '@truepill/react-capsule'
import { useQuery } from '@truepill/tpos-react-router'
import type { ApolloError } from '@truepill/tpos-react-router'
import DropDownOption from 'components/DropDownOption'
import SearchAutoComplete from 'components/SearchAutoComplete'
import { SEARCH_PHARMACISTS } from 'gql'
import useStateWithDebouncedValue from 'hooks/useStateWithDebouncedValue'
import styled from 'styled-components'
import { borderColor } from 'styles/styleVariables'
import type { User } from 'types'

interface PharmacistSearchProps {
  isVisible?: boolean
  hotKey?: string
  isModal?: boolean
  withCapsule?: boolean
  nameSpace?: string
  label?: string
  value?: string
  singleSelection?: boolean
  onSelect?: (user: User) => void
}

interface SuggestionItem {
  error?: string
  loading?: boolean
  suggestion?: User
}

interface UserSearchResponse {
  users?: User[]
}

const MIN_CHARACTERS_EXEC_SEARCH = 3

const getSuggestions = (loading: boolean, data?: UserSearchResponse, error?: ApolloError | undefined) => {
  let options: SuggestionItem[] = []

  if (data?.users?.length) {
    options = data.users.map(user => ({ suggestion: user }))
  } else {
    options = [{ error: `No Result Found` }]
  }

  if (error) {
    options = [
      {
        error: `Error getting Pharmacies: ${JSON.stringify(error)}`,
      },
    ]
  }
  if (loading) {
    options = [{ loading }]
  }

  return options
}

const PharmacistSearch = ({
  isVisible,
  hotKey = 'c',
  isModal,
  label = 'Pharmacist',
  withCapsule = false,
  value,
  onSelect,
}: PharmacistSearchProps): JSX.Element => {
  const [searchTerm, debouncedSearchTerm, setSearchTerm] = useStateWithDebouncedValue('')
  const { data, loading, error } = useQuery<UserSearchResponse>(SEARCH_PHARMACISTS, {
    skip: !debouncedSearchTerm || debouncedSearchTerm.length < MIN_CHARACTERS_EXEC_SEARCH,
    variables: { name: debouncedSearchTerm },
  })

  const options = getSuggestions(loading, data, error)

  return (
    <>
      <SearchAutoComplete
        withCapsule={withCapsule}
        label={label}
        data-testid="pharmacist"
        hotKey={isVisible ? hotKey : ''}
        isModal={isModal}
        iconColor={borderColor}
        placeholder={'Search by first and last name'}
        autoCompleteSuggestions={options}
        value={searchTerm}
        onSelect={(item: SuggestionItem) => {
          setSearchTerm('')
          if (item?.suggestion) {
            onSelect?.(item.suggestion)
          }
        }}
        suggestionComponent={suggestionItem => (
          <AutoCompleteOption
            onClick={suggestionItem.onClick}
            highlighted={suggestionItem.highlighted}
            selected={suggestionItem.selected}
            suggestion={suggestionItem.suggestion}
            error={suggestionItem.error}
            loading={suggestionItem.loading}
          />
        )}
        onChange={setSearchTerm}
      />
      {value && (
        <SearchResultContainer data-testid="pharmacists-results">
          <Chip data-testid="result-item" state="info" size="lg">
            <p>{value}</p>
          </Chip>
        </SearchResultContainer>
      )}
    </>
  )
}

interface AutoCompleteOptionProps {
  error?: string
  loading?: boolean
  suggestion?: User
  highlighted?: boolean
  selected?: boolean
  onClick?: () => void
}

const AutoCompleteOption = ({
  error,
  loading,
  suggestion,
  highlighted,
  onClick,
  selected,
}: AutoCompleteOptionProps): JSX.Element => {
  if (error) {
    return (
      <DropDownOption>
        <p>{error}</p>
      </DropDownOption>
    )
  }

  if (loading) {
    return (
      <DropDownOption>
        <p>Searching...</p>
      </DropDownOption>
    )
  }

  return (
    <DropDownOption highlighted={highlighted} onClick={onClick} selected={selected}>
      <p>
        {suggestion?.firstName} {suggestion?.lastName}
      </p>
    </DropDownOption>
  )
}

const SearchResultContainer = styled.div`
  margin-top: 0.75rem;
  width: 100%;
`

export default PharmacistSearch
