import type {
  Order,
  CopayRequest,
  QueueItem,
  RXFillRequest,
  CopayRequestFill,
  PriorAuthorization,
  CopayTriage,
  OrderTriage,
} from 'types'

export const isPriorAuthorizationItems = (items: QueueItem[] | PriorAuthorization[]): items is PriorAuthorization[] => {
  return isPriorAuthorizaion(items[0])
}

export const isPriorAuthorizaion = (item: QueueItem | PriorAuthorization | undefined): item is PriorAuthorization => {
  if (item === undefined) {
    return false
  }
  return 'actions' in item && 'status' in item
}

export const isCopayItems = (items: QueueItem[] | PriorAuthorization[]): items is CopayRequest[] => {
  return isCopayRequest(items[0])
}

export const isCopayRequest = (item: QueueItem | PriorAuthorization | undefined): item is CopayRequest => {
  if (item === undefined) {
    return false
  }
  return 'copayRequestFills' in item
}

export const isOrderItems = (items: QueueItem[] | PriorAuthorization[]): items is Order[] => {
  return isOrder(items[0])
}

export const isOrder = (item: QueueItem | PriorAuthorization | undefined): item is Order => {
  if (item === undefined) {
    return false
  }
  return 'rxFillRequests' in item
}

export const isOrderTriage = (triage: CopayTriage | OrderTriage | undefined): triage is OrderTriage => {
  if (triage === undefined) {
    return false
  }
  return 'rxFillRequestId' in triage
}
export function getItemFill(item: Order, fillId: string): RXFillRequest | undefined
export function getItemFill(item: CopayRequest, fillId: string): CopayRequestFill | undefined
export function getItemFill(item: Order | CopayRequest, fillId: string): RXFillRequest | CopayRequestFill | undefined {
  return isOrder(item)
    ? item.rxFillRequests?.find(i => i.fillId === fillId)
    : item.copayRequestFills?.find(i => i.fillId === fillId)
}

export const isRxFillRequest = (item: RXFillRequest | CopayRequestFill | undefined): item is RXFillRequest => {
  if (item === undefined) {
    return false
  }
  return 'paymentType' in item
}
