import { CopayTriageReasons, TriageReasons } from '@truepill/tpos-types'

function generateCopayTriageReasonMap() {
  const reasonMap: { [key: string]: CopayTriageReasons } = {}
  Object.entries(CopayTriageReasons).forEach(([key, value]) => {
    reasonMap[key] = value
  })
  return reasonMap
}

function generateTriageReasonMap() {
  const reasonMap: { [key: string]: TriageReasons } = {}
  Object.values(TriageReasons).forEach(reasonCollection => {
    Object.entries(reasonCollection).forEach(([key, value]) => {
      reasonMap[key] = value
    })
  })
  return reasonMap
}

export const TriageReasonMap: { [id: string]: TriageReasons } = generateTriageReasonMap()
export const CopayTriageReasonMap: { [id: string]: CopayTriageReasons } = generateCopayTriageReasonMap()
