import { useParams, Redirect, useQuery } from '@truepill/tpos-react-router'
import LoadingSpinner from 'components/Loading'
import { GET_ORDER_BY_CORE_ORDER_ID } from 'gql'
import { goToViewPharmacyOrder } from 'routes'
import styled from 'styled-components'
import type { OrderQueryResult } from 'types'
import { OrderNotFound } from './OrderNotFound'

export const LoadFromCoreOrderId = (): JSX.Element => {
  const routeParams = useParams<{ coreOrderId: string }>()

  const { data, loading } = useQuery<OrderQueryResult>(GET_ORDER_BY_CORE_ORDER_ID, {
    variables: { orderNumber: parseInt(routeParams.coreOrderId, 10) },
  })

  if (loading) {
    return (
      <LoadingSpinnerContainer>
        <LoadingSpinner />
      </LoadingSpinnerContainer>
    )
  }

  if (data?.order?._id) {
    const orderUrl = goToViewPharmacyOrder({ orderId: data.order._id })
    return <Redirect to={orderUrl} />
  }

  return (
    <OrderNotFound>
      Could not find order for core order id: <strong>{routeParams.coreOrderId}</strong>
    </OrderNotFound>
  )
}

const LoadingSpinnerContainer = styled.div`
  display: flex;
  padding-top: 0.8rem;
  justify-content: center;
  svg {
    height: 25px;
  }
`
