import moment from 'moment'
import type { Log, Order } from 'types'
import { getFormattedTimeSince } from './dates'

export const isShipmentGenerated = (order: Order): boolean => Boolean(order.shipments?.length)
export const isLabelPrinted = (order: Order): boolean =>
  order.logs.some(log => log.event === 'print' && log.message.includes('Printed shipping label'))

export const shippingLabelRequests = (order: Order): Log | undefined =>
  order?.logs?.find(log => log.message === 'Shipping Label Requested')
export const isShippingLabelRequested = (order: Order): boolean => Boolean(shippingLabelRequests(order))
export const shippingLabelPending = (order: Order): boolean =>
  isShippingLabelRequested(order) && !isShipmentGenerated(order)

export enum ShippingLabelStatus {
  Pending = 'Pending',
  NotRequested = 'Not Requested',
  Ready = 'Ready',
}

export const getShippingLabelStatus = (order: Order): ShippingLabelStatus => {
  if (isShipmentGenerated(order)) {
    return ShippingLabelStatus.Ready
  }
  if (shippingLabelPending(order)) {
    return ShippingLabelStatus.Pending
  }
  return ShippingLabelStatus.NotRequested
}

type formatCreatedDateType = {
  date: string
  greaterThan48: string
}
export const formatCreatedDate = (createdAt?: Date): formatCreatedDateType => {
  if (!createdAt) {
    return { date: '-', greaterThan48: '' }
  }
  const dateCreated = moment(createdAt)
  const date = dateCreated.format('MM/DD/YYYY hh:mmA')
  const greaterThan48 = getFormattedTimeSince(createdAt)

  return { date, greaterThan48 }
}
