const filteredWords = [
  'a',
  'allergy',
  'as',
  'certain',
  'mcg',
  'medications',
  'mg',
  'mild',
  'n/a',
  'na',
  'no',
  'none',
  'only',
  'or',
  'other',
  'problems',
  'such',
  'the',
  'to',
  'unspecified',
  'with',
]
const splitRegex = /,(?![^(]*\))\s?/

function filterUserString(input: string) {
  if (!input) return ['']

  const filtered = input
    .toLowerCase()
    .split(splitRegex)
    .map(chunkWhole =>
      chunkWhole
        .split(' ')
        .map(chunk => chunk.trim().replace(/[.]/g, ''))
        .filter(chunk => chunk)
        .filter(chunk => !filteredWords.includes(chunk))
        .join(' '),
    )
    .filter(chunk => chunk)

  return filtered
}

export default filterUserString
