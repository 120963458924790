import type { FC } from 'react'
import { ReactComponent as ClosedEyeIcon } from 'assets/icons/closedEye.svg'
import { ReactComponent as EyeIcon } from 'assets/icons/eye.svg'
import { StyledSelect, RXCell, AlternatingShading, StyledTextInput } from 'components/RXTable'
import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'
import {
  borderColor,
  contrastBackgroundColor,
  primaryActiveButtonBackground,
  primaryBackgroundColor,
  primaryColor,
} from 'styles/styleVariables'

export const applyToOptions = [
  { label: 'Fill', value: 'Fill' },
  { label: 'Prescription', value: 'Prescription' },
]

type RowDisableIconProps = {
  handleClick: () => void
  isVisible: boolean
  enabled: boolean
}

export const RowDisableIcon: FC<RowDisableIconProps> = ({ handleClick, isVisible, enabled }): JSX.Element => {
  if (!isVisible) {
    return <div />
  }

  return (
    <StyledButton styles={PlusIconStyles} onClick={handleClick}>
      {enabled ? <EyeIcon fill={'black'} /> : <ClosedEyeIcon fill={'black'} />}
    </StyledButton>
  )
}
export const PayerFormCell = styled.ul<{
  even?: boolean
}>`
  ${AlternatingShading}
`

export const RXFormInput = styled(StyledTextInput)`
  margin: 0.225rem;
  width: 98%;
`

export const PayerStyledSelect = styled(StyledSelect)`
  margin: 0.225rem;
  width: 98%;
  font-weight: 400;
`

export const PayerTitle = styled.p`
  font-weight: 500;
  margin-left: 0.625rem;
  margin-top: 1rem;
`

export const StyledButton = styled.button<{
  active?: boolean
  styles?: FlattenSimpleInterpolation
}>`
  margin: 0.25rem 0.165rem;
  max-height: 2.5rem;
  max-width: 2.5rem;
  border-radius: 0.3rem;
  background-color: ${contrastBackgroundColor};
  border: 0.1rem solid ${contrastBackgroundColor};
  :hover {
    box-shadow: 0px 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
  }
  :focus {
    outline: 0;
    box-shadow: 0 0 0.625rem ${primaryColor};
  }
  ${({ active }) =>
    active &&
    css`
      background-color: ${primaryActiveButtonBackground};
      color: ${primaryBackgroundColor};
      border: 0.1rem solid ${primaryColor};
      > svg {
        fill: ${primaryBackgroundColor};
      }
      > div {
        > svg {
          fill: ${primaryBackgroundColor};
        }
      }
    `}
  ${({ styles }) => styles}
`

export const RXFormRow = styled(RXCell)`
  grid-template-columns: [label] 13rem [value] 2fr [apply] 1fr;
  line-height: 1.2rem;
`
export const PlusIconStyles = css`
  background-color: ${primaryBackgroundColor};
  border: 0.1rem solid ${primaryBackgroundColor};
`

export const PayerSearchStyleSelect = styled(StyledSelect)`
  font-weight: 400;
  align-self: left;
  div:first-child {
    flex: unset;
    margin-right: 1rem;
    svg {
      fill: ${borderColor};
    }
  }
  svg {
    fill: ${borderColor};
  }
  margin: 0.225rem;
  width: 98%;
`
