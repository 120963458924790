import { ListRowLabel, ListRowValue, RaisedAnchor, RightFilledHeader, PaddingBlock } from 'components/RXTable'
import type { EdiOverrides } from 'types'
import { RXEDIListRow, RXEDImageCell, RXEDIRightEditCell, RXEDIRightTitleCell, RXEDITitleRow, RXTextInput } from '..'

type NarrativeSegmentProps = {
  ediOverrides: EdiOverrides
  updateEdiOverrides: (input: EdiOverrides) => void
}

const NarrativeSegment = ({ ediOverrides, updateEdiOverrides }: NarrativeSegmentProps): JSX.Element => {
  const { narrative } = ediOverrides

  const narrativeMessage = narrative?.narrativeMessage

  return (
    <>
      <NarrativeHeaderRow />
      <RXEDIListRow>
        <RXEDIRightEditCell>
          <ListRowLabel>Narrative Message (BM):</ListRowLabel>
          <ListRowValue>
            <RXTextInput
              value={narrativeMessage ?? ''}
              onChange={({ target: { value: narrativeMessage } }) =>
                updateEdiOverrides({ narrative: { narrativeMessage } })
              }
              placeholder="Narrative Message..."
            />
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>
    </>
  )
}

const NarrativeHeaderRow = () => {
  return (
    <RXEDITitleRow>
      <RXEDImageCell />
      <RXEDIRightTitleCell>
        <PaddingBlock />
        <RightFilledHeader>
          <RaisedAnchor id={'NarrativeSegment'} />
          Narrative segment
          {/* <HotKeyToolTip label={'2'} position={'right'} offsetLeft={1} offsetTop={0} /> */}
        </RightFilledHeader>
      </RXEDIRightTitleCell>
    </RXEDITitleRow>
  )
}

export default NarrativeSegment
