import CloseButton from 'components/CloseButton'
import { SearchSelectedCard } from 'components/ColumnForm'
import styled from 'styled-components'

type SpeciesCardProps = { species: string; closeCallback: () => void; label?: string }

const SpeciesCard = ({ species, closeCallback, label }: SpeciesCardProps): JSX.Element => {
  if (species === '') {
    return <></>
  }

  return (
    <>
      {!!label && <StyleLabel>{label}</StyleLabel>}
      <StyledSearchSelectedCard data-testid="species-selected">
        <strong>{species}</strong>
        <CloseButton size="0.625rem" clickCallback={closeCallback} />
      </StyledSearchSelectedCard>
    </>
  )
}

export default SpeciesCard

const StyleLabel = styled.label`
  font-weight: 700;
  margin-top: 1rem;
  display: block;
  line-height: 1.375;
  font-family: 'Lato', sans-serif;
`

const StyledSearchSelectedCard = styled(SearchSelectedCard)`
  margin-bottom: 0;
  margin-top: 0.425rem;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.625rem 0.8rem;
`
