import { ReactComponent as TimeIcon } from 'assets/icons/time.svg'
import IconWrapper from 'components/IconWrapper'
import MedicalHistory from 'components/MedicalHistory'
import { ModalWrapper, ModalHeader } from 'components/Modal'
import styled, { css } from 'styled-components'
import { bodyPrimaryColor } from 'styles/styleVariables'
import type { Fill, Patient } from 'types'

type MedicalHistoryModalProps = { patientId: Patient['_id']; fillId?: Fill['_id'] }

const MedicalHistoryModal = ({ patientId, fillId }: MedicalHistoryModalProps): JSX.Element => {
  return (
    <ModalWrapper id="MedicationHistoryModal" styles={WrapperMaxWidth}>
      <ModalHeader>
        <Wrapper>
          <IconWrapper>
            <TimeIcon fill={bodyPrimaryColor} />
          </IconWrapper>
          <h2>Medication history</h2>
        </Wrapper>
      </ModalHeader>
      <MedicalContent>
        <MedicalHistory patientIds={patientId} fillId={fillId} />
      </MedicalContent>
    </ModalWrapper>
  )
}

const Wrapper = styled.div`
  margin: 1rem 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  > :first-child {
    margin-right: 0.5rem;
  }
`

const MedicalContent = styled.div`
  margin-right: 1.5rem;
`

const WrapperMaxWidth = css`
  @media screen and (min-width: 1440px) {
    max-width: calc(100vw - 12rem);
  }
  @media screen and (min-width: 1800px) {
    max-width: calc(100vw - 25rem);
  }
`

export default MedicalHistoryModal
