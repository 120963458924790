import { PriorAuthorizationStatus } from '@truepill/tpos-types'
import { ReactComponent as StartPriorAuthIcon } from 'assets/icons/start-prior-auth.svg'
import ActionButton from 'components/ActionButton'
import IconWrapper from 'components/IconWrapper'
import { bodyPrimaryColor } from 'styles/styleVariables'
import type { PriorAuthorization } from 'types'

type StartPriorAuthorizationButtonProps = {
  priorAuthorization?: PriorAuthorization
  isPriorAuthorization?: boolean
  disabled?: boolean
  clickCallback?: (priorAuthorizationId: string) => void
}

export const StartPriorAuthorizationButton = ({
  priorAuthorization,
  isPriorAuthorization = false,
  disabled = false,
  clickCallback,
}: StartPriorAuthorizationButtonProps): JSX.Element => {
  if (!isPriorAuthorization || !priorAuthorization) {
    return <></>
  }

  const priorAuthStatusPending = priorAuthorization?.status === PriorAuthorizationStatus.Pending
  const priorAuthStatusManualStart = priorAuthorization?.status === PriorAuthorizationStatus.ManualStart
  if (!priorAuthStatusPending && !priorAuthStatusManualStart) {
    return <></>
  }

  const priorAuthStartButton = priorAuthorization?.actions.find(action => action.buttonLabel === 'Start')
  if (!priorAuthStartButton) {
    return <></>
  }

  return (
    <ActionButton
      disabled={disabled}
      icon={
        <IconWrapper>
          <StartPriorAuthIcon stroke={bodyPrimaryColor} />
        </IconWrapper>
      }
      label={priorAuthStartButton?.buttonLabel}
      onClick={() => {
        clickCallback?.(priorAuthorization._id)
        window.open(priorAuthStartButton?.url, '_blank')
      }}
    />
  )
}
