import { useContext } from 'react'
import { StoreContext } from 'providers/Store/StoreProvider'

/**
 * @returns Whether the 'show prescription as an image' mode is active, and a
 *          function to set it. Backed by the StoreProvider.
 */
export default function useRxImageIsPic(): [boolean, (value: boolean) => void] {
  const {
    state: { rxImageIsPic },
    actions: { setRxImageIsPic },
  } = useContext(StoreContext)

  return [rxImageIsPic, setRxImageIsPic]
}
