import { CopayStatus } from '@truepill/tpos-types'
import useFulfillmentQueue, { FulfillmentQueueName } from 'hooks/useFulfillmentQueue'
import { usePlusClient } from 'providers/VisionRouter'
import type { OrderSearchVariables } from 'types'
import ExpandedCopayQueueSection from './ExpandedCopayQueueSection'
import ExpandedOrderQueueSection from './ExpandedOrderQueueSection'
import ExpandedPriorAuthorizationQueueSection from './ExpandedPriorAuthorizationQueueSection'
import StatsSection from './StatsSection'

export interface QueryVars {
  customerIds: string[]
  hasOverdueFills: boolean
  hasRobotFills: boolean
  isBatch?: boolean
  ndcs: string[]
  selectedLocationIds?: string[]
  triageReasons?: string[]
}

type QueuesSectionProps = { orderSearchVariables: OrderSearchVariables; priorAuthorizationStringSearch: string }

const QueuesSection = ({ orderSearchVariables, priorAuthorizationStringSearch }: QueuesSectionProps): JSX.Element => {
  const fulfillmentQueue = useFulfillmentQueue()
  const {
    currentLocation: { queryMap },
  } = usePlusClient()
  const { copayStatus } = queryMap
  const isCopay = fulfillmentQueue && fulfillmentQueue.name === FulfillmentQueueName.Copay
  const isPriorAuthorizationOrder = fulfillmentQueue?.name === FulfillmentQueueName.PriorAuthorizationNew
  const isPriorAuthorizationCopay = isCopay && copayStatus === CopayStatus.PriorAuthorization

  if (isPriorAuthorizationOrder || isPriorAuthorizationCopay) {
    return <ExpandedPriorAuthorizationQueueSection search={priorAuthorizationStringSearch} isCopay={isCopay} />
  }

  if (isCopay) {
    return <ExpandedCopayQueueSection orderSearchVariables={orderSearchVariables} />
  }

  if (fulfillmentQueue) {
    return <ExpandedOrderQueueSection orderSearchVariables={orderSearchVariables} />
  }

  return <StatsSection />
}

export default QueuesSection
