import { LaunchDarkly, OrderStatus } from '@truepill/tpos-types'
import { useFlag } from 'providers/LaunchDarklyProvider'
import type { Order } from 'types'
import PackingMaterialsListAccuracy from './PackingMaterialsListAccuracy'
import PackingMaterialsSimpleList from './SimpleList'

const PackingMaterialsList = ({ order }: { order: Order }) => {
  const accuracyScanning = useFlag(LaunchDarkly.FeatureFlags.ENABLE_PACKING_MATERIALS_ACCURACY_SCANNING)

  if (accuracyScanning && order.status === OrderStatus.Packing) {
    return <PackingMaterialsListAccuracy order={order} />
  }

  return <PackingMaterialsSimpleList order={order} />
}

export default PackingMaterialsList
