import { useState, useRef, useCallback, useEffect, createContext } from 'react'
import useAutoUpdatingRef from 'hooks/useAutoUpdatingRef'
import useTypeBuffer from 'hooks/useTypeBuffer'
import type { ChildProps } from 'types'
import { KeyWords } from 'utils'

// Components can use the regsiterHotKey function exposed by HotKeyContext to
// register their own HotKeys with this service. They must also use
// deRegisterHotKey to clean up after the HotKey should no longer be effective.
// Normally you can just use the 'useHotKey' hook in src/hooks/useHotKey.ts
//
// When registering a HotKey, a component must specify what level they want that
// HotKey to be available at. This allows the HotKeyProvider to adjudicate
// whether or not a component should be able to receive a HotKey callback
// depending on what is on screen.
//
// For instance, if a Modal dialog has registered HotKeys (at the modal HotKey
// level), components beneath the Modal which registered Normal level HotKeys
// shouldn't be receiving HotKey callbacks - they're not visible, so the user
// wouldn't expect them to trigger.
//
// Global registered HotKey callbacks are always called no matter what else is
// registered.
//
// It's up to you to decide what level makes sense for the HotKeys you need.
//
// Level Summary:
// Global - effective no matter what is on screen
// Modal - effective no matter what is on screen
// Normal - effective only while no Modal HoyKeys are registered

export enum HotKeyLevel {
  modal = 'MODAL',
  global = 'GLOBAL',
  normal = 'NORMAL',
}

export interface MetaKeys {
  alt?: boolean
  ctrl?: boolean
  shift?: boolean
  meta?: boolean
}

const SHOW_HOTKEYS_KEY = '?'

function metaKeysFromEvent(event: KeyboardEvent): MetaKeys {
  const keys: MetaKeys = {}
  if (event.altKey) {
    keys.alt = true
  }
  if (event.ctrlKey) {
    keys.ctrl = true
  }
  if (event.metaKey) {
    keys.meta = true
  }
  if (event.shiftKey) {
    keys.shift = true
  }
  return keys
}

function metasEqual(a: MetaKeys, b: MetaKeys): boolean {
  const aKeys = Object.keys(a)
  aKeys.sort()
  const bKeys = Object.keys(b)
  bKeys.sort()

  if (aKeys.length !== bKeys.length) {
    return false
  }

  for (let i = 0; i < aKeys.length; i++) {
    const aKey = aKeys[i]
    const bKey = bKeys[i]
    if (aKey !== bKey) {
      return false
    }

    if (!!a[aKey as keyof MetaKeys] !== !!b[bKey as keyof MetaKeys]) {
      return false
    }
  }

  return true
}

// KeyCodes can be found here for reference:
// https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/code/code_values

export interface HotKeyProps {
  registerHotKey: (
    desiredKey: string,
    modalLevel: HotKeyLevel,
    callBack: (key: string, event: KeyboardEvent, wasKeyDown?: boolean) => void,
    registerForKeyDown?: boolean,
    meta?: MetaKeys,
    id?: string,
  ) => void
  deRegisterHotKey: (registeredKey: string, modalLevel: HotKeyLevel, meta?: MetaKeys, id?: string) => void
  showToolTips: boolean
  toggleShowToolTips: () => void
}

interface KeyRegistration {
  key: string
  keyDown?: boolean
  callBack: (key: string, event: KeyboardEvent, wasKeyDown?: boolean) => void
  metaKeys: MetaKeys
  id?: string
}

export const HotKeyContext = createContext<Partial<HotKeyProps>>({})

const keysInLevel = (
  desiredKey: string,
  keysAtLevel: KeyRegistration[],
  withKeyDown?: boolean,
  metaKeys = {},
  id?: string,
): KeyRegistration[] => {
  return keysAtLevel.filter(({ key, keyDown, id: keyId, metaKeys: regMetaKeys }) => {
    return (
      key === desiredKey &&
      !!withKeyDown === !!keyDown &&
      metasEqual(metaKeys, regMetaKeys) &&
      (id ? id === keyId : true)
    )
  })
}

const HotKeyProvider = ({ children }: ChildProps): JSX.Element => {
  const [showToolTips, setShowToolTips] = useState(false)

  const hotKeysRef = useRef({
    NORMAL: [],
    GLOBAL: [],
    MODAL: [],
  })

  const [typingBuffer, addKeyStroke, typingBufferHash, event] = useTypeBuffer(150)

  const handleKeyPress = useCallback((event: KeyboardEvent, withKeyDown?: boolean) => {
    const { key } = event
    const { GLOBAL, MODAL, NORMAL } = hotKeysRef.current

    if (!key) {
      return
    }

    // ? is the global show tooltips key
    if (key === SHOW_HOTKEYS_KEY) {
      if (withKeyDown) {
        setShowToolTips(true)
      } else {
        setShowToolTips(false)
      }
      return
    }

    // No Key Commands when Input Selected UNLESS command is a Meta or an
    // arrow key
    if (
      document?.activeElement?.tagName === 'INPUT' ||
      document?.activeElement?.tagName === 'TEXTAREA' ||
      document?.activeElement?.tagName === 'BUTTON'
    ) {
      // Leave Inputs on Escape globally
      if (key === 'Escape') {
        const activeElement = document.activeElement as HTMLInputElement
        activeElement && activeElement.blur()
      }

      if (!key.match(/Arrow.*/) && !['Enter', 'Backspace'].includes(key)) {
        return
      }
    }

    const metaKeys = metaKeysFromEvent(event)

    // Start Checking registrations
    const globalRegistration = keysInLevel(key, GLOBAL, withKeyDown, metaKeys)
    if (globalRegistration.length) {
      globalRegistration.map(({ callBack }) => callBack(key, event, withKeyDown))
      event.preventDefault && event.preventDefault()
      return
    }

    const modalRegistration = keysInLevel(key, MODAL, withKeyDown, metaKeys)
    if (modalRegistration.length) {
      modalRegistration.map(({ callBack }) => callBack(key, event, withKeyDown))
      event.preventDefault && event.preventDefault()
      return
    }

    // If there are any Modal hotKey registrations, we don't fall through to
    // checking Normal
    if (MODAL.length > 0) {
      return
    }

    const normalRegistration = keysInLevel(key, NORMAL, withKeyDown, metaKeys)
    if (normalRegistration.length) {
      normalRegistration.map(({ callBack }) => callBack(key, event, withKeyDown))
      event.preventDefault && event.preventDefault()
      event.stopPropagation && event.stopPropagation()
    }
  }, [])

  const handleKeyPressRef = useAutoUpdatingRef(handleKeyPress)

  useEffect(() => {
    if (typingBuffer && (typingBuffer.length === 1 || typingBuffer.length === 2 || KeyWords[typingBuffer])) {
      // This is not a scan so we should send it on
      handleKeyPressRef.current(event as KeyboardEvent)
    }
  }, [typingBuffer, handleKeyPressRef, typingBufferHash, event])

  useEffect(() => {
    const handleKeyUp = (event: KeyboardEvent) => {
      // Avoid hotkeys on input or textareas, as they may re render unwanted components
      const target = event.target as Element
      if (!['TEXTAREA', 'INPUT'].includes(target?.nodeName)) {
        addKeyStroke(event)
      }
    }

    const handleKeyDown = (event: KeyboardEvent) => {
      handleKeyPressRef.current(event, true)
    }

    document.addEventListener('keyup', handleKeyUp)
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keyup', handleKeyUp)
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyPressRef, addKeyStroke])

  const toggleShowToolTips = useCallback(() => {
    setShowToolTips(!showToolTips)
  }, [setShowToolTips, showToolTips])

  const deRegisterHotKey = useCallback((keyCodeToRemove: string, hotKeyLevel: HotKeyLevel, meta = {}, id?: string) => {
    const keysAtLevel = hotKeysRef.current[hotKeyLevel]
    const keyRegistrationIndex = keysAtLevel.findIndex(
      ({ key, id: keyId, metaKeys: keyMeta }) => key === keyCodeToRemove && id === keyId && metasEqual(meta, keyMeta),
    )
    if (keyRegistrationIndex === -1) {
      return
    }
    keysAtLevel.splice(keyRegistrationIndex, 1)
    hotKeysRef.current = {
      ...hotKeysRef.current,
      [hotKeyLevel]: keysAtLevel,
    }
    console.debug('dereg', keyCodeToRemove, hotKeyLevel)
  }, [])

  const value: HotKeyProps = {
    showToolTips,
    toggleShowToolTips,
    registerHotKey: (
      desiredKey: string,
      hotKeyLevel: HotKeyLevel,
      callBack: (key: string, event: KeyboardEvent, wasKeyDown?: boolean) => void,
      registerForKeyDown?: boolean,
      metaKeys: MetaKeys = {},
      id?: string,
    ) => {
      const keysAtLevel = hotKeysRef.current[hotKeyLevel]
      if (desiredKey === '') {
        return
      }
      const newRegistration = {
        key: desiredKey,
        callBack,
        // metaKeys only trigger on keydown
        keyDown: Object.keys(metaKeys).length > 0 ? true : registerForKeyDown,
        metaKeys,
        id,
      }
      hotKeysRef.current = {
        ...hotKeysRef.current,
        [hotKeyLevel]: [...keysAtLevel, newRegistration],
      }
      console.debug('--reg', desiredKey, hotKeyLevel, id)
    },
    deRegisterHotKey,
  }

  return <HotKeyContext.Provider value={value}>{children}</HotKeyContext.Provider>
}

export default HotKeyProvider
