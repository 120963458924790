import type { FunctionComponent } from 'react'
import { ListRowLabel, ListRowValue, RightFilledHeader, ListRowEyeButton } from 'components/RXTable'
import type { EdiReversalOverrides } from 'types'
import { RxEdiListRow, RxEdiRowContainer, RxEdiTitleRow, RxTextInput } from '..'
import SuppressFieldButton from '../../SuppressFieldButton'

const CoordinationOfBenefitSegmentEditReversal: FunctionComponent<{
  ediReversalOverrides: EdiReversalOverrides
  updateEdiReversalOverrides: (input: EdiReversalOverrides) => void
}> = ({ ediReversalOverrides, updateEdiReversalOverrides }) => {
  const { coordinationOfBenefits } = ediReversalOverrides
  const otherPayerCoverageType = coordinationOfBenefits?.otherPayerCoverageType
  const otherPayerCoverageTypeValue = otherPayerCoverageType?.value

  const hideOtherPayerCoverageTypeField = otherPayerCoverageType?.send === false

  // These toggle functions handle the state of SupressFieldButton when you click on it
  // and the true/false field we are sending to the backend
  const toggleIsOtherPayerCoverageTypeFieldDisabled = () => {
    updateEdiReversalOverrides({
      coordinationOfBenefits: {
        otherPayerCoverageType: {
          value: otherPayerCoverageTypeValue ?? '',
          send: hideOtherPayerCoverageTypeField,
        },
      },
    })
  }

  return (
    <>
      <CoordinationOfBenefitsBHeaderRow />
      <RxEdiListRow>
        <RxEdiRowContainer>
          <ListRowLabel>Other Payer CoverageType(5C):</ListRowLabel>
          <ListRowValue>
            <RxTextInput
              value={otherPayerCoverageTypeValue ?? ''}
              onChange={({ target: { value: otherPayerCoverageType } }) =>
                updateEdiReversalOverrides({
                  coordinationOfBenefits: {
                    otherPayerCoverageType: { value: otherPayerCoverageType, send: true },
                  },
                })
              }
              placeholder="Other payer CoverageType..."
              disabled={hideOtherPayerCoverageTypeField}
            />
          </ListRowValue>
          <ListRowEyeButton>
            <SuppressFieldButton
              onClick={toggleIsOtherPayerCoverageTypeFieldDisabled}
              hideField={hideOtherPayerCoverageTypeField}
            />
          </ListRowEyeButton>
        </RxEdiRowContainer>
      </RxEdiListRow>
    </>
  )
}

const CoordinationOfBenefitsBHeaderRow = () => {
  return (
    <RxEdiTitleRow>
      <RightFilledHeader>Coordination Of Benefit Segment</RightFilledHeader>
    </RxEdiTitleRow>
  )
}

export default CoordinationOfBenefitSegmentEditReversal
