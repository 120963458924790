import { useEffect, useMemo, useState } from 'react'
import { Checkbox } from '@truepill/react-capsule'
import { useQuery } from '@truepill/tpos-react-router'
import SearchAutoComplete from 'components/SearchAutoComplete'
import { SearchResultLozenge, SearchResultLozengeList } from 'components/Tiles/Lozenge'
import { SEARCH_ORDER_TRIAGE_REASONS, SEARCH_COPAY_TRIAGE_REASONS } from 'gql'
import useFulfillmentQueue from 'hooks/useFulfillmentQueue'
import { usePlusClient } from 'providers/VisionRouter'
import { borderColor } from 'styles/styleVariables'
import type { TriageReasonSearchResult } from 'types'
import { TriageReasonMap, CopayTriageReasonMap } from 'utils'

interface TriageReason {
  key: string
  value: string
}

interface TriageReasonSuggestion extends TriageReason {
  highlighted?: boolean
  selected?: boolean
  suggestionKey: string
}

interface Error {
  error: string
}

interface Loading {
  loading: boolean
}

type TriageReasonSuggestionItem = TriageReasonSuggestion | Error | Loading

interface TriageSearchProps {
  isVisible?: boolean
  hotKey?: string
  isModal?: boolean
  nameSpace?: string
  isCopay?: boolean
}

const TriageSearch = (props: TriageSearchProps): JSX.Element => {
  const { isVisible, hotKey = 'l', isModal, isCopay = false } = props
  const { currentLocation, routeToMergedQuery, tokenContext } = usePlusClient()
  const [searchTerm, setSearchTerm] = useState('')
  const fulfillmentQueue = useFulfillmentQueue()
  const [selectAllText, setSelectAllText] = useState('Select All')

  const query = isCopay ? SEARCH_COPAY_TRIAGE_REASONS : SEARCH_ORDER_TRIAGE_REASONS

  const { data, loading, error } = useQuery(query, {
    variables: {
      locationId: currentLocation.queryMap?.locationId || tokenContext?.locationId,
      orderStages: fulfillmentQueue?.orderStages ?? [],
      fillStages: fulfillmentQueue?.fillStages ?? [],
    },
  })

  const triageMap = isCopay ? CopayTriageReasonMap : TriageReasonMap

  const searchTriageReasons = useMemo(() => {
    if (data?.searchOrderTriageReasons) return data.searchOrderTriageReasons
    if (data?.searchCopayTriageReasons) return data?.searchCopayTriageReasons
    return []
  }, [data?.searchOrderTriageReasons, data?.searchCopayTriageReasons])

  const TriageReasonOptions: TriageReason[] = useMemo(() => {
    return [
      { key: 'All', value: selectAllText },
      ...searchTriageReasons
        .map((searchResult: TriageReasonSearchResult) => {
          // if the reason is not stored as key, just store key as value as well
          return {
            key: searchResult._id,
            value: triageMap[searchResult._id] ? triageMap[searchResult._id] : searchResult._id,
          }
        })
        .sort((a: TriageReason, b: TriageReason) => {
          if (a.value < b.value) return -1
          if (a.value > b.value) return 1
          return 0
        }),
    ]
  }, [selectAllText, searchTriageReasons, triageMap])

  const selectedReasonKeys: string[] = useMemo(() => {
    return currentLocation?.queryMap?.triageReasons ? currentLocation.queryMap.triageReasons : []
  }, [currentLocation?.queryMap?.triageReasons])

  useEffect(() => {
    if (
      selectAllText === 'Select All' &&
      TriageReasonOptions.every(reason => selectedReasonKeys.includes(reason.key))
    ) {
      setSelectAllText('Deselect All')
    }
    if (selectAllText === 'Deselect All' && TriageReasonOptions.length !== selectedReasonKeys.length) {
      setSelectAllText('Select All')
    }
  }, [TriageReasonOptions, selectedReasonKeys, selectAllText, setSelectAllText])
  return (
    <>
      <SearchAutoComplete
        hotKey={isVisible ? hotKey : ''}
        isModal={isModal}
        iconColor={borderColor}
        placeholder={'Search by reason....'}
        autoCompleteSuggestions={
          error
            ? [{ error: `Error searching Triage Reasons: ${JSON.stringify(error)}` }]
            : loading
            ? [{ loading }]
            : TriageReasonOptions?.filter(c => c.value.toLowerCase().startsWith(searchTerm.toLowerCase()))
        }
        value={searchTerm}
        showOptionsOnFocus={true}
        onSelect={(triageReason: TriageReason) => {
          const reasonKey = triageReason.key
          routeToMergedQuery({ triageReasons: [...selectedReasonKeys, reasonKey] })
          setSearchTerm('')
        }}
        suggestionComponent={optionProps => (
          <AutoCompleteOption
            triageReasonSuggestionItem={optionProps as TriageReasonSuggestionItem}
            routeToMergedQuery={routeToMergedQuery}
            selectedReasons={selectedReasonKeys}
            triageReasonOptions={TriageReasonOptions}
          />
        )}
        onChange={setSearchTerm}
        maximumHeight="16rem"
      />
      <SearchResultLozengeList>
        {selectedReasonKeys.map((reasonKey: any) => {
          return (
            <SearchResultLozenge
              key={reasonKey}
              closeCallback={() => {
                if (selectedReasonKeys.includes(reasonKey)) {
                  const newReasonKeys = [...selectedReasonKeys]
                  newReasonKeys.splice(selectedReasonKeys.indexOf(reasonKey), 1)
                  if (reasonKey === 'All') {
                    routeToMergedQuery({ triageReasons: [] })
                  } else routeToMergedQuery({ triageReasons: newReasonKeys })
                }
              }}
            >
              <p>{triageMap[reasonKey] || reasonKey}</p>
            </SearchResultLozenge>
          )
        })}
      </SearchResultLozengeList>
    </>
  )
}
type AutoCompleteOptionProps = {
  triageReasonSuggestionItem: TriageReasonSuggestionItem
  selectedReasons: string[]
  triageReasonOptions: TriageReason[]
  routeToMergedQuery: (toMerge: any) => void
}

const AutoCompleteOption = (props: AutoCompleteOptionProps) => {
  const { triageReasonSuggestionItem, selectedReasons, triageReasonOptions, routeToMergedQuery } = props
  if ((triageReasonSuggestionItem as Error).error) {
    return (
      <Checkbox {...props}>
        <p>{(triageReasonSuggestionItem as Error).error}</p>
      </Checkbox>
    )
  }

  if ((triageReasonSuggestionItem as Loading).loading) {
    return (
      <Checkbox {...triageReasonSuggestionItem}>
        <p>Searching...</p>
      </Checkbox>
    )
  }

  const triageReason = triageReasonSuggestionItem as TriageReasonSuggestion
  return (
    <Checkbox
      css={autoCompleteListItemCSS}
      label={triageReason.value}
      checked={selectedReasons.includes(triageReason.suggestionKey)}
      onCheckedChange={checked => {
        if (checked) {
          if (triageReason.suggestionKey === 'All') {
            routeToMergedQuery({ triageReasons: triageReasonOptions.map(reason => reason.key) })
          } else {
            if (selectedReasons.length + 2 === triageReasonOptions.length) {
              routeToMergedQuery({ triageReasons: triageReasonOptions.map(reason => reason.key) })
            } else {
              routeToMergedQuery({ triageReasons: [...selectedReasons, triageReason.suggestionKey] })
            }
          }
        } else {
          if (triageReason.suggestionKey === 'All') {
            routeToMergedQuery({ triageReasons: [] })
          } else {
            if (selectedReasons.includes(triageReason.suggestionKey)) {
              const remainingKeys = selectedReasons.filter(
                reason => reason !== triageReason.suggestionKey && reason !== 'All',
              )
              routeToMergedQuery({ triageReasons: remainingKeys })
            }
          }
        }
      }}
    >
      <p>{triageReason.value}</p>
    </Checkbox>
  )
}

const autoCompleteListItemCSS = {
  margin: '0.15rem',
  border: '1rem',
}

export default TriageSearch
