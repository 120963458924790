import { useQuery } from '@truepill/tpos-react-router'
import { LaunchDarkly } from '@truepill/tpos-types'
import { GET_NDC_PACKAGE } from 'components/ReviewPrescription'
import {
  ListRowLabel,
  ListRowValue,
  SmallPaddingBlock,
  RaisedAnchor,
  RXImageCell,
  ImageFilledHeader,
  RXListRowSingle,
} from 'components/RXTable'
import moment from 'moment'
import { useFlag } from 'providers/LaunchDarklyProvider'
import styled from 'styled-components'
import type { Fill, NdcFullInfoPackage, Prescription } from 'types'
import { checkIfProvided, getNdcText } from 'utils'

type ReviewPrescriptionProps = {
  prescription: Prescription
  fill: Fill
}

const ReviewPrescription = ({ prescription, fill }: ReviewPrescriptionProps): JSX.Element => {
  const tempDisplayPackSizeWithNdc = useFlag(LaunchDarkly.FeatureFlags.TEMP_DISPLAY_PACK_SIZE_WITH_NDC)
  const hypenhateNdc = useFlag(LaunchDarkly.FeatureFlags.TEMP_HYPHENATE_NDCS)

  const { data } = useQuery(GET_NDC_PACKAGE, {
    variables: { ndcs: [prescription.ndc] },
  })

  const ndcPackage = data?.getNdcPackage?.[0] as NdcFullInfoPackage
  const ndcText = getNdcText(ndcPackage, tempDisplayPackSizeWithNdc, hypenhateNdc)

  return (
    <>
      <TitleRow>
        <SmallPaddingBlock />
        <RXImageInsuranceFilledHeader>
          <RaisedAnchor id={`ReviewPrescription`} />
          Prescription
        </RXImageInsuranceFilledHeader>
      </TitleRow>
      <RXListRowSingle>
        <RXImageCell closeOffTopBorder>
          <ListRowLabel>Medication:</ListRowLabel>
          <ListRowValue>{prescription.name}</ListRowValue>
        </RXImageCell>
      </RXListRowSingle>
      <RXListRowSingle>
        <RXImageCell>
          <ListRowLabel>NDC:</ListRowLabel>
          <ListRowValue>{ndcText}</ListRowValue>
        </RXImageCell>
      </RXListRowSingle>
      <RXListRowSingle>
        <RXImageCell>
          <ListRowLabel>Qty:</ListRowLabel>
          <ListRowValue>{prescription.quantity}</ListRowValue>
        </RXImageCell>
      </RXListRowSingle>
      <RXListRowSingle>
        <RXImageCell>
          <ListRowLabel>DS:</ListRowLabel>
          <ListRowValue>{checkIfProvided(prescription.daysSupply)}</ListRowValue>
        </RXImageCell>
      </RXListRowSingle>
      <RXListRowSingle>
        <RXImageCell>
          <ListRowLabel>Refills:</ListRowLabel>
          <ListRowValue>{prescription.numberOfRefills}</ListRowValue>
        </RXImageCell>
      </RXListRowSingle>
      <RXListRowSingle>
        <RXImageCell>
          <ListRowLabel>Directions:</ListRowLabel>
          <ListRowValue>{prescription.directions}</ListRowValue>
        </RXImageCell>
      </RXListRowSingle>
      <RXListRowSingle>
        <RXImageCell>
          <ListRowLabel>Written:</ListRowLabel>
          <ListRowValue>{moment(prescription.writtenDate).format('MM/DD/YYYY')}</ListRowValue>
        </RXImageCell>
      </RXListRowSingle>
      <RXListRowSingle>
        <RXImageCell>
          <ListRowLabel>Fill:</ListRowLabel>
          <ListRowValue>{moment(fill.dispensedAt).format('MM/DD/YYYY')}</ListRowValue>
        </RXImageCell>
      </RXListRowSingle>
      <RXListRowSingle>
        <RXImageCell>
          <ListRowLabel>Expiration:</ListRowLabel>
          <ListRowValue>{moment(prescription.writtenDate).add(1, 'year').format('MM/DD/YYYY')}</ListRowValue>
        </RXImageCell>
      </RXListRowSingle>
      <RXListRowSingle>
        <RXImageCell>
          <ListRowLabel>Effective:</ListRowLabel>
          <ListRowValue>
            {prescription.effectiveDate
              ? moment(prescription.effectiveDate).add(1, 'year').format('MM/DD/YYYY')
              : 'None provided'}
          </ListRowValue>
        </RXImageCell>
      </RXListRowSingle>
      <RXListRowSingle>
        <RXImageCell>
          <ListRowLabel>Notes:</ListRowLabel>
          <ListRowValue>{checkIfProvided(prescription.note)}</ListRowValue>
        </RXImageCell>
      </RXListRowSingle>
      <RXListRowSingle>
        <RXImageCell>
          <ListRowLabel>Triplicate:</ListRowLabel>
          <ListRowValue>{checkIfProvided(prescription.triplicate)}</ListRowValue>
        </RXImageCell>
      </RXListRowSingle>
      <RXListRowSingle>
        <RXImageCell closeOffBottomBorder>
          <ListRowLabel>ICD 10:</ListRowLabel>
          <ListRowValue>{checkIfProvided(prescription.icd10s?.map(x => x.code).join(','))}</ListRowValue>
        </RXImageCell>
      </RXListRowSingle>
    </>
  )
}

const RXImageInsuranceFilledHeader = styled(ImageFilledHeader)`
  grid-column: 1 / span 3;
  margin-bottom: 1rem;
`

const TitleRow = styled.div``

export default ReviewPrescription
