import { useMutation } from '@truepill/tpos-react-router'
import { PriorAuthorizationStatus } from '@truepill/tpos-types'
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg'
import ActionButton from 'components/ActionButton'
import IconWrapper from 'components/IconWrapper'
import { INITIATE_PA } from 'gql'
import useErrorToast from 'hooks/toast/useErrorToast'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import { bodyPrimaryColor } from 'styles/styleVariables'
import type { PriorAuthorization, QueueItem, Prescription, Fill } from 'types'
import { getPrescriberAddressWithFax, isOrder } from 'utils'

type CreatePriorAuthorizationActionButtonProps = {
  priorAuthorization?: PriorAuthorization
  isPriorAuthorization?: boolean
  disabled?: boolean
  item: QueueItem
  fill: Fill
  prescription: Prescription
}

export const CreatePriorAuthorizationButton = ({
  priorAuthorization,
  isPriorAuthorization = false,
  disabled = false,
  item,
  fill,
  prescription,
}: CreatePriorAuthorizationActionButtonProps): JSX.Element => {
  const showErrorToast = useErrorToast()
  const showSuccessToast = useSuccessToast()

  const [initiatePriorAuthorization, { loading }] = useMutation(INITIATE_PA, {
    onCompleted() {
      showSuccessToast('Prior authorization created successfully.')
    },
    onError(err) {
      showErrorToast(err.message)
    },
  })

  if (!isPriorAuthorization || !priorAuthorization) {
    return <></>
  }

  const isPriorAuthExpired = [PriorAuthorizationStatus.Inactive, PriorAuthorizationStatus.Cancelled].includes(
    priorAuthorization?.status,
  )

  if (!isPriorAuthExpired) {
    return <></>
  }

  const claim = fill.claims[fill.claims.length - 1]
  const rejectCodes = claim?.received?.groups?.[0]?.response?.rejectCodes
  const rejectCode = (rejectCodes?.map((rc: { rejectCode: string }) => rc.rejectCode) || []).join(',')

  //"claim rejected" should only be when the claim rejects for multiple reject reasons or rejectCodeTranslated is empty
  const rejectMessage =
    rejectCodes?.length === 1 && rejectCodes[0]?.rejectCodeTranslated
      ? rejectCodes[0].rejectCodeTranslated
      : 'claim rejected'

  const submitNewPriorAuthorization = () => {
    const address = getPrescriberAddressWithFax(prescription)
    initiatePriorAuthorization({
      variables: {
        rxNumber: prescription.rxNumber,
        npi: fill.location.npi,
        prescriptionId: prescription._id,
        claimId: claim._id,
        fillId: fill._id,
        locationId: fill.locationId,
        customerId: prescription.customerId,
        prescriberId: prescription.prescriberId,
        claimEdi: claim.sent.edi,
        rejectCode,
        rejectMessage,
        prescriberFax: address?.fax,
        medicationName: prescription.name,
        patientId: prescription.patientId,
        orderId: isOrder(item) ? item._id : undefined,
        copayRequestId: isOrder(item) ? undefined : item._id,
        force: true,
      },
    })
  }

  return (
    <ActionButton
      disabled={disabled || loading}
      icon={
        <IconWrapper>
          <PlusIcon fill={bodyPrimaryColor} />
        </IconWrapper>
      }
      label={loading ? 'Creating...' : 'New PA'}
      onClick={submitNewPriorAuthorization}
    />
  )
}
