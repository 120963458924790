import type { ReactNode } from 'react'
import styled from 'styled-components'
import { contrastBackgroundColor, primaryBackgroundColor } from 'styles/styleVariables'

interface HorizontalItemListProps<T, Y> {
  items: T[]
  mapItem?: (item: T) => Y
  renderItem: (item: Y) => JSX.Element
  getItemKey: (item: Y) => string
  children?: ReactNode
}

const HorizontalItemList = <T, Y>({
  items,
  renderItem,
  children,
  mapItem,
  getItemKey,
}: HorizontalItemListProps<T, Y>) => {
  if (!items.length) {
    return <Container></Container>
  }

  return (
    <Container>
      {items.map(_item => {
        const item = mapItem ? mapItem(_item) : (_item as unknown as Y)
        return <Item key={getItemKey(item)}>{renderItem?.(item)}</Item>
      })}
      {children}
    </Container>
  )
}

export const Container = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: ${contrastBackgroundColor};
  border-radius: 8px;
`

export const Item = styled.li`
  margin: 8px;
  padding: 1rem;
  background-color: ${primaryBackgroundColor};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  flex-basis: calc(33.333% - 1rem);
`

export default HorizontalItemList
