import { FormItem, FormItems } from 'components/ColumnForm'
import { NumericInput } from 'components/NumericInput'
import type { InsuranceProvider } from 'types'
import { FormItemDiscount, DiscountContainer, InputWithLabel } from './StyledComponents'
import { updatePayerFormConfigurations } from './util'

type PayerPricingFormProps = {
  payerForm: InsuranceProvider
  updatePayerForm: (update: InsuranceProvider) => void
}

const PayerPricingForm = ({ payerForm, updatePayerForm }: PayerPricingFormProps): JSX.Element => (
  <FormItems data-testid="PayerPricingForm">
    <FormItem data-test-row="max-day-supply">
      <p>Claim processing fee</p>
      <NumericInput
        value={payerForm.configurations?.pricing?.claimProcessingFee}
        onChange={value =>
          updatePayerFormConfigurations(payerForm, updatePayerForm, 'pricing', 'claimProcessingFee', value)
        }
      />
    </FormItem>
    <FormItemDiscount data-test-row="brand-discount">
      <p>Brand</p>
      <DiscountContainer>
        <div>
          Discount
          <InputWithLabel>
            <NumericInput
              value={payerForm.configurations?.pricing?.brandDiscount}
              onChange={value =>
                updatePayerFormConfigurations(payerForm, updatePayerForm, 'pricing', 'brandDiscount', value)
              }
            />
            %
          </InputWithLabel>
        </div>
        <div>
          Max discount
          <NumericInput
            value={payerForm.configurations?.pricing?.brandMaxDiscount}
            onChange={value =>
              updatePayerFormConfigurations(payerForm, updatePayerForm, 'pricing', 'brandMaxDiscount', value)
            }
          />
        </div>
      </DiscountContainer>
    </FormItemDiscount>
    <FormItemDiscount data-test-row="generic-discount">
      <p>Generic</p>
      <DiscountContainer>
        <div>
          Discount
          <InputWithLabel>
            <NumericInput
              value={payerForm.configurations?.pricing?.genericDiscount}
              onChange={value =>
                updatePayerFormConfigurations(payerForm, updatePayerForm, 'pricing', 'genericDiscount', value)
              }
            />
            %
          </InputWithLabel>
        </div>
        <div>
          Max discount
          <NumericInput
            value={payerForm.configurations?.pricing?.genericMaxDiscount}
            onChange={value =>
              updatePayerFormConfigurations(payerForm, updatePayerForm, 'pricing', 'genericMaxDiscount', value)
            }
          />
        </div>
      </DiscountContainer>
    </FormItemDiscount>
    <FormItemDiscount data-test-row="compound-discount">
      <p>Compound</p>
      <DiscountContainer>
        <div>
          Discount
          <InputWithLabel>
            <NumericInput
              value={payerForm.configurations?.pricing?.compoundDiscount}
              onChange={value =>
                updatePayerFormConfigurations(payerForm, updatePayerForm, 'pricing', 'compoundDiscount', value)
              }
            />
            %
          </InputWithLabel>
        </div>
        <div>
          Max discount
          <NumericInput
            value={payerForm.configurations?.pricing?.compoundMaxDiscount}
            onChange={value =>
              updatePayerFormConfigurations(payerForm, updatePayerForm, 'pricing', 'compoundMaxDiscount', value)
            }
          />
        </div>
      </DiscountContainer>
    </FormItemDiscount>
  </FormItems>
)

export default PayerPricingForm
