import { useMutation } from '@truepill/tpos-react-router'
import type { EntityId, OutboundTransferTriage } from '@truepill/tpos-types'
import { NoResultsEntry } from 'components/PageStructure'
import { TitleRow, TriageEntryGeneric as TriageEntry, TriageIssueContainer } from 'components/TriageIssues'
import { RESOLVE_OUTBOUND_TRANSFER_TRIAGE } from 'gql'
import useErrorToast from 'hooks/toast/useErrorToast'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import styled from 'styled-components'
import { contrastColor } from 'styles/styleVariables'

const TriageIssueTable = ({
  triages,
  outboundTransferId,
}: {
  triages: OutboundTransferTriage[]
  outboundTransferId: EntityId
}): JSX.Element => {
  const showSuccessToast = useSuccessToast()
  const showErrorToast = useErrorToast()
  const { dismissModal } = useModalContext()

  const [resolveTriage] = useMutation(RESOLVE_OUTBOUND_TRANSFER_TRIAGE, {
    onCompleted: () => {
      showSuccessToast('Operation is successful')
      dismissModal()
    },
    refetchQueries: ['getBasicOutboundTransfer'],
    onError: error => {
      console.error('Failed to resolve triage:', error)
      showErrorToast(`Failed to resolve triage: ${error?.message ?? error}`)
      dismissModal()
    },
  })

  return (
    <TriageIssueContainer>
      <h2>Triage issues</h2>

      <TriageContainer>
        <TitleRow />
        {triages &&
          triages.map((triage, index) => {
            const isResolved = !!triage.endDate

            return (
              <TriageEntry
                key={triage._id.toString()}
                isAlreadyResolved={isResolved}
                triageNumber={index.toString()}
                triageReason={triage.reason}
                triageEndDate={triage.endDate}
                triageType={triage.code}
                triageMessage={triage.message}
                userPlaced={triage.userPlaced}
                userRemoved={triage.userRemoved}
                onResolveClick={() => {
                  resolveTriage({
                    variables: {
                      outboundTransferId,
                      triageId: triage._id.toString(),
                    },
                  })
                }}
              />
            )
          })}
        {triages?.length === 0 && <NoResultsEntry> No results </NoResultsEntry>}
      </TriageContainer>
    </TriageIssueContainer>
  )
}

const TriageContainer = styled.div`
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  align-items: stretch;
  :last-child {
    border-bottom: 0.25rem solid ${contrastColor};
    border-radius: 0.25rem;
  }
  flex: 1;
`

export default TriageIssueTable
