import { useEffect } from 'react'
import { useQuery } from '@truepill/tpos-react-router'
import DropDownOption, { AutoScrollElement } from 'components/DropDownOption'
import SearchAutoComplete from 'components/SearchAutoComplete'
import { SearchResultLozenge, SearchResultLozengeList } from 'components/Tiles/Lozenge'
import { SEARCH_ICD10 } from 'gql'
import useStateWithDebouncedValue from 'hooks/useStateWithDebouncedValue'
import styled, { css } from 'styled-components'
import { alertRed, bodySecondaryColor } from 'styles/styleVariables'
import type { Icd10 } from 'types'
import useMultiSelection from './useMultiSelection'

interface SuggestionICD10 {
  _id: string
  main: string
  decimal: string
  code: string
  text: string
  highlighted?: boolean
}

interface Error {
  error: string
}

interface Loading {
  loading: boolean
}

type ICD10SuggestionItem = SuggestionICD10 | Error | Loading

type Icd10SearchProps = {
  isModal?: boolean
  initialCodes?: Icd10[]
  updateIcd10Codes: (codes: Record<string, Icd10>) => void
  withCapsule?: boolean
}

const Icd10Search = ({
  updateIcd10Codes,
  isModal,
  initialCodes = [],
  withCapsule = false,
}: Icd10SearchProps): JSX.Element => {
  const [searchTerm, debouncedSearchTerm, setSearchTerm] = useStateWithDebouncedValue('')

  const { addSelection, deleteSelection, selections } = useMultiSelection<Icd10>(
    initialCodes.map(icd => ({ id: icd._id, value: icd })),
  )

  useEffect(() => {
    updateIcd10Codes(selections)
  }, [selections])

  const { data, loading, error } = useQuery(SEARCH_ICD10, {
    variables: { term: debouncedSearchTerm },
  })

  let options: ICD10SuggestionItem[] = data ? data.searchICD10 : []

  if (error) {
    options = [{ error: `Error getting ICD10s: ${JSON.stringify(error)}` }]
  }

  if (loading) {
    options = [{ loading }]
  }

  return (
    <SearchAutoComplete
      data-testid="icd10"
      autoCompleteSuggestions={options}
      hotKey="i"
      iconColor={bodySecondaryColor}
      isModal={isModal}
      placeholder={'Search ICD10 codes...'}
      retainFocusAfterSelection
      value={searchTerm}
      onChange={setSearchTerm}
      withCapsule={withCapsule}
      suggestionComponent={optionProps => <AutoCompleteOption {...(optionProps as ICD10SuggestionItem)} />}
      onSelect={icd10 => {
        addSelection({ id: icd10._id, value: icd10 })
        setSearchTerm('')
      }}
    >
      <PaddedSearchResultLozengeList data-testid={`icd10-results`}>
        {Object.keys(selections).map(
          id =>
            id !== '' && (
              <SearchResultLozenge data-testid="result-item" key={id} closeCallback={() => deleteSelection(id)}>
                <p>{labelForIcd10(selections[id])}</p>
              </SearchResultLozenge>
            ),
        )}
      </PaddedSearchResultLozengeList>
    </SearchAutoComplete>
  )
}

function labelForIcd10(icd10: Icd10): string {
  return `${icd10.code} - ${icd10.text}`
}

const AutoCompleteOption = (props: ICD10SuggestionItem): JSX.Element => {
  if ((props as Error).error) {
    return (
      <AutoScrollElement>
        <DropDownOption {...props}>
          <p>{(props as Error).error}</p>
        </DropDownOption>
      </AutoScrollElement>
    )
  }

  if ((props as Loading).loading) {
    return (
      <AutoScrollElement>
        <DropDownOption>
          <p>Searching...</p>
        </DropDownOption>
      </AutoScrollElement>
    )
  }

  if (!(props as SuggestionICD10)._id) {
    return <></>
  }

  const icd10 = props as SuggestionICD10

  return (
    <AutoScrollElement selected={icd10.highlighted}>
      <DropDownOption {...icd10}>
        <p>
          {icd10.main} - {icd10.text}
        </p>
        <p>{icd10.code}</p>
      </DropDownOption>
    </AutoScrollElement>
  )
}

const DeleteMode = css`
  border-color: ${alertRed};
`

const PaddedSearchResultLozengeList = styled(SearchResultLozengeList)<{
  deleteMode?: boolean
}>`
  width: 100%;
  margin-top: 0rem;
  overflow: hidden;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  :not(:empty) {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
  }
  > li {
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
    :last-of-type {
      ${({ deleteMode }) => deleteMode && DeleteMode};
    }
  }
  overflow-wrap: break-word;
`

export default Icd10Search
