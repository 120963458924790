import type { FC } from 'react'
import { useState } from 'react'
import { Button, Select } from '@truepill/react-capsule'
import CustomizedTextField from 'components/CustomizedTextField'
import DatePicker from 'components/DatePicker'
import moment from 'moment'
import { Eye, EyeOff } from 'react-feather'
import styled from 'styled-components'
import type { InsuranceOverrideCob } from 'types'
import { applyToOptions } from './COBStyleCommon'

type ValueProps = Date | boolean | string
type OtherPayerDateProps = {
  overrideCOB: Partial<InsuranceOverrideCob>
  handleChange: (name: keyof InsuranceOverrideCob, key: string, value?: ValueProps, index?: number) => void
}

const OtherPayerDate: FC<OtherPayerDateProps> = ({ overrideCOB, handleChange }) => {
  const otherPayerDate = overrideCOB.otherPayerDate
  const [sendFlag, setSendFlag] = useState(otherPayerDate?.send === undefined || !!otherPayerDate?.send)

  const toggleIsDisabled = () => {
    handleChange('otherPayerDate', 'send', !otherPayerDate?.send)
    setSendFlag(!!otherPayerDate?.send)
  }

  const inputStyleSelect = {
    margin: '0',
  }

  const inputStyle = {
    margin: '8px 0 0 0',
  }

  return (
    <PayerFormElement>
      <PayerFormElementLabel>Other payer date (E8)</PayerFormElementLabel>
      <Payer3Columns>
        <DatePicker
          date={moment(otherPayerDate?.value || new Date(), 'YYYY/MM/DD').toDate()}
          onChange={date => {
            handleChange('otherPayerDate', 'value', date)
          }}
          disabled={!sendFlag}
          useCapsule={true}
        />
        {sendFlag ? (
          <Select
            id={`#StyledSelect-d`}
            options={applyToOptions}
            isOptionDisabled={option => !sendFlag}
            label=""
            required
            selectedKey="label"
            placeholder=""
            onChange={option => {
              const value = option?.value
              handleChange('otherPayerDate', 'applyTo', value)
            }}
            value={applyToOptions.find(i => i.value === otherPayerDate?.applyTo)}
            css={inputStyleSelect}
            variant="small"
          />
        ) : (
          <CustomizedTextField
            id={`#StyledSelect-d`}
            placeholder=""
            value={applyToOptions.find(i => i.value === otherPayerDate?.applyTo)?.label}
            disabled={true}
            style={inputStyle}
            size="sm"
          />
        )}
        <Button
          size="xs"
          style={{
            borderRadius: '100%',
            backgroundColor: 'rgb(238, 238, 238)',
            borderColor: 'rgb(238, 238, 238)',
            height: '3.5rem',
            width: '3.5rem',
            verticalAlign: 'center',
            color: 'var(--cap-colors-primary-700)',
            textAlign: 'center',
            margin: '2px 0 0 0.25rem',
          }}
          onClick={toggleIsDisabled}
        >
          {sendFlag ? <Eye aria-hidden /> : <EyeOff aria-hidden />}
        </Button>
      </Payer3Columns>
    </PayerFormElement>
  )
}

export const DateCellPadding = styled.div``

export const ThreeColumnContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-rows: [content] 100%;
  grid-template-columns: [value] 13% [value] 110% [value1] 25%;
`

export default OtherPayerDate

const PayerFormElementLabel = styled.div`
  font-weight: 500;
  font-size: 0.875rem;
  display: block;
  width: 100%;
  margin: 12px 0 0 0;
`

const PayerColumns = styled.div`
  width: 100%;
  display: grid;
  align-items: flex-start;
  margin: 0;
  grid-gap: 0.25rem;
  vertical-align: baseline;
`

const Payer3Columns = styled(PayerColumns)`
  grid-template-columns: [col1] 64% [col2] 28% [col3] 8%;
`

const PayerFormElement = styled.div``
