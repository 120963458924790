import { makeVar } from '@truepill/tpos-react-router'
import { ClaimDataPaidStatus } from '@truepill/tpos-types'
import moment from 'moment'
import type {
  Fill,
  Order,
  Patient,
  Claim,
  ClaimsRequest,
  EdiOverrides,
  ClaimsSubmissionResponse,
  FillEdiPricing,
  ClaimsGroupPricing,
} from 'types'

const defaultClaim: Partial<Claim> = {}

export const originalClaimsRequest = makeVar({})
export const claimsRequest = makeVar<ClaimsRequest | undefined>(undefined)

export const updateClaimsRequest = (update: Partial<ClaimsRequest>): void => {
  const existingClaimsRequest = claimsRequest() as ClaimsRequest
  claimsRequest({ ...existingClaimsRequest, ...update })
}

export const maxPayers = 4

const mapRequest = (claim?: Partial<Claim>, fillEdiPricing?: Partial<FillEdiPricing>): EdiOverrides => {
  const claimSent = claim?.sent

  return {
    header: {
      dateOfService: claimSent?.header?.dateOfService && moment(claimSent.header?.dateOfService).format('MM/DD/YYYY'),
    },
    pricing: {
      ingredientCostSubmitted: fillEdiPricing?.ingredientCostSubmitted || undefined,
      dispensingFeeSubmitted: fillEdiPricing?.dispensingFeeSubmitted || undefined,
      usualAndCustomaryCharge: fillEdiPricing?.usualAndCustomaryCharge || undefined,
      grossAmountDue: fillEdiPricing?.grossAmountDue || undefined,
      basisOfCostDetermination: fillEdiPricing?.basisOfCostDetermination || undefined,
    },
  }
}

export const mapSubmissionResponse = (newClaim: Claim | undefined): ClaimsSubmissionResponse => ({
  rejectionMessages: newClaim?.received?.groups?.[0]?.response.rejectCodes || [],
  additionalInfo: newClaim?.received?.groups?.[0]?.response.additionalMessageInformation || '',
  nextAvailableFillDate: newClaim?.received?.groups?.[0]?.response?.nextAvailableFillDate,
  successfulClaim: ClaimDataPaidStatus.includes(newClaim?.status ?? ''),
  paymentResponse: newClaim?.received?.groups?.[0]?.pricingResponse,
})

export const createClaimsRequest = (
  claim?: Partial<Claim>,
  orderId?: string,
  fillId?: string,
  displayPricing?: Partial<ClaimsGroupPricing> | Partial<FillEdiPricing>,
): ClaimsRequest => ({
  claimsSubmissionResponse: claim ? mapSubmissionResponse(claim as Claim) : undefined,
  orderId: orderId ?? '',
  fillId: fillId ?? '',
  originalClaimsRequest: mapRequest(claim, displayPricing),
  claimsSubmitting: false,
})

export default function useClaim({ fill, order }: { fill?: Fill; order?: Order; patient?: Patient } = {}): void {
  // only set the original claims request once on page load
  const existingClaimsRequest = claimsRequest() as ClaimsRequest
  if (
    !existingClaimsRequest ||
    existingClaimsRequest.orderId?.toString() !== order?._id?.toString() ||
    existingClaimsRequest.fillId?.toString() !== fill?._id?.toString()
  ) {
    const lastClaimSent = fill?.claims?.length ? fill.claims[fill.claims.length - 1] : defaultClaim
    const fillEdiPricing = fill?.ediPricing
    const lastClaimPricingGroup = lastClaimSent?.sent?.groups?.[0]?.pricing
    // display the pricing from the previous claim or fall back to default fill pricing
    const displayPricing = lastClaimPricingGroup || fillEdiPricing

    const request = createClaimsRequest(lastClaimSent, order?._id, fill?._id, displayPricing)

    claimsRequest(request)
  }
}
