import type { ChangeEvent } from 'react'
import { useRef, useState } from 'react'
import { StyledTextInput } from 'components/RXTable'
import styled, { css } from 'styled-components'
import { usdFormatter } from 'utils'
import CustomizedTextField from './CustomizedTextField'

const containerMargin = css`
  margin: 0.225rem;
`

const getNumberValue = (value: string) => parseFloat(value.charAt(0) === '$' ? value.substr(1) : value)

export const RXTextInput = styled(StyledTextInput)`
  ${containerMargin}
`
export const USDInput = ({
  onChange,
  placeholder,
  value,
  disabled,
}: {
  onChange: (value: number | undefined) => void
  placeholder: string
  value?: number
  disabled?: boolean
}): JSX.Element => {
  const lastValue = useRef<number | undefined>(value)
  // undefined will cause the field to be blank and show the placeholder
  const formatted = value === undefined || value === null ? '' : usdFormatter.format(value)
  const lastEditValue = useRef(formatted)
  const [update, triggerUpdate] = useState(0)

  let activeValue: string

  // has this component been updated from the outside? (controlled component)
  if (value !== lastValue.current) {
    activeValue = formatted
    lastEditValue.current = activeValue
    const numberValue = getNumberValue(activeValue)
    lastValue.current = numberValue
  } else {
    activeValue = lastEditValue.current
  }

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    lastEditValue.current = value

    if (value === '') {
      triggerUpdate(update + 1)
      onChange(undefined)
      return
    }

    // store the last edit value so we can avoid causing the cursor to be moved to the end of the input

    // parse the float value
    const numberValue = getNumberValue(value)

    // trigger on onChange event if we have a valid number
    if (!Number.isNaN(numberValue)) {
      lastValue.current = numberValue
      onChange(numberValue)
    } else {
      // not a valid number but we need to trigger an update so the input value will change
      triggerUpdate(update + 1)
    }
  }

  const handleOnBlur = () => {
    // wipe the last value and trigger an update to trigger a format
    lastValue.current = undefined
    triggerUpdate(update + 1)
  }

  return (
    <CustomizedTextField
      placeholder={placeholder}
      value={activeValue}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      disabled={disabled}
      style={{
        margin: '8px 0 0 0',
      }}
      size="sm"
    />
  )
}
