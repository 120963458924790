import { useCallback } from 'react'
import OrderReplaceModal from 'modals/OrderReplacementModal'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import type { Order } from 'types'

const useProcessOrderReplacement = (orderId: Order['_id']) => {
  const { showModal } = useModalContext()

  const processOrderIssue = useCallback(() => {
    showModal(() => <OrderReplaceModal orderId={orderId} completionCallback={() => null} />)
  }, [orderId, showModal])

  return processOrderIssue
}

export default useProcessOrderReplacement
