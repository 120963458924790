export const overrideCodes = {
  priorAuthorization: {
    0: '0 - Not specified',
    1: '1 - Prior authorization',
    2: '2 - Medical certification',
    3: '3 - EPSDT',
    4: '4 - Exemption from copay',
    5: '5 - Exemption from prescription limits',
    6: '6 - Family planning indicator',
    7: '7 - TANF',
    8: '8 - Payer defined exemption',
    9: '9 - Emergency preparedness',
  },
  submissionClarification: {
    0: '0 - Not specified',
    1: '1 - No override',
    2: '2 - Other override',
    3: '3 - Vacation supply',
    4: '4 - Lost prescription',
    5: '5 - Therapy change',
    6: '6 - Starter dose',
    7: '7 - Medically necessary',
    8: '8 - Process compound for approved ingredients',
    9: '9 - Encounters',
    10: '10 - Meets plan limitation',
    11: '11 - Certification on file',
    12: '12 - DME replacement indicator',
    13: '13 - Payer-recognized emergency or disaster assistance request',
    14: '14 - Long term care leave of absence',
    15: '15 - Long term care replacement medication',
    16: '16 - Long term care box or automated dispensing machine',
    17: '17 - Long term care emergency supply remainder',
    18: '18 - Long term care patient admit or readmit indicator',
    19: '19 - Split billing',
    20: '20 - 340B',
    21: '21 - LTC dispensing: 14 days or less not applicable',
    22: '22 - LTC dispensing: 7 days',
    23: '23 - LTC dispensing: 4 days',
    24: '24 - LTC dispensing: 3 days',
    25: '25 - LTC dispensing: 2 days',
    26: '26 - LTC dispensing: 1 day',
    27: '27 - LTC dispensing: 4-3 days',
    28: '28 - LTC dispensing: 2-2-3 days',
    29: '29 - LTC dispensing: daily and 3-day weekend',
    30: '30 - LTC dispensing: per shift dispensing',
    31: '31 - LTC dispensing: per med pass dispensing',
    32: '32 - LTC dispensing: PRN on demand',
    33: '33 - LTC dispensing: 7 day of less cycle no otherwise represented',
    34: '34 - LTC dispensing: 14 days dispensing',
    35: '35 - LTC dispensing: 8-14 day dispensing method not listed above',
    36: '36 - LTC dispensing: dispensed outside short cycle',
    42: '42 - Prescriber ID submitted is valid and prescribing requirements have been validated',
    43: '43 - Prescriber’s DEA is active with DEA authorized prescription right',
    44: '44 - Associated DEA recently licensed or re-activated',
    45: '45 - DEA is a valid hospital DEA with suffix',
    46: '46 - DEA has prescriptive authority for this drug DEA schedule',
    47: '47 - Shortened days supply fill',
    48: '48 - Fill subsequent to a shortened day supply fill',
    49: '49 - Prescriber does not current have an active Type 1 NPI',
    50: '50 - Prescriber’s active medicare fee for service enrollment status has been validated',
    51: '51 - Pharmacy’s active medicare fee for service enrollment status has been validated',
    52: '52 - Prescriber’s state license with prescriptive authority has been validated',
    55: '55 - Prescriber enrollment in state Medicaid program has been validated',
    56: '56 - Non-matched prescriber ID',
    57: '57 - Discharge medication',
    58: '58 - Nominal price',
    59: '59 - Federal supply schedule',
    60: '60 - Long term care same drug strength and dosage form with multiple dosing directions',
    61: '61 - Medication synchronization override',
    99: '99 - Other',
  },
  ProductIdQualifier: {
    '00': '00 - Not specified',
    '01': '01 - Universal product code (UPC)',
    '02': '02 - Health related item (HRI)',
    '03': '03 - National drug code (NDC)',
    '04': '04 - Health industry business communications council (HIBCC)',
    '05': '05 - Department of defense (DOD)',
    '06': '06 - Drug use review / professional pharmacy service (DUR/PPS)',
    '07': '07 - Common procedure terminology (CPT4)',
    '08': '08 - Common procedure terminology (CPT5)',
    '09': '09 - Health care financing administration common procedural coding system (HCPCS)',
    '10': '10 - Pharmacy practice activity classification (PPAC)',
    '11': '11 - National pharmaceutical product interface code (NAPPI)',
    '12': '12 - Global trade identification number (GTIN)',
    '13': '13 - Drug identification number (DIN)',
    '34': '34 - Universal product number (UPN)',
    '99': '99 - Other',
  },
  OtherCoverage: {
    0: '0 - Not specified',
    1: '1 - No other coverage',
    2: '2 - Other coverage exists -  payment collected',
    3: '3 - Other coverage exists - claim not covered',
    4: '4 - Other coverage exists -  payment not collected',
    5: '5 - Managed care plan denial',
    6: '6 - Other coverage denied - not participating provider',
    7: '7 - Other coverage exists - not in effect on DOS',
    8: '8 - Claim is billing for copay',
  },
  LevelofService: {
    0: '0 - Not specified',
    1: '1 - Patient consultation',
    2: '2 - Home delivery',
    3: '3 - Emergency',
    4: '4 - 24 hour service',
    5: '5 - Patient consultation regarding generic product selection',
    6: '6 - In-home service',
    7: '7 - Medical at home with special pharmacy services identical to long term care',
  },
}

export const DURCodes = {
  Reason: {
    '00': '00 - Not specified',
    AD: 'AD - Additional drug needed',
    AN: 'AN - Prescription authentication',
    AR: 'AR - Adverse drug reaction',
    AT: 'AT - Additive toxicity',
    CD: 'CD - Chronic disease management',
    CH: 'CH - Call help desk',
    CS: 'CS - Patient complaint/symptom',
    DA: 'DA - Drug-allergy',
    DC: 'DC - Drug-disease (inferred)',
    DD: 'DD - Drug-drug interaction',
    DF: 'DF - Drug-food interaction',
    DI: 'DI - Drug incompatibility',
    DL: 'DL - Drug-lab conflict',
    DM: 'DM - Apparent drug misuse',
    DR: 'DR - Dose range conflict',
    DS: 'DS - Tobacco use',
    ED: 'ED - Patient education/instruction',
    ER: 'ER - Overuse',
    EX: 'EX - Excessive quantity',
    HC: 'HC - High cumulative dose',
    HD: 'HD - High dose',
    IC: 'IC - Iatrogenic condition',
    ID: 'ID - Ingredient duplication',
    LD: 'LD - Low dose',
    LK: 'LK - Lock in recipient',
    LR: 'LR - Underuse',
    MC: 'MC - Drug-disease (reported)',
    MN: 'MN - Insufficient duration',
    MP: 'MP - Poly-pharmacy detected',
    MR: 'MR - Poly-prescriber detected',
    MX: 'MX - Excessive duration',
    NA: 'NA - Drug not available',
    NC: 'NC - Non-covered drug purchase',
    ND: 'ND - New disease/diagnosis',
    NF: 'NF - Non-formulary drug',
    NN: 'NN - Unnecessary drug',
    NP: 'NP - New patient processing',
    NR: 'NR - Lactation/nursing interaction',
    NS: 'NS - Insufficient quantity',
    OH: 'OH - Alcohol conflict',
    PA: 'PA - Drug-age',
    PC: 'PC - Patient question/concern',
    PG: 'PG - Drug-pregnancy',
    PH: 'PH - Preventative health care',
    PN: 'PN - Prescriber consultation',
    PP: 'PP - Plan protocol',
    PR: 'PR - Prior adverse reaction',
    PS: 'PS - Product selection opportunity',
    RE: 'RE - Suspected environmental risk',
    RF: 'RF - Health provider referral',
    SC: 'SC - Suboptimal compliance',
    SD: 'SD - Suboptimal drug/indication',
    SE: 'SE - Side effect',
    SF: 'SF - Suboptimal dosage form',
    SR: 'SR - Suboptimal regimen',
    SX: 'SX - Drug-gender',
    TD: 'TD - Therapeutic',
    TN: 'TN - Laboratory test needed',
    TP: 'TP - Payer/processor question',
    UD: 'UD - Duplicate drug',
  },
  professionalService: {
    '00': '00 - No intervention',
    AS: 'AS - Patient assessment',
    CC: 'CC - Coordination of care',
    DE: 'DE - Dosing evaluation/determination',
    DP: 'DP - Dosage evaluated',
    FE: 'FE - Formulary enforcement',
    GP: 'GP - Generic product selection',
    M0: 'M0 - Prescriber consulted',
    MA: 'MA - Medication administration',
    MB: 'MB - Overriding benefit',
    MP: 'MP - Patient will be monitored',
    MR: 'MR - Medication review',
    P0: 'P0 - Patient consulted',
    PA: 'PA - Previous patient tolerance',
    PE: 'PE - Patient education/instruction',
    PH: 'PH - Patient medication history',
    PM: 'PM - Patient monitoring',
    PT: 'PT - Perform laboratory test',
    R0: 'R0 - Pharmacist consulted other source',
    RT: 'RT - Recommend laboratory test',
    SC: 'SC - Self-care consultation',
    SW: 'SW - Literature search/review',
    TC: 'TC - Payer/processor consulted',
    TH: 'TH - Therapeutic product interchange',
    ZZ: 'ZZ - Other acknowledgement',
  },
  resultOfService: {
    '00': '00 - Not specified',
    '1A': '1A - Fill as is, false positive',
    '1B': '1B - Filled prescription as is',
    '1C': '1C - Filled, with different dose',
    '1D': '1D - Filled, with different direction',
    '1E': '1E - Filled, with different drug',
    '1F': '1F - Filled, with different quantity',
    '1G': '1G - Filled, with prescriber approval',
    '1H': '1H - Brand-to-generic change',
    '1J': '1J - Rx-to-OTC Change',
    '1K': '1K - Filled, with different dosage form',
    '2A': '2A - Prescription not filled',
    '2B': '2B - Not filled, direction clarified',
    '3A': '3A - Recommendation accepted',
    '3B': '3B - Recommendation not accepted',
    '3C': '3C - Discontinued drug',
    '3D': '3D - Regimen changed',
    '3E': '3E - Therapy changed',
    '3F': '3F - Therapy changed-cost increased acknowledged',
    '3G': '3G - Drug therapy unchanged',
    '3H': '3H - Follow-up / report',
    '3J': '3J - Patient referral',
    '3K': '3K - Instruction understood',
    '3M': '3M - Compliance aid provided',
    '3N': '3N - Medication administered',
    '4A': '4A - Prescribed with acknowledgement',
    '4B': '4B - Dispensed, palliative care',
    '4C': '4C - Dispensed, hospice',
    '4D': '4D - Dispensed, cancer treatment',
    '4E': '4E - Dispensed, chronic pain',
    '4F': '4F - Dispensed, exempt per prescriber',
    '4G': '4G - Dispensed, surgery / trauma',
    '4H': '4H - Dispensed, hospital admission / discharge',
    '4J': '4J - Dispensed, Patient is not opioid naive',
    '4K': '4K - Prescriber specialty exemption-oncology or non-hospice palliative',
    '4L': '4L - Prescriber specialty exemption-hospice',
  },
  levelOfEffort: {
    '00': '00 - Not specified',
    '11': '11 - Level 1',
    '12': '12 - Level 2',
    '13': '13 - Level 3',
    '14': '14 - Level 4',
    '15': '15 - Level 5',
  },
  coAgentId: {
    '00': '00 - Not specified',
  },
  coAgentQualifier: {
    '03': '03 - NDC',
  },
}

export const relationshipCodes = [
  'NotSpecified',
  'Cardholder',
  'Spouse',
  'Child',
  'Other',
  'Student',
  'Disabled',
  'AdultDependent',
  'SignificantOther',
]

export const overrideCOB = {
  otherPayerIdQualifier: {
    '01': '01 - Health Plan Identifier',
    '02': '02 - Health Industry Number',
    '03': '03 - Bank Information Number',
    '04': '04 - National Association Of InsuranceCommisioners',
    '05': '05 - MedicareCarrier',
  },
  otherPayerAmountPaidQualifier: {
    '01': '01 - Delivery',
    '02': '02 - Shipping',
    '03': '03 - Postage',
    '04': '04 - Administrative',
    '05': '05 - Incentive',
    '06': '06 - Cognitive Service',
    '07': '07 - Drug Benefit',
    '09': '09 - Compound Preparation',
    '10': '10 - Sales Tax',
    '11': '11 - Medication Administration',
  },
  patientResponsibilityAmountQualifier: {
    '01': '01 - Periodic Deductible',
    '02': '02 - Brand Product Selection',
    '03': '03 - Sales Tax',
    '04': '04 - Periodic Benefit Exceeds Max',
    '05': '05 - Copay',
    '06': '06 - Patient Pay',
    '07': '07 - Coinsurance',
    '08': '08 - Non Preferred Formulary Product Selection',
    '09': '09 - Health Plan Assistance',
    '10': '10 - Provider Network Selection',
    '11': '11 - Brand Non Preffered Forumlary Product Selection',
    '12': '12 - Coverage Gap',
    '13': '13 - Processor Fee',
  },
  benefitStageQualifier: {
    '01': '01 - Deductible',
    '02': '02 - Initial Benefit',
    '03': '03 - Coverage Gap',
    '04': '04 - Catastrophic Coverage',
  },
}
