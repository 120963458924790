import { Chip } from '@truepill/react-capsule'
import { ReactComponent as PetIcon } from 'assets/icons/paw.svg'

const SpeciesLogo = (props: { isHuman: boolean }) => {
  return !props.isHuman ? (
    <Chip>
      <PetIcon style={{ verticalAlign: 'bottom', margin: '0' }} />
    </Chip>
  ) : (
    <></>
  )
}

export default SpeciesLogo
