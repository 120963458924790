import { RxFillRequestSpecialHandlingTag } from '@truepill/tpos-types'
import { ReactComponent as OrderIcon } from 'assets/icons/ellipse.svg'
import { ReactComponent as LegendIcon } from 'assets/icons/legend.svg'
import { ReactComponent as LockIcon } from 'assets/icons/lock.svg'
import InventorySystemTag from 'components/InventorySystemTag'
import { ModalWrapper, ModalHeader } from 'components/Modal'
import ClinicalReviewTile from 'components/Tiles/ClinicalReviewTile'
import HandlingTagsTile from 'components/Tiles/HandlingTagsTile'
import Lozenge from 'components/Tiles/Lozenge'
import RobotTile from 'components/Tiles/RobotTile'
import { useTPCacheContext } from 'providers/TPCacheProvider'
import styled from 'styled-components'
import { alertRed, bodyPrimaryColor, primaryColor, warningYellow, contrastColor } from 'styles/styleVariables'
import type { TPOSLocation } from 'types'

const formatLegacyId = (id: number): string => {
  const strId = id.toString()
  return strId.padStart(2, '0')
}

const LegendModal = (): JSX.Element => {
  const { locations } = useTPCacheContext()

  return (
    <FixedWidthWrapper>
      <StyledModalWrapper>
        <ModalHeader>
          <LegendTitle>
            <StyledLegendIcon />
            Legend
          </LegendTitle>
        </ModalHeader>
        <LegendHeading>Pharmacy Locations</LegendHeading>
        {locations.map((location: TPOSLocation) => (
          <LegendRow key={location._id}>
            <LegendCellLeft>{formatLegacyId(location.legacyId)}</LegendCellLeft>
            <LegendCellRight>{location.name}</LegendCellRight>
          </LegendRow>
        ))}
        <LegendHeading>Counters</LegendHeading>
        <LegendRow>
          <LegendCellLeft>
            <StatusLozenge bgColor={primaryColor}>100</StatusLozenge>
          </LegendCellLeft>
          <LegendCellRight>Count</LegendCellRight>
        </LegendRow>
        <LegendRow>
          <LegendCellLeft>
            <StatusLozenge bgColor={alertRed}>100</StatusLozenge>
          </LegendCellLeft>
          <LegendCellRight>Count overdue</LegendCellRight>
        </LegendRow>
        <LegendRow>
          <LegendCellLeft>
            <StatusLozenge bgColor={warningYellow}>100</StatusLozenge>
          </LegendCellLeft>
          <LegendCellRight>Count aging</LegendCellRight>
        </LegendRow>
        <LegendHeading>Clinical review</LegendHeading>
        <LegendRow>
          <LegendCellLeft>
            <ClinicalReviewTile durStatus={'none'}></ClinicalReviewTile>
          </LegendCellLeft>
          <LegendCellRight>Review not required</LegendCellRight>
        </LegendRow>
        <LegendRow>
          <LegendCellLeft>
            <ClinicalReviewTile durStatus={'severe'}></ClinicalReviewTile>
          </LegendCellLeft>
          <LegendCellRight>Review required</LegendCellRight>
        </LegendRow>
        <LegendHeading>Robot Automation</LegendHeading>
        <LegendRow>
          <LegendCellLeft>
            <RobotTile machine />
          </LegendCellLeft>
          <LegendCellRight>Robot automated fill</LegendCellRight>
        </LegendRow>
        <LegendHeading>Order</LegendHeading>
        <LegendRow>
          <LegendCellLeft>
            <StatusLozenge bgColor={contrastColor}>
              <LockIcon fill={'#ffffff'} />
            </StatusLozenge>
          </LegendCellLeft>
          <LegendCellRight>Locked Order</LegendCellRight>
        </LegendRow>
        <LegendRow>
          <LegendCellLeft>
            <StatusLozenge bgColor={contrastColor}>
              <OrderIcon fill={'#ffffff'} />
            </StatusLozenge>
          </LegendCellLeft>
          <LegendCellRight>Fills in order that have completed current step</LegendCellRight>
        </LegendRow>
        <LegendRow>
          <LegendCellLeft>
            <StatusLozenge bgColor={contrastColor}>
              <IncompleteOrderIcon fill={'none'} />
            </StatusLozenge>
          </LegendCellLeft>
          <LegendCellRight>Fills in order that have not completed current step</LegendCellRight>
        </LegendRow>
        <LegendHeading>Handling tags</LegendHeading>
        <LegendRow>
          <LegendCellLeft>
            <HandlingTagsTile specialHandlingTags={[RxFillRequestSpecialHandlingTag.COLD_CHAIN]} />
          </LegendCellLeft>
          <LegendCellRight>Cold chain handling required</LegendCellRight>
        </LegendRow>
        <LegendRow>
          <LegendCellLeft>
            <HandlingTagsTile specialHandlingTags={[RxFillRequestSpecialHandlingTag.CII]} />
          </LegendCellLeft>
          <LegendCellRight>Controlled substance, DEA schedule CII</LegendCellRight>
        </LegendRow>
        <LegendRow>
          <LegendCellLeft>
            <HandlingTagsTile specialHandlingTags={[RxFillRequestSpecialHandlingTag.CIII]} />
          </LegendCellLeft>
          <LegendCellRight>Controlled substance, DEA schedule CIII</LegendCellRight>
        </LegendRow>
        <LegendRow>
          <LegendCellLeft>
            <HandlingTagsTile specialHandlingTags={[RxFillRequestSpecialHandlingTag.CIV]} />
          </LegendCellLeft>
          <LegendCellRight>Controlled substance, DEA schedule CIV</LegendCellRight>
        </LegendRow>
        <LegendRow>
          <LegendCellLeft>
            <HandlingTagsTile specialHandlingTags={[RxFillRequestSpecialHandlingTag.CV]} />
          </LegendCellLeft>
          <LegendCellRight>Controlled substance, DEA schedule CV</LegendCellRight>
        </LegendRow>
        <LegendRow>
          <LegendCellLeft>
            <HandlingTagsTile specialHandlingTags={[RxFillRequestSpecialHandlingTag.HIGH_COST]} />
          </LegendCellLeft>
          <LegendCellRight>High-cost medication</LegendCellRight>
        </LegendRow>
        <LegendRow>
          <LegendCellLeft>
            <HandlingTagsTile specialHandlingTags={[RxFillRequestSpecialHandlingTag.SPECIALTY]} />
          </LegendCellLeft>
          <LegendCellRight>Specialty medication</LegendCellRight>
        </LegendRow>
        <LegendHeading>Inventory system indicators</LegendHeading>
        <LegendRow>
          <LegendCellLeft>
            <InventorySystemTag externalSystemName="tecsys" />
            <InventorySystemTag externalSystemName="tecsys" useLozenge />
          </LegendCellLeft>
          <LegendCellRight style={{ alignContent: 'center' }}>Inventory comes from Tecsys</LegendCellRight>
        </LegendRow>
        <LegendRow>
          <LegendCellLeft>
            <InventorySystemTag externalSystemName="pioneer_inventory" />
            <InventorySystemTag externalSystemName="pioneer_inventory" useLozenge />
          </LegendCellLeft>
          <LegendCellRight style={{ alignContent: 'center' }}>Inventory comes from Pioneer</LegendCellRight>
        </LegendRow>
      </StyledModalWrapper>
    </FixedWidthWrapper>
  )
}

const StyledModalWrapper = styled(ModalWrapper)`
  min-width: 26.5rem;
`

const IncompleteOrderIcon = styled(OrderIcon)`
  outline: #ffffff;
`

const StyledLegendIcon = styled(LegendIcon)`
  margin-right: 0.5rem;
`

const StatusLozenge = styled(Lozenge)<{ bgColor: string }>`
  color: ${props => (props.bgColor === warningYellow ? bodyPrimaryColor : '#ffffff')};
  background-color: ${props => props.bgColor};
  margin-left: 0;
  border-radius: 15px;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  height: 1rem;
  width: 1.5rem;
`

const LegendTitle = styled.h2`
  display: flex;
  align-items: center;
`

const LegendHeading = styled.h3`
  margin: 1rem 0 0.5rem;
`

const FixedWidthWrapper = styled.div`
  display: flex;
  min-width: 26.5rem;
  max-width: 26.5rem;
`

const LegendRow = styled.ul`
  display: flex;
  font-weight: 500;
  margin: 0.5rem 0;
`

const LegendCellLeft = styled.li`
  flex-basis: 3rem;
  font-size: 0.875rem;
  padding: 0;
`

const LegendCellRight = styled.li`
  font-size: 0.875rem;
  padding-left: 1rem;
`

export default LegendModal
