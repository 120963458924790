import { useEffect } from 'react'
import { BannerAlert, Spacer, Text } from '@truepill/react-capsule'
import { FormItem } from 'components/ColumnForm'
import CustomizedTextField from 'components/CustomizedTextField'
import PharmacistSearch from 'components/PharmacistSearch'
import PharmacyCard from 'components/PharmacyCard'
import PharmacySearch from 'components/PharmacySearch'
import { useFormData } from 'hooks/useFormData'
import type { FormDataState } from 'providers/Store'
import styled from 'styled-components'
import type { PharmacyAddress, PharmacyFormData } from 'types'

type PharmacySectionProps = { nameSpace: keyof FormDataState; alert?: JSX.Element }

const Label = styled.span`
  font-size: 1rem;
  font-weight: bold;
  font-family: Lato;
  line-height: 1.375;
`

const StyledBannerAlert = styled(BannerAlert)`
  border-left-width: 1px;
  align-items: center;
`

const PharmacySection = ({ nameSpace, alert }: PharmacySectionProps): JSX.Element => {
  const {
    state: {
      formData: { [nameSpace]: formData },
    },
    actions: { updateFormData },
  } = useFormData()
  useEffect(
    () => () => {
      // Clear the search on appear
      updateFormData({
        [nameSpace]: {
          pharmacy: { $set: null },
          pharmacistName: { $set: null },
          transferringPharmacistName: { $set: null },
          faxNumber: { $set: null },
          comments: { $set: null },
        },
      })
    },
    [],
  )

  const { pharmacy, faxNumber, pharmacistName, comments, transferringPharmacistName } = formData as PharmacyFormData
  return (
    <>
      <FormItem>
        <Label>Pharmacy</Label>
        <Spacer size="xs" />
        {!pharmacy && (
          <PharmacySearch
            withCapsule
            isModal
            onSelect={pharmacy => {
              const address = pharmacy.practiceAddresses[0] as PharmacyAddress
              updateFormData({
                [nameSpace]: {
                  pharmacy: { $set: pharmacy },
                  faxNumber: { $set: address?.fax },
                },
              })
            }}
          />
        )}
        {pharmacy && (
          <PharmacyCard
            pharmacyId={pharmacy._id}
            closeCallback={() => {
              updateFormData({
                [nameSpace]: {
                  pharmacy: { $set: null },
                  pharmacistName: { $set: '' },
                  faxNumber: { $set: '' },
                },
              })
            }}
          />
        )}
      </FormItem>
      {alert && (
        <StyledBannerAlert
          state="info"
          css={{
            '$$banner-color': '$colors$functional-warning-dark',
            '$$banner-background': '$colors$functional-warning-light',
            '$$banner-border': '$colors$functional-warning-dark',
          }}
        >
          {alert}
        </StyledBannerAlert>
      )}
      <FormItem>
        <CustomizedTextField
          label="Fax"
          size="sm"
          placeholder={'(xxx) xxx - xxxx'}
          value={faxNumber}
          onChange={e => {
            updateFormData({
              [nameSpace]: {
                faxNumber: { $set: e.target.value },
              },
            })
          }}
        />
      </FormItem>
      <FormItem>
        <PharmacistSearch
          withCapsule
          isModal
          value={transferringPharmacistName}
          label="Transferring pharmacist"
          onSelect={user => {
            updateFormData({
              [nameSpace]: {
                transferringPharmacistName: { $set: `${user.firstName} ${user.lastName}` },
              },
            })
          }}
        />
      </FormItem>
      <FormItem>
        <CustomizedTextField
          value={pharmacistName}
          label="Receiving pharmacist"
          size="sm"
          placeholder="Enter pharmacist's first and last name"
          onChange={e => {
            const user = e.target.value
            updateFormData({
              [nameSpace]: {
                pharmacistName: { $set: user },
              },
            })
          }}
        />
      </FormItem>
      <FormItem>
        <CustomizedTextField
          label="Comments (optional)"
          type="textarea"
          data-testid="transfer-form-comments-text-area"
          style={{ height: '8rem' }}
          onChange={e => {
            updateFormData({
              [nameSpace]: {
                comments: { $set: e.target.value },
              },
            })
          }}
          value={comments}
        />
      </FormItem>
      <StyledBannerAlert state="warning">
        <Text>
          System doesn't automatically fax. Rx needs to be manually faxed after the transfer image is generated.
        </Text>
      </StyledBannerAlert>
    </>
  )
}

export default PharmacySection
