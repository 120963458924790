import { ReactComponent as LoadingAnimation } from 'assets/icons/loading_dots.svg'
import HotKeyToolTip from 'components/HotKeyToolTip'
import { ActionButton as StyledActionButton } from 'components/PageStructure'
import type { ButtonProps } from 'grommet'
import useHotKey, { HotKeyLevel } from 'hooks/useHotKey'
import type { MetaKeys } from 'providers/HID/HotKeyProvider'
import styled from 'styled-components'
import { bodyPrimaryColor, capsuleDarkBlue, primaryBackgroundColor, primaryColor } from 'styles/styleVariables'

export interface ActionButtonProps extends ButtonProps {
  hotKey?: string
  hotKeyMeta?: MetaKeys
  hotKeyLevel?: HotKeyLevel
  hotKeyLabel?: string
  onClick?: () => void
  isModal?: boolean
  className?: string
  title?: string
  'data-testid'?: string
  loading?: boolean
  id?: string
}

const ActionButton = (props: ActionButtonProps): JSX.Element => {
  const { hotKey } = props

  if (!hotKey || hotKey === '') {
    return <StyledActionButton {...props} />
  }

  return <HotKeyedActionButton {...props} />
}

const HotKeyedActionButton = (props: ActionButtonProps): JSX.Element => {
  const { hotKey, hotKeyMeta, hotKeyLevel, hotKeyLabel, onClick, disabled, isModal } = props

  const level = hotKeyLevel || isModal ? HotKeyLevel.modal : HotKeyLevel.normal

  useHotKey(hotKey as string, level, () => !disabled && onClick && onClick(), hotKeyMeta)

  return (
    <>
      <StyledActionButton {...props} onClick={onClick && onClick} />
      <HotKeyToolTip label={hotKeyLabel || (hotKey as string)} position={'top'} offsetTop={-3.8} offsetLeft={-3.8} />
    </>
  )
}

export const SaveButton = (props: ActionButtonProps): JSX.Element => {
  if (props.loading) {
    return (
      <StyledSaveButton
        data-testid={props['data-testid'] || 'save'}
        hotKeyLabel={'CTRL-Enter'}
        hotKey={'Enter'}
        hotKeyMeta={{ ctrl: true }}
        {...props}
        icon={<LoadingAnimation role="img" />}
        label={null}
      />
    )
  }
  return (
    <StyledSaveButton
      data-testid={props['data-testid'] || 'save'}
      hotKeyLabel={'CTRL-Enter'}
      hotKey={'Enter'}
      hotKeyMeta={{ ctrl: true }}
      {...props}
    />
  )
}

export const RejectButton = (props: ActionButtonProps): JSX.Element => {
  if (props.loading) {
    return (
      <StyledRejectButton
        data-testid={props['data-testid'] || 'reject'}
        hotKeyLabel={'CTRL-Enter'}
        hotKey={'Enter'}
        hotKeyMeta={{ ctrl: true }}
        {...props}
        icon={<LoadingAnimation role="img" />}
        label={null}
      />
    )
  }
  return (
    <StyledRejectButton
      data-testid={props['data-testid'] || 'reject'}
      hotKeyLabel={'CTRL-Enter'}
      hotKey={'Enter'}
      hotKeyMeta={{ ctrl: true }}
      {...props}
    />
  )
}

const StyledSaveButton = styled(ActionButton)`
  text-transform: capitalize;
  height: 2.5rem;
  margin-left: 0.5rem;
  background-color: ${primaryColor};
  color: ${primaryBackgroundColor};
  border: none;
  border-radius: 4px;
`
const StyledRejectButton = styled(ActionButton)`
  text-transform: capitalize;
  height: 2.5rem;
  margin-left: 0.5rem;
  color: ${bodyPrimaryColor};
  border-radius: 4px;
`

export const ActionButtonOutline = styled(ActionButton)`
  background-color: ${primaryBackgroundColor};
  color: ${capsuleDarkBlue};
  border-color: ${capsuleDarkBlue};
  border-radius: 8px;
  height: 3rem;
  padding: 0 1rem;
  &:hover {
    box-shadow: none;
    color: ${primaryBackgroundColor};
    background-color: ${capsuleDarkBlue};
    svg path {
      stroke: ${primaryBackgroundColor};
    }
  }
`

export default ActionButton
