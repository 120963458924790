import { useState } from 'react'
import { useMutation } from '@truepill/tpos-react-router'
import { ReactComponent as ReverseIcon } from 'assets/icons/reverse.svg'
import { SaveButton } from 'components/ActionButton'
import IconWrapper from 'components/IconWrapper'
import { ButtonsContainer, InputContainer, ModalHeader, ModalWrapper } from 'components/Modal'
import { CancelButton } from 'components/PageStructure'
import { StyledTextInput as TextInput } from 'components/RXTable'
import Select from 'components/Select'
import { RETURN_ORDER, REVERSE_FILL } from 'gql'
import type { GraphQLError } from 'graphql'
import useErrorToast from 'hooks/toast/useErrorToast'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import styled, { css } from 'styled-components'
import { bodyPrimaryColor } from 'styles/styleVariables'
import type { Fill, Order } from 'types'

interface ModalProps {
  order?: Order
  fill?: Fill
}

const reverseReasonOptions = [
  'Return to sender',
  'Lost during shipping',
  'Damaged during shipping',
  'Billed incorrectly',
  'Other',
]

const ReverseModal = ({ order, fill }: ModalProps): JSX.Element => {
  const { dismissModal } = useModalContext()
  const [reason, setReason] = useState('')
  const [note, setNote] = useState('')
  const showSuccessToast = useSuccessToast(true)
  const showErrorToast = useErrorToast(true)
  const reversalTarget = order ? 'Order' : 'Script'

  const mutationCallbacks = {
    onCompleted: (response: Promise<Fill> | Promise<Order>) => {
      dismissModal()
      showSuccessToast(`${reversalTarget} reversed.`)
    },
    onError: ({ graphQLErrors }: { graphQLErrors: ReadonlyArray<GraphQLError> }) => {
      let errorMessage = ''
      if (graphQLErrors) graphQLErrors.forEach(({ message }) => (errorMessage += message + ', '))
      showErrorToast(`${reversalTarget} could not be reversed: ${errorMessage}`)
      console.error(errorMessage)
    },
  }

  const [returnOrder] = useMutation(RETURN_ORDER, mutationCallbacks)
  const [reverseFill] = useMutation(REVERSE_FILL, mutationCallbacks)

  if (!order && !fill) {
    console.error('Must provide either an order or a fill')
    showErrorToast('There was a problem showing the reversal modal')
    dismissModal()
    return <></>
  }

  const handleClick = async () => {
    if (fill && order) {
      reverseFill({
        variables: {
          orderId: order._id,
          fillId: fill._id,
          reason,
          note,
        },
      })
    } else if (order) {
      returnOrder({
        variables: {
          orderId: order._id,
          reason,
          note,
        },
      })
    }
  }

  return (
    <ModalWrapper styles={modalWrapperStyles}>
      <ModalHeader>
        <IconWrapper>
          <ReverseIcon fill={bodyPrimaryColor} />
        </IconWrapper>
        <h2>{`Reverse ${reversalTarget.toLowerCase()}`}</h2>
      </ModalHeader>
      <InputContainer>
        <StyledSelect
          disableClear
          value={reason}
          placeholder={'Select a reversal reason…'}
          options={reverseReasonOptions}
          onChange={([option]) => setReason(option.value)}
        />
        {reason === 'Other' && (
          <StyledTextInput value={note} placeholder={'Enter a message…'} onChange={e => setNote(e.target.value)} />
        )}
      </InputContainer>
      <ButtonsContainer>
        <CancelButton label={'Cancel'} onClick={dismissModal} />
        <SaveButton isModal disabled={!reason} label={'Submit'} onClick={handleClick} />
      </ButtonsContainer>
    </ModalWrapper>
  )
}

// unlike other styled components, the ModalWrapper requires additional styling to be passed in
const modalWrapperStyles = css`
  overflow-y: hidden;
`

const StyledTextInput = styled(TextInput)`
  margin-top: 0.625rem;
  height: 2.5rem;
`

const StyledSelect = styled(Select)`
  margin-top: 0.625rem;
`

export default ReverseModal
