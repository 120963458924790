import type { ReactNode } from 'react'
import type { UserRoles } from '@truepill/tpos-types'
import { usePlusClient } from 'providers/VisionRouter'
import type { ChildProps } from 'types'

type AuthLimitedType = { roles: UserRoles[]; fallback?: ReactNode } & ChildProps

const AuthLimited = ({ roles, fallback, children }: AuthLimitedType): JSX.Element => {
  const { tokenContext } = usePlusClient()

  if (!tokenContext) return <></>

  const { hasRole } = tokenContext

  if (hasRole && hasRole(roles)) {
    return <>{children}</>
  }

  return <>{fallback}</>
}

export default AuthLimited
