import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import moment from 'moment-timezone'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(duration)

export const getFormattedTimeSince = (since: Date): string => {
  const sinceDate = dayjs(since)
  const today = dayjs()

  const duration = dayjs.duration(today.diff(sinceDate))
  const days = Math.ceil(duration.asDays())
  const hours = Math.ceil(duration.asHours())

  return hours > 48 ? `${days} days` : `(${hours} hrs)`
}

export const formatDate = (date: string | Date | undefined | null | Dayjs, format = 'MM/DD/YYYY'): string =>
  date ? dayjs(date).format(format) : '-'

export const getDiffDaysBetweenDates = (since: Date, to: Date): number => moment(to).diff(since, 'days')

export const adjustDateToTimezone = (date: string | Dayjs, timezone?: string): string => {
  const newDate = dayjs(date)
  const adjustToTimezone = newDate.hour() !== 0 || newDate.minute() !== 0 || newDate.second() !== 0
  return adjustToTimezone ? formatDateWithTimezone(newDate, timezone) : newDate.format('MM/DD/YYYY')
}

export const formatDateWithTimezone = (date: string | Dayjs, timezone?: string, format = 'MM/DD/YYYY'): string => {
  return timezone ? dayjs(date).tz(timezone).format(format) : dayjs(date).format(format)
}

export const isTimestampDate = (date: string | undefined): boolean => {
  if (!date) return false
  if (date.includes('T') || date.includes(':')) {
    const parsedDate = dayjs(date)
    return parsedDate.isValid()
  }
  return false
}
