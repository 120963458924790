import { useMemo } from 'react'
import { useQuery } from '@truepill/tpos-react-router'
import { GET_NDC_FULL_INFO } from 'gql'
import type { NdcFullInfoPackageWithUnbrekableInfo } from 'types'

export interface NDCValidationInfo {
  packageUnitOfMeasure: string
  requiresUnitDosePackaging: boolean
  packageSize: number
  canUseDecimalQty: boolean
}

const useNdcValidationInfo = (
  fillNdc: string | undefined,
  skipUnitDosePackagingValidation = false,
): {
  ndcValidationInfo: NDCValidationInfo
} => {
  const { data } = useQuery(GET_NDC_FULL_INFO, {
    variables: {
      ndcs: [fillNdc],
    },
  })
  const ndcValidationInfo = useMemo(() => {
    if (data) {
      const pack: NdcFullInfoPackageWithUnbrekableInfo = data.getNdcFullInfo[0]?.packages.find(
        (pack: NdcFullInfoPackageWithUnbrekableInfo) => pack.ndc === fillNdc,
      )
      if (pack) {
        return {
          packageUnitOfMeasure: pack.packageSizeUnitOfMeasure,
          requiresUnitDosePackaging: pack.isUnbreakable && !skipUnitDosePackagingValidation,
          packageSize: pack.packageSize,
          canUseDecimalQty: pack.packageSizeUnitOfMeasure !== 'EA',
        }
      }
    }

    return {
      packageUnitOfMeasure: '',
      requiresUnitDosePackaging: false,
      packageSize: 0,
      canUseDecimalQty: false,
    }
  }, [data, fillNdc, skipUnitDosePackagingValidation])

  return { ndcValidationInfo }
}

export default useNdcValidationInfo
