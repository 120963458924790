import type { DataTableProps } from 'grommet'
import { DataTable as OriginalDataTable } from 'grommet'
import styled from 'styled-components'
import {
  borderColor,
  contrastBackgroundColor,
  contrastColor,
  primaryBackgroundColor,
  primaryColor,
  subduedColor,
} from 'styles/styleVariables'

const DataTableWrapper = styled.div`
  table {
    border-bottom: 0.25rem solid ${contrastColor};
    width: 100%;

    thead {
      font-size: 1.1em;

      th {
        border-bottom: 0;
        padding: 0.1rem 0.625rem;
        vertical-align: bottom;
        line-height: 1.5rem;

        button[aria-label^='focus-search-'] {
          padding: 0;

          svg {
            height: 1.25rem;
            width: 1.25rem;
            border-radius: 0.25rem;
            stroke: ${primaryBackgroundColor};
            border: 2px solid ${borderColor};

            :hover {
              border-color: ${primaryColor};
              box-shadow: 0px 0px 0px 2px ${subduedColor};
            }
          }

          :hover {
            background-color: inherit;
          }
        }

        input {
          margin: 0;
          padding: 0.1rem 0.625rem;
        }
      }

      th:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
      }

      th:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
      }
    }

    tbody {
      tr:nth-of-type(even) {
        background-color: ${contrastBackgroundColor};
      }

      td {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`

/**
 * A passthrough of the `grommet` DataTable that applies the styles needed to
 * get the slightly tweaked internal look (less spacing, right margin, rounded).
 */
export default function DataTable<TRowType = any>(
  props: DataTableProps<TRowType> & JSX.IntrinsicElements['table'] & { ref?: any },
): JSX.Element {
  return (
    <DataTableWrapper>
      <OriginalDataTable {...props} />
    </DataTableWrapper>
  )
}
