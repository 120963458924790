import { useMutation } from '@truepill/tpos-react-router'
import { ReactComponent as LoadingDots } from 'assets/icons/loading_dots.svg'
import { ReactComponent as ReplaceIcon } from 'assets/icons/replace.svg'
import ActionButton from 'components/ActionButton'
import IconWrapper from 'components/IconWrapper'
import { REPLACE_RXFILLREQUEST } from 'gql'
import useErrorToast from 'hooks/toast/useErrorToast'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import ReplacePrescriptionModal from 'modals/ReplaceRxFillRequestModal'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import styled from 'styled-components'
import { primaryBackgroundColor } from 'styles/styleVariables'

type ReplacePrescriptionButtonProps = {
  orderId: string
  rxFillRequestId: string
  patientId: string
  isMultiOrder?: boolean
}

const ReplacePrescriptionButton = ({
  orderId,
  rxFillRequestId,
  patientId,
  isMultiOrder,
}: ReplacePrescriptionButtonProps): JSX.Element => {
  const { showModal } = useModalContext()
  const showErrorToast = useErrorToast(true)
  const showSuccessToast = useSuccessToast(true)
  const [replaceRxFillRequest, { loading }] = useMutation(REPLACE_RXFILLREQUEST, {
    refetchQueries: ['getFullOrder', 'patientPrescriptions'],
    onCompleted() {
      showSuccessToast('Successfully replaced!')
    },
    onError(err) {
      showErrorToast(`Failed to replace prescription! ${err.message.replace('GraphQL error:', '')}`)
    },
  })

  const buttonIcon = loading ? (
    <LoadingIconWrapper>
      <LoadingDots />
    </LoadingIconWrapper>
  ) : (
    <ReplaceIconWrapper title="Swap Rx">
      <ReplaceIcon />
    </ReplaceIconWrapper>
  )

  return (
    <StyledActionButton
      isMultiOrder={isMultiOrder}
      icon={buttonIcon}
      onClick={() => {
        showModal(() => (
          <ReplacePrescriptionModal
            patientId={patientId}
            initialPrescriptionIds={[]}
            completionCallback={(rxId: string, quantity: number) => {
              replaceRxFillRequest({
                variables: {
                  orderId,
                  rxFillRequestId,
                  rxId,
                  quantity,
                },
              })
            }}
          />
        ))
      }}
    />
  )
}

const ReplaceIconWrapper = styled(IconWrapper)`
  display: flex;
  grid-column: removeIcon;
  svg {
    fill: #999999;
  }
`

const LoadingIconWrapper = styled(IconWrapper)`
  display: flex;
  grid-column: removeIcon;
  margin-right: -10px;
  svg {
    circle {
      fill: #999999;
    }
  }
`

const StyledActionButton = styled(ActionButton)<{ isMultiOrder?: boolean }>`
  background-color: ${primaryBackgroundColor};
  margin: 1px 0px 0px -3px;
  padding: 0 5px 0 2px;
  border-radius: 4px;
  grid-column: rxReplace;
  ${({ isMultiOrder }) => isMultiOrder && 'margin-top: 2px;'}
}
`

export default ReplacePrescriptionButton
