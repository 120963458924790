import { Select } from '@truepill/react-capsule'
import { SpeciesOptions } from '@truepill/tpos-types'

const SpeciesSelect = ({ onChange, value }: { value: string; onChange: (change?: string | undefined) => void }) => {
  return (
    <Select
      variant="small"
      options={Object.values(SpeciesOptions)}
      label="Patient species"
      onChange={onChange}
      value={value}
    />
  )
}

export default SpeciesSelect
