import { useMemo, useCallback, useEffect } from 'react'
import { useLazyQuery, useMutation, useParams } from '@truepill/tpos-react-router'
import { GET_PRESCRIPTION, UPDATE_PRESCRIBED } from 'gql'
import { useFormData } from 'hooks/useFormData'
import { useTPCacheContext } from 'providers/TPCacheProvider'
import type { Prescription } from 'types'
import type { LoadingError } from './types'

export interface PrescriptionHookValues extends LoadingError {
  prescription?: Prescription
  savePrescriptionChanges: () => void
  getChanges: () => Partial<Prescription>
  subscribeToMore: any
  savingChanges?: boolean
}

export default function usePrescription({
  prescriptionId: prescriptionIdProp,
  prescription: prescriptionProp,
  loading: upstreamLoading,
  error: upstreamError,
  onPrescriptionSavedCompleted,
  onPrescriptionSavedError,
}: {
  prescriptionId?: string
  prescription?: Prescription
  onPrescriptionSavedCompleted?: (...args: any[]) => void
  onPrescriptionSavedError?: () => void
} & LoadingError = {}): PrescriptionHookValues {
  const { getCustomerById, getLocationById } = useTPCacheContext()
  const prescriptionId =
    useParams<{
      prescriptionId?: string
    }>().prescriptionId || prescriptionIdProp
  const [getPrescription, { data, loading, error, called, client, subscribeToMore }] = useLazyQuery(GET_PRESCRIPTION, {
    variables: { prescriptionId: prescriptionId },
  })
  const [updatePrescribed, { loading: savingChanges }] = useMutation(UPDATE_PRESCRIBED, {
    refetchQueries: ['getBasicOrder'],
    onCompleted(response, options) {
      onPrescriptionSavedCompleted?.(response, options?.variables)
    },
    onError() {
      onPrescriptionSavedError?.()
    },
  })

  const {
    actions: { updateFormData },
    changes: { getPrescriptionChanges },
  } = useFormData()

  if (prescriptionProp) {
    client?.writeQuery({
      query: GET_PRESCRIPTION,
      variables: { prescriptionId: prescriptionProp._id },
      data: { getPrescription: prescriptionProp },
    })
  }

  useEffect(() => {
    if (prescriptionId && !prescriptionProp) {
      getPrescription()
    }
  }, [prescriptionProp, getPrescription, prescriptionId, subscribeToMore])

  const p = useMemo(() => (prescriptionProp || data?.getPrescription || {}) as Prescription, [prescriptionProp, data])

  const prescription = useMemo(
    () => ({
      ...p,
      fills:
        p?.fills?.map(fill => ({
          ...fill,
          location: getLocationById(fill?.locationId as string),
        })) ?? [],
      customer: getCustomerById(p?.customerId as string),
      location: getLocationById(p?.locationId as string),
    }),
    [p, getLocationById, getCustomerById],
  )

  // Init editable versions of the rx data
  useEffect(() => {
    updateFormData({ prescription: { $set: prescription } })
  }, [prescription, updateFormData])

  const getChanges = () => {
    return getPrescriptionChanges(prescription) as Partial<Prescription>
  }

  const savePrescriptionChanges = useCallback(() => {
    const changes = getPrescriptionChanges(prescription)

    if (Object.keys(changes).length) {
      updatePrescribed({
        variables: {
          rxId: prescription._id,
          ...changes,
        },
      })
    } else {
      onPrescriptionSavedCompleted?.()
    }
  }, [getPrescriptionChanges, prescription, updatePrescribed])

  if (!prescriptionProp && !prescriptionIdProp && !called && !upstreamLoading) {
    return { loading: true, error: undefined } as PrescriptionHookValues
  }

  if (loading || error || upstreamError || upstreamLoading) {
    return {
      loading: loading || upstreamLoading,
      error: error || upstreamError,
    } as PrescriptionHookValues
  }

  return {
    prescription,
    savePrescriptionChanges,
    savingChanges,
    subscribeToMore,
    getChanges,
  }
}
