import type { DUR } from '@truepill/tpos-types'
import { DURStatus } from '@truepill/tpos-types'

export default function sortDURsBySeverity(durs: DUR[]): DUR[] {
  return durs.slice().sort((durA, durB) => {
    const aWeight = Object.values(DURStatus).indexOf(durA.status)
    const bWeight = Object.values(DURStatus).indexOf(durB.status)
    if (aWeight < bWeight) {
      return 1
    } else if (aWeight > bWeight) {
      return -1
    } else {
      return 0
    }
  })
}
