import SearchAutoComplete from 'components/SearchAutoComplete'
import type { SearchAutoCompleteProps } from 'components/SearchAutoComplete'
import styled from 'styled-components'
import { alertRed } from 'styles/styleVariables'
import type { DeepRequired } from 'ts-essentials'

export const isReal = <TValue extends { type?: string }>(
  value: TValue | null | undefined,
  // @ts-expect-error my TS knowlegde is failing here
): value is DeepRequired<TValue> => value !== null && value !== undefined

export const ErrorText = styled.p`
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: ${alertRed};
`

export const SearchResultContainer = styled.div`
  width: 100%;
  :not(:empty) {
    margin: 1rem 0 0 0;
  }
  > div {
    margin-right: 0.5rem;
  }
  overflow: hidden;
`

export const StyledSearchAutoComplete = styled(SearchAutoComplete)<SearchAutoCompleteProps>`
  align-items: flex-start;
  position: relative;
  > form {
    > div {
      > input {
        padding: 0.5rem 0rem;
      }
    }
  }
`
