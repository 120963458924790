import { Link, useLocation } from '@truepill/tpos-react-router'
import { ReactComponent as ZoomIcon } from 'assets/icons/zoom-in.svg'
import { FilledHeadingStyle } from 'components/PageStructure'
import Lozenge from 'components/Tiles/Lozenge'
import { goToViewPharmacyOrder } from 'routes'
import styled, { css } from 'styled-components'
import EllipsisTruncate from 'styles/EllipsisTruncate'
import { confirmColor, contrastBackgroundColor, primaryColorLight, bodyPrimaryColor } from 'styles/styleVariables'
import type { CustomerReturn, OTCProduct, Order } from 'types'
import { camelCaseToHumanReadable } from 'utils'

type ReturnsByItems = { [otcProductId: string]: CustomerReturn[] }

export function getReturnsByItems(customerReturns: CustomerReturn[]): ReturnsByItems {
  return customerReturns.reduce((acc: ReturnsByItems, customerReturn) => {
    customerReturn.otcProducts.forEach(({ otcProductId }) => {
      if (!acc[otcProductId]) {
        acc[otcProductId] = []
      }
      acc[otcProductId].push(customerReturn)
    })
    return acc
  }, {})
}

function getProductsById(otcProducts: OTCProduct[]) {
  return otcProducts.reduce((acc: { [key: string]: OTCProduct }, otcProduct) => {
    acc[otcProduct._id] = otcProduct
    return acc
  }, {})
}

type ItemReturnListProps = {
  order: Order
}

const ItemReturnList = ({ order }: ItemReturnListProps): JSX.Element => {
  if (!order) {
    return <></>
  }

  // No idea why it's possible to receive [undefined] back from this query
  // but we need to ship so I'll handle the garbage data here :D
  const customerReturns = (order.customerReturns || []).filter(val => val)

  const products = order.otcProducts || []
  const returnsByItems = getReturnsByItems(customerReturns)
  const productsById = getProductsById(products)

  customerReturns.push(customerReturns[0])

  return (
    <StyledItemReturnList>
      <HeaderRow>
        <Item>
          <Truncatable>Item</Truncatable>
        </Item>
        <OrderQuantity>
          <Truncatable>Ord qty</Truncatable>
        </OrderQuantity>
        <Quantity>
          <Truncatable>Qty</Truncatable>
        </Quantity>
        <Status>
          <Truncatable>Status</Truncatable>
        </Status>
        <Reason>
          <Truncatable>Reason</Truncatable>
        </Reason>
        <Actions>
          <Truncatable>Actions</Truncatable>
        </Actions>
        {/*
        <Shipping>
          <Truncatable>Shipping</Truncatable>
        </Shipping>
        */}
        <ShippingCharge>
          <Truncatable>Shipping Charge</Truncatable>
        </ShippingCharge>
        <TrackingNumber>
          <Truncatable>TrackingNumber</Truncatable>
        </TrackingNumber>
      </HeaderRow>

      {Object.keys(returnsByItems).map(otcProductId => {
        const returns = returnsByItems[otcProductId]
        return <ItemReturnEntry key={otcProductId} otcProduct={productsById[otcProductId]} customerReturns={returns} />
      })}
    </StyledItemReturnList>
  )
}

interface AggregateReturnInfo {
  actions: string[]
  reasons: string[]
  shippingMethods: string[]
  shippingCharges: (string | undefined)[]
  statuses: string[]
}

type ItemReturnEntryProps = { otcProduct: OTCProduct; customerReturns: CustomerReturn[] }

const ItemReturnEntry = ({ otcProduct, customerReturns }: ItemReturnEntryProps): JSX.Element => {
  const returnedOtcProduct = customerReturns[0]?.otcProducts[0]

  const aggregate = customerReturns.reduce(
    (acc: AggregateReturnInfo, customerReturn) => {
      acc.actions.push(camelCaseToHumanReadable(customerReturn.action))
      acc.reasons.push(camelCaseToHumanReadable(customerReturn.reason))
      if (customerReturn.status) {
        acc.statuses.push(camelCaseToHumanReadable(customerReturn.status))
      }
      if (customerReturn.shippingCharge) {
        acc.shippingCharges.push(camelCaseToHumanReadable(customerReturn.shippingCharge))
      }
      return acc
    },
    {
      actions: [],
      reasons: [],
      shippingMethods: [],
      shippingCharges: [],
      statuses: [],
    },
  )
  const { search } = useLocation()
  const orderId = customerReturns[0].returnedOrderId || ''
  return (
    <ReturnRow>
      <Item>
        <ReturnTextList>
          <li>
            <Truncatable>{otcProduct.name || otcProduct.sku}</Truncatable>
          </li>
        </ReturnTextList>
      </Item>
      <OrderQuantity>
        <ReturnTextList>
          <li>
            <Truncatable>{otcProduct.quantity}</Truncatable>
          </li>
        </ReturnTextList>
      </OrderQuantity>
      <Quantity>
        <ReturnTextList>
          <li>
            <Truncatable>{returnedOtcProduct.quantity}</Truncatable>
          </li>
        </ReturnTextList>
      </Quantity>
      <Status>
        {aggregate.statuses.map((status, i) => (
          <StyledOTCStatusLozenge key={`${status}${i}`}>{status}</StyledOTCStatusLozenge>
        ))}
      </Status>
      <Reason>
        <ReturnTextList>
          {aggregate.reasons.map((reason, i) => (
            <li key={`${reason}${i}`} id={'reason' + i}>
              {reason}
            </li>
          ))}
        </ReturnTextList>
      </Reason>
      <Actions>
        <ReturnTextList>
          {aggregate.actions.map((action, i) => (
            <li key={`${action}${i}`} id={'action' + i}>
              {action}
            </li>
          ))}
        </ReturnTextList>
      </Actions>
      {/*
      <Shipping>
        <ReturnTextList>
          {aggregate.shippingMethods.map((shippingMethod, i) => (
            <li id={'shippingMethod' + i}>{shippingMethod}</li>
          ))}
        </ReturnTextList>
      </Shipping>
        */}
      <ShippingCharge>
        <ReturnTextList>
          {aggregate.shippingCharges.map((shippingCharge, i) => (
            <li key={`${shippingCharge}${i}`} id={'shippingCharge' + i}>
              {shippingCharge}
            </li>
          ))}
        </ReturnTextList>
      </ShippingCharge>
      <TrackingNumber>
        <ReturnTextList>
          <li>
            <Truncatable>
              <StyledLink to={orderId ? goToViewPharmacyOrder({ orderId, search }) : ''}>
                <ZoomIcon fill={bodyPrimaryColor} />
              </StyledLink>
            </Truncatable>
          </li>
        </ReturnTextList>
      </TrackingNumber>
    </ReturnRow>
  )
}

const StyledLink = styled(Link)`
  :hover {
    background-color: ${primaryColorLight};
    :nth-of-type(2n + 1) {
      background-color: ${primaryColorLight};
    }
  }
  :nth-of-type(2n + 1) {
    background-color: ${contrastBackgroundColor};
  }
`

const GridColumns = css`
  grid-template-columns:
    [item] minmax(13rem, 1fr) [orderQty] minmax(4rem, 3rem) [quantity] minmax(2rem, 3rem)
    [status] minmax(6rem, 12rem) [reason] minmax(6rem, 10rem) [actions] minmax(6rem, 10rem)
    [shipping] 0rem [shippingCharge] 10rem [trackingNumber] auto;
`

const ReturnRow = styled.ul`
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  grid-column: content;
  grid-template-rows: [content] auto;
  ${GridColumns}
  :nth-of-type(2n + 2) {
    background-color: ${contrastBackgroundColor};
  }
  display: grid;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
`

const HeaderRow = styled(ReturnRow)`
  ${FilledHeadingStyle}
  padding-left: 0.625rem;
  padding-right: 0.625rem;
`

const ReturnRowCell = styled.li`
  width: 100%;
  overflow: hidden;
  grid-row: 1;
  ${EllipsisTruncate}
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 0.3125rem;
  padding-right: 0.3125rem;
`

const Item = styled(ReturnRowCell)`
  grid-column: item;
`

const OrderQuantity = styled(ReturnRowCell)`
  grid-column: orderQty;
`

const Quantity = styled(ReturnRowCell)`
  grid-column: quantity;
`

const Status = styled(ReturnRowCell)`
  grid-column: status;
`

const Reason = styled(ReturnRowCell)`
  grid-column: reason;
`

const Actions = styled(ReturnRowCell)`
  grid-column: actions;
`

const ShippingCharge = styled(ReturnRowCell)`
  grid-column: shippingCharge;
`

const TrackingNumber = styled(ReturnRowCell)`
  grid-column: trackingNumber;
`

const Truncatable = styled.p`
  ${EllipsisTruncate}
`

const ReturnTextList = styled.ul`
  width: 100%;
  > li {
    height: 2rem;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    ${EllipsisTruncate}
  }
`

const StyledItemReturnList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  margin-bottom: 1rem;
  min-height: 32rem;
`

const StyledOTCStatusLozenge = styled(Lozenge)`
  background-color: ${confirmColor};
  height: 1.25rem;
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
`

export default ItemReturnList
