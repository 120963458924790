export enum ChangeOfPrescriptionStatusFlag {
  C = 'Cancel',
  D = 'Discontinue',
}
export enum DispensingRequestCode {
  LOA = 'leave of absense',
}
export enum UrgencyIndicatorCode {
  S = 'Standard',
  X = 'Urgent/Expedited',
}
export type AcknowledgementReason = string
export type AdministrationTimingNumericValue = number
export type AlternateContactRelationship = string // INDIVIDUAL RELATIONSHIP CODES 0 - ZZ
export type ANDORTO = 'AND' | 'OR' | 'TO'
export type BooleanCode = 'Y' | 'N'
export type BooleanType = 'True' | 'False'
export type BusinessName = string
export type ChangeReasonText = string
export type Code = string
export type CodedReferenceQualifier =
  | 'AA'
  | 'AB'
  | 'ABF'
  | 'AC'
  | 'BPK'
  | 'CPT4'
  | 'CPT5'
  | 'CVX'
  | 'DX'
  | 'GPK'
  | 'HCPCS'
  | 'HL7OID'
  | 'HL7TEMPLATEID'
  | 'LD'
  | 'LOINC'
  | 'NDC'
  | 'RT'
  | 'SBD'
  | 'SCD'
  | 'UCUM'
  | 'UNII'
  | 'ZZ'
  | 'DI'
export type CompoundIngredientItemDescription = string
export type CurrentTreatmentCycle = number // type = "n1..2"
export type DaysSupply = number
export type DeliveryLocation = 'HOME' | 'FACILITY' | 'CONTACT PATIENT FOR DELIVERY' | 'AGENCY OF SERVICE' | 'PROVIDER'
export type DeliveryRequest = 'NO DELIVERY' | 'FIRST FILL DELIVERY' | 'ALL FILLS DELIVERY'
export type Depth = string
export type Description = string
export type Dispensed = 'AH'
export type DoNotFill = 'Y' | 'H' | 'E' // 'Y' - yes - 'H' - hold | 'E' - do not fill
export type DrugAdminReasonCode = '01' | '02' | '03' | '04' | '05'
export type DrugAdminReasonText = string
export type DrugCoverageStatusCode = 'PR' | 'AP' | 'PA' | 'NF' | 'NR' | 'DC' | 'UN' | 'ST' | 'SI'
export enum EclSourceQualifier {
  PHARMACY = 'P2',
  PRESCRIBER = 'PC',
  PAYER = 'PY',
}
export type FacilityName = string
export type FillNumber = number // " type = "n2"
export type FlavoringRequested = 'Y'
export type FMTVersion = string
export type Gender = 'female' | 'male' | 'unknown'
export type GestationalAge = number
export type HistoryPrescriberOrderNumber = string // type = "an1..35" minOccurs = "0"
export type HospiceIndicator = 1 | 2
export enum InjuryRelated {
  WORK = 'WORK',
  AUTO = 'AUTO',
  OTHER = 'OTHER',
}
export type Length = string
export type LotNumber = string // " type = "an1..140"
export type ManufacturerName = string
export type MaximumMeasurementValue = string
export type MeasurementDurationClarifyingFreeText = string
export type MeasurementDurationNumericValue = number
export type MeasurementNotes = string
export type MeasurementTimingClarifyingFreeText = string
export type MeasurementTimingNumericValue = number
export type MessageRequestCode = 'C1' | 'C2' | 'C3' | 'OS'
export type MessageType =
  | 'NewRx'
  | 'RefillResponse'
  | 'RxRenewalResponse'
  | 'RxRenewalRequest'
  | 'RxChangeRequest'
  | 'RxChangeResponse'
  | 'RxTransferConfirm'
  | 'RxTransferRequest'
  | 'RxTransferResponse'
export type MinimumMeasurementValue = string
export type NoKnownAllergies = 'Y'
export type NotDispensed = 'AK'
export type Note = string
export type NumberOfCyclesPlanned = number
export type NumberOfPackagesToBeDispensed = number
export type NumberOfRefills = number
export type NumberValue = number
export type OfficeOfPharmacyAffairsID = string[]
export type OrderCaptureMethod = 'EP' | 'VT' | 'VI' | 'WR' | 'TV' | 'OM'
export type Originator = 'pioneer' | 'surescripts' | 'surescripts-sender' | 'surescripts-message-consumer'
export type PartiallyDispensed = 'AJ'
export type PayerResponsibilityCode = 'P' | 'S' | 'T' | 'U' | 'PP'
export type PaymentType = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '99'
export type PharmacyRequestedRefills = number
export type PrescriberCheckedREMS = 'A' | 'B' | 'N' // Prescriber has checked REMS and the prescriber’s actions have been completed - Prescriber has checked REMS and the prescriber’s actions are not yet completed.< - Prescriber has not checked REMS.
export type PrescriberPlaceOfService = string
export type PrimaryDiagnosisCodeQualifierCode = 'ABF' | 'DX' | 'LD'
export type PriorAuthorization = string
export type PriorAuthorizationStatus = 'A' | 'D' | 'F' | 'N' | 'R'
export type ProductQualifierCode = 'DI' | 'ND' | 'RT' | 'UN' | 'UP' | 'NH' | 'GMP' | 'GPI' | 'GSP'
export type ProphylacticOrEpisodic = 'PROPHYLACTIC' | 'EPISODIC' | 'BOTH'
export type QuantityCodeListQualifier = '38' | '40' | '87' | 'QS' | 'CF' | 'UQ' | 'QT'
export type ReasonCode17 =
  | 'AA'
  | 'AF'
  | 'AP'
  | 'BC'
  | 'BY'
  | 'CA'
  | 'CD'
  | 'CQ'
  | 'CR'
  | 'CS'
  | 'CT'
  | 'CU'
  | 'CV'
  | 'CW'
  | 'CX'
export type ReasonCode18 = 'DF' | 'DG' | 'DH' | 'FT' | 'FV'
export type ReasonCode21 = 'DF' | 'DG' | 'DH' | 'FT' | 'FV'
export enum ReasonForSubstitutionCodeUsed {
  BRAND_MEDICALLY_NECESSARY = 'BRAND MEDICALLY NECESSARY',
}
export type ReferenceNumber = string
export type RefillsRemaining = number // type = "n1..2" minOccurs = "0"
export type REMSAuthorizationNumber = string
export type REMSPatientRiskCategory = string
export type ResidenceCode = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15
export type ReturnReceipt = '1' | string // 1 = Return Receipt Requested - all
export type SNOMEDVersion = string
export type SourceDescription = string // " type = "an1..35"
export type SourceReference = string // " type = "an1..35"
export type Specialty = string
export type SplitScript = 'B'
export type StrengthValue = string
export type StringValue = string
export type SubstitutionCode = '0' | '1'
export type TestingFrequency = number
export type TestingFrequencyNotes = string
export type Text = string
export type TimeZoneDifferenceQuantity = string // " minOccurs = "0" >"xsd:string" pattern value = "[\+\-]?[0-9][0-9]?[0-9]?[0-9]?" />
export type TimeZoneIdentifier = 'UT' // Universal Time Coordinate
export type TreatmentIndicator = 'NEW' | 'CONTINUATION' | 'RESTART'
export type UtcDateType = Date
export type VariableMeasurementTimingModifier = ANDORTO
export type Width = string
export enum WoundLateralityCode {
  Anterior = '255549009',
  Left = '7771000',
  Medial = '255561001',
  Posterior = '255551008',
  Right = '24028007',
}

export enum RxChangeCode {
  GenericSubstitution = 'G',
  PriorAuthorizationRequired = 'P',
  TherapeuticInterchangeSubstitution = 'T',
  DrugUseEvaluation = 'D',
  ScriptClarification = 'S',
  PharmacyOutOfStockUntimely = 'OS',
  PrescriberAuthorization = 'U',
}

// Codes used in response messages by the ultimate receiver. Used by Transactions-Response Status: RxChangeResponse-Validated>
export type ReasonCode22 =
  | 'GM' // Active Registration Status
  | 'GN' // In-Active License with prescriptive authority based on state/federal regulations
  | 'GP' // Active with Prescriptive Authority – prescribed product class
  | 'GQ' // Active with Prescriptive Authority – Prescriber Type
  | 'GR' // Active with Prescriptive Authority – Supervising Prescriber Type
  | 'GS' // Registered
  | 'GT' // Enrolled/Re-Enrolled
  | 'GU' // Assigned

// Codes used in response messages by the ultimate receiver. Used by Transactions-Response Status: RxChangeResponse-Denied
export type ReasonCode2 =
  | 'AA' // Patient unknown to the Provider
  | 'AB' // Patient never under  Provider care
  | 'AC' // Patient no longer under  Provider care
  | 'AE' // Medication never prescribed for the patient
  | 'AF' // Patient should contact Provider first
  | 'AL' // Change not appropriate
  | 'AM' // Patient needs appointment
  | 'AN' // Prescriber not associated with this practice or location
  | 'AO' // No attempt will be made to obtain Prior Authorization
  | 'AP' // Request already responded to by other means (e.g. phone or fax)
  | 'BE' // Medication denied at patient request

export enum MessageRequestSubCode {
  PrescriberConfirmStateLicenseStatus = 'A',
  PrescriberConfirmDEALicenseStatusInPrescribingState = 'B',
  PrescriberConfirmDEARegistrationByDEAClass = 'C',
  PrescriberConfirmStateControlledSubstanceRegistrationLicenseStatus = 'D',
  PrescriberConfirmRegistrationByStateControlledSubstanceRegistrationClass = 'E',
  PrescriberConfirmNADEANLicenseStatus = 'F',
  PrescriberObtainValidateType1NPI = 'G',
  PrescriberEnrollReEnrollPrescriptionBenefitPlan = 'H',
  PrescriberConfirmPrescriptiveAuthority = 'I',
  PrescriberEnrollReEnrollInREMS = 'J',
  PrescriberConfirmTheirAssignmentAsPatientsLockInPrescriber = 'K',
  PrescriberObtainVALIDATETheirSupervisingPrescriber = 'L',
  PrescriberConfirmTheirCertificateToPrescribeNumberStatus = 'M',
}

export enum SourceOfInformation {
  PATIENT_PROVIDED = 'P',
  CLINICIAN_PROVIDED = 'C',
}

export enum ProblemNameCodeQualifier {
  ICD_10_CM = 'ABF',
  ICD_9_CM_Diagnosis = 'DX',
  SNOMED = 'LD',
}

export type AllergyDrugProductCodedQualifier =
  | 'ND'
  | 'UP'
  | 'RT'
  | 'NH'
  | 'UN'
  | 'SCD'
  | 'SBD'
  | 'GPK'
  | 'BPK'
  | 'GMP'
  | 'GPI'
  | 'GSP'
  | 'DI'

export enum ClinicalInformationQualifier {
  Prescriber = 1,
  PharmacyInferred,
}

export enum ClinicalSignificanceCode {
  NotSpecified = '',
  Major = 1,
  Moderate,
  Minor,
  Undetermined = 9,
}

export type CompoundIngredientProductCodeQualifier =
  | 'ND'
  | 'UP'
  | 'RT'
  | 'NH'
  | 'UN'
  | 'SCD'
  | 'SBD'
  | 'GPK'
  | 'BPK'
  | 'GMP'
  | 'GPI'
  | 'GSP'
  | 'DI'

export type CoAgentQualifier =
  | '01'
  | '02'
  | '03'
  | '04'
  | '07'
  | '08'
  | '09'
  | '11'
  | '12'
  | '14'
  | '15'
  | '16'
  | '17'
  | '18'
  | '19'
  | '20'
  | '21'
  | '23'
  | '24'
  | '25'
  | '26'
  | '27'
  | '28'
  | '29'
  | '30'
  | '31'
  | '32'
  | '33'
  | '37'
  | '38'
  | '39'
  | '40'
  | '41'
  | '99'
  | 'DI'

export type DrugDBCodeQualifier =
  | 'E'
  | 'G'
  | 'AF'
  | 'FG'
  | 'FS'
  | 'MC'
  | 'MD'
  | 'MG'
  | 'MM'
  | 'FL'
  | 'FM'
  | 'FN'
  | 'FD'
  | 'GPK'
  | 'SCD'
  | 'BPK'
  | 'SBD'
  | 'FI'
  | 'FR'
  | 'GS'
  | 'GMP'
  | 'GPI'
  | 'GSP'
  | 'US'

export enum EclSNOMEDAdverseEventCode {
  ADVERSE_REACTION = '420134006',
  ADVERSE_REACTION_TO_AN_ENVIRONMENTAL_AGENT = '418038007',
  ADVERSE_REACTION_TO_A_DRUG = '419511003',
  ADVERSE_REACTION_TO_A_FOOD = '418471000',
  ALLERGY_TO_AN_ENVIRONMENTAL_AGENT = '419199007',
  ALLERGY_TO_A_DRUG = '416098002',
  ALLERGY_TO_A_FOOD = '414285001',
  INTOLERANCE_TO_A_DRUG = '59037007',
  INTOLERANCE_TO_A_FOOD = '235719002',
}

// Used in Prescription Change Request transactions, to request a change to the original new prescription.

export type LTCLevelOfChangeCode =
  | 'C1' // "Label change (Any changes to the Drug, form, strength, dosage, or route) – Change to an active order to the drug, form, strength, dosage, or route (long term care settings)."
  | 'C2' // "Frequency Change (Any change to the frequency or hours of administration for the drug) - Change to the frequency or hours of administration for the medication (long term care settings)."
  | 'C3' // "Other Change (All other changes) – A change to the medication not covered by other values listed (long term care settings).
  | 'OS' // "Pharmacy is out of stock of the medication prescribed and it cannot be obtained in a clinically appropriate timeframe."

export type OtherMedicationDateQualifier =
  | 'StartDate'
  | 'AnticipatedHealthCareFacilityDischargeDate'
  | 'DateValidated'
  | 'DeliveredOnDate'
  | 'ExpirationDate'
  | 'EffectiveDate'
  | 'OtherHealthCareFacilityDischargeDate'
  | 'PeriodEnd'
  | 'SoldDate'
  | 'TransplantHealthCareFacilityDischargeDateMedicare'
  | 'TransplantHealthCareFacilityDischargeDateNonMedicare'
  | 'TransplantDate'

export type PatientCodifiedNoteQualifier =
  | 'AA'
  | 'AB'
  | 'AC'
  | 'AD'
  | 'AE'
  | 'AF'
  | 'AG'
  | 'AH'
  | 'AJ'
  | 'AK'
  | 'AL'
  | 'AM'
  | 'AN'

export type ProfessionalServiceCode =
  | 'SC'
  | 'ZZ'
  | 'RO'
  | 'TC'
  | 'MR'
  | 'FE'
  | 'CC'
  | 'PM'
  | 'PE'
  | 'MO'
  | 'TH'
  | 'PA'
  | 'RT'
  | 'AS'
  | 'DE'
  | 'P0'
  | 'MA'
  | 'SW'
  | 'MB'
  | '00'
  | 'PT'
  | 'GP'
  | 'PH'
  | 'DP'
  | 'MP'

// Codes used in response messages by the ultimate receiver. Used by Transactions-Response Status: CancelRxResponse-Denied
export enum ReasonCode3 {
  PatientUnknownToTheProvider = 'AA',
  PatientNeverUnderProviderCare = 'AB',
  PatientNoLongerUnderProviderCare = 'AC',
  RequestAlreadyRespondedToByOtherMeans = 'AP', // (e.g. phone or fax)
  UnableToCancelTransferredToAnotherPharmacy = 'AR',
}

export enum ReasonCode7 {
  PatientUnknownToTheProvider = 'AA',
  PatientNeverUnderProviderCare = 'AB',
  PatientNoLongerUnderProviderCare = 'AC',
  PatientHasRequestedRefillTooSoon = 'AD',
  FillRefillNotAppropriate = 'AG',
  NoAttemptWillBeMadeToObtainPriorAuthorization = 'AO',
  ConscientiousObjection = 'BF',
  NotThePatientDesiredPharmacy = 'BG',
  OutOfStockManufacturerBackOrder = 'BP',
  DiscontinuedByManufacturer = 'BQ',
  DrugRecalledByManufacturer = 'BT',
  PrescriptionNotFound = 'CS',
  DrugUseEvaluation = 'DB',
  NoRefillsRemainingOrMedicationOrderHasExpired = 'DC',
  PharmacyHasNoIntentionToStockTheMedicationRequested = 'DD',
  PriorAuthorizationHasBeenDeniedByPayer = 'DE',
  MedicationPrescribedOutOfStockAndCannotBeObtainedInTime = 'FS',
}

export type ReasonCode9 = {
  AA: 'Patient unknown to the Provider'
  AB: 'Patient never under Provider care'
  AC: 'Patient no longer under  Provider care'
  AF: 'Patient should contact Provider first'
  AM: 'Patient needs appointment'
  AN: 'Prescriber not associated with this practice or location.'
  AP: 'Request already responded to by other means (e.g. phone or fax)'
}

export type ServiceReasonCode =
  | 'PC'
  | 'PP'
  | 'EX'
  | 'OH'
  | 'DF'
  | 'NS'
  | 'AN'
  | 'HD'
  | 'SC'
  | 'PA'
  | 'PG'
  | 'DA'
  | 'IC'
  | 'MN'
  | 'DI'
  | 'DL'
  | 'AR'
  | 'SX'
  | 'AT'
  | 'PR'
  | 'DC'
  | 'MS'
  | 'TD'
  | 'ID'
  | 'PS'
  | 'MX'
  | 'LD'
  | 'LK'
  | 'ED'
  | 'PH'
  | 'CD'
  | 'NF'
  | 'NC'
  | 'NA'
  | 'DM'
  | 'CH'
  | 'CS'
  | 'DD'
  | 'SD'
  | 'RE'
  | 'TN'
  | 'DR'
  | 'ER'
  | 'SE'
  | 'AD'
  | 'NP'
  | 'TP'
  | 'LR'
  | 'ND'
  | 'SF'
  | 'NN'
  | 'UD'
  | 'PN'
  | 'DS'
  | 'MC'
  | 'RF'
  | 'SR'
  | 'NR'

export type ServiceResultCode =
  | '4A'
  | '3C'
  | '1C'
  | '3E'
  | '3M'
  | '1E'
  | '2A'
  | '1B'
  | '3A'
  | '3D'
  | '1H'
  | '3B'
  | '3J'
  | '1F'
  | '1K'
  | '3H'
  | '1D'
  | '2B'
  | '3G'
  | '3N'
  | '1A'
  | '00'
  | '3F'
  | '3K'
  | '1J'
  | '1G'

export enum eclSNOMEDAdverseEventCode {
  AdverseReactions = '420134006',
  AdverseReactionsToSubstance = '418038007',
  AdverseReactionsToDrug = '419511003',
  AdverseReactionsToFood = '418471000',
  AllergyToSubstance = '419199007',
  DrugAllergy = '416098002',
  FoodAllergy = '414285001',
  DrugIntolerance = '59037007',
  FoodIntolerance = '235719002',
}

export enum RxFillIndicator {
  ALL_FILL_STATUSES = 'All Fill Statuses',
  ALL_FILL_STATUSES_EXCEPT_TRANSFERRED = 'All Fill Statuses Except Transferred',
  DISPENSED_AND_PARTIALLY_DISPENSED = 'Dispensed And Partially Dispensed',
  PARTIALLY_DISPENSED_AND_NOT_DISPENSED = 'Partially Dispensed And Not Dispensed',
  NOT_DISPENSED_AND_TRANSFERRED = 'Not Dispensed And Transferred',
  PARTIALLY_DISPENSED = 'Partially Dispensed',
  NOT_DISPENSED = 'Not Dispensed',
  CANCEL_ALL_FILL_STATUSES = 'Cancel All Fill Statuses',
}

export type PrescriberPlaceOfServiceCodes =
  | '01'
  | '02'
  | '03'
  | '04'
  | '05'
  | '06'
  | '07'
  | '08'
  | '09'
  | '11'
  | '12'
  | '13'
  | '14'
  | '15'
  | '16'
  | '17'
  | '18'
  | '19'
  | '20'
  | '21'
  | '22'
  | '23'
  | '24'
  | '25'
  | '26'
  | '31'
  | '32'
  | '33'
  | '34'
  | '41'
  | '42'
  | '49'
  | '50'
  | '51'
  | '52'
  | '53'
  | '54'
  | '55'
  | '56'
  | '57'
  | '58'
  | '60'
  | '61'
  | '62'
  | '65'
  | '71'
  | '72'
  | '81'
  | '99'

export type ReasonCode1 =
  | 'AA' // 'Patient unknown to the Provider',
  | 'AB' // 'Patient never under  Provider care',
  | 'AC' // 'Patient no longer under  Provider care',
  | 'AD' // 'Patient has requested refill too soon',
  | 'AE' // 'Medication never prescribed for the patient',
  | 'AF' // 'Patient should contact Provider first',
  | 'AG' // 'Fill/Refill not appropriate',
  | 'AM' // 'Patient needs appointment',
  | 'AN' // 'Prescriber not associated with this practice or location',
  | 'AP' // 'Request already responded to by other means (e.g. phone or fax)',
  | 'BE' // 'Medication denied at patient request',
  | 'CZ' // 'Patient had allergy to requested medication',
  | 'DA' // 'Medication has been discontinued',

export type ReasonCode =
  | 'AA' // 'Patient unknown to the Provider',
  | 'AB' // 'Patient never under  Provider care',
  | 'AC' // 'Patient no longer under  Provider care',
  | 'AD' // 'Patient has requested refill too soon',
  | 'AE' // 'Medication never prescribed for the patient',
  | 'AF' // 'Patient should contact Provider first',
  | 'AG' // 'Fill/Refill not appropriate',
  | 'AL' // 'Change not appropriate',
  | 'AM' // 'Patient needs appointment',
  | 'AN' // 'Prescriber not associated with this practice or location.',
  | 'AO' // 'No attempt will be made to obtain Prior Authorization',
  | 'AP' // 'Request already responded to by other means (e.g. phone or fax)',
  | 'AQ' // 'More Medication History Available',
  | 'AR' // 'Unable to cancel prescription; prescription was transferred to another pharmacy',
  | 'AS' // 'Qualified provider unavailable to provide this service',
  | 'AT' // 'Not accepting new patients',
  | 'AU' // 'Unable to accommodate service based parameters',
  | 'AV' // "These parameters do not meet the patient's needs",
  | 'AW' // 'Based on assessment, patient needs are outside of contractual agreement',
  | 'AX' // 'Patient condition no longer applicable',
  | 'AY' // 'Patient not available for service',
  | 'AZ' // 'Patient declined service',
  | 'BA' // 'Qualified provider unavailable to provide this service',
  | 'BB' // 'No Information Available',
  | 'BC' // 'Not Authorized to Release',
  | 'BD' // 'Unable to Send Response in Format Requested',
  | 'BE' // 'Medication denied at patient request',
  | 'BF' // 'Conscientious objection',
  | 'BG' // 'Not the patient-desired pharmacy',
  | 'BH' // 'Not In Central Fill inventory List',
  | 'BJ' // 'Out of Stock – The dispensing entity does not have sufficient quantity of the requested product to fulfill the order/prescription.',
  | 'BK' // 'Quantity to Dispense Incorrect for NDC Sent – NDC requested is for an item that must be distributed in the manufacturer’s packaging and the requested quantity is not an even multiple of the manufacturer’s packaging.',
  | 'BL' // 'Rx Received After Established Deadline – The order was not received in time for the requested shipment date.',
  | 'BM' // 'Duplicate Order – More than one message for an order was received.',
  | 'BN' // 'Fill Locally not filled by Central Fill Facility',
  | 'BO' // 'NDC Discontinued from Formulary – Do not resend.  The NDC number requested in on the discontinued inventory list and there is not sufficient quantity of the medication requested to fulfill the order.',
  | 'BP' // 'Out of Stock/Manufacturer Back Order – NDC requested is currently on back order from the manufacturer.',
  | 'BQ' // 'Discontinued by Manufacturer – NDC requested has been discontinued by the manufacturer.',
  | 'BR' // 'Rx Canceled by Central Fill Facility = Automation – Due to automation issues at the Central Fill Facility the Rx must be filled locally.',
  | 'BS' // 'Rx Canceled by Central Fill Facility = Site Closure – Due to site closure issues at Central Fill Facility the Rx must be filled locally.',
  | 'BT' // 'Drug Recalled by Manufacturer – NDC requested has been recalled by the manufacturer.',
  | 'BU' // 'Eligible for Fulfillment from Central Fill Facility',
  | 'BV' // 'Pharmacy not enrolled/aligned with Central Fill Facility',
  | 'BW' // 'Change to a different medication',
  | 'BX' // 'Electronic Prior Authorization not supported. Submit via other methods.',
  | 'BY' // 'Other',
  | 'BZ' // 'Can’t find PACase ID',
  | 'CA' // 'Unable to locate based on insufficient information - identifiers do not match',
  | 'CC' // 'Prior Authorization not required for patient/medication',
  | 'CB' // 'Request already processed - final determination has been made',
  | 'CD' // 'Cannot find matching patient',
  | 'CE' // 'Patient not eligible (does not have coverage with the payer) ',
  | 'CF' // 'Prior Authorization duplicate/approved',
  | 'CG' // 'Prior Authorization duplicate/in process',
  | 'CH' // 'Closed by health plan/payer/processor/PBM',
  | 'CJ' // 'Closed by Provider',
  | 'CK' // 'Closed by Member',
  | 'CL' // 'Attachment type (mimetype) not supported',
  | 'CM' // 'Prescriber not allowed to submit PA request',
  | 'CN' // 'Response content is inconsistent with the question',
  | 'CO' // 'The receiver is not the PA processor for this patient',
  | 'CP' // 'The receiver is not the PA processor for this patient and medication combination',
  | 'CQ' // 'Transfer needs to be discussed - call with information provided',
  | 'CR' // 'Prescription(s) cannot be electronically transferred; will be sent via manual transfer',
  | 'CS' // 'Prescription not found',
  | 'CT' // 'Prescription cannot be transferred out because it was previously transferred',
  | 'CU' // 'Prescription cannot be transferred out because it is voided/cancelled/deactivated',
  | 'CV' // 'Stop date has been exceeded',
  | 'CW' // 'Prescription cannot be transferred out because it has expired',
  | 'CX' // 'Prescription cannot be transferred out by law/regulation',
  | 'CY' // 'Prior Authorization duplicate/denied',
  | 'CZ' // 'Patient had allergy to requested medication',
  | 'DA' // 'Medication has been discontinued',
  | 'DB' // 'Drug Use Evaluation',
  | 'DC' // 'No refills remaining or medication order has expired',
  | 'DD' // 'Pharmacy has no intention to stock the medication requested/prescribed',
  | 'DE' // 'Prior Authorization has been denied by payer',
  | 'DF' // 'Generic Substitution – A modification of the product prescribed to a generic equivalent.',
  | 'DG' // 'Therapeutic Interchange/Substitution – A modification of the product prescribed to a preferred product choice.',
  | 'DH' // 'Profile Medication – medication appropriate for administration, not dispensed by pharmacy at this time.',
  | 'DJ' // 'No Data – There is a response indicating that no data was found for the search criteria provided in the search request.',
  | 'DK' // 'Prescription Data',
  | 'DL' // 'Disallowed – There was a permission problem of some type performing this request against this destination.',
  | 'DM' // 'Error- This response indicates a processing error in performing this request. (May only be used when a more specific ReasonCode does not apply).',
  | 'DN' // 'Excessive days supply according to REMS restrictions.',
  | 'DO' // 'Insufficient days supply according to REMS restrictions.',
  | 'DP' // 'Inappropriate days supply for the quantity prescribed according to REMS restrictions.',
  | 'DQ' // 'Excessive dosage according to REMS restrictions.',
  | 'DR' // 'Insufficient dosage according to REMS restrictions.',
  | 'DS' // 'Refills not permitted according to REMS restrictions.',
  | 'DT' // 'Quantity limit exceeds maximum according to REMS restrictions.',
  | 'DU' // 'Inappropriate quantity prescribed according to REMS restrictions.',
  | 'DV' // 'Laboratory test results not documented',
  | 'DW' // 'Laboratory test not conducted within specified time period.',
  | 'DX' // 'Prescribing not authorized due to laboratory test results.',
  | 'DY' // 'Prescriber has not documented safe use conditions.',
  | 'DZ' // 'Prescriber has not documented patient opioid tolerance.',
  | 'EA' // 'Prescriber has not documented that patient has met contraceptive requirements.',
  | 'EB' // 'Invalid prescription may not be electronically submitted; hand written orders only.',
  | 'EC' // 'Non matched Diagnosis code submitted.',
  | 'ED' // 'Patient First Name must be submitted.',
  | 'EE' // 'Patient Last Name must  be submitted.',
  | 'EF' // 'Patient Zip Code must be submitted.',
  | 'EG' // 'Multiple patient matches; call REMS Administrator.',
  | 'EH' // 'Patient First Name must be submitted.',
  | 'EI' // 'Patient is younger than the minimum age required.',
  | 'EJ' // 'Patient is older than the maximum age allowed.',
  | 'EK' // 'Patient is on a 'Do Not Rechallenge List'.',
  | 'EL' // 'Patient has not documented safe use conditions.',
  | 'EM' // 'Patient must enroll/certify.',
  | 'EN' // 'Patient must re-enroll/re-certify.',
  | 'EO' // 'Pharmacy not enrolled/certified.',
  | 'EP' // 'Pharmacy must re-enroll/re-certify.',
  | 'EQ' // 'Pharmacy not matched.',
  | 'ER' // 'Invalid Pharmacy type - contact program administrator.',
  | 'ES' // 'Presciber must enroll/certify.',
  | 'ET' // 'Presciber must re-enroll/re-certify.',
  | 'EU' // 'Prescriber qualifications for REMS not met.',
  | 'EV' // 'Missing/Invalid Prescriber ID.',
  | 'EW' // 'Non-Matched Prescriber last name.',
  | 'EX' // 'Prescriber has been deactivated.',
  | 'EY' // 'Pharmacy is not participating.',
  | 'EZ' // 'Prescriber-Patient Agreement not found.',
  | 'FA' // 'No attempt will be made to obtain REMS approval.',
  | 'FB' // 'Electronic REMS not supported. Submit via other methods.',
  | 'FC' // 'Can not find REMSCaseID',
  | 'FD' // 'REMS Authorization not required for patient/medication.',
  | 'FE' // 'Patient not eligible',
  | 'FF' // 'REMS Authorization duplicate/approved',
  | 'FG' // 'REMS Authorization duplicate/in process',
  | 'FH' // 'REMS Authorization duplicate/denied',
  | 'FI' // 'Closed by REMS Administrator',
  | 'FJ' // 'The receiver is not the REMS Administrator for this patient',
  | 'FK' // 'The receiver is not the REMS Administrator for this patient and medication combination',
  | 'FL' // 'Provider responded after deadline to reply.  PA must be reinitiated',
  | 'FM' // 'Product not covered by this plan. Prior Authorization not available. (Used when the drug will not be covered even if the provider does a PA)',
  | 'FN' // 'Prescription within prescribing limits. Prior Authorization not required for PATIENT/MEDICATION. (Used when there are coverage rules in place, but the prescriber is not going outside of them)',
  | 'FO' // 'Coverage limits may exist for quantity and/or days supply. Plan will pay up to coverage limit. Prior Authorization not required for PATIENT/MEDICATION. (Used when the plan will only pay up to a certain amount, but not above.)',
  | 'FP' // 'Coverage limits have been exceeded exception not available. Prior Authorization not required for PATIENT/MEDICATION when within coverage limit.',
  | 'FQ' // 'Active PA on file.',
  | 'FR' // 'Submitted Product Code is not valid.  Please resolve and resubmit.',
  | 'FS' // 'The medication prescribed is out of stock and cannot be obtained in a clinically appropriate timeframe.',
  | 'FT' // 'The medication presscribed is available for administration from the Automated Dispensing System',
  | 'FV' // 'The medication prescribed is available for cycle fill',
  | 'FW' // 'Patient requested reduced quantity.',
  | 'FX' // 'Prescriber requested/allowed reduced quantity.',
  | 'FY' // 'Days’ Supply and/or Quantity are limited by Payer.',
  | 'FZ' // 'Regulatory Days Supply Limitation',
  | 'GA' // 'Prescriber Authorization – prescriber must confirm their state license status.',
  | 'GB' // 'Prescriber Authorization – prescriber must confirm their DEA license status in prescribing state.',
  | 'GC' // 'Prescriber Authorization – prescriber must confirm their DEA registration by DEA class.',
  | 'GD' // 'Prescriber Authorization – prescriber must confirm their state Controlled Substance Registration license status.',
  | 'GE' // 'Prescriber Authorization – prescriber must confirm their registration by state Controlled Substance Registration class.',
  | 'GF' // 'Prescriber Authorization – prescriber must confirm their NADEAN license status.',
  | 'GG' // 'Prescriber Authorization – prescriber must obtain/validate Type1 NPI.',
  | 'GH' // 'Prescriber Authorization – prescriber must enroll/re-enroll with prescription benefit plan.',
  | 'GI' // 'Prescriber Authorization – prescriber must confirm prescriptive authority criteria for prescribed medication is met.',
  | 'GJ' // 'Prescriber Authorization – prescriber must enroll/re-enroll in REMS.  ',
  | 'GK' // 'Patient unknown to the Provider',
  | 'GL' // 'Prescriber Authorization – prescriber must obtain/validate their supervising prescriber.',
  | 'GM' // 'Active Registration Status',
  | 'GN' // 'In-Active License with prescriptive authority based on state/federal regulations',
  | 'GP' // 'Active with Prescriptive Authority – prescribed product class',
  | 'GQ' // 'Active with Prescriptive Authority – Prescriber Type',
  | 'GR' // 'Active with Prescriptive Authority – Supervising Prescriber Type',
  | 'GS' // 'Registered',
  | 'GT' // 'Enrolled/Re-Enrolled',
  | 'GU' // 'Assigned',
  | 'GV' // 'RequestorRole not authorized to receive PDMP data.',

export enum SignatureDigestMethod {
  SHA1 = 'SHA-1',
  SHA256 = 'SHA-256',
}
