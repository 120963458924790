import type { Dispatch, SetStateAction } from 'react'
import { UserRoles } from '@truepill/tpos-types'
import AddressEntry from 'components/AddressEntry'
import AddressForm from 'components/AddressForm'
import DatePicker from 'components/DatePicker'
import { CompoundLabel, CompoundTextInput, CompoundValue, ListRowLabel, ListRowValue, RXCell } from 'components/RXTable'
import useEditMode from 'hooks/useEditMode'
import moment from 'moment'
import { usePlusClient } from 'providers/VisionRouter'
import styled from 'styled-components'
import { contrastBackgroundColor } from 'styles/styleVariables'
import type { Address, Patient } from 'types'
import { checkIfProvided, formatPhoneNumber } from 'utils'
import { isHuman } from 'utils/Patient'

const StyledPatientDetails = styled.ul`
  > :nth-child(2n) {
    background-color: ${contrastBackgroundColor};
  }
`

const StyledRXCell = styled(RXCell)`
  grid-template-columns: [label] 6rem [value] 1fr;
  padding-left: 0.625rem;
`

type PatientDetailsProps = {
  className?: string
  patient: Patient
  patientValues: Patient
  setPatientValues: Dispatch<SetStateAction<Patient>>
  condensedView?: boolean
}

interface CellProps extends Omit<PatientDetailsProps, 'condensedView' | 'className'> {
  canEdit?: boolean
  editMode?: boolean
}

const DOBCell = ({ patient, patientValues, setPatientValues, canEdit, editMode }: CellProps) => {
  return (
    <StyledRXCell data-test-row="dob">
      <ListRowLabel data-private>DOB:</ListRowLabel>
      <ListRowValue data-private>
        {!editMode || !canEdit ? (
          <>{moment(patient.dob).format('MM/DD/YYYY')}</>
        ) : (
          <>
            <DatePicker
              isPrivate={true}
              date={moment(patientValues.dob, 'YYYY-MM-DD').toDate()}
              onChange={(date?: Date) => {
                setPatientValues({
                  ...patientValues,
                  dob: moment(date).format('YYYY-MM-DD') as any,
                })
              }}
            />
          </>
        )}
      </ListRowValue>
    </StyledRXCell>
  )
}

const NameCell = ({ patient, patientValues, setPatientValues, canEdit, editMode }: CellProps) => {
  return (
    <StyledRXCell data-test-row="name">
      {!editMode || !canEdit ? (
        <>
          <ListRowLabel>Name:</ListRowLabel>
          <ListRowValue data-private>
            {patient.firstName} {patient.lastName}
          </ListRowValue>
        </>
      ) : (
        <>
          <ListRowLabel>
            <CompoundLabel>First Name:</CompoundLabel>
            <CompoundLabel>Last Name:</CompoundLabel>
          </ListRowLabel>
          <CompoundValue>
            <CompoundTextInput
              data-private
              value={patientValues.firstName}
              onChange={e =>
                setPatientValues({
                  ...patientValues,
                  firstName: e.target.value,
                })
              }
            />
            <CompoundTextInput
              data-private
              value={patientValues.lastName}
              onChange={e =>
                setPatientValues({
                  ...patientValues,
                  lastName: e.target.value,
                })
              }
            />
          </CompoundValue>
        </>
      )}
    </StyledRXCell>
  )
}

const AddressCell = ({ patient, patientValues, setPatientValues, canEdit, editMode }: CellProps) => {
  return (
    <StyledRXCell data-test-row="address">
      <ListRowLabel>Address:</ListRowLabel>
      <ListRowValue data-private>
        {!editMode || !canEdit ? (
          <AddressEntry address={patient.address?.home} />
        ) : (
          <AddressForm
            {...patientValues.address?.home}
            onChange={(key: string, value: string) =>
              setPatientValues({
                ...patientValues,
                address: {
                  ...patientValues.address,
                  home: {
                    ...(patientValues.address || {}).home,
                    [key]: value,
                  } as Address,
                },
              })
            }
          />
        )}
      </ListRowValue>
    </StyledRXCell>
  )
}

const PhoneCell = ({ patient, patientValues, setPatientValues, canEdit, editMode }: CellProps) => {
  return (
    <StyledRXCell data-test-row="phone">
      <ListRowLabel>Phone:</ListRowLabel>
      <ListRowValue data-private>
        {!editMode || !canEdit ? (
          <>{checkIfProvided(formatPhoneNumber(patient.contacts?.phone))}</>
        ) : (
          <CompoundValue>
            <CompoundTextInput
              value={patientValues.contacts?.phone}
              onChange={e =>
                setPatientValues({
                  ...patientValues,
                  contacts: {
                    ...patientValues.contacts,
                    phone: e.target.value,
                  },
                })
              }
            />
          </CompoundValue>
        )}
      </ListRowValue>
    </StyledRXCell>
  )
}

const EmailCell = ({ patient, patientValues, setPatientValues, canEdit, editMode }: CellProps) => {
  return (
    <StyledRXCell data-test-row="email">
      <ListRowLabel>Email:</ListRowLabel>
      <ListRowValue data-private>
        {!editMode || !canEdit ? (
          <>{checkIfProvided(patient.contacts?.email)}</>
        ) : (
          <CompoundTextInput
            value={patientValues.contacts?.email}
            onChange={e => {
              setPatientValues({
                ...patientValues,
                contacts: {
                  ...patientValues.contacts,
                  email: e.target.value,
                },
              })
            }}
          />
        )}
      </ListRowValue>
    </StyledRXCell>
  )
}

const PatientDetails = (props: PatientDetailsProps): JSX.Element => {
  const { className, patient, condensedView } = props
  const [editMode] = useEditMode()
  const { tokenContext } = usePlusClient()
  const canEdit = tokenContext.hasRole([UserRoles.Pharmacist, UserRoles.CustomerSupport, UserRoles.Technician])

  return (
    <StyledPatientDetails data-testid="PatientDetails" className={className}>
      <NameCell {...props} editMode={editMode} canEdit={canEdit} />
      {!isHuman(patient) && (
        <>
          <StyledRXCell data-test-row="species">
            <ListRowLabel data-private>Species:</ListRowLabel>
            <ListRowValue data-private>{patient?.species}</ListRowValue>
          </StyledRXCell>
          <StyledRXCell data-test-row="guardian">
            <ListRowLabel data-private>Guardian:</ListRowLabel>
            <ListRowValue data-private>{patient?.guardian}</ListRowValue>
          </StyledRXCell>
        </>
      )}
      {!condensedView && <DOBCell {...props} editMode={editMode} canEdit={canEdit} />}
      <AddressCell {...props} editMode={editMode} canEdit={canEdit} />
      {!condensedView && <PhoneCell {...props} editMode={editMode} canEdit={canEdit} />}
      {!condensedView && <EmailCell {...props} editMode={editMode} canEdit={canEdit} />}
    </StyledPatientDetails>
  )
}

export default PatientDetails
