import { useQuery } from '@truepill/tpos-react-router'
import CloseButton from 'components/CloseButton'
import { SearchSelectedCard } from 'components/ColumnForm'
import { GET_PRESCRIBER } from 'gql'
import { useFormData } from 'hooks/useFormData'
import type { Prescriber } from 'types'

type PrescriberCardType = { prescriberId?: string; closeCallback?: () => void }

const getPrescriberSuffix = (prescriber: Prescriber): string => {
  if (prescriber.suffix) {
    return `, ${prescriber.suffix}`
  } else if (prescriber.npiDoc?.credential) {
    return `, ${prescriber.npiDoc.credential}`
  }

  return ''
}
const PrescriberCard = ({ prescriberId, closeCallback }: PrescriberCardType): JSX.Element => {
  const { data, loading, error } = useQuery(GET_PRESCRIBER, {
    variables: { prescriberId },
    skip: !prescriberId,
  })
  const {
    state: { formData },
  } = useFormData()

  if (loading) {
    return (
      <SearchSelectedCard data-testid="prescriber-loading">
        <p>Loading</p>
      </SearchSelectedCard>
    )
  }
  if (error) {
    return (
      <SearchSelectedCard data-testid="prescriber-error">
        <p>Error loading prescriber: {JSON.stringify(error)} </p>
      </SearchSelectedCard>
    )
  }

  const prescriber = !prescriberId ? formData.prescriber : (data.getPrescriber as Prescriber)

  return (
    <SearchSelectedCard data-testid="prescriber-selected">
      {closeCallback && <CloseButton clickCallback={closeCallback} />}
      <h4>
        {prescriber.firstName} {prescriber.lastName}
        {getPrescriberSuffix(prescriber as Prescriber)}
      </h4>
      <ul>
        <li data-testid="prescriber-npi">
          <strong>NPI:</strong> {prescriber.npi}
        </li>
        {prescriber.stateLicense && (
          <li data-testid="prescriber-state-license">
            <strong>License #:</strong> {prescriber.stateLicense}
          </li>
        )}
      </ul>
    </SearchSelectedCard>
  )
}

export default PrescriberCard
