import { Breadcrumbs, Text } from '@truepill/react-capsule'
import { useRouteMatch, useParams, Link, useLocation } from '@truepill/tpos-react-router'
import { capitalize } from 'lodash'
import styled from 'styled-components'
import {
  primaryBackgroundColor,
  primaryColorDark,
  bodySecondaryColor,
  capsuleDarkGreyColor,
  capsuleDarkBlue,
} from 'styles/styleVariables'
import { removeCamelCase } from 'utils'

interface Crumb {
  fullPath: string
  path: string
  paramValue?: string
}

const TRIM_SEGMENTS: { [key: string]: boolean } = {
  ':orderId': true,
  ':fulfillmentQueueName': true,
  ':fillId': true,
  ':copayRequestId': true,
  ':priorAuthorizationId': true,
}

type BreadCrumbProps = { className?: string; useCapsuleDesign?: boolean }

const BreadCrumb = ({ className, useCapsuleDesign = false }: BreadCrumbProps): JSX.Element => {
  const params: { [key: string]: string } = useParams<{ [key: string]: string }>()
  const { search } = useLocation()
  const { url, path } = useRouteMatch()
  // We're going to be applying all slashes using markup, not text
  const splitUrl = url.slice(1, url.length).split('/')
  const trimmedPath = path.slice(1, path.length)
  const segments = trimmedPath.split('/')
  const crumbs: Crumb[] = []
  // Iterate through segments of the path and wherever necessary, record
  // parms on their parent segment
  for (let i = 0; i < segments.length; i++) {
    const segment = segments[i]
    const matchesForParams = segment.match(/:(\w+)/)
    // RX and Order breadcrumbs work differently from other breadcrumbs,
    // collapsing down because there's no landing page so we have
    // to support that logic here
    const trimDownSegment = TRIM_SEGMENTS[segment as string]
    if (matchesForParams !== null) {
      const priorSegment = crumbs[crumbs.length - 1]
      const paramKey = matchesForParams[1]
      if (trimDownSegment) {
        priorSegment.paramValue = params[paramKey]
        priorSegment.fullPath += `/${params[paramKey]}`
        crumbs[crumbs.length - 1] = priorSegment
      } else {
        params[paramKey] &&
          crumbs.push({
            path: removeCamelCase(params[paramKey]),
            fullPath: '/' + splitUrl.slice(0, i + 1).join('/'),
          })
      }
    } else {
      crumbs.push({
        path: removeCamelCase(segment),
        fullPath: '/' + splitUrl.slice(0, i + 1).join('/'),
      })
    }
  }

  if (useCapsuleDesign) {
    return (
      <StyledNav className={className}>
        <CapsuleStyledBreadCrumbs>
          <Breadcrumbs aria-label="breadcrumb">
            {crumbs.map(({ path, paramValue, fullPath }, index) => {
              const crumbText = capitalize(paramValue !== undefined ? `${path}` : path)
              if (index === crumbs.length - 1) {
                return <Text key={fullPath + crumbText}>{crumbText}</Text>
              } else {
                return (
                  <StyledLink key={fullPath + crumbText} to={{ pathname: fullPath, search }}>
                    {crumbText}
                  </StyledLink>
                )
              }
            })}
          </Breadcrumbs>
        </CapsuleStyledBreadCrumbs>
      </StyledNav>
    )
  }

  return (
    <StyledNav className={className}>
      <StyledBreadCrumbs>
        {crumbs.map(({ path, paramValue, fullPath }) => {
          const crumbText = paramValue !== undefined ? `${path}` : path
          return (
            <StyledLink key={fullPath + crumbText} to={{ pathname: fullPath, search }}>
              {crumbText}
            </StyledLink>
          )
        })}
      </StyledBreadCrumbs>
    </StyledNav>
  )
}

const StyledLink = styled(Link)`
  display: inline-block;
  font-weight: 500;
  margin: 0.2rem;
`

const StyledNav = styled.nav`
  background-color: ${primaryBackgroundColor};
  z-index: 1;
  padding-left: 1.875rem;
`

const StyledBreadCrumbs = styled.ol`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-transform: capitalize;
  font-size: 0.875em;
  a {
    color: ${primaryColorDark};
    :visited {
      color: ${primaryColorDark};
    }
  }
  > :not(:first-child) {
    :before {
      display: inline-block;
      margin: 0 0.25em;
      transform: rotate(19deg) translate(0, 15%);
      border-right: 0.1rem solid ${bodySecondaryColor};
      margin-right: 0.4em;
      height: 0.8em;
      content: '';
    }
  }
  > :first-child {
    margin: 0 0;
  }
  > :last-child {
    color: ${bodySecondaryColor};
    cursor: default;
    :visited {
      color: ${bodySecondaryColor};
    }
  }
`

const CapsuleStyledBreadCrumbs = styled(StyledBreadCrumbs)`
  a {
    text-decoration: underline;
    color: ${capsuleDarkBlue};
    :visited {
      color: ${capsuleDarkBlue};
    }
  }
  > :last-child {
    color: ${capsuleDarkGreyColor};
    cursor: default;
    :visited {
      color: ${capsuleDarkGreyColor};
    }
  }
`

export default BreadCrumb
