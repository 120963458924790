import useLogs from 'hooks/navigation/useLogs'
import moment from 'moment'
import type { FlattenSimpleInterpolation } from 'styled-components'
import styled from 'styled-components'
import type { Log } from 'types'
import type { TableDefinition } from '../../Table'
import Table from '../../Table'

interface UserActivityProps {
  userId: string
}

interface ParsedLog {
  logId: string
  logEvent: string
  message: string
  createdDate: string
  change?: Change
  user?: string
}

interface Change {
  field: string
  oldValue: string
  newValue: string
}

export const UserLogs = ({ userId }: UserActivityProps): JSX.Element => {
  const { logs, loading } = useLogs({ updatedUserId: userId })

  if (!loading && !logs) {
    return <p>No data</p>
  }

  return (
    <UserColumn>
      <Table
        data={logs?.map(log => parseUserActivity(log))}
        keyField="logId"
        definition={definition}
        loading={loading}
      />
    </UserColumn>
  )
}

const parseUserActivity = (log: Log): ParsedLog => {
  const { _id: logId, message, event, change, createdAt, isAnnotation, user } = log

  const createdDate = parseDate(createdAt)
  const logEvent = isAnnotation ? 'Annotation' : event

  return {
    logId,
    logEvent,
    message,
    change: change as Change,
    createdDate,
    user: user && `${user.firstName} ${user.lastName}`,
  }
}

const definition: TableDefinition<ParsedLog>[] = [
  { field: 'logEvent', headerName: '', width: '1fr', minWidth: '8rem' },
  {
    headerName: 'User',
    width: '1fr',
    minWidth: '8rem',
    customRender: ({ user }) => {
      return (
        <div data-private>
          <p>{user}</p>
        </div>
      )
    },
  },
  {
    headerName: 'Actions',
    width: '5fr',
    minWidth: '25rem',
    customRender: ({ message, createdDate, change }) => {
      const { field, oldValue, newValue } = change ?? {}
      const changeFound = field && oldValue && newValue
      return (
        <div data-private>
          <p>{message}</p>
          {!!changeFound && (
            <>
              <p>{`Field: ${field}`}</p>
              <p>{`Old value: ${oldValue} - New Value: ${newValue}`}</p>
            </>
          )}
          <ActivityDate>Created: {createdDate}</ActivityDate>
        </div>
      )
    },
  },
]

const parseDate = (date: string) => {
  try {
    return moment(date).format('MM/DD/YYYY hh:mmA')
  } catch (e) {
    return ''
  }
}

const UserColumn = styled.ul<{
  styles?: FlattenSimpleInterpolation
}>`
  :not(:last-child) {
    margin-right: 1.25rem;
  }
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  ${({ styles }) => styles};
`

const ActivityDate = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #999999;
`
