import { useQuery } from '@truepill/tpos-react-router'
import { RxStatus } from '@truepill/tpos-types'
import { gql } from 'gql/generated'

const GET_DUPLICATE_PRESCRIPTIONS = gql(`
  query getDuplicatePrescriptions($ids: [ID]!, $sort: Int, $filterStatus: [String], $sortBy: String ) {
    getPrescriptionsByIds(ids: $ids, sort: $sort, filterStatus: $filterStatus, sortBy: $sortBy ) {
      ...DuplicatePrescription_PrescriptionFragment
    }
  }
`)

const useFetchDuplicatePrescriptions = (ids: string[]) => {
  const { data, loading, error } = useQuery(GET_DUPLICATE_PRESCRIPTIONS, {
    variables: {
      ids,
      filterStatus: [RxStatus.OnHold, RxStatus.InFulfillment, RxStatus.CopayCheck, RxStatus.Filled],
      sort: -1,
      sortBy: 'writtenDate',
    },
  })

  return {
    loading,
    error,
    prescriptions: data?.getPrescriptionsByIds ? data.getPrescriptionsByIds : [],
  }
}

export default useFetchDuplicatePrescriptions
