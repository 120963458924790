import type { FormValueType } from 'providers/FormDataProvider'
import { useFormDataContext } from 'providers/FormDataProvider'

type useSetFormDataValueType = (key: string, newValue: FormValueType) => void

const useSetFormDataValue = (nameSpace: string): useSetFormDataValueType => {
  const { setValues } = useFormDataContext()

  return (key: string, newValue: FormValueType) => {
    setValues(nameSpace, { [key]: newValue })
  }
}

export const useSetFormDataArrayValue = (nameSpace: string, index: number): useSetFormDataValueType => {
  const { setArrayValues, formData } = useFormDataContext()

  if (!formData[nameSpace]) {
    formData[nameSpace] = []
  }

  return (key: string, newValue: FormValueType) => {
    const x = formData[nameSpace] as any[]
    x[index] = { ...x[index], ...{ [key]: newValue } }
    setArrayValues(nameSpace, x)
  }
}

export default useSetFormDataValue
