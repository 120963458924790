import { useState } from 'react'
import { useMutation } from '@truepill/tpos-react-router'
import { ReactComponent as ChangeIcon } from 'assets/icons/cycle.svg'
import { SaveButton } from 'components/ActionButton'
import IconWrapper from 'components/IconWrapper'
import { ButtonsContainer, InputContainer, ModalHeader, ModalWrapper } from 'components/Modal'
import { CancelButton } from 'components/PageStructure'
import PaymentTypeSelect from 'components/PaymentTypeSelect'
import { StyledTextInput as TextInput } from 'components/RXTable'
import Select from 'components/Select'
import { UPDATE_PAYMENT_TYPE, REVERSE_ACTIVE_CLAIMS } from 'gql'
import useErrorToast from 'hooks/toast/useErrorToast'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import styled, { css } from 'styled-components'
import { bodyPrimaryColor } from 'styles/styleVariables'
import { PaymentType } from 'types'
import type { Order } from 'types'

interface ModalProps {
  order: Order
}

const EditPaymentTypeModal = (props: ModalProps): JSX.Element => {
  const { dismissModal } = useModalContext()
  const { paymentType, rxFillRequests } = props.order
  const [paymentMethod, setPaymentMethod] = useState(paymentType)
  const [reason, setReason] = useState('')
  const [note, setNote] = useState('')

  const [updatePaymentType, { loading }] = useMutation(UPDATE_PAYMENT_TYPE, {
    variables: { orderId: props.order._id, paymentType: paymentMethod, reason: reason, note: note },
    onCompleted: () => {
      showSuccessToast('Changed payment type.')
    },
    onError: e => {
      console.error('Error attempting to change the payment type: ', e)
      showErrorToast(`Could not change payment type: ${props.order._id}: ${e?.message ?? e}`)
    },
  })

  const [reverseActiveClaims, { loading: loadingReverse }] = useMutation(REVERSE_ACTIVE_CLAIMS, {
    refetchQueries: ['getBasicOrder'],
    onCompleted: () => {
      showSuccessToast('Reversed Claims.')
    },
    onError: e => {
      console.error('Error attempting to reverse claims while changing payment type: ', e)
      showErrorToast(
        `Error attempting to reverse claims while changing payment type: ${props.order._id}: ${e?.message ?? e}`,
      )
      throw e
    },
  })

  const showSuccessToast = useSuccessToast(true)
  const showErrorToast = useErrorToast(true)

  const changePaymentReasonOptions = [
    'Pharmacy not contracted',
    'Missing/invalid insurance info',
    'Primary Insurance Required',
    'Prior authorization required',
    'Medication not covered',
    'Refills not covered by insurance',
    'Patient must use preferred pharmacy',
    'Coverage terminated',
    'Qty limited by insurance',
    'Other',
  ]

  return (
    <ModalWrapper styles={modalWrapperStyles}>
      <ModalHeader marginBottom="12px">
        <IconWrapper>
          <ChangeIcon fill={bodyPrimaryColor} />
        </IconWrapper>
        <h2>Edit Payment Method</h2>
      </ModalHeader>
      <InputContainer data-testid="payment-type-select">
        <PaymentTypeSelect
          value={paymentMethod}
          multipleFills={rxFillRequests.length > 1}
          onSelect={(paymentMethod: PaymentType) => {
            setPaymentMethod(paymentMethod)
          }}
        />
      </InputContainer>
      {paymentType !== paymentMethod && (
        <InputContainer>
          <StyledSelect
            data-testid="justification-reason"
            disableClear
            value={reason}
            placeholder={'Select a justification reason…'}
            options={changePaymentReasonOptions}
            onChange={([option]) => setReason(option.value)}
          />
          <StyledTextInput value={note} placeholder={'Enter a message…'} onChange={e => setNote(e.target.value)} />
        </InputContainer>
      )}
      <ButtonsContainer>
        <CancelButton label={'Close'} onClick={dismissModal} />
        <SaveButton
          isModal
          disabled={!reason || (reason === 'Other' && note === '') || loading || loadingReverse}
          label={'Change Payment Type'}
          onClick={async () => {
            try {
              if (paymentType === PaymentType.insurance && paymentMethod === PaymentType.cash) {
                for await (const rxFillRequest of rxFillRequests) {
                  await reverseActiveClaims({
                    variables: {
                      fillId: rxFillRequest.fillId,
                    },
                  })
                }
              }
              await updatePaymentType()
            } catch (err) {}
            dismissModal()
          }}
        />
      </ButtonsContainer>
    </ModalWrapper>
  )
}

const modalWrapperStyles = css`
  overflow-y: hidden;
`

const StyledSelect = styled(Select)`
  margin-top: 0.625rem;
`

const StyledTextInput = styled(TextInput)`
  margin-top: 0.625rem;
  height: 2.5rem;
`

export default EditPaymentTypeModal
