import { useContext, useMemo, useCallback } from 'react'
import type { License } from '@truepill/tpos-types'
import type { FormDataState } from 'providers/Store/FormDataStore'
import type { StoreContextType } from 'providers/Store/StoreProvider'
import { StoreContext } from 'providers/Store/StoreProvider'
import type {
  Fill,
  Order,
  Patient,
  Prescription,
  User,
  TPOSLocation,
  ClaimsRequest,
  Prescriber,
  PrescriptionViewConfig,
  Printer,
  TransferRequestFormData,
  TransferOutFormData,
  TransferToPioneerFormData,
  RequestRefillFormData,
  Log,
  TPOSDocument,
  SigCode,
} from 'types'
import { diffObjects, omitDeep } from 'utils'

type FormDataKey = keyof FormDataState

type ChangesResponse =
  | Partial<Fill>
  | Partial<Order>
  | Partial<TPOSLocation>
  | Partial<ClaimsRequest>
  | Partial<Patient>
  | Partial<Prescriber>
  | Partial<Prescription>
  | Partial<Printer>
  | Partial<User>
  | Partial<TransferRequestFormData>
  | Partial<TransferOutFormData>
  | Partial<TransferToPioneerFormData>
  | Partial<RequestRefillFormData>
  | Partial<TPOSDocument>
  | Partial<Log>
  | Partial<SigCode>
  | Partial<License[]>
  | Partial<PrescriptionViewConfig>

export type useFormDataType = {
  changes: {
    getFillChanges: (original: Partial<Fill>) => ChangesResponse
    getOrderChanges: (original: Partial<Order>) => ChangesResponse
    getPatientChanges: (original: Partial<Patient>) => ChangesResponse
    getPrescriptionChanges: (original: Partial<Prescription>) => ChangesResponse
    getUserChanges: (original: Partial<User>) => ChangesResponse
    getLocationChanges: (original: Partial<TPOSLocation>) => ChangesResponse
    getSigCodeChanges: (original: Partial<SigCode>) => ChangesResponse
    getPrescriptionViewChanges: (original: Partial<PrescriptionViewConfig>) => ChangesResponse
  }
} & StoreContextType

export const useFormData = (): useFormDataType => {
  const { state, actions } = useContext(StoreContext)

  function getSanitizedDiff<T extends object>(original: T, changed: T, sanitizeKeys: string[]) {
    const diffed = diffObjects<T>(original, changed, undefined, true)
    return omitDeep<T>(diffed, sanitizeKeys)
  }

  const getFormDataChanges = useCallback(
    (original: FormDataState[FormDataKey], formDataKey: FormDataKey) => {
      return getSanitizedDiff(original, state.formData[formDataKey], ['__typename'])
    },
    [state.formData],
  )

  const changes = useMemo(() => {
    return {
      getFillChanges: (original: FormDataState['fill']) => getFormDataChanges(original, 'fill'),
      getOrderChanges: (original: FormDataState['order']) => getFormDataChanges(original, 'order'),
      getPatientChanges: (original: FormDataState['patient']) => getFormDataChanges(original, 'patient'),
      getPrescriptionChanges: (original: FormDataState['prescription']) => getFormDataChanges(original, 'prescription'),
      getUserChanges: (original: FormDataState['user']) => getFormDataChanges(original, 'user'),
      getLocationChanges: (original: FormDataState['location']) => getFormDataChanges(original, 'location'),
      getSigCodeChanges: (original: FormDataState['sigCode']) => getFormDataChanges(original, 'sigCode'),
      getPrescriptionViewChanges: (original: FormDataState['prescriptionView']) =>
        getFormDataChanges(original, 'prescriptionView'),
    }
  }, [getFormDataChanges])

  return { state, actions, changes }
}
