import { StrictMode } from 'react'
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev'
import { render } from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorkerRegistration'
import 'utils/fakeBarcodeScanner'

// Adds Apollo messages instead of links to view messages (potentially leaking PII/PHI) JR-17455
loadDevMessages()
loadErrorMessages()

render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
