import type { FC } from 'react'
import { useQuery } from '@truepill/tpos-react-router'
import DropDownOption from 'components/DropDownOption'
import SearchAutoComplete from 'components/SearchAutoComplete'
import { SEARCH_PHARMACY } from 'gql'
import useStateWithDebouncedValue from 'hooks/useStateWithDebouncedValue'
import { isEmpty } from 'ramda'
import { bodySecondaryColor } from 'styles/styleVariables'
import type { Pharmacy, PharmacyAddress } from 'types'
import { matchZip5Digits } from 'utils'

interface SuggestionPharmacy extends Pharmacy {
  _id: string
  highlighted?: boolean
}

interface Error {
  error: string
}
interface Loading {
  loading: boolean
}

type PharmacySuggestionItem = SuggestionPharmacy | Error | Loading

interface PharmacySearchTerms {
  address?: string
  zip?: string
  name?: string
  npi?: string
}

const matchNPI = (text: string): string | undefined => {
  const match = text.match(/^\d{10}$/)
  if (match) {
    return text
  }
}

// Based on a given text string, we should try and parse out what search terms
// the user intends
const deriveSearchTerms = (text: string): PharmacySearchTerms => {
  // If the text string is 5 numbers (or 9) we can assume it's a ZIP
  const zip = matchZip5Digits(text)
  const npi = matchNPI(text)
  if (npi) {
    return { npi }
  } else if (text.length && zip) {
    return { zip }
  } else if (text.length > 4) {
    return { name: text, address: text }
  }
  return {}
}

interface PharmacySearchProps {
  isModal?: boolean
  onSelect: (chosen: Pharmacy) => void
  withCapsule?: boolean
  state?: 'default' | 'complete' | 'error'
}

const PharmacySearch: FC<PharmacySearchProps> = ({ onSelect, isModal, withCapsule, state }) => {
  const [searchTerm, debouncedSearchTerm, setSearchTerm] = useStateWithDebouncedValue('')
  const { data, loading, error } = useQuery(SEARCH_PHARMACY, {
    skip: isEmpty(deriveSearchTerms(debouncedSearchTerm)),
    variables: deriveSearchTerms(debouncedSearchTerm),
  })

  const emptyOrErrorSuggestion = debouncedSearchTerm.length < 5 ? [] : [{ error: `No Result Found` }]
  let options: PharmacySuggestionItem[] = data?.searchPharmacies?.length
    ? data.searchPharmacies
    : emptyOrErrorSuggestion

  if (error) {
    options = [
      {
        error: `Error getting Pharmacies: ${JSON.stringify(error)}`,
      },
    ]
  }

  if (loading) {
    options = [{ loading }]
  }

  return (
    <SearchAutoComplete
      withCapsule={withCapsule}
      hotKey="p"
      isModal={isModal}
      iconColor={bodySecondaryColor}
      autoCompleteSuggestions={options}
      value={searchTerm}
      suggestionComponent={optionProps => <AutoCompleteOption {...(optionProps as PharmacySuggestionItem)} />}
      onChange={setSearchTerm}
      placeholder="Search pharmacy name, address, or NPI"
      state={state}
      onSelect={chosen => {
        const chosenPharmacy = chosen as Pharmacy
        onSelect(chosenPharmacy)
        setSearchTerm('')
      }}
    />
  )
}

const AutoCompleteOption = (props: PharmacySuggestionItem): JSX.Element => {
  if ((props as Error).error) {
    return (
      <DropDownOption>
        <p>{(props as Error).error}</p>
      </DropDownOption>
    )
  }

  if ((props as Loading).loading) {
    return (
      <DropDownOption>
        <p>Searching...</p>
      </DropDownOption>
    )
  }

  if (!(props as SuggestionPharmacy)._id) {
    return <></>
  }

  const pharmacy = props as SuggestionPharmacy

  const address = pharmacy.practiceAddresses[0] as PharmacyAddress

  const addressText = [address.street1, address.city, address.state].join(', ')

  return (
    <DropDownOption {...pharmacy}>
      <p>{pharmacy.alternativeOrganizationName || pharmacy.organizationName}</p>
      <p>{addressText}</p>
    </DropDownOption>
  )
}

export default PharmacySearch
