import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'

dayjs.extend(customParseFormat)
dayjs.extend(utc)

interface MessageHandler {
  match: RegExp
  handler: (match: RegExpMatchArray) => Date | undefined
}

const messageHandlers: MessageHandler[] = [
  {
    match: /NEXT AVAILABLE FILL DATE ([0-9]{8})/,
    handler: ([, date]) => parseFormattedDate(date, 'YYYYMMDD'),
  },
  {
    match: /Refill Payable on or after ([0-9]{2}\/[0-9]{2}\/[0-9]{2})/,
    handler: ([, date]) => parseFormattedDate(date, 'MM/DD/YY'),
  },
  {
    match: /REFILL PAYABLE ON OR AFTER ([0-9]{2}\-[0-9]{2}\-[0-9]{2})/,
    handler: ([, date]) => parseFormattedDate(date, 'MM/DD/YY'),
  },
  {
    match: /NEXT RFL ([0-9]{6})/,
    handler: ([, date]) => parseFormattedDate(date, 'MMDDYY'),
  },
  {
    match: /Next Fill Payable on or after ([0-9]{2}\/[0-9]{2}\/[0-9]{2})/,
    handler: ([, date]) => parseFormattedDate(date, 'MM/DD/YY'),
  },
  {
    match: /FILL ON ([0-9]{2}\/[0-9]{2}\/[0-9]{2})/,
    handler: ([, date]) => parseFormattedDate(date, 'MM/DD/YY'),
  },
  {
    match: /NEXT FILL AVAILABLE ON ([0-9]{2})-([A-Z]{3})-([0-9]{2})/,
    handler: ([, day, month, year]) => parseFormattedDate(`${day}-${simpleCapitalize(month)}-${year}`, 'DD-MMM-YY'),
  },
  {
    match: /RD: ([0-9]{2})-([A-Z]{3})-([0-9]{4})/,
    handler: ([, day, month, year]) => parseFormattedDate(`${day}-${simpleCapitalize(month)}-${year}`, 'DD-MMM-YYYY'),
  },
  {
    match: /Next Fill ?Date: ([0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4})/,
    handler: ([, date]) => parseFormattedDate(date, 'M/D/YYYY'),
  },
  {
    match: /RD:([0-9]{4}-[0-9]{2}-[0-9]{2})/,
    handler: ([, date]) => parseFormattedDate(date, 'YYYY-MM-DD'),
  },
]

const simpleCapitalize = (value: string) => {
  return value.charAt(0).toUpperCase() + value.substring(1).toLowerCase()
}

const parseFormattedDate = (value: string, format: string): Date | undefined => {
  const date = dayjs.utc(value, format, true).toDate()

  return date && !isNaN(date.getTime()) ? date : undefined
}

const getNextFillDateFromMessage = (
  message: string | undefined,
  { after, daysSupply, lastFillDate }: { after?: Date; daysSupply?: number; lastFillDate?: Date } = {},
): Date | undefined => {
  if (!message) {
    return undefined
  }

  const lines = message.split('\n')
  const messageWithSpaces = lines.join(' ')
  const messageWithoutLineReturns = lines.join('')

  for (const messageHandler of messageHandlers) {
    const match = messageWithoutLineReturns.match(messageHandler.match) ?? messageWithSpaces.match(messageHandler.match)

    if (!match) {
      continue
    }

    const result = messageHandler.handler(match)

    if (!result || isNaN(result.getTime())) {
      continue
    }

    if (after && after >= result) {
      continue
    }

    if (lastFillDate && daysSupply && dayjs(lastFillDate).add(daysSupply, 'days').toDate() < result) {
      continue
    }

    return result
  }

  return undefined
}

export default getNextFillDateFromMessage
