import type {
  AllergyOrAdverseEvent,
  Approved,
  ApprovedWithChanges,
  Denied,
  DeniedNewPrescriptionToFollow,
  Facility,
  FollowUpPrescriber,
  MedicationPrescribed,
  Observation,
  Patient,
  Pharmacy,
  Prescriber,
  RefillResponseStatus,
  Replace,
  Supervisor,
} from './shared'

export interface RefillResponse {
  messageId: string
  status: RefillResponseStatus
  reasonCode?: string
  // referenceNumber?: string,
  reason?: string
  note?: string
  // pharmacy?: any
}

export interface RxRenewalResponses {
  Approved?: Approved
  ApprovedWithChanges?: ApprovedWithChanges
  Denied?: Denied
  Replace?: Replace
  DeniedNewPrescriptionToFollow?: DeniedNewPrescriptionToFollow
}
export interface RxRenewalResponse {
  ReturnReceipt?: string
  RequestReferenceNumber?: string
  UrgencyIndicatorCode?: string
  Response: RxRenewalResponses
  AllergyOrAdverseEvent?: AllergyOrAdverseEvent
  Facility?: Facility
  Patient: Patient
  Pharmacy: Pharmacy
  Prescriber: Prescriber
  Observation?: Observation
  Supervisor?: Supervisor
  MedicationResponse: MedicationPrescribed // TODO: confirm and update for any schema differences betweeh MedicationResponse and MedicationPrescribed
  FollowUpPrescriber?: FollowUpPrescriber
}

export const ReasonCode1Enum = {
  AA: 'Patient unknown to the Provider',
  AB: 'Patient never under  Provider care',
  AC: 'Patient no longer under  Provider care',
  AD: 'Patient has requested refill too soon',
  AE: 'Medication never prescribed for the patient',
  AF: 'Patient should contact Provider first',
  AG: 'Fill/Refill not appropriate',
  AM: 'Patient needs appointment',
  AN: 'Prescriber not associated with this practice or location',
  AP: 'Request already responded to by other means (e.g. phone or fax)',
  BE: 'Medication denied at patient request',
  CZ: 'Patient had allergy to requested medication',
  DA: 'Medication has been discontinued',
}
export const ReasonCodeType = {
  AA: 'Patient unknown to the Provider',
  AB: 'Patient never under  Provider care',
  AC: 'Patient no longer under  Provider care',
  AD: 'Patient has requested refill too soon',
  AE: 'Medication never prescribed for the patient',
  AF: 'Patient should contact Provider first',
  AG: 'Fill/Refill not appropriate',
  AL: 'Change not appropriate',
  AM: 'Patient needs appointment',
  AN: 'Prescriber not associated with this practice or location.',
  AO: 'No attempt will be made to obtain Prior Authorization',
  AP: 'Request already responded to by other means (e.g. phone or fax)',
  AQ: 'More Medication History Available',
  AR: 'Unable to cancel prescription; prescription was transferred to another pharmacy',
  AS: 'Qualified provider unavailable to provide this service',
  AT: 'Not accepting new patients',
  AU: 'Unable to accommodate service based parameters',
  AV: "These parameters do not meet the patient's needs",
  AW: 'Based on assessment, patient needs are outside of contractual agreement',
  AX: 'Patient condition no longer applicable',
  AY: 'Patient not available for service',
  AZ: 'Patient declined service',
  BA: 'Qualified provider unavailable to provide this service',
  BB: 'No Information Available',
  BC: 'Not Authorized to Release',
  BD: 'Unable to Send Response in Format Requested',
  BE: 'Medication denied at patient request',
  BF: 'Conscientious objection',
  BG: 'Not the patient-desired pharmacy',
  BH: 'Not In Central Fill inventory List',
  BJ: 'Out of Stock – The dispensing entity does not have sufficient quantity of the requested product to fulfill the order/prescription.',
  BK: 'Quantity to Dispense Incorrect for NDC Sent – NDC requested is for an item that must be distributed in the manufacturer’s packaging and the requested quantity is not an even multiple of the manufacturer’s packaging.',
  BL: 'Rx Received After Established Deadline – The order was not received in time for the requested shipment date.',
  BM: 'Duplicate Order – More than one message for an order was received.',
  BN: 'Fill Locally not filled by Central Fill Facility',
  BO: 'NDC Discontinued from Formulary – Do not resend.  The NDC number requested in on the discontinued inventory list and there is not sufficient quantity of the medication requested to fulfill the order.',
  BP: 'Out of Stock/Manufacturer Back Order – NDC requested is currently on back order from the manufacturer.',
  BQ: 'Discontinued by Manufacturer – NDC requested has been discontinued by the manufacturer.',
  BR: 'Rx Canceled by Central Fill Facility = Automation – Due to automation issues at the Central Fill Facility the Rx must be filled locally.',
  BS: 'Rx Canceled by Central Fill Facility = Site Closure – Due to site closure issues at Central Fill Facility the Rx must be filled locally.',
  BT: 'Drug Recalled by Manufacturer – NDC requested has been recalled by the manufacturer.',
  BU: 'Eligible for Fulfillment from Central Fill Facility',
  BV: 'Pharmacy not enrolled/aligned with Central Fill Facility',
  BW: 'Change to a different medication',
  BX: 'Electronic Prior Authorization not supported. Submit via other methods.',
  BY: 'Other',
  BZ: 'Can’t find PACase ID',
  CA: 'Unable to locate based on insufficient information - identifiers do not match',
  CC: 'Prior Authorization not required for patient/medication',
  CB: 'Request already processed - final determination has been made',
  CD: 'Cannot find matching patient',
  CE: 'Patient not eligible (does not have coverage with the payer) ',
  CF: 'Prior Authorization duplicate/approved',
  CG: 'Prior Authorization duplicate/in process',
  CH: 'Closed by health plan/payer/processor/PBM',
  CJ: 'Closed by Provider',
  CK: 'Closed by Member',
  CL: 'Attachment type (mimetype) not supported',
  CM: 'Prescriber not allowed to submit PA request',
  CN: 'Response content is inconsistent with the question',
  CO: 'The receiver is not the PA processor for this patient',
  CP: 'The receiver is not the PA processor for this patient and medication combination',
  CQ: 'Transfer needs to be discussed - call with information provided',
  CR: 'Prescription(s) cannot be electronically transferred; will be sent via manual transfer',
  CS: 'Prescription not found',
  CT: 'Prescription cannot be transferred out because it was previously transferred',
  CU: 'Prescription cannot be transferred out because it is voided/cancelled/deactivated',
  CV: 'Stop date has been exceeded',
  CW: 'Prescription cannot be transferred out because it has expired',
  CX: 'Prescription cannot be transferred out by law/regulation',
  CY: 'Prior Authorization duplicate/denied',
  CZ: 'Patient had allergy to requested medication',
  DA: 'Medication has been discontinued',
  DB: 'Drug Use Evaluation',
  DC: 'No refills remaining or medication order has expired',
  DD: 'Pharmacy has no intention to stock the medication requested/prescribed',
  DE: 'Prior Authorization has been denied by payer',
  DF: 'Generic Substitution – A modification of the product prescribed to a generic equivalent.',
  DG: 'Therapeutic Interchange/Substitution – A modification of the product prescribed to a preferred product choice.',
  DH: 'Profile Medication – medication appropriate for administration, not dispensed by pharmacy at this time.',
  DJ: 'No Data – There is a response indicating that no data was found for the search criteria provided in the search request.',
  DK: 'Prescription Data',
  DL: 'Disallowed – There was a permission problem of some type performing this request against this destination.',
  DM: 'Error- This response indicates a processing error in performing this request. (May only be used when a more specific ReasonCode does not apply).',
  DN: 'Excessive days supply according to REMS restrictions.',
  DO: 'Insufficient days supply according to REMS restrictions.',
  DP: 'Inappropriate days supply for the quantity prescribed according to REMS restrictions.',
  DQ: 'Excessive dosage according to REMS restrictions.',
  DR: 'Insufficient dosage according to REMS restrictions.',
  DS: 'Refills not permitted according to REMS restrictions.',
  DT: 'Quantity limit exceeds maximum according to REMS restrictions.',
  DU: 'Inappropriate quantity prescribed according to REMS restrictions.',
  DV: 'Laboratory test results not documented',
  DW: 'Laboratory test not conducted within specified time period.',
  DX: 'Prescribing not authorized due to laboratory test results.',
  DY: 'Prescriber has not documented safe use conditions.',
  DZ: 'Prescriber has not documented patient opioid tolerance.',
  EA: 'Prescriber has not documented that patient has met contraceptive requirements.',
  EB: 'Invalid prescription may not be electronically submitted; hand written orders only.',
  EC: 'Non matched Diagnosis code submitted.',
  ED: 'Patient First Name must be submitted.',
  EE: 'Patient Last Name must  be submitted.',
  EF: 'Patient Zip Code must be submitted.',
  EG: 'Multiple patient matches; call REMS Administrator.',
  EH: 'Patient First Name must be submitted.',
  EI: 'Patient is younger than the minimum age required.',
  EJ: 'Patient is older than the maximum age allowed.',
  EK: "Patient is on a 'Do Not Rechallenge List'.",
  EL: 'Patient has not documented safe use conditions.',
  EM: 'Patient must enroll/certify.',
  EN: 'Patient must re-enroll/re-certify.',
  EO: 'Pharmacy not enrolled/certified.',
  EP: 'Pharmacy must re-enroll/re-certify.',
  EQ: 'Pharmacy not matched.',
  ER: 'Invalid Pharmacy type - contact program administrator.',
  ES: 'Presciber must enroll/certify.',
  ET: 'Presciber must re-enroll/re-certify.',
  EU: 'Prescriber qualifications for REMS not met.',
  EV: 'Missing/Invalid Prescriber ID.',
  EW: 'Non-Matched Prescriber last name.',
  EX: 'Prescriber has been deactivated.',
  EY: 'Pharmacy is not participating.',
  EZ: 'Prescriber-Patient Agreement not found.',
  FA: 'No attempt will be made to obtain REMS approval.',
  FB: 'Electronic REMS not supported. Submit via other methods.',
  FC: 'Can not find REMSCaseID',
  FD: 'REMS Authorization not required for patient/medication.',
  FE: 'Patient not eligible',
  FF: 'REMS Authorization duplicate/approved',
  FG: 'REMS Authorization duplicate/in process',
  FH: 'REMS Authorization duplicate/denied',
  FI: 'Closed by REMS Administrator',
  FJ: 'The receiver is not the REMS Administrator for this patient',
  FK: 'The receiver is not the REMS Administrator for this patient and medication combination',
  FL: 'Provider responded after deadline to reply.  PA must be reinitiated',
  FM: 'Product not covered by this plan. Prior Authorization not available. (Used when the drug will not be covered even if the provider does a PA)',
  FN: 'Prescription within prescribing limits. Prior Authorization not required for PATIENT/MEDICATION. (Used when there are coverage rules in place, but the prescriber is not going outside of them)',
  FO: 'Coverage limits may exist for quantity and/or days supply. Plan will pay up to coverage limit. Prior Authorization not required for PATIENT/MEDICATION. (Used when the plan will only pay up to a certain amount, but not above.)',
  FP: 'Coverage limits have been exceeded exception not available. Prior Authorization not required for PATIENT/MEDICATION when within coverage limit.',
  FQ: 'Active PA on file.',
  FR: 'Submitted Product Code is not valid.  Please resolve and resubmit.',
  FS: 'The medication prescribed is out of stock and cannot be obtained in a clinically appropriate timeframe.',
  FT: 'The medication presscribed is available for administration from the Automated Dispensing System',
  FV: 'The medication prescribed is available for cycle fill',
  FW: 'Patient requested reduced quantity.',
  FX: 'Prescriber requested/allowed reduced quantity.',
  FY: 'Days’ Supply and/or Quantity are limited by Payer.',
  FZ: 'Regulatory Days Supply Limitation',
  GA: 'Prescriber Authorization – prescriber must confirm their state license status.',
  GB: 'Prescriber Authorization – prescriber must confirm their DEA license status in prescribing state.',
  GC: 'Prescriber Authorization – prescriber must confirm their DEA registration by DEA class.',
  GD: 'Prescriber Authorization – prescriber must confirm their state Controlled Substance Registration license status.',
  GE: 'Prescriber Authorization – prescriber must confirm their registration by state Controlled Substance Registration class.',
  GF: 'Prescriber Authorization – prescriber must confirm their NADEAN license status.',
  GG: 'Prescriber Authorization – prescriber must obtain/validate Type1 NPI.',
  GH: 'Prescriber Authorization – prescriber must enroll/re-enroll with prescription benefit plan.',
  GI: 'Prescriber Authorization – prescriber must confirm prescriptive authority criteria for prescribed medication is met.',
  GJ: 'Prescriber Authorization – prescriber must enroll/re-enroll in REMS.  ',
  GK: 'Patient unknown to the Provider',
  GL: 'Prescriber Authorization – prescriber must obtain/validate their supervising prescriber.',
  GM: 'Active Registration Status',
  GN: 'In-Active License with prescriptive authority based on state/federal regulations',
  GP: 'Active with Prescriptive Authority – prescribed product class',
  GQ: 'Active with Prescriptive Authority – Prescriber Type',
  GR: 'Active with Prescriptive Authority – Supervising Prescriber Type',
  GS: 'Registered',
  GT: 'Enrolled/Re-Enrolled',
  GU: 'Assigned',
  GV: 'RequestorRole not authorized to receive PDMP data.',
}
