import { ReactComponent as EditIcon } from 'assets/icons/edit.svg'
import ActionButton from 'components/ActionButton'
import IconWrapper from 'components/IconWrapper'
import { bodyPrimaryColor } from 'styles/styleVariables'
import type { PriorAuthorization } from 'types'

type EditPriorAuthorizationActionButtonProps = {
  priorAuthorization?: PriorAuthorization
  isPriorAuthorization?: boolean
  disabled?: boolean
  clickCallback?: (priorAuthorizationId: string) => void
}

export const EditPriorAuthorizationActionButton = ({
  priorAuthorization,
  isPriorAuthorization = false,
  disabled = false,
  clickCallback,
}: EditPriorAuthorizationActionButtonProps): JSX.Element => {
  if (!isPriorAuthorization || !priorAuthorization) {
    return <></>
  }

  const priorAuthCMMStatusActionRequired = priorAuthorization?.cmmStatus === 'ActionRequired'
  const priorAuthEditButton = priorAuthorization?.actions.find(action => action.buttonLabel === 'Edit')
  if (!priorAuthCMMStatusActionRequired || !priorAuthEditButton) {
    return <></>
  }

  return (
    <ActionButton
      disabled={disabled}
      icon={
        <IconWrapper>
          <EditIcon fill={bodyPrimaryColor} />
        </IconWrapper>
      }
      label={priorAuthEditButton?.buttonLabel}
      onClick={() => {
        clickCallback?.(priorAuthorization._id)
        window.open(priorAuthEditButton?.url, '_blank')
      }}
    />
  )
}
