import { useEffect } from 'react'

const useKeyPress = (key: string, action: () => void): void => {
  useEffect(() => {
    function onKeyup(e: KeyboardEvent) {
      if (e.key === key) {
        action()
      }
    }
    window.addEventListener('keyup', onKeyup)
    return () => window.removeEventListener('keyup', onKeyup)
  }, [key])
}

export default useKeyPress
