import { useState } from 'react'
import type { RejectCode } from '@truepill/tpos-ncpdp-constants'
import { getRejectCodes } from '@truepill/tpos-ncpdp-constants'
import { useQuery } from '@truepill/tpos-react-router'
import DropDownOption from 'components/DropDownOption'
import SearchAutoComplete from 'components/SearchAutoComplete'
import { SearchResultLozenge, SearchResultLozengeList } from 'components/Tiles/Lozenge'
import { GET_ADJUDICATION_REJECT_CODES } from 'gql'
import { usePlusClient } from 'providers/VisionRouter'
import { borderColor } from 'styles/styleVariables'

interface RejectCodeSuggestion extends RejectCode {
  highlighted?: boolean
  selected?: boolean
}

interface Error {
  error: string
}

interface Loading {
  loading: boolean
}

type RejectCodeSuggestionItem = RejectCodeSuggestion | Error | Loading

const getRejectCodeNameById = (rejectCodes: RejectCode[], rejectCodeId: string) =>
  rejectCodes?.find(code => code._id === rejectCodeId)?.name

type RejectCodeSearchProps = {
  isVisible?: boolean
  hotKey?: string
  isModal?: boolean
}

const RejectCodeSearch = ({ isVisible, hotKey = 's', isModal }: RejectCodeSearchProps): JSX.Element => {
  const { routeToMergedQuery, currentLocation, tokenContext } = usePlusClient()
  const [searchTerm, setSearchTerm] = useState('')

  const { data } = useQuery(GET_ADJUDICATION_REJECT_CODES, {
    variables: {
      locationId: currentLocation.queryMap?.locationId || tokenContext?.locationId,
      medicationNames: currentLocation.queryMap?.medications || undefined,
      customerIds: currentLocation.queryMap?.customerIds || undefined,
      rejectCodes: currentLocation.queryMap?.rejectCodes || undefined,
      batchOrders:
        currentLocation.queryMap?.batchOrders !== undefined
          ? currentLocation.queryMap.batchOrders === 'true'
          : undefined,
      includeTriage:
        currentLocation.queryMap?.showTriage !== undefined ? currentLocation.queryMap.showTriage === 'true' : undefined,
    },
  })

  const rejectionCodes: RejectCode[] = getRejectCodes(data?.getAdjudicationRejectCodes?.codes)
  const selectedRejectCodes = currentLocation?.queryMap?.rejectCodes || []

  return (
    <>
      <SearchAutoComplete
        hotKey={isVisible ? hotKey : ''}
        isModal={isModal}
        iconColor={borderColor}
        placeholder={'Search by reject code...'}
        autoCompleteSuggestions={rejectionCodes?.filter(c => c.name.toLowerCase().includes(searchTerm.toLowerCase()))}
        value={searchTerm}
        onSelect={chosen => {
          const rejectCodes = chosen as RejectCode
          if (!selectedRejectCodes.includes(rejectCodes._id))
            routeToMergedQuery({ rejectCodes: [...selectedRejectCodes, rejectCodes._id], page: 0 })

          setSearchTerm('')
        }}
        suggestionComponent={optionProps => <AutoCompleteOption {...(optionProps as RejectCodeSuggestionItem)} />}
        onChange={setSearchTerm}
        showOptionsOnFocus={true}
      />
      <SearchResultLozengeList>
        {selectedRejectCodes.map((rejectCodeId: string) => (
          <SearchResultLozenge
            key={rejectCodeId}
            closeCallback={() => {
              if (selectedRejectCodes.includes(rejectCodeId)) {
                const newRejectCodes = [...selectedRejectCodes]
                newRejectCodes.splice(selectedRejectCodes.indexOf(rejectCodeId), 1)

                routeToMergedQuery({ rejectCodes: newRejectCodes })
              }
            }}
          >
            <p>{getRejectCodeNameById(rejectionCodes, rejectCodeId)}</p>
          </SearchResultLozenge>
        ))}
      </SearchResultLozengeList>
    </>
  )
}

const AutoCompleteOption = (props: RejectCodeSuggestionItem) => {
  if ((props as Error).error) {
    return (
      <DropDownOption {...props}>
        <p>{(props as Error).error}</p>
      </DropDownOption>
    )
  }

  if ((props as Loading).loading) {
    return (
      <DropDownOption {...props}>
        <p>Loading...</p>
      </DropDownOption>
    )
  }

  const rejectCodes = props as RejectCode

  return (
    <DropDownOption {...rejectCodes}>
      <p>{rejectCodes.name}</p>
    </DropDownOption>
  )
}

export default RejectCodeSearch
