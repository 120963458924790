import { createContext, useState } from 'react'
import type { ChildProps } from 'types'
import type { ChosenDuplicateReason } from './types'

export type ReviewDuplicateRxCtx = {
  selectedDuplicatePrescriptions: ChosenDuplicateReason[]
  setSelectedDuplicatePrescriptions: React.Dispatch<
    React.SetStateAction<
      | {
          description: string
          reason: string
          prescriptionId: string
        }[]
      | never[]
    >
  >
}

export const ReviewDuplicateRxContext = createContext<Partial<ReviewDuplicateRxCtx>>({
  selectedDuplicatePrescriptions: [],
})

const ReviewDuplicateRxContextProvider = ({ children }: ChildProps) => {
  const [selectedValues, setSelectedValues] = useState<
    { description: string; reason: string; prescriptionId: string }[] | never[]
  >([])

  const value = {
    selectedDuplicatePrescriptions: selectedValues,
    setSelectedDuplicatePrescriptions: setSelectedValues,
  }

  return <ReviewDuplicateRxContext.Provider value={value}>{children}</ReviewDuplicateRxContext.Provider>
}

export default ReviewDuplicateRxContextProvider
