import { UserRoles } from '@truepill/tpos-types'
import { ReactComponent as LocationIcon } from 'assets/icons/location.svg'
import Select from 'components/Select'
import { useTPCacheContext } from 'providers/TPCacheProvider'
import { usePlusClient } from 'providers/VisionRouter'

interface inputProps {
  limitedLocations?: [string]
  disableAll?: boolean
}

const LocationSelectorDropDown = (props: inputProps): JSX.Element => {
  const { limitedLocations, disableAll } = props
  const {
    currentLocation: { queryMap },
    routeToMergedQuery,
    tokenContext,
  } = usePlusClient()
  const { locations, getLocationNameById } = useTPCacheContext()
  const locationNameFromUrl = getLocationNameById(queryMap.locationId)
  const { hasRole } = tokenContext
  const isUserAdmin = hasRole && hasRole([UserRoles.Admin])

  const matchedLocations = limitedLocations
    ? locations.filter(location => limitedLocations.includes(location._id))
    : locations

  // We only want admin users to see all the locations (both active and inactive)
  const activeLocations = !isUserAdmin ? matchedLocations.filter(location => location.active) : matchedLocations

  if (!activeLocations.length) {
    // This reduces UI element jumping around during first load.
    return <Select options={[]} placeholder="Location" />
  }

  const locationOptions = [...activeLocations]
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(location => ({ label: location.name, value: location._id }))

  if (!disableAll) locationOptions.unshift({ label: 'All', value: '' })
  const value = { value: queryMap.locationId, label: locationNameFromUrl || 'All' }

  return (
    <Select
      data-testid="locations"
      fadePlaceholderText={false}
      hotKey="l"
      options={locationOptions}
      value={value}
      placeholder="Location"
      icon={<LocationIcon />}
      onChange={([option]) => {
        const selectedLocationId = option?.value || undefined

        if (selectedLocationId !== queryMap.locationId) {
          routeToMergedQuery({ locationId: !selectedLocationId ? undefined : selectedLocationId, page: 0 })
        }
      }}
      showActive={!!locationNameFromUrl}
      disableClear={true}
      showLoadingAnimation={locationOptions.length <= 1}
    />
  )
}

export default LocationSelectorDropDown
