import { Tooltip } from '@truepill/react-capsule'
import type { RejectCode } from '@truepill/tpos-ncpdp-constants'
import { rejectCodes } from '@truepill/tpos-ncpdp-constants'
import styled from 'styled-components'
import EllipsisTruncate from 'styles/EllipsisTruncate'

const ClaimRejectionReasons = ({ claims }: { claims: string[] }): JSX.Element => {
  const allClaims = claims?.map((claim: string) => {
    const description = rejectCodes[claim as keyof typeof rejectCodes]?.text
    return {
      name: `${claim}${description ? ` - ${description}` : ''}`,
      _id: claim,
    } as RejectCode
  })

  const lastClaim = allClaims.pop()

  const moreReasonsContent = (
    <>
      {allClaims.map((claim: RejectCode) => (
        <p key={claim._id}>{claim.name}</p>
      ))}
    </>
  )

  const firstReasonContent = lastClaim && <RejectionText>{lastClaim?.name}</RejectionText>

  return (
    <>
      {allClaims.length ? (
        <Tooltip label={moreReasonsContent}>
          {firstReasonContent} +{allClaims.length}
        </Tooltip>
      ) : (
        firstReasonContent
      )}
    </>
  )
}

const RejectionText = styled.span`
  max-width: 270px;
  ${EllipsisTruncate}
`

export default ClaimRejectionReasons
