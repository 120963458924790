import { useContext } from 'react'
import type { PopUpProviderType } from 'providers/PopUpProvider'
import { PopUpContext } from 'providers/PopUpProvider'

export const usePopup = () => {
  const popUpContext = useContext(PopUpContext) as PopUpProviderType

  return popUpContext
}

export default usePopup
