import styled from 'styled-components'
import { contrastColor } from 'styles/styleVariables'

const PrescriptionContainer = styled.div`
  display: flex;
  min-width: 95vw;
  flex-direction: column;
  align-items: stretch;
  border-bottom: 0.25rem solid ${contrastColor};
  border-radius: 0.25rem;
  flex: 1;
`

const StyledPrescriptionContainer = styled(PrescriptionContainer)`
  :last-child {
    border-bottom: none;
  }
`

const LoadingSpinnerContainer = styled.div`
  display: flex;
  padding-top: 4rem;
  justify-content: center;
  svg {
    height: 126px;
  }
`

export { PrescriptionContainer, StyledPrescriptionContainer, LoadingSpinnerContainer }
