import { useState, useCallback } from 'react'
import type { ApolloError } from '@truepill/tpos-react-router'
import { useQuery } from '@truepill/tpos-react-router'
import { GET_SESSIONS_FOR_USER } from 'gql'

export interface Session {
  _id: string
  isRevoked: boolean
  userId: string
  createdAt: string
  expiresAt: string
  remoteAddresses: {
    ip: string
    country: string
    region: string
    geoLocation: number[]
  }[]
}

interface GetSessionsForUserParams {
  userId?: string
  limit: number
  offset: number
}

export interface PaginatedSessions {
  sessions: Session[]
  offset: number
  limit: number
  total: number
}

interface GetSessionsForUserResponse {
  paginatedSessions: PaginatedSessions
}

type useUserSessionsType = {
  paginatedSessions?: PaginatedSessions
  fetchMoreSessions: (newOffset: number, newLimit: number) => void
  loading: boolean
  error?: ApolloError
}

const useUserSessions = (userId?: string): useUserSessionsType => {
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(10)

  const { data, loading, error } = useQuery<GetSessionsForUserResponse, GetSessionsForUserParams>(
    GET_SESSIONS_FOR_USER,
    {
      skip: !userId,
      variables: { userId, limit, offset },
    },
  )

  const fetchMoreSessions = useCallback((newOffset, newLimit) => {
    setOffset(newOffset)
    setLimit(newLimit)
  }, [])

  if (!data) {
    return {
      fetchMoreSessions,
      loading,
      error,
    }
  }

  const { paginatedSessions } = data

  return {
    paginatedSessions,
    fetchMoreSessions,
    loading,
    error,
  }
}

export default useUserSessions
