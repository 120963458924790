import { useEffect, useMemo } from 'react'
import { useParams, useQuery } from '@truepill/tpos-react-router'
import { GET_BASIC_COPAY } from 'gql'
import { useFormData } from 'hooks/useFormData'
import { useTPCacheContext } from 'providers/TPCacheProvider'
import type { Fill, Patient, Prescription, CopayRequestFill, Claim, CopayRequest } from 'types'
import type { LoadingError } from './types'
import usePatient from './usePatient'
import usePrescription from './usePrescription'

export interface Fulfillment extends LoadingError {
  copayRequest: Readonly<CopayRequest>
  fill: Readonly<Fill>
  prescription: Readonly<Prescription>
  copayRequestFill: Readonly<CopayRequestFill>
  patient: Readonly<Patient>
  claims: Claim[]
  lastClaim: Partial<Claim>
  loading: boolean
}

export default function useFill({
  fillId: fillIdProp,
  copayRequestId: copayRequestIdProp,
}: { fillId?: string; copayRequestId?: string } = {}): Fulfillment {
  const { getLocationById } = useTPCacheContext()
  const {
    actions: { updateFormData },
  } = useFormData()
  const pathParms = useParams<{
    fillId?: string
    copayRequestId?: string
  }>()
  const fillId = fillIdProp ?? pathParms.fillId
  const copayRequestId = copayRequestIdProp ?? pathParms.copayRequestId

  if (!copayRequestId && !fillId) {
    throw new Error('Order ID and fill ID required for useFill')
  }

  const { loading, error, data } = useQuery(GET_BASIC_COPAY, {
    variables: { copayRequestId },
  })

  const patientHookProps = usePatient({ loading, error, patient: data?.getCopay?.patient })
  const patient = patientHookProps.patient

  const copayRequestFill = Object.freeze(
    data?.getCopay?.copayRequestFills?.find((crf: CopayRequestFill) => crf.fillId === fillId),
  )
  const prescriptionHookProps = usePrescription({ loading, error, prescription: copayRequestFill?.prescription })
  const prescription = prescriptionHookProps.prescription as Prescription

  const { fill: f } = copayRequestFill || {}
  const fill = useMemo(() => {
    return { ...f, location: getLocationById(f?.locationId as string) } as Fill
  }, [f, getLocationById])

  // Init editable versions of the fill data
  useEffect(() => {
    updateFormData({ fill: { $set: fill } })
  }, [fill, updateFormData])

  // useEffect(() => {
  //   if (data?.getCopay?._id) {
  //     updateFormData({ copayRequest: { $set: data.getCopay } })
  //   }
  // }, [data, updateFormData])

  if (
    loading ||
    error ||
    patientHookProps.loading ||
    patientHookProps.error ||
    prescriptionHookProps.loading ||
    prescriptionHookProps.error
  ) {
    return {
      loading: loading || patientHookProps.loading || prescriptionHookProps.loading,
      error: error || patientHookProps.error || prescriptionHookProps.error,
    } as Fulfillment
  }

  return {
    copayRequest: data?.getCopay,
    patient,
    fill,
    claims: fill?.claims || [],
    lastClaim: fill?.claims ? fill?.claims[fill?.claims?.length - 1] : {},
    prescription,
    copayRequestFill,
    loading: !!loading || !!patientHookProps.loading || !!prescriptionHookProps.loading,
  }
}
