import { useState, useCallback } from 'react'

export type Selection<T = any> = { value: T; id: string }

type useMultiSelectionType<T = any> = {
  selections: Record<string, T>
  addSelection: (selection: Selection) => void
  deleteSelection: (id: string) => void
}

const useMultiSelection = <T = any>(initialValues: Selection<T>[]): useMultiSelectionType => {
  const [selections, setSelections] = useState<Record<string, T>>(
    initialValues.reduce((acc, selection) => ({ ...acc, [selection.id]: selection.value }), {}),
  )

  const addSelection = useCallback((newSelection: Selection<T>) => {
    setSelections(selections => ({ ...selections, [newSelection.id]: newSelection.value }))
  }, [])

  const deleteSelection = useCallback((id: string) => {
    setSelections(({ [id]: toRemove, ...rest }) => rest)
  }, [])

  return {
    selections,
    addSelection,
    deleteSelection,
  }
}

export default useMultiSelection
