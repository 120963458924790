/* eslint-disable */
import * as types from "./graphql";
import type { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  "\n  fragment AddressEntry_AddressFragment on Address {\n    street1\n    street2\n    state\n    city\n    zip\n  }\n":
    types.AddressEntry_AddressFragmentFragmentDoc,
  "\n  fragment PatientCoverageInfo_InsuranceFragment on Insurance {\n    _id\n    group\n    bin\n    cardholderId\n  }\n":
    types.PatientCoverageInfo_InsuranceFragmentFragmentDoc,
  "\n  fragment PatientCoverageInfo_PatientFragment on Patient {\n    _id\n    insurances {\n      ...PatientCoverageInfo_InsuranceFragment\n    }\n  }\n":
    types.PatientCoverageInfo_PatientFragmentFragmentDoc,
  "\n  query getPatientCoverageInfo($patientId: ID!) {\n    getPatient(_id: $patientId) {\n      ...PatientCoverageInfo_PatientFragment\n    }\n  }\n":
    types.GetPatientCoverageInfoDocument,
  "\n  fragment DemographicInfo_PatientFragment on Patient {\n    _id\n    firstName\n    lastName\n    dob\n    gender\n    contacts {\n      email\n      phone\n    }\n    address {\n      home {\n        ...AddressEntry_AddressFragment\n      }\n    }\n    medicalHistory {\n      allergies {\n        idField\n        value\n        type\n        name\n      }\n      medications {\n        idField\n        value\n        type\n        name\n      }\n      conditions {\n        idField\n        value\n        type\n        name\n      }\n    }\n  }\n":
    types.DemographicInfo_PatientFragmentFragmentDoc,
  "\n  query getPatientDemographicInfo($patientId: ID!) {\n    getPatient(_id: $patientId) {\n      ...DemographicInfo_PatientFragment\n    }\n  }\n":
    types.GetPatientDemographicInfoDocument,
  "\n  fragment DuplicatePrescription_PrescriptionFragment on Prescription {\n    _id\n    name\n    strength {\n      value\n      form\n      unit\n    }\n    fills {\n      status\n      dispensed {\n        dispensedAt\n      }\n    }\n    customer {\n      name\n    }\n    dosageForm\n    ndc\n    quantity\n    daysSupply\n    directions\n    rxNumber\n    daw\n    refillsRemaining\n    quantityRemaining\n    writtenDate\n    expirationDate\n    status\n  }\n":
    types.DuplicatePrescription_PrescriptionFragmentFragmentDoc,
  "\n  query getDuplicatePrescriptions($ids: [ID]!, $sort: Int, $filterStatus: [String], $sortBy: String ) {\n    getPrescriptionsByIds(ids: $ids, sort: $sort, filterStatus: $filterStatus, sortBy: $sortBy ) {\n      ...DuplicatePrescription_PrescriptionFragment\n    }\n  }\n":
    types.GetDuplicatePrescriptionsDocument,
  "\n  mutation DiscontinuePrescriptions($orderId: ID!, $prescriptions: [DiscontinuePrescription]!) {\n    discontinuePrescriptions(orderId: $orderId, prescriptions: $prescriptions) {\n      successful {\n        rxNumber\n      }\n      unsuccessful{\n        rxNumber\n        error\n      }\n    }\n  }\n":
    types.DiscontinuePrescriptionsDocument,
  "\n  mutation ResolveDuplicatePrescriptionTriages($orderId: ID!, $prescriptionIds: [ID]!) {\n    resolveDuplicatePrescriptionTriages(orderId: $orderId, prescriptionIds: $prescriptionIds) {\n      successful\n      unsuccessful\n    }\n  }\n":
    types.ResolveDuplicatePrescriptionTriagesDocument,
  "\n  fragment NdcPackage_NdcPackageFragment on NdcFullInfoPackage {\n      ndc\n      packageSize\n      packageSizeUnitOfMeasure\n      packageQuantity\n      isUnbreakable\n      hasInnerPackSibling\n  }\n":
    types.NdcPackage_NdcPackageFragmentFragmentDoc,
  "\n  query getNdcPackage($ndcs: [String]!) {\n    getNdcPackage(ndcs: $ndcs) {\n      ...NdcPackage_NdcPackageFragment\n    }\n  }\n":
    types.GetNdcPackageDocument,
  "\n  query getPatientPrescriptions(\n    $patientIds: [ID]\n    $statuses: [String]\n    $customerIds: [ID]\n    $pageNumber: Int\n    $pageSize: Int\n  ) {\n    getPrescriptions(\n      patientIds: $patientIds\n      statuses: $statuses\n      customerIds: $customerIds\n      pageNumber: $pageNumber\n      pageSize: $pageSize\n    ) {\n      currentPage\n      totalRecords\n      prescriptions {\n        _id\n        rxNumber\n        customer {\n          _id\n          name\n          legacyId\n        }\n        fills {\n          _id\n          fillNumber\n          dispensed {\n            dispensedAt\n          }\n        }\n        name\n        quantity\n        daysSupply\n        directions\n        quantityRemaining\n        refillsRemaining\n        writtenDate\n        patient {\n          firstName\n          lastName\n        }\n        prescriber {\n          firstName\n          lastName\n          suffix\n        }\n        status\n        strength {\n          value\n          form\n          unit\n        }\n      }\n    }\n  }\n":
    types.GetPatientPrescriptionsDocument,
  "\n  query patientPrescriptions(\n    $patientIds: [ID]\n    $statuses: [String]\n    $customerIds: [ID]\n    $pageNumber: Int\n    $pageSize: Int\n  ) {\n    getPrescriptions(\n      patientIds: $patientIds\n      statuses: $statuses\n      customerIds: $customerIds\n      pageNumber: $pageNumber\n      pageSize: $pageSize\n    ) {\n      currentPage\n      totalRecords\n      prescriptions {\n        _id\n        rxNumber\n        customer {\n          _id\n          name\n          legacyId\n        }\n        fills {\n          _id\n          fillNumber\n          dispensed {\n            dispensedAt\n          }\n        }\n        name\n        quantity\n        daysSupply\n        directions\n        quantityRemaining\n        refillsRemaining\n        writtenDate\n        patient {\n          firstName\n          lastName\n        }\n        prescriber {\n          firstName\n          lastName\n          suffix\n        }\n        status\n        strength {\n          value\n          form\n          unit\n        }\n      }\n    }\n  }\n":
    types.PatientPrescriptionsDocument,
  "\nmutation sendOutboundScan($trackingNumber: String!, $outboundScanType: OutboundScanType!) {\n  sendOutboundScan(trackingNumber: $trackingNumber, outboundScanType: $outboundScanType) {\n    trackingNumber\n    scanAt\n    order {\n      _id\n      patient {\n        _id\n        firstName\n        lastName\n      }\n    }\n  }\n}\n":
    types.SendOutboundScanDocument,
  "\n  mutation confirmFillScanWithStockInfo(\n    $orderId: ID!\n    $fillId: ID!\n    $ndc: String!\n    $stockInfo: [StockInfoInput!]!\n    $manualEntryFilled: Boolean!\n  ) {\n    confirmFillScanWithStockInfo(\n      orderId: $orderId\n      fillId: $fillId\n      ndcPackage: $ndc\n      stockInfo: $stockInfo\n      manualEntryFilled: $manualEntryFilled\n    ) {\n      fill {\n        _id\n        dispensed {\n          ndc\n          packagesRequired\n          stockInfo {\n            lot\n            expirationDate\n            serialNumber\n            quantity\n          }\n          manualEntryFilled\n        }\n        shortFillCode\n      }\n    }\n  }\n":
    types.ConfirmFillScanWithStockInfoDocument,
  "\nquery getOtcOnlyOrders($locationId: ID, $customerId: ID, $states: [String]) {\n  getOtcOnlyOrders(locationId: $locationId, customerId: $customerId, states: $states) {\n    Packing\n  }\n}\n":
    types.GetOtcOnlyOrdersDocument,
  "\nquery getFillsByStatus($locationId: ID, $customerId: ID, $states: [String]) {\n  getFillsByStatus(locationId: $locationId, customerId: $customerId, states: $states) {\n    PV1 {\n      total\n      between24And48Hours\n      greaterThan48Hours\n    }\n    Fill {\n      total\n      between24And48Hours\n      greaterThan48Hours\n    }\n    Automation {\n      total\n      between24And48Hours\n      greaterThan48Hours\n    }\n    PV2 {\n      total\n      between24And48Hours\n      greaterThan48Hours\n    }\n    Adjudication {\n      total\n      between24And48Hours\n      greaterThan48Hours\n    }\n    PriorAuthorization {\n      total\n      between24And48Hours\n      greaterThan48Hours\n    }\n  }\n}\n":
    types.GetFillsByStatusDocument,
  "\nquery getOrdersByStatus($locationId: ID, $customerId: ID, $states: [String]) {\n  getOrdersByStatus(locationId: $locationId, customerId: $customerId, states: $states) {\n    packing\n  }\n}\n":
    types.GetOrdersByStatusDocument,
  "\nquery getTriages($locationId: ID, $customerId: ID, $states: [String]) {\n  getTriages(locationId: $locationId, customerId: $customerId, states: $states) {\n    inTriage\n  }\n}\n":
    types.GetTriagesDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\n  fragment AddressEntry_AddressFragment on Address {\n    street1\n    street2\n    state\n    city\n    zip\n  }\n"
): (typeof documents)["\n  fragment AddressEntry_AddressFragment on Address {\n    street1\n    street2\n    state\n    city\n    zip\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\n  fragment PatientCoverageInfo_InsuranceFragment on Insurance {\n    _id\n    group\n    bin\n    cardholderId\n  }\n"
): (typeof documents)["\n  fragment PatientCoverageInfo_InsuranceFragment on Insurance {\n    _id\n    group\n    bin\n    cardholderId\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\n  fragment PatientCoverageInfo_PatientFragment on Patient {\n    _id\n    insurances {\n      ...PatientCoverageInfo_InsuranceFragment\n    }\n  }\n"
): (typeof documents)["\n  fragment PatientCoverageInfo_PatientFragment on Patient {\n    _id\n    insurances {\n      ...PatientCoverageInfo_InsuranceFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\n  query getPatientCoverageInfo($patientId: ID!) {\n    getPatient(_id: $patientId) {\n      ...PatientCoverageInfo_PatientFragment\n    }\n  }\n"
): (typeof documents)["\n  query getPatientCoverageInfo($patientId: ID!) {\n    getPatient(_id: $patientId) {\n      ...PatientCoverageInfo_PatientFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\n  fragment DemographicInfo_PatientFragment on Patient {\n    _id\n    firstName\n    lastName\n    dob\n    gender\n    contacts {\n      email\n      phone\n    }\n    address {\n      home {\n        ...AddressEntry_AddressFragment\n      }\n    }\n    medicalHistory {\n      allergies {\n        idField\n        value\n        type\n        name\n      }\n      medications {\n        idField\n        value\n        type\n        name\n      }\n      conditions {\n        idField\n        value\n        type\n        name\n      }\n    }\n  }\n"
): (typeof documents)["\n  fragment DemographicInfo_PatientFragment on Patient {\n    _id\n    firstName\n    lastName\n    dob\n    gender\n    contacts {\n      email\n      phone\n    }\n    address {\n      home {\n        ...AddressEntry_AddressFragment\n      }\n    }\n    medicalHistory {\n      allergies {\n        idField\n        value\n        type\n        name\n      }\n      medications {\n        idField\n        value\n        type\n        name\n      }\n      conditions {\n        idField\n        value\n        type\n        name\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\n  query getPatientDemographicInfo($patientId: ID!) {\n    getPatient(_id: $patientId) {\n      ...DemographicInfo_PatientFragment\n    }\n  }\n"
): (typeof documents)["\n  query getPatientDemographicInfo($patientId: ID!) {\n    getPatient(_id: $patientId) {\n      ...DemographicInfo_PatientFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\n  fragment DuplicatePrescription_PrescriptionFragment on Prescription {\n    _id\n    name\n    strength {\n      value\n      form\n      unit\n    }\n    fills {\n      status\n      dispensed {\n        dispensedAt\n      }\n    }\n    customer {\n      name\n    }\n    dosageForm\n    ndc\n    quantity\n    daysSupply\n    directions\n    rxNumber\n    daw\n    refillsRemaining\n    quantityRemaining\n    writtenDate\n    expirationDate\n    status\n  }\n"
): (typeof documents)["\n  fragment DuplicatePrescription_PrescriptionFragment on Prescription {\n    _id\n    name\n    strength {\n      value\n      form\n      unit\n    }\n    fills {\n      status\n      dispensed {\n        dispensedAt\n      }\n    }\n    customer {\n      name\n    }\n    dosageForm\n    ndc\n    quantity\n    daysSupply\n    directions\n    rxNumber\n    daw\n    refillsRemaining\n    quantityRemaining\n    writtenDate\n    expirationDate\n    status\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\n  query getDuplicatePrescriptions($ids: [ID]!, $sort: Int, $filterStatus: [String], $sortBy: String ) {\n    getPrescriptionsByIds(ids: $ids, sort: $sort, filterStatus: $filterStatus, sortBy: $sortBy ) {\n      ...DuplicatePrescription_PrescriptionFragment\n    }\n  }\n"
): (typeof documents)["\n  query getDuplicatePrescriptions($ids: [ID]!, $sort: Int, $filterStatus: [String], $sortBy: String ) {\n    getPrescriptionsByIds(ids: $ids, sort: $sort, filterStatus: $filterStatus, sortBy: $sortBy ) {\n      ...DuplicatePrescription_PrescriptionFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\n  mutation DiscontinuePrescriptions($orderId: ID!, $prescriptions: [DiscontinuePrescription]!) {\n    discontinuePrescriptions(orderId: $orderId, prescriptions: $prescriptions) {\n      successful {\n        rxNumber\n      }\n      unsuccessful{\n        rxNumber\n        error\n      }\n    }\n  }\n"
): (typeof documents)["\n  mutation DiscontinuePrescriptions($orderId: ID!, $prescriptions: [DiscontinuePrescription]!) {\n    discontinuePrescriptions(orderId: $orderId, prescriptions: $prescriptions) {\n      successful {\n        rxNumber\n      }\n      unsuccessful{\n        rxNumber\n        error\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\n  mutation ResolveDuplicatePrescriptionTriages($orderId: ID!, $prescriptionIds: [ID]!) {\n    resolveDuplicatePrescriptionTriages(orderId: $orderId, prescriptionIds: $prescriptionIds) {\n      successful\n      unsuccessful\n    }\n  }\n"
): (typeof documents)["\n  mutation ResolveDuplicatePrescriptionTriages($orderId: ID!, $prescriptionIds: [ID]!) {\n    resolveDuplicatePrescriptionTriages(orderId: $orderId, prescriptionIds: $prescriptionIds) {\n      successful\n      unsuccessful\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\n  fragment NdcPackage_NdcPackageFragment on NdcFullInfoPackage {\n      ndc\n      packageSize\n      packageSizeUnitOfMeasure\n      packageQuantity\n      isUnbreakable\n      hasInnerPackSibling\n  }\n"
): (typeof documents)["\n  fragment NdcPackage_NdcPackageFragment on NdcFullInfoPackage {\n      ndc\n      packageSize\n      packageSizeUnitOfMeasure\n      packageQuantity\n      isUnbreakable\n      hasInnerPackSibling\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\n  query getNdcPackage($ndcs: [String]!) {\n    getNdcPackage(ndcs: $ndcs) {\n      ...NdcPackage_NdcPackageFragment\n    }\n  }\n"
): (typeof documents)["\n  query getNdcPackage($ndcs: [String]!) {\n    getNdcPackage(ndcs: $ndcs) {\n      ...NdcPackage_NdcPackageFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\n  query getPatientPrescriptions(\n    $patientIds: [ID]\n    $statuses: [String]\n    $customerIds: [ID]\n    $pageNumber: Int\n    $pageSize: Int\n  ) {\n    getPrescriptions(\n      patientIds: $patientIds\n      statuses: $statuses\n      customerIds: $customerIds\n      pageNumber: $pageNumber\n      pageSize: $pageSize\n    ) {\n      currentPage\n      totalRecords\n      prescriptions {\n        _id\n        rxNumber\n        customer {\n          _id\n          name\n          legacyId\n        }\n        fills {\n          _id\n          fillNumber\n          dispensed {\n            dispensedAt\n          }\n        }\n        name\n        quantity\n        daysSupply\n        directions\n        quantityRemaining\n        refillsRemaining\n        writtenDate\n        patient {\n          firstName\n          lastName\n        }\n        prescriber {\n          firstName\n          lastName\n          suffix\n        }\n        status\n        strength {\n          value\n          form\n          unit\n        }\n      }\n    }\n  }\n"
): (typeof documents)["\n  query getPatientPrescriptions(\n    $patientIds: [ID]\n    $statuses: [String]\n    $customerIds: [ID]\n    $pageNumber: Int\n    $pageSize: Int\n  ) {\n    getPrescriptions(\n      patientIds: $patientIds\n      statuses: $statuses\n      customerIds: $customerIds\n      pageNumber: $pageNumber\n      pageSize: $pageSize\n    ) {\n      currentPage\n      totalRecords\n      prescriptions {\n        _id\n        rxNumber\n        customer {\n          _id\n          name\n          legacyId\n        }\n        fills {\n          _id\n          fillNumber\n          dispensed {\n            dispensedAt\n          }\n        }\n        name\n        quantity\n        daysSupply\n        directions\n        quantityRemaining\n        refillsRemaining\n        writtenDate\n        patient {\n          firstName\n          lastName\n        }\n        prescriber {\n          firstName\n          lastName\n          suffix\n        }\n        status\n        strength {\n          value\n          form\n          unit\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\n  query patientPrescriptions(\n    $patientIds: [ID]\n    $statuses: [String]\n    $customerIds: [ID]\n    $pageNumber: Int\n    $pageSize: Int\n  ) {\n    getPrescriptions(\n      patientIds: $patientIds\n      statuses: $statuses\n      customerIds: $customerIds\n      pageNumber: $pageNumber\n      pageSize: $pageSize\n    ) {\n      currentPage\n      totalRecords\n      prescriptions {\n        _id\n        rxNumber\n        customer {\n          _id\n          name\n          legacyId\n        }\n        fills {\n          _id\n          fillNumber\n          dispensed {\n            dispensedAt\n          }\n        }\n        name\n        quantity\n        daysSupply\n        directions\n        quantityRemaining\n        refillsRemaining\n        writtenDate\n        patient {\n          firstName\n          lastName\n        }\n        prescriber {\n          firstName\n          lastName\n          suffix\n        }\n        status\n        strength {\n          value\n          form\n          unit\n        }\n      }\n    }\n  }\n"
): (typeof documents)["\n  query patientPrescriptions(\n    $patientIds: [ID]\n    $statuses: [String]\n    $customerIds: [ID]\n    $pageNumber: Int\n    $pageSize: Int\n  ) {\n    getPrescriptions(\n      patientIds: $patientIds\n      statuses: $statuses\n      customerIds: $customerIds\n      pageNumber: $pageNumber\n      pageSize: $pageSize\n    ) {\n      currentPage\n      totalRecords\n      prescriptions {\n        _id\n        rxNumber\n        customer {\n          _id\n          name\n          legacyId\n        }\n        fills {\n          _id\n          fillNumber\n          dispensed {\n            dispensedAt\n          }\n        }\n        name\n        quantity\n        daysSupply\n        directions\n        quantityRemaining\n        refillsRemaining\n        writtenDate\n        patient {\n          firstName\n          lastName\n        }\n        prescriber {\n          firstName\n          lastName\n          suffix\n        }\n        status\n        strength {\n          value\n          form\n          unit\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\nmutation sendOutboundScan($trackingNumber: String!, $outboundScanType: OutboundScanType!) {\n  sendOutboundScan(trackingNumber: $trackingNumber, outboundScanType: $outboundScanType) {\n    trackingNumber\n    scanAt\n    order {\n      _id\n      patient {\n        _id\n        firstName\n        lastName\n      }\n    }\n  }\n}\n"
): (typeof documents)["\nmutation sendOutboundScan($trackingNumber: String!, $outboundScanType: OutboundScanType!) {\n  sendOutboundScan(trackingNumber: $trackingNumber, outboundScanType: $outboundScanType) {\n    trackingNumber\n    scanAt\n    order {\n      _id\n      patient {\n        _id\n        firstName\n        lastName\n      }\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\n  mutation confirmFillScanWithStockInfo(\n    $orderId: ID!\n    $fillId: ID!\n    $ndc: String!\n    $stockInfo: [StockInfoInput!]!\n    $manualEntryFilled: Boolean!\n  ) {\n    confirmFillScanWithStockInfo(\n      orderId: $orderId\n      fillId: $fillId\n      ndcPackage: $ndc\n      stockInfo: $stockInfo\n      manualEntryFilled: $manualEntryFilled\n    ) {\n      fill {\n        _id\n        dispensed {\n          ndc\n          packagesRequired\n          stockInfo {\n            lot\n            expirationDate\n            serialNumber\n            quantity\n          }\n          manualEntryFilled\n        }\n        shortFillCode\n      }\n    }\n  }\n"
): (typeof documents)["\n  mutation confirmFillScanWithStockInfo(\n    $orderId: ID!\n    $fillId: ID!\n    $ndc: String!\n    $stockInfo: [StockInfoInput!]!\n    $manualEntryFilled: Boolean!\n  ) {\n    confirmFillScanWithStockInfo(\n      orderId: $orderId\n      fillId: $fillId\n      ndcPackage: $ndc\n      stockInfo: $stockInfo\n      manualEntryFilled: $manualEntryFilled\n    ) {\n      fill {\n        _id\n        dispensed {\n          ndc\n          packagesRequired\n          stockInfo {\n            lot\n            expirationDate\n            serialNumber\n            quantity\n          }\n          manualEntryFilled\n        }\n        shortFillCode\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\nquery getOtcOnlyOrders($locationId: ID, $customerId: ID, $states: [String]) {\n  getOtcOnlyOrders(locationId: $locationId, customerId: $customerId, states: $states) {\n    Packing\n  }\n}\n"
): (typeof documents)["\nquery getOtcOnlyOrders($locationId: ID, $customerId: ID, $states: [String]) {\n  getOtcOnlyOrders(locationId: $locationId, customerId: $customerId, states: $states) {\n    Packing\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\nquery getFillsByStatus($locationId: ID, $customerId: ID, $states: [String]) {\n  getFillsByStatus(locationId: $locationId, customerId: $customerId, states: $states) {\n    PV1 {\n      total\n      between24And48Hours\n      greaterThan48Hours\n    }\n    Fill {\n      total\n      between24And48Hours\n      greaterThan48Hours\n    }\n    Automation {\n      total\n      between24And48Hours\n      greaterThan48Hours\n    }\n    PV2 {\n      total\n      between24And48Hours\n      greaterThan48Hours\n    }\n    Adjudication {\n      total\n      between24And48Hours\n      greaterThan48Hours\n    }\n    PriorAuthorization {\n      total\n      between24And48Hours\n      greaterThan48Hours\n    }\n  }\n}\n"
): (typeof documents)["\nquery getFillsByStatus($locationId: ID, $customerId: ID, $states: [String]) {\n  getFillsByStatus(locationId: $locationId, customerId: $customerId, states: $states) {\n    PV1 {\n      total\n      between24And48Hours\n      greaterThan48Hours\n    }\n    Fill {\n      total\n      between24And48Hours\n      greaterThan48Hours\n    }\n    Automation {\n      total\n      between24And48Hours\n      greaterThan48Hours\n    }\n    PV2 {\n      total\n      between24And48Hours\n      greaterThan48Hours\n    }\n    Adjudication {\n      total\n      between24And48Hours\n      greaterThan48Hours\n    }\n    PriorAuthorization {\n      total\n      between24And48Hours\n      greaterThan48Hours\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\nquery getOrdersByStatus($locationId: ID, $customerId: ID, $states: [String]) {\n  getOrdersByStatus(locationId: $locationId, customerId: $customerId, states: $states) {\n    packing\n  }\n}\n"
): (typeof documents)["\nquery getOrdersByStatus($locationId: ID, $customerId: ID, $states: [String]) {\n  getOrdersByStatus(locationId: $locationId, customerId: $customerId, states: $states) {\n    packing\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: "\nquery getTriages($locationId: ID, $customerId: ID, $states: [String]) {\n  getTriages(locationId: $locationId, customerId: $customerId, states: $states) {\n    inTriage\n  }\n}\n"
): (typeof documents)["\nquery getTriages($locationId: ID, $customerId: ID, $states: [String]) {\n  getTriages(locationId: $locationId, customerId: $customerId, states: $states) {\n    inTriage\n  }\n}\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
  TDocumentNode extends DocumentNode<infer TType, any> ? TType : never;
