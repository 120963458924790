import { Link } from '@truepill/tpos-react-router'
import type { RedirectEntity } from '@truepill/tpos-types'
import { RedirectDestination } from '@truepill/tpos-types'
import styled from 'styled-components'
import { subduedColor, primaryColor } from 'styles/styleVariables'
import { getRedirectUrl } from 'utils/urls'

const ExternalRedirectButton = ({
  identifier,
  entity,
  to = RedirectDestination.Tpcs,
  altText = '',
}: {
  entity: RedirectEntity
  identifier: string | number
  to?: RedirectDestination
  altText?: string
}) => {
  return (
    <LinkButton target="_blank" to={{ pathname: getRedirectUrl(identifier, entity, to) ?? '' }} title={`${identifier}`}>
      {altText ?? identifier}
    </LinkButton>
  )
}

const LinkButton = styled(Link)`
  background-color: ${subduedColor};
  border-radius: 0.25rem;
  color: black;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 1rem;
  height: 2.5rem;
  margin-left: 0.625rem;
  padding: 0rem 0.625rem;
  text-overflow: ellipsis;
  text-decoration: none;
  white-space: nowrap;
  :focus {
    outline-color: ${primaryColor};
  }
`

export default ExternalRedirectButton
