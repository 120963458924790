import { useLocation } from '@truepill/tpos-react-router'
import { OrderStatus } from '@truepill/tpos-types'
import LoadingSpinner from 'components/Loading'
import { NoResultsEntry } from 'components/PageStructure'
import Paging from 'components/Paging'
import SpeciesLogo from 'components/SpeciesLogo'
import type { StatusValFilterSetting } from 'components/StatusSearch'
import Lozenge, { getStatusColor } from 'components/Tiles/Lozenge'
import type { GetOrdersParams } from 'hooks/navigation/useOrders'
import useOrders from 'hooks/navigation/useOrders'
import useDebouncedValue from 'hooks/useDebouncedValue'
import moment from 'moment'
import { useTPCacheContext } from 'providers/TPCacheProvider'
import { usePlusClient } from 'providers/VisionRouter'
import { uniq } from 'ramda'
import { goToFulfillmentOrder, goToViewPharmacyOrder } from 'routes'
import { alertRed, primaryBackgroundColor } from 'styles/styleVariables'
import type { Order, OrderSearchVariables } from 'types'
import { snakeCaseToHumanReadable, formatCreatedDate } from 'utils'
import { isHuman } from 'utils/Patient'
import {
  OrderContainer,
  OrdersPagingContainer,
  StyledOrderContainer,
  StyledLink,
  PaddingContainer,
  OrderRow,
  TitleOrderRow,
  OrderToken,
  RefNumber,
  Truncatable,
  CreatedAt,
  Patient,
  Species,
  DOB,
  Shipping,
  ShipDate,
  TrackingNumber,
  RXNumbers,
  Customer,
  Status,
  LoadingSpinnerContainer,
} from './StyledComponents'

type WrappedOrdersTableProps = { className?: string; orderSearchVariables: OrderSearchVariables }

const WrappedOrdersTable = ({ className, orderSearchVariables }: WrappedOrdersTableProps): JSX.Element => {
  const {
    routeToMergedQuery,
    currentLocation,
    QueryToolkit: { StringBoolean },
  } = usePlusClient()

  const {
    drugName,
    ndcs,
    patientName,
    patientDob,
    rxFillCode,
    orderNumber,
    customerRef,
    coreOrderToken,
    coreFillRequestToken,
    transactionId,
    lastFourDigits,
  } = useDebouncedValue(orderSearchVariables)

  const queryMap = currentLocation?.queryMap || {}
  const pageSize = queryMap.pageSize ? parseInt(queryMap.pageSize, 10) : 10
  const sortField = queryMap.sortField
  const sort = queryMap.sort ? (queryMap.sort === 'asc' ? 1 : -1) : undefined

  const orderStages = ((queryMap.statuses as Array<keyof StatusValFilterSetting>) || []).map(
    (statusVal: unknown) => OrderStatus[statusVal as keyof typeof OrderStatus],
  )

  const orderParams: GetOrdersParams = {
    pageSize: pageSize,
    pageNumber: Number(currentLocation.queryMap?.page) || 0,
    includeTriage: true,
    sortField: sortField || 'age',
    ...(sort && { sort }),
    ...(queryMap.inTriage !== undefined && {
      inTriage: StringBoolean(queryMap.inTriage),
    }),
    ...(queryMap.triageReasons && { triageReasons: queryMap.triageReasons }),
    ...(queryMap.customerIds && { customerIds: queryMap.customerIds }),
    ...(queryMap.medications?.length && { medicationNames: queryMap.medications }),
    ...(queryMap.paymentType && { paymentType: queryMap.paymentType }),
    ...(customerRef && { customerRef }),
    ...((drugName?.length ?? 0) > 0 && { drugName }),
    ...(queryMap.locationIds && { selectedLocationIds: queryMap.locationIds }),
    ...(!queryMap.locationIds && queryMap.locationId && { locationId: queryMap.locationId }),
    ...((ndcs?.length ?? 0) > 0 && { ndcs }),
    ...(orderNumber && { orderNumber: parseInt(orderNumber, 10) }),
    ...((patientDob?.length ?? 0) > 0 && { patientDob }),
    ...((patientName?.length ?? 0) > 0 && { patientName }),
    ...((rxFillCode?.length ?? 0) > 0 && { rxFillCode }),
    ...(orderStages.length > 0 && { orderStages }),
    ...(coreOrderToken && { coreOrderToken }),
    ...(coreFillRequestToken && { coreFillRequestToken }),
    ...(queryMap.states?.length && { states: queryMap.states }),
    ...(queryMap.noStates && { states: [] }),
    ...(transactionId && { transactionId }),
    ...(lastFourDigits && { lastFourDigits }),
  }

  const { data, loading, error } = useOrders(orderParams)

  const orders: Order[] = data ? data.getOrders.orders : []
  const totalOrders = data ? data.getOrders.totalRecords : 0
  const currentPage = data ? data.getOrders.currentPage : 0
  const totalPages = Math.ceil(totalOrders / pageSize)

  return (
    <OrdersPagingContainer>
      <OrdersTable orders={orders} className={className} loading={loading} error={error ? error.message : undefined} />
      {totalOrders > pageSize && (
        <Paging
          totalPages={totalPages}
          currentPage={currentPage}
          setPage={pageNumber => {
            routeToMergedQuery({ page: pageNumber })
          }}
          totalRecords={totalOrders}
          pageSize={pageSize}
        />
      )}
    </OrdersPagingContainer>
  )
}

const OrdersTable = (props: {
  orders: Order[]
  loading?: boolean
  error?: string
  className?: string
}): JSX.Element => {
  const { className, loading, error, orders } = props

  if (loading) {
    return (
      <PaddingContainer>
        <StyledOrderContainer className={className}>
          <TitleRow />
          <LoadingSpinnerContainer>
            <LoadingSpinner />
          </LoadingSpinnerContainer>
        </StyledOrderContainer>
      </PaddingContainer>
    )
  }

  if (error) {
    return <p>Failed to load Orders: {error} </p>
  }

  return (
    <PaddingContainer>
      <OrderContainer className={className}>
        <TitleRow />
        {orders.map(order => (
          <OrderEntry key={order._id} order={order} />
        ))}
        {orders.length === 0 && <NoResultsEntry> No results </NoResultsEntry>}
      </OrderContainer>
    </PaddingContainer>
  )
}

const TitleRow = (): JSX.Element => {
  return (
    <TitleOrderRow>
      <OrderToken>
        <Truncatable>Order token</Truncatable>
      </OrderToken>
      <RefNumber>
        <Truncatable>Customer ref</Truncatable>
      </RefNumber>
      <Customer>
        <Truncatable>Customer</Truncatable>
      </Customer>
      <CreatedAt>
        <Truncatable>Created At</Truncatable>
      </CreatedAt>
      <RXNumbers>
        <Truncatable>Rx numbers</Truncatable>
      </RXNumbers>
      <Species>
        <Truncatable></Truncatable>
      </Species>
      <Patient>
        <Truncatable>Patient</Truncatable>
      </Patient>
      <DOB>
        <Truncatable>Date of birth</Truncatable>
      </DOB>
      <Shipping>
        <Truncatable>Shipping</Truncatable>
      </Shipping>
      <ShipDate>
        <Truncatable>Ship date</Truncatable>
      </ShipDate>
      <TrackingNumber>
        <Truncatable>Tracking number</Truncatable>
      </TrackingNumber>
      <Status>Status</Status>
    </TitleOrderRow>
  )
}

type OrderEntryProps = { order: Order }

const OrderEntry = ({ order }: OrderEntryProps): JSX.Element => {
  const {
    tokenContext: { isCustomerSupport },
  } = usePlusClient()
  const { search } = useLocation()
  const { getCustomerNameById } = useTPCacheContext()
  const NoneProvidedIcon = <>&mdash;</>

  // Fulfilment Order page only handles either Orders with RXFillRequests or
  // orders in packing
  const isOTCOnlyOrder = !!order.guestPatient || (!order.rxFillRequests?.length && order.otcProducts?.length)
  const { date: createdDate } = formatCreatedDate(order.createdAt)
  const patient = order.guestPatient ?? order.patient

  return (
    <StyledLink
      to={
        !isCustomerSupport() && !isOTCOnlyOrder
          ? goToFulfillmentOrder({
              orderId: order._id,
              search,
            })
          : goToViewPharmacyOrder({ orderId: order._id, search })
      }
    >
      <OrderRow>
        <OrderToken>{order.coreOrderToken}</OrderToken>
        <RefNumber>{order.customerRef?.match(/^\w{2,12}$/) ? order.customerRef : NoneProvidedIcon}</RefNumber>
        <Customer>{getCustomerNameById(order.customerId)}</Customer>
        <CreatedAt>
          <Truncatable>{createdDate}</Truncatable>
        </CreatedAt>
        <RXNumbers>
          {(order.rxFillRequests?.map(rxFillReq => rxFillReq?.prescription?.rxNumber) ?? []).join(', ') || 'N/A'}
        </RXNumbers>
        <Species>
          <SpeciesLogo isHuman={isHuman(patient)} />{' '}
        </Species>
        <Patient data-private>
          {patient?.firstName} {patient?.lastName}
        </Patient>
        <DOB data-private>
          {order.patient?.dob ? moment(order.patient?.dob).format('MM/DD/YYYY') : NoneProvidedIcon}
        </DOB>
        <Shipping>{order.shippingMethod ? snakeCaseToHumanReadable(order.shippingMethod) : NoneProvidedIcon}</Shipping>
        <ShipDate>{NoneProvidedIcon}</ShipDate>
        <TrackingNumber>
          {uniq([
            ...(order.shipments ?? []).map(({ trackingNumber }) => trackingNumber),
            ...(order.otcProducts ?? []).map(({ trackingNumber }) => trackingNumber),
          ])
            .filter(Boolean)
            .join(', ') || NoneProvidedIcon}
        </TrackingNumber>
        <Status>
          <Lozenge backgroundColor={getStatusColor(order.status)}>{order.status}</Lozenge>
          {order.inTriage && (
            <Lozenge color={primaryBackgroundColor} backgroundColor={alertRed}>
              Triage
            </Lozenge>
          )}
        </Status>
      </OrderRow>
    </StyledLink>
  )
}

export default WrappedOrdersTable
