import { PriorAuthorizationStatus } from '@truepill/tpos-types'
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg'
import ActionButton from 'components/ActionButton'
import IconWrapper from 'components/IconWrapper'
import { bodyPrimaryColor } from 'styles/styleVariables'
import type { PriorAuthorization } from 'types'

type GetPriorAuthorizationInfoButtonProps = {
  priorAuthorization?: PriorAuthorization
  isPriorAuthorization?: boolean
  disabled?: boolean
  clickCallback?: (priorAuthorizationId: string) => void
}

export const GetPriorAuthorizationInfoButton = ({
  priorAuthorization,
  isPriorAuthorization = false,
  disabled = false,
  clickCallback,
}: GetPriorAuthorizationInfoButtonProps): JSX.Element => {
  if (!isPriorAuthorization || !priorAuthorization) {
    return <></>
  }

  const priorAuthStatusPending = priorAuthorization?.status === PriorAuthorizationStatus.Pending
  const priorAuthStatusManualStart = priorAuthorization?.status === PriorAuthorizationStatus.ManualStart
  if (!priorAuthStatusPending && !priorAuthStatusManualStart) {
    return <></>
  }

  const priorAuthInfoButton = priorAuthorization?.actions.find(action => action.buttonLabel === 'Info')
  if (!priorAuthInfoButton) {
    return <></>
  }

  return (
    <ActionButton
      disabled={disabled}
      icon={
        <IconWrapper>
          <InfoIcon fill={bodyPrimaryColor} />
        </IconWrapper>
      }
      label={priorAuthInfoButton?.buttonLabel}
      onClick={() => {
        clickCallback?.(priorAuthorization._id)
        window.open(priorAuthInfoButton?.url, '_blank')
      }}
    />
  )
}
