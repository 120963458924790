import type { Dispatch, SetStateAction } from 'react'
import { useState } from 'react'
import useDebouncedValue from './useDebouncedValue'

/**
 * A hook that wraps `useState` and `useDebouncedValue` for cases where we want
 * to use the debounced value for queries and the like, but the original value
 * for controlled inputs or other elements that need a live update.
 *
 * @default delay 500
 * @returns An array where index 0 is the current value, index 1 is the
 *          debounced value, and index 2 is the setter function.
 */
const useStateWithDebouncedValue = <T>(
  initialValue: T,
  delay?: number,
): readonly [T, T, Dispatch<SetStateAction<T>>] => {
  const [value, setValue] = useState(initialValue)
  const debouncedValue = useDebouncedValue(value, delay)
  return [value, debouncedValue, setValue] as const
}
export default useStateWithDebouncedValue
