import { FillDirectionsMaxLength } from '@truepill/tpos-types'
import type { GetNextOrderFilters } from 'hooks/useGetNextOrder'
import type { Fill, FillDirectionsDetails, Order, RXFillRequest } from 'types'
import { removeNewLineAndTrim } from './textFormatting'

export const getNextRxFillRequest = (
  order: Order,
  currentFill: Fill,
  fillStage?: string,
  filters?: GetNextOrderFilters,
): { nextFill?: RXFillRequest; stopNavigation?: boolean } => {
  const fillRequestFound = order.rxFillRequests.find(rxfr => {
    const inStatus = rxfr.fill._id !== currentFill._id && (!fillStage || rxfr.status === fillStage)
    const isTriage = order.triages && order.triages.some(t => t.rxFillRequestId === rxfr._id)

    if (!filters?.showTriage) return inStatus && !isTriage

    return inStatus
  })

  return {
    nextFill: fillRequestFound,
  }
}

const PackageMeasureDictionary: { [key: string]: string } = {
  GM: 'g',
  ML: 'ml',
}

export const getPackageMeasureDescription = (packageSizeUnitOfMeasure?: string): string => {
  if (packageSizeUnitOfMeasure && PackageMeasureDictionary[packageSizeUnitOfMeasure]) {
    return `(${PackageMeasureDictionary[packageSizeUnitOfMeasure]})`
  }
  return ''
}

export const directionsLengthDetails = (isGeneric: boolean, directions?: string): FillDirectionsDetails => {
  const cleanedText = removeNewLineAndTrim(directions)

  if (isGeneric) {
    return {
      tooLong: cleanedText.length > FillDirectionsMaxLength.GENERIC,
      maxLength: FillDirectionsMaxLength.GENERIC,
      cleanedText,
    }
  }

  return {
    tooLong: cleanedText.length > FillDirectionsMaxLength.BRAND,
    maxLength: FillDirectionsMaxLength.BRAND,
    cleanedText,
  }
}
