import { useRef, useEffect, useState } from 'react'
import { ReactComponent as AttachIcon } from 'assets/icons/attach.svg'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { ReactComponent as ArchiveIcon } from 'assets/icons/inventory.svg'
import ErrorBoundary from 'components/ErrorBoundary'
import type { ToolTipFacing } from 'components/HotKeyToolTip'
import { Pane, PaneContents } from 'components/PageStructure'
import { ToolButton } from 'components/SideToolBar'
import useHotKey, { HotKeyLevel } from 'hooks/useHotKey'
import usePane from 'hooks/usePane'
import styled from 'styled-components'
import { bodyPrimaryColor, contrastBackgroundColor, primaryBackgroundColor, primaryColor } from 'styles/styleVariables'
import type { AttachmentsOptions } from 'types'
import AddAttachment from './AddAttachment'
import AttachmentsList from './AttachmentsList'
import RemoveAttachment from './RemoveAttachment'

const useAttachmentsPane = () => {
  const { paneVisible, firstLoad, hidePane } = usePane('Attachments')
  const hidePaneRef = useRef(hidePane)

  useEffect(() => {
    return hidePaneRef.current
  }, [])

  useHotKey('Escape', HotKeyLevel.normal, hidePane)

  return { paneVisible, firstLoad, hidePane }
}

const AttachmentsPane = ({ orderId, fillId, prescriptionId, patientId }: AttachmentsOptions): JSX.Element => {
  const { paneVisible, firstLoad, hidePane } = useAttachmentsPane()
  const [hideArchivedNotes, setHideArchivedNotes] = useState(true)

  const tooltipPositioning = {
    position: 'top' as ToolTipFacing,
    offsetTop: -2.25,
    offsetLeft: 0.65,
  }

  return (
    <ErrorBoundary>
      <Pane id="AttachmentsPane" firstLoad={firstLoad} visible={paneVisible}>
        <PaneContents>
          <ArchiveButton
            data-testid="show-archived"
            archiveHidden={hideArchivedNotes}
            label={hideArchivedNotes ? 'Display archive' : 'Hide archive'}
            tooltipText={hideArchivedNotes ? 'Display archive' : 'Hide archive'}
            icon={ArchiveIcon}
            tooltipPositioning={tooltipPositioning}
            clickCallback={() => setHideArchivedNotes(!hideArchivedNotes)}
          ></ArchiveButton>
          <CloseButton data-testid="close" label="Close" icon={CloseIcon} clickCallback={hidePane} />
          <AttachmentsTitle>
            <AttachIcon fill={bodyPrimaryColor} />
            Attachments
          </AttachmentsTitle>
          <StyledList>
            <AddAttachment orderId={orderId} fillId={fillId} prescriptionId={prescriptionId} patientId={patientId} />
            <RemoveAttachment prescriptionId={prescriptionId} />
            <AttachmentsList
              orderId={orderId}
              fillId={fillId}
              prescriptionId={prescriptionId}
              patientId={patientId}
              hideArchivedNotes={hideArchivedNotes}
            />
          </StyledList>
        </PaneContents>
      </Pane>
    </ErrorBoundary>
  )
}

const AttachmentsTitle = styled.h3`
  display: flex;
  font-size: 1.125rem;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  > svg {
    margin-right: 0.5rem;
  }
`

const ArchiveButton = styled(ToolButton)<{ archiveHidden: boolean }>`
  background-color: ${({ archiveHidden }) => (archiveHidden ? contrastBackgroundColor : primaryColor)};
  border: none;
  position: absolute;
  right: 3.25rem;
  height: 2rem;
  width: 2rem;
  :hover,
  :focus {
    box-shadow: none !important;
    cursor: pointer;
  }
  svg {
    fill: ${({ archiveHidden }) => !archiveHidden && primaryBackgroundColor};
  }
`

const CloseButton = styled(ToolButton)`
  border: none;
  position: absolute;
  right: 1rem;
  height: 2rem;
  width: 2rem;
  :hover,
  :focus {
    box-shadow: none !important;
    cursor: pointer;
  }
`

const StyledList = styled.ul`
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  flex-grow: 1;
  overflow-y: auto;
  padding: 0.125rem;
`

export default AttachmentsPane
