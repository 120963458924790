import DatePicker from 'components/DatePicker'
import { ListRowLabel, ListRowValue, RightFilledHeader, ListRowEyeButton } from 'components/RXTable'
import moment from 'moment'
import type { EdiReversalOverrides } from 'types'
import { RxEdiListRow, RxEdiRowContainer, RxEdiTitleRow, DateCellPadding, RxTextInput } from '..'
import SuppressFieldButton from '../../SuppressFieldButton'

type TransmissionSegmentEditReversalProps = {
  ediReversalOverrides: EdiReversalOverrides
  updateEdiReversalOverrides: (input: EdiReversalOverrides) => void
}

const TransmissionSegmentEditReversal = ({
  ediReversalOverrides,
  updateEdiReversalOverrides,
}: TransmissionSegmentEditReversalProps): JSX.Element => {
  const { header } = ediReversalOverrides
  const bin = header?.bin
  const processorControlNumber = header?.processorControlNumber
  const serviceProviderId = header?.serviceProviderId
  const dateOfService = header?.dateOfService
  const softwareVendorId = header?.softwareVendorId

  const hideBinField = bin?.send === false
  const hideProcessorControlNumberField = processorControlNumber?.send === false
  const hideServiceProviderIdField = serviceProviderId?.send === false
  const hideDateOfServiceField = dateOfService?.send === false
  const hideSoftwareVendorIdField = softwareVendorId?.send === false

  // These toggle functions handle the state of SupressFieldButton when you click on it
  // and the true/false field we are sending to the backend
  const toggleIsHideBinFieldDisabled = () => {
    updateEdiReversalOverrides({ header: { bin: { value: bin?.value ?? '', send: hideBinField } } })
  }

  const toggleIsHideProcessorControlNumberFieldDisabled = () => {
    updateEdiReversalOverrides({
      header: {
        processorControlNumber: { value: processorControlNumber?.value ?? '', send: hideProcessorControlNumberField },
      },
    })
  }
  const toggleIsHideServiceProviderIdFieldDisabled = () => {
    updateEdiReversalOverrides({
      header: {
        serviceProviderId: { value: serviceProviderId?.value ?? '', send: hideServiceProviderIdField },
      },
    })
  }
  const toggleIsDateOfServiceFieldDisabled = () => {
    updateEdiReversalOverrides({
      header: {
        dateOfService: { value: dateOfService?.value ?? '', send: hideDateOfServiceField },
      },
    })
  }

  return (
    <>
      <TopRow />
      <RxEdiListRow>
        <RxEdiRowContainer>
          <ListRowLabel>BIN Number (A1):</ListRowLabel>
          <ListRowValue>
            <RxTextInput
              value={bin?.value ?? ''}
              onChange={({ target: { value: bin } }) => {
                updateEdiReversalOverrides({ header: { bin: { value: bin, send: true } } })
              }}
              placeholder="BIN number..."
              disabled={hideBinField}
            />
          </ListRowValue>
          <ListRowEyeButton>
            <SuppressFieldButton onClick={toggleIsHideBinFieldDisabled} hideField={hideBinField} />
          </ListRowEyeButton>
        </RxEdiRowContainer>
      </RxEdiListRow>
      <RxEdiListRow>
        <RxEdiRowContainer>
          <ListRowLabel>Processor Control Number (A4):</ListRowLabel>
          <ListRowValue>
            <RxTextInput
              value={processorControlNumber?.value ?? ''}
              onChange={({ target: { value: processorControlNumber } }) =>
                updateEdiReversalOverrides({
                  header: {
                    processorControlNumber: { value: processorControlNumber, send: true },
                  },
                })
              }
              placeholder="Processor control number..."
              disabled={hideProcessorControlNumberField}
            />
          </ListRowValue>
          <ListRowEyeButton>
            <SuppressFieldButton
              onClick={toggleIsHideProcessorControlNumberFieldDisabled}
              hideField={hideProcessorControlNumberField}
            />
          </ListRowEyeButton>
        </RxEdiRowContainer>
      </RxEdiListRow>
      <RxEdiListRow>
        <RxEdiRowContainer>
          <ListRowLabel>Service Provider ID (B1):</ListRowLabel>
          <ListRowValue>
            <RxTextInput
              value={serviceProviderId?.value ?? ''}
              onChange={({ target: { value: serviceProviderId } }) =>
                updateEdiReversalOverrides({
                  header: { serviceProviderId: { value: serviceProviderId, send: true } },
                })
              }
              placeholder="Service provider ID..."
              disabled={hideServiceProviderIdField}
            />
          </ListRowValue>
          <ListRowEyeButton>
            <SuppressFieldButton
              onClick={toggleIsHideServiceProviderIdFieldDisabled}
              hideField={hideServiceProviderIdField}
            />
          </ListRowEyeButton>
        </RxEdiRowContainer>
      </RxEdiListRow>
      <RxEdiListRow>
        <RxEdiRowContainer>
          <ListRowLabel>Date of Service (D1)</ListRowLabel>
          <ListRowValue>
            <DateCellPadding>
              <DatePicker
                showPlaceholder={dateOfService?.value ? false : true}
                date={dateOfService?.value ? moment(dateOfService.value).toDate() : undefined}
                onChange={date => {
                  updateEdiReversalOverrides({
                    header: {
                      dateOfService: { value: moment(date).format('YYYY/MM/DD'), send: true },
                    },
                  })
                }}
                disabled={hideDateOfServiceField}
              />
            </DateCellPadding>
          </ListRowValue>
          <ListRowEyeButton>
            <SuppressFieldButton onClick={toggleIsDateOfServiceFieldDisabled} hideField={hideDateOfServiceField} />
          </ListRowEyeButton>
        </RxEdiRowContainer>
      </RxEdiListRow>
      <RxEdiListRow>
        <RxEdiRowContainer>
          <ListRowLabel>Software Vendor ID (AK):</ListRowLabel>
          <ListRowValue>
            <RxTextInput
              value={softwareVendorId?.value ?? ''}
              onChange={({ target: { value: softwareVendorId } }) =>
                updateEdiReversalOverrides({
                  header: { softwareVendorId: { value: softwareVendorId, send: true } },
                })
              }
              placeholder="Software vendor ID..."
              disabled={hideSoftwareVendorIdField}
            />
          </ListRowValue>
        </RxEdiRowContainer>
      </RxEdiListRow>
    </>
  )
}

const TopRow = () => {
  return (
    <RxEdiTitleRow>
      <RightFilledHeader>Transmission</RightFilledHeader>
    </RxEdiTitleRow>
  )
}

export default TransmissionSegmentEditReversal
