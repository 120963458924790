import { useHistory, useLocation } from '@truepill/tpos-react-router'
import { CopayStatus, RxFillRequestStatus } from '@truepill/tpos-types'
import TriageOrResolveTriageButton from 'components/TriageOrResolveTriageButton'
import useFulfillmentQueue, { FulfillmentQueueName } from 'hooks/useFulfillmentQueue'
import useGetNextAdjudication from 'hooks/useGetNextAdjudication'
import useGetNextTriage from 'hooks/useGetNextTriage'
import { usePlusClient } from 'providers/VisionRouter'
import type { Fill, QueueItem } from 'types'
import { continueOrderWorkflow, getItemFill, isOrder } from 'utils'

type TriageOrResolveTriageActionButtonProps = {
  item: QueueItem
  fill: Fill
  isCopayInTriage?: boolean
  isPriorAuthorization?: boolean
  isRejectedOrder?: boolean
}

export const TriageOrResolveTriageActionButton = ({
  item,
  fill,
  isCopayInTriage = false,
  isPriorAuthorization = false,
  isRejectedOrder = false,
}: TriageOrResolveTriageActionButtonProps): JSX.Element => {
  const history = useHistory()
  const { search } = useLocation()
  const {
    currentLocation: { queryMap },
  } = usePlusClient()
  const { getNextOrder: getNextAdjudication } = useGetNextAdjudication()
  const { getNextOrder: getNextTriage } = useGetNextTriage()
  const fulfillmentQueue = useFulfillmentQueue()

  const isComplete = isOrder(item)
    ? getItemFill(item, fill._id)?.status === RxFillRequestStatus.Complete
    : getItemFill(item, fill._id)?.status === CopayStatus.Complete
  if (isComplete || isCopayInTriage || isRejectedOrder || isPriorAuthorization) {
    return <></>
  }

  const fulfillmentQueueName = fulfillmentQueue?.name
  let getNextOrder = fulfillmentQueueName === FulfillmentQueueName.PriorAuthorization ? undefined : getNextAdjudication
  if (isOrder(item) && item.inTriage && fulfillmentQueueName === FulfillmentQueueName.Triage) {
    getNextOrder = getNextTriage
  }

  return (
    <TriageOrResolveTriageButton
      item={item}
      fillId={fill._id}
      forceShowTriage
      onClick={async () => {
        if (isOrder(item)) {
          await continueOrderWorkflow(
            search,
            queryMap,
            history,
            () => getNextOrder?.(item, fill),
            fulfillmentQueueName,
            item.rxFillRequests.length > 1,
          )
        }
      }}
    />
  )
}
