import { Link } from '@truepill/tpos-react-router'
import {
  goToFulfillmentCopayRequestFill,
  goToFulfillmentFill,
  goToViewPharmacyCopay,
  goToViewPharmacyOrder,
  goToViewPharmacyPrescription,
} from 'routes'
import styled from 'styled-components'
import { primaryColor } from 'styles/styleVariables'

const InternalLink = ({ identifier, to, altText = '' }: { identifier: string; to: string; altText?: string }) => {
  return (
    <StyledLink target="_blank" to={{ pathname: to }} title={altText ?? identifier}>
      {altText ?? identifier}
    </StyledLink>
  )
}

const StyledLink = styled(Link)`
  font-size: unset;
  :hover {
    color: ${primaryColor};
  }
`

export const LinkToPrescription = ({
  prescriptionId,
  label,
}: {
  prescriptionId: string
  label: string
}): JSX.Element => {
  return (
    <InternalLink
      identifier={prescriptionId}
      to={goToViewPharmacyPrescription({
        prescriptionId,
      })}
      altText={label}
    />
  )
}

export const LinkToCopay = ({ copayId, label }: { copayId: string; label: string }): JSX.Element => {
  return <InternalLink identifier={copayId} to={goToViewPharmacyCopay({ copayRequestId: copayId })} altText={label} />
}

export const LinkToOrder = ({ orderId, label }: { orderId: string; label: string }): JSX.Element => {
  return <InternalLink identifier={orderId} to={goToViewPharmacyOrder({ orderId })} altText={label} />
}

export const LinkToCopayFill = ({
  copayId,
  fillId,
  label,
}: {
  copayId: string
  fillId: string
  label: string
}): JSX.Element => {
  return (
    <InternalLink
      identifier={label}
      to={goToFulfillmentCopayRequestFill({ copayRequestId: copayId, fillId })}
      altText={label}
    />
  )
}

export const LinkToOrderFill = ({
  orderId,
  fillId,
  label,
}: {
  orderId: string
  fillId: string
  label: string
}): JSX.Element => {
  return <InternalLink identifier={label} to={goToFulfillmentFill({ orderId, fillId })} altText={label} />
}

export default InternalLink
