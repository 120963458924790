import type { Ref } from 'react'
import { useMemo, useState, useEffect } from 'react'
import { useMutation, useQuery } from '@truepill/tpos-react-router'
import { OrderStatus } from '@truepill/tpos-types'
import { ReactComponent as DangerIcon } from 'assets/icons/danger.svg'
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg'
import ActionButton, { SaveButton } from 'components/ActionButton'
import { RemoveButton } from 'components/CloseButton'
import {
  WideTextInput,
  FormItem,
  FormItems,
  ShippingFormLine,
  AddressFormLine,
  PatientTitle,
} from 'components/ColumnForm'
import IconWrapper from 'components/IconWrapper'
import { getReturnsByItems } from 'components/ItemReturnList'
import { ModalWrapper, ModalHeader, InputContainer, ButtonsContainer } from 'components/Modal'
import { NumericInput } from 'components/NumericInput'
import { FilledHeadingStyle, CancelButton } from 'components/PageStructure'
import Select from 'components/Select'
import TopBanner from 'components/TopBanner'
import { CREATE_SNAP_RETURN, GET_RETURN_ITEMS, CREATE_SNAP_REPLACE } from 'gql'
import { Box, Grid } from 'grommet'
import useAutoFocus from 'hooks/useAutoFocus'
import useAutoUpdatingRef from 'hooks/useAutoUpdatingRef'
import useOrderReturnVariables from 'hooks/useOrderReturnVariables'
import type { OrderReturnVariables } from 'hooks/useOrderReturnVariables'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import ReturnDialogProvider, { useReturnDialogContext } from 'providers/ReturnDialogProvider'
import type { Items, AbstractOTCProduct, SelectedItem, ShippingForm } from 'providers/ReturnDialogProvider'
import { isNil } from 'ramda'
import styled from 'styled-components'
import EllipsisTruncate from 'styles/EllipsisTruncate'
import { bodyPrimaryColor, primaryBackgroundColor, contrastBackgroundColor } from 'styles/styleVariables'
import { OTCOrderIssueReason, OTCOrderItemIssueReason, OTCOrderIssueAction, OTCShippingCharge } from 'types'
import type { Order, OTCProduct, CustomerReturn, Full } from 'types'
import { states as USStates, usdFormatter, camelCaseToHumanReadable, formatZip } from 'utils'

interface OrderReturnModalProps {
  completionCallback: () => void
  orderId: Order['_id']
}

type Option = { value: string; label: string }

type ReplacementType = {
  shippingData: ShippingForm
  orderId: string
  otcProducts: {
    otcId: string
    quantity: number
  }[]
  action: string
  reason: string
  shippingPayment: string
  shippingCostRefunded: number
  isABundle: boolean
  isEntireOrder: boolean
  name: string
}

const buildOptions = (obj: { [key: string]: string }): Option[] => {
  return Object.keys(obj).map(key => {
    const sentence = camelCaseToHumanReadable(key)
    sentence.toLowerCase()
    return { label: sentence, value: key }
  })
}

const issueActions: Option[] = buildOptions({ replace: OTCOrderIssueAction.replace })
const issueReasons: Option[] = buildOptions(OTCOrderIssueReason)
const itemIssueReasons = buildOptions(OTCOrderItemIssueReason)

// These are temporarily set. We won't need them once replace and return comes
// online, at which point just pass OTCOrderIssueAction
const trimmedIssueShipingSpeeds = {
  UspsPriority: 'usps_priority',
  UspsFirst: 'usps_first',
  FedexPriorityExpress: 'fedex_priority_express',
  Fedex2Day: 'fedex_2_day',
}

const issueShipping: Option[] = buildOptions(trimmedIssueShipingSpeeds)
const issueShippingCharge: Option[] = buildOptions(OTCShippingCharge)

/**
 * Extracts the quantity per Otc Product Id from the array of selected items.
 * @param otcProductsSelectedForRefund
 * @returns Object example = { 60f554afbbd968001b156c89: { qty:2 } }
 */
const getQuantityByOtcIdFromReturnVariables = (otcProductsSelectedForRefund: OrderReturnVariables[]) => {
  const otcProductsQtySelected: { [k: string]: any } = {}

  otcProductsSelectedForRefund.forEach(otcSelected => {
    otcSelected.otcProducts.forEach(otcProduct => {
      if (otcProductsQtySelected[otcProduct.otcId]) {
        otcProductsQtySelected[otcProduct.otcId].qty += otcProduct.quantity
      } else {
        otcProductsQtySelected[otcProduct.otcId] = { qty: otcProduct.quantity }
      }
    })
  })

  return otcProductsQtySelected
}

/**
 * Extracts the quantity per Otc Product Id from the array of products previously returned or refunded.
 * @param otcProducsPreviouslyReturned
 * @returns Object example = { 60f554afbbd968001b156c89: { qty:2 } }
 */
const getQuantityByOtcIdFromCustomerReturns = (otcProducsPreviouslyReturned: CustomerReturn[]) => {
  const otcProductsQty: { [k: string]: any } = {}

  otcProducsPreviouslyReturned.forEach(otcCustomerReturn => {
    otcCustomerReturn.otcProducts.forEach(otcProduct => {
      if (otcProductsQty[otcProduct.otcProductId]) {
        otcProductsQty[otcProduct.otcProductId].qty += otcProduct.quantity
      } else {
        otcProductsQty[otcProduct.otcProductId] = { qty: otcProduct.quantity }
      }
    })
  })

  return otcProductsQty
}

const getErrorsForBundleProducts = (
  bundleSelected: OrderReturnVariables,
  otcPreviouslyReturned: CustomerReturn[],
): string | null => {
  if (otcPreviouslyReturned.length > 0) {
    const returnedQtyByOtcId = getQuantityByOtcIdFromCustomerReturns(otcPreviouslyReturned)
    bundleSelected.otcProducts.forEach(otcProduct => {
      if (otcProduct.quantity > returnedQtyByOtcId[otcProduct.otcId].qty) {
        return null
      }
    })

    return `${bundleSelected.name} has already been returned or refunded`
  } else {
    return null
  }
}

const mergeShippingData = (
  shippingAddress: any,
  firstNamePatient: string,
  lastNamePatient: string,
  shippingSpeed: any,
  email: string,
  phone: string,
  shippingForm: ShippingForm,
): ShippingForm => {
  const { city, firstName, lastName, state, street1, street2, zip } = shippingAddress
  return {
    ...{
      city,
      firstName: firstName || firstNamePatient,
      lastName: lastName || lastNamePatient,
      state,
      street1,
      street2,
      zip,
      shippingSpeed,
      email,
      phone,
    },
    ...shippingForm,
  }
}

const getOptionsReason = (type: string, action: keyof typeof OTCOrderIssueAction) => {
  if (OTCOrderIssueAction[action] !== OTCOrderIssueAction.cancel) {
    if (type === 'order') {
      return issueReasons
    }
    if (['bundle', 'item'].includes(type)) {
      return [...itemIssueReasons, ...issueReasons]
    }
  }
  return []
}

const getErrorsForEntireOrder = (
  otcPreviouslyReturned: CustomerReturn[],
  otcProducts: OTCProduct[],
): string[] | null => {
  if (otcPreviouslyReturned.length > 0) {
    const errors: string[] = ['You cannot return or refund the full order because:']
    const products = otcProducts.reduce((otcNamesById: any, otcProduct: OTCProduct) => {
      return { ...otcNamesById, [otcProduct._id]: otcProduct.name || '' }
    }, {})

    const returnedQtyByOtcId = getQuantityByOtcIdFromCustomerReturns(otcPreviouslyReturned)
    errors.push(
      ...Object.keys(returnedQtyByOtcId).map(otcId => {
        return `- ${returnedQtyByOtcId[otcId].qty} ${products[otcId]}`
      }),
    )

    errors.push('has already been returned or refunded')
    return errors
  } else {
    return null
  }
}

function getAlreadyRefundedError(
  orderItems: Items,
  orderReturnVariables: OrderReturnVariables[],
  customerReturns: CustomerReturn[],
  otcProducts: OTCProduct[],
): string | undefined {
  const returnsByItems = getReturnsByItems(customerReturns)
  const quantityByOtcProduct = getQuantityByOtcIdFromReturnVariables(orderReturnVariables)

  const errors: string[] = []
  for (const orderReturn of orderReturnVariables) {
    //Validation for Entire Order refund
    if (orderReturn.isEntireOrder) {
      const entireOrderErrorMsgs = getErrorsForEntireOrder(customerReturns, otcProducts)
      if (entireOrderErrorMsgs) errors.push(...entireOrderErrorMsgs)
      continue
    }

    //Validation for bundles
    if (orderReturn.isABundle) {
      const errorMsg = getErrorsForBundleProducts(orderReturn, customerReturns)
      if (errorMsg) errors.push(errorMsg)
      continue
    }

    //Validation for loose items
    for (const otcProduct of orderReturn.otcProducts) {
      const { otcId } = otcProduct
      const itemReturns = returnsByItems[otcId]
      const item = orderItems[otcId]
      if (itemReturns) {
        const numberOfItemReturnedOrRefunded: number = itemReturns.reduce((acc: number, customerReturn) => {
          for (const otcProduct of customerReturn.otcProducts) {
            if (otcProduct.otcProductId === otcId) {
              acc += otcProduct.quantity
            }
          }
          return acc
        }, 0)

        if (numberOfItemReturnedOrRefunded >= item.quantity) {
          const haveHas = (item.quantity > 1 ? 'have' : 'has') + 'already been returned or refunded.'
          errors.push(`${numberOfItemReturnedOrRefunded}/${item.quantity} of ${item.name} ${haveHas}`)
        } else if (numberOfItemReturnedOrRefunded + quantityByOtcProduct[otcId].qty > item.quantity) {
          const maxRefundableProducts = item.quantity - numberOfItemReturnedOrRefunded
          const returned = 'already been returned or refunded. You can only refund up to'
          errors.push(
            `${numberOfItemReturnedOrRefunded} of ${item.quantity} ${item.name} ${returned} ${maxRefundableProducts}.`,
          )
        }
      } else {
        if (otcProduct.quantity > item.quantity) {
          errors.push(`For ${item.name} you can only refund up to ${item.quantity}.`)
        }
      }
      // if the OTC Product has a return already set for it
    }
  }
  if (errors.length) {
    return errors.join('\n')
  }
}

const OrderReturnModal = (props: OrderReturnModalProps) => {
  const [submitErrorMessage, setSubmitErrorMessage] = useState<string | undefined>()
  const { orderId, completionCallback } = props

  const { dismissModal } = useModalContext()

  const { data, error } = useQuery(GET_RETURN_ITEMS, {
    variables: { orderId },
  })

  const order = data && data.getOrder

  // If order status is shipped, remove cancel action option
  if (order?.status === OrderStatus['Shipped']) {
    const index = issueActions.findIndex(
      action => OTCOrderIssueAction[action.value as keyof typeof OTCOrderIssueAction] === OTCOrderIssueAction.cancel,
    )
    if (index >= 0) {
      issueActions.splice(index, 1)
    }
  }

  const { changeItems, changeQuantity, items, selectedItems, shippingForm, changeShippingForm } =
    useReturnDialogContext()
  const changeItemsRef = useAutoUpdatingRef(changeItems)
  const changeQuantityRef = useAutoUpdatingRef(changeQuantity)

  const [createReturn] = useMutation(CREATE_SNAP_RETURN)
  const [createReplace] = useMutation(CREATE_SNAP_REPLACE)

  // We need to init the context reducer as well as add a blank row
  // to get us started
  useEffect(() => {
    if (order) {
      const abstractItems = order.otcProducts.map((otcProduct: OTCProduct) => {
        return {
          _id: otcProduct._id,
          quantity: otcProduct.quantity,
          name: otcProduct.name || otcProduct.sku,
          bundleId: otcProduct.bundleId,
          bundleItemPartialReturnAllowed: otcProduct.bundleItemPartialReturnAllowed,
          unitCost: otcProduct?.pricing?.cost || 0,
          type: 'item',
          discount: otcProduct?.pricing?.discount || 0,
          tax: otcProduct?.pricing?.tax || 0,
          total: otcProduct?.pricing?.total || 0,
        }
      })

      // sort by bundle id
      abstractItems.sort((a: AbstractOTCProduct, b: AbstractOTCProduct) => {
        const aBundleId = a.bundleId || ''
        const bBundleId = b.bundleId || ''
        if (aBundleId > bBundleId) {
          return 1
        } else if (aBundleId < bBundleId) {
          return -1
        } else {
          return 0
        }
      })

      // Entire Order is added as a means for CS agents to return the whole order
      // instead of an exhaustive list of items
      const entireOrder: AbstractOTCProduct = {
        _id: 'entireOrder',
        type: 'order',
        quantity: 1,
        name: 'Entire Order',
        unitCost: order.pricing?.total || 0,
        discount: 0,
        action: '',
        tax: order.pricing?.tax || 0,
        total: order.pricing?.total || 0,
        shippingCost: 0,
      }
      const newItems: AbstractOTCProduct[] = [entireOrder]
      let currentBundleId: string | null = null
      // We also allow (and sometimes mandate), the returning of an entire
      // bundle instead of individual items
      let currentBundle: AbstractOTCProduct | undefined
      let accumulatedPrice = 0
      for (let i = 0; i < abstractItems.length; i++) {
        const item = abstractItems[i]
        if (currentBundleId !== item.bundleId) {
          if (currentBundle) {
            currentBundle.unitCost = accumulatedPrice
            accumulatedPrice = 0
          }
          currentBundleId = item.bundleId
          currentBundle = {
            _id: item.bundleId,
            type: 'bundle',
            discount: 0,
            action: '',
            tax: 0,
            quantity: 1,
            name: item.bundleId + ' (Bundle)',
            unitCost: 0,
            total: 0,
            shippingCost: 0,
          }
          newItems.push(currentBundle)
        }
        if (currentBundle) {
          currentBundle.unitCost += item.total // item.unitCost * item.quantity
          currentBundle.total += item.total
          currentBundle.tax += item.tax
        }

        entireOrder.unitCost += item.total // item.unitCost * item.quantity
        entireOrder.total += item.total
        if (!item.bundleId) {
          newItems.push(item)
        } else if (item.bundleItemPartialReturnAllowed) {
          item.name = '-- ' + item.name
          newItems.push(item)
        }
      }

      changeItemsRef.current(newItems)
      changeQuantityRef.current('placeholder', 0, 'Starter')
    }
  }, [order, changeItemsRef, changeQuantityRef])

  const errorMessage = error ? error.message : submitErrorMessage

  const totalRefunded = useMemo(() => {
    return selectedItems.reduce((acc: number, selection) => {
      const { _id, quantity } = selection
      const item = items[_id]
      if (item) {
        // const { unitCost } = items[_id]
        const unitCost = items[_id].total / items[_id].quantity
        acc += unitCost * quantity
      }
      return acc
    }, 0)
  }, [selectedItems, items])

  const orderReturnVariables = useOrderReturnVariables(selectedItems as Full<SelectedItem>[], items, order)

  const alreadyRefundedError =
    order && getAlreadyRefundedError(items, orderReturnVariables, order.customerReturns || [], order.otcProducts)

  const shippingSpeed = shippingForm?.shippingSpeed
    ? issueShipping.find(({ value }) => value === shippingForm?.shippingSpeed)
    : undefined

  const shippingCharge = shippingForm?.shippingCharge
    ? issueShippingCharge.find(({ value }) => value === shippingForm?.shippingCharge)
    : undefined

  const shippingSpeedKey = order?.shippingMethod
    ? Object.keys(trimmedIssueShipingSpeeds).find(
        key => trimmedIssueShipingSpeeds[key as keyof typeof trimmedIssueShipingSpeeds] === order?.shippingMethod,
      )
    : undefined
  const shippingSpeedDefault = shippingSpeedKey
    ? issueShipping.find(({ value }) => value === shippingSpeedKey)
    : undefined

  const shippingAddress = order?.shippingAddress || {}
  const firstNamePatient = order?.patient?.firstName || order?.guestPatient?.firstName
  const lastNamePatient = order?.patient?.lastName || order?.guestPatient?.lastName
  const emailPatient = order?.patient?.contacts?.email || order?.guestPatient?.contacts?.email
  const phonePatient = order?.patient?.contacts?.phone || order?.guestPatient?.contacts?.phone
  const shippingData = mergeShippingData(
    shippingAddress,
    firstNamePatient,
    lastNamePatient,
    order?.shippingMethod,
    emailPatient,
    phonePatient,
    shippingForm,
  )
  const isRelaceAction = selectedItems.find(item => item?.action === 'replace')
  return (
    <ModalWrapper id="OrderReturnModal">
      <StyledModalHeader>
        <IconWrapper>
          <DangerIcon fill={bodyPrimaryColor} />
        </IconWrapper>
        <h2>Replacement</h2>
      </StyledModalHeader>
      <InputContainer>
        <TopBanner message={alreadyRefundedError || errorMessage} />
        <HeaderRow>
          <Item>
            <Truncatable>Item</Truncatable>
          </Item>
          <Quantity>
            <Truncatable>Quantity</Truncatable>
          </Quantity>
          <UnitCost>
            <Truncatable>Unit Cost</Truncatable>
          </UnitCost>
          <Discount>
            <Truncatable>Discount</Truncatable>
          </Discount>
          <Tax>
            <Truncatable>Tax</Truncatable>
          </Tax>
          <Subtotal>
            <Truncatable>Subtotal</Truncatable>
          </Subtotal>
          <Actions>
            <Truncatable>Actions</Truncatable>
          </Actions>
          <Reason>
            <Truncatable>Reason</Truncatable>
          </Reason>
        </HeaderRow>
        {selectedItems.map((selection, i) => (
          <SelectItemRow key={selection.selectionId} index={i} selection={selection} />
        ))}
        <BottomRow>
          <AddEntryButton />
          <RefundTotal>
            <p>Total</p>
            <p>{usdFormatter.format(totalRefunded)}</p>
          </RefundTotal>
        </BottomRow>
      </InputContainer>

      {isRelaceAction && (
        <Grid data-testid="order" columns={['flex', 'flex']} gap="0.625rem">
          <Box data-test-column="patient">
            <h2>Replacement</h2>
            <PatientTitle>Shipping</PatientTitle>
            <FormItems>
              <ShippingFormLine data-test-row="name-address">
                <FirstFormItem data-test-row="name">
                  <p>First Name</p>
                  <WideTextInput
                    data-testid="name"
                    value={shippingData.firstName}
                    onChange={e =>
                      changeShippingForm({
                        firstName: e.target.value,
                      })
                    }
                  />
                </FirstFormItem>
                <FormItem data-test-row="LastName">
                  <p>Last Name</p>
                  <WideTextInput
                    data-testid="LastName"
                    value={shippingData.lastName || order?.patient?.lastName || order?.guestPatient?.lastName}
                    onChange={e => {
                      changeShippingForm({
                        lastName: e.target.value,
                      })
                    }}
                  />
                </FormItem>
              </ShippingFormLine>
              <FormItem data-test-row="street-address">
                <p>Address</p>
                <WideTextInput
                  data-testid="street1"
                  value={shippingData.street1}
                  onChange={e =>
                    changeShippingForm({
                      street1: e.target.value,
                    })
                  }
                  placeholder={'Address'}
                />
                <WideTextInput
                  data-testid="street2"
                  value={shippingData.street2}
                  onChange={e =>
                    changeShippingForm({
                      street2: e.target.value,
                    })
                  }
                  placeholder={'Suite, building, etc...'}
                />
                <AddressFormLine data-test-row="city-address">
                  <FirstFormItem data-test-row="city">
                    <WideTextInput
                      data-testid="city"
                      value={shippingData.city}
                      onChange={e =>
                        changeShippingForm({
                          city: e.target.value,
                        })
                      }
                      placeholder={'City'}
                    />
                  </FirstFormItem>
                  <FormItem data-test-row="state">
                    <StateSelect
                      data-testid="state"
                      placeholder={'State'}
                      multiple={false}
                      value={shippingData.state}
                      options={USStates}
                      onChange={([option]) => {
                        const value = option ? option.value : ''
                        changeShippingForm({
                          state: value,
                        })
                      }}
                    />
                  </FormItem>
                  <FormItem data-test-row="zip">
                    <WideTextInput
                      value={shippingData.zip}
                      onChange={e => {
                        const newZip = formatZip(e.target.value)
                        changeShippingForm({
                          zip: newZip,
                        })
                      }}
                      placeholder={'Zip'}
                    />
                  </FormItem>
                </AddressFormLine>
              </FormItem>
              <ShippingFormLine data-test-row="city-address">
                <FirstFormItem data-test-row="city">
                  <p>Speed</p>
                  <Shipping>
                    <ItemRowSelect
                      disableClear
                      placeholder={'Select shipping speed…'}
                      modal
                      options={issueShipping}
                      value={shippingSpeed || shippingSpeedDefault}
                      onChange={([newShippingSpeed]) => {
                        const { value } = newShippingSpeed
                        changeShippingForm({
                          shippingSpeed: value,
                        })
                      }}
                    />
                  </Shipping>
                </FirstFormItem>
                <FormItem data-test-row="zip">
                  <p>Charge</p>
                  <ShippingCharge>
                    <ItemRowSelect
                      disableClear
                      placeholder={'Select shipping charge…'}
                      modal
                      options={issueShippingCharge}
                      value={shippingCharge}
                      onChange={([newShippingCharge]) => {
                        const { value } = newShippingCharge
                        changeShippingForm({
                          shippingCharge: value,
                        })
                      }}
                    />
                  </ShippingCharge>
                </FormItem>
              </ShippingFormLine>
            </FormItems>
          </Box>
        </Grid>
      )}

      <StyledButtonsContainer>
        <CancelButton label={'Cancel'} onClick={dismissModal} />
        <SaveButton
          isModal
          label={'Process replacement'}
          // disabled={!canSubmit}
          onClick={async () => {
            const replacementGroup = {} as Record<string, ReplacementType>
            for (const variables of orderReturnVariables) {
              if (variables.action === 'replace') {
                const replacement: ReplacementType = { ...variables, ...{ shippingData } }

                if (!replacementGroup[variables.reason]) {
                  replacementGroup[variables.reason] = replacement
                } else {
                  replacementGroup[variables.reason].otcProducts.push(replacement.otcProducts[0])
                }
              } else {
                await createReturn({
                  variables,
                  refetchQueries: ['getItemReturnListQuery', 'orderReturnModalQuery'],
                })
              }
            }
            try {
              Object.keys(replacementGroup).forEach(async key => {
                await createReplace({
                  variables: replacementGroup[key],
                  refetchQueries: ['getItemReturnListQuery', 'orderReturnModalQuery'],
                })
              })
              completionCallback()
              dismissModal()
            } catch (e) {
              setSubmitErrorMessage(e.message)
              console.error(e)
            }
          }}
        />
      </StyledButtonsContainer>
    </ModalWrapper>
  )
}

const StateSelect = styled(Select)`
  width: 6rem;
`

const FirstFormItem = styled(FormItem)`
  width: 50%;
`

const BottomRow = styled.div`
  margin-top: 0.625rem;
  display: flex;
  width: 50%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.25rem solid rgb(33, 45, 65);
  border-top: 0.15rem solid #cccccc;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 15px;
  width: auto;
`

const RefundTotal = styled.span`
  font-weight: 500;
  display: flex;
  > :first-child {
    margin-right: 2rem;
  }
`

const AddEntryButton = () => {
  const { changeQuantity, items } = useReturnDialogContext()
  if (Object.keys(items).length <= 1) return null

  return (
    <StyledActionButton
      onClick={() => {
        changeQuantity('placeholder', 1, `${new Date().getTime()}`)
      }}
      icon={
        <IconWrapper>
          <PlusIcon fill={'black'} />
        </IconWrapper>
      }
      label={'Add a row'}
    />
  )
}

const SelectItemRow = (props: { selection?: SelectedItem; index?: number }) => {
  const { selection, index = 0 } = props

  const { items, availableItems, changeQuantity, changeAction, changeReason, changeOtherReason, removeSelection } =
    useReturnDialogContext()

  const itemOptions = availableItems.map(({ _id, name, left, type }) => {
    if (type === 'item' || type === 'bundle') {
      return { label: `${name} (${left})`, value: _id }
    }
    return { label: name, value: _id }
  })

  const autoFocusRef = useAutoFocus()

  const selectedItem = useMemo(() => {
    if (selection) {
      const item = items[selection._id]
      if (!item) {
        return
      }
      return { label: item.name, value: item._id }
    }
  }, [selection, items])

  // const unitCost = (selection && items[selection._id]?.unitCost) || 0
  let disableQuantity = false

  if (selection && (items[selection._id]?.type === 'order' || items[selection._id]?.type === 'bundle')) {
    if (selection?.quantity === 1) disableQuantity = true
  } else if (!selection) {
    disableQuantity = true
  }

  let discount = (selection && items[selection._id]?.discount) || 0
  if (discount > 0 && selection) discount = (discount / items[selection._id]?.quantity) * selection?.quantity || 0

  let tax = (selection && items[selection._id]?.tax) || 0
  if (tax > 0 && selection) tax = (tax / items[selection._id]?.quantity) * selection?.quantity || 0

  const unitCost = (selection && items[selection._id]?.total / items[selection._id]?.quantity) || 0
  const quantity = selection?.quantity || 0

  const itemReason = selection?.reason
    ? [...itemIssueReasons, ...issueReasons].find(({ value }) => value === selection?.reason)
    : undefined
  const action = selection?.action ? issueActions.find(({ value }) => value === selection?.action) : undefined

  const optionRemoveAvailable = Object.keys(items).length > 1

  const itemType = selection ? items[selection._id]?.type : ''
  const optionsIssueReason =
    itemType && action ? getOptionsReason(itemType, action.value as keyof typeof OTCOrderIssueAction) : []

  return (
    <ItemRow>
      {selection && (
        <StyledRemoveButton
          contrast={index % 2 === 0}
          clickCallback={() => optionRemoveAvailable && removeSelection(selection?.selectionId)}
        />
      )}
      <Item>
        <ItemRowSelect
          ref={autoFocusRef as Ref<HTMLButtonElement>}
          disableClear
          placeholder={'Select an item…'}
          modal
          options={itemOptions}
          value={selectedItem}
          onChange={sel => {
            if (sel?.length > 0) {
              const [{ value }] = sel
              changeQuantity(value as string, 1, selection?.selectionId || `${new Date().getTime()}`)
            }
          }}
        />
      </Item>
      <Quantity>
        <QuantityInput
          fadeOnZeroValue={!selection}
          value={selection?.quantity}
          disabled={disableQuantity}
          onChange={val => {
            if (!selection) {
              throw new Error('Attempted to change quantity without a product selected')
            }
            if (!isNil(val)) changeQuantity(selection._id, val, selection.selectionId)
          }}
        />
      </Quantity>
      <UnitCost>{usdFormatter.format(unitCost)}</UnitCost>
      <Discount>{usdFormatter.format(discount)}</Discount>
      <Tax>{usdFormatter.format(tax)}</Tax>
      <Subtotal>{usdFormatter.format(unitCost * quantity)}</Subtotal>
      <Actions>
        <ItemRowSelect
          disableClear
          placeholder={'Select an action…'}
          modal
          options={issueActions}
          value={action}
          onChange={([newAction]) => {
            if (!selection) {
              throw new Error('Attempted to change action without a product selected')
            }

            selection.reason = ''

            if (newAction) {
              const { value } = newAction
              changeAction(selection._id, selection.selectionId, value as string)
            } else {
              changeAction(selection._id, selection.selectionId, undefined)
            }
          }}
        />
      </Actions>
      <Reason>
        <ItemRowSelect
          disableClear
          placeholder={'Select a reason…'}
          modal
          options={optionsIssueReason}
          value={itemReason}
          onChange={([newReason]) => {
            if (!selection) {
              throw new Error('Attempted to change reason without a product selected')
            }
            if (newReason) {
              const { value } = newReason
              changeReason(selection._id, selection.selectionId, value as string)
            } else {
              changeReason(selection._id, selection.selectionId, undefined)
            }
          }}
        />
      </Reason>
      {OTCOrderIssueReason[itemReason?.value as keyof typeof OTCOrderIssueReason] === OTCOrderIssueReason.other && (
        <OtherReason>
          <SlimWideTextInput
            placeholder={'Enter other reason…'}
            value={selection?.otherReason}
            onChange={e => {
              if (!selection) {
                throw new Error('Attempted to change other reason without a product selected')
              }
              changeOtherReason(selection._id, selection.selectionId, e.target.value)
            }}
          />
        </OtherReason>
      )}
    </ItemRow>
  )
}

const WrappedOrderReplaceModal = (props: OrderReturnModalProps): JSX.Element => (
  <ReturnDialogProvider>
    <OrderReturnModal {...props} />
  </ReturnDialogProvider>
)

const QuantityInput = styled(NumericInput)`
  width: 5rem;
`

const StyledActionButton = styled(ActionButton)`
  background-color: ${primaryBackgroundColor};
  grid-row: 1;
  grid-column: buttons;
`

const StyledModalHeader = styled(ModalHeader)`
  margin-bottom: 1rem;
`

const StyledButtonsContainer = styled(ButtonsContainer)`
  margin-bottom: 0.625rem;
`

const ItemRowSelect = styled(Select)`
  width: 100%;
  min-height: 2rem;
`

const ItemRow = styled.ul`
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  grid-column: content;
  grid-template-rows: [content] 2.5rem [subContent] auto;
  grid-template-columns:
    [removeButton] 1.8rem [item] minmax(13rem, 40rem) [quantity] minmax(6rem, 8rem)
    [unitCost] minmax(3rem, 8rem) [discount] minmax(3rem, 8rem) [tax] minmax(3rem, 8rem)
    [subtotal] minmax(3rem, 8rem) [reason] minmax(6rem, 16rem) [actions] minmax(6rem, 16rem);
  :nth-of-type(2n + 2) {
    background-color: ${contrastBackgroundColor};
  }
  display: grid;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
`

const ItemRowCell = styled.li`
  overflow: hidden;
  grid-row: 1;
  ${EllipsisTruncate}
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 0.3125rem;
  padding-right: 0.3125rem;
`

const Item = styled(ItemRowCell)`
  grid-column: item;
  overflow: visible;
`

const Quantity = styled(ItemRowCell)`
  grid-column: quantity;
`

const UnitCost = styled(ItemRowCell)`
  grid-column: unitCost;
`

const Discount = styled(ItemRowCell)`
  grid-column: discount;
`

const Tax = styled(ItemRowCell)`
  grid-column: tax;
`

const Subtotal = styled(ItemRowCell)`
  grid-column: subtotal;
`

const Reason = styled(ItemRowCell)`
  grid-column: reason;
  overflow: visible;
`

const Actions = styled(ItemRowCell)`
  grid-column: actions;
  overflow: visible;
`

const ShippingCharge = styled(ItemRowCell)`
  grid-column: shippingCharge;
  overflow: visible;
`

const OtherReason = styled(ItemRowCell)`
  grid-column: reason / span 4;
  grid-row: subContent;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
`

const Shipping = styled(ItemRowCell)`
  grid-column: shipping;
  overflow: visible;
`

const HeaderRow = styled(ItemRow)`
  ${FilledHeadingStyle}
  padding-left: 0.625rem;
  padding-right: 0.625rem;
`

const SlimWideTextInput = styled(WideTextInput)`
  height: 2rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
`

const Truncatable = styled.p`
  ${EllipsisTruncate}
`

const StyledRemoveButton = styled(RemoveButton)`
  grid-column: removeButton;
  align-self: center;
  justify-self: flex-start;
  position: relative;
  margin-left: 0.625rem;
  width: 1.5rem;
  height: 1.5rem;
`

export default WrappedOrderReplaceModal
