import { OrderStatus, RxFillRequestStatus } from '@truepill/tpos-types'
import TopBanner from 'components/TopBanner'
import { ProblemList } from 'components/TopBanner/OrderTriageTopBanner'
import useOrderLock from 'hooks/useOrderLock'
import type { RXFillRequest, Order, RejectionDetails } from 'types'
import { MachineStatus } from 'types'

type RXTopBannerProps = {
  order: Order
  rxFillRequest: RXFillRequest
}

const RXTopBanner = ({ order, rxFillRequest }: RXTopBannerProps): JSX.Element => {
  const { isOrderLockedByMe, orderLockedBy } = useOrderLock(order._id)

  if (!order) {
    return <></>
  }

  const orderEditable = !orderLockedBy || isOrderLockedByMe

  const problemsWithOrder: string[] = []

  const cannotProceedPastPV2 =
    rxFillRequest.status !== RxFillRequestStatus.PV2
      ? false
      : order.rxFillRequests
          .map(({ status }) => status)
          .some(status => [RxFillRequestStatus.Fill, RxFillRequestStatus.PV1].includes(status))

  // You cannot fill an RX if any other RXes in the order are in
  // PV1/OnHold.
  const cannotFill =
    rxFillRequest.status !== RxFillRequestStatus.Fill
      ? false
      : order.rxFillRequests.map(({ status }) => status).some(status => status === RxFillRequestStatus.PV1)

  if (!orderEditable && order.status === OrderStatus.Adjudication) {
    return (
      <TopBanner message="There is a problem with this order">
        <li>{`This order is currently being worked on by ${orderLockedBy?.firstName} ${orderLockedBy?.lastName}`}</li>
      </TopBanner>
    )
  }

  // Shows upper banner message when RxFillRequest goes back to Fill from PV2
  if (rxFillRequest?.isReAttempt?.previousStatus === RxFillRequestStatus.PV2) {
    let reattemptMessage = 'Fill has been sent back from PV2.'
    const { reason, message } = rxFillRequest.isReAttempt
    if (reason) {
      reattemptMessage = reattemptMessage.concat(` Reason: ${reason}`)
    }
    if (message) {
      reattemptMessage = reattemptMessage.concat(` | Message: ${message}`)
    }
    return <TopBanner message={`${reattemptMessage}`} />
  }

  if (order.inTriage) {
    return <TopBanner message="Triage" />
  }

  if (rxFillRequest?.machine?.status === MachineStatus.Error) {
    return <TopBanner message={`Automation Error: ${rxFillRequest?.machine?.lastActivity}`} />
  }

  if (cannotFill || cannotProceedPastPV2) {
    const msg = 'has Rx(s) in previous queues. These need to be completed before this order can move forward.'
    problemsWithOrder.push(`Order: ${order.coreOrderId} ${msg}`)
  }

  if (rxFillRequest && rxFillRequest.status === RxFillRequestStatus.Cancelled) {
    const { rejectionDetails } = rxFillRequest as {
      rejectionDetails: RejectionDetails
    }
    if (rejectionDetails) {
      let message = rejectionDetails.reason
      if (rejectionDetails.message) {
        message += ` (${rejectionDetails.message})`
      }
      problemsWithOrder.push(`This fill request has been rejected: ${message}`)
    }
  }

  if (!orderEditable) {
    problemsWithOrder.push(
      `This order is currently being worked on by ${orderLockedBy?.firstName} ${orderLockedBy?.lastName}`,
    )
  }

  return problemsWithOrder.length ? (
    <TopBanner message="There are problems with this order">
      <ProblemList>
        {problemsWithOrder.map((problem, i) => (
          <li key={i}>{problem}</li>
        ))}
      </ProblemList>
    </TopBanner>
  ) : (
    <></>
  )
}

export default RXTopBanner
