import { useCallback, useRef } from 'react'
import { useToastContext } from 'providers/Overlays/ToastProvider'
import type { ToastOptionalArgs } from 'types'
import { ToastType } from 'types'

type useSuccessToastType = (successMessage?: string, args?: ToastOptionalArgs) => void

const useSuccessToast = (showRepeatedSuccess?: boolean): useSuccessToastType => {
  const { showToast } = useToastContext()

  const successRef = useRef<string>()
  return useCallback(
    (successMessage?: string, args?: ToastOptionalArgs) => {
      if (successRef.current !== successMessage || showRepeatedSuccess) {
        successRef.current = successMessage
        if (successMessage) {
          showToast(successMessage, ToastType.success, args?.timeToAutoDismiss, args?.position)
        }
      }
    },
    [showRepeatedSuccess, showToast],
  )
}

export default useSuccessToast
