import type { ReactElement } from 'react'
import { useCallback, useState } from 'react'
import { StyledButton, StyledLink, StyledPrescriberTabList, StyledText } from './StyledComponents'

type TabTitleProps = {
  title: string
  index: number
  icon: JSX.Element
  setSelectedTab: (index: number) => void
  isCurrentTab: boolean
}

const TabTitle = ({ title, setSelectedTab, index, icon, isCurrentTab }: TabTitleProps): JSX.Element => {
  const onClick = useCallback(() => {
    setSelectedTab(index)
  }, [setSelectedTab, index])

  return (
    <StyledLink isCurrentTab={isCurrentTab}>
      <StyledButton isCurrentTab={isCurrentTab} onClick={onClick}>
        {icon}
        <StyledText isCurrentTab={isCurrentTab}>{title}</StyledText>
      </StyledButton>
    </StyledLink>
  )
}

type PrescriberTabsProps = { children: ReactElement[] }

const PrescriberTabs = ({ children }: PrescriberTabsProps): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState(0)

  return (
    <div>
      <StyledPrescriberTabList>
        {children.map((item, index) => (
          <TabTitle
            key={index}
            title={item.props.title}
            index={index}
            icon={item.props.icon}
            setSelectedTab={setSelectedTab}
            isCurrentTab={selectedTab === index}
          />
        ))}
      </StyledPrescriberTabList>
      {children[selectedTab]}
    </div>
  )
}

export default PrescriberTabs
