import { UserRoles } from '@truepill/tpos-types'
import { usePlusClient } from 'providers/VisionRouter'

const useShouldIncludePatientRelatedLogs = () => {
  const {
    tokenContext: { hasRole },
  } = usePlusClient()

  return hasRole([UserRoles.CustomerSupport, UserRoles.LeadCustomerSupport])
}

export default useShouldIncludePatientRelatedLogs
