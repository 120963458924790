import ActionButton from 'components/ActionButton'
import { ActionBar } from 'components/PageStructure'
import SearchInput from 'components/SearchInput'
import styled from 'styled-components'
import { primaryBackgroundColor, primaryColor, bodyPrimaryColor } from 'styles/styleVariables'

const PracticeAddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-radius: 0.25rem;
  flex: 1;
  margin-bottom: 1rem;
  width: 100%;
`

const StyledActionBar = styled(ActionBar)`
  align-items: flex-end;
  grid-template-columns: [meta] 0.68fr [actions] 1.5fr;
  padding-left: 0;
  width: 100%;
  padding-right: 0;
`

const StyledSearchInput = styled(SearchInput)`
  flex: 1;
  max-width: 14rem;
`

const PaddingContainer = styled.div`
  height: 500px;
  width: 100%;
  margin-bottom: 1rem;
  margin-top: 3rem;
`

const NewPracticeLocationControls = styled.div`
  grid-column: actions;
  grid-row: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`

const SearchLabel = styled.label`
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 0.625rem;
`

const StyledActionButton = styled(ActionButton)<{ showArchive?: boolean }>`
  background-color: ${props => (props.showArchive ? primaryColor : primaryBackgroundColor)};
  color: ${props => (props.showArchive ? primaryBackgroundColor : bodyPrimaryColor)};
`

const SearchControls = styled.div`
  grid-column: meta;
  grid-row: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

export {
  PracticeAddressContainer,
  StyledActionBar,
  StyledSearchInput,
  PaddingContainer,
  NewPracticeLocationControls,
  SearchLabel,
  StyledActionButton,
  SearchControls,
}
