export enum CustomerReturnAction {
  RefundToCard = 'refundToCard',
  RefundForCredit = 'refundForCredit',
  Return = 'return',
  Replace = 'replace',
  ReturnAndReplace = 'returnAndReplace',
}

/**
 * Possible reasons for a {@link CustomerReturn}.
 */
export enum CustomerReturnReason {
  ItemDamaged = 'itemDamaged',
  WrongItemShipped = 'wrongItemShipped',
  WrongItemPurchased = 'wrongItemPurchased',
  ItemNoLongerNeeded = 'itemNoLongerNeeded',
  DeliveredButNeverReceived = 'deliveredButNeverReceived',
  LostInTransit = 'lostInTransit',
  Other = 'other',
}

export enum CustomerReturnShippingCharge {
  WaiveCharge = 'waiveCharge',
  ChargeDifference = 'chargeDifference',
}

export enum CustomerReturnShippingPayment {
  Refund = 'refund',
  PartialRefund = 'partialRefund',
  DoNotRefund = 'doNotRefund',
}

export enum CustomerReturnStatus {
  Packing = 'Packing',
  Shipped = 'Shipped',
  Complete = 'Complete',
  Cancelled = 'Cancelled',
}
