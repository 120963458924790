import { memo, useCallback, useEffect } from 'react'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { Button, Grid, GridItem } from '@truepill/react-capsule'
import CustomizedTextField from 'components/CustomizedTextField'
import { GET_SIG_CODE, UPDATE_SIG_CODE } from 'gql'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import { useFormData } from 'hooks/useFormData'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import type { FormUpdate } from 'providers/Store'
import styled from 'styled-components'
import type { ExceptionNDC } from 'types'

const validations = [
  {
    validator: (value: string) => /[^A-Za-z0-9\s]/.test(value),
    error: 'Special characters are not allowed in NDC',
  },
  {
    validator: (value: string) => /\s/.test(value),
    error: 'Spaces are not allowed in NDC',
  },
  {
    validator: (value: string) => !value,
    error: 'NDC is required',
  },
]

const validationsMultiplier = [
  {
    validator: (value: string) => !value || Number(value) <= 0,
    error: 'DS Multiplier is required',
  },
]

interface TextInputProps {
  value?: string
  setter: (value: FormUpdate) => void
}

const validateNdc = (value: string) => {
  const wrongValidation = validations.find(({ validator }) => validator(value))
  return { error: wrongValidation?.error }
}

const validateMultiplier = (value: string) => {
  const wrongValidation = validationsMultiplier.find(({ validator }) => validator(value))
  return { error: wrongValidation?.error }
}

const NDCTextInput = memo(({ value, setter }: TextInputProps): JSX.Element => {
  const { error: errorMessage } = validateNdc(value ?? '')
  return (
    <CustomizedTextField
      value={value}
      onChange={e => {
        setter({
          exceptionNdc: {
            ndc: {
              $set: e.target.value,
            },
          },
        })
      }}
      label="Exception NDC"
      required
      placeholder="Ex. 502610104"
      helperText={errorMessage}
      state={errorMessage ? 'error' : 'default'}
    />
  )
})

const MultiplierTextInput = memo(({ value, setter }: TextInputProps): JSX.Element => {
  const { error: errorMessage } = validateMultiplier(value ?? '')
  return (
    <CustomizedTextField
      type="number"
      value={value}
      onChange={e => {
        setter({
          exceptionNdc: {
            multiplier: {
              $set: Number(e.target.value),
            },
          },
        })
      }}
      subCopy=""
      label="DS Multiplier"
      required
      placeholder="Ex. 30"
      helperText={errorMessage}
      state={errorMessage ? 'error' : 'default'}
    />
  )
})

const MedicationTextInput = memo(({ value, setter }: TextInputProps): JSX.Element => {
  return (
    <CustomizedTextField
      value={value}
      onChange={e => {
        setter({
          exceptionNdc: {
            medicationName: {
              $set: e.target.value,
            },
          },
        })
      }}
      subCopy=""
      label="Medication Name"
      required
      placeholder="Ex. Medication"
    />
  )
})

const validateForm = (formData: any): boolean => {
  const ndc = formData.exceptionNdc?.ndc || ''
  const multiplier = formData.exceptionNdc?.multiplier || 0
  const { error } = validateNdc(ndc)
  return !!ndc && !!multiplier && !error
}

export const ExceptionNDCForm = ({ sigCode, editNDC }: { sigCode?: string; editNDC?: ExceptionNDC }): JSX.Element => {
  const {
    state: { formData },
    actions: { updateFormData },
  } = useFormData()

  const [updateSigCode, { data, loading }] = useMutation(UPDATE_SIG_CODE, {
    refetchQueries: [{ query: GET_SIG_CODE, variables: { code: sigCode } }],
  })
  const { data: sigCodeData, loading: sigCodeLoading } = useQuery(GET_SIG_CODE, {
    variables: {
      code: sigCode,
    },
  })

  const ndc = formData.exceptionNdc?.ndc
  const multiplier = formData.exceptionNdc?.multiplier
  const medicationName = formData.exceptionNdc?.medicationName

  const { dismissModal } = useModalContext()
  const showSuccessToast = useSuccessToast()

  const handleSubmit = useCallback(() => {
    const ndcs = sigCodeData?.getSigCode?.exceptionNDC || []
    let addedNdcs = [...ndcs]

    if (editNDC) {
      const foundNdcIndex = addedNdcs.findIndex((ndc: ExceptionNDC) => ndc.ndc === editNDC.ndc)

      if (foundNdcIndex !== -1) {
        const updatedNdc = {
          ...addedNdcs[foundNdcIndex],
          ndc,
          medicationName,
          multiplier,
        }
        addedNdcs[foundNdcIndex] = updatedNdc
      }
    } else {
      addedNdcs = [...ndcs, formData.exceptionNdc as ExceptionNDC]
    }

    updateSigCode({
      variables: {
        id: sigCodeData?.getSigCode?._id,
        patch: {
          exceptionNDC: addedNdcs.map((ndc: ExceptionNDC) => ({
            ndc: ndc.ndc,
            medicationName: ndc.medicationName,
            multiplier: ndc.multiplier,
          })),
        },
      },
    })
  }, [
    editNDC,
    formData.exceptionNdc,
    medicationName,
    multiplier,
    ndc,
    sigCodeData?.getSigCode?._id,
    sigCodeData?.getSigCode?.exceptionNDC,
    updateSigCode,
  ])

  useEffect(() => {
    if (data) {
      showSuccessToast('Exception NDC added successfully.')
      dismissModal()
    }
  }, [data, dismissModal, showSuccessToast])
  const formIsValid = validateForm(formData)

  return (
    <FormWrapper>
      <Grid>
        <GridItem desktop={12} tablet={8}>
          <NDCTextInput value={ndc} setter={updateFormData} />
        </GridItem>
        <GridItem desktop={12} tablet={8}>
          <MultiplierTextInput value={multiplier?.toString()} setter={updateFormData} />
        </GridItem>
        <GridItem desktop={12} tablet={8}>
          <MedicationTextInput value={medicationName} setter={updateFormData} />
        </GridItem>
      </Grid>
      <ButtonsWrapper>
        <Button disabled={!formIsValid || loading || sigCodeLoading} size="sm" onClick={handleSubmit}>
          Save
        </Button>
      </ButtonsWrapper>
    </FormWrapper>
  )
}

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

const FormWrapper = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  margin-top: 2rem;
`
export default ExceptionNDCForm
