import { useCallback } from 'react'
import { useMutation, useQuery } from '@truepill/tpos-react-router'
import { CLEAR_COPAY_LOCK, GET_COPAY_LOCK, SET_COPAY_LOCK } from 'gql'
import { usePlusClient } from 'providers/VisionRouter'
import { isNil } from 'ramda'
import type { Lock, CopayRequest, User, UserFirstLastName } from 'types'

type LockWithPartialUser = Omit<Lock, 'user'> & { user: Pick<User, '_id' | 'firstName' | 'lastName'> }
type CopayLockMutation = Pick<CopayRequest, '_id'> & { lock: LockWithPartialUser }

interface CopayLock {
  copayEditable: boolean
  copayLock?: LockWithPartialUser
  copayLockedBy?: UserFirstLastName
  isCopayLockedByMe: boolean
  setCopayLock: () => Promise<LockWithPartialUser | undefined>
  clearCopayLock: () => Promise<LockWithPartialUser | undefined>
}

export default function useCopayLock(copayId?: string, shouldSkip = false): CopayLock {
  const { tokenContext } = usePlusClient()
  const { loading, error, data } = useQuery<{ copay: { lock?: LockWithPartialUser } }>(GET_COPAY_LOCK, {
    skip: isNil(copayId) || shouldSkip,
    variables: { copayId },
  })

  const [setCopayLockMutation, { data: copayLockData, error: copayLockError }] = useMutation<{
    copay?: CopayLockMutation
  }>(SET_COPAY_LOCK)

  const [clearCopayLockMutation, { data: copayClearData, error: copayClearError }] = useMutation<{
    copay?: CopayLockMutation
  }>(CLEAR_COPAY_LOCK)

  const setCopayLock = useCallback(async () => {
    if (!copayId) {
      return
    }

    await setCopayLockMutation({ variables: { copayId } })

    if (copayLockError) {
      throw new Error(copayLockError.toString())
    }

    return copayLockData?.copay?.lock
  }, [copayId, setCopayLockMutation, copayLockData, copayLockError])

  const clearCopayLock = async () => {
    if (!copayId) {
      return
    }

    await clearCopayLockMutation({ variables: { copayId } })

    if (copayClearError) {
      throw new Error(copayClearError.toString())
    }

    return copayClearData?.copay?.lock
  }

  const isCopayLockedByMe = data?.copay?.lock?.user._id === tokenContext?.id
  const copayEditable = !copayId || (!!tokenContext && (!data?.copay?.lock || isCopayLockedByMe))

  return {
    copayEditable,
    isCopayLockedByMe,
    copayLockedBy: data?.copay?.lock?.user,
    copayLock:
      loading || error || !data || (data.copay?.lock?.expiresAt ?? -1) < Date.now() ? undefined : data.copay?.lock,
    setCopayLock,
    clearCopayLock,
  }
}
