export {
  isCompleteAddress,
  getPrescriberAddress,
  getPrescriberAddressWithFax,
  checkIfAddressChanged,
  formatAddress,
  validateAddressForm,
} from './address'
export { getOrderAttachmentsCount, getParentAttachments } from './attachment'
export { default as boolToHumanReadable } from './boolToHumanReadable'
export { calculateClaimPricingResponseFee, calculateClaimPricingFee } from './Claim'
export { labelForDAW, shortLabelForDAW } from './daw'
export { default as diffObjects } from './diffObjects'
export { humanReadableFileSize } from './fileUpload'
export { default as formatCurrency } from './formatCurrency'
export { default as stripTypename } from './stripTypename'
export { default as hasIntersection } from './hasIntersection'
export { default as keyFromDur } from './keyFromDur'
export { default as mostSevereDURStatus } from './mostSevereDURStatus'
export { limitPeriods, onlyNumbers } from './numberFormatter'
export { default as formatPhoneNumber, validPhoneNumber, formatPhoneForDB, formatPhoneInput } from './phoneFormatter'
export { isPatientFormValid, validatePatientForm } from './Patient'
export { isRobotPrint } from './print'
export { default as sortDURsBySeverity } from './sortDURsBySeverity'
export { default as states } from './states'
export { fullNameToInitials } from './userNameFormatter'
export { default as formatZip, matchZip, matchZip5Digits } from './zipFormatter'
export { shippingMethodToName, getTPCSShippingMethodName } from './shippingMethods'
export { swapArrayElements } from './swapArrayElements'
export { default as isInsuranceFill } from './isInsuranceFill'
export {
  validateNpi,
  validatePractice,
  validatePrescriberAndPractice,
  isPrescriberFormComplete,
  validatePrescriberForm,
  validatePrescriberPracticeForm,
} from './prescriber'
export { getClaimsFromLastRun, getInsuranceKey } from './insurance'
export {
  isShipmentGenerated,
  isLabelPrinted,
  shippingLabelRequests,
  isShippingLabelRequested,
  shippingLabelPending,
  ShippingLabelStatus,
  getShippingLabelStatus,
  formatCreatedDate,
} from './order'
export {
  isCritical,
  noteToLog,
  sortByDateStamp,
  getCriticalNotes,
  getCriticalLogs,
  getFillLevelNotes,
  getFillParentNotes,
  getNotes,
} from './notes'
export { getCurrentFill, matchRXLabel, hasOpenFills, calculateFillsRemaining } from './Prescription'
export {
  camelCaseToHumanReadable,
  removeUnderscore,
  snakeCaseToHumanReadable,
  removeCamelCase,
  capitalize,
  checkIfProvided,
  checkIfNull,
  firstLetterUpper,
  minTwoDigits,
  removeSlashOne,
  ordinal,
  wordyOrdinal,
  escapeRegExp,
  usdFormatter,
  addStateToLocationName,
  nameFromNdcPackage,
  nameFromNdcSubstitution,
  descriptionFromNdcSubstitution,
  removeNewLineAndTrim,
} from './textFormatting'
export { TriageReasonMap, CopayTriageReasonMap } from './triageReasons'
export { default as omitDeep } from './omitDeep'
export { orderCanBeFilled, orderStatusValue, nextOrderFillWithStatus } from './orderStatus'
export { directionsLengthDetails, getNextRxFillRequest, getPackageMeasureDescription } from './Fill'
export { default as getCurrentRelease } from './getCurrentRelease'
export { KeyWords } from './keys'
export { validateNdcBarcode, isScannedCodeAnAlternateBarCode } from './ndc'
export { getFormattedTimeSince } from './dates'
export { floatSafeRemainder } from './floatSafeRemainder'
export {
  getRxImagePrescriber,
  getRxImagePrescription,
  getRxImagePatient,
  getRxImagePrescriberManualEntry,
  getRxImagePrescriptionManualEntry,
  getRxImagePatientManualEntry,
} from './rxImageFields'
export { default as continueOrderWorkflow } from './continueOrderWorkflow'
export { default as isTypeofObject } from './isTypeofObject'
export { default as getErrorMessage } from './getErrorMessage'
export { default as getApolloErrorMessage } from './getApolloErrorMessage'
export {
  isCopayRequest,
  isCopayItems,
  isOrderItems,
  isOrder,
  isOrderTriage,
  getItemFill,
  isRxFillRequest,
} from './copayChecks'
export { ageFormatter } from './ageFormatter'
export { languagesSupported } from './languageSupported'
export { default as isActivePatient } from './isActivePatient'
export { default as convertWeight } from './convertWeight'
export { parseGS1String } from './scanner'
export * from './getTotalCost'
export { default as getNdcText } from './getNdcText'
export const PATIENT_NAME_REGEXP = /[A-Za-z]+([, ]+[A-Za-z]+){0,2}/
export const PATIENT_ZIP_REGEXP = /^\d{5}(?:[-\s]?\d{4})?$/
export const PATIENT_TOKEN_REGEXP = /^[a-z0-9]{6}$|^[a-z0-9]{16}$/
export const PATIENT_EMAIL_REGEXP = /^[\w.-]+@[a-zA-Z\d.-]+.[a-zA-Z]{2,}$/
export const PATIENT_PHONE_REGEXP =
  /^([0-9]{10})|([0-9]{3}-[0-9]{3}-[0-9]{4})|(\([0-9]{3}\)[0-9]{3}-[0-9]{4})|(\([0-9]{3}\) [0-9]{3}-[0-9]{4})|(\+1[0-9]{10})$/

export const NDC_REGEXP = /^\d+$/
export const NDC_HYPHENATED_REGEXP = /^[ 0-9-]*$/
export const DRUGNAME_REGEX = /^[ A-Za-z0-9_@./#&+-]*$/

export const DATE_FORMAT = 'YYYY-MM-DD'

export const notEmpty = <TValue>(value: TValue | null | undefined): value is TValue =>
  value !== null && value !== undefined
