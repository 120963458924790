import Modal from 'components/Modal'
import Toast from 'components/Toast'
import styled from 'styled-components'
import type { ChildProps } from 'types'
import { Nav } from './Navigation'

const Layout = ({ children }: ChildProps): JSX.Element => {
  return (
    <>
      <Modal />
      <Nav />
      <MainContainer id={'MainContainer'}>{children}</MainContainer>
      <Toast />
    </>
  )
}

const MainContainer = styled.div`
  height: calc(100vh - 3.75rem);
  overflow-y: scroll;
`

export default Layout
