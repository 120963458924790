import { ReactComponent as ReverseIcon } from 'assets/icons/reverse.svg'
import ActionButton from 'components/ActionButton'
import IconWrapper from 'components/IconWrapper'
import ReverseModal from 'modals/ReverseModal'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import { bodyPrimaryColor } from 'styles/styleVariables'
import type { Order } from 'types'

type ReturnOrderButtonProps = { order?: Order; disabled?: boolean }

const ReturnOrderButton = ({ order, disabled }: ReturnOrderButtonProps): JSX.Element => {
  const { showModal } = useModalContext()

  return (
    <ActionButton
      icon={
        <IconWrapper>
          <ReverseIcon fill={bodyPrimaryColor} />
        </IconWrapper>
      }
      label={'Return order'}
      onClick={() => {
        showModal(() => <ReverseModal order={order} />)
      }}
      disabled={disabled}
    />
  )
}
export default ReturnOrderButton
