import { useState, useRef, useEffect, createContext, useContext } from 'react'
import { ToastPosition } from 'types'
import type { ChildProps, Toast, ToastType } from 'types'

interface ToastProviderContextProps {
  position: ToastPosition
  toastSlices: Toast[]
  dismissToast: (id: string) => void
  showToast: (message: string, type: ToastType, timeToAutoDismiss?: number, position?: ToastPosition) => void
}

const ToastContext = createContext<ToastProviderContextProps>({
  position: ToastPosition.BottomRight,
  toastSlices: [],
  dismissToast: (id: string) => {
    return
  },
  showToast: (message: string, type: ToastType, timeToAutoDismiss?: number, position?: ToastPosition) => {
    return
  },
})

const ToastProvider = ({ children }: ChildProps): JSX.Element => {
  const [toastSlices, setToastSlices] = useState<Toast[]>([])
  const [position, setPosition] = useState(ToastPosition.BottomRight)

  const slicesRef = useRef(toastSlices)
  const setSlicesRef = useRef(setToastSlices)
  useEffect(() => {
    slicesRef.current = toastSlices
    setSlicesRef.current = setToastSlices
  }, [toastSlices, setToastSlices])

  const positionRef = useRef(position)
  const setPositionRef = useRef(setPosition)
  useEffect(() => {
    positionRef.current = position
    setPositionRef.current = setPosition
  }, [position, setPositionRef])

  const value = {
    position,
    toastSlices,
    dismissToast: (dismissToastId: string) => {
      const newSlices = [...slicesRef.current]
      const toastIndex = newSlices.findIndex(({ id }) => id === dismissToastId)
      newSlices.splice(toastIndex, 1)
      setSlicesRef.current(newSlices)
    },
    showToast: (message: string, toastType: ToastType, timeToAutoDismiss?: number, newPosition?: ToastPosition) => {
      const newToast: Toast = {
        message,
        timeToAutoDismiss,
        type: toastType,
        id: `${new Date().getTime()}${message}`,
      }
      setSlicesRef.current([newToast, ...slicesRef.current])
      newPosition && setPositionRef.current(newPosition)
    },
  }

  return <ToastContext.Provider value={value}>{children}</ToastContext.Provider>
}

const useToastContext = (): ToastProviderContextProps => {
  const toastContext = useContext(ToastContext)

  if (toastContext === undefined) {
    throw new Error('Attempting to read ToastContext outside a Provider heirarchy')
  }

  return toastContext
}

export { useToastContext }
export default ToastProvider
