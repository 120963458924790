import type { FetchResult } from '@apollo/client'
import { useMutation } from '@truepill/tpos-react-router'
import { UNCANCEL_FILL } from 'gql'
import useErrorToast from './toast/useErrorToast'
import useSuccessToast from './toast/useSuccessToast'

export default function useUncancelFill(): (orderId: string, fillId: string) => Promise<FetchResult<any>> {
  const showErrorToast = useErrorToast()
  const showSuccessToast = useSuccessToast()
  const [mutate] = useMutation<{ orderId: string; fillId: string }>(UNCANCEL_FILL, {
    onCompleted: () => {
      showSuccessToast('Fill un-cancelled successfully!')
    },
    onError: error => {
      console.error(`Failed to un-cancel fill:`, error)
      showErrorToast(`Failed to un-cancel fill: ${error}`)
    },
  })

  return (orderId: string, fillId: string) => mutate({ variables: { fillId, orderId } })
}
