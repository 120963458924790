// TODO: MOVE ME TO TRUEUI
import type { MutableRefObject, DetailedHTMLProps, InputHTMLAttributes, CSSProperties } from 'react'
import { useRef } from 'react'
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg'
import CustomizedTextField from 'components/CustomizedTextField'
import HotKeyToolTip from 'components/HotKeyToolTip'
import IconWrapper from 'components/IconWrapper'
import type { TextInputProps } from 'grommet'
import { Form, TextInput } from 'grommet'
import useHotKey, { HotKeyLevel } from 'hooks/useHotKey'
import styled from 'styled-components'
import { color } from 'styles/styleVariables'
import type { OptChildProps } from 'types'

type SearchInputProps = {
  autoComplete?: string
  css?: CSSProperties
  state?: 'default' | 'complete' | 'error'
  iconColor?: string
  className?: string
  valueSelected?: boolean
  hotKey?: string
  withCapsule?: boolean
  label?: string
  disabled?: boolean
  searchInputRef?: MutableRefObject<HTMLInputElement | null>
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> &
  TextInputProps &
  OptChildProps

const SearchInput = ({
  autoComplete = 'off',
  css,
  children,
  iconColor,
  className,
  withCapsule,
  label,
  disabled,
  hotKey = '',
  searchInputRef,
  ...props
}: SearchInputProps): JSX.Element => {
  const ref = useRef<HTMLInputElement | null>()

  useHotKey(hotKey, HotKeyLevel.normal, () => {
    ref.current?.focus()
  })

  const renderTextInput = () => {
    return withCapsule ? (
      <CustomizedTextField
        type="search"
        startAdornment={() => (
          <StyledIconWrapper>
            <SearchIcon fill={iconColor} />
          </StyledIconWrapper>
        )}
        css={{ ...css }}
        size="sm"
        label={label || ''}
        state={props.state}
        value={props.value}
        disabled={disabled}
        placeholder={props.placeholder}
        onChange={props.onChange}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        onKeyDown={props.onKeyDown}
        ref={el => {
          if (searchInputRef) {
            searchInputRef.current = el as unknown as HTMLInputElement
          }

          ref.current = (el as unknown as HTMLInputElement) ?? undefined
        }}
      />
    ) : (
      <>
        <StyledIconWrapper>
          <SearchIcon fill={iconColor} />
        </StyledIconWrapper>
        <StyledForm>
          <StyledTextInput
            {...props}
            ref={el => {
              if (searchInputRef) {
                searchInputRef.current = el as unknown as HTMLInputElement
              }

              ref.current = (el as unknown as HTMLInputElement) ?? undefined
            }}
            type="search"
            plain
          />
        </StyledForm>
      </>
    )
  }

  return (
    <SearchContainer
      className={className}
      withCapsule={withCapsule}
      data-testid={
        (props as any)['data-testid'] ? `${(props as any)['data-testid']}-search-container` : 'search-container'
      }
    >
      {!withCapsule && (children || null)}
      <SearchField>
        {hotKey && <HotKeyToolTip label={hotKey.toUpperCase()} position="top" offsetTop={-2.8} offsetLeft={5} />}
        {renderTextInput()}
      </SearchField>
      {withCapsule && (children || null)}
    </SearchContainer>
  )
}
export default SearchInput

const StyledTextInput = styled(TextInput)<{ valueSelected?: boolean }>`
  margin-left: 0rem;
  padding-left: 0rem;
  ${({ valueSelected }) => valueSelected && `&::placeholder { color: black; }`}
`

const SearchField = styled.div`
  display: flex;
  width: 100%;
`

const StyledIconWrapper = styled(IconWrapper)`
  height: 2rem;
  width: 2rem;
  padding: 0.6rem 0.525rem;
  overflow: visible;
`

const SearchContainer = styled.div<{ withCapsule?: boolean }>`
  background-color: white;
  ${props => !props.withCapsule && `border: 2px solid ${color.border};`}
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`

const StyledForm = styled(Form)`
  margin-left: 0.125rem;
  flex-grow: 1;
  display: flex;
  align-items: center;
  min-height: 2rem;
`
