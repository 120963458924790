import { Checkbox, Select } from '@truepill/react-capsule'
import {
  HandlingTagLabels,
  RxFillRequestTags,
  RxFillRequestSpecialHandlingTag,
  RxFillRequestSpecialHandlingTagAllOrNoOptions,
} from '@truepill/tpos-types'
import { usePlusClient } from 'providers/VisionRouter'

const SpecialHandlingSelect = (): JSX.Element => {
  const { currentLocation, routeToMergedQuery } = usePlusClient()

  const selectedOptions = currentLocation?.queryMap?.specialHandlingTags || []
  const noHandlingTags = currentLocation?.queryMap?.noHandlingTags
  const defaultOption = { name: 'Select all', value: RxFillRequestSpecialHandlingTagAllOrNoOptions.ALL }
  const noSpecialHandlingOption = {
    name: RxFillRequestSpecialHandlingTagAllOrNoOptions.NO_SPECIAL_HANDLING,
    value: RxFillRequestSpecialHandlingTagAllOrNoOptions.NO_SPECIAL_HANDLING,
  }

  const handlingTagLabelsOptions = Object.values(HandlingTagLabels).map(value => {
    if (value === HandlingTagLabels.CIII_CV) {
      return {
        name: value,
        value: `${RxFillRequestSpecialHandlingTag.CIII},${RxFillRequestSpecialHandlingTag.CIV},${RxFillRequestSpecialHandlingTag.CV}`,
      }
    }

    return { name: value, value }
  })

  const rxFillRequestTagsOptions = Object.values(RxFillRequestTags).reduce<{ name: string; value: string }[]>(
    (acumm, curr) => {
      if (curr !== RxFillRequestTags.IRB) {
        acumm.push({ name: curr, value: curr })
      }
      return acumm
    },
    [],
  )

  const options = [defaultOption, ...handlingTagLabelsOptions, ...rxFillRequestTagsOptions, noSpecialHandlingOption]

  const value = selectedOptions.includes(RxFillRequestSpecialHandlingTagAllOrNoOptions.ALL)
    ? RxFillRequestSpecialHandlingTagAllOrNoOptions.ALL
    : selectedOptions.join(',')

  if (!noHandlingTags && !selectedOptions.length) {
    routeToMergedQuery({ specialHandlingTags: [RxFillRequestSpecialHandlingTagAllOrNoOptions.ALL] })
  }

  return (
    <Select
      label="Special handling"
      css={{
        ul: { maxHeight: '21rem' },
      }}
      value={value}
      onChange={e => {
        // Because the elements are checkboxes which trigger an action, we do not need to do anything here.
        // Do not close the dropdown everytime a check is selected
        e.preventDefault()
      }}
      options={options}
      placeholder="Select special handling"
      selectedKey="name"
      optionComponent={({ option }) => (
        <>
          <Checkbox
            label={option?.name}
            checked={
              selectedOptions.includes(option?.value) ||
              selectedOptions.includes(RxFillRequestSpecialHandlingTagAllOrNoOptions.ALL)
            }
            onCheckedChange={checked => {
              if (checked) {
                if (option?.value === RxFillRequestSpecialHandlingTagAllOrNoOptions.ALL) {
                  routeToMergedQuery({ specialHandlingTags: [option.value], noHandlingTags: undefined })
                } else {
                  routeToMergedQuery({
                    specialHandlingTags: [...selectedOptions, option?.value],
                    noHandlingTags: undefined,
                  })
                }
              } else {
                if (option?.value === RxFillRequestSpecialHandlingTagAllOrNoOptions.ALL) {
                  routeToMergedQuery({ specialHandlingTags: [], noHandlingTags: true })
                } else {
                  if (selectedOptions.includes(RxFillRequestSpecialHandlingTagAllOrNoOptions.ALL)) {
                    const remainingValues = [
                      ...handlingTagLabelsOptions.filter(opt => opt.value !== option?.value).map(opt => opt.value),
                      ...rxFillRequestTagsOptions.filter(opt => opt.value !== option?.value).map(opt => opt.value),
                    ]

                    if (option?.value !== RxFillRequestSpecialHandlingTagAllOrNoOptions.NO_SPECIAL_HANDLING) {
                      remainingValues.push(RxFillRequestSpecialHandlingTagAllOrNoOptions.NO_SPECIAL_HANDLING)
                    }
                    routeToMergedQuery({
                      specialHandlingTags: remainingValues,
                      noHandlingTags: undefined,
                    })
                  } else {
                    const newSelectedOptions = selectedOptions.filter((value: string) => value !== option?.value)
                    routeToMergedQuery({
                      specialHandlingTags: newSelectedOptions,
                      noHandlingTags: newSelectedOptions.length ? undefined : true,
                    })
                  }
                }
              }
            }}
          />
        </>
      )}
    />
  )
}

export default SpecialHandlingSelect
