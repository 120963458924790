import HotKeyToolTip from 'components/HotKeyToolTip'
import { ListRowLabel, ListRowValue, RaisedAnchor, RightFilledHeader, PaddingBlock } from 'components/RXTable'
import useHotKey, { HotKeyLevel } from 'hooks/useHotKey'
import { usePlusClient } from 'providers/VisionRouter'
import type { EdiOverrides } from 'types'
import { RXEDIListRow, RXEDImageCell, RXEDIRightEditCell, RXEDIRightTitleCell, RXEDITitleRow, RXTextInput } from '..'

type PharmacyProviderSegmentProps = {
  ediOverrides: EdiOverrides
  updateEdiOverrides: (input: EdiOverrides) => void
}

const PharmacyProviderSegment = ({ ediOverrides, updateEdiOverrides }: PharmacyProviderSegmentProps): JSX.Element => {
  const { pharmacyProvider } = ediOverrides

  const medicaidId = pharmacyProvider?.medicaidId

  return (
    <>
      <PharmacyProviderHeaderRow />
      <RXEDIListRow>
        <RXEDIRightEditCell>
          <ListRowLabel>Medicaid Id (N5):</ListRowLabel>
          <ListRowValue>
            <RXTextInput
              value={medicaidId ?? ''}
              onChange={({ target: { value: medicaidId } }) => updateEdiOverrides({ pharmacyProvider: { medicaidId } })}
              placeholder="Medicaid Id..."
            />
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>
    </>
  )
}

const PharmacyProviderHeaderRow = () => {
  const { routeToHash } = usePlusClient()

  useHotKey('6', HotKeyLevel.normal, () => {
    routeToHash(`PharmacyProviderSegment`)
  })

  return (
    <RXEDITitleRow>
      <RXEDImageCell />
      <RXEDIRightTitleCell>
        <PaddingBlock />
        <RightFilledHeader>
          <RaisedAnchor id={'PharmacyProviderSegment'} />
          Pharmacy provider segment (02)
          <HotKeyToolTip label={'6'} position={'right'} offsetLeft={1} offsetTop={0} />
        </RightFilledHeader>
      </RXEDIRightTitleCell>
    </RXEDITitleRow>
  )
}

export default PharmacyProviderSegment
