/**
 * A type-safe way to do `typeof obj === 'object'` (and check that it isn't null) of an unknown.
 * This also asserts that obj is a Record of unknowns allowing for further property checks that
 * aren't allowed with a simple `typeof obj === 'object'` check.
 * **WARNING**: returns true for Arrays */
const isTypeofObject = (obj: unknown): obj is Record<PropertyKey, unknown> => {
  return Boolean(obj && typeof obj === 'object')
}

export default isTypeofObject
