import type { InsuranceData } from '@truepill/tpos-types'
import { InsurancePatientRelationship } from '@truepill/tpos-types'

const areInsurancesEqual = (firstInsurance: InsuranceData, secondInsurance: InsuranceData): boolean =>
  firstInsurance.bin.toLowerCase() === secondInsurance.bin.toLowerCase() &&
  ((!firstInsurance.cardholderId && !secondInsurance.cardholderId) ||
    firstInsurance.cardholderId?.toLowerCase() === secondInsurance.cardholderId?.toLowerCase()) &&
  ((!firstInsurance.group && !secondInsurance.group) ||
    firstInsurance.group?.toLowerCase() === secondInsurance.group?.toLowerCase()) &&
  ((!firstInsurance.pcn && !secondInsurance.pcn) ||
    firstInsurance.pcn?.toLowerCase() === secondInsurance.pcn?.toLowerCase()) &&
  ((!firstInsurance.relationship && !secondInsurance.relationship) ||
    firstInsurance.relationship?.toLowerCase() === secondInsurance.relationship?.toLowerCase() ||
    (secondInsurance.relationship === InsurancePatientRelationship.NotSpecified && !firstInsurance.relationship) ||
    (firstInsurance.relationship === InsurancePatientRelationship.NotSpecified && !secondInsurance.relationship))

export default areInsurancesEqual
