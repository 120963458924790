import { useCallback, useRef } from 'react'
import type { RxFillRequestStatus, RxFillRequestSpecialHandlingTag } from '@truepill/tpos-types'
import useErrorToast from 'hooks/toast/useErrorToast'
import useInfoToast from 'hooks/toast/useInfoToast'
import useAutoUpdatingRef from 'hooks/useAutoUpdatingRef'
import { usePlusClient } from 'providers/VisionRouter'
import type { Fill, Order, FillInfoType, RXFillRequest } from 'types'
import { getNextRxFillRequest } from 'utils/Fill'
import { nextOrderFillWithStatus } from 'utils/orderStatus'
import type { FulfillmentQueueName } from './useFulfillmentQueue'

let lastFilterSet = {}

export type UseGetNextOrderType = {
  startWork: () => void | Promise<void>
  getNextOrder: (
    currentOrder?: Order | undefined,
    currentFill?: Fill | undefined,
    lastSolvedTriageId?: string | undefined,
  ) => void | Promise<void>
}

export type GetNextOrderFilters = {
  customerIds?: string[]
  locationIds?: string[]
  locationId?: string
  medications?: string[]
  otcOnly?: boolean
  paymentType?: string
  reasons?: string[]
  orderStages?: string[]
  fillStages?: string[]
  batchOrders?: boolean
  showTriage?: boolean
  specialHandlingTags?: RxFillRequestSpecialHandlingTag[]
}

export type GetDataType = (filters: GetNextOrderFilters) => Promise<Order>
export type GetNextFillFromOrderType = (
  order: Order,
  currentFill: Fill,
  fillStage?: string,
  lastSolvedTriageId?: string,
) => { nextFill?: RXFillRequest; stopNavigation?: boolean }

export type GetNextFillInfoType = (
  order: Order,
  status?: RxFillRequestStatus,
  fillId?: Fill['_id'],
) => FillInfoType | undefined

export const useGetNextOrder = (
  filters: GetNextOrderFilters,
  getData: GetDataType,
  fulfillmentQueueName: FulfillmentQueueName,
  fillStage?: string,
  rxFillRequestStatus?: RxFillRequestStatus,
  getNextFillFromOrder?: GetNextFillFromOrderType,
  getNextFillInfo?: GetNextFillInfoType,
): UseGetNextOrderType => {
  const {
    routeTo,
    tokenContext,
    currentLocation: { queryMap },
  } = usePlusClient()
  const showErrorToast = useErrorToast()
  const showInfoToast = useInfoToast()
  const showInfoToastRef = useAutoUpdatingRef(showInfoToast)
  const showErrorToastRef = useAutoUpdatingRef(showErrorToast)
  const streak = useRef(0)

  const getNextOrder = useCallback(
    (currentOrder?: Order, currentFill?: Fill, lastSolvedTriageId?: string) => {
      // Check for any remaining fills if the order is a batch
      // This only gets called for the second fill of a batch order
      if (currentFill && currentOrder) {
        const { nextFill, stopNavigation } = getNextFillFromOrder
          ? getNextFillFromOrder(currentOrder, currentFill, fillStage, lastSolvedTriageId)
          : getNextRxFillRequest(currentOrder, currentFill, fillStage, filters)

        // There's some situations that we want to stop the navigation, such as still
        // having order-level triages that were not solved
        if (stopNavigation) {
          return
        }

        if (nextFill) {
          streak.current += 1
          showInfoToastRef.current(`Another order is ready for your review. (Streak: ${streak.current})`)
          return routeTo
            .fulfillment(currentOrder._id, nextFill.fill._id, fulfillmentQueueName, {
              searchMap: {
                ...queryMap,
                ...lastFilterSet,
              },
            })
            .now()
        }
      }

      return (async () => {
        try {
          const variables = Object.keys(lastFilterSet).length
            ? lastFilterSet
            : {
                workflow: queryMap.workflow || undefined,
                ...filters,
              }

          const order = await getData(variables)
          // We know this endpoint only returns orders with valid fill statuses
          const nextFillInfoData: FillInfoType | undefined = getNextFillInfo
            ? getNextFillInfo(order, rxFillRequestStatus)
            : nextOrderFillWithStatus(order, rxFillRequestStatus, undefined, filters)
          streak.current += 1

          if (nextFillInfoData) {
            const { orderId, fillId } = nextFillInfoData
            showInfoToastRef.current(`Another order is ready for your review. (Streak: ${streak.current})`)
            routeTo
              .fulfillment(orderId, fillId, fulfillmentQueueName, {
                searchMap: {
                  ...lastFilterSet,
                },
              })
              .now()
          } else {
            showInfoToastRef.current('No more work found')
            routeTo
              .fulfillmentQueue(fulfillmentQueueName, {
                searchMap: {
                  ...queryMap,
                  locationId: tokenContext?.locationId,
                },
              })
              .now()
          }
        } catch (error) {
          showErrorToastRef.current('Failed to get next order: ' + (error as Error).message)
        }
      })()
    },
    [
      getNextFillFromOrder,
      fillStage,
      showInfoToastRef,
      routeTo,
      fulfillmentQueueName,
      queryMap.workflow,
      filters,
      getData,
      getNextFillInfo,
      rxFillRequestStatus,
      tokenContext?.locationId,
      showErrorToastRef,
    ],
  )

  const startWork = () => {
    lastFilterSet = {
      workflow: 'queue',
      ...filters,
    }

    return getNextOrder()
  }

  return { startWork, getNextOrder }
}
