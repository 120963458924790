const hyphenateNdc = (ndc: string): string => {
  if (ndc.length !== 11) {
    console.error('Ndc must be 11 digit long')
    return ndc
  }

  return `${ndc.slice(0, 5)}-${ndc.slice(5, 9)}-${ndc.slice(9, 11)}`
}

export default hyphenateNdc
