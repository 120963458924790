import type { ComponentClass } from 'react'
import { useEffect, useState } from 'react'
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg'
import DropDownContainer from 'components/DropDownContainer'
import IconWrapper from 'components/IconWrapper'
import type { CalendarProps } from 'grommet'
import { Calendar as BrokenTSDeclarationCalendar, MaskedInput } from 'grommet'
import moment from 'moment'
import styled, { css } from 'styled-components'
import { alertRed, primaryColor, borderColor, bodySecondaryColor, primaryBackgroundColor } from 'styles/styleVariables'

// Grommet type declaration is broken. Waiting for next release for this fix
const Calendar = BrokenTSDeclarationCalendar as ComponentClass<
  CalendarProps & Omit<JSX.IntrinsicElements['div'], 'onSelect'>
>

// The date picker only updates its onChange props when it has a valid date to
// offer, otherwise it displays an error. It does a lot of custom formatting as
// smartly as possible, only stepping in when the user has created a 'valid'
// date to format.
const DATE_INPUT_FORMAT = 'MM / DD / YYYY'
const today = moment().toDate()
const isValidDateString = (dateString: string): boolean => moment(dateString, DATE_INPUT_FORMAT, true).isValid()
const stringToDate = (dateString: string): Date => moment(dateString, DATE_INPUT_FORMAT).toDate()
const dateToString = (date: Date): string => moment(date).format(DATE_INPUT_FORMAT)
const dateToStringOrDefault = (defaultDate: Date, dateString: string) =>
  isValidDateString(dateString) ? dateString : dateToString(defaultDate)

interface Props {
  className?: string
  date?: Date
  onChange: (date?: Date) => void
  isPrivate?: boolean
  showPlaceholder?: boolean
  disabled?: boolean
  useCapsule?: boolean
}

const DatePicker = ({
  className,
  date = today,
  onChange,
  isPrivate = false,
  showPlaceholder = false,
  disabled = false,
  useCapsule = false,
}: Props): JSX.Element => {
  const [showCalendar, setShowCalendar] = useState(false)
  const [input, setInput] = useState<string>(date ? dateToString(date) : '')
  const [error, setError] = useState<boolean>(false)
  const [dateChanged, setDateChanged] = useState(false)

  useEffect(() => {
    setInput(dateToString(date))
  }, [date])

  const value = showPlaceholder && !dateChanged ? '' : input

  return (
    <InputContainer className={className} error={error} capsule={useCapsule} data-testid="date-container">
      <DateInput
        disabled={disabled}
        data-private={isPrivate}
        data-testid="date"
        mask={[
          {
            length: 2,
            regexp: /^[0-9]{0,2}$/,
            placeholder: 'MM',
          },
          { fixed: ' ' },
          { fixed: '/' },
          { fixed: ' ' },
          {
            length: 2,
            regexp: /^[0-9]{0,2}$/,
            placeholder: 'DD',
          },
          { fixed: ' ' },
          { fixed: '/' },
          { fixed: ' ' },
          {
            length: 4,
            regexp: /^[0-9]{0,4}$/,
            placeholder: 'YYYY',
          },
        ]}
        value={value}
        onChange={event => {
          const newDate = event.target.value
          const isValidDate = isValidDateString(newDate)

          setInput(newDate)
          setError(!isValidDate)
          setDateChanged(true)
          isValidDate && onChange(stringToDate(newDate))
        }}
      />
      <StyledIconWrapper
        data-testid="date-icon"
        open={showCalendar}
        disabled={disabled}
        onClick={() => {
          setShowCalendar(!showCalendar)
        }}
      >
        <CalendarIcon />
      </StyledIconWrapper>
      {showCalendar && (
        <DropDownContainer data-testid="date-dropdown" onClickOutside={() => setShowCalendar(false)}>
          <Calendar
            data-testid="date-calendar"
            date={dateToStringOrDefault(date, input)}
            size={'small'}
            range={false}
            onSelect={(selectedDates: string | string[]) => {
              setShowCalendar(false)
              const [firstSelectedDate] = Array.isArray(selectedDates) ? selectedDates : [selectedDates]
              const selectedDate = moment(firstSelectedDate)
              setInput(selectedDate.format(DATE_INPUT_FORMAT))
              onChange(selectedDate.toDate())
            }}
          />
        </DropDownContainer>
      )}
    </InputContainer>
  )
}

const InputContainer = styled.div<{ error?: boolean; capsule?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 0.125rem solid;
  ${({ error }) => (error ? ErrorState : NormalState)}
  background-color: ${primaryBackgroundColor};
  border-radius: 0.25rem;
  overflow: hidden;
  ${({ capsule }) => (capsule ? CapsuleStyle : StandardStyle)}
`

const CapsuleStyle = css`
  margin-top: 8px;
  --cap--select-field-outline: 1px solid var(--cap-colors-gray-700);
  --cap--select-field-outline-focus: 3px solid var(--cap-colors-primary-500);
  --cap--select-field-background: var(--cap-colors-white);
  font-size: var(--cap-fontSizes-md);
  width: 100%;
  height: 44px;
  background: var(--cap--select-field-background);
  padding: 0px 1rem;
  text-align: left;
  border: var(--cap--select-field-outline);
  border-radius: var(--cap-radii-sm);
`

const StandardStyle = css``

const HighlightedFill = css`
  fill: ${primaryColor};
`

const NormalFill = css`
  fill: ${bodySecondaryColor};
`

const DisabledIconStyle = css`
  opacity: 0.5;
  pointer-events: none;
`

const StyledIconWrapper = styled(IconWrapper)<{ open?: boolean; disabled?: boolean }>`
  cursor: pointer;
  margin-right: 0.625rem;
  ${({ disabled }) => disabled && DisabledIconStyle}
  > svg {
    ${({ open }) => (open ? HighlightedFill : NormalFill)}
  }
`

const ErrorState = css`
  border-color: ${alertRed};
`

const NormalState = css`
  border-color: ${borderColor};
`

const DateInput = styled(MaskedInput)`
  height: 1.64rem;
  font-size: 1rem;
  font-family: 'Roboto';
  padding-left: 0.625rem;
  border: none;
  width: 100%;

  :focus {
    outline-color: ${primaryColor};
  }
`

export default DatePicker
