export enum EscriptValidationIssueField {
  DAYS_SUPPLY = 'medicationPrescribed.daysSupply',
  PRODUCT_CODE = 'medicationPrescribed.drugCoded.productCode.code',
  PRESCRIBED_QUANTITY = 'medicationPrescribed.quantity.value',
  SIGNATURE = 'medicationPrescribed.sig',
  NOTE = 'medicationPrescribed.note',
}

export enum RxValidationIssueField {
  DAYS_SUPPLY = 'prescribed days supply',
  PRODUCT_CODE = 'prescribed ndc',
  PRESCRIBED_QUANTITY = 'quantity',
  SIGNATURE = 'signature',
  NOTE = 'note',
  DRUG_DESCRIPTION = 'drugDescription',
}

export const EscriptValidationIssueFieldToRxField = {
  [EscriptValidationIssueField.DAYS_SUPPLY]: RxValidationIssueField.DAYS_SUPPLY,
  [EscriptValidationIssueField.PRODUCT_CODE]: RxValidationIssueField.PRODUCT_CODE,
  [EscriptValidationIssueField.PRESCRIBED_QUANTITY]: RxValidationIssueField.PRESCRIBED_QUANTITY,
  [EscriptValidationIssueField.SIGNATURE]: RxValidationIssueField.SIGNATURE,
  [EscriptValidationIssueField.NOTE]: RxValidationIssueField.NOTE,
}
