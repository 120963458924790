import { Button } from '@truepill/react-capsule'
import { Link } from '@truepill/tpos-react-router'
import { LaunchDarkly } from '@truepill/tpos-types'
import { useFlag } from 'providers/LaunchDarklyProvider'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import { goToViewPharmacyPrescription } from 'routes'
import styled from 'styled-components'
import { capsuleDarkRedColor } from 'styles/styleVariables'
import type { OrderTriage } from 'types'
import ReviewDuplicatePrescriptionModal from './ReviewDuplicatePrescriptionModal'

interface ResolveLinkProps {
  duplicatePrescriptionIds: string[] // duplicates
  mainPrescriptionId: string // main rx id
  orderId: string
  triage: {
    prescriptionId: string
  } & Omit<OrderTriage, 'prescriptionId'>
  forceModal?: boolean
}

const ResolveLink = ({
  mainPrescriptionId,
  duplicatePrescriptionIds,
  triage,
  forceModal = false,
  orderId,
}: ResolveLinkProps) => {
  const shouldUseModal: boolean = useFlag(LaunchDarkly.FeatureFlags.TEMP_DUPLICATE_RX_MODAL) ?? false
  const { showModal } = useModalContext()

  if (shouldUseModal || forceModal) {
    return (
      <Button
        variant="primary-text"
        style={{ padding: '0', margin: '0 0.5rem' }}
        onClick={() =>
          showModal(() => (
            <ReviewDuplicatePrescriptionModal
              ids={duplicatePrescriptionIds}
              mainPrescriptionId={mainPrescriptionId}
              orderId={orderId}
            />
          ))
        }
      >
        Review duplicate prescription
      </Button>
    )
  }

  return (
    <StyledLink
      target="_blank"
      to={goToViewPharmacyPrescription({ prescriptionId: triage.prescriptionId })}
      duplicateRx
    >
      Review duplicate prescription
    </StyledLink>
  )
}

const StyledLink = styled(Link)<{
  duplicateRx?: boolean
}>`
  margin: 0 1rem;
  text-decoration: underline;
  color: ${({ duplicateRx }) => duplicateRx && capsuleDarkRedColor};
  :visited {
    color: ${({ duplicateRx }) => duplicateRx && capsuleDarkRedColor};
  }
`
export default ResolveLink
