import { ActionBar } from 'components/PageStructure'
import SearchInput from 'components/SearchInput'
import styled, { css } from 'styled-components'

const PagePadding = css`
  padding-right: 1.875rem;
`

const StyledActionBar = styled(ActionBar)`
  padding-left: 0;
`

const LoadingContainer = styled.div`
  width: 50px;
  margin: auto;
`

const SearchControls = styled.div`
  grid-column: meta;
  grid-row: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  max-width: 30rem;
`

const NewTeamMemberControls = styled.div`
  grid-column: actions;
  grid-row: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`

const StyledSearchInput = styled(SearchInput)`
  flex: 1;
  max-width: 21rem;
`

const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  ${PagePadding}
  > button {
    margin-right: 3.3rem;
  }
`

const Title = styled.h1`
  font-size: 24px;
  width: 100%;
  margin-bottom: 0.8rem;
  margin-top: 0.8rem;
  ${PagePadding}
`
export { LoadingContainer, SearchControls, NewTeamMemberControls, StyledSearchInput, Content, Title, StyledActionBar }
