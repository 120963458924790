import type { Ref } from 'react'
import { useMemo, useState, useEffect } from 'react'
import { useMutation, useQuery } from '@truepill/tpos-react-router'
import { ReactComponent as DangerIcon } from 'assets/icons/danger.svg'
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg'
import ActionButton, { SaveButton } from 'components/ActionButton'
import { RemoveButton } from 'components/CloseButton'
import { WideTextInput } from 'components/ColumnForm'
import IconWrapper from 'components/IconWrapper'
import { getReturnsByItems } from 'components/ItemReturnList'
import { ModalWrapper, ModalHeader, InputContainer, ButtonsContainer } from 'components/Modal'
import { NumericInput } from 'components/NumericInput'
import { FilledHeadingStyle, CancelButton } from 'components/PageStructure'
import Select from 'components/Select'
import TopBanner from 'components/TopBanner'
import { CREATE_SNAP_RETURN, GET_RETURN_ITEMS } from 'gql'
import useAutoFocus from 'hooks/useAutoFocus'
import useAutoUpdatingRef from 'hooks/useAutoUpdatingRef'
import useOrderReturnVariables from 'hooks/useOrderReturnVariables'
import type { OrderReturnVariables } from 'hooks/useOrderReturnVariables'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import ReturnDialogProvider, { useReturnDialogContext } from 'providers/ReturnDialogProvider'
import type { Items, AbstractOTCProduct, SelectedItem } from 'providers/ReturnDialogProvider'
import { isNil } from 'ramda'
import styled from 'styled-components'
import EllipsisTruncate from 'styles/EllipsisTruncate'
import { bodyPrimaryColor, primaryBackgroundColor, contrastBackgroundColor } from 'styles/styleVariables'
import { OTCOrderIssueReason, OTCOrderItemIssueReason, OTCShippingAction, OTCShippingPayment } from 'types'
import type { Order, OTCProduct, CustomerReturn, Full } from 'types'
import { usdFormatter, camelCaseToHumanReadable } from 'utils'

interface OrderReturnModalProps {
  completionCallback: () => void
  orderId: Order['_id']
}

type Option = { value: string; label: string }

const buildOptions = (obj: { [key: string]: string }): Option[] => {
  return Object.keys(obj).map(key => {
    const sentence = camelCaseToHumanReadable(key)
    sentence.toLowerCase()
    return { label: sentence, value: key }
  })
}

const issueReasons: Option[] = buildOptions(OTCOrderIssueReason)
const itemIssueReasons = [...buildOptions(OTCOrderItemIssueReason), ...issueReasons]

// These are temporarily set. We won't need them once replace and return comes
// online, at which point just pass OTCOrderIssueAction
const trimmedIssueActions = {
  refundToCard: 'REFUNDTOCARD',
}
const issueActions: Option[] = buildOptions(trimmedIssueActions)

// const issueShipping: Option[] = buildOptions(trimmedIssueShipingSpeeds)
// const issueShippingCharge: Option[] = buildOptions(OTCShippingCharge)
const issuesShippingAction: Option[] = buildOptions(OTCShippingAction)

/**
 * Extracts the quantity per Otc Product Id from the array of selected items.
 * @param otcProductsSelectedForRefund
 * @returns Object example = { 60f554afbbd968001b156c89: { qty:2, shippingCostRefunded: 0 } }
 */
const getQuantityByOtcIdFromReturnVariables = (otcProductsSelectedForRefund: OrderReturnVariables[]) => {
  const otcProductsQtySelected: { [k: string]: { qty: number; shippingCostRefunded: number } } = {}

  otcProductsSelectedForRefund.forEach(otcSelected => {
    otcSelected.otcProducts.forEach(otcProduct => {
      if (otcProductsQtySelected[otcProduct.otcId]) {
        otcProductsQtySelected[otcProduct.otcId].qty += otcProduct.quantity
      } else {
        otcProductsQtySelected[otcProduct.otcId] = {
          qty: otcProduct.quantity,
          shippingCostRefunded: otcSelected.shippingCostRefunded,
        }
      }
    })
  })

  return otcProductsQtySelected
}

/**
 * Extracts the quantity per Otc Product Id from the array of products previously returned or refunded.
 * @param otcProducsPreviouslyReturned
 * @returns Object example = { 60f554afbbd968001b156c89: { qty:2, shippingCostRefunded: 0 } }
 */
const getQuantityByOtcIdFromCustomerReturns = (otcProducsPreviouslyReturned: CustomerReturn[]) => {
  const otcProductsQty: { [k: string]: { qty: number; shippingCostRefunded: number } } = {}
  let currentReturn: string | null = null

  otcProducsPreviouslyReturned.forEach(otcCustomerReturn => {
    if (!currentReturn) currentReturn = otcCustomerReturn._id

    otcCustomerReturn.otcProducts.forEach(otcProduct => {
      if (otcProductsQty[otcProduct.otcProductId]) {
        otcProductsQty[otcProduct.otcProductId].qty += otcProduct.quantity
        if (currentReturn !== otcCustomerReturn._id) {
          currentReturn = otcCustomerReturn._id
          if (otcCustomerReturn.shippingPayment === OTCShippingPayment.PARTIAL_REFUND) {
            otcProductsQty[otcProduct.otcProductId].shippingCostRefunded += otcCustomerReturn.shippingCostRefunded || 0
          }
        }
      } else {
        otcProductsQty[otcProduct.otcProductId] = {
          qty: otcProduct.quantity,
          shippingCostRefunded: otcCustomerReturn.shippingCostRefunded || 0,
        }
      }
    })
  })

  return otcProductsQty
}

const getErrorsForBundleProducts = (
  bundleSelected: OrderReturnVariables,
  otcPreviouslyReturned: CustomerReturn[],
): string[] | null => {
  const errors: string[] = []
  let canBeRefunded = true
  if (otcPreviouslyReturned.length > 0) {
    const returnedQtyByOtcId = getQuantityByOtcIdFromCustomerReturns(otcPreviouslyReturned)
    bundleSelected.otcProducts.forEach(otcProduct => {
      if (otcProduct.quantity <= returnedQtyByOtcId[otcProduct.otcId].qty) {
        canBeRefunded = false
      }
    })

    if (!canBeRefunded) errors.push(`${bundleSelected.name} has already been returned or refunded`)
  }
  return errors
}

const getErrorsForEntireOrder = (otcPreviouslyReturned: CustomerReturn[], otcProducts: OTCProduct[]): string[] => {
  if (otcPreviouslyReturned.length > 0) {
    const errors: string[] = ['You cannot return or refund the full order because:']
    const products = otcProducts.reduce((otcNamesById: any, otcProduct: OTCProduct) => {
      return { ...otcNamesById, [otcProduct._id]: otcProduct.name || '' }
    }, {})

    const returnedQtyByOtcId = getQuantityByOtcIdFromCustomerReturns(otcPreviouslyReturned)
    let quantityOfProductsReturned = 0
    errors.push(
      ...Object.keys(returnedQtyByOtcId).map(otcId => {
        quantityOfProductsReturned += returnedQtyByOtcId[otcId].qty
        return `- ${returnedQtyByOtcId[otcId].qty} ${products[otcId]}`
      }),
    )

    if (quantityOfProductsReturned > 1) {
      errors.push('have already been returned or refunded')
    } else {
      errors.push('has already been returned or refunded')
    }
    return errors
  } else {
    return []
  }
}

function getAlreadyRefundedError(
  orderItems: Items,
  orderReturnVariables: OrderReturnVariables[],
  customerReturns: CustomerReturn[],
  otcProducts: OTCProduct[],
): string | undefined {
  const returnsByItems = getReturnsByItems(customerReturns)
  const otcPreviouslyReturned = getQuantityByOtcIdFromCustomerReturns(customerReturns)
  const quantityByOtcProduct = getQuantityByOtcIdFromReturnVariables(orderReturnVariables)

  const errors: string[] = []

  for (const orderReturn of orderReturnVariables) {
    //Validation for Entire Order refund
    if (orderReturn.isEntireOrder) {
      const entireOrderErrorMsgs = getErrorsForEntireOrder(customerReturns, otcProducts)
      if (entireOrderErrorMsgs) errors.push(...entireOrderErrorMsgs)
      continue
    }

    //Validation for bundles
    if (orderReturn.isABundle) {
      const errorMsg = getErrorsForBundleProducts(orderReturn, customerReturns)
      if (errorMsg) errors.push(...errorMsg)
      continue
    }

    //Validation for loose items
    for (const otcProduct of orderReturn.otcProducts) {
      const { otcId } = otcProduct
      const itemReturns = returnsByItems[otcId]
      const item = orderItems[otcId]
      if (itemReturns) {
        const numberOfItemReturnedOrRefunded: number = itemReturns.reduce((acc: number, customerReturn) => {
          for (const otcProduct of customerReturn.otcProducts) {
            if (otcProduct.otcProductId === otcId) {
              acc += otcProduct.quantity
            }
          }
          return acc
        }, 0)

        if (numberOfItemReturnedOrRefunded >= item.quantity) {
          const haveHas = (item.quantity > 1 ? 'have' : 'has') + ' already been returned or refunded.'
          errors.push(`${numberOfItemReturnedOrRefunded}/${item.quantity} of ${item.name} ${haveHas}`)
        } else if (numberOfItemReturnedOrRefunded + quantityByOtcProduct[otcId].qty > item.quantity) {
          const maxRefundableProducts = item.quantity - numberOfItemReturnedOrRefunded
          const returned = 'already been returned or refunded. You can only refund up to'
          errors.push(
            `${numberOfItemReturnedOrRefunded} of ${item.quantity} ${item.name} ${returned} ${maxRefundableProducts}.`,
          )
        } else if (
          orderReturn.shippingPayment !== OTCShippingPayment.DO_NOT_REFUND &&
          orderReturn.shippingCostRefunded + otcPreviouslyReturned[otcId].shippingCostRefunded > item.shippingCost
        ) {
          const maxRefundableShippingAmount = item.shippingCost - otcPreviouslyReturned[otcId].shippingCostRefunded
          if (maxRefundableShippingAmount <= 0) {
            errors.push(`${item.name}: The shipping cost for this item has been already fully refunded.`)
          } else {
            errors.push(`${item.name}: The maximum refund for shipping is ${maxRefundableShippingAmount}.`)
          }
        }
      } else {
        if (otcProduct.quantity > item.quantity) {
          errors.push(`For ${item.name} you can only refund up to ${item.quantity}.`)
        }
      }
      // if the OTC Product has a return already set for it
    }
  }
  if (errors.length) {
    return errors.join('\n')
  }
}

const OrderReturnModal = (props: OrderReturnModalProps) => {
  const [submitErrorMessage, setSubmitErrorMessage] = useState<string | undefined>()
  const { orderId, completionCallback } = props

  const { dismissModal } = useModalContext()

  const { data, error } = useQuery(GET_RETURN_ITEMS, {
    variables: { orderId },
  })

  const order = data && data.getOrder

  const { changeItems, changeQuantity, items, selectedItems } = useReturnDialogContext()

  const changeItemsRef = useAutoUpdatingRef(changeItems)
  const changeQuantityRef = useAutoUpdatingRef(changeQuantity)

  const [createReturn] = useMutation(CREATE_SNAP_RETURN)

  // We need to init the context reducer as well as add a blank row
  // to get us started
  useEffect(() => {
    if (order) {
      const abstractItems = order.otcProducts.map((otcProduct: OTCProduct) => {
        return {
          _id: otcProduct._id,
          quantity: otcProduct.quantity,
          name: otcProduct.name || otcProduct.sku,
          bundleId: otcProduct.bundleId,
          bundleItemPartialReturnAllowed: otcProduct.bundleItemPartialReturnAllowed,
          unitCost: otcProduct?.pricing?.cost || 0,
          type: 'item',
          discount: otcProduct?.pricing?.discount || 0,
          tax: otcProduct?.pricing?.tax || 0,
          total: otcProduct?.pricing?.total || 0,
          shippingCost: otcProduct?.pricing.shippingCost || 0,
        }
      })

      // sort by bundle id
      abstractItems.sort((a: AbstractOTCProduct, b: AbstractOTCProduct) => {
        const aBundleId = a.bundleId || ''
        const bBundleId = b.bundleId || ''
        if (aBundleId > bBundleId) {
          return 1
        } else if (aBundleId < bBundleId) {
          return -1
        } else {
          return 0
        }
      })

      // Entire Order is added as a means for CS agents to return the whole order
      // instead of an exhaustive list of items
      const entireOrder: AbstractOTCProduct = {
        _id: 'entireOrder',
        type: 'order',
        quantity: 1,
        name: 'Entire Order',
        unitCost: order.pricing?.total || 0,
        discount: 0,
        action: '',
        tax: 0, //order.pricing?.tax || 0
        total: 0, //order.pricing?.total || 0
        shippingCost: 0,
      }
      const newItems: AbstractOTCProduct[] = [entireOrder]
      let currentBundleId: string | null = null
      // We also allow (and sometimes mandate), the returning of an entire
      // bundle instead of individual items
      let currentBundle: AbstractOTCProduct | undefined
      let accumulatedPrice = 0
      for (let i = 0; i < abstractItems.length; i++) {
        const item = abstractItems[i]
        if (currentBundleId !== item.bundleId) {
          if (currentBundle) {
            currentBundle.unitCost = accumulatedPrice
            accumulatedPrice = 0
          }
          currentBundleId = item.bundleId
          currentBundle = {
            _id: item.bundleId,
            type: 'bundle',
            discount: 0,
            action: '',
            tax: 0,
            quantity: 1,
            name: '- ' + item.bundleId + ' (Bundle)',
            unitCost: 0,
            total: 0,
            shippingCost: 0,
          }
          newItems.push(currentBundle)
        }
        if (currentBundle) {
          currentBundle.unitCost += item.total // item.unitCost * item.quantity
          currentBundle.total += item.total
          currentBundle.tax += item.tax
          currentBundle.shippingCost += item.shippingCost
        }

        entireOrder.unitCost += item.total // item.unitCost * item.quantity
        entireOrder.total += item.total
        entireOrder.tax += item.tax
        entireOrder.shippingCost += item.shippingCost
        if (!item.bundleId) {
          item.name = '- ' + item.name
          newItems.push(item)
        } else if (item.bundleItemPartialReturnAllowed) {
          item.name = '-- ' + item.name
          newItems.push(item)
        }
      }

      changeItemsRef.current(newItems)
      changeQuantityRef.current('placeholder', 0, 'Starter')
    }
  }, [order, changeItemsRef, changeQuantityRef])

  const errorMessage = error ? error.message : submitErrorMessage

  const totalRefunded = useMemo(() => {
    return selectedItems.reduce((acc: number, selection) => {
      const { _id, quantity } = selection
      const item = items[_id]
      if (item) {
        // const { unitCost } = items[_id]
        const unitCost = items[_id].total / items[_id].quantity
        acc += unitCost * quantity
      }
      return acc
    }, 0)
  }, [selectedItems, items])

  const orderReturnVariables = useOrderReturnVariables(selectedItems as Full<SelectedItem>[], items, order)

  const alreadyRefundedError =
    order && getAlreadyRefundedError(items, orderReturnVariables, order.customerReturns || [], order.otcProducts)

  const canSubmit =
    !alreadyRefundedError &&
    orderReturnVariables.length &&
    orderReturnVariables.every(
      orderReturn =>
        orderReturn.action &&
        orderReturn.reason &&
        orderReturn.otcProducts &&
        orderReturn.otcProducts.length &&
        orderReturn.shippingPayment,
    )

  return (
    <ModalWrapper id="OrderReturnModal">
      <StyledModalHeader>
        <IconWrapper>
          <DangerIcon fill={bodyPrimaryColor} />
        </IconWrapper>
        <h2>Issue</h2>
      </StyledModalHeader>
      <InputContainer>
        <TopBanner message={alreadyRefundedError || errorMessage} />
        <HeaderRow>
          <Item>
            <Truncatable>Item</Truncatable>
          </Item>
          <Quantity>
            <Truncatable>Quantity</Truncatable>
          </Quantity>
          <UnitCost>
            <Truncatable>Unit Cost</Truncatable>
          </UnitCost>
          <Discount>
            <Truncatable>Discount</Truncatable>
          </Discount>
          <Tax>
            <Truncatable>Tax</Truncatable>
          </Tax>
          <ShippingCost>
            <Truncatable>Shipping Cost</Truncatable>
          </ShippingCost>
          <ShippingToRefund>
            <Truncatable>Shipping To Refund</Truncatable>
          </ShippingToRefund>
          <Subtotal>
            <Truncatable>Subtotal</Truncatable>
          </Subtotal>
          <Reason>
            <Truncatable>Reason</Truncatable>
          </Reason>
          <Actions>
            <Truncatable>Actions</Truncatable>
          </Actions>
          {/* <Shipping>
            <Truncatable>Shipping</Truncatable>
          </Shipping>
          <ShippingCharge>
            <Truncatable>Shipping Charge</Truncatable>
          </ShippingCharge> */}
          <ShippingAction>
            <Truncatable>Shipping Action</Truncatable>
          </ShippingAction>
        </HeaderRow>
        {selectedItems.map((selection, i) => (
          <SelectItemRow key={selection.selectionId} index={i} selection={selection} />
        ))}
        <BottomRow>
          <AddEntryButton />
          <RefundTotal>
            <p>Total</p>
            <p>{usdFormatter.format(totalRefunded)}</p>
          </RefundTotal>
        </BottomRow>
      </InputContainer>
      <StyledButtonsContainer>
        <CancelButton label={'Cancel'} onClick={dismissModal} />
        <SaveButton
          isModal
          label={'Process issue'}
          disabled={!canSubmit}
          onClick={() => {
            ;(async () => {
              try {
                for (const variables of orderReturnVariables) {
                  await createReturn({
                    variables,
                    refetchQueries: ['getItemReturnListQuery', 'orderReturnModalQuery'],
                  })
                }
                completionCallback()
                dismissModal()
              } catch (e) {
                setSubmitErrorMessage(e.message)
                console.error(e)
              }
            })()
          }}
        />
      </StyledButtonsContainer>
    </ModalWrapper>
  )
}

const BottomRow = styled.div`
  margin-top: 0.625rem;
  display: flex;
  width: 50%;
  justify-content: space-between;
  align-items: center;
`

const RefundTotal = styled.span`
  font-weight: 500;
  display: flex;
  > :first-child {
    margin-right: 2rem;
  }
`

const AddEntryButton = () => {
  const { changeQuantity } = useReturnDialogContext()

  return (
    <StyledActionButton
      onClick={() => {
        changeQuantity('placeholder', 1, `${new Date().getTime()}`)
      }}
      icon={
        <IconWrapper>
          <PlusIcon fill={'black'} />
        </IconWrapper>
      }
      label={'Add a row'}
    />
  )
}

const SelectItemRow = (props: { selection?: SelectedItem; index?: number }) => {
  const { selection, index = 0 } = props

  const {
    items,
    availableItems,
    changeQuantity,
    // changeShippingCharge,
    // changeShippingSpeed,
    changeShippingPayment,
    changeAction,
    changeReason,
    changeOtherReason,
    removeSelection,
  } = useReturnDialogContext()

  const itemOptions = availableItems.map(({ _id, name }) => {
    return { label: name, value: _id }
  })

  const autoFocusRef = useAutoFocus()

  const selectedItem = useMemo(() => {
    if (selection) {
      const item = items[selection._id]
      if (!item) {
        return
      }
      return { label: item.name, value: item._id }
    }
  }, [selection, items])

  // const unitCost = (selection && items[selection._id]?.unitCost) || 0
  let disableQuantity = false

  if (selection && (items[selection._id]?.type === 'order' || items[selection._id]?.type === 'bundle')) {
    if (selection?.quantity === 1) disableQuantity = true
  } else if (!selection) {
    disableQuantity = true
  }

  let discount = (selection && items[selection._id]?.discount) || 0
  if (discount > 0 && selection) discount = (discount / items[selection._id]?.quantity) * selection?.quantity || 0

  let tax = (selection && items[selection._id]?.tax) || 0
  if (tax > 0 && selection) tax = (tax / items[selection._id]?.quantity) * selection?.quantity || 0

  const unitCost = (selection && items[selection._id]?.total / items[selection._id]?.quantity) || 0
  const quantity = selection?.quantity || 0

  const shippingCost = (selection && items[selection._id]?.shippingCost) || 0

  const itemReason = selection?.reason ? itemIssueReasons.find(({ value }) => value === selection?.reason) : undefined

  const action = selection?.action ? issueActions.find(({ value }) => value === selection?.action) : undefined

  // const shippingSpeed = selection?.shippingSpeed
  //   ? issueShipping.find(({ value }) => value === selection?.shippingSpeed)
  //   : undefined

  // const shippingCharge = selection?.shippingCharge
  //   ? issueShippingCharge.find(({ value }) => value === selection?.shippingCharge)
  //   : undefined

  const shippingPayment = selection?.shippingPayment
    ? issuesShippingAction.find(({ value }) => value === selection?.shippingPayment)
    : undefined

  let shippingToRefund = 0
  if (shippingPayment) {
    if (shippingPayment.value === OTCShippingPayment.REFUND) {
      shippingToRefund = shippingCost
    } else if (shippingPayment.value === OTCShippingPayment.PARTIAL_REFUND) {
      shippingToRefund = (shippingCost / items[selection?._id || '']?.quantity) * quantity
    }
  }

  return (
    <ItemRow>
      {selection && (
        <StyledRemoveButton contrast={index % 2 === 0} clickCallback={() => removeSelection(selection?.selectionId)} />
      )}
      <Item>
        <ItemRowSelect
          ref={autoFocusRef as Ref<HTMLButtonElement>}
          disableClear
          placeholder={'Select an item…'}
          modal
          options={itemOptions}
          value={selectedItem}
          onChange={sel => {
            if (sel?.length > 0) {
              const [{ value }] = sel
              changeQuantity(value as string, 1, selection?.selectionId || `${new Date().getTime()}`)
            }
          }}
        />
      </Item>
      <Quantity>
        <QuantityInput
          fadeOnZeroValue={!selection}
          value={selection?.quantity}
          disabled={disableQuantity}
          onChange={val => {
            if (!selection) {
              throw new Error('Attempted to change quantity without a product selected')
            }
            if (!isNil(val)) changeQuantity(selection._id, val, selection.selectionId)
          }}
        />
      </Quantity>
      <UnitCost>{usdFormatter.format(unitCost)}</UnitCost>
      <Discount>{usdFormatter.format(discount)}</Discount>
      <Tax>{usdFormatter.format(tax)}</Tax>
      <ShippingCost>{usdFormatter.format(shippingCost)}</ShippingCost>
      <ShippingToRefund>{usdFormatter.format(shippingToRefund)}</ShippingToRefund>
      <Subtotal>{usdFormatter.format(unitCost * quantity)}</Subtotal>
      <Reason>
        <ItemRowSelect
          disableClear
          placeholder={'Select a reason…'}
          modal
          options={itemIssueReasons}
          value={itemReason}
          onChange={([newReason]) => {
            if (!selection) {
              throw new Error('Attempted to change reason without a product selected')
            }
            if (newReason) {
              const { value } = newReason
              changeReason(selection._id, selection.selectionId, value as string)
            } else {
              changeReason(selection._id, selection.selectionId, undefined)
            }
          }}
        />
      </Reason>
      <Actions>
        <ItemRowSelect
          disableClear
          placeholder={'Select an action…'}
          modal
          options={issueActions}
          value={action}
          onChange={([newAction]) => {
            if (selection?._id === 'placeholder') {
              return
            }
            if (newAction) {
              const { value } = newAction

              // if (!actionRequiresShipping(value as string)) {
              //   changeShippingSpeed(selection._id, selection.selectionId)
              //   changeShippingCharge(selection._id, selection.selectionId)
              //   changeShippingPayment(selection._id, selection.selectionId)
              // }
              changeAction(selection?._id || '', selection?.selectionId || '', value as string)
            } else {
              changeAction(selection?._id || '', selection?.selectionId || '', undefined)
            }
          }}
        />
      </Actions>
      <ShippingAction>
        <ItemRowSelect
          disableClear
          placeholder={'Select shipping action…'}
          modal
          options={issuesShippingAction}
          value={shippingPayment}
          onChange={([newShippingAction]) => {
            if (selection?._id === 'placeholder') {
              return
            }
            if (newShippingAction && selection) {
              const { value } = newShippingAction
              changeShippingPayment(selection._id, selection.selectionId, value as string)
              // changeShippingSpeed(selection._id, selection.selectionId, value as string)
            } else {
              changeShippingPayment(selection?._id || '', selection?.selectionId || '', undefined)
              // changeShippingSpeed(selection._id, selection.selectionId, undefined)
            }
          }}
        />
      </ShippingAction>
      {/* <ShippingCharge>
        {!action || actionRequiresShipping(action.value) ? (
          <ItemRowSelect
            disableClear
            placeholder={'Select shipping charge…'}
            modal
            options={issueShippingCharge}
            value={shippingCharge}
            onChange={([ newShippingCharge ]) => {
              if (!selection) {
                throw new Error('Attempted to change Shipping Charge without a product selected')
              }
              if (newShippingCharge) {
                const { value } = newShippingCharge
                changeShippingCharge(selection._id, selection.selectionId, value as string)
              } else {
                changeShippingCharge(selection._id, selection.selectionId, undefined)
              }
            }}
          />
        ) : (
          <p>N/A</p>
        )}
      </ShippingCharge> */}
      {OTCOrderIssueReason[itemReason?.value as keyof typeof OTCOrderIssueReason] === OTCOrderIssueReason.other && (
        <OtherReason>
          <SlimWideTextInput
            placeholder={'Enter other reason…'}
            value={selection?.otherReason}
            onChange={e => {
              if (!selection) {
                throw new Error('Attempted to change other reason without a product selected')
              }
              changeOtherReason(selection._id, selection.selectionId, e.target.value)
            }}
          />
        </OtherReason>
      )}
    </ItemRow>
  )
}

const WrappedOrderReturnModal = (props: OrderReturnModalProps): JSX.Element => (
  <ReturnDialogProvider>
    <OrderReturnModal {...props} />
  </ReturnDialogProvider>
)

const QuantityInput = styled(NumericInput)`
  width: 5rem;
`

const StyledActionButton = styled(ActionButton)`
  background-color: ${primaryBackgroundColor};
  grid-row: 1;
  grid-column: buttons;
`

const StyledModalHeader = styled(ModalHeader)`
  margin-bottom: 1rem;
`

const StyledButtonsContainer = styled(ButtonsContainer)`
  margin-bottom: 0.625rem;
`

const ItemRowSelect = styled(Select)`
  width: 100%;
  min-height: 2rem;
`

const ItemRow = styled.ul`
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  grid-column: content;
  grid-template-rows: [content] 2.5rem [subContent] auto;
  grid-template-columns:
    [removeButton] 1.8rem
    [item] minmax(13rem, 1fr)
    [quantity] minmax(6rem, 8rem)
    [unitCost] minmax(3rem, 8rem)
    [discount] minmax(3rem, 8rem)
    [tax] minmax(3rem, 8rem)
    [shippingCost] minmax(3rem, 8rem)
    [shippingToRefund] minmax(3rem, 10rem)
    [subtotal] minmax(3rem, 8rem)
    [reason] minmax(6rem, 10rem)
    [actions] minmax(6rem, 10rem)
    [shippingAction] 10rem;
  :nth-of-type(2n + 2) {
    background-color: ${contrastBackgroundColor};
  }
  display: grid;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
`

const ItemRowCell = styled.li`
  overflow: hidden;
  grid-row: 1;
  ${EllipsisTruncate}
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 0.3125rem;
  padding-right: 0.3125rem;
`

const Item = styled(ItemRowCell)`
  grid-column: item;
  overflow: visible;
`

const Quantity = styled(ItemRowCell)`
  grid-column: quantity;
`

const UnitCost = styled(ItemRowCell)`
  grid-column: unitCost;
`

const Discount = styled(ItemRowCell)`
  grid-column: discount;
`

const Tax = styled(ItemRowCell)`
  grid-column: tax;
`

const Subtotal = styled(ItemRowCell)`
  grid-column: subtotal;
`

const Reason = styled(ItemRowCell)`
  grid-column: reason;
  overflow: visible;
`

const Actions = styled(ItemRowCell)`
  grid-column: actions;
  overflow: visible;
`

// const ShippingCharge = styled(ItemRowCell)`
//   grid-column: shippingCharge;
//   overflow: visible;
// `

// const Shipping = styled(ItemRowCell)`
//   grid-column: shipping;
//   overflow: visible;
// `

const ShippingCost = styled(ItemRowCell)`
  grid-column: shippingCost;
`

const ShippingToRefund = styled(ItemRowCell)`
  grid-column: shippingToRefund;
`

const ShippingAction = styled(ItemRowCell)`
  grid-column: shippingAction;
  overflow: visible;
`

const OtherReason = styled(ItemRowCell)`
  grid-column: reason / span 4;
  grid-row: subContent;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
`

const HeaderRow = styled(ItemRow)`
  ${FilledHeadingStyle}
  padding-left: 0.625rem;
  padding-right: 0.625rem;
`

const SlimWideTextInput = styled(WideTextInput)`
  height: 2rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
`

const Truncatable = styled.p`
  ${EllipsisTruncate}
`

const StyledRemoveButton = styled(RemoveButton)`
  grid-column: removeButton;
  align-self: center;
  justify-self: flex-start;
  position: relative;
  margin-left: 0.625rem;
  width: 1.5rem;
  height: 1.5rem;
`

export default WrappedOrderReturnModal
