import { CopayStatus, RxFillRequestStatus } from '@truepill/tpos-types'
import { ReactComponent as TimeIcon } from 'assets/icons/time.svg'
import ActionButton from 'components/ActionButton'
import IconWrapper from 'components/IconWrapper'
import EligibiltyCheckModal from 'modals/EligibilityCheckModal'
import EligibilityResponseModal from 'modals/EligibilityResponseModal'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import { bodyPrimaryColor } from 'styles/styleVariables'
import type { Fill, Patient, QueueItem } from 'types'
import { getItemFill, isCopayRequest, isOrder } from 'utils'

type EligibilityCheckActionButtonProps = {
  item: QueueItem
  fill: Fill
  patient: Patient
  isPriorAuthorization: boolean
  isManualPriorAuth: boolean
  disabled?: boolean
}

export const EligibilityCheckActionButton = ({
  item,
  fill,
  patient,
  isPriorAuthorization,
  isManualPriorAuth,
  disabled = false,
}: EligibilityCheckActionButtonProps): JSX.Element => {
  const { showModal } = useModalContext()

  const isComplete = isOrder(item)
    ? getItemFill(item, fill._id)?.status === RxFillRequestStatus.Complete
    : getItemFill(item, fill._id)?.status === CopayStatus.Complete

  const isCompleteCopay = isCopayRequest(item) && isComplete

  if (isPriorAuthorization || isManualPriorAuth || isCompleteCopay) {
    return <></>
  }

  const isOrderItem = isOrder(item)

  return (
    <ActionButton
      disabled={disabled}
      icon={
        <IconWrapper>
          <TimeIcon fill={bodyPrimaryColor} />
        </IconWrapper>
      }
      label={'Eligibility Check'}
      onClick={() =>
        showModal(() => (
          <EligibiltyCheckModal
            fillId={fill._id}
            patientId={patient._id}
            itemId={item._id}
            itemType={isOrderItem ? 'order' : 'copay'}
            confirmationCallback={data => {
              showModal(() => <EligibilityResponseModal data={data} item={item} />)
            }}
          />
        ))
      }
    />
  )
}
