export const primaryColor = '#44bdd0'
export const primaryColorLight = '#e3f5f8'
export const primaryColorDark = '#44bcdf'
export const bodyPrimaryColor = '#000000'
export const bodySecondaryColor = '#aaaaaa'
export const capsuleBodySecondaryColor = '#13171D'
export const contrastColor = '#212d41'
export const capsuleContrastColor = '#234D83'
export const borderColor = '#cccccc'
export const accentPink = '#d82184'
export const accentPurple = '#a7449c'
export const primaryActiveButtonBackground = 'rgba(63, 190, 210, 1.00)'
export const primaryBackgroundColor = '#FFFFFF'
export const offsetBackgroundColor = '#efefef'
export const contrastBackgroundColor = '#EEEEEE'
export const contrastIconButtonColor = '#C4C4C4'
export const greyPaging = '#989898'
export const subduedColor = '#afbcc6'
export const alertRed = '#da3d3d'
export const errorRed = '#DE3702'
export const errorLight = '#F8D7CC'
export const errorDark = '#C3372E'
export const errorBorderShadow = '#ebb19d'
export const warningYellow = '#FFB803'
export const confirmColor = '#11CAB4'
export const confirmLight = '#CFF4F0'
export const highlightPrimaryColor = '#8FD7E3'
export const highlightPrimaryColorTransparent = 'rgba(143, 215, 227, 0.80)'
export const borderColorTransparent = 'rgba(255, 255, 255, 0.50)'
export const NAV_MENU_BREAKPOINT = '1050px'
export const darkBlue = '#194D83'
export const darkInfo = '#006FC3'
export const lightInfo = '#E3F4FF'
export const btnPrimary = '#034D83'
export const darkGreen = '#0E7016'
export const lightOrange = '#FFF6E8'
export const darkOrange = '#AF5304'
export const capsuleRed = '#E81A33'
export const lightRed = '#FF0000'

// capsule
export const capsulePrimaryColor = '#007ad1'
export const capsulePrimaryColorLight = '#e3f4ff'
export const capsulePrimaryColorDark = '#034d83'
export const capsuleBorderColor = '#4e5d70'
export const capsuleDarkBlue = '#034D83'
export const capsuleGrayColor = '#F9FAFB'
export const capsuleGreyColor = '#535762'
export const capsuleLightGreyColor = '#9EACBC'
export const capsuleDarkGreyColor = '#13171C'
export const capsuleDarkGreenColor = '#0E7016'
export const capsuleLightGreenColor = '#E6FFE9'
export const capsuleDarkGrayColor = '#8F8F8F'
export const capsuleDarkBlueColor = '#007AD1'
export const capsuleDarkRedColor = '#C3362E'
export const capsuleLightBlueColor = '#DBF0FF'
export const capsuleLightRedColor = '#FFF1F1'

// e.g. @media screen (min-width: ${media.lg})
export const media = {
  sm: '576px',
  md: '768px',
  lg: '992px', // if nav menu changes names, additional items this might need to be updated
  xl: '1200px',
  pageWidth: '1450px',
}

export const color = {
  primary: primaryColor,
  primaryLight: primaryColorLight,
  bodyPrimary: bodyPrimaryColor,
  bodySecondary: bodySecondaryColor,
  contrast: contrastColor,
  border: borderColor,
  background: primaryBackgroundColor,
  offsetBackground: offsetBackgroundColor,
  subdued: subduedColor,
}

export const text = {
  primaryColor: primaryColor,
  font: 'Roboto',
}

export const size = {
  pageWidth: 1450,
}
