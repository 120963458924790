import { FilledHeader } from 'components/PageStructure'
import type { Prescriber, Log } from 'types'
import DemographicBlock from './components/DemographicBlock'
import Notes from './components/Notes'
import { RXPrescriberDataContainer, RXDataContainer, RXDataColumn } from './StyledComponents'

type PrescriberProfileType = { prescriber: Prescriber; isVet: boolean; notes: Log[] }

const PrescriberProfile = ({ prescriber, isVet, notes }: PrescriberProfileType): JSX.Element => {
  return (
    <RXPrescriberDataContainer>
      <RXDataContainer>
        <RXDataColumn>
          <FilledHeader>Demographic</FilledHeader>
          <DemographicBlock isVet={isVet} prescriber={prescriber} />
        </RXDataColumn>
        <RXDataColumn>
          <FilledHeader>Critical notes</FilledHeader>
          <Notes notes={notes} />
        </RXDataColumn>
      </RXDataContainer>
    </RXPrescriberDataContainer>
  )
}

export default PrescriberProfile
