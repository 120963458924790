import { ReactComponent as TransferIcon } from 'assets/icons/transfer-in.svg'
import { SaveButton } from 'components/ActionButton'
import { FormColumn, FormItems, FormItem } from 'components/ColumnForm'
import IconWrapper from 'components/IconWrapper'
import { ModalWrapper, ModalHeader, InputContainer, ButtonsContainer } from 'components/Modal'
import { CancelButton } from 'components/PageStructure'
import SegmentedSelectButton from 'components/SegmentedSelectButton'
import { TextArea } from 'grommet'
import { useFormData } from 'hooks/useFormData'
import PharmacySection from 'modals/sections/PharmacySection'
import PrescriberSection from 'modals/sections/PrescriberSection'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import styled from 'styled-components'
import { bodyPrimaryColor } from 'styles/styleVariables'
import type { TransferRequestFormData } from 'types'

const useValidFormData = () => {
  const {
    state: { formData },
  } = useFormData()
  const { pharmacy, faxNumber, prescriberId, transferTarget, contactMethod, medication, spi } = formData.transferRequest
  if (!medication || medication.length === 0) {
    return false
  }

  if (pharmacy && transferTarget === 'pharmacy' && faxNumber) {
    return { pharmacy, faxNumber, medication }
  }

  if (prescriberId && contactMethod === 'fax' && faxNumber) {
    return { prescriberId, faxNumber, medication }
  }

  if (prescriberId && contactMethod === 'electronic' && spi) {
    return { prescriberId, spi, medication }
  }

  return false
}

const TransferRequestModal = (): JSX.Element => {
  const { dismissModal } = useModalContext()
  const {
    actions: { updateFormData },
    state: { formData },
  } = useFormData()
  const { transferTarget, medication } = formData.transferRequest

  const validFormData = useValidFormData()

  return (
    <ModalWrapper id="TransferRequestModal">
      <ModalHeader>
        <IconWrapper>
          <TransferIcon fill={bodyPrimaryColor} />
        </IconWrapper>
        <h2>Transfer request</h2>
      </ModalHeader>
      <StyledInputContainer>
        <FormColumn>
          <FormItems>
            <FormItem>
              <p>Recipient</p>
              <SegmentedSelectButton
                options={[
                  { id: 'prescriber', label: 'Prescriber' },
                  { id: 'pharmacy', label: 'Pharmacy' },
                ]}
                selectionChanged={newTarget => {
                  updateFormData({
                    transferRequest: {
                      transferTarget: { $set: newTarget as TransferRequestFormData['transferTarget'] },
                    },
                  })
                }}
              />
            </FormItem>
            {transferTarget === 'prescriber' && <PrescriberSection nameSpace={'transferRequest'} />}
            {transferTarget === 'pharmacy' && <PharmacySection nameSpace={'transferRequest'} />}
            <FormItem>
              <p>Medication</p>
              <TextArea
                placeholder={'Enter medication...'}
                value={medication}
                resize={'vertical'}
                onChange={e => {
                  updateFormData({
                    transferRequest: {
                      medication: { $set: e.target.value },
                    },
                  })
                }}
              />
            </FormItem>
          </FormItems>
        </FormColumn>
      </StyledInputContainer>
      <ButtonsContainer>
        <CancelButton label={'Cancel'} onClick={dismissModal} />
        <SaveButton
          isModal
          disabled={!validFormData}
          label={'Submit'}
          onClick={() => {
            if (validFormData) {
              // Submit the new data
              dismissModal()
            }
          }}
        />
      </ButtonsContainer>
    </ModalWrapper>
  )
}

const StyledInputContainer = styled(InputContainer)`
  width: 25rem;
`

export default TransferRequestModal
