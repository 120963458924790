import { useEffect, useState } from 'react'
import { Select } from '@truepill/react-capsule'
import type { ApolloError } from '@truepill/tpos-react-router'
import { ProviderType } from '@truepill/tpos-types'
import { FormItem, FormItems, CompoundFormLine } from 'components/ColumnForm'
import CustomizedTextField from 'components/CustomizedTextField'
import usePrescriberFormValidation from 'hooks/usePrescriberFormValidation'
import styled from 'styled-components'
import useCheckDuplicatedPrescriber from './useCheckDuplicatedPrescriber'

export enum PrescriberFormField {
  Npi = 1,
  Dea = 2,
  FirstName = 3,
  LastName = 4,
  Suffix = 5,
  StateLicense = 6,
}

const PrescriberTypeOptions = ['(~) Clear', ProviderType.MD, ProviderType.DO, ProviderType.NP, ProviderType.DVM]

export type ValidationStatus = {
  isPrescriberDuplicated: boolean
  loading: boolean
  error?: ApolloError
}

export type StateLicense = {
  number: string
  state: string
}

interface Props {
  npi?: string
  dea?: string
  firstName?: string
  lastName?: string
  suffix?: string
  stateLicense?: StateLicense
  onChange: (field: PrescriberFormField, value: string | undefined) => void
  onChangeValidationStatus: (status: ValidationStatus) => void
}

const NewPrescriberForm = ({
  npi,
  dea,
  firstName,
  lastName,
  suffix,
  stateLicense,
  onChange,
  onChangeValidationStatus,
}: Props): JSX.Element => {
  const { isPrescriberDuplicated, loading, error } = useCheckDuplicatedPrescriber({
    npi,
    stateLicense: stateLicense?.number,
    stateLicenseState: stateLicense?.state,
  })
  const { errors } = usePrescriberFormValidation({
    npi,
    firstName,
    lastName,
    suffix,
    stateLicense: stateLicense?.number,
  })
  const [showErrors, setShowErrors] = useState<Record<string, boolean>>({})

  useEffect(() => {
    onChangeValidationStatus({ isPrescriberDuplicated, loading, error })
  }, [isPrescriberDuplicated, loading, error])

  return (
    <FormItems data-testid="PrescriberForm">
      <FormItem data-test-row="provider-type">
        <SelectWrapper>
          <Select
            data-testid="providerType"
            label="Provider Type"
            variant="small"
            value={suffix || ''}
            state={errors.suffix ? 'error' : 'default'}
            helperText={errors.suffix}
            options={PrescriberTypeOptions}
            onChange={value => {
              onChange(PrescriberFormField.Suffix, (value === '(~) Clear' || !value ? '' : value) as string)
              if (value === ProviderType.DVM) {
                onChange(PrescriberFormField.Npi, undefined)
              } else {
                onChange(PrescriberFormField.StateLicense, undefined)
              }
            }}
          />
        </SelectWrapper>
      </FormItem>
      {suffix !== ProviderType.DVM && (
        <FormItem>
          <CompoundFormLine>
            <HalfFormItem data-test-row="npi">
              <CustomizedTextField
                required
                size="sm"
                label="NPI"
                value={npi}
                placeholder="ex. 0123456789"
                state={showErrors.npi && (isPrescriberDuplicated || errors.npi) ? 'error' : 'default'}
                helperText={
                  showErrors.npi
                    ? isPrescriberDuplicated
                      ? 'This NPI is associated with a prescriber already in the system'
                      : errors.npi
                    : undefined
                }
                onBlur={() => setShowErrors(value => ({ ...value, npi: true }))}
                onChange={event => onChange(PrescriberFormField.Npi, event.target.value)}
              />
            </HalfFormItem>
            <HalfFormItem data-test-row="dea">
              <CustomizedTextField
                required
                size="sm"
                label="DEA (optional)"
                value={dea}
                placeholder="ex. EB1234567"
                onChange={event => onChange(PrescriberFormField.Dea, event.target.value)}
              />
            </HalfFormItem>
          </CompoundFormLine>
        </FormItem>
      )}
      {suffix === ProviderType.DVM && (
        <FormItem data-test-row="stateLicense">
          <CustomizedTextField
            required
            size="sm"
            label="State License"
            value={stateLicense?.number}
            placeholder="ex. CA-123456789"
            state={showErrors.stateLicense && (isPrescriberDuplicated || errors.stateLicense) ? 'error' : 'default'}
            helperText={
              showErrors.stateLicense
                ? isPrescriberDuplicated
                  ? 'This State License is associated with a prescriber already in the system'
                  : errors.stateLicense
                : undefined
            }
            onBlur={() => setShowErrors(value => ({ ...value, stateLicense: true }))}
            onChange={event => onChange(PrescriberFormField.StateLicense, event.target.value)}
          />
        </FormItem>
      )}
      <FormItem data-test-row="firstname">
        <CustomizedTextField
          required
          size="sm"
          label="Prescriber first name"
          value={firstName}
          state={showErrors.firstName && errors.firstName ? 'error' : 'default'}
          helperText={showErrors.firstName ? errors.firstName : undefined}
          onBlur={() => setShowErrors(value => ({ ...value, firstName: true }))}
          onChange={event => onChange(PrescriberFormField.FirstName, event.target.value)}
        />
      </FormItem>
      <FormItem data-test-row="lastname">
        <CustomizedTextField
          required
          size="sm"
          label="Prescriber last name"
          value={lastName}
          state={showErrors.lastName && errors.lastName ? 'error' : 'default'}
          helperText={showErrors.lastName ? errors.lastName : undefined}
          onBlur={() => setShowErrors(value => ({ ...value, lastName: true }))}
          onChange={event => onChange(PrescriberFormField.LastName, event.target.value)}
        />
      </FormItem>
    </FormItems>
  )
}

const HalfFormItem = styled(FormItem)`
  width: 50% !important;
`

const SelectWrapper = styled.div`
  button {
    height: 48px;
  }
  .capsule.spacer {
    height: 4px;
    min-height: 4px;
  }
`

export default NewPrescriberForm
