import { useEffect, useState } from 'react'
import type { ApolloError } from '@truepill/tpos-react-router'
import { useLazyQuery } from '@truepill/tpos-react-router'
import { GET_SYNCED_PRESCRIBER, GET_PRESCRIBER } from 'gql'
import { validateNpi } from 'utils'

type useCheckDuplicatedPrescriberType = {
  isPrescriberDuplicated: boolean
  loading: boolean
  error?: ApolloError
}

type checkDuplicateProps = {
  npi?: string
  stateLicense?: string
  stateLicenseState?: string
}

const useCheckDuplicatedPrescriber = ({
  npi,
  stateLicense,
  stateLicenseState,
}: checkDuplicateProps): useCheckDuplicatedPrescriberType => {
  const [isPrescriberDuplicated, setIsPrescriberDuplicated] = useState(false)
  const [getSyncedPrescriber, { data, loading, error }] = useLazyQuery(GET_SYNCED_PRESCRIBER, {
    variables: { npi },
  })
  const [getPrescriber, { data: licenseData, loading: licenseLoading, error: licenseError }] =
    useLazyQuery(GET_PRESCRIBER)

  useEffect(() => {
    if (npi && validateNpi(npi)) {
      getSyncedPrescriber({ variables: { npi } })
    } else {
      setIsPrescriberDuplicated(false)
    }
  }, [npi])

  useEffect(() => {
    if (stateLicense) {
      getPrescriber({
        variables: { prescriberStateLicense: stateLicense, prescriberStateLicenseState: stateLicenseState },
      })
    } else {
      setIsPrescriberDuplicated(false)
    }
  }, [stateLicense])

  useEffect(() => {
    const npiDuplicate: boolean = npi && validateNpi(npi) && data?.getSyncedPrescriber
    setIsPrescriberDuplicated(npiDuplicate)
  }, [npi, data?.getSyncedPrescriber?.npi])

  useEffect(() => {
    const stateLicenseDuplicate: boolean = stateLicense && licenseData?.getPrescriber
    setIsPrescriberDuplicated(stateLicenseDuplicate)
  }, [stateLicense, licenseData?.getPrescriber?.stateLicense])

  const isLoading = loading || licenseLoading
  const errors = error || licenseError
  return { isPrescriberDuplicated, loading: isLoading, error: errors }
}

export default useCheckDuplicatedPrescriber
