import { useMemo } from 'react'
import { Text, Spacer, Header } from '@truepill/react-capsule'
import { UserRoles } from '@truepill/tpos-types'
import AuthLimited from 'components/AuthLimited'
import BreadCrumb from 'components/BreadCrumb'
import NavTabContainer, { Tab, Container, WrapSection } from 'components/CustomizedNavTabs'
import { PageContainer } from 'components/PageStructure'
import { UserSessionTable, UserActivity, UserLogs } from 'components/UserProfileData'
import useSetPageTitle from 'hooks/useSetPageTitle'
import useUserInfo from 'hooks/useUserInfo'
import useUserLogs from 'hooks/useUserLogs'
import useUserSessions from 'hooks/useUserSessions'
import { usePlusClient } from 'providers/VisionRouter'
import { useParams } from 'react-router'
import styled from 'styled-components'
import { formatDate } from 'utils/dates'
import UserProfileDetailsTab from '../UserProfileDetailsTab'

const UserProfile = (): JSX.Element => {
  const {
    tokenContext: { id, isAdmin },
  } = usePlusClient()
  const { userId: otherUserId } = useParams<{ userId: string }>()

  const userId = otherUserId || id

  const userLogsPerPage = 10

  const { userInfo, loading: loadingInfo, error: errorLoadingInfo } = useUserInfo(userId)

  const {
    paginatedLogs,
    fetchMoreLogs,
    loading: loadingLogs,
    error: errorLoadingLogs,
  } = useUserLogs(userId, userLogsPerPage)

  const { paginatedSessions, fetchMoreSessions, loading: loadingSession } = useUserSessions(userId)

  const { firstName, lastName, createdAt } = userInfo ?? {}
  const title = firstName && lastName ? `${firstName} ${lastName}` : '-'

  const pageTitle = useMemo(() => {
    return firstName || lastName ? `${firstName || ''}${lastName ? ` ${lastName}` : ''}` : 'Me'
  }, [firstName, lastName])

  useSetPageTitle(pageTitle)

  return (
    <PageContainer>
      <BreadCrumb />
      <Spacer size="2xs" />
      <Header variant="2xl" bold>
        {title}
      </Header>
      <Spacer size="2xs" />
      <Text bold variant="body" style={{ color: '#A1A1A1' }}>
        Created at: {formatDate(createdAt, 'MMM DD, YYYY hh:mmA')}
      </Text>
      <Spacer size="sm" />
      <TabContentWrapper>
        <NavTabContainer>
          <Tab title="Details">
            <Container>
              <WrapSection>
                <UserProfileDetailsTab userData={userInfo} loading={loadingInfo} error={errorLoadingInfo} />
              </WrapSection>
            </Container>
          </Tab>
          <Tab title="Activity">
            <Container>
              <WrapSection>
                <UserActivity
                  paginatedLogs={paginatedLogs}
                  fetchMoreLogs={fetchMoreLogs}
                  loading={loadingLogs}
                  error={errorLoadingLogs}
                />
              </WrapSection>
            </Container>
          </Tab>
          <Tab title="Sessions">
            <Container>
              <WrapSection>
                <UserSessionTable
                  paginatedSessions={paginatedSessions}
                  fetchMoreSessions={fetchMoreSessions}
                  loading={loadingSession}
                  userId={userId ?? ''}
                />
              </WrapSection>
            </Container>
          </Tab>
          {isAdmin() ? (
            <Tab title="Logs">
              <Container>
                <WrapSection>
                  <UserLogs userId={userId ?? ''} />
                </WrapSection>
              </Container>
            </Tab>
          ) : (
            <></>
          )}
        </NavTabContainer>
      </TabContentWrapper>
    </PageContainer>
  )
}

const TabContentWrapper = styled.div`
  margin-right: 1.875rem;
`

export default UserProfile
