import { ReactComponent as DataIcon } from 'assets/icons/data.svg'
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg'
import IconWrapper from 'components/IconWrapper'
import { ModalWrapper, ModalHeader } from 'components/Modal'
import { Box } from 'grommet'
import styled from 'styled-components'
import { bodyPrimaryColor, offsetBackgroundColor } from 'styles/styleVariables'
import type { Escript } from 'types'
import xmlFormat from 'xml-formatter'

type EscriptDataModalProps = { escript: Escript }

const EscriptDataModal = ({ escript }: EscriptDataModalProps): JSX.Element => {
  // removing formatting characters just in case they exist
  const xmlUnformatted = escript.raw.replace(/\r?\n|\r|\t/g, '')
  const xmlFormatted = xmlFormat(xmlUnformatted)
  const xmlBlob = new Blob([xmlFormatted])
  const xmlDownloadUrl = URL.createObjectURL(xmlBlob)

  return (
    <ModalWrapper>
      <ModalHeader>
        <IconWrapper>
          <DataIcon fill={bodyPrimaryColor} />
        </IconWrapper>
        <h2>E-script XML</h2>
        <StyledDownloadButton href={xmlDownloadUrl} target="_blank" download={`Escript_${escript._id}.xml`}>
          <IconWrapper>
            <DownloadIcon fill={bodyPrimaryColor} />
          </IconWrapper>
        </StyledDownloadButton>
      </ModalHeader>
      <StyledBox pad="small" background="light-2">
        <pre>{xmlFormatted}</pre>
      </StyledBox>
    </ModalWrapper>
  )
}

const StyledBox = styled(Box)`
  margin-top: 1rem;
  overflow-x: scroll;
`

const StyledDownloadButton = styled.a<{ contrast?: boolean }>`
  border: none;
  position: absolute;
  top: 1rem;
  right: 4rem;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  background-color: ${offsetBackgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
  > span {
    margin-top: 0.1em;
  }
`

export default EscriptDataModal
