import { useState, useEffect } from 'react'
import { BannerAlert, Spacer, Select, Text } from '@truepill/react-capsule'
import { useMutation } from '@truepill/tpos-react-router'
import { TriageReasons } from '@truepill/tpos-types'
import type { OrderStatus, PrescriberAddress } from '@truepill/tpos-types'
import { ReactComponent as DangerIcon } from 'assets/icons/danger.svg'
import { SaveButton } from 'components/ActionButton'
import CloseButton from 'components/CloseButton'
import { SearchSelectedCard } from 'components/ColumnForm'
import IconWrapper from 'components/IconWrapper'
import LoadingSpinner from 'components/Loading'
import { ModalHeader, ModalWrapper } from 'components/Modal'
import { CancelButton, ToggleButton } from 'components/PageStructure'
import PrescriberSearch from 'components/PrescriberSearch'
import { GENERATE_RX_CHANGE_IMAGE, SET_IN_TRIAGE } from 'gql'
import { TextArea, CheckBox, Box } from 'grommet'
import useErrorToast from 'hooks/toast/useErrorToast'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import { PhoneInput } from 'pages/PeoplePage/PayerEditPage/components/StyledComponents'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import styled, { css } from 'styled-components'
import { bodyPrimaryColor, borderColor, contrastBackgroundColor } from 'styles/styleVariables'
import type { Order, Prescription, OrderTriage, Prescriber, EscriptPrescriber } from 'types'
import { formatPhoneNumber, formatAddress, formatPhoneInput } from 'utils'

type RequestClarificationProps = {
  prescription: Prescription
  order: Order
}
type TriagePayload = Pick<OrderTriage, 'fillId' | 'reason' | 'inTriageUserMessage' | 'rxChange'>

const RequestClarificationModal = ({ prescription, order }: RequestClarificationProps): JSX.Element => {
  // variables
  const { escript } = prescription
  let prescriber: Prescriber | EscriptPrescriber,
    clinicName,
    firstName,
    lastName,
    address: any,
    phoneNumber,
    faxNumber,
    npi,
    prescriberAddressId: string | undefined
  if (escript) {
    ;({ prescriber } = escript)
    ;({
      firstName,
      lastName,
      address,
      npi,
      communicationNumbers: {
        phone: { number: phoneNumber },
      },
    } = prescriber)
    faxNumber = prescriber?.communicationNumbers?.fax?.[0]?.number
    prescriberAddressId = prescription?.prescriber?.addresses?.find(addr => addr.street1.includes(address.street1))?._id
  } else {
    ;({ prescriber } = prescription)
    address = prescriber?.selectedAddress || prescriber?.addresses?.[0]
    phoneNumber = prescriber?.contacts?.phone
    faxNumber = prescriber?.contacts?.fax
  }

  let prescriberAddressInfo
  let isPrescriberFaxOnly = true
  if (escript) {
    prescriberAddressInfo = prescription.prescriber.addresses.find(addr => addr.spi === escript.sender)
    const prescriberServiceLevels = prescriberAddressInfo?.serviceLevel
    isPrescriberFaxOnly = !prescriberServiceLevels?.includes('Change')
  } else {
    prescriberAddressInfo = prescription.prescriber.addresses.find(
      addr => addr._id === prescription.prescriberAddressId,
    )
  }

  const prescriberFax = prescriberAddressInfo?.fax || prescription.prescriber?.contacts?.fax

  // functions
  const { dismissModal } = useModalContext()
  const showSuccessToast = useSuccessToast()
  const showErrorToast = useErrorToast()
  const [clarificationNotes, setClarificationNotes] = useState('')
  const [requiresRphClarification, setRequiresRphClarification] = useState(false)
  const [triageReason, setTriageReason] = useState(TriageReasons.PV1TriageReasons.RxClarificationRequired)
  const [electronicOrFax, setElectronicOrFax] = useState(isPrescriberFaxOnly ? 'Fax' : 'Electronic')
  const [prescriberInfo, setPrescriberInfo] = useState({
    prescriber: {
      ...prescriber,
      _id: prescription?.prescriber?._id,
      contacts: {
        phone: phoneNumber,
        fax: faxNumber,
      },
    } as any,
    address: address as PrescriberAddress | undefined,
  })
  const [practiceAddresses, setPracticeAddresses] = useState([] as PrescriberAddress[])
  const [enableManualFaxInput, setEnableManualFaxInput] = useState(false)
  const [manualFaxInput, setManualFaxInput] = useState('')

  useEffect(() => {
    if (requiresRphClarification) setTriageReason(TriageReasons.PV1TriageReasons.RphClarificationRequired)
    else if (!requiresRphClarification) setTriageReason(TriageReasons.PV1TriageReasons.RxClarificationRequired)
  }, [requiresRphClarification])
  useEffect(() => {
    if (prescriberInfo?.prescriber?.addresses) setPracticeAddresses(prescriberInfo.prescriber?.addresses)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [setInTriageMutation, { loading: setInMutationLoading }] = useMutation<
    {
      setInTriage: { _id: string; inTriage: { reason: TriageReasons } }
      status: OrderStatus
      triages: TriagePayload[]
    },
    { orderId: string; triages: TriagePayload[] }
  >(SET_IN_TRIAGE, {
    onCompleted: () => {
      showSuccessToast(`Set triage for order ${order._id}`)
    },
    onError: error => {
      console.error('Failed to set triage:', error)
      showErrorToast(`Failed to set triage for order ${order._id}: ${error?.message ?? error}`)
    },
  })

  const [generateRxChangeImageMutation, { loading: generateRxChangeImageLoading }] = useMutation<
    {
      generatePrescriptionRxChangeImage: { status: string; faxId: string }
    },
    {
      prescriptionId: string
      rxChangeNote: string
      prescriberId?: string
      prescriberAddressId?: string
      prescriberFax?: string
    }
  >(GENERATE_RX_CHANGE_IMAGE, {
    onCompleted: () => {
      showSuccessToast(`Generated and send RxChange image for order ${order._id}`)
    },
    onError: error => {
      console.error('Failed to generate and send RxChange image:', error)
      showErrorToast(`Failed to generate and send RxChange image for order ${order._id}: ${error?.message ?? error}`)
    },
  })

  if (setInMutationLoading || generateRxChangeImageLoading) {
    return (
      <ModalWrapper styles={modalWrapperStyles}>
        <LoadingSpinnerContainer>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      </ModalWrapper>
    )
  }

  return (
    <ModalWrapper id="RequestClarificationModal" styles={modalWrapperStyles}>
      <ModalHeader>
        <IconWrapper>
          <DangerIcon fill={bodyPrimaryColor} />
        </IconWrapper>
        <Title>Request Prescriber Clarification</Title>
      </ModalHeader>
      <Box direction="row" background={contrastBackgroundColor}>
        <ToggleButton
          active={electronicOrFax === 'Electronic'}
          label="Electronic"
          onClick={() => {
            setElectronicOrFax('Electronic')
            setPrescriberInfo({
              prescriber,
              address,
            }) // from escript/rx
          }}
        />
        <ToggleButton
          active={electronicOrFax === 'Fax'}
          label="Fax"
          onClick={() => {
            setElectronicOrFax('Fax')
          }}
        />
      </Box>
      {electronicOrFax === 'Electronic' && isPrescriberFaxOnly ? (
        <BannerAlert state="warning">
          <Text variant="body-sm">Electronic clarification request is not available for this prescriber.</Text>
        </BannerAlert>
      ) : (
        <>
          <PrescriberDetailsContainer>
            <h3>Prescriber Details</h3>
            {/* prescriber search dropdown */}
            {!prescriberInfo?.prescriber && (
              <PrescriberSearch
                withCapsule
                hideAddress={false}
                onSelect={selected => {
                  setPracticeAddresses(selected.prescriberAddresses)
                  setPrescriberInfo({
                    prescriber: selected.prescriber,
                    address: undefined,
                  })
                }}
              />
            )}
            {/* user selected from prescriber search*/}
            {prescriberInfo?.prescriber && !prescriberInfo.address && (
              <SearchSelectedCard data-testid="prescriber-selected">
                <CloseButton
                  clickCallback={() =>
                    setPrescriberInfo({
                      prescriber: undefined,
                      address: undefined,
                    })
                  }
                />
                <h4>
                  {prescriberInfo?.prescriber?.firstName} {prescriberInfo?.prescriber?.lastName}
                  {prescriberInfo?.prescriber?.suffix ? `, ${prescriberInfo.prescriber.suffix}` : ''}
                </h4>
                <ul>
                  <li data-testid="prescriber-npi">
                    <strong>NPI:</strong> {prescriberInfo?.prescriber?.npi}
                  </li>
                </ul>
              </SearchSelectedCard>
            )}
            {/* prescriber address dropdown */}
            {prescriberInfo?.prescriber && !prescriberInfo.address && !!practiceAddresses.length && (
              <Select
                label="Practice address"
                variant="small"
                placeholder="Select address..."
                state={'default'}
                helperText={''}
                selectedKey="street1"
                options={practiceAddresses}
                onChange={choice => {
                  const prescriberInfoCopy = { ...prescriberInfo }
                  setPrescriberInfo({
                    ...prescriberInfoCopy,
                    address: choice,
                  })
                }}
                value={prescriberInfo.address}
                optionComponent={({ option }) => (
                  <>
                    <Spacer size="2xs" />
                    <Text bold>{option?.businessName || '-'}</Text>
                    <Spacer size="2xs" />
                    <Text variant="body-sm">{formatAddress(option)}</Text>
                    <Spacer size="2xs" />
                    <Text variant="body-sm">Phone: {formatPhoneNumber(option?.phone) || '-'}</Text>
                    <Spacer size="2xs" />
                    <Text variant="body-sm">Fax: {formatPhoneNumber(option?.fax) || '-'}</Text>
                    <Spacer size="2xs" />
                  </>
                )}
              />
            )}
            {/* final prescriber details to display */}
            {prescriberInfo?.prescriber && prescriberInfo.address && (
              <SearchSelectedCard data-testid="prescriber-selected">
                {electronicOrFax === 'Fax' && (
                  <CloseButton
                    clickCallback={() => {
                      setPrescriberInfo({
                        prescriber: undefined,
                        address: undefined,
                      })
                    }}
                  />
                )}
                <h4>{prescriberInfo.address?.businessName}</h4>
                <ul>
                  <li data-testid="prescriber-name">
                    {prescriberInfo?.prescriber?.firstName} {prescriberInfo?.prescriber?.lastName}
                  </li>
                  <li data-testid="prescriber-address">
                    {prescriberInfo?.address?.street1} {prescriberInfo?.address?.street2}
                    <br />
                    {prescriberInfo?.address?.city}, {prescriberInfo?.address?.state} {prescriberInfo?.address?.zip}
                  </li>
                  <li data-testid="prescriber-phone">{`Phone: ${formatPhoneNumber(
                    prescriberInfo?.prescriber?.contacts?.phone,
                  )}`}</li>
                  {prescriberInfo?.prescriber?.contacts?.fax && (
                    <li data-testid="prescriber-phone">
                      {`Fax:
                ${formatPhoneNumber(prescriberInfo?.prescriber?.contacts?.fax)}`}
                    </li>
                  )}
                  <li data-testid="prescriber-npi">{`NPI:${prescriberInfo?.prescriber?.npi}`}</li>
                </ul>
              </SearchSelectedCard>
            )}
            <CheckBox
              label={'I want to use a different fax number'}
              checked={!!enableManualFaxInput}
              onClick={e => e.stopPropagation()}
              onChange={e => {
                setEnableManualFaxInput(!enableManualFaxInput)
              }}
            />
            {enableManualFaxInput && (
              <InputContainer>
                <h3>Fax Number</h3>
                <PhoneInput
                  data-testid="fax-input"
                  value={manualFaxInput}
                  placeholder={'(xxx) - xxx - xxxx'}
                  onChange={e => setManualFaxInput(formatPhoneInput(e.target.value || '') || '')}
                />
              </InputContainer>
            )}
          </PrescriberDetailsContainer>
          <InputContainer>
            <h3>Clarification Notes (Required)</h3>
            <TextArea
              data-testid="clarification-notes"
              onChange={event => setClarificationNotes(event.target.value)}
              placeholder={''}
              value={clarificationNotes}
              resize={'vertical'}
            />
          </InputContainer>
          <CheckBox
            label={'Rph Clarification Required'}
            checked={!!requiresRphClarification}
            onClick={e => e.stopPropagation()}
            onChange={e => {
              setRequiresRphClarification(!requiresRphClarification)
            }}
          />
          <BannerAlert state="warning">
            <Text variant="body-sm">
              {`Once the 'Send Clarification Request' button is clicked, the order will automatically be sent to Triage for
           ${triageReason}`}
            </Text>
          </BannerAlert>
          <ButtonsContainer>
            <CancelButton label={'Cancel'} onClick={dismissModal} />
            <SaveButton
              isModal
              disabled={!clarificationNotes.length || !prescriberInfo?.prescriber || !prescriberInfo?.address}
              label={'Send Clarification Request'}
              onClick={async () => {
                let faxResponse
                if (electronicOrFax === 'Fax') {
                  if (prescriberFax || manualFaxInput) {
                    faxResponse = await generateRxChangeImageMutation({
                      variables: {
                        prescriptionId: prescription._id,
                        rxChangeNote: clarificationNotes,
                        prescriberId: prescriberInfo?.prescriber?._id,
                        prescriberAddressId: prescriberInfo?.address?._id || prescriberAddressId,
                        prescriberFax: manualFaxInput || prescriberFax,
                      },
                    })
                  } else {
                    showErrorToast(
                      'Prescriber does not allow Surescripts change requests and prescriber record has no fax number',
                    )
                  }
                }
                if (
                  electronicOrFax !== 'Fax' ||
                  (faxResponse?.data?.generatePrescriptionRxChangeImage?.status !== 'FAILED' && !faxResponse?.errors)
                ) {
                  await setInTriageMutation({
                    variables: {
                      orderId: order._id,
                      triages: [
                        {
                          fillId: order?.rxFillRequests[0]?.fillId,
                          reason: triageReason,
                          rxChange: {
                            reason: 'ScriptClarification',
                            details: [],
                            sendToSurescripts: electronicOrFax === 'Electronic',
                            ndc: escript?.medicationPrescribed?.drugCoded?.productCode?.code,
                          },
                          inTriageUserMessage: clarificationNotes,
                        },
                      ],
                    },
                  })
                }
                dismissModal()
              }}
            />
          </ButtonsContainer>
        </>
      )}
    </ModalWrapper>
  )
}

const modalWrapperStyles = css`
  padding: 1.5rem;
  font-family: Lato;
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 1.5rem;
`

const PrescriberDetailsContainer = styled.div``

const Title = styled.p`
  flex: 1;
  font-size: 1.5rem;
  line-height: 30px;
  font-weight: 700;
  padding: 0.315rem;
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1.25rem;
`
const RowItemContainer = styled.div`
  width: 100%;
  min-height: 36px;
  padding: 0.315rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  :first-child {
    font-size: 0.875rem;
    font-weight: 500;
  }
`

const InputContainer = styled(RowItemContainer)`
  position: relative;
  > textarea {
    width: 100%;
    border: 0.125rem solid ${borderColor};
    padding: 0.4em 40px 0.4em 11px;
  }
`

const LoadingSpinnerContainer = styled.div`
  display: flex;
  width: 100%;
  padding-top: 0.8rem;
  justify-content: center;
  align-items: center;
  svg {
    height: 100px;
  }
`

export default RequestClarificationModal
