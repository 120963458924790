import { useState } from 'react'
import { Select as CapsuleSelect } from '@truepill/react-capsule'
import { PriorAuthorizationStatus } from '@truepill/tpos-types'
import DropDownOption from 'components/DropDownOption'
import { LinkWrapper, OrderGroup as PriorAuthorizationContainer, StyledLink } from 'components/Order'
import {
  PatientName,
  StyledPriorAuthorization,
  RXNumber,
  DateStamp,
  PaDateStamp,
  Drug,
  Statuses,
} from 'components/Order/Rows'
import SearchAutoComplete from 'components/SearchAutoComplete'
import SearchInput from 'components/SearchInput'
import Lozenge, { getCopayStatusColor, SearchResultLozenge, SearchResultLozengeList } from 'components/Tiles/Lozenge'
import { useTPCacheContext } from 'providers/TPCacheProvider'
import { usePlusClient } from 'providers/VisionRouter'
import { goToPriorAuthorization } from 'routes'
import { contrastColor, accentPink, borderColor, warningYellow } from 'styles/styleVariables'
import { FillWorkStatus } from 'types'
import type { PriorAuthorization } from 'types'
import { formatCreatedDate } from 'utils'

interface priorAuthorizationStatusOption {
  key: string
  value: string
}

interface PriorAuthorizationStatusSuggestion extends priorAuthorizationStatusOption {
  highlighted?: boolean
  selected?: boolean
}

interface PriorAuthorizationProps {
  priorAuthorization: PriorAuthorization
  inline?: boolean
  onClick?: () => void
}

const PriorAuthorizationStatusMapped: { [key: string]: string } = {
  ManualStart: 'Manual Start',
  ProviderNotified: 'Provider Notified',
  PayerNotified: 'Payer Notified',
}

const PriorAuthorizationEntry = (props: PriorAuthorizationProps): JSX.Element => {
  const { getLocationNameById, getCustomerNameById } = useTPCacheContext()

  const { priorAuthorization, inline, onClick } = props

  const { status } = priorAuthorization
  const customerId = priorAuthorization.customerId.toString()
  const locationId = priorAuthorization.locationId.toString()
  const priorAuthorizationId = priorAuthorization._id

  const orderOrCopayCreatedAt = priorAuthorization.order?.createdAt || priorAuthorization.copayRequest?.createdAt
  const { date: createdAtDate, greaterThan48 } = formatCreatedDate(orderOrCopayCreatedAt)

  const { greaterThan48: daysSincePA } = orderOrCopayCreatedAt
    ? formatCreatedDate(priorAuthorization.createdAt)
    : { greaterThan48: null }
  const disabled = false

  const linkTo = priorAuthorizationId
    ? goToPriorAuthorization({
        priorAuthorizationId,
      })
    : '/'
  return (
    <PriorAuthorizationContainer
      id={`PriorAuthorization-${priorAuthorization._id}`}
      inline={inline}
      isMultiOrder={false}
      data-testid="PriorAuthorization"
      data-order-id={priorAuthorization._id}
    >
      <LinkWrapper disabled={false} key={`${priorAuthorization._id}`}>
        <StyledLink onClick={onClick} to={linkTo}>
          <StyledPriorAuthorization
            id={`PriorAuthorization-${priorAuthorization._id}`}
            inline={true}
            workStatus={FillWorkStatus.normal}
            data-fill-exists={false}
            data-fill-id={priorAuthorization._id}
            data-copayRequest-id={priorAuthorization._id}
            data-rx-number={priorAuthorization?.rxNumber || 'undefined'}
            data-testid="PriorAuthorization"
          >
            <RXNumber data-testid="rx-number" fade={disabled}>
              Rx: {priorAuthorization?.rxNumber}
            </RXNumber>
            <DateStamp data-testid="date-created">
              {createdAtDate} {greaterThan48}
            </DateStamp>
            <PaDateStamp data-testid="date-created">PA - {daysSincePA}</PaDateStamp>
            <PatientName data-testid="patient-name" fade={disabled} data-private>
              {priorAuthorization.patient ? (
                <>
                  {priorAuthorization.patient?.firstName} {priorAuthorization.patient?.lastName}
                </>
              ) : (
                <>Missing</>
              )}
            </PatientName>
            <Drug data-testid="medication" fade={disabled}>
              {priorAuthorization.medicationName}
            </Drug>
            <Statuses>
              {!!priorAuthorization.isManual && (
                <Lozenge data-testid="isManual" backgroundColor={warningYellow}>
                  Manual
                </Lozenge>
              )}
              <Lozenge data-testid="status" backgroundColor={getCopayStatusColor(status)}>
                {PriorAuthorizationStatusMapped[status as string] ?? status}
              </Lozenge>
              <Lozenge data-testid="customer" backgroundColor={contrastColor}>
                {getCustomerNameById(customerId)}
              </Lozenge>
              <Lozenge data-testid="location" backgroundColor={accentPink}>
                {getLocationNameById(locationId)}
              </Lozenge>
            </Statuses>
          </StyledPriorAuthorization>
        </StyledLink>
      </LinkWrapper>
    </PriorAuthorizationContainer>
  )
}

type PriorAuthorizationStatusSelectProps = {
  onSelect: (status: PriorAuthorizationStatus) => void
  value?: string
  className?: string
  multipleFills?: boolean
  clearable?: boolean
}

export const PriorAuthorizationStatusSelect = ({
  onSelect,
  value,
}: PriorAuthorizationStatusSelectProps): JSX.Element => {
  const options = Object.keys(PriorAuthorizationStatus).map(option => ({
    label: option,
    value: option,
  }))

  const selectedValue = value ? { label: value, value } : undefined

  return (
    <CapsuleSelect
      label="Status"
      value={selectedValue}
      placeholder="Select status…"
      options={options}
      selectedKey="value"
      onChange={option => onSelect(option?.value as PriorAuthorizationStatus)}
    />
  )
}

export const PriorAuthorizationStatusMultiSelect = (): JSX.Element => {
  const { currentLocation, routeToMergedQuery } = usePlusClient()
  const [searchTerm, setSearchTerm] = useState('')

  const options = Object.keys(PriorAuthorizationStatus).map(option => ({
    key: option,
    value: option,
  }))

  const selectedStatusKeys: string[] = currentLocation?.queryMap?.priorAuthorizationStatuses || []

  return (
    <>
      <SearchAutoComplete
        isModal={false}
        iconColor={borderColor}
        placeholder={'Search by status...'}
        autoCompleteSuggestions={options?.filter(c => c.value.toLowerCase().startsWith(searchTerm.toLowerCase()))}
        value={searchTerm}
        showOptionsOnFocus={true}
        onSelect={(priorAuthorizationStatus: priorAuthorizationStatusOption) => {
          const statusKey = priorAuthorizationStatus.key
          if (!selectedStatusKeys.includes(statusKey)) {
            routeToMergedQuery({ priorAuthorizationStatuses: [...selectedStatusKeys, statusKey] })
          }
          setSearchTerm('')
        }}
        suggestionComponent={optionProps => (
          <AutoCompleteOption {...(optionProps as PriorAuthorizationStatusSuggestion)} />
        )}
        onChange={setSearchTerm}
        maximumHeight="16rem"
      />
      <SearchResultLozengeList>
        {selectedStatusKeys.map((statusKey: any) => {
          return (
            <SearchResultLozenge
              key={statusKey}
              closeCallback={() => {
                if (selectedStatusKeys.includes(statusKey)) {
                  const newStatusKeys = [...selectedStatusKeys]
                  newStatusKeys.splice(selectedStatusKeys.indexOf(statusKey), 1)
                  routeToMergedQuery({ priorAuthorizationStatuses: newStatusKeys })
                }
              }}
            >
              <p>{statusKey}</p>
            </SearchResultLozenge>
          )
        })}
      </SearchResultLozengeList>
    </>
  )
}

const AutoCompleteOption = (props: PriorAuthorizationStatusSuggestion) => {
  const triageReason = props as PriorAuthorizationStatusSuggestion

  return (
    <DropDownOption {...triageReason}>
      <p>{triageReason.value}</p>
    </DropDownOption>
  )
}

interface PriorAuthorizationSearchProps {
  onChange: (arg0: string) => void
}
export const PriorAuthorizationSearch = ({ onChange }: PriorAuthorizationSearchProps) => {
  const [searchTerm, setSearchTerm] = useState('')
  return (
    <SearchInput
      data-private
      className={'priorAuthorizationSearch'}
      placeholder={'Search prior auths...'}
      iconColor={borderColor}
      value={searchTerm}
      onChange={({ currentTarget: { value: text } }) => {
        setSearchTerm(text)
        onChange(text)
      }}
    />
  )
}

export default PriorAuthorizationEntry
