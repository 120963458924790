import { useMutation } from '@truepill/tpos-react-router'
import { SaveButton } from 'components/ActionButton'
import { ActionButtonContainer, CancelButton } from 'components/PageStructure'
import { UPDATE_ORDER_FILL_REQUEST_EDI_OVERRIDES, UPDATE_COPAY_REQUEST_FILL_EDI_OVERRIDES } from 'gql'
import moment from 'moment'
import type { QueueItem, EdiOverrides } from 'types'
import { isOrder } from 'utils'

// Similar to map request in claims object, potential to combine into one function
const formatEdiOverrides = (ediOverrides: EdiOverrides[]) => {
  return ediOverrides.map(overrides => ({
    orderInsuranceOptionId: overrides.orderInsuranceOptionId,
    header: {
      dateOfService:
        (overrides?.header?.dateOfService && moment(overrides?.header?.dateOfService).format('MM/DD/YYYY')) ||
        undefined,
      softwareVendorId: overrides?.header?.softwareVendorId || undefined,
      serviceProviderId: overrides?.header?.serviceProviderId || undefined,
    },
    pricing: {
      ingredientCostSubmitted: overrides?.pricing?.ingredientCostSubmitted ?? undefined,
      dispensingFeeSubmitted: overrides?.pricing?.dispensingFeeSubmitted ?? undefined,
      usualAndCustomaryCharge: overrides?.pricing?.usualAndCustomaryCharge ?? undefined,
      grossAmountDue: overrides?.pricing?.grossAmountDue ?? undefined,
      basisOfCostDetermination: overrides?.pricing?.basisOfCostDetermination || undefined,
      flatSalesTax: overrides?.pricing?.flatSalesTax ?? undefined,
      patientPayAmount: overrides?.pricing?.patientPayAmount ?? undefined,
      percentageSalesTaxAmount: overrides?.pricing?.percentageSalesTaxAmount ?? undefined,
      percentageSalesTaxRate: overrides?.pricing?.percentageSalesTaxRate ?? undefined,
      percentageSalesTaxBasis: overrides?.pricing?.percentageSalesTaxBasis || undefined,
    },
    insurance: {
      firstName: overrides?.insurance?.firstName || undefined,
      lastName: overrides?.insurance?.lastName || undefined,
      homePlan: overrides?.insurance?.homePlan || undefined,
      planId: overrides?.insurance?.planId || undefined,
      eligibilityClarification: overrides?.insurance?.eligibilityClarification || undefined,
    },
    patient: {
      residence: overrides?.patient?.residence || undefined,
      id: overrides?.patient?.id || undefined,
    },
    claim: {
      scheduledPrescriptionIdNumber: overrides?.claim?.scheduledPrescriptionIdNumber || undefined,
      intermediaryAuthorizationTypeId: overrides?.claim?.intermediaryAuthorizationTypeId || undefined,
      intermediaryAuthorizationId: overrides?.claim?.intermediaryAuthorizationId || undefined,
      pharmacyServiceType: overrides?.claim?.pharmacyServiceType || undefined,
      daysSupply: overrides?.claim?.daysSupply ?? undefined,
    },
    pharmacyProvider: {
      medicaidId: overrides?.pharmacyProvider?.medicaidId || undefined,
    },
    prescriber: {
      primaryCareProviderIdQualifier: overrides?.prescriber?.primaryCareProviderIdQualifier || undefined,
      primaryCareProviderId: overrides?.prescriber?.primaryCareProviderId || undefined,
      primaryCareProviderLastName: overrides?.prescriber?.primaryCareProviderLastName || undefined,
    },
    facility: {
      facilityId: overrides?.facility?.facilityId || undefined,
      facilityName: overrides?.facility?.facilityName || undefined,
      facilityStreetAddress: overrides?.facility?.facilityStreetAddress || undefined,
      facilityCityAddress: overrides?.facility?.facilityCityAddress || undefined,
      facilityStateProvinceAddress: overrides?.facility?.facilityStateProvinceAddress || undefined,
      facilityZipPostalCode: overrides?.facility?.facilityZipPostalCode || undefined,
    },
    clinical: {
      diagnosisCode: overrides?.clinical?.diagnosisCode || undefined,
      diagnosisCodeQualifier: overrides?.clinical?.diagnosisCodeQualifier || undefined,
      clinicalInformationCounter: overrides?.clinical?.clinicalInformationCounter || undefined,
      measurementDate:
        (overrides?.clinical?.measurementDate && moment(overrides?.clinical?.measurementDate).format('MM/DD/YYYY')) ||
        undefined,
      measurementTime: overrides?.clinical?.measurementTime || undefined,
      measurementDimension: overrides?.clinical?.measurementDimension || undefined,
      measurementUnit: overrides?.clinical?.measurementUnit || undefined,
      measurementValue: overrides?.clinical?.measurementValue || undefined,
    },
    additionalDocumentation: {
      additionalDocumentationTypeID: overrides?.additionalDocumentation?.additionalDocumentationTypeID || undefined,
      requestPeriodBeginDate:
        (overrides?.additionalDocumentation?.requestPeriodBeginDate &&
          moment(overrides?.additionalDocumentation?.requestPeriodBeginDate).format('MM/DD/YYYY')) ||
        undefined,
      requestPeriodRecertificationDate:
        (overrides?.additionalDocumentation?.requestPeriodRecertificationDate &&
          moment(overrides?.additionalDocumentation?.requestPeriodRecertificationDate).format('MM/DD/YYYY')) ||
        undefined,
      requestStatus: overrides?.additionalDocumentation?.requestStatus || undefined,
      lengthOfNeedQualifier: overrides?.additionalDocumentation?.lengthOfNeedQualifier || undefined,
      lengthOfNeed: overrides?.additionalDocumentation?.lengthOfNeed || undefined,
      prescriberDateSigned:
        (overrides?.additionalDocumentation?.prescriberDateSigned &&
          moment(overrides?.additionalDocumentation?.prescriberDateSigned).format('MM/DD/YYYY')) ||
        undefined,
      supportingDocumentation: overrides?.additionalDocumentation?.supportingDocumentation || undefined,
    },
    narrative: {
      narrativeMessage: overrides?.narrative?.narrativeMessage || undefined,
    },
    coordinationOfBenefits: {
      otherPayerCoverageType: overrides?.coordinationOfBenefits?.otherPayerCoverageType ?? undefined,
      internalControlNumber: overrides?.coordinationOfBenefits?.internalControlNumber ?? undefined,
    },
  }))
}

type EdiEditActionButtonsProps = {
  setDisplayEdiEdit: (val: boolean) => void
  item: QueueItem
  fillId: string
  ediOverrides: EdiOverrides[]
}

const EdiEditActionButtons = ({
  setDisplayEdiEdit,
  item,
  fillId,
  ediOverrides,
}: EdiEditActionButtonsProps): JSX.Element => {
  const [updateOrderFillRequestEdiOverrides] = useMutation(UPDATE_ORDER_FILL_REQUEST_EDI_OVERRIDES)
  const [updateCopayRequestFillEdiOverrides] = useMutation(UPDATE_COPAY_REQUEST_FILL_EDI_OVERRIDES)

  return (
    <ActionButtonContainer>
      <CancelButton
        label="Cancel"
        onClick={() => {
          setDisplayEdiEdit(false)
        }}
      />
      <SaveButton
        label="Update"
        onClick={() => {
          const options: any = {
            variables: {
              fillId,
              ediOverrides: formatEdiOverrides(ediOverrides),
            },
          }

          isOrder(item) ? (options.variables.orderId = item._id) : (options.variables.copayRequestId = item._id)
          isOrder(item) ? updateOrderFillRequestEdiOverrides(options) : updateCopayRequestFillEdiOverrides(options)
          setDisplayEdiEdit(false)
        }}
      />
    </ActionButtonContainer>
  )
}

export default EdiEditActionButtons
