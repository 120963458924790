import { useState, useCallback } from 'react'
import type { ApolloError } from '@truepill/tpos-react-router'
import { useQuery } from '@truepill/tpos-react-router'
import { SEARCH_USERS } from 'gql'
import type { User } from 'types'

interface GetUsersParams {
  search?: string
  limit: number
  offset: number
}

export interface PaginatedUsers {
  users: User[]
  offset?: number
  limit?: number
  total: number
}

interface GetUsersResponse {
  paginatedUsers: PaginatedUsers
}

type useSearchUsersType = {
  paginatedUsers?: PaginatedUsers
  fetchMore: (newOffset: number, newLimit: number) => void
  loading: boolean
  error?: ApolloError
}

const useSearchUsers = (search?: string): useSearchUsersType => {
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(10)

  const { data, loading, error } = useQuery<GetUsersResponse, GetUsersParams>(SEARCH_USERS, {
    variables: { search, limit, offset },
  })

  const fetchMore = useCallback((newOffset, newLimit) => {
    setOffset(newOffset)
    setLimit(newLimit)
  }, [])

  if (!data) {
    return {
      fetchMore,
      loading,
      error,
    }
  }

  const { paginatedUsers } = data

  return {
    paginatedUsers,
    fetchMore,
    loading,
    error,
  }
}

export default useSearchUsers
