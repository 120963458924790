import { Box, Text } from '@truepill/react-capsule'
import styled from 'styled-components'
import { capsuleGrayColor } from 'styles/styleVariables'
import DurFields from './DurFields'

const DurEditingSection = () => {
  return (
    <Container>
      <Wrapper>
        <EditSection>
          <Box>
            <Title bold>Tokens</Title>
            <Text>List includes auto-detected tokens</Text>
          </Box>
          <Box>
            <Title bold>Medispan match</Title>
            <Text>Select the Medispan DUR equivalent</Text>
          </Box>
        </EditSection>
        <Box>
          <DurFields type="allergies" />
          <DurFields type="medications" />
          <DurFields type="conditions" />
        </Box>
      </Wrapper>
    </Container>
  )
}

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const Title = styled(Text)`
  line-height: 1.5rem;
`

const Container = styled(Box)`
  background-color: ${capsuleGrayColor};
  padding: 1rem;
  border-radius: 0.5rem;
  max-height: 60vh;
  overflow: scroll;
`

const EditSection = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`

export default DurEditingSection
