import type { FC } from 'react'
import { useHistory, useQuery } from '@truepill/tpos-react-router'
import { OrderStatus, RxFillRequestStatus, UserRoles } from '@truepill/tpos-types'
import AuthLimited from 'components/AuthLimited'
import {
  GET_PATIENT_MATCH_COUNT,
  GET_ESCRIPT_INTAKE_ISSUES_COUNT,
  GET_ESCRIPT_UNASSIGNED_COUNT,
  GET_COPAY_COUNT,
  GET_ORDER_PRIOR_AUTHORIZATION_COUNT,
} from 'gql'
import { Box } from 'grommet'
import type { PartialRxFillRequestStatus } from 'hooks/useFulfillmentQueue'
import {
  FulfillmentQueueName,
  RXFillRequestStatusToFulfillmentQueueName,
  OrderStatusToFulfillmentQueueName,
} from 'hooks/useFulfillmentQueue'
import useSetPageTitle from 'hooks/useSetPageTitle'
import { TaskQueueName, TASK_QUEUES } from 'hooks/useTaskQueue'
import TruncatedUserList from 'pages/fulfillment/components/TruncatedUserList'
import { usePlusClient } from 'providers/VisionRouter'
import { goToViewPharmacyEscripts, goToViewRxIntakeIssues, goToTaskQueue } from 'routes'
import styled from 'styled-components'
import {
  alertRed,
  contrastBackgroundColor,
  primaryBackgroundColor,
  primaryColor,
  subduedColor,
  warningYellow,
} from 'styles/styleVariables'

interface StatWithAging {
  total: number | null
  between24And48Hours: number | null
  greaterThan48Hours: number | null
}

interface Props {
  lastFetchTime: string
  fillsByStatus: {
    PV1: StatWithAging | null
    Fill: StatWithAging | null
    Automation: StatWithAging | null
    PV2: StatWithAging | null
    Adjudication: StatWithAging | null
    PriorAuthorization: StatWithAging | null
  } | null
  triage: StatWithAging | null
  packing: StatWithAging | null
  otcOnly: StatWithAging | null
}

const QueuesSummary: FC<Props> = ({ lastFetchTime, fillsByStatus, packing, otcOnly, triage }) => {
  useSetPageTitle('Fulfillment Summary')

  return (
    <StyledQueuesSummary margin={{ top: '-1rem' }} pad="0rem 1.875rem 1.875rem" width={{ max: '80rem' }}>
      <StyledH2>Queues</StyledH2>
      <TimeStamp>Since {lastFetchTime}</TimeStamp>
      <StyledFillsSummary direction="row" height="11.25rem" width="100%">
        <TriageQueueStatusCard stats={triage} />
        <QueueStatusCard status={RxFillRequestStatus.PV1} stats={fillsByStatus?.PV1 ?? null} />
        <QueueStatusCard status={RxFillRequestStatus.Automation} stats={fillsByStatus?.Automation ?? null} />
        <QueueStatusCard status={RxFillRequestStatus.Fill} stats={fillsByStatus?.Fill ?? null} />
        <QueueStatusCard status={RxFillRequestStatus.PV2} stats={fillsByStatus?.PV2 ?? null} />
        <PackingStatusCard stats={packing} />
      </StyledFillsSummary>
      <StyledH2>Triage</StyledH2>
      <TimeStamp>Since {lastFetchTime}</TimeStamp>
      <StyledFillsSummary direction="row" height="11.25rem" width="100%">
        <QueueStatusCard status={RxFillRequestStatus.Adjudication} stats={fillsByStatus?.Adjudication ?? null} />
        <PriorAuthorizationStatusCard />
        <QueueStatusCard
          status={RxFillRequestStatus.PriorAuthorization}
          display={'PA (Orders)'}
          stats={fillsByStatus?.PriorAuthorization ?? null}
        />
        <EscriptStatusCard />
        <RxIntakeExceptionsStatusCard />
      </StyledFillsSummary>
      <AuthLimited
        roles={[
          UserRoles.Pharmacist,
          UserRoles.LeadPharmacist,
          UserRoles.Admin,
          UserRoles.Developer,
          UserRoles.Technician,
          UserRoles.Warehouse,
          UserRoles.CustomerSupport,
          UserRoles.LeadCustomerSupport,
        ]}
      >
        <StyledH2>Tasks</StyledH2>
        <TimeStamp>Since {lastFetchTime}</TimeStamp>
        <StyledFillsSummary direction="row" height="11.25rem" width="100%">
          <AuthLimited
            roles={[
              UserRoles.Pharmacist,
              UserRoles.LeadPharmacist,
              UserRoles.Admin,
              UserRoles.Developer,
              UserRoles.Technician,
              UserRoles.Warehouse,
            ]}
          >
            <PatientMatchStatusCard />
            <OtcOnlyStatusCard stats={otcOnly} />
          </AuthLimited>
          <CopayCard />
        </StyledFillsSummary>
      </AuthLimited>
    </StyledQueuesSummary>
  )
}

const StyledQueuesSummary = styled(Box)`
  margin-top: -1rem;
  padding: 0rem 1.875rem 1.875rem;
  max-width: 80rem;
`

const StyledFillsSummary = styled(Box)`
  flex-direction: row;
  height: 11.25rem;
  width: 100%;
`

const PatientMatchStatusCard = (): JSX.Element => {
  const history = useHistory()

  const { data: patientMatchCountData } = useQuery(GET_PATIENT_MATCH_COUNT)
  const patientMatchCount = patientMatchCountData?.getPatientMatchCount

  return (
    <StatusCard onClick={() => history.push(goToTaskQueue({ taskQueueName: TaskQueueName.PatientMatch }))}>
      <TitleRow>
        <h4 style={{ textTransform: 'initial' }}>{TASK_QUEUES[TaskQueueName.PatientMatch].title}</h4>
      </TitleRow>
      <FillStatusSummaryList>
        <FillStatusSummary>
          <FillStatusTag tagColor={primaryColor} />
          <FillStatusSummaryData>{patientMatchCount ?? 0}</FillStatusSummaryData>
        </FillStatusSummary>
      </FillStatusSummaryList>
    </StatusCard>
  )
}

const OtcOnlyStatusCard: FC<{ stats: StatWithAging | null }> = ({ stats }): JSX.Element => {
  const {
    routeTo,
    tokenContext,
    currentLocation: { queryMap },
  } = usePlusClient()

  const total = stats?.total ?? '-'
  const nearlyOverdue = stats?.between24And48Hours ?? '-'
  const overdue = stats?.greaterThan48Hours ?? '-'

  return (
    <StatusCard
      data-testid="OtcOnly"
      onClick={() => {
        routeTo
          .fulfillmentQueue(FulfillmentQueueName.Packing, {
            searchMap: {
              otcOnly: true,
              ...getSearchMapVars(queryMap, tokenContext?.locationId),
            },
          })
          .now()
      }}
    >
      <TitleRow>
        <h4>OTC-Only</h4>
      </TitleRow>
      <FillStatusSummaryList>
        <FillStatusSummary>
          <FillStatusTag tagColor={primaryColor} />
          <FillStatusSummaryData>{total}</FillStatusSummaryData>
        </FillStatusSummary>
        <FillStatusSummary>
          <FillStatusTag tagColor={alertRed} />
          <FillStatusSummaryData>{overdue}</FillStatusSummaryData>
        </FillStatusSummary>
        <FillStatusSummary>
          <FillStatusTag tagColor={warningYellow} />
          <FillStatusSummaryData>{nearlyOverdue}</FillStatusSummaryData>
        </FillStatusSummary>
      </FillStatusSummaryList>
    </StatusCard>
  )
}

const EscriptStatusCard = (): JSX.Element => {
  const history = useHistory()

  const { data: escriptCountData } = useQuery(GET_ESCRIPT_UNASSIGNED_COUNT)
  const escriptUnnasignedCount = escriptCountData?.getEscriptUnassignedCount

  return (
    <SingleStatusCard onClick={() => history.push(goToViewPharmacyEscripts({ search: '?inTriage=true' }))}>
      <TitleRow>
        <h4 style={{ textTransform: 'initial' }}>Unassigned eRx</h4>
      </TitleRow>
      <FillStatusSummaryList>
        <FillStatusSummary>
          <FillStatusTag tagColor={primaryColor} />
          <FillStatusSummaryData>{escriptUnnasignedCount}</FillStatusSummaryData>
        </FillStatusSummary>
      </FillStatusSummaryList>
    </SingleStatusCard>
  )
}

const RxIntakeExceptionsStatusCard = (): JSX.Element => {
  const history = useHistory()

  const { data: escriptCountData } = useQuery(GET_ESCRIPT_INTAKE_ISSUES_COUNT)
  const escriptIntakeIssuesCount = escriptCountData?.getEscriptIntakeIssuesCount

  return (
    <SingleStatusCard onClick={() => history.push(goToViewRxIntakeIssues({ search: '' }))}>
      <TitleRow>
        <h4 style={{ textTransform: 'initial' }}>Rx Intake Exceptions</h4>
      </TitleRow>
      <FillStatusSummaryList>
        <FillStatusSummary>
          <FillStatusTag tagColor={primaryColor} />
          <FillStatusSummaryData>{escriptIntakeIssuesCount}</FillStatusSummaryData>
        </FillStatusSummary>
      </FillStatusSummaryList>
    </SingleStatusCard>
  )
}

const PackingStatusCard: FC<{ stats: StatWithAging | null }> = ({ stats }) => {
  const {
    routeTo,
    tokenContext,
    currentLocation: { queryMap },
  } = usePlusClient()

  const total = stats?.total ?? '-'
  const nearlyOverdue = stats?.between24And48Hours ?? '-'
  const overdue = stats?.greaterThan48Hours ?? '-'

  return (
    <StatusCard
      onClick={() => {
        routeTo
          .fulfillmentQueue(OrderStatusToFulfillmentQueueName[OrderStatus.Packing], {
            searchMap: getSearchMapVars(queryMap, tokenContext?.locationId),
          })
          .now()
      }}
    >
      <TitleRow>
        <h4>Packing</h4>
      </TitleRow>
      <FillStatusSummaryList>
        <FillStatusSummary>
          <FillStatusTag tagColor={primaryColor} />
          <FillStatusSummaryData>{total}</FillStatusSummaryData>
        </FillStatusSummary>
        <FillStatusSummary>
          <FillStatusTag tagColor={alertRed} />
          <FillStatusSummaryData>{overdue}</FillStatusSummaryData>
        </FillStatusSummary>
        <FillStatusSummary>
          <FillStatusTag tagColor={warningYellow} />
          <FillStatusSummaryData>{nearlyOverdue}</FillStatusSummaryData>
        </FillStatusSummary>
      </FillStatusSummaryList>
    </StatusCard>
  )
}

const CopayCard = (): JSX.Element => {
  const {
    routeTo,
    tokenContext,
    currentLocation: { queryMap },
  } = usePlusClient()

  const queueName = 'Copay'

  const { data: copayCountData } = useQuery(GET_COPAY_COUNT, {
    variables: {
      locationId: queryMap.locationId,
    },
  })

  const copayCount = copayCountData?.getCopayCount

  return (
    <StatusCard
      data-testid={queueName}
      onClick={() => {
        routeTo
          .fulfillmentQueue(queueName, {
            searchMap: getSearchMapVars(queryMap, tokenContext?.locationId),
          })
          .now()
      }}
    >
      <TitleRow>
        <NoWrapH4>{queueName}</NoWrapH4>
        <TruncatedUserList items={[]} />
      </TitleRow>
      <FillStatusSummaryList>
        <FillStatusSummary>
          <FillStatusTag tagColor={primaryColor} />
          <FillStatusSummaryData>{copayCount}</FillStatusSummaryData>
        </FillStatusSummary>
      </FillStatusSummaryList>
    </StatusCard>
  )
}

const QueueStatusCard: FC<{ status: PartialRxFillRequestStatus; display?: string; stats: StatWithAging | null }> = ({
  status,
  display,
  stats,
}) => {
  const {
    routeTo,
    tokenContext,
    currentLocation: { queryMap },
  } = usePlusClient()

  const total = stats?.total ?? '-'
  const nearlyOverdue = stats?.between24And48Hours ?? '-'
  const overdue = stats?.greaterThan48Hours ?? '-'

  const queueName = RXFillRequestStatusToFulfillmentQueueName[status]

  return (
    <StatusCard
      data-testid={queueName}
      onClick={() => {
        routeTo
          .fulfillmentQueue(queueName, {
            searchMap: getSearchMapVars(queryMap, tokenContext?.locationId),
          })
          .now()
      }}
    >
      <TitleRow>
        <NoWrapH4>{display ?? queueName}</NoWrapH4>
      </TitleRow>
      <FillStatusSummaryList>
        <FillStatusSummary>
          <FillStatusTag tagColor={primaryColor} />
          <FillStatusSummaryData>{total}</FillStatusSummaryData>
        </FillStatusSummary>
        <FillStatusSummary>
          <FillStatusTag tagColor={alertRed} />
          <FillStatusSummaryData>{overdue}</FillStatusSummaryData>
        </FillStatusSummary>
        <FillStatusSummary>
          <FillStatusTag tagColor={warningYellow} />
          <FillStatusSummaryData>{nearlyOverdue}</FillStatusSummaryData>
        </FillStatusSummary>
      </FillStatusSummaryList>
    </StatusCard>
  )
}

const TriageQueueStatusCard: FC<{ stats: StatWithAging | null }> = ({ stats }) => {
  const {
    routeTo,
    tokenContext,
    currentLocation: { queryMap },
  } = usePlusClient()

  const total = stats?.total ?? '-'
  const nearlyOverdue = stats?.between24And48Hours ?? '-'
  const overdue = stats?.greaterThan48Hours ?? '-'

  return (
    <StatusCard
      onClick={() => {
        routeTo
          .fulfillmentQueue(FulfillmentQueueName.Triage, {
            searchMap: getSearchMapVars(queryMap, tokenContext?.locationId),
          })
          .now()
      }}
    >
      <TitleRow>
        <h4>Triage</h4>
        <TruncatedUserList items={[]} />
      </TitleRow>
      <FillStatusSummaryList>
        <FillStatusSummary>
          <FillStatusTag tagColor={primaryColor} />
          <FillStatusSummaryData>{total}</FillStatusSummaryData>
        </FillStatusSummary>
        <FillStatusSummary>
          <FillStatusTag tagColor={alertRed} />
          <FillStatusSummaryData>{overdue}</FillStatusSummaryData>
        </FillStatusSummary>
        <FillStatusSummary>
          <FillStatusTag tagColor={warningYellow} />
          <FillStatusSummaryData>{nearlyOverdue}</FillStatusSummaryData>
        </FillStatusSummary>
      </FillStatusSummaryList>
    </StatusCard>
  )
}

const PriorAuthorizationStatusCard = (): JSX.Element => {
  const {
    routeTo,
    tokenContext,
    currentLocation: { queryMap },
  } = usePlusClient()

  const { data } = useQuery(GET_ORDER_PRIOR_AUTHORIZATION_COUNT, {
    variables: {
      locationId: queryMap.locationId,
    },
  })

  const priorAuthorizationCount = data?.getOrderPriorAuthorizationCount

  return (
    <SingleStatusCard
      onClick={() => {
        routeTo
          .fulfillmentQueue(FulfillmentQueueName.PriorAuthorizationNew, {
            searchMap: getSearchMapVars(queryMap, tokenContext?.locationId),
          })
          .now()
      }}
    >
      <TitleRow>
        <h4>Prior Auth</h4>
        <TruncatedUserList items={[]} />
      </TitleRow>
      <FillStatusSummaryList>
        <FillStatusSummary>
          <FillStatusTag tagColor={primaryColor} />
          <FillStatusSummaryData>{priorAuthorizationCount}</FillStatusSummaryData>
        </FillStatusSummary>
      </FillStatusSummaryList>
    </SingleStatusCard>
  )
}

const TitleRow = styled.span`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  > h4 {
    font-size: 0.875rem;
    margin-top: 0rem;
    text-transform: capitalize;
  }
`

const FillStatusSummary = styled.ul`
  margin-top: 0.3125rem;
  margin-bottom: 0.3125rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  height: 2.25rem;
`

const FillStatusSummaryData = styled.li`
  cursor: pointer;
  border: 0.25rem solid;
  border-radius: 0rem 0.25rem 0.25rem 0rem;
  border-color: ${primaryBackgroundColor};
  background-color: ${primaryBackgroundColor};
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  font-size: 1.3125rem;
  font-weight: 500;
`

const FillStatusTag = styled.li<{ tagColor: string }>`
  border-left: 0.25rem solid;
  border-radius: 0.25rem 0rem 0rem 0.25rem;
  border-left-color: ${({ tagColor }) => tagColor};
  width: 0rem;
`

const FillStatusSummaryList = styled.ul`
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex-grow: 1;
  margin-top: 0.5rem;
`

const StyledH2 = styled.h2`
  margin-top: 1.75rem;
`

const Card = styled.div`
  position: relative;
  width: 100%;
  height: 11.25rem;
  display: grid;
  grid-template-rows: 0.5fr 0.5fr;
  grid-template-columns: 1fr;
  padding: 0.625rem;
  background-color: ${contrastBackgroundColor};
  border-radius: 0.25rem;
  :not(:first-of-type) {
    margin-left: 0.65rem;
  }
  :not(:last-of-type) {
    margin-right: 0.625rem;
  }
  h4 {
    font-weight: 500;
  }
  > h4 {
    grid-row: 1;
    grid-column: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    font-size: 1.125rem;
  }
`

const StatusCard = styled(Card)`
  display: flex;
  width: 20rem;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding-top: 1rem;
  padding-bottom: 1rem;
  height: 12rem;
`

const SingleStatusCard = styled(StatusCard)`
  height: fit-content;
`

const TimeStamp = styled.p`
  color: ${subduedColor};
  margin-bottom: 0.625rem;
`

const NoWrapH4 = styled.h4`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const getSearchMapVars = (
  queryMap: { states?: string[]; customerIds?: string[]; noStates?: boolean; locationId?: string },
  defaultLocationId?: string,
) => {
  return {
    ...(queryMap.states?.length && { states: queryMap?.states }),
    ...(queryMap.customerIds?.length && { customerIds: queryMap.customerIds }),
    ...(queryMap.noStates && { states: [] }),
    locationId: queryMap.locationId || defaultLocationId,
  }
}

export default QueuesSummary
