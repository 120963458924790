import type { PatientAddress, PatientContacts } from '@truepill/tpos-types'

const hasPatientContactInfo = (patientAddress?: PatientAddress, patientContacts?: PatientContacts): boolean =>
  (!!patientAddress?.home?.street1 &&
    !!patientAddress?.home?.city &&
    !!patientAddress?.home?.state &&
    !!patientAddress?.home?.zip) ||
  !!patientContacts?.email

export default hasPatientContactInfo
