import { useHistory, useLocation } from '@truepill/tpos-react-router'
import { ReactComponent as KeyboardIcon } from 'assets/icons/keyboard.svg'
import LargeIconWrapper from 'components/IconWrapper'
import { PageContainer, LargeButtonCard } from 'components/PageStructure'
import useSetPageTitle from 'hooks/useSetPageTitle'
import { gotoSigAdminCodes, gotoInventoryGroups } from 'routes'
import styled, { css } from 'styled-components'
import { bodyPrimaryColor } from 'styles/styleVariables'
import type { ChildProps } from 'types'

type AdminCardType = {
  destination: ({ search }: { search?: string }) => string
  label: string
} & ChildProps

const AdminCard = ({ destination, label, children }: AdminCardType): JSX.Element => {
  const history = useHistory()
  const { search } = useLocation()
  return (
    <StyledLargeButtonCard onClick={() => history.push(destination({ search }))}>
      <IconWrapper>{children}</IconWrapper>
      <h2>{label}</h2>
    </StyledLargeButtonCard>
  )
}

const AdminPage = (): JSX.Element => {
  useSetPageTitle('Admin')

  return (
    <PageContainer>
      <Title>Admin</Title>
      <Content>
        <AdminCard destination={gotoSigAdminCodes} label="Sig codes">
          <KeyboardIcon fill={bodyPrimaryColor} />
        </AdminCard>
        <AdminCard destination={gotoInventoryGroups} label="Inventory Groups">
          <KeyboardIcon fill={bodyPrimaryColor} />
        </AdminCard>
      </Content>
    </PageContainer>
  )
}

const PagePadding = css`
  padding-left: 1.875rem;
  padding-right: 1.875rem;
`

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  ${PagePadding}
  @media (min-width: 1800px) {
    justify-content: flex-start;
  }
`

const Title = styled.h1`
  font-size: 24px;
  width: 100%;
  margin-bottom: 0.8rem;
  ${PagePadding}
`

const IconWrapper = styled(LargeIconWrapper)`
  width: unset;
  margin-bottom: 1.125rem;
  svg {
    height: 2rem;
    width: 1.75rem;
    max-width: unset;
    max-height: unset;
  }
`
const StyledLargeButtonCard = styled(LargeButtonCard)`
  margin-top: 0.625rem;
  width: 31%;
  margin-right: 0.2rem;
  > h2 {
    font-weight: 500;
    font-size: 1.3125rem;
    line-height: 1.5rem;
    font-family: Roboto;
  }
  @media (min-width: 1800px) {
    width: 500px;
    margin-right: 3rem;
  }
`

export default AdminPage
