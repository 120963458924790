import { useRef, useCallback } from 'react'
import { useToastContext } from 'providers/Overlays/ToastProvider'
import type { ToastOptionalArgs } from 'types'
import { ToastType } from 'types'

type useErrorToastType = (errorMessage?: string, args?: ToastOptionalArgs) => void

const useErrorToast = (showRepeatedErrors = true): useErrorToastType => {
  const { showToast } = useToastContext()

  const errorRef = useRef<string>()
  return useCallback(
    (errorMessage?: string, args?: ToastOptionalArgs) => {
      if (errorRef.current !== errorMessage || showRepeatedErrors) {
        errorRef.current = errorMessage
        if (errorMessage) {
          showToast(errorMessage, ToastType.error, args?.timeToAutoDismiss, args?.position)
        }
      }
    },
    [showRepeatedErrors, showToast],
  )
}

export default useErrorToast
