import { useCallback, useState, useMemo } from 'react'
import type { Dispatch, SetStateAction } from 'react'
import { useHistory, useParams, useQuery } from '@truepill/tpos-react-router'
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg'
import { ReactComponent as PersonIcon } from 'assets/icons/person.svg'
import { ReactComponent as GearIcon } from 'assets/nav/gear.svg'
import BreadCrumb from 'components/BreadCrumb'
import LoadingSpinner from 'components/Loading'
import NotesPane from 'components/NotesPane'
import { PageContainer, StickyPageHeadingContainer } from 'components/PageStructure'
import PayerData from 'components/PayerData'
import { ToolButton } from 'components/SideToolBar'
import ToolBar, { NotesButton } from 'components/ToolBar'
import { GET_PAYER } from 'gql'
import useLogs from 'hooks/navigation/useLogs'
import useHotKey, { HotKeyLevel } from 'hooks/useHotKey'
import useSetPageTitle from 'hooks/useSetPageTitle'
import moment from 'moment'
import { goToEditPayer } from 'routes'
import type { InsuranceProvider } from 'types'
import {
  Content,
  LoadingSpinnerContainer,
  Subtitle,
  Title,
  TitleSection,
  ToolBarContainer,
  ViewLabel,
  ViewOption,
  ViewOptionSelected,
  ViewToggle,
} from './StyledComponents'

const makeViewOption = (
  name: string,
  viewOption: string,
  setViewOption: Dispatch<SetStateAction<string>>,
): JSX.Element => {
  const selected = name === viewOption
  const icon = name === 'Profile' ? <PersonIcon /> : <GearIcon />

  if (selected) {
    return (
      <ViewOptionSelected onClick={() => setViewOption(name)}>
        {icon}
        <ViewLabel>{name}</ViewLabel>
      </ViewOptionSelected>
    )
  }

  return (
    <ViewOption onClick={() => setViewOption(name)}>
      {icon}
      <ViewLabel>{name}</ViewLabel>
    </ViewOption>
  )
}

const PayerPage = (): JSX.Element => {
  const { payerId } = useParams<{ payerId: string }>()
  const [viewOption, setViewOption] = useState('Profile')

  const { data, error, loading } = useQuery(GET_PAYER, {
    variables: {
      payerId: payerId,
    },
  })
  const { logs } = useLogs({ payerId })
  const history = useHistory()

  const goToEditPage = useCallback(() => {
    history.push(goToEditPayer({ payerId, showConfigurations: viewOption === 'Configurations' }))
  }, [payerId, history, viewOption])

  useHotKey('be', HotKeyLevel.normal, goToEditPage)

  const payer: InsuranceProvider = data?.getInsuranceProvider

  const pageTitle = useMemo(() => {
    return !payer ? 'Payer' : `Payer - ${payer.name}`
  }, [payer])

  useSetPageTitle(pageTitle)

  if (error) {
    return (
      <PageContainer>
        <StickyPageHeadingContainer>
          <BreadCrumb />
        </StickyPageHeadingContainer>
        <Title>Error loading Payer: {JSON.stringify(error)}</Title>
      </PageContainer>
    )
  }

  if (loading || !data) {
    return (
      <PageContainer>
        <StickyPageHeadingContainer />
        <LoadingSpinnerContainer>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      </PageContainer>
    )
  }

  const showConfigurations = viewOption === 'Configurations'

  return (
    <>
      <PageContainer>
        <BreadCrumb />
        <TitleSection>
          <Title data-private>
            {payer.name ? payer.name : `${payer.bin ? payer.bin : ''}${payer.pcn ? `-${payer.pcn}` : ''}`}
          </Title>
          <Subtitle>Created on: {moment(payer.importDate).format('MM/DD/YYYY')}</Subtitle>
        </TitleSection>
        <ViewToggle>
          {makeViewOption('Profile', viewOption, setViewOption)}
          {makeViewOption('Configurations', viewOption, setViewOption)}
        </ViewToggle>
        <ToolBarContainer>
          <Content>
            <PayerData payer={payer} showConfigurations={showConfigurations} />
          </Content>
          <ToolBar>
            <ToolButton label="Edit" icon={EditIcon} tooltipText="Edit (b-e)" clickCallback={goToEditPage} />
            <NotesButton notesCount={logs?.length} />
          </ToolBar>
        </ToolBarContainer>
      </PageContainer>
      <NotesPane logs={logs} payer={payer} />
    </>
  )
}

export default PayerPage
