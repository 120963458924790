import { useState } from 'react'
import { BannerAlert, Button, Text } from '@truepill/react-capsule'
import { Link } from '@truepill/tpos-react-router'
import { LaunchDarkly } from '@truepill/tpos-types'
import { ReviewDuplicatePrescriptionModal } from 'components/ReviewDuplicatePrescription'
import { useFlag } from 'providers/LaunchDarklyProvider'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import { goToViewPharmacyPrescription } from 'routes'
import { darkOrange } from 'styles/styleVariables'

interface DuplicateRxBannerProps {
  mainPrescriptionId: string
  duplicatePrescriptionsIds: string[]
  orderId: string
  forceModal?: boolean
}

interface ResolveLinkProps extends DuplicateRxBannerProps {}

const ReviewLink = ({
  mainPrescriptionId,
  duplicatePrescriptionsIds,
  forceModal = false,
  orderId,
}: ResolveLinkProps) => {
  const shouldUseModal: boolean = useFlag(LaunchDarkly.FeatureFlags.TEMP_DUPLICATE_RX_MODAL) ?? false
  const { showModal } = useModalContext()

  if (shouldUseModal || forceModal) {
    return (
      <Button
        variant="primary-text"
        style={{ padding: '0', margin: '0 0.5rem', textDecoration: 'underline', color: darkOrange, fontWeight: 'bold' }}
        onClick={() =>
          showModal(() => (
            <ReviewDuplicatePrescriptionModal
              ids={duplicatePrescriptionsIds}
              mainPrescriptionId={mainPrescriptionId}
              orderId={orderId}
              hideResolveWithoutChangesButton
            />
          ))
        }
      >
        Review duplicate prescription
      </Button>
    )
  }

  return (
    <Link
      style={{ fontWeight: 'bold', textDecoration: 'underline', color: darkOrange }}
      target="_blank"
      to={goToViewPharmacyPrescription({ prescriptionId: duplicatePrescriptionsIds[0] })}
    >
      Review potential duplicate.
    </Link>
  )
}

const DuplicateRxBanner = ({
  mainPrescriptionId,
  duplicatePrescriptionsIds,
  forceModal,
  orderId,
}: DuplicateRxBannerProps): JSX.Element => {
  const [isBannerVisible, SetIsBannerVisible] = useState(true)
  if (!isBannerVisible) {
    return <></>
  }
  return (
    <BannerAlert css={{ '$$banner-border': 'none' }} state="warning" onDismiss={() => SetIsBannerVisible(false)}>
      <Text variant="body-sm">
        Potential duplicate prescription in the system, review before confirming.&nbsp;
        <ReviewLink
          forceModal={forceModal}
          mainPrescriptionId={mainPrescriptionId}
          duplicatePrescriptionsIds={duplicatePrescriptionsIds}
          orderId={orderId}
        />
      </Text>
    </BannerAlert>
  )
}

export default DuplicateRxBanner
