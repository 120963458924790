import { RxFillRequestStatus } from '@truepill/tpos-types'
import { GET_NEXT_ADJUDICATION } from 'gql'
import { usePlusClient } from 'providers/VisionRouter'
import type { Order } from 'types'
import { FulfillmentQueueName } from './useFulfillmentQueue'
import { useGetNextOrder } from './useGetNextOrder'
import type { UseGetNextOrderType, GetNextOrderFilters } from './useGetNextOrder'

const useGetNextAdjudication = (): UseGetNextOrderType => {
  const {
    client,
    tokenContext,
    currentLocation: { queryMap },
  } = usePlusClient()

  const getData = async (variables: GetNextOrderFilters) => {
    const { data } = await client.query({
      query: GET_NEXT_ADJUDICATION,
      fetchPolicy: 'no-cache',
      variables,
    })

    return data.getNextAdjudication as Order
  }

  const customFilters = {
    selectedLocationIds: queryMap.locationIds || undefined,
    medicationNames: queryMap.medications || undefined,
    customerIds: queryMap.customerIds || undefined,
    locationId: queryMap.locationId || tokenContext?.locationId,
    rejectCodes: queryMap.rejectCodes || undefined,
    rejectCodeCategories: queryMap.rejectCodeCategories || undefined,
    batchOrders: queryMap.batchOrders !== undefined ? queryMap.batchOrders === 'true' : undefined,
    showTriage: queryMap.showTriage !== undefined ? queryMap.showTriage === 'true' : undefined,
    specialHandlingTags: queryMap.noHandlingTags ? [] : queryMap.specialHandlingTags || undefined,
    states: queryMap.noStates ? [] : queryMap.states || undefined,
  } as GetNextOrderFilters

  return useGetNextOrder(
    customFilters,
    getData,
    FulfillmentQueueName.Adjudication,
    'Adjudication',
    RxFillRequestStatus.Adjudication,
  )
}

export default useGetNextAdjudication
