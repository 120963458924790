import { useCallback, useState } from 'react'
import { useMutation } from '@truepill/tpos-react-router'
import { AdjudicationTriageReasons } from '@truepill/tpos-types'
import { ReactComponent as CancelIcon } from 'assets/icons/cancel.svg'
import { SaveButton } from 'components/ActionButton'
import DatePicker from 'components/DatePicker'
import IconWrapper from 'components/IconWrapper'
import { ButtonsContainer, InputContainer, ModalHeader, ModalWrapper } from 'components/Modal'
import { CancelButton } from 'components/PageStructure'
import Select from 'components/Select'
import { CANCEL_ORDER, REJECT_COPAY_REQUEST } from 'gql'
import { TextArea } from 'grommet'
import useErrorToast from 'hooks/toast/useErrorToast'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import moment from 'moment'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import styled, { css } from 'styled-components'
import { bodyPrimaryColor } from 'styles/styleVariables'
import type { CopayRequest, CopayRequestFill, Order, RXFillRequest } from 'types'
import { CopayFillRejectionReason, OrderRejectionReason, CopayRejectionReason } from 'types'
import { isCopayRequest } from 'utils'

interface ModalProps {
  item: Order | CopayRequest
  isCopay?: boolean
}

const RejectOrderModal = (props: ModalProps): JSX.Element => {
  const { dismissModal } = useModalContext()
  const { isCopay = false } = props
  const [reason, setReason] = useState('')
  const [note, setNote] = useState('')
  const [code, setCode] = useState('')
  const [disableStatus, setDisableFlag] = useState(false)
  const containsControlledSubstance = !!(props.item as any)?.rxFillRequests?.find(
    (rxFill: RXFillRequest) =>
      !!rxFill?.fill?.handlingTags?.deaScheduleNum || rxFill?.fill?.handlingTags?.isControlledSubstance,
  )

  const [cancelOrder] = useMutation(CANCEL_ORDER, {
    variables: { orderId: props.item._id, reason: reason, code: code, note: note },
    onCompleted: data => {
      const { reversalsFailed } = data.cancelOrder
      if (reversalsFailed) {
        showErrorToast(`Order rejected but one or more claim reversals failed`)
      } else {
        showSuccessToast('Order rejected.')
      }
      if (containsControlledSubstance) {
        // confirmExcessiveCsRejectionsModal to appear on page reload
        window.location.reload()
      }
      dismissModal()
    },
    onError: e => {
      console.error('Order could not be rejected: ', e)
      showErrorToast(`Order could not be rejected: ${props.item._id}: ${e?.message ?? e}`)
      dismissModal()
    },
  })
  const [rejectCopayRequest, { loading: loadingCopay }] = useMutation(REJECT_COPAY_REQUEST, {
    refetchQueries: ['getBasicCopay'],
    variables: { copayRequestId: props?.item?._id, reason: reason, reasonCode: code, message: note },
    onCompleted: data => {
      const { reversalsFailed } = data.rejectCopayRequest
      if (reversalsFailed) {
        showErrorToast(`Copay rejected but one or more claim reversals failed`)
      } else {
        showSuccessToast('Copay rejected.')
      }
      dismissModal()
    },
    onError: e => {
      console.error('Copay could not be rejected: ', e)
      showErrorToast(`Copay could not be rejected: ${props?.item?._id}: ${e?.message ?? e}`)
      dismissModal()
    },
  })

  const showSuccessToast = useSuccessToast(true)
  const showErrorToast = useErrorToast(true)
  const filterRejectedFills = (rx: RXFillRequest | CopayRequestFill) => {
    const rejectionDetails = rx.rejectionDetails
    const isValidToosoon = isCopay
      ? rejectionDetails?.reason === CopayFillRejectionReason['Refill too soon']
      : rejectionDetails?.reason === AdjudicationTriageReasons.RefillTooSoonRejection ||
        rejectionDetails?.reasonCode === '79'
    if (!!rejectionDetails && isValidToosoon) return true
    return false
  }

  const handleFillTooSoonRejection = () => {
    const rx = (isCopayRequest(props.item) ? props.item?.copayRequestFills : props?.item?.rxFillRequests) as Array<
      CopayRequestFill | RXFillRequest
    >
    const fillTooSoonRejected = rx?.filter(filterRejectedFills) ?? []
    const errorMessage = isCopay ? 'copay' : 'order'
    if (
      (code === OrderRejectionReason['Refill too soon'] || code === CopayRejectionReason['Refill Too Soon']) &&
      fillTooSoonRejected.length === 0
    ) {
      showErrorToast(
        `A fill-level 'Refill Too Soon' rejection is required before this ${errorMessage} can be rejected for 'Refill Too Soon'`,
      )
      setDisableFlag(true)
    }
    return
  }
  const useDatePickerForMsg = code === OrderRejectionReason['Rx Expired']
  const label = isCopay ? 'Reject Copay' : 'Reject order'
  const options = isCopay ? Object.keys(CopayRejectionReason) : Object.keys(OrderRejectionReason)
  const valueType = isCopay ? CopayRejectionReason : OrderRejectionReason
  const resetOptions = useCallback(() => {
    setReason('')
    setCode('')
  }, [])

  return (
    <ModalWrapper styles={modalWrapperStyles}>
      <ModalHeader>
        <IconWrapper>
          <CancelIcon fill={bodyPrimaryColor} />
        </IconWrapper>
        <h2>{label}</h2>
      </ModalHeader>
      <InputContainer>
        <StyledSelect
          data-testid="rejectReason"
          disableClear
          value={reason}
          placeholder={'Select a rejection reason…'}
          options={options}
          onChange={([option]) => {
            if (!option) {
              resetOptions()
              return
            }

            const value = option.value as keyof typeof valueType
            const newRejectCode = valueType[value]
            setReason(value)
            setCode(newRejectCode)
            if (code === OrderRejectionReason['Refill too soon'] || code === CopayRejectionReason['Refill Too Soon']) {
              handleFillTooSoonRejection()
              return
            }
            setDisableFlag(false)
          }}
        />
        {useDatePickerForMsg ? (
          <StyledDatePicker
            data-testid="message"
            onChange={date => {
              const dateStr = moment(date).format('MM/DD/YYYY')
              setNote(dateStr)
            }}
          />
        ) : (
          <TextArea
            data-testid="message"
            onChange={event => setNote(event.target.value)}
            placeholder={'Type a message...'}
            value={note}
            resize={'vertical'}
          />
        )}
      </InputContainer>
      <ButtonsContainer>
        <CancelButton label={'Close'} onClick={dismissModal} />
        <SaveButton
          isModal
          disabled={!reason || disableStatus || loadingCopay}
          label={label}
          onClick={async () => {
            if (isCopay) {
              await rejectCopayRequest()
              return
            }
            await cancelOrder()
          }}
        />
      </ButtonsContainer>
    </ModalWrapper>
  )
}

// unlike other styled components, the ModalWrapper requires additional styling to be passed in
const modalWrapperStyles = css`
  overflow-y: hidden;
`

const StyledSelect = styled(Select)`
  margin-top: 0.625rem;
`

const StyledDatePicker = styled(DatePicker)`
  margin-top: 0.625rem;

  input {
    line-height: 1.5rem;
    height: auto;
    padding: 0.3rem 1rem;
  }
`

export default RejectOrderModal
