import { useState } from 'react'
import { useMutation } from '@truepill/tpos-react-router'
import { ReactComponent as UmbrellaIcon } from 'assets/icons/umbrella.svg'
import { SaveButton } from 'components/ActionButton'
import IconWrapper from 'components/IconWrapper'
import { ModalWrapper, ModalHeader, InputContainer, ButtonsContainer } from 'components/Modal'
import { CancelButton } from 'components/PageStructure'
import { CompoundTextInput, StyledSelect } from 'components/RXTable'
import { RUN_ELIGIBILITY_CHECK } from 'gql'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import SelectionProvider from 'providers/SelectionProvider'
import styled from 'styled-components'
import { bodyPrimaryColor } from 'styles/styleVariables'
import type { Fill, Patient, EligibilityCheckReceived, Item, ItemType } from 'types'

const CLAIMS_ELIGIBILITY_REASONS = ['Commercial', 'Government']

interface EligibilityCheckModalProps {
  fillId: Fill['_id']
  patientId: Patient['_id']
  itemId: Item['_id']
  itemType: ItemType
  confirmationCallback: (data: EligibilityCheckReceived) => void
}

const WrappedEligibilityCheckModal = (props: EligibilityCheckModalProps): JSX.Element => {
  const { fillId, patientId, itemId, itemType, confirmationCallback } = props
  const [runEligibilityCheck] = useMutation(RUN_ELIGIBILITY_CHECK)
  const { dismissModal } = useModalContext()

  const [eligibilityCheckType, setEligibilityCheckType] = useState('')
  const [governmentReason, setGovermentReason] = useState('')
  const [isRunning, setIsRunning] = useState(false)

  const isTypeSelected = !!eligibilityCheckType
  return (
    <SelectionProvider>
      <ModalWrapper id="EligibilityCheckModal">
        <ModalHeader>
          <IconWrapper>
            <UmbrellaIcon fill={bodyPrimaryColor} />
          </IconWrapper>
          <h2>Eligibility check</h2>
        </ModalHeader>
        <ConstrainedInputContainer>
          <ReasonLabel>Eligibility check type</ReasonLabel>
          <ReasonSelect
            multiple={false}
            modal={true}
            value={eligibilityCheckType}
            placeholder={'Select a type...'}
            options={CLAIMS_ELIGIBILITY_REASONS}
            onChange={([option]) => {
              const value = option ? option.value : ''
              setEligibilityCheckType(value as string)
            }}
          />
          {eligibilityCheckType === 'Government' && (
            <StyledTextInput
              onChange={event => setGovermentReason(event.target.value)}
              placeholder={'Enter SSN or MBI...'}
              value={governmentReason}
            />
          )}
        </ConstrainedInputContainer>
        <ButtonsContainer>
          <CancelButton label={'Cancel'} onClick={dismissModal} />
          <SaveButton
            isModal
            disabled={!isTypeSelected || isRunning}
            label={isRunning ? '...' : 'Submit'}
            onClick={async () => {
              if (isTypeSelected) {
                try {
                  setIsRunning(true)
                  const variables = {
                    variables: {
                      fillId,
                      patientId,
                      cardholderId: eligibilityCheckType === 'Commercial' ? '' : governmentReason,
                      type: eligibilityCheckType.toLowerCase(),
                      itemId,
                      itemType,
                    },
                  }
                  const response = await runEligibilityCheck(variables)
                  const received = response.data.runEligibilityCheck.received
                  setIsRunning(false)
                  confirmationCallback(received)
                } catch (e) {
                  setIsRunning(false)
                  dismissModal()
                }
              }
            }}
          />
        </ButtonsContainer>
      </ModalWrapper>
    </SelectionProvider>
  )
}

const ReasonSelect = styled(StyledSelect)`
  height: 2.5rem;
  align-self: stretch;
  width: 100%;
  font-weight: normal;
`
const ConstrainedInputContainer = styled(InputContainer)`
  max-width: 31rem;
`

const ReasonLabel = styled.p`
  font-size: 0.875rem;
  font-weight: 500;
  font-family: Roboto;
  margin-bottom: 0.5rem;
`

const StyledTextInput = styled(CompoundTextInput)`
  padding-left: 1rem;
`

export default WrappedEligibilityCheckModal
