import type { ApolloError } from '@truepill/tpos-react-router'
import { UserDetails, UserInfoContainer } from 'components/UserProfileData'
import type { UserInfo } from 'hooks/useUserInfo'

interface UserProfileDetailsTabProps {
  userData?: UserInfo
  loading: boolean
  error?: ApolloError
}

const UserProfileDetailsTab = ({ loading, error, userData }: UserProfileDetailsTabProps): JSX.Element => {
  return (
    <UserInfoContainer>
      <UserDetails userInfo={userData} loading={loading} error={error} />
    </UserInfoContainer>
  )
}

export default UserProfileDetailsTab
