export type SendBackReason = SendBackToPv1FromPV2Reason | SendBackToFillFromPV2Reason | SendBackToPv1FromFillReason
export enum SendBackToPv1FromPV2Reason {
  'DDI' = 'DDI',
  'Drug allergy' = 'Drug allergy',
  'Drug disease' = 'Drug disease',
  'Contraindication' = 'Contraindication',
  'Dose clarification' = 'Dose clarification',
  'DUR' = 'DUR',
  'Patient name/DOB mismatch' = 'Patient name/DOB mismatch',
  'Incorrect sig' = 'Incorrect sig',
  'Incorrect days supply' = 'Incorrect days supply',
  'Incorrect prescriber' = 'Incorrect prescriber',
  'Incorrect medication' = 'Incorrect medication',
  'Incorrect quantity' = 'Incorrect quantity',
  'Incorrect patient' = 'Incorrect patient',
  'Incorrect payer or payment method' = 'Incorrect payer or payment method',
  'Other' = 'Other',
  'Patient outreach required' = 'Patient outreach required',
}
export enum SendBackToFillFromPV2Reason {
  'IncorrectQuantity' = 'Incorrect quantity',
  'IncorrectMedication' = 'Incorrect medication',
}
export enum SendBackToPv1FromFillReason {
  'IncorrectQuantity' = 'Incorrect quantity',
  'IncorrectMedication' = 'Incorrect medication',
}

export const SendBackReasons = {
  SendBackToFillFromPV2Reason,
  SendBackToPv1FromFillReason,
  SendBackToPv1FromPV2Reason,
}
