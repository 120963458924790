import { useEffect, useCallback } from 'react'
import useLastReleaseChecked, { LastReleaseStatus } from 'hooks/useLastReleaseChecked'
import ReleaseNotesModal from 'modals/ReleaseNotesModal'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import { usePlusClient } from 'providers/VisionRouter'
import { getCurrentRelease } from 'utils'

const useReleaseNotes = (): void => {
  const { showModal } = useModalContext()
  const { lastReleaseChecked, fetchStatus } = useLastReleaseChecked()

  const { isAuthenticated } = usePlusClient()

  const hasCheckedLastRelease = useCallback(() => {
    const currentRelease = getCurrentRelease()

    // In the case we do not have release date set in the
    // project, we won't show the modal
    if (!currentRelease) {
      return true
    }

    if (fetchStatus === LastReleaseStatus.Fetched) {
      return currentRelease === lastReleaseChecked
    }

    return true
  }, [lastReleaseChecked, fetchStatus])

  // Show modal reference changes every time a modal is shown,
  // making it impossible to put it in the dependencies array
  const showReleaseNotesModal = useCallback(() => {
    showModal(() => <ReleaseNotesModal reviewIsRequired />)
  }, [])

  useEffect(() => {
    if (!isAuthenticated) {
      return
    }

    const checkedCurrentRelease = hasCheckedLastRelease()
    if (!checkedCurrentRelease) {
      showReleaseNotesModal()
    }
  }, [hasCheckedLastRelease, showReleaseNotesModal, isAuthenticated])
}

export default useReleaseNotes
