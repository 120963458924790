import type { EntityId, VerificationPatient, VerificationPrescriber } from '../../'

export enum ControlledSubstancesVerificationType {
  C2 = 'c2',
  C3 = 'c3',
  C4 = 'c4',
  C5 = 'c5',
}

export interface ControlledSubstancesVerificationData {
  prescriberStateLicense: string
  prescriberDeaNumber: string
  pharmacistAttestation: boolean
}

export interface ControlledSubstancesVerification extends ControlledSubstancesVerificationData {
  type: ControlledSubstancesVerificationType
}

export interface PV1Verification {
  pharmacistId: EntityId
  verifiedAt?: Date
  skipped?: boolean
  patient?: VerificationPatient
  prescriber?: VerificationPrescriber
  controlledSubstances?: ControlledSubstancesVerification
}

export interface PV2Verification {
  pharmacistId: EntityId
  verifiedAt?: Date
  skipped?: boolean
}

export interface TransferOutVerification {
  pharmacistId: EntityId
  verifiedAt?: Date
}
