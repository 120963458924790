import type { ReactElement } from 'react'
import { useState } from 'react'
import { SaveButton } from 'components/ActionButton'
import { ModalWrapper, ModalHeader, InputContainer, ButtonsContainer } from 'components/Modal'
import { CheckBox } from 'grommet'
import useLastReleaseChecked from 'hooks/useLastReleaseChecked'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import styled from 'styled-components'
import { getCurrentRelease } from 'utils'

export const RELEASE_NOTES_DOC =
  'https://docs.google.com/document/d/e/2PACX-1vT_Tr2Veacy0MVQmn2axE0lbXHEvrgRkenvzLpzKug-LSICXl92nbUpWWngV5D51uydIbpniFI8Trrz/pub'
const EMBEDDED_RELEASE_NOTES = `${RELEASE_NOTES_DOC}?embedded=true`

interface ModalProps {
  reviewIsRequired?: boolean
}

const ReleaseNotesModal = (props: ModalProps): ReactElement => {
  const { reviewIsRequired = false } = props

  const { dismissModal } = useModalContext()

  const [hasCheckedReleaseNotes, setCheckedReleaseNotes] = useState(false)
  const { setNewReleaseChecked } = useLastReleaseChecked()

  const setReleaseChecked = () => {
    const currentRelease = getCurrentRelease() ?? ''
    setNewReleaseChecked(currentRelease)
  }

  const confirmReadReleaseNotes = (
    <ConfirmReleaseNotes>
      <ConfirmCheckbox
        checked={hasCheckedReleaseNotes}
        onChange={({ target: { checked } }) => {
          setCheckedReleaseNotes(checked)
        }}
      />
      <span>
        I have reviewed the latest release notes, understand the changes, and will ask leadership if I have questions
      </span>
    </ConfirmReleaseNotes>
  )
  const canDismiss = reviewIsRequired ? hasCheckedReleaseNotes : true

  return (
    <ModalWrapper id="ReleaseNotesModal">
      <ModalHeader hideClose>
        <h1>Review required</h1>
      </ModalHeader>
      <InputContainer width={'600px'}>
        <p>There’s been a version update to our system! Please review the most recent release notes to continue.</p>
        <EmbeddedReleaseNotes src={EMBEDDED_RELEASE_NOTES} />
        {reviewIsRequired && confirmReadReleaseNotes}
      </InputContainer>
      <ButtonsContainer>
        <SaveButton
          isModal
          disabled={!canDismiss}
          label="Got it"
          title={!canDismiss ? 'Please review the release notes' : ''}
          onClick={() => {
            setReleaseChecked()
            dismissModal()
          }}
        />
      </ButtonsContainer>
    </ModalWrapper>
  )
}

const ConfirmCheckbox = styled(CheckBox)`
  margin-top: 4px;
`

const ConfirmReleaseNotes = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
`

const EmbeddedReleaseNotes = styled.iframe`
  border-width: 1px;
  border-color: gray;
  border-style: solid;
  height: 500px;
  margin-top: 15px;
  margin-bottom: 15px;
`

export default ReleaseNotesModal
