import type { EntityId, FillPrescribed, VerificationPatient, VerificationPrescriber } from '@truepill/tpos-types'
import { FillStatus } from '@truepill/tpos-types'
import { pick } from 'lodash'
import type { Dispensed, Fill, Patient, Prescriber, Prescription } from 'types'
import { formatAddress } from 'utils/address'

const dispensedFields: (keyof Dispensed)[] = ['ndc', 'name', 'directions', 'manufacturer', 'daysSupply', 'quantity']
const prescribedFields: (keyof FillPrescribed)[] = [
  'daw',
  'daysSupply',
  'directions',
  'expirationDate',
  'name',
  'ndc',
  'numberOfRefills',
  'quantity',
  'writtenDate',
]
const patientFields: (keyof VerificationPatient)[] = ['address', 'dob', 'gender', 'name', 'phone']
const prescriberFields: (keyof VerificationPrescriber)[] = [
  'address',
  'customer',
  'dea',
  'name',
  'npi',
  'phone',
  'supervisingPrescriber',
]

export type DispensedField = (typeof dispensedFields)[number]
export type PatientField = (typeof patientFields)[number]
export type PrescribedField = (typeof prescribedFields)[number]
export type PrescriberField = (typeof prescriberFields)[number]

// shallow not deep!
const getDiff = <T extends Record<string, unknown>>(fields: (keyof T & string)[], a?: T, b?: T) => {
  const diff = []
  for (const field of fields) {
    const aVal = a?.[field] ?? ''
    const bVal = b?.[field] ?? ''
    if (aVal !== bVal) {
      diff.push(field)
    }
  }
  return diff
}

export const getLastCompletedTposFill = (fills: Fill[]): Fill | undefined => {
  return fills
    .slice()
    .reverse()
    .find(fill => fill.status === FillStatus.Complete && !fill.isPioneerHistory)
}

export const compareDispensed = ({
  currentFill,
  lastCompletedFill,
}: {
  lastCompletedFill?: Fill
  currentFill: Fill
}) => {
  const delta = new Map<DispensedField, boolean>()
  dispensedFields.forEach(field => delta.set(field, false))
  // If there isn't a previous complete fill (in tpos) then is the first fill
  if (!lastCompletedFill) {
    return delta
  }

  // Compare dispensed from last to current fill
  const dispensedDiff = getDiff(
    dispensedFields,
    pick(lastCompletedFill.dispensed, dispensedFields),
    pick(currentFill.dispensed, dispensedFields),
  )
  dispensedDiff.forEach(field => delta.set(field as DispensedField, true))

  return delta
}

export const comparePrescribed = ({
  prescription,
  lastCompletedFill,
}: {
  lastCompletedFill?: Fill
  prescription: Pick<Prescription & { quantity: number; refillsRemaining: number }, PrescribedField>
}) => {
  const delta = new Map<PrescribedField, boolean>()
  prescribedFields.forEach(field => delta.set(field, false))
  // If there isn't a previous complete fill (in tpos) then is the first fill
  if (!lastCompletedFill) {
    return delta
  }

  // compare prescribed from last fill's to prescription
  const prescribedDiff = getDiff(
    prescribedFields,
    pick(lastCompletedFill.prescribed, prescribedFields),
    pick(prescription, prescribedFields),
  )
  prescribedDiff.forEach(field => delta.set(field as PrescribedField, true))

  return delta
}

export const comparePatient = ({ lastCompletedFill, patient }: { lastCompletedFill?: Fill; patient: Patient }) => {
  const delta = new Map<PatientField, boolean>()
  patientFields.forEach(field => delta.set(field, false))
  // If there isn't a previous complete fill (in tpos) then is the first fill
  if (!lastCompletedFill) {
    return delta
  }

  const verificationPatientDiff = getDiff(
    patientFields,
    pick(lastCompletedFill.verifications.pv1?.patient, patientFields),
    {
      name: `${patient.firstName} ${patient.lastName}`,
      dob: patient.dob,
      gender: patient.gender,
      phone: patient.contacts?.phone,
      address: formatAddress(patient.address?.home),
    },
  )
  verificationPatientDiff.forEach(field => delta.set(field as PatientField, true))

  return delta
}

export const comparePrescriber = ({
  prescriberAddressId,
  prescriber,
  lastCompletedFill,
}: {
  prescriberAddressId?: EntityId
  prescriber: Prescriber
  lastCompletedFill?: Fill
}) => {
  const delta = new Map<PrescriberField, boolean>()
  prescriberFields.forEach(field => delta.set(field, false))
  // If there isn't a previous complete fill (in tpos) then is the first fill
  if (!lastCompletedFill) {
    return delta
  }

  const prescriberAddress = prescriber.addresses?.find(
    address => address._id?.toString() === prescriberAddressId?.toString(),
  )

  const verificationPrescriberDiff = getDiff(
    prescriberFields,
    pick(lastCompletedFill.verifications.pv1?.prescriber, prescriberFields),
    {
      address: prescriberAddress ? formatAddress(prescriberAddress) : '',
      customer: prescriber.customer,
      dea: prescriber.dea,
      name: `${prescriber.firstName} ${prescriber.lastName}`,
      npi: prescriber.npi,
      phone: prescriber.contacts?.phone,
    },
  )
  verificationPrescriberDiff.forEach(field => delta.set(field as PrescriberField, true))
  return delta
}
