import { useState } from 'react'
import SearchInput from 'components/SearchInput'
import moment from 'moment'
import { borderColor } from 'styles/styleVariables'
import type { OrderSearchVariables } from 'types'

const SearchTokenRegex = Object.freeze({
  CUSTOMER_REF: /^ref=([\w-]+)/i,
  CORE_FILL_REQUEST_TOKEN: /^req=([\w-]+)/i,
  ORDER_TOKEN_HOT_KEY: /token=(\w+)/i,
  ORDER_TOKEN: /^[a-z0-9]{6}$/i,
  DRUG_NAME: /^drug=([\w-]+)$/i,
  NDC: /drug=[\d-]+/g,
  RX_FILL_CODE: /^rx=([\d-]+)$/i,
  ORDER_NUMBER: /^[\d]+$/,
  ORDER_NUMBER_BY_ID: /^id=([\d]+)$/,
  PATIENT_DOB: /^\d{4}[/-]?\d{2}[/-]?\d{2}$/,
  PATIENT_NAME: /^[A-Za-z'’ ]+$/,
  TRANSACTION_ID: /^[A-Za-z]{2}_[A-Za-z0-9]+$/,
  TRANSACTION_ID_PREFIX: /^transaction=[\w-]+$/i,
  LAST_FOUR_DIGITS: /^card=\d{4}$/i,
})

/**
 * OrderSearch doesn't perform any searches itself, it merely returns an
 * OrderSearchVariables object to a specified searchTermChanged callback.
 *
 * Some example search text that gets parsed here:
 *
 * - c908123 or token=c908123
 * - req=51a815c52bb311470926e4ebaf8e
 * - John Wick
 * - drug=someNameHere
 * - drug=1234 drug=5678
 *
 * The only case that handles multiple input values is drugs with multiple NDC
 * numbers. Every other case is only made to handle a single value.
 */

type OrderSearchProps = {
  hotKey?: string
  placeholder?: string
  searchTermChanged: (variables: OrderSearchVariables) => void
  className?: string
}

const OrderSearch = ({
  hotKey = 'o',
  placeholder = 'Search orders…',
  searchTermChanged,
  className,
}: OrderSearchProps): JSX.Element => {
  const [searchTerm, setSearchTerm] = useState('')

  return (
    <SearchInput
      data-private
      className={className}
      hotKey={hotKey}
      placeholder={placeholder}
      iconColor={borderColor}
      value={searchTerm}
      onChange={({ currentTarget: { value: text } }) => {
        setSearchTerm(text)
        const cleanedSearchTerm = text.trimStart().trimEnd().replace(/[,.]/, '')
        searchTermChanged({
          rxFillCode: cleanedSearchTerm.match(SearchTokenRegex.RX_FILL_CODE)?.[1],
          drugName: cleanedSearchTerm.match(SearchTokenRegex.DRUG_NAME)?.[1],
          ndcs: cleanedSearchTerm
            .match(SearchTokenRegex.NDC)
            ?.map(ndcTextWithPrefix => ndcTextWithPrefix.replace(/^drug=/i, '')),
          patientDob:
            SearchTokenRegex.PATIENT_DOB.test(cleanedSearchTerm) &&
            moment((cleanedSearchTerm.match(SearchTokenRegex.PATIENT_DOB) || [])[0]).isValid()
              ? moment((cleanedSearchTerm.match(SearchTokenRegex.PATIENT_DOB) || [])[0]).format('YYYY-MM-DD')
              : undefined,
          patientName: cleanedSearchTerm.match(SearchTokenRegex.PATIENT_NAME)?.[0].trimStart().trimEnd(),
          orderNumber:
            cleanedSearchTerm.match(SearchTokenRegex.ORDER_NUMBER)?.[0] ??
            cleanedSearchTerm.match(SearchTokenRegex.ORDER_NUMBER_BY_ID)?.[1],
          customerRef: cleanedSearchTerm.match(SearchTokenRegex.CUSTOMER_REF)?.[1],
          coreOrderToken:
            cleanedSearchTerm.match(SearchTokenRegex.ORDER_TOKEN)?.[0] ??
            cleanedSearchTerm.match(SearchTokenRegex.ORDER_TOKEN_HOT_KEY)?.[1],
          coreFillRequestToken: cleanedSearchTerm.match(SearchTokenRegex.CORE_FILL_REQUEST_TOKEN)?.[1],
          transactionId:
            cleanedSearchTerm.match(SearchTokenRegex.TRANSACTION_ID)?.[0] ||
            cleanedSearchTerm
              .replace(/\s+/g, '')
              .match(SearchTokenRegex.TRANSACTION_ID_PREFIX)?.[0]
              ?.replace(/^transaction=/i, ''),
          lastFourDigits: cleanedSearchTerm
            .replace(/\s+/g, '')
            .match(SearchTokenRegex.LAST_FOUR_DIGITS)?.[0]
            ?.replace(/^card=/i, ''),
        })
      }}
    />
  )
}

export default OrderSearch
