import { FormItem, FormItems, Select } from 'components/ColumnForm'
import { NumericInput } from 'components/NumericInput'
import type { InsuranceProvider } from 'types'
import { updatePayerFormConfigurations } from './util'

type PayerReconciliationFormProps = {
  payerForm: InsuranceProvider
  updatePayerForm: (update: InsuranceProvider) => void
}

const PayerReconciliationForm = ({ payerForm, updatePayerForm }: PayerReconciliationFormProps): JSX.Element => (
  <FormItems>
    <FormItem data-test-row="transaction-fee">
      <p>Per transaction fee</p>
      <NumericInput
        value={payerForm.configurations?.reconciliation?.transactionFee}
        onChange={value =>
          updatePayerFormConfigurations(payerForm, updatePayerForm, 'reconciliation', 'transactionFee', value)
        }
      />
    </FormItem>
    <FormItem data-test-row="rx-fee">
      <p>Per Rx fee</p>
      <NumericInput
        value={payerForm.configurations?.reconciliation?.rxFee}
        onChange={value => updatePayerFormConfigurations(payerForm, updatePayerForm, 'reconciliation', 'rxFee', value)}
      />
    </FormItem>
    <FormItem data-test-row="paid-claim-fee">
      <p>Per paid claim fee</p>
      <NumericInput
        value={payerForm.configurations?.reconciliation?.paidClaimFee}
        onChange={value =>
          updatePayerFormConfigurations(payerForm, updatePayerForm, 'reconciliation', 'paidClaimFee', value)
        }
      />
    </FormItem>
    <FormItem data-test-row="reversal-claim-fee">
      <p>Per reversal claim fee</p>
      <NumericInput
        value={payerForm.configurations?.reconciliation?.reversalClaimFee}
        onChange={value =>
          updatePayerFormConfigurations(payerForm, updatePayerForm, 'reconciliation', 'reversalClaimFee', value)
        }
      />
    </FormItem>
    <FormItem data-test-row="automatically-reconcile-claims">
      <p>Automatically reconcile $0 claims</p>
      <Select
        data-testid="allow-partial-fills-cIII"
        multiple={false}
        value={payerForm.configurations?.reconciliation?.automaticallyReconcileClaims ? 'True' : 'False'}
        placeholder={'Select...'}
        options={['True', 'False']}
        onChange={([option]) => {
          const value = option ? option.value : ''
          updatePayerFormConfigurations(
            payerForm,
            updatePayerForm,
            'reconciliation',
            'automaticallyReconcileClaims',
            value === 'True',
          )
        }}
        disableClear
      />
    </FormItem>

    <FormItem data-test-row="bill-no-cost-claims">
      <p>Bill $0 secondary claims</p>
      <Select
        data-testid="allow-partial-fills-cIII"
        multiple={false}
        value={payerForm.configurations?.reconciliation?.billNoCostSecondaryClaims ? 'True' : 'False'}
        placeholder={'Select...'}
        options={['True', 'False']}
        onChange={([option]) => {
          const value = option ? option.value : ''
          updatePayerFormConfigurations(
            payerForm,
            updatePayerForm,
            'reconciliation',
            'billNoCostSecondaryClaims',
            value === 'True',
          )
        }}
        disableClear
      />
    </FormItem>
  </FormItems>
)

export default PayerReconciliationForm
