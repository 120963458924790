import { useMutation } from '@truepill/tpos-react-router'
import { gql } from 'gql/generated'
import useErrorToast from 'hooks/toast/useErrorToast'
import useSuccessToast from 'hooks/toast/useSuccessToast'

const RESOLVE_DUPLICATE_PRESCRIPTION_TRIAGE = gql(`
  mutation ResolveDuplicatePrescriptionTriages($orderId: ID!, $prescriptionIds: [ID]!) {
    resolveDuplicatePrescriptionTriages(orderId: $orderId, prescriptionIds: $prescriptionIds) {
      successful
      unsuccessful
    }
  }
`)

const useResolveWithoutChanges = ({ onCompleted, onError }: { onCompleted?: () => void; onError?: () => void }) => {
  const showErrorToast = useErrorToast()
  const showSuccessToast = useSuccessToast()

  const [resolveDuplicateTriage, { loading: isResolveDuplicateTriageLoading }] = useMutation(
    RESOLVE_DUPLICATE_PRESCRIPTION_TRIAGE,
    {
      onCompleted: response => {
        response.resolveDuplicatePrescriptionTriages?.successful.forEach(res =>
          showSuccessToast(`Triage with prescription id ${res} has been succesfully resolved`),
        )
        response.resolveDuplicatePrescriptionTriages?.unsuccessful.forEach(res =>
          showErrorToast(`Triage with prescription id ${res} could not be resolved`),
        )
        onCompleted?.()
      },
      onError: error => {
        showErrorToast(error.message)
        onError?.()
      },
    },
  )
  return {
    resolveDuplicateTriage,
    isResolveDuplicateTriageLoading,
  }
}

export default useResolveWithoutChanges
