import type { ReactNode } from 'react'
import { useState } from 'react'
import { ReactComponent as CheckboxIcon } from 'assets/icons/checkmark.svg'
import type { ToolTipFacing } from 'components/HotKeyToolTip'
import HotKeyToolTip from 'components/HotKeyToolTip'
import IconWrapper from 'components/IconWrapper'
import { useGetProductionEnvironment } from 'hooks/useGetProductionEnvironment'
import styled, { css } from 'styled-components'
import {
  borderColor,
  primaryBackgroundColor,
  alertRed,
  confirmColor,
  primaryColor,
  contrastColor,
  warningYellow,
  accentPink,
  darkBlue,
  capsuleLightRedColor,
  darkOrange,
  lightOrange,
  capsuleDarkRedColor,
  capsuleDarkGreenColor,
  capsuleLightGreenColor,
} from 'styles/styleVariables'
import type { Log } from 'types'

interface UserInitialsBadgeProps {
  user: Log['user']
  className?: string
  onClick?: () => void
}

const UserInitialsBadge = ({ user, className, onClick }: UserInitialsBadgeProps): JSX.Element => {
  const isProductionEnvironment = useGetProductionEnvironment()
  const firstName = user.firstName ?? ''
  const lastName = user.lastName ?? ''
  const initials = `${firstName[0]}${lastName[0]}`
  const fullName = `${firstName} ${lastName}`

  return (
    <Badge
      label={initials.toUpperCase()}
      title={fullName}
      className={className}
      backgroundColor={isProductionEnvironment ? accentPink : darkBlue}
      onClick={onClick}
      data-testid={'user-badge'}
      data-test-userid={user?._id}
    />
  )
}
const constrastColorMap = new Map<string, string>()

constrastColorMap.set(alertRed, 'white')
constrastColorMap.set(confirmColor, 'white')
constrastColorMap.set(primaryColor, 'white')
constrastColorMap.set(contrastColor, 'white')
constrastColorMap.set(accentPink, 'white')
constrastColorMap.set(darkBlue, 'white')
constrastColorMap.set(warningYellow, contrastColor)
constrastColorMap.set(lightOrange, darkOrange)
constrastColorMap.set(capsuleLightRedColor, capsuleDarkRedColor)
constrastColorMap.set(capsuleLightGreenColor, capsuleDarkGreenColor)

const getContrastingColor = (color: string): string => {
  const contrastColor = constrastColorMap.get(color)
  return contrastColor ?? 'black'
}

const Circle = css`
  height: 2.5em;
  width: 2.5em;
  border-radius: 50%;
`

type BadgeProps = {
  preserveCircle?: boolean
  backgroundColor?: string
  fontSize?: string
  className?: string
  label: string
  title?: string
  onClick?: () => void
  tooltipText?: string | ReactNode
  tooltipPositioning?: {
    position: ToolTipFacing
    offsetTop: number
    offsetLeft: number
  }
  'data-testid'?: string
}

export const Badge = (props: BadgeProps): JSX.Element => {
  const {
    fontSize,
    preserveCircle,
    backgroundColor,
    className,
    label,
    title,
    onClick,
    tooltipText,
    tooltipPositioning = {
      position: 'right',
      offsetLeft: 0.5,
      offsetTop: 0,
    },
    'data-testid': testId,
  } = props
  const [showToolTip, setShowToolTip] = useState(false)

  const shouldPreserveCircle = preserveCircle !== undefined ? preserveCircle : label.length <= 2
  return (
    <StyledBadge
      title={title}
      fontSize={fontSize}
      className={className}
      preserveCircle={shouldPreserveCircle}
      backgroundColor={backgroundColor}
      onClick={onClick}
      onMouseEnter={() => {
        setShowToolTip(true)
      }}
      onMouseLeave={() => {
        setShowToolTip(false)
      }}
      data-testid={testId}
    >
      {tooltipText && (
        <HotKeyToolTip
          onMouseEnter={() => {
            setShowToolTip(false)
          }}
          forceShow={showToolTip}
          label={tooltipText}
          {...tooltipPositioning}
        />
      )}
      {props.label}
    </StyledBadge>
  )
}

const StyledBadge = styled.span<{
  preserveCircle?: boolean
  backgroundColor?: string
  color?: string
  fontSize?: string
}>`
  display: flex;
  font-weight: 800;
  padding: 0.69em 0.63em 0.5em;
  border-radius: 100em;
  font-size: ${({ fontSize }) => fontSize ?? '0.5rem'};
  justify-content: center;
  align-items: center;
  text-align: center;
  ${({ backgroundColor }) => backgroundColor && `color: ${getContrastingColor(backgroundColor)};`}
  background-color: ${({ backgroundColor }) => backgroundColor};
  p {
    text-align: center;
  }
  ${({ preserveCircle }) => preserveCircle && Circle}
  cursor: default;
`

const FilledBadge = css`
  background-color: ${primaryColor};
`

const CompletedBadgeBorder = css`
  border: 0.125rem solid ${primaryColor};
`

const BadgeBorder = css`
  border: 0.125rem solid ${borderColor};
`

export const TaskBadge = (props: { completed?: boolean }): JSX.Element => {
  const { completed } = props

  const icon = completed && (
    <IconWrapper>
      <CheckboxIcon />
    </IconWrapper>
  )
  return (
    <StyledTaskBadge data-testid="TaskBadge" data-completed={completed} completed={completed}>
      {icon}
    </StyledTaskBadge>
  )
}

const StyledTaskBadge = styled.div<{ completed?: boolean }>`
  width: 1.25rem;
  height: 1.25rem;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${primaryBackgroundColor};
  ${({ completed }) => completed && FilledBadge}
  ${({ completed }) => completed && CompletedBadgeBorder}
  ${({ completed }) => !completed && BadgeBorder}
`

export default UserInitialsBadge
