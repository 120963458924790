import useEditMode from 'hooks/useEditMode'
import useEditValue from 'hooks/useEditValue'
import type { ChildProps } from 'types'

// If a value is passed into these components then they are editable if the query param of editValue is equal to
// the expected value, otherwise they ar editable if the query param of editMode is true
export const EditMode = ({ children, value }: { value?: string } & ChildProps): JSX.Element => {
  const [editMode] = useEditMode()
  const [editValue] = useEditValue()

  if ((!value && !editMode) || (value && editValue !== value)) {
    return <></>
  }

  return <>{children}</>
}

export const NormalMode = ({ children, value }: { value?: string } & ChildProps): JSX.Element => {
  const [editMode] = useEditMode()
  const [editValue] = useEditValue()

  if ((!value && editMode) || (value && editValue === value)) {
    return <></>
  }
  return <>{children}</>
}
