import type { RedirectDestination, RedirectEntity } from '@truepill/tpos-types'

const FULL_URL_VAR = 'REACT_APP_LOCAL'
const PARTIAL_URL_VAR = 'REACT_APP_BACKEND' // If modifying this, make sure to update package.json to match

const getTPDashUrl = () => {
  const origin = window.location.origin
  // prod
  let url = 'https://app.truepill.com'

  if (origin.includes('localhost')) {
    url = 'localhost'
  } else if (origin.includes('stagingpill')) {
    // staging
    url = 'https://dashboard.stagingpill.com'
    // sandbox
  } else if (origin.includes('falsepill')) {
    url = 'https://app.falsepill.com'
  }

  return url
}

const getVisionApiUrl = () => {
  if (process.env[FULL_URL_VAR]) {
    return process.env[FULL_URL_VAR]
  }
  const subdomain = process.env[PARTIAL_URL_VAR]
  switch (subdomain) {
    case 'LOCAL':
      return `http://localhost:3000`
    case 'STAGING':
      return `https://vision.stagingpill.com`
  }
  if (subdomain) {
    return `https://vision.${subdomain}.tpos.falsepill.com`
  } else {
    return `${window.location.protocol}//${window.location.hostname}`
  }
}

const getRedirectUrl = (identifier: string | number, entity: RedirectEntity, destination: RedirectDestination) => {
  const baseUrl = getVisionApiUrl()
  return baseUrl ? `${baseUrl}/redirect/${destination}/${entity}/${identifier}` : null
}

const getBackendUrl = (useV2?: boolean): string | null | undefined => {
  /** Return the full GraphQL URL of the selected backend, given the process's environment **/

  // This function is used both in a Node context (cypress/plugins/index.ts) and in a Browser context (src/providers/VisionRouter.tsx).
  // Therefore, it must not assume the presence of globals such as `window` or `localStorage`.

  const graphqlPath = useV2 ? 'v2/graphql' : 'graphql'
  const baseUrl = getVisionApiUrl()
  return baseUrl ? `${baseUrl}/${graphqlPath}` : null
}

const getBackendUrlHelp = (): string => {
  return (
    `To specify a backend, set an environment variable (directly or via yarn command):\n` +
    `* ${PARTIAL_URL_VAR}=<name> --> use ephemeral environment at https://vision.$\{name}.tpos.falsepill.com>\n` +
    `* ${PARTIAL_URL_VAR}=LOCAL --> use predefined existing environment\n` +
    `* ${FULL_URL_VAR}=<full URL, including http[s]:// and /graphql>\n` +
    ``
  ).trimRight()
}

export { FULL_URL_VAR, PARTIAL_URL_VAR, getBackendUrl, getBackendUrlHelp, getTPDashUrl, getRedirectUrl }
