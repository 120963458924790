import { useEffect } from 'react'
import useEditMode from 'hooks/useEditMode'

/**
 * This component lives under routes that have order ID params. When the user
 * navigates away from one of those routes, this automatically unlocks that
 * order if the user was previously in edit mode.
 */
export const OrderUnlocker = (): JSX.Element => {
  const [editMode, setEditMode] = useEditMode()

  // If the page is first loaded in edit mode, turn off edit mode. (Eventually
  // we want to be able to remove this override, but for now there are issues
  // with FormDataProvider not initializing properly.)
  useEffect(() => {
    if (editMode) {
      setEditMode(false)
    }
  }, [])

  // TODO Work with this code and test it a whole bunch.
  // If the user navigates away from the route that uses order IDs, we unlock the order.
  // useEffect(() => {
  //   const skipUnlocking = !orderId || !orderLock || !tokenContext || orderLock.user._id !== tokenContext.id

  //   return function cleanup() {
  //     console.log('[OrderUnlocker] - skipUnlocking', skipUnlocking)

  //     if (!skipUnlocking) {
  //       clearOrderLock()
  //     }
  //   }
  // }, [orderId, orderLock, tokenContext])

  return <></>
}
