import HotKeyToolTip from 'components/HotKeyToolTip'
import {
  ListRowLabel,
  ListRowValue,
  RaisedAnchor,
  RightFilledHeader,
  PaddingBlock,
  StyledSelect,
} from 'components/RXTable'
import useHotKey, { HotKeyLevel } from 'hooks/useHotKey'
import { usePlusClient } from 'providers/VisionRouter'
import type { EdiOverrides } from 'types'
import { RXEDIListRow, RXEDImageCell, RXEDIRightEditCell, RXEDIRightTitleCell, RXEDITitleRow, RXTextInput } from '..'

type PatientSegmentProps = {
  ediOverrides: EdiOverrides
  updateEdiOverrides: (input: EdiOverrides) => void
}

type ResidenceOption = {
  code: string
  description: string
}

const residenceOptions: ResidenceOption[] = [
  {
    code: '00',
    description: 'Not Specified',
  },
  {
    code: '01',
    description: 'Home',
  },
  {
    code: '02',
    description: 'Skilled Nursing Facility',
  },
  {
    code: '03',
    description: 'Nursing Facility',
  },
  {
    code: '04',
    description: 'Assisted Living Facility',
  },
  {
    code: '05',
    description: 'Custodial Care Facility',
  },
  {
    code: '06',
    description: 'Group Home',
  },
  {
    code: '07',
    description: 'Inpatient Psychiatric Facility',
  },
  {
    code: '08',
    description: 'Psychiatric Facility',
  },
  {
    code: '09',
    description: 'Intermediate Care Facility',
  },
  {
    code: '10',
    description: 'Residential Substance Abuse Treatment Facility',
  },
  {
    code: '11',
    description: 'Hospice',
  },
  {
    code: '12',
    description: 'Psychiatric Residential Treatment Facility',
  },
  {
    code: '13',
    description: 'Comprehensive Inpatient Rehabilitation Facility',
  },
  {
    code: '14',
    description: 'Homeless Shelter',
  },
  {
    code: '15',
    description: 'Correctional Institution',
  },
]

const PatientSegment = ({ ediOverrides, updateEdiOverrides }: PatientSegmentProps): JSX.Element => {
  const { patient } = ediOverrides
  const residence = residenceOptions.find(residenceOption => residenceOption.code === patient?.residence)
  const id = patient?.id

  return (
    <>
      <PatientHeaderRow />
      <RXEDIListRow>
        <RXEDIRightEditCell>
          <ListRowLabel>Patient Id (CY):</ListRowLabel>
          <ListRowValue>
            <RXTextInput
              value={id ?? ''}
              onChange={({ target: { value: id } }) => updateEdiOverrides({ patient: { id } })}
              placeholder="Id..."
            />
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>

      <RXEDIListRow>
        <RXEDIRightEditCell>
          <ListRowLabel>Residence (4X):</ListRowLabel>
          <ListRowValue>
            <StyledSelect
              disableClear
              value={residence ? `${residence?.code} - ${residence?.description}` : ''}
              placeholder={'Residence...'}
              options={residenceOptions.map(
                residenceOption => `${residenceOption.code} - ${residenceOption.description}`,
              )}
              onChange={([option]) =>
                updateEdiOverrides({ patient: { residence: option.value.split('-')[0].trim() as string } })
              }
            />
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>
    </>
  )
}

const PatientHeaderRow = () => {
  const { routeToHash } = usePlusClient()

  useHotKey('3', HotKeyLevel.normal, () => {
    routeToHash(`PatientSegment`)
  })

  return (
    <RXEDITitleRow>
      <RXEDImageCell />
      <RXEDIRightTitleCell>
        <PaddingBlock />
        <RightFilledHeader>
          <RaisedAnchor id={'PatientSegment'} />
          Patient segment (01)
          <HotKeyToolTip label={'3'} position={'right'} offsetLeft={1} offsetTop={0} />
        </RightFilledHeader>
      </RXEDIRightTitleCell>
    </RXEDITitleRow>
  )
}

export default PatientSegment
