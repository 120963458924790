import { useMutation } from '@truepill/tpos-react-router'
import { gql } from 'gql/generated'
import useErrorToast from 'hooks/toast/useErrorToast'
import useSuccessToast from 'hooks/toast/useSuccessToast'

const DISCONTINUE_PRESCRIPTIONS = gql(`
  mutation DiscontinuePrescriptions($orderId: ID!, $prescriptions: [DiscontinuePrescription]!) {
    discontinuePrescriptions(orderId: $orderId, prescriptions: $prescriptions) {
      successful {
        rxNumber
      }
      unsuccessful{
        rxNumber
        error
      }
    }
  }
`)

const useDiscontinuePrescriptions = ({ onCompleted, onError }: { onCompleted?: () => void; onError?: () => void }) => {
  const showErrorToast = useErrorToast()
  const showSuccessToast = useSuccessToast()

  const [discontinuePrescriptions, { loading: isDiscontinuePrescriptionsLoading }] = useMutation(
    DISCONTINUE_PRESCRIPTIONS,
    {
      onCompleted: response => {
        response.discontinuePrescriptions?.successful.forEach(res =>
          showSuccessToast(`Rx ${res?.rxNumber} has been successfuly discontinued`),
        )
        response.discontinuePrescriptions?.unsuccessful.forEach(res =>
          showErrorToast(`Rx ${res?.rxNumber} could not be discontinued. Error: ${res?.error}`),
        )
        onCompleted?.()
      },
      onError: error => {
        showErrorToast(error.message)
        onError?.()
      },
    },
  )

  return {
    discontinuePrescriptions,
    isDiscontinuePrescriptionsLoading,
  }
}

export default useDiscontinuePrescriptions
