import LargeIconWrapper from 'components/IconWrapper'
import { LargeButtonCard } from 'components/PageStructure'
import styled, { css } from 'styled-components'

const PagePadding = css`
  padding-left: 1.875rem;
  padding-right: 1.875rem;
`

const PageContainer = styled.div`
  display: flex;
  flex-basis: 100%;
  flex-wrap: wrap;
  padding-top: 1.25rem;
  position: relative;
`

const StyledLargeButtonCard = styled(LargeButtonCard)`
  margin-top: 0.625rem;
  width: 31%;
  > h2 {
    font-weight: 500;
    font-size: 1.3125rem;
    line-height: 1.5rem;
    font-family: Roboto;
  }
  @media (min-width: 1800px) {
    width: 500px;
    margin-right: 3rem;
  }
`

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  ${PagePadding}
  @media (min-width: 1800px) {
    justify-content: flex-start;
  }
`

const Title = styled.h1`
  font-size: 24px;
  width: 100%;
  margin-bottom: 0.8rem;
  ${PagePadding}
`

const IconWrapper = styled(LargeIconWrapper)`
  width: unset;
  margin-bottom: 1.125rem;
  svg {
    height: 2rem;
    width: 1.75rem;
    max-width: unset;
    max-height: unset;
  }
`

export { PageContainer, StyledLargeButtonCard, Content, Title, IconWrapper }
