import { useQuery } from '@truepill/tpos-react-router'
import { GET_BASIC_OUTBOUND_TRANSFER } from 'gql'
import { useTPCacheContext } from 'providers/TPCacheProvider'
import type { OutboundTransfer, Prescription, Patient, Log } from 'types'
import type { LoadingError } from './types'

export interface BasicOutboundTransfer extends LoadingError {
  outboundTransfer?: OutboundTransfer
  notes?: Log[]
  patient?: Patient
  prescription?: Prescription
}

const OutboundTransferDefaults: BasicOutboundTransfer = {
  outboundTransfer: undefined,
}

/**
 * @param outboundTransferId If undefined, an undefined outbound transfer will be returned.
 */
export default function useOutboundTransfer(outboundTransferId?: string): BasicOutboundTransfer {
  const { getCustomerById, getLocationById } = useTPCacheContext()

  const outboundTransfer = useQuery(GET_BASIC_OUTBOUND_TRANSFER, {
    skip: !outboundTransferId,
    variables: { outboundTransferId },
  })

  const { loading, error, data } = outboundTransfer

  if (loading || error)
    return {
      loading: loading,
      error: error,
    } as BasicOutboundTransfer

  let value: BasicOutboundTransfer = { ...OutboundTransferDefaults }

  if (outboundTransferId) {
    const ot = data.getOutboundTransfer as OutboundTransfer
    const outboundTransfer = Object.freeze({
      ...ot,
      customer: getCustomerById(ot.prescription?.customerId),
      location: getLocationById(ot.prescription?.locationId),
    })

    value = Object.assign({}, value, {
      loading,
      error,
      outboundTransfer: Object.freeze(outboundTransfer),
      prescription: outboundTransfer.prescription,
      patient: outboundTransfer.prescription?.patient,
    })
  }

  return value
}
