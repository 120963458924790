import { useMutation } from '@truepill/tpos-react-router'
import { ReactComponent as PlusIcon } from 'assets/icons/capsule/plus.svg'
import { ActionButtonOutline } from 'components/ActionButton'
import IconWrapper from 'components/IconWrapper'
import { CREATE_USER } from 'gql'
import useErrorToast from 'hooks/toast/useErrorToast'
import SimpleModal from 'modals/SimpleModal'
import UserModal from 'modals/UserModal'
import { useModalContext } from 'providers/Overlays/ModalProvider'

type NewTeamMemberProps = {
  locationId?: string
}

const NewTeamMember = ({ locationId }: NewTeamMemberProps): JSX.Element => {
  const { showModal } = useModalContext()
  const showErrorToast = useErrorToast(true)
  const refetchQueries = [locationId ? 'getUsersByLocation' : 'searchUsers']

  const [createUserMutation] = useMutation(CREATE_USER, {
    onCompleted({ createUser }) {
      showModal(() => (
        <SimpleModal title={'User Created'} hideCancel>
          <p>
            The user <strong>{createUser.username}</strong> has been created with the temporary password:{' '}
            <strong>{createUser.oneTimePassword}</strong>
          </p>
        </SimpleModal>
      ))
    },
    onError(err) {
      showErrorToast(`Failed to create user! ${err.message.replace('GraphQL error:', '')}`)
    },
  })

  return (
    <ActionButtonOutline
      icon={
        <IconWrapper>
          <PlusIcon />
        </IconWrapper>
      }
      hotKey="n"
      label="New employee"
      onClick={() =>
        showModal(() => (
          <UserModal
            locationId={locationId}
            confirmationCallback={({ firstName, lastName, preferredName, roles, email, locationId, licenses }) =>
              createUserMutation({
                variables: {
                  firstName: firstName.trim(),
                  lastName: lastName.trim(),
                  preferredName: preferredName ? preferredName.trim() : undefined,
                  roles,
                  email: email.trim(),
                  locationId,
                  licenses,
                },
                refetchQueries,
              })
            }
          />
        ))
      }
    />
  )
}

export default NewTeamMember
