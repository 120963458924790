import type React from 'react'
import { useState } from 'react'
import { useMutation } from '@truepill/tpos-react-router'
import { InsuranceCardType } from '@truepill/tpos-types'
import { ReactComponent as CheckmarkIcon } from 'assets/icons/checkmark.svg'
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg'
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg'
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg'
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg'
import HotKeyToolTip from 'components/HotKeyToolTip'
import IconWrapper from 'components/IconWrapper'
import { ButtonsContainer } from 'components/Modal'
import { ActionButton, CancelButton, SaveButton } from 'components/PageStructure'
import RXScanImage from 'components/RXScanImage'
import {
  StyledSelect,
  ListRowLabel,
  ListRowValue,
  RXCell,
  AlternatingShading,
  RaisedAnchor,
  ImageFilledHeader,
} from 'components/RXTable'
import type { Option } from 'components/Select'
import {
  ADD_ORDER_INSURANCE,
  ADD_ORDER_INSURANCE_OPTION,
  SET_ORDER_INSURANCE,
  UPDATE_ORDER_FILL_REQUEST_INSURANCE_DURS,
  UPDATE_ORDER_FILL_REQUEST_INSURANCE_OVERRIDE_CODES,
  UPDATE_ORDER_INSURANCE,
  UPDATE_ORDER_FILL_REQUEST_INSURANCE_COB,
  REMOVE_ORDER_INSURANCE,
  ADD_COPAY_INSURANCE,
  ADD_COPAY_INSURANCE_OPTION,
  SET_COPAY_INSURANCE,
  REMOVE_COPAY_INSURANCE,
  UPDATE_COPAY_INSURANCE,
  UPDATE_COPAY_REQUEST_FILL_INSURANCE_DURS,
  UPDATE_COPAY_REQUEST_FILL_INSURANCE_OVERRIDE_CODES,
  UPDATE_COPAY_REQUEST_FILL_INSURANCE_COB,
} from 'gql'
import { Box } from 'grommet'
import useErrorToast from 'hooks/toast/useErrorToast'
import useHotKey, { HotKeyLevel } from 'hooks/useHotKey'
import EditPayer from 'modals/EditPayer'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import { usePlusClient } from 'providers/VisionRouter'
import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'
import {
  primaryBackgroundColor,
  primaryColor,
  contrastBackgroundColor,
  primaryActiveButtonBackground,
  confirmColor,
  errorRed,
} from 'styles/styleVariables'
import type { Fill, Insurance, OrderInsurance, InsurancePayer } from 'types'
import { InsuranceDesignationType } from 'types'
import { diffObjects, stripTypename, swapArrayElements, getInsuranceKey } from 'utils'
import NewPayerDetails from './EditSections/NewPayerDetails'

export type PayerStatus = 'success' | 'failed' | 'previousSuccess' | undefined

export const billingOrderDesignationType = [
  InsuranceDesignationType.Primary,
  InsuranceDesignationType.Secondary,
  InsuranceDesignationType.Tertiary,
]

const getInsuranceLabel = ({
  name,
  pcn,
  bin,
  cardholderId,
  group,
  billingOrder,
}: {
  name?: string
  pcn?: string
  bin?: string
  cardholderId?: string
  group?: string
  billingOrder?: number
}): string => {
  const text = `${name ? `${name} ` : ''}${bin ?? ''} | ${pcn ?? ''} | ${group ?? ''} | ${cardholderId ?? ''}`
  return `${text}${typeof billingOrder === 'number' ? ` (${billingOrderDesignationType[billingOrder]})` : ''}`
}

const removeNullAndUndefinedKeys = (obj: Record<string, unknown>) =>
  Object.fromEntries(Object.entries(obj).filter(([, value]) => value !== undefined && value !== null))

type ReviewPayerProps = {
  styles?: FlattenSimpleInterpolation
  containerStyles?: FlattenSimpleInterpolation
  insurances: OrderInsurance[]
  insuranceOptions: Insurance[]
  itemId: string
  nonEditable: boolean
  setInsurancesState: (insurances: OrderInsurance[]) => void
  fill: Fill
  payerStatuses: Record<string, PayerStatus>
  insuranceImages: { insuranceOptionId: string; url: string }[]
  claimJumperErrors: string[]
  isOrder: boolean
}

const ReviewPayer = ({
  styles,
  containerStyles,
  insurances,
  insuranceOptions,
  setInsurancesState,
  itemId,
  payerStatuses,
  nonEditable,
  fill,
  insuranceImages = [],
  claimJumperErrors,
  isOrder,
}: ReviewPayerProps): React.JSX.Element => {
  const fillId = fill._id

  const [removeOrderInsurance] = useMutation(REMOVE_ORDER_INSURANCE)
  const [addOrderInsurance] = useMutation(ADD_ORDER_INSURANCE)
  const [addOrderInsuranceOption] = useMutation(ADD_ORDER_INSURANCE_OPTION)
  const [setOrderInsurance] = useMutation(SET_ORDER_INSURANCE)
  const [updateOrderInsurance] = useMutation(UPDATE_ORDER_INSURANCE)
  const [updateOrderFillRequestInsuranceDurs] = useMutation(UPDATE_ORDER_FILL_REQUEST_INSURANCE_DURS)
  const [updateOrderFillRequestInsuranceOverrideCodes] = useMutation(UPDATE_ORDER_FILL_REQUEST_INSURANCE_OVERRIDE_CODES)
  const [updateOrderFillRequestInsuranceCobs] = useMutation(UPDATE_ORDER_FILL_REQUEST_INSURANCE_COB)

  const [addCopayInsurance] = useMutation(ADD_COPAY_INSURANCE)
  const [addCopayInsuranceOption] = useMutation(ADD_COPAY_INSURANCE_OPTION)
  const [removeCopayInsurance] = useMutation(REMOVE_COPAY_INSURANCE)
  const [setCopayInsurance] = useMutation(SET_COPAY_INSURANCE)
  const [updateCopayInsurance] = useMutation(UPDATE_COPAY_INSURANCE)
  const [updateCopayRequestFillInsuranceDurs] = useMutation(UPDATE_COPAY_REQUEST_FILL_INSURANCE_DURS)
  const [updateCopayRequestFillInsuranceOverrideCodes] = useMutation(UPDATE_COPAY_REQUEST_FILL_INSURANCE_OVERRIDE_CODES)
  const [updateCopayRequestFillInsuranceCobs] = useMutation(UPDATE_COPAY_REQUEST_FILL_INSURANCE_COB)

  const { showModal, dismissModal } = useModalContext()
  // toggles the active payer seen by user ( if the user clicks on the button whilst active it will hide it)
  const [activePayerIndex, setActivePayerIndex] = useState(-1) // pass the index of the active payer
  const showActivePayer = activePayerIndex >= 0
  const [newPayerFormIndex, setNewPayerFormIndex] = useState(-1) // pass the index where the new payer should appear
  const showNewPayerForm = newPayerFormIndex >= 0
  const [newPayer, setNewPayer] = useState<InsurancePayer>({
    bin: '',
    group: '',
    cardholderId: '',
    pcn: '',
    name: '',
    helpdeskNumber: '',
    personCode: '',
    relationship: 'Cardholder',
    cardType: InsuranceCardType.NotSpecified,
  })

  const maxSlotsSelected = insurances.length === insuranceOptions.length

  const getAllInsuranceOptions = (insurances: Insurance[]): Option[] | string[] => {
    if (insurances) {
      const existingInsurances = insurances.map((insurance, index) => ({
        label: getInsuranceLabel({
          name: insurance.name,
          pcn: insurance.pcn,
          bin: insurance.bin,
          cardholderId: insurance.cardholderId,
          group: insurance.group,
        }),
        value: index,
      }))
      existingInsurances.push({ label: 'Create New Payer', value: -1 })
      return existingInsurances
    } else {
      return [
        { label: '', value: '' },
        { label: 'Create New Payer', value: -1 },
      ]
    }
  }

  const resetInsurance = (index: number) => {
    const updatedInsurances = insurances.slice()
    const referenceInsurance = insuranceOptions.find(i => i._id === updatedInsurances[index].orderInsuranceOptionId)
    if (referenceInsurance) {
      updatedInsurances[activePayerIndex] = {
        ...referenceInsurance,
        _id: updatedInsurances[activePayerIndex]._id,
        orderInsuranceOptionId: updatedInsurances[activePayerIndex].orderInsuranceOptionId,
      }
      setInsurancesState(updatedInsurances)
    }
  }

  const addPayerSlot = async (): Promise<void> => {
    const usedInsuranceOptionIds = insurances.map(i => i.orderInsuranceOptionId)
    const newInsuranceIndex = insuranceOptions.findIndex(i => !usedInsuranceOptionIds.includes(i._id))
    const newOrderInsurance = { ...insuranceOptions[newInsuranceIndex] }

    if (newInsuranceIndex !== -1 && newOrderInsurance) {
      const newPayer: InsurancePayer = {
        orderInsuranceOptionId: newOrderInsurance._id,
        bin: newOrderInsurance.bin,
        pcn: newOrderInsurance.pcn,
        group: newOrderInsurance.group,
        cardholderId: newOrderInsurance.cardholderId,
        name: newOrderInsurance.name,
        helpdeskNumber: newOrderInsurance.helpdeskNumber,
        personCode: newOrderInsurance.personCode,
        relationship: newOrderInsurance.relationship,
        cardType: InsuranceCardType.NotSpecified,
        coreInsuranceToken: newOrderInsurance.coreInsuranceToken,
      }

      isOrder
        ? await addOrderInsurance({
            variables: { orderId: itemId, addInsurance: { ...newPayer } },
          }).catch(err => showErrorToast('Failed to create new payer: ' + err))
        : await addCopayInsurance({
            variables: { copayRequestId: itemId, addInsurance: { ...newPayer } },
          }).catch(err => showErrorToast('Failed to create new payer: ' + err))
    }
  }

  const removePayerSlot = async (index: number): Promise<void> => {
    isOrder
      ? await removeOrderInsurance({ variables: { orderId: itemId, insuranceId: insurances[index]._id } }).catch(err =>
          showErrorToast('Failed to remove insurance: ' + err),
        )
      : await removeCopayInsurance({
          variables: { copayRequestId: itemId, insuranceId: insurances[index]._id },
        }).catch(err => showErrorToast('Failed to remove insurance: ' + err))
    setActivePayerIndex(-1)
  }

  const onPayerSelected = async (option: { value: number }, index: number): Promise<void> => {
    const selectedPayer = Number(option.value)
    if (selectedPayer === -1) {
      // call function to display New Payer Form
      setNewPayerFormIndex(index)
    } else {
      if (selectedPayer < insuranceOptions.length) {
        const { durs, overrideCodes, ...selectedInsuranceObj } = insuranceOptions[selectedPayer]
        let updatedInsurances = insurances.map(({ durs, overrideCodes, ...insuranceData }) => insuranceData)

        const matchedIndex = insurances.findIndex(i => i.orderInsuranceOptionId === selectedInsuranceObj._id)

        if (matchedIndex === -1) {
          updatedInsurances[index] = {
            ...selectedInsuranceObj,
            _id: updatedInsurances[index]?._id,
            orderInsuranceOptionId: selectedInsuranceObj._id,
          }
        } else {
          updatedInsurances = swapArrayElements(updatedInsurances, index, matchedIndex)
        }

        updatedInsurances = stripTypename(updatedInsurances)
        isOrder
          ? await setOrderInsurance({ variables: { orderId: itemId, setInsurance: updatedInsurances } }).catch(err => {
              showErrorToast('Failed to set insurances: ' + err)
            })
          : await setCopayInsurance({ variables: { copayRequestId: itemId, setInsurance: updatedInsurances } }).catch(
              err => {
                showErrorToast('Failed to set insurances: ' + err)
              },
            )
      }

      setNewPayerFormIndex(-1)
    }
  }

  const handleNewPayer = async (index: number) => {
    const result = isOrder
      ? await addOrderInsuranceOption({
          variables: { orderId: itemId, addInsurance: { ...newPayer } },
        }).catch(err => showErrorToast('Failed to create new payer: ' + err))
      : await addCopayInsuranceOption({
          variables: { copayRequestId: itemId, addInsurance: { ...newPayer } },
        }).catch(err => showErrorToast('Failed to create new payer: ' + err))

    if (!result) {
      return
    }

    insuranceOptions = isOrder
      ? result.data.addOrderInsuranceOption.insuranceOptions
      : result.data.addCopayInsuranceOption.insuranceOptions

    await onPayerSelected({ value: insuranceOptions.length - 1 }, index)

    // close the new payer form
    cancelNewPayerForm()

    // closes payer info if its open
    if (activePayerIndex >= 0) {
      resetInsurance(activePayerIndex)
      setActivePayerIndex(-1)
    }
  }

  const handleUpdateInsurance = async (updatedInsurances: OrderInsurance) => {
    const oldInsurances = insurances.slice()
    oldInsurances[activePayerIndex] = updatedInsurances
    setInsurancesState(oldInsurances)

    const {
      durs: newDurs = [],
      overrideCodes: newOverrides = { orderInsuranceOptionId: null },
      cobOverrides: newCobOverrides = { orderInsuranceOptionId: null },
      ...newPayer
    } = updatedInsurances
    const {
      durs: oldDurs = [],
      overrideCodes: oldOverrides = { orderInsuranceOptionId: null },
      cobOverrides: oldCobOverrides = { orderInsuranceOptionId: null },
      ...oldPayer
    } = insuranceOptions.find(i => i._id === newPayer.orderInsuranceOptionId) || {}
    const payerDiff: OrderInsurance = stripTypename(diffObjects(oldPayer, newPayer)) as OrderInsurance
    const { _id, orderInsuranceOptionId, ...patch } = payerDiff
    const dursNeedUpdate =
      newDurs.length !== oldDurs.length ||
      newDurs?.some((newDur, i) => Object.keys(diffObjects(newDur, oldDurs[i])).length > 0)

    if (Object.keys(patch).length) {
      const result = isOrder
        ? await updateOrderInsurance({
            variables: { orderId: itemId, insuranceId: newPayer.orderInsuranceOptionId, patch },
          }).catch(err => {
            showErrorToast('Failed to update payer: ' + err)
          })
        : await updateCopayInsurance({
            variables: { copayRequestId: itemId, insuranceId: newPayer.orderInsuranceOptionId, patch },
          }).catch(err => {
            showErrorToast('Failed to update payer: ' + err)
          })

      if (!result) {
        return
      }
    }

    if (dursNeedUpdate) {
      const insuranceDurs = newDurs
        .map(({ orderInsuranceOptionId, ...dur }) => dur)
        .map(dur => removeNullAndUndefinedKeys(dur))
        .filter(dur => Object.keys(dur).length > 0) // remove dur if all fields were cleared
      const result = isOrder
        ? await updateOrderFillRequestInsuranceDurs({
            variables: {
              orderId: itemId,
              orderInsuranceOptionId: newPayer.orderInsuranceOptionId,
              fillId,
              insuranceDurs,
            },
          })
        : await updateCopayRequestFillInsuranceDurs({
            variables: {
              copayRequestId: itemId,
              orderInsuranceOptionId: newPayer.orderInsuranceOptionId,
              fillId,
              insuranceDurs,
            },
          })

      if (!result) {
        return
      }
    }
    const { orderInsuranceOptionId: newId, ...newOverridesValues } = newOverrides
    const { orderInsuranceOptionId: oldId, ...oldOverridesValues } = oldOverrides
    const overridesNeedUpdate = Object.keys(diffObjects(newOverridesValues, oldOverridesValues)).length > 0

    if (overridesNeedUpdate) {
      // newOverridesValues = removeNullAndUndefinedKeys(newOverridesValues)

      const result = isOrder
        ? await updateOrderFillRequestInsuranceOverrideCodes({
            variables: {
              orderId: itemId,
              fillId,
              insuranceOverrideCodes: newOverrides,
            },
          })
        : await updateCopayRequestFillInsuranceOverrideCodes({
            variables: {
              copayRequestId: itemId,
              fillId,
              insuranceOverrideCodes: newOverrides,
            },
          })

      if (!result) {
        return
      }
    }

    const { orderInsuranceOptionId: newInsuranceId, ...newCobOverridesValues } = newCobOverrides
    const { orderInsuranceOptionId: oldInsuranceId, ...oldCObOverridesValues } = oldCobOverrides
    const cobNeedsUpdate = Object.keys(diffObjects(newCobOverridesValues, oldCObOverridesValues)).length > 0
    if (cobNeedsUpdate) {
      const result = isOrder
        ? await updateOrderFillRequestInsuranceCobs({
            variables: {
              orderId: itemId,
              fillId,
              insuranceCobs: newCobOverrides,
            },
          })
        : await updateCopayRequestFillInsuranceCobs({
            variables: {
              copayRequestId: itemId,
              fillId,
              insuranceCobs: newCobOverrides,
            },
          })

      if (!result) {
        return
      }
    }

    setActivePayerIndex(-1)
    dismissModal()
  }

  const handleCloseUpdateInsuranceModal = async () => {
    setActivePayerIndex(-1)
    dismissModal()
  }

  const cancelNewPayerForm = () => {
    setNewPayerFormIndex(-1)
    setNewPayer({
      bin: '',
      group: '',
      cardholderId: '',
      pcn: '',
      name: '',
      helpdeskNumber: '',
      personCode: '',
      relationship: '',
      cardType: InsuranceCardType.NotSpecified,
    })
  }

  const showErrorToast = useErrorToast()

  return (
    <>
      <HeaderRow />
      <PayerFormContainer styles={containerStyles}>
        {showNewPayerForm ? (
          <>
            <NewPayerDetails newPayer={newPayer} setNewPayer={setNewPayer} />
            <ButtonsContainer>
              <CancelButton label={'Cancel'} onClick={cancelNewPayerForm} />
              <SaveButton
                disabled={newPayer.bin?.length === 0}
                label={'Create'}
                onClick={() => handleNewPayer(newPayerFormIndex)}
              />
            </ButtonsContainer>
          </>
        ) : (
          insurances?.map(
            (
              { _id: insuranceId, orderInsuranceOptionId, cardholderId, name, helpdeskNumber, bin, pcn, group },
              currentIndex,
            ) => {
              const insuranceKey = bin && cardholderId ? getInsuranceKey(bin, cardholderId, pcn) : undefined
              const payerStatus = insuranceKey ? payerStatuses[insuranceKey] : undefined
              const insuranceLabel = getInsuranceLabel({ name, pcn, bin, cardholderId, group })
              const insuranceImage = insuranceImages.find(
                (i: { insuranceOptionId: string }) =>
                  i.insuranceOptionId.toString() === orderInsuranceOptionId?.toString() ||
                  i.insuranceOptionId.toString() === insuranceId.toString(),
              )?.url

              return (
                <div key={`payers-${currentIndex}`}>
                  <PayerFormList styles={styles}>
                    <PayerFormCell key={currentIndex}>
                      <RXFormRow key={currentIndex}>
                        <ListRowLabel>
                          <Box direction="row" align="center">
                            <PayerState status={payerStatus} /> {billingOrderDesignationType[currentIndex] ?? 'Other'}:
                          </Box>
                        </ListRowLabel>
                        <PayerFormListRowValue>
                          <PayerName>
                            <PayerStyledSelect
                              disableClear={true}
                              disabled={nonEditable}
                              value={insuranceLabel}
                              placeholder={insuranceLabel}
                              options={getAllInsuranceOptions(insuranceOptions)}
                              preventScrolling={false}
                              onChange={([option]: Option<number>[]) => onPayerSelected(option, currentIndex)}
                            ></PayerStyledSelect>
                          </PayerName>
                          <StyledButton
                            data-testid="override-button"
                            disabled={nonEditable}
                            active={activePayerIndex === currentIndex}
                            styles={css`
                              display: flex;
                              justify-content: center;
                              align-items: center;
                            `}
                            onClick={() => {
                              setNewPayerFormIndex(-1)
                              if (showActivePayer && activePayerIndex === currentIndex) {
                                setActivePayerIndex(-1)
                                resetInsurance(currentIndex)
                              } else {
                                setActivePayerIndex(currentIndex)
                              }
                              showModal(() => (
                                <EditPayer
                                  originalOrderInsurance={insurances[currentIndex]}
                                  payerIndex={currentIndex}
                                  onUpdate={handleUpdateInsurance}
                                  onClose={handleCloseUpdateInsuranceModal}
                                  claimJumperErrors={claimJumperErrors}
                                  claims={fill.claims}
                                />
                              ))
                            }}
                          >
                            <EditIcon fill={'black'} />
                          </StyledButton>
                          {currentIndex > 0 && (
                            <StyledButton
                              data-testid="override-button"
                              disabled={nonEditable}
                              active={activePayerIndex === currentIndex}
                              onClick={async () => {
                                await removePayerSlot(currentIndex)
                              }}
                            >
                              <DeleteIcon fill={'black'} />
                            </StyledButton>
                          )}
                        </PayerFormListRowValue>
                      </RXFormRow>
                    </PayerFormCell>
                    <PayerFormCell>
                      <RXFormRow>
                        <ListRowLabel>Pricing:</ListRowLabel>
                        <ListRowValue>0</ListRowValue>
                      </RXFormRow>
                    </PayerFormCell>
                    <PayerFormCell>
                      <RXFormRow>
                        <ListRowLabel>Help desk:</ListRowLabel>
                        <ListRowValue>{`${helpdeskNumber || '- unknown -'}`}</ListRowValue>
                      </RXFormRow>
                    </PayerFormCell>
                  </PayerFormList>
                  {insuranceImage && !showNewPayerForm && <RXScanImage src={insuranceImage} inline={true} />}
                </div>
              )
            },
          )
        )}
        {!showNewPayerForm && !showActivePayer && !nonEditable && (
          <ButtonWrapper>
            <StyledActionButton
              icon={
                <IconWrapper>
                  <PlusIcon fill={'black'} />
                </IconWrapper>
              }
              onClick={async () => {
                if (!insuranceOptions.length) {
                  // Creates new payer form because we have no insurance options on the order
                  await onPayerSelected({ value: -1 }, 0)
                } else if (maxSlotsSelected) {
                  // Creates new payer form because we have selected all the insurance options on the order
                  await onPayerSelected({ value: -1 }, insurances.length)
                } else {
                  await addPayerSlot()
                }
              }}
              label={`Add ${billingOrderDesignationType[insurances?.length || 0] || 'Another'} Payer`}
            />
          </ButtonWrapper>
        )}
      </PayerFormContainer>
    </>
  )
}

const HeaderRow = (): React.JSX.Element => {
  const { routeToHash } = usePlusClient()

  useHotKey('2', HotKeyLevel.normal, () => {
    routeToHash(`Payer`)
  })

  return (
    <RXImageFilledHeader>
      <RaisedAnchor id={'Payer'} />
      Payer
      <HotKeyToolTip label={'2'} position={'right'} offsetLeft={3} offsetTop={-1} />
    </RXImageFilledHeader>
  )
}

const ButtonWrapper = styled.div`
  display: flex;
`

const RXImageFilledHeader = styled(ImageFilledHeader)`
  grid-column: 1 / span 3;
  margin-bottom: 1rem;
`

const InactiveButton = css`
  background-color: ${primaryBackgroundColor};
`

const StyledActionButton = styled(ActionButton)`
  ${InactiveButton};

  :hover {
    border: 2px solid ${primaryColor};
  }

  margin-left: 0;
`

const PayerName = styled.div`
  width: 70%;
  flex-grow: 1;
`

const PayerFormContainer = styled.div<{
  styles?: FlattenSimpleInterpolation
}>`
  display: flex;
  flex-basis: 100%;
  flex-wrap: wrap;
  background-color: white;
  margin-bottom: 0.44rem;
  justify-content: flex-start;
  ${({ styles }) => styles};
`
const PayerFormCell = styled.ul<{
  even?: boolean
}>`
  ${AlternatingShading}
`

export const StyledButton = styled.button<{
  active?: boolean
  styles?: FlattenSimpleInterpolation
}>`
  margin: 0.25rem;
  max-height: 2.5rem;
  max-width: 2.5rem;
  border-radius: 0.3rem;
  background-color: ${contrastBackgroundColor};
  border: 0.1rem solid ${contrastBackgroundColor};

  :hover {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
  }

  :focus {
    outline: 0;
    box-shadow: 0 0 0.625rem ${primaryColor};
  }

  ${({ active }) =>
    active &&
    css`
      background-color: ${primaryActiveButtonBackground};
      color: ${primaryBackgroundColor};
      border: 0.1rem solid ${primaryColor};

      > svg {
        fill: ${primaryBackgroundColor};
      }

      > div {
        > svg {
          fill: ${primaryBackgroundColor};
        }
      }
    `}
  ${({ styles }) => styles}
`

const PayerFormListRowValue = styled(ListRowValue)`
  padding-right: unset;
`

const PayerFormList = styled.div<{
  styles?: FlattenSimpleInterpolation
}>`
  width: 410px;
  @media screen and (min-width: 1356px) {
    width: 29.4vw;
  }
  @media screen and (min-width: 1702px) {
    width: 30.2vw;
  }
  @media screen and (min-width: 1850px) {
    width: 32.6vw;
  }
  :not(:nth-child(3)) {
    margin-right: 1.25rem;
  }
  margin-bottom: 1.25rem;
  ${({ styles }) => styles}
`

const RXFormRow = styled(RXCell)`
  grid-template-columns: [label] 6.5rem [value] 1fr;
`

const PayerStyledSelect = styled(StyledSelect)`
  margin: 0.225rem;
  width: 98%;
  font-weight: 400;
`

const PayerStateStyles = styled.span`
  box-sizing: border-box;
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  background: ${confirmColor};
  margin-left: 0;
  margin-right: 0.5rem;
  line-height: 1rem;
  text-align: center;
`

const PayerStateSuccessStyles = styled(PayerStateStyles)`
  background: ${confirmColor};
  padding: 0 0.0975rem;
`

const PayerStateFailedStyles = styled(PayerStateStyles)`
  background: ${errorRed};
  & > svg {
    position: relative;
    bottom: 0.05rem;
  }
`

type PayerStateProps = { status: PayerStatus }

const PayerState = ({ status }: PayerStateProps): React.JSX.Element => {
  if (status === undefined) return <></>

  if (status === 'failed') {
    return (
      <PayerStateFailedStyles>
        <WarningIcon fill="white" />
      </PayerStateFailedStyles>
    )
  }

  return <PayerStateSuccessStyles>{status === 'success' && <CheckmarkIcon />}</PayerStateSuccessStyles>
}

export default ReviewPayer
