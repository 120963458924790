import { useState, useEffect } from 'react'
import { Grid, GridItem } from '@truepill/react-capsule'
import { useLazyQuery } from '@truepill/tpos-react-router'
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg'
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg'
import { ActionButtonOutline } from 'components/ActionButton'
import BreadCrumb from 'components/BreadCrumb'
import IconWrapper, { LargeIconWrapper } from 'components/IconWrapper'
import { CapsulePageContainer, PageContent, CapsulePageHeading, CapsuleTitle } from 'components/PageStructure'
import Pagination from 'components/Pagination'
import type { TableDefinition } from 'components/Table'
import Table from 'components/Table'
import { LIST_INVENTORY_GROUPS } from 'gql'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import styled from 'styled-components'
import { capsulePrimaryColorDark } from 'styles/styleVariables'
import type { InventoryGroup } from 'types'
import AddInventoryGroupModal from './components/AddInventoryGroupModal'
import EditInventoryGroupModal from './components/EditInventoryGroupModal'

interface TableRow {
  pioneerAlias: string
  customer?: string
  customerId: string
  _id: string
  active: boolean
}

interface useListInventoryGroupQueryWithPaginationProps {
  pageSize: number
}

interface EditActionButtonProps {
  data: TableRow
}

const useListInventoryGroupWithPagination = ({ pageSize }: useListInventoryGroupQueryWithPaginationProps) => {
  const [results, setResults] = useState<InventoryGroup[]>([])
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(pageSize)
  const [totalResults, setTotalResults] = useState(0)
  const [totalPages, setTotalPages] = useState(1)
  const [listInventoryGroups, { data: inventoryGroups, loading, error }] = useLazyQuery(LIST_INVENTORY_GROUPS)
  useEffect(() => {
    if (inventoryGroups) {
      const totalRecords = inventoryGroups?.listInventoryGroups?.totalRecords
      setTotalResults(totalRecords)
      setTotalPages(Math.ceil(totalRecords / limit))
      setResults(inventoryGroups?.listInventoryGroups?.records)
    }
  }, [inventoryGroups, limit])

  useEffect(() => {
    listInventoryGroups?.({
      variables: {
        activeOnly: false,
        pageNumber: page,
        pageSize: limit,
      },
    })
  }, [listInventoryGroups, limit, page])
  return { inventoryGroups: results, totalResults, totalPages, loading, error, setPage, setLimit, page, limit }
}

const parseInventoryGroupData = (data?: InventoryGroup[]) => {
  return data
    ? data.map(({ customer, customerId, _id, pioneerAlias, active }) => {
        return {
          _id,
          pioneerAlias,
          customer: customer?.name,
          customerId,
          active: !!active,
        }
      })
    : []
}

const EditActionButton = ({ data }: EditActionButtonProps) => {
  const { showModal } = useModalContext()
  if (data.pioneerAlias === 'Rx') {
    return <></>
  }

  return (
    <LargeIconWrapper>
      <EditIcon
        style={{ cursor: 'pointer' }}
        fill={capsulePrimaryColorDark}
        onClick={() => {
          showModal(() => (
            <EditInventoryGroupModal
              customerId={data.customerId}
              inventoryGroupId={data._id}
              pioneerAlias={data.pioneerAlias}
              active={data.active}
            />
          ))
        }}
      />
    </LargeIconWrapper>
  )
}

const tableDataDefinition: TableDefinition<TableRow>[] = [
  { field: 'pioneerAlias', headerName: 'Pioneer Alias', width: '2fr', minWidth: '12rem' },
  { field: 'customer', headerName: 'Customer', width: '3fr', minWidth: '30rem' },
  {
    field: 'active',
    headerName: 'Active?',
    width: '1fr',
    minWidth: '6rem',
    customRender: (data: TableRow) => (
      <Grid>
        <GridItem>{data.active ? 'True' : 'False'}</GridItem>
      </Grid>
    ),
  },
  {
    headerName: 'Actions',
    minWidth: '4rem',
    width: '4rem',
    customRender: (data: TableRow) => (
      <Grid>
        <GridItem>
          <EditActionButton data={data} />
        </GridItem>
      </Grid>
    ),
  },
]

const InventoryGroupAdministration = (): JSX.Element => {
  const { showModal } = useModalContext()
  const {
    inventoryGroups,
    loading,
    setPage,
    setLimit,
    totalPages,
    totalResults,
    page: currentPage,
    limit,
  } = useListInventoryGroupWithPagination({
    pageSize: 10,
  })
  return (
    <CapsulePageContainer>
      <BreadCrumb useCapsuleDesign />
      <CapsulePageHeading>
        <CapsuleTitle>Inventory Groups</CapsuleTitle>
        <div>
          <ActionButtonOutline
            icon={
              <IconWrapper>
                <PlusIcon />
              </IconWrapper>
            }
            hotKey="n"
            label="Add inventory group"
            onClick={() => {
              showModal(() => <AddInventoryGroupModal />)
            }}
          />
        </div>
      </CapsulePageHeading>
      <PageContent>
        <TableWrapper>
          <Table
            rowStyle={{ alignItems: 'center' }}
            definition={tableDataDefinition}
            data={parseInventoryGroupData(inventoryGroups)}
            loading={loading}
            keyField="_id"
          />
          <Pagination
            onChangePage={setPage}
            totalRecords={totalResults}
            currentPage={currentPage}
            totalPages={totalPages}
            pagesForEachSide={1}
            rowsPerPage={limit}
            rowsPerPageOptions={[10, 25, 50]}
            onChangeRowsPerPage={setLimit}
          />
        </TableWrapper>
      </PageContent>
    </CapsulePageContainer>
  )
}

const TableWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
`

export default InventoryGroupAdministration
