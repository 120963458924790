import type { Dispatch, SetStateAction } from 'react'
import { useState, useEffect, useCallback } from 'react'
import { Select, Button } from '@truepill/react-capsule'
import type { SendBackReason } from '@truepill/tpos-types'
import CustomizedTextField from 'components/CustomizedTextField'
import { ModalWrapper, ModalHeader, ModalTitle } from 'components/Modal'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import styled from 'styled-components'
import { btnPrimary } from 'styles/styleVariables'

type Tabs = {
  title: string
  options: string[]
  id: string
}

const SendBackModal = ({
  confirmationCallback,
  tabs,
}: {
  confirmationCallback: (tabId: string, reason: SendBackReason, message?: string) => void
  tabs: Tabs[]
}): JSX.Element => {
  const { dismissModal } = useModalContext()
  return (
    <ModalWrapper id="SendBackModal" styles={[{ overflowY: 'visible' }]}>
      <ModalHeader>
        <ModalTitle>Send back</ModalTitle>
      </ModalHeader>
      <SendBackTabs confirmationCallback={confirmationCallback} tabs={tabs} dismissModal={dismissModal} />
    </ModalWrapper>
  )
}

const SendBackTabs = ({
  tabs,
  dismissModal,
  confirmationCallback,
}: {
  tabs: Tabs[]
  dismissModal: () => void
  confirmationCallback: (tabId: string, reason: SendBackReason, message?: string) => void
}): JSX.Element => {
  const [selectedTabId, setSelectedTabId] = useState(tabs[0].id)
  return (
    <div>
      <TabsContainer>
        {tabs.length > 1 &&
          tabs.map(tab => {
            return (
              <Tab
                key={tab.id}
                title={tab.title}
                selected={selectedTabId === tab.id}
                tabId={tab.id}
                setSelectedTabId={setSelectedTabId}
              />
            )
          })}
      </TabsContainer>
      {tabs.map(tab => {
        return (
          <SendBackForm
            dismissModal={dismissModal}
            visible={tab.id === selectedTabId}
            tabId={selectedTabId}
            confirmationCallback={confirmationCallback}
            key={tab.id}
            options={tab.options.sort((a, b) => (a < b ? -1 : a > b ? 1 : 0))}
          />
        )
      })}
    </div>
  )
}

const Tab = ({
  selected,
  tabId,
  title,
  setSelectedTabId,
}: {
  selected: boolean
  tabId: string
  title: string
  setSelectedTabId: Dispatch<SetStateAction<string>>
}): JSX.Element => {
  const handleClick = useCallback(() => setSelectedTabId(tabId), [setSelectedTabId, tabId])
  return (
    <TabContainer selected={selected} onClick={handleClick}>
      {title}
    </TabContainer>
  )
}

const SendBackForm = ({
  confirmationCallback,
  dismissModal,
  options,
  visible = true,
  tabId,
}: {
  confirmationCallback: (tabId: string, reason: SendBackReason, message?: string) => void
  dismissModal: () => void
  options: string[]
  visible: boolean
  tabId: string
}): JSX.Element => {
  const [sendBackReason, setSendBackReason] = useState<SendBackReason>()
  const [sendBackMessage, setSendBackMessage] = useState<string>('')

  useEffect(() => {
    if (!visible) {
      setSendBackReason(undefined)
      setSendBackMessage('')
    }
  }, [visible])

  if (!visible) return <></>

  const reasonGiven = sendBackReason !== undefined
  return (
    <>
      <InputContainer>
        <Select
          variant="small"
          value={sendBackReason}
          label="Reason"
          placeholder={'Select a send back reason...'}
          options={options}
          state={reasonGiven ? 'default' : 'error'}
          onChange={option => {
            setSendBackReason(option as SendBackReason)
          }}
        />
        <CustomizedTextField
          size="sm"
          type="textarea"
          onChange={event => {
            setSendBackMessage(event.target.value)
          }}
          placeholder={'Type a message...'}
          value={sendBackMessage}
          label="Message"
        />
      </InputContainer>
      <ButtonsContainer>
        <Button size={'sm'} variant="primary-text" onClick={dismissModal}>
          Cancel
        </Button>
        <Button
          size={'sm'}
          disabled={!reasonGiven}
          onClick={() => {
            if (reasonGiven) {
              confirmationCallback(tabId, sendBackReason as SendBackReason, sendBackMessage)
              dismissModal()
            }
          }}
        >
          Send Back
        </Button>
      </ButtonsContainer>
    </>
  )
}

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1.25rem;
`

const InputContainer = styled.div`
  width: 440px;
  margin-top: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  textarea {
    resize: none;
  }
  > div {
    margin: 10px 0;
  }
`

const TabsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  font-weight: 700;
  cursor: pointer;
`

const TabContainer = styled.a<{ selected: boolean }>`
  border-bottom: 1px solid #e9ecf0;
  ${({ selected }) => selected && `border-bottom: 2px solid ${btnPrimary};`}
  ${({ selected }) => selected && `color: ${btnPrimary};`}
  flex: 1;
  padding: 10px;
  font-size: 0.875rem;
  text-align: center;
`

export default SendBackModal
