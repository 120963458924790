import { useCallback, useEffect } from 'react'
import { GridItem, Grid, Button, BannerAlert, Text } from '@truepill/react-capsule'
import { useMutation } from '@truepill/tpos-react-router'
import LoadingSpinner from 'components/Loading'
import { ModalHeader, ModalTitle, ModalWrapper } from 'components/Modal'
import { UPDATE_INVENTORY_GROUP } from 'gql'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import { useFormData } from 'hooks/useFormData'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import styled from 'styled-components'
import { ButtonContainer, InventoryGroupFormRaw, validateForm } from './InventoryGroupForm'

interface EditInventoryGroupModalProps {
  inventoryGroupId: string
  customerId?: string
  pioneerAlias: string
  active: boolean
}

const EditInventoryGroupModal = ({
  customerId,
  pioneerAlias,
  inventoryGroupId,
  active,
}: EditInventoryGroupModalProps): JSX.Element => {
  return (
    <ModalWrapper
      styles={[
        {
          width: '31rem',
        },
      ]}
      id="editinventoryGroupModal"
    >
      <ModalHeader>
        <ModalTitle>Edit Inventory Group</ModalTitle>
      </ModalHeader>
      <EditinventoryGroupModalContent
        customerId={customerId}
        inventoryGroupId={inventoryGroupId}
        pioneerAlias={pioneerAlias}
        active={active}
      />
    </ModalWrapper>
  )
}

const EditinventoryGroupModalContent = ({
  customerId,
  pioneerAlias,
  inventoryGroupId,
  active,
}: EditInventoryGroupModalProps): JSX.Element => {
  const {
    state: { formData },
    actions: { updateFormData },
  } = useFormData()

  const customerData = formData.inventoryGroup.customerId
  const pioneerAliasData = formData.inventoryGroup.pioneerAlias
  const activeData = formData.inventoryGroup.active
  const formIsValid = validateForm(formData)

  const { dismissModal } = useModalContext()
  const showSuccessToast = useSuccessToast()
  const [updateInventoryGroup, { data, loading, error }] = useMutation(UPDATE_INVENTORY_GROUP)

  useEffect(() => {
    updateFormData({
      inventoryGroup: {
        $set: {
          customerId,
          pioneerAlias,
          active,
        },
      },
    })
  }, [customerId, pioneerAlias, active, updateFormData])

  useEffect(() => {
    if (data) {
      showSuccessToast('inventory group edited succesfully.')
      dismissModal()
    }
  }, [data, dismissModal, showSuccessToast])

  const handleSubmit = useCallback(() => {
    updateInventoryGroup({
      variables: {
        id: inventoryGroupId,
        patch: { customerId: customerData, pioneerAlias: pioneerAliasData, active: activeData },
      },
    })
  }, [customerData, pioneerAliasData, activeData, inventoryGroupId, updateInventoryGroup])

  if (loading) {
    return (
      <LoadingSpinnerContainer>
        <LoadingSpinner />
      </LoadingSpinnerContainer>
    )
  }

  return (
    <>
      {error && (
        <BannerAlert state="error">
          <Text variant="body-sm">{error.message}</Text>
        </BannerAlert>
      )}
      <Grid>
        <GridItem desktop={12} tablet={8}>
          <Title>Inventory Group</Title>
          <InventoryGroupFormRaw />
        </GridItem>
        <GridItem desktop={6} tablet={4} css={{ display: 'none' }}>
          <Title>Edits</Title>
        </GridItem>
      </Grid>
      <ButtonContainer>
        <Button disabled={!formIsValid} onClick={handleSubmit} size="sm">
          Update
        </Button>
      </ButtonContainer>
    </>
  )
}

const Title = styled.h1`
  margin: 1rem 0;
`

const LoadingSpinnerContainer = styled.div`
  width: 3rem;
  margin: 1rem auto;
`

export default EditInventoryGroupModal
