import ActionButton from 'components/ActionButton'
import SearchInput from 'components/SearchInput'
import styled, { css } from 'styled-components'
import { primaryBackgroundColor, primaryColor } from 'styles/styleVariables'

const PagePadding = css`
  padding-left: 1.875rem;
  padding-right: 1.875rem;
`

const StyledSearchInput = styled(SearchInput)`
  flex: 1;
  max-width: 22rem;
`

const StyledActionButton = styled(ActionButton)`
  background-color: ${primaryBackgroundColor};
  :hover {
    border: 2px solid ${primaryColor};
  }
`

const SearchControls = styled.div`
  gap: 1rem;
  grid-column: meta;
  grid-row: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: end;
`

const NewPatientControls = styled.div`
  grid-column: actions;
  grid-row: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: end;
`

const Content = styled.div`
  display: flex;
  grid-column: 1 / span 2;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  ${PagePadding}
  > button {
    margin-right: 3.3rem;
  }
`

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 0.8rem;
  margin-top: 0.8rem;
  ${PagePadding}
`

export { StyledSearchInput, StyledActionButton, SearchControls, NewPatientControls, Content, Title }
