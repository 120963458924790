import type { QueryResult } from '@truepill/tpos-react-router'
import { useQuery } from '@truepill/tpos-react-router'
import { LIST_ESCRIPT_VALIDATION_ISSUE_TYPES } from 'gql'
import type { EscriptValidationIssueType } from 'types'

export interface GetEscriptValidationIssueTypesResult {
  getEscriptValidationIssueTypes: EscriptValidationIssueType[]
}

const useGetEscriptValidationIssueTypes = (
  identifiers?: string[] | undefined,
): QueryResult<GetEscriptValidationIssueTypesResult> => {
  return useQuery<GetEscriptValidationIssueTypesResult>(LIST_ESCRIPT_VALIDATION_ISSUE_TYPES, {
    fetchPolicy: 'network-only',
    variables: {
      identifiers,
    },
  })
}

export default useGetEscriptValidationIssueTypes
