import type { ReactNode } from 'react'
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent, Text } from '@truepill/react-capsule'

interface AccordionContainerProps {
  title: string
  children: ReactNode
  css?: React.CSSProperties
  makeAccordionCollapsible?: boolean
}

const AccordionContainer = ({
  title,
  children,
  css,
  makeAccordionCollapsible = true,
}: AccordionContainerProps): JSX.Element => {
  return (
    <Accordion
      css={{
        marginTop: '1rem',
        borderRadius: '$sm',
        backgroundColor: '$white',
        ...css,
      }}
      type="single"
      collapsible={makeAccordionCollapsible}
      {...(!makeAccordionCollapsible && { defaultValue: 'item-1' })}
    >
      <AccordionItem value="item-1">
        <AccordionTrigger
          css={{
            borderRadius: '4px',
            backgroundColor: '#212D41',
            color: 'white',
            fontWeight: 'bold',
            fontSize: '1.125rem',
            padding: '4px 10px',
          }}
          withChevron={makeAccordionCollapsible}
        >
          <Text>{title}</Text>
        </AccordionTrigger>
        <AccordionContent
          css={{
            overflow: 'visible',
          }}
        >
          {children}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  )
}
export default AccordionContainer
