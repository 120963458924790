import { useCallback, useEffect, useState } from 'react'
import { Button, Grid, GridItem } from '@truepill/react-capsule'
import Pagination from 'components/Pagination'
import Table from 'components/Table'
import type { TableDefinition } from 'components/Table'
import TableChip from 'components/TableChip'
import type { PaginatedSessions, Session } from 'hooks/useUserSessions'
import moment from 'moment'
import styled from 'styled-components'
import {
  capsuleDarkGreenColor,
  capsuleLightGreenColor,
  capsuleDarkGrayColor,
  primaryBackgroundColor,
} from 'styles/styleVariables'
import useEndUserSession from './useEndUserSession'

const Row = styled(Grid)`
  grid-template-columns: none;
  height: 100%;
`

export const SessionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const SessionsTitle = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 24px;
  margin-bottom: 10px;
`

enum StatusSession {
  ACTIVE = 'active',
  LOGGED_OUT = 'loggedOut',
}

interface UserSessionTableProps {
  paginatedSessions?: PaginatedSessions
  fetchMoreSessions: (offset: number, limit: number) => void
  loading: boolean
  userId: string
}

interface PaginationInfo {
  sessionsToShow: Session[]
  currentPage: number
  total: number
  totalPages: number
  pageSize: number
  setPage: (pageNumber: number) => void
  setLimit: (limitNumber: number) => void
}

interface TableRow extends Pick<Session, 'isRevoked' | 'userId' | 'createdAt' | 'expiresAt'> {
  id: string
  ip: string
  location: string
  country: string
  region: string
}

const statusIndicatorConfig = {
  [StatusSession.ACTIVE]: {
    colors: { main: capsuleDarkGreenColor, background: capsuleLightGreenColor },
    label: 'Active',
  },
  [StatusSession.LOGGED_OUT]: {
    colors: { main: primaryBackgroundColor, background: capsuleDarkGrayColor },
    label: 'Logged out',
  },
}

const parseDate = (date?: string) => {
  if (!date) {
    return 'No date found'
  }

  const numericDate = parseInt(date)
  return moment(numericDate).format('MM/DD/YYYY hh:mmA')
}

const EndSessionButton = ({
  sessionId,
  userId,
  totalPages,
  pageSize,
}: {
  sessionId: string
  userId: string
  totalPages: number
  pageSize: number
}) => {
  const { endUserSession, loading, error } = useEndUserSession(userId, totalPages, pageSize)

  const onEndSessionPressed = useCallback(() => {
    endUserSession(sessionId)
  }, [endUserSession, sessionId])

  return (
    <Row justifyItems="end" alignContent="center">
      <GridItem>
        {error && <p>{error.message}</p>}
        {loading ? (
          <p>Ending session...</p>
        ) : (
          <Button variant="primary-outline" size="xs" onClick={onEndSessionPressed}>
            End this session
          </Button>
        )}
      </GridItem>
    </Row>
  )
}

const StatusColumn = ({ isRevoked }: TableRow) => {
  const variant = isRevoked ? StatusSession.LOGGED_OUT : StatusSession.ACTIVE
  const config = statusIndicatorConfig[variant]
  return (
    <Row alignContent="center">
      <GridItem>
        <TableChip colors={config.colors} label={config.label} />
      </GridItem>
    </Row>
  )
}

const useSessionsPaginationInfo = (
  fetchMoreSessions: (offset: number, limit: number) => void,
  paginatedSessions?: PaginatedSessions,
): PaginationInfo => {
  const { total, sessions } = paginatedSessions ?? { sessions: [], total: 0 }
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(10)

  useEffect(() => {
    const newOffset = page * limit
    fetchMoreSessions(newOffset, limit)
  }, [fetchMoreSessions, page, limit])

  const changePage = useCallback((page: number) => {
    setPage(page)
  }, [])

  const totalPages = Math.ceil(total / limit)

  return {
    sessionsToShow: sessions,
    currentPage: page,
    total,
    totalPages,
    pageSize: limit,
    setPage: changePage,
    setLimit,
  }
}

const getTableDefinition = (totalPages: number, pageSize: number): TableDefinition<TableRow>[] => [
  { field: 'ip', headerName: 'IP Address', width: '1.6fr', minWidth: '10rem' },
  { field: 'location', headerName: 'Location', width: '2.5fr', minWidth: '10rem' },
  { field: 'country', headerName: 'Country', width: '0.7fr', minWidth: '5.5em' },
  { field: 'region', headerName: 'Region', width: '0.7fr', minWidth: '5.5em' },
  { field: 'createdAt', headerName: 'Created At', width: '1.6fr', minWidth: '10rem' },
  { field: 'expiresAt', headerName: 'Expires At', width: '1.6fr', minWidth: '10rem' },
  { headerName: 'Status', width: '0.7fr', customRender: StatusColumn, minWidth: '5.8em' },
  {
    headerName: '',
    width: '1.6fr',
    minWidth: '10rem',
    customRender: ({ id, userId, isRevoked }) =>
      !isRevoked ? (
        <EndSessionButton sessionId={id} userId={userId} totalPages={totalPages} pageSize={pageSize} />
      ) : (
        <></>
      ),
  },
]

const parseRows = (sessions: Session[]) => {
  const rows: TableRow[] = []
  sessions.forEach(({ remoteAddresses, createdAt, expiresAt, _id, isRevoked, userId }) => {
    const [address] = remoteAddresses
    const { geoLocation, ip, country, region } = address ?? {}
    const [lat, long] = geoLocation ?? []
    rows.push({
      id: _id,
      ip: ip || 'No ip found',
      createdAt: parseDate(createdAt),
      expiresAt: parseDate(expiresAt),
      location: lat && long ? `lat: ${lat}, long: ${long}` : 'No location found',
      country,
      region,
      isRevoked,
      userId,
    })
  })
  return rows
}

export const UserSessionTable = ({
  paginatedSessions,
  fetchMoreSessions,
  loading,
  userId,
}: UserSessionTableProps): JSX.Element => {
  const { sessionsToShow, currentPage, setPage, total, totalPages, pageSize, setLimit } = useSessionsPaginationInfo(
    fetchMoreSessions,
    paginatedSessions,
  )

  return (
    <>
      <Table
        data={parseRows(sessionsToShow)}
        keyField="id"
        definition={getTableDefinition(totalPages, pageSize)}
        loading={loading}
      />
      <Pagination
        onChangePage={setPage}
        totalRecords={total}
        currentPage={currentPage}
        totalPages={totalPages}
        rowsPerPage={pageSize}
        onChangeRowsPerPage={setLimit}
      />
    </>
  )
}
