import { useCallback, useState } from 'react'
import type { ApolloError } from '@truepill/tpos-react-router'
import { useQuery } from '@truepill/tpos-react-router'
import { GET_USER_LOGS } from 'gql'

export interface UserLog {
  _id: string
  isRevoked: boolean
  userId: string
  createdAt: string
  isAnnotation: boolean
  message: string
  event: string
  change?: {
    field: string
    oldValue: string
    newValue: string
  }
  patient?: {
    _id: string
    firstName: string
    lastName: string
  }
  prescription?: {
    _id: string
    rxNumber: string
  }
  fill?: {
    _id: string
    rxFillCode: string
  }
  order?: {
    _id: string
    coreOrderId: string
  }
}

export interface PaginatedLogs {
  logs: UserLog[]
  offset: number
  limit: number
  total: number
}

interface GetUserLogsParams {
  userId: string
  offset?: number
  limit?: number
}

interface GetUserLogsResponse {
  paginatedLogs: PaginatedLogs
}

type useUserLogsType = {
  paginatedLogs?: PaginatedLogs
  fetchMoreLogs: (offset: number, limit: number) => void
  loading: boolean
  error?: ApolloError
}

const useUserLogs = (userId: string, startingLimit?: number): useUserLogsType => {
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(startingLimit || 10)

  const { data, loading, error } = useQuery<GetUserLogsResponse, GetUserLogsParams>(GET_USER_LOGS, {
    skip: !userId,
    variables: { userId, limit, offset },
  })

  const fetchMoreLogs = useCallback((offset, newLimit) => {
    setOffset(offset)
    setLimit(newLimit)
  }, [])

  if (!data) {
    return {
      fetchMoreLogs,
      loading,
      error,
    }
  }

  const { paginatedLogs } = data

  return {
    paginatedLogs,
    fetchMoreLogs,
    loading,
    error,
  }
}

export default useUserLogs
