import type { FC } from 'react'
import { useQuery } from '@truepill/tpos-react-router'
import { LaunchDarkly } from '@truepill/tpos-types'
import CloseButton from 'components/CloseButton'
import { SearchSelectedCard } from 'components/ColumnForm'
import { GET_NDC_FULL_INFO } from 'gql'
import { useFlag } from 'providers/LaunchDarklyProvider'
import styled from 'styled-components'
import type { NdcFullInfo, NdcFullInfoPackage } from 'types'
import { getNdcText } from 'utils'
import { getDisplayedDrugName } from 'utils/getDisplayedDrugName'

type MedicationCardProps = {
  ndc: NdcFullInfoPackage['ndc']
  closeCallback?: () => void
  label?: string
  hideFields?: string[]
}

const MedicationCard: FC<MedicationCardProps> = ({ ndc, closeCallback, label, hideFields }) => {
  const { data, loading, error } = useQuery(GET_NDC_FULL_INFO, {
    variables: { ndcs: [ndc] },
  })
  const tempDisplayPackSizeWithNdc = useFlag(LaunchDarkly.FeatureFlags.TEMP_DISPLAY_PACK_SIZE_WITH_NDC)
  const hyphenateNdc = useFlag(LaunchDarkly.FeatureFlags.TEMP_HYPHENATE_NDCS)

  if (ndc === '') {
    return <></>
  }

  if (loading) {
    return (
      <>
        {!!label && <StyleLabel>{label}</StyleLabel>}
        <SearchSelectedCard data-testid="medication-loading">
          <p>Loading</p>
        </SearchSelectedCard>
      </>
    )
  }
  const ndcPackages: NdcFullInfo[] = data?.getNdcFullInfo
  const ndcInfo = ndcPackages?.length ? ndcPackages[0]?.packages.find(pkg => pkg.ndc === ndc) : null

  const ndcText = getNdcText(ndcInfo, tempDisplayPackSizeWithNdc, hyphenateNdc)

  const displayedDrugName = getDisplayedDrugName(
    ndcInfo?.drugName ?? '',
    ndcPackages?.length ? ndcPackages[0].strength : '',
    ndcPackages?.length ? ndcPackages[0].strengthUnitOfMeasure : '',
    ndcPackages?.length ? ndcPackages[0].dosageForm : '',
  )
  if (error || !ndcInfo) {
    return (
      <SearchSelectedCard data-testid="medication-error">
        <div>Error loading medication:</div>
        {closeCallback && <CloseButton clickCallback={closeCallback} contrast />}
        <p
          style={{
            maxWidth: '100%',
            maxHeight: '3rem',
            overflow: 'scroll',
          }}
        >
          {JSON.stringify(error)}
        </p>
      </SearchSelectedCard>
    )
  }

  return (
    <>
      {!!label && <StyleLabel>{label}</StyleLabel>}
      <StyledSearchSelectedCard data-testid="medication-selected">
        <strong>{displayedDrugName}</strong>
        {!!ndcPackages[0].genericFor && (
          <p>
            <strong>Generic for:</strong> {ndcPackages[0].genericFor}
          </p>
        )}
        {!hideFields?.includes('Manufacturer') && (
          <p>
            <strong>Manufacturer:</strong> {ndcPackages[0]?.labeler.name}
          </p>
        )}
        {!hideFields?.includes('NDC') && (
          <p>
            <strong>NDC:</strong> {ndcText}
          </p>
        )}
        {closeCallback && <CloseButton size="0.625rem" clickCallback={closeCallback} />}
      </StyledSearchSelectedCard>
    </>
  )
}

export default MedicationCard

const StyleLabel = styled.label`
  font-weight: 700;
  margin-top: 1rem;
  display: block;
  line-height: 1.375;
  font-family: 'Lato', sans-serif;
`

const StyledSearchSelectedCard = styled(SearchSelectedCard)`
  flex-grow: 1;
  margin-bottom: 0;
  flex-flow: column;
  padding: 0.625rem 0.8rem;
`
