import { useState, useEffect } from 'react'
import { Link, useQuery } from '@truepill/tpos-react-router'
import { LaunchDarkly } from '@truepill/tpos-types'
import AddressEntry from 'components/AddressEntry'
import CriticalMedicalNoteWarning from 'components/CriticalMedicalNoteWarning'
import { FilledHeader } from 'components/PageStructure'
import { GET_PRESCRIPTION } from 'gql'
import { Box } from 'grommet'
import useHotKey, { HotKeyLevel } from 'hooks/useHotKey'
import useRxImageIsPic from 'hooks/useRxImageIsPic'
import moment from 'moment'
import { useFlag } from 'providers/LaunchDarklyProvider'
import { goToViewPharmacyEscript, goToViewPharmacyPrescription } from 'routes'
import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'
import { contrastBackgroundColor, primaryColor } from 'styles/styleVariables'
import type { NdcFullInfoPackage, Patient, Prescriber, Prescription, Transfer, Weight } from 'types'
import { TransferType } from 'types'
import {
  getCurrentFill,
  formatPhoneNumber,
  capitalize,
  checkIfProvided,
  getPrescriberAddress,
  getPackageMeasureDescription,
  convertWeight,
  getNdcText,
} from 'utils'
import { getDisplayedDrugName } from 'utils/getDisplayedDrugName'
import { twoDecimalOrLess } from 'utils/numberFormatter'
import { isHuman } from 'utils/Patient'
import { GET_NDC_PACKAGE } from './ReviewPrescription'
import RxAnnotations from './RxAnnotations'
import RXScanImage from './RXScanImage'

type RXDataProps = {
  prescription: Prescription
  isModal?: boolean
  columnStyles?: FlattenSimpleInterpolation
}

const RXData = ({ prescription, isModal, columnStyles }: RXDataProps): JSX.Element => {
  const [rxImageIsPic, setRxImageIsPic] = useRxImageIsPic()
  const [linkedPrescriptionId, setLinkedPrescriptionId] = useState('')

  const { data } = useQuery(GET_PRESCRIPTION, {
    variables: {
      prescriptionId: linkedPrescriptionId,
    },
    skip: linkedPrescriptionId === '',
  })

  useEffect(() => {
    setLinkedPrescriptionId(prescription?.refillRequests?.slice(-1)[0]?.prescriptionId || '')
  }, [prescription?._id])

  // TEMP HACKY DEV STUFF FOR TESTING
  useHotKey('x', isModal ? HotKeyLevel.modal : HotKeyLevel.normal, () => setRxImageIsPic(!rxImageIsPic))

  const { patient, prescriber, annotations } = prescription

  if (rxImageIsPic) {
    return (
      <RXDataContainer data-testid="RXData">
        <RXImageColumn>
          <StyledHeader>Rx image</StyledHeader>
          <ScanImageWrapper>
            <RXScanImage
              prescription={prescription}
              inline={true}
              scanPdfStyle={StyledPdf}
              wrapperStyle={wrapperStyle}
            />
          </ScanImageWrapper>
        </RXImageColumn>
        <RXDataColumnAnnotations>
          {(annotations || []).length > 0 ? (
            <>
              <StyledHeader>Annotations</StyledHeader>
              <RxAnnotations />
            </>
          ) : null}
        </RXDataColumnAnnotations>
      </RXDataContainer>
    )
  }

  return (
    <RXDataContainer data-testid="RXData">
      <RXDataColumn>
        <StyledHeader>Rx image</StyledHeader>
        <ScanImageWrapper>
          <RXScanImage prescription={prescription} inline={true} scanPdfStyle={StyledPdf} />
        </ScanImageWrapper>
      </RXDataColumn>
      <RXDataColumn>
        <Box data-test-column="prescription">
          <StyledHeader>Prescription</StyledHeader>
          <PrescriptionBlock prescription={prescription} />
          {data && (
            <>
              <StyledHeader>Linked prescription</StyledHeader>
              <LinkedPrescriptionBlock prescription={data.getPrescription} />
            </>
          )}
          {prescription?.escript && (
            <>
              <StyledHeader>Linked escript</StyledHeader>
              <LinkedEscriptBlock prescription={prescription} />
            </>
          )}
        </Box>
      </RXDataColumn>
      <RXDataColumn>
        <Box data-test-column="patient">
          <StyledHeader>Patient</StyledHeader>
          <PatientBlock patient={patient} />
        </Box>
        <Box data-test-column="prescriber">
          <StyledHeader>Prescriber</StyledHeader>
          <PrescriberBlock prescriber={prescriber} prescription={prescription} />
        </Box>
        {!!annotations?.length && (
          <Box data-test-column="annotations">
            <StyledHeader>Annotations</StyledHeader>
            <RxAnnotations />
          </Box>
        )}
        {!!prescription.transfers.length && prescription.transfers[0].transferType === TransferType.TRANSFER_IN && (
          <Box data-test-column="transfers">
            <StyledHeader>Transfer details</StyledHeader>
            <TransferDetailsBlock transfer={prescription.transfers[0]} />
          </Box>
        )}
      </RXDataColumn>
    </RXDataContainer>
  )
}

type LinkedPrescriptionType = { prescription: Prescription }

export const LinkedPrescriptionBlock = ({ prescription }: LinkedPrescriptionType): JSX.Element => {
  return (
    <StyledLink
      title={`Rx: ${prescription.rxNumber} - ${prescription.name}`}
      to={goToViewPharmacyPrescription({
        prescriptionId: prescription._id,
      })}
    >
      <LinkedPrescription>
        Rx: {prescription.rxNumber} - {prescription.name}
      </LinkedPrescription>
    </StyledLink>
  )
}

type LinkedEscript = { prescription: Prescription }

export const LinkedEscriptBlock = ({ prescription }: LinkedEscript): JSX.Element => {
  return (
    <>
      <RXDataRow data-test-row="escript">
        <ListRowLabel>Escript:</ListRowLabel>
        <ListRowValue>
          <StyledLink
            title={`Escript: ${prescription.escript._id.toString()}`}
            to={goToViewPharmacyEscript({
              escriptId: prescription.escript._id,
            })}
          >
            <LinkedPrescription>{prescription.escript._id.toString()}</LinkedPrescription>
          </StyledLink>
        </ListRowValue>
      </RXDataRow>
    </>
  )
}

type PatientBlockProps = { patient: Patient }

export const PatientBlock = ({ patient }: PatientBlockProps): JSX.Element => {
  const patientDOB = moment(patient.dob).format('MM/DD/YYYY')
  const patientGender = capitalize(patient.gender)
  const patientWeight = patient.weight?.value ? convertWeight(patient.weight.value as number) : ({} as Weight)

  return (
    <>
      <RXDataRow data-test-row="first-name">
        <ListRowLabel>First Name:</ListRowLabel>
        <ListRowValue data-private>{patient.firstName}</ListRowValue>
      </RXDataRow>
      <RXDataRow data-test-row="last-name">
        <ListRowLabel>Last Name:</ListRowLabel>
        <ListRowValue data-private>{patient.lastName}</ListRowValue>
      </RXDataRow>
      {!isHuman(patient) && (
        <>
          <RXDataRow data-test-row="species">
            <ListRowLabel>Species:</ListRowLabel>
            <ListRowValue data-private>{patient.species}</ListRowValue>
          </RXDataRow>
          <RXDataRow data-test-row="guardian">
            <ListRowLabel>Guardian:</ListRowLabel>
            <ListRowValue data-private>{patient.guardian}</ListRowValue>
          </RXDataRow>
        </>
      )}
      <RXDataRow data-test-row="dob">
        <ListRowLabel>DOB:</ListRowLabel>
        <ListRowValue data-private>{patientDOB}</ListRowValue>
      </RXDataRow>
      {patient.weight?.value && (
        <RXDataRow data-test-row="weight">
          <ListRowLabel>Weight:</ListRowLabel>
          <ListRowValue data-private>{`${patientWeight.kg} kg / ${patientWeight.lb} lb`}</ListRowValue>
        </RXDataRow>
      )}
      <RXDataRow data-test-row="gender">
        <ListRowLabel>Gender:</ListRowLabel>
        <ListRowValue data-private>{patientGender}</ListRowValue>
      </RXDataRow>
      <RXDataRow data-test-row="address">
        <ListRowLabel>Address:</ListRowLabel>
        <ListRowValue>
          <AddressEntry data-private address={patient.address?.home} />
        </ListRowValue>
      </RXDataRow>
      <RXDataRow data-test-row="phone">
        <ListRowLabel>Phone:</ListRowLabel>
        <ListRowValue data-private>{checkIfProvided(formatPhoneNumber(patient.contacts?.phone))}</ListRowValue>
      </RXDataRow>
    </>
  )
}

type PrescriberBlockProps = {
  prescriber: Prescriber
  prescription: Prescription
}

export const PrescriberBlock = ({ prescriber, prescription }: PrescriberBlockProps): JSX.Element => {
  const prescriptionPracticeAddress = getPrescriberAddress(prescription)

  return (
    <>
      <RXDataRow data-test-row="name">
        <ListRowLabel>Prescriber:</ListRowLabel>
        <ListRowValue>
          {prescriber.firstName} {prescriber.lastName}
          {prescriber.suffix ? `, ${prescriber.suffix}` : ''}
        </ListRowValue>
      </RXDataRow>
      {prescriber.npi && (
        <RXDataRow data-test-row="npi">
          <ListRowLabel>NPI:</ListRowLabel>
          <ListRowValue>{prescriber.npi}</ListRowValue>
        </RXDataRow>
      )}
      {prescriber.stateLicense && (
        <RXDataRow data-test-row="state-license">
          <ListRowLabel>License #:</ListRowLabel>
          <ListRowValue>{prescriber.stateLicense}</ListRowValue>
        </RXDataRow>
      )}
      <RXDataRow data-test-row="supervising">
        <ListRowLabel>Supervising prescriber:</ListRowLabel>
        <ListRowValue>
          {prescription.supervisingPrescriber
            ? prescription.supervisingPrescriber.suffix
              ? `${prescription.supervisingPrescriber?.firstName} ${prescription.supervisingPrescriber?.lastName}, ${prescription.supervisingPrescriber?.suffix}`
              : `${prescription.supervisingPrescriber?.firstName} ${prescription.supervisingPrescriber?.lastName}`
            : 'None provided'}
        </ListRowValue>
      </RXDataRow>
      <RXDataRow data-test-row="address">
        <ListRowLabel>Address:</ListRowLabel>
        <ListRowValue>
          <AddressEntry address={prescriptionPracticeAddress} />
        </ListRowValue>
      </RXDataRow>
      <RXDataRow data-test-row="phone">
        <ListRowLabel>Phone:</ListRowLabel>
        <ListRowValue>
          {checkIfProvided(formatPhoneNumber(prescriptionPracticeAddress?.phone || prescriber?.contacts?.phone))}
        </ListRowValue>
      </RXDataRow>
      <RXDataRow data-test-row="fax">
        <ListRowLabel>Fax:</ListRowLabel>
        <ListRowValue>
          {checkIfProvided(formatPhoneNumber(prescriptionPracticeAddress?.fax || prescriber?.contacts?.fax))}
        </ListRowValue>
      </RXDataRow>
      <RXDataRow data-test-row="dea">
        <ListRowLabel>DEA:</ListRowLabel>
        <ListRowValue>{checkIfProvided(prescriber.dea)}</ListRowValue>
      </RXDataRow>
    </>
  )
}

type PrescriptionBlockProps = { prescription: Prescription }

const PrescriptionBlock = ({ prescription }: PrescriptionBlockProps): JSX.Element => {
  const expiration = moment(prescription.expirationDate).format('MM/DD/YYYY')
  const written = moment(prescription.writtenDate).format('MM/DD/YYYY')
  const effectiveDate = prescription.effectiveDate ? moment(prescription.effectiveDate).format('MM/DD/YYYY') : ''

  const fill = getCurrentFill(prescription)
  const lastFillDate = fill?.dispensed.dispensedAt || prescription.transfers?.[0]?.lastFillDate

  const { data } = useQuery(GET_NDC_PACKAGE, {
    variables: { ndcs: [prescription.ndc] },
  })

  const tempDisplayPackSizeWithNdc = useFlag(LaunchDarkly.FeatureFlags.TEMP_DISPLAY_PACK_SIZE_WITH_NDC)
  const hyphenateNdc = useFlag(LaunchDarkly.FeatureFlags.TEMP_HYPHENATE_NDCS)

  const ndcPackage = data?.getNdcPackage?.[0] as NdcFullInfoPackage
  const packageSizeUnitOfMeasure = ndcPackage?.packageSizeUnitOfMeasure

  const ndcText = getNdcText(ndcPackage, tempDisplayPackSizeWithNdc, hyphenateNdc)

  return (
    <>
      <RXDataRow data-test-row="medication">
        <ListRowLabel>Medication:</ListRowLabel>
        <ListRowValue>
          {getDisplayedDrugName(
            prescription.name,
            prescription.strength?.value,
            prescription.strength?.unit,
            prescription.strength?.form,
          )}
          <CriticalMedicalNoteWarning ndc={prescription.ndc} />
        </ListRowValue>
      </RXDataRow>
      <RXDataRow data-test-row="ndc">
        <ListRowLabel>NDC:</ListRowLabel>
        <ListRowValue>{ndcText}</ListRowValue>
      </RXDataRow>
      <RXDataRow data-test-row="quantity-remaining">
        <ListRowLabel>Qty Remaining:</ListRowLabel>
        <ListRowValue>
          {twoDecimalOrLess(prescription.quantityRemaining ?? 0)}{' '}
          {getPackageMeasureDescription(packageSizeUnitOfMeasure)}
        </ListRowValue>
      </RXDataRow>
      <RXDataRow data-test-row="quantity">
        <ListRowLabel>Qty:</ListRowLabel>
        <ListRowValue>
          {prescription.quantity === '.' ? '.' : twoDecimalOrLess(prescription.quantity)}{' '}
          {getPackageMeasureDescription(packageSizeUnitOfMeasure)}
        </ListRowValue>
      </RXDataRow>
      <RXDataRow data-test-row="dosage-form">
        <ListRowLabel>Dosage Form:</ListRowLabel>
        <ListRowValue>{prescription.dosageForm ?? ''}</ListRowValue>
      </RXDataRow>
      <RXDataRow data-test-row="days-supply">
        <ListRowLabel>DS:</ListRowLabel>
        <ListRowValue>{checkIfProvided(prescription.daysSupply)}</ListRowValue>
      </RXDataRow>
      <RXDataRow data-test-row="refills">
        <ListRowLabel>Total Original Fills:</ListRowLabel>
        <ListRowValue>{prescription.numberOfRefills}</ListRowValue>
      </RXDataRow>
      <RXDataRow data-test-row="refills-remaining">
        <ListRowLabel>Refills Remaining:</ListRowLabel>
        <ListRowValue>{prescription.refillsRemaining ?? 0}</ListRowValue>
      </RXDataRow>
      <RXDataRow data-test-row="daw">
        <ListRowLabel>DAW:</ListRowLabel>
        <ListRowValue>{prescription.daw}</ListRowValue>
      </RXDataRow>
      <RXDataRow data-test-row="directions">
        <ListRowLabel>Directions:</ListRowLabel>
        <ListRowValue>{prescription.directions}</ListRowValue>
      </RXDataRow>
      <RXDataRow data-test-row="written-date">
        <ListRowLabel>Written:</ListRowLabel>
        <ListRowValue>{written}</ListRowValue>
      </RXDataRow>
      <RXDataRow data-test-row="effective-date">
        <ListRowLabel>Effective:</ListRowLabel>
        <ListRowValue>{effectiveDate}</ListRowValue>
      </RXDataRow>
      <RXDataRow data-test-row="last-fill-date">
        <ListRowLabel>Last Fill:</ListRowLabel>
        <ListRowValue>{lastFillDate ? moment(lastFillDate).format('MM/DD/YYYY') : '- No Fills -'}</ListRowValue>
      </RXDataRow>
      <RXDataRow data-test-row="expiration-date">
        <ListRowLabel>Expires On:</ListRowLabel>
        <ListRowValue>{expiration}</ListRowValue>
      </RXDataRow>
      <RXDataRow data-test-row="notes">
        <ListRowLabel>Notes:</ListRowLabel>
        <ListRowValue>{checkIfProvided(prescription.note)}</ListRowValue>
      </RXDataRow>
      <RXDataRow data-test-row="prn">
        <ListRowLabel>PRN:</ListRowLabel>
        <ListRowValue>{prescription.prn || 'None provided'}</ListRowValue>
      </RXDataRow>
      <RXDataRow data-test-row="triplicate">
        <ListRowLabel>Triplicate:</ListRowLabel>
        <ListRowValue>{checkIfProvided(prescription.triplicate)}</ListRowValue>
      </RXDataRow>
      <RXDataRow data-test-row="icd10">
        <ListRowLabel>ICD 10:</ListRowLabel>
        <ListRowValue>{checkIfProvided(prescription.icd10s?.map(x => x.code).join(','))}</ListRowValue>
      </RXDataRow>
    </>
  )
}

type TransferDetailsBlockProps = { transfer: Transfer }

const TransferDetailsBlock = ({ transfer }: TransferDetailsBlockProps): JSX.Element => {
  const lastFillDate = transfer.lastFillDate ? moment(transfer.lastFillDate).format('MM/DD/YYYY') : '- No Fills -'

  return (
    <>
      <RXDataRow>
        <ListRowLabel>Original Rx number:</ListRowLabel>
        <ListRowValue>{transfer.originalRxNumber}</ListRowValue>
      </RXDataRow>
      <RXDataRow>
        <ListRowLabel>Receiving pharmacy:</ListRowLabel>
        <ListRowValue>
          <p>
            <strong>{transfer.pharmacy}</strong>
          </p>
          <p>{transfer.address.street1} </p>
          {transfer.address.street2 && <p>{transfer.address.street2}</p>}
          <p>
            {transfer.address.city}, {transfer.address.state} {transfer.address.zip}
          </p>
          <p>
            <strong>Phone:</strong> {transfer.phone}
          </p>
        </ListRowValue>
      </RXDataRow>
      <RXDataRow>
        <ListRowLabel>Receiving pharmacist:</ListRowLabel>
        <ListRowValue>{transfer.pharmacistName}</ListRowValue>
      </RXDataRow>
      <RXDataRow>
        <ListRowLabel>Original refills:</ListRowLabel>
        <ListRowValue>{transfer.originalRefills}</ListRowValue>
      </RXDataRow>
      <RXDataRow>
        <ListRowLabel>Refills remaining:</ListRowLabel>
        <ListRowValue>{transfer.refillsRemaining}</ListRowValue>
      </RXDataRow>
      <RXDataRow>
        <ListRowLabel>Last fill:</ListRowLabel>
        <ListRowValue>{lastFillDate}</ListRowValue>
      </RXDataRow>

      {transfer?.priorAuthorization && (
        <Box>
          <StyledHeader>Prior Authorization Details</StyledHeader>
          <RXDataRow>
            <ListRowLabel>Type:</ListRowLabel>
            <ListRowValue>{transfer.priorAuthorization?.type}</ListRowValue>
          </RXDataRow>
          <RXDataRow>
            <ListRowLabel>Key:</ListRowLabel>
            <ListRowValue>
              {transfer.priorAuthorization.type?.toLowerCase() !== 'other' ? transfer.priorAuthorization.key : 'N/A'}
            </ListRowValue>
          </RXDataRow>
          <RXDataRow>
            <ListRowLabel>Status:</ListRowLabel>
            <ListRowValue style={{ textTransform: 'capitalize' }}>{transfer.priorAuthorization.status}</ListRowValue>
          </RXDataRow>
          <RXDataRow>
            <ListRowLabel>Submission Date:</ListRowLabel>
            <ListRowValue>{moment(transfer.priorAuthorization.submission_date).format('YYYY-MM-DD')}</ListRowValue>
          </RXDataRow>
          {transfer.priorAuthorization.status.toLowerCase() === 'approved' && (
            <>
              <RXDataRow>
                <ListRowLabel>Start Date:</ListRowLabel>
                <ListRowValue>{moment(transfer.priorAuthorization.start_date).format('YYYY-MM-DD')}</ListRowValue>
              </RXDataRow>
              <RXDataRow>
                <ListRowLabel>Expiration Date:</ListRowLabel>
                <ListRowValue>{moment(transfer.priorAuthorization.expiration_date).format('YYYY-MM-DD')}</ListRowValue>
              </RXDataRow>
            </>
          )}
        </Box>
      )}
    </>
  )
}

const StyledHeader = styled(FilledHeader)`
  margin-top: 0.625rem;
  margin-top: 1.25rem;
`

const RXDataContainer = styled.div`
  display: flex;
  flex-basis: 100%;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 1.25rem;
`

const RXDataColumn = styled.div<{
  styles?: FlattenSimpleInterpolation
}>`
  :not(:last-child) {
    margin-right: 1.25rem;
  }
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 33%;
  ${({ styles }) => styles};
`
const RXDataColumnAnnotations = styled(RXDataColumn)`
  width: 40%;
`
const RXImageColumn = styled(RXDataColumn)`
  width: 60%;
  div {
    background-color: #f4f4f4;
  }
`

const StyledLink = styled(Link)`
  font-size: 1.125rem;
  color: ${primaryColor};
  padding: 0.5rem 0;
  padding-left: 0.65rem;
`

const LinkedPrescription = styled.span`
  color: ${primaryColor};
  visited {
    color: ${primaryColor};
  }
`

const RXDataRow = styled.div.attrs(props => {
  return { role: props.role ?? 'group' }
})`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: [label] 7rem [value] 1fr;
  padding: 0.5rem 0;
  padding-left: 0.65rem;
  :nth-of-type(even) {
    background-color: ${contrastBackgroundColor};
  }
`

const ListRowLabel = styled.span`
  grid-row: 1;
  grid-column: label;
  padding-right: 0.3125rem;
  font-size: 0.875rem;
  font-weight: 500;
`
const ListRowValue = styled.span.attrs(props => {
  return { 'data-testid': 'value' }
})`
  grid-row: 1;
  grid-column: value;
`

const ScanImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    object-fit: contain;
  }
`

const StyledPdf = css`
  width: 24.78rem;
  height: 105vh;
  @media screen and (min-width: 1380px) {
    width: 43rem;
  }
  @media screen and (min-width: 1800px) {
    height: calc(100vh - 30rem);
  }
  @media screen and (min-width: 2400px) {
    width: 43rem;
  }
`

const wrapperStyle = css`
  width: 24.78rem;
  @media screen and (min-width: 1380px) {
    width: 43rem;
  }
  @media screen and (min-width: 2400px) {
    width: 43rem;
  }
`

export default RXData
