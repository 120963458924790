import { useMutation } from '@truepill/tpos-react-router'
import { ModalWrapper, ModalHeader, ButtonsContainer, InputContainer } from 'components/Modal'
import { SaveButton } from 'components/PageStructure'
import { CONFIRM_EXCESSIVE_CS_REJECTIONS_REPORT } from 'gql'
import useErrorToast from 'hooks/toast/useErrorToast'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import styled from 'styled-components'

interface ExcessiveCsrejectionsModalProps {
  orderId?: string
  fillId?: string
}

const ExcessiveCsRejectionsModal = (props: ExcessiveCsrejectionsModalProps): JSX.Element => {
  const { orderId, fillId } = props
  const showErrorToast = useErrorToast()
  const { dismissModal } = useModalContext()

  const [confirmExcessiveCsRejectionsReport] = useMutation(CONFIRM_EXCESSIVE_CS_REJECTIONS_REPORT, {
    refetchQueries: ['getAllLogs'],
    awaitRefetchQueries: true,
    onCompleted: data => {
      dismissModal()
    },
    onError: e => {
      console.error(`Failed to confirm cs rejections report. ` + e.message)
      showErrorToast(`Failed to confirm cs rejections report. ` + e.message)
    },
  })

  return (
    <ModalWrapper id="ExcessiveCsRejectionsModal">
      <ModalHeader hideClose={true}>
        <h2>Report of Excessive CS Rejections Required</h2>
      </ModalHeader>
      <ConstrainedInputContainer>
        <p>
          Please report to Quynh Do and Rick Hagan Myers with this order, prescription, and prescriber information for
          further action.
        </p>
        <p>
          By clicking complete, you are confirming that you have already reported this occurence to Quynh and Rick as
          part of Truepill’s established DEA process
        </p>
      </ConstrainedInputContainer>
      <ButtonsContainer>
        <SaveButton
          label={'Complete'}
          onClick={async () => {
            await confirmExcessiveCsRejectionsReport({
              variables: {
                orderId,
                fillId,
              },
            })
          }}
        />
      </ButtonsContainer>
    </ModalWrapper>
  )
}

export default ExcessiveCsRejectionsModal

const ConstrainedInputContainer = styled(InputContainer)`
  max-width: 31rem;
`
