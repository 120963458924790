import { useQuery } from '@truepill/tpos-react-router'
import AddressEntry from 'components/AddressEntry'
import DropDownOption from 'components/DropDownOption'
import SearchAutoComplete from 'components/SearchAutoComplete'
import { UNIFIED_SEARCH_PRESCRIBERS } from 'gql'
import useStateWithDebouncedValue from 'hooks/useStateWithDebouncedValue'
import { bodySecondaryColor } from 'styles/styleVariables'
import type { Prescriber } from 'types'

interface PrescriberSuggestion extends Prescriber {
  highlighted?: boolean
  loading: boolean
  hideAddress?: boolean
}

interface Error {
  error: string
}

interface Loading {
  loading: boolean
}

type PrescriberSuggestionItem = PrescriberSuggestion | Error | Loading

type PrescriberSearchOptionsProps = {
  isModal?: boolean
  withCapsule?: boolean
  state?: 'default' | 'complete' | 'error'
  hideAddress?: boolean
  onSelect: (args: {
    prescriberId: Prescriber['_id']
    prescriberNpi: Prescriber['npi']
    prescriberAddresses: Prescriber['addresses']
    prescriber: Prescriber
  }) => void
}

const PrescriberSearchOptions = ({
  onSelect,
  withCapsule,
  isModal,
  hideAddress,
  state,
}: PrescriberSearchOptionsProps): JSX.Element => {
  const [searchTerm, debouncedSearchTerm, setSearchTerm] = useStateWithDebouncedValue('')

  const { data, loading, error } = useQuery(UNIFIED_SEARCH_PRESCRIBERS, {
    variables: { search: debouncedSearchTerm },
  })
  const resultData = data?.unifiedSearchPrescribers

  let options: PrescriberSuggestionItem[] = resultData || []

  if (error) {
    options = [{ error: `Error getting Prescribers: ${JSON.stringify(error)}` }]
  }

  if (loading) {
    options = [{ loading }]
  }

  return (
    <SearchAutoComplete
      data-testid="prescriber"
      withCapsule={withCapsule}
      hotKey="p"
      isModal={isModal}
      iconColor={bodySecondaryColor}
      autoCompleteSuggestions={options}
      value={searchTerm}
      suggestionComponent={optionProps => (
        <AutoCompleteOption hideAddress={hideAddress} {...(optionProps as PrescriberSuggestionItem)} />
      )}
      onChange={setSearchTerm}
      placeholder="Search prescriber by name, NPI, or License #"
      onSelect={(chosen: PrescriberSuggestion) => {
        onSelect({
          prescriberId: chosen._id,
          prescriberNpi: chosen.npi,
          prescriberAddresses: chosen.addresses,
          prescriber: chosen,
        })
        setSearchTerm('')
      }}
      state={state}
    />
  )
}

const AutoCompleteOption = (props: PrescriberSuggestionItem): JSX.Element => {
  if ((props as Error).error) {
    return (
      <DropDownOption data-testid="error" {...props}>
        <p>{(props as Error).error}</p>
      </DropDownOption>
    )
  }

  if ((props as Loading).loading) {
    return (
      <DropDownOption data-testid="loading">
        <p>Searching...</p>
      </DropDownOption>
    )
  }

  if (!(props as Prescriber)._id) {
    return <></>
  }

  const prescriber = props as PrescriberSuggestion
  const {
    firstName,
    lastName,
    suffix,
    createdAt,
    primarySpecializationID,
    primarySpecializationText,
    serialNumberPerson,
    stateLicense,
    loading,
    hideAddress,
    ...dropProps
  } = prescriber

  const AddressComponentprescriber = hideAddress ? null : prescriber.addresses.length > 1 ? (
    <p> {prescriber.addresses.length} Locations </p>
  ) : (
    <AddressEntry address={prescriber.addresses.slice(-1)[0]} inline />
  )

  return (
    <DropDownOption data-testid="option" {...dropProps}>
      <p>
        {prescriber.lastName} {prescriber.suffix}, {prescriber.firstName} ({prescriber.npi || prescriber.stateLicense})
      </p>
      {AddressComponentprescriber}
    </DropDownOption>
  )
}

export default PrescriberSearchOptions
