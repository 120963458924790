import { useEffect, useState, useMemo } from 'react'
import { Button } from '@truepill/react-capsule'
import type { RouteComponentProps } from '@truepill/tpos-react-router'
import { useQuery } from '@truepill/tpos-react-router'
import type { Medispan } from '@truepill/tpos-types'
import { FormItem } from 'components/ColumnForm'
import {
  DURAllergiesAutoComplete,
  DURConditionsAutoComplete,
  DURMedicationsAutoComplete,
} from 'components/DURSubjectAutoComplete'
import LoadingSpinner from 'components/Loading'
import { TransparentHeader } from 'components/PageStructure'
import PatientForm from 'components/PatientForm'
import { GET_PATIENT } from 'gql'
import usePatient from 'hooks/navigation/usePatient'
import useErrorToast from 'hooks/toast/useErrorToast'
import useCreatePatient from 'hooks/useCreatePatient'
import { useFormData } from 'hooks/useFormData'
import useSetPageTitle from 'hooks/useSetPageTitle'
import useUpdatePatient from 'hooks/useUpdatePatient'
import { usePlusClient } from 'providers/VisionRouter'
import type { Address, InsuranceInput, Patient } from 'types'
import { stripTypename, isPatientFormValid, notEmpty } from 'utils'
import Insurance from './components/InsuranceColumnDetail'
import Preferences from './components/PreferencesDetail'
import {
  DemographicColumn,
  DurColumn,
  ExpandedActionButtonContainer,
  InsuranceColumn,
  LoadingSpinnerContainer,
  StyledFormPage,
  Title,
  TitleRow,
} from './StyledComponents'

type PatientMutationType = Pick<Patient, 'firstName' | 'lastName' | 'dob' | 'gender' | 'contacts' | 'preferences'> & {
  address?: { home?: Address; defaultShipping?: Address }
  insurances?: InsuranceInput[]
  medicalHistory?: Medispan.DURSubjectCategories
}

enum PatientFormMode {
  create = 'create',
  update = 'update',
}

const getPatientFormMode = (patientId?: string): PatientFormMode =>
  patientId ? PatientFormMode.update : PatientFormMode.create

const EditPatientPage = ({
  match: {
    params: { patientId },
  },
}: RouteComponentProps<{ patientId?: string }>): JSX.Element => {
  const { routeTo } = usePlusClient()
  const {
    state: { formData },
    actions: { clearFormData, updateFormData },
    changes: { getPatientChanges },
  } = useFormData()

  const { patient: originalPatient } = usePatient({ patientId })
  const [createPatient] = useCreatePatient()
  const [updatePatient] = useUpdatePatient()
  const [insurances, setInsurances] = useState<InsuranceInput[]>([])
  const showErrorToast = useErrorToast()
  const formMode = getPatientFormMode(patientId)

  // If there isn't a patientId then we're creating a new patient so reset the formData
  useEffect(() => {
    if (formMode === PatientFormMode.create) {
      clearFormData()
    }
  }, [patientId])

  // If someone navigates straight to the edit page we will need to populate the state
  const { error, loading } = useQuery<{ getPatient: Patient }>(GET_PATIENT, {
    skip: !patientId,
    variables: { patientId },
    onCompleted: ({ getPatient: patient }) => {
      updateFormData({ patient: { $set: patient } })
      setInsurances(patient?.insurances ?? [])
    },
  })

  const pageTitle = useMemo(() => {
    if (!patientId) return 'New patient'

    return !originalPatient
      ? 'Edit patient'
      : `Edit patient - ${originalPatient?.firstName} ${originalPatient?.lastName}`
  }, [originalPatient, patientId])

  useSetPageTitle(pageTitle)

  if (error) {
    return (
      <>
        <Title>Edit Patient</Title>
        <p> Error: Failed to get Patient Data {JSON.stringify(error)}</p>
      </>
    )
  }

  if (loading) {
    return (
      <>
        <Title>Edit Patient</Title>
        <LoadingSpinnerContainer>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      </>
    )
  }

  const patient = formData.patient
  const patientConditions = patient?.medicalHistory?.conditions?.filter(notEmpty) ?? []
  const patientAllergies = patient?.medicalHistory?.allergies?.filter(notEmpty) ?? []
  const patientMedications = patient?.medicalHistory?.medications?.filter(notEmpty) ?? []
  const patientCustomAllergies = patient?.medicalHistory?.custom?.allergies?.filter(notEmpty) ?? []
  const patientCustomConditions = patient?.medicalHistory?.custom?.conditions?.filter(notEmpty) ?? []
  const patientCustomMedications = patient?.medicalHistory?.custom?.medications?.filter(notEmpty) ?? []

  return (
    <>
      <TitleRow>
        <Title>{patientId ? <>Edit</> : <>New</>} patient</Title>
        <ExpandedActionButtonContainer>
          <Button
            data-testid="cancel"
            size="sm"
            variant="primary-text"
            onClick={() => {
              clearFormData()
              if (patientId) {
                routeTo.patient(patientId).now()
                return
              }

              routeTo.patients().now()
            }}
          >
            Cancel
          </Button>
          <Button
            size="sm"
            disabled={!isPatientFormValid(patient)}
            onClick={async () => {
              try {
                if (patientId) {
                  const patientChanges = getPatientChanges(originalPatient) as Partial<Patient>
                  const patch = {
                    ...patientChanges,
                    insurances: insurances.map(stripTypename),
                  } as PatientMutationType

                  await updatePatient({
                    variables: { patientId, ...patch },
                  })

                  routeTo.patient(patientId).now()
                } else {
                  const { data } = await createPatient({
                    variables: {
                      firstName: (patient as Patient).firstName,
                      lastName: (patient as Patient).lastName,
                      dob: (patient as Patient).dob,
                      gender: (patient as Patient).gender,
                      species: (patient as Patient).species,
                      contacts: (patient as Patient).contacts,
                      address: (patient as Patient).address,
                      preferences: (patient as Patient).preferences,
                    },
                  })

                  if (data?.createPatient._id) {
                    routeTo.patient(data.createPatient._id).now()
                  }
                }
              } catch (error) {
                if (error instanceof Error) {
                  showErrorToast(`Failed to ${formMode} patient: ${error.message}`)
                }
              }
            }}
          >
            {patientId ? 'Update' : 'Create'}
          </Button>
        </ExpandedActionButtonContainer>
      </TitleRow>

      <StyledFormPage>
        <DemographicColumn data-test-column="demographic">
          <TransparentHeader>Demographic</TransparentHeader>
          <PatientForm />
        </DemographicColumn>
        <DurColumn data-test-column="safety">
          <TransparentHeader>Safety check</TransparentHeader>
          <FormItem data-test-row="allergies">
            <DURAllergiesAutoComplete
              label="Allergies (optional)"
              hotKey="a"
              value={patientAllergies}
              customValue={patientCustomAllergies}
              onCustomInputChange={customAllergies => {
                if (!patient.medicalHistory?.custom) {
                  updateFormData({
                    patient: { medicalHistory: { custom: { $set: { allergies: customAllergies } } } },
                  })
                } else {
                  updateFormData({
                    patient: { medicalHistory: { custom: { $merge: { allergies: customAllergies } } } },
                  })
                }
              }}
              onChange={allergies =>
                updateFormData({
                  patient: { medicalHistory: { $merge: { allergies } } },
                })
              }
            />
          </FormItem>
          <FormItem data-test-row="conditions">
            <DURConditionsAutoComplete
              label="Conditions (optional)"
              hotKey="c"
              value={patientConditions}
              customValue={patientCustomConditions}
              onCustomInputChange={customConditions => {
                if (!patient.medicalHistory?.custom) {
                  updateFormData({
                    patient: { medicalHistory: { custom: { $set: { conditions: customConditions } } } },
                  })
                } else {
                  updateFormData({
                    patient: { medicalHistory: { custom: { $merge: { conditions: customConditions } } } },
                  })
                }
              }}
              onChange={conditions =>
                updateFormData({
                  patient: { medicalHistory: { $merge: { conditions } } },
                })
              }
            />
          </FormItem>
          <FormItem data-test-row="medications">
            <DURMedicationsAutoComplete
              label="Medications (optional)"
              hotKey="m"
              value={patientMedications}
              customValue={patientCustomMedications}
              onCustomInputChange={customMedications => {
                if (!patient.medicalHistory?.custom) {
                  updateFormData({
                    patient: { medicalHistory: { custom: { $set: { medications: customMedications } } } },
                  })
                } else {
                  updateFormData({
                    patient: { medicalHistory: { custom: { $merge: { medications: customMedications } } } },
                  })
                }
              }}
              onChange={medications =>
                updateFormData({
                  patient: { medicalHistory: { $merge: { medications } } },
                })
              }
            />
          </FormItem>
          <Preferences
            preferences={patient?.preferences ?? {}}
            onChange={preferences =>
              updateFormData({
                patient: {
                  preferences: { $set: preferences },
                },
              })
            }
          />
        </DurColumn>
        <InsuranceColumn>
          <Insurance insurances={insurances} setInsurances={setInsurances} />
        </InsuranceColumn>
      </StyledFormPage>
    </>
  )
}

export default EditPatientPage
