import { useQuery } from '@truepill/tpos-react-router'
import { Species, Languages, DEFAULT_LANGUAGE } from '@truepill/tpos-types'
import AddressEntry from 'components/AddressEntry'
import DURSubjectsList from 'components/DURSubjectsList'
import LoadingSpinner from 'components/Loading'
import { NoteEntry } from 'components/NotesPane/NotesList'
import { ActionButton, TransparentHeader } from 'components/PageStructure'
import { ListRowExtraValue, ExtraValueEntry, AliasMarker } from 'components/RXTable'
import { GET_PATIENT, GET_PATIENT_NOTES } from 'gql'
import { Box } from 'grommet/components/Box'
import moment from 'moment'
import styled from 'styled-components'
import {
  borderColor,
  contrastBackgroundColor,
  primaryBackgroundColor,
  primaryColor,
  subduedColor,
} from 'styles/styleVariables'
import type { Patient, Insurance, Note, Weight, DURSubject } from 'types'
import { capitalize, checkIfProvided, formatPhoneNumber, getCriticalNotes, convertWeight } from 'utils'
import CustomDURSubjectsList from './CustomDURSubjectsList'
import Lozenge from './Tiles/Lozenge'

type PatientIdProps = { patientId: Patient['_id'] }
type PatientProps = { patient: Patient }

const RXData = ({ patientId }: PatientIdProps): JSX.Element => {
  const { data, loading, error } = useQuery(GET_PATIENT, {
    variables: { patientId },
  })

  if (loading) {
    return (
      <LoadingSpinnerContainer>
        <LoadingSpinner />
      </LoadingSpinnerContainer>
    )
  }

  if (error) {
    return <p>Failed to load RX Data {JSON.stringify(error)} </p>
  }

  const { insurances = [], ...patient } = data.getPatient as Patient & {
    insurances: Insurance[]
  }

  return (
    <RXPatientDataContainer data-testid="PatientData">
      <RXDataContainer>
        <RXDataColumn data-test-column="patient">
          <TransparentHeader>Demographic information</TransparentHeader>
          <PatientBlock patient={patient} />
        </RXDataColumn>
        <RXDataColumn data-test-column="coverage">
          <TransparentHeader>Coverage</TransparentHeader>
          <CoverageBlock insurances={insurances} />
        </RXDataColumn>
        <RXDataColumn data-test-column="critical-notes">
          <TransparentHeader>Critical Notes</TransparentHeader>
          <Notes patientId={patientId} />
        </RXDataColumn>
      </RXDataContainer>
      <RXDataContainer>
        <RXDataColumn data-test-column="safety">
          <TransparentHeader>Safety check</TransparentHeader>
          <SafetyBlock patient={patient} />
        </RXDataColumn>
        <RXDataColumn>
          <TransparentHeader>Financials</TransparentHeader>
        </RXDataColumn>
        <RXDataColumn>
          <TransparentHeader>Tasks</TransparentHeader>
        </RXDataColumn>
      </RXDataContainer>
      <RXDataContainer>
        <RXDataColumn data-test-column="preferences">
          <TransparentHeader>Preferences</TransparentHeader>
          <PreferencesBlock patient={patient} />
        </RXDataColumn>
      </RXDataContainer>
    </RXPatientDataContainer>
  )
}

export const PatientBlock = ({ patient }: PatientProps): JSX.Element => {
  const patientDOB = moment(patient.dob).format('MM/DD/YYYY')
  const secondaryInfo = patient.secondaryInfo
  const isHuman = !patient.species || patient.species === Species.Human
  const patientWeight = patient.weight?.value ? convertWeight(patient.weight.value as number) : ({} as Weight)

  return (
    <>
      <RXDataRow data-test-row="name" showIcon={!!secondaryInfo?.names?.length}>
        <ListRowLabel>Name:</ListRowLabel>
        <ListRowValue data-private>
          {patient.firstName} {patient.lastName}
        </ListRowValue>
        {!!patient.secondaryInfo?.names?.length && (
          <ListRowExtraValue>
            {secondaryInfo.names.map((name, index) => (
              <ExtraValueEntry key={index} data-private>
                {name.firstName} {name.lastName} {<AliasMarker>(alias)</AliasMarker>}
              </ExtraValueEntry>
            ))}
          </ListRowExtraValue>
        )}
      </RXDataRow>
      <RXDataRow>
        <ListRowLabel>Species:</ListRowLabel>
        <ListRowValue data-private>{isHuman ? Species.Human : patient.species}</ListRowValue>
      </RXDataRow>
      {!isHuman && (
        <RXDataRow>
          <ListRowLabel>Guardian:</ListRowLabel>
          <ListRowValue data-private>{patient.guardian}</ListRowValue>
        </RXDataRow>
      )}
      <RXDataRow data-test-row="birthdate">
        <ListRowLabel>DOB:</ListRowLabel>
        <ListRowValue data-private>{patientDOB}</ListRowValue>
      </RXDataRow>
      {patient.weight?.value && (
        <RXDataRow data-test-row="weight">
          <ListRowLabel>Weight:</ListRowLabel>
          <ListRowValue data-private>{`${patientWeight.kg} kg / ${patientWeight.lb} lb`}</ListRowValue>
        </RXDataRow>
      )}
      <RXDataRow data-test-row="gender">
        <ListRowLabel>Gender:</ListRowLabel>
        <ListRowValue data-private>{capitalize(patient.gender)}</ListRowValue>
      </RXDataRow>
      <RXDataRow data-test-row="address" showIcon={!!secondaryInfo?.addresses?.length}>
        <ListRowLabel>Address:</ListRowLabel>
        <ListRowValue>
          <AddressEntry data-private address={patient.address?.home} />
        </ListRowValue>
        {!!secondaryInfo?.addresses?.length && (
          <ListRowExtraValue>
            {secondaryInfo.addresses.map((address, index) => (
              <ExtraValueEntry key={index}>
                <AddressEntry data-private address={address} />
              </ExtraValueEntry>
            ))}
          </ListRowExtraValue>
        )}
      </RXDataRow>
      <RXDataRow data-test-row="phone" showIcon={!!secondaryInfo?.phoneNumbers?.length}>
        <ListRowLabel>Phone:</ListRowLabel>
        <ListRowValue data-private>{checkIfProvided(formatPhoneNumber(patient.contacts?.phone))}</ListRowValue>
        {!!secondaryInfo?.phoneNumbers?.length && (
          <ListRowExtraValue>
            {secondaryInfo.phoneNumbers.map((phone, index) => (
              <ExtraValueEntry key={index} data-private>
                {checkIfProvided(formatPhoneNumber(phone))}
              </ExtraValueEntry>
            ))}
          </ListRowExtraValue>
        )}
      </RXDataRow>
      <RXDataRow data-test-row="email" showIcon={!!secondaryInfo?.emails?.length}>
        <ListRowLabel>Email:</ListRowLabel>
        <ListRowValue data-private>{checkIfProvided(patient.contacts?.email)}</ListRowValue>
        {!!secondaryInfo?.emails?.length && (
          <ListRowExtraValue>
            {secondaryInfo?.emails.map((email, index) => (
              <ExtraValueEntry key={index} data-private>
                {email}
              </ExtraValueEntry>
            ))}
          </ListRowExtraValue>
        )}
      </RXDataRow>
    </>
  )
}

export const PreferencesBlock = ({ patient }: PatientProps): JSX.Element => {
  const language = patient?.preferences?.language ?? DEFAULT_LANGUAGE
  const rows = [
    {
      name: 'Language',
      data: Languages[language],
    },
  ]

  return (
    <>
      {rows.map((row, i) => (
        <RXDataRow key={`preferences-${i}`} data-test-row={`preferences-${i}`}>
          <ListRowLabel>{row.name}:</ListRowLabel>
          <ListRowValue>{row.data}</ListRowValue>
        </RXDataRow>
      ))}
    </>
  )
}

export const SafetyBlock = ({ patient }: PatientProps): JSX.Element => {
  const { medicalHistory } = patient
  return (
    <>
      <RXDataRow data-test-row="allergies">
        <ListRowLabel>Allergies:</ListRowLabel>
        <ListRowValue>
          {medicalHistory?.allergies && medicalHistory.allergies.length !== 0 ? (
            <DURSubjectsList subjects={medicalHistory.allergies} />
          ) : (
            <>{!medicalHistory?.custom?.allergies?.length && <>None provided</>}</>
          )}
          {!!medicalHistory?.custom?.allergies?.length && (
            <div>
              <CustomDURSubjectsList subjects={medicalHistory?.custom?.allergies} />
            </div>
          )}
        </ListRowValue>
      </RXDataRow>
      <RXDataRow data-test-row="medications">
        <ListRowLabel>Medications:</ListRowLabel>
        <ListRowValue>
          {medicalHistory?.medications && medicalHistory.medications.length !== 0 ? (
            <DURSubjectsList subjects={medicalHistory.medications} />
          ) : (
            <>{!medicalHistory?.custom?.medications?.length && <>None provided</>}</>
          )}
          {!!medicalHistory?.custom?.medications?.length && (
            <div>
              <CustomDURSubjectsList subjects={medicalHistory?.custom?.medications} />
            </div>
          )}
        </ListRowValue>
      </RXDataRow>
      <RXDataRow data-test-row="conditions">
        <ListRowLabel>Conditions:</ListRowLabel>
        <ListRowValue>
          {medicalHistory?.conditions && medicalHistory.conditions.length !== 0 ? (
            <DURSubjectsList subjects={medicalHistory.conditions as DURSubject[]} />
          ) : (
            <>{!medicalHistory?.custom?.conditions?.length && <>None provided</>}</>
          )}
          {!!medicalHistory?.custom?.conditions?.length && (
            <div>
              <CustomDURSubjectsList subjects={medicalHistory?.custom?.conditions} />
            </div>
          )}
        </ListRowValue>
      </RXDataRow>
    </>
  )
}

type CoverageBlockProps = { insurances: Insurance[] }

const CoverageBlock = ({ insurances }: CoverageBlockProps): JSX.Element => {
  return (
    <>
      {insurances.map((insurance, i) => (
        <RXDataRow key={'insurance ' + insurance._id}>
          <ListRowLabel>
            <span>Payer {i + 1}:</span>
            {insurance.governmentSponsoredPlan && (
              <StyledLozenge backgroundColor={subduedColor}>Government</StyledLozenge>
            )}
          </ListRowLabel>
          <InsuranceListRowValue>
            <Box direction="row" data-testid="insurance-name">
              <InsuranceLabel>Name:</InsuranceLabel> {checkIfProvided(insurance.name)}
            </Box>
            <Box direction="row" data-testid="insurance-group">
              <InsuranceLabel>Group #:</InsuranceLabel> {checkIfProvided(insurance.group)}
            </Box>
            <Box direction="row" data-testid="cardholder-id">
              <InsuranceLabel>Cardholder ID:</InsuranceLabel> {checkIfProvided(insurance.cardholderId)}
            </Box>
            <Box direction="row" data-testid="rx-bin">
              <InsuranceLabel>RxBIN:</InsuranceLabel> {checkIfProvided(insurance.bin)}
            </Box>
            <Box direction="row" data-testid="rx-pcn">
              <InsuranceLabel>RxPCN:</InsuranceLabel> {checkIfProvided(insurance.pcn)}
            </Box>
            <Box direction="row" data-testid="pcn">
              <InsuranceLabel>Person code:</InsuranceLabel> {checkIfProvided(insurance.personCode)}
            </Box>
            <Box direction="row" data-testid="relationship">
              <InsuranceLabel>Relationship:</InsuranceLabel> {checkIfProvided(insurance.relationship)}
            </Box>
          </InsuranceListRowValue>
        </RXDataRow>
      ))}
      {false && (
        <EligibilityCheck>
          <StyledActionButton label={'Eligibility check'} />
        </EligibilityCheck>
      )}
    </>
  )
}

const Notes = ({ patientId }: PatientIdProps): JSX.Element => {
  const { data, loading, error } = useQuery<{ notes: Note[] }>(GET_PATIENT_NOTES, {
    variables: { patientId },
  })

  if (error) {
    return <AnnotationContainer>Error loading notes: {JSON.stringify(error)}</AnnotationContainer>
  }

  if (loading) {
    return (
      <AnnotationContainer>
        <LoadingSpinnerContainer>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      </AnnotationContainer>
    )
  }

  return (
    <AnnotationContainer>
      <StyledList>
        {getCriticalNotes(data?.notes).map(criticalNote => (
          <NoteEntry key={criticalNote._id} note={criticalNote} />
        ))}
      </StyledList>
    </AnnotationContainer>
  )
}

const AnnotationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

const StyledList = styled.ul`
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  flex-grow: 1;
  overflow-y: auto;
  > :first-child {
    margin-top: 0rem;
  }
`

const RXPatientDataContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`

const InsuranceLabel = styled.span`
  font-weight: 500;
  font-size: 14px;
  margin-right: 0.325rem;
`

const StyledLozenge = styled(Lozenge)`
  margin-left: 0;
`

const RXDataContainer = styled.ul`
  display: flex;
  flex-basis: 100%;
  flex-direction: row;
  align-items: flex-start;
  :not(:last-child) {
    margin-bottom: 1.75rem;
  }
  :last-child {
    margin-bottom: 1rem;
  }
`

const RXDataColumn = styled.ul`
  :not(:last-child) {
    margin-right: 1.25rem;
  }
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 33%;
`

const RXDataRow = styled.ul<{ showIcon?: boolean }>`
  display: grid;
  border-radius: 0.5rem;
  grid-template-rows: auto;
  grid-template-columns: [label] 7rem [value] 1fr;
  padding: 0.5rem 0.3125rem;
  :nth-of-type(even) {
    background-color: ${contrastBackgroundColor};
  }
  grid-template-columns: [label] 6rem [value] 1fr ${({ showIcon }) => (showIcon ? '[icon] 1.25rem' : '')};
`
const ListRowLabel = styled.li`
  grid-row: 1;
  grid-column: label;
  padding-right: 0.3125rem;
  padding-left: 0.425rem;
  font-size: 0.875rem;
  font-weight: 500;
`
const ListRowValue = styled.li.attrs({
  'data-testid': 'value',
})`
  grid-row: 1;
  grid-column: value;
`

const InsuranceListRowValue = styled(ListRowValue)`
  margin-left: 2rem;
  margin-top: 0.25rem;
`

const EligibilityCheck = styled.div`
  display: flex;
  width: 100%;
`

const StyledActionButton = styled(ActionButton)`
  border-color: ${borderColor};
  width: 100%;
  margin-left: 0;
  background-color: ${primaryBackgroundColor};
  :hover {
    border: 2px solid ${primaryColor};
    outline-color: ${primaryColor};
  }
`

const LoadingSpinnerContainer = styled.div`
  display: flex;
  width: 100%;
  padding-top: 0.8rem;
  justify-content: center;
  align-items: center;
  svg {
    height: 25px;
  }
`

export default RXData
