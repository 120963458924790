import { useQuery } from '@truepill/tpos-react-router'
import AutoCompleteOption from 'components/MedicationAutocompleteOption'
import SearchAutoComplete from 'components/SearchAutoComplete'
import { SearchResultLozenge, SearchResultLozengeList } from 'components/Tiles/Lozenge'
import { SEARCH_PRESCRIPTION_MEDICATIONS } from 'gql'
import useStateWithDebouncedValue from 'hooks/useStateWithDebouncedValue'
import { usePlusClient } from 'providers/VisionRouter'
import { bodySecondaryColor } from 'styles/styleVariables'
import type { MedicationSuggestion, MedicationSuggestionItem } from 'types'

type PrescriptionMedicationSearchOptionsProps = {
  isVisible?: boolean
  hotKey?: string
  onSelect?: (medication: MedicationSuggestion) => void
}
const PrescriptionMedicationSearchOptions = ({
  hotKey = 'm',
  isVisible,
  onSelect,
}: PrescriptionMedicationSearchOptionsProps): JSX.Element => {
  const { currentLocation, routeToMergedQuery } = usePlusClient()
  const [searchTerm, debouncedSearchTerm, setSearchTerm] = useStateWithDebouncedValue('')
  const { data, loading, error } = useQuery(SEARCH_PRESCRIPTION_MEDICATIONS, {
    skip: debouncedSearchTerm.length < 3,
    variables: {
      [isFinite(Number.parseInt(debouncedSearchTerm)) ? 'ndc' : 'name']: debouncedSearchTerm,
    },
  })

  // select the correct query returns based on the search param sent
  const options: MedicationSuggestionItem[] =
    debouncedSearchTerm.length >= 3 && data?.prescriptionSearchMedications
      ? data.prescriptionSearchMedications.map((searchResult: MedicationSuggestion) => ({
          name: searchResult.name,
          ndc: searchResult.ndc,
        }))
      : []

  // Handle errors / loading state
  if (error) {
    options.push({
      error: `Error searching Medications: ${error.message}`,
    })
  } else if (loading) {
    options.push({
      loading,
    })
  } else if (debouncedSearchTerm && !options.length) {
    options.push({
      noResults: true,
    })
  }

  const medications = currentLocation?.queryMap?.medications || []

  return (
    <>
      <SearchAutoComplete
        hotKey={isVisible ? hotKey : ''}
        iconColor={bodySecondaryColor}
        autoCompleteSuggestions={options}
        value={searchTerm}
        suggestionComponent={optionProps => <AutoCompleteOption {...(optionProps as MedicationSuggestionItem)} />}
        onChange={newValue => setSearchTerm(newValue)}
        placeholder="Search by name or NDC..."
        onSelect={chosen => {
          setSearchTerm('')
          if (!chosen) {
            return
          }

          if (!medications.includes(chosen.name)) routeToMergedQuery({ medications: [...medications, chosen.name] })
          onSelect?.(chosen)
        }}
      />
      <SearchResultLozengeList>
        {medications.map((name: string, idx: number) => {
          return (
            <SearchResultLozenge
              key={idx}
              closeCallback={() => {
                if (medications.includes(name)) {
                  const newMeds = [...medications]
                  newMeds.splice(medications.indexOf(name), 1)

                  routeToMergedQuery({ medications: newMeds })
                }
              }}
            >
              <p>{name}</p>
            </SearchResultLozenge>
          )
        })}
      </SearchResultLozengeList>
    </>
  )
}

export default PrescriptionMedicationSearchOptions
