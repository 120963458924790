/**
 * Validates a given DEA number with the following rules:
 * 2 letters followed by 7 numbers
 * The first letter is one of A, B, F, G, M, P, R, or X
 * The second letter is EITHER the first letter of the prescriber’s last name, or "9"
 * The last digit is a checksum calculated as such:
 * Add together the first, third and fifth digits, call this CALC1,3,5
 * Add together the second, fourth and sixth digits and multiply the sum by 2, call this CALC2,4,6
 * Add CALC1,3,5 + CALC2,4,6 - call this CHECK
 * The rightmost digit of CHECK (the digit in the ones place) is used as the check digit in the DEA number
 */
export const validateDEANumber = (deaNumber: string, prescriberLastName = '') => {
  if (deaNumber?.length !== 9) return false

  const firstLetter = deaNumber.substring(0, 1)
  if (!new Set(['A', 'B', 'F', 'G', 'M', 'P', 'R', 'X']).has(firstLetter.toUpperCase())) return false

  const secondLetter = deaNumber.substring(1, 2)
  if (secondLetter !== '9' && secondLetter.toUpperCase() !== prescriberLastName.substring(0, 1).toUpperCase())
    return false

  const calcA =
    parseInt(deaNumber.substring(2, 3)) + parseInt(deaNumber.substring(4, 5)) + parseInt(deaNumber.substring(6, 7))
  const calcB =
    2 *
    (parseInt(deaNumber.substring(3, 4)) + parseInt(deaNumber.substring(5, 6)) + parseInt(deaNumber.substring(7, 8)))
  const check = (calcA + calcB).toString()

  if (check.slice(-1) !== deaNumber.substring(8, 9)) return false
  return true
}
