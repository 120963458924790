import { Accordion, AccordionPanel, Box } from 'grommet'
import styled from 'styled-components'

type ApiRequestDetailsProps = {
  apiRequest: Record<string, any>
}

const ApiRequestParams = ({ apiRequest }: ApiRequestDetailsProps): JSX.Element => {
  const formattedRequestParams = JSON.stringify(apiRequest, null, 2)
  return <pre>{formattedRequestParams}</pre>
}

const ApiRequestDetails = ({ apiRequest }: ApiRequestDetailsProps): JSX.Element => (
  <ApiRequestContainer data-testid="ApiRequestDetails" background="light-2" animate={false}>
    <StyledAccordionPanel label="API request">
      <StyledBox pad="small" background="light-2">
        <ApiRequestParamsStyled data-private apiRequest={apiRequest} />
      </StyledBox>
    </StyledAccordionPanel>
  </ApiRequestContainer>
)

const ApiRequestParamsStyled = styled(ApiRequestParams)`
  font-family: monospace;
  color: #242424;
`

const ApiRequestContainer = styled(Accordion)`
  border-radius: 4px;
  color: #000000;
  font-family: monospace;
  font-weight: 500;
  margin-top: 10px;
  :hover:not(:focus-visible),
  :focus:not(:focus-visible) {
    box-shadow: none;
    outline: none;
  }
  z-index: 0 !important;
`
const StyledAccordionPanel = styled(AccordionPanel)`
  padding: 10px;
`
const StyledBox = styled(Box)`
  overflow-x: scroll;
`

export default ApiRequestDetails
