import { useEffect } from 'react'
import { BannerAlert, Spacer, Text } from '@truepill/react-capsule'
import { SaveButton } from 'components/ActionButton'
import { ModalWrapper, ModalHeader, InputContainer, ButtonsContainer } from 'components/Modal'
import { CancelButton } from 'components/PageStructure'
import type { UserFormData } from 'components/UserForm'
import UserForm from 'components/UserForm'
import { useFormData } from 'hooks/useFormData'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import styled from 'styled-components'
import type { User } from 'types'

interface ModalProps {
  confirmationCallback: (newUserData: UserFormData) => void
  user?: User
  locationId?: string
}

const validString = (text: string) => text?.length > 0

const UserModal = (props: ModalProps): JSX.Element => {
  const title = props.user ? `Edit ${props.user.firstName} ${props.user.lastName}` : `New user`

  const userData = { locationId: '', ...props.user, licenses: [] } as UserFormData
  if (props.locationId) userData.locationId = props.locationId
  if (props.user?.location) userData.locationId = props.user?.location._id

  const {
    actions: { updateFormData },
  } = useFormData()

  useEffect(() => {
    if (props.user) {
      updateFormData({ user: { $set: props.user } })
    }
  }, [props.user, updateFormData])

  return (
    <ModalWrapper id="UserModal">
      <ModalHeader>
        <h2>{title}</h2>
      </ModalHeader>
      <UserEditContent {...props} isNewUser={!props.user} />
    </ModalWrapper>
  )
}

const UserEditContent = (props: ModalProps & { isNewUser: boolean }) => {
  const { isNewUser } = props
  const { dismissModal } = useModalContext()
  const {
    state: { formData },
    actions: { updateFormData },
  } = useFormData()
  const userFormData = formData.user as User

  const { lastName, firstName, email, roles = [], locationId, licenses } = userFormData

  const licenseStates = licenses?.map(l => l.registeredState) ?? []
  const licensesHasSameState = licenseStates.length !== [...new Set(licenseStates)].length

  const licenseValidation = licenses
    ?.map(l => [l.number, l.registeredState])
    .flat()
    .every(validString)

  const saveDisabled =
    (isNewUser && (![lastName, firstName, email].every(validString) || roles.length === 0 || !locationId)) ||
    licensesHasSameState ||
    !licenseValidation

  useEffect(() => {
    return () => updateFormData({ user: { $set: {} } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <InputContainer>
        <StyledUserForm modal isNewUser={isNewUser} />

        {licensesHasSameState && (
          <>
            <Spacer />
            <BannerAlert state="error">
              <Text variant="body-sm">Licenses cannot have the same registered state.</Text>
            </BannerAlert>
          </>
        )}
      </InputContainer>
      <ButtonsContainer>
        <CancelButton label={'Cancel'} onClick={dismissModal} />
        <SaveButton
          disabled={saveDisabled}
          label={props.user ? 'Update' : 'Create'}
          isModal
          onClick={() => {
            dismissModal()
            props.confirmationCallback(userFormData)
          }}
        />
      </ButtonsContainer>
    </>
  )
}

const StyledUserForm = styled(UserForm)`
  margin-top: 0rem;
  > :first-child {
    margin-top: 0.625rem;
  }
`

export default UserModal
