import { ReactComponent as RadioIcon } from 'assets/icons/radio.svg'
import styled, { css } from 'styled-components'
import { color } from './styleVariables'

const SelectArrow = styled.div`
  width: 0;
  height: 0;
  border-left: 0.375rem solid transparent;
  border-right: 0.375rem solid transparent;
  border-top: 0.5rem solid ${color.bodySecondary};
`

const grommetTheme = {
  global: {
    font: {
      family: 'Roboto',
      size: '1rem',
    },
    colors: {
      brand: color.primary,
      active: 'brand',
      focus: 'brand',
      'background-contrast': color.contrast,
    },
    hover: {
      background: {
        color: 'active',
        opacity: 1,
      },
      color: 'white',
    },
    input: {
      weight: 400,
      padding: {
        vertical: '0.2em',
      },
    },
    active: {
      background: {
        color: 'brand',
        opacity: 1,
      },
      color: 'white',
    },
    drop: {
      'z-index': 4,
      border: {
        radius: '0.25rem',
      },
    },
  },
  layer: {
    overlay: {
      background: 'rgba(0, 0, 0, 0.0)',
    },
  },
  button: {
    border: {
      radius: '0.25rem',
      color: { dark: color.subdued, light: color.subdued },
    },
    padding: {
      vertical: '0.4rem',
    },
    active: {
      border: {
        color: { dark: 'brand', light: 'brand' },
      },
    },
    extend: 'font-weight: 500;',
  },
  text: {
    medium: {
      size: '1rem',
    },
  },
  textInput: {
    suggestions: {
      extend: `
        button {
font-size: 1rem;
          padding: 0rem;
        }
      `,
    },
  },
  select: {
    icons: {
      down: <SelectArrow />,
      margin: '0.625rem !important',
    },
    control: {
      extend: `border: 0.125rem solid ${color.border};`,
    },
    options: {
      container: {
        pad: 'none',
      },
    },
  },
  table: {
    header: {
      background: 'background-contrast',
      extend: {
        fontWeight: '500',
      },
    },
  },
  checkBox: {
    hover: {
      border: {
        color: 'brand',
      },
    },
    check: { radius: '2px' },
    size: '1em',
    gap: '0.5rem !important',
  },
  radioButton: {
    size: '15px',
    hover: {
      border: {
        color: color.primary,
      },
    },
    icons: {
      circle: RadioIcon,
    },
  },
  accordion: {
    icons: {
      color: '#000000',
    },
    hover: {
      heading: {
        color: undefined,
      },
    },
    border: undefined,
  },
  tabs: {
    header: {
      background: 'transparent',
      extend: css`
        border-bottom: 1px solid ${color.border};
      `,
    },
  },
  tab: {
    color: '#fff',
    border: undefined,
    background: color.primary,
    active: {
      border: color.primary,
      color: '#fff',
    },
  },
}

export default grommetTheme
