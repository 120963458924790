import type {
  RouteOptions,
  RouteOptionsWithParams,
  RouteThisContext,
  CentralRoutes,
  RouteToGeneric,
  NormalizedCacheObject,
  ApolloClient,
  history as H,
} from '@truepill/tpos-react-router'
import { ClientRouter, useClientRouter } from '@truepill/tpos-react-router'
import { UserRoles } from '@truepill/tpos-types'
import { LOGIN, ME, UPDATE_USER_INFO_FROM_AUTH0 } from 'gql'
import { FulfillmentQueueName } from 'hooks/useFulfillmentQueue'
import type { TaskQueueName } from 'hooks/useTaskQueue'
import { RoutePath } from 'routes'
import type { ChildProps } from 'types'
import { getBackendUrl } from 'utils/urls'

interface DecodedToken {
  id: string
  firstName: string
  lastName: string
  user: string
  locationId?: string
  scope: string[]
  forcePasswordReset?: boolean
  ediEdit: string
}

interface TransformedToken extends DecodedToken {
  roles: Record<string, boolean>
  hasRole: (roles: string | string[]) => boolean
  isAdmin: () => boolean
  isCustomerSupport: () => boolean
  isPharmacist: () => boolean
  isLeadCustomerSupport: () => boolean
  isLeadPharmacist: () => boolean
  isTechnician: () => boolean
  isCompliance: () => boolean
}

interface Routes {
  dashboard: (this: RouteThisContext<Routes, TransformedToken>, extended?: RouteOptions) => RouteOptionsWithParams
  order: (
    this: RouteThisContext<Routes, TransformedToken>,
    orderId: string,
    fulfillment?: boolean,
    extended?: RouteOptions,
  ) => RouteOptionsWithParams
  orders: (this: RouteThisContext<Routes, TransformedToken>, extended?: RouteOptions) => RouteOptionsWithParams
  fulfillment: (
    this: RouteThisContext<Routes, TransformedToken>,
    orderId: string,
    fillId: string,
    fulfillmentQueueName: FulfillmentQueueName | 'all',
    extended?: RouteOptions,
  ) => RouteOptionsWithParams
  fulfillmentQueue: (
    this: RouteThisContext<Routes, TransformedToken>,
    fulfillmentQueueName: FulfillmentQueueName | 'all',
    extended?: RouteOptions,
  ) => RouteOptionsWithParams
  taskQueue: (
    this: RouteThisContext<Routes, TransformedToken>,
    taskQueueName: TaskQueueName,
    extended?: RouteOptions,
  ) => RouteOptionsWithParams
  payers: (this: RouteThisContext<Routes, TransformedToken>, extended?: RouteOptions) => RouteOptionsWithParams
  payer: (
    this: RouteThisContext<Routes, TransformedToken>,
    payerId: string,
    extended?: RouteOptions,
  ) => RouteOptionsWithParams
  patients: (this: RouteThisContext<Routes, TransformedToken>, extended?: RouteOptions) => RouteOptionsWithParams
  patient: (
    this: RouteThisContext<Routes, TransformedToken>,
    patientId: string,
    extended?: RouteOptions,
  ) => RouteOptionsWithParams
  rxIntakeIssues: (this: RouteThisContext<Routes, TransformedToken>, extended?: RouteOptions) => RouteOptionsWithParams
}

type usePlusClientType = Omit<typeof useClientRouter, 'tokenContext' | 'routeTo'> & {
  tokenContext: TransformedToken
  routeTo: Omit<CentralRoutes<Routes>, 'landingPage'> & {
    landingPage: () => RouteToGeneric
  }
  isAuthenticated: boolean
  routeToHash: (hash: string) => void
  routeToMergedQuery: (toMerge: any) => void
  loginUser: (username: string, password: string) => Promise<boolean>
  loginUserAuth0: (token: string, newLogin: boolean) => Promise<boolean>
  routeToQuery: (newQueryParams: any) => void
  client: ApolloClient<NormalizedCacheObject>
  QueryToolkit: {
    StringBoolean: (val: string) => boolean
  }
  logoutUser: () => void
  currentLocation: {
    queryMap: any
    path: string
    hash?: string
  }
}

const useV2: string | null = localStorage.getItem('use-v2')
const defaultBackendUrl = `${window.location.protocol}//${window.location.hostname}/${useV2 ? 'v2/graphql' : 'graphql'}`
const backendUrl = getBackendUrl(useV2 === 'true') || defaultBackendUrl

// This function is also used by Auth0Provider to redirect to landing page
export const getDefaultPath = (
  tokenContext: TransformedToken,
  landingPage: H.LocationDescriptorObject,
  routeTo:
    | CentralRoutes<Routes>
    | (Omit<CentralRoutes<Routes>, 'landingPage'> & {
        landingPage: () => RouteToGeneric
      }),
): H.LocationDescriptorObject => {
  if (tokenContext.forcePasswordReset)
    return {
      pathname: RoutePath.ResetPassword,
    }

  if (landingPage && !['/', '/login'].includes(landingPage.pathname as string)) return { ...landingPage }

  if (tokenContext.isPharmacist() || tokenContext.isCustomerSupport())
    return routeTo.fulfillmentQueue('all', {
      searchMap: {
        locationId: tokenContext.locationId,
      },
    })

  return {
    pathname: RoutePath.Root,
  }
}

const usePlusClient = (): usePlusClientType => useClientRouter<TransformedToken, Routes>()

const VisionRouter = ({ children }: ChildProps): JSX.Element => {
  return (
    <ClientRouter<Routes, TransformedToken>
      loginQuery={[LOGIN, 'getJwtToken.token']}
      userProfileQuery={[ME, 'me']}
      userUpsertMutation={[UPDATE_USER_INFO_FROM_AUTH0, 'updateUserInfoFromAuth0']}
      skipBatchOps={[
        'getTPCacheData',
        'substitutionSelectQuery',
        'getNdcFullInfo',
        'getOrders',
        'getPrescriber',
        'getFullOrder',
        'confirmFillPV1',
        'getNextPV1',
        'getNextOtcPacking',
        'setInTriage',
        'createNote',
      ]}
      path={backendUrl}
      transformToken={(decoded: DecodedToken): TransformedToken => {
        const roles = (decoded.scope || []).reduce(
          (acc: Record<string, boolean>, role: string) => ({ ...acc, ...{ [role]: true } }),
          {},
        )

        return {
          ...decoded,
          roles,
          hasRole: (checkRoles: string | string[]) => {
            const normalized = Array.isArray(checkRoles) ? checkRoles : [checkRoles]

            return normalized.some(checkRole => roles[checkRole])
          },
          isAdmin: () => roles[UserRoles.Admin],
          isCustomerSupport: () => roles[UserRoles.CustomerSupport] || roles[UserRoles.LeadCustomerSupport],
          isPharmacist: () => roles[UserRoles.Pharmacist] || roles[UserRoles.LeadPharmacist],
          isLeadCustomerSupport: () => roles[UserRoles.LeadCustomerSupport],
          isLeadPharmacist: () => roles[UserRoles.LeadPharmacist],
          isTechnician: () => roles[UserRoles.Technician],
          isCompliance: () => roles[UserRoles.Compliance],
        }
      }}
      routes={{
        dashboard: function (extended) {
          return {
            ...extended,
            pathname: '/',
          }
        },
        fulfillment: function (
          orderId: string,
          fillId: string,
          fulfillmentQueueName: FulfillmentQueueName | 'all',
          extended: RouteOptions = {},
        ) {
          return {
            pathname: RoutePath.FulfillmentFill,
            params: {
              fulfillmentQueueName,
              orderId,
              fillId,
            },
            ...extended,
          }
        },
        fulfillmentQueue: function (
          fulfillmentQueueName: FulfillmentQueueName | 'all' = 'all',
          extended: RouteOptions = {},
        ) {
          return {
            pathname: RoutePath.Fulfillment,
            params: {
              fulfillmentQueueName,
            },
            ...extended,
          }
        },
        taskQueue: function (taskQueueName: TaskQueueName, extended: RouteOptions = {}) {
          return {
            pathname: RoutePath.Tasks,
            params: {
              taskQueueName,
            },
            ...extended,
          }
        },
        payers(extended: RouteOptions = {}) {
          return {
            pathname: RoutePath.ViewPayers,
            ...extended,
          }
        },
        payer(payerId: string, extended: RouteOptions = {}) {
          return {
            pathname: RoutePath.ViewPayer,
            params: {
              payerId,
            },
            ...extended,
          }
        },
        patients(extended: RouteOptions = {}) {
          return {
            pathname: RoutePath.ViewPatients,
            ...extended,
          }
        },
        patient(patientId: string, extended: RouteOptions = {}) {
          return {
            pathname: RoutePath.ViewPatient,
            params: {
              patientId,
            },
            ...extended,
          }
        },
        landingPage: function (landingPage: H.LocationDescriptorObject): RouteOptionsWithParams {
          return getDefaultPath(this.tokenContext, landingPage, this.routeTo)
        },
        order: function (orderId: string, fulfillment = false, extended: RouteOptions = {}) {
          const pathname = fulfillment ? RoutePath.FulfillmentOrder : RoutePath.ViewPharmacyOrder
          const params = fulfillment ? { orderId, fulfillmentQueueName: FulfillmentQueueName.Packing } : { orderId }
          return {
            pathname,
            params,
            ...extended,
          }
        },
        orders: function (extended: RouteOptions = {}) {
          const { tokenContext } = this

          return {
            pathname: RoutePath.ViewPharmacyOrders,
            searchMap: {
              locationId: tokenContext?.locationId,
            },
            ...extended,
          }
        },
        rxIntakeIssues: function (extended: RouteOptions = {}) {
          const { tokenContext } = this
          return {
            pathname: RoutePath.ViewRxIntakeIssues,
            searchMap: {
              locationId: tokenContext?.locationId,
            },
            ...extended,
          }
        },
      }}
    >
      {children}
    </ClientRouter>
  )
}

export { usePlusClient }
export default VisionRouter
