import { useMutation } from '@truepill/tpos-react-router'
import type { Preferences } from '@truepill/tpos-types'
import { CREATE_PATIENT } from 'gql'
import type { Address, DURSubject, InsuranceInput, Patient } from 'types'

export default function useCreatePatient() {
  return useMutation<
    { createPatient: Patient },
    Pick<Patient, 'firstName' | 'lastName' | 'dob' | 'gender' | 'contacts' | 'species'> & {
      address?: {
        home?: Address
        defaultShipping?: Address
      }
      insurances?: InsuranceInput[]
      medicalHistory?: {
        allergies?: DURSubject[]
        conditions?: DURSubject[]
        medications?: DURSubject[]
      }
      preferences?: Preferences
    }
  >(CREATE_PATIENT)
}
