import { FormItem, FormItems } from 'components/ColumnForm'
import { NumericInput } from 'components/NumericInput'
import type { InsuranceProvider } from 'types'
import { updatePayerFormConfigurations } from './util'

type PayerMinimumProfitRejectionFormProps = {
  payerForm: InsuranceProvider
  updatePayerForm: (update: InsuranceProvider) => void
}

const PayerMinimumProfitRejectionForm = ({
  payerForm,
  updatePayerForm,
}: PayerMinimumProfitRejectionFormProps): JSX.Element => (
  <FormItems>
    <FormItem data-test-row="percent-submitted">
      <p>Percent of submitted</p>
      <NumericInput
        value={payerForm.configurations?.minimumProfitRejection?.percentSubmitted}
        onChange={value => {
          updatePayerFormConfigurations(payerForm, updatePayerForm, 'minimumProfitRejection', 'percentSubmitted', value)
        }}
      />
    </FormItem>
    <FormItem data-test-row="percent-over-cost">
      <p>Percent over cost</p>
      <NumericInput
        value={payerForm.configurations?.minimumProfitRejection?.percentOverCost}
        onChange={value => {
          updatePayerFormConfigurations(payerForm, updatePayerForm, 'minimumProfitRejection', 'percentOverCost', value)
        }}
      />
    </FormItem>
    <FormItem data-test-row="amount-due-over-cost">
      <p>Amount due over cost</p>
      <NumericInput
        value={payerForm.configurations?.minimumProfitRejection?.amountDueOverCost}
        onChange={value => {
          updatePayerFormConfigurations(
            payerForm,
            updatePayerForm,
            'minimumProfitRejection',
            'amountDueOverCost',
            value,
          )
        }}
      />
    </FormItem>
  </FormItems>
)

export default PayerMinimumProfitRejectionForm
