import { createContext, useContext, useEffect } from 'react'
import { useQuery } from '@truepill/tpos-react-router'
import { GET_TP_CACHE_DATA } from 'gql'
import groupBy from 'lodash.groupby'
import keyBy from 'lodash.keyby'
import type { Printer, User, Customer, ChildProps, TPOSLocation } from 'types'
import { updateCustomer, updateLocation, updatePrinter, updateUser } from 'utils/subscribeToolkit'
import { usePlusClient } from './VisionRouter'

type IdToName = (id: string) => string

type TPCache = {
  initialized: boolean
  customers: Customer[]
  customersById: Record<string, Customer>
  locations: TPOSLocation[]
  locationsById: Record<string, TPOSLocation>
  printers: Printer[]
  printerMakes: string[]
  printersById: Record<Printer['_id'], Printer>
  users: User[]
  usersById: Record<string, User>
  usersByLocation: Record<string, User[]>
  getMyPrinters: () => Printer[]
  getPrinterById: (printerId: string) => Printer
  getPrintersByLocation: (locationId: string) => Printer[]
  getPrinterMakes: IdToName
  getCustomerNameById: IdToName
  getCustomerById: (customerId: string) => Customer
  getLocationNameById: IdToName
  getLocationById: (locationId: string) => TPOSLocation
  getLocations: () => TPOSLocation[]
  getUsers: () => User[]
  getUsersByLocationId: (locationId: string) => User[]
  getUserById: (userId: string) => User
}

const internalCacheDefaults: TPCache = {
  initialized: false,
  customers: [],
  customersById: {},
  locations: [],
  locationsById: {},
  printers: [],
  printerMakes: [],
  printersById: {},
  users: [],
  usersById: {},
  usersByLocation: {},
  getMyPrinters: () => [],
  getPrinterById: () => ({} as Printer),
  getPrintersByLocation: () => [],
  getPrinterMakes: () => '',
  getCustomerNameById: () => '',
  getCustomerById: () => ({} as Customer),
  getLocationNameById: () => '',
  getLocationById: () => ({} as TPOSLocation),
  getLocations: () => [],
  getUsers: () => [],
  getUserById: () => ({} as User),
  getUsersByLocationId: () => [],
}

const TPCacheContext = createContext(internalCacheDefaults)

const useTPCacheContext = (): TPCache => {
  const tpCacheContext = useContext(TPCacheContext)

  if (tpCacheContext === undefined) {
    throw new Error('Attempting to read TPCacheContext outside a Provider heirarchy')
  }

  return tpCacheContext
}

const TPCacheProvider = ({ children }: ChildProps): JSX.Element => {
  const { isAuthenticated, tokenContext } = usePlusClient()
  const { loading, data, subscribeToMore } = useQuery(GET_TP_CACHE_DATA, { skip: !isAuthenticated })

  useEffect(() => {
    if (isAuthenticated) {
      try {
        updateCustomer(subscribeToMore)
        updatePrinter(subscribeToMore)
        updateLocation(subscribeToMore)
        updateUser(subscribeToMore)
      } catch (error) {
        console.error(`Error subscribing to cache updates: ${(error as Error)?.message}`)
      }
    }
  }, [subscribeToMore, isAuthenticated])

  const customers: Customer[] = data?.getCustomers || []
  const customersById = keyBy<Customer>(customers, '_id')

  const locations: TPOSLocation[] = data?.getLocations || []
  const locationsById = keyBy<TPOSLocation>(locations, '_id')

  const printers: Printer[] = data?.getPrinters || []

  const printerMakes: string[] = Array.from(new Set(printers.map(item => item.make))) || []
  const printersById = keyBy<Printer>(printers, '_id')
  const printersByLocation = groupBy<Printer>(printers, 'locationId')

  const users: User[] = (data?.getUsers || []).map((user: User) => ({
    ...user,
    location: locationsById[user.locationId],
  }))
  const usersById = keyBy<User>(users, '_id')
  const usersByLocation = groupBy<User>(users, 'locationId')

  const cache: TPCache = {
    initialized: !loading,
    customers,
    customersById,
    locations,
    locationsById,
    printers,
    printerMakes,
    printersById,
    users,
    usersById,
    usersByLocation,
    getMyPrinters: () => (tokenContext?.locationId ? printersByLocation[tokenContext.locationId] : []),
    getPrinterById: (id: string) => printersById[id],
    getPrintersByLocation: (locationId: string) => printersByLocation[locationId],
    getPrinterMakes: (make: string) => printerMakes[printerMakes.indexOf(make)] || '',
    getCustomerNameById: (id?: string) => (id ? customersById[id]?.name : ''),
    getCustomerById: (id: string) => customersById[id],
    getLocationNameById: (id: string) => locationsById[id]?.name || '',
    getLocationById: (id: string) => locationsById[id],
    getLocations: () => [...locations],
    getUserById: (id: string) => usersById[id],
    getUsers: () => [...users],
    getUsersByLocationId: (locationId: string) => usersByLocation[locationId] || [],
  }

  return <TPCacheContext.Provider value={cache}>{children}</TPCacheContext.Provider>
}

export { useTPCacheContext }

export default TPCacheProvider
