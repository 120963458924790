import { useCallback, useMemo } from 'react'
import { useHistory, useLocation } from '@truepill/tpos-react-router'

/**
 * A hook that can be used in a similar way to `useState`, but that will save
 * the value in the URL's query params. This hook is meant only for booleans and
 * automatically handles the details of them.
 */
export default function useQueryParamBoolean(
  queryParam: string,
  defaultValue = false,
): [boolean, (value: boolean | null) => void] {
  const history = useHistory()
  const { pathname, search } = useLocation()
  const searchObj = useMemo(() => new URLSearchParams(search), [search])

  const setQueryParam = useCallback(
    (value: boolean | null = defaultValue) => {
      const updatedSearch = new URLSearchParams(searchObj.toString())
      if (value === null) {
        updatedSearch.delete(queryParam)
      } else {
        updatedSearch.set(queryParam, value === true ? 'true' : 'false')
      }

      if (updatedSearch.toString() === searchObj.toString()) {
        return
      }

      history.push(updatedSearch.toString().length > 0 ? `${pathname}?${updatedSearch.toString()}` : pathname)
    },
    [defaultValue, history, pathname, queryParam, searchObj],
  )

  return [(searchObj.get(queryParam) ?? defaultValue) === 'true', setQueryParam]
}
