import type { DUR } from '@truepill/tpos-types'
import { DURStatus } from '@truepill/tpos-types'

export default function mostSevereDURStatus(durs: DUR[]): DURStatus {
  const mostSevereIndex = durs.reduce((topWeightedStatusIndex, dur) => {
    const weight = Object.values(DURStatus).indexOf(dur.status)
    if (weight > topWeightedStatusIndex) {
      return weight
    }
    return topWeightedStatusIndex
  }, 0)

  return Object.values(DURStatus)[mostSevereIndex]
}
