import { DURType } from '@truepill/tpos-types'

const TitleForDURType: { [key: string]: string } = {
  [DURType.DUPLICATE_THERAPY]: 'Duplicate therapy',
  [DURType.DDI]: 'Drug-drug interaction',
  [DURType.INFERRED_CONDITION]: 'Inferred condition',
  [DURType.REPORTED_CONDITION]: 'Reported condition',
  [DURType.ALLERGY]: 'Allergy',
  [DURType.AGE_CONTRAINDICATION]: 'Age contraindication',
  [DURType.GENDER_CONTRAINDICATION]: 'Gender contraindication',
  [DURType.DOSING_INDICATIONS]: 'Dosing indications',
  [DURType.INGREDIENT_DOSING_INDICATIONS]: 'Ingredient Dosing indications',
  [DURType.PREGNANCY_CONTRAINDICATION]: 'Pregnancy contraindication',
  [DURType.LACTATION_CONTRAINDICATION]: 'Lactation contraindication',
}

const mapTitleToDURTypes = (durType: DURType) => TitleForDURType[durType]

export default mapTitleToDURTypes
