import { useEffect, useMemo, useState } from 'react'
import { useQuery, Link, useLocation } from '@truepill/tpos-react-router'
import { RxStatus } from '@truepill/tpos-types'
import FormattedFillDate from 'components/FormattedFillDate'
import LoadingSpinner from 'components/Loading'
import { SurescriptsNumericInput } from 'components/NumericInput'
import { FilledHeadingStyle, NoResultsEntry } from 'components/PageStructure'
import Paging from 'components/Paging'
import Lozenge from 'components/Tiles/Lozenge'
import { LIST_PRESCRIPTIONS } from 'gql'
import { CheckBox, RadioButton } from 'grommet'
import useSetFormDataValue from 'hooks/useSetFormDataValue'
import moment from 'moment'
import { SELECTED_PRESCRIPTION_NAMESPACE } from 'NameSpaces'
import { useFormDataContext } from 'providers/FormDataProvider'
import type { Selectable } from 'providers/SelectionProvider'
import { useSelectionContext } from 'providers/SelectionProvider'
import { usePlusClient } from 'providers/VisionRouter'
import { isNil } from 'ramda'
import { goToViewPharmacyPrescription } from 'routes'
import styled, { css } from 'styled-components'
import EllipsisTruncate from 'styles/EllipsisTruncate'
import {
  subduedColor,
  bodyPrimaryColor,
  contrastColor,
  primaryBackgroundColor,
  contrastBackgroundColor,
  primaryColorLight,
} from 'styles/styleVariables'
import type { Prescription, SimpleKV, SurescriptsNumber } from 'types'
import { removeCamelCase, getCurrentFill } from 'utils'
import { getDisplayedDrugName } from 'utils/getDisplayedDrugName'
import { twoDecimalOrLess } from 'utils/numberFormatter'

interface GetPrescriptionVariables {
  customerIds?: string[]
  ndcs?: string[]
  statuses?: string[]
  medicationNames?: string[]
  rxNumber?: number
  rxFillCode?: string
  corePrescriptionToken?: string
  pageNumber?: number
  pageSize?: number
}

type WrappedPrescriptionsTableProps = { className?: string; searchTerm?: string }

const WrappedPrescriptionsTable = ({ className, searchTerm = '' }: WrappedPrescriptionsTableProps): JSX.Element => {
  const {
    currentLocation: { queryMap },
  } = usePlusClient()
  const variables: GetPrescriptionVariables = {}

  const rxFillCodeMatch = searchTerm && searchTerm.match(/(\d+)-(\d+)-(\d\d)(-[CP])?(-R\d)?/)
  const rxNumberMatch = searchTerm && searchTerm.match(/^[0-9]*$/)

  if (rxFillCodeMatch) {
    variables.rxFillCode = searchTerm
  } else if (rxNumberMatch && rxNumberMatch.length) {
    variables.rxNumber = Number.parseInt(searchTerm)
  }
  // Do we know a format that we can check against for corePrescriptionToken?
  else if (searchTerm) {
    variables.corePrescriptionToken = searchTerm
  }

  if (queryMap.customerIds?.length) {
    variables.customerIds = queryMap.customerIds
  }

  if (queryMap.statuses?.length) {
    const statuses = queryMap.statuses.map((status: unknown) => RxStatus[status as keyof typeof RxStatus])

    variables.statuses = statuses
  }

  if (queryMap.medications?.length) {
    variables.medicationNames = queryMap.medications
  }

  const pageSize = queryMap.pageSize ? parseInt(queryMap.pageSize, 10) : 10
  variables.pageNumber = Number(queryMap?.page) || 0
  variables.pageSize = pageSize

  const { data, loading, error } = useQuery(LIST_PRESCRIPTIONS, {
    variables,
  })

  const prescriptions = data?.getPrescriptions.prescriptions || []
  const totalPrescriptions = data?.getPrescriptions.totalRecords ?? 0
  const currentPage = data?.getPrescriptions.currentPage ?? 0
  const errorMessage = error?.message

  return (
    <PrescriptionsTable
      error={errorMessage}
      loading={loading}
      prescriptions={prescriptions}
      totalPrescriptions={totalPrescriptions}
      currentPage={currentPage}
      pageSize={pageSize}
      className={className}
    />
  )
}

type PrescriptionsTableProps = {
  error?: string
  loading?: boolean
  prescriptions: Prescription[]
  className?: string
  withSelections?: boolean
  disableLinks?: boolean
  withQuantity?: boolean
  withRadioSelection?: boolean
  selectedPrescription?: string
  totalPrescriptions: number
  currentPage?: number
  pageSize?: number
}

export const PrescriptionsTable = ({
  error,
  loading,
  prescriptions,
  className,
  withSelections,
  disableLinks,
  withQuantity,
  withRadioSelection,
  selectedPrescription,
  totalPrescriptions,
  currentPage = 0,
  pageSize = 10,
}: PrescriptionsTableProps): JSX.Element => {
  const { routeToMergedQuery } = usePlusClient()

  if (loading) {
    return (
      <StyledPrescriptionContainer className={className}>
        <TitleRow prescriptions={[]} />
        <LoadingSpinnerContainer>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      </StyledPrescriptionContainer>
    )
  }

  if (error) {
    return <p>Failed to load Prescriptions {error} </p>
  }

  const totalPages = Math.ceil(totalPrescriptions / pageSize)
  const showPagination = !loading && totalPrescriptions > pageSize

  return (
    <>
      <PrescriptionContainer className={className}>
        <TitleRow
          withSelections={withSelections}
          withRadioSelection={withRadioSelection}
          prescriptions={prescriptions}
          withQuantity={withQuantity}
        />
        {prescriptions.map(prescription => (
          <PrescriptionEntry
            disableLinks={disableLinks}
            key={prescription._id}
            prescription={prescription}
            withSelections={withSelections}
            withQuantity={withQuantity}
            withRadioSelection={withRadioSelection}
            selectedPrescription={selectedPrescription}
          />
        ))}
        {prescriptions.length === 0 && <NoResultsEntry> No results </NoResultsEntry>}
      </PrescriptionContainer>
      {showPagination && (
        <Paging
          totalPages={totalPages}
          currentPage={currentPage}
          setPage={pageNumber => {
            routeToMergedQuery({ page: pageNumber })
          }}
          totalRecords={totalPrescriptions}
          pageSize={pageSize}
          margin="1.875rem"
        />
      )}
    </>
  )
}
const TitleRow = (props: {
  withSelections?: boolean
  withRadioSelection?: boolean
  prescriptions: Prescription[]
  withQuantity?: boolean
}): JSX.Element => {
  const { withSelections, prescriptions, withQuantity, withRadioSelection } = props

  return (
    <TableTitleRow>
      {withSelections && <SelectAllCheckBox prescriptions={prescriptions} />}
      {withRadioSelection && <CheckBoxContainer hidden />}
      <PrescriptionRow>
        <RXNumber>Rx Number</RXNumber>
        <Customer>Customer</Customer>
        <Fill>Fill #</Fill>
        <Medication>Medication</Medication>
        <Quantity>Qty</Quantity>
        <DaysSupply>DS</DaysSupply>
        <Directions>Instructions</Directions>
        <RemainingQuantity>Qty Left</RemainingQuantity>
        <RemainingRefills>RF Left</RemainingRefills>
        <Written>Written</Written>
        <FillDate>Fill Date</FillDate>
        <Patient>Patient</Patient>
        <Prescriber>Prescriber</Prescriber>
        <Status>Status</Status>
        {withQuantity && <RequestQuantity>Dsp qty</RequestQuantity>}
      </PrescriptionRow>
    </TableTitleRow>
  )
}

const SelectionCheckBox = (props: { prescription: Prescription }) => {
  const { prescription } = props
  const { selections, select, deSelect } = useSelectionContext()
  const checked = selections.some(sel => sel._id === prescription._id)
  const { formData = {} } = useFormDataContext()
  const { prescriptions } = formData[SELECTED_PRESCRIPTION_NAMESPACE] as { prescriptions: SimpleKV[] }
  const setSelectedPrescriptionValues = useSetFormDataValue(SELECTED_PRESCRIPTION_NAMESPACE)

  return (
    <CheckBoxContainer>
      <CheckBox
        checked={checked}
        onChange={() => {
          if (checked) {
            deSelect(prescription)
          } else {
            if (!prescriptions.some(rx => Object.keys(rx)[0] === prescription._id)) {
              setSelectedPrescriptionValues('prescriptions', [
                ...prescriptions,
                { [prescription._id]: prescription.quantity },
              ])
            }
            select(prescription)
          }
        }}
      />
    </CheckBoxContainer>
  )
}

const SelectionRadioButton = (props: { prescription: Prescription; preSelect?: boolean }): JSX.Element => {
  const { prescription, preSelect } = props
  const [actPreSelect, setActPreSelect] = useState(preSelect)
  const { selections, select, removeBulkSelections } = useSelectionContext()
  const checked = selections.some(sel => sel._id === prescription._id)
  const { formData = {} } = useFormDataContext()
  const { prescriptions } = formData[SELECTED_PRESCRIPTION_NAMESPACE] as { prescriptions: SimpleKV[] }
  const setSelectedPrescriptionValues = useSetFormDataValue(SELECTED_PRESCRIPTION_NAMESPACE)

  const selectPrescription = () => {
    if (!prescriptions.some(rx => Object.keys(rx)[0] === prescription._id)) {
      setSelectedPrescriptionValues('prescriptions', [...prescriptions, { [prescription._id]: prescription.quantity }])
    }
    select(prescription)
  }

  useEffect(() => {
    if (actPreSelect) {
      selectPrescription()
      setActPreSelect(false)
    }
  }, [])

  return (
    <CheckBoxContainer>
      <RadioButton
        name="rxSelection"
        checked={checked}
        onChange={() => {
          if (!checked) {
            removeBulkSelections(selections)
            selectPrescription()
          }
        }}
      />
    </CheckBoxContainer>
  )
}

const SelectAllCheckBox = (props: { prescriptions: Prescription[] }): JSX.Element => {
  const { prescriptions } = props
  const { selections, bulkSetSelections, removeBulkSelections } = useSelectionContext()

  const [allChecked, someChecked] = useMemo(() => {
    let someChecked = false
    let allChecked = true

    for (let i = 0; i < prescriptions.length; i++) {
      const prescription = prescriptions[i]
      if (!selections.some(sel => sel._id === prescription._id)) {
        allChecked = false
      } else {
        someChecked = true
      }
    }
    return [allChecked, someChecked]
  }, [selections, prescriptions])

  return (
    <CheckBoxContainer>
      <CheckBox
        checked={allChecked}
        indeterminate={!allChecked && someChecked}
        onChange={e => {
          if (allChecked) {
            removeBulkSelections(prescriptions)
          } else {
            const newSelections: Selectable[] = prescriptions.reduce((acc: Selectable[], prescription) => {
              acc.push(prescription)

              return acc
            }, [])
            bulkSetSelections(newSelections)
          }
        }}
      />
    </CheckBoxContainer>
  )
}

type PrescriptionEntryProps = {
  prescription: Prescription
  withSelections?: boolean
  hideStatus?: boolean
  disableLinks?: boolean
  withQuantity?: boolean
  withRadioSelection?: boolean
  selectedPrescription?: string
}

const PrescriptionEntry = ({
  prescription,
  withSelections,
  hideStatus,
  disableLinks,
  withQuantity,
  withRadioSelection,
  selectedPrescription,
}: PrescriptionEntryProps): JSX.Element => {
  const { search } = useLocation()
  const currentFill = getCurrentFill(prescription)
  const status = removeCamelCase(prescription.status)

  const { selections } = useSelectionContext()

  // TODO FIX Form Data Context
  const prescriptions: any[] = []
  const setSelectedPrescriptionValues = useSetFormDataValue(SELECTED_PRESCRIPTION_NAMESPACE)

  const [dispensedQuantity, setDispensedQuantity] = useState<SurescriptsNumber | undefined>(prescription.quantity)

  const NoneProvidedIcon = <>&mdash;</>
  const displayedDrugName = getDisplayedDrugName(
    prescription.name,
    prescription.strength?.value,
    prescription.strength?.unit,
    prescription.strength?.form,
  )

  const Content = (
    <PrescriptionRow>
      <RXNumber>{prescription.rxNumber}</RXNumber>
      <Customer>{prescription.customer?.name ?? NoneProvidedIcon}</Customer>
      <Fill>{currentFill?.fillNumber ?? 0}</Fill>
      <Medication>{displayedDrugName}</Medication>
      <Quantity>{prescription.quantity === '.' ? '.' : twoDecimalOrLess(prescription.quantity)}</Quantity>
      <DaysSupply>{prescription.daysSupply || NoneProvidedIcon}</DaysSupply>
      <Directions>{prescription.directions}</Directions>
      <RemainingQuantity>{twoDecimalOrLess(prescription.quantityRemaining) ?? 0}</RemainingQuantity>
      <RemainingRefills>{prescription.refillsRemaining ?? 0}</RemainingRefills>
      <Written>{moment(prescription.writtenDate).format('MM/DD/YYYY')}</Written>
      <FillDate>
        <FormattedFillDate fill={currentFill} />
      </FillDate>
      <Patient>
        {prescription.patient?.firstName} {prescription.patient?.lastName}
      </Patient>
      <Prescriber>
        {prescription.prescriber.firstName} {prescription.prescriber.lastName}
        {prescription.prescriber.suffix ? `, ${prescription.prescriber.suffix}` : ''}
      </Prescriber>
      {!hideStatus && (
        <Status>
          <StatusLozenge>{status}</StatusLozenge>
        </Status>
      )}
      {withQuantity && (
        <SmallSurescriptsNumberInput
          disabled={!selections.some(sel => sel._id === prescription._id)}
          value={dispensedQuantity}
          onChange={newNumber => {
            if (!isNil(newNumber)) {
              setDispensedQuantity(newNumber)
              setSelectedPrescriptionValues(
                'prescriptions',
                prescriptions.map(rx =>
                  Object.keys(rx)[0] === prescription._id ? { [Object.keys(rx)[0]]: dispensedQuantity } : rx,
                ),
              )
            }
          }}
        />
      )}
    </PrescriptionRow>
  )

  if (disableLinks) {
    return (
      <StyledPrescriptionEntry>
        {withSelections && <SelectionCheckBox prescription={prescription} />}
        {withRadioSelection && (
          <SelectionRadioButton prescription={prescription} preSelect={prescription._id === selectedPrescription} />
        )}
        <PrescriptionRowContainer>{Content}</PrescriptionRowContainer>
      </StyledPrescriptionEntry>
    )
  }

  return (
    <StyledLink to={goToViewPharmacyPrescription({ prescriptionId: prescription._id, search })}>
      {withSelections && <SelectionCheckBox prescription={prescription} />}
      {withRadioSelection && (
        <SelectionRadioButton prescription={prescription} preSelect={prescription._id === selectedPrescription} />
      )}
      {Content}
    </StyledLink>
  )
}

const StatusLozenge = styled(Lozenge)`
  color: ${bodyPrimaryColor};
  background-color: ${subduedColor};
  margin-left: 0;
`

const PrescriptionRowContainer = styled.div`
  font-weight: normal;
  grid-column: content;
`

const PrescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-width: 40vw;
  border-bottom: 0.25rem solid ${contrastColor};
  border-radius: 0.25rem;
  flex: 1;
`

const StyledPrescriptionContainer = styled(PrescriptionContainer)`
  :last-child {
    border-bottom: none;
  }
`

const EntryStyle = css`
  :nth-of-type(2n + 1) {
    background-color: ${contrastBackgroundColor};
  }
  display: grid;
  grid-column: prescription;
  grid-template-rows: [content] auto;
  grid-template-columns: [checkbox] auto [content] 1fr;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
`

const StyledPrescriptionEntry = styled.ul`
  ${EntryStyle}
`

const StyledLink = styled(Link)`
  grid-column: content;
  :hover {
    background-color: ${primaryColorLight};
    :nth-of-type(2n + 1) {
      background-color: ${primaryColorLight};
    }
  }
  ${EntryStyle}
`

const PrescriptionRow = styled.ul`
  display: grid;
  grid-column: content;
  grid-template-rows: [content] 2.5rem;
  grid-template-columns:
    [rxNumber] minmax(6rem, 8rem) [customer] minmax(7rem, 0.8fr)
    [fillNumber] 3.5rem [medication] minmax(4rem, 1.8fr)
    [qty] minmax(2.5rem, 0.5fr)
    [ds] minmax(3rem, 0.6fr) [directions] minmax(3rem, 2fr)
    [qtyLeft] minmax(5rem, 0.7fr) [refillsLeft] minmax(4rem, 0.8fr) [written] minmax(6.2rem, 1fr)
    [fill] minmax(6rem, 1fr)
    [patient] minmax(4rem, 1.2fr) [prescriber] minmax(4rem, 1.6fr)
    [status] 10rem
    [quantity] 4rem;
`

const TableTitleRow = styled.ul`
  display: grid;
  grid-template-rows: [content] auto;
  grid-template-columns: [checkbox] auto [content] 1fr;
  ${FilledHeadingStyle}
  font-weight: 500;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  border-radius: 0.25rem 0.25rem 0rem 0rem;
  position: sticky;
  top: 0rem;
`

const PrescriptionRowCell = styled.li`
  grid-row: 1;
  ${EllipsisTruncate}
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 0.3125rem;
  margin-right: 0.3125rem;
`

const TrucateTextPosition = css`
  display: block;
  margin-top: 0.5rem;
`

const RXNumber = styled(PrescriptionRowCell)`
  grid-column: rxNumber;
  ${TrucateTextPosition}
`
const Fill = styled(PrescriptionRowCell)`
  grid-column: fillNumber;
  ${EllipsisTruncate}
`

const Medication = styled(PrescriptionRowCell)`
  grid-column: medication;
  ${TrucateTextPosition}
`

const Quantity = styled(PrescriptionRowCell)`
  grid-column: qty;
  ${TrucateTextPosition}
`
const Directions = styled(PrescriptionRowCell)`
  grid-column: directions;
  ${TrucateTextPosition};
`

const DaysSupply = styled(PrescriptionRowCell)`
  grid-column: ds;
  ${TrucateTextPosition}
`

const RemainingQuantity = styled(PrescriptionRowCell)`
  grid-column: qtyLeft;
  ${TrucateTextPosition};
`

const RemainingRefills = styled(PrescriptionRowCell)`
  grid-column: refillsLeft;
  ${TrucateTextPosition};
`

const Written = styled(PrescriptionRowCell)`
  grid-column: written;
  ${TrucateTextPosition};
`

const Prescriber = styled(PrescriptionRowCell)`
  grid-column: prescriber;
  ${TrucateTextPosition};
`

const Patient = styled(PrescriptionRowCell)`
  grid-column: patient;
  ${TrucateTextPosition};
`

const Customer = styled(PrescriptionRowCell)`
  grid-column: customer;
  ${TrucateTextPosition}
`

const FillDate = styled(PrescriptionRowCell)`
  grid-column: fill;
  ${TrucateTextPosition}
`

const Status = styled(PrescriptionRowCell)`
  grid-column: status;
`

const CheckBoxContainer = styled.div<{ hidden?: boolean }>`
  grid-column: checkbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.625rem;
  > label {
    > div {
      background-color: ${primaryBackgroundColor};
    }
  }
  ${({ hidden }) => hidden && 'width: 15px;'}
`

const LoadingSpinnerContainer = styled.div`
  display: flex;
  padding-top: 4rem;
  justify-content: center;
  svg {
    height: 126px;
  }
`

const RequestQuantity = styled(PrescriptionRowCell)`
  grid-column: quantity;
`

const SmallSurescriptsNumberInput = styled(SurescriptsNumericInput)`
  background-color: ${primaryBackgroundColor};
  width: 3.75rem;
  grid-column: quantity;
  text-align: center;
  margin-top: 4px;
`

export default WrappedPrescriptionsTable
