import type { Address } from '@truepill/tpos-types'
import type { FragmentType } from 'gql/generated'
import { getFragmentData, gql } from 'gql/generated'
import type { AddressEntry_AddressFragmentFragment } from 'gql/generated/graphql'
import { isFragment } from 'gql/utils'

const AddressEntry_AddressFragment = gql(`
  fragment AddressEntry_AddressFragment on Address {
    street1
    street2
    state
    city
    zip
  }
`)

type AddressEntryProps = {
  address?: FragmentType<typeof AddressEntry_AddressFragment> | Partial<Address> | null
  inline?: boolean
}

const AddressEntry = ({ address, inline }: AddressEntryProps): JSX.Element => {
  const addressData = isFragment(address) ? getFragmentData(AddressEntry_AddressFragment, address) : address
  return addressData?.street1 ? (
    inline ? (
      <InlineAddressEntry address={addressData} />
    ) : (
      <StandardAddressEntry address={addressData} />
    )
  ) : (
    <>None provided</>
  )
}

export default AddressEntry

type InlineAddressEntryProps = { address: AddressEntry_AddressFragmentFragment | Partial<Address> }

const InlineAddressEntry = ({
  address: { street1, street2, city, state, zip },
}: InlineAddressEntryProps): JSX.Element => (
  <p data-testid="inline-address" data-private>
    {street1}
    {street1 && street2 && <>,</>} {street2}
    {street1 && <>,</>} {city}
    {(street1 || city) && <>,</>} {state} {zip}
  </p>
)

const StandardAddressEntry = ({
  address: { street1, street2, city, state, zip },
}: InlineAddressEntryProps): JSX.Element => (
  <ul data-testid="standard-address" data-private>
    <li>{street1}</li>
    {street2 && <li>{street2}</li>}
    <li>
      {city && <>{city},</>} {state} {zip}
    </li>
  </ul>
)
