/* eslint-disable @typescript-eslint/no-use-before-define */
import type * as benefits from '../entities/escript/benefitsCoordination'
import type * as enums from '../entities/escript/enums'
import type * as sharedBase from './sharedBase'
import type {
  FacilityIDType,
  FollowUpPrescriberID,
  HistorySourceID,
  NonVeterinarianID,
  PatientID,
  PharmacistID,
  PharmacyID,
  SupervisorID,
  PayerIDType,
} from './sharedIdentification'

export interface Allergy {
  // allergyRestricted
  SourceOfInformation: string // patient or patient representative, or observed by the clinician or provider
  EffectiveDate?: sharedBase.DateType //
  ExpirationDate?: sharedBase.DateType //
  AdverseEvent?: sharedBase.SNOMEDAdverseEventCode // SNOMEDAdverseEventCode
  DrugProductCoded: sharedBase.DrugProductCodeWithText // DrugProductCodeWithText
  ReactionCoded?: sharedBase.SNOMEDCode // SNOMEDCode
  SeverityCoded?: sharedBase.SNOMEDCode // SNOMEDCode
}

export interface AllergyRestrictedChoice {
  NoKnownAllergies?: enums.NoKnownAllergies // Y - yes
  Allergies?: Allergy | Allergy[]
}

export interface AlternateContact {
  Name: sharedBase.Name
  FormerName?: sharedBase.Name
  Address: sharedBase.Address
  CommunicationNumbers: CommunicationNumbers
  AlternateContactRelationship: enums.AlternateContactRelationship // See field 247 – Eligibility / Patient Relationship Code values
}

export interface ApprovedWithoutReasonType {
  ReasonCode?: enums.ReasonCode
  ReferenceNumber?: string
  Note?: string
}

export interface DeniedTransferredType {
  ReasonCode?: enums.ReasonCode3
  ReferenceNumber?: string
  DenialReason?: string // A DenialReason or DeniedTransferredType response must be accompanied by the ReasonCode or DenialReason explanation, or both.
  Pharmacy?: Pharmacy
}

export interface RefillResponseDeniedType {
  ReasonCode: enums.ReasonCode1
  ReferenceNumber?: string
  DenialReason?: string // A DenialReason response must be accompanied by the ReasonCode or DenialReason explanation, or both.
  Pharmacy?: MandatoryPharmacy
}

export type CardholderID = string
export type GroupID = string
export type PersonCode = string
export type GroupName = string
export type PBMMemberID = string

export interface BenefitsCoordinationRestricted {
  PayerIdentification?: PayerIDType // type="PayerID"
  PayerName?: string // type="an1..70"
  CardholderID?: CardholderID // type="an1..35"
  CardHolderName?: sharedBase.Name // type="NameType"
  GroupID?: GroupID // type="an1..35"
  PayerResponsibilityCode?: benefits.PayerResponsibilityCode // type="PayerResponsibilityCode", P (primary), S (secondary), T, U, PP
  PatientRelationship?: benefits.PatientRelationshipCode // type="PatientRelationshipCode"
  PersonCode?: PersonCode // type="n1..3" Code assigned to a specific x(3) person within a family.
  GroupName?: GroupName // type="an1..70"
  Address?: sharedBase.Address //
  CommunicationNumbers?: CommunicationNumbers // type="CommunicationNumbersType"
  Consent?: benefits.Consent // Note: If Patient Consent = N, it is expected the RxHistory transactions would not be sent.
  PBMMemberID?: PBMMemberID // type="an1..80"
  ResponsibleParty?: sharedBase.Name // type="NameType"
  ServiceTypeCoded?: benefits.ServiceTypeCode // type="ServiceTypeCode" - C - current medication orders
  PayerType?: benefits.PayerType // type="PayerType"
}
export type BaseBenefitsCoordination = BenefitsCoordinationRestricted

export interface CommunicationNumbers {
  PrimaryTelephone: sharedBase.PhoneType // type="PhoneType"
  Beeper?: sharedBase.PhoneType // type="PhoneType"
  ElectronicMail?: string // type="MailAddressType" base="an1..80"
  Fax?: sharedBase.PhoneType // type="PhoneType"
  HomeTelephone?: sharedBase.PhoneType // type="PhoneType"
  WorkTelephone?: sharedBase.PhoneType // type="PhoneType"
  OtherTelephone?: sharedBase.PhoneType // type="PhoneType"
  DirectAddress?: string // type="an1..254"
}

export interface DiagnosisGeneral {
  SourceOfInformation?: enums.SourceOfInformation // type="SourceOfInformation"
  EffectiveDate?: sharedBase.DateType // type="DateType"
  ExpirationDate?: sharedBase.DateType // type="DateType"
  ProblemType?: sharedBase.SNOMEDCode // type="SNOMEDCode"
  ProblemNameCoded: sharedBase.ProblemNameCode // type="ProblemNameCode"
}

export interface IndefiniteSuspension {
  SuspendDateTime: sharedBase.UtcDateType // UtcDateType" >
  TimeZone?: TimeZone // TimeZone"  />
}

export interface FixedLengthSuspension {
  SuspendDateTime: sharedBase.UtcDateType
  ResumeDateTime: sharedBase.UtcDateType
  TimeZone?: TimeZone
}
export interface ResumeAdministration {
  ResumeDateTime: sharedBase.UtcDateType
  TimeZone?: TimeZone
}

export type AnySimpleType = any

export type CancelSuspension = AnySimpleType

// export type IndefiniteSuspension = 'IS'
// export type FixedLengthSuspension = 'FS'
// export type ResumeAdministration = 'RA'
// export type CancelSuspension = 'CS'

export interface DrugAdministrationRequest {
  IndefiniteSuspension?: IndefiniteSuspension // " type = "IndefiniteSuspension"  value IS - choice
  FixedLengthSuspension?: FixedLengthSuspension // " type = "FixedLengthSuspension" value FS - choice
  ResumeAdministration?: ResumeAdministration // " type = "ResumeAdministration" value RA - choice
  CancelSuspension?: CancelSuspension // " type = "xsd:anySimpleType" value CS - choice
  DrugAdminReasonCode?: enums.DrugAdminReasonCode // " type = "DrugAdminReasonCode"
  DrugAdminReasonText?: enums.DrugAdminReasonText // " type = "an1..100"
}

export interface Facility {
  Identification: FacilityIDType // type="FacilityID"
  FacilityName?: enums.FacilityName // type="an1..70"
  Address?: sharedBase.Address // type="AddressType"
  CommunicationNumbers?: CommunicationNumbers // type="CommunicationNumbersType"
}

export interface FillStatus {
  // type CHOICE
  Dispensed?: Dispensed // type="NoteTypeWithReasonCode18"> Value AH
  PartiallyDispensed?: PartiallyDispensed // type="NoteTypeWithReasonCode21"> Value AJ
  NotDispensed?: NotDispensed // type="NoteTypeWithReasonCode"> Value AK
  Transferred?: NoteType // type="NoteType"
}

export interface NoteType {
  ReferenceNumber?: string
  Note?: string
}

export interface NoteTypeWithReasonCode {
  ReferenceNumber?: string
  Note?: string
  ReasonCode?: enums.ReasonCode7
}

export interface NoteTypeWithReasonCode18 {
  ReferenceNumber?: string
  Note?: string
  ReasonCode?: enums.ReasonCode18
}

export interface NoteTypeWithReasonCode21 {
  // Fill Status Note Structure. Used for PartiallyDispensed
  ReferenceNumber?: string
  ReasonCode?: enums.ReasonCode21
  Note?: string
}

export type ReferenceNumber = string //  type = "an1..35"

export type Dispensed = 'AH'
export type PartiallyDispensed = 'AJ'
export type NotDispensed = 'AK'

export interface Observation {
  Measurement?: Measurement // type = "MeasurementType"
  ObservationNotes?: ObservationNotes // type = "an1..140"
}

export interface Measurement {
  VitalSign: VitalSign // type = "an" />
  LOINCVersion: LOINCVersion // type = "an" />
  Value: enums.NumberValue // type = "n1..18" />
  UnitOfMeasure: UnitOfMeasure // type = "an" />
  UCUMVersion: UCUMVersion // type = "an" />
  ObservationDate: sharedBase.DateType // type = "DateType" />
}

export type VitalSign = string // type = "an"
export type LOINCVersion = string // type = "an"
export type UnitOfMeasure = string // type = "an"
export type UCUMVersion = string // type = "an"

export type ObservationNotes = string // type = "an1..140"

export interface Patient {
  HumanPatient: PatientMandatoryAddressForNewRx
  NonHumanPatient: NonHumanPatient
}
export interface PatientTypeWithSubstanceUse {
  HumanPatient: PatientWithSubstanceUse
  NonHumanPatient: NonHumanPatient
}

export interface Pharmacist {
  Identification?: PharmacistID // " type="PharmacistID"
  Name: sharedBase.Name // "
  FormerName?: sharedBase.Name // " type="NameType"
  BusinessName?: string // " type="an1..70"
  Address?: sharedBase.Address // " type="AddressType"
  CommunicationNumbers?: CommunicationNumbers // " type="CommunicationNumbersType"
}

export interface MandatoryPharmacy {
  Identification: PharmacyID // type="PharmacyID">
  Specialty?: enums.Specialty // type="an1..10" pdf version - https://nex12.org/images/PDF/Taxonomy_20_1.pdf - they had ransomware attack and their site is in repair
  Pharmacist?: Pharmacist // type="Pharmacist"
  BusinessName: enums.BusinessName // type="an1..70">
  Address?: sharedBase.Address // type="AddressType"
  CommunicationNumbers: CommunicationNumbers // type="CommunicationNumbersType">
}

export interface MandatoryPrescriberChoice {
  NonVeterinarian?: MandatoryPrescriber // type="MandatoryPrescriber"
  Veterinarian?: any // type="VetPrescriberMirrorMandatoryPrescriber"
}

export type LanguageNameCode = string

export interface PatientMandatoryAddressForNewRx {
  Identification?: PatientID // " type="PatientID"
  Name: sharedBase.Name // " type="NameType"
  FormerName?: sharedBase.Name // " type="NameType"
  Gender: enums.Gender // " type="Gender"
  DateOfBirth: sharedBase.DateType // " type="DateType"
  Address: sharedBase.Address // " type="MandatoryAddressType"
  CommunicationNumbers?: CommunicationNumbers // " type="CommunicationNumbersType"
  PatientLocation?: sharedBase.PatientLocation // " type="PatientLocation"
  LanguageNameCode?: LanguageNameCode // " type="an1..3"
  ResidenceCode?: enums.ResidenceCode // " type="ResidenceCode" number 0 - 15
  PregnancyIndicator?: enums.BooleanCode // " type="BooleanCode"
  SubstanceUse?: SubstanceUse // " type="SubstanceUseType"
  AlternateContact?: AlternateContact // " type="AlternateContactStructure"
  GestationalAge?: enums.GestationalAge // " type="n1..2" Gestational age at birth (number of weeks)
  HospiceIndicator?: enums.HospiceIndicator // " type="HospiceIndicator"
}

export interface PatientWithSubstanceUse {
  Identification?: PatientID
  Name: sharedBase.ProblemNameCode
  FormerName?: sharedBase.ProblemNameCode
  Gender: enums.Gender
  DateOfBirth: sharedBase.DateType
  Address?: sharedBase.Address
  CommunicationNumbers?: CommunicationNumbers
  PatientLocation?: sharedBase.PatientLocation
  LanguageNameCode?: string
  SubstanceUse?: SubstanceUse
  AlternateContact?: AlternateContact
  GestationalAge?: string
  HospiceIndicator?: enums.HospiceIndicator
}

export interface NonHumanPatient {
  any
}

export interface PracticeLocation {
  Identification?: FacilityIDType // type="FacilityID"
  BusinessName?: enums.BusinessName // type="an1..70"
}

export interface PrescriberAgent {
  Name: sharedBase.Name // type = "NameType"
  FormerName?: sharedBase.Name // type = "NameType"
}

export interface FollowUpPrescriberType {
  NonVeterinarian?: FollowUpPrescriber // type = "FollowUpPrescriber"  />
  Veterinarian?: any // " type = "VetSupervisorMirrorSupervisorOptional"  />
}

export interface FollowUpPrescriber {
  Identification?: FollowUpPrescriberID // type = "FollowUpPrescriberID"  >
  Specialty?: enums.Specialty // type = "an1..10"  >
  PracticeLocation?: PracticeLocation // type = "PracticeLocationType"  >
  Name: sharedBase.Name // type = "NameType" >
  FormerName?: sharedBase.Name // type = "NameType"  >
  Address?: sharedBase.Address // type = "AddressType"  >
  CommunicationNumbers: CommunicationNumbers // type = "CommunicationNumbersType" >
}

export type DrugDescription = string // type = "an1..105"

export interface DrugCoded {
  ProductCode?: sharedBase.ProductCode // type = "ProductCode"
  Strength?: Strength // type = "Strength"
  DrugDBCode?: sharedBase.DrugDBCode // type = "DrugDBCode"
  DEASchedule?: sharedBase.NCICode // type = "NCICode" DEASchedule
}

export interface Strength {
  StrengthValue?: enums.StrengthValue // type = "an1..70"
  StrengthForm?: sharedBase.NCICode // type = "NCICode" StrengthForm
  StrengthUnitOfMeasure?: sharedBase.NCICode // type = "NCICode" StrengthUnitOfMeasure
}

export interface Diagnosis {
  ClinicalInformationQualifier: enums.ClinicalInformationQualifier // type = "ClinicalInformationQualifier" />
  Primary: DiagnosisQualifiedCodeWithDescription // type = "DiagnosisQualifiedCodeWithDescription" >
  Secondary?: DiagnosisQualifiedCodeWithDescription // type = "DiagnosisQualifiedCodeWithDescription"  >
}

export interface DiagnosisRestricted {
  ClinicalInformationQualifier: enums.ClinicalInformationQualifier
  Primary: DiagnosisQualifiedCodeWithDescriptionRestricted
  Secondary?: DiagnosisQualifiedCodeWithDescriptionRestricted
}

export interface DiagnosisQualifiedCodeWithDescription {
  Code: enums.Code // type = "Code"/>
  Qualifier: enums.PrimaryDiagnosisCodeQualifierCode // type = "PrimaryDiagnosisCodeQualifierCode" />
  Description: enums.Description // type = "an" />
  DateOfLastOfficeVisit?: sharedBase.DateType // type = "DateType" />
}

export type DiagnosisQualifiedCodeWithDescriptionRestricted = DiagnosisQualifiedCodeWithDescription

export interface DrugUseEvaluation {
  ServiceReasonCode: enums.ServiceReasonCode // type = "ServiceReasonCode"/>
  ProfessionalServiceCode?: enums.ProfessionalServiceCode // type = "ProfessionalServiceCode" />
  ServiceResultCode?: enums.ServiceResultCode // type = "ServiceResultCode" />
  CoAgent?: CoAgentCode // type = "CoAgentCode" />
  ClinicalSignificanceCode?: enums.ClinicalSignificanceCode // type = "ClinicalSignificanceCode" />
  AcknowledgementReason?: enums.AcknowledgementReason // type = "an1..100" > // Mandatory if DUE Professional Service Code = “ZZ” (Other) to provide further explanation
}

export interface CoAgentCode {
  Code: enums.Code // " type = "Code"
  Qualifier: enums.CoAgentQualifier // " type = "CoAgentQualifier"
  Description: enums.Description // " type = "an"
}

export interface NonVeterinarian {
  Identification: NonVeterinarianID // " type="NonVeterinarianID">
  Specialty?: enums.Specialty // " type="an1..10"
  PracticeLocation?: PracticeLocation // " type="PracticeLocationType"
  Name: sharedBase.Name // " type="NameType">
  FormerName?: sharedBase.Name // " type="NameType"
  Address: sharedBase.Address // " type="MandatoryAddressType">
  PrescriberAgent?: PrescriberAgent // " type="NameComposite" minOccurs="0"
  CommunicationNumbers: CommunicationNumbers // " type="CommunicationNumbersType">
  PrescriberPlaceOfService?: enums.PrescriberPlaceOfService // " type="n2" placeOfService codes
}

export interface MandatoryPrescriber {
  Identification: NonVeterinarianID // " type = "NonVeterinarianID" >
  Specialty?: enums.Specialty // " type = "an1..10" >
  PracticeLocation?: PracticeLocation // " type = "PracticeLocationType" >
  Name: sharedBase.Name // " type = "NameType" >
  FormerName?: sharedBase.Name // " type = "NameType" >
  Address: sharedBase.Address // " type = "MandatoryAddressType" >
  PrescriberAgent?: PrescriberAgent // " type = "NameComposite" />
  CommunicationNumbers: CommunicationNumbers // " type = "CommunicationNumbersType" >
  PrescriberPlaceOfService?: enums.PrescriberPlaceOfService // " type = "n2" >
}

export interface SigCode {
  // If code not available from source, must use Code = 10003008, Text of “Unspecified” and Dose Clarifying Free Text. See section “Use of Clarifying Free Text Elements”
  Text: string // type="an"
  Qualifier: string // type="SigCodes" SNOMEDCode?
  Code: string // type="Code"
}

export interface SubstanceUse {
  Substance: [Substances]
}

export interface Substances {
  Type: SigCode
  Level?: SigCode
  RouteOfAdministration?: SigCode
}

export interface Quantity {
  Value: enums.NumberValue // type = "n1..11"
  CodeListQualifier: enums.QuantityCodeListQualifier // type = "QuantityCodeListQualifier"
  QuantityUnitOfMeasure: sharedBase.NCICode // type = "NCICode"
}

export interface Veterinarian {
  any
}

export type SigTextFreeText = string // type = "an1..1000"

export interface Sig {
  SigText: SigTextFreeText | StructuredSig
}

export interface StructuredSig {
  StructuredSig: any // TODO figure out structure sig
}

export interface CodeSystem {
  SNOMEDVersion: enums.SNOMEDVersion // " type = "an"
  FMTVersion: enums.FMTVersion // " type = "an" If using structured Sig but not needing to send any of the elements that use dose form, send a valid FMT version using by the application for other sigs.
}

export interface TimeZone {
  TimeZoneIdentifier: enums.TimeZoneIdentifier // " type = "TimeZoneIdentifier"/>
  TimeZoneDifferenceQuantity?: enums.TimeZoneDifferenceQuantity // "xsd:string" pattern value = "[\+\-]?[0-9][0-9]?[0-9]?[0-9]?" />
}

export interface LotNumberType {
  LotNumber: enums.LotNumber // " type = "an1..140" Number assigned by the manufacturer to a batch of medications for tracking.
  LotExpiration: sharedBase.DateType // " type = "DateType"  The expiration date associated with a specific lot number of a medication.
}

export interface HistorySourceWithoutPayment {
  Source: SourceQualifier // " type = "SourceQualifier"/>
  SourceReference?: enums.SourceReference // " type = "an1..35"
  // Prescription Number associated to medication history record.If SourceQualifier value is “P2” (Pharmacy), if sent, this field must contain the pharmacy’s prescription number. If SourceQualifier value is “PC” (Prescriber), this field is not sent.The Prescriber Order Number is found in Drug section. If SourceQualifier value is “PY” (Payer), if sent, this field must contain the pharmacy’s prescription number from the payer system from claims processing.
  FillNumber?: string // " type = "n2"
  // Defines the dispensing episode as an initial fill or an authorized refill.If SourceQualifier value is “P2” (Pharmacy), if sent, this field must contain the fill number from the pharmacy.If SourceQualifier value is “PC” (Prescriber), this field is not sent.If SourceQualifier value is “PY” (Payer), if sent, this field must contain the pharmacy’s fill number from the payer system from claims processing.Values: 00 = initial fill, 01 = first refill, 02 = second refill, etc.Allowed values 00 thru 99. This element must always be two significant digits(e.g. 01, 02, 08, 14, 99).
  PaymentType?: enums.PaymentType // " type = "PaymentType"
}

export interface SourceQualifier {
  Reference?: HistorySourceID // " type = "HistorySourceID" > The identification for the prescriber, pharmacy, payer, et cetera, which is the medication source. If Qualifier is sent, Value must be sent and vice versa.
  SourceDescription?: enums.SourceDescription // " type = "an1..35"  />
  SourceQualifier: enums.EclSourceQualifier // " type = "eclSourceQualifier" />
}

export interface DiabeticSupply {
  TestingFrequency: enums.TestingFrequency // type = "n1..2"/>
  TestingFrequencyNotes?: enums.TestingFrequencyNotes // type = "an1..210"  >
  SupplyIndicator: enums.BooleanCode // type = "BooleanCode" Y = Prescription is for a Diabetic Supply, N = Prescription is not for a Diabetic Supply
  InsulinDependent: enums.BooleanCode // type = "BooleanCode" Y = Patient is Insulin Treated, N = Patient is not Insulin Treated.
  HasAutomatedInsulinDevice: enums.BooleanCode // type = "BooleanCode" Y = Patient has an automated insulin device, N = Patient does not have an automated insulin device.
}

export interface ServiceStructure {
  AgencyOfService?: AgencyOfServiceType // type = "AgencyOfServiceType"
  ServiceType?: ServiceType // type = "ServiceType"
}

export interface AgencyOfServiceType {
  BusinessName: enums.BusinessName // type = "an1..70"
  Address?: sharedBase.Address // type = "AddressType"
  CommunicationNumbers: CommunicationNumbers // type = "CommunicationNumbersTypeRestricted"
}

export interface ServiceType {
  TypeOfServiceFreeText?: TypeOfServiceFreeText // " type = "an"
  TypeOfService?: sharedBase.QualifiedCodeWithText // " type = "QualifiedCodeWithText"
  TargetedTypeOfServiceFreeText?: TypeOfServiceFreeText // " type = "an" >
  TargetedTypeOfService?: sharedBase.QualifiedCodeWithText // " type = "QualifiedCodeWithText" >
  EffectiveDate?: sharedBase.DateType // type = "DateType"
  ExpirationDate?: sharedBase.DateType // type = "DateType"
  ReasonForMTMServiceFreeText?: TypeOfServiceFreeText // " type = "an"/>
  ReasonForMTMService?: sharedBase.QualifiedCodeWithText // " type = "QualifiedCodeWithText" />
  TypeOfServiceGroupSetting?: TypeOfServiceGroupSetting // " type = "an" default="Y"  />
}

export type TypeOfServiceFreeText = string // type = "an"
export type TypeOfServiceGroupSetting = string // type = "an" default="Y"

export interface IVAdministrationType {
  NumberOfLumens?: NumberOfLumens // " type = "an"
  DiluentAmount?: QuantityWithoutSecondQuantity // " type = "QuantityWithoutSecondQuantity"
  SpecificAdministrationGauge?: SpecificAdministrationGauge // " type = "n"
  SpecificAdministrationBrand?: SpecificAdministrationBrand // " type = "an"
  SpecificAdministrationLength?: SpecificAdministrationLength // " type = "an"
  SpecificAdministrationPump?: enums.BooleanCode // " type = "BooleanCode"
  IVAccessType: sharedBase.SNOMEDCode // " type = "SNOMEDCode" />
  IVAccessDeviceType: IVAccessDeviceType
  IVAccessCatheterTip: IVAccessCatheterTip
  IVInfusion: IVInfusion
}

export type NumberOfLumens = string // " type = "an"
export type SpecificAdministrationGauge = number // " type = "n"
export type SpecificAdministrationBrand = string // " type = "an"
export type SpecificAdministrationLength = string // " type = "an"

export interface QuantityWithoutSecondQuantity {
  Value: enums.NumberValue // type = "n1..11" />
  CodeListQualifier: enums.QuantityCodeListQualifier // type = "QuantityCodeListQualifier" />
  QuantityUnitOfMeasure: sharedBase.NCICode //  type = "NCICode" >
}

export interface IVAccessDeviceType {
  IVAccessDeviceTypeDescription?: IVAccessDeviceTypeDescription // " type="an"
  IVAccessDeviceType?: sharedBase.SNOMEDCode
}

export interface IVAccessCatheterTip {
  IVAccessCatheterTipDescription?: IVAccessCatheterTipDescription
  IVAccessCatheterTipType?: sharedBase.SNOMEDCode
}

export interface IVInfusion {
  IVInfusionDescription?: IVInfusionDescription // type = "an"
  IVInfusionType?: sharedBase.SNOMEDCode
}

export type IVAccessDeviceTypeDescription = string // type="an"
export type IVAccessCatheterTipDescription = string // type="an"
export type IVInfusionDescription = string // type="an"

export interface WoundStructure {
  Location: sharedBase.SNOMEDCode // type = "SNOMEDCode">
  Laterality: SNOMEDLateralityCode // type = "SNOMEDLateralityCode" >
  Length: enums.Length // type = "an1..6" >
  Width: enums.Width // type = "an1..6" >
  Depth?: enums.Depth // type = "an1..6"  >
}

export interface SNOMEDLateralityCode {
  Text: string
  Code: enums.WoundLateralityCode
}

export interface Titration {
  PharmacyToTitrateDose?: enums.BooleanCode // type = "BooleanCode" // An indicator the pharmacy is to titrate the dose, frequency, etc. at the request of the prescriber in response to lab results.
  TitrationDose: TitrationDose // type="TitrationDose
}

export interface TitrationDose {
  Measurement?: Measurement // " type = "TitrationDoseMeasurementType"
  MeasurementTimingAndDuration?: TitrationTimingAndDuration // " type = "TitrationTimingAndDuration"
}

export interface TitrationDoseMeasurementType {
  VitalSign: VitalSign // type = "an" >
  LOINCVersion: LOINCVersion // type = "an" />
  Value: enums.NumberValue // type = "n1..18" >
  UnitOfMeasure: UnitOfMeasure // type = "an"
  UCUMVersion: UCUMVersion // type = "an"
  MinimumMeasurementValue?: enums.MinimumMeasurementValue // type = "n1..18"
  MaximumMeasurementValue?: enums.MaximumMeasurementValue // type = "n1..18"
  MeasurementNotes?: enums.MeasurementNotes // type = "an1..255"
}

export interface TitrationTimingAndDuration {
  // TODO all missing types from this type
  MeasurementAdministrationTiming?: MeasurementTiming // " type = "MeasurementTiming" />
  MeasurementFrequency?: MeasurementFrequencyTiming // " type = "MeasurementFrequencyTiming" />
  MeasurementInterval?: MeasurementIntervalTiming // " type = "MeasurementIntervalTiming" />
  MeasurementDuration: MeasurementDuration // " type = "MeasurementDuration" />
  MeasurementDurationTrigger: MeasurementDurationTriggerTiming // " type = "MeasurementDurationTriggerTiming" />
}

export interface MeasurementDuration {
  MeasurementDurationNumericValue: enums.MeasurementDurationNumericValue // " type = "n" />
  MeasurementDurationText: SigCode // " type = "SigCode" />
}

// TODO double check schema of MeasurementFrequencyTiming
export interface MeasurementFrequencyTiming {
  MeasurementFrequencyNumericValue: MeasurementFrequencyNumericValue | MeasurementVariableFrequency // type = "n"
  MeasurementFrequencyUnits: SigCode // type = "SigCode"
}

export interface MeasurementVariableFrequency {
  MeasurementVariableFrequencyModifier: enums.ANDORTO // type = "ANDORTO" >
  MeasurementFrequencyNumericValue: MeasurementFrequencyNumericValue // type = "n" />
}

// TODO double check schema of MeasurementIntervalTiming
export interface MeasurementIntervalTiming {
  MeasurementIntervalNumericValue: MeasurementIntervalNumericValue | MeasurementVariableInterval // type = "n" />
  MeasurementIntervalUnits: SigCode // type = "SigCode" />
}

export interface MeasurementVariableInterval {
  MeasurementVariableIntervalModifier: enums.ANDORTO // type = "ANDORTO" >
  MeasurementIntervalNumericValue: MeasurementIntervalNumericValue // type = "n" />
}

export type MeasurementFrequencyNumericValue = number // type = "n"
export type MeasurementIntervalNumericValue = number // type = "n"

export interface MeasurementDurationTriggerTiming {
  MeasurementTrigger: SigCode // " type = "SigCode"/>
  MeasurementDurationClarifyingFreeText?: enums.MeasurementDurationClarifyingFreeText // " type = "an1..255"
}

export interface MeasurementTiming {
  MeasurementTimingSequence?: MeasurementTimingSequence
  AdministrationTimingNumericValue?: enums.AdministrationTimingNumericValue // type = "n" />
  MeasurementTimingUnits: SigCode // type = "SigCode" />
  MeasurementTimingModifier?: SigCode // type = "SigCode"  Examples such as before, during, with, without, after, while, at., on, start, end.
  MeasurementTimingEvent: SigCode // type = "SigCode" />
  MeasurementTimingClarifyingFreeText?: enums.MeasurementTimingClarifyingFreeText // type = "an1..255"  >
}

export interface MeasurementTimingSequence {
  MeasurementTimingNumericValue: enums.MeasurementTimingNumericValue // type = "n"/>
  VariableMeasurementTimingModifier: enums.VariableMeasurementTimingModifier // type = "ANDORTO" >
}

export interface DateRange {
  StartDate: sharedBase.DateType // " type = "DateType"
  EndDate: sharedBase.DateType // " type = "DateType"
}

export interface CompoundLotNotUsed {
  FinalCompoundPharmaceuticalDosageForm: enums.Code // type = "Code" NCICode
  CompoundIngredientsLotNotUsed: CompoundIngredientsLotNotUsed // type = "CompoundIngredientLotNotUsed" maxOccurs = "25"
}

export type NCICode = enums.Code

export type CompoundIngredientItemDescription = string

export interface Ingredient {
  CompoundIngredientItemDescription: CompoundIngredientItemDescription
  ItemNumber?: ProductCodeExtended
  Strength?: Strength
  DEASchedule?: sharedBase.NCICode
  LotNumber?: enums.LotNumber
}

export interface CompoundQuantity {
  Value: number
  CodeListQualifier: enums.QuantityCodeListQualifier
  QuantityUnitOfMeasure: NCICode
}

export interface FinalCompoundPharmaceuticalDosageForm {
  CompoundIngredient?: CompoundIngredient // " type = "Ingredient" />
  Quantity: Quantity // " type = "CompoundQuantity" />
  DrugUseEvaluation?: DrugUseEvaluation // " type = "DrugUseEvaluation"  maxOccurs = "5" />
}

export interface Compound {
  FinalCompoundPharmaceuticalDosageForm: FinalCompoundPharmaceuticalDosageForm //  type = "Code"
  CompoundIngredients: CompoundIngredient // " type = "CompoundIngredient" maxOccurs = "25" />
}

export interface CompoundIngredientsLotNotUsed {
  CompoundIngredient?: CompoundIngredient // type = "IngredientLotNotUsed"
  Quantity: CompoundQuantity
  DrugUseEvaluation?: DrugUseEvaluation[] // type = "DrugUseEvaluation"
}

export interface CompoundIngredient {
  CompoundIngredientItemDescription: enums.CompoundIngredientItemDescription // type = "an1..105" The appropriate source data element should contain the description from the commercially available product name(or the name that appeared when it was commercially available).It may generally contain the drug name, strength unit, and form, as appropriate.
  ItemNumber?: ProductCodeExtended // type = "ProductCodeExtended"  />
  Strength?: Strength // type = "Strength"  />
  DEASchedule?: sharedBase.NCICode // type = "NCICode"  > Required if the medication is categorized as a controlled substance by the Drug Enforcement Administration(DEA).The DEA Schedule would be populated by the system generating the message, and would utilize the Federal DEA Schedule classification code list, based on federal classification of the medication or the state reclassification of the medication.NCPDP DEASchedule Terminology – available at http://www.cancer.gov/cancertopics/terminologyresources/page7<
}

export interface ProductCodeExtended {
  Code: enums.Code // type = "Code"
  Qualifier: enums.CompoundIngredientProductCodeQualifier // type = "CompoundIngredientProductCodeQualifier"
}

export interface PatientCodifiedNote {
  Qualifier: enums.PatientCodifiedNoteQualifier // type = "PatientCodifiedNoteQualifier">
  Value?: number // type = "n1..2"  >
}

export interface FacilitySpecificHoursOfAdministrationTiming {
  HoursOfAdministrationValue: number //  type = "n1..5" >
  HoursOfAdministration: SigCode
}

export interface OtherMedicationDate {
  OtherMedicationDate: sharedBase.DateType // type = "DateType"
  OtherMedicationDateQualifier: enums.OtherMedicationDateQualifier // type = "OtherMedicationDateQualifier"
}

export interface NeedNoLaterThan {
  NeededNoLaterThanDate: sharedBase.UtcDateType // type = "UtcDateType" >
  NeededNoLaterThanReason?: NeededNoLaterThanReason // type = "an1..70"
}

export type NeededNoLaterThanReason = string // type = "an1..70"

export type PlaceOfServiceNonSelfAdministeredProduct = number // type = "n2"

export interface Medication {
  DrugDescription: string
  DrugCoded?: DrugCoded
  Quantity?: Quantity
  DaysSupply?: number
  WrittenDate?: sharedBase.DateType
  LastFillDate?: sharedBase.DateType
  DeliveredOnDate?: sharedBase.DateType
  Substitutions?: enums.SubstitutionCode
  NumberOfRefills?: number
  Diagnosis?: DiagnosisRestricted
  PriorAuthorization?: string
  Note?: string
  DrugUseEvaluation?: DrugUseEvaluation[]
  DrugCoverageStatusCode?: enums.DrugCoverageStatusCode
  PriorAuthorizationStatus?: enums.PriorAuthorizationStatus
  Sig?: Sig
  PharmacyRequestedRefills?: number
  DoNotFill?: enums.DoNotFill
  TimeZone?: TimeZone
  RefillsRemaining?: number
  HistoryPrescriberOrderNumber?: string
  OrderCaptureMethod?: enums.OrderCaptureMethod
  Pharmacy?: OptionalPharmacy
  Prescriber?: HistoryPrescriberChoice
  HistorySource?: HistorySourceWithoutPayment
  ReasonForSubstitutionCodeUsed: enums.ReasonForSubstitutionCodeUsed
  SplitScript?: enums.SplitScript
  ManufacturerName?: string
  LotNumber?: enums.LotNumber
  RxFillIndicator?: enums.RxFillIndicator
  DiabeticSupply?: DiabeticSupply
  InjuryRelated?: enums.InjuryRelated
  Service?: ServiceStructure
  IVAdministration?: IVAdministrationType
  TreatmentIndicator?: enums.TreatmentIndicator
  ProphylacticOrEpisodic?: enums.ProphylacticOrEpisodic
  CurrentTreatmentCycle?: number
  NumberOfCyclesPlanned?: number
  CompoundInformation?: Compound
  PatientCodifiedNote?: PatientCodifiedNote
  FacilitySpecificHoursOfAdministrationTiming: FacilitySpecificHoursOfAdministrationTiming
  OtherMedicationDate?: OtherMedicationDate
  NeedNoLaterThan?: NeedNoLaterThan
  PlaceOfServiceNonSelfAdministeredProduct?: number
}

export interface PrescribedMedication {
  DrugDescription: DrugDescription
  DrugCoded?: DrugCoded
  Quantity: Quantity
  DaysSupply?: enums.DaysSupply
  WrittenDate: sharedBase.DateType
  LastFillDate?: sharedBase.DateType
  Substitutions: enums.SubstitutionCode
  NumberOfRefills: enums.NumberOfRefills
  Diagnosis?: Diagnosis
  PriorAuthorization?: enums.PriorAuthorization
  Note?: enums.Note
  DrugUseEvaluation?: DrugUseEvaluation[]
  DrugCoverageStatusCode?: enums.DrugCoverageStatusCode
  PriorAuthorizationStatus?: enums.PriorAuthorizationStatus
  Sig: Sig
  PharmacyRequestedRefills?: enums.PharmacyRequestedRefills
  DoNotFill?: enums.DoNotFill
  TimeZone?: TimeZone
  RefillsRemaining?: enums.RefillsRemaining
  HistoryPrescriberOrderNumber?: enums.HistoryPrescriberOrderNumber
  OrderCaptureMethod?: enums.OrderCaptureMethod
  Pharmacy?: Pharmacy
  Prescriber?: PrescriberGeneralChoice
  HistorySource?: HistorySourceWithoutPayment
  ReasonForSubstitutionCodeUsed?: enums.ReasonForSubstitutionCodeUsed
  SplitScript?: enums.SplitScript
  ManufacturerName?: enums.ManufacturerName
  LotNumber?: LotNumberType
  RxFillIndicator?: enums.RxFillIndicator
  OfficeOfPharmacyAffairsID?: enums.OfficeOfPharmacyAffairsID
  DiabeticSupply?: DiabeticSupply
  InjuryRelated?: enums.InjuryRelated
  Service?: ServiceStructure
  IVAdministration?: IVAdministrationType
  TreatmentIndicator?: enums.TreatmentIndicator
  ProphylacticOrEpisodic?: enums.ProphylacticOrEpisodic
  NumberOfCyclesPlanned?: enums.NumberOfCyclesPlanned
  Wound?: WoundStructure
  Titration?: Titration
  FlavoringRequested?: enums.FlavoringRequested
  CompoundInformation?: CompoundLotNotUsed
  NumberOfPackagesToBeDispensed?: enums.NumberOfPackagesToBeDispensed
  PatientCodifiedNote?: PatientCodifiedNote
  FacilitySpecificHoursOfAdministrationTiming?: FacilitySpecificHoursOfAdministrationTiming
  OtherMedicationDate?: OtherMedicationDate
  NeedNoLaterThan?: NeedNoLaterThan
  PlaceOfServiceNonSelfAdministeredProduct?: PlaceOfServiceNonSelfAdministeredProduct
  ProviderExplicitAuthorizationToAdminister?: enums.BooleanCode
}

export interface PrescribedMedicationForCancelRx {
  DrugDescription: string
  DrugCoded?: DrugCoded
  Quantity: Quantity
  DaysSupply?: enums.DaysSupply
  WrittenDate: sharedBase.DateType
  LastFillDate?: sharedBase.DateType
  Substitutions: enums.SubstitutionCode
  NumberOfRefills: number
  Diagnosis?: DiagnosisRestricted
  PriorAuthorization?: enums.PriorAuthorization
  Note?: enums.Note
  DrugUseEvaluation?: DrugUseEvaluation[]
  DrugCoverageStatusCode?: enums.DrugCoverageStatusCode
  PriorAuthorizationStatus?: enums.PriorAuthorizationStatus
  Sig: Sig
  ReasonForSubstitutionCodeUsed?: enums.ReasonForSubstitutionCodeUsed
  InjuryRelated?: enums.InjuryRelated
  Service?: ServiceStructure
  IVAdministration?: IVAdministrationType
  TreatmentIndicator?: enums.TreatmentIndicator
  ProphylacticOrEpisodic?: enums.ProphylacticOrEpisodic
  CurrentTreatmentCycle?: enums.CurrentTreatmentCycle
  NumberOfCyclesPlanned?: enums.NumberOfCyclesPlanned
  Wound?: WoundStructure
  Titration?: Titration
  CompoundInformation?: CompoundLotNotUsed
  NumberOfPackagesToBeDispensed?: number
  FacilitySpecificHoursOfAdministrationTiming?: FacilitySpecificHoursOfAdministrationTiming
  OtherMedicationDate?: OtherMedicationDate
  PlaceOfServiceNonSelfAdministeredProduct?: number
  ProviderExplicitAuthorizationToAdminister?: enums.BooleanCode
}

export interface RefillResponseReplacedMedication {
  DrugDescription: string
  DrugCoded?: DrugCoded
  Quantity: Quantity
  DaysSupply?: number
  WrittenDate: sharedBase.DateType
  LastFillDate?: sharedBase.DateType
  Substitutions: enums.SubstitutionCode
  NumberOfRefills: number
  Diagnosis?: Diagnosis
  PriorAuthorization?: string
  Note?: string
  DrugUseEvaluation?: DrugUseEvaluation[]
  DrugCoverageStatusCode?: enums.DrugCoverageStatusCode
  PriorAuthorizationStatus?: enums.PriorAuthorizationStatus
  Sig: Sig
  PharmacyRequestedRefills?: number
  DoNotFill?: enums.DoNotFill
  TimeZone?: TimeZone
  RefillsRemaining?: number
  HistoryPrescriberOrderNumber?: string
  OrderCaptureMethod?: enums.OrderCaptureMethod
  Pharmacy?: Pharmacy
  Prescriber?: PrescriberGeneralChoice
  HistorySource?: HistorySourceWithoutPayment
  ReasonForSubstitutionCodeUsed?: enums.ReasonForSubstitutionCodeUsed
  SplitScript?: enums.SplitScript
  ManufacturerName?: string
  LotNumber?: enums.LotNumber
  RxFillIndicator?: enums.RxFillIndicator
  OfficeOfPharmacyAffairsID?: string[]
  DiabeticSupply?: DiabeticSupply
  Service?: ServiceStructure
  IVAdministration?: IVAdministrationType
  TreatmentIndicator?: enums.TreatmentIndicator
  ProphylacticOrEpisodic?: enums.ProphylacticOrEpisodic
  CurrentTreatmentCycle?: number
  NumberOfCyclesPlanned?: number
  Wound?: WoundStructure
  PrescriberCheckedREMS?: enums.PrescriberCheckedREMS
  REMSPatientRiskCategory?: string
  REMSAuthorizationNumber?: string
  Titration?: Titration
  CompoundInformation?: CompoundLotNotUsed
  NumberOfPackagesToBeDispensed?: number
  PatientCodifiedNote?: PatientCodifiedNote
  FacilitySpecificHoursOfAdministrationTiming?: FacilitySpecificHoursOfAdministrationTiming
  OtherMedicationDate?: OtherMedicationDate
  NeedNoLaterThan?: NeedNoLaterThan
  PlaceOfServiceNonSelfAdministeredProduct?: number
  ProviderExplicitAuthorizationToAdminister?: enums.BooleanCode
}

export interface RxChangePrescribedMedication {
  DrugDescription: DrugDescription
  DrugCoded?: DrugCoded
  Quantity: Quantity
  DaysSupply?: enums.DaysSupply
  WrittenDate: sharedBase.DateType
  LastFillDate?: sharedBase.DateType
  Substitutions: enums.SubstitutionCode
  NumberOfRefills: enums.NumberOfRefills
  Diagnosis?: Diagnosis
  PriorAuthorization?: enums.PriorAuthorization
  Note?: enums.Note
  DrugUseEvaluation?: DrugUseEvaluation[]
  DrugCoverageStatusCode?: enums.DrugCoverageStatusCode
  PriorAuthorizationStatus?: enums.PriorAuthorizationStatus
  Sig: Sig
  ReasonForSubstitutionCodeUsed?: enums.ReasonForSubstitutionCodeUsed
  RxFillIndicator?: enums.RxFillIndicator
  OfficeOfPharmacyAffairsID?: enums.OfficeOfPharmacyAffairsID
  InjuryRelated?: enums.InjuryRelated
  Service?: ServiceStructure
  IVAdministration?: IVAdministrationType
  TreatmentIndicator?: enums.TreatmentIndicator
  ProphylacticOrEpisodic?: enums.ProphylacticOrEpisodic
  CurrentTreatmentCycle?: enums.CurrentTreatmentCycle
  NumberOfCyclesPlanned?: enums.NumberOfCyclesPlanned
  Wound?: WoundStructure
  PrescriberCheckedREMS?: enums.PrescriberCheckedREMS
  REMSPatientRiskCategory?: enums.REMSPatientRiskCategory
  REMSAuthorizationNumber?: enums.REMSAuthorizationNumber
  Titration?: Titration
  FlavoringRequested?: enums.FlavoringRequested
  CompoundInformation?: CompoundLotNotUsed
  NumberOfPackagesToBeDispensed?: enums.NumberOfPackagesToBeDispensed
  PatientCodifiedNote?: PatientCodifiedNote
  FacilitySpecificHoursOfAdministrationTiming: FacilitySpecificHoursOfAdministrationTiming
  OtherMedicationDate?: OtherMedicationDate
  PlaceOfServiceNonSelfAdministeredProduct?: PlaceOfServiceNonSelfAdministeredProduct
  ProviderExplicitAuthorizationToAdminister?: enums.BooleanCode
}

export interface NewRxRequestMedication {
  DrugDescription: DrugDescription
  DrugCoded?: DrugCoded
  Quantity?: Quantity
  LastFillDate?: sharedBase.DateType
  Substitutions?: enums.SubstitutionCode
  Diagnosis?: DiagnosisRestricted
  Note?: enums.Note
  Sig?: Sig
  Service?: ServiceStructure
  IVAdministration?: IVAdministrationType
  TreatmentIndicator?: enums.TreatmentIndicator
  ProphylacticOrEpisodic?: enums.ProphylacticOrEpisodic
  CurrentTreatmentCycle?: enums.CurrentTreatmentCycle
  NumberOfCyclesPlanned?: enums.NumberOfCyclesPlanned
  NumberOfPackagesToBeDispensed?: enums.NumberOfPackagesToBeDispensed
  OtherMedicationDate?: OtherMedicationDate
}

export interface TransferMedication {
  DrugDescription: DrugDescription
  DrugCoded?: DrugCoded
  LastFillDate?: sharedBase.DateType
  Diagnosis?: DiagnosisRestricted | DiagnosisRestricted[]
  Note?: string
  HistorySource?: HistorySourceWithoutPayment
  FlavoringRequested?: enums.FlavoringRequested
  PatientCodifiedNote?: PatientCodifiedNote | PatientCodifiedNote[]
  FacilitySpecificHoursOfAdministrationTiming?:
    | FacilitySpecificHoursOfAdministrationTiming
    | FacilitySpecificHoursOfAdministrationTiming[]
  OtherMedicationDate?: OtherMedicationDate | OtherMedicationDate[]
  ProviderExplicitAuthorizationToAdminister?: enums.BooleanCode
}

export interface TransferDispensedMedication extends TransferMedication {
  Quantity: Quantity
  DaysSupply?: string
  WrittenDate?: sharedBase.DateType
  LastFillDate: sharedBase.DateType
  Substitutions: enums.SubstitutionCode
  PriorAuthorization?: string
  Sig?: Sig
}

export interface TransferResponseMedication extends TransferMedication {
  Quantity: Quantity | Quantity[]
  DaysSupply?: string
  WrittenDate: sharedBase.DateType
  Substitutions: enums.SubstitutionCode
  NumberOfRefills: string
  PriorAuthorization?: string
  DrugCoverageStatusCode?: enums.DrugCoverageStatusCode
  PriorAuthorizationStatus?: enums.PriorAuthorizationStatus
  Sig: Sig
  DoNotFill?: enums.DoNotFill
  RefillsRemaining: string
  HistoryPrescriberOrderNumber?: string
  Prescriber: MandatoryPrescriberChoice
  ReasonForSubstitutionCodeUsed?: enums.ReasonForSubstitutionCodeUsed
  RxFillIndicator?: enums.RxFillIndicator
  RxReferenceNumber?: string
  DiabeticSupply?: DiabeticSupply
  InjuryRelated?: enums.InjuryRelated
  PrescriberCheckedREMS?: enums.PrescriberCheckedREMS
  REMSPatientRiskCategory?: string
  REMSAuthorizationNumber?: string
  NumberOfPackagesToBeDispensed?: string
  PlaceOfServiceNonSelfAdministeredProduct?: string
}

export interface SupervisorMandatoryAddressType {
  NonVeterinarian?: SupervisorMandatoryAddress // type = "SupervisorMandatoryAddress"/>
  Veterinarian?: any // type = "VetSupervisorMirrorSupervisorMandatoryAddress" />
}

export interface SupervisorOptional {
  Identification?: SupervisorID
  Specialty?: enums.Specialty
  // Specialty of prescriber.Health Care Provider Taxonomy Code http://www.wpc-edi.com/reference/</xsd:documentation>
  PracticeLocation?: PracticeLocation
  // The identity of the organization the practitioner represents or acts on behalf of.< /xsd:documentation>
  Name: sharedBase.Name
  FormerName?: sharedBase.Name
  // Used to provide additional matching criteria when the current Name does not match < /xsd:documentation>
  Address?: sharedBase.Address
  CommunicationNumbers: CommunicationNumbers
}

export interface SupervisorOptionalType {
  NonVeterinarian?: SupervisorOptional // type = "SupervisorOptional"/>
  Veterinarian?: any // type = "VetSupervisorMirrorSupervisorOptional" />
}

export interface SupervisorMandatoryAddress {
  Identification: SupervisorID // " type = "SupervisorID">
  Specialty?: enums.Specialty // " type = "an1..10"  >
  PracticeLocation?: PracticeLocation // " type = "PracticeLocationType"  >
  Name: sharedBase.Name // " type = "NameType" >
  FormerName?: sharedBase.Name // " type = "NameType"  >
  Address: sharedBase.Address // " type = "MandatoryAddressType" >
  CommunicationNumbers: CommunicationNumbers // " type = "CommunicationNumbersType" >
}

export interface Pharmacy {
  Identification: PharmacyID // type = "PharmacyID"
  Specialty?: enums.Specialty // type = "an1..10"
  Pharmacist?: Pharmacist // type = "Pharmacist"
  BusinessName: enums.BusinessName // type = "an1..70"
  Address?: sharedBase.Address // type = "AddressType"
  CommunicationNumbers?: CommunicationNumbers // type = "CommunicationNumbersType"
}

interface OptionalPharmacy {
  Identification?: PharmacyID
  Specialty: enums.Specialty
  Pharmacist?: Pharmacist
  BusinessName: enums.BusinessName
  Address?: sharedBase.Address
  CommunicationNumbers?: CommunicationNumbers
}
export interface PrescriberGeneral {
  Identification?: NonVeterinarianID
  Specialty?: enums.Specialty
  PracticeLocation?: PracticeLocation
  Name: sharedBase.Name
  FormerName?: sharedBase.Name
  Address?: sharedBase.Address
  PrescriberAgent?: PrescriberAgent
  CommunicationNumbers: CommunicationNumbers
  PrescriberPlaceOfService?: enums.PrescriberPlaceOfService
}

export interface PrescriberGeneralChoice {
  NonVeterinarian: PrescriberGeneral // type = "PrescriberGeneral"
  Veterinarian: VetPrescriberMirrorPrescriberGeneral // type = "VetPrescriberMirrorPrescriberGeneral"
}

export type VetPrescriberMirrorPrescriberGeneral = PrescriberGeneral

export interface HistoryPrescriberChoice {
  NonVeterinarian?: PrescriberGeneral // type = "PrescriberGeneral"
  Veterinarian?: VetPrescriberMirrorPrescriberGeneral // type = "VetPrescriberMirrorPrescriberGeneral"
}

export interface NewRxPrescribedMedication {
  DrugDescription: DrugDescription
  DrugCoded?: DrugCoded
  Quantity: Quantity
  DaysSupply?: enums.DaysSupply
  WrittenDate: sharedBase.DateType
  LastFillDate?: sharedBase.DateType
  Substitutions: enums.SubstitutionCode
  NumberOfRefills: enums.NumberOfRefills
  Diagnosis?: Diagnosis
  PriorAuthorization?: enums.PriorAuthorization
  Note?: enums.Note
  DrugUseEvaluation?: DrugUseEvaluation[]
  DrugCoverageStatusCode?: enums.DrugCoverageStatusCode
  PriorAuthorizationStatus?: enums.PriorAuthorizationStatus
  Sig: Sig
  PharmacyRequestedRefills?: enums.PharmacyRequestedRefills
  DoNotFill?: enums.DoNotFill
  TimeZone?: TimeZone
  RefillsRemaining?: enums.RefillsRemaining
  HistoryPrescriberOrderNumber?: enums.HistoryPrescriberOrderNumber
  OrderCaptureMethod?: enums.OrderCaptureMethod
  Pharmacy?: Pharmacy
  Prescriber?: PrescriberGeneralChoice
  HistorySource?: HistorySourceWithoutPayment
  ReasonForSubstitutionCodeUsed?: enums.ReasonForSubstitutionCodeUsed
  SplitScript?: enums.SplitScript
  ManufacturerName?: enums.ManufacturerName
  LotNumber?: enums.LotNumber
  RxFillIndicator?: enums.RxFillIndicator
  OfficeOfPharmacyAffairsID?: enums.OfficeOfPharmacyAffairsID
  DeliveryRequest?: enums.DeliveryRequest
  DeliveryLocation?: enums.DeliveryLocation
  DiabeticSupply?: DiabeticSupply
  InjuryRelated?: enums.InjuryRelated
  Service?: ServiceStructure
  IVAdministration?: IVAdministrationType
  TreatmentIndicator?: enums.TreatmentIndicator
  ProphylacticOrEpisodic?: enums.ProphylacticOrEpisodic
  CurrentTreatmentCycle?: enums.CurrentTreatmentCycle
  NumberOfCyclesPlanned?: enums.NumberOfCyclesPlanned
  Wound?: WoundStructure
  PrescriberCheckedREMS?: enums.PrescriberCheckedREMS
  REMSPatientRiskCategory?: enums.REMSPatientRiskCategory
  REMSAuthorizationNumber?: enums.REMSAuthorizationNumber
  Titration?: Titration
  FlavoringRequested?: enums.FlavoringRequested
  CompoundInformation?: CompoundLotNotUsed
  NumberOfPackagesToBeDispensed?: enums.NumberOfPackagesToBeDispensed
  PatientCodifiedNote?: PatientCodifiedNote
  FacilitySpecificHoursOfAdministrationTiming?: FacilitySpecificHoursOfAdministrationTiming
  OtherMedicationDate?: OtherMedicationDate
  NeedNoLaterThan?: NeedNoLaterThan
  PlaceOfServiceNonSelfAdministeredProduct?: PlaceOfServiceNonSelfAdministeredProduct
  ProviderExplicitAuthorizationToAdminister?: enums.BooleanCode
}

export interface RequestedMedication {
  DrugDescription: DrugDescription // " type = "an1..105">
  DrugCoded?: DrugCoded // " type = "DrugCoded"
  Quantity?: Quantity // " type = "Quantity"  />
  DaysSupply?: enums.DaysSupply // " type = "n1..3"
  WrittenDate?: sharedBase.DateType // " type = "DateType"  />
  LastFillDate?: sharedBase.DateType // " type = "DateType"  />
  Substitutions: enums.SubstitutionCode // " type = "SubstitutionCode" >
  NumberOfRefills?: enums.NumberOfRefills // " type = "n1..2"
  Diagnosis?: Diagnosis // " type = "DiagnosisRestricted"
  PriorAuthorization?: enums.PriorAuthorization // " type = "an1..35"
  Note?: enums.Note // " type = "an1..210"
  DrugUseEvaluation?: DrugUseEvaluation[] // " type = "DrugUseEvaluation[]"
  DrugCoverageStatusCode?: enums.DrugCoverageStatusCode // " type = "DrugCoverageStatusCode"
  PriorAuthorizationStatus?: enums.PriorAuthorizationStatus // " type = "PriorAuthorizationStatus"
  Sig?: Sig // " type = "Sig"
  PharmacyRequestedRefills?: enums.PharmacyRequestedRefills // " type = "n1..2"
  DoNotFill?: enums.DoNotFill // " type = "DoNotFill"
  TimeZone?: TimeZone // " type = "TimeZone"
  RefillsRemaining?: enums.RefillsRemaining // " type = "n1..2"
  HistoryPrescriberOrderNumber?: enums.HistoryPrescriberOrderNumber // " type = "an1..35"
  OrderCaptureMethod?: enums.OrderCaptureMethod // " type = "OrderCaptureMethod"
  Pharmacy?: Pharmacy // " type = "Pharmacy"
  Prescriber?: PrescriberGeneralChoice // " type = "PrescriberGeneralChoice"
  HistorySource?: HistorySourceWithoutPayment // " type = "HistorySourceWithoutPayment"
  ReasonForSubstitutionCodeUsed?: enums.ReasonForSubstitutionCodeUsed // " type = "ReasonForSubstitutionCodeUsed" (for all Medicaid programs).< /xsd:documentation>
  SplitScript?: enums.SplitScript // " type = "SplitScript"
  ManufacturerName?: enums.ManufacturerName // " type = "an1..105"
  LotNumber?: enums.LotNumber // " type = "LotNumber"
  RxFillIndicator?: enums.RxFillIndicator // " type = "RxFillIndicator"
  Service?: ServiceStructure // " type = "ServiceStructure"  />
  IVAdministration?: IVAdministrationType // " type = "IVAdministrationType"  />
  TreatmentIndicator?: enums.TreatmentIndicator // " type = "TreatmentIndicator"
  ProphylacticOrEpisodic?: enums.ProphylacticOrEpisodic // " type = "ProphylacticOrEpisodic"
  CurrentTreatmentCycle?: enums.CurrentTreatmentCycle // " type = "n1..2"
  NumberOfCyclesPlanned?: enums.NumberOfCyclesPlanned // " type = "n1..2"
  Titration?: Titration // " type = "TitrationType"  />
  CompoundInformation?: CompoundLotNotUsed // " type = "CompoundLotNotUsed"  />
  NumberOfPackagesToBeDispensed?: enums.NumberOfPackagesToBeDispensed // " type = "n1..2"
  OtherMedicationDate?: OtherMedicationDate // " type = "OtherMedicationDate"
  NeedNoLaterThan?: NeedNoLaterThan // " type = "NeedNoLaterThan"
}

export enum ReasonCode {
  AA = 'Patient unknown to the Provider',
  AB = 'Patient never under  Provider care',
  AC = 'Patient no longer under  Provider care',
  AD = 'Patient has requested refill too soon',
  AG = 'Fill / Refill not appropriate',
  AO = 'No attempt will be made to obtain Prior Authorization',
  BF = 'Conscientious objection',
  BG = 'Not the patient - desired pharmacy',
  BP = 'Out of Stock / Manufacturer Back Order – NDC requested is currently on back order from the manufacturer',
  BQ = 'Discontinued by Manufacturer – NDC requested has been discontinued by the manufacturer',
  BT = 'Drug Recalled by Manufacturer – NDC requested has been recalled by the manufacturer',
  CS = 'Prescription not found',
  DB = 'Drug Use Evaluation',
  DC = 'No refills remaining or medication order has expired',
  DD = 'Pharmacy has no intention to stock the medication requested / prescribed',
  DE = 'Prior Authorization has been denied by payer',
  FS = 'The medication prescribed is out of stock and cannot be obtained in a clinically appropriate timeframe',
}

export enum TransferRequest {
  ALL = 'ALL',
  SPECIFIC = 'SPECIFIC',
}

export enum TransferType {
  TRANSFER_TO_PHARMACY = 'TRANSFER TO PHARMACY',
  TRANSFER_FROM_PHARMACY = 'TRANSFER FROM PHARMACY',
}

export interface PharmacyTransfer {
  Identification: PharmacyID
  Specialty?: string
  BusinessName?: string
  Address: sharedBase.Address
  CommunicationNumbers?: CommunicationNumbers
  TransferType: TransferType
}
