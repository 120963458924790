import { ListRowLabel, ListRowValue, RightFilledHeader, ListRowEyeButton } from 'components/RXTable'
import type { EdiReversalOverrides } from 'types'
import { RxEdiListRow, RxEdiRowContainer, RxEdiRightTitleCell, RxEdiTitleRow, RxTextInput } from '..'
import SuppressFieldButton from '../../SuppressFieldButton'

type ClaimSegmentEditReversalProps = {
  ediReversalOverrides: EdiReversalOverrides
  updateEdiReversalOverrides: (input: EdiReversalOverrides) => void
}

const ClaimSegmentEditReversal = ({
  ediReversalOverrides,
  updateEdiReversalOverrides,
}: ClaimSegmentEditReversalProps): JSX.Element => {
  const { claim } = ediReversalOverrides

  const prescriptionRefNumber = claim?.prescriptionRefNumber
  const productId = claim?.productId
  const fillNumber = claim?.fillNumber
  const otherCoverageCode = claim?.otherCoverageCode
  const pharmacyServiceType = claim?.pharmacyServiceType

  const hidePrescriptionRefNumberField = prescriptionRefNumber?.send === false
  const hideProductIdField = productId?.send === false
  const hideFillNumberField = fillNumber?.send === false
  const hideOtherCoverageCodeField = otherCoverageCode?.send === false
  const hidePharmacyServiceTypeField = pharmacyServiceType?.send === false

  // These toggle functions handle the state of SupressFieldButton when you click on it
  // and the true/false field we are sending to the backend
  const toggleIsPrescriptionRefNumberFieldDisabled = () => {
    updateEdiReversalOverrides({
      claim: {
        prescriptionRefNumber: { value: prescriptionRefNumber?.value ?? '', send: hidePrescriptionRefNumberField },
      },
    })
  }
  const toggleIsProductIdFieldDisabled = () => {
    updateEdiReversalOverrides({
      claim: {
        productId: { value: productId?.value ?? '', send: hideProductIdField },
      },
    })
  }
  const toggleIsFillNumberFieldDisabled = () => {
    updateEdiReversalOverrides({
      claim: {
        //Set up initial value as 0 since it's a type number
        fillNumber: { value: fillNumber?.value ?? 0, send: hideFillNumberField },
      },
    })
  }
  const toggleIsOtherCoverageCodeFieldDisabled = () => {
    updateEdiReversalOverrides({
      claim: {
        //Set up initial value as 0 since it's a type number
        otherCoverageCode: { value: otherCoverageCode?.value ?? 0, send: hideOtherCoverageCodeField },
      },
    })
  }
  const toggleIsPharmacyServiceTypeFieldDisabled = () => {
    updateEdiReversalOverrides({
      claim: {
        pharmacyServiceType: { value: pharmacyServiceType?.value ?? '', send: hidePharmacyServiceTypeField },
      },
    })
  }

  return (
    <>
      <ClaimHeaderRow />
      <RxEdiListRow>
        <RxEdiRowContainer>
          <ListRowLabel>Prescription/Service Reference Number (D2):</ListRowLabel>
          <ListRowValue>
            <RxTextInput
              value={prescriptionRefNumber?.value ?? ''}
              onChange={({ target: { value: prescriptionRefNumber } }) =>
                updateEdiReversalOverrides({
                  claim: {
                    prescriptionRefNumber: { value: prescriptionRefNumber, send: true },
                  },
                })
              }
              placeholder="Prescription/service reference number..."
              disabled={hidePrescriptionRefNumberField}
            />
          </ListRowValue>
          <ListRowEyeButton>
            <SuppressFieldButton
              onClick={toggleIsPrescriptionRefNumberFieldDisabled}
              hideField={hidePrescriptionRefNumberField}
            />
          </ListRowEyeButton>
        </RxEdiRowContainer>
      </RxEdiListRow>

      <RxEdiListRow>
        <RxEdiRowContainer>
          <ListRowLabel>Product/Service ID (D7):</ListRowLabel>
          <ListRowValue>
            <RxTextInput
              value={productId?.value ?? ''}
              onChange={({ target: { value: productId } }) =>
                updateEdiReversalOverrides({ claim: { productId: { value: productId, send: true } } })
              }
              placeholder="Product/service ID..."
              disabled={hideProductIdField}
            />
          </ListRowValue>
          <ListRowEyeButton>
            <SuppressFieldButton onClick={toggleIsProductIdFieldDisabled} hideField={hideProductIdField} />
          </ListRowEyeButton>
        </RxEdiRowContainer>
      </RxEdiListRow>

      <RxEdiListRow>
        <RxEdiRowContainer>
          <ListRowLabel>Fill Number (D3):</ListRowLabel>
          <ListRowValue>
            <RxTextInput
              value={fillNumber?.value ?? ''}
              onChange={({ target: { value: fillNumber } }) =>
                updateEdiReversalOverrides({
                  claim: { fillNumber: { value: Number(fillNumber), send: true } },
                })
              }
              placeholder="Fill number..."
              disabled={hideFillNumberField}
            />
          </ListRowValue>
          <ListRowEyeButton>
            <SuppressFieldButton onClick={toggleIsFillNumberFieldDisabled} hideField={hideFillNumberField} />
          </ListRowEyeButton>
        </RxEdiRowContainer>
      </RxEdiListRow>

      <RxEdiListRow>
        <RxEdiRowContainer>
          <ListRowLabel>Other Coverage Code (C8):</ListRowLabel>
          <ListRowValue>
            <RxTextInput
              value={otherCoverageCode?.value ?? ''}
              onChange={({ target: { value: otherCoverageCode } }) =>
                updateEdiReversalOverrides({
                  claim: { otherCoverageCode: { value: Number(otherCoverageCode), send: true } },
                })
              }
              placeholder="Other coverage code..."
              disabled={hideOtherCoverageCodeField}
            />
          </ListRowValue>
          <ListRowEyeButton>
            <SuppressFieldButton
              onClick={toggleIsOtherCoverageCodeFieldDisabled}
              hideField={hideOtherCoverageCodeField}
            />
          </ListRowEyeButton>
        </RxEdiRowContainer>
      </RxEdiListRow>

      <RxEdiListRow>
        <RxEdiRowContainer>
          <ListRowLabel>Pharmacy Service Type (U7):</ListRowLabel>
          <ListRowValue>
            <RxTextInput
              value={pharmacyServiceType?.value ?? ''}
              onChange={({ target: { value: pharmacyServiceType } }) =>
                updateEdiReversalOverrides({
                  claim: { pharmacyServiceType: { value: pharmacyServiceType, send: true } },
                })
              }
              placeholder="Pharmacy service type..."
              disabled={hidePharmacyServiceTypeField}
            />
          </ListRowValue>
          <ListRowEyeButton>
            <SuppressFieldButton
              onClick={toggleIsPharmacyServiceTypeFieldDisabled}
              hideField={hidePharmacyServiceTypeField}
            />
          </ListRowEyeButton>
        </RxEdiRowContainer>
      </RxEdiListRow>
    </>
  )
}

const ClaimHeaderRow = () => {
  return (
    <RxEdiTitleRow>
      <RxEdiRightTitleCell>
        <RightFilledHeader>Claim segment</RightFilledHeader>
      </RxEdiRightTitleCell>
    </RxEdiTitleRow>
  )
}

export default ClaimSegmentEditReversal
