import { useState } from 'react'
import { useMutation } from '@truepill/tpos-react-router'
import { UserRoles } from '@truepill/tpos-types'
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg'
import { ReactComponent as ArchiveIcon } from 'assets/icons/inventory.svg'
import { ReactComponent as UnarchiveIcon } from 'assets/icons/reverse.svg'
import AuthLimited from 'components/AuthLimited'
import type { ToolTipFacing } from 'components/HotKeyToolTip'
import { ToolButton } from 'components/SideToolBar'
import Lozenge from 'components/Tiles/Lozenge'
import { UPDATE_DOCUMENT } from 'gql'
import useErrorToast from 'hooks/toast/useErrorToast'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import AttachmentDetailsModal from 'modals/AttachmentDetailsModal'
import EditAttachmentModal from 'modals/EditAttachmentModal'
import moment from 'moment'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import styled from 'styled-components'
import { subduedColor } from 'styles/styleVariables'
import type { TPOSDocument } from 'types'

const isPdf = (url: string) => url.includes('.pdf')

type AttachmentPreviewProps = { document: TPOSDocument }

const AttachmentPreview = ({ document }: AttachmentPreviewProps): JSX.Element => {
  const showSuccessToast = useSuccessToast()
  const showErrorToast = useErrorToast()
  const [isHovered, setIsHovered] = useState(false)
  const [updateDocument] = useMutation(UPDATE_DOCUMENT, {
    onCompleted({ updateDocument }) {
      showSuccessToast(`Attachment ${updateDocument.isArchived ? '' : 'un'}archived`)
    },
    onError(err) {
      showErrorToast(`Failed to archive attachment! ${err.message.replace('GraphQL error:', '')}`)
    },
  })

  const { caption, _id: documentId, createdAt, tag, s3SignedUrl, isArchived } = document

  const createdDate = moment(createdAt).format('MM/DD/YYYY, h:mmA')

  const { showModal } = useModalContext()
  const onAttachmentClicked = () => {
    showModal(() => (
      <AttachmentDetailsModal
        attachmentId={documentId}
        caption={caption}
        createdAt={createdDate}
        tag={tag}
        s3Url={s3SignedUrl}
      />
    ))
  }

  const previewIsPdf = isPdf(s3SignedUrl)

  const tooltipPositioning = {
    position: (isArchived ? 'left' : 'top') as ToolTipFacing,
    offsetTop: isArchived ? 0.5 : -2,
    offsetLeft: 0.85,
  }

  return (
    <AttachmentElement
      key={documentId}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onAttachmentClicked}
      showBorder={previewIsPdf}
      isArchived={isArchived}
    >
      {previewIsPdf ? (
        <AttachmentPdfViewer src={s3SignedUrl} hovered={isHovered} />
      ) : (
        <AttachmentImageViewer src={s3SignedUrl} hovered={isHovered} />
      )}
      {isHovered && (
        <AttachmentDataContainer>
          <AuthLimited roles={[UserRoles.Pharmacist, UserRoles.LeadPharmacist]}>
            <ButtonsContainer id="AttachmentButtonsContainer">
              <ArchiveButton
                label={isArchived ? 'Unarchive' : 'Archive'}
                tooltipText={isArchived ? 'Unarchive' : 'Archive'}
                icon={isArchived ? UnarchiveIcon : ArchiveIcon}
                tooltipPositioning={tooltipPositioning}
                clickCallback={event => {
                  // Stop the click from triggering the details modal open
                  event?.stopPropagation()
                  updateDocument({
                    variables: {
                      documentId,
                      isArchived: !isArchived,
                    },
                  })
                }}
              />
              {!isArchived && (
                <EditButton
                  label="Edit"
                  tooltipText="Edit"
                  icon={EditIcon}
                  tooltipPositioning={tooltipPositioning}
                  clickCallback={event => {
                    // Stop the click from triggering the details modal open
                    event?.stopPropagation()
                    showModal(() => <EditAttachmentModal document={document} />)
                  }}
                />
              )}
            </ButtonsContainer>
          </AuthLimited>
          <AttachmentsCaption isArchived={isArchived}>{caption}</AttachmentsCaption>
          <AttachmentsDate isArchived={isArchived}>Added {createdDate}</AttachmentsDate>
          <StyledLozenge backgroundColor={subduedColor} isArchived={isArchived}>
            {tag}
          </StyledLozenge>
        </AttachmentDataContainer>
      )}
    </AttachmentElement>
  )
}

const StyledLozenge = styled(Lozenge)<{ isArchived: boolean }>`
  min-width: 40px;
  max-width: 80px;
  margin-left: 0;
  margin-top: 5px;
  ${({ isArchived }) => isArchived && 'text-decoration: line-through;'}
`

const AttachmentsCaption = styled.h3<{ isArchived: boolean }>`
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  ${({ isArchived }) => isArchived && 'text-decoration: line-through;'}
`

const AttachmentsDate = styled.p<{ isArchived: boolean }>`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #999999;
  margin-top: 5px;
  ${({ isArchived }) => isArchived && 'text-decoration: line-through;'}
`

const AttachmentDataContainer = styled.div`
  z-index: 2;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 8px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  background-color: transparent;
`

const AttachmentImageViewer = styled.img<{ hovered: boolean }>`
  width: 100%;
  height: 100%;
  opacity: ${({ hovered }) => (hovered ? 0.1 : 1)};
`

const AttachmentPdfViewer = styled.iframe<{ hovered: boolean }>`
  width: 100%;
  height: 100%;
  opacity: ${({ hovered }) => (hovered ? 0.1 : 1)};
`

const AttachmentElement = styled.div<{ showBorder: boolean; isArchived: boolean }>`
  width: 170px;
  height: 170px;
  margin-top: 20px;
  position: relative;
  cursor: pointer;
  border-radius: 4px;
  ${({ showBorder }) => showBorder && 'border: 1px solid;'}
  ${({ isArchived }) =>
    isArchived &&
    `
    background-color: rgba(0, 0, 0, 0.2);
    background:
      repeating-linear-gradient(45deg, rgba(0,0,0,0.8), rgba(0,0,0,0.8) 3px, rgba(0,0,0,0.2) 3px, rgba(0,0,0,0.2) 20px);
  `}
  :hover {
    background-color: transparent;
    background: transparent;
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
`

const EditButton = styled(ToolButton)`
  background-color: transparent !important;
  border: none !important;
  max-height: 1.5rem;
  :hover,
  :focus {
    box-shadow: none !important;
    cursor: pointer;
  }
`

const ArchiveButton = styled(ToolButton)`
  background-color: transparent !important;
  border: none !important;
  margin-right: 0;
  max-height: 1.5rem;
  :hover,
  :focus {
    box-shadow: none !important;
    cursor: pointer;
  }
`

export default AttachmentPreview
