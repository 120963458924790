import { Text, theme } from '@truepill/react-capsule'
import { useQuery } from '@truepill/tpos-react-router'
import HyphenatedNdc from 'components/HyphenatedNdc'
import { GET_CRITICAL_MEDICAL_NOTE_BY_NDC } from 'gql'
import { AlertOctagon } from 'react-feather'
import styled from 'styled-components'
import type { CriticalMedicalNotes } from 'types'

type CriticalMedicalNoteWarningProps = {
  ndc: string
  disableBackgroundColor?: boolean
  style?: any
}

const CriticalMedicalNoteWarning = (props: CriticalMedicalNoteWarningProps): JSX.Element => {
  const { ndc, disableBackgroundColor, style } = props

  const {
    data: dataGetCmn = { getNoteByNdc: undefined },
    loading: loadingGetCmn,
    error: errorGetCmn,
  } = useQuery<{
    getNoteByNdc: CriticalMedicalNotes
  }>(GET_CRITICAL_MEDICAL_NOTE_BY_NDC, {
    variables: { ndc },
  })

  if (errorGetCmn) {
    return (
      <Text>
        Error retrieving the critical medical notes for NDC <HyphenatedNdc ndc={ndc} />
      </Text>
    )
  }

  if (loadingGetCmn) {
    return <></>
  }

  const critMedNotes = dataGetCmn?.getNoteByNdc

  if (!critMedNotes?.note) {
    return <></>
  }

  return (
    <MedicalNoteAlertContainer
      style={{
        backgroundColor: disableBackgroundColor ? 'inherit' : theme.colors['functional-warning-light'].value,
        ...style,
      }}
    >
      <IconWrapper>
        <AlertOctagon
          style={{
            color: theme.colors['functional-warning-dark'].value,
          }}
        />
      </IconWrapper>
      <TextWrapper>
        <Text
          css={{
            color: theme.colors['functional-warning-dark'].value,
          }}
        >
          {critMedNotes?.note}
        </Text>
      </TextWrapper>
    </MedicalNoteAlertContainer>
  )
}

const MedicalNoteAlertContainer = styled.div`
  height: hug;
  width: hug;
  border-radius: 0.5rem;
  padding: 0.6rem;
  gap: 0.6rem;
  display: flex;
  flex-direction: row;
`

const IconWrapper = styled.div`
  flex: 1;
  min-width: 1rem;
  min-height: 1rem;
`

const TextWrapper = styled.div`
  flex: 19;
`

export default CriticalMedicalNoteWarning
