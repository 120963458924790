import { ReactComponent as SortIcon } from 'assets/icons/sort.svg'
import Select from 'components/Select'
import { usePlusClient } from 'providers/VisionRouter'
import styled from 'styled-components'
import { primaryBackgroundColor, bodyPrimaryColor, borderColor } from 'styles/styleVariables'

const StyledSelectButton = styled(Select)`
  border-color: ${borderColor};
  margin-left: 0.625rem;
  background-color: ${primaryBackgroundColor};
  color: ${bodyPrimaryColor};
  > svg {
    fill: ${bodyPrimaryColor};
  }
  > div {
    > svg {
      fill: ${bodyPrimaryColor};
    }
  }
`

export default function OrderSortDropdown(): JSX.Element {
  const { routeToMergedQuery, currentLocation } = usePlusClient()
  const queryMap = currentLocation?.queryMap || {}
  const options = [
    { label: 'Age', value: 'age' },
    { label: 'Drug Name', value: 'drugName' },
    { label: 'Quantity', value: 'dispensedQty' },
  ]
  const value = options.find(opt => opt.value === queryMap.sortField) || options[0]
  return (
    <StyledSelectButton
      options={options}
      value={value}
      icon={<SortIcon />}
      onChange={([option]) => {
        routeToMergedQuery({ sortField: option.value })
      }}
      showActive={true}
      disableClear={true}
    />
  )
}
