import { useState, useCallback, useEffect } from 'react'
import { SaveButton } from 'components/ActionButton'
import { FormItem } from 'components/ColumnForm'
import { ModalWrapper, ModalHeader, ButtonsContainer } from 'components/Modal'
import { TextInput } from 'grommet'
import useErrorToast from 'hooks/toast/useErrorToast'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import { useFormData } from 'hooks/useFormData'
import type { ConvertTempSecretResponse } from 'hooks/useMFA'
import { useConvertMFATimeToken } from 'hooks/useMFA'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import styled, { css } from 'styled-components'

type MFAModalProps = {
  userId?: string
}

const AddMFAModal = ({ userId }: MFAModalProps): JSX.Element => {
  const { dismissModal } = useModalContext()

  const [timeToken, setTimeToken] = useState('')
  const showSuccessToast = useSuccessToast(true)
  const showErrorToast = useErrorToast(true)

  const { convertSecret, status: convertSecretStatus } = useConvertMFATimeToken(userId || '')

  const {
    state: { formData },
  } = useFormData()

  const onTimeTokenChange = useCallback(({ target: { value } }) => setTimeToken(value), [])

  useEffect(() => {
    if (convertSecretStatus.success) {
      dismissModal()
    }
  })

  useEffect(() => {
    if (convertSecretStatus.data) {
      const response: ConvertTempSecretResponse = convertSecretStatus.data
      if (response.convertTemporaryMFASecret.created) {
        showSuccessToast('Multifactor Authentication successfully verified.')
      } else {
        showErrorToast('Verification of Multifactor Authentication failed.')
      }
    }
  })

  return (
    <ModalWrapper id="AddMFAModal" styles={ModalWrapperStyles}>
      <ModalHeader marginTop="30px">
        <AuthHeader>Multi-factor Authentication</AuthHeader>
      </ModalHeader>
      <p>
        {' '}
        Scan this QR code from an authenticator app and enter the 6-digit code to set up your multi-factor
        authorization.{' '}
      </p>
      {formData.user?.qr_url ? (
        <QRItem>
          <QRCodeStyled alt="qr code" src={formData.user?.qr_url} />
        </QRItem>
      ) : (
        <QRItem>
          <QRCodeStyled />
        </QRItem>
      )}
      <FormItem>
        <h3>Authentication Code</h3>
        <AuthInput value={timeToken} placeholder={'6-Digit Number'} onChange={onTimeTokenChange} />
      </FormItem>
      <AuthButtonsContainer>
        <SaveButton
          disabled={false}
          isModal
          label={'Verify'}
          onClick={() => {
            convertSecret(timeToken)
          }}
        />
      </AuthButtonsContainer>
    </ModalWrapper>
  )
}

const AuthHeader = styled.h1`
  font-size: 21px;
`

const AuthInput = styled(TextInput)`
  height: 40px;
`

const AuthButtonsContainer = styled(ButtonsContainer)`
  margin-top: 10px;
`

const QRItem = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const ModalWrapperStyles = css`
  overflow-y: hidden;
  width: 420px;
  height: 460px;
  display: flex;
  padding: 0px 20px;
`

const QRCodeStyled = styled.img`
  width: 141px;
  height: 140px;
  left: 119.5px;
  padding-top: 30px;

  flex: none;
  order: 1;
  flex-grow: 0;
  justify-content: center;
`
export default AddMFAModal
