import { useEffect, useState } from 'react'
import { useQuery } from '@truepill/tpos-react-router'
import {
  UserRoles,
  CopayStatus,
  ClaimDataPaidStatus,
  RxFillRequestStatus,
  LaunchDarkly,
  CopayTriageOtherReasonMessages,
  CopayTriageReasons,
} from '@truepill/tpos-types'
import ActivePriorAuthorizationBanner from 'components/ActivePriorAuthorizationBanner'
import AuthLimited from 'components/AuthLimited'
import BreadCrumb from 'components/BreadCrumb'
import ClaimsTable from 'components/ClaimsTable'
import CopayRequestFillTopBanner from 'components/CopayRequestFillTopBanner'
import { PageContent, PageHeading, StickyPageHeadingContainer } from 'components/PageStructure'
import ReviewPatient from 'components/ReviewPatient'
import ReviewPrescriberEdit from 'components/ReviewPrescriber'
import ReviewPrescription from 'components/ReviewPrescription'
import RxHeading from 'components/RxHeading'
import { EditMode, NormalMode } from 'components/RXPageStructure'
import { RXTable, RXSegment, RXRowTable } from 'components/RXTable'
import RXTopBanner from 'components/RXTopBanner'
import ToolBar, {
  EditButton,
  OrderButton,
  NotesButton,
  MedicalHistoryButton,
  DataButton,
  MonographButton,
  EditEdiButton,
  LogButton,
  EditReversalInfoButton,
} from 'components/ToolBar'
import TriageIssues from 'components/TriageIssues'
import { GET_ORDER_INSURANCE_OPTION_IMAGES } from 'gql'
import { useOrderLock, useCopayLock } from 'hooks'
import useClaim, { claimsRequest } from 'hooks/navigation/useClaim'
import { uniqueLogEntries } from 'hooks/navigation/useLogs'
import useFulfillmentQueue from 'hooks/useFulfillmentQueue'
import ClaimsSuccessModal from 'modals/ClaimsSuccessModal'
import useCriticalNotesModal from 'modals/useCriticalNotesModal'
import { useClient, useFlag } from 'providers/LaunchDarklyProvider'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import { usePlusClient } from 'providers/VisionRouter'
import { goToPriorAuthorization } from 'routes'
import styled from 'styled-components'
import type {
  Claim,
  QueueItem,
  OrderInsurance,
  Insurance,
  Fill,
  Patient,
  Prescription,
  RXFillRequest,
  CopayRequestFill,
  Log,
  RxFillRequestOverrideCodes,
  RxFillRequestDur,
  RxFillRequestCOBOverride,
  CopayTriage,
} from 'types'
import {
  getFillLevelNotes,
  getFillParentNotes,
  getInsuranceKey,
  isCopayRequest,
  stripTypename,
  isOrder,
  getItemFill,
} from 'utils'
import EdiEditView from '../claims/ediEditView'
import PV1ActionButtons from '../pv1/ActionButtons'
import ActionButtons from './ActionButtons'
import EditReversalInfoView from './editReversalInfoView'
import type { PayerStatus } from './ReviewSections/ReviewPayer'
import ReviewPayer from './ReviewSections/ReviewPayer'
import ReviewPrescriber from './ReviewSections/ReviewPrescriber'
import TopSection from './ReviewSections/TopSection'

const paidStatuses = ClaimDataPaidStatus

const getPreviousClaimStatus = ({ status }: Readonly<Claim>): 'previousSuccess' | undefined =>
  status && paidStatuses.includes(status) ? 'previousSuccess' : undefined

const getClaimStatus = ({ status }: Readonly<Claim>): PayerStatus =>
  status === undefined ? undefined : paidStatuses.includes(status) ? 'success' : 'failed'

const getPayerStatuses = (fill: Readonly<Fill>, prescription: Readonly<Prescription>) => {
  const payerStatuses: Record<string, PayerStatus> = {}

  prescription.fills.forEach(prescriptionFill => {
    // ignore the current fill
    if (prescriptionFill._id.toString() === fill._id.toString()) return

    prescriptionFill.claims.forEach(claim => {
      if (!claim?.bin || !claim?.sent?.metadata?.insurance?.cardholderId) return

      const payerKey = getInsuranceKey(claim.bin, claim.sent.metadata.insurance.cardholderId, claim.pcn)
      payerStatuses[payerKey] = getPreviousClaimStatus(claim) || payerStatuses[payerKey]
      // fall back to previous status if not found
    })
  })

  fill.claims.forEach(claim => {
    if (!claim?.bin || !claim.sent?.metadata?.insurance?.cardholderId) return

    const payerKey = getInsuranceKey(claim.bin, claim.sent.metadata.insurance.cardholderId, claim.pcn)
    payerStatuses[payerKey] = getClaimStatus(claim) || payerStatuses[payerKey]
    // fall back to previous status if not found
  })

  return payerStatuses
}

type ClaimViewProps = {
  fill: Fill
  item: QueueItem
  logs: Log[]
  patient: Patient
  prescription: Prescription
  rxFillRequest?: RXFillRequest
}

const ClaimView = ({ fill, item, logs, patient, prescription, rxFillRequest }: ClaimViewProps): JSX.Element => {
  const orderId = isOrder(item) ? item._id : undefined
  const customer = isOrder(item) ? item.customer : undefined
  const copayRequestId = isCopayRequest(item) ? item._id : undefined
  const fillId = fill._id.toString()
  const itemFill = isCopayRequest(item) ? getItemFill(item, fillId) : getItemFill(item, fillId)
  const {
    tokenContext: { isAdmin, isLeadCustomerSupport },
  } = usePlusClient()

  let overrideCodes = [] as RxFillRequestOverrideCodes[]
  let durs = [] as RxFillRequestDur[]
  let cobOverrides = [] as RxFillRequestCOBOverride[]

  if (itemFill) {
    overrideCodes = itemFill.overrideCodes
    durs = itemFill.durs
    cobOverrides = itemFill.cobOverrides
  }

  // initialize variables from the backend
  useClaim({ order: isOrder(item) ? item : undefined, fill, patient })
  const { data: { getInsuranceOptionImages: insuranceImages = [] } = {} } = useQuery(
    GET_ORDER_INSURANCE_OPTION_IMAGES,
    { variables: { orderId, copayRequestId } },
  )
  const [patientForm] = useState(patient)
  const isSubmitting = claimsRequest()?.claimsSubmitting
  const [runningClaim, setRunningClaim] = useState(false)
  const isOrderItem = isOrder(item)
  const { orderLockedBy, isOrderLockedByMe } = useOrderLock(item?._id, !isOrderItem)
  const { copayEditable, isCopayLockedByMe, copayLockedBy } = useCopayLock(item?._id, isOrderItem)
  const nonEditable =
    runningClaim ||
    isSubmitting ||
    (isOrderItem && orderLockedBy && !isOrderLockedByMe) ||
    (!isOrderItem && !copayEditable)
  const parentNotes = getFillParentNotes(logs, item._id, isCopayRequest(item))
  const fillLevelNotes = getFillLevelNotes(logs, fill._id, item._id, isCopayRequest(item))
  const payerStatuses = getPayerStatuses(fill, prescription)
  const { showModal } = useModalContext()
  const fulfillmentQueue = useFulfillmentQueue()
  useCriticalNotesModal({ order: isOrder(item) ? item : undefined, prescription, logs: logs })

  const failedClaimReversals = fill.claims.find(claim => claim?.status === 'Paid' && claim?.failedReversals?.length > 0)

  const tempInsuranceOptions: Insurance[] = (item?.insuranceOptions || [])
    // add the durs and override codes to each insurance option
    .map((ins: Insurance) => {
      const optionOverrideCodes = overrideCodes?.find(overrideCode => overrideCode.orderInsuranceOptionId === ins._id)
      const optionCobOverrides = cobOverrides?.find(cobOverride => cobOverride?.orderInsuranceOptionId === ins._id)
      return {
        ...ins,
        durs: durs.filter(dur => dur.orderInsuranceOptionId === ins._id).map(dur => stripTypename(dur)),
        overrideCodes: optionOverrideCodes ? stripTypename(optionOverrideCodes) : undefined,
        cobOverrides: optionCobOverrides ? stripTypename(optionCobOverrides) : undefined,
      }
    })

  const [insuranceOptions, setInsuranceOptions] = useState<Insurance[]>(tempInsuranceOptions)

  useEffect(() => {
    setInsuranceOptions(tempInsuranceOptions)
  }, [JSON.stringify(tempInsuranceOptions)])

  const tempInsurances: OrderInsurance[] = (item?.insurances || [])
    // add the durs and override codes to each insurance
    .map((ins: OrderInsurance) => {
      const insuranceOverideCodes = overrideCodes?.find(
        overrideCode => overrideCode.orderInsuranceOptionId === ins.orderInsuranceOptionId,
      )
      const insuranceCobOverrides = cobOverrides?.find(
        cobOverrides => cobOverrides.orderInsuranceOptionId === ins.orderInsuranceOptionId,
      )
      return {
        ...ins,
        durs: durs
          .filter(dur => dur.orderInsuranceOptionId === ins.orderInsuranceOptionId)
          .map(dur => stripTypename(dur)),
        overrideCodes: insuranceOverideCodes ? stripTypename(insuranceOverideCodes) : undefined,
        cobOverrides: insuranceCobOverrides ? stripTypename(insuranceCobOverrides) : undefined,
      }
    })

  const [insurances, setInsurancesState] = useState<OrderInsurance[]>(tempInsurances)

  useEffect(() => {
    setInsurancesState(tempInsurances)
  }, [JSON.stringify(tempInsurances)])

  const successfulClaim = itemFill?.adjudication?.runSuccessful
  const claimJumperErrors = itemFill?.adjudication?.claimJumperErrors ?? []

  const isTriageSetAtFillLevel = (triage: CopayTriage) => !!triage?.copayRequestFillId
  const isTriageEnded = (triage: CopayTriage) => !!triage?.endDate

  const isCopayInTriageFailedToReverseClaim =
    isCopayRequest(item) &&
    !!item.triages.find(
      triage =>
        !isTriageEnded(triage) &&
        triage.reason === CopayTriageReasons.Other &&
        (triage.message === CopayTriageOtherReasonMessages.FailedReversalTriageReason ||
          !!triage.message?.match(CopayTriageOtherReasonMessages.FailedReversalAfterMinutesTriageReason)),
    )

  // Storing as an integer to force retriggering claim success modal by changing the value
  const [shouldShowClaimsSuccessModal, setShouldShowClaimSuccessModal] = useState(0)

  useEffect(() => {
    if (!isCopayInTriageFailedToReverseClaim && (successfulClaim || shouldShowClaimsSuccessModal > 0)) {
      showModal(() => (
        <ClaimsSuccessModal
          fill={fill}
          itemFill={itemFill as RXFillRequest | CopayRequestFill}
          item={item}
          onDismissOtherModals={() => setShouldShowClaimSuccessModal(shouldShowClaimsSuccessModal + 1)}
          showActions
          setShouldShowClaimSuccessModal={setShouldShowClaimSuccessModal}
          fulfillmentQueueName={fulfillmentQueue?.name}
        />
      ))
    }
  }, [successfulClaim, shouldShowClaimsSuccessModal])

  const [displayEdiEdit, setDisplayEdiEdit] = useState(false)
  const [displayEditReversalInfo, setDisplayEditReversalInfo] = useState(false)

  // Feature flags (launch darkly)
  const { client: ldClient } = useClient()
  const [showCopayCollected, setShowCopayCollected] = useState(false)
  const [shouldValidateCopayPayment, setShouldValidateCopayPayment] = useState(false)
  const displayCopayCollected = useFlag(LaunchDarkly.FeatureFlags.DISPLAY_COLLECTED_COPAY_IN_FRONTEND)
  const useCopayPaymentValidation = useFlag(LaunchDarkly.FeatureFlags.USE_COPAY_PAYMENT_VALIDATION)

  useEffect(() => {
    if (customer?.legacyId && ldClient) {
      ldClient.identify({ key: customer.legacyId.toString() })

      setShowCopayCollected(displayCopayCollected)
      setShouldValidateCopayPayment(useCopayPaymentValidation)
    }
  }, [customer, ldClient, displayCopayCollected, useCopayPaymentValidation, setShouldValidateCopayPayment])

  if (displayEdiEdit) {
    if (!item) {
      return <></>
    }
    return (
      <EdiEditView
        item={item}
        itemFill={itemFill as RXFillRequest | CopayRequestFill}
        prescription={prescription}
        patient={patient}
        fill={fill}
        setDisplayEdiEdit={setDisplayEdiEdit}
      />
    )
  }

  if (!itemFill) {
    return <></>
  }

  if (displayEditReversalInfo) {
    return (
      <EditReversalInfoView
        item={item}
        itemFill={itemFill as RXFillRequest | CopayRequestFill}
        prescription={prescription}
        patient={patient}
        fill={fill}
        setDisplayEditReversalInfo={setDisplayEditReversalInfo}
      />
    )
  }

  const hideEditButton =
    isCopayRequest(item) &&
    ([CopayStatus.Confirmed, undefined].includes(getItemFill(item, fillId)?.status) ||
      (getItemFill(item, fillId)?.status === CopayStatus.Rejected && !isAdmin()))

  const hideActionButtons =
    isCopayRequest(item) &&
    ([CopayStatus.Confirmed, undefined].includes(getItemFill(item, fillId)?.status) ||
      (getItemFill(item, fillId)?.status === CopayStatus.Rejected && !(isAdmin() || isLeadCustomerSupport())))

  const disableEditInsurance =
    isCopayRequest(item) &&
    [CopayStatus.Rejected, CopayStatus.Complete, CopayStatus.Confirmed, undefined].includes(
      getItemFill(item, fillId)?.status,
    )

  const isCopayInTriage =
    isCopayRequest(item) && !!item.triages.find(triage => !isTriageSetAtFillLevel(triage) && !isTriageEnded(triage))

  const showCopayTriage = isCopayRequest(item) && (itemFill?.status === CopayStatus.Triage || isCopayInTriage)
  const onlyAdminCanReverseClaims =
    !isCopayRequest(item) &&
    itemFill?.status !== CopayStatus.Triage &&
    !isCopayInTriage &&
    itemFill?.status !== CopayStatus.Pending

  const shouldShowActivePriorAuthBannerForOrder =
    isOrder(item) && (itemFill?.status as RxFillRequestStatus) === RxFillRequestStatus.Adjudication

  const shouldShowActivePriorAuthBannerForCopay =
    isCopayRequest(item) &&
    [CopayStatus.Triage, CopayStatus.Pending, CopayStatus.PriorAuthorization].includes(itemFill?.status as CopayStatus)

  const shouldShowActivePriorAuthBanner =
    shouldShowActivePriorAuthBannerForOrder || shouldShowActivePriorAuthBannerForCopay

  return (
    <>
      <StyledStickyPageHeadingContainer>
        {!isCopayRequest(item) && <RXTopBanner order={item} rxFillRequest={itemFill as RXFillRequest} />}
        {isCopayRequest(item) && (
          <CopayRequestFillTopBanner
            copay={item}
            copayRequestFill={itemFill as CopayRequestFill}
            disableCopay={!isCopayLockedByMe && !!copayLockedBy}
            copayLockedBy={copayLockedBy}
          />
        )}
        {shouldShowActivePriorAuthBanner && itemFill.activePriorAuthorization?._id && (
          <ActivePriorAuthorizationBanner
            reference={goToPriorAuthorization({ priorAuthorizationId: itemFill.activePriorAuthorization._id })}
          />
        )}
        <BreadCrumb />
        <PageHeading>
          <RxHeading item={item} prescription={prescription} fill={fill} patient={patient} />
          <NormalMode>
            {!hideActionButtons && (
              <ActionButtons
                fill={fill}
                item={item}
                patient={patient}
                prescription={prescription}
                setRunningClaim={setRunningClaim}
                hasRunClaim={!!fill.claims.length}
                disabled={nonEditable}
                isCopayInTriage={isCopayInTriage}
                onlyAdminCanExecuteAction={onlyAdminCanReverseClaims}
              />
            )}
          </NormalMode>
          <EditMode>
            {/* // TODO - make this work for copays (only used for the edit buttons -_-) */}
            {isOrder(item) && (
              <PV1ActionButtons
                fill={fill}
                order={item}
                prescription={prescription}
                rxFillRequest={getItemFill(item, fill._id) as RXFillRequest}
              />
            )}
          </EditMode>
        </PageHeading>
      </StyledStickyPageHeadingContainer>
      <PageContent>
        <RXViewContainer>
          {((isOrder(item) && item.inTriage) || showCopayTriage) && itemFill && (
            <TriageIssues
              item={item}
              fill={fill}
              itemFill={itemFill}
              useCopayPaymentValidation={shouldValidateCopayPayment}
            />
          )}{' '}
          <RXTable>
            <RXSegment>
              <RXRowTable>
                <NormalMode>
                  <ClaimsTable claims={fill.claims} />
                  <TopSection
                    claimJumperErrors={claimJumperErrors}
                    fill={fill}
                    item={item}
                    patient={patient}
                    prescription={prescription}
                    rxFillRequest={rxFillRequest}
                    showCopayCollected={showCopayCollected}
                  />
                  <ReviewPayer
                    itemId={item._id}
                    nonEditable={nonEditable || disableEditInsurance || false}
                    insurances={insurances}
                    setInsurancesState={setInsurancesState}
                    insuranceOptions={insuranceOptions}
                    insuranceImages={insuranceImages}
                    fill={fill}
                    payerStatuses={payerStatuses}
                    claimJumperErrors={claimJumperErrors}
                    isOrder={isOrder(item)}
                  />
                  <ReviewPrescription claimsView item={item} prescription={prescription} fill={fill} />
                  <ReviewPrescriber prescription={prescription} />
                </NormalMode>
                <EditMode>
                  <ReviewPatient patient={patientForm} prescription={prescription} />
                  <ReviewPrescriberEdit prescription={prescription} />
                  <ReviewPrescription item={item} prescription={prescription} fill={fill} />
                </EditMode>
              </RXRowTable>
            </RXSegment>
          </RXTable>
        </RXViewContainer>
        <ToolBar>
          {!nonEditable && (
            <AuthLimited roles={[UserRoles.Pharmacist, UserRoles.Technician, UserRoles.CustomerSupport]}>
              {!hideEditButton && <EditButton orderId={item._id} />}
            </AuthLimited>
          )}
          <LogButton
            logs={logs ? uniqueLogEntries(logs) : []}
            fillId={fill._id}
            copayRequestId={isOrder(item) ? undefined : item._id}
            isCopay={isCopayRequest(item)}
          />
          <NotesButton
            notesCount={fillLevelNotes.length}
            showBadge={parentNotes.length > 0 || fillLevelNotes.length > 0}
          />
          <MedicalHistoryButton fillId={fill._id} patientId={patient._id} />
          {isOrder(item) && <OrderButton order={item} />}
          <DataButton prescription={prescription} />
          <MonographButton ndc={fill.dispensed.ndc} />
          {!nonEditable && <EditEdiButton setDisplayEdiEdit={setDisplayEdiEdit} />}
          {failedClaimReversals && <EditReversalInfoButton setDisplayEditReversalInfo={setDisplayEditReversalInfo} />}
        </ToolBar>
      </PageContent>
    </>
  )
}

const RXViewContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-top: 1.25rem;
  padding-left: 1.875rem;
`
RXViewContainer.displayName = 'RXViewContainer'

const StyledStickyPageHeadingContainer = styled(StickyPageHeadingContainer)`
  z-index: 2;
`
StyledStickyPageHeadingContainer.displayName = 'StyledStickyPageHeadingContainer '

export default ClaimView
