import type { RejectionDetails } from '../../utility/shared'
import type { Address } from '../address'
import type { EntityId } from '../shared'
import type { User } from '../user'

export enum OutboundTransferSource {
  API = 'API',
  Manual = 'Manual',
  Surescripts = 'Surescripts',
}

export enum OutboundTransferStatus {
  Complete = 'Complete',
  Pending = 'Pending',
  PV = 'PV',
  Rejected = 'Rejected',
  Triage = 'Triage',
}

export interface OutboundTransferTriage {
  _id: EntityId
  userPlacedInTriage: EntityId
  userPlaced?: User
  userRemovedFromTriage?: EntityId
  userRemoved?: User
  outboundTransferId?: EntityId
  reason: string
  code: string
  message?: string
  startDate: Date
  endDate?: Date
}

export interface OutboundTransferOutreach {
  method: string
  reason: string
  notes: string
  outreachDate: Date
  addedByUserId: EntityId
}

export interface OutboundTransferOriginalRequestTransferTo {
  pharmacy_name: string
  pharmacist_name?: string
  npi?: string
  phone?: string
  fax?: string
  address: Address
}

export interface OutboundTransferOriginalRequest {
  reason_code: string
  transfer_to: OutboundTransferOriginalRequestTransferTo
}

// TODO - populate when we handle create
// export interface OutboundTransferInput {}

export interface OutboundTransfer {
  completedByUserId?: EntityId
  coreTransferOutId?: number
  coreTransferOutToken?: string
  customerId: EntityId
  locationId: EntityId
  originalRequest: OutboundTransferOriginalRequest
  outreaches?: OutboundTransferOutreach[]
  prescriptionId: EntityId
  reason: string
  rejectionDetails?: RejectionDetails
  source: OutboundTransferSource
  status: OutboundTransferStatus
  transferTo: {
    pharmacy: string
    pharmacistName?: string
    pharmacyNPI?: string
    phone?: string
    fax?: string
    address: Address
  }
  triages?: OutboundTransferTriage[]
}

export enum OutboundTransferTriageReason {
  ADDITIONAL_OUTREACH_REQUIRED = 'Additional Outreach Required',
  EFAX_FAILED = 'E-fax Failed',
  OTHER = 'Other',
}
