import { memo, useMemo } from 'react'
import { Select } from '@truepill/react-capsule'
import type { Language } from '@truepill/tpos-types'
import { DEFAULT_LANGUAGE, Languages } from '@truepill/tpos-types'

interface LanguageSelectProps {
  languagesAvailable?: Language[]
  onChange: (option?: LanguageOption) => void
  value?: LanguageOption
  label?: string
}

export interface LanguageOption {
  label: Languages
  value: Language
}

export const defaultOption = {
  label: Languages[DEFAULT_LANGUAGE],
  value: DEFAULT_LANGUAGE,
}

const mapLanguageOptions = (languages: Language[]): LanguageOption[] =>
  languages.map(lang => ({
    label: Languages[lang],
    value: lang,
  }))

const defaultLanguages = (Object.keys(Languages) as Language[]).map((l: Language) => l)

const LanguageSelect = ({ label, value, languagesAvailable, onChange }: LanguageSelectProps) => {
  const options = useMemo(() => mapLanguageOptions(languagesAvailable || defaultLanguages), [languagesAvailable])

  return (
    <Select
      value={value || defaultOption}
      label={label ?? 'Language'}
      options={options}
      onChange={onChange}
      selectedKey="label"
      optionComponent={({ option }) => <div>{option.label}</div>}
    />
  )
}

export const MemoizedLanguageSelect = memo(LanguageSelect)

export default LanguageSelect
