export * as Coupon from './fieldCodes.Coupon'
export * as OtherPayer from './fieldCodes.OtherPayer'
export * as DURPPS from './fieldCodes.DURPPS'

// Header Field
export type StandardVersion = 'D0'

// Header Field
export enum TransactionCodes {
  Claim = 'B1', // Claim
  ClaimReversal = 'B2', // Claim-Reversal
  Eligibility = 'E1', // Eligibility Check
}

export enum TransactionStatus {
  Approved = 'A',
  Benefit = 'B',
  Captured = 'C',
  DuplicateOfPaid = 'D',
  PADeferred = 'F',
  Paid = 'P',
  DuplicateOfCapture = 'Q',
  Rejected = 'R',
  DuplicatedOfApproved = 'S',
}

export enum TransactionStatusNames {
  DuplicateOfPaid = 'Duplicate',
  DuplicatedOfApproved = 'Duplicated of Approved',
}

// 305-C5 Gender Code
export enum Gender {
  NotSpecified = 0,
  Male = 1,
  Female = 2,
}

// 307-C7 Place of Service
export enum PlaceOfService {
  Pharmacy = 1,
}

// 331-CX
// Patient Id Qualifier
export enum PatientIDQualifier {
  SSN = '01',
  FacilityId = '1J',
  DriversLicense = '02',
  MilitaryId = '03',
  NonSSNHealthId = '04',
  SSNHealthId = '05',
  MedicaidId = '06',
  StateId = '07',
  PassportId = '08',
  MedicareHic = '09',
  PayerPBMId = '11',
  AlienNumber = '12',
  StudentVisaNumber = '13',
  IndianTribalId = '14',
  Other = '99',
  MedicalRecordId = 'EA',
}

// 335-2C
export enum PregnancyIndicator {
  NotSpecified = '',
  Yes = 2,
  No = 1,
}

// 309-C9 Eligibility Clarification
export enum EligibilityClarification {
  NotSpecified = 0,
  NoOverride = 1,
  Override = 2,
  FullTimeStudent = 3,
  DisabledDependent = 4,
  SignificantOther = 5,
}

// 408-D8 Dispense as Written Code
export enum DispenseAsWritten {
  // Subs Not Allowed
  NoSubByPrescriber = 1,
  NoSubBrandByLaw = 7,
  // Subs Allowed
  NoProductSelectionIndicated = 0,
  // Subs Allowed But Brand Used
  PatientRequested = 2,
  PharmacistSelected = 3,
  GenericNotInStock = 4,
  BrandDispensedAsGeneric = 5,
  GenericNotAvailable = 8,
  BrandRequestedByPlan = 9,
  // Other
  Override = 6,
}

// 455-EM Prescription Service Number Qualifier
// We are 1 usually
export enum RxServiceNumberQualifier {
  Rx = 1,
  Service = 2,
  NonPrescriptionProduct = 3,
}

// 436-E1 Product/Service Id Qualifier

export enum ProductIdQualifier {
  NotSpecified = '00',
  UPC = '01', // Universal Product Code
  HRI = '02', // Health Related Item
  NDC = '03', // National Drug Code
  HIBCC = '04', // Health Industry Business Communications Council
  DOD = '05', // Department of Defense
  DUR_PPS = '06', //  Drug Use Review / Professional Pharmacy Service
  CPT4 = '07', // Current Procedural Terminology
  CPT5 = '08', // Current Procedural Terminology
  HCPCS = '09', // Healthcare Common Procedure Coding System
  PPAC = '10', // Pharmacy Practice Activity Classification
  NAPPI = '11', // National Pharmaceutical Product Interface Code
  GTIN = '12', // Global Trade Identification Number
  DIN = '13', // Drug Identification Number
  // GPI = '14', // Medi Span's Generic Product Identifier
  GCN = '15', // First Databack Formulation ID
  // GFC = '16', // Truven Micromedex Generic Formulation Code
  // DDID = '17', // Medi Span Drug Descriptor ID
  FDBMedName = '28', // First DataBank Medication Name ID
  FDBRoutedMed = '29',
  FDBRoutedDosage = '30',
  FDBMedId = '31',
  FDBFormId = '32',
  FDBIngredientList = '33',
  UPN = '34', // Universial Product Number
  // LOINC = '35', // Logical Observation Identifier Names and Codes
  RepresentiveNDC = '36',
  MPid = '42', // Gold Standard Marketed Product Identifier
  ProdID = '43', // Gold Standard Product Identifier
  SPID = '44', // Gold Standard Specific Identifier
  DI = '45', // Device Identifier
  Other = '99',
}

// 419-DJ Prescription Origin Code
export enum PrescriptionOrigin { // TODO - clean up cap/lowercase properly
  NotKnown = 0,
  Written = 1,
  Telephone = 2,
  Electronic = 3,
  Fax = 4,
  Pharmacy = 5 /*  Pharmacy Value --------
      This value is used to cover any situation where a new Rx number needs to be created
      from an existing valid prescription such as traditional transfers,
      intrachain transfers, file buys, software upgrades/migrations, and any
      reason necessary to "give it a new number." This value is also the appropriate
      value for “Pharmacy dispensing” when applicable such as BTC (behind the counter),
      Plan B, established protocols, pharmacists authority to prescribe, etc.
    */,
  notKnown = 0,
  written = 1,
  telephone = 2,
  electronic = 3,
  fax = 4,
  pharmacy = 5,
}

// 406-D6 Compound Codes
export enum Compound {
  NotSpecified = 0,
  NotACompound = 1,
  Compound = 2,
}

// 461-EU Prior Authorization Code
export enum PriorAuthorization {
  NotSpecified = 0,
  PriorAuthorization = 1,
  MedicalCertification = 2,
  EPSDT = 3, // Early Periodic Screening Diagnosis Treatment
  ExemptionFromCopayAndOrCoinsurance = 4,
  ExemptionFromRx = 5,
  FamilyPlanningIndicator = 6,
}

// 418-DI Level of Service Code
export enum LevelOfService {
  NotSpecified = 0,
  PatientConsultation = 1,
  HomeDelivery = 2,
  Emergency = 3,
  TwentyFourHourService = 4,
  PatientConsultationRegardingGenericProduuctSelection = 5,
  InHomeService = 6,
  MedicalAtHome = 7, // Medical at home with special pharmacy services identical to Long Term Care beneficiaries with the exception of emergency kits.
}

// 420-DK Submission Clarification Code

export enum SubmissionClarification {
  NotSpecified = 0,
  NoOverride = 1,
  OtherOverride = 2,
  VacationSupply = 3,
  LostPrescription = 4,
  TherapyChange = 5,
  StarterDose = 6,
  MedicallyNecessary = 7,
  ProcessCompound = 8,
  Encounters = 9,
  MeetsPlanLimitations = 10,
  CertificationOnFile = 11,
  DMEReplacement = 12,
  PayerEmergencyAssistance = 13,
  LongTermCareLeave = 14,
  LongTermCareReplacement = 15,
  LongTermCareEmergencyBox = 16,
  LongTermCareEmergencySupply = 17,
  LongTermCarePatientAdmitIndicator = 18,
  SplitBilling = 19,
  _340B = 20,
  LTC14NotApplicable = 21, // 14 days or less not applicable
  LTC7 = 22, // 7 day supplies
  LTC4 = 23, // 4 day supplies
  LTC3 = 24, // 3 day supplies
  LTC2 = 25, // 2 day supplies
  LTC1 = 26, // 1 day supply
  LTC43 = 27, // 4 then 3 day
  LTC223 = 28, // 2 - 2 - 3 day supplies
  LTCD3D = 29, // Daily then 3 day Weekend
  LTCPerShift = 30, // Per Shift
  LTCPerMed = 31, // Per Med Shift
  LTCPRN = 32, // PRN on Demand
  LTC7Less = 33, // 7 day or less cycle otherwise not permitted
  LTC14 = 34, // 14 days
  LTC8to14 = 35, // 8-14 days
  LTCOutsideShortCycle = 36,
  PrescriberIdSubmitted = 42,
  PrescriberDeaAuthorized = 43,
  PrescriberDeaHospital = 45,
  PrescriberDea = 46,
  ShortenedDaysSupply = 47,
  FillSubsequentShortenedDaysSupply = 48,
  PrescriberNoNPI = 49,
  PrescriberMedicareFeeIsValidated = 50,
  PharmacyMedicareFeeIsValidated = 51,
  PrescriberStateLicenseIsValidated = 52,
  PrescriberEnrollmentInMedicaidIsValidated = 55,
  NonMatchedPrescriberID = 56,
  DischargeMedication = 57,
  NominalPrice = 58,
  FederalSupplySchedule = 59,
  LongTermCareMultipleDosing = 60, // Long term care same drug strength and dosage form with multiple dosing directions
  MedicationSynchronizationOverride = 61,
  Other = 99,
}

// 479-H8 // Other Amount Claim Submitted Qualifier
// 564-J3 { // Other Amount Paid Qualifier
export enum OtherAmount {
  Delivery = '01',
  Shipping = '02',
  Postage = '03',
  Administrative = '04',
  CompoundPreparation = '09',
  MedicationAdministration = '11',
}

// 423-DN
// Basis of Cost Determination
export enum BasisOfCostDetermination {
  Default = '00',
  AverageWholesalePrice = '01',
  LocalWholesaler = '02',
  Direct = '03', // Manufacturer's Published Non Wholesale Price
  EstimatedAquisitionCost = '04',
  Aquisition = '05',
  MaximumAllowableCost = '06',
  UsualAndCustomary = '07',
  _340B = '08',
  Other = '09',
  AverageSalesPrice = '10',
  AverageManufacturerPrice = '11',
  WholesaleAquisitionCost = '12',
  SpecialPatientPricing = '13',
  UnreportableQuantities = '14',
  FreeProduct = '15',
}

// 466-EZ Prescriber ID Qualifier
// 579-XX Associated Prescription / Service Provider Id Qualifier
// 468-2E Primary Care Provider ID Qualifier
// 202-B2 Service Provider ID Qualifier
export enum ProviderIdentificationCodes {
  NPI = '01',
  BlueCross = '02',
  BlueShield = '03',
  Medicare = '04',
  Medicaid = '05',
  UPIN = '06',
  // NCPDPID = '07', // Deprecated
  StateLicense = '08',
  TriCare = '09',
  HIN = '10',
  FederalTaxId = '11',
  DEA = '12',
  StateIssued = '13',
  PlanSpecific = '14',
  HCIDea = '15',
  ForeignPrescriberId = '17',
  NoPrescriberRequired = '18',
  Other = '99',
}

// 306-C6 Patient Relationship Code
export enum PatientRelationship {
  NotSpecified = 0,
  Cardholder = 1,
  Spouse = 2,
  Child = 3,
  Other = 4,
  Student = 5,
  Disabled = 6,
  AdultDependent = 7,
  SignificantOther = 8,
}

// 429-DT Special Packaging Indicator
export enum SpecialPackaging {
  NotSpecified = 0,
  NoSpecialPackaging = 1,
  ManufacturerUnitDose = 2,
  PharmacyUnitDose = 3,
  PharmacyUnitDosePatientCompliant = 4,
  PharmacyMultiDrugPatientCompliant = 5,
  RemoteDeviceUnitDose = 6,
  RemoteDeviceMultiDrug = 7,
  ManufacturerUnitOfUsePackage = 8,
}

// 600-28 Unit of Measure
export enum UnitOfMeasure {
  Each = 'EA',
  Grams = 'GM',
  MilliLiters = 'ML',
}

// 308-C8 Other Coverage Code
export enum OtherCoverage {
  NotSpecifiedByPatient = 0,
  NoOtherCoverage = 1,
  OtherCoverageExistsAndPaymentCollected = 2,
  OtherCoverageExistsBilledAndRejected = 3,
  OtherCoverageExistsPaymentNotCollected = 4,
  ManagedCarePlanDenial = 5,
  OtherCoverageDeniedProviderNotParticipating = 6,
  OtherCoverageExistsNotInEffectOnDOS = 7,
  PatientFinancialResponsibilityOnly = 8,
}

// 440-E5
export enum ProfessionalServiceCode {
  NoIntervention = '00',
  PatientAssessment = 'AS',
  CoordinationOfCare = 'CC',
  DosingEvaluationDetermination = 'DE',
  DosageEvaluated = 'DP',
  FormularyEnforcement = 'FE',
  GenericProductSelection = 'GP',
  PrescriberConsulted = 'M0',
  MedicationAdministration = 'MA',
  OverridingBenefit = 'MB',
  PatientWillBePresciberMonitored = 'MP',
  MedicationReview = 'MR',
  PreviousPatientTolerance = 'PA',
  PatientEducation = 'PE',
  PatientMedicationHistory = 'PH',
  PatientMonitoring = 'PM',
  PatientConsulted = 'P0',
  PerformLaboratoryTest = 'PT',
  PharmacistConsultedOtherSource = 'R0',
  RecommendedLaboratoryTest = 'PT',
  SelfCareConsultation = 'SC',
  LiteratureSearchReview = 'SW',
  PayerProcessorConsulted = 'TC',
  TherapeuticProductInterchange = 'TH',
  OtherAcknowledgement = 'ZZ',
}

export enum EligibilityBin {
  Commercial = '014765',
  Government = '012361',
}

export enum EligibilityPCN {
  Commercial = 'COM',
  Government = 'ABINQUIRY',
}

export enum EligibilitySoftwareVendorId {
  Commercial = 'VD0TTP',
  Government = 'ABELIG',
}

export enum IntermediaryAuthorization {
  TypeId = '99',
}

export enum LocationNPI {
  Hayward = '1295182590',
  Brooklyn = '1851947139',
}
