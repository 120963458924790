import { useEffect } from 'react'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { Grid, GridItem, Header } from '@truepill/react-capsule'
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg'
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg'
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg'
import { ActionButtonOutline } from 'components/ActionButton'
import BreadCrumb from 'components/BreadCrumb'
import IconWrapper, { LargeIconWrapper } from 'components/IconWrapper'
import { CapsulePageContainer, CapsulePageHeading, CapsuleTitle, PageContent } from 'components/PageStructure'
import type { TableDefinition } from 'components/Table'
import Table from 'components/Table'
import { GET_SIG_CODE, UPDATE_SIG_CODE } from 'gql'
import useErrorToast from 'hooks/toast/useErrorToast'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import { useFormData } from 'hooks/useFormData'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import styled from 'styled-components'
import { capsulePrimaryColorDark } from 'styles/styleVariables'
import type { ExceptionNDC } from 'types'
import AddExceptionNDCModal from './components/AddExceptionNDCModal'
import SigCodeForm from './components/SigCodeForm'

interface TableRow {
  sigCode?: string
  ndc: string
  multiplier?: number
  medicationName: string
}

interface EditActionButtonProps {
  data: TableRow
}

const RemoveActionButton = ({ data }: EditActionButtonProps) => {
  const showErrorToast = useErrorToast()
  const showSuccessToast = useSuccessToast()
  const { data: sigCodeData } = useQuery(GET_SIG_CODE, {
    variables: {
      code: data?.sigCode,
    },
  })
  const [updateSigCode] = useMutation(UPDATE_SIG_CODE, {
    refetchQueries: ['getSigCode'],
    onError: () => {
      showErrorToast('An error ocurred while trying to remove the Exception NDC.')
    },
    onCompleted: () => {
      showSuccessToast('Removed successfully.')
    },
  })

  const removeExceptionNDC = () => {
    const ndcs = sigCodeData?.getSigCode?.exceptionNDC || []
    const newNdcs = ndcs
      .filter((ndc: ExceptionNDC) => ndc.ndc !== data?.ndc)
      .map((ndc: ExceptionNDC) => ({
        ndc: ndc.ndc,
        medicationName: ndc.medicationName,
        multiplier: ndc.multiplier,
      }))

    updateSigCode({
      variables: {
        id: sigCodeData?.getSigCode?._id,
        patch: {
          exceptionNDC: newNdcs,
        },
      },
    })
  }

  return (
    <LargeIconWrapper>
      <DeleteIcon
        style={{ cursor: 'pointer' }}
        fill={capsulePrimaryColorDark}
        onClick={() => {
          window.confirm('Are you sure you want to remove this Exception NDC?') && removeExceptionNDC()
        }}
      />
    </LargeIconWrapper>
  )
}

const EditActionButton = ({ data }: EditActionButtonProps) => {
  const { showModal } = useModalContext()
  return (
    <LargeIconWrapper>
      <EditIcon
        style={{ cursor: 'pointer' }}
        fill={capsulePrimaryColorDark}
        onClick={() => {
          showModal(() => <AddExceptionNDCModal sigCode={data?.sigCode} editNDC={data} />)
        }}
      />
    </LargeIconWrapper>
  )
}
const tableDataDefinition: TableDefinition<TableRow>[] = [
  { field: 'ndc', headerName: 'NDC', width: '1fr', minWidth: '12rem' },
  { field: 'multiplier', headerName: 'Day supply multiplier', width: '2fr', minWidth: '30rem' },
  { field: 'medicationName', headerName: 'Medication', width: '2fr', minWidth: '30rem' },
  {
    headerName: 'Actions',
    minWidth: '4rem',
    width: '4rem',
    customRender: (data: TableRow) => (
      <Grid>
        <GridItem>{<EditActionButton data={data} />}</GridItem>
        <GridItem>{<RemoveActionButton data={data} />}</GridItem>
      </Grid>
    ),
  },
]
const SigCodesDetails = ({ sigCode }: { sigCode: string }): JSX.Element => {
  const { showModal } = useModalContext()
  const [getSigCode, { data, loading }] = useLazyQuery(GET_SIG_CODE)
  const [updateSigCode, { data: updateData, loading: updateLoading, error: updateError }] = useMutation(UPDATE_SIG_CODE)

  const showSuccessToast = useSuccessToast()
  const showErrorToast = useErrorToast()

  const {
    actions: { updateFormData },
  } = useFormData()

  useEffect(() => {
    getSigCode({
      variables: {
        code: sigCode,
      },
    })
  }, [getSigCode, sigCode])

  useEffect(() => {
    if (!data?.getSigCode) return

    updateFormData({
      sigCode: {
        $set: {
          code: data?.getSigCode?.code,
          translations: data?.getSigCode?.translations,
          multiplier: data?.getSigCode?.multiplier,
        },
      },
    })
  }, [data?.getSigCode, updateFormData])

  useEffect(() => {
    if (updateData?.updateSigCode) {
      showSuccessToast('Sig code edited successfully.')
    }
  }, [updateData?.updateSigCode, showSuccessToast])

  useEffect(() => {
    if (updateError) {
      showErrorToast('Sig code edited successfully.')
    }
  }, [showErrorToast, updateError])

  if (loading) return <div>Loading ...</div>

  return (
    <CapsulePageContainer>
      <BreadCrumb useCapsuleDesign />
      <CapsulePageHeading>
        <CapsuleTitle>
          Manage Sig code
          <SubHeader>Edit sig codes, descriptions, day supply multiplier, and manage NDC.</SubHeader>
        </CapsuleTitle>
      </CapsulePageHeading>
      <div>
        <SigCodeForm
          buttonText="Save"
          isLoading={updateLoading}
          onSubmit={(sigCode: string, englishDirections: string, spanishTranslation?: string, multiplier?: number) => {
            updateSigCode({
              variables: {
                id: data?.getSigCode?._id,
                patch: {
                  code: sigCode,
                  enTranslation: englishDirections,
                  esTranslation: spanishTranslation,
                  multiplier,
                },
              },
            })
          }}
        />

        <Header css={{}} variant="xl" as="h2">
          Exception NDC
        </Header>

        <PageContent>
          <TableWrapper>
            <Table
              rowStyle={{ alignItems: 'center' }}
              definition={tableDataDefinition}
              data={
                data?.getSigCode?.exceptionNDC?.map((ndc: ExceptionNDC) => ({
                  ...ndc,
                  sigCode: data?.getSigCode?.code,
                })) || []
              }
              loading={loading}
              keyField="ndc"
            />
          </TableWrapper>
        </PageContent>

        <PageContent>
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <ActionButtonOutline
              icon={
                <IconWrapper>
                  <PlusIcon />
                </IconWrapper>
              }
              hotKey="n"
              label="Add Exception NDC"
              onClick={() => {
                showModal(() => <AddExceptionNDCModal sigCode={data?.getSigCode?.code} />)
              }}
            />
          </div>
        </PageContent>
      </div>
    </CapsulePageContainer>
  )
}

const TableWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
`

const SubHeader = styled.p`
  font-size: var(--cap-fontSizes-md);
  font-weight: normal;
  color: var(--cap-colors-typography-medium);
`

export default SigCodesDetails
