import { LinkToCopay, LinkToCopayFill, LinkToOrder, LinkToOrderFill, LinkToPrescription } from 'components/InternalLink'
import type { Log } from 'types'
import type { LozengeText } from './types'

const noteToTokenLink = {
  Prescription: (note: Partial<Log>) =>
    note.prescription?.rxNumber &&
    note.prescriptionId && (
      <LinkToPrescription prescriptionId={note.prescriptionId} label={`${note.prescription.rxNumber}`} />
    ),
  Order: (note: Partial<Log>) =>
    note.order?.coreOrderToken &&
    note.orderId && <LinkToOrder orderId={note.orderId} label={note.order.coreOrderToken} />,
  Copay: (note: Partial<Log>) =>
    note.copayRequest?.coreCopayToken &&
    note.copayRequestId && <LinkToCopay copayId={note.copayRequestId} label={note.copayRequest.coreCopayToken} />,
  Fill: (note: Partial<Log>) =>
    note.copayRequest?.coreCopayToken && note.copayRequestId && note.fillId ? (
      <LinkToCopayFill copayId={note.copayRequestId} fillId={note.fillId} label={note.copayRequest.coreCopayToken} />
    ) : (
      note.order?.coreOrderToken &&
      note.orderId &&
      note.fillId && <LinkToOrderFill orderId={note.orderId} fillId={note.fillId} label={note.order.coreOrderToken} />
    ),
  'Order-Fill': (note: Partial<Log>) =>
    note.order?.coreOrderToken &&
    note.orderId &&
    note.fillId && <LinkToOrderFill orderId={note.orderId} fillId={note.fillId} label={note.order.coreOrderToken} />,
  'Copay-Fill': (note: Partial<Log>) =>
    note.copayRequest?.coreCopayToken &&
    note.copayRequestId &&
    note.fillId && (
      <LinkToCopayFill copayId={note.copayRequestId} fillId={note.fillId} label={note.copayRequest.coreCopayToken} />
    ),
}

const getTokenLink = (noteLozenge: LozengeText, note: Partial<Log>) => {
  if (noteLozenge) {
    return noteLozenge in noteToTokenLink ? (
      noteToTokenLink[noteLozenge as keyof typeof noteToTokenLink](note) || <></>
    ) : (
      <></>
    )
  }

  return <></>
}

const TokenLink = ({ noteLozenge, note }: { noteLozenge: LozengeText; note: Partial<Log> }) => {
  return getTokenLink(noteLozenge, note)
}

export default TokenLink
