import { useQuery } from '@truepill/tpos-react-router'
import { GET_DOCUMENTS } from 'gql'
import type { AttachmentsOptions, TPOSDocument } from 'types'
import AttachmentElements from './AttachmentElements'

const AttachmentsList = ({
  orderId,
  fillId,
  prescriptionId,
  patientId,
  hideArchivedNotes = true,
}: AttachmentsOptions): JSX.Element => {
  const { data, error, loading } = useQuery<{ getDocuments: TPOSDocument[] }>(GET_DOCUMENTS, {
    skip: !orderId && !fillId && !prescriptionId && !patientId,
    variables: {
      orderId,
      fillId,
      prescriptionId,
      patientId,
    },
  })

  if (!orderId && !fillId && !prescriptionId && !patientId) return <p>An error happened. Please try again</p>

  return (
    <AttachmentElements
      data={data?.getDocuments || []}
      loading={loading}
      error={error}
      hideArchivedNotes={hideArchivedNotes}
    />
  )
}

export default AttachmentsList
