import { useLocation } from '@truepill/tpos-react-router'
import FormattedFillDate from 'components/FormattedFillDate'
import moment from 'moment'
import { goToViewPharmacyPrescription } from 'routes'
import type { Prescription } from 'types'
import { getCurrentFill } from 'utils'
import { getDisplayedDrugName } from 'utils/getDisplayedDrugName'
import { twoDecimalOrLess } from 'utils/numberFormatter'
import {
  PrescriptionRow,
  RXNumber,
  Customer,
  Fill,
  Medication,
  Quantity,
  DaysSupply,
  Directions,
  RemainingQuantity,
  RemainingRefills,
  Written,
  FillDate,
  PrescriberRX,
  StyledLink,
} from './StyledComponents'

type PrescriptionEntryProps = {
  prescription: Prescription
}

const PrescriptionEntry = ({ prescription }: PrescriptionEntryProps): JSX.Element => {
  const { search } = useLocation()
  const currentFill = getCurrentFill(prescription)

  const NoneProvidedIcon = <>&mdash;</>
  const displayedDrugName = getDisplayedDrugName(
    prescription.name,
    prescription.strength?.value,
    prescription.strength?.unit,
    prescription.strength?.form,
  )

  const content = (
    <PrescriptionRow>
      <RXNumber>{prescription.rxNumber}</RXNumber>
      <Customer>{prescription.customer?.name ?? NoneProvidedIcon}</Customer>
      <Fill>{currentFill?.fillNumber ?? 0}</Fill>
      <Medication>{displayedDrugName}</Medication>
      <Quantity>{prescription.quantity === '.' ? '.' : twoDecimalOrLess(prescription.quantity)}</Quantity>
      <DaysSupply>{prescription.daysSupply || NoneProvidedIcon}</DaysSupply>
      <Directions>{prescription.directions}</Directions>
      <RemainingQuantity>{twoDecimalOrLess(prescription.quantityRemaining) ?? 0}</RemainingQuantity>
      <RemainingRefills>{prescription.refillsRemaining ?? 0}</RemainingRefills>
      <Written>{moment(prescription.writtenDate).format('MM/DD/YYYY')}</Written>
      <FillDate>
        <FormattedFillDate fill={currentFill} />
      </FillDate>
      <PrescriberRX>
        {prescription.prescriber.firstName} {prescription.prescriber.lastName}
      </PrescriberRX>
    </PrescriptionRow>
  )

  return (
    <StyledLink to={goToViewPharmacyPrescription({ prescriptionId: prescription._id, search })}>{content}</StyledLink>
  )
}

export default PrescriptionEntry
