export enum ParataRequestType {
  FillScript = 'FillScript',
  EditScript = 'EditScript',
  CancelScript = 'CancelScript',
  HoldScript = 'HoldScript',
  FillOrder = 'FillOrder',
  CancelOrder = 'CancelOrder',
  GetScriptStatus = 'GetScriptStatus',
  GetAllNDCStatus = 'GetAllNdcStatus',
  UnlockCell = 'UnlockCell',
}
