import { useCallback, useEffect, useState } from 'react'
import { Grid, GridItem, Text, Tooltip } from '@truepill/react-capsule'
import { Link, useMutation } from '@truepill/tpos-react-router'
import { UserRoles } from '@truepill/tpos-types'
import { ReactComponent as CancelIcon } from 'assets/icons/capsule/circle-cancel.svg'
import { ReactComponent as ErrorIcon } from 'assets/icons/capsule/circle-error.svg'
import { ReactComponent as ConfirmIcon } from 'assets/icons/capsule/confirmed-user.svg'
import { ReactComponent as EditIcon } from 'assets/icons/capsule/edit.svg'
import { ReactComponent as LockIcon } from 'assets/icons/capsule/lock.svg'
import { IconOnlyButton, LargeIconWrapper } from 'components/IconWrapper'
import Pagination from 'components/Pagination'
import Table from 'components/Table'
import type { TableDefinition } from 'components/Table'
import TableChip from 'components/TableChip'
import { RESET_USER_PASSWORD, UPDATE_USER } from 'gql'
import type { PaginatedUsers } from 'hooks/useUsers'
import SimpleModal from 'modals/SimpleModal'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import { usePlusClient } from 'providers/VisionRouter'
import { goToUserProfile } from 'routes'
import styled from 'styled-components'
import {
  capsuleDarkGreenColor,
  capsuleLightGreenColor,
  capsuleDarkGrayColor,
  primaryBackgroundColor,
  capsuleDarkBlueColor,
  capsuleLightBlueColor,
  capsuleLightRedColor,
} from 'styles/styleVariables'
import type { User } from 'types'
import { formatDate, getDiffDaysBetweenDates } from 'utils/dates'

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`

const ItemContainer = styled.div`
  display: inline-block;
  margin-right: 10px;
`

const ErrorIconWrapper = styled(LargeIconWrapper)`
  background-color: ${capsuleLightRedColor};
  border-radius: 1rem;
  height: 1.6rem;
  width: 2rem;
  margin-left: 0.5rem;
`

type FecthMore = (offset: number, limit: number) => void
interface UserTableProps {
  paginatedUsers?: PaginatedUsers
  fetchMore?: FecthMore
  loading?: boolean
  pagination?: boolean
}

interface UserTableActionInterface {
  user: User
}

interface TableRow extends User {
  createdDate: string
}

interface PaginationInfo {
  usersToShow: User[]
  currentPage: number
  total: number
  totalPages: number
  pageSize: number
  setPage: (pageNumber: number) => void
  setLimit: (limitNumber: number) => void
}

enum UserStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

enum LicenseStatus {
  ACTIVE = 'active',
  ABOUT_TO_EXPIRE = 'about_to_expire',
}

const statusIndicatorConfig = {
  [UserStatus.ACTIVE]: {
    colors: { main: capsuleDarkGreenColor, background: capsuleLightGreenColor },
    label: 'Active',
  },
  [UserStatus.INACTIVE]: {
    colors: { main: primaryBackgroundColor, background: capsuleDarkGrayColor },
    label: 'Deactivated',
  },
}

const activationConfig = {
  [UserStatus.ACTIVE]: {
    verb: 'Deactivate',
    title: 'Deactivate team member',
    icon: CancelIcon,
  },
  [UserStatus.INACTIVE]: {
    verb: 'Reactivate',
    title: 'Reactivate team member',
    icon: ConfirmIcon,
  },
}

const aboutToExpireLicenseDays = 30

const getVariant = (disabled: boolean) => (disabled ? UserStatus.INACTIVE : UserStatus.ACTIVE)

const parseRows = (users: User[]): TableRow[] =>
  users.map(user => {
    const row = { ...user, createdDate: formatDate(user.createdAt) } as TableRow
    return row
  })

const getActiveLicenses = ({ licenses }: TableRow) => {
  return licenses?.filter(license => getDiffDaysBetweenDates(new Date(), license.expirationDate) >= 1) ?? []
}

const useUsersPaginationInfo = (fetchMore?: FecthMore, paginatedUers?: PaginatedUsers): PaginationInfo => {
  const { total, users } = paginatedUers ?? { users: [], total: 0 }
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(10)

  useEffect(() => {
    const newOffset = page * limit
    if (fetchMore) {
      fetchMore(newOffset, limit)
    }
  }, [fetchMore, page, limit])

  const changePage = useCallback((page: number) => {
    setPage(page)
  }, [])

  const totalPages = Math.ceil(total / limit)

  return {
    usersToShow: users,
    currentPage: page,
    total,
    totalPages,
    pageSize: limit,
    setPage: changePage,
    setLimit,
  }
}

const RolesColumn = ({ roles }: TableRow) => (
  <>
    {roles.map((role, index) => (
      <TableChip key={index} label={role} colors={{ background: capsuleLightBlueColor, main: capsuleDarkBlueColor }} />
    ))}
  </>
)

const LicensesColumn = (row: TableRow) => {
  const licenses = getActiveLicenses(row)
  return (
    <>
      {licenses.length
        ? licenses.map((license, index) => {
            const diff = getDiffDaysBetweenDates(new Date(), license.expirationDate)
            const variant = diff <= aboutToExpireLicenseDays ? LicenseStatus.ABOUT_TO_EXPIRE : LicenseStatus.ACTIVE
            return (
              <ItemContainer key={index}>
                <FlexContainer>
                  <Text>{license.registeredState ?? 'N/A'}</Text>
                  {LicenseStatus.ABOUT_TO_EXPIRE === variant && (
                    <Tooltip label="License about to expire" variant="white">
                      <ErrorIconWrapper>
                        <ErrorIcon />
                      </ErrorIconWrapper>
                    </Tooltip>
                  )}
                  {index + 1 < licenses.length && <Text>&#44;</Text>}
                </FlexContainer>
              </ItemContainer>
            )
          })
        : '-'}
    </>
  )
}

const StatusColumn = ({ disabled }: TableRow) => {
  const variant = getVariant(disabled)
  const config = statusIndicatorConfig[variant]
  return <TableChip colors={config.colors} label={config.label} />
}

const SeeUserButton = ({ user }: UserTableActionInterface) => {
  const { tokenContext } = usePlusClient()
  const { hasRole } = tokenContext
  const isUserAdmin = hasRole && hasRole([UserRoles.Admin])

  if (!isUserAdmin)
    return (
      <LargeIconWrapper desaturate opacity={0.2}>
        <EditIcon />
      </LargeIconWrapper>
    )

  return (
    <Link to={goToUserProfile({ userId: user._id })} title="View profile">
      <LargeIconWrapper>
        <EditIcon />
      </LargeIconWrapper>
    </Link>
  )
}

const ActivationButton = ({ user }: UserTableActionInterface) => {
  const { showModal } = useModalContext()
  const { _id: id, disabled } = user
  const variant = getVariant(disabled)
  const config = activationConfig[variant]

  const [updateUser] = useMutation(UPDATE_USER, {
    refetchQueries: ['getUsers'],
  })

  return (
    <IconOnlyButton
      title={config.title}
      onClick={() =>
        showModal(() => (
          <SimpleModal
            title={config.title}
            confirmationCallback={() => {
              updateUser({
                variables: {
                  id,
                  disabled: !disabled,
                },
              })
            }}
            confirmButtonLabel={config.verb}
          >
            <Text>
              Are you sure you want to {config.verb}&nbsp;
              <strong>
                {user.firstName} {user.lastName}
              </strong>
              ?
            </Text>
          </SimpleModal>
        ))
      }
    >
      <LargeIconWrapper>
        <config.icon />
      </LargeIconWrapper>
    </IconOnlyButton>
  )
}

const ResetPasswordButton = ({ user }: UserTableActionInterface) => {
  const { showModal } = useModalContext()
  const [resetPassword] = useMutation(RESET_USER_PASSWORD)

  return (
    <IconOnlyButton
      title={'Reset Password'}
      onClick={() =>
        showModal(() => (
          <SimpleModal
            title={'Reset password'}
            confirmationCallback={() => {
              ;(async () => {
                const resp = await resetPassword({
                  variables: {
                    username: user.username,
                  },
                })
                const data = resp.data.resetPassword
                showModal(() => (
                  <SimpleModal title={'Reset Password Confirmation'} hideCancel>
                    <Text>
                      A temporary password: <strong>{data.resetPassword}</strong> for the user&nbsp;
                      <strong>{user.username}</strong> has been created.
                    </Text>
                  </SimpleModal>
                ))
              })()
            }}
            confirmButtonLabel={'reset'}
          >
            <Text>
              Are you sure you want to reset the password for&nbsp;
              <strong>
                {user.firstName} {user.lastName}
              </strong>
              ?
            </Text>
          </SimpleModal>
        ))
      }
    >
      <LargeIconWrapper>
        <LockIcon />
      </LargeIconWrapper>
    </IconOnlyButton>
  )
}

const ActionsColumn = (user: User) => (
  <Grid>
    <GridItem>
      <SeeUserButton user={user} />
    </GridItem>
    <GridItem>
      <ResetPasswordButton user={user} />
    </GridItem>
    <GridItem>
      <ActivationButton user={user} />
    </GridItem>
  </Grid>
)

const tableDefinition: TableDefinition<TableRow>[] = [
  { field: 'lastName', headerName: 'Last Name', width: '1.2fr', minWidth: '10rem' },
  { field: 'firstName', headerName: 'First Name', width: '1.2fr', minWidth: '10rem' },
  { headerName: 'Role', width: '1fr', minWidth: '10rem', customRender: RolesColumn },
  { field: 'email', headerName: 'Email', width: '1.6fr', minWidth: '14rem' },
  {
    headerName: 'Active Licenses',
    width: '1fr',
    minWidth: '8rem',
    customRender: LicensesColumn,
  },
  { field: 'createdDate', headerName: 'Created At', width: '1fr', minWidth: '6em' },
  { headerName: 'Status', width: '0.7fr', minWidth: '5.5em', customRender: StatusColumn },
  { headerName: 'Actions', width: '0.6fr', minWidth: '5.5em', customRender: ActionsColumn },
]

const UsersTable = ({ fetchMore, loading, paginatedUsers, pagination = true }: UserTableProps): JSX.Element => {
  const { usersToShow, currentPage, setPage, total, totalPages, pageSize, setLimit } = useUsersPaginationInfo(
    fetchMore,
    paginatedUsers,
  )

  return (
    <>
      <Table data={parseRows(usersToShow)} keyField="_id" loading={loading} definition={tableDefinition} />
      {pagination && (
        <Pagination
          onChangePage={setPage}
          totalRecords={total}
          currentPage={currentPage}
          totalPages={totalPages}
          rowsPerPage={pageSize}
          onChangeRowsPerPage={setLimit}
        />
      )}
    </>
  )
}

export default UsersTable
