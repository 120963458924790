import { useMemo } from 'react'
import type { DeepPartial } from 'ts-essentials'
import type { Patient } from 'types'
import { validatePatientForm } from 'utils'

type usePatientFormValidationReturn = { isValid: boolean; errors: Record<string, string> }

const usePatientFormValidation = (patient: DeepPartial<Patient>): usePatientFormValidationReturn => {
  const validation = useMemo(() => {
    return validatePatientForm(patient)
  }, [patient])
  return validation
}

export default usePatientFormValidation
