import { FormPage } from 'components/ColumnForm'
import { ActionButtonContainer } from 'components/PageStructure'
import styled from 'styled-components'

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 1285px;
  margin: 2rem 2rem 0.8rem;
`

const Title = styled.h1`
  font-size: 24px;
  width: 100%;
`

const ExpandedActionButtonContainer = styled(ActionButtonContainer)`
  margin-left: auto;
`

const StyledFormPage = styled(FormPage)`
  grid-template-columns: [form] 0.1fr [dur] 0.1fr [insurance] 0.1fr;
  grid-gap: 1.25rem;
`

const DurColumn = styled.ul`
  width: 26rem;
  grid-column: dur;
`

const InsuranceColumn = styled.ul`
  width: 26rem;
  grid-column: insurance;
`

const DemographicColumn = styled.ul`
  width: 26rem;
  grid-column: form;
`

const LoadingSpinnerContainer = styled.div`
  display: flex;
  padding-top: 12rem;
  justify-content: center;
  svg {
    height: 126px;
  }
`

export {
  TitleRow,
  Title,
  ExpandedActionButtonContainer,
  StyledFormPage,
  DurColumn,
  InsuranceColumn,
  DemographicColumn,
  LoadingSpinnerContainer,
}
