import type { FillData } from '@truepill/tpos-types'
import { FillStatus } from '@truepill/tpos-types'

type FillVerifications = FillData['verifications']
/**
 * Returns verification from last completed fill having verifications
 * @param fills ordered list of FillVerifications
 * @returns verification from last completed fill having verifications
 */
export const getLastFillVerifications = (
  fills: Pick<FillData, 'status' | 'verifications'>[],
): FillVerifications | undefined => {
  for (let i = fills.length - 1; i >= 0; i--) {
    if (fills[i].status === FillStatus.Complete && fills[i].verifications?.pv1) {
      return fills[i].verifications
    }
  }
}
