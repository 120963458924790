import React from 'react'
import { TextField } from '@truepill/react-capsule'

// As of August 2023, Operations asked us to remove the autocomplete HTML attribute from all text fields,
// for more information, please see ticket JR-13755

type TextFieldType = typeof TextField
type PropsTposTextField = React.ComponentProps<typeof TextField>

const CustomizedTextField = React.forwardRef((props: PropsTposTextField, ref): JSX.Element => {
  const { autoComplete = 'off', ...rest } = props

  return <TextField autoComplete={autoComplete} {...rest} ref={ref}></TextField>
}) as TextFieldType

export default CustomizedTextField
