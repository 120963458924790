import { useQuery } from '@truepill/tpos-react-router'
import AddressEntry from 'components/AddressEntry'
import DURSubjectsList from 'components/DURSubjectsList'
import LoadingSpinner from 'components/Loading'
import { FilledHeader } from 'components/PageStructure'
import { TwoColumnDivision, RXCenterCell, ListRowLabel, ListRowValue } from 'components/RXTable'
import { getFragmentData, gql } from 'gql/generated'
import moment from 'moment'
import styled, { css } from 'styled-components'
import { contrastBackgroundColor } from 'styles/styleVariables'
import type { Patient } from 'types'
import { formatPhoneNumber, capitalize, checkIfProvided } from 'utils'

const DemographicInfo_PatientFragment = gql(`
  fragment DemographicInfo_PatientFragment on Patient {
    _id
    firstName
    lastName
    dob
    gender
    contacts {
      email
      phone
    }
    address {
      home {
        ...AddressEntry_AddressFragment
      }
    }
    medicalHistory {
      allergies {
        idField
        value
        type
        name
      }
      medications {
        idField
        value
        type
        name
      }
      conditions {
        idField
        value
        type
        name
      }
    }
  }
`)

const GET_PATIENT_QUERY = gql(`
  query getPatientDemographicInfo($patientId: ID!) {
    getPatient(_id: $patientId) {
      ...DemographicInfo_PatientFragment
    }
  }
`)

type DemographicInfoProps = { patientId: Patient['_id']; className?: string }

const DemographicInfo = ({ className, patientId }: DemographicInfoProps): JSX.Element => {
  const { data, loading, error } = useQuery(GET_PATIENT_QUERY, {
    variables: { patientId },
  })

  if (error) {
    return (
      <DemographicInfoContainer className={className}>
        <p>Error loading Demographic Information for patient {JSON.stringify(error)}</p>
      </DemographicInfoContainer>
    )
  }

  if (loading || !data?.getPatient) {
    return (
      <DemographicInfoContainer className={className}>
        <LoadingSpinnerContainer>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      </DemographicInfoContainer>
    )
  }

  const patient = getFragmentData(DemographicInfo_PatientFragment, data.getPatient)
  const patientDOB = moment(patient.dob).format('MM/DD/YYYY')
  const patientGender = capitalize(patient.gender ?? '')

  return (
    <DemographicInfoContainer className={className}>
      <Columns>
        <PatientColumn>
          <FilledHeader>Patient</FilledHeader>
          <PatientCell>
            <StyledListRowLabel>Name:</StyledListRowLabel>
            <StyledListRowValue>
              {patient.firstName} {patient.lastName}
            </StyledListRowValue>
          </PatientCell>
          <PatientCell>
            <StyledListRowLabel>DOB:</StyledListRowLabel>
            <StyledListRowValue>{patientDOB}</StyledListRowValue>
          </PatientCell>
          <PatientCell>
            <StyledListRowLabel>Gender:</StyledListRowLabel>
            <StyledListRowValue>{patientGender}</StyledListRowValue>
          </PatientCell>
          <PatientCell>
            <StyledListRowLabel>Address:</StyledListRowLabel>
            <StyledListRowValue>
              {patient.address && <AddressEntry address={patient.address.home} />}
            </StyledListRowValue>
          </PatientCell>
          <PatientCell>
            <StyledListRowLabel>Phone:</StyledListRowLabel>
            <StyledListRowValue>{checkIfProvided(formatPhoneNumber(patient.contacts?.phone))}</StyledListRowValue>
          </PatientCell>
          <PatientCell>
            <StyledListRowLabel>Email:</StyledListRowLabel>
            <StyledListRowValue>{checkIfProvided(patient.contacts?.email)}</StyledListRowValue>
          </PatientCell>
        </PatientColumn>
        <SafetyColumn>
          <FilledHeader>Safety check</FilledHeader>
          <SafetyCell>
            <ListRowLabel>Allergies:</ListRowLabel>
            <StyledListRowValue>
              {patient.medicalHistory && patient.medicalHistory?.allergies?.length ? (
                <DURSubjectsList subjects={patient.medicalHistory?.allergies ?? []} />
              ) : (
                <>None provided</>
              )}
            </StyledListRowValue>
          </SafetyCell>
          <SafetyCell>
            <ListRowLabel>Medications:</ListRowLabel>
            <StyledListRowValue>
              {patient.medicalHistory && patient.medicalHistory?.medications?.length !== 0 ? (
                <DURSubjectsList subjects={patient.medicalHistory.medications ?? []} />
              ) : (
                <>None provided</>
              )}
            </StyledListRowValue>
          </SafetyCell>
          <SafetyCell>
            <ListRowLabel>Conditions:</ListRowLabel>
            <StyledListRowValue>
              {patient.medicalHistory && patient.medicalHistory?.conditions?.length !== 0 ? (
                <DURSubjectsList subjects={patient.medicalHistory.conditions ?? []} />
              ) : (
                <>None provided</>
              )}
            </StyledListRowValue>
          </SafetyCell>
        </SafetyColumn>
      </Columns>
    </DemographicInfoContainer>
  )
}

const DemographicCell = css`
  grid-template-columns: [label] 5rem [value] 1fr;
  padding-left: 0.625rem;
`

const PatientCell = styled(RXCenterCell)`
  ${DemographicCell}
`

const SafetyCell = styled(RXCenterCell)`
  ${DemographicCell}
`

const StyledListRowLabel = styled(ListRowLabel)`
  line-height: 1rem;
  font-size: 0.875rem;
  padding-top: 0.125rem;
`

const StyledListRowValue = styled(ListRowValue)`
  padding-left: 1.25rem;
`

const Columns = styled.div`
  ${TwoColumnDivision}
  grid-gap: 1.25rem;
`

const DemographicInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledColumn = styled.ul`
  width: 25rem;
  > :nth-child(2n) {
    background-color: ${contrastBackgroundColor};
  }
`

const PatientColumn = styled(StyledColumn)`
  grid-column: center;
`

const SafetyColumn = styled(StyledColumn)`
  grid-column: right;
`

const LoadingSpinnerContainer = styled.div`
  display: flex;
  padding-top: 4rem;
  justify-content: center;
  svg {
    height: 50px;
  }
`

export default DemographicInfo
