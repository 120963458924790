// Sometimes you still want a text string but only want numbers. Users find it
// less irritating
export const onlyNumbers = (input: string): number => {
  const numericString = input.replace(/[^0-9]/g, '')
  let val = parseInt(numericString)
  if (isNaN(val)) {
    val = 0
  }
  return val
}

// Annoyingly JS regex doesn't support greedy lookbehind so
// this is the cleanest way to perform this operation
export const limitPeriods = (input: string): string => {
  let periodFound = false
  let i = 0
  let output = ''
  while (i < input.length) {
    const char = input[i]
    if (char === '.') {
      if (!periodFound) {
        periodFound = true
        output += char
      }
    } else {
      output += char
    }
    i++
  }
  return output
}

export function twoDecimalOrLess(value?: number) {
  if (value === undefined) return undefined
  return Math.round(value * 100) / 100
}
