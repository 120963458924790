import type { NdcFullInfoPackage } from 'types'
import hyphenateNdc from './hyphenateNdc'
import { checkIfProvided } from './textFormatting'

export default function getNdcText(
  ndcPackage: NdcFullInfoPackage | undefined | null,
  tempDisplayPackSizeWithNdc: boolean,
  tempHyphenateNdc = false,
) {
  if (!ndcPackage) return 'None provided'

  const ndc = tempHyphenateNdc ? hyphenateNdc(ndcPackage.ndc) : ndcPackage.ndc

  if (!tempDisplayPackSizeWithNdc) return checkIfProvided(ndc)

  let packageSize
  if (ndcPackage.packageSize) {
    if (ndcPackage.innerpackCode === 'N' && ndcPackage.hasInnerPackSibling)
      packageSize = ndcPackage.packageSize?.toString() || ''
    else if (ndcPackage.packageQuantity) packageSize = (ndcPackage.packageSize * ndcPackage.packageQuantity).toString()
  }
  const packageSizeUnitOfMeasure = ndcPackage?.packageSizeUnitOfMeasure

  const displayPackSize =
    tempDisplayPackSizeWithNdc &&
    (ndcPackage.isUnbreakable || ndcPackage.hasInnerPackSibling) &&
    !!packageSize &&
    !!packageSizeUnitOfMeasure

  return displayPackSize ? `${ndc} (Package size: ${packageSize} ${packageSizeUnitOfMeasure})` : checkIfProvided(ndc)
}
