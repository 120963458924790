import { useContext } from 'react'
import { DURStatus } from '@truepill/tpos-types'
import DisclosureArrow from 'components/DisclosureArrow'
import { CheckBox } from 'grommet'
import DisclosureProvider, { DisclosureContext } from 'providers/DisclosureProvider'
import styled, { css, keyframes } from 'styled-components'
import { primaryBackgroundColor, capsuleDarkRedColor, darkOrange, capsuleDarkGreenColor } from 'styles/styleVariables'
import type { DUR } from 'types'
import mapTitleToDURTypes from 'utils/mapTitleToDURType'

const ManagementForDURStatus: { [key: string]: string } = {
  [DURStatus.NONE]: 'n/a',
  [DURStatus.MILD]: 'Use with MILD caution',
  [DURStatus.MODERATE]: 'Use with MODERATE caution',
  [DURStatus.SEVERE]: 'Use with SEVERE caution',
}

interface DURCardProps extends DUR {
  inline?: boolean
  selectCallback?: (checked: boolean) => void
  selected?: boolean
  showManagement?: boolean
  releasedBy?: string
  initialDisclosed?: boolean
}

const WrappedDURCard = (props: DURCardProps): JSX.Element => {
  return (
    <DisclosureProvider initialDisclosed={props.initialDisclosed || false}>
      <DURCard {...props} />
    </DisclosureProvider>
  )
}

const DURCard = (props: DURCardProps): JSX.Element => {
  const { firstLoad, disclosed, toggleDisclosure } = useContext(DisclosureContext)
  const { inline, selectCallback, selected, showManagement = true } = props

  const subjects = props.subjects.map(subject => subject.name)

  return (
    <StyledDURCard
      inline={inline}
      status={props.status}
      onClick={toggleDisclosure}
      data-testid={`DurCard-${props.status}`}
    >
      <Title>
        {selectCallback && (
          <CheckBox
            checked={selected}
            onChange={e => {
              const { checked } = e.target
              selectCallback(checked)
            }}
          />
        )}
        {mapTitleToDURTypes(props.type)} - {subjects.join(', ')}
      </Title>
      {!inline && (
        <>
          <StyledDisclosureArrow />
          {showManagement && <Management>{ManagementForDURStatus[props.status]}</Management>}
          <DisclosureBox show={disclosed} firstLoad={firstLoad}>
            <Directions>{props.directions}</Directions>
            {props.releasedBy && <ReleasedBy>Last released by: {props.releasedBy}</ReleasedBy>}
          </DisclosureBox>
        </>
      )}
    </StyledDURCard>
  )
}

const Title = styled.h3`
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5rem;
  display: flex;
  flex-direction: row;
  > :first-child {
    margin-right: 0.625rem;
  }
`

const Management = styled.p`
  font-size: 1rem;
  line-height: 1.5rem;
  :before {
    content: 'Management: ';
    font-weight: bold;
  }
`

const ShowAnimation = keyframes`
  0% {
    max-height: 0rem;
  }
  100% {
    max-height: 50rem;
  }
`

const HideAnimation = keyframes`
  0% {
    max-height: 500rem;
  }
  100% {
    max-height: 0rem;
  }
`

const Instant = css`
  animation-duration: 0ms;
`

const Show = css`
  animation: ${ShowAnimation} 400ms;
`

const Hide = css`
  animation: ${HideAnimation} 400ms;
`

const DisclosureBox = styled.div<{ firstLoad?: boolean; show?: boolean }>`
  overflow: hidden;
  ${({ show }) => (show ? Show : Hide)}
  ${({ firstLoad }) => firstLoad && Instant}
  animation-fill-mode: forwards;
  transition-timing-function: ease;
`

const Directions = styled.p`
  font-size: 1rem;
  margin-top: 0.625rem;
`

const ReleasedBy = styled.p`
  font-size: 1rem;
  margin-top: 0.625rem;
  font-style: italic;
`

const Moderate = css`
  border-left-color: ${darkOrange};
`

const Severe = css`
  border-left-color: ${capsuleDarkRedColor};
`

const Mild = css`
  border-left-color: ${capsuleDarkGreenColor};
`

const StyledDisclosureArrow = styled(DisclosureArrow)`
  position: absolute;
  top: 0.625rem;
  right: 0rem;
`

const StyledDURCard = styled.li<{ inline?: boolean; status: DURStatus }>`
  position: relative;
  display: flex;
  flex-direction: column;
  border-left: 0.5rem solid;
  border-right: 0.25rem solid;
  border-radius: 0.25rem;
  border-color: ${primaryBackgroundColor};
  background: ${primaryBackgroundColor};
  cursor: ${({ inline }) => (inline ? 'default' : 'pointer')};
  ${({ status }) => {
    switch (status) {
      case DURStatus.MODERATE:
        return Moderate
      case DURStatus.SEVERE:
        return Severe
      case DURStatus.MILD:
        return Mild
      default:
    }
  }}
  padding: 0.625rem;
`

export default WrappedDURCard
