import { Chip } from '@truepill/react-capsule'
import { useLocation } from '@truepill/tpos-react-router'
import { ReactComponent as PetIcon } from 'assets/icons/paw.svg'
import AddressEntry from 'components/AddressEntry'
import LoadingSpinner from 'components/Loading'
import type { TableDefinition } from 'components/Table'
import Table from 'components/Table'
import moment from 'moment'
import { goToViewPatient } from 'routes'
import styled from 'styled-components'
import { contrastColor } from 'styles/styleVariables'
import type { Address, Patient } from 'types'
import { capitalize, formatPhoneNumber } from 'utils'
import { isHuman } from 'utils/Patient'

type PatientsTableProps = {
  showGuardian?: boolean
  patients: Patient[]
  searchTermGiven?: boolean
  loading?: boolean
}

interface TableRow {
  _id: string
  lastName: string
  firstName: string
  dob: string
  gender: string
  email?: string
  token?: string
  address?: Address
  phone?: string
  createdAt: string
  isHuman: boolean
  guardian?: string
}

const SpeciesLogo = (props: { isHuman: boolean }) => {
  return !props.isHuman ? (
    <Chip>
      <PetIcon style={{ verticalAlign: 'bottom', margin: '0' }} />
    </Chip>
  ) : (
    <></>
  )
}

const tableDefinition: TableDefinition<TableRow>[] = [
  { field: 'isHuman', headerName: '', width: '1.5rem', minWidth: '2rem', customRender: SpeciesLogo },
  { field: 'lastName', headerName: 'Last Name', width: '1.2fr', minWidth: '4rem' },
  { field: 'firstName', headerName: 'First Name', width: '1.2fr', minWidth: '4rem' },
  { field: 'dob', headerName: 'Date of birth', width: '0.5fr', minWidth: '6rem' },
  { field: 'gender', headerName: 'Gender', width: '0.5fr' },
  { field: 'email', headerName: 'Email', width: '1fr', minWidth: '4em' },
  { field: 'token', headerName: 'Token', width: '1fr', minWidth: '4em' },
  { field: 'address', headerName: 'Address', width: '2fr', customRender: AddressEntry },
  { field: 'phone', headerName: 'Phone number', width: '1fr', minWidth: '6em' },
  { field: 'createdAt', headerName: 'Created', width: '0.7fr', minWidth: '5.5em' },
]

const tableDefinitionWithGuardian: TableDefinition<TableRow>[] = [
  { field: 'guardian', headerName: 'Guardian', width: '1fr', minWidth: '4rem' },
  { field: 'isHuman', headerName: '', width: '1.5rem', minWidth: '2rem', customRender: SpeciesLogo },
  { field: 'lastName', headerName: 'Last Name', width: '1fr', minWidth: '4rem' },
  { field: 'firstName', headerName: 'First Name', width: '1fr', minWidth: '4rem' },
  { field: 'dob', headerName: 'Date of birth', width: '1.5fr', minWidth: '2rem' },
  { field: 'gender', headerName: 'Gender', width: '0.5fr' },
  { field: 'email', headerName: 'Email', width: '1fr', minWidth: '4em' },
  { field: 'token', headerName: 'Token', width: '1fr', minWidth: '4em' },
  { field: 'address', headerName: 'Adress', width: '2fr', customRender: AddressEntry },
  { field: 'phone', headerName: 'Phone number', width: '1fr', minWidth: '6em' },
  { field: 'createdAt', headerName: 'Created', width: '0.7fr', minWidth: '5.5em' },
]

const parseRows = (patients: Patient[]): TableRow[] =>
  patients.map(patient => ({
    _id: patient._id,
    isHuman: isHuman(patient),
    lastName: patient.lastName,
    firstName: patient.firstName,
    dob: moment(patient.dob).format('MM/DD/YYYY'),
    gender: capitalize(patient.gender),
    email: patient.contacts?.email,
    token: patient.truepillPatientToken,
    address: patient.address?.home,
    phone: formatPhoneNumber(patient.contacts?.phone),
    createdAt: moment(patient.createdAt).format('MM/DD/YYYY'),
    guardian: patient.guardian,
  }))

const PatientsTable = ({
  searchTermGiven,
  patients = [],
  loading,
  showGuardian = false,
}: PatientsTableProps): JSX.Element => {
  const { search } = useLocation()
  const definition = showGuardian ? tableDefinitionWithGuardian : tableDefinition

  if (loading) {
    return (
      <PatientLoadingContainer>
        <LoadingSpinnerBox>
          <LoadingSpinner />
        </LoadingSpinnerBox>
      </PatientLoadingContainer>
    )
  }

  return (
    <Table
      data={parseRows(patients)}
      keyField="_id"
      loading={loading}
      definition={definition}
      rowLinkTo={(row: TableRow) => goToViewPatient({ patientId: row._id, search })}
    />
  )
}

const PatientContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  :last-child {
    border-bottom: 0.25rem solid ${contrastColor};
    border-radius: 0.25rem;
  }
  flex: 1;
`

const PatientLoadingContainer = styled(PatientContainer)`
  :last-child {
    border-bottom: none;
    border-radius: 0;
  }
`

const LoadingSpinnerBox = styled.div`
  display: flex;
  padding-top: 4rem;
  justify-content: center;
  svg {
    height: 126px;
  }
`

export default PatientsTable
