export const rejectCodes = {
  '1C': { appliesToFields: ['334-1C'], text: 'Missing/Invalid Smoker/Non-Smoker Code' },
  '1E': { appliesToFields: ['467'], text: 'Missing/Invalid Prescriber Location Code' },
  '1K': { appliesToFields: ['A43-1K'], text: 'Missing/Invalid Patient Country Code' },
  '1R': { appliesToFields: ['102-A2'], text: 'Version/Release Value Not Supported' },
  '1S': { appliesToFields: ['103-A3'], text: 'Transaction Code/Type Value Not Supported' },
  '1T': { appliesToFields: ['104-A4'], text: 'PCN Must Contain Processor/Payer Assigned Value' },
  '1U': { appliesToFields: ['109-A9'], text: 'Transaction Count Does Not Match Number of Transactions' },
  '1V': { appliesToFields: ['109-A9'], text: 'Multiple Transactions Not Supported' },
  '1W': { appliesToFields: ['109-A9'], text: 'Multi-Ingredient Compound Must Be A Single Transaction' },
  '1X': { appliesToFields: ['110-AK'], text: 'Vendor Not Certified For Processor/Payer' },
  '1Y': { appliesToFields: ['111-AM'], text: 'Claim Segment Required For Adjudication' },
  '1Z': { appliesToFields: ['111-AM'], text: 'Clinical Segment Required For Adjudication' },
  '2A': { appliesToFields: ['359-2A'], text: 'Missing/Invalid Medigap ID' },
  '2B': { appliesToFields: ['360-2B'], text: 'Missing/Invalid Medicaid Indicator' },
  '2C': { appliesToFields: ['335-2C'], text: 'Missing/Invalid Pregnancy Indicator' },
  '2D': { appliesToFields: ['361-2D'], text: 'Missing/Invalid Provider Accept Assignment Indicator' },
  '2E': { appliesToFields: ['468-2E'], text: 'Missing/Invalid Primary Care Provider ID Qualifier' },
  '2G': { appliesToFields: ['362-2G'], text: 'Missing/Invalid Compound Ingredient Modifier Code Count' },
  '2H': { appliesToFields: ['363-2H'], text: 'Missing/Invalid Compound Ingredient Modifier Code' },
  '2J': { appliesToFields: ['364-2J'], text: 'Missing/Invalid Prescriber First Name' },
  '2K': { appliesToFields: ['365-2K'], text: 'Missing/Invalid Prescriber Street Address' },
  '2M': { appliesToFields: ['366-2M'], text: 'Missing/Invalid Prescriber City Address' },
  '2N': { appliesToFields: ['367-2N'], text: 'Missing/Invalid Prescriber State/Province Address' },
  '2P': { appliesToFields: ['368-2P'], text: 'Missing/Invalid Prescriber Zip/Postal Zone' },
  '2Q': { appliesToFields: ['369-2Q'], text: 'Missing/Invalid Additional Documentation Type ID' },
  '2R': { appliesToFields: ['370-2R'], text: 'Missing/Invalid Length of Need' },
  '2S': { appliesToFields: ['371-2S'], text: 'Missing/Invalid Length of Need Qualifier' },
  '2T': { appliesToFields: ['372-2T'], text: 'Missing/Invalid Prescriber/Supplier Date Signed' },
  '2U': { appliesToFields: ['373-2U'], text: 'Missing/Invalid Request Status' },
  '2V': { appliesToFields: ['374-2V'], text: 'Missing/Invalid Request Period Begin Date' },
  '2W': { appliesToFields: ['375-2W'], text: 'Missing/Invalid Request Period Recert/Revised Date' },
  '2X': { appliesToFields: ['376-2X'], text: 'Missing/Invalid Supporting Documentation' },
  '2Z': { appliesToFields: ['377-2Z'], text: 'Missing/Invalid Question Number/Letter Count' },
  '3A': { appliesToFields: ['498-PA'], text: 'Missing/Invalid Request Type' },
  '3B': { appliesToFields: ['498-PB'], text: 'Missing/Invalid Request Period Date-Begin' },
  '3C': { appliesToFields: ['498-PC'], text: 'Missing/Invalid Request Period Date-End' },
  '3D': { appliesToFields: ['498-PD'], text: 'Missing/Invalid Basis Of Request' },
  '3E': { appliesToFields: ['498-PE'], text: 'Missing/Invalid Authorized Representative First Name' },
  '3F': { appliesToFields: ['498-PF'], text: 'Missing/Invalid Authorized Representative Last Name' },
  '3G': { appliesToFields: ['498-PG'], text: 'Missing/Invalid Authorized Representative Street Address' },
  '3H': { appliesToFields: ['498-PH'], text: 'Missing/Invalid Authorized Representative City Address' },
  '3J': { appliesToFields: ['498-PJ'], text: 'Missing/Invalid Authorized Representative State/Province Address' },
  '3K': { appliesToFields: ['498-PK'], text: 'Missing/Invalid Authorized Representative Zip/Postal Zone' },
  '3M': { appliesToFields: ['498-PM'], text: 'Missing/Invalid Prescriber Phone Number' },
  '3N': { appliesToFields: ['498-PY'], text: 'Missing/Invalid Prior Authorized Number-Assigned' },
  '3P': { appliesToFields: ['503-F3'], text: 'Missing/Invalid Authorization Number' },
  '3Q': { appliesToFields: ['385-3Q'], text: 'Missing/Invalid Facility Name' },
  '3R': { appliesToFields: ['407-D7'], text: 'Prior Authorization Not Required' },
  '3S': { appliesToFields: ['498-PP'], text: 'Missing/Invalid Prior Authorization Supporting Documentation' },
  '3T': {
    appliesToFields: ['302-C2', '401-D1', '407-D7'],
    text: 'Active Prior Authorization Exists Resubmit At Expiration Of Prior Authorization',
  },
  '3U': { appliesToFields: ['386-3U'], text: 'Missing/Invalid Facility Street Address' },
  '3V': { appliesToFields: ['387-3V'], text: 'Missing/Invalid Facility State/Province Address' },
  '3W': { appliesToFields: ['302-C2', '401-D1', '407-D7'], text: 'Prior Authorization In Process' },
  '3X': { appliesToFields: ['503-F3'], text: 'Authorization Number Not Found' },
  '3Y': { appliesToFields: ['302-C2', '401-D1', '407-D7'], text: 'Prior Authorization Denied' },
  '4B': { appliesToFields: ['378-4B'], text: 'Missing/Invalid Question Number/Letter' },
  '4C': { appliesToFields: ['337-4C'], text: 'Missing/Invalid Coordination Of Benefits/Other Payments Count' },
  '4D': { appliesToFields: ['379-4D'], text: 'Missing/Invalid Question Percent Response' },
  '4E': { appliesToFields: ['470-4E'], text: 'Missing/Invalid Primary Care Provider Last Name' },
  '4G': { appliesToFields: ['380-4G'], text: 'Missing/Invalid Question Date Response' },
  '4H': { appliesToFields: ['381-4H'], text: 'Missing/Invalid Question Dollar Amount Response' },
  '4J': { appliesToFields: ['382-4J'], text: 'Missing/Invalid Question Numeric Response' },
  '4K': { appliesToFields: ['383-4K'], text: 'Missing/Invalid Question Alphanumeric Response' },
  '4M': {
    appliesToFields: ['362-2G'],
    text: 'Compound Ingredient Modifier Code Count Does Not Match Number of Repetitions',
  },
  '4N': { appliesToFields: ['377-2Z'], text: 'Question Number/Letter Count Does Not Match Number of Repetitions' },
  '4P': { appliesToFields: ['378-4B'], text: 'Question Number/Letter Not Valid for Identified Document' },
  '4Q': { appliesToFields: ['378-4B'], text: 'Question Response Not Appropriate for Question Number/Letter' },
  '4R': {
    appliesToFields: ['378-4B'],
    text: 'Required Question Number/Letter Response for Indicated Document Missing',
  },
  '4S': { appliesToFields: ['489-TE'], text: 'Compound Product ID Requires a Modifier Code' },
  '4T': { appliesToFields: ['111-AM'], text: 'Missing/Invalid Additional Documentation Segment' },
  '4W': { appliesToFields: ['407-D7', '489-TE'], text: 'Must Fill Through Specialty Pharmacy' },
  '4X': { appliesToFields: ['384-4X'], text: 'Missing/Invalid Patient Residence' },
  '4Y': { appliesToFields: ['384-4X'], text: 'Patient Residence Value Not Supported' },
  '4Z': { appliesToFields: ['307-C7'], text: 'Place of Service Not Supported By Plan' },
  '5C': { appliesToFields: ['338-5C'], text: 'Missing/Invalid Other Payer Coverage Type' },
  '5E': { appliesToFields: ['471-5E'], text: 'Missing/Invalid Other Payer Reject Count' },
  '5J': { appliesToFields: ['388-5J'], text: 'Missing/Invalid Facility City Address' },
  '6C': { appliesToFields: ['339-6C'], text: 'Missing/Invalid Other Payer ID Qualifier' },
  '6D': { appliesToFields: ['389-6D'], text: 'Missing/Invalid Facility Zip/Postal Zone' },
  '6E': { appliesToFields: ['472-6E'], text: 'Missing/Invalid Other Payer Reject Code' },
  '6G': {
    appliesToFields: ['111-AM'],
    text: 'Coordination Of Benefits/Other Payments Segment Required For Adjudication',
  },
  '6H': { appliesToFields: ['111-AM'], text: 'Coupon Segment Required For Adjudication' },
  '6J': { appliesToFields: ['111-AM'], text: 'Insurance Segment Required For Adjudication' },
  '6K': { appliesToFields: ['111-AM'], text: 'Patient Segment Required For Adjudication' },
  '6M': { appliesToFields: ['111-AM'], text: 'Pharmacy Provider Segment Required For Adjudication' },
  '6N': { appliesToFields: ['111-AM'], text: 'Prescriber Segment Required For Adjudication' },
  '6P': { appliesToFields: ['111-AM'], text: 'Pricing Segment Required For Adjudication' },
  '6Q': { appliesToFields: ['111-AM'], text: 'Prior Authorization Segment Required For Adjudication' },
  '6R': { appliesToFields: ['111-AM'], text: "Worker's Compensation Segment Required For Adjudication" },
  '6S': { appliesToFields: ['111-AM'], text: 'Transaction Segment Required For Adjudication' },
  '6T': { appliesToFields: ['111-AM'], text: 'Compound Segment Required For Adjudication' },
  '6U': { appliesToFields: ['111-AM'], text: 'Compound Segment Incorrectly Formatted' },
  '6V': { appliesToFields: ['111-AM'], text: 'Multi-ingredient Compounds Not Supported,' },
  '6W': { appliesToFields: ['111-AM'], text: 'DUR/PPS Segment Required For Adjudication' },
  '6X': { appliesToFields: ['111-AM'], text: 'DUR/PPS Segment Incorrectly Formatted' },
  '6Y': { appliesToFields: ['201-B1'], text: 'Not Authorized To Submit Electronically' },
  '6Z': { appliesToFields: ['201-B1'], text: 'Provider Not Eligible To Perform Service/Dispense Product' },
  '7A': { appliesToFields: ['201-B1'], text: 'Provider Does Not Match Authorization On File' },
  '7B': { appliesToFields: ['202-B2'], text: 'Service Provider ID Qualifier Value Not Supported For Processor/Payer' },
  '7C': { appliesToFields: ['340-7C'], text: 'Missing/Invalid Other Payer ID' },
  '7D': { appliesToFields: ['304-C4'], text: 'Non-Matched DOB' },
  '7E': { appliesToFields: ['473-7E'], text: 'Missing/Invalid DUR/PPS Code Counter' },
  '7F': { appliesToFields: ['304-C4'], text: 'Future date not allowed for Date of Birth' },
  '7G': { appliesToFields: ['304-C4'], text: 'Future Date Not Allowed For DOB' },
  '7H': { appliesToFields: ['305-C5'], text: 'Non-Matched Gender Code' },
  '7J': { appliesToFields: ['306-C6'], text: 'Patient Relationship Code Value Not Supported' },
  '7K': { appliesToFields: ['308-C8'], text: 'Discrepancy Between Other Coverage Code And Other Payer Amount' },
  '7M': {
    appliesToFields: ['308-C8'],
    text: 'Discrepancy Between Other Coverage Code And Other Coverage Information On File',
  },
  '7N': { appliesToFields: ['331-CX'], text: 'Patient ID Qualifier Value Not Supported' },
  '7P': {
    appliesToFields: ['337-4C'],
    text: 'Coordination Of Benefits/Other Payments Count Exceeds Number of Supported Payers',
  },
  '7Q': { appliesToFields: ['339-6C'], text: 'Other Payer ID Qualifier Value Not Supported' },
  '7R': { appliesToFields: ['341-HB'], text: 'Other Payer Amount Paid Count Exceeds Number of Supported Groupings' },
  '7S': { appliesToFields: ['342-HC'], text: 'Other Payer Amount Paid Qualifier Value Not Supported' },
  '7T': {
    appliesToFields: ['344-HF'],
    text: 'Quantity Intended To Be Dispensed Required For Partial Fill Transaction',
  },
  '7U': {
    appliesToFields: ['345-HG'],
    text: 'Days Supply Intended To Be Dispensed Required For Partial Fill Transaction',
  },
  '7V': { appliesToFields: ['403-D3'], text: 'Duplicate Refills,' },
  '7W': { appliesToFields: ['403-D3'], text: 'Refills Exceed allowable Refills' },
  '7X': { appliesToFields: ['405-D5'], text: 'Days Supply Exceeds Plan Limitation' },
  '7Y': { appliesToFields: ['406-D6'], text: 'Compounds Not Covered,' },
  '7Z': { appliesToFields: ['406-D6'], text: 'Compound Requires Two Or More Ingredients,' },
  '8A': { appliesToFields: ['406-D6'], text: 'Compound Requires At Least One Covered Ingredient' },
  '8B': { appliesToFields: ['406-D6'], text: 'Compound Segment Missing On A Compound Claim' },
  '8C': { appliesToFields: ['336-8C'], text: 'Missing/Invalid Facility ID' },
  '8D': { appliesToFields: ['406-D6'], text: 'Compound Segment Present On A Non-Compound Claim' },
  '8E': { appliesToFields: ['474-8E'], text: 'Missing/Invalid DUR/PPS Level Of Effort' },
  '8G': { appliesToFields: ['407-D7'], text: 'Product/Service ID (407-D7) Must Be A Single Zero "0" For Compounds' },
  '8H': { appliesToFields: ['407-D7'], text: 'Product/Service Only Covered On Compound Claim' },
  '8J': { appliesToFields: ['407-D7', '489-TE'], text: 'Incorrect Product/Service ID For Processor/Payer' },
  '8K': { appliesToFields: ['408-D8'], text: 'DAW Code Value Not Supported' },
  '8M': {
    appliesToFields: ['409-D9'],
    text: 'Sum Of Compound Ingredient Costs Does Not Equal Ingredient Cost Submitted',
  },
  '8N': { appliesToFields: ['414-DE'], text: 'Future Date Prescription Written Not Allowed,' },
  '8P': { appliesToFields: ['414-DE'], text: 'Date Written Different On Previous Filling' },
  '8Q': { appliesToFields: ['415-DF'], text: 'Excessive Refills Authorized' },
  '8R': { appliesToFields: ['420-DK'], text: 'Submission Clarification Code Value Not Supported' },
  '8S': { appliesToFields: ['423-DN'], text: 'Basis Of Cost Determination Value Not Supported' },
  '8T': { appliesToFields: ['426-DQ'], text: 'U&C Must Be Greater Than Zero' },
  '8U': { appliesToFields: ['430-DU'], text: 'GAD Must Be Greater Than Zero' },
  '8V': {
    appliesToFields: ['431-DV'],
    text: 'Negative Dollar Amount Is Not Supported In The Other Payer Amount Paid Field',
  },
  '8W': { appliesToFields: ['431-DV'], text: 'Discrepancy Between Other Coverage Code and Other Payer Amount Paid' },
  '8X': { appliesToFields: ['433-DX'], text: 'Collection From Cardholder Not Allowed' },
  '8Y': { appliesToFields: ['433-DX'], text: 'Excessive Amount Collected' },
  '8Z': { appliesToFields: ['436-E1'], text: 'Product/Service ID Qualifier Value Not Supported' },
  '9B': { appliesToFields: ['439-E4'], text: 'Reason For Service Code Value Not Supported' },
  '9C': { appliesToFields: ['440-E5'], text: 'Professional Service Code Value Not Supported' },
  '9D': { appliesToFields: ['441-E6'], text: 'Result Of Service Code Value Not Supported' },
  '9E': { appliesToFields: ['442-E7'], text: 'Quantity Does Not Match Dispensing Unit' },
  '9G': { appliesToFields: ['442-E7'], text: 'Quantity Dispensed Exceeds Maximum Allowed' },
  '9H': { appliesToFields: ['442-E7'], text: 'Quantity Not Valid For Product/Service ID Submitted' },
  '9J': { appliesToFields: ['443-E8'], text: 'Future Other Payer Date Not Allowed' },
  '9K': {
    appliesToFields: ['447-EC'],
    text: 'Compound Ingredient Component Count Exceeds Number Of Ingredients Supported',
  },
  '9M': { appliesToFields: ['447-EC'], text: 'Minimum Of Two Ingredients Required' },
  '9N': { appliesToFields: ['448-ED'], text: 'Compound Ingredient Quantity Exceeds Maximum Allowed' },
  '9P': { appliesToFields: ['449-EE'], text: 'Compound Ingredient Drug Cost Must Be Greater Than Zero' },
  '9Q': { appliesToFields: ['995-E2'], text: 'Route Of Administration Submitted Not Covered' },
  '9R': { appliesToFields: ['455-EM'], text: 'Prescription/Service Reference Number Qualifier Submitted Not Covered' },
  '9S': { appliesToFields: ['457-EP'], text: 'Future Associated Prescription/Service Date Not Allowed' },
  '9T': { appliesToFields: ['461-EU'], text: 'Prior Authorization Type Code Submitted Not Covered' },
  '9U': { appliesToFields: ['465-EY'], text: 'Provider ID Qualifier Submitted Not Covered' },
  '9V': { appliesToFields: ['466-EZ'], text: 'Prescriber ID Qualifier Submitted Not Covered' },
  '9W': { appliesToFields: ['473-7E'], text: 'DUR/PPS Code Counter Exceeds Number Of Occurrences Supported' },
  '9X': { appliesToFields: ['485-KE'], text: 'Coupon Type Submitted Not Covered' },
  '9Y': { appliesToFields: ['488-RE'], text: 'Compound Product ID Qualifier Submitted Not Covered' },
  '9Z': { appliesToFields: ['489-TE'], text: 'Duplicate Product ID In Compound' },
  '00': { appliesToFields: ['Not Provided'], text: '("Missing" Means Missing/Invalid)' },
  '01': { appliesToFields: ['101-A1'], text: 'Missing/Invalid Bin Number' },
  '02': { appliesToFields: ['102-A2'], text: 'Missing/Invalid Version/Release Number' },
  '03': { appliesToFields: ['103-A3'], text: 'Missing/Invalid Transaction Code' },
  '04': { appliesToFields: ['104-A4'], text: 'Missing/Invalid Processor Control Number' },
  '05': { appliesToFields: ['201-B1'], text: 'Missing/Invalid Service Provider Number' },
  '06': { appliesToFields: ['301-C1'], text: 'Missing/Invalid Group ID' },
  '07': { appliesToFields: ['302-C2'], text: 'Missing/Invalid Cardholder ID' },
  '08': { appliesToFields: ['303-C3'], text: 'Missing/Invalid Person Code' },
  '09': { appliesToFields: ['304-C4'], text: 'Missing/Invalid Date Of Birth' },
  '10': { appliesToFields: ['305-C5'], text: 'Missing/Invalid Patient Gender Code' },
  '11': { appliesToFields: ['306-C6'], text: 'Missing/Invalid Patient Relationship Code' },
  '12': { appliesToFields: ['307-C7'], text: 'Missing/Invalid Place of Service' },
  '13': { appliesToFields: ['308-C8'], text: 'Missing/Invalid Other Coverage Code' },
  '14': { appliesToFields: ['309-C9'], text: 'Missing/Invalid Eligibility Clarification Code' },
  '15': { appliesToFields: ['401-D1'], text: 'Missing/Invalid Date of Service' },
  '16': { appliesToFields: ['402-D2'], text: 'Missing/Invalid Prescription/Service Reference Number' },
  '17': { appliesToFields: ['403-D3'], text: 'Missing/Invalid Fill Number' },
  '19': { appliesToFields: ['405-D5'], text: 'Missing/Invalid Days Supply' },
  '20': { appliesToFields: ['406-D6'], text: 'Missing Compound Code' },
  '21': { appliesToFields: ['407-D7', '489-TE'], text: 'Missing/Invalid Product/Service ID' },
  '22': { appliesToFields: ['408-D8'], text: 'Missing/Invalid Dispense As Written (DAW)/Product Selection Code' },
  '23': { appliesToFields: ['409-D9'], text: 'Missing/Invalid Ingredient Cost Submitted' },
  '25': { appliesToFields: ['411-DB'], text: 'Missing/Invalid Prescriber ID' },
  '26': { appliesToFields: ['600-28'], text: 'Missing/Invalid Unit Of Measure' },
  '27': { appliesToFields: ['407-D7', '489-TE'], text: 'Product Identifier not FDA/NSDE Listed' },
  '28': { appliesToFields: ['414-DE'], text: 'Missing/Invalid Date Prescription Written' },
  '29': { appliesToFields: ['415-DF'], text: 'Missing/Invalid Number Of Refills Authorized' },
  '30': { appliesToFields: ['Not Provided'], text: 'Reversal request outside processor reversal window.' },
  '31': { appliesToFields: ['Not Provided'], text: 'No matching paid claim found for reversal request.' },
  '32': { appliesToFields: ['418-DI'], text: 'Missing/Invalid Level Of Service' },
  '33': { appliesToFields: ['419-DJ'], text: 'Missing/Invalid Prescription Origin Code' },
  '34': { appliesToFields: ['420-DK'], text: 'Missing/Invalid Submission Clarification Code' },
  '35': { appliesToFields: ['421-DL'], text: 'Missing/Invalid Primary Care Provider ID' },
  '38': { appliesToFields: ['423-DN'], text: 'Missing/Invalid Basis Of Cost Determination' },
  '39': { appliesToFields: ['424-DO'], text: 'Missing/Invalid Diagnosis Code' },
  '40': { appliesToFields: ['None'], text: 'Pharmacy Not Contracted With Plan On Date Of Service' },
  '41': { appliesToFields: ['None'], text: 'Submit Bill To Other Processor Or Primary Payer' },
  '42': {
    appliesToFields: ['411-DB', '420-DK'],
    text: "Plan's Prescriber data base indicates the Prescriber ID Submitted is inactive or expired",
  },
  '43': {
    appliesToFields: ['411-DB', '420-DK'],
    text: "Plan's Prescriber data base indicates the associated DEA to submitted Prescriber ID is inactive",
  },
  '44': {
    appliesToFields: ['411-DB', '420-DK'],
    text: "Plan's Prescriber data base indicates the associated DEA to submitted Prescriber ID Is not found",
  },
  '46': {
    appliesToFields: ['411-DB', '420-DK'],
    text: "Plan's Prescriber data base indicates associated DEA to submitted Prescriber ID does not allow this drug DEA Schedule",
  },
  '50': { appliesToFields: ['201-B1'], text: 'Non-Matched Pharmacy Number' },
  '51': { appliesToFields: ['301-C1'], text: 'Non-Matched Group ID' },
  '52': { appliesToFields: ['302-C2'], text: 'Non-Matched Cardholder ID' },
  '53': { appliesToFields: ['303-C3'], text: 'Non-Matched Person Code' },
  '54': { appliesToFields: ['407-D7', '489-TE'], text: 'Non-Matched Product/Service ID Number' },
  '55': { appliesToFields: ['407-D7', '489-TE'], text: 'Non-Matched Product Package Size' },
  '56': { appliesToFields: ['411-DB'], text: 'Non-Matched Prescriber ID' },
  '58': { appliesToFields: ['421-DL'], text: 'Non-Matched Primary Prescriber' },
  '60': {
    appliesToFields: ['302-C2', '304-C4', '401-D1', '407-D7', '489-TE'],
    text: 'Product/Service Not Covered For Patient Age',
  },
  '61': {
    appliesToFields: ['302-C2', '305-C5', '407-D7', '489-TE'],
    text: 'Product/Service Not Covered For Patient Gender',
  },
  '62': {
    appliesToFields: ['310-CA', '311-CB', '312-CC', '313-CD', '302-C2'],
    text: 'Patient/Card Holder ID Name Mismatch',
  },
  '63': {
    appliesToFields: ['302-C2. 401-D1', '407-D7'],
    text: 'Product/Service ID Not Covered For Institutionalized Patient',
  },
  '64': {
    appliesToFields: ['201-B1', '407-D7', '442-E7', '461-EU', '462-EV', '489-TE'],
    text: 'Claim Submitted Does Not Match Prior Authorization',
  },
  '65': { appliesToFields: ['302-C2', '303-C3', '306-C6'], text: 'Patient Is Not Covered' },
  '66': { appliesToFields: ['302-C2', '303-C3', '304-C4', '306-C6'], text: 'Patient Age Exceeds Maximum Age' },
  '67': { appliesToFields: ['302-C2', '401-D1'], text: 'Filled Before Coverage Effective' },
  '68': { appliesToFields: ['302-C2', '401-D1'], text: 'Filled After Coverage Expired' },
  '69': { appliesToFields: ['302-C2', '401-D1'], text: 'Filled After Coverage Terminated' },
  '70': { appliesToFields: ['407-D7', '489-TE'], text: 'Product/Service Not Covered - Plan/Benefit Exclusion' },
  '71': { appliesToFields: ['411-DB'], text: 'Prescriber ID Is Not Covered' },
  '72': { appliesToFields: ['421-DL'], text: 'Primary Prescriber Is Not Covered' },
  '73': { appliesToFields: ['402-D2', '403-D3'], text: 'Refills Are Not Covered' },
  '74': {
    appliesToFields: ['409-D9', '442-E7', '481-HA', '482-GE'],
    text: 'Other Carrier Payment Meets Or Exceeds Payable',
  },
  '75': { appliesToFields: ['462-EV', '489-TE'], text: 'Prior Authorization Required' },
  '76': { appliesToFields: ['405-D5', '442-E7'], text: 'Plan Limitations Exceeded' },
  '77': { appliesToFields: ['407-D7', '489-TE'], text: 'Discontinued Product/Service ID Number' },
  '78': {
    appliesToFields: ['407-D7', '409-D9', '442-E7', '448-ED', '449-EE', '481-HA', '482-GE', '489-TE'],
    text: 'Cost Exceeds Maximum',
  },
  '79': { appliesToFields: ['401-D1', '403-D3', '405-D5'], text: 'Refill Too Soon' },
  '80': { appliesToFields: ['407-D7', '424-DO'], text: 'Drug-Diagnosis Mismatch' },
  '81': { appliesToFields: ['401-D1'], text: 'Claim Too Old' },
  '82': { appliesToFields: ['401-D1'], text: 'Claim Is Post-Dated' },
  '83': { appliesToFields: ['201-B1', '401-D1', '402-D2', '403-D3', '407-D7'], text: 'Duplicate Paid/Captured Claim' },
  '84': { appliesToFields: ['201-B1', '401-D1', '402-D2'], text: 'Claim Has Not Been Paid/Captured' },
  '85': { appliesToFields: ['None'], text: 'Claim Not Processed' },
  '86': { appliesToFields: ['None'], text: 'Submit Manual Reversal' },
  '87': { appliesToFields: ['None'], text: 'Reversal Not Processed' },
  '88': { appliesToFields: ['401-D1', '407-D7'], text: 'DUR Reject Error' },
  '89': { appliesToFields: ['Not Provided'], text: 'Rejected Claim Fees Paid' },
  '90': { appliesToFields: ['Host Disconnected Before Session Completed'], text: 'Host Hung Up' },
  '91': { appliesToFields: ['Response Not In Appropriate Format To Be Displayed'], text: 'Host Response Error' },
  '92': {
    appliesToFields: ['Processing Host Did Not Accept Transaction/Did Not Respond Within Time Out Period'],
    text: 'System Unavailable/Host Unavailable',
  },
  '95': { appliesToFields: ['Not Provided'], text: 'Time Out' },
  '96': { appliesToFields: ['Not Provided'], text: 'Scheduled Downtime' },
  '97': { appliesToFields: ['Not Provided'], text: 'Payer Unavailable' },
  '98': { appliesToFields: ['Not Provided'], text: 'Connection To Payer Is Down' },
  '99': { appliesToFields: ['Do Not Retransmit Transaction(s)'], text: 'Host Processing Error' },
  '201': { appliesToFields: ['111-AM'], text: 'Patient Segment is not used for this Transaction Code' },
  '202': { appliesToFields: ['111-AM'], text: 'Insurance Segment is not used for this Transaction Code' },
  '203': { appliesToFields: ['111-AM'], text: 'Claim Segment is not used for this Transaction Code' },
  '204': { appliesToFields: ['111-AM'], text: 'Pharmacy Provider Segment is not used for this Transaction Code' },
  '205': { appliesToFields: ['111-AM'], text: 'Prescriber Segment is not used for this Transaction Code' },
  '206': {
    appliesToFields: ['111-AM'],
    text: 'Coordination of Benefits/Other Payments Segment is not used for this Transaction Code',
  },
  '207': { appliesToFields: ['111-AM'], text: "Workers' Compensation Segment is not used for this Transaction Code" },
  '208': { appliesToFields: ['111-AM'], text: 'DUR/PPS Segment is not used for this Transaction Code' },
  '209': { appliesToFields: ['111-AM'], text: 'Pricing Segment is not used for this Transaction Code' },
  '210': { appliesToFields: ['111-AM'], text: 'Coupon Segment is not used for this Transaction Code' },
  '211': { appliesToFields: ['111-AM'], text: 'Compound Segment is not used for this Transaction Code' },
  '212': { appliesToFields: ['111-AM'], text: 'Prior Authorization Segment is not used for this Transaction Code' },
  '213': { appliesToFields: ['111-AM'], text: 'Clinical Segment is not used for this Transaction Code' },
  '214': {
    appliesToFields: ['111-AM'],
    text: 'Additional Documentation Segment is not used for this Transaction Code',
  },
  '215': { appliesToFields: ['111-AM'], text: 'Facility Segment is not used for this Transaction Code' },
  '216': { appliesToFields: ['111-AM'], text: 'Narrative Segment is not used for this Transaction Code' },
  '217': { appliesToFields: ['111-AM'], text: 'Purchaser Segment is not used for this Transaction Code' },
  '218': { appliesToFields: ['111-AM'], text: 'Service Provider Segment is not used for this Transaction Code' },
  '219': { appliesToFields: ['331-CX'], text: 'Patient ID Qualifier is not used for this Transaction Code' },
  '220': { appliesToFields: ['332-CY'], text: 'Patient ID is not used for this Transaction Code' },
  '221': { appliesToFields: ['304-C4'], text: 'Date of Birth is not used for this Transaction Code' },
  '222': { appliesToFields: ['305-C5'], text: 'Patient Gender Code is not used for this Transaction Code' },
  '223': { appliesToFields: ['310-CA'], text: 'Patient First Name is not used for this Transaction Code' },
  '224': { appliesToFields: ['311-CB'], text: 'Patient Last Name is not used for this Transaction Code' },
  '225': { appliesToFields: ['322-CM'], text: 'Patient Street Address is not used for this Transaction Code' },
  '226': { appliesToFields: ['323-CN'], text: 'Patient City Address is not used for this Transaction Code' },
  '227': { appliesToFields: ['324-CO'], text: 'Patient State/Province Address is not used for this Transaction Code' },
  '228': { appliesToFields: ['325-CP'], text: 'Patient ZIP/Postal Zone is not used for this Transaction Code' },
  '229': { appliesToFields: ['326-CQ'], text: 'Patient Phone Number is not used for this Transaction Code' },
  '230': { appliesToFields: ['307-C7'], text: 'Place of Service is not used for this Transaction Code' },
  '231': { appliesToFields: ['333-CZ'], text: 'Employer ID is not used for this Transaction Code' },
  '232': { appliesToFields: ['334-1C'], text: 'Smoker/Non-Smoker Code is not used for this Transaction Code' },
  '233': { appliesToFields: ['335-2C'], text: 'Pregnancy Indicator is not used for this Transaction Code' },
  '234': { appliesToFields: ['350-HN'], text: 'Patient E-Mail Address is not used for this Transaction Code' },
  '235': { appliesToFields: ['384-4X'], text: 'Patient Residence is not used for this Transaction Code' },
  '236': {
    appliesToFields: ['A22-YR'],
    text: 'Patient ID Associated State/Province Address is not used for this Transaction Code',
  },
  '237': { appliesToFields: ['312-CC'], text: 'Cardholder First Name is not used for this Transaction Code' },
  '238': { appliesToFields: ['313-CD'], text: 'Cardholder Last Name is not used for this Transaction Code' },
  '239': { appliesToFields: ['314-CE'], text: 'Home Plan is not used for this Transaction Code' },
  '240': { appliesToFields: ['524-FO'], text: 'Plan ID is not used for this Transaction Code' },
  '241': { appliesToFields: ['309-C9'], text: 'Eligibility Clarification Code is not used for this Transaction Code' },
  '242': { appliesToFields: ['301-C1'], text: 'Group ID is not used for this Transaction Code' },
  '243': { appliesToFields: ['303-C3'], text: 'Person Code is not used for this Transaction Code' },
  '244': { appliesToFields: ['306-C6'], text: 'Patient Relationship Code is not used for this Transaction Code' },
  '245': { appliesToFields: ['990-MG'], text: 'Other Payer BIN Number is not used for this Transaction Code' },
  '246': {
    appliesToFields: ['991-MH'],
    text: 'Other Payer Processor Control Number is not used for this Transaction Code',
  },
  '247': { appliesToFields: ['356-NU'], text: 'Other Payer Cardholder ID is not used for this Transaction Code' },
  '248': { appliesToFields: ['992-MJ'], text: 'Other Payer Group ID is not used for this Transaction Code' },
  '249': { appliesToFields: ['359-2A'], text: 'Medigap ID is not used for this Transaction Code' },
  '250': { appliesToFields: ['360-2B'], text: 'Medicaid Indicator is not used for this Transaction Code' },
  '251': {
    appliesToFields: ['361-2D'],
    text: 'Provider Accept Assignment Indicator is not used for this Transaction Code',
  },
  '252': {
    appliesToFields: ['997-G2'],
    text: 'CMS Part D Defined Qualified Facility is not used for this Transaction Code',
  },
  '253': { appliesToFields: ['115-N5'], text: 'Medicaid ID Number is not used for this Transaction Code' },
  '254': { appliesToFields: ['116-N6'], text: 'Medicaid Agency Number is not used for this Transaction Code' },
  '255': {
    appliesToFields: ['456-EN'],
    text: 'Associated Prescription/Service Reference Number is not used for this Transaction Code',
  },
  '256': {
    appliesToFields: ['457-EP'],
    text: 'Associated Prescription/Service Date is not used for this Transaction Code',
  },
  '257': { appliesToFields: ['458-SE'], text: 'Procedure Modifier Code Count is not used for this Transaction Code' },
  '258': { appliesToFields: ['459-ER'], text: 'Procedure Modifier Code is not used for this Transaction Code' },
  '259': { appliesToFields: ['442-E7'], text: 'Quantity Dispensed is not used for this Transaction Code' },
  '260': { appliesToFields: ['403-D3'], text: 'Fill Number is not used for this Transaction Code' },
  '261': { appliesToFields: ['405-D5'], text: 'Days Supply is not used for this Transaction Code' },
  '262': { appliesToFields: ['406-D6'], text: 'Compound Code is not used for this Transaction Code' },
  '263': {
    appliesToFields: ['408-D8'],
    text: 'Dispense As Written(DAW)/Product Selection Code is not used for this Transaction Code',
  },
  '264': { appliesToFields: ['414-DE'], text: 'Date Prescription Written is not used for this Transaction Code' },
  '265': { appliesToFields: ['415-DF'], text: 'Number of Refills Authorized is not used for this Transaction Code' },
  '266': { appliesToFields: ['419-DJ'], text: 'Prescription Origin Code is not used for this Transaction Code' },
  '267': {
    appliesToFields: ['354-NX'],
    text: 'Submission Clarification Code Count is not used for this Transaction Code',
  },
  '268': { appliesToFields: ['420-DK'], text: 'Submission Clarification Code is not used for this Transaction Code' },
  '269': { appliesToFields: ['460-ET'], text: 'Quantity Prescribed is not used for this Transaction Code' },
  '270': { appliesToFields: ['308-C8'], text: 'Other Coverage Code is not used for this Transaction Code' },
  '271': { appliesToFields: ['429-DT'], text: 'Special Packaging Indicator is not used for this Transaction Code' },
  '272': {
    appliesToFields: ['453-EJ'],
    text: 'Originally Prescribed Product/Service ID Qualifier is not used for this Transaction Code',
  },
  '273': {
    appliesToFields: ['445-EA'],
    text: 'Originally Prescribed Product/Service Code is not used for this Transaction Code',
  },
  '274': { appliesToFields: ['446-EB'], text: 'Originally Prescribed Quantity is not used for this Transaction Code' },
  '275': { appliesToFields: ['330-CW'], text: 'Alternate ID is not used for this Transaction Code' },
  '276': {
    appliesToFields: ['454-EK'],
    text: 'Scheduled Prescription ID Number is not used for this Transaction Code',
  },
  '277': { appliesToFields: ['600-28'], text: 'Unit of Measure is not used for this Transaction Code' },
  '278': { appliesToFields: ['418-DI'], text: 'Level of Service is not used for this Transaction Code' },
  '279': { appliesToFields: ['461-EU'], text: 'Prior Authorization Type Code is not used for this Transaction Code' },
  '280': {
    appliesToFields: ['462-EV'],
    text: 'Prior Authorization Number Submitted is not used for this Transaction Code',
  },
  '281': {
    appliesToFields: ['463-EW'],
    text: 'Intermediary Authorization Type ID is not used for this Transaction Code',
  },
  '282': { appliesToFields: ['464-EX'], text: 'Intermediary Authorization ID is not used for this Transaction Code' },
  '283': { appliesToFields: ['343-HD'], text: 'Dispensing Status is not used for this Transaction Code' },
  '284': {
    appliesToFields: ['344-HF'],
    text: 'Quantity Intended to be Dispensed is not used for this Transaction Code',
  },
  '285': {
    appliesToFields: ['345-HG'],
    text: 'Days Supply Intended to be Dispensed is not used for this Transaction Code',
  },
  '286': { appliesToFields: ['357-NV'], text: 'Delay Reason Code is not used for this Transaction Code' },
  '287': { appliesToFields: ['880-K5'], text: 'Transaction Reference Number is not used for this Transaction Code' },
  '288': {
    appliesToFields: ['391-MT'],
    text: 'Patient Assignment Indicator (Direct Member Reimbursement Indicator) is not used for this Transaction Code',
  },
  '289': { appliesToFields: ['995-E2'], text: 'Route of Administration is not used for this Transaction Code' },
  '290': { appliesToFields: ['996-G1'], text: 'Compound Type is not used for this Transaction Code' },
  '291': {
    appliesToFields: ['114-N4'],
    text: 'Medicaid Subrogation Internal Control Number/Transaction Control Number (ICN/TCN) is not used for this Transaction Code',
  },
  '292': { appliesToFields: ['147-U7'], text: 'Pharmacy Service Type is not used for this Transaction Code' },
  '293': {
    appliesToFields: ['579-XX'],
    text: 'Associated Prescription/Service Provider ID Qualifier is not used for this Transaction Code',
  },
  '294': {
    appliesToFields: ['580-XY'],
    text: 'Associated Prescription/Service Provider ID is not used for this Transaction Code',
  },
  '295': {
    appliesToFields: ['581-XZ'],
    text: 'Associated Prescription/Service Reference Number Qualifier is not used for this Transaction Code',
  },
  '296': {
    appliesToFields: ['582-X0'],
    text: 'Associated Prescription/Service Reference Fill Number is not used for this Transaction Code',
  },
  '297': { appliesToFields: ['678-Y6'], text: 'Time of Service is not used for this Transaction Code' },
  '298': { appliesToFields: ['681-ZF'], text: 'Sales Transaction ID is not used for this Transaction Code' },
  '299': { appliesToFields: ['A29-ZS'], text: 'Reported Payment Type is not used for this Transaction Code' },
  '300': { appliesToFields: ['465-EY'], text: 'Provider ID Qualifier is not used for this Transaction Code' },
  '301': { appliesToFields: ['444-E9'], text: 'Provider ID is not used for this Transaction Code' },
  '302': { appliesToFields: ['466-EZ'], text: 'Prescriber ID Qualifier is not used for this Transaction Code' },
  '303': { appliesToFields: ['411-DB'], text: 'Prescriber ID is not used for this Transaction Code' },
  '304': {
    appliesToFields: ['A24-ZK'],
    text: 'Prescriber ID Associated State/Province Address is not used for this Transaction Code',
  },
  '305': { appliesToFields: ['427-DR'], text: 'Prescriber Last Name is not used for this Transaction Code' },
  '306': { appliesToFields: ['498-PM'], text: 'Prescriber Phone Number is not used for this Transaction Code' },
  '307': {
    appliesToFields: ['468-2E'],
    text: 'Primary Care Provider ID Qualifier is not used for this Transaction Code',
  },
  '308': { appliesToFields: ['421-DL'], text: 'Primary Care Provider ID is not used for this Transaction Code' },
  '309': { appliesToFields: ['470-4E'], text: 'Primary Care Provider Last Name is not used for this Transaction Code' },
  '310': { appliesToFields: ['364-2J'], text: 'Prescriber First Name is not used for this Transaction Code' },
  '311': { appliesToFields: ['365-2K'], text: 'Prescriber Street Address is not used for this Transaction Code' },
  '312': { appliesToFields: ['366-2M'], text: 'Prescriber City Address is not used for this Transaction Code' },
  '313': {
    appliesToFields: ['367-2N'],
    text: 'Prescriber State/Province Address is not used for this Transaction Code',
  },
  '314': { appliesToFields: ['368-2P'], text: 'Prescriber ZIP/Postal Zone is not used for this Transaction Code' },
  '315': {
    appliesToFields: ['A25-ZM'],
    text: 'Prescriber Alternate ID Qualifier is not used for this Transaction Code',
  },
  '316': { appliesToFields: ['A26-ZP'], text: 'Prescriber Alternate ID is not used for this Transaction Code' },
  '317': {
    appliesToFields: ['A27-ZQ'],
    text: 'Prescriber Alternate ID Associated State/Province Address is not used for this Transaction Code',
  },
  '318': { appliesToFields: ['339-6C'], text: 'Other Payer ID Qualifier is not used for this Transaction Code' },
  '319': { appliesToFields: ['340-7C'], text: 'Other Payer ID is not used for this Transaction Code' },
  '320': { appliesToFields: ['443-E8'], text: 'Other Payer Date is not used for this Transaction Code' },
  '321': { appliesToFields: ['993-A7'], text: 'Internal Control Number is not used for this Transaction Code' },
  '322': { appliesToFields: ['341-HB'], text: 'Other Payer Amount Paid Count is not used for this Transaction Code' },
  '323': {
    appliesToFields: ['342-HC'],
    text: 'Other Payer Amount Paid Qualifier is not used for this Transaction Code',
  },
  '324': { appliesToFields: ['431-DV'], text: 'Other Payer Amount Paid is not used for this Transaction Code' },
  '325': { appliesToFields: ['471-5E'], text: 'Other Payer Reject Count is not used for this Transaction Code' },
  '326': { appliesToFields: ['472-6E'], text: 'Other Payer Reject Code is not used for this Transaction Code' },
  '327': {
    appliesToFields: ['353-NR'],
    text: 'Other Payer-Patient Responsibility Amount Count is not used for this Transaction Code',
  },
  '328': {
    appliesToFields: ['351-NP'],
    text: 'Other Payer-Patient Responsibility Amount Qualifier is not used for this Transaction Code',
  },
  '329': {
    appliesToFields: ['352-NQ'],
    text: 'Other Payer-Patient Responsibility Amount is not used for this Transaction Code',
  },
  '330': { appliesToFields: ['392-MU'], text: 'Benefit Stage Count is not used for this Transaction Code' },
  '331': { appliesToFields: ['393-MV'], text: 'Benefit Stage Qualifier is not used for this Transaction Code' },
  '332': { appliesToFields: ['394-MW'], text: 'Benefit Stage Amount is not used for this Transaction Code' },
  '333': { appliesToFields: ['315-CF'], text: 'Employer Name is not used for this Transaction Code' },
  '334': { appliesToFields: ['316-CG'], text: 'Employer Street Address is not used for this Transaction Code' },
  '335': { appliesToFields: ['317-CH'], text: 'Employer City Address is not used for this Transaction Code' },
  '336': { appliesToFields: ['318-CI'], text: 'Employer State/Province Address is not used for this Transaction Code' },
  '337': { appliesToFields: ['319-CJ'], text: 'Employer Zip/Postal Code is not used for this Transaction Code' },
  '338': { appliesToFields: ['320-CK'], text: 'Employer Phone Number is not used for this Transaction Code' },
  '339': { appliesToFields: ['321-CL'], text: 'Employer Contact Name is not used for this Transaction Code' },
  '340': { appliesToFields: ['327-CR'], text: 'Carrier ID is not used for this Transaction Code' },
  '341': { appliesToFields: ['435-DZ'], text: 'Claim/Reference ID is not used for this Transaction Code' },
  '342': { appliesToFields: ['117-TR'], text: 'Billing Entity Type Indicator is not used for this Transaction Code' },
  '343': { appliesToFields: ['118-TS'], text: 'Pay To Qualifier is not used for this Transaction Code' },
  '344': { appliesToFields: ['119-TT'], text: 'Pay To ID is not used for this Transaction Code' },
  '345': { appliesToFields: ['120-TU'], text: 'Pay To Name is not used for this Transaction Code' },
  '346': { appliesToFields: ['121-TV'], text: 'Pay To Street Address is not used for this Transaction Code' },
  '347': { appliesToFields: ['122-TW'], text: 'Pay To City Address is not used for this Transaction Code' },
  '348': { appliesToFields: ['123-TX'], text: 'Pay To State/Province Address is not used for this Transaction Code' },
  '349': { appliesToFields: ['124-TY'], text: 'Pay To ZIP/Postal Zone is not used for this Transaction Code' },
  '350': {
    appliesToFields: ['125-TZ'],
    text: 'Generic Equivalent Product ID Qualifier is not used for this Transaction Code',
  },
  '351': { appliesToFields: ['126-UA'], text: 'Generic Equivalent Product ID is not used for this Transaction Code' },
  '352': { appliesToFields: ['473-7E'], text: 'DUR/PPS Code Counter is not used for this Transaction Code' },
  '353': { appliesToFields: ['439-E4'], text: 'Reason for Service Code is not used for this Transaction Code' },
  '354': { appliesToFields: ['440-E5'], text: 'Professional Service Code is not used for this Transaction Code' },
  '355': { appliesToFields: ['441-E6'], text: 'Result of Service Code is not used for this Transaction Code' },
  '356': { appliesToFields: ['474-8E'], text: 'DUR/PPS Level of Effort is not used for this Transaction Code' },
  '357': { appliesToFields: ['475-J9'], text: 'DUR Co-Agent ID Qualifier is not used for this Transaction Code' },
  '358': { appliesToFields: ['476-H6'], text: 'DUR Co-Agent ID is not used for this Transaction Code' },
  '359': { appliesToFields: ['409-D9'], text: 'Ingredient Cost Submitted is not used for this Transaction Code' },
  '360': { appliesToFields: ['412-DC'], text: 'Dispensing Fee Submitted is not used for this Transaction Code' },
  '361': {
    appliesToFields: ['477-BE'],
    text: 'Professional Service Fee Submitted is not used for this Transaction Code',
  },
  '362': { appliesToFields: ['433-DX'], text: 'Patient Paid Amount Submitted is not used for this Transaction Code' },
  '363': { appliesToFields: ['438-E3'], text: 'Incentive Amount Submitted is not used for this Transaction Code' },
  '364': {
    appliesToFields: ['478-H7'],
    text: 'Other Amount Claimed Submitted Count is not used for this Transaction Code',
  },
  '365': {
    appliesToFields: ['479-H8'],
    text: 'Other Amount Claimed Submitted Qualifier is not used for this Transaction Code',
  },
  '366': { appliesToFields: ['480-H9'], text: 'Other Amount Claimed Submitted is not used for this Transaction Code' },
  '367': { appliesToFields: ['481-HA'], text: 'Flat Sales Tax Amount Submitted is not used for this Transaction Code' },
  '368': {
    appliesToFields: ['482-GE'],
    text: 'Percentage Sales Tax Amount Submitted is not used for this Transaction Code',
  },
  '369': {
    appliesToFields: ['483-HE'],
    text: 'Percentage Sales Tax Rate Submitted is not used for this Transaction Code',
  },
  '370': {
    appliesToFields: ['484-JE'],
    text: 'Percentage Sales Tax Basis Submitted is not used for this Transaction Code',
  },
  '371': { appliesToFields: ['426-DQ'], text: 'Usual and Customary Charge is not used for this Transaction Code' },
  '372': { appliesToFields: ['430-DU'], text: 'Gross Amount Due is not used for this Transaction Code' },
  '373': { appliesToFields: ['423-DN'], text: 'Basis of Cost Determination is not used for this Transaction Code' },
  '374': { appliesToFields: ['113-N3'], text: 'Medicaid Paid Amount is not used for this Transaction Code' },
  '375': { appliesToFields: ['487-NE'], text: 'Coupon Value Amount is not used for this Transaction Code' },
  '376': { appliesToFields: ['449-EE'], text: 'Compound Ingredient Drug Cost is not used for this Transaction Code' },
  '377': {
    appliesToFields: ['490-UE'],
    text: 'Compound Ingredient Basis of Cost Determination is not used for this Transaction Code',
  },
  '378': {
    appliesToFields: ['362-2G'],
    text: 'Compound Ingredient Modifier Code Count is not used for this Transaction Code',
  },
  '379': {
    appliesToFields: ['363-2H'],
    text: 'Compound Ingredient Modifier Code is not used for this Transaction Code',
  },
  '380': {
    appliesToFields: ['498-PE'],
    text: 'Authorized Representative First Name is not used for this Transaction Code',
  },
  '381': { appliesToFields: ['498-PF'], text: 'Authorized Rep. Last Name is not used for this Transaction Code' },
  '382': { appliesToFields: ['498-PG'], text: 'Authorized Rep. Street Address is not used for this Transaction Code' },
  '383': { appliesToFields: ['498-PH'], text: 'Authorized Rep. City is not used for this Transaction Code' },
  '384': { appliesToFields: ['498-PJ'], text: 'Authorized Rep. State/Province is not used for this Transaction Code' },
  '385': { appliesToFields: ['498-PK'], text: 'Authorized Rep. Zip/Postal Code is not used for this Transaction Code' },
  '386': {
    appliesToFields: ['498-PY'],
    text: 'Prior Authorization Number - Assigned is not used for this Transaction Code',
  },
  '387': { appliesToFields: ['503-F3'], text: 'Authorization Number is not used for this Transaction Code' },
  '388': {
    appliesToFields: ['498-PP'],
    text: 'Prior Authorization Supporting Documentation is not used for this Transaction Code',
  },
  '389': { appliesToFields: ['491-VE'], text: 'Diagnosis Code Count is not used for this Transaction Code' },
  '390': { appliesToFields: ['492-WE'], text: 'Diagnosis Code Qualifier is not used for this Transaction Code' },
  '391': { appliesToFields: ['424-DO'], text: 'Diagnosis Code is not used for this Transaction Code' },
  '392': { appliesToFields: ['493-XE'], text: 'Clinical Information Counter is not used for this Transaction Code' },
  '393': { appliesToFields: ['494-ZE'], text: 'Measurement Date is not used for this Transaction Code' },
  '394': { appliesToFields: ['495-H1'], text: 'Measurement Time is not used for this Transaction Code' },
  '395': { appliesToFields: ['496-H2'], text: 'Measurement Dimension is not used for this Transaction Code' },
  '396': { appliesToFields: ['497-H3'], text: 'Measurement Unit is not used for this Transaction Code' },
  '397': { appliesToFields: ['499-H4'], text: 'Measurement Value is not used for this Transaction Code' },
  '398': { appliesToFields: ['374-2V'], text: 'Request Period Begin Date is not used for this Transaction Code' },
  '399': {
    appliesToFields: ['375-2W'],
    text: 'Request Period Recert/Revised Date is not used for this Transaction Code',
  },
  '400': { appliesToFields: ['373-2U'], text: 'Request Status is not used for this Transaction Code' },
  '401': { appliesToFields: ['371-2S'], text: 'Length Of Need Qualifier is not used for this Transaction Code' },
  '402': { appliesToFields: ['370-2R'], text: 'Length Of Need is not used for this Transaction Code' },
  '403': { appliesToFields: ['372-2T'], text: 'Prescriber/Supplier Date Signed is not used for this Transaction Code' },
  '404': { appliesToFields: ['376-2X'], text: 'Supporting Documentation is not used for this Transaction Code' },
  '405': { appliesToFields: ['377-2Z'], text: 'Question Number/Letter Count is not used for this Transaction Code' },
  '406': { appliesToFields: ['378-4B'], text: 'Question Number/Letter is not used for this Transaction Code' },
  '407': { appliesToFields: ['379-4D'], text: 'Question Percent Response is not used for this Transaction Code' },
  '408': { appliesToFields: ['380-4G'], text: 'Question Date Response is not used for this Transaction Code' },
  '409': { appliesToFields: ['381-4H'], text: 'Question Dollar Amount Response is not used for this Transaction Code' },
  '410': { appliesToFields: ['382-4J'], text: 'Question Numeric Response is not used for this Transaction Code' },
  '411': { appliesToFields: ['383-4K'], text: 'Question Alphanumeric Response is not used for this Transaction Code' },
  '412': { appliesToFields: ['336-8C'], text: 'Facility ID is not used for this Transaction Code' },
  '413': { appliesToFields: ['385-3Q'], text: 'Facility Name is not used for this Transaction Code' },
  '414': { appliesToFields: ['386-3U'], text: 'Facility Street Address is not used for this Transaction Code' },
  '415': { appliesToFields: ['388-5J'], text: 'Facility City Address is not used for this Transaction Code' },
  '416': { appliesToFields: ['387-3V'], text: 'Facility State/Province Address is not used for this Transaction Code' },
  '417': { appliesToFields: ['389-6D'], text: 'Facility ZIP/Postal Zone is not used for this Transaction Code' },
  '418': { appliesToFields: ['591-YU'], text: 'Purchaser ID Qualifier is not used for this Transaction Code' },
  '419': { appliesToFields: ['592-YV'], text: 'Purchaser ID is not used for this Transaction Code' },
  '420': {
    appliesToFields: ['593-YW'],
    text: 'Purchaser ID Associated State Code is not used for this Transaction Code',
  },
  '421': { appliesToFields: ['594-YX'], text: 'Purchaser Date of Birth is not used for this Transaction Code' },
  '422': { appliesToFields: ['595-YY'], text: 'Purchaser Gender Code is not used for this Transaction Code' },
  '423': { appliesToFields: ['596-YZ'], text: 'Purchaser First Name is not used for this Transaction Code' },
  '424': { appliesToFields: ['597-Y0'], text: 'Purchaser Last Name is not used for this Transaction Code' },
  '425': { appliesToFields: ['598-Y1'], text: 'Purchaser Street Address is not used for this Transaction Code' },
  '426': { appliesToFields: ['599-Y2'], text: 'Purchaser City Address is not used for this Transaction Code' },
  '427': {
    appliesToFields: ['675-Y3'],
    text: 'Purchaser State/Province Address is not used for this Transaction Code',
  },
  '428': { appliesToFields: ['676-Y4'], text: 'Purchaser ZIP/Postal Zone is not used for this Transaction Code' },
  '429': { appliesToFields: ['677-Y5'], text: 'Purchaser Country Code is not used for this Transaction Code' },
  '430': { appliesToFields: ['A23-YS'], text: 'Purchaser Relationship Code is not used for this Transaction Code' },
  '431': { appliesToFields: ['A30-ZT'], text: 'Released Date is not used for this Transaction Code' },
  '432': { appliesToFields: ['A31-ZU'], text: 'Released Time is not used for this Transaction Code' },
  '433': { appliesToFields: ['583-YK'], text: 'Service Provider Name is not used for this Transaction Code' },
  '434': { appliesToFields: ['584-YM'], text: 'Service Provider Street Address is not used for this Transaction Code' },
  '435': { appliesToFields: ['585-YN'], text: 'Service Provider City Address is not used for this Transaction Code' },
  '436': {
    appliesToFields: ['586-YP'],
    text: 'Service Provider State/Province Address is not used for this Transaction Code',
  },
  '437': {
    appliesToFields: ['587-YQ'],
    text: 'Service Provider ZIP/Postal Zone is not used for this Transaction Code',
  },
  '438': { appliesToFields: ['680-ZB'], text: 'Seller ID Qualifier is not used for this Transaction Code' },
  '439': { appliesToFields: ['679-Y9'], text: 'Seller ID is not used for this Transaction Code' },
  '440': { appliesToFields: ['590-YT'], text: 'Seller Initials is not used for this Transaction Code' },
  '441': { appliesToFields: ['478-H7', '479-H8', '480-H9'], text: 'Other Amount Claimed Submitted Grouping Incorrect' },
  '442': { appliesToFields: ['341-HB', '342-HC', '431-DV'], text: 'Other Payer Amount Paid Grouping Incorrect' },
  '443': {
    appliesToFields: ['353-NR', '351-NP', '352-NQ'],
    text: 'Other Payer-Patient Responsibility Amount Grouping Incorrect',
  },
  '444': { appliesToFields: ['392-MU', '393-MV', '394-MW'], text: 'Benefit Stage Amount Grouping Incorrect' },
  '445': { appliesToFields: ['491-VE', '492-WE', '424-DO'], text: 'Diagnosis Code Grouping Incorrect' },
  '446': { appliesToFields: ['111-AM'], text: 'COB/Other Payments Segment Incorrectly Formatted' },
  '447': { appliesToFields: ['111-AM'], text: 'Additional Documentation Segment Incorrectly Formatted' },
  '448': { appliesToFields: ['111-AM'], text: 'Clinical Segment Incorrectly Formatted' },
  '449': { appliesToFields: ['111-AM'], text: 'Patient Segment Incorrectly Formatted' },
  '450': { appliesToFields: ['111-AM'], text: 'Insurance Segment Incorrectly Formatted' },
  '451': { appliesToFields: ['111-AM'], text: 'Transaction Header Segment Incorrectly Formatted' },
  '452': { appliesToFields: ['111-AM'], text: 'Claim Segment Incorrectly Formatted' },
  '453': { appliesToFields: ['111-AM'], text: 'Pharmacy Provider Segment Incorrectly Formatted' },
  '454': { appliesToFields: ['111-AM'], text: 'Prescriber Segment Incorrectly Formatted' },
  '455': { appliesToFields: ['111-AM'], text: "Workers' Compensation Segment Incorrectly Formatted" },
  '456': { appliesToFields: ['111-AM'], text: 'Pricing Segment Incorrectly Formatted' },
  '457': { appliesToFields: ['111-AM'], text: 'Coupon Segment Incorrectly Formatted' },
  '458': { appliesToFields: ['111-AM'], text: 'Prior Authorization Segment Incorrectly Formatted' },
  '459': { appliesToFields: ['111-AM'], text: 'Facility Segment Incorrectly Formatted' },
  '460': { appliesToFields: ['111-AM'], text: 'Narrative Segment Incorrectly Formatted' },
  '461': { appliesToFields: ['111-AM'], text: 'Purchaser Segment Incorrectly Formatted' },
  '462': { appliesToFields: ['111-AM'], text: 'Service Provider Segment Incorrectly Formatted' },
  '463': { appliesToFields: ['302-C2', '401-D1'], text: 'Pharmacy not contracted in Assisted Living Network' },
  '464': { appliesToFields: ['202-B2'], text: 'Service Provider ID Qualifier Does Not Precede Service Provider ID' },
  '465': { appliesToFields: ['331-CX'], text: 'Patient ID Qualifier Does Not Precede Patient ID' },
  '466': {
    appliesToFields: ['455-EM'],
    text: 'Prescription/Service Reference Number Qualifier Does Not Precede Prescription/Service Reference Number',
  },
  '467': { appliesToFields: ['436-E1'], text: 'Product/Service ID Qualifier Does Not Precede Product/Service ID' },
  '468': {
    appliesToFields: ['458-SE'],
    text: 'Procedure Modifier Code Count Does Not Precede Procedure Modifier Code',
  },
  '469': {
    appliesToFields: ['354-NX'],
    text: 'Submission Clarification Code Count Does Not Precede Submission Clarification Code',
  },
  '470': {
    appliesToFields: ['453-EJ'],
    text: 'Originally Prescribed Product/Service ID Qualifier Does Not Precede Originally Prescribed Product/Service Code',
  },
  '471': {
    appliesToFields: ['478-H7'],
    text: 'Other Amount Claimed Submitted Count Does Not Precede Other Amount Claimed Amount And/Or Qualifier',
  },
  '472': {
    appliesToFields: ['479-H8'],
    text: 'Other Amount Claimed Submitted Qualifier Does Not Precede Other Amount Claimed Submitted',
  },
  '473': { appliesToFields: ['465-EY'], text: 'Provider Id Qualifier Does Not Precede Provider ID' },
  '474': { appliesToFields: ['466-EZ'], text: 'Prescriber Id Qualifier Does Not Precede Prescriber ID' },
  '475': {
    appliesToFields: ['468-2E'],
    text: 'Primary Care Provider ID Qualifier Does Not Precede Primary Care Provider ID',
  },
  '476': {
    appliesToFields: ['337-4C'],
    text: 'Coordination Of Benefits/Other Payments Count Does Not Precede Other Payer Coverage Type',
  },
  '477': { appliesToFields: ['355-NT'], text: 'Other Payer ID Count Does Not Precede Other Payer ID Data Fields' },
  '478': { appliesToFields: ['339-6C'], text: 'Other Payer ID Qualifier Does Not Precede Other Payer ID' },
  '479': {
    appliesToFields: ['341-HB'],
    text: 'Other Payer Amount Paid Count Does Not Precede Other Payer Amount Paid And/Or Qualifier',
  },
  '480': {
    appliesToFields: ['342-HC'],
    text: 'Other Payer Amount Paid Qualifier Does Not Precede Other Payer Amount Paid',
  },
  '481': { appliesToFields: ['471-5E'], text: 'Other Payer Reject Count Does Not Precede Other Payer Reject Code' },
  '482': {
    appliesToFields: ['353-NR'],
    text: 'Other Payer-Patient Responsibility Amount Count Does Not Precede Other Payer-Patient Responsibility Amount and/or Qualifier',
  },
  '483': {
    appliesToFields: ['351-NP'],
    text: 'Other Payer-Patient Responsibility Amount Qualifier Does Not Precede Other Payer-Patient Responsibility Amount',
  },
  '484': {
    appliesToFields: ['392-MU'],
    text: 'Benefit Stage Count Does Not Precede Benefit Stage Amount and/or Qualifier',
  },
  '485': { appliesToFields: ['393-MV'], text: 'Benefit Stage Qualifier Does Not Precede Benefit Stage Amount' },
  '486': { appliesToFields: ['118-TS'], text: 'Pay To Qualifier Does Not Precede Pay To ID' },
  '487': {
    appliesToFields: ['125-TZ'],
    text: 'Generic Equivalent Product Id Qualifier Does Not Precede Generic Equivalent Product Id',
  },
  '488': { appliesToFields: ['473-7E'], text: 'DUR/PPS Code Counter Does Not Precede DUR Data Fields' },
  '489': { appliesToFields: ['475-J9'], text: 'DUR Co-Agent ID Qualifier Does Not Precede DUR Co-Agent ID' },
  '490': {
    appliesToFields: ['447-EC'],
    text: 'Compound Ingredient Component Count Does Not Precede Compound Product ID And/Or Qualifier',
  },
  '491': { appliesToFields: ['488-RE'], text: 'Compound Product ID Qualifier Does Not Precede Compound Product ID' },
  '492': {
    appliesToFields: ['362-2G'],
    text: 'Compound Ingredient Modifier Code Count Does Not Precede Compound Ingredient Modifier Code',
  },
  '493': { appliesToFields: ['491-VE'], text: 'Diagnosis Code Count Does Not Precede Diagnosis Code And/Or Qualifier' },
  '494': { appliesToFields: ['492-WE'], text: 'Diagnosis Code Qualifier Does Not Precede Diagnosis Code' },
  '495': {
    appliesToFields: ['493-XE'],
    text: 'Clinical Information Counter Does Not Precede Clinical Measurement data',
  },
  '496': { appliesToFields: ['371-2S'], text: 'Length Of Need Qualifier Does Not Precede Length Of Need' },
  '497': { appliesToFields: ['377-2Z'], text: 'Question Number/Letter Count Does Not Precede Question Number/Letter' },
  '504': { appliesToFields: ['393-MV'], text: 'Benefit Stage Qualifier Value Not Supported' },
  '505': { appliesToFields: ['338-5C'], text: 'Other Payer Coverage Type Value Not Supported' },
  '506': { appliesToFields: ['455-EM'], text: 'Prescription/Service Reference Number Qualifier Value Not Supported' },
  '507': { appliesToFields: ['369-2Q'], text: 'Additional Documentation Type ID Value Not Supported' },
  '508': { appliesToFields: ['498-PJ'], text: 'Authorized Representative State/Province Address Value Not Supported' },
  '509': { appliesToFields: ['498-PD'], text: 'Basis Of Request Value Not Supported' },
  '510': { appliesToFields: ['117-TR'], text: 'Billing Entity Type Indicator Value Not Supported' },
  '511': { appliesToFields: ['997-G2'], text: 'CMS Part D Defined Qualified Facility Value Not Supported' },
  '512': { appliesToFields: ['406-D6'], text: 'Compound Code Value Not Supported' },
  '513': { appliesToFields: ['451-EG'], text: 'Compound Dispensing Unit Form Indicator Value Not Supported' },
  '514': { appliesToFields: ['490-UE'], text: 'Compound Ingredient Basis of Cost Determination Value Not Supported' },
  '515': { appliesToFields: ['488-RE'], text: 'Compound Product ID Qualifier Value Not Supported' },
  '516': { appliesToFields: ['996-G1'], text: 'Compound Type Value Not Supported' },
  '517': { appliesToFields: ['485-KE'], text: 'Coupon Type Value Not Supported' },
  '518': { appliesToFields: ['475-J9'], text: 'DUR Co-Agent ID Qualifier Value Not Supported' },
  '519': { appliesToFields: ['474-8E'], text: 'DUR/PPS Level Of Effort Value Not Supported' },
  '520': { appliesToFields: ['357-NV'], text: 'Delay Reason Code Value Not Supported' },
  '521': { appliesToFields: ['492-WE'], text: 'Diagnosis Code Qualifier Value Not Supported' },
  '522': { appliesToFields: ['343-HD'], text: 'Dispensing Status Value Not Supported' },
  '523': { appliesToFields: ['309-C9'], text: 'Eligibility Clarification Code Value Not Supported' },
  '524': { appliesToFields: ['318-CI'], text: 'Employer State/ Province Address Value Not Supported' },
  '525': { appliesToFields: ['387-3V'], text: 'Facility State/Province Address Value Not Supported' },
  '526': { appliesToFields: ['501-F1'], text: 'Header Response Status Value Not Supported' },
  '527': { appliesToFields: ['463-EW'], text: 'Intermediary Authorization Type ID Value Not Supported' },
  '528': { appliesToFields: ['371-2S'], text: 'Length of Need Qualifier Value Not Supported' },
  '529': { appliesToFields: ['418-DI'], text: 'Level Of Service Value Not Supported' },
  '530': { appliesToFields: ['496-H2'], text: 'Measurement Dimension Value Not Supported' },
  '531': { appliesToFields: ['497-H3'], text: 'Measurement Unit Value Not Supported' },
  '532': { appliesToFields: ['360-2B'], text: 'Medicaid Indicator Value Not Supported' },
  '533': {
    appliesToFields: ['453-EJ'],
    text: 'Originally Prescribed Product/Service ID Qualifier Value Not Supported',
  },
  '534': { appliesToFields: ['479-H8'], text: 'Other Amount Claimed Submitted Qualifier Value Not Supported' },
  '535': { appliesToFields: ['308-C8'], text: 'Other Coverage Code Value Not Supported' },
  '536': {
    appliesToFields: ['351-NP'],
    text: 'Other Payer-Patient Responsibility Amount Qualifier Value Not Supported',
  },
  '537': {
    appliesToFields: ['391-MT'],
    text: 'Patient Assignment Indicator (Direct Member Reimbursement Indicator) Value Not Supported',
  },
  '538': { appliesToFields: ['305-C5'], text: 'Patient Gender Code Value Not Supported' },
  '539': { appliesToFields: ['324-CO'], text: 'Patient State/Province Address Value Not Supported' },
  '540': { appliesToFields: ['123-TX'], text: 'Pay to State/ Province Address Value Not Supported' },
  '541': { appliesToFields: ['484-JE'], text: 'Percentage Sales Tax Basis Submitted Value Not Supported' },
  '542': { appliesToFields: ['335-2C'], text: 'Pregnancy Indicator Value Not Supported' },
  '543': { appliesToFields: ['466-EZ'], text: 'Prescriber ID Qualifier Value Not Supported' },
  '544': { appliesToFields: ['367-2N'], text: 'Prescriber State/Province Address Value Not Supported' },
  '545': { appliesToFields: ['419-DJ'], text: 'Prescription Origin Code Value Not Supported' },
  '546': { appliesToFields: ['468-2E'], text: 'Primary Care Provider ID Qualifier Value Not Supported' },
  '547': { appliesToFields: ['461-EU'], text: 'Prior Authorization Type Code Value Not Supported' },
  '548': { appliesToFields: ['361-2D'], text: 'Provider Accept Assignment Indicator Value Not Supported' },
  '549': { appliesToFields: ['465-EY'], text: 'Provider ID Qualifier Value Not Supported' },
  '550': { appliesToFields: ['373-2U'], text: 'Request Status Value Not Supported' },
  '551': { appliesToFields: ['498-PA'], text: 'Request Type Value Not Supported' },
  '552': { appliesToFields: ['995-E2'], text: 'Route of Administration Value Not Supported' },
  '553': { appliesToFields: ['334-1C'], text: 'Smoker/Non-Smoker Code Value Not Supported' },
  '554': { appliesToFields: ['429-DT'], text: 'Special Packaging Indicator Value Not Supported' },
  '555': { appliesToFields: ['109-A9'], text: 'Transaction Count Value Not Supported' },
  '556': { appliesToFields: ['600-28'], text: 'Unit Of Measure Value Not Supported' },
  '557': { appliesToFields: ['308-C8'], text: 'COB Segment Present On A Non-COB Claim' },
  '558': {
    appliesToFields: ['Not Provided'],
    text: 'Part D Plan cannot coordinate benefits with another Part D Plan.',
  },
  '559': { appliesToFields: ['201-B1'], text: 'ID Submitted is associated with a Sanctioned Pharmacy' },
  '560': { appliesToFields: ['201-B1'], text: 'Pharmacy Not Contracted in Retail Network' },
  '561': { appliesToFields: ['201-B1'], text: 'Pharmacy Not Contracted in Mail Order Network' },
  '562': { appliesToFields: ['201-B1'], text: 'Pharmacy Not Contracted in Hospice Network' },
  '563': { appliesToFields: ['201-B1'], text: 'Pharmacy Not Contracted in Veterans Administration Network' },
  '564': { appliesToFields: ['201-B1'], text: 'Pharmacy Not Contracted in Military Network' },
  '565': { appliesToFields: ['A43-1K'], text: 'Patient Country Code Value Not Supported' },
  '566': { appliesToFields: ['A43-1K'], text: 'Patient Country Code Not Used For This Transaction' },
  '567': { appliesToFields: ['A45-1R'], text: 'Missing/Invalid Veterinary Use Indicator' },
  '568': { appliesToFields: ['A45-1R'], text: 'Veterinary Use Indicator Value Not Supported' },
  '569': {
    appliesToFields: ['Not Provided'],
    text: 'Provide Notice: Medicare Prescription Drug Coverage and Your Rights',
  },
  '570': { appliesToFields: ['A45-1R'], text: 'Veterinary Use Indicator Not Used For This Transaction' },
  '571': { appliesToFields: ['A22-YR'], text: 'Patient ID Associated State/Province Address Value Not Supported' },
  '572': { appliesToFields: ['359-2A'], text: 'Medigap ID Not Covered' },
  '573': {
    appliesToFields: ['A27-ZQ'],
    text: 'Prescriber Alternate ID Associated State/Province Address Value Not Supported',
  },
  '574': { appliesToFields: ['362-2G'], text: 'Compound Ingredient Modifier Code Not Covered' },
  '575': { appliesToFields: ['675-Y3'], text: 'Purchaser State/Province Address Value Not Supported' },
  '576': { appliesToFields: ['586-YP'], text: 'Service Provider State/Province Address Value Not Supported' },
  '577': { appliesToFields: ['355-NT'], text: 'Missing/Invalid Other Payer ID' },
  '578': { appliesToFields: ['355-NT'], text: 'Other Payer ID Count Does Not Match Number of Repetitions' },
  '579': { appliesToFields: ['355-NT'], text: 'Other Payer ID Count Exceeds Number Of Occurrences Supported' },
  '580': { appliesToFields: ['355-NT'], text: 'Other Payer ID Count Grouping Incorrect' },
  '581': { appliesToFields: ['355-NT'], text: 'Other Payer ID Count is not used for this Transaction Code' },
  '582': { appliesToFields: ['403-D3'], text: 'Missing/Invalid Fill Number' },
  '583': { appliesToFields: ['444-E9'], text: 'Provider ID Not Covered' },
  '584': { appliesToFields: ['593-YW'], text: 'Purchaser ID Associated State/Province Code Value Not Supported' },
  '585': { appliesToFields: ['403-D3'], text: 'Fill Number Value Not Supported' },
  '586': { appliesToFields: ['336-8C'], text: 'Facility ID Not Covered' },
  '587': { appliesToFields: ['327-CR'], text: 'Carrier ID Not Covered' },
  '588': { appliesToFields: ['330-CW'], text: 'Alternate ID Not Covered' },
  '589': { appliesToFields: ['332-CY'], text: 'Patient ID Not Covered' },
  '590': { appliesToFields: ['450-EF'], text: 'Compound Dosage Form Not Covered' },
  '591': { appliesToFields: ['524-FO'], text: 'Plan ID Not Covered' },
  '592': { appliesToFields: ['476-H6'], text: 'DUR Co-Agent ID Not Covered' },
  '593': { appliesToFields: ['401-D1'], text: 'Missing/Invalid Date of Service' },
  '594': { appliesToFields: ['119-TT'], text: 'Pay To ID Not Covered' },
  '595': { appliesToFields: ['580-XY'], text: 'Associated Prescription/Service Provider ID Not Covered' },
  '596': { appliesToFields: ['A32-ZW'], text: 'Compound Preparation Time Not Used For This Transaction Code' },
  '597': { appliesToFields: ['420-DK', '429-DT'], text: 'LTC Dispensing Type Does Not Support The Packaging Type' },
  '598': { appliesToFields: ['Not Provided'], text: 'More Than One Patient Found' },
  '599': { appliesToFields: ['Not Provided'], text: 'Cardholder ID Matched But Last Name Did Not' },
  '600': { appliesToFields: ['Not Provided'], text: 'Coverage Outside Submitted Date Of Service' },
  '601': {
    appliesToFields: ['463-EW 464-EX'],
    text: 'Intermediary Authorization Type ID Does Not Precede Intermediary Authorization ID',
  },
  '602': {
    appliesToFields: ['579-XX 580-XY'],
    text: 'Associated Prescription/Service Provider ID Qualifier Does Not Precede Associated Prescription/ Service Provider ID',
  },
  '603': {
    appliesToFields: ['A25-ZM A26-ZP'],
    text: 'Prescriber Alternate ID Qualifier Does Not Precede Prescriber Alternate ID',
  },
  '604': { appliesToFields: ['591-YU 592-YV'], text: 'Purchaser ID Qualifier Does Not Precede Purchaser ID' },
  '605': { appliesToFields: ['680-ZB 679-Y9'], text: 'Seller ID Qualifier Does Not Precede Seller ID' },
  '606': { appliesToFields: ['407-D7'], text: 'Brand Drug / Specific Labeler Code Required' },
  '607': {
    appliesToFields: ['Not Provided'],
    text: 'Information Reporting Transaction Cannot Be Matched To A Paid Claim',
  },
  '608': {
    appliesToFields: ['407-D7'],
    text: 'Step Therapy, Alternate Drug Therapy Required Prior To Use Of Submitted Product Service ID',
  },
  '609': {
    appliesToFields: ['Not Provided'],
    text: 'COB Claim Not Required, Patient Liability Amount Submitted Was Zero',
  },
  '610': {
    appliesToFields: ['Not Provided'],
    text: 'Supplemental Claim Could Not Be Matched To A Claim Covered By Part D Plan',
  },
  '611': {
    appliesToFields: ['Not Provided'],
    text: 'Supplemental Claim Was Matched To A Claim Covered By A Benefit Other Than Part D',
  },
  '612': {
    appliesToFields: ['420-DK'],
    text: 'LTC Appropriate Dispensing Invalid Submission Clarification Code (SCC) Combination',
  },
  '613': {
    appliesToFields: ['420-DK', '429-DT'],
    text: 'The Packaging Methodology Or Dispensing Frequency Is Missing Or Inappropriate For LTC Short Cycle',
  },
  '614': { appliesToFields: ['Not Provided'], text: 'Uppercase Character(s) Required' },
  '615': {
    appliesToFields: ['490-UE'],
    text: 'Compound Ingredient Basis Of Cost Determination Value 14 Required When Compound Ingredient Quantity Is 0 But Cost Is Greater Than $0',
  },
  '616': {
    appliesToFields: ['448-ED'],
    text: 'Submission Clarification Code 8 Required When Compound Ingredient Quantity Is 0',
  },
  '617': { appliesToFields: ['449-EE'], text: 'Compound Ingredient Drug Cost Cannot Be Negative Amount' },
  '618': {
    appliesToFields: ['411-DB', '420-DK'],
    text: "Plan's Prescriber Data Base Indicates The Submitted Prescriber's DEA Does Not Allow This Drug DEA Schedule",
  },
  '619': { appliesToFields: ['411-DB'], text: 'Prescriber Type 1 NPI Required' },
  '620': { appliesToFields: ['407-D7'], text: 'This Product/Service May Be Covered Under Medicare Part D' },
  '621': { appliesToFields: ['Not Provided'], text: 'This Medicaid Patient Is Medicare Eligible' },
  '622': {
    appliesToFields: ['Not Provided'],
    text: 'COB Claim not required, patient liability amount submitted was zero',
  },
  '623': { appliesToFields: ['B34-1U'], text: 'Missing/Invalid Authorized Representative Country Code' },
  '624': { appliesToFields: ['B35-1V'], text: 'Missing/Invalid Employer Country Code' },
  '625': { appliesToFields: ['B36-1W'], text: 'Missing/Invalid Entity Country Code' },
  '627': { appliesToFields: ['B37-1X'], text: 'Missing/Invalid Facility Country Code' },
  '628': { appliesToFields: ['B38-1Y'], text: 'Missing/Invalid Patient ID Associated Country Code' },
  '629': { appliesToFields: ['B39-1Z'], text: 'Missing/Invalid Pay to Country Code' },
  '630': { appliesToFields: ['B40-3A'], text: 'Missing/Invalid Prescriber Alternate ID Associated Country Code' },
  '631': { appliesToFields: ['B41-3B'], text: 'Missing/Invalid Prescriber ID Associated Country Code' },
  '632': { appliesToFields: ['B42-3C'], text: 'Missing/Invalid Prescriber Country Code' },
  '633': { appliesToFields: ['B43-3D'], text: 'Missing/Invalid Purchaser ID Associated Country Code' },
  '634': { appliesToFields: ['B34-1U'], text: 'Authorized Representative Country Code Value Not Supported' },
  '635': { appliesToFields: ['B35-1V'], text: 'Employer Country Code Value Not Supported' },
  '637': { appliesToFields: ['B36-1W'], text: 'Entity Country Code Value Not Supported' },
  '638': { appliesToFields: ['B37-1X'], text: 'Facility Country Code Value Not Supported' },
  '639': { appliesToFields: ['B38-1Y'], text: 'Patient ID Associated Country Code Value Not Supported' },
  '640': { appliesToFields: ['B39-1Z'], text: 'Pay to Country Code Value Not Supported' },
  '641': {
    appliesToFields: ['B40-3A'],
    text: 'Prescriber Alternate ID Associated Country Code Value Not Supported',
  },
  '642': { appliesToFields: ['B41-3B'], text: 'Prescriber ID Associated Country Code Value Not Supported' },
  '643': { appliesToFields: ['B42-3C'], text: 'Prescriber Country Code Value Not Supported' },
  '644': { appliesToFields: ['B43-3D'], text: 'Purchaser ID Associated Country Code Value Not Supported' },
  '645': { appliesToFields: ['407-D7'], text: 'Repackaged product is not covered by the contract.' },
  '646': {
    appliesToFields: ['Not Provided'],
    text: 'Patient Not Eligible Due To Non Payment Of Premium. Patient To Contact Plan.',
  },
  '647': { appliesToFields: ['407-D7 460-ET'], text: 'Quantity Prescribed Required For CII Prescription' },
  '648': {
    appliesToFields: ['407-D7 460-ET'],
    text: 'Quantity Prescribed Does Not Match Quantity Prescribed On Original CII Dispensing',
  },
  '649': {
    appliesToFields: ['Not Provided'],
    text: 'Cumulative Quantity For This CII Rx Number Exceeds Quantity Prescribed',
  },
  '650': {
    appliesToFields: ['414-DE'],
    text: 'Fill Date Greater Than 60 Days From CII Date Prescription Written (414-DE).',
  },
  '651': { appliesToFields: ['Not Provided'], text: 'REMS: Mandatory data element(s) missing' },
  '652': { appliesToFields: ['411-DB'], text: 'REMS: Prescriber not matched or may not be enrolled' },
  '653': {
    appliesToFields: ['310-CA 311-CB 304-C4 325-CP'],
    text: 'REMS: Patient not matched or may not be enrolled.',
  },
  '654': { appliesToFields: ['201-B1'], text: 'REMS: Pharmacy not matched or may not be enrolled.' },
  '655': { appliesToFields: ['310-CA 311-CB 304-C4 325-CP'], text: 'REMS: Multiple patient matches.' },
  '656': { appliesToFields: ['304-C4'], text: 'REMS: Patient age not matched.' },
  '657': { appliesToFields: ['305-C5'], text: 'REMS: Patient gender not matched.' },
  '658': { appliesToFields: ['201-B1'], text: 'REMS: Pharmacy has not enrolled.' },
  '659': { appliesToFields: ['201-B1'], text: 'REMS: Pharmacy has not renewed enrollment.' },
  '660': { appliesToFields: ['201-B1'], text: 'REMS: Pharmacy has not submitted agreement form.' },
  '661': { appliesToFields: ['201-B1'], text: 'REMS: Pharmacy has been suspended due to non-compliance.' },
  '662': { appliesToFields: ['411-DB'], text: 'REMS: Prescriber has not enrolled.' },
  '663': { appliesToFields: ['411-DB'], text: 'REMS: Prescriber has not completed a knowledge assessment.' },
  '664': { appliesToFields: ['411-DB'], text: 'REMS: Prescriber has been suspended due to non-compliance.' },
  '665': { appliesToFields: ['405-D5'], text: 'REMS: Excessive days supply.' },
  '666': { appliesToFields: ['405-D5'], text: 'REMS: Insufficient days supply.' },
  '667': { appliesToFields: ['405-D5'], text: 'REMS: Excessive dosage.' },
  '668': { appliesToFields: ['405-D5'], text: 'REMS: Insufficient dosage.' },
  '669': { appliesToFields: ['403-D3 415-DF'], text: 'REMS: Refills not permitted.' },
  '670': { appliesToFields: ['Not Provided'], text: 'REMS: Laboratory test results not documented.' },
  '671': {
    appliesToFields: ['Not Provided'],
    text: 'REMS: Laboratory test not conducted within specified time period.',
  },
  '672': { appliesToFields: ['Not Provided'], text: 'REMS: Dispensing not authorized due to laboratory test results.' },
  '673': { appliesToFields: ['411-DB'], text: 'REMS: Prescriber counseling of patient not documented.' },
  '674': { appliesToFields: ['411-DB'], text: 'REMS: Prescriber has not documented safe use conditions.' },
  '675': { appliesToFields: ['411-DB'], text: 'REMS: Prescriber has not documented patient opioid tolerance.' },
  '676': { appliesToFields: ['411-DB'], text: 'REMS: Prescriber has not documented patient contraceptive use.' },
  '677': { appliesToFields: ['Not Provided'], text: 'REMS: Lack of contraindicated therapy not documented.' },
  '678': { appliesToFields: ['Not Provided'], text: 'REMS: Step therapy not documented.' },
  '679': { appliesToFields: ['411-DB'], text: 'REMS: Prescriber has not enrolled patient.' },
  '680': { appliesToFields: ['411-DB'], text: 'REMS: Prescriber must renew patient enrollment.' },
  '681': { appliesToFields: ['Not Provided'], text: 'REMS: Patient enrollment requirements have not been met.' },
  '682': { appliesToFields: ['411-DB'], text: 'REMS: Prescriber has not submitted patient agreement.' },
  '683': { appliesToFields: ['411-DB'], text: "REMS: Prescriber has not verified patient's reproductive potential." },
  '684': { appliesToFields: ['Not Provided'], text: 'REMS: Patient has not documented safe use conditions.' },
  '685': { appliesToFields: ['Not Provided'], text: 'REMS: Patient has not documented completed education.' },
  '686': { appliesToFields: ['Not Provided'], text: 'REMS: Patient has not documented contraceptive use.' },
  '687': { appliesToFields: ['Not Provided'], text: 'REMS Administrator Denied' },
  '688': { appliesToFields: ['Not Provided'], text: 'REMS: Service Billing Denied' },
  '689': { appliesToFields: ['Not Provided'], text: 'PDMP Administrator Denied' },
  '690': { appliesToFields: ['201-B1'], text: 'PDMP: Pharmacy not contracted' },
  '691': { appliesToFields: ['201-B1'], text: 'PDMP: Pharmacy contract not renewed.' },
  '692': { appliesToFields: ['310-CA'], text: 'PDMP: Missing/Invalid Patient First Name' },
  '693': { appliesToFields: ['311-CB'], text: 'PDMP: Missing/Invalid Patient Last Name' },
  '694': { appliesToFields: ['B08-7A B09-7B'], text: 'PDMP: Missing/Invalid Patient Street Address' },
  '695': { appliesToFields: ['323-CN'], text: 'PDMP: Missing/Invalid Patient City' },
  '696': { appliesToFields: ['324-CO'], text: 'PDMP: Missing/Invalid Patient State or Province' },
  '697': { appliesToFields: ['325-CP'], text: 'PDMP: Missing/Invalid Patient ZIP/Postal Code' },
  '698': { appliesToFields: ['411-DB'], text: 'PDMP: Missing/Invalid Prescriber ID' },
  '699': { appliesToFields: ['427-DR'], text: 'PDMP: Missing/Invalid Prescriber Last Name' },
  '700': { appliesToFields: ['332-CY'], text: 'PDMP: Missing/Invalid Patient ID' },
  '701': { appliesToFields: ['304-C4'], text: 'PDMP: Missing/Invalid Patient Date of Birth' },
  '702': { appliesToFields: ['305-C5'], text: 'PDMP: Missing/Invalid Patient Gender' },
  '703': { appliesToFields: ['419-DJ'], text: 'PDMP: Missing/Invalid Prescription Origin Code' },
  '704': { appliesToFields: ['454-EK'], text: 'PDMP: Missing/Invalid Scheduled Rx Serial Number' },
  '705': { appliesToFields: ['407-D7', '489-TE'], text: 'PDMP: Missing/Invalid Product/Service ID' },
  '706': { appliesToFields: ['406-D6'], text: 'PDMP: Missing/Invalid Compound Code' },
  '707': { appliesToFields: ['326-CQ'], text: 'PDMP: Missing/Invalid Patient Phone Number' },
  '708': { appliesToFields: ['A29-ZS'], text: 'PDMP: Missing/Invalid Payment Code' },
  '769': {
    appliesToFields: ['Not Provided'],
    text: 'Medicare Part D Paid claim (B1/B3) found, but Information Reporting Reversal (N2) or Information Reporting Rebill (N3) cannot be matched to an Information Reporting (N1/N3) transaction.',
  },
  '770': {
    appliesToFields: ['Not Provided'],
    text: 'Medicare Part D Paid claim (B1/B3) not found, Information Reporting transaction (N1/N3) not found, Information Reporting Reversal (N2) not processed.',
  },
  '771': {
    appliesToFields: ['489-TE'],
    text: 'Compound contains unidentifiable ingredient(s); Submission Clarification Code override not allowed',
  },
  '772': {
    appliesToFields: ['489-TE'],
    text: 'Compound not payable due to non-covered ingredient(s); Submission Clarification Code override not allowed',
  },
  '773': { appliesToFields: ['411-DB'], text: 'Prescriber Is Not Listed On Medicare Enrollment File' },
  '774': {
    appliesToFields: ['411-DB'],
    text: 'Prescriber Medicare Enrollment Period Is Outside Of Claim Date Of Service',
  },
  '775': {
    appliesToFields: ['201-B1'],
    text: 'Pharmacy not listed within Medicare Fee For Service active enrollment file',
  },
  '776': { appliesToFields: ['201-B1'], text: 'Pharmacy enrollment with Medicare Fee For Service has terminated' },
  '777': {
    appliesToFields: ['Not Provided'],
    text: "Plan's Prescriber data base not able to verify active state license with prescriptive authority for Prescriber ID Submitted",
  },
  '816': {
    appliesToFields: ['Not Provided'],
    text: "Pharmacy Benefit Exclusion, May Be Covered Under Patient's Medical Benefit",
  },
  '817': {
    appliesToFields: ['Not Provided'],
    text: "Pharmacy Benefit Exclusion, Covered Under Patient's Medical Benefit",
  },
  '818': { appliesToFields: ['Not Provided'], text: 'Medication Administration Not Covered, Plan Benefit Exclusion' },
  '819': { appliesToFields: ['Not Provided'], text: 'Plan Enrollment File Indicates Medicare As Primary Coverage' },
  '820': {
    appliesToFields: ['Not Provided'],
    text: 'Information Reporting Transaction (N1/N3) Matched To Reversed Or Rejected Claim Not Submitted Under Part D BIN PCN',
  },
  '821': {
    appliesToFields: ['Not Provided'],
    text: 'Information Reporting (N1/N3) Transaction Matched To Paid Claim Not Submitted Under Part D BIN PCN',
  },
  '822': {
    appliesToFields: ['Not Provided'],
    text: 'Drug Is Unrelated To The Terminal Illness And/Or Related Conditions. Not Covered Under Hospice.',
  },
  '823': {
    appliesToFields: ['Not Provided'],
    text: "Drug Is Beneficiary's Liability - Not Covered By Hospice Or Part D. Hospice Non-Formulary. Check Other Coverage.",
  },
  '824': { appliesToFields: ['109-A9'], text: 'Multi-transaction transmission not allowed in current NCPDP standard' },
  '825': {
    appliesToFields: ['407-D7', '489-TE'],
    text: "Claim Date Of Service Is Outside Of Product's FDA/NSDE Marketing Dates",
  },
  '826': {
    appliesToFields: ['466-EZ', '411-DB'],
    text: "Prescriber NPI Submitted Not Found Within Processor's NPI File",
  },
  '827': {
    appliesToFields: ['Not Provided'],
    text: 'Pharmacy Service Provider Is Temporarily Suspended From Processing Claims By Payer/Processor',
  },
  '828': { appliesToFields: ['Not Provided'], text: 'Plan/Beneficiary Case Management Restriction In Place' },
  '829': {
    appliesToFields: ['Not Provided'],
    text: 'Pharmacy Must Notify Beneficiary: Claim Not Covered Due To Failure To Meet Medicare Part D Active, Valid Prescriber NPI Requirements',
  },
  '830': {
    appliesToFields: ['Not Provided'],
    text: "Workers' Comp Or P&C Adjuster Authorization Required - Patient Must Directly Contact Their Adjuster",
  },
  '831': { appliesToFields: ['Not Provided'], text: 'Product Service ID Carve-Out, Bill Medicaid Fee For Service' },
  '832': {
    appliesToFields: ['Not Provided'],
    text: 'Prescriber NPI Not Found, Therefore NPI Active Status, MEDICARE Enrollment, Prescriptive Authority Could Not Be Validated',
  },
  '833': { appliesToFields: ['Not Provided'], text: 'Accumulator Year Is Not Within ATBT Timeframe' },
  '834': { appliesToFields: ['B96-4A'], text: 'M/I Provider First Name' },
  '835': { appliesToFields: ['B97-4M'], text: 'M/I Provider Last Name' },
  '836': { appliesToFields: ['B95-3Z'], text: 'M/I Facility ID Qualifier' },
  '837': { appliesToFields: ['B95-3Z'], text: 'Facility ID Value Not Supported' },
  '838': { appliesToFields: ['C01-4N'], text: 'M/I Original Manufacturer Product ID' },
  '839': { appliesToFields: ['C02-4P'], text: 'M/I Original Manufacturer Product ID Qualifier' },
  '840': { appliesToFields: ['C01-4N'], text: 'Original Manufacturer Product ID Value Not Supported' },
  '844': { appliesToFields: ['C47-9T'], text: 'M/I Other Payer Adjudicated Program Type' },
  '845': { appliesToFields: ['C49-9V'], text: 'Other Payer Reconciliation ID Is Not Used For This Transaction Code' },
  '846': { appliesToFields: ['C50-9W'], text: 'Benefit Stage Indicator Count Is Not Used For This Transaction Code' },
  '847': {
    appliesToFields: ['C50-9W'],
    text: 'Benefit Stage Indicator Count Does Not Precede Benefit Stage Indicator',
  },
  '848': { appliesToFields: ['C50-9W'], text: 'M/I Benefit Stage Indicator Count' },
  '849': { appliesToFields: ['C50-9W'], text: 'Benefit Stage Indicator Count Does Not Match Number of Repetitions' },
  '850': { appliesToFields: ['C51-9X'], text: 'Benefit Stage Indicator Is Not Used For This Transaction Code' },
  '851': { appliesToFields: ['C51-9X'], text: 'Benefit Stage Indicator Value Not Supported' },
  '852': { appliesToFields: ['C51-9X'], text: 'M/I Benefit Stage Indicator' },
  '853': { appliesToFields: ['C52-9Y'], text: 'N Payer IIN Is Not Used For This Transaction Code' },
  '854': { appliesToFields: ['C52-9Y'], text: 'M/I N Payer IIN' },
  '855': { appliesToFields: ['C52-9Y'], text: 'Non-Matched N Payer IIN' },
  '856': {
    appliesToFields: ['C53-9Z'],
    text: 'N Payer Processor Control Number Is Not Used For This Transaction Code',
  },
  '857': { appliesToFields: ['C53-9Z'], text: 'M/I N Payer Processor Control Number' },
  '858': { appliesToFields: ['C53-9Z'], text: 'Non-Matched N Payer Processor Control Number' },
  '859': { appliesToFields: ['C55-AB'], text: 'N Payer Group ID Is Not Used For This Transaction Code' },
  '860': { appliesToFields: ['C55-AB'], text: 'M/I N Payer Group ID' },
  '861': { appliesToFields: ['C55-AB'], text: 'Non-Matched N Payer Group ID' },
  '862': { appliesToFields: ['C54-AA'], text: 'N Payer Cardholder ID Is Not Used For This Transaction Code' },
  '863': { appliesToFields: ['C54-AA'], text: 'M/I N Payer Cardholder ID' },
  '864': { appliesToFields: ['C54-AA'], text: 'N Payer Cardholder ID Is Not Covered' },
  '865': {
    appliesToFields: ['C48-9U'],
    text: 'N Payer Adjudicated Program Type Is Not Used For This Transaction Code',
  },
  '866': { appliesToFields: ['C48-9U'], text: 'M/I N Payer Adjudicated Program Type' },
  '867': { appliesToFields: ['C48-9U'], text: 'N Payer Adjudicated Program Type Value Not Supported' },
  '868': { appliesToFields: ['C57-AD'], text: 'M/I N Transaction Reconciliation ID' },
  '869': { appliesToFields: ['C56-AC'], text: 'M/I N Transaction Source Type' },
  '870': { appliesToFields: ['D01-KV'], text: 'M/I Prescriber DEA Number' },
  '871': { appliesToFields: ['C60-AG'], text: 'M/I Compound Level Of Complexity' },
  '872': {
    appliesToFields: ['C60-AG', 'C99-KU'],
    text: 'Mismatch Between Compound Level Of Complexity And Preparation Environment Type',
  },
  '873': { appliesToFields: ['C99-KU'], text: 'M/I Preparation Environment Type' },
  '874': { appliesToFields: ['C98-KT'], text: 'M/I Preparation Environment Event Code' },
  '875': { appliesToFields: ['D02-KW'], text: 'M/I Total Prescribed Quantity Remaining' },
  '876': { appliesToFields: ['Not Provided'], text: 'Prescriptive Authority Restrictions Apply, Criteria Not Met' },
  '877': { appliesToFields: ['Not Provided'], text: 'Service Provider ID Terminated On NPPES File' },
  '878': { appliesToFields: ['Not Provided'], text: 'Service Provider ID Not Found On NPPES File' },
  '879': { appliesToFields: ['Not Provided'], text: 'Service Provider ID Excluded From Receiving CMS Enrollment Data' },
  '880': { appliesToFields: ['D17-K8'], text: 'M/I Submission Type Code' },
  '881': { appliesToFields: ['D16-KZ'], text: 'Missing Submission Type Code Count' },
  '882': { appliesToFields: ['D18-K9'], text: 'M/I Do Not Dispense Before Date' },
  '883': { appliesToFields: ['D18-K9'], text: 'Date of Service Prior To Do Not Dispense Before Date' },
  '884': { appliesToFields: ['D22-M4'], text: 'M/I Multiple RX Order Group Reason Code' },
  '885': { appliesToFields: ['D21-M3'], text: 'M/I Multiple RX Order Group ID' },
  '886': { appliesToFields: ['D57-RG'], text: 'M/I Prescriber Place of Service' },
  '887': {
    appliesToFields: ['Not Provided'],
    text: 'A Previous Payer(s) Is An Excluded Federal Health Care Program Copay Assistance Is Not Allowed',
  },
  '888': { appliesToFields: ['Not Provided'], text: 'Beneficiary Is Enrolled In Excluded Federal Health Care Program' },
  '889': { appliesToFields: ['Not Provided'], text: 'Prescriber Not Enrolled in State Medicaid Program' },
  '890': { appliesToFields: ['Not Provided'], text: 'Pharmacy Not Enrolled in State Medicaid Program' },
  '891': { appliesToFields: ['405-D5'], text: 'Days Supply Is Less Than Plan Minimum' },
  '892': { appliesToFields: ['Not Provided'], text: 'Pharmacy Must Attest FDA REMS Requirements Have Been Met' },
  '893': { appliesToFields: ['Not Provided'], text: 'Pharmacy Must Attest Required Patient Form Is On File' },
  '894': {
    appliesToFields: ['Not Provided'],
    text: 'Pharmacy Must Attest Plan Medical Necessity Criteria Has Been Met',
  },
  '895': { appliesToFields: ['420-DK'], text: 'Allowed Number of Overrides Exhausted' },
  '896': { appliesToFields: ['Not Provided'], text: 'Other Adjudicated Program Type Of Unknown Is Not Covered' },
  '897': { appliesToFields: ['D60-RK'], text: 'M/I Regulatory Fee Count' },
  '898': { appliesToFields: ['D61-RL'], text: 'M/I Regulatory Fee Type Code' },
  '899': { appliesToFields: ['D51-P7'], text: 'M/I Other Payer Percentage Tax Exempt Indicator' },
  '921': { appliesToFields: ['D52-P8'], text: 'Other Payer Regulatory Fee Exempt Indicator Value Not Supported' },
  '932': { appliesToFields: ['E09-0C'], text: 'M/I Patient Middle Name' },
  '933': { appliesToFields: ['E10-0D'], text: 'M/I Patient Name Suffix' },
  '934': { appliesToFields: ['E11-0E'], text: 'M/I Patient Name Prefix' },
  '938': { appliesToFields: ['E12-0F'], text: 'M/I Prescriber Middle Name' },
  '942': { appliesToFields: ['E06-S8'], text: 'M/I Species' },
  '943': { appliesToFields: ['Not Provided'], text: 'DUR Reject - Pharmacy Override Using DUR/PPS Not Allowed' },
  '944': { appliesToFields: ['439-E4'], text: 'All Lots Of Drug/Product Recalled' },
  '945': { appliesToFields: ['Not Provided'], text: 'High Dollar Amount Is Not Supported' },
  A1: { appliesToFields: ['411-DB'], text: 'ID Submitted is associated with a Sanctioned Prescriber' },
  A2: { appliesToFields: ['411-DB'], text: 'ID Submitted is associated to a Deceased Prescriber' },
  A3: { appliesToFields: ['Not Provided'], text: 'This Product May Be Covered Under Hospice - Medicare A' },
  A4: {
    appliesToFields: ['Not Provided'],
    text: 'This Product May Be Covered Under The Medicare- B Bundled Payment To An ESRD Dialysis Facility',
  },
  A5: { appliesToFields: ['302-C2', '401-D1', '407-D7'], text: 'Not Covered Under Part D Law' },
  A6: {
    appliesToFields: ['302-C2', '401-D1', '407-D7'],
    text: 'This Product/Service May Be Covered Under Medicare Part B',
  },
  A7: { appliesToFields: ['993-A7'], text: 'Missing/Invalid Internal Control Number' },
  A9: { appliesToFields: ['109-A9'], text: 'Missing/Invalid Transaction Count' },
  AA: { appliesToFields: ['302-C2', '401-D1', '407-D7'], text: 'Patient Spenddown Not Met' },
  AB: { appliesToFields: ['401-D1'], text: 'Date Written Is After Date Filled' },
  AC: { appliesToFields: ['489-TE', '407-D7'], text: 'Product Not Covered Non-Participating Manufacturer' },
  AD: {
    appliesToFields: ['302-C2', '401-D1', '407-D7'],
    text: 'Billing Provider Not Eligible To Bill This Claim Type',
  },
  AE: { appliesToFields: ['302-C2'], text: 'QMB (Qualified Medicare Beneficiary)-Bill Medicare' },
  AF: { appliesToFields: ['302-C2'], text: 'Patient Enrolled Under Managed Care' },
  AG: { appliesToFields: ['489-TE', '407-D7'], text: 'Days Supply Limitation For Product/Service' },
  AH: { appliesToFields: ['302-C2', '407-D7'], text: 'Unit Dose Packaging Only Payable For Nursing Home Recipients' },
  AJ: { appliesToFields: ['489-TE', '407-D7'], text: 'Generic Drug Required' },
  AK: { appliesToFields: ['110-AK'], text: 'Missing/Invalid Software Vendor/Certification ID' },
  AM: { appliesToFields: ['111-AM'], text: 'Missing/Invalid Segment Identification' },
  AQ: { appliesToFields: ['111-AM'], text: 'Missing/Invalid Facility Segment' },
  B2: { appliesToFields: ['202-B2'], text: 'Missing/Invalid Service Provider ID Qualifier' },
  BA: { appliesToFields: ['490-UE'], text: 'Compound Basis of Cost Determination Submitted Not Covered' },
  BB: { appliesToFields: ['492-WE'], text: 'Diagnosis Code Qualifier Submitted Not Covered' },
  BC: { appliesToFields: ['494-ZE'], text: 'Future Measurement Date Not Allowed' },
  BE: { appliesToFields: ['477-BE'], text: 'Missing/Invalid Professional Service Fee Submitted' },
  BM: { appliesToFields: ['390-BM'], text: 'Missing/Invalid Narrative Message' },
  CA: { appliesToFields: ['310-CA'], text: 'Missing/Invalid Patient First Name' },
  CB: { appliesToFields: ['311-CB'], text: 'Missing/Invalid Patient Last Name' },
  CC: { appliesToFields: ['312-CC'], text: 'Missing/Invalid Cardholder First Name' },
  CD: { appliesToFields: ['313-CD'], text: 'Missing/Invalid Cardholder Last Name' },
  CE: { appliesToFields: ['314-CE'], text: 'Missing/Invalid Home Plan' },
  CF: { appliesToFields: ['315-CF'], text: 'Missing/Invalid Employer Name' },
  CG: { appliesToFields: ['316-CG'], text: 'Missing/Invalid Employer Street Address' },
  CH: { appliesToFields: ['317-CH'], text: 'Missing/Invalid Employer City Address' },
  CI: { appliesToFields: ['318-CI'], text: 'Missing/Invalid Employer State/Province Address' },
  CJ: { appliesToFields: ['319-CJ'], text: 'Missing/Invalid Employer Zip Postal Zone' },
  CK: { appliesToFields: ['320-CK'], text: 'Missing/Invalid Employer Phone Number' },
  CL: { appliesToFields: ['321-CL'], text: 'Missing/Invalid Employer Contact Name' },
  CM: { appliesToFields: ['322-CM'], text: 'Missing/Invalid Patient Street Address' },
  CN: { appliesToFields: ['323-CN'], text: 'Missing/Invalid Patient City Address' },
  CO: { appliesToFields: ['324-CO'], text: 'Missing/Invalid Patient State/Province Address' },
  CP: { appliesToFields: ['325-CP'], text: 'Missing/Invalid Patient Zip/Postal Zone' },
  CQ: { appliesToFields: ['326-CQ'], text: 'Missing/Invalid Patient Phone Number' },
  CR: { appliesToFields: ['327-CR'], text: 'Missing/Invalid Carrier ID' },
  CW: { appliesToFields: ['330-CW'], text: 'Missing/Invalid Alternate ID' },
  CX: { appliesToFields: ['331-CX'], text: 'Missing/Invalid Patient ID Qualifier' },
  CY: { appliesToFields: ['332-CY'], text: 'Missing/Invalid Patient ID' },
  CZ: { appliesToFields: ['333-CZ'], text: 'Missing/Invalid Employer ID' },
  DC: { appliesToFields: ['412-DC'], text: 'Missing/Invalid Dispensing Fee Submitted' },
  DN: { appliesToFields: ['423-DN', '490-UE'], text: 'Missing/Invalid Basis Of Cost Determination' },
  DQ: { appliesToFields: ['426-DQ'], text: 'Missing/Invalid Usual And Customary Charge' },
  DR: { appliesToFields: ['427-DR'], text: 'Missing/Invalid Prescriber Last Name' },
  DT: { appliesToFields: ['429-DT'], text: 'Missing/Invalid Special Packaging Indicator' },
  DU: { appliesToFields: ['430-DU'], text: 'Missing/Invalid Gross Amount Due' },
  DV: { appliesToFields: ['431-DV'], text: 'Missing/Invalid Other Payer Amount Paid' },
  DX: { appliesToFields: ['433-DX'], text: 'Missing/Invalid Patient Paid Amount Submitted' },
  DY: { appliesToFields: ['434-DY'], text: 'Missing/Invalid Date Of Injury' },
  DZ: { appliesToFields: ['435-DZ'], text: 'Missing/Invalid Claim/Reference ID' },
  E1: { appliesToFields: ['436-E1', '488-RE'], text: 'Missing/Invalid Product/Service ID Qualifier' },
  E2: { appliesToFields: ['995-E2'], text: 'Missing/Invalid Route of Administration' },
  E3: { appliesToFields: ['438-E3'], text: 'Missing/Invalid Incentive Amount Submitted' },
  E4: { appliesToFields: ['439-E4'], text: 'Missing/Invalid Reason For Service Code' },
  E5: { appliesToFields: ['440-E5'], text: 'Missing/Invalid Professional Service Code' },
  E6: { appliesToFields: ['441-E6'], text: 'Missing/Invalid Result Of Service Code' },
  E7: { appliesToFields: ['442-E7'], text: 'Missing/Invalid Quantity Dispensed' },
  E8: { appliesToFields: ['443-E8'], text: 'Missing/Invalid Other Payer Date' },
  E9: { appliesToFields: ['444-E9'], text: 'Missing/Invalid Provider ID' },
  EA: { appliesToFields: ['445-EA'], text: 'Missing/Invalid Originally Prescribed Product/Service Code' },
  EB: { appliesToFields: ['446-EB'], text: 'Missing/Invalid Originally Prescribed Quantity' },
  EC: { appliesToFields: ['447-EC'], text: 'Missing/Invalid Compound Ingredient Component Count' },
  ED: { appliesToFields: ['448-ED'], text: 'Missing/Invalid Compound Ingredient Quantity' },
  EE: { appliesToFields: ['449-EE'], text: 'Missing/Invalid Compound Ingredient Drug Cost' },
  EF: { appliesToFields: ['450-EF'], text: 'Missing/Invalid Compound Dosage Form Description Code' },
  EG: { appliesToFields: ['451-EG'], text: 'Missing/Invalid Compound Dispensing Unit Form Indicator' },
  EJ: { appliesToFields: ['453-EJ'], text: 'Missing/Invalid Originally Prescribed Product/Service ID Qualifier' },
  EK: { appliesToFields: ['454-EK'], text: 'Missing/Invalid Scheduled Prescription ID Number' },
  EM: { appliesToFields: ['455-EM'], text: 'Missing/Invalid Prescription/Service Reference Number Qualifier' },
  EN: { appliesToFields: ['456-EN'], text: 'Missing/Invalid Associated Prescription/Service Reference Number' },
  EP: { appliesToFields: ['457-EP'], text: 'Missing/Invalid Associated Prescription/Service Date' },
  ER: { appliesToFields: ['459-ER'], text: 'Missing/Invalid Procedure Modifier Code' },
  ET: { appliesToFields: ['460-ET'], text: 'Missing/Invalid Quantity Prescribed' },
  EU: { appliesToFields: ['461-EU'], text: 'Missing/Invalid Prior Authorization Type Code' },
  EV: { appliesToFields: ['462-EV'], text: 'Missing/Invalid Prior Authorization Number Submitted' },
  EW: { appliesToFields: ['463-EW'], text: 'Missing/Invalid Intermediary Authorization Type ID' },
  EX: { appliesToFields: ['464-EX'], text: 'Missing/Invalid Intermediary Authorization ID' },
  EY: { appliesToFields: ['465-EY'], text: 'Missing/Invalid Provider ID Qualifier' },
  EZ: { appliesToFields: ['466-EZ'], text: 'Missing/Invalid Prescriber ID Qualifier' },
  FO: { appliesToFields: ['524-FO'], text: 'Missing/Invalid Plan ID' },
  G1: { appliesToFields: ['996-G1'], text: 'Missing/Invalid Compound Type' },
  G2: { appliesToFields: ['997-G2'], text: 'Missing/Invalid CMS Part D Defined Qualified Facility' },
  G4: { appliesToFields: ['411-DB'], text: 'Physician must contact plan' },
  G5: { appliesToFields: ['Not Provided'], text: 'Pharmacist must contact plan' },
  G6: { appliesToFields: ['Not Provided'], text: 'Pharmacy Not Contracted in Specialty Network' },
  G7: { appliesToFields: ['Not Provided'], text: 'Pharmacy Not Contracted in Home Infusion Network' },
  G8: { appliesToFields: ['Not Provided'], text: 'Pharmacy Not Contracted in Long Term Care Network' },
  G9: {
    appliesToFields: ['Not Provided'],
    text: 'Pharmacy Not Contracted in 90 Day Retail Network (this message would be used when the pharmacy is not contracted to provide a 90 days supply of drugs)',
  },
  GE: { appliesToFields: ['482-GE'], text: 'Missing/Invalid Percentage Sales Tax Amount Submitted' },
  H1: { appliesToFields: ['495-H1'], text: 'Missing/Invalid Measurement Time' },
  H2: { appliesToFields: ['496-H2'], text: 'Missing/Invalid Measurement Dimension' },
  H3: { appliesToFields: ['497-H3'], text: 'Missing/Invalid Measurement Unit' },
  H4: { appliesToFields: ['499-H4'], text: 'Missing/Invalid Measurement Value' },
  H5: { appliesToFields: ['469'], text: 'Missing/Invalid Primary Care Provider Location Code' },
  H6: { appliesToFields: ['476-H6'], text: 'Missing/Invalid DUR Co-Agent ID' },
  H7: { appliesToFields: ['478-H7'], text: 'Missing/Invalid Other Amount Claimed Submitted Count' },
  H8: { appliesToFields: ['479-H8'], text: 'Missing/Invalid Other Amount Claimed Submitted Qualifier' },
  H9: { appliesToFields: ['480-H9'], text: 'Missing/Invalid Other Amount Claimed Submitted' },
  HA: { appliesToFields: ['481-HA'], text: 'Missing/Invalid Flat Sales Tax Amount Submitted' },
  HB: { appliesToFields: ['341-HB'], text: 'Missing/Invalid Other Payer Amount Paid Count' },
  HC: { appliesToFields: ['342-HC'], text: 'Missing/Invalid Other Payer Amount Paid Qualifier' },
  HD: { appliesToFields: ['343-HD'], text: 'Missing/Invalid Dispensing Status' },
  HE: { appliesToFields: ['483-HE'], text: 'Missing/Invalid Percentage Sales Tax Rate Submitted' },
  HF: { appliesToFields: ['344-HF'], text: 'Missing/Invalid Quantity Intended To Be Dispensed' },
  HG: { appliesToFields: ['345-HG'], text: 'Missing/Invalid Days Supply Intended To Be Dispensed' },
  HN: { appliesToFields: ['350-HN'], text: 'Missing/Invalid Patient E-Mail Address' },
  J9: { appliesToFields: ['475-J9'], text: 'Missing/Invalid DUR Co-Agent ID Qualifier' },
  JE: { appliesToFields: ['484-JE'], text: 'Missing/Invalid Percentage Sales Tax Basis Submitted' },
  K5: { appliesToFields: ['880-K5'], text: 'Missing/Invalid Transaction Reference Number' },
  KE: { appliesToFields: ['485-KE'], text: 'Missing/Invalid Coupon Type' },
  M1: { appliesToFields: ['302-C2', '401-D1'], text: 'Patient Not Covered In This Aid Category' },
  M2: { appliesToFields: ['302-C2', '401-D1'], text: 'Recipient Locked In' },
  M3: { appliesToFields: ['Not Provided'], text: 'Host PA/MC Error' },
  M4: { appliesToFields: ['402-D2'], text: 'Prescription/Service Reference Number/Time Limit Exceeded' },
  M5: { appliesToFields: ['Not Provided'], text: 'Requires Manual Claim' },
  M6: { appliesToFields: ['Not Provided'], text: 'Host Eligibility Error' },
  M7: { appliesToFields: ['Not Provided'], text: 'Host Drug File Error' },
  M8: { appliesToFields: ['Not Provided'], text: 'Host Provider File Error' },
  ME: { appliesToFields: ['486-ME'], text: 'Missing/Invalid Coupon Number' },
  MG: { appliesToFields: ['990-MG'], text: 'Missing/Invalid Other Payer BIN Number' },
  MH: { appliesToFields: ['991-MH'], text: 'Missing/Invalid Other Payer Processor Control Number' },
  MJ: { appliesToFields: ['992-MJ'], text: 'Missing/Invalid Other Payer Group ID' },
  MK: { appliesToFields: ['990-MG'], text: 'Non-Matched Other Payer BIN Number' },
  MM: { appliesToFields: ['991-MH'], text: 'Non-Matched Other Payer Processor Control Number' },
  MN: { appliesToFields: ['992-MJ'], text: 'Non-Matched Other Payer Group ID' },
  MP: { appliesToFields: ['356-NU'], text: 'Other Payer Cardholder ID Not Covered' },
  MR: { appliesToFields: ['407-D7'], text: 'Product Not On Formulary' },
  MS: { appliesToFields: ['302-C2'], text: 'More than 1 Cardholder Found - Narrow Search Criteria' },
  MT: {
    appliesToFields: ['391-MT'],
    text: 'Missing/Invalid Patient Assignment Indicator (Direct Member Reimbursement Indicator)',
  },
  MU: { appliesToFields: ['392-MU'], text: 'Missing/Invalid Benefit Stage Count' },
  MV: { appliesToFields: ['393-MV'], text: 'Missing/Invalid Benefit Stage Qualifier' },
  MW: { appliesToFields: ['394-MW'], text: 'Missing/Invalid Benefit Stage Amount' },
  MX: { appliesToFields: ['392-MU'], text: 'Benefit Stage Count Does Not Match Number Of Repetitions' },
  MZ: { appliesToFields: ['Not Provided'], text: 'Error Overflow' },
  N1: { appliesToFields: ['302-C2'], text: 'No patient match found.' },
  N3: { appliesToFields: ['113-N3'], text: 'Missing/Invalid Medicaid Paid Amount' },
  N4: {
    appliesToFields: ['114-N4'],
    text: 'Missing/Invalid Medicaid Subrogation Internal Control Number/Transaction Control Number (ICN/TCN)',
  },
  N5: { appliesToFields: ['115-N5'], text: 'Missing/Invalid Medicaid ID Number' },
  N6: { appliesToFields: ['116-N6'], text: 'Missing/Invalid Medicaid Agency Number' },
  N7: { appliesToFields: ['462-EV'], text: 'Use Prior Authorization Code Provided During Transition Period' },
  N8: { appliesToFields: ['462-EV'], text: 'Use Prior Authorization Code Provided For Emergency Fill' },
  N9: { appliesToFields: ['462-EV'], text: 'Use Prior Authorization Code Provided For Level of Care Change' },
  NE: { appliesToFields: ['487-NE'], text: 'Missing/Invalid Coupon Value Amount' },
  NN: { appliesToFields: ['Not Provided'], text: 'Transaction Rejected At Switch Or Intermediary' },
  NP: { appliesToFields: ['351-NP'], text: 'Missing/Invalid Other Payer-Patient Responsibility Amount Qualifier' },
  NQ: { appliesToFields: ['352-NQ'], text: 'Missing/Invalid Other Payer-Patient Responsibility Amount' },
  NR: { appliesToFields: ['353-NR'], text: 'Missing/Invalid Other Payer-Patient Responsibility Amount Count' },
  NU: { appliesToFields: ['356-NU'], text: 'Missing/Invalid Other Payer Cardholder ID' },
  NV: { appliesToFields: ['357-NV'], text: 'Missing/Invalid Delay Reason Code' },
  NX: { appliesToFields: ['354-NX'], text: 'Missing/Invalid Submission Clarification Code Count' },
  P0: { appliesToFields: ['438-E3'], text: 'Non-zero Value Required for Vaccine Administration' },
  P1: { appliesToFields: ['456-EN'], text: 'Associated Prescription/Service Reference Number Not Found' },
  P2: { appliesToFields: ['493-XE'], text: 'Clinical Information Counter Out Of Sequence' },
  P3: { appliesToFields: ['447-EC'], text: 'Compound Ingredient Component Count Does Not Match Number Of Repetitions' },
  P4: {
    appliesToFields: ['337-4C'],
    text: 'Coordination Of Benefits/Other Payments Count Does Not Match Number Of Repetitions',
  },
  P5: { appliesToFields: ['486-ME'], text: 'Coupon Expired' },
  P6: { appliesToFields: ['304-C4', '401-D1'], text: 'Date Of Service Prior To Date Of Birth' },
  P7: { appliesToFields: ['491-VE'], text: 'Diagnosis Code Count Does Not Match Number Of Repetitions' },
  P8: { appliesToFields: ['473-7E'], text: 'DUR/PPS Code Counter Out Of Sequence' },
  P9: { appliesToFields: ['Not Provided'], text: 'Field Is Non-Repeatable' },
  PA: { appliesToFields: ['462-EV'], text: 'PA Exhausted/Not Renewable' },
  PB: { appliesToFields: ['103-A3', '109-A9'], text: 'Invalid Transaction Count For This Transaction Code' },
  PC: { appliesToFields: ['111-AM'], text: 'Missing/Invalid Request Claim Segment' },
  PD: { appliesToFields: ['111-AM'], text: 'Missing/Invalid Request Clinical Segment' },
  PE: { appliesToFields: ['111-AM'], text: 'Missing/Invalid Request Coordination Of Benefits/Other Payments Segment' },
  PF: { appliesToFields: ['111-AM'], text: 'Missing/Invalid Request Compound Segment' },
  PG: { appliesToFields: ['111-AM'], text: 'Missing/Invalid Request Coupon Segment' },
  PH: { appliesToFields: ['111-AM'], text: 'Missing/Invalid Request DUR/PPS Segment' },
  PJ: { appliesToFields: ['111-AM'], text: 'Missing/Invalid Request Insurance Segment' },
  PK: { appliesToFields: ['111-AM'], text: 'Missing/Invalid Request Patient Segment' },
  PM: { appliesToFields: ['111-AM'], text: 'Missing/Invalid Request Pharmacy Provider Segment' },
  PN: { appliesToFields: ['111-AM'], text: 'Missing/Invalid Request Prescriber Segment' },
  PP: { appliesToFields: ['111-AM'], text: 'Missing/Invalid Request Pricing Segment' },
  PQ: { appliesToFields: ['111-AM'], text: 'Missing/Invalid Narrative Segment' },
  PR: { appliesToFields: ['111-AM'], text: 'Missing/Invalid Request Prior Authorization Segment' },
  PS: { appliesToFields: ['111-AM'], text: 'Missing/Invalid Transaction Header Segment' },
  PT: { appliesToFields: ['111-AM'], text: "Missing/Invalid Request Worker's Compensation Segment" },
  PV: { appliesToFields: ['457-EP'], text: 'Non-Matched Associated Prescription/Service Date' },
  PW: { appliesToFields: ['333-CZ'], text: 'Employer ID Not Covered' },
  PX: { appliesToFields: ['340-7C'], text: 'Other Payer ID Not Covered' },
  PY: { appliesToFields: ['451-EG', '995', '600-28'], text: 'Non-Matched Unit Form/Route of Administration' },
  PZ: { appliesToFields: ['407-D7', '600-28'], text: 'Non-Matched Unit Of Measure To Product/Service ID' },
  R0: { appliesToFields: ['440-E5'], text: 'Professional Service Code Required For Vaccine Incentive Fee' },
  R1: {
    appliesToFields: ['478-H7'],
    text: 'Other Amount Claimed Submitted Count Does Not Match Number Of Repetitions',
  },
  R2: { appliesToFields: ['471-5E'], text: 'Other Payer Reject Count Does Not Match Number Of Repetitions' },
  R3: { appliesToFields: ['458-SE'], text: 'Procedure Modifier Code Count Does Not Match Number Of Repetitions' },
  R4: {
    appliesToFields: ['407-D7', '436-E1', '459-ER'],
    text: 'Procedure Modifier Code Invalid For Product/Service ID',
  },
  R5: {
    appliesToFields: ['407-D7', '436-E1'],
    text: 'Product/Service ID Must Be Zero When Product/Service ID Qualifier Equals 06',
  },
  R6: {
    appliesToFields: ['307-C7', '407-D7', '436-E1', '489-TE'],
    text: 'Product/Service Not Appropriate For This Location',
  },
  R7: { appliesToFields: ['Not Provided'], text: 'Repeating Segment Not Allowed In Same Transaction' },
  R8: { appliesToFields: ['Not Provided'], text: 'Syntax Error' },
  R9: { appliesToFields: ['430-DU'], text: 'Value In Gross Amount Due Does Not Follow Pricing Formulae' },
  RA: { appliesToFields: ['Not Provided'], text: 'PA Reversal Out Of Order' },
  RB: { appliesToFields: ['343-HD'], text: 'Multiple Partials Not Allowed' },
  RC: { appliesToFields: ['407-D7'], text: 'Different Drug Entity Between Partial & Completion' },
  RD: { appliesToFields: ['301-C1', '302-C2'], text: 'Mismatched Cardholder/Group ID-Partial To Completion' },
  RE: { appliesToFields: ['488'], text: 'Missing/Invalid Compound Product ID Qualifier' },
  RF: { appliesToFields: ['343-HD'], text: "Improper Order Of 'Dispensing Status' Code On Partial Fill Transaction" },
  RG: {
    appliesToFields: ['456-EN'],
    text: 'Missing/Invalid Associated Prescription/service Reference Number On Completion Transaction',
  },
  RH: {
    appliesToFields: ['457-EP'],
    text: 'Missing/Invalid Associated Prescription/Service Date On Completion Transaction',
  },
  RJ: { appliesToFields: ['343-HD'], text: 'Associated Partial Fill Transaction Not On File' },
  RK: { appliesToFields: ['343-HD', '344-HF', '345-HG'], text: 'Partial Fill Transaction Not Supported' },
  RL: { appliesToFields: ['Not Provided'], text: 'Transitional Benefit/Resubmit Claim' },
  RM: {
    appliesToFields: ['401-D1'],
    text: "Completion Transaction Not Permitted With Same 'Date Of Service' As Partial Transaction",
  },
  RN: {
    appliesToFields: ['344-HF', '345-HG'],
    text: 'Plan Limits Exceeded On Intended Partial Fill Field Limitations',
  },
  RP: { appliesToFields: ['343-HD'], text: "Out Of Sequence 'P' Reversal On Partial Fill Transaction" },
  RS: {
    appliesToFields: ['457-EP'],
    text: 'Missing/Invalid Associated Prescription/Service Date On Partial Transaction',
  },
  RT: {
    appliesToFields: ['456-EN'],
    text: 'Missing/Invalid Associated Prescription/Service Reference Number On Partial Transaction',
  },
  RU: {
    appliesToFields: ['Not Provided'],
    text: 'Mandatory Data Elements Must Occur Before Optional Data Elements In A Segment',
  },
  RV: { appliesToFields: ['109-A9'], text: 'Multiple Reversals Per Transmission Not Supported' },
  SE: { appliesToFields: ['458-SE'], text: 'Missing/Invalid Procedure Modifier Code Count' },
  SF: { appliesToFields: ['341-HB'], text: 'Other Payer Amount Paid Count Does Not Match Number Of Repetitions' },
  SG: { appliesToFields: ['354-NX'], text: 'Submission Clarification Code Count Does Not Match Number of Repetitions' },
  SH: {
    appliesToFields: ['353-NR'],
    text: 'Other Payer-Patient Responsibility Amount Count Does Not Match Number of Repetitions',
  },
  TE: { appliesToFields: ['489-TE'], text: 'Missing/Invalid Compound Product ID' },
  TN: { appliesToFields: ['Not Provided'], text: 'Emergency Fill/Resubmit Claim' },
  TP: { appliesToFields: ['Not Provided'], text: 'Level of Care Change/Resubmit Claim' },
  TQ: { appliesToFields: ['442-E7', '405-D5'], text: 'Dosage Exceeds Product Labeling Limit' },
  TR: { appliesToFields: ['117-TR'], text: 'Missing/Invalid Billing Entity Type Indicator' },
  TS: { appliesToFields: ['118-TS'], text: 'Missing/Invalid Pay To Qualifier' },
  TT: { appliesToFields: ['119-TT'], text: 'Missing/Invalid Pay To ID' },
  TU: { appliesToFields: ['120-TU'], text: 'Missing/Invalid Pay To Name' },
  TV: { appliesToFields: ['121-TV'], text: 'Missing/Invalid Pay To Street Address' },
  TW: { appliesToFields: ['122-TW'], text: 'Missing/Invalid Pay To City Address' },
  TX: { appliesToFields: ['123-TX'], text: 'Missing/Invalid Pay to State/ Province Address' },
  TY: { appliesToFields: ['124-TY'], text: 'Missing/Invalid Pay To Zip/Postal Zone' },
  TZ: { appliesToFields: ['125-TZ'], text: 'Missing/Invalid Generic Equivalent Product ID Qualifier' },
  U7: { appliesToFields: ['147-U7'], text: 'Missing/Invalid Pharmacy Service Type' },
  UA: { appliesToFields: ['126-UA'], text: 'Missing/Invalid Generic Equivalent Product ID' },
  UE: { appliesToFields: ['490'], text: 'Missing/Invalid Compound Ingredient Basis Of Cost Determination' },
  UU: {
    appliesToFields: ['407-D7', '408-D8'],
    text: 'DAW 0 cannot be submitted on a multi-source drug with available generics.',
  },
  UZ: {
    appliesToFields: ['338-5C'],
    text: 'Other Payer Coverage Type (338-5C) required on reversals to downstream payers. Resubmit reversal with this field.',
  },
  VA: { appliesToFields: ['118-TS'], text: 'Pay To Qualifier Value Not Supported' },
  VB: { appliesToFields: ['125-TZ'], text: 'Generic Equivalent Product ID Qualifier Value Not Supported' },
  VC: { appliesToFields: ['147-U7'], text: 'Pharmacy Service Type Value Not Supported' },
  VD: { appliesToFields: ['401-D1'], text: 'Eligibility Search Time Frame Exceeded' },
  VE: { appliesToFields: ['491-VE'], text: 'Missing/Invalid Diagnosis Code Count' },
  WE: { appliesToFields: ['492-WE'], text: 'Missing/Invalid Diagnosis Code Qualifier' },
  X0: { appliesToFields: ['582-X0'], text: 'Missing/Invalid Associated Prescription/Service Fill Number' },
  X8: { appliesToFields: ['458-SE'], text: 'Procedure Modifier Code Count Exceeds Number Of Occurrences Supported' },
  X9: { appliesToFields: ['491-VE'], text: 'Diagnosis Code Count Exceeds Number Of Occurrences Supported' },
  XE: { appliesToFields: ['493-XE'], text: 'Missing/Invalid Clinical Information Counter' },
  XZ: {
    appliesToFields: ['581-XZ'],
    text: 'Missing/Invalid Associated Prescription/Service Reference Number Qualifier',
  },
  Y0: { appliesToFields: ['597-Y0'], text: 'Missing/Invalid Purchaser Last Name' },
  Y1: { appliesToFields: ['598-Y1'], text: 'Missing/Invalid Purchaser Street Address' },
  Y2: { appliesToFields: ['599-Y2'], text: 'Missing/Invalid Purchaser City Address' },
  Y3: { appliesToFields: ['675-Y3'], text: 'Missing/Invalid Purchaser State/Province Code' },
  Y4: { appliesToFields: ['676-Y4'], text: 'Missing/Invalid Purchaser Zip/Postal Code' },
  Y5: { appliesToFields: ['677-Y5'], text: 'Missing/Invalid Purchaser Country Code' },
  Y6: { appliesToFields: ['678-Y6'], text: 'Missing/Invalid Time of Service' },
  Y7: { appliesToFields: ['579-XX'], text: 'Missing/Invalid Associated Prescription/Service Provider ID Qualifier' },
  Y8: { appliesToFields: ['580-XY'], text: 'Missing/Invalid Associated Prescription/Service Provider ID' },
  Y9: { appliesToFields: ['679-Y9'], text: 'Missing/Invalid Seller ID' },
  YA: {
    appliesToFields: ['362-2G'],
    text: 'Compound Ingredient Modifier Code Count Exceeds Number Of Occurrences Supported',
  },
  YB: {
    appliesToFields: ['478-H7'],
    text: 'Other Amount Claimed Submitted Count Exceeds Number Of Occurrences Supported',
  },
  YC: { appliesToFields: ['471-5E'], text: 'Other Payer Reject Count Exceeds Number Of Occurrences Supported' },
  YD: {
    appliesToFields: ['353-NR'],
    text: 'Other Payer-Patient Responsibility Amount Count Exceeds Number Of Occurrences Supported',
  },
  YE: {
    appliesToFields: ['354-NX'],
    text: 'Submission Clarification Code Count Exceeds Number of Occurrences Supported',
  },
  YF: { appliesToFields: ['377-2Z'], text: 'Question Number/Letter Count Exceeds Number Of Occurrences Supported' },
  YG: { appliesToFields: ['392-MU'], text: 'Benefit Stage Count Exceeds Number Of Occurrences Supported' },
  YH: { appliesToFields: ['493-XE'], text: 'Clinical Information Counter Exceeds Number of Occurrences Supported' },
  YJ: { appliesToFields: ['116-N6'], text: 'Medicaid Agency Number Not Supported' },
  YK: { appliesToFields: ['583-YK'], text: 'Missing/Invalid Service Provider Name' },
  YM: { appliesToFields: ['584-YM'], text: 'Missing/Invalid Service Provider Street Address' },
  YN: { appliesToFields: ['585-YN'], text: 'Missing/Invalid Service Provider City Address' },
  YP: { appliesToFields: ['586-YP'], text: 'Missing/Invalid Service Provider State/Province Code Address' },
  YQ: { appliesToFields: ['587-YQ'], text: 'Missing/Invalid Service Provider Zip/Postal Code' },
  YR: { appliesToFields: ['A22-YR'], text: 'Missing/Invalid Patient ID Associated State/Province Address' },
  YS: { appliesToFields: ['A23-YS'], text: 'Missing/Invalid Purchaser Relationship Code' },
  YT: { appliesToFields: ['590-YT'], text: 'Missing/Invalid Seller Initials' },
  YU: { appliesToFields: ['591-YU'], text: 'Missing/Invalid Purchaser ID Qualifier' },
  YV: { appliesToFields: ['592-YV'], text: 'Missing/Invalid Purchaser ID' },
  YW: { appliesToFields: ['593-YW'], text: 'Missing/Invalid Purchaser ID Associated State/Province Code' },
  YX: { appliesToFields: ['594-YX'], text: 'Missing/Invalid Purchaser Date of Birth' },
  YY: { appliesToFields: ['595-YY'], text: 'Missing/Invalid Purchaser Gender Code' },
  YZ: { appliesToFields: ['596-YZ'], text: 'Missing/Invalid Purchaser First Name' },
  Z0: { appliesToFields: ['677-Y5'], text: 'Purchaser Country Code Value Not Supported For Processor/Payer' },
  Z1: { appliesToFields: ['A25-ZM'], text: 'Prescriber Alternate ID Qualifier Value Not Supported' },
  Z2: { appliesToFields: ['111-AM'], text: 'Missing/Invalid Purchaser Segment' },
  Z3: {
    appliesToFields: ['111-AM'],
    text: 'Purchaser Segment Present On A Non-Controlled Substance Reporting Transaction',
  },
  Z4: {
    appliesToFields: ['111-AM'],
    text: 'Purchaser Segment Required On A Controlled Substance Reporting Transaction',
  },
  Z5: { appliesToFields: ['111-AM'], text: 'Missing/Invalid Service Provider Segment' },
  Z6: {
    appliesToFields: ['111-AM'],
    text: 'Service Provider Segment Present On A non-Controlled Substance Reporting Transaction',
  },
  Z7: {
    appliesToFields: ['111-AM'],
    text: 'Service Provider Segment Required On A Controlled Substance Reporting Transaction',
  },
  Z8: { appliesToFields: ['A23-YS'], text: 'Purchaser Relationship Code Value Not Supported' },
  Z9: { appliesToFields: ['A26-ZP'], text: 'Prescriber Alternate ID Not Covered' },
  ZA: {
    appliesToFields: ['Not Provided'],
    text: 'The Coordination of Benefits/Other Payments Segment is mandatory to a downstream payer.',
  },
  ZB: { appliesToFields: ['680-ZB'], text: 'Missing/Invalid Seller ID Qualifier' },
  ZC: {
    appliesToFields: ['579-XX'],
    text: 'Associated Prescription/Service Provider ID Qualifier Value Not Supported For Processor/Payer',
  },
  ZD: {
    appliesToFields: ['581-XZ'],
    text: 'Associated Prescription/Service Reference Number Qualifier Value Not Supported',
  },
  ZE: { appliesToFields: ['494-ZE'], text: 'Missing/Invalid Measurement Date' },
  ZF: { appliesToFields: ['681-ZF'], text: 'Missing/Invalid Sales Transaction ID' },
  ZK: { appliesToFields: ['A24-ZK'], text: 'Missing/Invalid Prescriber ID Associated State/Province Address' },
  ZM: { appliesToFields: ['A25-ZM'], text: 'Missing/Invalid Prescriber Alternate ID Qualifier' },
  ZN: { appliesToFields: ['591-YU'], text: 'Purchaser ID Qualifier Value Not Supported For Processor/Payer' },
  ZP: { appliesToFields: ['A26-ZP'], text: 'Missing/Invalid Prescriber Alternate ID' },
  ZQ: {
    appliesToFields: ['A27-ZQ'],
    text: 'Missing/Invalid Prescriber Alternate ID Associated State/Province Address',
  },
  ZS: { appliesToFields: ['A29-ZS'], text: 'Missing/Invalid Reported Payment Type' },
  ZT: { appliesToFields: ['A30-ZT'], text: 'Missing/Invalid Released Date' },
  ZU: { appliesToFields: ['A31-ZU'], text: 'Missing/Invalid Released Time' },
  ZV: { appliesToFields: ['A29-ZS'], text: 'Reported Payment Type Value Not Supported' },
  ZW: { appliesToFields: ['A32-ZW'], text: 'Missing/Invalid Compound Preparation Time' },
  ZX: { appliesToFields: ['A33-ZX'], text: 'Missing/Invalid CMS Part D Contract ID' },
  ZY: { appliesToFields: ['A34-ZY'], text: 'Missing/Invalid Medicare Part D Plan Benefit Package (PBP)' },
  ZZ: { appliesToFields: ['302-C2'], text: 'Cardholder ID submitted is inactive. New Cardholder ID on file.' },
}
