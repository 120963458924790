import type { EntityId } from '../shared'

export enum UserRoles {
  System = 'system',
  Admin = 'admin',
  Technician = 'technician',
  Pharmacist = 'pharmacist',
  Customer = 'customer',
  Patient = 'patient',
  LeadPharmacist = 'leadPharmacist',
  Tester = 'tester',
  CustomerSupport = 'customerSupport',
  LeadCustomerSupport = 'leadCustomerSupport',
  Warehouse = 'warehouse',
  LeadWarehouse = 'leadWarehouse',
  Developer = 'developer',
  Compliance = 'compliance',
}

export interface User {
  _id: EntityId
  locationId?: EntityId
  pioneerPharmacistId?: string
  firstName?: string
  lastName?: string
  preferredName?: string
  username?: string
  email?: string
  password?: string
  forcePasswordReset?: boolean
  temporaryId?: EntityId
  temporaryPassword?: string
  roles: string[]
  system: 'Pharmacy'
  disabled: boolean
  lastInviteSentAt?: Date
  invitesSentCount?: number
  lastCheckedReleaseNotes?: string
}

export const nonCSARoles = [
  UserRoles.System,
  UserRoles.Admin,
  UserRoles.Technician,
  UserRoles.Pharmacist,
  UserRoles.Customer,
  UserRoles.Patient,
  UserRoles.LeadPharmacist,
  UserRoles.Tester,
  UserRoles.Warehouse,
  UserRoles.LeadWarehouse,
  UserRoles.Developer,
  UserRoles.Compliance,
]

export const allRolesExceptCustomerSupport = [...nonCSARoles, UserRoles.LeadCustomerSupport]

export interface License {
  id: string
  number: string
  expirationDate: Date
  registeredState: string
  imageId?: string
}
