export enum InsuranceDesignationType {
  Primary = 'Primary',
  Secondary = 'Secondary',
  Coupon = 'Coupon',
  Tertiary = 'Tertiary',
}

// string form of FieldCodes.PatientRelationship that we are actually saving
export enum InsurancePatientRelationship {
  NotSpecified = 'NotSpecified',
  Cardholder = 'Cardholder',
  Spouse = 'Spouse',
  Child = 'Child',
  Other = 'Other',
  Student = 'Student',
  Disabled = 'Disabled',
  AdultDependent = 'AdultDependent',
  SignificantOther = 'SignificantOther',
}

export enum InsuranceCardType {
  NotSpecified = '',
  Insurance = 'insurance',
  SavingsCard = 'savings_card',
  Commercial = 'commercial',
  Government = 'government',
  Medicare = 'medicare',
  Medicaid = 'medicaid',
}

export interface InsuranceData {
  benefitEffectiveDate?: string
  bin: string
  cardholderId?: string
  coverageType?: string
  designation?: InsuranceDesignationType
  governmentSponsoredPlan?: boolean
  group?: string
  helpdeskNumber?: string
  images?: {
    front: string
    back?: string
  }
  name?: string
  pcn?: string
  personCode?: string
  relationship?: InsurancePatientRelationship
  cardType?: InsuranceCardType
  coreInsuranceToken?: string
}

export enum BinCodes {
  CASH = '025573',
}
