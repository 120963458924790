import { FilledHeader } from 'components/PageStructure'
import styled from 'styled-components'

const Title = styled.h1`
  margin-top: 1.25rem;
  padding: 0 1.875rem;
`

const DataContainer = styled.div`
  width: 26rem;
  padding: 0 1.875rem;
`

const StyledFilledHeader = styled(FilledHeader)`
  margin-top: 2rem;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

export { Title, DataContainer, StyledFilledHeader, ButtonContainer }
