import { createContext, useContext, useEffect, useState } from 'react'
import * as H from 'history'
import queryString from 'query-string'
import { generatePath, useHistory, useLocation } from 'react-router-dom'
import { useClient } from './Client'
import type { RouteThisContext } from './ClientRouter'
import * as QueryToolkit from './utils/queryToolkit'

export interface CentralRoutingProps<T, U> {
  routes: T & {
    landingPage: (this: RouteThisContext<T, U>, landingPage: H.LocationDescriptorObject) => RouteOptionsWithParams
  }
  children?: any
}

type RouteSearch = Record<string, any>
type RouteParams = Record<string, any>

export interface RouteOptions extends H.LocationDescriptorObject {
  searchMap?: RouteSearch
  hash?: string
}

export interface RouteOptionsWithParams extends RouteOptions {
  params?: RouteParams
}

export type LandingPageHandler = (landingPage: H.LocationDescriptorObject) => RouteOptionsWithParams

export type RouteTo = ((path: string, { searchMap, params, hash }?: RouteOptionsWithParams) => RouteToGeneric) & {
  landingPage: LandingPageHandler
}

export type WrappedRoutes<T> = { [P in keyof T]: (...args) => RouteToGeneric }
export type CentralRoutes<T> = RouteTo & WrappedRoutes<T>

export type RouteToGeneric = RouteOptionsWithParams & {
  now: () => void
}

const emptyRouteToGeneric: RouteToGeneric = {
  now: () => undefined,
}

const emptyRouteTo: RouteTo = () => emptyRouteToGeneric
emptyRouteTo.landingPage = () => emptyRouteToGeneric

export const CentralRoutingDefaults: {
  routeTo: RouteTo
  routeToHash: (hash: string) => void
  routeToMergedQuery: (toMerge: any) => void
  routeToQuery: (newQueryParams: any) => void
  history: H.History
  QueryToolkit: typeof QueryToolkit
  currentLocation: {
    queryMap: any
    path: string
    hash?: string
  }
} = {
  routeTo: emptyRouteTo,
  routeToHash: () => undefined,
  routeToMergedQuery: () => undefined,
  routeToQuery: () => undefined,
  history: H.createMemoryHistory(),
  QueryToolkit,
  currentLocation: { queryMap: {}, path: '' },
}

export const CentralRoutingContext = createContext(CentralRoutingDefaults)

export const useCentralRouting = function useCentralRoutingContext() {
  const centralRouting = useContext(CentralRoutingContext)

  return centralRouting
}

// Used below to check if a route hash is a valid DOM selector
const isSelectorValid = (selector: string) => {
  try {
    document.createDocumentFragment().querySelector(selector)
  } catch {
    return false
  }
  return true
}

export default function CentralRouting<T, U>(props: CentralRoutingProps<T, U>) {
  const myLocation = useLocation()
  const history = useHistory()
  const [landingPage, setLandingPage] = useState<H.LocationDescriptorObject>()
  const { isAuthenticated, tokenContext } = useClient()

  useEffect(() => {
    history.listen(({ hash }) => {
      if (hash && isSelectorValid(hash)) {
        // Hack to get scroll to hash working in react
        const element = document.querySelector(hash)
        element?.scrollIntoView()
      }
    })

    setLandingPage({
      pathname: myLocation.pathname,
      search: myLocation.search,
      hash: myLocation.hash,
    })
  }, [])

  useEffect(() => {
    // Login using Auth0 will be skipped when routing to landingPage and will be handled by Auth0Provider
    const isAuth0Login = landingPage && landingPage.pathname === '/login' && landingPage.search.includes('?code=')
    if (isAuthenticated && landingPage && !isAuth0Login) {
      routeTo.landingPage().now()

      setLandingPage(undefined)
    }
  }, [landingPage, isAuthenticated])

  function wrapRouteTo(location: RouteOptionsWithParams): RouteToGeneric {
    const newLocation = { ...location }
    const searchString = queryString.stringify(location.searchMap, {
      arrayFormat: 'bracket',
    })

    if (searchString) newLocation.search = '?' + searchString
    delete newLocation.searchMap

    Object.defineProperty(newLocation, 'now', {
      value: () => {
        let path = newLocation.pathname || ''

        if (newLocation.search) path += (newLocation.search.startsWith('?') ? '' : '?') + newLocation.search
        if (newLocation.hash) path += '#' + newLocation.hash

        history.push(path)
      },
      enumerable: false,
    })

    if (location.params) {
      newLocation.pathname = generatePath(location.pathname || '', location.params)
      delete newLocation.params
    }

    return newLocation as RouteToGeneric
  }

  const routeTo = (path, { searchMap = {}, params = {}, hash = '' } = {}) => {
    const searchString = queryString.stringify(searchMap, {
      arrayFormat: 'bracket',
    })

    const route = {
      pathname: generatePath(path, params),
      ...(searchString && { search: '?' + searchString }),
      ...(hash && { hash }),
    }

    return wrapRouteTo(route)
  }

  const landingPageRouteHandler = props.routes.landingPage

  const thisContext = {
    routeTo: routeTo as CentralRoutes<T>,
    tokenContext: tokenContext as U,
  }

  const routes: Record<string, (...args) => RouteToGeneric> = Object.entries({
    ...props.routes,
  }).reduce(
    (acc, [key, value]) =>
      // @ts-ignore
      ({
        ...acc,
        [key]: (...args) => wrapRouteTo(value.bind(thisContext)(...args)),
      }),
    {},
  )

  Object.assign(routeTo, routes)

  routeTo.landingPage = landingPageRouteHandler
    ? () => wrapRouteTo((landingPageRouteHandler.bind(thisContext) as LandingPageHandler)(landingPage))
    : () => wrapRouteTo(landingPage)

  const queryMap = queryString.parse(myLocation.search, {
    arrayFormat: 'bracket',
  })

  const value = {
    routeTo,
    routeToHash: (hash: string) => {
      let path = myLocation.pathname

      if (myLocation.search) path += myLocation.search
      if (hash) path += '#' + hash

      history.push(path)
    },
    routeToMergedQuery: (toMerge: any) => {
      routeTo(myLocation.pathname, {
        searchMap: Object.assign({}, queryMap, toMerge),
        hash: myLocation.hash,
      }).now()
    },
    routeToQuery: (newQueryParams: any) => {
      routeTo(myLocation.pathname, {
        searchMap: newQueryParams,
        hash: myLocation.hash,
      }).now()
    },
    history,
    QueryToolkit,
    currentLocation: {
      hash: myLocation.hash,
      path: myLocation.pathname,
      queryMap,
    },
  }

  return <CentralRoutingContext.Provider value={value} {...props} />
}
