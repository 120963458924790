import { useCallback } from 'react'
import type { ApolloError } from '@truepill/tpos-react-router'
import { useMutation } from '@truepill/tpos-react-router'
import { END_USER_SESSION, GET_SESSIONS_FOR_USER } from 'gql'

interface GetSessionsForUserParams {
  sessionId: string
}

interface GetSessionsForUserResponse {
  endSession: {
    succesfull: boolean
  }
}

const buildQueriesToRefetch = (userId: string, totalPages: number, pageSize: number) => {
  const queries = []
  for (let index = 0; index < totalPages; index = index + 1) {
    queries.push({
      query: GET_SESSIONS_FOR_USER,
      variables: {
        userId,
        offset: index * pageSize,
        limit: pageSize,
      },
    })
  }

  return queries
}

type useEndUserSessionType = {
  endUserSession: (sessionId: string) => void
  loading: boolean
  error?: ApolloError
  success: boolean
}

const useEndUserSession = (userId: string, totalPages: number, pageSize: number): useEndUserSessionType => {
  const queriesToRefetch = buildQueriesToRefetch(userId, totalPages, pageSize)

  const [endUserSessionMutation, { loading, error, called }] = useMutation<
    GetSessionsForUserResponse,
    GetSessionsForUserParams
  >(END_USER_SESSION, {
    refetchQueries: queriesToRefetch,
  })

  const endUserSession = useCallback((sessionId: string) => {
    endUserSessionMutation({
      variables: {
        sessionId,
      },
    })
  }, [])

  return {
    endUserSession,
    loading,
    error,
    success: called && !error && !loading,
  }
}

export default useEndUserSession
