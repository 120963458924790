import { useState } from 'react'
import { getRejectCodeCategories } from '@truepill/tpos-ncpdp-constants'
import { useQuery } from '@truepill/tpos-react-router'
import DropDownOption from 'components/DropDownOption'
import SearchAutoComplete from 'components/SearchAutoComplete'
import { SearchResultLozenge, SearchResultLozengeList } from 'components/Tiles/Lozenge'
import { GET_ADJUDICATION_REJECT_CODES } from 'gql'
import { usePlusClient } from 'providers/VisionRouter'
import { borderColor } from 'styles/styleVariables'

interface RejectCodeCategory {
  _id: string
  name: string
}

interface RejectCodeCategorySuggestion extends RejectCodeCategory {
  highlighted?: boolean
  selected?: boolean
}

interface Error {
  error: string
}

interface Loading {
  loading: boolean
}

type RejectCodeCategorySuggestionItem = RejectCodeCategorySuggestion | Error | Loading

const getRejectCodeCategoryById = (rejectCodeCategories: RejectCodeCategory[], rejectCodeId: string) => {
  const categories = rejectCodeCategories.filter(c => c._id === rejectCodeId)

  return categories[0]?.name
}

type RejectCodeCategorySearchProps = { isVisible?: boolean; hotKey?: string; isModal?: boolean }

const RejectCodeCategorySearch = ({ isVisible, hotKey = 's', isModal }: RejectCodeCategorySearchProps): JSX.Element => {
  const { currentLocation, routeToMergedQuery, tokenContext } = usePlusClient()
  const [searchTerm, setSearchTerm] = useState('')

  const { data, loading, error } = useQuery(GET_ADJUDICATION_REJECT_CODES, {
    variables: {
      locationId: currentLocation.queryMap?.locationId || tokenContext?.locationId,
      medicationNames: currentLocation.queryMap?.medications || undefined,
      customerIds: currentLocation.queryMap?.customerIds || undefined,
      rejectCodes: currentLocation.queryMap?.rejectCodes || undefined,
      batchOrders:
        currentLocation.queryMap?.batchOrders !== undefined
          ? currentLocation.queryMap.batchOrders === 'true'
          : undefined,
      includeTriage:
        currentLocation.queryMap?.showTriage !== undefined ? currentLocation.queryMap.showTriage === 'true' : undefined,
    },
  })

  const rejectionCodes: RejectCodeCategory[] = data?.getAdjudicationRejectCodes?.codes
    ? getRejectCodeCategories(data?.getAdjudicationRejectCodes?.codes)
    : []
  const selectedRejectCodes = currentLocation?.queryMap?.rejectCodeCategories || []

  return (
    <>
      <SearchAutoComplete
        hotKey={isVisible ? hotKey : ''}
        isModal={isModal}
        iconColor={borderColor}
        placeholder={'Search by reject category...'}
        autoCompleteSuggestions={
          error
            ? [{ error: `Error searching Medications: ${JSON.stringify(error)}` }]
            : loading
            ? [{ loading }]
            : rejectionCodes.filter(c => c.name.toLowerCase().includes(searchTerm.toLowerCase())) ?? [
                { noResults: true },
              ]
        }
        value={searchTerm}
        onSelect={chosen => {
          const rejectCodes = chosen as RejectCodeCategory
          if (!selectedRejectCodes.includes(rejectCodes._id))
            routeToMergedQuery({ rejectCodeCategories: [...selectedRejectCodes, rejectCodes._id], page: 0 })

          setSearchTerm('')
        }}
        suggestionComponent={optionProps => (
          <AutoCompleteOption {...(optionProps as RejectCodeCategorySuggestionItem)} />
        )}
        onChange={setSearchTerm}
        showOptionsOnFocus={true}
      />
      <SearchResultLozengeList>
        {selectedRejectCodes.map((rejectCodeId: string) => (
          <SearchResultLozenge
            key={rejectCodeId}
            closeCallback={() => {
              if (selectedRejectCodes.includes(rejectCodeId)) {
                const newRejectCodes = [...selectedRejectCodes]
                newRejectCodes.splice(selectedRejectCodes.indexOf(rejectCodeId), 1)

                routeToMergedQuery({ rejectCodeCategories: newRejectCodes })
              }
            }}
          >
            <p>{getRejectCodeCategoryById(rejectionCodes, rejectCodeId)}</p>
          </SearchResultLozenge>
        ))}
      </SearchResultLozengeList>
    </>
  )
}

const AutoCompleteOption = (props: RejectCodeCategorySuggestionItem) => {
  if ((props as Error).error) {
    return (
      <DropDownOption {...props}>
        <p>{(props as Error).error}</p>
      </DropDownOption>
    )
  }

  if ((props as Loading).loading) {
    return (
      <DropDownOption {...props}>
        <p>Loading...</p>
      </DropDownOption>
    )
  }

  const rejectCodeCategory = props as RejectCodeCategory

  return (
    <DropDownOption {...rejectCodeCategory}>
      <p>{rejectCodeCategory.name}</p>
    </DropDownOption>
  )
}

export default RejectCodeCategorySearch
