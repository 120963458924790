import { Box, Text } from '@truepill/react-capsule'
import type { PatientSurveyInput } from '@truepill/tpos-types'
import Highlight from 'components/Highlight'
import styled from 'styled-components'

interface DefualtHighlights {
  allergies: string[]
  conditions: string[]
  medications: string[]
}

interface SurveySectionProps {
  defaultHighlights: DefualtHighlights
  survey: PatientSurveyInput
  onAppendHighlight: (type: 'allergies' | 'medications' | 'conditions', highlight: string) => void
  onRemoveHighlight: (type: 'allergies' | 'medications' | 'conditions', highlight: string) => void
}

const SurveySection = ({ defaultHighlights, survey, onAppendHighlight, onRemoveHighlight }: SurveySectionProps) => {
  return (
    <Container>
      <Wrapper>
        <Box>
          <Title bold>Patient survey</Title>
          <Text>Highlight words / phrases to create tokens</Text>
        </Box>
        <Box>
          <Title bold>Allergies</Title>
          <Highlight
            key="highlight-text-allergies"
            containerName="highlight-text-allergies"
            defaultHighlights={defaultHighlights?.allergies ?? []}
            text={survey?.allergies ?? ''}
            onHighlight={(highlight: string) => onAppendHighlight('allergies', highlight)}
            onUnhighlight={(unhighlight: string) => onRemoveHighlight('allergies', unhighlight)}
          />
        </Box>
        <Box>
          <Title bold>Medications</Title>
          <Highlight
            key="highlight-text-medications"
            containerName="highlight-text-medications"
            defaultHighlights={defaultHighlights?.medications ?? []}
            text={survey?.medications ?? ''}
            onHighlight={(highlight: string) => onAppendHighlight('medications', highlight)}
            onUnhighlight={(unhighlight: string) => onRemoveHighlight('medications', unhighlight)}
          />
        </Box>
        <Box>
          <Title bold>Conditions</Title>
          <Highlight
            key="highlight-text-conditions"
            containerName="highlight-text-conditions"
            defaultHighlights={defaultHighlights?.conditions ?? []}
            text={survey?.conditions ?? ''}
            onHighlight={(highlight: string) => onAppendHighlight('conditions', highlight)}
            onUnhighlight={(unhighlight: string) => onRemoveHighlight('conditions', unhighlight)}
          />
        </Box>
      </Wrapper>
    </Container>
  )
}

const Title = styled(Text)`
  line-height: 1.5rem;
`

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`

const Container = styled(Box)`
  padding: 1rem 0;
`
export default SurveySection
