import { useReactiveVar } from '@truepill/tpos-react-router'
import LoadingSpinner from 'components/Loading'
import MultiLineMessage from 'components/MultiLineMessage'
import { ListRowLabel, ListRowValue, RXCell, AlternatingShading } from 'components/RXTable'
import { claimsRequest } from 'hooks/navigation/useClaim'
import type { FlattenSimpleInterpolation } from 'styled-components'
import styled from 'styled-components'
import { borderColor } from 'styles/styleVariables'
import type { Claim, ClaimsRequest, Fill, Prescription, QueueItem } from 'types'
import InitiatePriorAuthorization from './InitiatePriorAuthorization'

type RejectionMessagesProps = {
  styles?: FlattenSimpleInterpolation
  containerStyles?: FlattenSimpleInterpolation
  rxImageIsPic?: boolean
  claimJumperErrors: string[]
  claims: Claim[]
  prescription?: Prescription
  item?: QueueItem
  fill?: Fill
}

const formatNextAvailableFillDate = (nextAvailableFillDate: string) => {
  const year = nextAvailableFillDate.substring(0, 4)
  const month = nextAvailableFillDate.substring(4, 6)
  const day = nextAvailableFillDate.substring(6, 8)
  return `${parseInt(month, 10)}/${parseInt(day, 10)}/${year}`
}

const RejectionMessages = ({
  styles,
  containerStyles,
  rxImageIsPic,
  claimJumperErrors,
  claims,
  prescription,
  item,
  fill,
}: RejectionMessagesProps): JSX.Element => {
  const claimsRequestObject = useReactiveVar(claimsRequest) as ClaimsRequest
  let claimsSubmissionResponse = null
  let claimsSubmitting = null
  if (claimsRequestObject) {
    claimsSubmissionResponse = claimsRequestObject.claimsSubmissionResponse
    claimsSubmitting = claimsRequestObject.claimsSubmitting
  }

  const { additionalInfo, nextAvailableFillDate } = claimsSubmissionResponse ?? ({} as Record<string, undefined>)

  return (
    <RejectionMessageContainer borderBottom={rxImageIsPic} styles={containerStyles}>
      <TopRow />
      {claimsSubmitting ? (
        <LoadingSpinnerContainer>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      ) : (
        <RejectionMessagesList styles={styles}>
          {claimsSubmissionResponse?.rejectionMessages.map(({ rejectCode, rejectCodeTranslated }, i) => {
            return (
              <div key={`rejection-messages-${i}`}>
                <RejectionMessage even={true}>
                  <RejectionMessageCell>
                    <ListRowLabel data-testid="rejectCode">{rejectCode}:</ListRowLabel>
                    <ListRowValue>{rejectCodeTranslated || 'Unknown Rejection Code'}</ListRowValue>
                  </RejectionMessageCell>
                </RejectionMessage>
                {/* <DURMessage even={false}>
                <RXCell>
                  <ListRowLabel>DUR MESSAGE</ListRowLabel>
                </RXCell>
                <DURResponseCell>
                  <ListRowLabel>Reason:</ListRowLabel>
                  <ListRowValue>LR</ListRowValue>
                </DURResponseCell>
                <DURResponseCell>
                  <ListRowLabel>Message:</ListRowLabel>
                  <ListRowValue>Min use</ListRowValue>
                </DURResponseCell>
                <DURResponseCell>
                  <ListRowLabel>Clinical sig:</ListRowLabel>
                  <ListRowValue>0</ListRowValue>
                </DURResponseCell>
                <DURResponseCell>
                  <ListRowLabel>Previous fill:</ListRowLabel>
                  <ListRowValue>04/04/2020</ListRowValue>
                </DURResponseCell>
                <DURResponseCell>
                  <ListRowLabel>Previous qty:</ListRowLabel>
                  <ListRowValue>30</ListRowValue>
                </DURResponseCell>
              </DURMessage> */}
              </div>
            )
          })}
        </RejectionMessagesList>
      )}
      {!claimsSubmitting && claimJumperErrors?.length > 0 && claims?.length === 0 && (
        <ClaimJumperErrorList styles={styles}>
          {claimJumperErrors.map((error, i) => (
            <div key={`claim-jumper-error-${i}`}>
              <ClaimJumperError even={true}>
                <ClaimJumperErrorCell>
                  <ListRowLabel>Claim Jumper Error:</ListRowLabel>
                  <ListRowValue>{error}</ListRowValue>
                </ClaimJumperErrorCell>
              </ClaimJumperError>
            </div>
          ))}
        </ClaimJumperErrorList>
      )}
      {additionalInfo && (
        <RejectionMessage even={true}>
          <RejectionMessageCell>
            <ListRowLabel>Info:</ListRowLabel>
            <ListRowValue>
              <span>
                <MultiLineMessage message={additionalInfo} />
              </span>
            </ListRowValue>
          </RejectionMessageCell>
        </RejectionMessage>
      )}
      {nextAvailableFillDate && (
        <RejectionMessage even={true}>
          <RejectionMessageCell>
            <ListRowLabel>Next Fill:</ListRowLabel>
            <ListRowValue>
              <span>
                <MultiLineMessage message={formatNextAvailableFillDate(nextAvailableFillDate)} />
              </span>
            </ListRowValue>
          </RejectionMessageCell>
        </RejectionMessage>
      )}
      {prescription && fill && item && (
        <InitiatePriorAuthorization prescription={prescription} item={item} fill={fill} />
      )}
    </RejectionMessageContainer>
  )
}

const TopRow = styled.div`
  display: flex;
  flex-basis: 100%;
`

const RejectionMessageContainer = styled.div<{
  styles?: FlattenSimpleInterpolation
  borderBottom?: boolean
}>`
  display: flex;
  font-family: monospace;
  flex-basis: 100%;
  background-color: white;
  flex-direction: column;
  align-self: flex-start;
  border-radius: 0.25rem;
  border-top: 0.125rem solid ${borderColor};
  border-left: 0.125rem solid ${borderColor};
  border-right: 0.125rem solid ${borderColor};
  ${({ borderBottom }) => borderBottom && `border-bottom: 0.125rem solid ${borderColor};`}
  margin-bottom: 2rem;
  margin-top: 0.4rem;
  padding-top: 0.61rem;
  min-height: 238px;
  max-height: 238px;
  overflow: auto;
  label {
    font-weight: 550;
  }
  ${({ styles }) => styles}
`
const RejectionMessage = styled.ul<{
  even?: boolean
}>`
  ${AlternatingShading}
`

const RejectionMessageCell = styled(RXCell)`
  grid-template-columns: [label] 3rem [value] 1fr;
`

const RejectionMessagesList = styled.ul<{
  styles?: FlattenSimpleInterpolation
}>`
  width: 100%;
  ${({ styles }) => styles}
`

const ClaimJumperErrorList = styled.ul<{
  styles?: FlattenSimpleInterpolation
}>`
  ${({ styles }) => styles}
`

const ClaimJumperError = styled.ul<{
  even?: boolean
}>`
  ${AlternatingShading}
`

const ClaimJumperErrorCell = styled(RXCell)`
  grid-template-columns: [label] 5em [value] 1fr;
`

const LoadingSpinnerContainer = styled.div`
  margin: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  > svg {
    height: 125px;
  }
`

export default RejectionMessages
