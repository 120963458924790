import { CopayStatus, RxFillRequestStatus } from '@truepill/tpos-types'
import { ReactComponent as CycleIcon } from 'assets/icons/cycle.svg'
import ActionButton from 'components/ActionButton'
import IconWrapper from 'components/IconWrapper'
import { useCopayLock, useOrderLock } from 'hooks'
import ChangeDispensed from 'modals/ChangeDispensed'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import { bodyPrimaryColor } from 'styles/styleVariables'
import type { Fill, Prescription, QueueItem } from 'types'
import { getItemFill, isCopayRequest, isOrder } from 'utils'

type ChangeDispensedActionButtonProps = {
  item: QueueItem
  fill: Fill
  prescription: Prescription
  isPriorAuthorization: boolean
}

export const ChangeDispensedActionButton = ({
  item,
  fill,
  prescription,
  isPriorAuthorization,
}: ChangeDispensedActionButtonProps): JSX.Element => {
  const { showModal } = useModalContext()
  const isOrderItem = isOrder(item)
  const { orderEditable } = useOrderLock(item._id, !isOrderItem)
  const { copayEditable } = useCopayLock(item._id, isOrderItem)

  const isComplete = isOrder(item)
    ? getItemFill(item, fill._id)?.status === RxFillRequestStatus.Complete
    : getItemFill(item, fill._id)?.status === CopayStatus.Complete

  const isCompleteCopay = isCopayRequest(item) && isComplete
  if (isPriorAuthorization || isCompleteCopay) {
    return <></>
  }

  return (
    <ActionButton
      data-testid="change-dispensed"
      disabled={(isOrderItem && !orderEditable) || (!isOrderItem && !copayEditable)}
      icon={
        <IconWrapper>
          <CycleIcon fill={bodyPrimaryColor} />
        </IconWrapper>
      }
      label="Change dispensed"
      hotKey="C"
      hotKeyMeta={{ ctrl: true, shift: true }}
      hotKeyLabel="CTRL-SHIFT-C"
      onClick={() => showModal(() => <ChangeDispensed item={item} fill={fill} prescription={prescription} />)}
    />
  )
}
