import { useState, useEffect } from 'react'
import { useMutation } from '@truepill/tpos-react-router'
import type { PrescriberAddress } from '@truepill/tpos-types'
import { allRolesExceptCustomerSupport } from '@truepill/tpos-types'
import AddressEntry from 'components/AddressEntry'
import HotKeyToolTip from 'components/HotKeyToolTip'
import PrescriberCard from 'components/PrescriberCard'
import PrescriberSearch from 'components/PrescriberSearch'
import { EditMode, NormalMode } from 'components/RXPageStructure'
import {
  ListRowLabel,
  ListRowValue,
  PaddingBlock,
  RaisedAnchor,
  RightFilledHeader,
  RXCenterCell,
  RXCenterCellHeader,
  RXCenterColumn,
  RXImageCell,
  RXListRow,
  RXRightCell,
  RXTitleRow,
  TwoColumnFilledHeader,
} from 'components/RXTable'
import type { Option } from 'components/Select'
import Select from 'components/Select'
import { UPDATE_PRESCRIPTION, UPDATE_PRESCRIBED_CUSTOMER } from 'gql'
import { Box } from 'grommet'
import useEditMode from 'hooks/useEditMode'
import useHotKey, { HotKeyLevel } from 'hooks/useHotKey'
import { useTPCacheContext } from 'providers/TPCacheProvider'
import { usePlusClient } from 'providers/VisionRouter'
import styled from 'styled-components/macro'
import type { EscriptPrescriber, EscriptSupervisor, Prescription } from 'types'
import { getPrescriberAddress, formatPhoneNumber, checkIfProvided, getRxImagePrescriber } from 'utils'
import { normalizeProviderType } from 'utils/prescriber'
import AuthLimited from './AuthLimited'
import CustomerCard from './CustomerCard'
import CustomerSearch from './CustomerSearch'
import DropDownOption from './DropDownOption'
import RxAnnotations from './RxAnnotations'

type ReviewPrescriberProps = {
  prescription: Prescription
  withoutAnnotations?: boolean
}

const ReviewPrescriber = ({ prescription, withoutAnnotations = false }: ReviewPrescriberProps): JSX.Element => {
  const { routeToHash } = usePlusClient()
  const [editMode] = useEditMode()
  const [showCard, setShowCard] = useState(true)
  const [selectedPrescriberId, setSelectedPrescriberId] = useState(prescription.prescriber._id)
  const [showCustomerCard, setShowCustomerCard] = useState(true)
  const escript = prescription?.escript
  const escriptPrescriber = escript?.prescriber as EscriptPrescriber
  const escriptSupervisor = escript?.supervisor as EscriptSupervisor
  const directTransferPrescriber = prescription?.directTransfer?.prescriber
  const { customersById, getCustomerNameById } = useTPCacheContext()
  const [updatePrescription] = useMutation(UPDATE_PRESCRIPTION, {
    refetchQueries: ['getFullOrder'],
  })
  const [updatePrescribedCustomer] = useMutation(UPDATE_PRESCRIBED_CUSTOMER, {
    refetchQueries: ['getFullOrder'],
  })

  const [prescriberAddress, setPrescribrerAdress] = useState<Option>()

  useEffect(() => {
    const mPrescriberAddress = getPrescriberAddress(prescription)
    const prescriptionPracticeAddress = convertAddressToOption(mPrescriberAddress)
    setPrescribrerAdress(prescriptionPracticeAddress)
  }, [])

  const convertAddressToOption = (address?: PrescriberAddress): Option => {
    const businessName = address?.businessName || ''
    const addressInfo = `${address?.street1 || ''} ${address?.street2 || ''} ${address?.city || ''}
      ${address?.state || ''} ${address?.zip || ''}`
    const phone = address?.phone || ''

    const label = `${businessName || 'No Clinic Name Provided'}, ${addressInfo}, ${phone || 'None Phone Provided'}`
    const value = address?._id

    return { value, label, additionalProps: { businessName, addressInfo, phone } }
  }

  const directTransferCustomer = prescription?.directTransfer?.customerId
    ? customersById[prescription?.directTransfer?.customerId.toString()]
    : undefined

  const rxImagePrescriber = getRxImagePrescriber(escriptPrescriber, directTransferPrescriber)

  const prescriptionPracticeAddress = getPrescriberAddress(prescription)
  const prescriberAddressOptions = prescription?.prescriber?.addresses?.map(convertAddressToOption)
  const prescriberAddressOption = rxImagePrescriber?.address
  const addressHasChanged = prescriberAddressOption && prescriptionPracticeAddress !== prescriberAddressOption

  useHotKey('2', HotKeyLevel.normal, () => {
    routeToHash('ReviewPrescriber')
  })

  if (!prescription) {
    return <></>
  }

  return (
    <Box direction="column" data-testid="ReviewPrescriber">
      <RXTitleRow data-testid="headers">
        <RaisedAnchor id="ReviewPrescriber" />
        <RXImageCell></RXImageCell>
        <EditMode>
          <RXCenterCellHeader>
            <PaddingBlock />
            <TwoColumnFilledHeader>
              Prescriber
              <HotKeyToolTip label="2" position="right" offsetLeft={1} offsetTop={0} />
            </TwoColumnFilledHeader>
          </RXCenterCellHeader>
        </EditMode>
        <NormalMode>
          <RXCenterColumn>
            <PaddingBlock />
            <RightFilledHeader>
              Review prescriber
              <HotKeyToolTip label="2" position="right" offsetLeft={1} offsetTop={0} />
            </RightFilledHeader>
          </RXCenterColumn>
        </NormalMode>
        {!withoutAnnotations && (
          <AdditionalNotesRightCell>
            <RightFilledHeader>Annotations</RightFilledHeader>
          </AdditionalNotesRightCell>
        )}
      </RXTitleRow>

      <Box direction="row">
        <Box basis="2/3" width={{ min: '53rem' }} data-testid="table-body">
          <RXListRowTwoThirds data-test-row="prescriber">
            <RXImageCell>
              <ListRowLabel>Prescriber:</ListRowLabel>
              <ListRowValue>
                {checkIfProvided(rxImagePrescriber?.firstName)} {rxImagePrescriber?.lastName}
                {rxImagePrescriber?.suffix ? `, ${rxImagePrescriber.suffix}` : ''}
              </ListRowValue>
            </RXImageCell>
            <RXCenterCell>
              <ListRowLabel>Prescriber:</ListRowLabel>
              <ListRowValue>
                <EditMode>
                  <AuthLimited
                    roles={allRolesExceptCustomerSupport}
                    fallback={
                      <>
                        {prescription.prescriber.firstName} {prescription.prescriber.lastName}
                      </>
                    }
                  >
                    <>
                      {showCard ? (
                        <div>
                          <PrescriberCard
                            prescriberId={selectedPrescriberId}
                            closeCallback={() => setShowCard(false)}
                          />
                        </div>
                      ) : (
                        <PrescriberSearch
                          onSelect={({ prescriberId }) => {
                            setSelectedPrescriberId(prescriberId.toString())
                            updatePrescription({
                              variables: {
                                prescriptionId: prescription._id,
                                prescriberId,
                              },
                            })
                            setShowCard(true)
                          }}
                        />
                      )}
                    </>
                  </AuthLimited>
                </EditMode>
                <NormalMode>
                  {prescription.prescriber.firstName} {prescription.prescriber.lastName}
                </NormalMode>
              </ListRowValue>
            </RXCenterCell>
          </RXListRowTwoThirds>
          <RXListRowTwoThirds data-test-row="credential">
            <RXImageCell>
              <ListRowLabel>Credential:</ListRowLabel>
              <ListRowValue>
                {rxImagePrescriber?.prescriberType
                  ? normalizeProviderType(rxImagePrescriber.prescriberType)
                  : 'unknown'}
              </ListRowValue>
            </RXImageCell>
            <RXCenterCell>
              <ListRowLabel>Credential:</ListRowLabel>
              <ListRowValue>
                {prescription.prescriber.npiDoc?.credential
                  ? normalizeProviderType(prescription.prescriber.npiDoc.credential)
                  : 'unknown'}
              </ListRowValue>
            </RXCenterCell>
          </RXListRowTwoThirds>
          {(rxImagePrescriber?.npi || prescription.prescriber.npi) && (
            <RXListRowTwoThirds data-test-row="npi">
              <RXImageCell>
                <ListRowLabel>NPI:</ListRowLabel>
                <ListRowValue>{checkIfProvided(rxImagePrescriber?.npi)}</ListRowValue>
              </RXImageCell>
              <RXCenterCell>
                <ListRowLabel>NPI:</ListRowLabel>
                <ListRowValue>{checkIfProvided(prescription.prescriber.npi)}</ListRowValue>
              </RXCenterCell>
            </RXListRowTwoThirds>
          )}
          {(rxImagePrescriber?.stateLicense || prescription.prescriber.stateLicense) && (
            <RXListRowTwoThirds data-test-row="state-license">
              {rxImagePrescriber?.stateLicense && (
                <RXImageCell>
                  <ListRowLabel>License #:</ListRowLabel>
                  <ListRowValue>{checkIfProvided(rxImagePrescriber?.stateLicense)}</ListRowValue>
                </RXImageCell>
              )}
              {prescription.prescriber.stateLicense && (
                <RXCenterCell>
                  <ListRowLabel>License #:</ListRowLabel>
                  <ListRowValue>{checkIfProvided(prescription.prescriber.stateLicense)}</ListRowValue>
                </RXCenterCell>
              )}
            </RXListRowTwoThirds>
          )}
          <RXListRowTwoThirds data-test-row="supervisor">
            <RXImageCell>
              <ListRowLabel>Supervising prescriber:</ListRowLabel>
              <ListRowValue>
                {checkIfProvided(escriptSupervisor?.firstName)} {escriptSupervisor?.lastName}
              </ListRowValue>
            </RXImageCell>
            <RXCenterCell>
              {/* <EditMode>
                <ListRowLabel>
                  <CompoundLabel>Supervising prescriber (first name):</CompoundLabel>
                  <CompoundLabel>Supervising prescriber (last name):</CompoundLabel>
                </ListRowLabel>
                <CompoundValue>
                  <CompoundTextInput
                    value={escriptSupervisor?.firstName}
                    onChange={() => {
                      // This needs a `setPrescriber` mutation to use here,
                      // which updates the prescriber values.
                    }}
                  />
                  <CompoundTextInput
                    value={escriptSupervisor?.lastName}
                    onChange={() => {
                      // This needs a `setPrescriber` mutation to use here,
                      // which updates the prescriber values.
                    }}
                  />
                </CompoundValue>
              </EditMode> */}
              <ListRowLabel>Supervising prescriber:</ListRowLabel>
              <ListRowValue>
                {checkIfProvided(escriptSupervisor?.firstName)} {escriptSupervisor?.lastName}
              </ListRowValue>
            </RXCenterCell>
          </RXListRowTwoThirds>
          <RXListRowTwoThirds data-test-row="address">
            <RXImageCell>
              <ListRowLabel>Address:</ListRowLabel>
              <ListRowValue>
                <AddressEntry address={rxImagePrescriber?.address} />
              </ListRowValue>
            </RXImageCell>
            <RXCenterCell>
              <ListRowLabel>Address:</ListRowLabel>
              <NormalMode>
                <ListRowValue>
                  <AddressEntry address={prescriptionPracticeAddress} />
                </ListRowValue>
              </NormalMode>
              <EditMode>
                <AuthLimited
                  roles={allRolesExceptCustomerSupport}
                  fallback={
                    <ListRowValue>
                      <AddressEntry address={prescriptionPracticeAddress} />
                    </ListRowValue>
                  }
                >
                  <ListRowValueAddress>
                    <Select
                      value={prescriberAddress}
                      error={addressHasChanged}
                      disableClear
                      placeholder="Select a practice..."
                      options={prescriberAddressOptions}
                      onChange={([selectAddress]) => {
                        const { value: id } = selectAddress ?? Object.create({})
                        if (prescriberAddress && id !== prescriberAddress?.value) {
                          setPrescribrerAdress(selectAddress)
                          updatePrescription({
                            variables: {
                              prescriptionId: prescription._id,
                              prescriberAddressId: id,
                            },
                          })
                        }
                      }}
                      children={(option, status) => (
                        <DropDownOption
                          highlighted={status.highlighted}
                          selected={status.selected}
                          data-testid="prescriber-searchable-selected"
                        >
                          <h4>{checkIfProvided(option.additionalProps?.businessName, 'No clinic name provided')}</h4>
                          <ul>
                            <li data-testid="prescriber-address">{option.additionalProps?.addressInfo}</li>
                            <li data-testid="prescriber-phone">
                              {checkIfProvided(formatPhoneNumber(option.additionalProps?.phone))}
                            </li>
                          </ul>
                        </DropDownOption>
                      )}
                    />
                    {addressHasChanged ? (
                      <StyledSpan>
                        <p>The prescriber image does not match the prescription image.</p>
                      </StyledSpan>
                    ) : (
                      ''
                    )}
                  </ListRowValueAddress>
                </AuthLimited>
              </EditMode>
            </RXCenterCell>
          </RXListRowTwoThirds>
          <RXListRowTwoThirds data-test-row="phone">
            <RXImageCell>
              <ListRowLabel>Phone:</ListRowLabel>
              <ListRowValue>
                {checkIfProvided(formatPhoneNumber(rxImagePrescriber?.communicationNumbers?.phone?.number))}
              </ListRowValue>
            </RXImageCell>
            <RXCenterCell>
              <ListRowLabel>Phone:</ListRowLabel>
              <ListRowValue>
                {checkIfProvided(
                  formatPhoneNumber(prescriptionPracticeAddress?.phone || prescription.prescriber?.contacts?.phone),
                )}
              </ListRowValue>
            </RXCenterCell>
          </RXListRowTwoThirds>
          <RXListRowTwoThirds data-test-row="fax">
            <RXImageCell>
              <ListRowLabel>Fax:</ListRowLabel>
              <ListRowValue>
                {checkIfProvided(formatPhoneNumber(rxImagePrescriber?.communicationNumbers?.fax?.[0]?.number))}
              </ListRowValue>
            </RXImageCell>
            <RXCenterCell>
              <ListRowLabel>Fax:</ListRowLabel>
              <ListRowValue>
                {checkIfProvided(
                  formatPhoneNumber(prescriptionPracticeAddress?.fax || prescription.prescriber?.contacts?.fax),
                )}
              </ListRowValue>
            </RXCenterCell>
          </RXListRowTwoThirds>
          <RXListRowTwoThirds data-test-row="dea">
            <RXImageCell>
              <ListRowLabel>DEA:</ListRowLabel>
              <ListRowValue>{checkIfProvided(rxImagePrescriber?.deaNumber)}</ListRowValue>
            </RXImageCell>
            <RXCenterCell>
              <ListRowLabel>DEA:</ListRowLabel>
              <ListRowValue>{checkIfProvided(prescription.prescriber.dea)}</ListRowValue>
            </RXCenterCell>
          </RXListRowTwoThirds>
          <RXListRowTwoThirds data-test-row="customer">
            <RXImageCell>
              <ListRowLabel>Customer:</ListRowLabel>
              <ListRowValue>{checkIfProvided(escript?.clinicName || directTransferCustomer?.name)}</ListRowValue>
            </RXImageCell>
            <RXCenterCell>
              <ListRowLabel>Customer:</ListRowLabel>
              <ListRowValue>
                <EditMode>
                  <>
                    {showCustomerCard ? (
                      <CustomerCard
                        customerId={prescription.customerId}
                        closeCallback={() => setShowCustomerCard(false)}
                      />
                    ) : (
                      <CustomerSearch
                        isVisible
                        singleSelection
                        onSelect={(customerId: string) => {
                          updatePrescribedCustomer({
                            variables: {
                              rxId: prescription._id,
                              customerId,
                            },
                          })
                          setShowCustomerCard(true)
                        }}
                      />
                    )}
                  </>
                </EditMode>
                <NormalMode>{checkIfProvided(getCustomerNameById(prescription.customerId))}</NormalMode>
              </ListRowValue>
            </RXCenterCell>
          </RXListRowTwoThirds>
        </Box>

        {!withoutAnnotations && (
          <Box basis="1/3" pad={{ left: '2rem' }} overflow="auto" data-testid="annotations-container">
            <RxAnnotations height={editMode ? '19rem' : '12.5rem'} />
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default ReviewPrescriber

const AdditionalNotesRightCell = styled(RXRightCell)`
  flex-wrap: wrap;
  overflow-y: visible;
  position: relative;
  white-space: nowrap;
  padding-top: 4.3444rem;
  height: 0;
  padding-left: 0;
  background-color: white;
`
const ListRowValueAddress = styled.div.attrs({
  'data-testid': 'value',
})`
  grid-row: 1;
  align-self: center;
  display: flex;
  flex-direction: column;
  padding-right: 0.625rem;
  overflow: overlay;
  align-items: flex-stretch;
  > button {
    flex: 1;
  }
`

const StyledSpan = styled.span`
  color: red;
  font-size: 0.8rem;
  display: flex;
  justify-content: left;
`

const RXListRowTwoThirds = styled(RXListRow)`
  grid-template-columns: [image] minmax(26.5rem, 1fr) [center] minmax(26.5rem, 1fr);
`
