import MessagesPane from 'components/MessagesPane'
import { UnPaddedPageContainer } from 'components/PageStructure'
import { useSetPageTitle } from 'hooks'
import useOutboundTransfer from 'hooks/navigation/useOutboundTransfer'
import { LoadingOrder } from '../../../fulfillment/orders/rx'
import OutboundTransferView from './outboundTransfer'

type OutboundTransferPageProps = { outboundTransferId: string }

const OutboundTransferPage = ({ outboundTransferId }: OutboundTransferPageProps): JSX.Element => {
  const { outboundTransfer, patient, prescription, loading, error } = useOutboundTransfer(outboundTransferId)

  useSetPageTitle('Outbound Transfers')

  if (error) {
    return (
      <UnPaddedPageContainer>
        <p>Error loading Outbound Transfer {JSON.stringify(error)}</p>
      </UnPaddedPageContainer>
    )
  }

  if (loading || !outboundTransfer || !prescription || !patient) {
    return <LoadingOrder />
  }

  return (
    <>
      <UnPaddedPageContainer>
        <OutboundTransferView
          outboundTransfer={outboundTransfer}
          prescription={prescription}
          patient={patient}
          loading={false}
          logs={[]}
        />
      </UnPaddedPageContainer>
      <MessagesPane logs={[]} />
    </>
  )
}
export default OutboundTransferPage
