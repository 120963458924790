import { useState } from 'react'
import { Select, Button } from '@truepill/react-capsule'
import CloseButton from 'components/CloseButton'
import CustomizedTextField from 'components/CustomizedTextField'
import { ButtonsContainer } from 'components/Modal'
import { remove } from 'ramda'
import styled from 'styled-components'
import type { InsuranceDur, OrderInsurance } from 'types'
import { checkIfProvided } from 'utils'
import { getOptionsFromLabelMap, findFullOption } from '../helper'
import { DURCodes } from '../ServiceCodes'

const levelOfEffortOptions = getOptionsFromLabelMap(DURCodes.levelOfEffort, true)
const resultOfServiceOptions = getOptionsFromLabelMap(DURCodes.resultOfService, true)
const professionalServiceOptions = getOptionsFromLabelMap(DURCodes.professionalService, true)
const reasonForServiceOptions = getOptionsFromLabelMap(DURCodes.Reason, true)
const coAgentQualifierOptions = getOptionsFromLabelMap(DURCodes.coAgentQualifier, true)
const deleteFromArray = (insuranceDurs: InsuranceDur[]) => (index: number) => remove(index, 1, insuranceDurs)

const emptyDur = {
  orderInsuranceOptionId: '',
  reasonForService: '00',
  professionalService: '00',
  resultOfService: '00',
  levelOfEffort: '00',
  coAgentQualifier: '00',
  coAgentId: '',
}

type EditDURServiceCodesProps = {
  lastClaim?: any
  handleChange: (
    durIndex: number,
    name: keyof InsuranceDur,
    setFormDUR: (value: InsuranceDur[]) => void,
    value?: string,
  ) => void
  handleAddUpdate: (durs: InsuranceDur[], setFormDUR: (value: InsuranceDur[]) => void) => void
  payerInfo: OrderInsurance
}

const EditDURServiceCodes = ({ handleChange, payerInfo, handleAddUpdate }: EditDURServiceCodesProps): JSX.Element => {
  const durs: InsuranceDur[] = !payerInfo?.durs?.length ? [emptyDur] : payerInfo?.durs

  const [formDUR, setFormDUR] = useState<InsuranceDur[]>(durs)
  const deleteDUR = deleteFromArray(formDUR)

  const inputStyleSelect = {
    margin: '4px 0 0 0',
  }

  return (
    <>
      {formDUR.map((dur, durIndex) => (
        <ul key={`DUR-${durIndex}`}>
          <PayerTitle>
            DUR service code {durIndex > 0 && durIndex + 1}{' '}
            <StyledCloseButton clickCallback={() => handleAddUpdate([...deleteDUR(durIndex)], setFormDUR)} />
          </PayerTitle>
          <PayerFormElement>
            <PayerFormElementLabel style={{ margin: '4px 0 0 0' }}>Reason (E4)</PayerFormElementLabel>
            <Select
              options={reasonForServiceOptions}
              label=""
              required
              selectedKey="label"
              placeholder=""
              onChange={option => {
                const value = option && option.value !== '00' ? option.value : undefined
                handleChange(durIndex, 'reasonForService', setFormDUR, value)
              }}
              value={findFullOption(dur.reasonForService || '00', reasonForServiceOptions)}
              css={inputStyleSelect}
              variant="small"
            />
          </PayerFormElement>
          <PayerFormElement>
            <PayerFormElementLabel>Professional service code (E5)</PayerFormElementLabel>
            <Select
              options={professionalServiceOptions}
              label=""
              required
              selectedKey="label"
              placeholder=""
              onChange={option => {
                const value = option && option.value !== '00' ? option.value : undefined
                handleChange(durIndex, 'professionalService', setFormDUR, value)
              }}
              value={findFullOption(dur.professionalService || '00', professionalServiceOptions)}
              css={inputStyleSelect}
              variant="small"
            />
          </PayerFormElement>
          <PayerFormElement>
            <PayerFormElementLabel>Result of service (E6)</PayerFormElementLabel>
            <Select
              options={resultOfServiceOptions}
              label=""
              required
              selectedKey="label"
              placeholder=""
              onChange={option => {
                const value = option && option.value !== '00' ? option.value : undefined
                handleChange(durIndex, 'resultOfService', setFormDUR, value)
              }}
              value={findFullOption(dur.resultOfService || '00', resultOfServiceOptions)}
              css={inputStyleSelect}
              variant="small"
            />
          </PayerFormElement>
          <PayerFormElement>
            <PayerFormElementLabel>Level of effort (8E)</PayerFormElementLabel>
            <Select
              options={levelOfEffortOptions}
              label=""
              required
              selectedKey="label"
              placeholder=""
              onChange={option => {
                const value = option && option.value !== '00' ? option.value : undefined
                handleChange(durIndex, 'levelOfEffort', setFormDUR, value)
              }}
              value={findFullOption(dur.levelOfEffort || '00', levelOfEffortOptions)}
              css={inputStyleSelect}
              variant="small"
            />
          </PayerFormElement>
          <PayerFormElement>
            <PayerFormElementLabel>Co-Agent ID qualifier</PayerFormElementLabel>
            <Select
              options={coAgentQualifierOptions}
              label=""
              required
              selectedKey="label"
              placeholder=""
              onChange={option => {
                const value = option && option.value !== '00' ? option.value : undefined
                handleChange(durIndex, 'coAgentQualifier', setFormDUR, value)
              }}
              value={findFullOption(dur.coAgentQualifier || '00', coAgentQualifierOptions)}
              css={inputStyleSelect}
              variant="small"
            />
          </PayerFormElement>

          <PayerFormElement>
            <PayerFormElementLabel>Co-agent ID</PayerFormElementLabel>
            <CustomizedTextField
              placeholder=""
              value={checkIfProvided(payerInfo?.durs?.[durIndex]?.coAgentId)}
              onChange={({ target: { value: coAgentId } }) =>
                handleChange(durIndex, 'coAgentId', setFormDUR, coAgentId)
              }
              style={inputStyleSelect}
              size="sm"
            />
          </PayerFormElement>
        </ul>
      ))}
      <ButtonsContainerLeft>
        <Button
          variant="primary-outline"
          style={{ margin: '8px 0 0 0' }}
          size="sm"
          onClick={() => {
            handleAddUpdate([...formDUR, emptyDur], setFormDUR)
          }}
        >
          Add another service code
        </Button>
      </ButtonsContainerLeft>
    </>
  )
}

export default EditDURServiceCodes

const ButtonsContainerLeft = styled(ButtonsContainer)`
  margin-top: 0px;
`

const PayerTitle = styled.h2`
  font-weight: 500;
  margin: 0 0 0 0;
  display: flex;
  padding: 0;
`

const StyledCloseButton = styled(CloseButton)`
  position: relative;
  margin-left: auto;
  right: 0;
`

const PayerFormElement = styled.div``

const PayerFormElementLabel = styled.div`
  font-weight: 500;
  font-size: 0.875rem;
  display: block;
  width: 100%;
  margin: 12px 0 0 0;
`
