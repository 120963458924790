import { useHistory, useParams, useMutation, useQuery } from '@truepill/tpos-react-router'
import { SaveButton } from 'components/ActionButton'
import LoadingSpinner from 'components/Loading'
import NewPracticeForm from 'components/NewPracticeForm'
import { CancelButton, PageContainer } from 'components/PageStructure'
import PrescriberCard from 'components/PrescriberCard'
import { ADD_PRESCRIBER_PRACTICE, GET_SYNCED_PRESCRIBER } from 'gql'
import useErrorToast from 'hooks/toast/useErrorToast'
import useAddPractice from 'hooks/useAddPractice'
import useSetPageTitle from 'hooks/useSetPageTitle'
import { useLocation } from 'react-use'
import { goToViewPrescriber } from 'routes'
import type { Prescriber } from 'types'
import { formatPhoneForDB, validatePractice } from 'utils'
import { Title, LoadingSpinnerContainer, DataContainer, StyledFilledHeader, ButtonContainer } from './StyledComponents'

const AddPrescriberPracticePage = (): JSX.Element => {
  const history = useHistory()
  const { search } = useLocation()
  const { prescriberId } = useParams<{ prescriberId: string }>()
  const { businessName, phone, fax, address, handlePracticeFormChange } = useAddPractice()

  const showErrorToast = useErrorToast()

  useSetPageTitle('New practice')
  console.info(`Prescrier ID ${prescriberId}`)

  const [addPractice] = useMutation(ADD_PRESCRIBER_PRACTICE, {
    onCompleted() {
      history.push(goToViewPrescriber({ prescriberId, search }))
    },
    onError(e) {
      showErrorToast('Failed to add new practice to prescriber: ' + e.message)
      console.error(e)
    },
  })

  const { data, loading, error } = useQuery(GET_SYNCED_PRESCRIBER, {
    variables: { prescriberId },
  })

  const prescriber = data?.getSyncedPrescriber as Prescriber

  if (error) {
    return (
      <PageContainer>
        <Title>Error loading Prescriber: {JSON.stringify(error)}</Title>
      </PageContainer>
    )
  }

  if (loading || !prescriber) {
    return (
      <PageContainer>
        <LoadingSpinnerContainer>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      </PageContainer>
    )
  }

  return (
    <PageContainer>
      <Title>New practice</Title>
      <DataContainer>
        <StyledFilledHeader>Prescriber</StyledFilledHeader>
        <PrescriberCard prescriberId={prescriber._id} />
        <StyledFilledHeader>Practice</StyledFilledHeader>
        <NewPracticeForm
          businessName={businessName}
          address={address}
          phone={phone}
          fax={fax}
          onChange={handlePracticeFormChange}
        />
        <ButtonContainer>
          <CancelButton
            label="Cancel"
            onClick={() => history.push(goToViewPrescriber({ prescriberId: prescriber._id, search }))}
          />
          <SaveButton
            disabled={!validatePractice(address, phone || '', fax || '')}
            label="Create"
            onClick={() =>
              addPractice({
                variables: {
                  npi: prescriber.npi,
                  stateLicense: prescriber.stateLicense,
                  newPractice: {
                    address,
                    phone: formatPhoneForDB(phone),
                    ...(businessName && { name: businessName }),
                    ...(fax && { fax: formatPhoneForDB(fax) }),
                  },
                },
                refetchQueries: ['getSyncedPrescriber'],
              })
            }
          />
        </ButtonContainer>
      </DataContainer>
    </PageContainer>
  )
}

export default AddPrescriberPracticePage
