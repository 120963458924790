import { Link } from '@truepill/tpos-react-router'
import { ReactComponent as LinkIcon } from 'assets/icons/external-link.svg'
import styled from 'styled-components'
import { primaryColor } from 'styles/styleVariables'
import { getTPDashUrl } from 'utils/urls'

const tpDashboardUrl = getTPDashUrl()

const DashboardTokenLink = ({
  token,
  entity,
  iconColor,
}: {
  token: string
  entity: 'copays' | 'orders' | 'patient'
  iconColor?: string
}) => {
  return (
    <StyledLink target="_blank" to={{ pathname: `${tpDashboardUrl}/${entity}/${token}` }} title={`${token}`}>
      <span style={{ display: 'inline-flex' }}>
        {token} <LinkIcon width="0.625rem" stroke={iconColor || 'black'} />
      </span>
    </StyledLink>
  )
}

const StyledLink = styled(Link)`
  font-size: inherit;
  margin: 0 0.5rem;
  :hover {
    margin: 0 0.125rem 0rem 0.5rem;
    color: ${primaryColor};
    svg {
      stroke: ${primaryColor};
      width: 1rem;
    }
  }
`

export default DashboardTokenLink
