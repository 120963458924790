import styled from 'styled-components'

const RXPrescriberDataContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`

const RXDataContainer = styled.ul`
  display: flex;
  flex-basis: 100%;
  flex-direction: row;
  align-items: flex-start;
  :not(:last-child) {
    margin-bottom: 1.75rem;
  }
  :last-child {
    margin-bottom: 1rem;
  }
`

const RXDataColumn = styled.ul`
  :not(:last-child) {
    margin-right: 1.25rem;
  }
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 33%;
`

export { RXPrescriberDataContainer, RXDataContainer, RXDataColumn }
