import type { ApprovedCopay } from '../copayRequest'
import type { PatientContacts } from '../patient'
import type { PrintHistory } from '../printHistory'
import type { EntityId, WithStamps } from '../shared'
import type { ClaimData } from './claim'
import type { ClaimSummary } from './claimSummary'
import type { DUR, DURScreeningMessage, DURSubjectCategories } from './dur'
import type { FillTransfer } from './transfer'
import type { PV1Verification, PV2Verification } from './verifications'

export type CopayPaymentConsentStatus = 'Accepted' | 'Rejected'

export enum FillStatus {
  OnHold = 'OnHold',
  CopayCheck = 'CopayCheck',
  InFulfillment = 'InFulfillment',
  Complete = 'Complete',
  Cancelled = 'Cancelled',
  Reversed = 'Reversed',
  Transferred = 'Transferred',
}

export interface NextFillInfo {
  dueDate: Date
  dateOkToFill: Date
  daysLeft: number
  isOkToFill: boolean
}

export enum FillLabelReprintReason {
  ReplacementLabel = 'Replacement label',
  AdditionalLabelsRequired = 'Additional labels required',
  EditDirections = 'Edit directions',
}

export enum FillDirectionsMaxLength {
  GENERIC = 100,
  GENERIC_FF = 130,
  GENERIC_FF_SHORT = 100,
  BRAND = 120,
  BRAND_FF = 130,
}

export const NonActionableFillStatusesSharingFillCodes = [FillStatus.Cancelled, FillStatus.OnHold, FillStatus.Reversed]

export interface HandlingTags {
  deaScheduleNum?: string
  isColdChain?: boolean
  isExpensive?: boolean
  isSpecialty?: boolean
  isControlledSubstance?: boolean
}

// threshold past which an item is considered expensive in USD
export const EXPENSIVE_DOLLAR_THRESHOLD = 1000

export const DEFAULT_HANDLING_TAGS: HandlingTags = Object.freeze({
  deaScheduleNum: '',
  isColdChain: false,
  isExpensive: false,
  isSpecialty: false,
  isControlledSubstance: false,
})

export enum ColdChainStorageConditionCodes {
  Refrigerated = 'R',
  Frozen = 'F',
  DeepFrozen = 'D',
}

export enum DeaScheduleNums {
  two = '2',
  three = '3',
  four = '4',
  five = '5',
}

export enum HandlingTagLabels {
  HIGH_COST = 'High cost',
  COLD_CHAIN = 'Cold Chain',
  SPECIALTY = 'Specialty',
  CIII_CV = 'CIII - CV',
  CII = 'CII',
  CONTROLLED_SUBSTANCE = 'Controlled Substance',
}

export enum PatientConsentRequestTypes {
  Order = 'Order',
  Copay = 'Copay',
}

export interface FormularyResult {
  onFormulary?: boolean
  preferred?: boolean
  consigned?: boolean
  outOfStock?: boolean
  needsReplenishment?: boolean
  acquisitionCost?: number
  otherConsigned?: boolean
  error?: string
}

export interface StockInfo {
  lot: string
  expirationDate: string
  serialNumber: string | null
  quantity: number
}

export interface FfsInventoryInfo {
  ownerId?: string
  ownerName?: string
  ownerLegacyId?: number
  ownerCustomerId?: string
  externalSystemId?: string
  externalSystemName?: string
  inventoryId?: string
  warehouseLocation?: string // TODO unmark optional once FFS starts sending
  expectedLots?: string[] // TODO unmark optional once FFS starts sending
  needsReplenishment?: boolean
}

export interface Pod {
  id: string
  podName: string
  locationLegacyId: number
  drugs: PodDrug[]
  createdAt?: string
  updatedAt?: string
}

export interface PodDrug {
  id: string
  podId: string
  ndc: string
  warehouseLocation: string
  createdAt?: string
  updatedAt?: string
}

export interface Dispensed {
  daysSupply?: number
  directions?: string
  sigCode?: string
  dispensedAt: string
  gcn?: string
  inventoryGroupId?: EntityId
  manufacturer: string
  name: string
  ndc: string
  packageSizeUnitOfMeasure?: string
  packagesRequired: number
  prn?: boolean
  quantity: number
  quantityPerPackage: number
  strength?: MedicationStrength
  upc?: string
  formularyResult?: FormularyResult
  stockInfo?: StockInfo[]
  ffsInventoryInfo?: FfsInventoryInfo
  manualEntryFilled?: boolean
}

export interface FillPrescribed {
  ndc?: string
  name?: string
  daw?: number
  directions?: string
  writtenDate: string
  expirationDate: string
  daysSupply?: number
  quantity: number
  quantityRemaining?: number
  numberOfRefills?: number
  refillsRemaining: number
  totalQuantity: number
}
export interface MedicationStrength {
  value?: string
  form?: string
  unit?: string
}
export interface FillDUR extends WithStamps {
  _id: EntityId
  input: DURSubjectCategories
  screeningNdcs: string[]
  deferred?: boolean
  results?: DUR[]
  messages?: DURScreeningMessage[]
  userId: EntityId
}

export interface ReversalDetails {
  note?: string
  reason: string
  reversedAt: Date
}

export interface CopayPayments {
  cardholderName?: string
  cardType?: string
  lastFourDigits?: string
  paymentType: string
  transactionAmount?: number
  transactionDate?: Date
}

export interface PatientConsent {
  consentDate?: Date
  consentDateStr?: string
  consentMethod?: string
  consentStatement?: string
  consentType?: string
  patientName?: string
  consentProvidedBy?: string
  item?: string
  requestType?: PatientConsentRequestTypes
}

export interface VerificationPatient {
  name: string
  dob: string
  gender: string
  address?: string
  phone?: PatientContacts['phone']
}

export interface VerificationPrescriber {
  name: string
  npi: string
  supervisingPrescriber: string
  address: string
  phone: string
  dea: string
  customer: string
  npiDoc: {
    credential: string
  }
}

export interface Verifications {
  skipPv1EvaluationResult?: boolean
  pv1?: PV1Verification
  pv2?: PV2Verification
}

export interface FillData extends WithStamps {
  _id: EntityId
  approvedCopay?: ApprovedCopay
  claims: ClaimData[]
  claimSummary?: ClaimSummary
  copayPaymentConsentStatus?: CopayPaymentConsentStatus
  copayPayments?: CopayPayments[]
  dispensed: Dispensed
  prescribed: FillPrescribed
  durScreenings: FillDUR[]
  fillNumber: number
  isPartialComplete?: boolean
  isPartialFill?: boolean
  isPioneerHistory?: boolean
  isReplacement: boolean
  labelsPrinted: number
  lastPrintedAt?: Date
  locationId: EntityId
  /** @deprecated, how we initially stored consent when there was only one, replaced with patientConsents */
  patientConsent?: PatientConsent
  patientConsents?: PatientConsent[]
  printHistory: PrintHistory[]
  replacementFills: number
  reversalDetails?: ReversalDetails
  rxFillCode: string
  shortFillCode: string
  status: FillStatus
  transfer?: FillTransfer
  verifications: Verifications
}
