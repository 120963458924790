import { useRef, useCallback } from 'react'
import { useToastContext } from 'providers/Overlays/ToastProvider'
import type { ToastOptionalArgs } from 'types'
import { ToastType } from 'types'

type useInfoToastType = (infoMessage?: string, args?: ToastOptionalArgs) => void

const useInfoToast = (showRepeatedInfos?: boolean): useInfoToastType => {
  const { showToast } = useToastContext()

  const infoRef = useRef<string>()
  return useCallback(
    (infoMessage?: string, args?: ToastOptionalArgs) => {
      if (infoRef.current !== infoMessage || showRepeatedInfos) {
        infoRef.current = infoMessage
        if (infoMessage) {
          showToast(infoMessage, ToastType.info, args?.timeToAutoDismiss, args?.position)
        }
      }
    },
    [showRepeatedInfos, showToast],
  )
}

export default useInfoToast
