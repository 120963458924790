import Lozenge from 'components/Tiles/Lozenge'
import UserInitialsBadge from 'components/UserInitialsBadge'
import styled from 'styled-components'
import { primaryColor, alertRed } from 'styles/styleVariables'
import type { UserFirstLastName } from 'types'

const ControlledSubstanceNote = (): JSX.Element => {
  const tposUserFragment: UserFirstLastName = {
    firstName: 'T',
    lastName: 'P',
    _id: '',
  }

  return (
    <StyledNote>
      <Row>
        <BadgeContainer>
          <UserInitialsBadge user={tposUserFragment} />
        </BadgeContainer>
        <UserName>TruepillOS</UserName>
      </Row>
      <Row>
        <StyledLozenge backgroundColor={primaryColor}>Drug</StyledLozenge>
        <StyledLozenge backgroundColor={alertRed}>Critical</StyledLozenge>
      </Row>
      <Row>
        This prescription meets the requirements of the <br></br>
        Drug Enforcement Administration&apos;s electronic <br></br>
        prescribing for controlled substances rules (21 <br></br>
        CFR Parts 1300, 1304, 1306, &amp; 1311).
      </Row>
    </StyledNote>
  )
}

const StyledLozenge = styled(Lozenge)`
  margin-left: 0;
`

const BadgeContainer = styled.span`
  font-size: 1.25rem;
`

const UserName = styled.p`
  font-weight: 500;
`

const Row = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  > :not(:last-child) {
    margin-right: 0.625rem;
  }
`

const StyledNote = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  margin-top: 1.25rem;
  > :not(:first-child) {
    margin-top: 0.3125rem;
  }
`

export default ControlledSubstanceNote
