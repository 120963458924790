export interface AutobotConfig {
  deviceName: string
  modelName: string
  statusOrder: MachineStatus[]
  disabledStatuses: MachineStatus[]
  disabledCheckboxStatuses: MachineStatus[]
  allowSelectionStatuses: MachineStatus[]
  disabledSelectOnCompleteToggle: boolean
  showPrint: boolean
}

export interface AutobotConfigMap {
  mini: AutobotConfig
  max: AutobotConfig
  deferredAutomation: AutobotConfig
}

export enum MachineStatus {
  waitingToBeSent = 'waitingToBeSent',
  Error = 'Error',
  Completed = 'Completed',
  Started = 'Started',
  Queued = 'Queued',
  ScannedOut = 'ScannedOut',
  Canceled = 'Canceled',
  NotFound = 'NotFound',
}

export type AutobotBrand = 'parata'

export type AutobotModel = 'max1' | 'max2'

export enum AutobotType {
  mini = 'mini',
  max = 'max',
  noStatus = 'noStatus',
  deferredAutomation = 'deferredAutomation',
}

export const autobotMap: AutobotConfigMap = {
  mini: {
    deviceName: 'mini', // sent by the machine in their responses to us
    modelName: 'mini', // stored on our autobot collection (we can update this to match if we want)
    statusOrder: [MachineStatus.Error, MachineStatus.Completed, MachineStatus.Started, MachineStatus.Queued],
    disabledStatuses: [],
    disabledCheckboxStatuses: [],
    allowSelectionStatuses: [MachineStatus.Queued, MachineStatus.Completed, MachineStatus.Started],
    disabledSelectOnCompleteToggle: false,
    showPrint: true,
  },
  max: {
    deviceName: 'max',
    modelName: 'max2',
    statusOrder: [MachineStatus.Error, MachineStatus.Started, MachineStatus.Queued, MachineStatus.Completed],
    disabledStatuses: [MachineStatus.Started],
    disabledCheckboxStatuses: [MachineStatus.Started],
    allowSelectionStatuses: [MachineStatus.Queued],
    disabledSelectOnCompleteToggle: true,
    showPrint: true,
  },
  deferredAutomation: {
    deviceName: 'deferredAutomation',
    modelName: '',
    statusOrder: [MachineStatus.Error, MachineStatus.Started, MachineStatus.Queued, MachineStatus.Completed],
    disabledStatuses: [MachineStatus.Started],
    disabledCheckboxStatuses: [MachineStatus.Started],
    allowSelectionStatuses: [MachineStatus.Queued],
    disabledSelectOnCompleteToggle: true,
    showPrint: true,
  },
}
