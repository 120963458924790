import { useEffect, useRef } from 'react'
import { useClient, useMutation } from '@truepill/tpos-react-router'
import { ADD_ORDER_USER_EVENT } from 'gql'
import { print } from 'graphql'
import { getBackendUrl } from 'utils/urls'

type UserEventTimerProps = {
  event: string
  orderId: string
  rxFillRequestId?: string
}

/**
 * This component will start a new event every time it mounts, then it will call the addEvent endpoint
 * when either the component is unmounted or the user leaves the page (tab/window closed)
 * @param
 * @returns
 */
export default function UserEventTimer({ event, orderId, rxFillRequestId }: UserEventTimerProps): JSX.Element {
  const [addUserEvent] = useMutation(ADD_ORDER_USER_EVENT)
  const currentEvent = useRef({
    event,
    orderId,
    rxFillRequestId,
    startAt: new Date(),
  })
  const { token } = useClient()

  /**
   * This hook handles the pahehide, so we can keep track of current event even when the user closes the tab/window.
   * We use fetch + keepalive instead of the useMutation hook to support sending it after the tab/window is closed.
   */
  useEffect(() => {
    const backendUrl = getBackendUrl()
    const useV2: string | null = localStorage.getItem('use-v2')
    const defaultBackendUrl = `${window.location.protocol}//${window.location.hostname}/${
      useV2 ? 'v2/graphql' : 'graphql'
    }`
    const url = backendUrl ?? defaultBackendUrl
    // Some browsers like firefox don't support keepalive, in that case we use service worker
    const browserSupportsKeepalive = 'keepalive' in new Request('')

    const handler = (_event: PageTransitionEvent) => {
      if (_event.persisted) {
        return
      }

      const request = {
        method: 'POST',
        body: JSON.stringify([
          {
            operationName: 'addUserEvent',
            variables: {
              orderId,
              rxFillRequestId,
              userEvent: {
                event,
                startAt: currentEvent.current.startAt,
                endAt: new Date(),
              },
            },
            query: print(ADD_ORDER_USER_EVENT),
          },
        ]),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }

      if (!browserSupportsKeepalive) {
        navigator?.serviceWorker?.controller?.postMessage({
          type: 'OutliveAysncApiRequest',
          request,
        })
        return
      }

      /**
       *  https://developer.mozilla.org/en-US/docs/Web/API/fetch
       * The keepalive option can be used to allow the request to outlive the page.
       * Fetch with the keepalive flag is a replacement for the Navigator.sendBeacon() API.
       */
      fetch(url, {
        ...request,
        keepalive: true,
      })
    }

    window.addEventListener('pagehide', handler)
    return () => {
      window.removeEventListener('pagehide', handler)
    }
  }, [])

  /**
   * This hook sets the start of the event on mount, then
   * sends the event on unmount (not executed when closing tab/windows, the previous hook handles that case)
   */
  useEffect(() => {
    const startAt = currentEvent.current.startAt

    return function cleanupEventTimer() {
      const endAt = new Date()

      addUserEvent({
        variables: {
          orderId,
          rxFillRequestId,
          userEvent: {
            event,
            startAt,
            endAt,
          },
        },
      })
    }
  }, [])

  return <></>
}
