import type { EntityId } from '..'

export interface History {
  note?: string
  updatedAt: Date
  updatedBy: EntityId
}

export enum CriticalMedicalNoteType {
  NDC = 'Ndc',
  GPI = 'Gpi',
}

export interface CriticalMedicalNotesInput {
  identifier: string
  note: string
  type: CriticalMedicalNoteType
  userId: EntityId
}

export interface CriticalMedicalNotesUpdate {
  identifier: string
  note: string
  userId: EntityId
}

export interface CriticalMedicalNotesArchive {
  identifier: string
  userId: EntityId
}
