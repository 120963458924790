/* eslint-disable @typescript-eslint/no-use-before-define */
import type * as enums from '../entities/escript/enums'
import type { MIMEType } from '../utility/MIMEType'
import type * as sharedBase from './sharedBase'
import type * as sharedComplex from './sharedComplex'
import type { PatientID } from './sharedIdentification'

export enum InternalResponseStatus {
  Pending = 'Pending', // 'Pending' is internal status for vision, the remaining are NCPDP/Surescripts
  Error = 'Error', // 'Error' is internal status for vision based on error message response
}

export enum RefillResponseStatus {
  Approved = 'Approved',
  ApprovedWithChanges = 'ApprovedWithChanges',
  Replace = 'Replace',
  DeniedNewPrescriptionToFollow = 'DeniedNewPrescriptionToFollow',
  Denied = 'Denied',
}

export enum ChangeResponseStatus {
  Approved = 'Approved',
  ApprovedWithChanges = 'ApprovedWithChanges',
  Denied = 'Denied',
  DeniedNewPrescriptionToFollow = 'DeniedNewPrescriptionToFollow',
  Validated = 'Validated',
}

export enum TransferResponseStatus {
  Approved = 'Approved',
  Denied = 'Denied',
}

export const FillableResponsesTypes = [
  RefillResponseStatus.Approved,
  RefillResponseStatus.ApprovedWithChanges,
  ChangeResponseStatus.Approved,
  ChangeResponseStatus.ApprovedWithChanges,
  ChangeResponseStatus.Validated,
]

export enum ServiceLevel {
  New = 'New',
  Refill = 'Refill',
  Change = 'Change',
  Cancel = 'Cancel',
  ControlledSubstance = 'ControlledSubstance',
  RxFill = 'RxFill',
  RxFillIndicatorChange = 'RxFillIndicatorChange',
  RxTransfer = 'RxTransfer',
  NewRxRequest = 'NewRxRequest',
  NewRxResponseDenied = 'NewRxResponseDenied',
  DrugAdministration = 'DrugAdministration',
  Recertification = 'Recertification',
  ReSupp = 'ReSupp',
  Census = 'Census',
  LongTermCare = 'LongTermCare',
  CIMessage = 'CIMessage',
  CIEvent = 'CIEvent',
  ePA = 'ePA',
  RLE = 'RLE',
  MedicationAdherence = 'MedicationAdherence',
  HighRiskMedication = 'HighRiskMedication',
  MissingMedication = 'MissingMedication',
  PDC = 'PDC',
  MmpocMpiSearch = 'MmpocMpiSearch',
  DispositionRequest = 'DispositionRequest',
  DispositionAcknowledgement = 'DispositionAcknowledgement',
  PatMedBenefitCheck = 'PatMedBenefitCheck',
  SpecialtyPatientEnrollment = 'Specialty Patient Enrollment',
  CCR = 'CCR',
  Eligibility = 'Eligibility',
  MedHistory = 'MedHistory',
  RealTimeFormulary = 'Real-Time Formulary',
  SPOAutoEnrollment = 'SPOAutoEnrollment',
}

export enum MessageType {
  NewRx = 'NewRx',
  RxRenewalRequest = 'RxRenewalRequest',
  RxRenewalResponse = 'RxRenewalResponse',
  RxChangeRequest = 'RxChangeRequest',
  RxChangeResponse = 'RxChangeResponse',
  CancelRx = 'CancelRx',
  CancelRxResponse = 'CancelRxResponse',
  RxFill = 'RxFill',
  RxFillIndicatorChange = 'RxFillIndicatorChange',
  RxTransferRequest = 'RxTransferRequest',
  RxTransferResponse = 'RxTransferResponse',
  RxTransferConfirm = 'RxTransferConfirm',
  NewRxRequest = 'NewRxRequest',
  NewRxResponseDenied = 'NewRxResponseDenied',
  DrugAdministration = 'DrugAdministration',
  Recertification = 'Recertification',
  Resupply = 'Resupply',
  Census = 'Census',
  ClinicalMessage = 'ClinicalMessage',
  ClinicalEvent = 'ClinicalEvent',
  PAInitiationRequest = 'PAInitiationRequest',
  PAInitiationResponse = 'PAInitiationResponse',
  PARequest = 'PARequest',
  PAResponse = 'PAResponse',
  PACancelRequest = 'PACancelRequest',
  PACancelResponse = 'PACancelResponse',
  PAAppealRequest = 'PAAppealRequest',
  PAAppealResponse = 'PAAppealResponse',
  RLEPatientSearch = 'RLEPatientSearch',
  RLEDocumentSearch = 'RLEDocumentSearch',
  RLEDocumentRetrieve = 'RLEDocumentRetrieve',
  MMPOCMedicationAdherence = 'MMPOCMedicationAdherence',
  MMPOCHighRiskMedication = 'MMPOCHighRiskMedication',
  MMPOCMissingMedication = 'MMPOCMissingMedication',
  MMPOCPDC = 'MMPOCPDC',
  MmpocMpiSearch = 'MmpocMpiSearch',
  DispositionRequest = 'DispositionRequest',
  DispositionAcknowledgement = 'DispositionAcknowledgement',
  BenefitRequest = 'BenefitRequest',
  BenefitResponse = 'BenefitResponse',
  SpecialtyInitiationRequest = 'SpecialtyInitiationRequest',
  SPOEnrollmentRequest = 'SPOEnrollmentRequest', // Sent by Surescripts for Accelerator customers
  SpecialtyInitiationResponse = 'SpecialtyInitiationResponse',
  SPOEnrollmentResponse = 'SPOEnrollmentResponse',
}

export interface Address {
  AddressLine1: string
  City: string
  State: string
  ZipCode: string
}

export interface Name {
  LastName: string
  MiddleName?: string
  FirstName: string
  Suffix?: string
  Prefix?: string
}

export interface Patient {
  HumanPatient?: BasePatient
  NonHumanPatient?: BasePatient
}
export interface BasePatient {
  Identification?: PatientID
  Name: Name
  FormerName?: Name
  Gender: enums.Gender
  DateOfBirth: sharedBase.DateType
  Address?: Address
  CommunicationNumbers?: CommunicationNumbers
  PatientLocation?: sharedBase.PatientLocation
  LanguageNameCode?: sharedComplex.LanguageNameCode
  ResidenceCode?: enums.ResidenceCode
  PregnancyIndicator?: enums.BooleanCode
  Race?: sharedBase.CDCCode
  Ethnicity?: sharedBase.CDCCode
  SubstanceUse?: sharedComplex.SubstanceUse
  AlternateContact?: sharedComplex.AlternateContact
  GestationalAge?: enums.GestationalAge
  HospiceIndicator?: enums.HospiceIndicator
}

export interface PatientMandatoryAddressForNewRx extends BasePatient {
  Address: Address
}

// After double checking the schema it looks identical to BasePatient types and required fields
export type PatientWithSubstanceUse = BasePatient

export interface PatientIdentification {
  PatientAccountNumber?: string
  MedicalRecordIdentificationNumberEHR?: string
  SocialSecurity?: string
  MedicareNumber?: string
}

export interface CommunicationNumbers {
  Communication: Communication
}

export interface Communication {
  Number: string
  Qualifier: string
}

export interface Pharmacy {
  Identification: PharmacyIdentification
  StoreName?: string
  BusinessName?: string
  Address: Address
  CommunicationNumbers: CommunicationNumbers
}

export interface PharmacyIdentification {
  NCPDPID: string
  NPI: string
}

export interface PrescriberIdentification {
  NPI: string
  DEANumber: string
  StateLicenseNumber: string
}

export interface PrescriberName {
  LastName: string
  FirstName: string
}

export interface NonVeterinarian {
  Identification: PrescriberIdentification
  Name: PrescriberName
  Address: Address
  CommunicationNumbers: CommunicationNumbers
  Specialty?: string
  ClinicName?: string
}

export interface Prescriber {
  NonVeterinarian: NonVeterinarian
}

export interface MedicationPrescribed {
  DrugDescription: string
  DrugCoded: DrugCoded
  Quantity: Quantity
  Directions: string
  Refills: Partial<{
    Qualifier: string
    Value: string
  }>
  Substitutions: string
  WrittenDate: DateTime
  DaysSupply?: string
  Note?: string
  Sig: string
}

export interface MedicationDispensed extends MedicationPrescribed {
  LastFillDate?: DateTime
  PharmacyRequestedRefills?: string
}

export interface DrugCoded {
  ProductCode: ProductCode
  DrugDBCode?: ProductCode
}

export interface ProductCode {
  Code: string
  Qualifier: ProductQualifierCode
}
export interface Quantity {
  Value: string
  CodeListQualifier: QuantityCodeListQualifier
  QuantityUnitOfMeasure: QuantityUnitOfMeasure
}
export interface QuantityUnitOfMeasure {
  Code: string
}

export interface AllergyOrAdverseEvent {
  Allergies: Allergies
}

export interface Allergies {
  SourceOfInformation: string
  AdverseEvent: AdverseEvent
  DrugProductCoded: DrugProductCoded
  ReactionCoded?: ReactionCoded
  SeverityCoded?: SeverityCoded
  NoKnownAllergies?: string // if 'Y' there are no knowm allergies
}

export interface AdverseEvent {
  Text?: string
  Code?: string
}

export interface ReactionCoded {
  Text?: string
  Code?: string
}

export interface SeverityCoded {
  Text?: string
  Code?: string
}

export interface DrugProductCoded {
  Code?: string
  Qualifier?: string
  Text?: string
}

export interface BenefitsCoordination {
  PayerIdentification?: PayerIdentification
  PayerName?: string
  CardholderID?: string
  CardholderName?: CardholderName
  GroupID?: string
  GroupName?: string
  PBMMemberID?: string
  PayerType?: string
}
export interface PayerIdentification {
  PayerID: string
  ProcessorIdentificationNumber: string
  MutuallyDefined?: string
  IINNumber: string
}
export interface CardholderName {
  FirstName: string
  LastName: string
}

export interface DateTime {
  Date: string
}
export interface Date {
  Date: string
}

export interface Facility {
  Identification: Identification
  FacilityName: string
  CommunicationNumbers: CommunicationNumbers
}

export interface Identification {
  NPI: string
  DEANumber: string
  StateLicenseNumber: string
}

export interface Observation {
  Measurement: Measurement
  ObservationNotes?: string
}
export interface Measurement {
  VitalSign: string
  LOINCVersion: string // External Code List for information on obtaining the LOINC Codes and UCUM
  Value: string
  UnitOfMeasure: string
  UCUMVersion: string // External Code List for information on obtaining the LOINC Codes and UCUM
  ObservationDate: string
}

export interface ObservationNotes {
  ObservationNotes?: string
}

export interface SurescriptsResponse {
  code?: string
  description?: string
  error?: { code?: string; description?: string }
  message?: string
}

export interface Qualifier {
  Qualifier: string
}
export interface WithQualifier {
  _attributes: Qualifier
  _text: string
}

export interface MessageHeader {
  To: WithQualifier
  From: WithQualifier
  MessageID: string
  RelatesToMessageID?: string
  SentTime: string
  Security?: string
  SenderSoftware: Partial<{
    SenderSoftwareDeveloper: string // The developer may be a software vendor or, if the software was developed "in-house", the developer is the entity actually sending the transaction (e.g., a chain).
    SenderSoftwareProduct: string
    SenderSoftwareVersionRelease: string
  }>
  Mailbox?: string
  TestMessage?: string
  RxReferenceNumber?: string
  TertiaryIdentifier?: string
  PrescriberOrderNumber?: string
  DigitalSignature?: string
  PrescriberOrderGroup?: string
  RxReferenceOrderGroup?: string
}

export interface MessageRequestCode {
  Code: string
}

export interface Supervisor {
  NonVeterinarian: Partial<{
    Identification: Identification
    Name: Name
    Address: Address
    CommunicationNumbers: CommunicationNumbers
  }>
}

export interface Approved {
  Reason?: string
}

export interface ApprovedWithChanges {
  Note: string
}
export interface Denied {
  ReasonCode: string
}
export interface Replace {
  Note: string
}

export interface DeniedNewPrescriptionToFollow {
  Note: string
}

export interface MedicationResponse {
  name: string
}

export interface FollowUpPrescriber {
  name: string
}

export enum QuantityCodeListQualifier {
  ORIGINAL_QUANTITY = '38',
  REMAINING_QUANTITY = '40',
  QUANTITY_RECEIVED = '87',
  QUANTITY_SUFFICIENT = 'QS',
  COMPOUND_FINAL_QUANTITY = 'CF',
  CENTRAL_FILL_UNIT_OF_USE_QUANTITY = 'UQ',
  QUANTITY_TRANSFERRED = 'QT',
}

export enum ProductQualifierCode {
  DEVICE_IDENTIFIER = 'DI',
  NATIONAL_DRUG_CODE = 'ND', // NDC
  NATIONAL_DRUG_FILE_REFERENCE = 'RT', // NDF-RT
  UNIQUE_INGREDIENT_IDENTIFIER = 'UN', // UNII
  UNIVERSAL_PRODUCT_CODE = 'UP', // UPC
  HEALTH_RELATED_ITEM = 'NH', // HRI
  GOLD_STANDARD_MARKETED_PRODUCT_IDENTIFIER = 'GMP', // MPid
  GOLD_STANDARD_PRODUCT_IDENTIFIER = 'GPI', // ProdID
  GOLD_STANDARD_SPECIFIC_PRODUCT_IDENTIFIER = 'GSP', // SPID
}

export interface Attachment {
  AttachmentSource: string
  AttachmentData: string // all attached information is Base64 encoded
  MIMEType: MIMEType
}

export interface SurescriptsDirPhone {
  number: string
  ext: number
  sms: boolean
}
