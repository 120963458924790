import { isEqual, isPlainObject } from 'lodash'

const isObject = (obj: unknown): obj is Record<PropertyKey, unknown> => isPlainObject(obj)

export default function diffObjects<T extends object = object>(
  current: T,
  changes: T,
  keys?: string[],
  deep = false,
): T {
  function diff<K extends object>(changes: K, current: K) {
    const updateKeys = keys || Object.keys({ ...current, ...changes })
    return updateKeys.reduce((acc: K, key: string) => {
      const changedVal = changes[key as keyof K]
      const currentVal = current[key as keyof K]
      if (!isEqual(currentVal, changedVal)) {
        acc[key as keyof K] =
          deep && isObject(changedVal) && isObject(currentVal) ? diff(changedVal, currentVal) : changedVal
      }
      return acc
    }, {} as K)
  }

  return diff<T>(changes, current)
}
