export enum Species {
  Addax = 'Addax',
  AfricanGoshawk = 'African Goshawk',
  Alpaca = 'Alpaca',
  ArcticChar = 'Arctic Char',
  AtlanticSalmon = 'Atlantic Salmon',
  Badger = 'Badger',
  Bat = 'Bat',
  Bear = 'Bear',
  Beaver = 'Beaver',
  Bison = 'Bison',
  Bivalvia = 'Bivalvia',
  Bobcat = 'Bobcat',
  Brill = 'Brill',
  Budgerigar = 'Budgerigar',
  Buffalo = 'Buffalo',
  Camel = 'Camel',
  Carp = 'Carp',
  CatFeline = 'Cat/Feline',
  Catfish = 'Catfish',
  CattleBovine = 'Cattle/Bovine',
  Cephalopoda = 'Cephalopoda',
  Chicken = 'Chicken',
  Chinchilla = 'Chinchilla',
  Cockatiel = 'Cockatiel',
  Cockatoo = 'Cockatoo',
  Cod = 'Cod',
  CommonCanary = 'Common Canary',
  Cougar = 'Cougar',
  Crocodile = 'Crocodile',
  Crow = 'Crow',
  CrucianCarp = 'Crucian Carp',
  DogCanine = 'Dog/Canine',
  DonkeyAsinine = 'Donkey/Asinine',
  Dormouse = 'Dormouse',
  Duck = 'Duck',
  DwarfHamster = 'Dwarf Hamster',
  Eagle = 'Eagle',
  Elk = 'Elk',
  EuropeanEel = 'European Eel',
  EuropeanHamster = 'European Hamster',
  EuropeanLynx = 'European Lynx',
  EuropeanRabbit = 'European Rabbit',
  Fallow = 'Fallow',
  Ferret = 'Ferret',
  Fox = 'Fox',
  Frog = 'Frog',
  Gerbil = 'Gerbil',
  Gilthead = 'Gilthead',
  GoatCarpine = 'Goat/Carpine',
  GoldenHamster = 'Golden Hamster',
  Goldfish = 'Goldfish',
  Goose = 'Goose',
  Gudgeon = 'Gudgeon',
  GuineaFowl = 'Guinea Fowl',
  GuineaPig = 'Guinea Pig',
  Halibut = 'Halibut',
  Hare = 'Hare',
  Hedgehog = 'Hedgehog',
  HorseEquine = 'Horse/Equine',
  HouseMouse = 'House Mouse',
  Human = 'Human',
  Hybrid = 'Hybrid',
  Iguana = 'Iguana',
  Jackal = 'Jackal',
  Jaguar = 'Jaguar',
  Kestrel = 'Kestrel',
  Kite = 'Kite',
  LargemouthBass = 'Largemouth Bass',
  Leopard = 'Leopard',
  Lion = 'Lion',
  Lizard = 'Lizard',
  Llama = 'Llama',
  Macaw = 'Macaw',
  Mink = 'Mink',
  Monkey = 'Monkey',
  Moose = 'Moose',
  Mullet = 'Mullet',
  Ostrich = 'Ostrich',
  OtherArthopoda = 'Other Arthopoda',
  OtherAves = 'Other Aves',
  OtherBovinae = 'Other Bovinae',
  OtherCamelidae = 'Other Camelidae',
  OtherCaprinae = 'Other Caprinae',
  OtherCervidae = 'Other Cervidae',
  OtherEquidae = 'Other Equidae',
  OtherFelidae = 'Other Felidae',
  OtherLeporidae = 'Other Leporidae',
  OtherMammalia = 'Other Mammalia',
  OtherMollusca = 'Other Mollusca',
  OtherMustelidae = 'Other Mustelidae',
  OtherPisces = 'Other Pisces',
  OtherRodentia = 'Other Rodentia',
  OtherSuidae = 'Other Suidae',
  Owl = 'Owl',
  Parakeet = 'Parakeet',
  Parrot = 'Parrot',
  Partridge = 'Partridge',
  Perch = 'Perch',
  PeregrinFalcon = 'Peregrin Falcon',
  Pheasant = 'Pheasant',
  PigPorcine = 'Pig/Porcine',
  Pigeon = 'Pigeon',
  Pike = 'Pike',
  Puma = 'Puma',
  Quail = 'Quail',
  RaccoonDog = 'Raccoon Dog',
  Rat = 'Rat',
  RedDeer = 'Red Deer',
  Reindeer = 'Reindeer',
  Roach = 'Roach',
  RockfishWolffish = 'Rockfish/Wolffish',
  RoeDeer = 'Roe Deer',
  RussianHamster = 'Russian Hamster',
  SeaBream = 'Sea Bream',
  Seabass = 'Seabass',
  SharpsnoutSeabream = 'Sharpsnout Seabream',
  SheepOvine = 'Sheep/Ovine',
  Snake = 'Snake',
  Snipe = 'Snipe',
  Sole = 'Sole',
  Sparrow = 'Sparrow',
  Spider = 'Spider',
  Starling = 'Starling',
  Sturgeon = 'Sturgeon',
  Swan = 'Swan',
  SyrianHamster = 'Syrian Hamster',
  Tench = 'Tench',
  Tiger = 'Tiger',
  Tortoise = 'Tortoise',
  Trout = 'Trout',
  Turbot = 'Turbot',
  Turtle = 'Turtle',
  TurtleDove = 'Turtle Dove',
  Vole = 'Vole',
  WildBoar = 'Wild Boar',
  WildCat = 'Wild Cat',
  Wolf = 'Wolf',
  Zebra = 'Zebra',
  Animal = 'Animal',
}

export type SpeciesType = keyof typeof Species

export const DEFAULT_SPECIES = 'Human' as SpeciesType

export const SUPPORTED_SPECIES = Object.keys(Species) as SpeciesType[]

export enum SpeciesOptions {
  Human = 'Human',
  Pet = 'Pet',
  All = 'All',
}
