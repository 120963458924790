import type { ApolloError } from '@truepill/tpos-react-router'
import LoadingSpinner from 'components/Loading'
import styled from 'styled-components'
import type { TPOSDocument } from 'types'
import AttachmentPreview from './AttachmentPreview'

type AttachElementsProps = {
  data: TPOSDocument[]
  loading: boolean
  error?: ApolloError
  hideArchivedNotes: boolean
}

const AttachmentElements = ({ data, loading, error, hideArchivedNotes }: AttachElementsProps): JSX.Element => {
  const dataIsEmpty = data && data.length === 0

  if (loading) {
    return (
      <LoadingSpinnerContainer>
        <LoadingSpinner />
      </LoadingSpinnerContainer>
    )
  }

  if (dataIsEmpty) {
    return <EmptyAttachmentsList>No attachments yet</EmptyAttachmentsList>
  }

  if (error) {
    return <p>{error.message}</p>
  }

  const filteredAttachments = data.filter(document => !(hideArchivedNotes && document.isArchived))

  return (
    <AttachmentsContainer>
      {filteredAttachments.map((document, i) => (
        <AttachmentPreview document={document} key={i} />
      ))}
    </AttachmentsContainer>
  )
}

const EmptyAttachmentsList = styled.p`
  margin-top: 25px;
  text-align: center;
  width: 100%;
`

const LoadingSpinnerContainer = styled.div`
  display: flex;
  padding-top: 0.8rem;
  justify-content: center;
  svg {
    height: 60px;
  }
`

const AttachmentsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
`

export default AttachmentElements
