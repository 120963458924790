import { Button, Select } from '@truepill/react-capsule'
import CustomizedTextField from 'components/CustomizedTextField'
import { ButtonsContainer } from 'components/Modal'
import styled from 'styled-components'
import type { InsuranceOverrideCodes, OrderInsurance } from 'types'
import { getOptionsFromLabelMap, findFullOption } from '../helper'
import { overrideCodes } from '../ServiceCodes'

const priorAuthorizationOptions = getOptionsFromLabelMap(overrideCodes.priorAuthorization, true)
const submissionClarificationOptions = getOptionsFromLabelMap(overrideCodes.submissionClarification, true)
const productIdQualifierOptions = getOptionsFromLabelMap(overrideCodes.ProductIdQualifier, true)
const otherCoverageOptions = getOptionsFromLabelMap(overrideCodes.OtherCoverage, true)
const levelofServiceOptions = getOptionsFromLabelMap(overrideCodes.LevelofService, true)

type EditPayerOverrideSectionProps = {
  handleChange: (name: keyof InsuranceOverrideCodes, key: string, value?: any) => void
  payerInfo: OrderInsurance
}

const EditPayerOverrideSection = ({ handleChange, payerInfo }: EditPayerOverrideSectionProps): JSX.Element => {
  const overrideCodes: Partial<InsuranceOverrideCodes> = payerInfo?.overrideCodes || {}

  const applyToOptions = [
    { label: 'Fill', value: 'Fill' },
    { label: 'Prescription', value: 'Prescription' },
    { label: 'Patient', value: 'Patient' },
  ]

  const inputStyleSelect = {
    margin: '4px 0 0 0',
  }

  const inputStyle = {
    margin: '8px 0 0 0',
  }

  return (
    <ul>
      <PayerTitle>Override codes</PayerTitle>
      <PayerFormElement>
        <PayerFormElementLabel>Prior authorization</PayerFormElementLabel>
        <Payer3Columns>
          <Select
            options={priorAuthorizationOptions}
            label=""
            required
            selectedKey="label"
            placeholder=""
            onChange={option => {
              const value = option && option.value !== '0' ? parseInt(option.value) : undefined
              handleChange('priorAuthorizationTypeCode', 'value', value)
            }}
            value={findFullOption(overrideCodes.priorAuthorizationTypeCode?.value || 0, priorAuthorizationOptions)}
            css={inputStyleSelect}
            variant="small"
          />
          <CustomizedTextField
            placeholder="Code..."
            value={overrideCodes.priorAuthorizationNumberSubmitted}
            onChange={e => handleChange('priorAuthorizationNumberSubmitted', '', e.target.value)}
            style={inputStyle}
            size="sm"
          />
          <Select
            id={`#StyledSelect-d`}
            options={applyToOptions}
            label=""
            required
            selectedKey="label"
            placeholder=""
            onChange={option => {
              const value = option?.value
              handleChange('priorAuthorizationTypeCode', 'applyTo', value)
            }}
            value={applyToOptions.find(i => i.value === overrideCodes.priorAuthorizationTypeCode?.applyTo)}
            css={inputStyleSelect}
            variant="small"
          />
        </Payer3Columns>
      </PayerFormElement>

      <PayerFormElement>
        {!overrideCodes.submissionClarification?.value?.length && (
          <div>
            <PayerFormElementLabel>Submission clarification</PayerFormElementLabel>
          </div>
        )}
        {overrideCodes.submissionClarification?.value?.map((submission, index) => (
          <div key={`submission-clarification${index}`}>
            <PayerFormElementLabel>{index === 0 ? 'Submission clarification' : ''}</PayerFormElementLabel>
            <Payer2ColumnsFirstWider>
              <Select
                options={submissionClarificationOptions}
                label=""
                required
                selectedKey="label"
                placeholder=""
                onChange={option => {
                  const value = option && option.value !== '0' ? parseInt(option.value) : undefined

                  const submissionClarificationValue = overrideCodes.submissionClarification?.value
                    ? [...overrideCodes.submissionClarification?.value]
                    : []
                  submissionClarificationValue[index] = value || 0
                  handleChange('submissionClarification', 'value', submissionClarificationValue)
                }}
                value={findFullOption(submission || 0, submissionClarificationOptions)}
                css={{
                  ...inputStyleSelect,
                  ...(index === 0 ? { margin: '4px 0 0 0' } : { margin: '-12px 0 0 0' }),
                }}
                variant="small"
              />
              {index === 0 && (
                <Select
                  id={`#StyledSelect-d`}
                  options={applyToOptions}
                  label=""
                  required
                  selectedKey="label"
                  placeholder=""
                  onChange={option => {
                    const value = option?.value
                    handleChange('submissionClarification', 'applyTo', value)
                  }}
                  value={applyToOptions.find(i => i.value === overrideCodes.submissionClarification?.applyTo)}
                  css={inputStyleSelect}
                  variant="small"
                />
              )}
            </Payer2ColumnsFirstWider>
          </div>
        ))}
        <ButtonsContainerLeft>
          <Button
            variant="primary-outline"
            style={{ margin: '8px 0 0 0' }}
            size="sm"
            onClick={() => {
              const submissionClarificationValue = overrideCodes.submissionClarification?.value ?? []
              submissionClarificationValue.push(0)
              handleChange('submissionClarification', 'value', submissionClarificationValue)
            }}
          >
            Add another submission clarification
          </Button>
        </ButtonsContainerLeft>
      </PayerFormElement>

      <PayerFormElement>
        <PayerFormElementLabel>Product ID qualifier</PayerFormElementLabel>
        <Payer2ColumnsFirstWider>
          <Select
            options={productIdQualifierOptions}
            label=""
            required
            selectedKey="label"
            placeholder=""
            onChange={option => {
              const value = option && option.value !== 'OO' ? option.value : undefined
              handleChange('productIdQualifier', 'value', value)
            }}
            value={findFullOption(overrideCodes.productIdQualifier?.value || '00', productIdQualifierOptions)}
            css={inputStyleSelect}
            variant="small"
          />
          <Select
            id={`#StyledSelect-d`}
            options={applyToOptions}
            label=""
            required
            selectedKey="label"
            placeholder=""
            onChange={option => {
              const value = option?.value
              handleChange('productIdQualifier', 'applyTo', value)
            }}
            value={applyToOptions.find(i => i.value === overrideCodes.productIdQualifier?.applyTo)}
            css={inputStyleSelect}
            variant="small"
          />
        </Payer2ColumnsFirstWider>
      </PayerFormElement>

      <PayerFormElement>
        <PayerFormElementLabel>Other coverage</PayerFormElementLabel>
        <Payer2ColumnsFirstWider>
          <Select
            options={otherCoverageOptions}
            label=""
            required
            selectedKey="label"
            placeholder=""
            onChange={option => {
              const value = option ? parseInt(option.value) : undefined
              handleChange('otherCoverageCode', 'value', value)
            }}
            value={findFullOption(overrideCodes.otherCoverageCode?.value || 0, otherCoverageOptions)}
            css={inputStyleSelect}
            variant="small"
          />
          <Select
            id={`#StyledSelect-d`}
            options={applyToOptions}
            label=""
            required
            selectedKey="label"
            placeholder=""
            onChange={option => {
              const value = option?.value
              handleChange('otherCoverageCode', 'applyTo', value)
            }}
            value={applyToOptions.find(i => i.value === overrideCodes.otherCoverageCode?.applyTo)}
            css={inputStyleSelect}
            variant="small"
          />
        </Payer2ColumnsFirstWider>
      </PayerFormElement>

      <PayerFormElement>
        <PayerFormElementLabel>Level of service</PayerFormElementLabel>
        <Payer2ColumnsFirstWider>
          <Select
            options={levelofServiceOptions}
            label=""
            required
            selectedKey="label"
            placeholder=""
            onChange={option => {
              const value = option && option.value !== '0' ? parseInt(option.value) : undefined
              handleChange('levelOfService', 'value', value)
            }}
            value={findFullOption(overrideCodes.levelOfService?.value || 0, levelofServiceOptions)}
            css={inputStyleSelect}
            variant="small"
          />
          <Select
            id={`#StyledSelect-d`}
            options={applyToOptions}
            label=""
            required
            selectedKey="label"
            placeholder=""
            onChange={option => {
              const value = option?.value
              handleChange('levelOfService', 'applyTo', value)
            }}
            value={applyToOptions.find(i => i.value === overrideCodes.levelOfService?.applyTo)}
            css={inputStyleSelect}
            variant="small"
          />
        </Payer2ColumnsFirstWider>
      </PayerFormElement>
    </ul>
  )
}

export default EditPayerOverrideSection

const Payer3Columns = styled.div`
  width: 100%;
  display: grid;
  align-items: flex-start;
  grid-template-columns: [col1] 1fr [col2] 1fr [col3] 1fr;
  margin: 0 0 0 0;
  grid-gap: 0.25rem;
  vertical-align: baseline;
`

const Payer2ColumnsFirstWider = styled.div`
  width: 100%;
  display: grid;
  align-items: flex-start;
  grid-template-columns: [col1] 2fr [col2] 1fr;
  margin: 0 0 0 0;
  grid-gap: 0.25rem;
  vertical-align: baseline;
`

const PayerTitle = styled.h2`
  font-weight: 500;
  margin: 0 0 0 0;
`

const PayerFormElement = styled.div``

const PayerFormElementLabel = styled.div`
  font-weight: 500;
  font-size: 0.875rem;
  display: block;
  width: 100%;
  margin: 12px 0 0 0;
`
const ButtonsContainerLeft = styled(ButtonsContainer)`
  margin-top: 0px;
`
