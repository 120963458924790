import { useEffect, useState } from 'react'
import { ReactComponent as ExternalLinkIcon } from 'assets/icons/external-link.svg'
import AddressEntry from 'components/AddressEntry'
import AddressForm from 'components/AddressForm'
import DatePicker from 'components/DatePicker'
import HotKeyToolTip from 'components/HotKeyToolTip'
import IconWrapper from 'components/IconWrapper'
import RXScanImage from 'components/RXScanImage'
import {
  CompoundLabel,
  CompoundTextInput,
  CompoundValue,
  ImageFilledHeader,
  ListRowLabel,
  ListRowValue,
  RaisedAnchor,
  RightFilledHeader,
  RXCenterAndRightCell,
  RXCenterCell,
  RXCenterColumn,
  RXImageCell,
  RXListRow,
  RXRightCell,
  RXRow,
  RXTitleRow,
  StyledRXImageCell,
  StyledSelect,
} from 'components/RXTable'
import { Box } from 'grommet'
import { useFormData } from 'hooks/useFormData'
import useHotKey, { HotKeyLevel } from 'hooks/useHotKey'
import { usePopup } from 'hooks/usePopup'
import useRxImageIsPic from 'hooks/useRxImageIsPic'
import moment from 'moment'
import { useClient } from 'providers/LaunchDarklyProvider'
import { usePlusClient } from 'providers/VisionRouter'
import { goToViewRxImagePharmacyPrescription } from 'routes'
import styled from 'styled-components'
import { contrastColor } from 'styles/styleVariables'
import type { EscriptPatient, Patient, Weight, Prescription } from 'types'
import { gender, PrescriptionOrigin } from 'types'
import { capitalize, checkIfProvided, formatPhoneNumber, getRxImagePatient, convertWeight } from 'utils'
import type { PatientField } from 'utils/compare'
import { isHuman } from 'utils/Patient'
import NoEscriptInfo from './NoEscriptInfo'
import PopupIsOpenInfo from './PopupIsOpenInfo'

const ORIGIN_MAPPING = {
  [PrescriptionOrigin.ELECTRONIC]: 'eRx',
  [PrescriptionOrigin.PHARMACY]: 'Transfer in',
  [PrescriptionOrigin.FAX]: 'Manual intake',
  [PrescriptionOrigin.WRITTEN]: 'Manual intake',
  [PrescriptionOrigin.TELEPHONE]: 'Manual intake',
}

const ReviewPatientEdit = ({
  prescription,
  patient,
  rightHeader,
  rightContent,
  highlightedFields = new Map<PatientField, boolean>(),
}: {
  prescription: Prescription
  patient: Patient
  rightHeader?: JSX.Element
  rightContent?: JSX.Element
  highlightedFields?: Map<PatientField, boolean>
}): JSX.Element => {
  const [showRxImageAsPdf, setShowRxImageAsPdf] = useRxImageIsPic()
  const [noEscript, setNoEscript] = useState(false)
  const [isPopupOpenState, setIsPopupOpenState] = useState(false)
  const [showPopupIsOpenText, setShowPopupIsOpenText] = useState(false)
  const { openPopup, isPopupOpen } = usePopup()

  const {
    state: { formData },
    actions: { updateFormData },
  } = useFormData()
  const { routeToHash } = usePlusClient()
  const patientFormData = formData.patient as Patient

  const patientWeight = patient.weight?.value ? convertWeight(patient.weight.value as number) : ({} as Weight)

  const customer = prescription.customer
  // Set the customer id for upcoming feature-flag calls
  const { client: ldClient } = useClient()

  useEffect(() => {
    if (customer?.legacyId) {
      ldClient?.identify({ key: customer?.legacyId.toString() })
    }
  }, [ldClient, customer])

  useEffect(() => {
    if (!patientFormData?._id) {
      updateFormData({ patient: { $set: patient } })
    }
  }, [patient, patientFormData, updateFormData])

  // If the prescription's origin is “electronic”, then display the transcribed Rx image by default
  useEffect(() => {
    setShowRxImageAsPdf(prescription?.origin !== PrescriptionOrigin.ELECTRONIC)
    setShowPopupIsOpenText(prescription?.origin !== PrescriptionOrigin.ELECTRONIC)
  }, [prescription?.rxNumber, prescription?.origin, setShowRxImageAsPdf, setShowPopupIsOpenText])

  useEffect(() => {
    const isOpen = isPopupOpen()
    setIsPopupOpenState(isOpen)
    if (isOpen) {
      setShowRxImageAsPdf(false)
    }
  }, [isPopupOpen, setIsPopupOpenState, setShowRxImageAsPdf, setShowPopupIsOpenText, prescription])

  useHotKey('x', HotKeyLevel.normal, () => {
    if (!prescription?.escript && !prescription?.directTransfer) {
      setNoEscript(!noEscript)
    } else if (isPopupOpenState) {
      setShowPopupIsOpenText(!showPopupIsOpenText)
      setShowRxImageAsPdf(true)
    } else {
      setShowRxImageAsPdf(!showRxImageAsPdf)
    }
  })

  useHotKey('1', HotKeyLevel.normal, () => {
    routeToHash(`ReviewPatient`)
  })

  const escriptPatient = prescription?.escript?.patient as EscriptPatient
  const directTransferPatient = prescription?.directTransfer?.patient
  const rxImagePatient = getRxImagePatient(escriptPatient, directTransferPatient)

  if (!patient || !patientFormData?._id) {
    return <></>
  }

  const secondaryInfo = patient.secondaryInfo

  return (
    <Box id="ReviewPatientBox" direction="column" data-testid="ReviewPatient">
      <RXTitleRow data-testid="headers">
        <RaisedAnchor id="ReviewPatient" />
        <StyledRXImageCell noBorder>
          <ImageFilledHeader>
            Rx image {prescription.origin in ORIGIN_MAPPING ? `(${ORIGIN_MAPPING[prescription.origin]})` : ''}
            <PrescriptionImageButton
              onClick={() => {
                openPopup(goToViewRxImagePharmacyPrescription({ prescriptionId: prescription._id }))
                setIsPopupOpenState(true)
                setShowRxImageAsPdf(false)
                setShowPopupIsOpenText(prescription?.origin !== PrescriptionOrigin.ELECTRONIC)
                localStorage.setItem('isPopupOpen', 'true')
              }}
            >
              <IconWrapper>
                <ExternalLinkIcon />
              </IconWrapper>
            </PrescriptionImageButton>
          </ImageFilledHeader>
        </StyledRXImageCell>
        <RXCenterColumn>
          <RightFilledHeader>
            Patient
            <HotKeyToolTip label="1" position="right" offsetLeft={1} offsetTop={0} />
          </RightFilledHeader>
        </RXCenterColumn>
        {rightHeader && (
          <RXAnnotationsRightCell>
            <RightFilledHeader>{rightHeader}</RightFilledHeader>
          </RXAnnotationsRightCell>
        )}
      </RXTitleRow>

      <RXRow data-testid="image">
        {showRxImageAsPdf && !isPopupOpenState && (
          <ScanImageWrapper>
            <RXScanImage prescription={prescription} />
          </ScanImageWrapper>
        )}
        {!(showPopupIsOpenText && isPopupOpenState) && noEscript && (
          <NoEscriptInfo setNoEscript={() => setNoEscript(false)} />
        )}
        {showPopupIsOpenText && isPopupOpenState && <PopupIsOpenInfo />}

        <StyledRXImageCell closeOffTopBorder></StyledRXImageCell>
        <RXCenterAndRightCell></RXCenterAndRightCell>
      </RXRow>

      <Box direction="row">
        <Box basis="2/3" width={{ min: '53rem' }} data-testid="table-body">
          <RXListRowTwoThirds data-test-row="name">
            <RXImageCell>
              <ListRowLabel>Name:</ListRowLabel>
              <ListRowValue data-private>
                {checkIfProvided(rxImagePatient?.firstName)} {rxImagePatient?.lastName}
              </ListRowValue>
            </RXImageCell>
            <RXCenterCell
              showIcon={secondaryInfo?.names?.length > 0}
              className={highlightedFields.get('name') ? 'highlighted' : ''}
            >
              <ListRowLabel>
                <CompoundLabel>First Name:</CompoundLabel>
                <CompoundLabel>Last Name:</CompoundLabel>
              </ListRowLabel>
              <CompoundValue>
                <CompoundTextInput
                  data-private
                  data-testid="firstName"
                  value={patientFormData.firstName}
                  onChange={e => {
                    updateFormData({
                      patient: {
                        firstName: { $set: e.target.value },
                      },
                    })
                  }}
                />
                <CompoundTextInput
                  data-private
                  data-testid="lastName"
                  value={patientFormData.lastName}
                  onChange={e => {
                    updateFormData({
                      patient: {
                        lastName: { $set: e.target.value },
                      },
                    })
                  }}
                />
              </CompoundValue>
            </RXCenterCell>
          </RXListRowTwoThirds>
          {!isHuman(patient) && (
            <>
              <RXListRowTwoThirds data-test-row="species">
                <RXImageCell>
                  <ListRowLabel>Species:</ListRowLabel>
                  <ListRowValue>{patient?.species}</ListRowValue>
                </RXImageCell>
                <RXCenterCell>
                  <ListRowLabel>Species:</ListRowLabel>
                  <ListRowValue>{patient?.species}</ListRowValue>
                </RXCenterCell>
              </RXListRowTwoThirds>
              <RXListRowTwoThirds data-test-row="guardian">
                <RXImageCell>
                  <ListRowLabel>Guardian:</ListRowLabel>
                  <ListRowValue>{patient?.guardian}</ListRowValue>
                </RXImageCell>
                <RXCenterCell>
                  <ListRowLabel>Guardian:</ListRowLabel>
                  <ListRowValue>{patient?.guardian}</ListRowValue>
                </RXCenterCell>
              </RXListRowTwoThirds>
            </>
          )}
          <RXListRowTwoThirds data-test-row="dob">
            <RXImageCell>
              <ListRowLabel>DOB:</ListRowLabel>
              <ListRowValue data-private>
                {rxImagePatient?.dob ? moment(rxImagePatient.dob).format('MM/DD/YYYY') : 'None provided'}
              </ListRowValue>
            </RXImageCell>
            <RXCenterCell className={highlightedFields.get('dob') ? 'highlighted' : ''}>
              <ListRowLabel>DOB:</ListRowLabel>
              <ListRowValue>
                <DatePicker
                  data-testid="dob"
                  isPrivate={true}
                  date={moment(patientFormData.dob, 'YYYY-MM-DD').toDate()}
                  onChange={(date?: Date) => {
                    updateFormData({
                      patient: {
                        dob: { $set: moment(date).format('YYYY-MM-DD') },
                      },
                    })
                  }}
                />
              </ListRowValue>
            </RXCenterCell>
          </RXListRowTwoThirds>
          {patient.weight?.value && (
            <RXListRowTwoThirds data-test-row="weight">
              <RXImageCell>
                <ListRowLabel>Weight:</ListRowLabel>
                <ListRowValue data-private>{`${patientWeight.kg} kg / ${patientWeight.lb} lb`}</ListRowValue>
              </RXImageCell>
              <RXCenterCell>
                <ListRowLabel>Weight:</ListRowLabel>
                <ListRowValue data-private>{`${patientWeight.kg} kg / ${patientWeight.lb} lb`}</ListRowValue>
              </RXCenterCell>
            </RXListRowTwoThirds>
          )}
          <RXListRowTwoThirds data-test-row="gender">
            <RXImageCell>
              <ListRowLabel>Gender:</ListRowLabel>
              <ListRowValue data-private>
                {rxImagePatient?.gender ? capitalize(rxImagePatient?.gender) : 'None provided'}
              </ListRowValue>
            </RXImageCell>
            <RXCenterCell className={highlightedFields.get('gender') ? 'highlighted' : ''}>
              <ListRowLabel>Gender:</ListRowLabel>
              <ListRowValue>
                <GenderSelect
                  data-testid="gender"
                  value={patientFormData?.gender}
                  disableClear
                  options={gender}
                  onChange={([option]) => {
                    const value = option ? option.value : ''
                    updateFormData({
                      patient: {
                        gender: { $set: value as string },
                      },
                    })
                  }}
                />
              </ListRowValue>
            </RXCenterCell>
          </RXListRowTwoThirds>
          <RXListRowTwoThirds data-test-row="address">
            <RXImageCell>
              <ListRowLabel>Address:</ListRowLabel>
              <ListRowValue>
                <AddressEntry data-private address={rxImagePatient?.address} />
              </ListRowValue>
            </RXImageCell>
            <RXCenterCell
              showIcon={secondaryInfo?.addresses?.length > 0}
              className={highlightedFields.get('address') ? 'highlighted' : ''}
            >
              <ListRowLabel>Address:</ListRowLabel>
              <ListRowValue>
                <AddressForm
                  data-private
                  {...patientFormData.address?.home}
                  onChange={(key: string, value: string) =>
                    updateFormData({
                      patient: {
                        address: {
                          home: {
                            [key]: { $set: value },
                          },
                        },
                      },
                    })
                  }
                />
              </ListRowValue>
            </RXCenterCell>
          </RXListRowTwoThirds>
          <RXListRowTwoThirds data-test-row="phone">
            <RXImageCell>
              <ListRowLabel>Phone:</ListRowLabel>
              <ListRowValue data-private>
                {checkIfProvided(formatPhoneNumber(rxImagePatient?.communicationNumbers?.phone?.number))}
              </ListRowValue>
            </RXImageCell>
            <RXCenterCell
              showIcon={secondaryInfo?.phoneNumbers?.length > 0}
              className={highlightedFields.get('phone') ? 'highlighted' : ''}
            >
              <ListRowLabel>Phone:</ListRowLabel>
              <CompoundValue>
                <CompoundTextInput
                  data-private
                  data-testid="phone"
                  value={patientFormData?.contacts?.phone}
                  onChange={e => {
                    updateFormData({
                      patient: {
                        contacts: {
                          phone: { $set: e.target.value },
                        },
                      },
                    })
                  }}
                />
              </CompoundValue>
            </RXCenterCell>
          </RXListRowTwoThirds>
        </Box>

        {rightContent && (
          <Box basis="1/3" overflow="auto" data-testid="fillrequest-container">
            {rightContent}
          </Box>
        )}
      </Box>
    </Box>
  )
}

const GenderSelect = styled(StyledSelect)`
  width: 7rem;
`

const RXListRowTwoThirds = styled(RXListRow)`
  grid-template-columns: [image] minmax(26.5rem, 1fr) [center] minmax(26.5rem, 1fr);
`

const RXAnnotationsRightCell = styled(RXRightCell)`
  flex-wrap: wrap;
  overflow-y: visible;
  position: relative;
  height: 0;
  padding-left: 0;
  background-color: white;
`

const ScanImageWrapper = styled.div`
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  grid-row: 1 / span 1;
  grid-column: image;
  width: 100%;
  display: grid;
  justify-content: center;
`

const PrescriptionImageButton = styled.button`
  background: ${contrastColor};
  border: 0;
  box-shadow: none;
  border-radius: 0px;
`
export default ReviewPatientEdit
