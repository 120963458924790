import ActionButton from 'components/ActionButton'
import { ActionBar } from 'components/PageStructure'
import SearchInput from 'components/SearchInput'
import styled from 'styled-components'
import { borderColor, primaryBackgroundColor, primaryColor } from 'styles/styleVariables'

const TitleSection = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 1.25rem 1.875rem;
  align-items: center;
  h1 {
    width: min-content;
    white-space: nowrap;
  }
  p {
    font-size: 1rem;
    font-weight: 500;
  }
`

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-bottom: 3rem;
  align-items: flex-start;
`

const ToolBarContainer = styled.div`
  display: flex;
  margin-left: 1.875rem;
`

const SearchLabel = styled.label`
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 0.625rem;
`

const SearchControls = styled.div`
  grid-column: meta;
  grid-row: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

const NewTeamMemberControls = styled.div`
  grid-column: actions;
  grid-row: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`

const StyledActionButton = styled(ActionButton)`
  border-color: ${borderColor};
  background-color: ${primaryBackgroundColor};
  :hover {
    border: 2px solid ${primaryColor};
    outline-color: ${primaryColor};
  }
`

const StyledSearchInput = styled(SearchInput)`
  flex: 1;
  max-width: 14rem;
`

const SearchTables = styled.div`
  padding-top: 1.5rem;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`

const StyledActionBar = styled(ActionBar)`
  align-items: flex-end;
  grid-template-columns: [meta] 0.68fr [actions] 1.5fr;
  padding-left: 0;
  width: 100%;
  padding-right: 0;
`

const Title = styled.h1`
  font-size: 24px;
`

const LoadingSpinnerContainer = styled.div`
  display: flex;
  padding-top: 12rem;
  justify-content: center;
  svg {
    height: 126px;
  }
`

export {
  TitleSection,
  Content,
  ToolBarContainer,
  SearchLabel,
  SearchControls,
  NewTeamMemberControls,
  StyledActionButton,
  StyledSearchInput,
  SearchTables,
  StyledActionBar,
  Title,
  LoadingSpinnerContainer,
}
