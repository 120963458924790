import { useMemo } from 'react'
import { RXCell, ListRowLabel, ListRowValue } from 'components/RXTable'
import type { Order, PaymentType } from 'types'
import { capitalize, getTotalCost, getPaymentMethod, usdFormatter } from 'utils'

type OrderTotalCostProps = {
  bins: string[]
  paymentType: PaymentType
  pricing: Order['pricing']
  rxFillRequests: Order['rxFillRequests']
}

const OrderTotalCost = ({ paymentType, pricing, rxFillRequests, bins }: OrderTotalCostProps) => {
  const paymentMethod = getPaymentMethod(paymentType)

  const totalCost = useMemo(() => {
    return usdFormatter.format(getTotalCost({ paymentType, pricing, rxFillRequests, bins }))
  }, [bins, paymentType, pricing, rxFillRequests])

  return (
    <RXCell data-test-row={paymentMethod}>
      <ListRowLabel>{capitalize(paymentMethod)}:</ListRowLabel>
      <ListRowValue>{totalCost}</ListRowValue>
    </RXCell>
  )
}

export default OrderTotalCost
