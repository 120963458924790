import { useState } from 'react'
import { Select } from '@truepill/react-capsule'
import type { SpeciesType } from '@truepill/tpos-types'
import { Species, SUPPORTED_SPECIES, UserRoles } from '@truepill/tpos-types'
import AddressForm from 'components/AddressForm'
import { FormItem, FormItems, CompoundFormLine } from 'components/ColumnForm'
import CustomizedTextField from 'components/CustomizedTextField'
import DropDownOption from 'components/DropDownOption'
import SearchAutoComplete from 'components/SearchAutoComplete'
import { useFormData } from 'hooks/useFormData'
import usePatientFormValidation from 'hooks/usePatientFormValidation'
import moment from 'moment'
import styled from 'styled-components'
import { borderColor } from 'styles/styleVariables'
import { formatPhoneInput } from 'utils'
import AuthLimited from '.././AuthLimited'
import SpeciesCard from '.././SpeciesCard'

interface SpeciesOption {
  key: SpeciesType
  value: Species
}

interface SpeciesSuggestion extends SpeciesOption {
  highlighted?: boolean
  selected?: boolean
}

interface Error {
  error: string
}

interface Loading {
  loading: boolean
}

type SpeciesSuggestionItem = SpeciesSuggestion | Error | Loading

const supportedSpecies =
  (SUPPORTED_SPECIES.map(species => ({
    key: species,
    value: Species[species],
  })) as SpeciesOption[]) ?? []

const PatientForm = (): JSX.Element => {
  const {
    state: { formData },
    actions: { updateFormData },
  } = useFormData()

  const [searchTerm, setSearchTerm] = useState('')
  const patientFormData = formData.patient
  const homeAddress = patientFormData.address?.home
  const patientContacts = patientFormData.contacts

  const { errors } = usePatientFormValidation(patientFormData)

  const speciesValue = patientFormData?.species
  const speciesOption = supportedSpecies.find(({ value }) => value === speciesValue)

  return (
    <FormItems data-testid="PatientForm">
      <FormItem data-test-row="firstname">
        <CustomizedTextField
          required
          size="sm"
          label="First name"
          value={formData.patient.firstName || ''}
          state={errors.firstName ? 'error' : 'default'}
          helperText={errors.firstName}
          onChange={e =>
            updateFormData({
              patient: {
                firstName: { $set: e.target.value },
              },
            })
          }
        />
      </FormItem>
      <FormItem data-test-row="lastname">
        <CustomizedTextField
          required
          size="sm"
          label="Last name"
          value={formData.patient.lastName}
          state={errors.lastName ? 'error' : 'default'}
          helperText={errors.lastName}
          onChange={e =>
            updateFormData({
              patient: {
                lastName: { $set: e.target.value },
              },
            })
          }
        />
      </FormItem>
      <FormItem data-test-row="species">
        <AuthLimited
          roles={[UserRoles.Admin, UserRoles.Pharmacist, UserRoles.LeadPharmacist, UserRoles.LeadCustomerSupport]}
          fallback={<>Species: {patientFormData.species}</>}
        >
          {speciesOption ? (
            <>
              <SpeciesCardTitle>Species</SpeciesCardTitle>
              <SpeciesCard
                species={speciesOption.value}
                closeCallback={() => {
                  updateFormData({ patient: { species: { $set: undefined } } })
                }}
              />
            </>
          ) : (
            <SearchAutoComplete
              withCapsule
              label={'Species'}
              data-testid="species"
              isModal={false}
              iconColor={borderColor}
              placeholder={'Search species'}
              autoCompleteSuggestions={supportedSpecies.filter(c =>
                c.key.toLowerCase().startsWith(searchTerm.toLowerCase()),
              )}
              value={searchTerm}
              onSelect={(species: any) => {
                setSearchTerm('')
                updateFormData({ patient: { species: { $set: species.value } } })
                if (species.value === 'Human') {
                  updateFormData({ patient: { guardian: { $set: '' } } })
                }
              }}
              suggestionComponent={optionProps => <AutoCompleteOption {...optionProps} />}
              onChange={setSearchTerm}
              state={'default'}
            />
          )}
        </AuthLimited>
      </FormItem>
      <FormItem data-test-row="species"></FormItem>
      {speciesOption?.key !== 'Human' && (
        <FormItem data-test-row="guardian">
          <AuthLimited
            roles={[UserRoles.Admin, UserRoles.Pharmacist, UserRoles.LeadPharmacist, UserRoles.LeadCustomerSupport]}
            fallback={<>Guardian: {patientFormData.guardian}</>}
          >
            <CustomizedTextField
              required
              size="sm"
              label="Guardian"
              value={formData.patient.guardian}
              state={errors.lastName ? 'error' : 'default'}
              helperText={errors.guardian}
              onChange={e =>
                updateFormData({
                  patient: {
                    guardian: { $set: e.target.value },
                  },
                })
              }
            />
          </AuthLimited>
        </FormItem>
      )}

      <FormItem>
        <CompoundFormLine>
          <DOBFormItem data-test-row="birthdate">
            <CustomizedTextField
              required
              size="sm"
              label="Date of Birth"
              type="date"
              value={formData.patient.dob ? moment(formData.patient.dob, 'YYYY-MM-DD').format('YYYY-MM-DD') : ''}
              state={errors.dob ? 'error' : 'default'}
              helperText={errors.dob}
              onChange={e => {
                updateFormData({
                  patient: {
                    dob: { $set: moment(e.target.value).format('YYYY-MM-DD') },
                  },
                })
              }}
            />
          </DOBFormItem>
          <GenderFormItem data-test-row="gender">
            <SelectWrapper>
              <Select
                required
                data-testid="gender"
                label="Gender"
                variant="small"
                value={formData.patient.gender}
                state={errors.gender ? 'error' : 'default'}
                helperText={errors.gender}
                placeholder={'Select gender...'}
                options={['male', 'female']}
                onChange={value => {
                  updateFormData({
                    patient: {
                      gender: { $set: (value || '') as string },
                    },
                  })
                }}
              />
            </SelectWrapper>
          </GenderFormItem>
        </CompoundFormLine>
      </FormItem>
      <AddressForm
        {...homeAddress}
        withCapsule
        onChange={(key: string, value: string) => {
          updateFormData({
            patient: {
              address: {
                home: { [key]: { $set: value } },
              },
            },
          })
        }}
      />
      <FormItem>
        <CompoundFormLine>
          <PhoneFormItem data-test-row="phone">
            <CustomizedTextField
              required
              data-testid="phone"
              label="Phone"
              size="sm"
              type="tel"
              value={patientContacts?.phone}
              state={errors.phone ? 'error' : 'default'}
              helperText={errors.phone}
              placeholder={'(xxx) xxx - xxxx'}
              onChange={e => {
                const formattedPhone = formatPhoneInput(e.target.value)
                updateFormData({
                  patient: {
                    contacts: {
                      phone: { $set: formattedPhone },
                    },
                  },
                })
              }}
            />
          </PhoneFormItem>
          <EmailFormItem data-test-row="email">
            <CustomizedTextField
              data-testid="email"
              label="Email (optional)"
              size="sm"
              value={patientContacts?.email}
              placeholder={'example@email.com'}
              onChange={e =>
                updateFormData({
                  patient: {
                    contacts: {
                      email: { $set: e.target.value },
                    },
                  },
                })
              }
            />
          </EmailFormItem>
        </CompoundFormLine>
      </FormItem>
    </FormItems>
  )
}

const AutoCompleteOption = (props: SpeciesSuggestionItem): JSX.Element => {
  if ((props as Error).error) {
    return (
      <DropDownOption {...props}>
        <p>{(props as Error).error}</p>
      </DropDownOption>
    )
  }

  if ((props as Loading).loading) {
    return (
      <DropDownOption {...props}>
        <p>Loading...</p>
      </DropDownOption>
    )
  }

  const species = props as SpeciesSuggestion

  return (
    <DropDownOption {...species}>
      <p>{species.value}</p>
    </DropDownOption>
  )
}

const SpeciesCardTitle = styled.div`
  font-weight: 700;
  font-family: Lato;
`

const DOBFormItem = styled(FormItem)`
  width: 50% !important;
`

const GenderFormItem = styled(FormItem)`
  width: 50% !important;
`

const PhoneFormItem = styled(FormItem)`
  width: 10rem !important;
`

const EmailFormItem = styled(FormItem)`
  width: calc(100% - 10rem) !important;
`
const SelectWrapper = styled.div`
  button {
    height: 48px;
  }
  .capsule.spacer {
    height: 4px;
    min-height: 4px;
  }
`

export default PatientForm
