import { ReactComponent as ReverseIcon } from 'assets/icons/reverse.svg'
import ActionButton from 'components/ActionButton'
import IconWrapper from 'components/IconWrapper'
import useProcessOrderReplacement from 'hooks/useProcessOrderReplacement'
import { bodyPrimaryColor } from 'styles/styleVariables'

type IssueButtonProps = { orderId: string; disabled?: boolean }

const IssueButton = ({ orderId, disabled }: IssueButtonProps): JSX.Element => {
  const processOrderReplacement = useProcessOrderReplacement(orderId)

  return (
    <ActionButton
      icon={
        <IconWrapper>
          <ReverseIcon fill={bodyPrimaryColor} />
        </IconWrapper>
      }
      onClick={processOrderReplacement}
      label="Replacement"
      disabled={disabled}
    />
  )
}
export default IssueButton
