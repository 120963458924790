import { createContext, useContext, useState } from 'react'
import type { ChildProps } from 'types'

type ModalRenderFun = () => JSX.Element

interface ModalProviderContextProps {
  modals: ModalRenderFun[]
  showModal: (renderFun: ModalRenderFun) => void
  dismissModal: () => void
}

const ModalContext = createContext<ModalProviderContextProps>({
  modals: [],
  showModal: () => {
    return
  },
  dismissModal: () => {
    return
  },
})

const ModalProvider = ({ children }: ChildProps): JSX.Element => {
  const [modals, setModals] = useState<ModalRenderFun[]>([])

  const value = {
    showModal: (newModal: ModalRenderFun) => {
      setModals([newModal, ...modals])
    },
    dismissModal: () => {
      setModals(modals.slice(1, modals.length - 1))
    },
    modals,
  }

  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
}

export const useModalContext = (): ModalProviderContextProps => {
  const modalContext = useContext(ModalContext)

  if (modalContext === undefined) {
    throw new Error('Attempting to read ModalContext outside a Provider hierarchy')
  }

  return modalContext
}

export default ModalProvider
