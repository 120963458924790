import { Link, useLocation } from '@truepill/tpos-react-router'
import LoadingSpinner from 'components/Loading'
import { FilledHeadingStyle, NoResultsEntry } from 'components/PageStructure'
import Lozenge, { getStatusColor } from 'components/Tiles/Lozenge'
import useFulfillmentOrders from 'hooks/navigation/useFulfillmentOrders'
import useFulfillmentQueue from 'hooks/useFulfillmentQueue'
import { useTPCacheContext } from 'providers/TPCacheProvider'
import { goToFulfillmentOrder } from 'routes'
import styled, { css } from 'styled-components'
import EllipsisTruncate from 'styles/EllipsisTruncate'
import {
  contrastColor,
  contrastBackgroundColor,
  primaryColorLight,
  alertRed,
  primaryBackgroundColor,
} from 'styles/styleVariables'
import type { Patient, Order } from 'types'
import { checkIfProvided, snakeCaseToHumanReadable, usdFormatter, formatCreatedDate, getTotalCost } from 'utils'

type OrderHistoryProps = {
  patientIds: Patient['_id'][]
  searchTerm?: string
}

const OrderHistory = (props: OrderHistoryProps): JSX.Element => {
  const { patientIds, searchTerm = '' } = props

  const { data, loading, error } = useFulfillmentOrders({ patientIds, includeTriage: true })

  if (loading) {
    return (
      <MainLoadingContainer>
        <TitleRow />
        <LoadingSpinnerContainer>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      </MainLoadingContainer>
    )
  }

  if (error) {
    return <p>Failed to load Order History {JSON.stringify(error)} </p>
  }

  const orders: Order[] = data?.getFulfillmentOrders.orders || []
  const filteredOrders = orders.filter(order => {
    const coreOrderTokenMatch = order.coreOrderToken.includes(searchTerm.toLowerCase())
    const rxNumbers: string[] = order.rxFillRequests.map(request => request.prescription.rxNumber.toString())
    const rxNumberMatch = rxNumbers.toString().includes(searchTerm)
    const customerNameMatch = order.customer?.name.toLowerCase().includes(searchTerm.toLowerCase())
    return rxNumberMatch || customerNameMatch || coreOrderTokenMatch
  })

  return (
    <OrderHistoryContainer>
      <TitleRow />
      {filteredOrders.map(order => (
        <OrderHistoryEntry key={order._id} order={order} />
      ))}
      {orders.length === 0 && <NoResultsEntry> No results </NoResultsEntry>}
    </OrderHistoryContainer>
  )
}

const TitleRow = (): JSX.Element => {
  return (
    <TitleOrderHistoryRow>
      <OrderToken>Order Token</OrderToken>
      <RefNumber>Customer Ref</RefNumber>
      <Customer>Customer</Customer>
      <CreatedAt>Created At</CreatedAt>
      <RXNumbers>Rx #</RXNumbers>
      <TotalCost>Total cost</TotalCost>
      <PaymentType>Payment Type</PaymentType>
      <Shipping>Shipping</Shipping>
      <ShipDate>Ship date</ShipDate>
      <TrackingNumber>Tracking #</TrackingNumber>
      <Status>Status</Status>
    </TitleOrderHistoryRow>
  )
}

type OrderHistoryEntryProps = { order: Order }

const OrderHistoryEntry = ({ order }: OrderHistoryEntryProps): JSX.Element => {
  const { search } = useLocation()
  const { getCustomerNameById } = useTPCacheContext()
  const fulfillmentQueue = useFulfillmentQueue()
  const NoneProvidedIcon = <>&mdash;</>
  const { date: createdAtDate } = formatCreatedDate(order.createdAt)
  const rxNumbers: string[] = order.rxFillRequests.map(request => request.prescription.rxNumber.toString())
  const shippingMethod = order.shippingMethod ? snakeCaseToHumanReadable(order.shippingMethod) : NoneProvidedIcon
  const trackingNumber =
    order.otcProducts && order.otcProducts.length ? order.otcProducts[0].trackingNumber : NoneProvidedIcon
  const copayOrCashTotalCost = getTotalCost({
    paymentType: order.paymentType,
    pricing: order.pricing,
    rxFillRequests: order.rxFillRequests,
    bins: order.insurances?.map(ins => ins.bin ?? '')?.filter(ins => !!ins) ?? [],
  })

  return (
    <StyledLink
      to={goToFulfillmentOrder({
        fulfillmentQueueName: fulfillmentQueue?.name,
        orderId: order._id,
        search,
      })}
    >
      <OrderHistoryRow>
        <OrderToken>{order.coreOrderToken}</OrderToken>
        <RefNumber>{order.customerRef?.match(/^\w{2,12}$/) ? order.customerRef : 'None provided'}</RefNumber>
        <Customer>{checkIfProvided(getCustomerNameById(order.customerId))}</Customer>
        <CreatedAt>{createdAtDate}</CreatedAt>
        <RXNumbers>{rxNumbers.length ? rxNumbers.join(', ') : 'N/A'}</RXNumbers>
        <TotalCost>{usdFormatter.format(copayOrCashTotalCost)}</TotalCost>
        <PaymentType>{order.paymentType ?? NoneProvidedIcon}</PaymentType>
        <Shipping>{shippingMethod}</Shipping>
        <ShipDate>{NoneProvidedIcon}</ShipDate>
        <TrackingNumber>{trackingNumber}</TrackingNumber>
        <Status>
          <Lozenge backgroundColor={getStatusColor(order.status)}>{order.status}</Lozenge>
          {order.inTriage && (
            <Lozenge color={primaryBackgroundColor} backgroundColor={alertRed}>
              Triage
            </Lozenge>
          )}
        </Status>
      </OrderHistoryRow>
    </StyledLink>
  )
}

const OrderHistoryContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: stretch;
  :last-child {
    border-bottom: 0.25rem solid ${contrastColor};
    border-radius: 0.25rem;
  }
`

const StyledLink = styled(Link)`
  :hover {
    background-color: ${primaryColorLight};
    :nth-of-type(2n + 1) {
      background-color: ${primaryColorLight};
    }
  }
  padding: 0.1rem 0.625rem;
  :nth-of-type(1n) {
    padding-bottom: 0.625rem;
  }
  :nth-of-type(1n - 1) {
    padding-top: 0.625rem;
  }
  :nth-of-type(2n + 1) {
    background-color: ${contrastBackgroundColor};
  }
`

const OrderHistoryRow = styled.ul`
  display: grid;
  grid-template-rows: [content] auto [fillHistory] auto;
  grid-template-columns:
    [orderToken] minmax(6rem, 9rem)
    [refNumber] minmax(6rem, 10rem)
    [customer] minmax(5rem, 8rem)
    [createdAt] minmax(11rem, 12rem)
    [rxNumbers] minmax(4rem, 1fr)
    [totalCost] minmax(4rem, 8rem)
    [paymentType] minmax(6rem, 10rem)
    [shipping] minmax(4rem, 10rem)
    [shipDate] minmax(4rem, 8rem)
    [trackingNumber] minmax(6rem, 3fr)
    [status] minmax(7rem, 10rem);

  // Capitalize all cells except the first one
  li:not(:nth-child(1)) {
    text-transform: capitalize;
  }
`

const TitleOrderHistoryRow = styled(OrderHistoryRow)`
  ${FilledHeadingStyle}
  border-radius: 0.25rem 0.25rem 0rem 0rem;
`

const HistoryCell = styled.li`
  grid-row: 1;
  ${EllipsisTruncate}
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 0.3125rem;
  margin-right: 0.3125rem;
`

const TrucateTextPosition = css`
  display: block;
`

const OrderToken = styled(HistoryCell)`
  grid-column: orderToken;
`
const RefNumber = styled(HistoryCell)`
  grid-column: refNumber;
`
const Customer = styled(HistoryCell)`
  grid-column: customer;
`
const CreatedAt = styled(HistoryCell)`
  grid-column: createdAt;
`
const RXNumbers = styled(HistoryCell)`
  grid-column: rxNumbers;
`
const TotalCost = styled(HistoryCell)`
  grid-column: totalCost;
`
const PaymentType = styled(HistoryCell)`
  grid-column: paymentType;
`
const ShipDate = styled(HistoryCell)`
  grid-column: shipDate;
`
const Shipping = styled(HistoryCell)`
  grid-column: shipping;
  ${TrucateTextPosition};
`
const TrackingNumber = styled(HistoryCell)`
  grid-column: trackingNumber;
`
const Status = styled(HistoryCell)`
  grid-column: status;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  ${Lozenge}:first-child {
    margin-left: 0;
    margin-right: 0.5rem;
  }
  ${Lozenge}:last-child {
    margin-left: 0;
    margin-right: 0;
  }
  @media screen and (min-width: 1640px) {
    flex-wrap: nowrap;
  }
`
const LoadingSpinnerContainer = styled.div`
  display: flex;
  width: 100%;
  padding-top: 0.8rem;
  justify-content: center;
  svg {
    height: 50px;
  }
`
const MainLoadingContainer = styled.div`
  width: 100%;
`

export default OrderHistory
