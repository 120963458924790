import { useEffect } from 'react'
import { useLocation } from '@truepill/tpos-react-router'
import { usePrevious } from 'react-use'

/**
 * This hook will call the callback given to it once each time that the user
 * navigates to a different path.
 */
const useNavigation = (callback: () => void): void => {
  const location = useLocation()
  const lastPathName = usePrevious(location.pathname)

  useEffect(() => {
    if (lastPathName && location.pathname !== lastPathName) {
      callback()
    }
  }, [callback, lastPathName, location.pathname])
}

export default useNavigation
