import { OutboundTransferStatus, UserRoles } from '@truepill/tpos-types'
import AuthLimited from 'components/AuthLimited'
import BreadCrumb from 'components/BreadCrumb'
import OutboundTransferDetails from 'components/OutboundTransferDetails'
import { PageContent, PageHeading, StickyPageHeadingContainer } from 'components/PageStructure'
import ReviewPatient from 'components/ReviewPatient'
import ReviewPrescription from 'components/ReviewPrescription'
import { OutboundTransferHeading } from 'components/RxHeading'
import RxReviewPrescriber from 'components/RxReviewPrescriber'
import ToolBar, { NotesButton, LogButton, EditOutboundTransferButton } from 'components/ToolBar'
import styled from 'styled-components'
import type { OutboundTransfer, Patient, Prescription, Log } from 'types'
import ActionButtons from './ActionButtons'
import TopBanner from './TopBanner'
import TriageIssueTable from './TriageIssuesTable'

type OutboundTransferViewProps = {
  outboundTransfer: OutboundTransfer
  prescription: Prescription
  patient: Patient
  logs: Log[]
  loading: boolean
}

const EDIT_BUTTON_ROLES_BY_STATUS = {
  [OutboundTransferStatus.PV]: [UserRoles.Pharmacist, UserRoles.LeadPharmacist, UserRoles.Admin],
  [OutboundTransferStatus.Triage]: Object.values(UserRoles),
  [OutboundTransferStatus.Pending]: Object.values(UserRoles),
  [OutboundTransferStatus.Complete]: [],
  [OutboundTransferStatus.Rejected]: [],
}

const OutboundTransferView = ({
  outboundTransfer,
  prescription,
  patient,
  logs,
  loading,
}: OutboundTransferViewProps) => {
  if (loading || !outboundTransfer) {
    return <></>
  }

  const hideActionButtons =
    outboundTransfer.status === OutboundTransferStatus.Complete ||
    outboundTransfer.status === OutboundTransferStatus.Rejected

  return (
    <>
      <StyledStickyPageHeadingContainer>
        <TopBanner transfer={outboundTransfer} />
        <BreadCrumb />
        <PageHeading>
          <OutboundTransferHeading prescription={prescription} patient={patient} outboundTransfer={outboundTransfer} />
          {!hideActionButtons && <ActionButtons outboundTransfer={outboundTransfer} />}
        </PageHeading>
      </StyledStickyPageHeadingContainer>
      <PageContent>
        <OutboundTransferViewContainer>
          {outboundTransfer.triages?.length && (
            <TriageIssueTable outboundTransferId={outboundTransfer._id} triages={outboundTransfer.triages} />
          )}
          <ReviewPatient
            patient={patient}
            prescription={prescription}
            rightContent={<OutboundTransferDetails outboundTransfer={outboundTransfer} />}
            rightHeader={<>Transfer Request</>}
          />
          <RxReviewPrescriber prescription={prescription} />
          <ReviewPrescription prescription={prescription} />
        </OutboundTransferViewContainer>
        <ToolBar>
          <AuthLimited roles={EDIT_BUTTON_ROLES_BY_STATUS[outboundTransfer.status] ?? []}>
            <EditOutboundTransferButton outboundTransferId={outboundTransfer._id} />
          </AuthLimited>
          <LogButton logs={[]} fillId={undefined} copayRequestId={undefined} isCopay={false} />
          <NotesButton notesCount={0} showBadge={false} />
        </ToolBar>
      </PageContent>
    </>
  )
}

export default OutboundTransferView

const StyledStickyPageHeadingContainer = styled(StickyPageHeadingContainer)`
  z-index: 2;
`
StyledStickyPageHeadingContainer.displayName = 'StyledStickyPageHeadingContainer '

const OutboundTransferViewContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-top: 1.25rem;
  padding-left: 1.875rem;
`
OutboundTransferViewContainer.displayName = 'OutboundTransferViewContainer'
