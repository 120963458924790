import { useReducer, useMemo, useCallback } from 'react'
import { KeyWords } from 'utils'

interface TypeBufferState {
  latency: number
  buffer: string
  timestamp: number
}

interface TypeBufferAction {
  type: 'addKeyStroke'
  payload: {
    event: KeyboardEvent | Record<string, unknown>
    key: string
    timestamp: number
    override?: boolean
  }
}

const TypeBufferReducer = (state: TypeBufferState, action: TypeBufferAction) => {
  const { payload } = action
  const { event, key, timestamp: newTimestamp, override } = payload
  const { timestamp, buffer, latency } = state

  if (override || newTimestamp > timestamp + latency) {
    return { buffer: key, timestamp: newTimestamp, latency, event }
  } else {
    return { buffer: buffer + key, timestamp: newTimestamp, latency, event }
  }
}

const useTypeBuffer = (
  latency = 300,
): [string, (event: KeyboardEvent) => void, string | undefined, KeyboardEvent | Record<string, unknown>] => {
  const [{ buffer: typingBuffer, timestamp, event }, typeBufferDispatch] = useReducer(TypeBufferReducer, {
    buffer: '',
    timestamp: 0,
    latency,
    event: {},
  })

  const addKeyStroke = useCallback(
    (event: KeyboardEvent) => {
      typeBufferDispatch({
        type: 'addKeyStroke',
        payload: {
          event,
          key: event.key,
          timestamp: new Date().getTime(),
          override: KeyWords[event.key],
        },
      })
    },
    [typeBufferDispatch],
  )

  // Sometimes the same value is stored in the typing buffer but it was
  // obtained at a different time. components using this hook might want to
  // know that this has changed even if the typingBuffer contents haven't
  const typingBufferHash = useMemo(() => {
    if (typingBuffer && typingBuffer.length) {
      return typingBuffer + timestamp
    }
  }, [typingBuffer, timestamp])

  return [typingBuffer, addKeyStroke, typingBufferHash, event]
}

export default useTypeBuffer
