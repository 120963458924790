import { PriorAuthorizationStatus } from '@truepill/tpos-types'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import ActionButton from 'components/ActionButton'
import IconWrapper from 'components/IconWrapper'
import { bodyPrimaryColor } from 'styles/styleVariables'
import type { PriorAuthorization } from 'types'

type CancelPriorAuthorizationButtonProps = {
  priorAuthorization?: PriorAuthorization
  isPriorAuthorization?: boolean
  disabled?: boolean
  clickCallback?: (priorAuthorizationId: string) => void
}

export const CancelPriorAuthorizationButton = ({
  priorAuthorization,
  isPriorAuthorization = false,
  disabled = false,
  clickCallback,
}: CancelPriorAuthorizationButtonProps): JSX.Element => {
  if (!isPriorAuthorization || !priorAuthorization) {
    return <></>
  }

  const priorAuthStatusPending = priorAuthorization?.status === PriorAuthorizationStatus.Pending
  const priorAuthCMMStatusActionRequired = priorAuthorization?.cmmStatus === 'ActionRequired'
  const priorAuthCancelButton = priorAuthorization?.actions.find(action => action.buttonLabel === 'Cancel')
  if ((!priorAuthStatusPending && !priorAuthCMMStatusActionRequired) || !priorAuthCancelButton) {
    return <></>
  }

  return (
    <ActionButton
      disabled={disabled}
      icon={
        <IconWrapper>
          <CloseIcon fill={bodyPrimaryColor} />
        </IconWrapper>
      }
      label={priorAuthCancelButton?.buttonLabel}
      onClick={() => {
        clickCallback?.(priorAuthorization._id)
        window.open(priorAuthCancelButton?.url, '_blank')
      }}
    />
  )
}
