import { useEffect, useRef, useState } from 'react'
import { ReactComponent as CheckboxIcon } from 'assets/icons/circle-checkmark.svg'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { ReactComponent as DangerIcon } from 'assets/icons/danger.svg'
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg'
import IconWrapper from 'components/IconWrapper'
import MultiLineMessage from 'components/MultiLineMessage'
import { useToastContext } from 'providers/Overlays/ToastProvider'
import styled, { css, keyframes } from 'styled-components'
import {
  capsuleLightBlueColor,
  capsuleLightGreenColor,
  capsuleLightRedColor,
  capsuleRed,
  darkGreen,
  darkInfo,
} from 'styles/styleVariables'
import type { Toast, ToastPosition } from 'types'
import { ToastType } from 'types'

const ToastRack = (): JSX.Element => {
  const { toastSlices } = useToastContext()

  if (toastSlices.length) {
    return <ToastView />
  }

  return <></>
}

const ToastView = (): JSX.Element => {
  const { toastSlices, position } = useToastContext()
  return (
    <StyledToastContainer position={position}>
      {toastSlices.map((toastSlice, i) => (
        <Slice key={toastSlice.id} {...toastSlice} topSlice={i === 0} childNumber={i} />
      ))}
    </StyledToastContainer>
  )
}

interface SliceProps extends Toast {
  childNumber: number
  topSlice: boolean
  timeToAutoDismiss?: number
}

export const Slice = ({
  message,
  type,
  id,
  topSlice,
  timeToAutoDismiss = 5000,
  childNumber,
}: SliceProps): JSX.Element => {
  const { dismissToast } = useToastContext()
  const [isNewToast, setIsNewToast] = useState(true)
  const [dismissed, setDismissed] = useState(false)
  const setNewToastTimer: { current: NodeJS.Timeout | null } = useRef(null)
  const setDismissToastTimer: { current: NodeJS.Timeout | null } = useRef(null)
  const dismissToastTimer: { current: NodeJS.Timeout | null } = useRef(null)

  useEffect(() => {
    setNewToastTimer.current = setTimeout(() => {
      setIsNewToast(false)
    }, 1000)
    return () => {
      if (setNewToastTimer.current) clearInterval(setNewToastTimer.current)
    }
  }, [id])

  useEffect(() => {
    setDismissToastTimer.current = setTimeout(() => {
      setDismissed(true)
    }, timeToAutoDismiss)
    return () => {
      if (setDismissToastTimer.current) clearInterval(setDismissToastTimer.current)
    }
  }, [dismissToast, id, timeToAutoDismiss])

  useEffect(() => {
    if (dismissed) {
      dismissToastTimer.current = setTimeout(() => {
        dismissToast(id)
      }, 300)
    }
    return () => {
      if (dismissToastTimer.current) clearInterval(dismissToastTimer.current)
    }
  }, [dismissed, dismissToast, id])

  return (
    <StyledSlice
      data-testid="ToastMessage"
      type={type}
      topSlice={isNewToast && topSlice}
      dismiss={dismissed}
      style={{ zIndex: -childNumber }}
    >
      <Content data-testid="toast-content">
        <IconWrapper>
          <ToastIcon type={type} />
        </IconWrapper>
        <p>
          <MultiLineMessage message={message} />
        </p>
      </Content>
      <CloseToastButton clickCallback={() => setDismissed(true)} type={type} />
    </StyledSlice>
  )
}

type CloseToastButtonProps = { clickCallback: () => void; type: ToastType }

const CloseToastButton = ({ clickCallback, type }: CloseToastButtonProps): JSX.Element => {
  return (
    <ClearButton data-testid="close-toast-button" onClick={clickCallback}>
      <CloseIcon fill={type === ToastType.success ? darkGreen : type === ToastType.info ? darkInfo : capsuleRed} />
    </ClearButton>
  )
}

const ClearButton = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
`

type ToastIconProps = { type: ToastType }

const ToastIcon = ({ type }: ToastIconProps): JSX.Element => {
  if (type === ToastType.error) {
    return <DangerIcon fill={capsuleRed} />
  } else if (type === ToastType.success) {
    return <CheckboxIcon stroke={darkGreen} />
  } else {
    return <InfoIcon fill={darkInfo} />
  }
}

const Dismiss = keyframes`
  0% {
    opacity: 1;
    max-height: 4rem;
  }

  100% {
    opacity: 0;
    max-height: 0;
    margin-top: 0;
    padding: 0;
  }
`

const Error = css`
  color: ${capsuleRed};
  border: 1px solid ${capsuleRed};
  border-left-width: 4px;
  background-color: ${capsuleLightRedColor};
`

const Info = css`
  color: ${darkInfo};
  border: 1px solid ${darkInfo};
  border-left-width: 4px;
  background-color: ${capsuleLightBlueColor};
`

const Success = css`
  color: ${darkGreen};
  border: 1px solid ${darkGreen};
  border-left-width: 4px;
  background-color: ${capsuleLightGreenColor};
`

const SlideIn = keyframes`
  0% {
    overflow-y: visible;
    max-height: 0;
    margin-bottom: 100px;
    margin-top: -100px;
  }
  100% {
    margin-bottom: 0;
    margin-top: 0.625rem;
    overflow-y: visible;
    max-height: 4rem;
  }
`

const SlideInAnimation = css`
  animation: ${SlideIn} 300ms;
`

const DismissAnimation = css`
  animation: ${Dismiss} 200ms;
`

const StyledSlice = styled.ul<{
  type: ToastType
  topSlice?: boolean
  dismiss?: boolean
}>`
  position: relative;
  width: 100%;
  display: flex;
  border-radius: 8px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: -5px;
  ${({ topSlice }) => topSlice && SlideInAnimation}
  ${({ dismiss }) => dismiss && DismissAnimation}
  animation-fill-mode: forwards;
  padding: 0.8rem;

  ${({ type }) => {
    if (type === ToastType.error) {
      return Error
    } else if (type === ToastType.info) {
      return Info
    } else {
      return Success
    }
  }}
`

const Content = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  > p {
    margin-left: 0.5rem;
  }
`
const Top = css`
  top: 1rem;
`

const Left = css`
  left: 1rem;
`

const Right = css`
  right: 1rem;
`

const Bottom = css`
  bottom: 1rem;
`

const Center = css`
  right: 0;
  left: 0;
`

const StyledToastContainer = styled.div<{ position?: ToastPosition }>`
  position: fixed;
  ${({ position }) => position?.includes('top') && Top}
  ${({ position }) => position?.includes('bottom') && Bottom}
  ${({ position }) => position?.includes('left') && Left}
  ${({ position }) => position?.includes('right') && Right}
  ${({ position }) => position?.includes('center') && Center}
  width: 30rem;
  margin-left: auto;
  margin-right: auto;
  z-index: 7;
`

export const ToastHeader = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  > :first-child {
    margin-right: 0.5rem;
  }
`

export default ToastRack
