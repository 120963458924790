import { FormItem, FormItems, Select } from 'components/ColumnForm'
import { NumericInput } from 'components/NumericInput'
import type { InsuranceProvider } from 'types'
import { PayerOptionsContainer, PayerOptionsInnerContainer, InputWithLabel } from './StyledComponents'
import { updatePayerFormConfigurations } from './util'

type PayerOptionsFormProps = {
  payerForm: InsuranceProvider
  updatePayerForm: (update: InsuranceProvider) => void
}

const PayerOptionsForm = ({ payerForm, updatePayerForm }: PayerOptionsFormProps): JSX.Element => (
  <FormItems data-testid="PayerOptionsForm">
    <PayerOptionsContainer style={{ display: 'flex' }}>
      <PayerOptionsInnerContainer>
        <FormItem data-test-row="b3">
          <p>B3</p>
          <Select
            data-testid="b3"
            multiple={false}
            value={payerForm.configurations?.options?.b3 || ''}
            placeholder={'Select...'}
            options={['Accepted', 'Not accepted']}
            onChange={([option]) => {
              const value = option ? option.value : ''

              updatePayerFormConfigurations(payerForm, updatePayerForm, 'options', 'b3', value)
            }}
            disableClear
          />
        </FormItem>
        <FormItem data-test-row="allow-partial-fills">
          <p>Allow partial fills</p>
          <Select
            data-testid="allow-partial-fills"
            multiple={false}
            value={payerForm.configurations?.options?.allowPartialFills ? 'True' : 'False'}
            placeholder={'Select...'}
            options={['True', 'False']}
            onChange={([option]) => {
              const value = option ? option.value : ''

              updatePayerFormConfigurations(
                payerForm,
                updatePayerForm,
                'options',
                'allowPartialFills',
                value === 'True',
              )
            }}
            disableClear
          />
        </FormItem>
        <FormItem data-test-row="allow-partial-fills-cII">
          <p>Allow partial fills on C-II</p>
          <Select
            data-testid="allow-partial-fills-cII"
            multiple={false}
            value={payerForm.configurations?.options?.allowPartialFillsCII ? 'True' : 'False'}
            placeholder={'Select...'}
            options={['True', 'False']}
            onChange={([option]) => {
              const value = option ? option.value : ''

              updatePayerFormConfigurations(
                payerForm,
                updatePayerForm,
                'options',
                'allowPartialFillsCII',
                value === 'True',
              )
            }}
            disableClear
          />
        </FormItem>
        <FormItem data-test-row="allow-partial-fills-cIII">
          <p>Allow partial fills on C-III</p>
          <Select
            data-testid="allow-partial-fills-cIII"
            multiple={false}
            value={payerForm.configurations?.options?.allowPartialFillsCIII ? 'True' : 'False'}
            placeholder={'Select...'}
            options={['True', 'False']}
            onChange={([option]) => {
              const value = option ? option.value : ''

              updatePayerFormConfigurations(
                payerForm,
                updatePayerForm,
                'options',
                'allowPartialFillsCIII',
                value === 'True',
              )
            }}
            disableClear
          />
        </FormItem>
        <FormItem data-test-row="allow-partial-fills-cIV">
          <p>Allow partial fills on C-IV</p>
          <Select
            data-testid="allow-partial-fills-cIV"
            multiple={false}
            value={payerForm.configurations?.options?.allowPartialFillsCIV ? 'True' : 'False'}
            placeholder={'Select...'}
            options={['True', 'False']}
            onChange={([option]) => {
              const value = option ? option.value : ''

              updatePayerFormConfigurations(
                payerForm,
                updatePayerForm,
                'options',
                'allowPartialFillsCIV',
                value === 'True',
              )
            }}
            disableClear
          />
        </FormItem>
        <FormItem data-test-row="allow-partial-fills-cV">
          <p>Allow partial fills on C-V</p>
          <Select
            data-testid="allow-partial-fills-cV"
            multiple={false}
            value={payerForm.configurations?.options?.allowPartialFillsCV ? 'True' : 'False'}
            placeholder={'Select...'}
            options={['True', 'False']}
            onChange={([option]) => {
              const value = option ? option.value : ''

              updatePayerFormConfigurations(
                payerForm,
                updatePayerForm,
                'options',
                'allowPartialFillsCV',
                value === 'True',
              )
            }}
            disableClear
          />
        </FormItem>
        <FormItem data-test-row="rx-expiration">
          <p>Rx expiration</p>
          <InputWithLabel>
            <NumericInput
              value={payerForm.configurations?.options?.rxExpiration}
              onChange={value =>
                updatePayerFormConfigurations(payerForm, updatePayerForm, 'options', 'rxExpiration', value)
              }
            />
            days
          </InputWithLabel>
        </FormItem>
        <FormItem data-test-row="cII-expiration">
          <p>C-II expiration</p>
          <InputWithLabel>
            <NumericInput
              value={payerForm.configurations?.options?.cIIExpiration}
              onChange={value =>
                updatePayerFormConfigurations(payerForm, updatePayerForm, 'options', 'cIIExpiration', value)
              }
            />
            days
          </InputWithLabel>
        </FormItem>
        <FormItem data-test-row="other-C-expiration">
          <p>C-III, IV, V expiration</p>
          <InputWithLabel>
            <NumericInput
              value={payerForm.configurations?.options?.otherCExpiration}
              onChange={value =>
                updatePayerFormConfigurations(payerForm, updatePayerForm, 'options', 'otherCExpiration', value)
              }
            />
            days
          </InputWithLabel>
        </FormItem>
        <FormItem data-test-row="copay-method">
          <p>Copay method</p>
          <Select
            data-testid="copay-method"
            multiple={false}
            value={payerForm.configurations?.options?.copayMethod}
            placeholder={'Select...'}
            options={[
              'Automatical',
              'Add taxes to copay',
              'Manually override',
              'Maximum copay',
              'Minimum copay',
              'Missouri Medicaid',
            ]}
            onChange={([option]) => {
              const value = option ? option.value : ''

              updatePayerFormConfigurations(payerForm, updatePayerForm, 'options', 'copayMethod', value)
            }}
            disableClear
          />
        </FormItem>
        <FormItem data-test-row="refills-due">
          <p>Refills due</p>
          <NumericInput
            value={payerForm.configurations?.options?.refillsDue}
            onChange={value =>
              updatePayerFormConfigurations(payerForm, updatePayerForm, 'options', 'refillsDue', value)
            }
          />
        </FormItem>
      </PayerOptionsInnerContainer>
      <PayerOptionsInnerContainer>
        <FormItem data-test-row="tax-method">
          <p>Tax Method</p>
          <Select
            data-testid="tax-method"
            multiple={false}
            value={payerForm.configurations?.options?.taxMethod || ''}
            placeholder={'Select...'}
            options={['None', 'Patient pays on all', 'Patient pays on copay', 'Payer pays', 'Payer plan is exempt']}
            onChange={([option]) => {
              const value = option ? option.value : ''

              updatePayerFormConfigurations(payerForm, updatePayerForm, 'options', 'taxMethod', value)
            }}
            disableClear
          />
        </FormItem>
        <FormItem data-test-row="max-claims-per-transmit">
          <p>Max claims per transmit</p>
          <NumericInput
            value={payerForm.configurations?.options?.maxClaimsPerTransmit}
            onChange={value =>
              updatePayerFormConfigurations(payerForm, updatePayerForm, 'options', 'maxClaimsPerTransmit', value)
            }
          />
        </FormItem>
        <FormItem data-test-row="min-rx-price">
          <p>Min Rx price</p>
          <NumericInput
            value={payerForm.configurations?.options?.minRxPrice}
            onChange={value =>
              updatePayerFormConfigurations(payerForm, updatePayerForm, 'options', 'minRxPrice', value)
            }
          />
        </FormItem>
        <FormItem data-test-row="max-rx-price">
          <p>Max Rx price</p>
          <NumericInput
            value={payerForm.configurations?.options?.maxRxPrice}
            onChange={value =>
              updatePayerFormConfigurations(payerForm, updatePayerForm, 'options', 'maxRxPrice', value)
            }
          />
        </FormItem>
        <FormItem data-test-row="max-monthly-fills">
          <p>Max monthly fills</p>
          <NumericInput
            value={payerForm.configurations?.options?.maxMonthlyFills}
            onChange={value =>
              updatePayerFormConfigurations(payerForm, updatePayerForm, 'options', 'maxMonthlyFills', value)
            }
          />
        </FormItem>
        <FormItem data-test-row="max-rx-refills">
          <p>Max Rx refills</p>
          <NumericInput
            value={payerForm.configurations?.options?.maxRxRefills}
            onChange={value =>
              updatePayerFormConfigurations(payerForm, updatePayerForm, 'options', 'maxRxRefills', value)
            }
          />
        </FormItem>
        <FormItem data-test-row="max-cIII-refills">
          <p>Max C-III refills</p>
          <NumericInput
            value={payerForm.configurations?.options?.maxCIIIRefills}
            onChange={value =>
              updatePayerFormConfigurations(payerForm, updatePayerForm, 'options', 'maxCIIIRefills', value)
            }
          />
        </FormItem>
        <FormItem data-test-row="max-cIV-refills">
          <p>Max C-IV refills</p>
          <NumericInput
            value={payerForm.configurations?.options?.maxCIVRefills}
            onChange={value =>
              updatePayerFormConfigurations(payerForm, updatePayerForm, 'options', 'maxCIVRefills', value)
            }
          />
        </FormItem>
        <FormItem data-test-row="max-cV-refills">
          <p>Max C-V refills</p>
          <NumericInput
            value={payerForm.configurations?.options?.maxCVRefills}
            onChange={value =>
              updatePayerFormConfigurations(payerForm, updatePayerForm, 'options', 'maxCVRefills', value)
            }
          />
        </FormItem>
        <FormItem data-test-row="max-monthly-amount">
          <p>Max monthly amount</p>
          <NumericInput
            value={payerForm.configurations?.options?.maxMonthlyAmount}
            onChange={value =>
              updatePayerFormConfigurations(payerForm, updatePayerForm, 'options', 'maxMonthlyAmount', value)
            }
          />
        </FormItem>
        <FormItem data-test-row="max-day-supply">
          <p>Max day supply</p>
          <NumericInput
            value={payerForm.configurations?.options?.maxDaySupply}
            onChange={value =>
              updatePayerFormConfigurations(payerForm, updatePayerForm, 'options', 'maxDaySupply', value)
            }
          />
        </FormItem>
      </PayerOptionsInnerContainer>
    </PayerOptionsContainer>
  </FormItems>
)

export default PayerOptionsForm
