import { ReactComponent as NotesIcon } from 'assets/icons/notes.svg'
import { SaveButton } from 'components/ActionButton'
import IconWrapper from 'components/IconWrapper'
import { ModalWrapper, ModalHeader, ButtonsContainer } from 'components/Modal'
import { NoteEntry } from 'components/NotesPane/NotesList'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import styled from 'styled-components'
import { bodyPrimaryColor } from 'styles/styleVariables'
import type { Log, OptChildProps } from 'types'

type CriticalNotesModalProps = { notes?: Log[] } & OptChildProps

const CriticalNotesModal = ({ notes, children }: CriticalNotesModalProps): JSX.Element => {
  const { dismissModal } = useModalContext()

  if (!notes) {
    return <></>
  }

  if (notes.length || children) {
    return (
      <ModalWrapper id="CriticalNotesModal">
        <ModalHeader>
          <IconWrapper>
            <NotesIcon fill={bodyPrimaryColor} />
          </IconWrapper>
          <h2>Critical notes</h2>
        </ModalHeader>
        <NotesContainer>
          <StyledList>
            {notes.map(message => (
              <NoteEntry key={message._id} note={message} />
            ))}
            {children}
          </StyledList>
        </NotesContainer>
        <ButtonsContainer>
          <SaveButton label="Confirm" isModal={false} onClick={dismissModal} />
        </ButtonsContainer>
      </ModalWrapper>
    )
  }

  return <></>
}

const NotesContainer = styled.div`
  display: flex;
  width: 100%;
  min-width: 27rem;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

const StyledList = styled.ul`
  width: 100%;
  max-height: 13rem;
  overflow-y: auto;
`

export default CriticalNotesModal
