import { ModalWrapper, ModalHeader } from 'components/Modal'
import RXScanImage from 'components/RXScanImage'
import { FullWidthHeader } from 'components/RXTable'
import Lozenge from 'components/Tiles/Lozenge'
import styled from 'styled-components'
import { subduedColor } from 'styles/styleVariables'

type AttachmentDetailsModalProps = {
  attachmentId: string
  s3Url: string
  createdAt: string
  caption: string
  tag: string
}

const AttachmentDetailsModal = ({
  attachmentId,
  s3Url,
  createdAt,
  caption,
  tag,
}: AttachmentDetailsModalProps): JSX.Element => {
  return (
    <ModalWrapper>
      <ModalHeader>
        <InfoContainer>
          <h2>{caption}</h2>
          <AttachmentsDate>Added {createdAt}</AttachmentsDate>
          <StyledLozenge backgroundColor={subduedColor}>{tag}</StyledLozenge>
        </InfoContainer>
      </ModalHeader>
      <ContentHeader> Image </ContentHeader>
      <RXScanImage src={s3Url} inline />
    </ModalWrapper>
  )
}

const ContentHeader = styled(FullWidthHeader)`
  margin-top: 20px;
  margin-bottom: 10px;
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledLozenge = styled(Lozenge)`
  width: 80px;
  margin-left: 0;
  margin-top: 5px;
`

const AttachmentsDate = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #999999;
  margin-top: 2px;
`

export default AttachmentDetailsModal
