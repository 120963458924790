import { useMemo } from 'react'
import type { Address } from 'types'
import { validateAddressForm } from 'utils'

type useAddressFormValidationType = { isValid: boolean; errors: Record<keyof Address, string> }

const useAddressFormValidation = (address: Partial<Address>, isOptional = true): useAddressFormValidationType => {
  const validation = useMemo(() => {
    return validateAddressForm(address, isOptional)
  }, [address.street1, address.city, address.state, address.zip, isOptional])
  return validation
}

export default useAddressFormValidation
