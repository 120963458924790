import { useCallback, useState } from 'react'
import { ReactComponent as TransferIcon } from 'assets/icons/request.svg'
import { PrescriptionsFilter } from 'components/Filter'
import FilterPane from 'components/FilterPane'
import IconWrapper from 'components/IconWrapper'
import { PageContainer } from 'components/PageStructure'
import useHotKey, { HotKeyLevel } from 'hooks/useHotKey'
import TransferRequestModal from 'modals/TransferRequestModal'
import { FILTER_NAMESPACE } from 'NameSpaces'
import FormDataProvider from 'providers/FormDataProvider'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import { borderColor } from 'styles/styleVariables'
import type { Prescriber } from 'types'
import PrescriptionsTable from '../PrescriberPrescriptionsTable'
import {
  NewPrescriptionControls,
  Title,
  Content,
  SearchControls,
  StyledSearchInput,
  StyledActionButton,
  StyledActionBar,
} from './StyledComponents'

type PrescriberPrescriptionsPageProps = { prescriberId: Prescriber['_id'] }

const initialData = {
  [FILTER_NAMESPACE]: {
    medications: {},
    customerIds: [],
    statusIds: [],
  },
}

const PrescriberPrescriptionsPage = ({ prescriberId }: PrescriberPrescriptionsPageProps): JSX.Element => {
  const [searchTerm, setSearchTerm] = useState('')

  const [[hideFilterControls, isFirstLoad], setHideFilterControls] = useState([true, true])
  const { showModal } = useModalContext()

  const toggleHideFilterControls = useCallback(() => {
    setHideFilterControls([!hideFilterControls, false])
  }, [hideFilterControls, setHideFilterControls])

  useHotKey('f', HotKeyLevel.normal, toggleHideFilterControls)

  return (
    <FormDataProvider initialData={initialData}>
      <PageContainer>
        <Title>Prescription list</Title>
        <StyledActionBar>
          <SearchControls>
            <StyledSearchInput
              iconColor={borderColor}
              placeholder={'Search by rxNumber, rxFillCode or prescriptionToken'}
              onChange={({ currentTarget: { value } }) => setSearchTerm(value)}
            />
          </SearchControls>
          <NewPrescriptionControls>
            <StyledActionButton
              icon={
                <IconWrapper>
                  <TransferIcon fill="black" />
                </IconWrapper>
              }
              hotKey="tr"
              hotKeyLabel="T-R"
              label="Request"
              onClick={() => showModal(() => <TransferRequestModal />)}
            />
          </NewPrescriptionControls>
        </StyledActionBar>
        <Content>
          <FilterPane isFirstLoad={isFirstLoad} hideFilters={hideFilterControls}>
            <PrescriptionsFilter isVisible={!hideFilterControls} />
          </FilterPane>
          <PrescriptionsTable prescriberIds={prescriberId} searchTerm={searchTerm} />
        </Content>
      </PageContainer>
    </FormDataProvider>
  )
}

export default PrescriberPrescriptionsPage
