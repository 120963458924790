import styled, { keyframes, css } from 'styled-components'
import type { ChildProps } from 'types'

const slideIn = keyframes`
  0% {
    overflow: hidden;
    white-space: nowrap;
    max-width: 0px;
  }
  99% {
    overflow: hidden;
    white-space: nowrap;
    max-width: 300px;
  }
  100% {
    overflow: hidden;
    flex: 1;
    max-width: 300px;
  }
`

const slideOut = keyframes`
  0% {
    overflow: hidden;
    white-space: nowrap;
    max-width: 300px;
  }
  100% {
    overflow: hidden;
    white-space: nowrap;
    max-width: 0px;
  }
`

const slideInAnimation = css`
  animation: ${slideIn} 200ms;
`

const slideOutAnimation = css`
  animation: ${slideOut} 200ms;
`

const instantDuration = css`
  animation-duration: 0ms;
`

const StaticPaddedContainer = styled.div`
  padding-left: 1.875rem;
`

const FilterContainer = styled.div<{
  hideFilters: boolean
  isFirstLoad: boolean
}>`
  background-color: white;
  grid-row: content;
  grid-column: filter;
  animation-iteration-count: 1;
  ${({ hideFilters }) => (hideFilters ? slideOutAnimation : slideInAnimation)};
  ${({ isFirstLoad }) => isFirstLoad && instantDuration}
  animation-fill-mode: forwards;
  overflow: hidden;
  min-height: 28.5rem;
`

type FilterPaneProps = {
  hideFilters: boolean
  isFirstLoad: boolean
} & ChildProps

const FilterPane = ({ hideFilters, isFirstLoad, children }: FilterPaneProps): JSX.Element => {
  return (
    <FilterContainer hideFilters={hideFilters} isFirstLoad={isFirstLoad}>
      <StaticPaddedContainer>{children}</StaticPaddedContainer>
    </FilterContainer>
  )
}
export default FilterPane
