import { Button } from '@truepill/react-capsule'
import { useMutation, useHistory } from '@truepill/tpos-react-router'
import type { SendBackReason } from '@truepill/tpos-types'
import { RxFillRequestStatus, UserRoles, SendBackToPv1FromFillReason } from '@truepill/tpos-types'
import { ReactComponent as CancelIcon } from 'assets/icons/cancel.svg'
import { ReactComponent as LoadingDots } from 'assets/icons/loading_dots.svg'
import { ReactComponent as PrintIcon } from 'assets/icons/print.svg'
import { ReactComponent as ReverseIcon } from 'assets/icons/reverse.svg'
import { ReactComponent as RobotIcon } from 'assets/icons/robot.svg'
import { ReactComponent as RouteIcon } from 'assets/icons/route.svg'
import { ReactComponent as TimeIcon } from 'assets/icons/time.svg'
import ActionButton from 'components/ActionButton'
import AuthLimited from 'components/AuthLimited'
import IconWrapper from 'components/IconWrapper'
import { ActionButtonContainer } from 'components/PageStructure'
import TriageOrResolveTriageButton from 'components/TriageOrResolveTriageButton'
import {
  SEND_BACK_TO_PV1,
  SEND_BACK_TO_ADJUDICATION,
  SEND_TO_AUTOMATION,
  SYNC_AUTOMATION_STATUS,
  SEND_TO_MANUAL,
  BULK_SEND_DEFERRED_TO_AUTOMATION,
} from 'gql'
import useErrorToast from 'hooks/toast/useErrorToast'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import { FulfillmentQueueName } from 'hooks/useFulfillmentQueue'
import useOrderLock from 'hooks/useOrderLock'
import PrintModal from 'modals/PrintModal'
import RejectModal from 'modals/RejectModal'
import SendBackModal from 'modals/SendBackModal'
import SendToManualModal from 'modals/SendToManualModal'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import { usePlusClient } from 'providers/VisionRouter'
import { useLocation } from 'react-use'
import { goToFulfillment } from 'routes'
import styled from 'styled-components'
import { bodyPrimaryColor, capsulePrimaryColorDark } from 'styles/styleVariables'
import { MachineStatus } from 'types'
import type { Fill, Order, Prescription, RXFillRequest, NdcSubstitutionPackage } from 'types'
import { isInsuranceFill } from 'utils'
import { ChangeDispensedItemModal } from './modals'

type ActionButtonsProps = {
  fill: Fill
  order: Order
  prescription: Prescription
  rxFillRequest: RXFillRequest
  hasParataNdcs: boolean
  ndcSubstitutionPackage?: NdcSubstitutionPackage
}

const changeDispensedStatusCheck = (currentRxFillRequest: RXFillRequest, rxFillRequests: RXFillRequest[]): boolean => {
  return (
    rxFillRequests.every(({ status }) =>
      [
        RxFillRequestStatus.Fill,
        RxFillRequestStatus.Automation,
        RxFillRequestStatus.PV2,
        RxFillRequestStatus.Cancelled,
      ].includes(status),
    ) && currentRxFillRequest.status !== RxFillRequestStatus.Cancelled
  )
}

const ActionButtons = ({
  order,
  fill,
  prescription,
  rxFillRequest,
  hasParataNdcs,
  ndcSubstitutionPackage,
}: ActionButtonsProps): JSX.Element => {
  const showErrorToast = useErrorToast()
  const showSuccessToast = useSuccessToast()
  const isInsurance = isInsuranceFill(rxFillRequest)
  const history = useHistory()
  const { search } = useLocation()
  const { showModal } = useModalContext()
  const { orderEditable } = useOrderLock(order._id)
  const {
    tokenContext: { isPharmacist, isTechnician, isLeadPharmacist, isCustomerSupport },
  } = usePlusClient()
  const isDeferredAutomationOrder = rxFillRequest.machine?.status === MachineStatus.waitingToBeSent

  const [sendBackToPV1] = useMutation(SEND_BACK_TO_PV1)

  const [sendBackToAdjudication, { loading: loadingSendBackToAdjudication }] = useMutation(SEND_BACK_TO_ADJUDICATION, {
    refetchQueries: ['getBasicOrder'],
  })

  const [sendToAutomation] = useMutation(SEND_TO_AUTOMATION, {
    onCompleted() {
      showSuccessToast(`Successfully sent to automation`)
    },
    onError(err) {
      showErrorToast(err.message)
    },
  })

  const [bulkSendDeferredToAutomation] = useMutation(BULK_SEND_DEFERRED_TO_AUTOMATION, {
    onCompleted(res) {
      const error = res.bulkSendDeferredToAutomation.map((i: any) => i.error).filter((i: any) => !!i)
      if (error.length) {
        showErrorToast(error.join('/'))
      } else {
        showSuccessToast(`Successfully sent to automation.`)
      }
    },
    onError(err) {
      showErrorToast(err.message)
    },
  })

  const [syncAutomationStatus] = useMutation(SYNC_AUTOMATION_STATUS, {
    onCompleted() {
      showSuccessToast(`Successfully updated automation status`)
    },
    onError(err) {
      showErrorToast(err.message)
    },
  })

  const [sendToManual, { loading: sendToManualIsLoading }] = useMutation(SEND_TO_MANUAL, {
    onCompleted() {
      showSuccessToast(`Successfully sent to manual.`)
    },
    onError(err) {
      showErrorToast(err.message)
    },
  })

  const isReprint = (fill.labelsPrinted || 0) > 0

  if (!order) {
    return <></>
  }

  const parataStatus = rxFillRequest?.machine?.status
  const inParataWorkflow =
    rxFillRequest?.status === RxFillRequestStatus.Automation &&
    [
      MachineStatus.waitingToBeSent,
      MachineStatus.Queued,
      MachineStatus.Started,
      MachineStatus.Error,
      MachineStatus.Completed,
    ].includes(parataStatus)

  const changeDispenseItemAllowed =
    changeDispensedStatusCheck(rxFillRequest, order.rxFillRequests) && orderEditable && prescription.daw === 0
  const customerId = order.rxFillRequests[0]?.prescription?.customer?.legacyId

  const showPrintModal = () => (
    <PrintModal
      fills={[{ fillId: fill._id, orderId: order._id }]}
      isReprint={isReprint}
      customerId={customerId}
      title={`${isReprint ? 'Reprint' : 'Print'} label`}
    />
  )

  return (
    <ActionButtonContainer>
      <AuthLimited roles={[UserRoles.Pharmacist]}>
        <ActionButton
          data-testid="reject"
          disabled={!orderEditable}
          icon={
            <IconWrapper>
              <CancelIcon fill={bodyPrimaryColor} />
            </IconWrapper>
          }
          label="Reject"
          onClick={() => {
            showModal(() => (
              <RejectModal
                itemId={order._id}
                fillId={fill._id}
                durs={fill.durScreenings?.slice(-1)[0]?.results ?? []}
                isControlledSubstance={!!fill.handlingTags.deaScheduleNum || fill.handlingTags.isControlledSubstance}
              />
            ))
          }}
          hotKey="R"
          hotKeyMeta={{ alt: true, shift: true }}
          hotKeyLabel="ALT-SHIFT-R"
        />
      </AuthLimited>
      {hasParataNdcs && (!rxFillRequest?.machine || rxFillRequest.machine.status === MachineStatus.waitingToBeSent) && (
        <AuthLimited roles={[UserRoles.LeadPharmacist, UserRoles.Pharmacist, UserRoles.Technician]}>
          <ActionButton
            disabled={!orderEditable}
            icon={
              <IconWrapper>
                <RobotIcon fill={bodyPrimaryColor} />
              </IconWrapper>
            }
            label="Send to Automation"
            onClick={async () => {
              isDeferredAutomationOrder
                ? await bulkSendDeferredToAutomation({
                    variables: { fills: [{ fillId: fill._id, orderId: order._id }] },
                  })
                : await sendToAutomation({
                    variables: { fillId: fill._id },
                  })
            }}
          />
        </AuthLimited>
      )}
      {rxFillRequest?.machine && rxFillRequest?.machine.status !== MachineStatus.waitingToBeSent && (
        <>
          <ActionButton
            disabled={!orderEditable}
            icon={
              sendToManualIsLoading ? (
                <IconWrapper>
                  <LoadingDots fill={bodyPrimaryColor} />
                </IconWrapper>
              ) : (
                <IconWrapper>
                  <RouteIcon fill={bodyPrimaryColor} />
                </IconWrapper>
              )
            }
            label="Send to manual"
            onClick={() =>
              showModal(() => (
                <SendToManualModal
                  isBulk={false}
                  fillRequests={order.rxFillRequests}
                  onConfirm={async () => {
                    await sendToManual({
                      variables: { fillId: fill._id },
                    })
                  }}
                />
              ))
            }
          />
          <AuthLimited roles={[UserRoles.Pharmacist, UserRoles.Technician]}>
            <ActionButton
              icon={
                <IconWrapper>
                  <ReverseIcon fill={bodyPrimaryColor} />
                </IconWrapper>
              }
              label="Resync Status"
              onClick={async () => {
                await syncAutomationStatus({
                  variables: { fillId: fill._id },
                })
              }}
            />
          </AuthLimited>
        </>
      )}
      <AuthLimited roles={[UserRoles.Pharmacist, UserRoles.Technician]}>
        <ActionButton
          disabled={!orderEditable || inParataWorkflow}
          icon={
            <IconWrapper>
              <CancelIcon fill={bodyPrimaryColor} />
            </IconWrapper>
          }
          label="Send back"
          onClick={() => {
            showModal(() => (
              <SendBackModal
                tabs={[{ id: 'toPv1', title: '', options: Object.values(SendBackToPv1FromFillReason) }]}
                confirmationCallback={async (tabId: string, sendBackReason: SendBackReason, message?: string) => {
                  await sendBackToPV1({
                    variables: { orderId: order._id, fillId: fill._id, message, sendBackReason },
                  })
                  history.push(goToFulfillment({ fulfillmentQueueName: FulfillmentQueueName.Fill, search }))
                }}
              />
            ))
          }}
        />
      </AuthLimited>
      {isInsurance && (
        <AuthLimited roles={[UserRoles.Admin, UserRoles.Pharmacist]}>
          <ActionButton
            data-testid="reverse"
            disabled={!orderEditable}
            icon={
              <IconWrapper>
                <TimeIcon fill={bodyPrimaryColor} />
              </IconWrapper>
            }
            label={loadingSendBackToAdjudication ? 'Reversing...' : 'Reverse Claims'}
            onClick={async () => {
              try {
                const variables = {
                  variables: {
                    orderId: order._id,
                    fillId: fill?._id,
                    message: 'Fill Reverse Claims',
                  },
                }
                await sendBackToAdjudication(variables)
              } catch (e) {
                showErrorToast(e.message)
                console.error(e)
              }
            }}
          />
        </AuthLimited>
      )}
      <TriageOrResolveTriageButton item={order} fillId={fill._id} disabled={inParataWorkflow} forceShowTriage />
      <AuthLimited roles={[UserRoles.Pharmacist, UserRoles.Technician, UserRoles.Admin]}>
        <ActionButton
          disabled={!changeDispenseItemAllowed || inParataWorkflow}
          title={changeDispenseItemAllowed ? '' : 'Substitutions not allowed'}
          icon={
            <IconWrapper>
              <CancelIcon fill={bodyPrimaryColor} />
            </IconWrapper>
          }
          hotKey="C"
          hotKeyMeta={{ ctrl: true, shift: true }}
          hotKeyLabel="CTRL-SHIFT-C"
          label="Change dispensed item"
          onClick={() => {
            showModal(() => (
              <ChangeDispensedItemModal
                order={order}
                fill={fill}
                prescription={prescription}
                rxFillRequest={rxFillRequest}
                ndcSubstitutionPackage={ndcSubstitutionPackage}
              />
            ))
          }}
        />
      </AuthLimited>
      {/*isCustomerSupport includes both CustomerSupport and LeadCustomerSupport*/}
      {(isPharmacist() || isLeadPharmacist() || isTechnician() || isCustomerSupport()) && (
        <WrapButton variant="primary-outline" onClick={() => showModal(showPrintModal)} disabled={!orderEditable}>
          <IconWrapper>
            <PrintIcon />
          </IconWrapper>
          {isReprint ? 'Reprint' : 'Print'}
        </WrapButton>
      )}
    </ActionButtonContainer>
  )
}
export default ActionButtons

export const WrapButton = styled(Button)`
  display: flex;
  height: 2.5rem;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.25rem;
  margin-left: 0.5rem;

  span {
    margin-right: 0.25rem;
    svg {
      fill: ${capsulePrimaryColorDark};
    }
  }

  :hover:not(:disabled) {
    span {
      svg {
        fill: white;
      }
    }
  }
`
