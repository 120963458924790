import type { Species } from '../entities'

export enum TransactionType {
  SEND_OR_REQUEST_TRANSACTION = '01',
  ACKNOWLEDGEMENT = '02',
  ERROR_RECEIVING = '03',
  VOID = '04', // used to void a specific Rx in a real-time transmission or an entire batch that has been transmitted
}

export enum FileType {
  PRODUCTION = 'P',
  TEST = 'T',
}

export enum PatientSpecies {
  HUMAN = '01',
  ANIMAL = '02',
}

export enum ReportingStatus {
  NEW_RECORD = '01', // indicates a new prescription dispensing transaction
  REVISE = '02', // indicates that one or more data element values in a previously submitted transaction are being revised
  VOID = '03', // message to the PMP to remove the original prescription transaction from its data, or to mark the record as invalid or to be ignored
}

export enum ProductIDQualifier {
  NDC = '01',
  COMPOUND = '06',
}

export enum DrugDosageUnitsCode {
  EACH = '01',
  MILLILITERS = '02',
  GRAMS = '03',
}

export enum RxOriginCode {
  WRITTEN = '01',
  TELEPHONE = '02',
  TELEPHONE_EMERGENCY = '03',
  FAX = '04',
  ELECTRONIC = '05',
  OTHER = '99',
}

export enum PartialFillIndicator {
  NOT_A_PARTIAL = '00',
  FIRST_PARTIAL = '01', // NOTE: For additional fills per prescription, increment by 1. So, the second partial fill would be reported as 02, up to a maximum of 99
}

export enum PaymentTypeCode {
  PRIVATE_PAY = '01', // cash, check, debit, credit
  MEDICAID = '02',
  MEDICARE = '03',
  COMMERCIAL_INSURANCE = '04',
  MILITARY_INSTALLATIONS_OR_VA = '05',
  WORKERS_COMPENSATION = '06',
  INDIAN_NATIONS = '07',
  OTHER = '99',
}

export enum PatientIdType {
  MILITARY_ID = '01',
  STATE_ISSUED_ID = '02',
  UNIQUE_SYSTEM_ID = '03',
  PERMANENT_RESIDENT_CARD = '04', // Green Card
  PASSPORT_ID = '05',
  DRIVERS_LICENSE = '06',
  TRIBAL_ID = '08',
  VENDOR_SPECIFIC = '09',
  VETERINARY_PATIENT_MICROCHIP_NUMBER = '10',
  MEDICAID_RECIPIENT_ID_NUMBER = '11',
  OTHER = '99', // agreed upon ID
}

export enum RelationshipOfPersonPickingUp {
  PATIENT = '01',
  PARENT_LEGAL_GUARDIAN = '02',
  SPOUSE = '03',
  CAREGIVER = '04',
  OTHER = '99',
}

export enum DroppingOffPickingUpIdentifierQualifier {
  PERSON_DROPPING_OFF = '01',
  PERSON_PICKING_UP = '02',
  NOT_APPLICABLE = '03',
}

export enum PharmacyDispenserType {
  INDEPENDENT_PHARMACY = '01',
  CHAIN_PHARMACY = '02',
  LONG_TERM_CARE_PHARMACY = '03',
  HOSPITAL_PHARMACY = '04',
  OPIOID_PHARMACY = '05',
  CANNABIS_DISPENSARY = '06',
  VETERINARY_VET_PATIENT_ONLY_DISPENSER = '07',
  DISPENSING_PRESCRIBER = '08',
  SPECIALTY_PHARMACY = '09',
  FEDERAL = '10',
  TRIBAL = '11',
  OTHER = '99',
}

export enum VeterinarySpecies {
  CAT_FELINE = '01',
  DOG_CANINE = '02',
  SMALL_ANIMAL = '03', // Chinchilla, Ferret, Hedgehog, European Rabbit, Guinea Pig, Other Rodentia, Rat, Syrian Hamster
  REPTILE = '04', // Lizard, Snake, Turtle
  BIRD = '05', // Chicken, Duck, Other Aves
  LIVESTOCK_LARGE_ANIMAL = '06', // Alpaca, Goat/Caprine, Horse/Equine, Llama, Other Bovinae, Pig/Porcine, Sheep/Ovine
  OTHER = '99', // Fox, Monkey, Other Mammalia
}

export const getDrugDosageUnitsCode = (unitOfMeasure: string): string => {
  if (unitOfMeasure === 'EA') return DrugDosageUnitsCode.EACH
  if (unitOfMeasure === 'GM') return DrugDosageUnitsCode.GRAMS
  if (unitOfMeasure === 'ML') return DrugDosageUnitsCode.MILLILITERS
  return ''
}

export const getPrescriptionOriginCode = (origin: string): string => {
  switch (origin) {
    case 'written':
      return RxOriginCode.WRITTEN
    case 'telephone':
      return RxOriginCode.TELEPHONE
    case 'fax':
      return RxOriginCode.FAX
    case 'electronic':
      return RxOriginCode.ELECTRONIC
    default:
      return RxOriginCode.OTHER
  }
}

export const getPatientSpeciesCode = (species?: Species): PatientSpecies => {
  if (!species || species === 'Human') {
    return PatientSpecies.HUMAN
  } else {
    return PatientSpecies.ANIMAL
  }
}
export const getVeterinarySpeciesCode = (species: Species): VeterinarySpecies => {
  switch (species) {
    case 'Cat/Feline':
      return VeterinarySpecies.CAT_FELINE
    case 'Dog/Canine':
      return VeterinarySpecies.DOG_CANINE
    case 'Chinchilla':
    case 'Ferret':
    case 'Hedgehog':
    case 'European Rabbit':
    case 'Guinea Pig':
    case 'Other Rodentia':
    case 'Rat':
    case 'Syrian Hamster':
      return VeterinarySpecies.SMALL_ANIMAL
    case 'Lizard':
    case 'Snake':
    case 'Turtle':
      return VeterinarySpecies.REPTILE
    case 'Chicken':
    case 'Duck':
    case 'Other Aves':
      return VeterinarySpecies.BIRD
    case 'Alpaca':
    case 'Goat/Carpine':
    case 'Horse/Equine':
    case 'Llama':
    case 'Other Bovinae':
    case 'Pig/Porcine':
    case 'Sheep/Ovine':
      return VeterinarySpecies.LIVESTOCK_LARGE_ANIMAL
    case 'Fox':
    case 'Monkey':
    case 'Other Mammalia':
    default:
      return VeterinarySpecies.OTHER
  }
}

export enum TransactionHeaderFields {
  SEGMENT_IDENTIFIER = 'SegmentIdentifier',
  VERSION = 'Version',
  CONTROL_NUMBER = 'ControlNumber',
  TYPE = 'Type',
  RESPONSE_ID = 'ResponseId',
  CREATION_DATE = 'CreationDate',
  CREATION_TIME = 'CreationTime',
  FILE_TYPE = 'FileType',
  ROUTING_NUMBER = 'RoutingNumber',
  SEGMENT_TERMINATOR_CHARACTER = 'SegmentTerminatorCharacter',
}

export enum InformationSourceFields {
  SEGMENT_IDENTIFIER = 'SegmentIdentifier',
  UNIQUE_SOURCE_ID = 'UniqueSourceId',
  SOURCE_NAME = 'SourceName',
  MESSAGE = 'Message',
  PHARMACY_SOFTWARE_VENDOR = 'PharmacySoftwareVendor',
  SOFTWARE_VENDOR_PHONE_NUMBER = 'SoftwareVendorPhoneNumber',
}

export enum PharmacyHeaderFields {
  SEGMENT_IDENTIFIER = 'SegmentIdentifier',
  NPI = 'NPI',
  NCPDP_PROVIDER_ID = 'NCPDPProviderId',
  DEA_NUMBER = 'DeaNumber',
  NAME = 'Name',
  STREET_1 = 'Street1',
  STREET_2 = 'Street2',
  CITY = 'City',
  STATE = 'State',
  ZIP_CODE = 'ZipCode',
  PHONE_NUMBER = 'PhoneNumber',
  CONTACT_NAME = 'ContactName',
  CHAIN_SITE_ID = 'ChainSiteId',
  LICENSE_NUMBER = 'LicenseNumber',
  DISPENSER_TYPE = 'DispenserType',
  MAIL_ORDER_PHARMACY_DESIGNATION = 'MailOrderPharmacyDesignation',
}

export enum PatientInformationFields {
  SEGMENT_IDENTIFIER = 'SegmentIdentifier',
  ID_STATE = 'IdState',
  ID_TYPE = 'IdType',
  ID = 'Id',
  ADDITIONAL_ID_STATE = 'AdditionalIdState',
  ADDITIONAL_ID_TYPE = 'AdditionalIdType',
  ADDITIONAL_ID = 'AdditionalId',
  LAST_NAME = 'LastName',
  FIRST_NAME = 'FirstName',
  MIDDLE_NAME = 'MiddleName',
  NAME_PREFIX = 'NamePrefix',
  NAME_SUFFIX = 'NameSuffix',
  ADDRESS_1 = 'Address1',
  ADDRESS_2 = 'Address2',
  ADDRESS_CITY = 'AddressCity',
  ADDRESS_STATE = 'AddressState',
  ADDRESS_ZIP = 'AddressZip',
  PHONE = 'Phone',
  DOB = 'Dob',
  GENDER = 'Gender',
  SPECIES_CODE = 'SpeciesCode',
  LOCATION_CODE = 'LocationCode',
  NON_RESIDENT_COUNTRY = 'NonResidentCountry',
  ANIMAL_NAME = 'AnimalName',
  PREFERRED_LAST_NAME = 'PreferredLastName',
  PREFERRED_FIRST_NAME = 'PreferredFirstName',
  RACE_CATEGORY = 'RaceCategory',
  ETHNICITY = 'Ethnicity',
  VET_SPECIES_CODE = 'VetSpeciesCode',
  ANIMAL_LOCATION_CODE = 'AnimalLocationCode',
}

export enum DispensingRecordFields {
  SEGMENT_IDENTIFIER = 'SegmentIdentifier',
  REPORTING_STATUS = 'ReportingStatus',
  PRESCRIPTION_NUMBER = 'PrescriptionNumber',
  DATE_WRITTEN = 'DateWritten',
  REFILLS_AUTHORIZED = 'RefillsAuthorized',
  DATE_FILLED = 'DateFilled',
  FILL_NUMBER = 'FillNumber',
  PRODUCT_ID_QUALIFIER_CODE = 'ProductIdQualifierCode',
  PRODUCT_ID = 'ProductId',
  QUANTITY_DISPENSED = 'QuantityDispensed',
  DAYS_SUPPLY = 'DaysSupply',
  DRUG_DOSAGE_UNITS_CODE = 'DrugDosageUnitsCode',
  TRANSMISSION_FORM_RX_ORIGIN_CODE = 'TransmissionFormRxOriginCode',
  PARTIAL_FILL_INDICATOR_CODE = 'PartialFillIndicatorCode',
  PHARMACIST_NPI = 'PharmacistNpi',
  PHARMACIST_LICENSE_NUMBER = 'PharmacistLicenseNumber',
  PAYMENT_TYPE_CODE = 'PaymentTypeCode',
  DATE_SOLD = 'DateSold',
  RXNORM_PRODUCT_QUALIFIER_CODE = 'RxNormProductQualifierCode',
  RXNORM_CODE = 'RxNormCode',
  ERX_REFERENCE_NUMBER = 'ErxReferenceNumber',
  ERX_ORDER_NUMBER = 'ErxOrderNumber',
  QUANTITY_PRESCRIBED = 'QuantityPrescribed',
  RX_SIG = 'RxSig',
  OPIOID_TREATMENT_TYPE_CODE = 'OpioidTreatmentTypeCode',
  DIAGNOSIS_CODE = 'DiagnosisCode',
  TIME_WRITTEN = 'TimeWritten',
  TIME_FILLED = 'TimeFilled',
  TIME_SOLD = 'TimeSold',
  TOTAL_QTY_REMAINING = 'TotalQtyRemaining',
  TOTAL_QTY_REMAINING_DRUG_DOSAGE_UNITS_CODE = 'TotalQtyRemainingDrugDosageUnitsCode',
  DISCOUNT_CARD = 'DiscountCard',
  ADDITIONAL_PAYMENT_TYPE_CODE = 'AdditionalPaymentTypeCode',
  ADDITIONAL_PAYMENT_TYPE_DISCOUNT_CARD = 'AdditionalPaymentTypeDiscountCard',
  DEA_SCHEDULE_CODE = 'DeaScheduleCode',
  PHARMACIST_LAST_NAME = 'PharmacistLastName',
  PHARMACIST_FIRST_NAME = 'PharmacistFirstName',
}

export enum PrescriberInformationFields {
  SEGMENT_IDENTIFIER = 'SegmentIdentifier',
  NPI = 'NPI',
  DEA = 'DEA',
  DEA_SUFFIX = 'DeaSuffix',
  LICENSE_NUMBER = 'LicenseNumber',
  LAST_NAME = 'LastName',
  FIRST_NAME = 'FirstName',
  MIDDLE_NAME = 'MiddleName',
  PHONE_NUMBER = 'PhoneNumber',
  XDEA_NUMBER = 'XdeaNumber',
  LICENSE_STATE = 'LicenseState',
  ADDRESS_1 = 'Address1',
  ADDRESS_2 = 'Address2',
  CITY = 'City',
  STATE = 'State',
  ZIP_CODE = 'ZipCode',
}

export enum AdditionalInformationReportingFields {
  SEGMENT_IDENTIFIER = 'SegmentIdentifier',
  RX_STATE = 'RxState',
  RX_NUMBER = 'RxNumber',
  PICKUP_ID_STATE = 'PickupIdState',
  PICKUP_ID_QUALIFIER_CODE = 'PickupIdQualifierCode',
  PICKUP_ID_NUMBER = 'PickupIdNumber',
  PICKUP_RELATIONSHIP_CODE = 'PickupRelationshipCode',
  PICKUP_LAST_NAME = 'PickupLastName',
  PICKUP_FIRST_NAME = 'PickupFirstName',
  PHARMACIST_LAST_NAME = 'PharmacistLastName',
  PHARMACIST_FIRST_NAME = 'PharmacistFirstName',
  DROP_OFF_QUALIFIER_CODE = 'DropOffQualifierCode',
  PICKUP_DOB = 'PickupDob',
  PICKUP_ADDRESS_1 = 'PickupAddress1',
  PICKUP_ADDRESS_2 = 'PickupAddress2',
  PICKUP_CITY = 'PickupCity',
  PICKUP_STATE = 'PickupState',
  PICKUP_ZIP_CODE = 'PickupZipCode',
  PICKUP_PHONE_NUMBER = 'PickupPhoneNumber',
  PICKUP_METHOD_DELIVERY_CODE = 'PickupMethodDeliveryCode',
  DROPOFF_ID_STATE = 'DropoffIdState',
  DROPOFF_ID_QUALIFIER_CODE = 'DropoffIdQualifierCode',
  DROPOFF_ID = 'DropoffId',
  DROPOFF_RELATIONSHIP_CODE = 'DropoffRelationshipCode',
  DROPOFF_LAST_NAME = 'DropoffLastName',
  DROPOFF_FIRST_NAME = 'DropoffFirstName',
  DROPOFF_DOB = 'DropoffDob',
  DROPOFF_ADDRESS_1 = 'DropoffAddress1',
  DROPOFF_ADDRESS_2 = 'DropoffAddress2',
  DROPOFF_CITY = 'DropoffCity',
  DROPOFF_STATE = 'DropoffState',
  DROPOFF_ZIP_CODE = 'DropoffZipCode',
  DROPOFF_PHONE_NUMBER = 'DropoffPhoneNumber',
}
