import type { PrescriberAddress } from '@truepill/tpos-types'
import AddressEntry from 'components/AddressEntry'
import Lozenge from 'components/Tiles/Lozenge'
import EditPracticeModal from 'modals/EditPracticeModal'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import { primaryColor, subduedColor } from 'styles/styleVariables'
import type { Prescriber } from 'types'
import { checkIfProvided, formatPhoneNumber } from 'utils'
import {
  StyledLink,
  PracticeName,
  PracticeAddress,
  PhoneNumber,
  FaxNumber,
  Spi,
  ServiceLevel,
  Status,
  PracticeAddressRow,
} from './StyledComponents'

type PracticeAddressEntryType = { address: PrescriberAddress; prescriber: Prescriber; isVet: boolean }

const PracticeAddressEntry = ({ address, prescriber, isVet }: PracticeAddressEntryType): JSX.Element => {
  const { showModal } = useModalContext()

  const handleRowClick = () => {
    showModal(() => <EditPracticeModal address={address} prescriber={prescriber} />)
  }

  return (
    <StyledLink>
      <PracticeAddressRow onClick={handleRowClick} isArchived={address.isArchived}>
        <PracticeName>{address?.businessName}</PracticeName>
        <PracticeAddress>
          <AddressEntry inline address={address} />
        </PracticeAddress>
        <PhoneNumber>{checkIfProvided(formatPhoneNumber(address?.phone))}</PhoneNumber>
        <FaxNumber>{checkIfProvided(formatPhoneNumber(address?.fax))}</FaxNumber>
        {isVet ? '' : <Spi>{checkIfProvided(address?.spi)}</Spi>}
        {isVet ? '' : <ServiceLevel>{(address?.serviceLevel || []).join(', ')}</ServiceLevel>}
        {isVet ? (
          ''
        ) : (
          <Status>
            <Lozenge backgroundColor={address.isActive ? primaryColor : subduedColor}>
              {address.isActive ? 'ACTIVE' : 'INACTIVE'}
            </Lozenge>
          </Status>
        )}
      </PracticeAddressRow>
    </StyledLink>
  )
}

export default PracticeAddressEntry
