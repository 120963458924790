import TopBanner from 'components/TopBanner'
import { ProblemList } from 'components/TopBanner/OrderTriageTopBanner'
import type { UserFirstLastName, Order } from 'types'

type OrderTopBannerProps = {
  orderId: Order['_id']
  disableOrder: boolean | undefined
  orderLockedBy: UserFirstLastName | undefined
}

const OrderTopBanner = ({ orderId, disableOrder, orderLockedBy }: OrderTopBannerProps): JSX.Element => {
  if (!orderId) {
    return <></>
  }

  const problemsWithOrder: string[] = []

  if (disableOrder) {
    problemsWithOrder.push(
      `This order is currently being worked on by ${orderLockedBy?.firstName} ${orderLockedBy?.lastName}`,
    )
  }

  return problemsWithOrder.length ? (
    <TopBanner message="There are problems with this order">
      <ProblemList>
        {problemsWithOrder.map((problem, i) => (
          <li key={i}>{problem}</li>
        ))}
      </ProblemList>
    </TopBanner>
  ) : (
    <></>
  )
}

export default OrderTopBanner
