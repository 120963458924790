import { useLayoutEffect, useState } from 'react'
import styled from 'styled-components'
import { primaryBackgroundColor } from 'styles/styleVariables'
import { StyledRXImageCell } from './RXTable'

const PopupIsOpenInfo = (): JSX.Element => {
  const [popUpTextBoxHeight, setPopUpTextBoxHeight] = useState(0)
  useLayoutEffect(() => {
    const reviewPatientHeight = document.getElementById('ReviewPatientBox')?.clientHeight ?? 0
    const reviewPrescriberHeight = document.getElementById('ReviewPrescriberBox')?.clientHeight ?? 0
    const reviewPrescriptionHeight = document.getElementById('ReviewPrescriptionBox')?.clientHeight ?? 0
    const popUpTextBoxHeight = reviewPatientHeight + reviewPrescriberHeight + reviewPrescriptionHeight - 50
    setPopUpTextBoxHeight(popUpTextBoxHeight)
  }, [])

  return (
    <StyledRXImageCell noColorBackground closeOffTopBorder>
      <PopupIsOpenInfoBox id="PopupIsOpenBox" height={popUpTextBoxHeight}>
        <PopupIsOpenMessage>Rx image open in another window</PopupIsOpenMessage>
      </PopupIsOpenInfoBox>
    </StyledRXImageCell>
  )
}

export default PopupIsOpenInfo

const PopupIsOpenInfoBox = styled.div<{ height: number }>`
  background-color: ${primaryBackgroundColor};
  position: absolute;
  height: 75vh;
  width: 30.6vw;
  z-index: 1;
  margin-left: 1px;
  margin-right: 1px;
  display: flex;
  align-items: flex-start;
  height: ${({ height }) => height && `${height}px`};
`

const PopupIsOpenMessage = styled.div`
  margin: 0.625rem;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
`
