import { useEffect, useCallback } from 'react'
import { useParams, useLazyQuery } from '@truepill/tpos-react-router'
import { GET_PATIENT } from 'gql'
import { useFormData } from 'hooks/useFormData'
import useUpdatePatient from 'hooks/useUpdatePatient'
import type { Patient, Address } from 'types'
import { updatePatient as subscriptionUpdate } from 'utils/subscribeToolkit'
import type { LoadingError } from './types'

export interface PatientHookValues extends LoadingError {
  patient: Patient
  savePatientChanges: () => Promise<void>
}

export default function usePatient({
  patientId: patientIdProp,
  patient: patientProp,
  loading: upstreamLoading,
  error: upstreamError,
  guestPatient,
}: { patientId?: string; patient?: Patient; guestPatient?: any } & LoadingError = {}): PatientHookValues {
  const patientId =
    useParams<{
      patientId?: string
    }>().patientId || patientIdProp
  const [getPatient, { data, loading, error, called, client, subscribeToMore }] = useLazyQuery<{ getPatient: Patient }>(
    GET_PATIENT,
    {
      variables: { patientId },
    },
  )

  if (patientProp) {
    client?.writeQuery({
      query: GET_PATIENT,
      variables: { patientId: patientProp._id },
      data: { getPatient: patientProp },
    })
  }

  const [updatePatient] = useUpdatePatient()

  const {
    state: { formData },
    actions: { updateFormData },
    changes: { getPatientChanges },
  } = useFormData()

  const patient = patientProp || (data?.getPatient as Patient)

  useEffect(() => {
    if (patientId) return subscriptionUpdate(subscribeToMore, { patientId })
  }, [subscribeToMore, patientId])

  useEffect(() => {
    if (patientId && !patientProp) {
      getPatient()
    }
  }, [called, patientProp, getPatient, guestPatient, patientId, subscribeToMore])

  useEffect(() => {
    if (patient) {
      updateFormData({ patient: { $set: patient } })
    }
  }, [patient, updateFormData, subscribeToMore])

  const savePatientChanges = useCallback(async () => {
    const changes = getPatientChanges(patient) as any
    if (Object.keys(changes).length) {
      if (changes.address) {
        changes.address = {
          home: {
            ...changes.address.home,
          } as Address,
        }
      }

      await updatePatient({
        variables: {
          patientId: patient?._id,
          ...changes,
        },
      })
    }
  }, [patient, getPatientChanges, updatePatient, formData.patient])

  if (guestPatient) {
    return {
      patient: {} as Patient,
      savePatientChanges,
    }
  }

  if (loading || error || upstreamError || upstreamLoading) {
    return {
      loading: loading || upstreamLoading,
      error: error || upstreamError,
    } as PatientHookValues
  }

  return {
    patient,
    savePatientChanges,
  }
}
