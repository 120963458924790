import { useState } from 'react'
import { TextField } from '@truepill/react-capsule'
import { useHistory, useLocation } from '@truepill/tpos-react-router'
import { SpeciesOptions } from '@truepill/tpos-types'
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg'
import BreadCrumb from 'components/BreadCrumb'
import IconWrapper from 'components/IconWrapper'
import { ActionBar, PageContainer } from 'components/PageStructure'
import PatientsTable from 'components/PatientsTable'
import SpeciesSelect from 'components/SpeciesSelect'
import useSearchPatients from 'hooks/useSearchPatients'
import useSetPageTitle from 'hooks/useSetPageTitle'
import useStateWithDebouncedValue from 'hooks/useStateWithDebouncedValue'
import { Search } from 'react-feather'
import { goToCreatePatient } from 'routes'
import { Content, NewPatientControls, SearchControls, StyledActionButton, Title } from './StyledComponents'

const PatientsPage = (): JSX.Element => {
  const history = useHistory()
  const { search } = useLocation()
  const [speciesFilterValue, setSpeciesFilterValue] = useState<SpeciesOptions | undefined>(SpeciesOptions.All)
  const [searchTerm, debouncedSearchTerm, setSearchTerm] = useStateWithDebouncedValue('')
  const { data, error, loading } = useSearchPatients(debouncedSearchTerm, undefined, speciesFilterValue)

  useSetPageTitle('Patients')

  return (
    <PageContainer>
      <BreadCrumb />
      <Title>Patients</Title>
      <ActionBar>
        <SearchControls>
          <div style={{ width: '25rem' }}>
            <TextField
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              type="search"
              size="sm"
              startAdornment={Search}
              placeholder="Search patients by name, DOB, zip code..."
              value={searchTerm}
              onChange={({ currentTarget: { value } }) => setSearchTerm(value)}
              width="15rem"
            />
          </div>
          <div style={{ width: '15rem' }}>
            <SpeciesSelect
              value={speciesFilterValue ?? ''}
              onChange={val => setSpeciesFilterValue(val as SpeciesOptions | undefined)}
            />
          </div>
        </SearchControls>
        <NewPatientControls>
          <StyledActionButton
            icon={
              <IconWrapper>
                <PlusIcon fill="black" />
              </IconWrapper>
            }
            hotKey="n"
            onClick={() => history.push(goToCreatePatient({ search }))}
            label="New patient"
          />
        </NewPatientControls>
      </ActionBar>
      <Content>
        {error ? (
          <p>Failed to load patients list {JSON.stringify(error)}</p>
        ) : loading || !data ? (
          <PatientsTable loading={loading} patients={[]} />
        ) : (
          <PatientsTable
            searchTermGiven={debouncedSearchTerm !== ''}
            showGuardian={speciesFilterValue === SpeciesOptions.Pet}
            patients={data.searchPatients || []}
          />
        )}
      </Content>
    </PageContainer>
  )
}

export default PatientsPage
