import { Chip } from '@truepill/react-capsule'
import { Bell } from 'react-feather'

const BellIcon = () => {
  return (
    <Chip state="warning">
      <Bell aria-hidden />
    </Chip>
  )
}

export default BellIcon
