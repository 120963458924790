import type { Dispatch, SetStateAction } from 'react'
import { useState } from 'react'
import { useMutation } from '@truepill/tpos-react-router'
import { ReactComponent as CancelIcon } from 'assets/icons/cancel.svg'
import { SaveButton } from 'components/ActionButton'
import IconWrapper from 'components/IconWrapper'
import { ModalWrapper, ModalHeader, InputContainer, ButtonsContainer } from 'components/Modal'
import { CancelButton } from 'components/PageStructure'
import { REVERSE_ACTIVE_CLAIMS } from 'gql'
import { TextArea } from 'grommet'
import useErrorToast from 'hooks/toast/useErrorToast'
import { useToastContext } from 'providers/Overlays/ToastProvider'
import styled from 'styled-components'
import { bodyPrimaryColor } from 'styles/styleVariables'
import type { Order, Prescription, RXFillRequest } from 'types'
import { type Fill, ToastType } from 'types'

interface ReverseClaimDocumentationModalProps {
  fill: Fill
  order: Order
  rxFillRequest: RXFillRequest
  prescription: Prescription
  showReverseClaimsModal: boolean
  setShowClaimsSummaryModal: Dispatch<SetStateAction<boolean>>
  setShowReverseClaimsDocumentationModal: Dispatch<SetStateAction<boolean>>
  dismissModal: () => void
}

const WrappedReverseClaimDocumentationModal = (props: ReverseClaimDocumentationModalProps): JSX.Element => {
  return <ReverseClaimDocumentationModal {...props} />
}

const ReverseClaimDocumentationModal = (props: ReverseClaimDocumentationModalProps) => {
  const {
    fill,
    showReverseClaimsModal,
    setShowClaimsSummaryModal,
    setShowReverseClaimsDocumentationModal,
    dismissModal,
  } = props

  const showErrorToast = useErrorToast()
  const { showToast } = useToastContext()
  const [claimReversalReason, setclaimReversalReason] = useState('')

  const [reverseActiveClaims, { loading, error }] = useMutation(REVERSE_ACTIVE_CLAIMS, {
    variables: { fillId: fill?._id, reason: claimReversalReason },
    refetchQueries: ['getBasicOrder'],
  })

  const disableSave = loading || !claimReversalReason || !claimReversalReason.length
  const disableCancel = loading

  return (
    <>
      {showReverseClaimsModal && (
        <ModalWrapper id="ReverseClaimDocumentationModal">
          <ModalHeader>
            <IconWrapper data-testid="close">
              <CancelIcon role="img" fill={bodyPrimaryColor} />
            </IconWrapper>
            <h2>Reverse Claim Reason</h2>
          </ModalHeader>
          <ConstrainedInputContainer>
            <TextArea
              data-testid="message"
              onChange={event => setclaimReversalReason(event.target.value)}
              placeholder={'Type the reason for claim reversal...'}
              value={claimReversalReason}
              resize={'vertical'}
            />
          </ConstrainedInputContainer>
          <ButtonsContainer>
            <CancelButton
              label="Cancel"
              disabled={disableCancel}
              onClick={() => {
                setShowReverseClaimsDocumentationModal(false)
                setShowClaimsSummaryModal(true)
              }}
            />
            <SaveButton
              isModal
              disabled={disableSave}
              label={`Revers${loading ? 'ing' : 'e'}`}
              onClick={async () => {
                try {
                  await reverseActiveClaims()
                  setShowReverseClaimsDocumentationModal(false)
                  dismissModal()
                } catch (e) {
                  showErrorToast('Failed to reverse claims: ' + e.message)
                }

                if (!error) showToast(`Successfully reversed claims for fill: ${fill?._id}`, ToastType.success)
              }}
            />
          </ButtonsContainer>
        </ModalWrapper>
      )}
    </>
  )
}

const ConstrainedInputContainer = styled(InputContainer)`
  max-width: 31rem;
`

export default WrappedReverseClaimDocumentationModal
