import type { MouseEvent as ReactMouseEvent } from 'react'
import { Tooltip } from '@truepill/react-capsule'
import { getLastCompletedFill } from '@truepill/tpos-data-util'
import { Link, useLocation, useQuery } from '@truepill/tpos-react-router'
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg'
import { ReactComponent as TransferIcon } from 'assets/icons/transfer.svg'
import LoadingSpinner from 'components/Loading'
import { FilledHeadingStyle, NoResultsEntry } from 'components/PageStructure'
import Lozenge from 'components/Tiles/Lozenge'
import { LIST_PRESCRIPTIONS_INCLUDING_CONTROLLED_SUBSTANCES } from 'gql'
import moment from 'moment'
import { goToViewPharmacyPrescription } from 'routes'
import styled, { css } from 'styled-components'
import EllipsisTruncate from 'styles/EllipsisTruncate'
import {
  bodyPrimaryColor,
  subduedColor,
  contrastColor,
  contrastBackgroundColor,
  primaryColorLight,
} from 'styles/styleVariables'
import { TransferType } from 'types'
import type { Fill, Patient, Prescription, Transfer } from 'types'
import { removeCamelCase, getCurrentFill } from 'utils'
import { getDisplayedDrugName } from 'utils/getDisplayedDrugName'
import { twoDecimalOrLess } from 'utils/numberFormatter'
import FormattedFillDate from './FormattedFillDate'

type MedicalHistoryProps = {
  patientIds: Patient['_id']
  searchTerm?: string
  fillId?: Fill['_id'] // Include when we need to compare duplicate medications against a fill
}

type MedicalHistoryEntryProps = {
  prescription: Prescription
  isDuplicatedControlledMed: boolean
}

const MedicalHistory = ({ patientIds, fillId, searchTerm = '' }: MedicalHistoryProps): JSX.Element => {
  const { data, loading, error } = useQuery(LIST_PRESCRIPTIONS_INCLUDING_CONTROLLED_SUBSTANCES, {
    variables: { patientIds: [patientIds] },
  })

  if (loading) {
    return (
      <MainLoadingContainer>
        <TitleRow />
        <LoadingSpinnerContainer>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      </MainLoadingContainer>
    )
  }

  if (error) {
    return <p>Failed to load Medical History {JSON.stringify(error)} </p>
  }

  const prescriptions: Prescription[] = data.getPrescriptions.prescriptions || []
  let fillGpi = '',
    fillRx: Prescription | undefined

  // When there is a fillId we want to mark some of the prescriptions depending on the gpis
  if (fillId) {
    fillRx = prescriptions.find(p => p.fills.some(f => f._id.toString() === fillId))
    fillGpi = fillRx?.fills.find(fill => fill._id === fillId)?.gpi ?? ''
  }

  const filteredPrescriptions = prescriptions.filter(prescription => {
    const rxNumberMatch = prescription.rxNumber.toString().includes(searchTerm)
    const customerNameMatch = prescription.customer?.name.toLowerCase().includes(searchTerm.toLowerCase())
    const drugNameMatch = prescription.name.toLowerCase().includes(searchTerm.toLowerCase())
    return rxNumberMatch || customerNameMatch || drugNameMatch
  })

  return (
    <MedicalHistoryContainer role="table">
      <TitleRow />
      {filteredPrescriptions
        // If there is a fillId we don't show the fill's prescription entry
        .filter(p => p._id !== fillRx?._id)
        .map(prescription => (
          <MedicalHistoryEntry
            key={prescription._id}
            prescription={prescription}
            isDuplicatedControlledMed={
              prescription.gpi && fillGpi ? prescription.gpi === fillGpi && prescription.isControlledSubstance : false
            }
          />
        ))}
      {prescriptions.length === 0 && <NoResultsEntry> No results </NoResultsEntry>}
    </MedicalHistoryContainer>
  )
}

const TitleRow = (): JSX.Element => {
  return (
    <TitleMedicalHistoryRow role="row" data-testid="headers">
      <RXNumber role="columnheader">Rx Number</RXNumber>
      <Customer role="columnheader">Customer</Customer>
      <FillNumber role="columnheader" aria-label="Fill Number">
        Fill #
      </FillNumber>
      <Medication role="columnheader">Medication</Medication>
      <Quantity role="columnheader" aria-label="Quantity">
        Qty
      </Quantity>
      <DaysSupply role="columnheader" aria-label="Day Supply">
        DS
      </DaysSupply>
      <Directions role="columnheader">Instructions</Directions>
      <RemainingQuantity role="columnheader" aria-label="Quantity Left">
        Qty Left
      </RemainingQuantity>
      <RemainingRefills role="columnheader" aria-label="Refills Left">
        RF Left
      </RemainingRefills>
      <Written role="columnheader" aria-label="Written Date">
        Written
      </Written>
      <FillDate role="columnheader" aria-label="Fill Date">
        Fill Date
      </FillDate>
      <Prescriber role="columnheader">Prescriber</Prescriber>
      <Status role="columnheader">Status</Status>
      <StyledTransfer role="columnheader"></StyledTransfer>
    </TitleMedicalHistoryRow>
  )
}

export const MedicalHistoryEntry = (props: MedicalHistoryEntryProps): JSX.Element => {
  const { prescription, isDuplicatedControlledMed } = props
  const { search } = useLocation()

  const writtenDate = moment(prescription.writtenDate).format('MM/DD/YYYY')
  const currentFill = getCurrentFill(prescription)
  const lastFill = getLastCompletedFill(prescription.fills)
  const status = removeCamelCase(prescription.status)
  const NoneProvidedIcon = <span aria-hidden>&mdash;</span>
  const transferOut = prescription?.transfers?.filter(
    transfer => transfer?.transferType === TransferType.TRANSFER_OUT,
  )[0]
  const displayedDrugName = getDisplayedDrugName(
    prescription.name,
    prescription.strength?.value,
    prescription.strength?.unit,
    prescription.strength?.form,
  )

  return (
    <StyledLink
      to={goToViewPharmacyPrescription({ prescriptionId: prescription._id, search })}
      role="rowgroup"
      data-rxid={prescription._id}
      isControlledSubstance={prescription.isControlledSubstance && !isDuplicatedControlledMed}
      isDuplicatedControlledMed={isDuplicatedControlledMed}
    >
      <MedicalHistoryRow role="row" data-rxid={prescription._id}>
        <RXNumber>{prescription.rxNumber}</RXNumber>
        <Customer>{prescription.customer?.name ?? NoneProvidedIcon}</Customer>
        <FillNumber>{currentFill?.fillNumber ?? 0}</FillNumber>
        <Medication>
          <MedicationName>{displayedDrugName}</MedicationName>
          {(prescription.isControlledSubstance || isDuplicatedControlledMed) && (
            <Tooltip
              label={
                isDuplicatedControlledMed
                  ? 'Patient has another prescription for same controlled substance, dose, and form.'
                  : 'This is a controlled substance.'
              }
              variant="white"
              css={{
                zIndex: '999',
              }}
            >
              <IconContainer>
                <InfoIcon fill="#AF5304" />
              </IconContainer>
            </Tooltip>
          )}
        </Medication>
        <Quantity>{prescription.quantity === '.' ? '.' : twoDecimalOrLess(prescription.quantity)}</Quantity>
        <DaysSupply>{prescription.daysSupply ?? NoneProvidedIcon}</DaysSupply>
        <Directions>{prescription.directions}</Directions>
        <RemainingQuantity>{twoDecimalOrLess(prescription.quantityRemaining ?? 0)}</RemainingQuantity>
        <RemainingRefills>{prescription.refillsRemaining ?? 0}</RemainingRefills>
        <Written>{writtenDate}</Written>
        <FillDate>
          <FormattedFillDate fill={lastFill as Fill} />
        </FillDate>
        <Prescriber>
          {prescription.prescriber.firstName} {prescription.prescriber.lastName}
        </Prescriber>
        <Status>
          <StatusLozenge>{status}</StatusLozenge>
        </Status>
        <StyledTransfer>
          {transferOut && (
            <TransferIcon
              onClick={ev => {
                openTransferOut(ev, transferOut)
              }}
            />
          )}
        </StyledTransfer>
      </MedicalHistoryRow>
    </StyledLink>
  )
}

export const openTransferOut = (event: ReactMouseEvent<SVGSVGElement, MouseEvent>, transferOut: Transfer): void => {
  window.open(transferOut?.transferImage?.s3SignedUrl, '_blank')
  event.stopPropagation()
}

const StatusLozenge = styled(Lozenge)`
  color: ${bodyPrimaryColor};
  background-color: ${subduedColor};
  margin-left: 0;
`

const MedicalHistoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: stretch;
  :last-child {
    border-bottom: 0.25rem solid ${contrastColor};
    border-radius: 0.25rem;
  }
`

const StyledLink = styled(Link)<{
  isControlledSubstance: boolean
  isDuplicatedControlledMed: boolean
}>`
  :hover {
    background-color: ${primaryColorLight};
    :nth-of-type(2n + 1) {
      background-color: ${primaryColorLight};
    }
  }
  padding: 0.1rem 0.625rem;
  :nth-of-type(1n) {
    padding-bottom: 0.625rem;
  }
  :nth-of-type(1n - 1) {
    padding-top: 0.625rem;
  }
  :nth-of-type(2n + 1) {
    background-color: ${contrastBackgroundColor};
  }
  ${({ isControlledSubstance }) =>
    isControlledSubstance && `background-color: #FFF1F1 !important; color: #D42122 !important;`}
  ${({ isDuplicatedControlledMed }) =>
    isDuplicatedControlledMed && `background-color: #FFF6E8 !important; color: #B75500 !important;`}
`

const TrucateTextPosition = css`
  display: block;
`

const MedicalHistoryRow = styled.ul`
  display: grid;
  grid-column: content;
  grid-template-rows: [content] [fillHistory] auto;
  grid-template-columns:
    [rxNumber] 7rem
    [customer] 9rem
    [fillNumber] 4rem
    [medication] 2fr
    [qty] 0.55fr
    [ds] 0.55fr
    [directions] 2.4fr
    [qtyLeft] 5rem
    [refillsLeft] 5rem
    [written] 6.5rem
    [fill] 7rem
    [prescriber] 1.5fr
    [status] minmax(7rem, 8rem);
    [transfer] 1rem;
  padding-left: 0rem;
  padding-right: 0rem;
`

const TitleMedicalHistoryRow = styled(MedicalHistoryRow)`
  ${FilledHeadingStyle}
  border-radius: 0.25rem 0.25rem 0rem 0rem;
`

const HistoryCell = styled.li.attrs(props => {
  return { role: props.role ?? 'cell' }
})`
  grid-row: 1;
  ${EllipsisTruncate}
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 0.3125rem;
  margin-right: 0.3125rem;
`

const IconContainer = styled.div`
  width: 22px;
`

const RXNumber = styled(HistoryCell).attrs(props => ({
  'data-test-column': 'rx-number',
}))`
  grid-column: rxNumber;
`
const FillNumber = styled(HistoryCell).attrs({
  'data-test-column': 'fill-number',
})`
  grid-column: fillNumber;
`
const Medication = styled(HistoryCell).attrs({
  'data-test-column': 'medication',
})`
  grid-column: medication;
`
const MedicationName = styled(HistoryCell)`
  ${TrucateTextPosition}
`
const Quantity = styled(HistoryCell).attrs({
  'data-test-column': 'quantity',
})`
  grid-column: qty;
`
const Directions = styled(HistoryCell).attrs({
  'data-test-column': 'directions',
})`
  grid-column: directions;
  ${TrucateTextPosition}
`

const DaysSupply = styled(HistoryCell).attrs({
  'data-test-column': 'days-supply',
})`
  grid-column: ds;
`

const RemainingQuantity = styled(HistoryCell).attrs({
  'data-test-column': 'quantity-remaining',
})`
  grid-column: qtyLeft;
`

const RemainingRefills = styled(HistoryCell).attrs({
  'data-test-column': 'refills-remaining',
})`
  grid-column: refillsLeft;
`

const Written = styled(HistoryCell).attrs({
  'data-test-column': 'written-date',
})`
  grid-column: written;
`

const Prescriber = styled(HistoryCell).attrs({
  'data-test-column': 'prescriber',
})`
  grid-column: prescriber;
  ${TrucateTextPosition}
`

const Customer = styled(HistoryCell).attrs({
  'data-test-column': 'customer',
})`
  grid-column: customer;
  ${TrucateTextPosition}
`

const FillDate = styled(HistoryCell).attrs({
  'data-test-column': 'fill-date',
})`
  grid-column: fill;
`

const Status = styled(HistoryCell).attrs({
  'data-test-column': 'status',
})`
  grid-column: status;
`
const StyledTransfer = styled(HistoryCell).attrs({
  'data-test-column': 'transfer',
})`
  grid-column: transfer;
`

const LoadingSpinnerContainer = styled.div`
  display: flex;
  width: 100%;
  padding-top: 0.8rem;
  justify-content: center;
  svg {
    height: 50px;
  }
`

const MainLoadingContainer = styled.div`
  width: 100%;
`

export default MedicalHistory
