import { useState } from 'react'
import { Button, Spacer } from '@truepill/react-capsule'
import { useMutation, useQuery } from '@truepill/tpos-react-router'
import { ModalWrapper, ModalHeader, ButtonsContainer } from 'components/Modal'
import { NumericInput } from 'components/NumericInput'
import Select from 'components/Select'
import { CREATE_COPAY_REQUEST, LIST_LOCATIONS } from 'gql'
import useErrorToast from 'hooks/toast/useErrorToast'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import { usePlusClient } from 'providers/VisionRouter'
import { XCircle, CheckCircle, Trash2 } from 'react-feather'
import styled from 'styled-components'
import type { TPOSLocation } from 'types'

interface CopayRequestValuesProps {
  rxNumbers: (number | undefined)[]
  setRxNumbers: (arg0: (number | undefined)[]) => void
  index: number
}

type locationOption = {
  label: string
  value: string
}

const RxNumbersDisplay = (props: CopayRequestValuesProps) => {
  const { index, rxNumbers, setRxNumbers } = props

  return (
    <RxNumberContainer>
      <RxNumberHeader>Rx #{index + 1}</RxNumberHeader>
      <RxNumber>
        <RxNumberSelect>
          <NumericInput
            value={rxNumbers[index]}
            onChange={val => updateRxNumber(val, index, rxNumbers, setRxNumbers)}
            placeholder="Type Rx Number..."
          />
        </RxNumberSelect>
        <RxNumberDelete>
          <Button
            size="2xs"
            variant="primary-outline"
            data-testid="deleteRx"
            style={{ display: 'flex', alignItems: 'center', marginLeft: '0.5em' }}
            onClick={() => deleteRxNumber(index, rxNumbers, setRxNumbers)}
          >
            <Trash2 aria-hidden />
          </Button>
        </RxNumberDelete>
      </RxNumber>
    </RxNumberContainer>
  )
}

const addNewRxNumber = (rxNumbers: (number | undefined)[], setRxNumbers: (arg0: (number | undefined)[]) => void) => {
  const newRxNumbers = [...rxNumbers]
  newRxNumbers.push(undefined)
  setRxNumbers(newRxNumbers)
}

const deleteRxNumber = (
  index: number,
  rxNumbers: (number | undefined)[],
  setRxNumbers: (arg0: (number | undefined)[]) => void,
) => {
  const newRxNumbers = [...rxNumbers]
  newRxNumbers.splice(index, 1)
  setRxNumbers(newRxNumbers)
}

const updateRxNumber = (
  val: number | undefined,
  index: number,
  rxNumbers: (number | undefined)[],
  setRxNumbers: (arg0: (number | undefined)[]) => void,
) => {
  const newRxNumbers = [...rxNumbers]
  newRxNumbers[index] = val
  setRxNumbers(newRxNumbers)
}

const CreateCopayRequestModal = () => {
  const { dismissModal } = useModalContext()
  const showSuccess = useSuccessToast()
  const showError = useErrorToast()
  const {
    currentLocation: { queryMap },
  } = usePlusClient()

  const [createCopayRequest] = useMutation(CREATE_COPAY_REQUEST, {
    onCompleted(data) {
      dismissModal()
      showSuccess('Successfully created copay request!')
    },
    onError(err) {
      showError('Issue creating copay request: ' + err.message)
    },
    refetchQueries: ['getFulfillmentCopays'],
  })

  const { data: locationData } = useQuery(LIST_LOCATIONS, { variables: {} })
  const locations = locationData?.getLocations
  const sortedLocationOptions = locations
    ? locations
        .map((i: TPOSLocation) => ({ label: i.name, value: i._id.toString() }))
        .sort((a: locationOption, b: locationOption) => (a.label > b.label ? 1 : -1))
    : []

  const [rxNumbers, setRxNumbers] = useState<(number | undefined)[]>([])
  const [location, setLocation] = useState(queryMap.locationId)
  return (
    <ModalWrapper id="CreateCopayRequestModal" styles={[{ 'overflow-x': 'hidden' }, { 'overflow-y': 'scroll' }]}>
      <ModalHeader>
        <h1>Generate Fixture Copay</h1>
      </ModalHeader>
      <CopayRequestWrapper>
        <LocationHeader>Location</LocationHeader>
        <StyledSelect
          preventScrolling={false}
          data-testid="location"
          multiple={false}
          placeholder={'Select...'}
          options={sortedLocationOptions}
          onChange={([option]) => {
            setLocation(option.value)
          }}
          value={sortedLocationOptions.find((locationOption: locationOption) => locationOption.value === location)}
          disableClear
        />
        {rxNumbers.map((rxNumber, index) => {
          return <RxNumbersDisplay rxNumbers={rxNumbers} setRxNumbers={setRxNumbers} index={index} />
        })}
        <AddNewRxWrapper>
          <Button
            size="sm"
            variant="primary-outline"
            data-testid="addNewRx"
            style={{ display: 'flex', alignItems: 'center', marginLeft: '0.5em' }}
            onClick={() => addNewRxNumber(rxNumbers, setRxNumbers)}
          >
            <Spacer size="xs" />
            Add new Rx
          </Button>
        </AddNewRxWrapper>
      </CopayRequestWrapper>
      <ButtonsContainer>
        <Button
          size="sm"
          variant="primary-outline"
          data-testid="cancel"
          style={{ display: 'flex', alignItems: 'center', marginLeft: '0.5em' }}
          onClick={() => dismissModal()}
        >
          <XCircle aria-hidden />
          <Spacer size="xs" />
          Cancel
        </Button>
        <Button
          size="sm"
          variant="primary"
          style={{ display: 'flex', alignItems: 'center', marginLeft: '0.5em' }}
          data-testid="submit"
          onClick={async () => {
            const variables = {
              variables: {
                rxNumbers,
                locationId: location,
              },
            }

            await createCopayRequest(variables)
          }}
        >
          <CheckCircle aria-hidden />
          <Spacer size="xs" />
          Submit
        </Button>
      </ButtonsContainer>
    </ModalWrapper>
  )
}

const RxNumberContainer = styled.div`
  padding-top: 1.3rem;
`
const RxNumberHeader = styled.div`
  font-weight: bold;
  font-size: 1.3rem;
  padding-bottom: 0.3rem;
`
const RxNumber = styled.div`
  display: flex;
`
const RxNumberSelect = styled.div`
  padding-right: 0.7rem;
`
const RxNumberDelete = styled.div``

const CopayRequestWrapper = styled.div`
  padding-top: 1rem;
`

const LocationHeader = styled.div`
  font-weight: bold;
`

const AddNewRxWrapper = styled.div`
  float: right;
  padding-top: 1rem;
`

const StyledSelect = styled(Select)`
  width: 193px;
`
export default CreateCopayRequestModal
