import getErrorMessage from './getErrorMessage'
import isTypeofObject from './isTypeofObject'

const getApolloErrorMessage = (err: unknown, title?: string): string => {
  const errorMessages: string[] = title ? [title] : []

  if (isTypeofObject(err)) {
    if (isTypeofObject(err.graphQLErrors) && Array.isArray(err.graphQLErrors)) {
      errorMessages.push(...err.graphQLErrors.map(e => getErrorMessage(e)))
    }
  }

  if (errorMessages.length === (title ? 1 : 0)) {
    errorMessages.push(getErrorMessage(err))
  }

  return errorMessages.join('\n')
}

export default getApolloErrorMessage
