import { Select } from '@truepill/react-capsule'
import { AddressFormLine, FormItem } from 'components/ColumnForm'
import CustomizedTextField from 'components/CustomizedTextField'
import { StyledSelect, StyledTextInput } from 'components/RXTable'
import useAddressFormValidation from 'hooks/useAddressFormValidation'
import styled from 'styled-components'
import { primaryBackgroundColor } from 'styles/styleVariables'
import type { Address } from 'types'
import { formatZip, states as USStates } from 'utils'

const USStatesOptions = ['(~) Clear', ...USStates]

type AddressFormProps = Partial<Address> & {
  onChange: (key: keyof Address, value: string) => void
  className?: string
  withCapsule?: boolean
  optional?: boolean
}

const AddressForm = ({
  street1,
  street2,
  city,
  state,
  zip,
  onChange,
  className,
  withCapsule,
  optional,
}: AddressFormProps): JSX.Element => {
  const { errors } = useAddressFormValidation(
    {
      street1,
      street2,
      city,
      state,
      zip,
    },
    optional,
  )

  if (withCapsule) {
    return (
      <>
        <FormItem data-test-row="street-address">
          <CustomizedTextField
            data-testid="street1"
            label="Address"
            size="sm"
            value={street1}
            state={errors.street1 ? 'error' : 'default'}
            helperText={errors.street1}
            onChange={e => onChange('street1', e.target.value)}
          />
          <StreetSpacer />
          <CustomizedTextField
            data-testid="street2"
            label=""
            size="sm"
            value={street2}
            onChange={e => onChange('street2', e.target.value)}
            placeholder={'Suite, building, etc...'}
          />
        </FormItem>
        <AddressFormLine data-test-row="city-address">
          <FormItem data-test-row="city">
            <CustomizedTextField
              data-testid="city"
              label="City"
              size="sm"
              value={city}
              state={errors.city ? 'error' : 'default'}
              helperText={errors.city}
              onChange={e => onChange('city', e.target.value)}
            />
          </FormItem>
          <FormItem data-test-row="state">
            <StateSelectWrapper>
              <Select
                data-testid="state"
                label="State"
                variant="small"
                value={state || ''}
                state={errors.state ? 'error' : 'default'}
                helperText={errors.state}
                options={USStatesOptions}
                onChange={value => {
                  onChange('state', (value === '(~) Clear' || !value ? '' : value) as string)
                }}
              />
            </StateSelectWrapper>
          </FormItem>
          <FormItem data-test-row="zip">
            <CustomizedTextField
              data-testid="zip"
              label="Zip code"
              size="sm"
              value={zip}
              state={errors.zip ? 'error' : 'default'}
              helperText={errors.zip}
              onChange={e => {
                const newZip = formatZip(e.target.value)
                onChange('zip', newZip)
              }}
            />
          </FormItem>
        </AddressFormLine>
      </>
    )
  }
  return (
    <StyledAddressForm className={className} data-testid="AddressForm">
      <AddressTextInput
        data-private
        data-testid="street1"
        placeholder="Street address…"
        value={street1 ?? ''}
        onChange={e => onChange('street1', e.target.value)}
      />
      <AddressTextInput
        data-private
        data-testid="street2"
        placeholder="Suite, building, etc…"
        value={street2 ?? ''}
        onChange={e => onChange('street2', e.target.value)}
      />
      <AddressRow>
        <AddressTextInput
          data-private
          data-testid="city"
          placeholder="City"
          value={city ?? ''}
          onChange={e => onChange('city', e.target.value)}
        />
        <StateSelecter
          data-testid="state"
          value={state ?? ''}
          options={USStates}
          placeholder="State"
          onChange={([option]) => onChange('state', (option ? option.value : '') as string)}
        />
        <AddressTextInput
          data-private
          data-testid="zip"
          value={zip ?? ''}
          placeholder="Zip"
          onChange={e => onChange('zip', e.target.value)}
        />
      </AddressRow>
    </StyledAddressForm>
  )
}

const StateSelecter = styled(StyledSelect)`
  height: 2rem;
  width: 11rem;
  flex: unset;
  font-weight: 400;
`

const AddressTextInput = styled(StyledTextInput)`
  background-color: ${primaryBackgroundColor};
`

const AddressRow = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  > button {
    margin-left: 0.3125rem;
    margin-right: 0.3125rem;
  }
  :last-child {
    input {
      margin-right: 0rem;
    }
  }
`
const StyledAddressForm = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  margin-right: 0.625rem;
  > * {
    margin-top: 0.3125rem;
  }
`

const SelectWrapper = styled.div`
  button {
    height: 48px;
  }
  .capsule.spacer {
    height: 4px;
    min-height: 4px;
  }
`

const StateSelectWrapper = styled(SelectWrapper)`
  button {
    width: 6rem;
  }
`

const StreetSpacer = styled.div`
  margin: 5px;
`

export default AddressForm
