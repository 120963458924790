import { useState, useCallback } from 'react'
import { useMutation } from '@truepill/tpos-react-router'
import { ReactComponent as CancelIcon } from 'assets/icons/cancel.svg'
import { SaveButton } from 'components/ActionButton'
import DatePicker from 'components/DatePicker'
import DURCard from 'components/DURCard'
import IconWrapper from 'components/IconWrapper'
import { ModalWrapper, ModalHeader, InputContainer, ButtonsContainer } from 'components/Modal'
import { CancelButton } from 'components/PageStructure'
import { StyledSelect } from 'components/RXTable'
import { REJECT_RX_FILL_REQUEST, REJECT_COPAY_REQUEST_FILL, TRACK_CONTROLLED_SUBSTANCE_REJECTIONS } from 'gql'
import { TextArea } from 'grommet'
import useErrorToast from 'hooks/toast/useErrorToast'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import moment from 'moment'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import SelectionProvider, { useSelectionContext } from 'providers/SelectionProvider'
import styled from 'styled-components'
import { bodyPrimaryColor } from 'styles/styleVariables'
import type { Fill, DUR, QueueItem, CopayRequest, Order, CopayRequestFill } from 'types'
import { OrderRejectionReason, CopayFillRejectionReason, AltCopayFillRejectionReason } from 'types'
import { keyFromDur } from 'utils'

interface RejectModalProps {
  fillId: Fill['_id']
  durs?: DUR[]
  confirmationCallback?: () => void
  onDismiss?: () => void
  description?: string
  showDescription?: boolean
  isControlledSubstance?: boolean
}

interface Utils {
  dismissModal: () => void
  showSuccessToast: (msg: string) => void
  showErrorToast: (msg: string) => void
}

interface RejectCopayRequestFillProps extends RejectModalProps, Utils {
  copayRequestId: CopayRequest['_id']
}

interface RejectOrderProps extends RejectModalProps, Utils {
  orderId: Order['_id']
}

type Mutation = ReturnType<typeof useMutation>[0]

interface RejectModalViewProps extends RejectModalProps, Utils {
  variables: Partial<RejectFillInput>
  loading?: boolean
  rejectionReason: typeof AltCopayFillRejectionReason | typeof CopayFillRejectionReason | typeof OrderRejectionReason
  action: Mutation
  onChange?: ((opt: unknown) => void) | undefined
}

type RejectFillRequestOutput = { rejectRxFillRequest: { reversalsFailed: boolean; copayRequest: CopayRequest } }
type RejectCopayRequestFillOutput = {
  rejectCopayRequestFill: { reversalsFailed: boolean; copayRequestFill: CopayRequestFill }
}

type RejectFillInput = {
  fillId: string
  message?: string
  nextFillableDate?: string
  orderId?: string
  copayRequestId?: string
  reason: string
  reasonCode: OrderRejectionReason | CopayFillRejectionReason | AltCopayFillRejectionReason
}

const WrappedRejectModal = (
  props: RejectModalProps & { itemId: QueueItem['_id']; isCopayRequest?: boolean },
): JSX.Element => {
  return (
    <SelectionProvider>
      <RejectModalFactory {...props} />
    </SelectionProvider>
  )
}

const RejectModalFactory = (props: RejectModalProps & { itemId: QueueItem['_id']; isCopayRequest?: boolean }) => {
  const { isCopayRequest = false, itemId } = props
  const { dismissModal } = useModalContext()
  const showErrorToast = useErrorToast()
  const showSuccessToast = useSuccessToast()

  if (isCopayRequest) {
    return (
      <RejectCopayRequestFillModal
        showSuccessToast={showSuccessToast}
        showErrorToast={showErrorToast}
        dismissModal={dismissModal}
        copayRequestId={itemId}
        {...props}
      />
    )
  }
  return (
    <RejectOrderModal
      showSuccessToast={showSuccessToast}
      showErrorToast={showErrorToast}
      dismissModal={dismissModal}
      orderId={itemId}
      {...props}
    />
  )
}

const RejectOrderModal = (props: RejectOrderProps) => {
  const { orderId, fillId, confirmationCallback, dismissModal, showSuccessToast, showErrorToast } = props
  const { initSelections } = useSelectionContext()

  const [rejectRxFillRequest, { loading }] = useMutation<RejectFillRequestOutput, RejectFillInput>(
    REJECT_RX_FILL_REQUEST,
    {
      onCompleted: data => {
        const { reversalsFailed } = data.rejectRxFillRequest
        if (confirmationCallback) {
          confirmationCallback()
        } else if (!reversalsFailed) {
          showSuccessToast(`Rx: ${fillId} - rejected`)
        }
        if (reversalsFailed) showErrorToast(`Rx: ${fillId} - rejected but claim reversal failed`)
        dismissModal()
      },
      onError: e => {
        console.error('Failed to reject fill. ' + e.message)
        showErrorToast('Failed to reject fill. ' + e.message)
        dismissModal()
      },
    },
  )

  return (
    <RejectModal
      {...props}
      rejectionReason={OrderRejectionReason}
      action={rejectRxFillRequest as Mutation}
      loading={loading}
      variables={{
        orderId,
        fillId,
      }}
      onChange={(option: unknown) => {
        const valueTyped = (option as { value: keyof typeof OrderRejectionReason })?.value
        const newRejectCodeTyped = OrderRejectionReason[valueTyped]
        // Clear the DUR selections if we move away from contraindication
        // as a reason
        if (newRejectCodeTyped === 'TEMPTEMPCONTRA') {
          initSelections?.([])
        }
      }}
    />
  )
}

const RejectCopayRequestFillModal = (props: RejectCopayRequestFillProps) => {
  const { copayRequestId, fillId, confirmationCallback, dismissModal, showSuccessToast, showErrorToast } = props
  const [rejectCopayRequestFill, { loading }] = useMutation<RejectCopayRequestFillOutput, RejectFillInput>(
    REJECT_COPAY_REQUEST_FILL,
    {
      refetchQueries: ['getBasicCopay'],
      onCompleted: data => {
        const { reversalsFailed } = data.rejectCopayRequestFill
        if (confirmationCallback) {
          confirmationCallback()
        } else if (!reversalsFailed) {
          showSuccessToast(`Rx: ${fillId} - rejected`)
        }
        if (reversalsFailed) showErrorToast(`Rx: ${fillId} - rejected but claim reversal failed`)
        dismissModal()
      },
      onError: e => {
        console.error('Failed to reject copay. ' + e.message)
        showErrorToast('Failed to reject copay. ' + e.message)
        dismissModal()
      },
    },
  )

  const rejectionReason = AltCopayFillRejectionReason

  return (
    <RejectModal
      {...props}
      rejectionReason={rejectionReason}
      action={rejectCopayRequestFill as Mutation}
      loading={loading}
      variables={{
        fillId,
        copayRequestId,
      }}
    />
  )
}
const sortRejectReasons = (a: string, b: string) => {
  if (a === 'Other') {
    return 1
  }
  if (b === 'Other') {
    return -1
  }
  return a < b ? -1 : 1
}

const getPreparedRejectReasons = (rejectionReasons: RejectModalViewProps['rejectionReason']) => {
  return Object.keys(rejectionReasons).sort(sortRejectReasons)
}

const RejectModal = (props: RejectModalViewProps) => {
  const {
    action,
    onDismiss,
    durs = [],
    showDescription = false,
    description = '',
    loading = false,
    variables,
    rejectionReason,
    onChange,
    dismissModal,
    isControlledSubstance,
    showErrorToast,
  } = props

  const options = getPreparedRejectReasons(rejectionReason)

  const [trackControlledSubstanceRejections] = useMutation(TRACK_CONTROLLED_SUBSTANCE_REJECTIONS, {
    onCompleted: data => {
      return data
    },
    onError: e => {
      console.error(`Failed to track controlled substance rejection. ` + e.message)
      showErrorToast(`Failed to track controlled substance rejection. ` + e.message)
    },
  })

  const [rejectReason, setRejectReason] = useState('')
  const [rejectMessage, setRejectMessage] = useState('')
  const [rejectCode, setRejectCode] = useState<
    OrderRejectionReason | CopayFillRejectionReason | AltCopayFillRejectionReason | undefined
  >(undefined)
  const [nextFillableDate, setNextFillableDate] = useState<string | undefined>(undefined)
  const { selections } = useSelectionContext()

  const anySelectionsMade = selections

  const isReasonGiven =
    rejectCode === OrderRejectionReason['Contraindication (drug, disease, allergy)']
      ? anySelectionsMade
      : rejectReason !== ''
  const needsNextFillDate =
    (rejectCode === OrderRejectionReason['Refill too soon'] ||
      rejectCode === CopayFillRejectionReason['Refill too soon']) &&
    !nextFillableDate
  const disableSave = !isReasonGiven || needsNextFillDate || loading

  const resetOptions = useCallback(() => {
    setRejectReason('')
    setRejectCode(undefined)
  }, [])

  const useDatePickerForMsg = rejectCode === OrderRejectionReason['Rx Expired']
  const isRefillTooSoonRejection = rejectCode === '79' || rejectCode === 'RX_REFILL_TOO_SOON'
  const isDrugDiseaseOrAllergyContradiction = rejectCode === 'TEMPTEMPCONTRA'

  return (
    <ModalWrapper id="RejectModal">
      <ModalHeader closeCallback={onDismiss}>
        <IconWrapper data-testid="close">
          <CancelIcon role="img" fill={bodyPrimaryColor} />
        </IconWrapper>
        <h2>Reject reason</h2>
      </ModalHeader>
      <ConstrainedInputContainer>
        {showDescription && <p>{description}</p>}
        <ReasonSelect
          data-testid="reason"
          multiple={false}
          modal={true}
          value={rejectReason}
          placeholder={'Select a reject reason...'}
          options={options}
          onChange={([option]) => {
            if (!option) {
              resetOptions()
              return
            }

            const value = option.value as keyof typeof rejectionReason
            const newRejectCode = rejectionReason[value]
            setRejectReason(value)
            setRejectCode(newRejectCode)

            onChange?.(option.value)
          }}
        />
        {isDrugDiseaseOrAllergyContradiction && <RejectDURSelect durs={durs} />}
        {isRefillTooSoonRejection && (
          <StyledDatePicker
            date={nextFillableDate ? moment(nextFillableDate, 'MM/DD/YYYY').toDate() : undefined}
            onChange={date => {
              const dateStr = moment(date).format('MM/DD/YYYY')
              setNextFillableDate(dateStr)
            }}
            showPlaceholder={nextFillableDate === undefined}
          />
        )}
        {useDatePickerForMsg ? (
          <StyledDatePicker
            data-testid="message"
            onChange={date => {
              const dateStr = moment(date).format('MM/DD/YYYY')
              setRejectMessage(dateStr)
            }}
          />
        ) : (
          <TextArea
            data-testid="message"
            onChange={event => setRejectMessage(event.target.value)}
            placeholder={'Type a message...'}
            value={rejectMessage}
            resize={'vertical'}
          />
        )}
      </ConstrainedInputContainer>
      <ButtonsContainer>
        <CancelButton
          label="Cancel"
          onClick={() => {
            dismissModal()
            onDismiss && onDismiss()
          }}
        />
        <SaveButton
          isModal
          disabled={disableSave}
          label={`Submit${loading ? 'ing' : ''}`}
          onClick={async () => {
            if (rejectCode && isReasonGiven) {
              const vars: RejectFillInput = {
                ...variables,
                fillId: variables.fillId ?? '',
                nextFillableDate,
                reason: rejectReason,
                reasonCode: rejectCode,
              }

              if (rejectMessage && rejectMessage.length) {
                vars.message = rejectMessage
              }

              if (rejectCode === 'TEMPTEMPCONTRA') {
                const selectedDURReasons = selections.join(', ')
                const newMessageText = `${variables.message || ''} - (${selectedDURReasons})`
                vars.message = newMessageText
              }

              await action({
                variables: vars,
              })

              if (isControlledSubstance) {
                await trackControlledSubstanceRejections({
                  variables: {
                    orderId: variables.orderId,
                    fillId: variables.fillId,
                  },
                })
              }
            }
          }}
        />
      </ButtonsContainer>
    </ModalWrapper>
  )
}

const RejectDURSelect = (props: { durs: DUR[] }) => {
  const { durs } = props
  const { deSelect, select, selections } = useSelectionContext()

  return (
    <DURCardList data-testid="DURs">
      <DURLabel>Select applicable DURs</DURLabel>
      {durs.map(dur => {
        const key = keyFromDur(dur)
        return (
          <DURCard
            key={key}
            {...dur}
            selected={selections.some(sel => sel._id === key)}
            selectCallback={newSelected => {
              if (newSelected) {
                select({ _id: key })
              } else {
                deSelect({ _id: key })
              }
            }}
          />
        )
      })}
    </DURCardList>
  )
}

const DURCardList = styled.ul`
  margin-top: 0.5rem;
  list-style: none;
  > li {
    margin: 0.5rem 0rem;
  }
`

const DURLabel = styled.p`
  font-weight: 500;
  font-size: 1rem;
  margin-top: 0.325rem;
  margin-bottom: 0.625rem;
`

const ReasonSelect = styled(StyledSelect)`
  height: 2.5rem;
  align-self: stretch;
`
const ConstrainedInputContainer = styled(InputContainer)`
  max-width: 31rem;
`

const StyledDatePicker = styled(DatePicker)`
  margin-top: 0.625rem;

  input {
    line-height: 1.5rem;
    height: auto;
    padding: 0.3rem 1rem;
  }
`

export default WrappedRejectModal
