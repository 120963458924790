import type { EscriptTriageReasons } from '.'
import type { EntityId } from '../shared'
import type { AllergyRestrictedChoice } from './allergyOrAdverseEvent'
import type { BenefitsCoordinationRestricted } from './benefitsCoordination'
import type { DiagnosisGeneral } from './diagnosisGeneral'
import type { DrugAdministrationRequest } from './drugAdministrationRequest'
import type * as enums from './enums'
import type { Facility } from './facility'
import type { EscriptFillStatus } from './fillStatus'
import type { FollowUpPrescriber } from './followUpPrescriber'
import type { NewRxPrescribedMedication, PrescribedMedication } from './medicationPrescribed'
import type { Observation } from './observation'
import type { PatientMandatoryAddress } from './patient'
import type { MandatoryPharmacy } from './pharmacy'
import type { MandatoryPrescriber } from './prescriber'
import type { SupervisorMandatoryAddress } from './supervisor'

export interface RequestedDates {
  startDate?: string
  endDate?: string
}

export interface EscriptDigitalSignature {
  indicator?: boolean
  signature?: string
}

export interface EscriptInput {
  raw: string
  messageId: string
  pioneerMessageId?: string
  clinicName?: string
  sentTime: Date
  pioneerId?: string
  originator: enums.Originator
  messageType: enums.MessageType
  prescriberOrderNumber?: string
  rxReferenceNumber?: string
  relatesToMessageId?: string
  prescriptionId?: EntityId
  ndc?: string
  sender: string
  Message: any // JSON form of the original Escript xml
  messageRequestCode?: enums.MessageRequestCode
  returnReceipt?: string
  requestReferenceNumber?: string
  urgencyIndicatorCode?: string
  followUpRequest?: number
  changeOfPrescriptionStatusFlag?: enums.ChangeOfPrescriptionStatusFlag
  allergyOrAdverseEvent?: AllergyRestrictedChoice
  benefitsCoordination?: BenefitsCoordinationRestricted[]
  diagnosisGeneral?: DiagnosisGeneral // only used for LTC facility
  facility?: Facility
  fillStatus?: EscriptFillStatus
  patient: PatientMandatoryAddress
  pharmacy?: MandatoryPharmacy
  prescriber: MandatoryPrescriber
  dispensingRequestCode?: enums.DispensingRequestCode
  drugAdministrationRequest?: DrugAdministrationRequest
  observation?: Observation
  medicationDispensed?: PrescribedMedication
  medicationPrescribed?: NewRxPrescribedMedication
  medicationRequested?: PrescribedMedication
  supervisor?: SupervisorMandatoryAddress
  requestedDates?: RequestedDates // Looks used mainly for rxHistoryRequest and rxHistoryResponse
  prohibitRenewalRequest?: boolean
  changeReasonText?: string
  followUpPrescriber?: FollowUpPrescriber
  inTriage?: boolean
  triageReason?: EscriptTriageReasons
  customerId?: EntityId
  digitalSignature?: EscriptDigitalSignature
  rerouted?: boolean
  externalId?: string
}

export interface GetEscriptsParameters {
  pageNumber?: number
  pageSize?: number
  searchTerm?: string
  prescriptionId?: string
  customerIds?: string[]
  medicationNames?: string[]
  inTriage?: boolean
  filterExternalRxPending?: boolean
  filterUnassigned?: boolean
  filterTriagedByPatientMatch?: boolean
  intakeIssueStatuses?: string[]
  statuses?: string[]
}

export enum EscriptStatus {
  erxReceived = 'ERX RECEIVED',
  rxAssigned = 'RX ASSIGNED',
  triage = 'TRIAGE',
  externalRxPending = 'EXTERNAL RX - PENDING',
  externalRxReceived = 'EXTERNAL RX - RECEIVED',
  erxValidated = 'ERX VALIDATED',
  erxReservedForInvestigation = 'ERX RESERVED FOR INVESTIGATION',
  erxClinicalReviewRequired = 'ERX CLINICAL REVIEW REQUIRED',
  erxIntakeValidationIssue = 'ERX INTAKE VALIDATION ISSUE',
  erxRejected = 'ERX REJECTED',
}
