import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
  Text,
  Spacer,
  Header,
  Button,
} from '@truepill/react-capsule'
import type { ApolloError } from '@truepill/tpos-react-router'
import { useMutation } from '@truepill/tpos-react-router'
import CardWithBorder from 'components/CustomizedCardWithBorder'
import LoadingSpinner from 'components/Loading'
import { FilledHeader } from 'components/PageStructure'
import AddUserMFA from 'components/UserProfileData/AddUserMFA'
import { UPDATE_USER } from 'gql'
import type { UserInfo } from 'hooks/useUserInfo'
import UserModal from 'modals/UserModal'
import moment from 'moment'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import styled from 'styled-components'
import type { FlattenSimpleInterpolation } from 'styled-components'
import { capsuleRed, contrastBackgroundColor, darkGreen } from 'styles/styleVariables'
import { checkIfProvided } from 'utils'

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const TextImageContainer = styled.div`
  width: 60%;
`

const ImageContainer = styled.div`
  width: 40%;
`

const FlexContainer = styled.div`
  display: flex;
  width: 100%;
`

const CardWrapper = styled.div`
  padding: 8px;
  background-color: ${contrastBackgroundColor};
  border-radius: 5px;
  > div:not(:last-child) {
    margin-bottom: 8px;
  }
`

const LoadingContainer = styled.div`
  width: 50px;
  margin: 50px auto;
  margin-top: 50px;
`

const Row = styled.ul<{ extraInfo?: boolean; minHeight?: number }>`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: [label] 7rem [value] 1fr ${({ extraInfo }) => extraInfo && '[extra] 0.5fr'};
  padding: 0.5rem 0;
  padding-left: 0.65rem;
  border-radius: 5px;
  :nth-of-type(even) {
    background-color: ${contrastBackgroundColor};
  }
  ${({ minHeight }) => minHeight && `min-height: ${minHeight}px`}
`

const RowLabel = styled.li`
  grid-row: 1;
  grid-column: label;
  padding-right: 0.3125rem;
  font-size: 0.875rem;
  font-weight: 500;
`

const RowValue = styled.li`
  grid-row: 1;
  grid-column: value;
`

const StyledHeader = styled(FilledHeader)`
  margin-top: 0.625rem;
  margin-top: 1.25rem;
`

const UserColumn = styled.ul<{
  styles?: FlattenSimpleInterpolation
}>`
  :not(:last-child) {
    margin-right: 1.25rem;
  }
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 33%;
  ${({ styles }) => styles};
`

export const UserInfoContainer = styled.ul`
  width: 100%;
  display: flex;
  flex-basis: 100%;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 1.25rem;
`

const Wrapper = styled.div`
  width: 100%;
  color: #eeeeee;
`

const parseUserInfo = (user?: UserInfo) => {
  const { firstName, lastName, location, roles, email, preferredName } = user || {}

  const nameOfUser = `${firstName} ${lastName}`

  const userRoles = roles?.join(', ')

  const { name: pharmacyName, pharmacistInCharge } = location ?? {}
  const { firstName: PICName, lastName: PICLastName } = pharmacistInCharge ?? {}
  const pharmacistInChargeName = `${PICName ?? ''} ${PICLastName ?? ''}`.trim()

  return {
    email: email,
    name: nameOfUser,
    roles: userRoles,
    pharmacyName,
    PIC: pharmacistInChargeName,
    preferredName,
  }
}

interface UserDetailsProps {
  userInfo?: UserInfo
  loading: boolean
  error?: ApolloError
}

export const UserDetails = ({ userInfo, loading, error }: UserDetailsProps): JSX.Element => {
  const { email, name, roles, pharmacyName, PIC, preferredName } = parseUserInfo(userInfo)
  const { licenses } = userInfo || {}

  const loadingIndicator = (
    <LoadingContainer>
      <LoadingSpinner />
    </LoadingContainer>
  )

  const content = (
    <>
      <Row>
        <RowLabel>Name:</RowLabel>
        <RowValue data-private>{checkIfProvided(name)}</RowValue>
      </Row>
      <Row>
        <RowLabel>Preferred Name:</RowLabel>
        <RowValue data-private>{checkIfProvided(preferredName)}</RowValue>
      </Row>
      <Row>
        <RowLabel>Email:</RowLabel>
        <RowValue data-private>{checkIfProvided(email)}</RowValue>
      </Row>
      <Row>
        <RowLabel>Role/s:</RowLabel>
        <RowValue data-private>{checkIfProvided(roles)}</RowValue>
      </Row>
      <Row>
        <RowLabel>Pharmacy:</RowLabel>
        <RowValue data-private>{checkIfProvided(pharmacyName)}</RowValue>
      </Row>
      <Row>
        <RowLabel>PIC:</RowLabel>
        <RowValue data-private>{checkIfProvided(PIC)}</RowValue>
      </Row>
      <Row>
        <RowLabel>License:</RowLabel>
      </Row>
    </>
  )

  const todaysDate = moment()
  const { showModal } = useModalContext()
  const [updateUser] = useMutation(UPDATE_USER)

  return (
    <UserColumn>
      <HeaderContainer>
        <Header variant="xl" bold css={{ padding: '5px' }}>
          Demographic Information
        </Header>
        <Button
          variant="primary-text"
          onClick={() =>
            showModal(() => (
              <UserModal
                user={userInfo}
                confirmationCallback={userData =>
                  updateUser({
                    variables: {
                      id: userInfo?._id,
                      firstName: userData.firstName.trim(),
                      lastName: userData.lastName.trim(),
                      preferredName: userData.preferredName ? userData.preferredName.trim() : '',
                      email: userData.email ? userData.email.trim() : undefined,
                      locationId: userData.locationId,
                      roles: userData.roles,
                      licenses: userData.licenses
                        ? userData.licenses.map(({ id, number, expirationDate, registeredState, imageId }) => ({
                            id,
                            number,
                            expirationDate,
                            registeredState,
                            imageId,
                          }))
                        : [],
                    },
                  })
                }
              />
            ))
          }
        >
          Edit
        </Button>
      </HeaderContainer>
      {loading ? loadingIndicator : content}
      <CardWrapper>
        {(!licenses || licenses.length === 0) && <Text>No licenses found</Text>}
        {licenses?.map(license => {
          const expDateFormatted = moment(license.expirationDate).format('MM/DD/YYYY')
          const isActive = moment(license.expirationDate) > moment(todaysDate)
          return (
            <Wrapper key={license.id}>
              <CardWithBorder borderColor={isActive ? darkGreen : capsuleRed}>
                <Accordion
                  css={{
                    borderRadius: '$sm',
                    backgroundColor: '$white',
                  }}
                  type="single"
                  collapsible
                >
                  <AccordionItem value="item-1">
                    <AccordionTrigger>
                      <div>
                        <FlexContainer>
                          <Text bold>State:</Text>
                          <Spacer size="sm" />
                          <Text>{`${license.registeredState} #${license.number}`}</Text>
                        </FlexContainer>
                        <FlexContainer>
                          <Text bold>Expiration date:</Text>
                          <Spacer size="sm" />
                          <Text>{expDateFormatted}</Text>
                        </FlexContainer>
                      </div>
                    </AccordionTrigger>
                    <AccordionContent css={{ padding: '$md' }}>
                      <FlexContainer>
                        <TextImageContainer>
                          <Text bold>Image:</Text>
                        </TextImageContainer>
                        <ImageContainer>
                          {!!license.image?.s3SignedUrl ? (
                            <img src={license.image.s3SignedUrl} alt={license.number} />
                          ) : (
                            <Text bold>Not found:</Text>
                          )}
                        </ImageContainer>
                      </FlexContainer>
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </CardWithBorder>
            </Wrapper>
          )
        })}
      </CardWrapper>
    </UserColumn>
  )
}

interface UserAccountAndSecurityProps {
  userInfo?: UserInfo
  loading: boolean
  error?: ApolloError
}

export const UserAccountAndSecurity = ({ userInfo, loading }: UserAccountAndSecurityProps): JSX.Element => {
  const { email, _id } = userInfo ?? {}

  const loadingIndicator = (
    <LoadingContainer>
      <LoadingSpinner />
    </LoadingContainer>
  )

  const content = (
    <>
      <Row>
        <RowLabel>Email:</RowLabel>
        <RowValue data-private>{email}</RowValue>
      </Row>
      {/* TODO: Uncomment when functionality is hooked up */}
      {/* <Row>
        <RowLabel>Password</RowLabel>
        <RowValue data-private>
          <SecurityButton>Change password</SecurityButton>
        </RowValue>
      </Row> */}
      <Row>
        <RowLabel>Multi-factor authorization:</RowLabel>
        <RowValue data-private>
          <AddUserMFA userId={_id} />
        </RowValue>
      </Row>
    </>
  )

  return (
    <UserColumn>
      <StyledHeader>Account and security</StyledHeader>
      {loading ? loadingIndicator : content}
    </UserColumn>
  )
}
