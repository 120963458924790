import type { FunctionComponent, SVGProps, MouseEvent, ReactNode } from 'react'
import { useState } from 'react'
import type { ToolTipFacing } from 'components/HotKeyToolTip'
import HotKeyToolTip from 'components/HotKeyToolTip'
import styled, { css, keyframes } from 'styled-components'
import { primaryColor, contrastBackgroundColor } from 'styles/styleVariables'
import type { ChildProps } from 'types'

type ToolButtonProps = {
  label: string
  icon: FunctionComponent<SVGProps<SVGSVGElement>>
  clickCallback: (event?: MouseEvent<HTMLElement>) => void
  active?: boolean
  disabled?: boolean
  tooltipText?: string | ReactNode
  tooltipPositioning?: {
    position: ToolTipFacing
    offsetTop: number
    offsetLeft: number
  }
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  tooltipForceHide?: boolean
  children?: ReactNode
  className?: string
  'data-testid'?: string
}

export const ToolButton = ({
  label,
  clickCallback,
  icon: Icon,
  active,
  disabled,
  tooltipText,
  tooltipPositioning = {
    position: 'left',
    offsetLeft: -0.5,
    offsetTop: 0.5,
  },
  children,
  className,
  onMouseEnter,
  onMouseLeave,
  tooltipForceHide,
  'data-testid': testId,
}: ToolButtonProps): JSX.Element => {
  const [showToolTip, setShowToolTip] = useState(false)

  return (
    <StyledCircleButton
      aria-label={typeof tooltipText === 'string' ? tooltipText : label}
      data-testid={testId ?? label}
      className={className}
      onPointerEnter={() => {
        onMouseEnter && onMouseEnter()
        setShowToolTip(true)
      }}
      onPointerLeave={() => {
        onMouseLeave && onMouseLeave()
        setShowToolTip(false)
      }}
      onClick={clickCallback}
      active={active}
      disabled={disabled}
    >
      {tooltipText && (
        <HotKeyToolTip
          forceShow={showToolTip}
          onMouseEnter={() => {
            setShowToolTip(false)
          }}
          label={tooltipText}
          forceHide={tooltipForceHide}
          {...tooltipPositioning}
        />
      )}
      {children}
      <CircleIconWrapper>
        <Icon fill={active ? 'white' : 'black'} />
      </CircleIconWrapper>
    </StyledCircleButton>
  )
}

export const CircleIconWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 1rem;
  height: 1rem;
`

type SideBarToolsProps = { visible?: boolean; paddingTop?: boolean } & ChildProps

const SideBarTools = ({ visible = true, children, paddingTop }: SideBarToolsProps): JSX.Element => {
  return (
    <SideBarToolsContainer visible={visible} paddingTop={paddingTop}>
      <SideBarToolsWrapper visible={visible}>{children}</SideBarToolsWrapper>
    </SideBarToolsContainer>
  )
}

const SideBarToolsWrapper = styled.div<{
  visible?: boolean
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  > button:not(:first-child) {
    margin-top: 0.3125rem;
  }
  > button {
    margin-bottom: 0.3125rem;
  }
  ${({ visible }) => (visible ? slideInAnimation : slideOutAnimation)};
  animation-fill-mode: forwards;
`

const SideBarToolsContainer = styled.div<{ visible: boolean; paddingTop?: boolean }>`
  position: sticky;
  ${({ paddingTop }) => (paddingTop ? 'top: 14rem' : 'top: 9rem')};
  width: 3rem;
  padding-right: 1.875rem;
  display: flex;
  margin-left: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  ${({ visible }) => !visible && `overflow-x: hidden;`};
  z-index: 1;
`

const ActiveBackground = css`
  background-color: ${primaryColor};
  border-color: ${primaryColor};
`

export const StyledCircleButton = styled.button<{
  active?: boolean
  disabled?: boolean
}>`
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 100%;
  background-color: ${contrastBackgroundColor};
  border: 0.1rem solid ${contrastBackgroundColor};
  :hover {
    box-shadow: 0px 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
  }
  :focus {
    outline: 0;
    box-shadow: 0 0 0.625rem ${primaryColor};
  }

  ${({ active }) => active && ActiveBackground}
  ${({ disabled }) => disabled && `opacity: 0.5; cursor: not-allowed`}
`

const slideIn = keyframes`
  0% {
    white-space: nowrap;
    padding-left: 10rem;
  }
  100% {
    flex: 1;
    padding-left: 0rem;
  }
`

const slideOut = keyframes`
  0% {
    white-space: nowrap;
    padding-left: 0rem;
  }
  100% {
    white-space: nowrap;
    padding-left: 10rem;
  }
`

const slideInAnimation = css`
  animation: ${slideIn} 300ms;
`

const slideOutAnimation = css`
  animation: ${slideOut} 600ms;
`

export default SideBarTools
