import { Box } from '@truepill/react-capsule'
import { Link } from '@truepill/tpos-react-router'
import type { RedirectEntity } from '@truepill/tpos-types'
import { RedirectDestination } from '@truepill/tpos-types'
import { ReactComponent as LinkIcon } from 'assets/icons/external-link.svg'
import styled from 'styled-components'
import { primaryColor } from 'styles/styleVariables'
import { getRedirectUrl } from 'utils/urls'

const ExternalRedirectLink = ({
  identifier,
  entity,
  iconColor,
  to = RedirectDestination.Dash,
  altText = '',
}: {
  entity: RedirectEntity
  identifier: string | number
  to?: RedirectDestination
  iconColor?: string
  altText?: string
}) => {
  return (
    <StyledBox>
      {altText || identifier}
      <Link target="_blank" to={{ pathname: getRedirectUrl(identifier, entity, to) ?? '' }} title={`${identifier}`}>
        <span>
          <LinkIcon stroke={iconColor || 'black'} />
        </span>
      </Link>
    </StyledBox>
  )
}

const StyledBox = styled(Box)`
  display: flex;
  font-size: inherit;
  margin: 0 0.5rem;
  span {
    display: inline-flex;
  }
  a {
    width: 0.625rem;
  }
  :hover {
    margin: 0 0.125rem 0rem 0.5rem;
    color: ${primaryColor};
    a {
      width: 1rem;
    }
    svg {
      stroke: ${primaryColor};
    }
  }
`

export const RedirectToDashRx = ({ identifier, altText }: { identifier: string | number; altText?: string }) => (
  <ExternalRedirectLink identifier={identifier} altText={altText} entity="rx" to={RedirectDestination.Dash} />
)

export const RedirectToDashCopay = ({ identifier }: { identifier: string | number }) => (
  <ExternalRedirectLink identifier={identifier} entity="copay" to={RedirectDestination.Dash} />
)

export default ExternalRedirectLink
