import { useMutation } from '@truepill/tpos-react-router'
import { CopayStatus, RxFillRequestStatus, UserRoles } from '@truepill/tpos-types'
import { ReactComponent as TimeIcon } from 'assets/icons/time.svg'
import ActionButton from 'components/ActionButton'
import AuthLimited from 'components/AuthLimited'
import IconWrapper from 'components/IconWrapper'
import { REVERSE_ACTIVE_CLAIMS, REVERSE_ACTIVE_COPAY_CLAIMS } from 'gql'
import useErrorToast from 'hooks/toast/useErrorToast'
import { bodyPrimaryColor } from 'styles/styleVariables'
import type { Fill, QueueItem } from 'types'
import { getErrorMessage, getItemFill, isCopayRequest, isOrder } from 'utils'

type ReverseClaimsActionButtonProps = {
  fill: Fill
  item: QueueItem
  isPriorAuthorization?: boolean
  isManualPriorAuth?: boolean
  onlyAdminCanExecuteAction?: boolean
  disabled?: boolean
}

export const ReverseClaimsActionButton = ({
  item,
  fill,
  isPriorAuthorization = false,
  isManualPriorAuth = false,
  onlyAdminCanExecuteAction = true,
  disabled = false,
}: ReverseClaimsActionButtonProps): JSX.Element => {
  const showErrorToast = useErrorToast()

  const [reverseActiveClaims, { loading: loadingReverse }] = useMutation(REVERSE_ACTIVE_CLAIMS, {
    refetchQueries: ['getBasicOrder'],
    onError: e => {
      showErrorToast(getErrorMessage(e))
    },
  })

  const [reverseActiveCopayClaims, { loading: loadingReverseCopay }] = useMutation(REVERSE_ACTIVE_COPAY_CLAIMS, {
    refetchQueries: ['getBasicCopay'],
    onError: e => {
      showErrorToast(getErrorMessage(e))
    },
  })

  const isComplete = isOrder(item)
    ? getItemFill(item, fill._id)?.status === RxFillRequestStatus.Complete
    : getItemFill(item, fill._id)?.status === CopayStatus.Complete

  const isCompleteCopay = isCopayRequest(item) && isComplete
  if (isPriorAuthorization || isManualPriorAuth || isCompleteCopay) {
    return <></>
  }

  const rolesAbleToReverseClaim = !onlyAdminCanExecuteAction
    ? [
        UserRoles.Admin,
        UserRoles.Pharmacist,
        UserRoles.LeadPharmacist,
        UserRoles.LeadCustomerSupport,
        UserRoles.CustomerSupport,
        UserRoles.Technician,
      ]
    : [UserRoles.Admin]

  return (
    <AuthLimited roles={rolesAbleToReverseClaim}>
      <ActionButton
        disabled={disabled}
        icon={
          <IconWrapper>
            <TimeIcon fill={bodyPrimaryColor} />
          </IconWrapper>
        }
        label={loadingReverse || loadingReverseCopay ? 'Reversing...' : 'Reverse Claims'}
        onClick={() => {
          if (isOrder(item)) {
            const variables = {
              variables: {
                fillId: fill._id,
              },
            }
            reverseActiveClaims(variables)
          } else {
            const variables = {
              variables: {
                copayRequestId: item._id,
                fillId: fill._id,
              },
            }
            reverseActiveCopayClaims(variables)
          }
        }}
      />
    </AuthLimited>
  )
}
