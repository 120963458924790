import type { Allergy, Condition, Medication } from '../clinical-review'
import type { MedispanConcept } from '../medispan'
import type { EntityId } from '../shared'

export interface CustomDurSubject {
  name: string
}

export interface CustomDURSubjectCategories {
  allergies?: CustomDurSubject[]
  medications?: CustomDurSubject[]
  conditions?: CustomDurSubject[]
}

export interface DURSubjectCategories {
  allergies: Allergy[]
  conditions: Condition[]
  medications: Medication[]
  custom?: CustomDURSubjectCategories
}

export interface DURConditions {
  conditions: string[]
  initialDurInputs: Condition[]
}

export enum DURStatus {
  NONE = 'none',
  MILD = 'mild',
  MODERATE = 'moderate',
  SEVERE = 'severe',
}

interface DurUser {
  firstName?: string
  lastName?: string
}

export enum DURType {
  NONE = 'none',
  DUPLICATE_THERAPY = 'duplicateTherapy',
  DDI = 'drugDrugInteraction',
  INFERRED_CONDITION = 'inferredCondition',
  REPORTED_CONDITION = 'reportedCondition',
  ALLERGY = 'allergy',
  AGE_CONTRAINDICATION = 'ageContraindication',
  PREGNANCY_CONTRAINDICATION = 'pregnancyContraindication',
  LACTATION_CONTRAINDICATION = 'lactationContraindication',
  GENDER_CONTRAINDICATION = 'genderContraindication',
  DOSING_INDICATIONS = 'dosingIndications',
  INGREDIENT_DOSING_INDICATIONS = 'ingredientDosingIndications',
}

export interface DURScreeningMessage {
  type: DURType
  description: string
  subject: MedispanConcept
  ignore?: boolean
}

export interface DUR {
  status: DURStatus
  type: DURType
  subjects: MedispanConcept[]
  directions: string
  releasedByUserId?: EntityId
  releasedByUser?: DurUser
  ignore?: boolean
}
