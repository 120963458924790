import HotKeyToolTip from 'components/HotKeyToolTip'
import { ListRowLabel, ListRowValue, RaisedAnchor, RightFilledHeader, PaddingBlock } from 'components/RXTable'
import useHotKey, { HotKeyLevel } from 'hooks/useHotKey'
import { usePlusClient } from 'providers/VisionRouter'
import type { EdiOverrides } from 'types'
import { RXEDIListRow, RXEDImageCell, RXEDIRightEditCell, RXEDIRightTitleCell, RXEDITitleRow, RXTextInput } from '..'

type PrescriberSegmentProps = {
  ediOverrides: EdiOverrides
  updateEdiOverrides: (input: EdiOverrides) => void
}

const PrescriberSegment = ({ ediOverrides, updateEdiOverrides }: PrescriberSegmentProps): JSX.Element => {
  const { prescriber } = ediOverrides

  const primaryCareProviderIdQualifier = prescriber?.primaryCareProviderIdQualifier
  const primaryCareProviderId = prescriber?.primaryCareProviderId
  const primaryCareProviderLastName = prescriber?.primaryCareProviderLastName

  return (
    <>
      <PrescriberHeaderRow />
      <RXEDIListRow>
        <RXEDIRightEditCell>
          <ListRowLabel>Primary Care Provider Id Qualifier (2E):</ListRowLabel>
          <ListRowValue>
            <RXTextInput
              value={primaryCareProviderIdQualifier ?? ''}
              onChange={({ target: { value: primaryCareProviderIdQualifier } }) =>
                updateEdiOverrides({ prescriber: { primaryCareProviderIdQualifier } })
              }
              placeholder="Primary Care Provider Id Qualifier..."
            />
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>

      <RXEDIListRow>
        <RXEDIRightEditCell>
          <ListRowLabel>Primary Care Provider Id (DL):</ListRowLabel>
          <ListRowValue>
            <RXTextInput
              value={primaryCareProviderId ?? ''}
              onChange={({ target: { value: primaryCareProviderId } }) =>
                updateEdiOverrides({ prescriber: { primaryCareProviderId } })
              }
              placeholder="Primary Care Provider Id..."
            />
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>

      <RXEDIListRow>
        <RXEDIRightEditCell>
          <ListRowLabel>Primary Care Provider Last Name (4E):</ListRowLabel>
          <ListRowValue>
            <RXTextInput
              value={primaryCareProviderLastName ?? ''}
              onChange={({ target: { value: primaryCareProviderLastName } }) =>
                updateEdiOverrides({ prescriber: { primaryCareProviderLastName } })
              }
              placeholder="Primary Care Provider Last Name..."
            />
          </ListRowValue>
        </RXEDIRightEditCell>
      </RXEDIListRow>
    </>
  )
}

const PrescriberHeaderRow = () => {
  const { routeToHash } = usePlusClient()

  useHotKey('7', HotKeyLevel.normal, () => {
    routeToHash(`PrescriberSegment`)
  })

  return (
    <RXEDITitleRow>
      <RXEDImageCell></RXEDImageCell>
      <RXEDIRightTitleCell>
        <PaddingBlock />
        <RightFilledHeader>
          <RaisedAnchor id={'PrescriberSegment'} />
          Prescriber segment (03)
          <HotKeyToolTip label={'7'} position={'right'} offsetLeft={1} offsetTop={0} />
        </RightFilledHeader>
      </RXEDIRightTitleCell>
    </RXEDITitleRow>
  )
}

export default PrescriberSegment
