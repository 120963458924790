import type { FunctionComponent, MutableRefObject } from 'react'
import { forwardRef, useReducer, useContext, useEffect, useRef, useCallback, useMemo, useState } from 'react'
import { ReactComponent as LoadingDots } from 'assets/icons/loading_dots.svg'
import { ReactComponent as TriangleIcon } from 'assets/icons/triangle.svg'
import Drop from 'components/DropDownContainer'
import DropDownOption, { AutoScrollElement } from 'components/DropDownOption'
import HotKeyToolTip from 'components/HotKeyToolTip'
import { SearchResultLozenge, SearchResultLozengeList } from 'components/Tiles/Lozenge'
import useAutoUpdatingRef from 'hooks/useAutoUpdatingRef'
import useClickOutside from 'hooks/useClickOutside'
import useHotKey, { HotKeyLevel } from 'hooks/useHotKey'
import useTypeBuffer from 'hooks/useTypeBuffer'
import type { HotKeyProps } from 'providers/HID/HotKeyProvider'
import { HotKeyContext } from 'providers/HID/HotKeyProvider'
import styled, { css } from 'styled-components'
import EllipsisTruncate from 'styles/EllipsisTruncate'
import {
  subduedColor,
  bodySecondaryColor,
  bodyPrimaryColor,
  primaryActiveButtonBackground,
  primaryBackgroundColor,
  borderColor,
  primaryColor,
  alertRed,
  errorBorderShadow,
} from 'styles/styleVariables'

export interface Option<T = any> {
  label: string
  value: T
  icon?: FunctionComponent<{ fill: string }>
  additionalProps?: any
}

export type SelectValue = (string | number | boolean | Option)[] | string | number | boolean | Option

type ChildrenStatus = {
  highlighted: boolean
  selected: boolean
  active: boolean
  disabled: boolean
}

interface SelectProps {
  id?: string
  modal?: boolean
  ref?: HTMLButtonElement | null
  placeholder?: string
  fadePlaceholderText?: boolean
  options: Option[] | string[]
  value?: SelectValue
  multiple?: boolean
  className?: string
  onChange?: (changes: Option[]) => void
  children?: (option: Option, status: ChildrenStatus) => JSX.Element
  icon?: JSX.Element
  hotKey?: string
  disabled?: boolean
  disableClear?: boolean
  showActive?: boolean
  error?: boolean
  showLoadingAnimation?: boolean
  heightOffset?: number
  maximumHeight?: string
  preventScrolling?: boolean
  'data-testid'?: string
}

type SelectStateAction =
  | {
      type: 'set' | 'overwrite'
      payload: Option[]
    }
  | { type: 'setShowDropDown'; payload: boolean }

type SelectState = { selection: Option[]; showDropDown: boolean }

const SelectStateReducer = (state: SelectState, action: SelectStateAction) => {
  const { selection, showDropDown } = state
  const { type, payload } = action
  const selectedLabels = state.selection.map(({ label }) => label)
  let newSelection = [...selection]

  switch (type) {
    case 'overwrite':
      // Quick fix to prevent an infinite loop as working with any objects
      // of depth in a functional manner will always fail straight equality
      // checks (new references from copying etc).
      if (JSON.stringify(newSelection) === JSON.stringify(payload)) {
        return state
      }
      return { showDropDown, selection: payload as Option[] }
    case 'set':
      for (let i = 0; i < (payload as Option[]).length; i++) {
        const payloadItem = (payload as Option[])[i]
        const indexOf = selectedLabels.indexOf(payloadItem.label)
        if (indexOf === -1) {
          newSelection = [...newSelection, payloadItem]
        } else {
          newSelection.splice(indexOf, 1)
        }
      }
      return { selection: newSelection, showDropDown }
    case 'setShowDropDown':
      return { selection, showDropDown: payload as boolean }
  }
}

const useDeRegisterHotKeys = (hotKeyLevel: HotKeyLevel) => {
  const { deRegisterHotKey } = useContext(HotKeyContext) as HotKeyProps

  return useCallback(() => {
    deRegisterHotKey('ArrowUp', hotKeyLevel)
    deRegisterHotKey('ArrowDown', hotKeyLevel)
  }, [deRegisterHotKey, hotKeyLevel])
}

const formatOptions = (options: (string | Option)[]): Option[] => {
  if (!options.length) {
    return []
  }
  if (typeof options[0] === 'string') {
    return (options as string[]).map(text => {
      return { label: text, value: text }
    })
  }
  return options as Option[]
}

const useSyncValues = (
  firstLoad: MutableRefObject<boolean>,
  value: SelectProps['value'],
  dispatch: (action: SelectStateAction) => void,
) => {
  useEffect(() => {
    if (firstLoad.current) {
      return
    }
    if (!value) {
      if (!firstLoad.current) {
        dispatch({ type: 'overwrite', payload: [] })
      }
      return
    }
    if (value.constructor.name === 'Array') {
      const payload = (value as (string | number)[]).map(val => {
        return { label: `${val}`, value: val }
      })
      dispatch({ type: 'overwrite', payload })
    } else if (typeof value === 'object') {
      dispatch({ type: 'overwrite', payload: [value as Option] })
    } else {
      dispatch({
        type: 'overwrite',
        payload: [{ value: value as string | number, label: `${value}` }],
      })
    }
  }, [value, dispatch, firstLoad])
}

const useInitialValue = (value: SelectProps['value']) => {
  return useMemo(() => {
    if (!value) {
      return { selection: [], showDropDown: false }
    }

    if (value.constructor.name === 'Array') {
      return {
        selection: formatOptions(value as Option[]),
        showDropDown: false,
      }
    }

    return {
      selection: formatOptions([value] as string[]),
      showDropDown: false,
    }
  }, [value])
}

// gets options filtered by already selected options
const useFilteredOptions = (
  options: SelectProps['options'],
  selected: SelectState['selection'],
  multiple?: boolean,
  disableClear?: boolean,
) => {
  return useMemo(() => {
    const opts = [...formatOptions(options)]
    if (!disableClear) {
      opts.unshift({ label: '(~) Clear', value: undefined })
    }
    if (!multiple) {
      return opts
    }
    const selectedLabels = selected.map(({ label }) => label)
    return opts.filter(({ label }) => !selectedLabels.includes(label))
  }, [options, selected, multiple, disableClear])
}

const useCloseDropdown = (dispatch: (action: SelectStateAction) => void, hotKeyLevel: HotKeyLevel) => {
  const { deRegisterHotKey } = useContext(HotKeyContext) as HotKeyProps
  return useCallback(() => {
    dispatch({ type: 'setShowDropDown', payload: false })
    deRegisterHotKey('Enter', hotKeyLevel)
  }, [dispatch, deRegisterHotKey, hotKeyLevel])
}

const useOpenDropDown = (
  dispatch: (action: SelectStateAction) => void,
  handleEnterRef: MutableRefObject<() => void>,
  hotKeyLevel: HotKeyLevel,
  showDropDown: boolean,
  registerHotKeys: () => void,
  buttonRef: MutableRefObject<HTMLButtonElement | null>,
) => {
  const { registerHotKey } = useContext(HotKeyContext) as HotKeyProps
  return useCallback(() => {
    // Enter is a special case in that its only usable when the dropdown
    // is open which is why it is registered here and not in registerHotKeys
    if (!showDropDown) {
      dispatch({ type: 'setShowDropDown', payload: true })
      setTimeout(() => {
        registerHotKey('Enter', hotKeyLevel, () => {
          handleEnterRef.current && handleEnterRef.current()
        })
      }, 300)

      // if the user clicks on the button, it will trigger this open check
      // but won't focus the button, so we need to register the hotkeys here
      if (document.activeElement !== buttonRef.current) {
        registerHotKeys()
      }
    }
  }, [showDropDown, registerHotKey, registerHotKeys, handleEnterRef, dispatch, buttonRef, hotKeyLevel])
}

const useMoveUpList = (
  showDropDown: boolean,
  openDropDownRef: MutableRefObject<(() => void) | undefined>,
  setHighlightIndex: (val: number) => void,
  highlightIndex: number,
) => {
  const moveUpList = useCallback(() => {
    if (!showDropDown) {
      openDropDownRef && openDropDownRef.current && openDropDownRef.current()
    } else {
      setHighlightIndex(Math.max(highlightIndex - 1, 0))
    }
  }, [setHighlightIndex, highlightIndex, openDropDownRef, showDropDown])
  return useAutoUpdatingRef(moveUpList)
}

const useMoveDownList = (
  setHighlightIndex: (val: number) => void,
  highlightIndex: number,
  filteredOptionsLength: number,
  openDropDownRef: MutableRefObject<(() => void) | undefined>,
  showDropDown: boolean,
) => {
  const moveDownList = useCallback(() => {
    if (!showDropDown) {
      openDropDownRef && openDropDownRef.current && openDropDownRef.current()
    } else {
      setHighlightIndex(Math.min(highlightIndex + 1, filteredOptionsLength - 1))
    }
  }, [showDropDown, setHighlightIndex, highlightIndex, filteredOptionsLength, openDropDownRef])
  return useAutoUpdatingRef(moveDownList)
}

const SearchableSelect = forwardRef<HTMLButtonElement, SelectProps>((props, ref): JSX.Element => {
  const {
    options,
    placeholder,
    fadePlaceholderText = true,
    modal,
    onChange,
    value,
    className,
    multiple,
    id,
    children,
    icon,
    disableClear,
    hotKey = '',
    disabled = false,
    showActive = false,
    error = false,
    showLoadingAnimation = false,
    heightOffset,
    maximumHeight,
    preventScrolling,
    'data-testid': testId,
  } = props
  const firstLoad = useRef(true)
  const buttonRef = useRef<HTMLButtonElement | null>(null)
  const hotKeyLevel = modal ? HotKeyLevel.modal : HotKeyLevel.normal

  const initialValue = useInitialValue(value)

  const [state, dispatch] = useReducer(SelectStateReducer, initialValue)

  const { selection: selected, showDropDown } = state

  useSyncValues(firstLoad, value, dispatch)

  // TODO We need to kill off the concept of type buffers
  // https://truepill.atlassian.net/browse/JR-1584
  const [typingBuffer, addKeyStroke] = useTypeBuffer(300)

  const [highlightIndex, setHighlightIndex] = useState(0)
  const { registerHotKey, deRegisterHotKey } = useContext(HotKeyContext) as HotKeyProps

  const filteredOptions = useFilteredOptions(options, selected, multiple, disableClear)

  // handle firing off changes. We need to suppor the onChange function
  // changing without firing off unecessary updates
  const onChangeRef = useAutoUpdatingRef(onChange)

  useEffect(() => {
    !firstLoad.current && onChangeRef.current && onChangeRef.current(selected)
  }, [selected, onChangeRef])

  const addToSelected = useCallback(
    (option: Option) => {
      if (option.value === undefined) {
        dispatch({ type: 'overwrite', payload: [] })
      } else {
        if (multiple) {
          dispatch({ type: 'set', payload: [option] })
        } else {
          dispatch({ type: 'overwrite', payload: [option] })
        }
      }
    },
    [dispatch, multiple],
  )

  const closeDropDown = useCloseDropdown(dispatch, hotKeyLevel)

  const handleEnter = useCallback(() => {
    if (showDropDown) {
      closeDropDown()
      addToSelected(filteredOptions[highlightIndex])
    }
  }, [filteredOptions, highlightIndex, showDropDown, closeDropDown, addToSelected])
  const handleEnterRef = useAutoUpdatingRef(handleEnter)

  const openDropDownRef = useRef<() => void>()

  const moveUpListRef = useMoveUpList(showDropDown, openDropDownRef, setHighlightIndex, highlightIndex)

  const moveDownListRef = useMoveDownList(
    setHighlightIndex,
    highlightIndex,
    filteredOptions.length,
    openDropDownRef,
    showDropDown,
  )

  const registerHotKeys = useCallback(() => {
    registerHotKey(
      'ArrowUp',
      hotKeyLevel,
      (key, event, wasKeyDown) => {
        if (wasKeyDown) {
          moveUpListRef.current && moveUpListRef.current()
        }
      },
      true,
    )
    registerHotKey(
      'ArrowDown',
      hotKeyLevel,
      (key, event, wasKeyDown) => {
        if (wasKeyDown) {
          moveDownListRef.current && moveDownListRef.current()
        }
      },
      true,
    )
    document.addEventListener('keyup', handleTypingRef.current)
  }, [moveUpListRef, moveDownListRef, registerHotKey, hotKeyLevel])

  const openDropDown = useOpenDropDown(dispatch, handleEnterRef, hotKeyLevel, showDropDown, registerHotKeys, buttonRef)

  useEffect(() => {
    openDropDownRef.current = openDropDown
  }, [openDropDown])

  const showDropDownRef = useAutoUpdatingRef(showDropDown)

  const deRegisterHotKeys = useDeRegisterHotKeys(hotKeyLevel)

  const handleTypingRef = useRef<(e: KeyboardEvent) => void>(e => {
    addKeyStroke(e)
  })

  // Set up HotKey if we've been given one. Blank string hotkeys are ignored
  // by the provider so it's safe to use this even if we aren't passed a
  // hotkey
  useHotKey(hotKey, hotKeyLevel, () => {
    buttonRef.current && buttonRef.current.focus()
    openDropDownRef && openDropDownRef.current && openDropDownRef.current()
  })

  useEffect(() => {
    if (typingBuffer?.length) {
      for (let i = 0; i < filteredOptions.length; i++) {
        const option = filteredOptions[i]
        // the user almost never wants to type in clear to clear the
        // selection and it can end up wiping previous selections too easily
        const label = option.label.replace(/[()]/g, '')
        if (label.slice(0, typingBuffer.length).toLowerCase() === typingBuffer) {
          setHighlightIndex(i)
          addToSelected(option)
          break
        }
      }
    }
  }, [typingBuffer])

  const isSelected = useCallback(
    (option: Option) => {
      if (!selected.length) {
        return false
      }
      return selected.findIndex(({ value }) => value === option.value) !== -1
    },
    [selected],
  )

  // Final clean up if we leave the DOM
  useEffect(() => {
    return () => {
      deRegisterHotKeys()
      deRegisterHotKey('Enter', hotKeyLevel)
    }
  }, [deRegisterHotKeys, deRegisterHotKey, hotKeyLevel])

  useClickOutside(buttonRef, () => {
    if (showDropDownRef.current && buttonRef.current !== document.activeElement) {
      closeDropDown()
      deRegisterHotKeys()
    }
  })

  useEffect(() => {
    firstLoad.current = false
  }, [firstLoad])

  const usePlaceholder = selected.length === 0

  const labelText = usePlaceholder ? placeholder : selected.map(({ label }) => label).join(', ')

  return (
    <>
      <StyledButton
        data-testid={testId}
        id={id}
        error={error}
        onFocus={() => {
          registerHotKeys()
        }}
        className={className}
        onBlur={() => {
          if (!buttonRef.current?.contains(document.activeElement)) {
            document.removeEventListener('keyup', handleTypingRef.current)
            deRegisterHotKeys()
            showDropDownRef.current && closeDropDown()
          }
        }}
        ref={newRef => {
          if (ref) {
            ;(ref as MutableRefObject<HTMLButtonElement | null>).current = newRef
          }
          buttonRef.current = newRef
        }}
        onClick={() => {
          buttonRef.current?.focus()
          if (showDropDown) {
            closeDropDown()
          } else {
            openDropDown()
          }
        }}
        disabled={disabled}
        open={showDropDown}
        showActive={showActive}
        fadeText={fadePlaceholderText && usePlaceholder}
      >
        {icon && <WrappedIcon>{icon}</WrappedIcon>}
        <p>{labelText}</p>
        <Spacer />
        {hotKey !== '' && (
          <HotKeyToolTip label={hotKey.toUpperCase()} position={'top'} offsetTop={-3.4} offsetLeft={-2} />
        )}
        {showLoadingAnimation && <LoadingDots />}
        <Arrow fill={bodySecondaryColor} />
        {showDropDown && (
          <Drop
            data-testid={testId ? testId + '-dropdown' : 'dropdown'}
            containerWidth={buttonRef.current?.getBoundingClientRect().width ?? 0}
            heightOffset={heightOffset}
            maximumHeight={maximumHeight}
            preventScrolling={preventScrolling}
          >
            {filteredOptions.map((option, i) => {
              const onClick = () => {
                addToSelected(option)
                setHighlightIndex(i)
                closeDropDown()
                buttonRef.current?.focus()
              }
              const highlighted = highlightIndex === i
              const selected = isSelected(option)
              const { icon: Icon } = option
              if (children) {
                return (
                  <AutoScrollElement selected={highlighted} onMouseDown={onClick} key={i}>
                    {children(option, {
                      highlighted,
                      selected,
                      disabled: false,
                      active: false,
                    })}
                  </AutoScrollElement>
                )
              }
              return (
                <AutoScrollElement selected={highlighted} key={i}>
                  <DropDownOption highlighted={highlighted} onMouseDown={onClick} selected={selected}>
                    <p>
                      {Icon ? <Icon fill={bodyPrimaryColor} /> : <></>}
                      {option.label}
                    </p>
                  </DropDownOption>
                </AutoScrollElement>
              )
            })}
          </Drop>
        )}
      </StyledButton>
      {multiple && (
        <PaddedSearchResultLozengeList>
          {selected.map((option, i) => (
            <SearchResultLozenge
              key={i}
              closeCallback={() => {
                addToSelected(option)
              }}
            >
              <p>{option.label}</p>
            </SearchResultLozenge>
          ))}
        </PaddedSearchResultLozengeList>
      )}
    </>
  )
})
SearchableSelect.displayName = 'SearchableSelect'

const WrappedIcon = styled.div`
  padding-right: 0.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 0 !important;
`

const Spacer = styled.div`
  min-width: 0.625rem;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`

const Arrow = styled(TriangleIcon)<{
  fill: string
}>`
  padding-bottom: 0.025rem;
  width: 0.625rem;
  height: 0.5rem;
  transform: rotate(180deg);
`

const PaddedSearchResultLozengeList = styled(SearchResultLozengeList)`
  padding-top: 0.3125rem;
`

const StyledButton = styled.button<{
  open: boolean
  fadeText?: boolean
  showActive?: boolean
  disabled?: boolean
  error?: boolean
}>`
  position: relative;
  font-family: Roboto;
  background-color: ${primaryBackgroundColor};
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 2.5rem;
  border: 0.125rem solid;
  border-color: ${({ showActive, error }) =>
    error ? alertRed : showActive ? primaryActiveButtonBackground : borderColor};
  border-radius: 0.25rem;
  min-width: 4rem;
  ${({ fadeText }) => (fadeText ? `color: ${subduedColor};` : `color: ${bodyPrimaryColor};`)};
  padding: 0.3rem 1rem;
  > svg {
    ${({ fadeText }) => (fadeText ? `fill: ${subduedColor}` : `fill: ${bodyPrimaryColor}`)}
  }
  > div {
    flex-grow: 1;
    justify-content: space-between;
    > svg {
      ${({ fadeText }) => (fadeText ? `fill: ${subduedColor}` : `fill: ${bodyPrimaryColor}`)}
    }
  }
  ${({ open, showActive }) =>
    (open || showActive) &&
    css`
      background-color: ${primaryActiveButtonBackground};
      color: ${primaryBackgroundColor};
      > svg {
        fill: ${primaryBackgroundColor};
      }
      > div {
        > svg {
          fill: ${primaryBackgroundColor};
        }
      }
    `}
  > p {
    ${EllipsisTruncate}
  }
  :focus,
  :hover {
    outline-color: ${({ error }) => (error ? errorBorderShadow : primaryColor)};
    border: 2px solid ${({ error }) => (error ? errorBorderShadow : primaryColor)};
  }

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.5;
    pointer-events: none;
    > svg {
      display: none;
    }
  `}
`

export default SearchableSelect
