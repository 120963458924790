import type { EVoucherSwitchService } from '../prescription/claimSummary'

export enum RxFillRequestStatus {
  PV1 = 'PV1',
  Fill = 'Fill',
  Automation = 'Automation',
  PV2 = 'PV2',
  Complete = 'Complete',
  Cancelled = 'Cancelled',
  Adjudication = 'Adjudication',
  PreAdjudication = 'PreAdjudication',
  PriorAuthorization = 'PriorAuthorization',
  Reverted = 'Reverted',
}

export interface RequestedMedication {
  name: string
  daysSupply?: number
  quantityRequested?: number
  quantityDispensed?: number
  dawCode?: number
  requestedDaw?: number
  requestedNdc?: string
}

export interface RxFillRequestAdjudication {
  claimJumperErrors?: string[]
  claimRunDate?: Date
  lastClaimRejectCodes: string[]
  runSuccessful?: boolean
  reverseAfterMinutes?: number
  reverseDate?: Date
  runId?: string
  reversedAt?: Date
  eVoucher?: EVoucherSwitchService
  copayAmount?: number
  completedAt?: Date
  nextFillableDate?: Date
  fillNumber?: number
}

export const AutomationActionableStatus = [
  RxFillRequestStatus.PV1,
  RxFillRequestStatus.Automation,
  RxFillRequestStatus.Fill,
]

export enum RxFillRequestTags {
  IRB = 'IRB',
  HERS = 'Hers',
}

export const LEVELS_IRB_NOTE = 'IRB_DEXCOM'

export enum RxFillRequestSpecialHandlingTag {
  HIGH_COST = 'High cost',
  COLD_CHAIN = 'Cold Chain',
  SPECIALTY = 'Specialty',
  CII = 'CII',
  CIII = 'CIII',
  CIV = 'CIV',
  CV = 'CV',
  IRB = 'IRB',
  HERS = 'Hers',
  CONTROLLED_SUBSTANCE = 'Controlled Substance',
}

export enum RxFillRequestSpecialHandlingTagAllOrNoOptions {
  ALL = 'All',
  NO_SPECIAL_HANDLING = 'No special handling',
}
