import { FormItem, FormItems, Select, WideTextInput } from 'components/ColumnForm'
import DatePicker from 'components/DatePicker'
import moment from 'moment'
import type { InsuranceProvider } from 'types'

type PayerDetailsFormProps = {
  payerForm: InsuranceProvider
  updatePayerForm: (update: InsuranceProvider) => void
}

const PayerDetailsForm = ({ payerForm, updatePayerForm }: PayerDetailsFormProps): JSX.Element => (
  <FormItems data-testid="PayerDetailForm">
    <FormItem data-test-row="bin">
      <p>BIN</p>
      <WideTextInput value={payerForm.bin} onChange={e => updatePayerForm({ ...payerForm, bin: e.target.value })} />
    </FormItem>
    <FormItem data-test-row="pcn">
      <p>PCN</p>
      <WideTextInput value={payerForm.pcn} onChange={e => updatePayerForm({ ...payerForm, pcn: e.target.value })} />
    </FormItem>
    <FormItem data-test-row="name">
      <p>Name</p>
      <WideTextInput value={payerForm.name} onChange={e => updatePayerForm({ ...payerForm, name: e.target.value })} />
    </FormItem>
    <FormItem data-test-row="code">
      <p>Code</p>
      <WideTextInput value={payerForm.code} onChange={e => updatePayerForm({ ...payerForm, code: e.target.value })} />
    </FormItem>
    <FormItem data-test-row="plan">
      <p>Plan Type</p>
      <Select
        data-testid="plan-type"
        multiple={false}
        value={payerForm.planType}
        placeholder={'Select plan type...'}
        options={['Commercial', 'Government', 'Medicare Part B', 'Medicare Part D', 'Medicaid', `Worker's Comp`]}
        onChange={([option]) => updatePayerForm({ ...payerForm, planType: option ? option.value : '' })}
        disableClear
      />
    </FormItem>
    <FormItem data-test-row="submission">
      <p>Submission Type</p>
      <Select
        data-testid="submission-type"
        multiple={false}
        value={payerForm.submissionType}
        placeholder={'Select submission type...'}
        options={['Transmitted', 'Manual']}
        onChange={([option]) => updatePayerForm({ ...payerForm, submissionType: option ? option.value : '' })}
        disableClear
      />
    </FormItem>
    <FormItem data-test-row="billing">
      <p>Billing Type</p>
      <Select
        data-testid="billing-type"
        multiple={false}
        value={payerForm.billingType}
        placeholder={'Select billing type...'}
        options={['Any', 'Primary', 'Secondary']}
        onChange={([option]) => updatePayerForm({ ...payerForm, billingType: option ? option.value : '' })}
        disableClear
      />
    </FormItem>
    <FormItem data-test-row="contract-expiration">
      <p>Contract expiration</p>
      <DatePicker
        date={moment(payerForm.contractExpiration, 'YYYY-MM-DD').toDate()}
        onChange={newDate =>
          updatePayerForm({ ...payerForm, contractExpiration: moment(newDate).format('YYYY-MM-DD') })
        }
      />
    </FormItem>
    <FormItem data-test-row="status">
      <p>Status</p>
      <Select
        data-testid="status"
        multiple={false}
        value={payerForm.status}
        placeholder={'Select status...'}
        options={['All']}
        onChange={([option]) => updatePayerForm({ ...payerForm, status: option ? option.value : '' })}
        disableClear
      />
    </FormItem>
    <FormItem data-test-row="state-code">
      <p>State Code</p>
      <Select
        data-testid="state-code"
        multiple={false}
        value={payerForm.stateCode}
        placeholder={'Select state code...'}
        options={['All']}
        onChange={([option]) => updatePayerForm({ ...payerForm, stateCode: option ? option.value : '' })}
        disableClear
      />
    </FormItem>
  </FormItems>
)

export default PayerDetailsForm
