import { ReactComponent as InfoIcon } from 'assets/icons/info.svg'
import IconWrapper from 'components/IconWrapper'
import ItemReturnList from 'components/ItemReturnList'
import { ModalWrapper, ModalHeader, InputContainer } from 'components/Modal'
import styled from 'styled-components'
import { bodyPrimaryColor } from 'styles/styleVariables'
import type { Order } from 'types'

type ItemReturnListModalProps = {
  order: Order
}

const ItemReturnListModal = ({ order }: ItemReturnListModalProps): JSX.Element => (
  <ModalWrapper id="ItemReturnListModal">
    <ModalHeader>
      <IconContainer>
        <IconWrapper>
          <InfoIcon fill={bodyPrimaryColor} />
        </IconWrapper>
      </IconContainer>
      <h2>Order Item Information</h2>
    </ModalHeader>
    <StyledInputContainer>
      <ItemReturnList order={order} />
    </StyledInputContainer>
  </ModalWrapper>
)

const StyledInputContainer = styled(InputContainer)`
  flex-grow: 1;
`

const IconContainer = styled.div`
  padding-top: 0.15rem;
`

export default ItemReturnListModal
