import type { UploadState } from 'components/UploadTarget'

const UNITS = ['B', 'KB', 'MB']

export const humanReadableFileSize = (byteSize: number): string => {
  let i = 0
  while (byteSize >= 1024) {
    byteSize /= 1024
    ++i
  }
  return `${byteSize.toFixed(1)} ${UNITS[i]}`
}

export function fileToUploadState(file: File): Promise<UploadState> {
  return new Promise(res => {
    const reader = new FileReader()
    reader.onload = function (e) {
      res({
        uploaded: false,
        name: file.name,
        size: humanReadableFileSize(file.size),
        dataURL: e.target?.result as string,
      })
    }
    reader.readAsDataURL(file)
  })
}
