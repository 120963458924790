import { useMutation } from '@truepill/tpos-react-router'
import { ReactComponent as UmbrellaIcon } from 'assets/icons/umbrella.svg'
import IconWrapper from 'components/IconWrapper'
import { ModalWrapper, ModalHeader, ButtonsContainer } from 'components/Modal'
import { ActionButton } from 'components/PageStructure'
import {
  AlternatingShading,
  ErrorText,
  ListRowLabel,
  ListRowValue,
  NoColorBackground,
  StyledRXImageCell,
} from 'components/RXTable'
import {
  ADD_COPAY_INSURANCE_OPTION,
  ADD_ORDER_INSURANCE_OPTION,
  UPDATE_COPAY_INSURANCE,
  UPDATE_ORDER_INSURANCE,
} from 'gql'
import useErrorToast from 'hooks/toast/useErrorToast'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import SelectionProvider from 'providers/SelectionProvider'
import styled from 'styled-components'
import { bodyPrimaryColor, confirmLight, primaryBackgroundColor, primaryColor } from 'styles/styleVariables'
import type { InsuranceValues, Insurance, EligibilityCheckReceived, ChildProps, QueueItem } from 'types'
import { diffObjects, isCopayRequest, isOrder, stripTypename } from 'utils'
import { relationshipCodes } from '../pages/fulfillment/orders/rx/screens/claims/ReviewSections/ReviewPayer/ServiceCodes'

interface ModalProps {
  data: EligibilityCheckReceived
  item?: QueueItem
}

const WrappedEligibilityCheckModal = ({ data, item }: ModalProps): JSX.Element => {
  return (
    <SelectionProvider>
      <EligibilityResponseModal data={data} item={item} />
    </SelectionProvider>
  )
}

type PatientProfileProps = { match: boolean } & ChildProps

const PatientProfile = ({ children, match }: PatientProfileProps) => {
  if (!match) {
    return null
  }
  return <PatientProfileCell>{children}</PatientProfileCell>
}

const EligibilityResponseModal = ({ item, data }: ModalProps): JSX.Element => {
  const order = isOrder(item) ? item : undefined
  const copayRequest = isCopayRequest(item) ? item : undefined

  const [addOrderInsuranceOption] = useMutation(ADD_ORDER_INSURANCE_OPTION)
  const [addCopayInsuranceOption] = useMutation(ADD_COPAY_INSURANCE_OPTION)
  const [updateOrderInsurance] = useMutation(UPDATE_ORDER_INSURANCE)
  const [updateCopayInsurance] = useMutation(UPDATE_COPAY_INSURANCE)

  const showErrorToast = useErrorToast()
  const { dismissModal } = useModalContext()
  const successfulResponse = data && data.groups && data.groups[0]?.coordinationOfBenefitsResponse
  const insuranceResponses = successfulResponse
    ? data.groups.map((i: any) => ({
        bin: i.coordinationOfBenefitsResponse.otherPayerId,
        pcn: i.coordinationOfBenefitsResponse.otherPayerProcessorControlNumber,
        group: i.coordinationOfBenefitsResponse.otherPayerGroupId,
        cardholderId: i.coordinationOfBenefitsResponse.otherPayerCardholderId,
        personCode: i.coordinationOfBenefitsResponse.otherPayerPersonCode,
        relationship: i.coordinationOfBenefitsResponse.otherPayerPatientRelationshipCode || '0',
        name: undefined,
        helpdeskNumber: i.coordinationOfBenefitsResponse.otherPayerHelpDeskPhoneNumber,
        governmentSponsoredPlan: false,
      }))
    : []

  const handleAddPayer = async (insurance: InsuranceValues) => {
    const fixedInsurance = { ...insurance }
    fixedInsurance.relationship = fixedInsurance.relationship
      ? relationshipCodes[parseInt(fixedInsurance.relationship)]
      : relationshipCodes[0]

    const result = order
      ? await addOrderInsuranceOption({
          variables: { orderId: order?._id, addInsurance: fixedInsurance },
        }).catch(err => showErrorToast('Failed to create new payer: ' + err))
      : copayRequest
      ? await addCopayInsuranceOption({
          variables: { copayRequestId: copayRequest._id, addInsurance: fixedInsurance },
        }).catch(err => showErrorToast('Failed to create new payer: ' + err))
      : undefined

    if (result) {
      dismissModal()
    }
  }

  const handleUpdatePayer = async (insurance: InsuranceValues, insuranceOptionMatch: Insurance) => {
    const fixedInsurance = { ...insurance }
    fixedInsurance.relationship = fixedInsurance.relationship
      ? relationshipCodes[parseInt(fixedInsurance.relationship)]
      : relationshipCodes[0]
    const diff = stripTypename(diffObjects(insuranceOptionMatch, fixedInsurance))
    const { ...patch } = diff
    if (Object.keys(patch).length) {
      const result = order
        ? await updateOrderInsurance({
            variables: { orderId: order._id, insuranceId: insuranceOptionMatch._id, patch },
          }).catch(err => {
            showErrorToast('Failed to update payer: ' + err)
          })
        : copayRequest
        ? await updateCopayInsurance({
            variables: { copayRequestId: copayRequest._id, insuranceId: insuranceOptionMatch._id, patch },
          }).catch(err => {
            showErrorToast('Failed to update payer: ' + err)
          })
        : undefined

      if (result) {
        dismissModal()
      }
    }
  }

  return (
    <ModalWrapper id="EligibilityResponseModal">
      <ModalHeader>
        <IconWrapper>
          <UmbrellaIcon fill={bodyPrimaryColor} />
        </IconWrapper>
        <h2>Eligibility response</h2>
      </ModalHeader>
      {!successfulResponse && (
        <>
          <div>Status - {data?.header?.responseStatus}</div>
          <div>Message - {data?.metadata?.responseMessage?.message}</div>
        </>
      )}
      {successfulResponse && (
        <>
          <EligibilityListRow>
            <ResponseTitle>Response</ResponseTitle>
            <ResponseTitle>{order ? 'Order' : 'Copay Request'} Payer</ResponseTitle>
          </EligibilityListRow>
          {insuranceResponses.map((insurance: InsuranceValues, i) => {
            const perfectMatch = item?.insuranceOptions?.find(
              option =>
                option.bin === insurance.bin &&
                option.pcn === insurance.pcn &&
                option.cardholderId === insurance.cardholderId,
            )

            const insuranceOptionMatch = perfectMatch
              ? perfectMatch
              : item?.insuranceOptions?.find(option => option.bin === insurance.bin && option.pcn === insurance.pcn)

            const orderPayerRelationshipCode = relationshipCodes.findIndex(
              i => i === insuranceOptionMatch?.relationship,
            )
            const insuranceRelationshipName = insurance.relationship
              ? relationshipCodes[parseInt(insurance.relationship)]
              : relationshipCodes[0]

            return (
              <EligibilityResponseContainer key={i}>
                <EligibilityListRow>
                  <EligibilityResponseCell closeOffTopBorder>
                    <ListRowLabel>Name:</ListRowLabel>
                    <ListRowValue>{insurance?.name}</ListRowValue>
                  </EligibilityResponseCell>
                  <PatientProfile match={!!insuranceOptionMatch}>
                    <ListRowLabel>Name:</ListRowLabel>
                    <ListRowValue>{insuranceOptionMatch?.name}</ListRowValue>
                  </PatientProfile>
                </EligibilityListRow>
                <EligibilityListRow crossMatch={!!insuranceOptionMatch} noStripe={!!insuranceOptionMatch}>
                  <EligibilityResponseCell>
                    <ListRowLabel>BIN:</ListRowLabel>
                    <ListRowValue>{insurance?.bin}</ListRowValue>
                  </EligibilityResponseCell>
                  <PatientProfile match={!!insuranceOptionMatch}>
                    <ListRowLabel>BIN:</ListRowLabel>
                    <ListRowValue>{insuranceOptionMatch?.bin}</ListRowValue>
                  </PatientProfile>
                </EligibilityListRow>
                <EligibilityListRow crossMatch={!!insuranceOptionMatch} noStripe={!!insuranceOptionMatch}>
                  <EligibilityResponseCell>
                    <ListRowLabel>PCN:</ListRowLabel>
                    <ListRowValue>{insurance?.pcn}</ListRowValue>
                  </EligibilityResponseCell>
                  <PatientProfile match={!!insuranceOptionMatch}>
                    <ListRowLabel>PCN:</ListRowLabel>
                    <ListRowValue>{insuranceOptionMatch?.pcn}</ListRowValue>
                  </PatientProfile>
                </EligibilityListRow>
                <EligibilityListRow crossMatch={!!perfectMatch} noStripe={!!perfectMatch}>
                  <EligibilityResponseCell>
                    <ListRowLabel>Cardholder ID:</ListRowLabel>
                    <ListRowValue>{insurance.cardholderId}</ListRowValue>
                  </EligibilityResponseCell>
                  <PatientProfile match={!!insuranceOptionMatch}>
                    <ListRowLabel>Cardholder ID:</ListRowLabel>
                    <ListRowValue>{insuranceOptionMatch?.cardholderId}</ListRowValue>
                  </PatientProfile>
                </EligibilityListRow>
                <EligibilityListRow>
                  <EligibilityResponseCell>
                    <ListRowLabel>Group #:</ListRowLabel>
                    <ListRowValue>{insurance.group}</ListRowValue>
                  </EligibilityResponseCell>
                  <PatientProfile match={!!insuranceOptionMatch}>
                    <ListRowLabel>Group #:</ListRowLabel>
                    <ListRowValue>{insuranceOptionMatch?.group}</ListRowValue>
                  </PatientProfile>
                </EligibilityListRow>
                <EligibilityListRow>
                  <EligibilityResponseCell>
                    <ListRowLabel>Person code:</ListRowLabel>
                    <ListRowValue>{insurance.personCode}</ListRowValue>
                  </EligibilityResponseCell>
                  <PatientProfile match={!!insuranceOptionMatch}>
                    <ListRowLabel>Person code:</ListRowLabel>
                    <ListRowValue>{insuranceOptionMatch?.personCode}</ListRowValue>
                  </PatientProfile>
                </EligibilityListRow>
                <EligibilityListRow>
                  <EligibilityResponseCell>
                    <ListRowLabel>Relationship:</ListRowLabel>
                    <ListRowValue>
                      {insuranceRelationshipName} ({insurance.relationship})
                    </ListRowValue>
                  </EligibilityResponseCell>
                  <PatientProfile match={!!insuranceOptionMatch}>
                    <ListRowLabel>Relationship:</ListRowLabel>
                    <ListRowValue>
                      {insuranceOptionMatch?.relationship} ({orderPayerRelationshipCode})
                    </ListRowValue>
                  </PatientProfile>
                </EligibilityListRow>
                <EligibilityListRow>
                  <EligibilityResponseCell>
                    <ListRowLabel>Help desk:</ListRowLabel>
                    <ListRowValue>{insurance.helpdeskNumber}</ListRowValue>
                  </EligibilityResponseCell>
                  <PatientProfile match={!!insuranceOptionMatch}>
                    <ListRowLabel>Help desk:</ListRowLabel>
                    <ListRowValue>{insuranceOptionMatch?.helpdeskNumber}</ListRowValue>
                  </PatientProfile>
                </EligibilityListRow>
                <EligibilityListRow>
                  <EligibilityResponseCell closeOffBottomBorder>
                    <StyledButtonWrapper>
                      {!insuranceOptionMatch && (
                        <ButtonsContainer>
                          <StyledAddButton label={'Add to profile'} onClick={() => handleAddPayer(insurance)} />
                        </ButtonsContainer>
                      )}
                      {!!insuranceOptionMatch && !perfectMatch && (
                        <>
                          <ButtonsContainer>
                            <StyledAddButton label={'Add to profile'} onClick={() => handleAddPayer(insurance)} />
                            <StyledReplaceButton
                              label={'Replace in profile'}
                              onClick={() => handleUpdatePayer(insurance, insuranceOptionMatch)}
                            />
                          </ButtonsContainer>
                        </>
                      )}
                      {perfectMatch && insuranceOptionMatch && (
                        <ButtonsContainer>
                          <StyledReplaceButton
                            label={'Replace in profile'}
                            onClick={() => handleUpdatePayer(insurance, insuranceOptionMatch)}
                          />
                        </ButtonsContainer>
                      )}
                    </StyledButtonWrapper>
                  </EligibilityResponseCell>
                </EligibilityListRow>
              </EligibilityResponseContainer>
            )
          })}
        </>
      )}
    </ModalWrapper>
  )
}

const ResponseTitle = styled.p`
  font-weight: 500;
  margin-left: 1.25rem;
  margin-bottom: 0.625rem;
  :last-child {
    margin-left: 1.75rem;
  }
`

const EligibilityResponseContainer = styled.ul`
  margin-bottom: 1rem;
  width: 100%;
`

const StyledButtonWrapper = styled(ListRowValue)`
  justify-content: flex-end;
`

const StyledAddButton = styled(ActionButton)`
  height: 2.5rem;
  margin-left: 0.5rem;
  background-color: ${primaryBackgroundColor};
  color: ${bodyPrimaryColor};
  border: none;
`

const StyledReplaceButton = styled(StyledAddButton)`
  background-color: ${primaryColor};
  color: ${primaryBackgroundColor};
`

const RXEligiblityResponseRow = styled.ul<{ transparent?: boolean }>`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: [image] 1fr [edit] 1fr;
  ${({ transparent }) => transparent && NoColorBackground}
`

const EligibilityListRow = styled(RXEligiblityResponseRow)<{
  noStripe?: boolean
  crossMatch?: boolean
  error?: boolean
}>`
  ${({ error }) => error && ErrorText}
  ${({ noStripe }) => !noStripe && AlternatingShading}
  ${({ crossMatch }) =>
    crossMatch &&
    `background-color: ${confirmLight};
  `}
`

const EligibilityResponseCell = styled(StyledRXImageCell)`
  grid-template-columns: [label] 10rem [value] 15rem;
  font-family: Roboto;
  > h4 {
    font-family: roboto;
  }
  > label {
    padding-left: 1.25rem;
    font-weight: 500;
  }
`

const PatientProfileCell = styled.ul`
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  grid-row: 1;
  display: grid;
  padding-left: 2rem;
  grid-template-rows: auto;
  grid-template-columns: [label] 0.9fr [value] 1fr;
  grid-column: edit;
`

export default WrappedEligibilityCheckModal
