import { Box, Button, Header, Modal } from '@truepill/react-capsule'
import type { DURSubjectCategories, PatientSurveyInput } from '@truepill/tpos-types'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import styled from 'styled-components'
import DurEditingSection from './Form/DurEditingSection'
import SurveySection from './Form/SurveySection'
import type { FormValues } from './types'

const getDefaultConditions = (durInputs: DURSubjectCategories) => {
  const conditions: FormValues['conditions'] = durInputs.conditions
    ?.filter(a => a.patientSurveyInput)
    .map(a => ({
      term: a.patientSurveyInput ?? '',
      medispanData: { ...a } as FormValues['conditions'][number]['medispanData'],
    }))

  conditions.push({ term: '' })

  return conditions
}

const getDefaultAllergies = (durInputs: DURSubjectCategories) => {
  const allergies: FormValues['allergies'] = durInputs.allergies
    ?.filter(a => a.patientSurveyInput)
    .map(a => ({ term: a.patientSurveyInput ?? '', medispanData: { ...a } }))

  allergies.push({ term: '' })

  return allergies
}

const getDefaultMedications = (durInputs: DURSubjectCategories) => {
  const medications: FormValues['medications'] = durInputs.medications
    ?.filter(a => a.patientSurveyInput)
    .map(a => ({ term: a.patientSurveyInput ?? '', medispanData: { ...a } }))

  medications.push({ term: '' })

  return medications
}

interface EditUracModalProps {
  orderId: string
  survey: PatientSurveyInput
  currentDurInputs: DURSubjectCategories
  isOpen: boolean
  onClose: () => void
}

const DurInputMappingsModal = ({
  isOpen,
  orderId,
  currentDurInputs,
  survey,
  onClose,
}: EditUracModalProps): JSX.Element => {
  const handleUpdate = (data: FormValues) => {
    // Here we should probably filter fields with term: '' and empty medispanData
    console.info('TODO: Handling update', data)
  }

  const useFormProps = useForm<FormValues>({
    defaultValues: {
      allergies: getDefaultAllergies(currentDurInputs),
      conditions: getDefaultMedications(currentDurInputs),
      medications: getDefaultConditions(currentDurInputs),
    },
    mode: 'onBlur',
  })

  const { control, watch } = useFormProps

  const allergiesFieldArray = useFieldArray({ name: 'allergies', control })
  const medicationsFieldArray = useFieldArray({ name: 'medications', control })
  const conditionsFieldArray = useFieldArray({ name: 'conditions', control })

  const watchedAllergies = watch('allergies')
  const watchedConditions = watch('conditions')
  const watchedMedications = watch('medications')

  const appendHighlight = (type: 'allergies' | 'medications' | 'conditions', highlight: string) => {
    if (type === 'allergies') {
      const existingHighlight = allergiesFieldArray.fields.some(field => field.term === highlight)
      if (!existingHighlight) {
        allergiesFieldArray.append({ term: highlight })
      }
    } else if (type === 'medications') {
      const existingHighlight = medicationsFieldArray.fields.some(field => field.term === highlight)
      if (!existingHighlight) {
        medicationsFieldArray.append({ term: highlight })
      }
    } else if (type === 'conditions') {
      const existingHighlight = conditionsFieldArray.fields.some(field => field.term === highlight)
      if (!existingHighlight) {
        conditionsFieldArray.append({ term: highlight })
      }
    }
  }

  const removeHighlight = (type: 'allergies' | 'medications' | 'conditions', highlight: string) => {
    if (type === 'allergies') {
      const indexToRemove = allergiesFieldArray.fields.findIndex(field => field.term === highlight)
      if (indexToRemove !== -1) {
        allergiesFieldArray.remove(indexToRemove)
      }
    } else if (type === 'medications') {
      const indexToRemove = medicationsFieldArray.fields.findIndex(field => field.term === highlight)
      if (indexToRemove !== -1) {
        medicationsFieldArray.remove(indexToRemove)
      }
    } else if (type === 'conditions') {
      const indexToRemove = conditionsFieldArray.fields.findIndex(field => field.term === highlight)
      if (indexToRemove !== -1) {
        conditionsFieldArray.remove(indexToRemove)
      }
    }
  }

  return (
    <Modal
      overlayCss={{ zIndex: 999 }}
      css={{
        width: '72rem',
        height: 'auto',
        maxHeight: '80vh',
        maxWidth: 'none !important',
        padding: '1.5rem',
      }}
      isOpen={isOpen}
      onDismiss={onClose}
    >
      <Header variant="2xl" id="title" bold css={{ marginBottom: '1rem' }}>
        Edit URAC
      </Header>
      <FormProvider {...useFormProps}>
        <form onSubmit={useFormProps.handleSubmit(handleUpdate)}>
          <Content>
            <SurveySection
              survey={survey}
              defaultHighlights={{
                allergies: watchedAllergies?.map(({ term }) => term) ?? [],
                conditions: watchedConditions?.map(({ term }) => term) ?? [],
                medications: watchedMedications?.map(({ term }) => term) ?? [],
              }}
              onAppendHighlight={appendHighlight}
              onRemoveHighlight={removeHighlight}
            />
            <DurEditingSection />
          </Content>
          <Buttons>
            <Button type="button" variant="primary-text" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit">Update</Button>
          </Buttons>
        </form>
      </FormProvider>
    </Modal>
  )
}

const Buttons = styled(Box)`
  display: flex;
  gap: 0.5rem;
  justify-content: end;
  padding-top: 1.25rem;
`

const Content = styled(Box)`
  font-family: Lato;
  display: grid;
  grid-template-columns: 3fr 7fr;
`

export default DurInputMappingsModal
