import styled, { css } from 'styled-components'

const Wrapper = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.25rem;
`

export const SmallIconWrapper = styled.span<{ color?: string }>`
  ${Wrapper}
  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}

  > svg {
    max-width: 0.8rem;
    max-height: 0.8rem;
  }
`

const IconWrapper = styled.span<{ color?: string }>`
  ${Wrapper}
  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}

  > svg {
    max-width: 1.25rem;
    max-height: 1.25rem;
  }
`
export default IconWrapper

export const IconOnlyButton = styled.button`
  border: none;
  background-color: transparent;
  :first-of-type {
    padding-left: 0rem;
  }
`

export const LargeIconWrapper = styled.span<{ color?: string; desaturate?: boolean; opacity?: number }>`
  ${Wrapper}
  ${({ color, desaturate, opacity }) =>
    (color || desaturate || opacity) &&
    css`
      ${opacity ? `opacity: ${opacity};` : ''}
      ${color ? `color: ${color};` : ''}
      ${desaturate ? 'filter: saturate(0);' : ''}
    `}

  > svg {
    max-width: 1.75rem;
    max-height: 1.75rem;
  }
`

export const XLIconWrapper = styled.span<{ color?: string }>`
  ${Wrapper}
  width: 2rem;
  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}

  > svg {
    width: 2rem;
    height: auto;
  }
`
