import { useState } from 'react'
import { Button } from '@truepill/react-capsule'
import { ModalWrapper, ModalHeader, InputContainer, ButtonsContainer } from 'components/Modal'
import { TwoColumnDivision } from 'components/RXTable'
import RejectionMessages from 'pages/fulfillment/orders/rx/screens/claims/RejectionMessages'
import EditCOBOverrideSection from 'pages/fulfillment/orders/rx/screens/claims/ReviewSections/ReviewPayer/EditSections/EditCOBSections/EditCOBOverrideSection'
import EditDURServiceCodes from 'pages/fulfillment/orders/rx/screens/claims/ReviewSections/ReviewPayer/EditSections/EditDURServiceCodes'
import EditPayerDetails from 'pages/fulfillment/orders/rx/screens/claims/ReviewSections/ReviewPayer/EditSections/EditPayerDetails'
import EditPayerOverrideSection from 'pages/fulfillment/orders/rx/screens/claims/ReviewSections/ReviewPayer/EditSections/EditPayerOverrideSection'
import SelectionProvider from 'providers/SelectionProvider'
import styled, { css } from 'styled-components'
import type { OrderInsurance, InsuranceOverrideCodes, InsuranceDur, InsuranceOverrideCob, Claim } from 'types'

interface EditPayerModalProps {
  originalOrderInsurance: OrderInsurance
  payerIndex: number
  onUpdate: (updatedOrderInsurances: OrderInsurance) => Promise<void>
  onClose: () => Promise<void>
  claimJumperErrors: string[]
  claims: Claim[]
}

const WrappedEditPayerModal = (props: EditPayerModalProps): JSX.Element => {
  return (
    <SelectionProvider>
      <EditPayerModal {...props} />
    </SelectionProvider>
  )
}

const EditPayerModal = (props: EditPayerModalProps) => {
  const { originalOrderInsurance, payerIndex, onUpdate, onClose, claimJumperErrors, claims } = props
  const [orderInsurance, setOrderInsurance] = useState(originalOrderInsurance)

  const handleChange = (name: string, value: string) => {
    const updatedOrderInsurances = {
      ...orderInsurance,
      ...{ [name]: value },
    }

    setOrderInsurance(updatedOrderInsurances)
  }

  const handleOverrideCodesChange = (name: keyof InsuranceOverrideCodes, key: string, value?: any) => {
    const realValue = value === '(~) Clear' ? '' : value

    const activeOverrideCodes = orderInsurance?.overrideCodes
    const updatedOverrideCodes: any = {
      ...activeOverrideCodes,
      orderInsuranceOptionId: orderInsurance.orderInsuranceOptionId,
    }

    if (!updatedOverrideCodes[name]) {
      updatedOverrideCodes[name] = {}
    }
    if (name === 'priorAuthorizationNumberSubmitted') {
      updatedOverrideCodes[name] = realValue
    } else {
      updatedOverrideCodes[name][key] = realValue
    }

    const updatedOrderInsurances = {
      ...orderInsurance,
      overrideCodes: updatedOverrideCodes,
    }

    setOrderInsurance(updatedOrderInsurances)
  }

  const handleDurChange = (
    durIndex: number,
    name: keyof InsuranceDur,
    setFormDUR: (value: InsuranceDur[]) => void,
    value?: string,
  ) => {
    const realValue = value === '(~) Clear' ? '' : value

    const activeDur = orderInsurance?.durs?.[durIndex]

    const dur = {
      ...activeDur,
      ...{ [name]: realValue },
      orderInsuranceOptionId: orderInsurance.orderInsuranceOptionId,
    }

    const updatedDurs = orderInsurance?.durs?.slice() || []
    updatedDurs[durIndex] = dur

    const updatedOrderInsurances = {
      ...orderInsurance,
      durs: updatedDurs,
    }

    setOrderInsurance(updatedOrderInsurances)
    setFormDUR(updatedDurs)
  }

  const handleDurAddUpdate = (durs: InsuranceDur[], setFormDUR: (value: InsuranceDur[]) => void) => {
    const updatedOrderInsurances = {
      ...orderInsurance,
      durs,
    }

    setOrderInsurance(updatedOrderInsurances)
    setFormDUR(durs)
  }

  const generateOverrideCOBArrayObject = (
    updatedCobOverrides: any,
    name: string,
    key: string,
    value: any,
    index: number,
  ) => {
    const checkArray = Array.isArray(updatedCobOverrides[name][key])

    // override the existing value to accomodate new set of input fields and rerender
    if (index === -1) {
      updatedCobOverrides[name][key] = value
      return updatedCobOverrides
    }

    // add new value to empty array
    if (checkArray && updatedCobOverrides[name][key]?.length === 0) {
      updatedCobOverrides[name][key] = [value]
    }

    //update if the value already exist
    if (checkArray && updatedCobOverrides[name][key]?.length > 0) {
      updatedCobOverrides[name][key][index] = { ...updatedCobOverrides[name][key][index], ...value }
    }

    return updatedCobOverrides
  }

  const handleOverrideCOBChange = (name: keyof InsuranceOverrideCob, key: string, value?: any, index = 0) => {
    let realValue = value.qualifier === '(~) Clear' ? { qualifier: '' } : value
    realValue = realValue === '(~) Clear' ? '' : realValue

    const activeOverrideCob = orderInsurance?.cobOverrides

    let updatedCobOverrides: any = {
      ...activeOverrideCob,
      orderInsuranceOptionId: orderInsurance.orderInsuranceOptionId,
    }
    const isArrayofObjectflag =
      key === 'value' &&
      ['otherPayerAmountPaid', 'otherPayerPatientResponsibilityAmount', 'benefitStageAmount'].includes(name)

    if (!updatedCobOverrides[name] && !isArrayofObjectflag) {
      updatedCobOverrides[name] = {}
    }

    //initialize the value to an array if the name or key does not exist on COB override object
    if ((!updatedCobOverrides[name] || !updatedCobOverrides[name][key]) && isArrayofObjectflag) {
      updatedCobOverrides[name] = { [key]: [], send: true }
    }

    if (isArrayofObjectflag) {
      updatedCobOverrides = {
        ...updatedCobOverrides,
        ...generateOverrideCOBArrayObject(updatedCobOverrides, name, key, realValue, index),
      }
    } else {
      updatedCobOverrides[name][key] = realValue
    }
    const updatedOrderInsurances = {
      ...orderInsurance,
      cobOverrides: updatedCobOverrides,
    }

    setOrderInsurance(updatedOrderInsurances)
  }

  const [currentTab, setCurrentTab] = useState('Payer')

  const tabStyle = {
    margin: '12px 0 0 0',
    width: 'fit-content',
    border: 'none',
    'border-bottom': '2px solid #E9ECF0',
    'border-radius': '0',
    color: 'black',
    hover: {
      'background-color': 'none',
    },
  }

  const tabStyleActive = {
    ...tabStyle,
    color: 'var(--cap-colors-primary-700)',
    'border-bottom': '2px solid var(--cap-colors-primary-700)',
  }

  const rejectionMessagesStyle = css`
    min-height: 36px;
    max-height: 999px;
    display: block;
    width: 100%;
    border-bottom: 0.125rem solid #cccccc;
    margin: 8px 0 16px 0;
    padding: 0;
  `

  return (
    <ModalWrapper id="RejectModal">
      <ModalHeader closeCallback={onClose}>
        <h1>Edit payer</h1>
      </ModalHeader>
      <ConstrainedInputContainer>
        <h2>Response</h2>
        <RejectionMessages
          rxImageIsPic={false}
          claimJumperErrors={claimJumperErrors}
          claims={claims}
          containerStyles={rejectionMessagesStyle}
        />
        <ButtonsContainerLeft>
          <Button
            variant="dark-text"
            style={currentTab === 'Payer' ? tabStyleActive : tabStyle}
            size="sm"
            onClick={() => {
              setCurrentTab('Payer')
            }}
          >
            Payer
          </Button>
          <Button
            variant="dark-text"
            style={currentTab === 'Overrides' ? tabStyleActive : tabStyle}
            size="sm"
            onClick={() => {
              setCurrentTab('Overrides')
            }}
          >
            Overrides
          </Button>
          <Button
            variant="dark-text"
            style={currentTab === 'COB' ? tabStyleActive : tabStyle}
            size="sm"
            onClick={() => {
              setCurrentTab('COB')
            }}
          >
            COB
          </Button>
        </ButtonsContainerLeft>
        {currentTab === 'Payer' && (
          <EditPayerDetails handleChange={handleChange} payerInfo={orderInsurance} payerIndex={payerIndex} />
        )}
        {currentTab === 'Overrides' && (
          <Columns style={{ width: '70rem' }}>
            <EditDetails>
              <EditPayerOverrideSection handleChange={handleOverrideCodesChange} payerInfo={orderInsurance} />
            </EditDetails>
            <EditDetails>
              <EditDURServiceCodes
                handleChange={handleDurChange}
                handleAddUpdate={handleDurAddUpdate}
                payerInfo={orderInsurance}
              />
            </EditDetails>
          </Columns>
        )}
        {currentTab === 'COB' && (
          <EditDetails style={{ width: '70rem' }}>
            <EditCOBOverrideSection handleChange={handleOverrideCOBChange} payerInfo={orderInsurance} />
          </EditDetails>
        )}
      </ConstrainedInputContainer>
      <ButtonsContainer>
        <Button variant="primary-text" style={{ margin: '0.75rem 0 0 0.625rem' }} onClick={onClose}>
          Cancel
        </Button>
        <Button
          data-testid="UpdateButton"
          style={{ margin: '0.75rem 0 0 0.625rem' }}
          onClick={() => onUpdate(orderInsurance)}
        >
          Update
        </Button>
      </ButtonsContainer>
    </ModalWrapper>
  )
}

const ConstrainedInputContainer = styled(InputContainer)`
  max-width: 70rem;
`

const EditDetails = styled.div``

const Columns = styled.div`
  ${TwoColumnDivision}
  grid-gap: 1.25rem;
`

const ButtonsContainerLeft = styled(ButtonsContainer)`
  justify-content: left;
  padding-bottom: 24px;
  margin-top: 0px;
`

export default WrappedEditPayerModal
