import { createContext, useRef } from 'react'
import type { ChildProps } from 'types'

interface Funcs {
  openPopup: (url: string) => Window | null | undefined
  sendMessageToPopup: <T>(data: T) => void
  isPopupOpen: () => boolean
}

export interface PopUpProviderType extends Funcs {
  popup: Window | null | undefined
}

export const PopUpContext = createContext<Partial<PopUpProviderType>>({
  popup: undefined,
})

const PopUpProvider = ({ children }: ChildProps): JSX.Element => {
  const popupRef = useRef<Window | null | undefined>(undefined)

  const openPopup = (url: string) => {
    const windowFeatures = 'left=2000,top=0,width=1800,height=1200'
    popupRef.current = window.open(url, 'mozillaWindow', windowFeatures)
    return popupRef.current
  }

  const sendMessageToPopup = <T,>(data: T) => {
    if (popupRef.current) {
      popupRef.current.postMessage(data, '*')
    } else if (isPopupOpen()) {
      // if the popup storage flag is on but the reference is not present then there was a refresh and we need a new reference
      const newRef = openPopup('')
      newRef?.postMessage(data, '*')
    }
  }

  const isPopupOpen = () => {
    return !!localStorage.getItem('isPopupOpen')
  }

  const value = {
    isPopupOpen,
    sendMessageToPopup,
    openPopup,
    popup: popupRef.current,
  }

  return <PopUpContext.Provider value={value}>{children}</PopUpContext.Provider>
}

export default PopUpProvider
