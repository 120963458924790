import type { InsuranceData } from '../insurance/Insurance'
import type {
  EligibilityCheckGovernmentEdiSent,
  EligibilityCheckGovernmentEdiReceived,
  EligibilityCheckCommercialEdiSent,
  EligibilityCheckCommercialEdiReceived,
} from '../ncpdp'
import type { Gender } from '../patient'

export enum EligibilityType {
  Government = 'government',
  Commercial = 'commercial',
}

export enum ItemType {
  Order = 'order',
  Copay = 'copay',
}

export enum EligibilityCheckOrigin {
  API = 'api',
  UI = 'ui',
}

export enum EligibilityResponseOptions {
  accepted = 'accepted',
  rejected = 'rejected',
}

export interface EligibilityCheckInput {
  firstName: string
  lastName: string
  dob: string
  gender: Gender
  city?: string
  state?: string
  zip: string
  cardholderId?: string //(defaults to all 0s if none passed in)
  npi?: string //(defaults to Hayward NPI if none are passed in)
}

export interface RejectCode {
  rejectCode: string
  rejectMessage?: string
}

export interface EligibilityCheckBase {
  origin: EligibilityCheckOrigin
  input: EligibilityCheckInput
  transactionResponseStatus: EligibilityResponseOptions
  rejectCodes: RejectCode[]
  insurances: InsuranceData[]
  customerId?: string
  prescriptionId?: string
  patientId?: string
  orderId?: string
  copayRequestId?: string
}

export interface EligibilityCheckCommercial extends EligibilityCheckBase {
  type: 'commercial'
  sent: EligibilityCheckCommercialEdiSent
  received: EligibilityCheckCommercialEdiReceived
}

export interface EligibilityCheckGovernment extends EligibilityCheckBase {
  type: 'government'
  sent: EligibilityCheckGovernmentEdiSent
  received: EligibilityCheckGovernmentEdiReceived
}

export type EligibilityCheck = EligibilityCheckCommercial | EligibilityCheckGovernment

export interface EligibilityCheckOutput {
  sent: EligibilityCheckCommercialEdiSent | EligibilityCheckGovernmentEdiSent
  received: EligibilityCheckCommercialEdiReceived | EligibilityCheckGovernmentEdiReceived
  insurances: InsuranceData[]
}

// The following type(s) are consumed by the TPOS API to run an eligiblity check. Fields use _ instead of camelCase.
export interface EligibilityCheckApiRequest {
  insurance_type: string
  first_name: string
  last_name: string
  date_of_birth: string
  gender: EligibilityCheckGender
  city: string
  state: string
  zip: string
  cardholder_id?: string
}

export interface EligibilityCheckApiResponse {
  first_name: string
  last_name: string
  date_of_birth: string
  transaction_response_status: EligibilityCheckResponseStatus
  reject_codes?: EligibilityCheckApiRejectCode[]
  insurances?: EligibilityCheckApiInsurance[]
}

export enum EligibilityCheckResponseStatus {
  Accepted = 'accepted',
  Rejected = 'rejected',
}

export interface EligibilityCheckApiRejectCode {
  reject_code: string
  reject_message: string
}

export interface EligibilityCheckApiInsurance {
  other_payer_coverage_type?: string
  bin: string
  pcn?: string
  group_id: string
  cardholder_id: string
  person_code: string
  help_desk_phone_number?: string
  relationship_code?: string
  benefit_effective_date?: string
}

export enum EligibilityCheckGender {
  Male = 1,
  Female = 2,
}
