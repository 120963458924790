import { Card } from '@truepill/react-capsule'
import styled from 'styled-components'

type CardWithBorderProps = {
  borderColor: string
}

/*
 *
 * @param borderColor -> Allows to add borderColor on the card as a property
 *
 */

const CardWithBorder = styled(Card).attrs({
  shadow: 'none',
})<CardWithBorderProps>`
  border-left: 7px solid ${(props: { borderColor: string }) => props.borderColor};
  padding: 5px;
  width: 100%;
  border-radius: 5px;
`

export default CardWithBorder

/*
Example of usage:

<CardWithBorder>
  <ComponentA
    css={{
      borderRadius: '$sm',
      backgroundColor: '$white',
      }}
    type='single'
    collapsible>
    <Component B>
      <div style={{ color: 'red' }}>
        <Text>Hello world!</Text>
      </div>
    </Component B>
  </Component A>
</CardWithBorder>
*/
