import { OrderStatus, RxFillRequestStatus } from '@truepill/tpos-types'
import type { GetNextOrderFilters } from 'hooks/useGetNextOrder'
import type { Order, Fill, FillInfoType } from 'types'

export const orderCanBeFilled = (order: Order): boolean => {
  return order.rxFillRequests.every(({ status }) =>
    [RxFillRequestStatus.Fill, RxFillRequestStatus.Automation, RxFillRequestStatus.PV2].includes(status),
  )
}

const orderStatusList = Object.values(OrderStatus)

// If you need to sort by orderStatus, this function returns a numerical value
// for the status according to an order's progress
export const orderStatusValue = (order: Order): number => {
  return orderStatusList.indexOf(order.status)
}

export const nextOrderFillWithStatus = (
  order: Order,
  status?: RxFillRequestStatus,
  fillId?: Fill['_id'],
  filters?: GetNextOrderFilters,
): FillInfoType | undefined => {
  if (!order || !order.rxFillRequests) {
    return
  }
  for (const rxFillRequest of order.rxFillRequests) {
    const { fill } = rxFillRequest

    // checks if only triages fills are needed
    const isTriage = order.triages && order.triages.some(t => t.rxFillRequestId === rxFillRequest._id)
    if (!filters?.showTriage && isTriage) continue

    if (fill._id !== fillId && (!status || rxFillRequest.status === status)) {
      return {
        fillId: fill._id,
        rxId: rxFillRequest.prescription._id,
        orderId: order._id,
      }
    }
  }
}
