import { useEffect } from 'react'
import { LaunchDarkly, OrderStatus } from '@truepill/tpos-types'
import { ReactComponent as CheckboxIcon } from 'assets/icons/checkmark.svg'
import HorizontalItemList, {
  Container as HorizontalItemListContainer,
} from 'components/OrderView/components/HorizontalItemList'
import { useClient, useFlag } from 'providers/LaunchDarklyProvider'
import type { Task } from 'providers/TaskProvider'
import { useTaskContext } from 'providers/TaskProvider'
import styled from 'styled-components'
import EllipsisTruncate from 'styles/EllipsisTruncate'
import type { Order, OTCProduct } from 'types'
import OTCPackingItemListAccuracy from './OTCPackingItemListAccuracy'

const OTCPackingItemList = (props: { order: Order }) => {
  const { order } = props

  const shouldShowOtcAdjudicationAltUI = useFlag(LaunchDarkly.FeatureFlags.TEMP_OTC_ADJUDICATION_HIDE_RX_COMPONENT)

  // Set the customer id for upcoming feature-flag calls
  const { client: ldClient } = useClient()

  const customerLegacyId = order.customer?.legacyId

  const { tasks } = useTaskContext()
  useEffect(() => {
    if (customerLegacyId) {
      ldClient?.identify({ key: customerLegacyId.toString() })
    }
  }, [ldClient, customerLegacyId])

  const accuracyScanning = useFlag(LaunchDarkly.FeatureFlags.ENABLE_PACKING_ACCURACY_SCANNING)

  if (!order.otcProducts) {
    return <HorizontalItemListContainer />
  }

  if (accuracyScanning) {
    return <OTCPackingItemListAccuracy order={order} />
  }

  return (
    <HorizontalItemList
      items={order.otcProducts}
      mapItem={otcProduct => {
        const fillRequest = order?.rxFillRequests.find(r => r.otcProductId?.toString() === otcProduct?._id?.toString())
        // if there is a request associated to the product, then we show the task checkbox in packing
        const task = fillRequest?.fill ? tasks.find(task => task.code === fillRequest?.fill.shortFillCode) : undefined
        const isTaskCompleted = !!task?.completed
        const showScanCheckbox: boolean =
          order.status === OrderStatus.Packing && !!task && shouldShowOtcAdjudicationAltUI
        return { product: otcProduct, showScanCheckbox, isTaskCompleted }
      }}
      getItemKey={item => item.product._id}
      renderItem={(item: { product: OTCProduct; showScanCheckbox: boolean; isTaskCompleted: boolean }) => (
        <OTCItem
          product={item.product}
          showScanCheckbox={item.showScanCheckbox}
          isTaskCompleted={item.isTaskCompleted}
        />
      )}
    />
  )
}

export const checkProduct = (tasks: Task[], taskKey: string, skuCode: string): Task | undefined => {
  return tasks.find(t => t.key === taskKey && (t.code === skuCode || t.completed))
}

const OTCItem = (props: { product: OTCProduct; showScanCheckbox: boolean; isTaskCompleted?: boolean }): JSX.Element => {
  const { product, showScanCheckbox, isTaskCompleted } = props

  return (
    <div data-testid="OtcItem" data-sku={product.sku}>
      {showScanCheckbox && (
        <ScanCheckbox completed={isTaskCompleted}>{isTaskCompleted && <CheckboxIcon />}</ScanCheckbox>
      )}
      <OTCItemHeader>
        <FlexRowContainer>
          <HeaderLabel>Item:</HeaderLabel>
          <HeaderText data-testid="sku">{product.name}</HeaderText>
        </FlexRowContainer>
      </OTCItemHeader>
      <OTCItemBody>
        <Info>
          <FlexRowContainer>
            <ContentLabel>SKU:</ContentLabel>
            <Text data-testid="name">{product.sku ?? 'N/A'}</Text>
          </FlexRowContainer>
          <FlexRowContainer>
            <ContentLabel>Quantity:</ContentLabel>
            <Text data-testid="quantity">{product.quantity}</Text>
          </FlexRowContainer>
        </Info>
      </OTCItemBody>
    </div>
  )
}

const OTCItemHeader = styled.div`
  display: flex;
`

const OTCItemBody = styled.div`
  display: flex;
`

const FlexRowContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`

const HeaderLabel = styled.p`
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 700;
`

const ContentLabel = styled.p`
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 700;
`

const Text = styled.p`
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin-left: 0.2rem;
`

const HeaderText = styled(Text)`
  font-size: 16px;
`

const Info = styled.div`
  font-size: 1rem;
  display: flex;
  flex: 1;
  justify-content: space-between;

  > p {
    ${EllipsisTruncate}
  }
`

const ScanCheckbox = styled.div<{ completed?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 28px;
  height: 28px;
  background: ${({ completed }) => (completed ? '#034d83' : '#FFFFFF')};
  border-radius: 56px;
  margin-right: 10px;
  border: 2px solid #034d83;
`

export default OTCPackingItemList
