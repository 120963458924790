import { useCallback, useEffect, useMemo } from 'react'
import { useMutation, useHistory, useLocation } from '@truepill/tpos-react-router'
import TaskList from 'components/TaskList'
import { CONFIRM_FILL_SCAN } from 'gql'
import useErrorToast from 'hooks/toast/useErrorToast'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import useAutoUpdatingRef from 'hooks/useAutoUpdatingRef'
import useBarcodeScanner, { REGEXES } from 'hooks/useBarcodeScanner'
import useDevToolBarcode from 'hooks/useDevtoolBarcode'
import { FulfillmentQueueName } from 'hooks/useFulfillmentQueue'
import type { Task } from 'providers/TaskProvider'
import { useTaskContext } from 'providers/TaskProvider'
import { usePlusClient } from 'providers/VisionRouter'
import type { Fill, Order, Prescription } from 'types'
import { getNextRxFillRequest, isScannedCodeAnAlternateBarCode, parseGS1String } from 'utils'
import { validateNdcBarcode } from 'utils/ndc'

const useInitTasks = (fill: Fill, ndc: string): void => {
  const { dispensed, shortFillCode } = fill
  const { initTasks } = useTaskContext()
  const initTasksRef = useAutoUpdatingRef(initTasks)

  useEffect(() => {
    const scanFillLabel = 'Scan fill bar code'
    const tasks: Task[] = [
      {
        label: scanFillLabel,
        key: 'scanFill',
        completed: false,
        code: shortFillCode,
      } as Task,
    ]

    for (let i = 0; i < (dispensed.packagesRequired || 1); i++) {
      const label = `Scan stock product ${i + 1} (NDC) bar code`

      tasks.push({
        key: 'scanProduct-' + i,
        label,
        completed: false,
        code: ndc,
      } as Task)
    }

    initTasksRef.current(tasks)
  }, [initTasksRef, dispensed, ndc, shortFillCode, fill])
}

const useSubmitOnComplete = (tasks: Task[], fill: Fill, order: Order, manualEntryFilled: boolean) => {
  const { initTasks } = useTaskContext()
  const showErrorToast = useErrorToast()
  const showErrorToastRef = useAutoUpdatingRef(showErrorToast)
  const showSuccessToast = useSuccessToast(true)
  const showSuccessToastRef = useAutoUpdatingRef(showSuccessToast)
  const history = useHistory()
  const { search } = useLocation()
  const { routeTo, tokenContext } = usePlusClient()

  const fulfillmentQueueName = FulfillmentQueueName.Fill
  const [confirmFill] = useMutation(CONFIRM_FILL_SCAN)

  useEffect(() => {
    // Don't submit on init
    if (tasks.length < 2) {
      return
    }

    const allComplete = tasks.every(({ completed }) => completed)
    if (!allComplete) {
      return
    }

    ;(async () => {
      try {
        if (!order) {
          return
        }

        if (order.inTriage) {
          throw new Error('unable to confirm a fill while it is in Triage')
        }

        initTasks([])
        await confirmFill({
          variables: {
            orderId: order._id,
            fillId: fill._id,
            ndc: fill.dispensed.ndc,
            manualEntryFilled: manualEntryFilled,
          },
        })

        showSuccessToastRef.current('Step complete')

        const { nextFill } = getNextRxFillRequest(order, fill, fulfillmentQueueName)

        const nextRoute = nextFill
          ? routeTo.fulfillment(order._id, nextFill.fill._id, fulfillmentQueueName)
          : routeTo.fulfillmentQueue(fulfillmentQueueName, {
              searchMap: {
                locationId: tokenContext.locationId,
              },
            })

        nextRoute.now()
      } catch (e) {
        showErrorToastRef.current('Failed to submit: ' + e.message)
        console.error(e)
      }
    })()
  }, [
    routeTo,
    tokenContext.locationId,
    tasks,
    fill,
    showSuccessToastRef,
    showErrorToastRef,
    confirmFill,
    history,
    search,
    fulfillmentQueueName,
    initTasks,
    order,
    manualEntryFilled,
  ])
}

type FillTaskListProps = {
  fill: Fill
  prescription: Prescription
  order: Order
  alternateBarCodes: string[]
  manualEntryFilled: boolean
}

const FillTaskList = ({
  fill,
  prescription,
  order,
  alternateBarCodes,
  manualEntryFilled,
}: FillTaskListProps): JSX.Element => {
  const { tasks, completeTask } = useTaskContext()
  const showErrorToast = useErrorToast()
  const showErrorToastRef = useAutoUpdatingRef(showErrorToast)
  const showSuccessToast = useSuccessToast(true)
  const showSuccessToastRef = useAutoUpdatingRef(showSuccessToast)

  const { registerListener, deregisterListener } = useBarcodeScanner()

  useInitTasks(fill, fill.dispensed.ndc)
  useSubmitOnComplete(tasks, fill, order, manualEntryFilled)

  // Open chrome devtools -> sources for a preview barcode
  // Must add localStorage item: devtoolBarcode=1
  useDevToolBarcode(tasks.map(({ label, code }) => ({ label, code })))

  const fillLabelScanned = useMemo(() => !!tasks.find(({ key }) => key === 'scanFill')?.completed, [tasks])

  // product scan task keys are scanProduct-Num after the scanFill task, so
  // find what the next one will be
  const nextProductScanTaskIndex = useMemo(
    () => tasks.findIndex(({ key, completed }) => !completed && key.includes('scanProduct')) - 1,
    [tasks],
  )

  const handleShortcodeScan = useCallback(
    (value: string) => {
      if (fill.shortFillCode === value) {
        completeTask('scanFill')
      }
    },
    [completeTask, fill],
  )

  const handleNDCScan = useCallback(
    (value: string) => {
      // currently doesn't support hyphenated barcodes

      if (!fillLabelScanned) {
        showErrorToastRef.current('Please scan the Fill label before scanning a product')
        return
      }

      let scannedNdcIsAMatch: string | boolean | undefined = validateNdcBarcode(value, fill.dispensed.ndc)

      if (!scannedNdcIsAMatch && alternateBarCodes.length > 0) {
        // Fallback alternate bar codes
        scannedNdcIsAMatch = isScannedCodeAnAlternateBarCode(value, alternateBarCodes)
      }

      if (!scannedNdcIsAMatch) {
        showErrorToastRef.current('Incorrect stock product (GTIN) barcode scanned.')
        console.error('Incorrect stock product (GTIN) barcode scanned.')
        return
      }

      showSuccessToastRef.current('Scanned product: ' + value)
      completeTask('scanProduct-' + nextProductScanTaskIndex, fill.dispensed.ndc)
    },
    [
      fill,
      fillLabelScanned,
      nextProductScanTaskIndex,
      completeTask,
      showErrorToastRef,
      showSuccessToastRef,
      alternateBarCodes,
    ],
  )

  const handleGS1Scan = useCallback(
    (value: string) => {
      console.debug('GS1 scan value:', value)
      const parsedGS1 = parseGS1String(value)
      console.debug('GS1 parsed value:', parsedGS1)
      const parsedGTIN = parsedGS1['01']
      if (parsedGTIN) {
        handleNDCScan(parsedGTIN)
      }
    },
    [handleNDCScan],
  )

  useEffect(() => {
    registerListener(REGEXES.ShortFillCode, handleShortcodeScan)
    registerListener(REGEXES.NDC, handleNDCScan)
    registerListener(REGEXES.GS1, handleGS1Scan)
    return () => {
      deregisterListener(REGEXES.ShortFillCode, handleShortcodeScan)
      deregisterListener(REGEXES.NDC, handleNDCScan)
      deregisterListener(REGEXES.GS1, handleGS1Scan)
    }
  }, [handleShortcodeScan, handleNDCScan, handleGS1Scan, deregisterListener, registerListener])

  const formattedTasks = useMemo(() => {
    return tasks.map(({ label, completed }) => {
      return { label, completed }
    })
  }, [tasks])

  return <TaskList tasks={formattedTasks} />
}

export default FillTaskList
