import type { FunctionComponent } from 'react'
import { createContext, useCallback, useContext, useState } from 'react'
import type { DocumentNode, NormalizedCacheObject } from '@apollo/client'
import { ApolloClient, ApolloLink, ApolloProvider, createHttpLink, InMemoryCache, split } from '@apollo/client'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from '@apollo/client/utilities'
import { createUploadLink } from 'apollo-upload-client'
import * as dotProp from 'dot-prop'
import { extractFiles } from 'extract-files'
import type { OperationDefinitionNode } from 'graphql'
import { createClient } from 'graphql-ws'
import jwt_decode from 'jwt-decode'
import moment from 'moment'
import { useCookie } from 'react-use'

const OPERATION_NAME_HEADER = 'X-APOLLO-OPERATION-NAME' // Used to match requests in Cypress tests
export interface ClientProps {
  children?: any
  path: string
  skipBatchOps?: string[]
  loginQuery: [DocumentNode, ((loginQueryResult: any) => string) | string]
  userProfileQuery: [DocumentNode, ((loginQueryResult: any) => string) | string]
  userUpsertMutation: [DocumentNode, ((loginQueryResult: any) => string) | string]
  transformToken?: (decodedToken: any) => any
}

interface ClientContextStore {
  client: ApolloClient<NormalizedCacheObject>
  token: string
  loginUser: (username: string, password: string) => Promise<boolean>
  loginUserAuth0: (token: string, newLogin: boolean) => Promise<boolean>
  logoutUser: () => void
  tokenContext: any
  isAuthenticated: boolean
}

export const ClientContextDefaults: ClientContextStore = {
  client: new ApolloClient({
    uri: '',
    cache: new InMemoryCache(),
  }),
  token: '',
  loginUser: () => Promise.resolve(false),
  loginUserAuth0: () => Promise.resolve(false),
  logoutUser: () => undefined,
  tokenContext: {},
  isAuthenticated: false,
}

export const ClientContext = createContext<ClientContextStore>(ClientContextDefaults)

export const useClient = function useClientContext() {
  const clientContextValues = useContext(ClientContext)

  return clientContextValues
}

interface UserData {
  _id: string
  email: string
  firstName: string
  forcePasswordReset: boolean
  lastName: string
  locationId: string
  roles: string[]
  username: string
}

interface GraphQlBasicRequestOptions {
  headers?: Record<string, string>
  uri: string
  variables: Record<string, any>
}

interface GraphQlQueryRequest<T extends Record<string, any>> extends GraphQlBasicRequestOptions {
  query: DocumentNode
  variables: T
}

interface GraphQlMutationRequest<T extends Record<string, any>> extends GraphQlBasicRequestOptions {
  mutation: DocumentNode
  variables: T
}

type GraphQlRequestOptions<T extends Record<string, any>> = GraphQlQueryRequest<T> | GraphQlMutationRequest<T>

type DecodedPayload = {
  exp: number
  firstName: string
  forcePasswordReset: boolean
  id: string
  lastName: string
  locationId: string
  scope: string[]
  user: string
}

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, path }) => {
      console.error(`[GraphQL error]: Message: ${message}, Path: ${path}`)
    })
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError}`)
  }
})

/** function to create a quick one-time graphql request */
const graphQlRequest = async <Variables extends Record<string, any>, Result = any>(
  options: GraphQlRequestOptions<Variables>,
): Promise<Result> => {
  const { headers, uri, variables } = options
  const authorizeClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([errorLink, createHttpLink({ headers, uri })]),
  })

  if ('query' in options) {
    const { data } = await authorizeClient.query<Result, Variables>({
      query: options.query,
      variables,
    })
    return data
  }

  const { data } = await authorizeClient.mutate<Result, Variables>({
    mutation: options.mutation,
    variables,
  })

  return data as Result
}

export const Client: FunctionComponent<ClientProps> = ({
  path,
  children,
  loginQuery,
  userProfileQuery,
  userUpsertMutation,
  skipBatchOps = [],
  transformToken = (decodedToken: any) => decodedToken,
}) => {
  const [tposToken = '', setTposToken, deleteTposToken] = useCookie('jwtToken')
  const [userData, setUserData] = useState<{ user: UserData; token: string } | undefined>(undefined)
  const token = tposToken || userData?.token // default to TPOS token. Reset password page will not login the user if they started with an Auth0 token otherwise
  const loginUser = async (username: string, password: string): Promise<boolean> => {
    const [query, dotPath] = loginQuery
    const data = await graphQlRequest({
      headers: {
        authorization: `Basic ${btoa(`${encodeURIComponent(username)}:${encodeURIComponent(password)}`)}`,
        [OPERATION_NAME_HEADER]: 'login',
      },
      query,
      uri: path,
      variables: { username, password },
    })

    const myToken = (typeof dotPath === 'string' ? dotProp.get(data, dotPath) : dotPath(data)) as string

    if (myToken) {
      setTposToken(myToken)
      return true
    }

    return false
  }

  const loginUserAuth0 = useCallback(
    async (token: string, newLogin: boolean) => {
      const [query, dotPath] = newLogin ? userUpsertMutation : userProfileQuery

      const data = await graphQlRequest({
        ...(newLogin ? { mutation: query } : { query }),
        headers: {
          authorization: `Bearer ${token}`,
          [OPERATION_NAME_HEADER]: 'loginAuth0',
        },
        uri: path,
        variables: {},
      })

      const user = (typeof dotPath === 'string' ? dotProp.get(data, dotPath) : dotPath(data)) as UserData

      if (token) {
        setUserData({ user, token })
        return true
      }
      return false
    },
    [path, userProfileQuery, userUpsertMutation],
  )

  const logoutUser = () => {
    deleteTposToken()
  }

  const wsLink = new GraphQLWsLink(
    createClient({
      url: path.replace(/^http/i, 'ws').replace('/graphql', '/subscribe'),
      connectionParams: {
        authentication: token,
      },
      on: {
        error: error => {
          console.error('[WebSocket error]:', error)
        },
      },
    }),
  )

  const uploadLink = createUploadLink({
    uri: path,
    headers: {
      authorization: `Bearer ${token}`,
    },
  })

  const batchHttpLink = new BatchHttpLink({
    uri: path,
    headers: {
      authorization: `Bearer ${token}`,
    },
  })

  const splitHttpLink = split(
    operation => {
      const hasSkipOp = skipBatchOps.includes(operation.operationName)
      const hasUpload = extractFiles(operation).files.size > 0
      return hasSkipOp || hasUpload
    },
    uploadLink as unknown as ApolloLink,
    batchHttpLink,
  )

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query) as OperationDefinitionNode
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    },
    wsLink,
    splitHttpLink,
  )

  const logOperationNameLink = new ApolloLink((operation, forward) => {
    // eslint-disable-next-line no-console
    console.debug(`Operation: ${operation?.operationName}`)
    return forward(operation)
  })

  const addOperationNameLink = setContext((operation, context) => {
    const operationName = operation?.operationName
    const headers = context.headers ?? {}
    return {
      ...context,
      headers: {
        ...headers,
        ...(operationName ? { [OPERATION_NAME_HEADER]: operationName } : {}),
      },
    }
  })

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([errorLink, logOperationNameLink, addOperationNameLink, splitLink]),
  })

  const decodeToken = (): DecodedPayload | undefined => {
    if (!token) return undefined

    const decoded = jwt_decode(token)

    if (typeof decoded === 'string') {
      return undefined
    }

    let { firstName, forcePasswordReset, id, lastName, locationId, scope, user } = decoded as unknown as DecodedPayload
    const { exp } = decoded as unknown as DecodedPayload

    if (isAuth0Token(decoded) && userData?.user) {
      // use user profile data
      id = userData.user._id
      firstName = userData.user.firstName
      forcePasswordReset = userData.user.forcePasswordReset
      lastName = userData.user.lastName
      locationId = userData.user.locationId
      scope = userData.user.roles
      user = userData.user.username
    }

    return { exp, firstName, forcePasswordReset, id, lastName, locationId, scope, user }
  }

  const isAuth0Token = (context: Record<string, any> | null) => {
    return context?.given_name && context?.family_name ? true : false
  }

  const decodedToken = decodeToken()

  const isExpired = decodedToken?.exp && moment().isAfter(moment(decodedToken.exp * 1000))

  if (decodedToken?.exp) {
    const timeLeft = (decodedToken?.exp || 0) * 1000 - Date.now()

    setTimeout(() => {
      logoutUser()
    }, timeLeft)
  }

  const value: ClientContextStore = {
    client,
    token: isExpired || !token ? '' : token,
    loginUser,
    loginUserAuth0,
    logoutUser,
    tokenContext: isExpired || !decodedToken ? {} : transformToken(decodedToken),
    isAuthenticated: !!token && !isExpired,
  }

  return (
    <ApolloProvider client={client}>
      <ClientContext.Provider value={value}>{children}</ClientContext.Provider>
    </ApolloProvider>
  )
}
export default Client
