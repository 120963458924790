import type { SelectValue } from 'components/Select'
export type ReturnProps = {
  label: string
  value: string
}
export const findOption = (
  value: SelectValue,
  options: Array<{ label: string; value: string }>,
): SelectValue | undefined => options.find(({ value: optionValue }) => optionValue === value.toString())

export const findFullOption = (
  value: SelectValue,
  options: Array<{ label: string; value: string }>,
): ReturnProps | undefined => options.find(({ value: optionValue }) => optionValue === value.toString())

export const getOptionsFromLabelMap = (
  optionsObject: Record<string, string>,
  addClearOption = false,
): ReturnProps[] => {
  const options = []

  if (addClearOption) {
    options.push({ label: '(~) Clear', value: '(~) Clear' })
  }

  return options.concat(Object.entries(optionsObject).map(([value, label]) => ({ label, value })))
}
