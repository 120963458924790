import { ReactComponent as EditIcon } from 'assets/icons/edit.svg'
import IconWrapper from 'components/IconWrapper'
import { ModalWrapper, ModalHeader } from 'components/Modal'
import NotesCreateOrUpdate from 'components/NotesPane/NotesCreateOrUpdate'
import UserInitialsBadge from 'components/UserInitialsBadge'
import moment from 'moment'
import styled from 'styled-components'
import { bodySecondaryColor, bodyPrimaryColor } from 'styles/styleVariables'
import type { Log, UserFirstLastName } from 'types'

type EditNoteModalProps = { note: Partial<Log>; excludeCritical?: boolean; excludeExternal?: boolean }

const EditNoteModal = ({ note, excludeCritical = false, excludeExternal = false }: EditNoteModalProps): JSX.Element => {
  const { updatedAt } = note
  const user = note.user as UserFirstLastName

  return (
    <ModalWrapper id="EditNoteModal">
      <ModalHeader>
        <IconWrapper>
          <EditIcon fill={bodyPrimaryColor} />
        </IconWrapper>
        <h2>Edit note</h2>
      </ModalHeader>
      <Row>
        <BadgeContainer data-testid="badge">
          <UserInitialsBadge user={user} />
        </BadgeContainer>
        <UserName data-testid="user">
          {user.firstName} {user.lastName}
        </UserName>
        <Date data-testid="date">{moment(updatedAt).format('MM/DD/YYYY, h:mmA')}</Date>
      </Row>
      <NotesCreateOrUpdate
        note={note}
        grabFocus
        isNewNote={false}
        excludeCritical={excludeCritical}
        excludeExternal={excludeExternal}
      />
    </ModalWrapper>
  )
}

const Row = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1rem;
`

const BadgeContainer = styled.span`
  font-size: 1.25rem;
`

const UserName = styled.p`
  font-weight: 500;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`

const Date = styled.p`
  color: ${bodySecondaryColor};
`

export default EditNoteModal
