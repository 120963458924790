import { CustomerReturnReason, CustomerReturnStatus } from '../entities/order/CustomerReturn'
import { OrderStatus } from '../entities/order/Order'
import { ShippingCarrier, ShippingMethod } from '../entities/order/Shipping'

/** This isn't a lookup value. */
export enum SnapFulfilCarrierId {
  Shippo = 'SHIPPO',
}

/** This isn't a lookup value. */
export enum SnapFulfilCustomerId {
  Optum = 'OPTUM',
}

/** This must be one of these two values, but isn't a lookup value. */
export enum SnapFulfilMaintenanceIndication {
  WarehouseMaintained = 'W',
  HostMaintained = 'W',
}

/** @see https://trpilltestapi.snapwarehouse.net/api/snaplookups/CURRENCY */
export enum SnapFulfilCurrency {
  GBP = 'GBP',
}

/** @see https://trpilltestapi.snapwarehouse.net/api/snaplookups/ORDTYPE */
export enum SnapFulfilOrderType {
  ReceiptSupplier = 'R01',
  ReceiptProduction = 'R02',
  ReceiptContract = 'R03',
  ReceiptWarehouse = 'R04',
  ReceiptValueAdd = 'R10',
  ReceiptMisc = 'R20',
  ShipmentCustomer = 'S01',
  ShipmentProduction = 'S02',
  ShipmentContract = 'S03',
  ShipmentWarehouse = 'S04',
  ShipmentValueAdd = 'S10',
  ShipmentMisc = 'S20',
}

/** @see https://trpilltestapi.snapwarehouse.net/api/snaplookups/PRIORITY */
export enum SnapFulfilPriority {
  Promoted = '00',
  Critical = '01',
  Urgent = '02',
  Normal = '03',
  Low = '04',
}

/** @see https://trpilltestapi.snapwarehouse.net/api/snaplookups/RECCLASS */
export enum SnapFulfilReturnClass {
  CustomerReturn = 'CUSRTN',
  UnknownReturn = 'CUSUNK',
  InterWarehouseRecept = 'INTWHS',
  InboundReturns = 'RETURN',
  StandardSupplierReceipt = 'STDREC',
  ValueAddReceipt = 'VALUEADD',
}

/** @see https://trpilltestapi.snapwarehouse.net/api/snaplookups/RECLSTAGE */
export enum SnapFulfilReceiptLineStage {
  NotStarted = '00',
  Active = '01',
  Unfulfilled = '10',
  Fulfilled = '20',
  Received = '90',
  Cancelled = '91',
  Purged = '99',
}

/** @see https://trpilltestapi.snapwarehouse.net/api/snaplookups/RECRETURN */
export enum SnapFulfilReturnReason {
  Refused = '01',
  Reject = '02',
  Excess = '03',
  CustomerClosed = '04',
}

/** @see https://trpilltestapi.snapwarehouse.net/api/snaplookups/RECSTATUS */
export enum SnapFulfilReturnStatus {
  Valid = '00',
  Cancelled = '01',
  Postponed = '02',
  Error = '80',
}

/** @see https://trpilltestapi.snapwarehouse.net/api/snaplookups/REGION */
export enum SnapFulfilRegion {
  Optum = 'OPTUM',
}

/** @see https://trpilltestapi.snapwarehouse.net/api/snaplookups/ROTATION */
export enum SnapFulfilRotation {
  FIFOStrict = 'F1',
  FIFOSlot = 'F2',
  FIFOStock = 'F3',
  LIFOStrict = 'L1',
  LIFOOther = 'L2',
  LowestSlotQty = 'X1',
  LargestSlotQty = 'X2',
  NoPreferredMethod = 'XX',
}

/** @see https://trpilltestapi.snapwarehouse.net/api/snaplookups/QACODE */
export enum SnapFulfilQACode {
  Free = '00',
  HoldQA = '01',
  Reject = '02',
  Sampled = '03',
  FirstDelivery = '05',
}

/** @see https://trpilltestapi.snapwarehouse.net/api/snaplookups/QCCODE */
export enum SnapFulfilQCCode {
  Free = '00',
  HoldQC = '01',
  FullCheck = '02',
}

/** @see https://trpilltestapi.snapwarehouse.net/api/snaplookups/SHIPCLASS */
export enum SnapFulfilOrderClass {
  InterWarehouseShipment = 'INTWHS',
  PackStationShipment = 'PAKDES',
  Production = 'PROD',
  NonPackStationShipment = 'STDDES',
  ValueAddShipment = 'VALUEADD',
}

/** @see https://trpilltestapi.snapwarehouse.net/api/snaplookups/SHIPLSTAGE */
export enum SnapFulfilShipmentLineStage {
  NotStarted = '00',
  Active = '10',
  Fulfilled = '20',
  Shipped = '90',
  Cancelled = '91',
  Purged = '99',
}

/** @see https://trpilltestapi.snapwarehouse.net/api/snaplookups/SHIPRETURN */
export enum SnapFulfilShipmentReturnReason {
  Refusal = '01',
  Reject = '02',
  Excess = '03',
  CustomerClosed = '04',
}
/** @see https://trpilltestapi.snapwarehouse.net/api/snaplookups/SHIPSTAGE */
export enum SnapFulfilShipmentStage {
  DueOut = '00',
  Allocate = '10',
  Allocated = '11',
  Release = '15',
  Released = '16',
  Pick = '20',
  Picking = '21',
  Picked = '29',
  Pack = '30',
  Packed = '39',
  Marshall = '40',
  Marshalling = '41',
  Marshalled = '49',
  Packing = '50',
  Loading = '61',
  Loaded = '69',
  Shipped = '70',
  Fulfilled = '90',
  Cancelled = '91',
  Purged = '99',
}

/** @see https://trpilltestapi.snapwarehouse.net/api/snaplookups/SHIPMETHOD */
export enum SnapFulfilShipmentMethod {
  OpFedex2Day = 'OP_FEDEX2',
  OpFedexGround = 'OP_FEDEXG',
  OpFEDEXPriorityOvernight = 'OP_FEDEXPO',
  OpFEDEXSmartPost = 'OP_FEDEXS',
  OpFEDEXStandardOvernight = 'OP_FEDEXSO',
  OpUPS2DayAir = 'OP_UPS2D',
  OpUPSGround = 'OP_UPSG',
  OpUPSNextDayAir = 'OP_UPSNXT',
  PoUSPSFirst = 'PO_USPSF',
  PoUSPSGroundAdv = 'PO_USPSGA',
  POUSPSPriorityExpress = 'PO_USPSE',
  POUSPSPriority = 'PO_USPSP',
  ShUSPSPriorityExpress = 'SH_USPSE',
  ShUSPSPriority = 'SH_USPSP',
  TpFEDEX2Day = 'TP_FEDEX2',
  TpFEDEXGround = 'TP_FEDEXG',
  TpFEDEXPriorityOvernight = 'TP_FEDEXPO',
  TpFEDEXSmartPost = 'TP_FEDEXS',
  TpFEDEXStandardOvernight = 'TP_FEDEXSO',
  TpDHLParcelExpedited = 'TP_DHL_EX',
  TpDHLParcelPlusExpedited = 'TP_DHL_PEX',
  TpDHLParcelExpeditedMax = 'TP_DHL_EXMX',
  Ups1Ground = 'UPS1_G',
  Ups1MailInnovations = 'UPS1_I',
  Ups1NextDayAir = 'UPS1_NDA',
  Ups2Ground = 'UPS2_G',
  Ups2MailInnovations = 'UPS2_I',
  Ups2NextDayAir = 'UPS2_NDA',
  Ups3Ground = 'UPS3_G',
  Ups3MailInnovations = 'UPS3_I',
  Ups3NextDayAir = 'UPS3_NDA',
  QdFedex2Day = 'QD_FEDEX2',
  QdFedexGround = 'QD_FEDEXG',
  QdFedexPriorityOvernight = 'QD_FEDEXPO',
  QdFedexSmartPost = 'QD_FEDEXS',
  QdFedexStandardOvernight = 'QD_FEDEXSO',
}

/** @see https://trpilltestapi.snapwarehouse.net/api/snaplookups/SHIPSTATUS */
export enum SnapFulfilShipmentStatus {
  Valid = '00',
  Cancelled = '01',
  Postponed = '02',
  Suspended = '04',
  Repack = '20',
  Error = '80',
}

/** @see https://trpilltestapi.snapwarehouse.net/api/snaplookups/SKUCLASS */
export enum SnapFulfilSkuClass {
  SuperFast = '01',
  Fast = '02',
  Medium = '03',
  Slow = '04',
  SuperSlow = '05',
  Dead = '06',
}

/** @see https://trpilltestapi.snapwarehouse.net/api/snaplookups/SKUSTATUS */
export enum SnapFulfilSkuStatus {
  Valid = '00',
  QAHold = '01',
  Blocked = '10',
  BlockedInbound = '11',
  BlockedOutbound = '12',
  New = '20',
  Obsolete = '30',
  Purge = '99',
}

/** @see https://trpilltestapi.snapwarehouse.net/api/snaplookups/SKUTYPE */
export enum SnapFulfilSkuType {
  WarehouseItem = '00',
  NonPickableItem = '10',
}

/** @see https://trpilltestapi.snapwarehouse.net/api/snaplookups/SKUVALUE */
export enum SnapFulfilSkuValue {
  HighValue = '01',
  MediumValue = '02',
  LowValue = '03',
}

/** @see https://trpilltestapi.snapwarehouse.net/api/snaplookups/STKSTATUS */
export enum SnapFulfilStockStatus {
  Good = '00',
  Held = '01',
  SterilisationHold = '03',
  Damaged = '10',
  QA = '30',
  Missing = '80',
}

/** @see https://trpilltestapi.snapwarehouse.net/api/snaplookups/UNIT */
export enum SnapFulfilUnitOfMeasure {
  BAG = 'BAG',
  CAS = 'CAS',
  EA = 'EA',
  KG = 'KG',
  PND = 'PND',
}

/** @see https://trpilltestapi.snapwarehouse.net/api/snaplookups/VARIANCE */
export enum SnapFulfilVariance {
  No = '0',
  Yes = '1',
  Accepted = '2',
}

export interface SnapFulfilProduct {
  /** Max length 30 */ SKUId: string
  /** Max length 15 */ BizId: string
  /** @default SnapFulfilSkuStatus.Valid */ Status?: SnapFulfilSkuStatus | null
  /** Max length 30 */ Description: string
  /** Max length 250 */ Text?: string | null
  /** Max length 15 */ Group1?: string | null
  /** Max length 15 */ Group2?: string | null
  BoxUnit?: SnapFulfilUnitOfMeasure | null
  BoxQty?: number | null
  ItemUnit?: SnapFulfilUnitOfMeasure | null
  /** @default 0 */ Life?: number | null
  /** Max length 1; @default '0' */ ExpiryReqd?: string | null
  /** Max length 1; @default '0' */ ProductionReqd?: string | null
  /** Max length 1; @default '0' */ BatchReqd?: string | null
  /** Max length 1; @default '0' */ SerialReqd?: string | null
  /** Max length 1; @default '0' */ Country?: string | null
  /** @default SnapFulfilRotation.LowestSlotQty */ RotationMethod?: SnapFulfilRotation | null
  /** @default SnapFulfilSkuType.WarehouseItem */ Type?: SnapFulfilSkuType | null
  /** @default SnapFulfilSkuClass.Medium */ Class?: SnapFulfilSkuClass | null
  /** @default SnapFulfilSkuValue.MediumValue */ Value?: SnapFulfilSkuValue | null
  BoxHeight?: number | null
  BoxWidth?: number | null
  BoxDepth?: number | null
  BoxWeight?: number | null
  ItemHeight?: number | null
  ItemWidth?: number | null
  ItemDepth?: number | null
  ItemWeight?: number | null
  /** @default '00' */ Stage?: string | null
  /** Max length 3 */ QA?: string | null
  /** Max length 3 */ QC?: string | null
  /** Max length 3 */ HazardCode?: string | null
  /** Max length 1 */ MaintInd?: SnapFulfilMaintenanceIndication | null
  /**
   * ISO datetime string without the milliseconds or 'Z'
   * @example '2012-10-01T19:32:49'
   */ DateHeld?: string | null
}

export interface SnapFulfilSerialsShipment<ShipmentId extends string = string> {
  /** Max length 10 */ SerialKey?: string
  /** Max length 250 */ SerialValues?: string
  /** Max length 50 */ SerialNumber?: string
  /** Max length 30 */ SKUId?: string
  /** Max length 1 */ State?: string
  /** Max length 15 */ Site?: string
  /** Max length 15 */ Facility?: string
  /** Max length 5 */ ReceiptType?: string
  /** Max length 20 */ ReceiptId?: string
  /** Max length 20 */ ReceiptStU?: string
  /** Max length 2 */ ReceiptStatus?: string
  /** Max length 15 */ ReceiptBizId?: string
  /** Max length 15 */ ReceiptOwnerId?: string
  /** Max length 10 */ ReceivingOperator?: string
  ReceiptDate?: Date
  /** Max length 5 */ ShipmentType?: string
  /** Max length 20 */ ShipmentId?: ShipmentId
  /** Max length 20 */ ShipmentStU?: string
  /** Max length 2 */ ShipmentStatus?: string
  /** Max length 15 */ ShipmentBizId?: string
  /** Max length 15 */ ShipmentOwnerId?: string
  /** Max length 10 */ ShippingOperator?: string
  ShipDate?: Date
  /** Max length 2 */ SerialStage?: string
}

export interface SnapFulfilShipmentLine<ShipmentId extends string = string> {
  /** Max length 20 */ ShipmentId: ShipmentId
  /** Max length 10 */ Line?: string | null
  /** Max length 10 */ ShipmentLineValues?: string | null
  /** Max length 2 */ Level?: string | null
  /** Max length 30 */ SKUId: string
  /** Max length 30 */ BizSKU?: string | null
  /** Max length 30 */ CustomerSKU?: string | null
  /** Max length 30 */ UPC?: string | null
  /** Max length 100 */ UPCDescription?: string | null
  /** Max lenght 15 */ Category?: string | null
  UnitOfMeasure: SnapFulfilUnitOfMeasure
  /** Max lenght 15 */ LineOwner?: string | null
  /** Max length 2 */ LineStockStatus?: string | null
  QtyOrdered: number
  QtyRequired?: number | null
  QtyAllocated?: number | null
  QtyTasked?: number | null
  QtyPicked?: number | null
  QtyShipped?: number | null
  QtyDelivered?: number | null
  QtyDueOut?: number | null
  Price?: number | null
  Discount?: number | null
  TaxRate?: number | null
  SOLineId?: number | null
  /** Max length 4 */ ReturnReason?: string | null
  QC?: SnapFulfilQCCode
  /** Max length 2 */ Shortage?: string | null
  Variance?: SnapFulfilVariance
  /** Max length 1 */ BOInd?: string | null
  /** Max length 20 */ ConsignmentId?: string | null
  /** Max length 20 */ PickGroupId?: string | null
  /** Max length 15 */ SiteId?: string | null
  /** Max length 15 */ Warehouse?: string | null
  /** Max length 15 */ BizId?: string | null
  /** Max length 15 */ OwnerId?: string | null
  /** Max length 2 */ StockStatus?: string | null
  /**
   * ISO datetime string without the milliseconds or 'Z'
   * @example '2012-10-01T19:32:49'
   */ DateShipment?: string | null
  /** Max length 1 */ AttachmentInd?: string | null
  /** Max length 1 */ SpecialConditionInd?: string | null
  /** @default SnapFulfilShipmentLineStage.NotStarted */ Stage?: SnapFulfilShipmentLineStage
}

export interface SnapFulfilShipmentAddress<ShipmentId extends string = string, AddressId extends string = string> {
  /** Max length 4 */ Table: 'SHH'
  /** Max length 30 */ ShipmentId?: ShipmentId
  /** Must match a value in the InvoiceAddressId or ShippingAddressId within the Shipment Header; Max length 10 */ AddressId?: AddressId
  /** Customer Name to appear on Despatch labels; Max length 50 */ Name?: string | null
  /** Max length 50 */ Line1?: string | null
  /** Max length 50 */ Line2?: string | null
  /** Max length 50 */ Line3?: string | null
  /** Max length 30 */ City?: string | null
  /** Max length 30 */ State?: string | null
  /** Max length 20 */ Postcode?: string | null
  /** A valid ISO 3166–1 alpha–2 Country Code; Max length 10 */ Country: string
  /** Max length 20 */ Region?: string | null
  Latitude?: number | null
  Longitude?: number | null
}

export interface SnapFulfilShipmentContact<ShipmentId extends string = string> {
  /** Max length 5 */ Table: 'SHH'
  /** Max length 30 */ ShipmentId: ShipmentId
  /** Max length 10 */ LineId: string
  /** Max length 10 */ Title?: string | null
  /** Max length 30 */ FirstName?: string | null
  /** Max length 30 */ LastName?: string | null
  /** Max length 30 */ Salutation?: string | null
  /** Max length 20 */ Position?: string | null
  /** Max length 50 */ Email?: string | null
  /** Max length 20 */ Fax?: string | null
  /** Max length 20 */ Phone?: string | null
  /** Max length 20 */ Mobile?: string | null
  /** Max length 20 */ Pager?: string | null
  /** Max length 10 */ Language?: string | null
}

export interface SnapFulfilShipmentDespatch<ShipmentId extends string = string> {
  /** Max length 20 */ ShipmentId: ShipmentId
  /** Max length 3 */ Type: string
  /** Pallet type; Max length 20 */ Stu: string
  /** Max length 15 */ Carrier?: string | null
  /** Max length 10 */ ShipMethod?: string | null
  /** Max length 75 */ CarrierTrack?: string | null
  /** Max length 75 */ CarrierRef1?: string | null
  /** Max length 75 */ CarrierRef2?: string | null
  /** Max length 75 */ CarrierRef3?: string | null
  /** Max length 75 */ CarrierRef4?: string | null
  Freight?: number | null
  /**
   * ISO datetime string without the milliseconds or 'Z'
   * @example '2012-10-01T19:32:49'
   */ DateShipment?: string | null
  /** Max length 1 */ ManifestInd?: string | null
}

export interface SnapFulfilShipment<
  ShipmentId extends string = string,
  ShippingAddressId extends string = string,
  InvoiceAddressId extends string = string,
> {
  /** Max length 20 */ ShipmentId: ShipmentId
  /** Max length 250 */ ShipmentValues?: string | null
  /** Max length 15 */ BizId?: string | null
  /** Max length 20 */ BizSalesOrder?: string | null
  /** @default SnapFulfilShipmentStatus.Valid */ Status?: SnapFulfilShipmentStatus
  /** @default SnapFulfilOrderType.ShipmentCustomer */ OrderType?: SnapFulfilOrderType
  /** @default `CUSORD` */ OrderClass?: SnapFulfilOrderClass
  /** Max length 15 */ CustomerId: string
  /** Max length 15 */ BizCustomerId?: string | null
  /** Max length 15 */ CustomerGroup?: string | null
  /** Max length 50 */ CustomerName?: string | null
  /** Max length 10 */ ShippingAddressId?: ShippingAddressId
  /** Max length 10 */ InvoiceAddressId?: InvoiceAddressId
  /** Max length 15 */ Site?: string | null
  /** Max length 15 */ Warehouse?: string | null
  /** Max length 15 */ OwnerId?: string | null
  StockStatus?: SnapFulfilStockStatus
  /** Max length 20 */ SalesOrder?: string | null
  /** Max length 20 */ Prime?: string | null
  PriorityAllocation?: SnapFulfilPriority
  PriorityDespatch?: SnapFulfilPriority
  /** Max length 20 */ CustomerRef?: string | null
  /** Max length 20 */ ConsignmentId?: string | null
  /** Max length 20 */ PickGroupId?: string | null
  /** Max length 20 */ ASNNumber?: string | null
  /** Max length 20 */ DNoteNumber?: string | null
  /** Max length 20 */ InvoiceNumber?: string | null
  /** Max length 20 */ ManifestNumber?: string | null
  /** Max length 50 */ BOL?: string | null
  /** Max length 50 */ MasterBOL?: string | null
  /** Max length 20 */ POD?: string | null
  ShippingMethod?: SnapFulfilShipmentMethod
  Region?: SnapFulfilRegion
  /** Max length 30 */ DC?: string | null
  /** Max length 30 */ Store?: string | null
  /** Max length 1 */ CarrierId?: string | null
  /** Max length 15 */ CarrierScac?: string | null
  /** Max length 4 */ CarrierTrackingNumber?: string | null
  Route?: number | null
  LoadId?: number | null
  LoadSequence?: number | null
  PackStation?: number | null
  ShippingLane?: string | null
  ReturnReason?: SnapFulfilShipmentReturnReason
  QC?: SnapFulfilQCCode
  /** Max length 1 */ Lines?: string | null
  /** Max length 1 */ LineQty?: string | null
  /** Pallet quantity. Max length 2 */ StUQty?: string | null
  /** Max length 1 */ Volume?: string | null
  Weight?: number | null
  ActualWeight?: number | null
  TaskCountNew?: number | null
  TaskCountCurrent?: number | null
  TaskCountActioned?: number | null
  TimeToPick?: number | null
  TimeToPack?: number | null
  TimeToCheck?: number | null
  TimeToLoad?: number | null
  TimeOther?: number | null
  TimeToDeliver?: number | null
  InvoiceInd?: number | null
  Currency?: SnapFulfilCurrency
  LineValue?: number | null
  Discount?: number | null
  Packing?: number | null
  Freight?: number | null
  Insurance?: number | null
  Charges?: number | null
  Allowances?: number | null
  Tax?: number | null
  InvoiceValue?: number | null
  ShortageCode?: number | null
  Variance?: SnapFulfilVariance
  CutOffInd?: string | null
  Supervisor?: string | null
  Reason?: string | null
  /**
   * ISO datetime string without the milliseconds or 'Z'
   * @example '2012-10-01T19:32:49'
   */ DateCreated?: string | null
  /**
   * ISO datetime string without the milliseconds or 'Z'
   * @example '2012-10-01T19:32:49'
   */ DateSuspended?: string | null
  /**
   * ISO datetime string without the milliseconds or 'Z'
   * @example '2012-10-01T19:32:49'
   */ DateClosed?: string | null
  /**
   * ISO datetime string without the milliseconds or 'Z'
   * @example '2012-10-01T19:32:49'
   */ DateDueOut?: string | null
  /**
   * ISO datetime string without the milliseconds or 'Z'
   * @example '2012-10-01T19:32:49'
   */ DateShipment?: string | null
  /**
   * ISO datetime string without the milliseconds or 'Z'
   * @example '2012-10-01T19:32:49'
   */ DateDelivery?: string | null
  /**
   * ISO datetime string without the milliseconds or 'Z'
   * @example '2012-10-01T19:32:49'
   */ DateInvoice?: string | null
  ASNInd?: string | null
  OverdueInd?: string | null
  Stage?: SnapFulfilShipmentStage
  MaintInd?: string | null
  ShipmentLines: Array<SnapFulfilShipmentLine>
  ShipAddress: Array<SnapFulfilShipmentAddress<ShippingAddressId | InvoiceAddressId>>
  ShipContacts: Array<SnapFulfilShipmentContact>
  ShipmentDespatch: Array<SnapFulfilShipmentDespatch>
}

export interface SnapFulfilReceiptLine<ReceiptId extends string = string> {
  /** Max length 20 */ ReceiptId: ReceiptId
  /** Max length 10 */ Line?: string
  /** Max length 2 */ Level?: string | null
  /** Max length 30 */ SKUId: string
  /** Max length 30 */ BizSKU?: string
  UnitOfMeasure?: SnapFulfilUnitOfMeasure
  /** Max length 15 */ LineOwner?: string
  LineStockStatus?: SnapFulfilStockStatus
  QtyOrdered: number | null
  QtyAdvised?: number
  QtyReceived?: number | null
  QtyRejected?: number | null
  QtyDueIn?: number | null
  POLineId?: any
  /** Max length 4 */ ReturnReason?: string | null
  QA?: SnapFulfilQACode
  /** Max length 2 */ Acceptance?: string | null
  Variance?: SnapFulfilVariance
  /** Max length 1 */ BOInd?: string | null
  /** Max length 20 */ ConsignmentId?: string | null
  /** Max length 15 */ SiteId?: string | null
  /** Max length 15 */ Warehouse?: string | null
  /** Max length 15 */ BizId?: string | null
  /** Max length 15 */ OwnerId?: string | null
  /** Max length 2 */ StockStatus?: string | null
  /**
   * ISO datetime string without the milliseconds or 'Z'
   * @example '2012-10-01T19:32:49'
   */ DateReceipt?: string | null
  /** Max length 1 */ AttachmentInd?: string | null
  /** Max length 1 */ SpecialConditionInd?: string | null
  Stage?: SnapFulfilReceiptLineStage
}

export interface SnapFulfilReceiptAddress<ReceiptId extends string = string, AddressId extends string = string> {
  /** Max length 4 */ Table: 'REH'
  /** Max length 20 */ ReceiptId?: ReceiptId
  /** Max length 10 */ AddressId?: AddressId
  /** Max length 50 */ Name?: string | null
  /** Max length 50 */ Line1?: string | null
  /** Max length 50 */ Line2?: string | null
  /** Max length 50 */ Line3?: string | null
  /** Max length 30 */ City?: string | null
  /** Max length 30 */ State?: string | null
  /** Max length 20 */ Postcode?: string | null
  /** A valid ISO 3166–1 alpha–2 country code; max length 2 */ Country: string
  /** Max length 20 */ Region?: string | null
  Latitude?: number | null
  Longitude?: number | null
}

export interface SnapFulfilReturn<ReceiptId extends string = string> {
  /** Max length 20 */ ReceiptId: ReceiptId
  /** Max length 15 */ BizId?: string | null
  /** Max length 20 */ BizPurchaseOrder?: string | null
  /** @default SnapFulfilCustomerReturnStatus.Valid */ Status?: SnapFulfilReturnStatus
  /** @default SnapFulfilOrderType.ReceiptCustomer */ OrderType?: SnapFulfilOrderType
  OrderClass?: SnapFulfilReturnClass
  /** Max length 15 */ SupplierId: string
  /** Max length 15 */ BizSupplierId?: string | null
  /** Max length 15 */ SupplierGroup?: string | null
  /** Max length 50 */ SupplierName?: string | null
  /** Max length 10 */ ShippingAddressId?: string | null
  /** Max length 10 */ InvoiceAddressId?: string | null
  /** Max length 15 */ Site?: string | null
  /** Max length 15 */ Warehouse?: string | null
  /** Max length 15 */ OwnerId?: string | null
  StockStatus?: SnapFulfilStockStatus
  /** Max length 15 */ PurchaseOrder?: string | null
  /** Max length 20 */ Prime?: string | null
  Priority?: SnapFulfilPriority
  /** Max length 20 */ SupplierRef?: string | null
  /** Max length 20 */ ConsignmentId?: string | null
  /** Max length 20 */ GRNNumber?: string | null
  /** Max length 20 */ ManifestNumber?: string | null
  /** Max length 20 */ POC?: string | null
  ShippingMethod?: SnapFulfilShipmentMethod
  Region?: SnapFulfilRegion
  /** Max length 15 */ CarrierId?: string | null
  /** Max length 75 */ CarrierTrackingNumber?: string | null
  /** Max length 20 */ Route?: string | null
  /** Max length 20 */ LoadId?: string | null
  /** Max length 18 */ LoadSequence?: string | null
  /** Max length 15 */ ReceiptLane?: string | null
  ReturnReason?: SnapFulfilReturnReason
  QA?: SnapFulfilQACode
  Lines?: number | null
  LineQty?: number | null
  /** Pallet quantity */ StUQty?: number | null
  Volume?: number | null
  Weight?: number | null
  ActualWeight?: number | null
  TaskCountNew?: number | null
  TaskCountCurrent?: number | null
  TaskCountActioned?: number | null
  TimeToUnload?: number | null
  TimeToInspect?: number | null
  TimeToPutaway?: number | null
  TimeOther?: number | null
  TimeToCollect?: number | null
  /** Max length 2 */ AcceptanceCode?: string | null
  Variance?: SnapFulfilVariance
  /** Max length 15 */ Supervisor?: string | null
  /** Max length 4 */ Reason?: string | null
  /**
   * ISO datetime string without the milliseconds or 'Z'
   * @example '2012-10-01T19:32:49'
   */ DateCreated?: string | null
  /**
   * ISO datetime string without the milliseconds or 'Z'
   * @example '2012-10-01T19:32:49'
   */ DateSuspended?: string | null
  /**
   * ISO datetime string without the milliseconds or 'Z'
   * @example '2012-10-01T19:32:49'
   */ DateClosed?: string | null
  /**
   * ISO datetime string without the milliseconds or 'Z'
   * @example '2012-10-01T19:32:49'
   */ DateCollected?: string | null
  /**
   * ISO datetime string without the milliseconds or 'Z'
   * @example '2012-10-01T19:32:49'
   */ DateDueIn?: string | null
  /**
   * ISO datetime string without the milliseconds or 'Z'
   * @example '2012-10-01T19:32:49'
   */ DateArrival?: string | null
  /**
   * ISO datetime string without the milliseconds or 'Z'
   * @example '2012-10-01T19:32:49'
   */ DateReceipt: string
  /** Max length 1 */ ASNInd?: string | null
  /** Max length 1 */ PaperworkInd?: string | null
  /** Max length 1 */ OverdueInd?: string | null
  Stage?: SnapFulfilShipmentStage
  /** Max length 1 */ MaintInd?: string | null
  ReceiptLines: Array<SnapFulfilReceiptLine<ReceiptId>>
  ReceiptAddresses: Array<SnapFulfilReceiptAddress<ReceiptId>>
}

export const OrderStatusBySnapFulfilmentStage: { [k in SnapFulfilShipmentStage]: OrderStatus } = {
  [SnapFulfilShipmentStage.DueOut]: OrderStatus.Packing,
  [SnapFulfilShipmentStage.Allocate]: OrderStatus.Packing,
  [SnapFulfilShipmentStage.Allocated]: OrderStatus.Packing,
  [SnapFulfilShipmentStage.Release]: OrderStatus.Packing,
  [SnapFulfilShipmentStage.Released]: OrderStatus.Packing,
  [SnapFulfilShipmentStage.Pick]: OrderStatus.Packing,
  [SnapFulfilShipmentStage.Picking]: OrderStatus.Packing,
  [SnapFulfilShipmentStage.Picked]: OrderStatus.Packing,
  [SnapFulfilShipmentStage.Pack]: OrderStatus.Packing,
  [SnapFulfilShipmentStage.Packed]: OrderStatus.Packing,
  [SnapFulfilShipmentStage.Marshall]: OrderStatus.Packing,
  [SnapFulfilShipmentStage.Marshalling]: OrderStatus.Packing,
  [SnapFulfilShipmentStage.Marshalled]: OrderStatus.Packing,
  [SnapFulfilShipmentStage.Packing]: OrderStatus.Packing,
  [SnapFulfilShipmentStage.Loading]: OrderStatus.PartialShipped,
  [SnapFulfilShipmentStage.Loaded]: OrderStatus.PartialShipped,
  [SnapFulfilShipmentStage.Shipped]: OrderStatus.Shipped,
  [SnapFulfilShipmentStage.Fulfilled]: OrderStatus.Complete,
  [SnapFulfilShipmentStage.Cancelled]: OrderStatus.Cancelled,
  [SnapFulfilShipmentStage.Purged]: OrderStatus.Cancelled,
}

export const CustomerReturnStatusBySnapFulfilmentStage: { [k in SnapFulfilShipmentStage]: CustomerReturnStatus } = {
  [SnapFulfilShipmentStage.DueOut]: CustomerReturnStatus.Packing,
  [SnapFulfilShipmentStage.Allocate]: CustomerReturnStatus.Packing,
  [SnapFulfilShipmentStage.Allocated]: CustomerReturnStatus.Packing,
  [SnapFulfilShipmentStage.Release]: CustomerReturnStatus.Packing,
  [SnapFulfilShipmentStage.Released]: CustomerReturnStatus.Packing,
  [SnapFulfilShipmentStage.Pick]: CustomerReturnStatus.Packing,
  [SnapFulfilShipmentStage.Picking]: CustomerReturnStatus.Packing,
  [SnapFulfilShipmentStage.Picked]: CustomerReturnStatus.Packing,
  [SnapFulfilShipmentStage.Pack]: CustomerReturnStatus.Packing,
  [SnapFulfilShipmentStage.Packed]: CustomerReturnStatus.Packing,
  [SnapFulfilShipmentStage.Marshall]: CustomerReturnStatus.Packing,
  [SnapFulfilShipmentStage.Marshalling]: CustomerReturnStatus.Packing,
  [SnapFulfilShipmentStage.Marshalled]: CustomerReturnStatus.Packing,
  [SnapFulfilShipmentStage.Packing]: CustomerReturnStatus.Packing,
  [SnapFulfilShipmentStage.Loading]: CustomerReturnStatus.Shipped,
  [SnapFulfilShipmentStage.Loaded]: CustomerReturnStatus.Shipped,
  [SnapFulfilShipmentStage.Shipped]: CustomerReturnStatus.Shipped,
  [SnapFulfilShipmentStage.Fulfilled]: CustomerReturnStatus.Complete,
  [SnapFulfilShipmentStage.Cancelled]: CustomerReturnStatus.Cancelled,
  [SnapFulfilShipmentStage.Purged]: CustomerReturnStatus.Cancelled,
}

export const CustomerReturnReasonBySnapFulfilReturnReason: { [k in SnapFulfilReturnReason]: CustomerReturnReason } = {
  [SnapFulfilReturnReason.Refused]: CustomerReturnReason.WrongItemPurchased,
  [SnapFulfilReturnReason.Reject]: CustomerReturnReason.ItemDamaged,
  [SnapFulfilReturnReason.Excess]: CustomerReturnReason.ItemNoLongerNeeded,
  [SnapFulfilReturnReason.CustomerClosed]: CustomerReturnReason.Other,
}

export const ShippingMethodBySnapFulfilShipmentMethod: Partial<{ [k in SnapFulfilShipmentMethod]: ShippingMethod }> = {
  [SnapFulfilShipmentMethod.TpFEDEXGround]: ShippingMethod.FedexGround,
  [SnapFulfilShipmentMethod.TpFEDEXSmartPost]: ShippingMethod.FedexSmartPost,
  [SnapFulfilShipmentMethod.TpFEDEXStandardOvernight]: ShippingMethod.FedexStandardOvernight,
  [SnapFulfilShipmentMethod.TpFEDEXPriorityOvernight]: ShippingMethod.FedexPriorityOvernight,
  [SnapFulfilShipmentMethod.TpFEDEX2Day]: ShippingMethod.Fedex2Day,
  [SnapFulfilShipmentMethod.TpDHLParcelExpedited]: ShippingMethod.DhlEcommerceParcelExpedited,
  [SnapFulfilShipmentMethod.TpDHLParcelPlusExpedited]: ShippingMethod.DhlEcommerceParcelPlusExpedited,
  [SnapFulfilShipmentMethod.TpDHLParcelExpeditedMax]: ShippingMethod.DhlEcommerceParcelPlusExpeditedMax,
  [SnapFulfilShipmentMethod.OpFedexGround]: ShippingMethod.FedexGround,
  [SnapFulfilShipmentMethod.OpFEDEXSmartPost]: ShippingMethod.FedexSmartPost,
  [SnapFulfilShipmentMethod.OpFEDEXStandardOvernight]: ShippingMethod.FedexStandardOvernight,
  [SnapFulfilShipmentMethod.OpFEDEXPriorityOvernight]: ShippingMethod.FedexPriorityOvernight,
  [SnapFulfilShipmentMethod.OpFedex2Day]: ShippingMethod.Fedex2Day,
  [SnapFulfilShipmentMethod.OpUPS2DayAir]: ShippingMethod.UpsSecondDayAir,
  [SnapFulfilShipmentMethod.OpUPSGround]: ShippingMethod.UpsGround,
  [SnapFulfilShipmentMethod.OpUPSNextDayAir]: ShippingMethod.UpsNextDayAir,
  [SnapFulfilShipmentMethod.ShUSPSPriority]: ShippingMethod.UspsPriority,
  [SnapFulfilShipmentMethod.ShUSPSPriorityExpress]: ShippingMethod.UspsPriorityMailExpress,
  [SnapFulfilShipmentMethod.PoUSPSFirst]: ShippingMethod.UspsFirst,
  [SnapFulfilShipmentMethod.PoUSPSGroundAdv]: ShippingMethod.UspsGroundAdvantage,
  [SnapFulfilShipmentMethod.Ups1Ground]: ShippingMethod.UpsGround,
  [SnapFulfilShipmentMethod.Ups1NextDayAir]: ShippingMethod.UpsNextDayAir,
  [SnapFulfilShipmentMethod.Ups1MailInnovations]: ShippingMethod.UpsMailInnovationsDomestic,
  [SnapFulfilShipmentMethod.Ups2Ground]: ShippingMethod.UpsGround,
  [SnapFulfilShipmentMethod.Ups2NextDayAir]: ShippingMethod.UpsNextDayAir,
  [SnapFulfilShipmentMethod.Ups2MailInnovations]: ShippingMethod.UpsMailInnovationsDomestic,
  [SnapFulfilShipmentMethod.Ups3Ground]: ShippingMethod.UpsGround,
  [SnapFulfilShipmentMethod.Ups3NextDayAir]: ShippingMethod.UpsNextDayAir,
  [SnapFulfilShipmentMethod.Ups3MailInnovations]: ShippingMethod.UpsMailInnovationsDomestic,
  [SnapFulfilShipmentMethod.QdFedex2Day]: ShippingMethod.Fedex2Day,
  [SnapFulfilShipmentMethod.QdFedexGround]: ShippingMethod.FedexGround,
  [SnapFulfilShipmentMethod.QdFedexPriorityOvernight]: ShippingMethod.FedexPriorityOvernight,
  [SnapFulfilShipmentMethod.QdFedexSmartPost]: ShippingMethod.FedexSmartPost,
  [SnapFulfilShipmentMethod.QdFedexStandardOvernight]: ShippingMethod.FedexStandardOvernight,
}

export const CarrierBySnapFulfilShipmentMethod: Partial<{ [k in SnapFulfilShipmentMethod]: ShippingCarrier }> = {
  [SnapFulfilShipmentMethod.TpFEDEXGround]: ShippingCarrier.FEDEX,
  [SnapFulfilShipmentMethod.TpFEDEXSmartPost]: ShippingCarrier.FEDEX,
  [SnapFulfilShipmentMethod.TpFEDEXStandardOvernight]: ShippingCarrier.FEDEX,
  [SnapFulfilShipmentMethod.TpFEDEXPriorityOvernight]: ShippingCarrier.FEDEX,
  [SnapFulfilShipmentMethod.TpFEDEX2Day]: ShippingCarrier.FEDEX,
  [SnapFulfilShipmentMethod.TpDHLParcelExpedited]: ShippingCarrier.DHL,
  [SnapFulfilShipmentMethod.TpDHLParcelPlusExpedited]: ShippingCarrier.DHL,
  [SnapFulfilShipmentMethod.TpDHLParcelExpeditedMax]: ShippingCarrier.DHL,
  [SnapFulfilShipmentMethod.OpFedexGround]: ShippingCarrier.FEDEX,
  [SnapFulfilShipmentMethod.OpFEDEXSmartPost]: ShippingCarrier.FEDEX,
  [SnapFulfilShipmentMethod.OpFEDEXStandardOvernight]: ShippingCarrier.FEDEX,
  [SnapFulfilShipmentMethod.OpFEDEXPriorityOvernight]: ShippingCarrier.FEDEX,
  [SnapFulfilShipmentMethod.OpFedex2Day]: ShippingCarrier.FEDEX,
  [SnapFulfilShipmentMethod.OpUPS2DayAir]: ShippingCarrier.UPS,
  [SnapFulfilShipmentMethod.OpUPSGround]: ShippingCarrier.UPS,
  [SnapFulfilShipmentMethod.OpUPSNextDayAir]: ShippingCarrier.UPS,
  [SnapFulfilShipmentMethod.ShUSPSPriority]: ShippingCarrier.USPS,
  [SnapFulfilShipmentMethod.ShUSPSPriorityExpress]: ShippingCarrier.USPS,
  [SnapFulfilShipmentMethod.PoUSPSFirst]: ShippingCarrier.USPS,
  [SnapFulfilShipmentMethod.POUSPSPriority]: ShippingCarrier.USPS,
  [SnapFulfilShipmentMethod.PoUSPSGroundAdv]: ShippingCarrier.USPS,
  [SnapFulfilShipmentMethod.Ups1Ground]: ShippingCarrier.UPS,
  [SnapFulfilShipmentMethod.Ups1NextDayAir]: ShippingCarrier.UPS,
  [SnapFulfilShipmentMethod.Ups1MailInnovations]: ShippingCarrier.UPS,
  [SnapFulfilShipmentMethod.Ups2Ground]: ShippingCarrier.UPS,
  [SnapFulfilShipmentMethod.Ups2NextDayAir]: ShippingCarrier.UPS,
  [SnapFulfilShipmentMethod.Ups2MailInnovations]: ShippingCarrier.UPS,
  [SnapFulfilShipmentMethod.Ups3Ground]: ShippingCarrier.UPS,
  [SnapFulfilShipmentMethod.Ups3NextDayAir]: ShippingCarrier.UPS,
  [SnapFulfilShipmentMethod.Ups3MailInnovations]: ShippingCarrier.UPS,
  [SnapFulfilShipmentMethod.QdFedex2Day]: ShippingCarrier.FEDEX,
  [SnapFulfilShipmentMethod.QdFedexGround]: ShippingCarrier.FEDEX,
  [SnapFulfilShipmentMethod.QdFedexPriorityOvernight]: ShippingCarrier.FEDEX,
  [SnapFulfilShipmentMethod.QdFedexSmartPost]: ShippingCarrier.FEDEX,
  [SnapFulfilShipmentMethod.QdFedexStandardOvernight]: ShippingCarrier.FEDEX,
}

export const SnapFulfilShipmentMethodByShippingMethod: { [key in ShippingMethod]?: SnapFulfilShipmentMethod } = {
  [ShippingMethod.UspsFirst]: SnapFulfilShipmentMethod.PoUSPSFirst,
  [ShippingMethod.UspsPriority]: SnapFulfilShipmentMethod.POUSPSPriority,
  [ShippingMethod.UspsPriorityExpress]: SnapFulfilShipmentMethod.POUSPSPriorityExpress,
  [ShippingMethod.UspsGroundAdvantage]: SnapFulfilShipmentMethod.PoUSPSGroundAdv,
  [ShippingMethod.Fedex2Day]: SnapFulfilShipmentMethod.TpFEDEX2Day,
  [ShippingMethod.FedexStandardOvernight]: SnapFulfilShipmentMethod.TpFEDEXStandardOvernight,
  [ShippingMethod.FedexPriorityOvernight]: SnapFulfilShipmentMethod.TpFEDEXPriorityOvernight,
  [ShippingMethod.DhlEcommerceParcelExpedited]: SnapFulfilShipmentMethod.TpDHLParcelExpedited,
  [ShippingMethod.DhlEcommerceParcelPlusExpedited]: SnapFulfilShipmentMethod.TpDHLParcelPlusExpedited,
  [ShippingMethod.DhlEcommerceParcelExpeditedMax]: SnapFulfilShipmentMethod.TpDHLParcelExpeditedMax,
  [ShippingMethod.UpsSecondDayAir]: SnapFulfilShipmentMethod.Ups2NextDayAir,
  [ShippingMethod.UpsGround]: SnapFulfilShipmentMethod.Ups1Ground,
  [ShippingMethod.UpsNextDayAir]: SnapFulfilShipmentMethod.Ups1NextDayAir,
  [ShippingMethod.FedexGround]: SnapFulfilShipmentMethod.TpFEDEXGround,
  [ShippingMethod.FedexSmartPost]: SnapFulfilShipmentMethod.TpFEDEXSmartPost,
}
