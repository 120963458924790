import { useMemo, useState } from 'react'
import { Box, Button, Header, Modal } from '@truepill/react-capsule'
import { useMutation } from '@truepill/tpos-react-router'
import type {
  Allergy,
  Condition,
  CustomDurSubject,
  CustomDURSubjectCategories,
  DURSubjectCategories,
  Medication,
  MedispanSubject,
} from '@truepill/tpos-types'
import { ReactComponent as LoadingSpinner } from 'assets/icons/loading-circle.svg'
import {
  DURAllergiesAutoComplete,
  DURConditionsAutoComplete,
  DURMedicationsAutoComplete,
} from 'components/DURSubjectAutoComplete'
import { UPDATE_DUR_SCREEN } from 'gql'
import useErrorToast from 'hooks/toast/useErrorToast'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import { omit } from 'lodash'
import styled from 'styled-components'
import EditList from './EditList'

interface EditUracModalProps {
  orderId: string
  isOpen: boolean
  forceIsLoading?: boolean
  onClose: () => void
  onURACUpdateCompleted?: () => void
  onError?: () => void
  durInputs: DURSubjectCategories
}

type DURSubjectType = Allergy[] | Condition[] | Medication[] | CustomDurSubject[]

/**
 * Removes unnecessary keys
 * @param durInputs
 * @returns the sanitized Durinput
 */
const sanitizeDURInput = (
  durInputs: Record<keyof DURSubjectCategories, DURSubjectCategories[keyof DURSubjectCategories]>,
) => {
  const _durInputs = Object.entries(omit(durInputs, ['custom'])).reduce<Record<string, MedispanSubject[]>>(
    (acc, [key, value]) => {
      if (Array.isArray(value)) {
        acc[key] = value.map(({ type, value, idField, name, patientSurveyInput }) => ({
          type,
          value,
          idField,
          name,
          patientSurveyInput,
        }))
      }
      return acc
    },
    {},
  ) as unknown as DURSubjectCategories

  const _custom =
    durInputs.custom &&
    Object.entries(durInputs.custom).reduce<Record<string, CustomDurSubject[]>>((acc, [key, value]) => {
      acc[key] = (value as CustomDurSubject[]).map(({ name }) => ({ name }))
      return acc
    }, {})

  _durInputs.custom = _custom as unknown as CustomDURSubjectCategories

  return _durInputs
}

const EditUracModal = ({
  isOpen,
  durInputs,
  orderId,
  forceIsLoading = false,
  onClose,
  onError,
  onURACUpdateCompleted,
}: EditUracModalProps): JSX.Element => {
  const showSuccesToast = useSuccessToast()
  const showErrorToast = useErrorToast()

  const [updateDUR, { loading: durScreeningLoading }] = useMutation(UPDATE_DUR_SCREEN, {
    onCompleted: () => {
      showSuccesToast('URAC updated')
      onURACUpdateCompleted?.()
    },
    onError: () => {
      showErrorToast('An error ocurred while updating URAC')
      onError?.()
    },
    refetchQueries: ['getBasicOrder'],
  })

  const [allergies, setAllergies] = useState(durInputs.allergies)
  const [customAllergies, setCustomAllergies] = useState(durInputs.custom?.allergies ?? [])
  const [customMedications, setCustomMedications] = useState(durInputs.custom?.medications ?? [])
  const [customConditions, setCustomConditions] = useState(durInputs.custom?.conditions ?? [])
  const [medications, setMedications] = useState(durInputs.medications)
  const [conditions, setConditions] = useState(durInputs.conditions)

  const isLoading = forceIsLoading || durScreeningLoading

  const durHasChanges = useMemo(() => {
    const checkChanges = (current: DURSubjectType, initial: DURSubjectType) =>
      current.length !== initial.length || current.some(item => !initial.some(init => init.name === item.name))

    return (
      checkChanges(allergies, durInputs.allergies) ||
      checkChanges(medications, durInputs.medications) ||
      checkChanges(conditions, durInputs.conditions) ||
      checkChanges(customAllergies, durInputs.custom?.allergies ?? []) ||
      checkChanges(customMedications, durInputs.custom?.medications ?? []) ||
      checkChanges(customConditions, durInputs.custom?.conditions ?? [])
    )
  }, [allergies, medications, conditions, customAllergies, customMedications, customConditions, durInputs])

  const handleUpdate = () => {
    const sanitizedDURInputs = sanitizeDURInput({
      allergies,
      medications,
      conditions,
      custom: { allergies: customAllergies, medications: customMedications, conditions: customConditions },
    })

    updateDUR({
      variables: {
        orderId,
        ...sanitizedDURInputs,
      },
    })
  }

  return (
    <Modal
      overlayCss={{ zIndex: 999 }}
      css={{
        width: '54rem',
        maxWidth: 'none !important',
        padding: '1.5rem',
        position: 'fixed',
        right: '2vw',
        '@media (min-width:1550px)': {
          right: '10vw',
        },
      }}
      isOpen={isOpen}
      showCloseButton={!isLoading}
      onDismiss={onClose}
    >
      <Header variant="4xl" id="title" css={{ fontWeight: 700, marginBottom: '1rem' }}>
        Edit URAC
      </Header>
      <SubTitle>
        Use the search fields to find allergies, medications, and conditions in the system to update the DUR. If you
        cannot find a match, you can add a free-text custom input, but it will not be included in the DUR screening.
      </SubTitle>
      <Box css={{ display: 'flex', gap: '2rem' }}>
        <DurInputsWrapper>
          <DURAllergiesAutoComplete
            label="Allergies"
            idKey="reviewPrescriptionAllergies"
            value={allergies || []}
            customValue={customAllergies}
            onChange={allergies => {
              setAllergies(allergies)
            }}
            onCustomInputChange={customAllergies => {
              setCustomAllergies(customAllergies)
            }}
          />
          <DURMedicationsAutoComplete
            label="Medications"
            idKey="reviewPrescriptionMedications"
            value={medications || []}
            customValue={customMedications}
            onChange={medications => {
              setMedications(medications)
            }}
            onCustomInputChange={customMedications => {
              setCustomMedications(customMedications)
            }}
          />
          <DURConditionsAutoComplete
            label="Conditions"
            idKey="reviewPrescriptionConditions"
            value={conditions || []}
            customValue={customConditions}
            onChange={conditions => {
              setConditions(conditions)
            }}
            onCustomInputChange={customConditions => {
              setCustomConditions(customConditions)
            }}
          />
        </DurInputsWrapper>
        <Box css={{ width: '50%' }}>
          <EditList orderId={orderId} />
        </Box>
      </Box>
      <Box css={{ display: 'flex', gap: '0.5rem', justifyContent: 'end', marginTop: '2rem' }}>
        <Button variant="primary-text" onClick={onClose}>
          Cancel
        </Button>
        <Button type="submit" onClick={handleUpdate} disabled={isLoading || !durHasChanges}>
          {isLoading ? <LoadingSpinner stroke="white" /> : 'Update'}
        </Button>
      </Box>
    </Modal>
  )
}

const SubTitle = styled.p`
  margin-bottom: 1rem;
  line-height: 1.5rem;
`

const DurInputsWrapper = styled(Box)`
  flex-grow: 1;
  width: 50%;
  flex-shrink: 0;
  > :not(:first-child) {
    display: block;
    margin-top: 1rem;
  }
`

export default EditUracModal
