import { useRef, useState } from 'react'
import { Box, TextField, Portal, Button, Radio } from '@truepill/react-capsule'
import { RxDiscontinueReason } from '@truepill/tpos-types'
import { ReactComponent as OpenIcon } from 'assets/icons/pageArrow.svg'
import styled from 'styled-components'

const options = [
  RxDiscontinueReason.IncorrectDrugDispensed,
  RxDiscontinueReason.IncorrectQuantity,
  RxDiscontinueReason.IncorrectPrescriber,
  RxDiscontinueReason.PrescriberRequestedDiscontinue,
  RxDiscontinueReason.PatientRequestedDiscontinue,
  RxDiscontinueReason.NoLongerValid,
  RxDiscontinueReason.DuplicatePrescription,
  RxDiscontinueReason.NewerRxReceived,
]

const DiscontinueReasonSelect = ({
  onChange,
  isOpened = false,
  setIsOpened,
}: {
  setIsOpened: React.Dispatch<React.SetStateAction<boolean>>
  isOpened: boolean
  onChange?: (o: { description: string; reason: string }) => void
}) => {
  const [description, setDescription] = useState('')
  const [selectedReason, setSelectedReason] = useState<number>(0)
  const buttonRef = useRef<HTMLButtonElement>(null)

  const handleSubmit = () => {
    const reason = options[selectedReason]
    onChange?.({ description, reason })
    setIsOpened(o => !o)
  }

  return (
    <Box css={{ position: 'relative' }}>
      <TriggerButton onClick={() => setIsOpened(o => !o)} ref={buttonRef}>
        <OpenIcon fill="black" style={{ transform: 'rotate(270deg)' }} />
      </TriggerButton>

      {isOpened && (
        <Portal>
          <PortalledBackground />
          <PortalledContainer
            style={{
              left:
                20 +
                ((buttonRef.current && (buttonRef.current as HTMLButtonElement).getBoundingClientRect().left) ?? 0),
              top:
                -20 +
                ((buttonRef.current &&
                  (buttonRef.current as HTMLButtonElement).getBoundingClientRect().bottom + window.scrollY) ??
                  0),
            }}
          >
            <ContentWrapper>
              <Title>Discontinue reason</Title>
              <List>
                {options.map((o, index) => (
                  <li key={index}>
                    <Radio
                      cssLabel={{ whiteSpace: 'nowrap' }}
                      name={o}
                      value={index}
                      label={o}
                      checked={index === selectedReason}
                      onChange={e => {
                        if (e.target.value !== undefined) {
                          setSelectedReason(Number(e.target.value))
                        }
                      }}
                    />
                  </li>
                ))}
              </List>
              <Box>
                <TextField
                  placeholder="Enter a message..."
                  value={description}
                  onChange={e => {
                    setDescription(e.target.value)
                  }}
                  type="text"
                />
              </Box>
              <Box css={{ marginTop: '1rem', display: 'flex', justifyContent: 'right', gap: '1rem' }}>
                <Button onClick={() => setIsOpened(o => !o)}>Cancel</Button>
                <Button disabled={selectedReason === undefined} onClick={() => handleSubmit()}>
                  Proceed
                </Button>
              </Box>
            </ContentWrapper>
          </PortalledContainer>
        </Portal>
      )}
    </Box>
  )
}

const List = styled.ul`
  & > li {
    padding: 0.5rem 0rem;
    position: relative;
    cursor: pointer;
  }
`

const Title = styled.p`
  margin-bottom: 1rem;
  font-weight: bold;
  font-family: 'lato';
`

const TriggerButton = styled.button`
  background: transparent;
  border: none;
`

const PortalledBackground = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0px;
`

const PortalledContainer = styled.div`
  z-index: 1000;
  position: absolute;
  width: fit-content;
`

const ContentWrapper = styled.div`
  padding: 1rem;
  border-radius: 1rem;
  height: auto;
  width: auto;
  z-index: 1000;
  top: 0;
  background-color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 6px 8px rgba(50, 50, 93, 0.22);
`
export default DiscontinueReasonSelect
