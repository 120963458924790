import { Button } from '@truepill/react-capsule'
import { Eye, EyeOff } from 'react-feather'
import styled from 'styled-components'

type SupressFieldButtonProps = {
  hideField: boolean
  onClick: () => void
}

const SupressFieldButton = ({ hideField, onClick }: SupressFieldButtonProps): JSX.Element => {
  return (
    <ButtonWrapper>
      <StyledButton size="xs" onClick={onClick}>
        {hideField ? <EyeOff aria-hidden /> : <Eye aria-hidden />}
      </StyledButton>
    </ButtonWrapper>
  )
}

const StyledButton = styled(Button)`
  border-radius: 100%;
  margin: 2px 0 0 0;
  background-color: rgba(238, 238, 238, 0);
  border-color: rgba(238, 238, 238, 0);
  height: 3rem;
  color: var(--cap-colors-primary-700);
`

const ButtonWrapper = styled.div`
  display: flex;
  > button {
    ${StyledButton}
  }
`

export default SupressFieldButton
