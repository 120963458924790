import { useEffect } from 'react'
import { useHistory, useLocation, useRouteMatch } from '@truepill/tpos-react-router'
import useCopayLock from 'hooks/useCopayLock'

const useUnlockCopay = (copayId: string): void => {
  const { clearCopayLock, copayLockedBy, isCopayLockedByMe } = useCopayLock(copayId)
  const location = useLocation()
  const history = useHistory()
  const { url } = useRouteMatch()
  const currentPath = location.pathname
  const splitUrl = url.slice(1, url.length).split('/')
  const basePath = `/${splitUrl.slice(0, 4).join('/')}`

  return useEffect(() => {
    if (copayLockedBy && isCopayLockedByMe) {
      const unlisten = history.listen(location => {
        if (location.pathname !== currentPath && location.pathname !== basePath) {
          clearCopayLock()
        }
      })

      return function cleanup() {
        unlisten()
      }
    }
  }, [location, isCopayLockedByMe, copayLockedBy, history, basePath, clearCopayLock, currentPath])
}

export default useUnlockCopay
