import { createGlobalStyle } from 'styled-components'
import { bodyPrimaryColor } from 'styles/styleVariables'

const GlobalStyles = createGlobalStyle`
  html {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
  }

  body {
    margin: 0;

    h1 {
      font-size: 1.5rem;
      font-weight: 500;
    }

    h2 {
      font-size: 1.125rem;
      font-weight: 500;
    }

    h3 {
      font-size: 1rem;
      font-weight: 500;
    }

    a {
      font-size: 1rem;
      text-decoration: none;
      color: ${bodyPrimaryColor};
      :visited {
        color: ${bodyPrimaryColor};
      }
    }

    strong {
      font-weight: 500;
    }

    button {
      cursor: pointer;
      user-select: none;
    }
  }
`

export default GlobalStyles
