import type { FuzzySearchProps } from 'modals/DurInputMappingsModal/types'
import AllergiesFuzzySearch from './AllergiesFuzzySearch'
import ConditionsFuzzySearch from './ConditionsFuzzySearch'
import MedicationsFuzzySearch from './MedicationsFuzzySearch'

interface MedicationProps extends FuzzySearchProps {
  type: 'medications'
}
interface ConditionProps extends FuzzySearchProps {
  type: 'conditions'
}
interface AllergyProps extends FuzzySearchProps {
  type: 'allergies'
}

type Props = MedicationProps | ConditionProps | AllergyProps

const FuzzySearchAutoComplete = (props: Props) => {
  const { type } = props

  if (type === 'medications') return <MedicationsFuzzySearch {...(props as MedicationProps)} />

  if (type === 'allergies') return <AllergiesFuzzySearch {...(props as AllergyProps)} />

  if (type === 'conditions') return <ConditionsFuzzySearch {...(props as ConditionProps)} />

  return <></>
}

export default FuzzySearchAutoComplete
