import type { ReactComponentElement } from 'react'
import { useMutation, useQuery } from '@truepill/tpos-react-router'
import AccuracyScanningItem from 'components/AccuracyScanningItem'
import AccuracyScanningList from 'components/AccuracyScanningList'
import { GET_OTC_BY_SKUS, OVERRIDE_OTC_PRODUCT } from 'gql'
import useErrorToast from 'hooks/toast/useErrorToast'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import { useTaskContext } from 'providers/TaskProvider'
import type { Otc, Order } from 'types'

const OTCPackingItemListAccuracy = (props: { order: Order }): ReactComponentElement<typeof AccuracyScanningList> => {
  const { order } = props

  const skus = order.otcProducts?.map(otcProduct => otcProduct.sku) || []
  const { data: otcs, loading: otcsLoading } = useQuery<{ getOtcBySKUS: Otc[] }>(GET_OTC_BY_SKUS, {
    variables: { customerId: order.customerId, skus },
    skip: !order.otcProducts?.length,
  })

  const { tasks } = useTaskContext()

  const showSuccessToast = useSuccessToast(true)
  const showErrorToast = useErrorToast()
  const [overrideOtcProduct] = useMutation<
    { overrideOtcProduct: { _id: string; overrideScan: boolean } },
    { orderId: string; otcProductId: string }
  >(OVERRIDE_OTC_PRODUCT, {
    onError: (e: Error) => showErrorToast('Failed to override OTC Product: ' + e.message),
    onCompleted: () => showSuccessToast('OTC Product successfully overridden'),
  })

  const handleOverride = (productId: string) => {
    overrideOtcProduct({ variables: { orderId: order._id, otcProductId: productId } })
  }

  return (
    <AccuracyScanningList loading={!order.otcProducts || otcsLoading}>
      {order.otcProducts?.map(otcProduct => {
        const packSize = otcs?.getOtcBySKUS.find(otc => otc.sku === otcProduct.sku)?.quantity ?? 0
        const scansNeeded = packSize ? otcProduct.quantity / packSize : Number.NaN
        const numberOfScansNotSupported = !Number.isInteger(scansNeeded)

        return (
          <AccuracyScanningItem
            key={otcProduct._id}
            product={{
              ...otcProduct,
            }}
            packingData={{
              error: numberOfScansNotSupported ? 'Qty Error - Please report to your lead' : '',
              packSize,
            }}
            tasks={tasks}
            handleOverride={() => handleOverride(otcProduct._id)}
            productType="OTC"
          />
        )
      })}
    </AccuracyScanningList>
  )
}

export default OTCPackingItemListAccuracy
