import { useCallback } from 'react'
import type { ApolloError } from '@truepill/tpos-react-router'
import { useQuery, useMutation } from '@truepill/tpos-react-router'
import { LAST_CHECKED_RELEASE_NOTES, UPDATE_LAST_CHECKED_RELEASE_NOTES } from 'gql'
import { usePlusClient } from 'providers/VisionRouter'

interface useLastReleaseCheckedReturnType {
  lastReleaseChecked: string
  setNewReleaseChecked: (lastReleaseChecked: string) => void
  fetchStatus: LastReleaseStatus
}

const useUserId = () => {
  const {
    tokenContext: { id },
  } = usePlusClient()
  return id
}

const useUpdateLastCheckedReleaseNotes = (userId: string) => {
  const [updateLastCheckedReleaseNotes, { loading, error }] = useMutation<boolean, { id: string; release: string }>(
    UPDATE_LAST_CHECKED_RELEASE_NOTES,
    {
      refetchQueries: [
        {
          query: LAST_CHECKED_RELEASE_NOTES,
          variables: { userId },
        },
      ],
    },
  )

  return {
    updateLastCheckedReleaseNotes,
    loading,
    error,
  }
}

const useGetLastCheckedReleaseNotes = (userId: string) => {
  const { data, loading, error } = useQuery<{ lastChecked: { lastCheckedReleaseNotes: string } }, { userId: string }>(
    LAST_CHECKED_RELEASE_NOTES,
    {
      skip: !userId,
      variables: { userId },
    },
  )

  const {
    lastChecked: { lastCheckedReleaseNotes },
  } = data ?? { lastChecked: { lastCheckedReleaseNotes: '' } }

  return {
    lastCheckedReleaseNotes: lastCheckedReleaseNotes ?? '',
    loading,
    error,
  }
}

export enum LastReleaseStatus {
  Loading,
  Fetched,
  Error,
}

const getStatus = (loading: boolean, error?: ApolloError) => {
  let fetchStatus = LastReleaseStatus.Loading
  if (!loading && !error) {
    fetchStatus = LastReleaseStatus.Fetched
  } else if (!loading && error) {
    fetchStatus = LastReleaseStatus.Error
  }

  return fetchStatus
}

const useLastReleaseChecked = (): useLastReleaseCheckedReturnType => {
  const userId = useUserId()

  const { lastCheckedReleaseNotes, loading, error } = useGetLastCheckedReleaseNotes(userId)
  const { updateLastCheckedReleaseNotes } = useUpdateLastCheckedReleaseNotes(userId)

  const setNewReleaseChecked = useCallback(
    (lastReleaseChecked: string) => {
      updateLastCheckedReleaseNotes({ variables: { id: userId, release: lastReleaseChecked } })
    },
    [userId, updateLastCheckedReleaseNotes],
  )

  const fetchStatus = getStatus(loading, error)

  return { lastReleaseChecked: lastCheckedReleaseNotes, setNewReleaseChecked, fetchStatus }
}

export default useLastReleaseChecked
