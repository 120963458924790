import type { MessageHeader } from './shared'

export enum PrescriptionDeliveryMethod {
  'Electronic Delivery' = 1,
  'Facsimile Delivery',
}

export interface StatusShared {
  Description?: string
  PrescriptionDeliveryMethod?: PrescriptionDeliveryMethod
}

export interface Status extends StatusShared {
  Code: string
  DescriptionCode?: string
}

export interface VerifyStatus extends StatusShared {
  Code: string
}

export interface StatusMessageRoot {
  Message: Partial<{
    Header: MessageHeader
    Body: Partial<{
      Status: Status
    }>
  }>
}
