import { useCallback, useContext, useState } from 'react'
import { Box, Checkbox } from '@truepill/react-capsule'
import type { ReviewDuplicateRxCtx } from '../ReviewDuplicateRxContext'
import { ReviewDuplicateRxContext } from '../ReviewDuplicateRxContext'
import type { ChosenDuplicateReason, TableRow } from '../types'
import DiscontinueReasonSelect from './DiscontinueReasonSelect'

const DiscontinueReasonInputs = ({ item }: { item: TableRow }) => {
  const { selectedDuplicatePrescriptions, setSelectedDuplicatePrescriptions } = useContext(
    ReviewDuplicateRxContext,
  ) as ReviewDuplicateRxCtx

  const handleSelect = useCallback(
    (opt: ChosenDuplicateReason, remove?: boolean) => {
      const index = selectedDuplicatePrescriptions?.findIndex(currOpt => currOpt.prescriptionId === opt.prescriptionId)

      if (index !== -1) {
        const selectedValuesCloned = [...selectedDuplicatePrescriptions]

        if (remove) {
          selectedValuesCloned.splice(index, 1)
          setSelectedDuplicatePrescriptions(selectedValuesCloned)
          return
        }

        selectedValuesCloned.splice(index, 1)
        selectedValuesCloned.push(opt)
        setSelectedDuplicatePrescriptions(selectedValuesCloned)
      } else {
        setSelectedDuplicatePrescriptions([...selectedDuplicatePrescriptions, opt])
      }
    },
    [selectedDuplicatePrescriptions, setSelectedDuplicatePrescriptions],
  )

  const [isOptionListOpened, setIsOptionListOpened] = useState(false)
  const isChecked = selectedDuplicatePrescriptions.some(s => s.prescriptionId === item.id)
  return (
    <Box css={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '100%' }}>
      <Checkbox
        css={{ marginRight: '0.5rem' }}
        checked={isChecked}
        onClick={() => {
          if (!isChecked) {
            setIsOptionListOpened(() => !isOptionListOpened)
          } else {
            handleSelect({ reason: '', description: '', prescriptionId: `${item.id}` }, true)
          }
        }}
      />
      <DiscontinueReasonSelect
        isOpened={isOptionListOpened}
        setIsOpened={setIsOptionListOpened}
        onChange={o => {
          handleSelect({ ...o, prescriptionId: `${item.id}` })
        }}
      />
    </Box>
  )
}

export default DiscontinueReasonInputs
