import { useMutation } from '@truepill/tpos-react-router'
import { DURStatus } from '@truepill/tpos-types'
import type { ActionButtonProps } from 'components/ActionButton'
import ActionButton from 'components/ActionButton'
import LoadingSpinner from 'components/Loading'
import ClinicalReviewTile from 'components/Tiles/ClinicalReviewTile'
import { FORCE_STATUS_TO_COMPLETE } from 'gql'
import useErrorToast from 'hooks/toast/useErrorToast'
import styled from 'styled-components'
import type { Order } from 'types'

interface ForceStatusToCompleteButtonProps extends ActionButtonProps {
  order: Order
}

const Spinner = styled.div`
  width: 15px;
`

export const ForceStatusToCompleteButton = (props: ForceStatusToCompleteButtonProps): JSX.Element => {
  const [forceStatusToComplete, { loading, error }] = useMutation(FORCE_STATUS_TO_COMPLETE)
  const showErrorToast = useErrorToast()

  const handleClick = async () => {
    if (!props.order.hasShippingLabel) {
      showErrorToast('Failed to force complete: order does not have a shipping label')
      return
    }
    await forceStatusToComplete({ variables: { orderId: props.order._id } })
  }

  if (error) {
    console.error('Forcing status to "Complete" failure', error)
    showErrorToast(`Forcing status to Complete failed. ${error}`)
  }

  return (
    <ActionButton
      label="Force Complete"
      onClick={handleClick}
      icon={<Spinner>{loading ? <LoadingSpinner /> : <ClinicalReviewTile durStatus={DURStatus.SEVERE} />}</Spinner>}
      {...props}
    />
  )
}
