import type { Context } from 'react'
import { useContext, useState, useRef, useEffect, useReducer, createContext } from 'react'
import useAutoUpdatingRef from 'hooks/useAutoUpdatingRef'
import type {
  Address,
  DrugICD10,
  Patient,
  GuestPatient,
  Prescriber,
  Prescription,
  Pharmacy,
  Fill,
  TPOSDocument,
  TPOSLocation,
  Order,
  ChildProps,
} from 'types'

// Form Step and StepFormState are used for multi step forms where you want to
// navigate backwards and forwards between steps
export interface FormStep {
  name: string
  completed?: boolean
}

export interface StepFormState {
  stepIndex: number
  steps: FormStep[]
}

export type FormValueType =
  | string
  | number
  | boolean
  | Date
  | undefined
  | null
  | { [key: string]: FormValueType }
  | Patient
  | GuestPatient
  | Prescriber
  | Pharmacy
  | Prescription
  | Fill
  | Address
  | FormValueType[]
  | FormStep
  | StepFormState
  | DrugICD10
  | TPOSDocument
  | TPOSLocation
  | any[]
  | TPOSLocation[]
  | Order

export type NameSpacedState = { [key: string]: FormValueType }

export interface FormState {
  [key: string]: FormValueType
}

interface Funcs {
  saveValues: () => void
  restoreSavedValues: () => void
  setStepFormState: (newValues: Partial<StepFormState>) => void
  setValues: (nameSpace: string, newValues: FormState) => void
  setArrayValues: (nameSpace: string, newValue: any[]) => void
  clearValues: (nameSpace: string, newValues?: FormState) => void
  clearAllValues: (newValues?: FormState) => void
}

export interface FormDataProps extends Funcs {
  formData: FormState
  stepFormState: StepFormState
}

interface FormDataAction {
  type: 'clear' | 'set' | 'clearAll' | 'save' | 'restore' | 'setArray'
  nameSpace: string
  payload: NameSpacedState | any[]
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noopFunction = () => {}

const FormDataContext = createContext<FormDataProps>({
  formData: {},
  stepFormState: {
    stepIndex: -1,
    steps: [],
  },
  saveValues: noopFunction,
  restoreSavedValues: noopFunction,
  setStepFormState: noopFunction,
  setValues: noopFunction,
  setArrayValues: noopFunction,
  clearValues: noopFunction,
  clearAllValues: noopFunction,
})

const FormStateReducer = (state: FormState, action: FormDataAction) => {
  const { type, nameSpace, payload } = action
  const nameSpacedState = state[nameSpace] as NameSpacedState
  const { saved, ...currentState } = state
  let newState: FormState

  switch (type) {
    case 'clear':
      newState = {
        ...state,
        [nameSpace]: payload,
      }
      break
    case 'clearAll':
      newState = { ...payload }
      break
    case 'set':
      newState = {
        ...state,
        [nameSpace]: {
          ...nameSpacedState,
          ...payload,
        },
      }
      break
    case 'setArray':
      newState = {
        ...state,
        [nameSpace]: payload,
      }
      break
    case 'save':
      newState = {
        ...state,
        saved: { ...currentState },
      }
      break
    case 'restore':
      newState = saved as FormState
      break
  }

  return newState
}

type FormDataProviderProps = {
  resetHash?: string
  initialData?: FormState
  initialStepFormState?: StepFormState
  customContext?: Context<FormDataProps>
} & ChildProps

const FormDataProvider = (props: FormDataProviderProps): JSX.Element => {
  const {
    initialData = {},
    resetHash,
    initialStepFormState = {
      stepIndex: -1,
      steps: [],
    },
    customContext,
    ...rest
  } = props

  const [formState, dispatch] = useReducer(FormStateReducer, {
    resetHash,
    ...initialData,
  })

  const firstLoad = useRef(true)

  // We don't want to be having updates to this value triggering resets
  // that haven't been asked for by the resetHash BUT the data is still required
  const initialDataRef = useAutoUpdatingRef(initialData)

  // Changing the top level initial data will wipe all form data
  useEffect(() => {
    if (!firstLoad.current) {
      dispatch({
        type: 'clearAll',
        nameSpace: '',
        payload: { resetHash, ...initialDataRef.current },
      })
    }
  }, [firstLoad, initialDataRef, dispatch, resetHash])

  useEffect(() => {
    firstLoad.current = false
  }, [firstLoad])

  const [stepFormState, setStepFormState] = useState(initialStepFormState)

  const value = {
    stepFormState,
    formData: formState,
    setValues: (nameSpace: string, newValues: FormState) => {
      dispatch({ type: 'set', nameSpace, payload: newValues })
    },
    setArrayValues: (nameSpace: string, newValue: any[]) => {
      dispatch({ type: 'setArray', nameSpace, payload: newValue })
    },
    clearValues: (nameSpace: string, newValues: FormState = {}) => {
      dispatch({ type: 'clear', nameSpace, payload: newValues })
    },
    clearAllValues: (newValues: FormState = {}) => {
      dispatch({ type: 'clearAll', nameSpace: '', payload: newValues })
    },
    saveValues: () => {
      dispatch({ type: 'save', nameSpace: '', payload: {} })
    },
    restoreSavedValues: () => {
      dispatch({ type: 'restore', nameSpace: '', payload: {} })
    },
    setStepFormState: (newValues: Partial<StepFormState>) => {
      setStepFormState(currentStepFormState => ({ ...currentStepFormState, ...newValues }))
    },
  }

  if (customContext) {
    return <customContext.Provider value={value} {...rest} />
  }

  return <FormDataContext.Provider value={value} {...rest} />
}

export const useFormDataContext = (): FormDataProps => {
  const formDataContext = useContext(FormDataContext)

  if (formDataContext === undefined) {
    throw new Error('Attempting to read FormDataContext outside a Provider heirarchy')
  }

  return formDataContext
}

export default FormDataProvider
