import type { EntityId } from '..'
import type LogData from '../../events/Log'

export enum PrescriptionOrigin {
  WRITTEN = 'written',
  TELEPHONE = 'telephone',
  ELECTRONIC = 'electronic',
  FAX = 'fax',
  PHARMACY = 'pharmacy',
  UNKNOWN = 'unknown',
}

export enum RxStatus {
  OnHold = 'OnHold',
  InFulfillment = 'InFulfillment',
  ExpiredNoRefills = 'ExpiredNoRefills',
  Transferred = 'Transferred',
  Discontinued = 'Discontinued',
  Filled = 'Filled',
  CopayCheck = 'CopayCheck',
}

export enum CoreRxStatus {
  OnHold = 'On Hold',
  FillInProgress = 'Fill in Progress',
  ExpiredNoRefills = 'Expired or No Refills',
  FillRequested = 'Fill Requested',
  ExternalReadOnly = 'External Readonly',
  Deleted = 'Deleted',
  QuantityRemaining = 'Quantity Remaining',
  RefillsRemaining = 'Refills Remaining',
  Transferred = 'Transferred',
  Discontinued = 'Discontinued',
}

export const INACTIVE_RX_STATUSES = [
  CoreRxStatus.ExpiredNoRefills.toString(),
  CoreRxStatus.Deleted.toString(),
  CoreRxStatus.ExternalReadOnly.toString(),
  RxStatus.Discontinued.toString(),
  RxStatus.ExpiredNoRefills.toString(),
  RxStatus.Transferred.toString(),
]

export interface UpdatePrescribed {
  ndc?: string
  name?: string
  daw?: number
  quantity?: number
  daysSupply?: number
  directions?: string
  totalQuantity?: number
  numberOfRefills?: number
  refillsRemaining?: number
  writtenDate?: string
  expirationDate?: string
  prn?: boolean
  quantityUnitOfMeasure?: string
  customerId?: string
  prescriberId?: string
  prescriberAddressId?: string
  note?: string
  newAnnotations?: LogData[]
  idc10?: string[]
}

export enum RxDiscontinueReason {
  IncorrectDrugDispensed = 'Quality issue - incorrect drug dispensed',
  IncorrectQuantity = 'Quality issue - incorrect quantity',
  IncorrectPrescriber = 'Quality issue - incorrect prescriber selected',
  PrescriberRequestedDiscontinue = 'Prescriber request to discontinue',
  PatientRequestedDiscontinue = 'Patient request to discontinue',
  NoLongerValid = 'Prescription no longer valid',
  DirectTransferRejected = 'Direct Transfer Rejected',
  CancellationRequest = 'Cancellation request',
  DuplicatePrescription = 'Prescription is a duplicate of another',
  NewerRxReceived = 'Newer Rx received',
}

export interface RxImageRegistry {
  rxImageId: EntityId
  createdAt?: Date
  updatedAt?: Date
}

export interface ExceptionNDC {
  ndc: string
  multiplier: number
  medicationName: string
}

export interface SigCodeInput {
  code: string
  en?: string
  es?: string
  active?: boolean
  multiplier?: number
  exceptionNDC?: ExceptionNDC[]
}

export interface SigMappingInput {
  code: string
  codeId: EntityId
  sig: string
  active?: boolean
  exceptionNDC?: ExceptionNDC[]
}

export interface SigMappingInputFormatted {
  sig: string
  sigCode: {
    codeId: EntityId
    code: string
  }
  active: boolean
}

export interface DiscontinuePrescriptionsUnsuccessful {
  prescriptionId: string
  error: string
  rxNumber?: number
}

export interface DiscontinuePrescriptionsSuccessful {
  prescriptionId: string
  rxNumber: number
}

export interface DiscontinuePrescriptionsResponse {
  unsuccessful: DiscontinuePrescriptionsUnsuccessful[]
  successful: DiscontinuePrescriptionsSuccessful[]
}
