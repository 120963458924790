import { useState } from 'react'
import ActionButton from 'components/ActionButton'
import styled, { css } from 'styled-components'
import { bodyPrimaryColor, contrastColor, primaryBackgroundColor } from 'styles/styleVariables'

interface SegmentedSelectOption {
  id: string
  label: string
}

type SegmentedSelectButtonProps = {
  options: SegmentedSelectOption[]
  selectionChanged: (newSelectionId: SegmentedSelectOption['id']) => void
  initiallySelectedOptionId?: SegmentedSelectOption['id']
}

const SegmentedSelectButton = ({
  options,
  initiallySelectedOptionId,
  selectionChanged,
}: SegmentedSelectButtonProps): JSX.Element => {
  const [selectedId, setSelectedId] = useState(initiallySelectedOptionId)

  return (
    <Container>
      {options.map(({ id, label }, i) => (
        <StyledActionButton
          key={i}
          label={label}
          onClick={() => {
            setSelectedId(id)
            selectionChanged(id)
          }}
          selected={selectedId === id}
        />
      ))}
    </Container>
  )
}

const SelectedStyle = css`
  background-color: ${contrastColor};
  color: ${primaryBackgroundColor};
`

const StyledActionButton = styled(ActionButton)<{ selected: boolean }>`
  ${({ selected }) => selected && SelectedStyle}
  margin: 0rem;
  border: none;
  border-radius: 0rem;
  :hover {
    box-shadow: none;
  }
`

const Container = styled.ul`
  > button {
    :first-child {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }
    :last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }
    :not(:last-child) {
      border-right: 0.0625rem solid ${bodyPrimaryColor};
    }
  }
`

export default SegmentedSelectButton
