import { useState } from 'react'
import DropDownOption from 'components/DropDownOption'
import SearchAutoComplete from 'components/SearchAutoComplete'
import { SearchResultLozenge, SearchResultLozengeList } from 'components/Tiles/Lozenge'
import useGetEscriptValidationIssueTypes from 'hooks/navigation/useGetEscriptValidationIssueTypes'
import { usePlusClient } from 'providers/VisionRouter'
import styled from 'styled-components'
import { borderColor } from 'styles/styleVariables'

interface StatusSuggestion {
  val: string
  name: any
  highlighted?: boolean
  selected?: boolean
}

interface Error {
  error: string
}

interface Loading {
  loading: boolean
}

type StatusSuggestionItem = StatusSuggestion | Error | Loading

export type StatusValFilterSetting = {
  [key in any]: boolean
}

type StatusObject = {
  val: any
  name: string
}

type StatusSearchProps = {
  isVisible?: boolean
  hotKey?: string
  isModal?: boolean
}

const IntakeIssueStatusSearch = ({ isVisible, hotKey = 's', isModal }: StatusSearchProps): JSX.Element => {
  const { routeToMergedQuery, currentLocation } = usePlusClient()
  const [searchTerm, setSearchTerm] = useState('')

  const statuses: { [key: string]: string }[] = []
  const { data: validationIssuesData } = useGetEscriptValidationIssueTypes()
  const selectedStatuses: string[] = []

  validationIssuesData?.getEscriptValidationIssueTypes.forEach(issueType => {
    statuses.push({ val: issueType._id, name: issueType.description })
    if (
      currentLocation.queryMap.intakeIssueStatuses &&
      currentLocation.queryMap.intakeIssueStatuses.indexOf(issueType._id) !== -1
    ) {
      selectedStatuses.push(issueType.description)
    }
  })

  return (
    <>
      <SearchAutoComplete
        hotKey={isVisible ? hotKey : ''}
        isModal={isModal}
        iconColor={borderColor}
        placeholder={'Search by status…'}
        autoCompleteSuggestions={statuses.filter(c => c.name.toLowerCase().startsWith(searchTerm.toLowerCase()))}
        value={searchTerm}
        onSelect={chosen => {
          const status = chosen as StatusObject
          routeToMergedQuery({ intakeIssueStatuses: [status.val] })

          setSearchTerm('')
        }}
        suggestionComponent={optionProps => <AutoCompleteOption {...(optionProps as StatusSuggestionItem)} />}
        onChange={setSearchTerm}
      />
      <SearchResultLozengeList>
        {selectedStatuses.map((statusVal: string) => (
          <SearchResultLozenge
            key={statusVal}
            closeCallback={() => {
              if (selectedStatuses.includes(statusVal)) {
                const newStatuses = [...selectedStatuses]
                newStatuses.splice(selectedStatuses.indexOf(statusVal), 1)

                routeToMergedQuery({ intakeIssueStatuses: newStatuses })
              }
            }}
          >
            <StatusName>{statusVal}</StatusName>{' '}
          </SearchResultLozenge>
        ))}
      </SearchResultLozengeList>
    </>
  )
}

const AutoCompleteOption = (props: StatusSuggestionItem): JSX.Element => {
  const status = props as StatusObject

  return (
    <DropDownOption {...status}>
      <StatusName>{status.name}</StatusName>
    </DropDownOption>
  )
}

const StatusName = styled.p`
  text-transform: capitalize;
`

export default IntakeIssueStatusSearch
