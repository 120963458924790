export interface Address {
  line1?: string
  line2?: string
  street1: string
  street2?: string
  state: string
  city: string
  zip: string
  country?: string
}

export const stateAllOption = 'All'
