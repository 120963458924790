import type { Dispatch, SetStateAction } from 'react'
import { useState, useEffect } from 'react'
import { useQuery } from '@truepill/tpos-react-router'
import { UserRoles, FillStatus, RxFillRequestStatus, OrderStatus, LaunchDarkly } from '@truepill/tpos-types'
import AuthLimited from 'components/AuthLimited'
import BreadCrumb from 'components/BreadCrumb'
import DuplicateRxBanner from 'components/DuplicateRxBanner'
import FillRequestDetails from 'components/FillRequestDetails'
import { PageContent, PageHeading, StickyPageHeadingContainer } from 'components/PageStructure'
import ReviewPatient from 'components/ReviewPatient'
import ReviewPrescription from 'components/ReviewPrescription'
import RxHeading from 'components/RxHeading'
import RxReviewPrescriber from 'components/RxReviewPrescriber'
import { RXRowTable, RXSegment, RXTable } from 'components/RXTable'
import RXTopBanner from 'components/RXTopBanner'
import ToolBar, {
  DataButton,
  EditButton,
  LogButton,
  MedicalHistoryButton,
  MonographButton,
  ClaimSummaryButton,
  NotesButton,
  OrderButton,
  AttachmentsButton,
  DispensingDecisionButton,
  EditReversalInfoButton,
} from 'components/ToolBar'
import TriageIssues from 'components/TriageIssues'
import { GET_PRESCRIPTION_WARNINGS } from 'gql'
import { useUserInfo } from 'hooks'
import { uniqueLogEntries } from 'hooks/navigation/useLogs'
import { useLastFillDiff } from 'hooks/useLastFillDiff'
import useLogPageView from 'hooks/useLogPageView'
import { usePopup } from 'hooks/usePopup'
import useRunClinicalReview from 'hooks/useRunClinicalReview'
import DurInputMappingsModal from 'modals/DurInputMappingsModal/DurInputMappingsModal'
import { EditUracModal } from 'modals/EditUracModal'
import MedicalHistoryModal from 'modals/MedicalHistoryModal'
import MismatchedPatientPrescriptionIdModal from 'modals/MismatchedPatientPrescriptionIdModal'
import useCriticalNotesModal from 'modals/useCriticalNotesModal'
import { useClient, useFlag } from 'providers/LaunchDarklyProvider'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import { usePlusClient } from 'providers/VisionRouter'
import styled from 'styled-components'
import type { TPOSDocument, Log, RXFillRequest, Fill, Patient, Order, Prescription } from 'types'
import { getOrderAttachmentsCount, getParentAttachments } from 'utils'
import ReviewSafety from '../../components/ReviewSafety'
import EditReversalInfoView from '../claims/editReversalInfoView'
import ActionButtons from './ActionButtons'

const useMismatchedPatientFillModal = (prescription?: Prescription, order?: Order) => {
  const { showModal } = useModalContext()

  useEffect(() => {
    if (!prescription || !order?.patient?._id || !showModal) {
      return
    }
    if (prescription.patientId !== order.patient._id) {
      showModal(() => <MismatchedPatientPrescriptionIdModal order={order} prescription={prescription} />)
    }
  }, [prescription, order])
}

const useMedicalHistoryModal = (patientId: string, fillId: string, rxFillRequest: RXFillRequest) => {
  const { showModal } = useModalContext()
  useEffect(() => {
    if (!showModal) {
      return
    }
    const isControlledSubstance = rxFillRequest.prescription.isControlledSubstance
    if (isControlledSubstance) {
      showModal(() => {
        return <MedicalHistoryModal patientId={patientId} fillId={fillId} />
      })
    }
  }, [patientId, rxFillRequest.prescription.isControlledSubstance])
}

type PV1ViewProps = {
  notesBadgeCount: number
  patient: Patient
  prescription: Prescription
  order: Order
  fill: Fill
  rxFillRequest: RXFillRequest
  docs?: TPOSDocument[]
  orderLogs: Log[]
  disableRphButtons: boolean
  forceLoadingSpinner?: Dispatch<SetStateAction<boolean>>
}

interface PrescriptionWarning {
  prescriptionId: string
  type?: string
}

const PV1View = ({
  patient,
  prescription,
  order,
  notesBadgeCount,
  fill,
  rxFillRequest,
  docs,
  orderLogs,
  disableRphButtons,
  forceLoadingSpinner,
}: PV1ViewProps): JSX.Element => {
  const [canConfirm, setCanConfirm] = useState<boolean>()
  const customer = prescription.customer
  const { client: ldClient } = useClient()
  const { _id: patientId } = patient

  const {
    tokenContext: { id },
  } = usePlusClient()
  const { userInfo } = useUserInfo(id)

  const { sendMessageToPopup } = usePopup()
  useLogPageView({
    page: `order/${rxFillRequest.status}${order.inTriage ? '/Triage' : ''}`,
    orderId: order._id,
    fillId: fill._id,
  })
  useCriticalNotesModal({ order, prescription, logs: orderLogs })
  useMismatchedPatientFillModal(prescription, order)
  useMedicalHistoryModal(patientId, fill._id, rxFillRequest)

  // Feature flags helpers - so the values are set after LD client indentificaction
  const [showCopayCollected, setShowCopayCollected] = useState(false)
  const [shouldValidateCopayPayment, setShouldValidateCopayPayment] = useState(false)

  const newDurInputsModalEnabled = useFlag(LaunchDarkly.FeatureFlags.TEMP_DUR_TOKEN_MATCHING)
  const displayCopayCollected = useFlag(LaunchDarkly.FeatureFlags.DISPLAY_COLLECTED_COPAY_IN_FRONTEND)
  const hideConsentOnPv1 = useFlag(LaunchDarkly.FeatureFlags.TEMP_HIDE_CONSENT_ON_PV1)
  const hideRefillsWrittenField: boolean = useFlag(LaunchDarkly.FeatureFlags.TEMP_HIDE_REFILLS_WRITEEN_FIELD_RX_REVIEW)
  const highlightPv1Differences = useFlag(LaunchDarkly.FeatureFlags.TEMP_HIGHLIGHT_PV1_DIFFERENCES)
  const useCopayPaymentValidation = useFlag(LaunchDarkly.FeatureFlags.USE_COPAY_PAYMENT_VALIDATION)

  // We should not include more identify calls into this component or in its children components
  useEffect(() => {
    if (ldClient) {
      ldClient.identify({
        key: customer?.legacyId.toString() ?? '',
        email: userInfo?.email ?? '',
      })

      setShowCopayCollected(displayCopayCollected)
      setShouldValidateCopayPayment(useCopayPaymentValidation)
    }
  }, [ldClient, customer, displayCopayCollected, setShouldValidateCopayPayment, useCopayPaymentValidation, userInfo])

  useEffect(() => {
    sendMessageToPopup<{ prescriptionId: string; source: string }>({
      prescriptionId: prescription._id,
      source: 'vision',
    })

    return () => {
      sendMessageToPopup<{ prescriptionId: string; source: string }>({ prescriptionId: '', source: 'vision' })
    }
  }, [prescription._id, sendMessageToPopup])

  const prescriptionId = prescription._id
  const { data } = useQuery<{ getPrescriptionWarnings: PrescriptionWarning[] }>(GET_PRESCRIPTION_WARNINGS, {
    variables: { prescriptionId },
  })
  const prescriptionWarnings = data?.getPrescriptionWarnings ?? []
  const duplicatePrescriptionWarning = prescriptionWarnings.filter(warning => warning.type === 'Duplicate Prescription')
  const [showDurModal, setShowDurModal] = useState(false)
  const [displayEditReversalInfo, setDisplayEditReversalInfo] = useState(false)

  const {
    runClinicalReview,
    loading: clinicalReviewIsLoading,
    error: clinicalReviewError,
  } = useRunClinicalReview({
    onCompleted: () => {
      // Close the modal just in case is opened
      setShowDurModal(false)
    },
    onError: () => {
      // Close the modal just in case is opened
      setShowDurModal(false)
    },
  })

  const {
    dispensedFields: highlightedDispensedFields,
    prescribedFields: highlightedPrescribedFields,
    prescriberFields: highlightedPrescriberFields,
    patientFields: highlightedPatientFields,
  } = useLastFillDiff(
    {
      prescription,
      fill,
    },
    { skip: !highlightPv1Differences },
  )

  if (!order || !fill || !prescription || !patient) {
    return <></>
  }

  const fillNotCancelled =
    fill.status !== FillStatus.Cancelled && rxFillRequest.status !== RxFillRequestStatus.Cancelled

  const orderInPV1 = order.status === OrderStatus.PV1
  const orderInTriage = order.inTriage

  const allowClaimSummaryButton = (fill?.claims?.length ?? 0) > 0
  const failedClaimReversals = fill.claims.find(claim => claim?.status === 'Paid' && claim?.failedReversals?.length > 0)

  const orderAttachments = getOrderAttachmentsCount(docs)
  const parentAttachments = getParentAttachments(docs)

  if (displayEditReversalInfo) {
    if (!order || !rxFillRequest) {
      return <></>
    }
    return (
      <EditReversalInfoView
        item={order}
        itemFill={rxFillRequest}
        prescription={prescription}
        patient={patient}
        fill={fill}
        setDisplayEditReversalInfo={setDisplayEditReversalInfo}
      />
    )
  }

  return (
    <>
      <StickyPageHeadingContainer>
        <RXTopBanner order={order} rxFillRequest={rxFillRequest} />
        {/* We don't want the DuplicateRxBanner to appear in Adjudication & Cancelled pages */}
        {fillNotCancelled && orderInPV1 && !orderInTriage ? (
          duplicatePrescriptionWarning.length > 0 && (
            <DuplicateRxBanner
              mainPrescriptionId={prescriptionId}
              duplicatePrescriptionsIds={duplicatePrescriptionWarning.map(p => p.prescriptionId)}
              orderId={order._id}
            />
          )
        ) : (
          <></>
        )}
        <StyledBreadCrumb />
        <PageHeading>
          <RxHeading item={order} prescription={prescription} fill={fill} patient={patient} />
          {fillNotCancelled && (
            <ActionButtons
              order={order}
              fill={fill}
              prescription={prescription}
              rxFillRequest={rxFillRequest}
              canConfirm={canConfirm}
              disableRphButtons={disableRphButtons}
              forceLoadingSpinner={forceLoadingSpinner}
              onPrescribedNdcUpdated={() => {
                // run the clinical review after prescribed ndc is updated
                runClinicalReview({
                  variables: {
                    orderId: order._id,
                    fillId: fill._id,
                  },
                })
              }}
            />
          )}
        </PageHeading>
      </StickyPageHeadingContainer>
      <PageContent>
        <RXViewContainer>
          {order.inTriage && (
            <TriageIssues
              item={order}
              fill={fill}
              itemFill={rxFillRequest}
              useCopayPaymentValidation={shouldValidateCopayPayment}
            />
          )}
          <RXTable>
            <RXSegment>
              <RXRowTable>
                <ReviewPatient
                  patient={patient}
                  prescription={prescription}
                  rightContent={
                    <FillRequestDetails
                      fill={fill}
                      hideConsent={hideConsentOnPv1}
                      order={order}
                      prescription={prescription}
                      rxFillRequest={rxFillRequest}
                      showCopayCollected={showCopayCollected}
                    />
                  }
                  rightHeader={<>Fill Request</>}
                  highlightedFields={highlightedPatientFields}
                />
                <RxReviewPrescriber prescription={prescription} highlightedFields={highlightedPrescriberFields} />
                <ReviewPrescription
                  item={order}
                  prescription={prescription}
                  fill={fill}
                  setCanConfirm={setCanConfirm}
                  hideRefillsWrittenField={hideRefillsWrittenField}
                  highlightedDispensedFields={highlightedDispensedFields}
                  highlightedPrescribedFields={highlightedPrescribedFields}
                />
                <ReviewSafety
                  order={order}
                  fill={fill}
                  rxFillRequest={rxFillRequest}
                  prescription={prescription}
                  showEditUracModal={() => {
                    setShowDurModal(true)
                  }}
                  clinicalReviewError={clinicalReviewError}
                  isClinicalReviewLoading={clinicalReviewIsLoading}
                  onRerunClinicalReviewClick={() => {
                    runClinicalReview({
                      variables: {
                        orderId: order._id,
                        fillId: fill._id,
                      },
                    })
                  }}
                />
                {showDurModal &&
                  (newDurInputsModalEnabled ? (
                    <DurInputMappingsModal
                      currentDurInputs={order.durInputs}
                      survey={order.survey}
                      orderId={order._id}
                      isOpen={showDurModal}
                      onClose={() => {
                        setShowDurModal(false)
                      }}
                    />
                  ) : (
                    <EditUracModal
                      durInputs={order.durInputs}
                      orderId={order._id}
                      isOpen={showDurModal}
                      forceIsLoading={clinicalReviewIsLoading}
                      onClose={() => {
                        // close modal
                        setShowDurModal(false)
                      }}
                      onURACUpdateCompleted={() => {
                        // run the clinical review after URAC is updated
                        runClinicalReview({
                          variables: {
                            orderId: order._id,
                            fillId: fill._id,
                          },
                        })
                      }}
                    />
                  ))}
              </RXRowTable>
            </RXSegment>
          </RXTable>
        </RXViewContainer>
        <ToolBar paddingTop={order.inTriage && order.inTriage?.reason?.length > 1}>
          {fillNotCancelled && (
            <AuthLimited
              roles={[
                UserRoles.Pharmacist,
                UserRoles.Technician,
                UserRoles.CustomerSupport,
                UserRoles.LeadCustomerSupport,
                UserRoles.Admin,
              ]}
            >
              <EditButton orderId={order._id} />
            </AuthLimited>
          )}
          <LogButton
            logs={orderLogs ? uniqueLogEntries(orderLogs) : []}
            fillId={fill._id}
            prescriptionId={prescription._id}
          />
          <NotesButton notesCount={notesBadgeCount} showBadge={notesBadgeCount > 0} />
          <AttachmentsButton
            attachmentsCount={orderAttachments}
            showBadge={parentAttachments || orderAttachments > 0}
          />
          <MedicalHistoryButton fillId={fill._id} patientId={patientId} />
          <OrderButton order={order} />
          <DataButton prescription={prescription} />
          <MonographButton ndc={fill.dispensed.ndc} />
          {allowClaimSummaryButton && (
            <ClaimSummaryButton
              fill={fill}
              order={order}
              rxFillRequest={rxFillRequest}
              allowDisable={true}
              prescription={prescription}
            />
          )}
          {failedClaimReversals && <EditReversalInfoButton setDisplayEditReversalInfo={setDisplayEditReversalInfo} />}
          <DispensingDecisionButton prescription={prescription} fillId={fill._id} />
        </ToolBar>
      </PageContent>
    </>
  )
}

const RXViewContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-top: 1.25rem;
  padding-left: 1.875rem;
`

const StyledBreadCrumb = styled(BreadCrumb)`
  margin-top: 1.25rem;
`

export default PV1View
