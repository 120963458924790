import { useState } from 'react'
import { OrderStatus, RxStatus, CopayStatus } from '@truepill/tpos-types'
import DropDownOption from 'components/DropDownOption'
import SearchAutoComplete from 'components/SearchAutoComplete'
import { SearchResultLozenge, SearchResultLozengeList } from 'components/Tiles/Lozenge'
import { usePlusClient } from 'providers/VisionRouter'
import styled from 'styled-components'
import { borderColor } from 'styles/styleVariables'

type Statuses = RxStatus | OrderStatus

interface StatusSuggestion {
  val: string
  name: Statuses
  highlighted?: boolean
  selected?: boolean
}

interface Error {
  error: string
}

interface Loading {
  loading: boolean
}

type StatusSuggestionItem = StatusSuggestion | Error | Loading

export type StatusValFilterSetting = {
  [key in Statuses]: boolean
}

type StatusObject = {
  val: Statuses
  name: string
}

type StatusSearchProps = {
  isVisible?: boolean
  hotKey?: string
  isModal?: boolean
  statusType?: 'prescription' | 'order' | 'copay'
}

const statusEnums = {
  prescription: RxStatus,
  order: OrderStatus,
  copay: CopayStatus,
}
const StatusSearch = ({
  isVisible,
  hotKey = 's',
  isModal,
  statusType = 'prescription',
}: StatusSearchProps): JSX.Element => {
  const { routeToMergedQuery, currentLocation } = usePlusClient()
  const [searchTerm, setSearchTerm] = useState('')

  const statuses: { [key: string]: string }[] = []
  const chosenStatusEnum = statusEnums[statusType] || OrderStatus

  for (const statusValue in chosenStatusEnum) {
    statuses.push({ val: statusValue, name: statusValue })
  }

  const selectedStatuses = currentLocation.queryMap.statuses || []

  return (
    <>
      <SearchAutoComplete
        hotKey={isVisible ? hotKey : ''}
        isModal={isModal}
        iconColor={borderColor}
        placeholder={'Search by status…'}
        autoCompleteSuggestions={statuses.filter(c => c.name.toLowerCase().startsWith(searchTerm.toLowerCase()))}
        value={searchTerm}
        onSelect={chosen => {
          const status = chosen as StatusObject
          if (!selectedStatuses.includes(status.val))
            routeToMergedQuery({ statuses: [...selectedStatuses, status.val] })

          setSearchTerm('')
        }}
        suggestionComponent={optionProps => <AutoCompleteOption {...(optionProps as StatusSuggestionItem)} />}
        onChange={setSearchTerm}
      />
      <SearchResultLozengeList>
        {selectedStatuses.map((statusVal: string) => (
          <SearchResultLozenge
            key={statusVal}
            closeCallback={() => {
              if (selectedStatuses.includes(statusVal)) {
                const newStatuses = [...selectedStatuses]
                newStatuses.splice(selectedStatuses.indexOf(statusVal), 1)

                routeToMergedQuery({ statuses: newStatuses })
              }
            }}
          >
            <StatusName>{statusVal}</StatusName>{' '}
          </SearchResultLozenge>
        ))}
      </SearchResultLozengeList>
    </>
  )
}

const AutoCompleteOption = (props: StatusSuggestionItem): JSX.Element => {
  const status = props as StatusObject

  return (
    <DropDownOption {...status}>
      <StatusName>{status.name}</StatusName>
    </DropDownOption>
  )
}

const StatusName = styled.p`
  text-transform: capitalize;
`

export default StatusSearch
