import { useEffect, useState } from 'react'
import { OutboundTransferStatus } from '@truepill/tpos-types'
import { default as GenericTopBanner } from 'components/TopBanner'
import { ProblemList } from 'components/TopBanner/OrderTriageTopBanner'
import type { OutboundTransfer } from 'types'
import { formatDate } from 'utils/dates'

const getActiveTriages = (transfer: OutboundTransfer) => transfer.triages?.filter(triage => !triage.endDate)

const getErrorsFromTransfer = (transfer: OutboundTransfer) => {
  const { rejectionDetails, status } = transfer
  let accumErrors = [] as string[]

  const activeTriages = getActiveTriages(transfer)
  if (activeTriages?.length) {
    accumErrors = [
      ...accumErrors,
      ...activeTriages.map(
        triage =>
          `${formatDate(triage.startDate, 'MM/DD/YYYY')} - ${triage.reason}${
            triage.message ? ` - ${triage.message}` : ''
          }`,
      ),
    ]
  } else if (status === OutboundTransferStatus.Rejected && rejectionDetails) {
    accumErrors.push(
      `This transfer has been rejected. ${rejectionDetails.reason}${
        rejectionDetails.message ? ` (${rejectionDetails.message})` : ''
      }`,
    )
  }

  return accumErrors
}

const TopBanner = ({ transfer }: { transfer: OutboundTransfer }): JSX.Element => {
  const [errors, setErrors] = useState([] as string[])

  useEffect(() => {
    setErrors(getErrorsFromTransfer(transfer))
  }, [transfer])

  return errors.length ? (
    <GenericTopBanner message={getActiveTriages(transfer)?.length ? 'Triage' : 'There are problems with this transfer'}>
      <ProblemList>
        {errors.map((error, i) => (
          <li key={i}>{error}</li>
        ))}
      </ProblemList>
    </GenericTopBanner>
  ) : (
    <></>
  )
}

export default TopBanner
